export enum MarketExplorerSegmentBreakdownValueTypeId {
  Average = 'Average',
  Median = 'Median',
  Best = 'Best',
  Worst = 'Worst',
}

export interface MarketExplorerSegmentBreakdownValueType {
  id: MarketExplorerSegmentBreakdownValueTypeId
  name: string
}
