import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, ListSubheader, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import marketExplorerService from '../../../../services/MarketExplorerService'
import { MarketExplorerGamesChartAxisId } from '../../types/MarketExplorerGamesChartAxisId'
import {
  MarketExplorerGamesChartAxisScale,
  MarketExplorerGamesChartAxisType,
  MarketExplorerGamesChartAxisTypeId,
} from '../../types/MarketExplorerGamesChartAxisType'

const chartAxesTypes: MarketExplorerGamesChartAxisType[] = marketExplorerService.gamesChartAxes.reduce((typesArray, chartAxis) => {
  return [...typesArray, ...chartAxis.types]
}, [] as MarketExplorerGamesChartAxisType[])

interface Props {
  id: MarketExplorerGamesChartAxisId
  axis: MarketExplorerGamesChartAxisType
  axisMax: MarketExplorerGamesChartAxisScale
  axisMin: MarketExplorerGamesChartAxisScale
  onAxisSelectChange: (id: MarketExplorerGamesChartAxisId, axisType: MarketExplorerGamesChartAxisType) => void
  onMaxValueSelectChange: (
    id: MarketExplorerGamesChartAxisId,
    axisType: MarketExplorerGamesChartAxisType,
    axisScale: MarketExplorerGamesChartAxisScale,
    minAxisScale: MarketExplorerGamesChartAxisScale
  ) => void
  onMinValueSelectChange: (
    id: MarketExplorerGamesChartAxisId,
    axisType: MarketExplorerGamesChartAxisType,
    axisScale: MarketExplorerGamesChartAxisScale,
    maxAxisScale: MarketExplorerGamesChartAxisScale
  ) => void
}

const MarketExplorerGamesChartAxis: React.FC<Props> = ({ id, axis, axisMax, axisMin, onAxisSelectChange, onMaxValueSelectChange, onMinValueSelectChange }) => {
  const { t } = useTranslation()

  const selectHeadersAndMenuItems = useMemo(() => {
    return marketExplorerService.gamesChartAxes.map((group) => {
      return [
        <ListSubheader key={`${group.id}-${id}`}>{t(group.name)}</ListSubheader>,
        group.types.map((type) => {
          return (
            <MenuItem key={`${type.id}-${id}`} value={type.id}>
              {t(type.name)}
            </MenuItem>
          )
        }),
      ]
    })
  }, [t, id])

  const handleAxisSelectChange = (event: SelectChangeEvent) => {
    onAxisSelectChange(id, chartAxesTypes.find((axis) => axis.id === (event.target.value as MarketExplorerGamesChartAxisTypeId)) || chartAxesTypes[0])
  }

  const handleMaxValueSelectChange = (event: SelectChangeEvent) => {
    onMaxValueSelectChange(id, axis, axis.maxScales.find((scale) => scale.label === (event.target.value as string)) || axis.maxScales[0], axisMin)
  }

  const handleMinValueSelectChange = (event: SelectChangeEvent) => {
    onMinValueSelectChange(id, axis, axis.minScales.find((scale) => scale.label === (event.target.value as string)) || axis.minScales[0], axisMax)
  }

  const { maxValueMenuItems, minValueMenuItems } = useMemo(() => {
    const maxValueMenuItems = axis.maxScales.map((scale) => {
      return (
        <MenuItem key={`${axis.id}-max-${scale.label}`} value={scale.label}>
          {t(scale.label)}
        </MenuItem>
      )
    })

    const minValueMenuItems = axis.minScales.map((scale) => {
      return (
        <MenuItem key={`${axis.id}-min-${scale.label}`} value={scale.label}>
          {t(scale.label)}
        </MenuItem>
      )
    })

    return {
      maxValueMenuItems,
      minValueMenuItems,
    }
  }, [t, axis])

  return (
    <div className="MarketExplorerGamesChartAxis">
      <div className="MarketExplorerGamesChartAxis__Left">
        <FormControl sx={{ minWidth: 80 }}>
          <Select value={axisMax.label} size="small" style={{ fontSize: '12px' }} onChange={handleMaxValueSelectChange}>
            {maxValueMenuItems}
          </Select>
        </FormControl>
      </div>

      <div className="MarketExplorerGamesChartAxis__Center">
        <FormControl sx={{ minWidth: 120 }}>
          <Select value={axis.id} size="small" style={{ fontSize: '12px' }} onChange={handleAxisSelectChange}>
            {selectHeadersAndMenuItems}
          </Select>
        </FormControl>
      </div>

      <div className="MarketExplorerGamesChartAxis__Right">
        <FormControl sx={{ minWidth: 80 }}>
          <Select value={axisMin.label} size="small" style={{ fontSize: '12px' }} onChange={handleMinValueSelectChange}>
            {minValueMenuItems}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default MarketExplorerGamesChartAxis
