import { FC, memo, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { TopGame } from '../../types/TopGame'

type TopGameLinkProps = {
  children?: ReactNode
  topGame: TopGame
}

/**
 * Component for creating a link to Top Game page
 */
export const TopGameLink: FC<TopGameLinkProps> = memo(({ topGame, children }) => {
  const gameLink = `/game/${topGame.gameId}`

  return <Link to={gameLink}>{children}</Link>
})
