import { t } from 'i18next'
import React from 'react'

import { Lock } from '@mui/icons-material'
import { Divider, Typography, Grid, Box } from '@mui/material'

import { Screenshot, SimpleFeature } from '../../../../api/core'
import ScreenshotFeatureDots from '../../../../components/ScreenshotModal/ScreenshotFeatureDots'

/**
 * RelatedFeatureList list of features with choices and effect dots
 */
interface RelatedFeatureListProps {
  features?: Screenshot['features']
  locked?: boolean
}
const RelatedFeatureList: React.FC<RelatedFeatureListProps> = ({ features, locked }) => {
  if (!features || !features.length) {
    return null
  }

  return locked ? (
    <Box>
      <Divider>
        <Typography>{t('feature:related_features')}</Typography>
      </Divider>
      <Box sx={{ textAlign: 'center' }}>
        <Lock style={{ top: '5px', position: 'relative' }} />
        {t('overview:upgrade_plan_to_access_related_features')}
      </Box>
    </Box>
  ) : (
    <Box padding={2}>
      <Divider>
        <Typography>{t('feature:related_features')}</Typography>
      </Divider>
      <Grid style={{ maxWidth: '95%', margin: '0 auto' }} container direction="column">
        {(features as SimpleFeature[]).map((feature, i) => (
          <Grid item container key={i} direction="row" justifyContent="space-between">
            <Grid item xs>
              <Typography component="span">{feature?.featureName}</Typography>
            </Grid>
            <Grid item xs container justifyContent="center">
              <Typography component="span" color="primary">
                {feature.choiceName}
              </Typography>
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <ScreenshotFeatureDots feature={feature} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default RelatedFeatureList
