import { FC, ReactNode } from 'react'
import React from 'react'

import { MaskedText, MaskedTextProps } from '../MaskedText/MaskedText'

/**
 * Helper component to mask text elements
 */
type MaskedTextGuardProps = MaskedTextProps & {
  reveal?: boolean
  children?: ReactNode
}

export const MaskedTextGuard: FC<MaskedTextGuardProps> = ({ reveal, children, ...maskedTextProps }) => {
  const { text } = maskedTextProps
  return <>{reveal ? children ? children : text : <MaskedText {...maskedTextProps} />}</>
}
