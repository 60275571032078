export const AIAanalystPageExamples = {
  gamesAndMarket: [
    `Which are the top grossing puzzle games at the moment?`,
    `Which are the games in Casino genre that are trending in downloads at the moment?`,
    `What are the key features of Marvel Snap?`,
  ],
  innovation: [
    `What are some innovations in gacha mechanics?`,
    `Show me some current interesting or innovative games in Casino genre?`,
    `Are there any innovative Chinese mobile games in RPG genre?`,
  ],
  liveEventsAndUpdated: [
    `What are the latest updates for Gardenscapes?`,
    `Give me an overview of Candy Crush Friends Saga's live events`,
    `Which are the current live events for Monopoly Go! ?`,
  ],
  tipsAndGuidance: [
    `Do you have any tips how to implement a Battle Pass System?`,
    `What kind of meta layer could be useful in casual games?`,
    `Any tips for introducing seasonal events into my game?`,
    `Any tips for my game's ad monetization?`,
    `Does GameRefinery have blogs about gacha mechanics?`,
    `How could I use the GameRefinery's motivation framework in my game development process?`,
    `Any tips how to use Game Analyzer effectively?`,
  ],
}

export const AIAnalystPageCapabilites = ['Trained to provide truthful answers and committed to honesty']

export const AIAnalystPageLimitations = [
  `Doesn’t understand yet implementation examples`,
  `Doesn't remember what user said earlier in the conversation`,
  `Not good at comparing things (for example genres or games)`,
  `Is trained to understand mobile games, not good at answering unrelated questions`,
]

export const cuteRoboticThoughts = [
  `Please wait, while I process the data!`,
  `Calculating results... Beep boop beep!`,
  `Hold on a moment... Processing information.`,
  `Analyzing... Whirr click beep!`,
  `I'm crunching the numbers... Buzz buzz beep.`,
  `Just a second... Scanning database.`,
  `Working on it... Beep beep whirr!`,
  `Please stand by... Processing in progress.`,
  `Processing request... Bzzt beep beep!`,
  `Gathering data... Analyzing... Beep boop!`,
  `Hold tight... Initiating data evaluation.`,
  `Performing calculations... Whirr click beep!`,
  `I'm thinking... Buzz buzz beep.`,
  `Just a moment... Scanning information.`,
  `Processing your query... Beep beep whirr!`,
  `Please wait while I analyze... Bzzt beep beep!`,
  `Data processing... Stand by.`,
  `Processing... Beep boop!`,
  `Hold on... Processing in progress.`,
  `Analyzing your request... Whirr click beep!`,
]
