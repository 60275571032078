import { useMemo } from 'react'

import { Screenshot, useGetScreenshotsByConceptIdsQuery, useGetScreenshotsByFeaturesQuery } from '../../../api/core'
import { uniqueArray } from '../../../helpers/arrays'
import { useGetGameList } from '../../game/hooks/gameHooks'
import { FeatureGame } from '../../game/types/Game'

type LegacyScreenshot = Screenshot & {
  id?: string
  features: { [legacyId: string]: number }
  conceptIds: string[]
}

/**
 * We need to add feature legacyIds, because Genre selection filters are using it. Also, some places
 * are using 'screenshotId' param instead of just 'id'. Maybe fix that, if possible?
 *
 * @param screenshot to add needed params
 */
const legacyFeatureMapper = (screenshot: LegacyScreenshot): Screenshot => ({
  ...screenshot,
  screenshotId: screenshot.screenshotId || screenshot.id,
  features: Object.keys(screenshot.features).map((featureLegacyId) => ({
    featureLegacyId: parseInt(featureLegacyId, 10),
  })),
})

/**
 * Three queries: First ones gets all screenshots with given features & concepts. Then third one fetches all games
 * that screenshots are from. Return function combines games with screenshots.
 *
 * @param choices
 */
export const useGamesByFeatureScreenshots = (choices: number[], concepts: string[]) => {
  const { data: screenshotsByFeatures, isFetching: isFetchingScreenshotsByFeatures } = useGetScreenshotsByFeaturesQuery({ choices }, { skip: !choices?.length })
  const { data: screenshotsByConcepts, isFetching: isFetchingScreenshotsByConcepts } = useGetScreenshotsByConceptIdsQuery(concepts, { skip: !concepts?.length })

  // Combine, deduplicate, and sort screenshots in one step
  const combinedScreenshots = [...(screenshotsByFeatures ?? []), ...(screenshotsByConcepts ?? [])]
  const screenshotMap = new Map<string, Screenshot>()

  combinedScreenshots.forEach((screenshot) => {
    if (screenshot?.id) {
      const existing = screenshotMap.get(screenshot.id)
      if (!existing || (existing.versionReleaseDate || 0) < (screenshot.versionReleaseDate || 0)) {
        screenshotMap.set(screenshot.id, screenshot)
      }
    }
  })

  const screenshots = Array.from(screenshotMap.values()).sort((a, b) => (a.versionReleaseDate || 0) - (b.versionReleaseDate || 0))

  const gameIds = uniqueArray(screenshots.map(({ gameId }) => gameId)) as string[]

  const { data: games, isFetching } = useGetGameList(
    {
      gameIds,
      include: 'name,artist,icon,icons,visibility,genreId,archive,sranks,sdranks,appId,conventionalSubgenreId',
    },
    { skip: !screenshots.length || !gameIds.length }
  )

  // Map legacy screenshots to avoid repeating operations
  const featureScreenshots = (screenshotsByFeatures as LegacyScreenshot[])?.map(legacyFeatureMapper)
  const conceptScreenshots = (screenshotsByConcepts as LegacyScreenshot[])?.map(legacyFeatureMapper)

  // Memoize FeatureGame instances by gameId
  const featureGameCache = useMemo(() => new Map<string, FeatureGame>(), [])

  const data = useMemo(() => {
    if (!games?.length) return []

    return games?.map((game) => {
      let featureGame = featureGameCache.get(game.id)

      if (!featureGame) {
        featureGame = new FeatureGame(game)
        featureGameCache.set(game.id, featureGame)

        featureGame.featureScreenshots = featureScreenshots?.filter(({ gameId }) => gameId === game.id)
        featureGame.conceptScreenshots = conceptScreenshots?.filter(({ gameId }) => gameId === game.id)
      }

      return featureGame
    })
  }, [games, featureScreenshots, conceptScreenshots, featureGameCache])

  return {
    data,
    isFetching: isFetching || isFetchingScreenshotsByFeatures || isFetchingScreenshotsByConcepts,
  }
}
