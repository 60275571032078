import { PayloadAction } from '@reduxjs/toolkit'

import PageService from '../services/PageService'
import { TPage } from '../types/TPage'

const currentPageReducer = (state: TPage | undefined = PageService.getPageWithId('daily-insights'), action: PayloadAction<TPage>) => {
  switch (action.type) {
    case 'CHANGE_PAGE':
      return action.payload

    default:
      return state
  }
}
export default currentPageReducer
