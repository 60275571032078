import { t } from 'i18next'
import React from 'react'

import { Box, FormControlLabel, Button, FormGroup, FormControl, Switch } from '@mui/material'

import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useGameUpdateImpactsAccessCheck, useSoftLaunchAccessCheck } from '../../../account/hooks/roleHooks'
import { useIsOnFreePlan } from '../../../account/hooks/userHooks'
import newsEntriesService, { NewsEntryOptionsTypeId } from '../../../news/services/NewsEntriesService'

/**
 * NewEntriesType
 */
interface NewEntriesTypeProps {
  selectedNewsEntryTypes: { [k: string]: boolean }
  handleChange: (event: any) => void
  lockedFeatureDialogOpen: boolean
  setLockedFeatureDialogType: (value: string) => void
  setLockedFeatureDialogOpen: (value: boolean) => void
}
const NewEntriesType: React.FC<NewEntriesTypeProps> = ({
  selectedNewsEntryTypes,
  handleChange,
  lockedFeatureDialogOpen,
  setLockedFeatureDialogType,
  setLockedFeatureDialogOpen,
}) => {
  const hasAccessToGameUpdateImpacts = useGameUpdateImpactsAccessCheck()
  const newsEntryOptionTypes = newsEntriesService.getMappedWithLockedFeature()
  const hasAccessToSoftLaunch = useSoftLaunchAccessCheck()
  const isOnFreePlan = useIsOnFreePlan()

  const hasUpgradePlanButton = (type: { id: NewsEntryOptionsTypeId }) => {
    switch (type.id) {
      case NewsEntryOptionsTypeId.PostUpdate:
        return !hasAccessToGameUpdateImpacts
      case NewsEntryOptionsTypeId.SoftLaunch:
        return !hasAccessToSoftLaunch
      case NewsEntryOptionsTypeId.NewPublishersGame:
        return isOnFreePlan
      default:
        return false
    }
  }

  return (
    <div style={{ paddingLeft: '6px' }}>
      <FormControl component="fieldset" sx={{ width: '100%' }}>
        <FormGroup>
          {newsEntryOptionTypes.map((type) => (
            <Box key={type.id} sx={{ my: 0.5 }}>
              <FormControlLabel
                key={type.id}
                label={t<string>(`newsfeed-options:${type.id}`)}
                control={<Switch size="small" checked={selectedNewsEntryTypes[type.id]} onChange={handleChange} name={type.id} color="primary" />}
              />
              {hasUpgradePlanButton(type) && (
                <>
                  <Button
                    autoFocus
                    onClick={() => setLockedFeatureDialogType(type.lockedFeature)}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ float: 'right' }}
                  >
                    {t('common:unlock')}
                  </Button>
                  {type.lockedFeature !== '' && (
                    <LockedFeature.Dialog
                      lockedFeatureId={type.lockedFeature as LockedFeatureId}
                      open={lockedFeatureDialogOpen}
                      onClose={() => setLockedFeatureDialogOpen(false)}
                    />
                  )}
                </>
              )}
            </Box>
          ))}
        </FormGroup>
      </FormControl>
    </div>
  )
}

export default NewEntriesType
