import React from 'react'

import { Box } from '@mui/material'

import { MotivationKey } from '../../../market-explorer/types/MotivationType'
import { MotivationArchetypeCardBadge } from '../MotivationArchetypeCardBadge/MotivationArchetypeCardBadge'
import { MotivationLevel, MotivationTypeBadge, MotivationTypeWithLevel } from '../MotivationTypeBadge/MotivationTypeBadge'

interface Props {
  motivationKeys: MotivationKey[]
  motivationTypesWithLevel: MotivationTypeWithLevel[]
  alignLeft?: boolean
}

export const MotivationArchetypeAndTypeBadges: React.FC<Props> = ({ motivationKeys, motivationTypesWithLevel, alignLeft }) => {
  return (
    <Box className="ArchetypesAndMotivationTypeBadges" textAlign={alignLeft ? 'left' : 'center'}>
      <Box>
        {motivationTypesWithLevel
          .filter((typeWithLevel) => typeWithLevel.level === MotivationLevel.MAJOR)
          .map((typeWithLevel) => {
            return (
              <MotivationTypeBadge
                key={typeWithLevel.type}
                motivationTypeWithLevel={{ type: typeWithLevel.type, level: typeWithLevel.level }}
                alignLeft={alignLeft}
              />
            )
          })}
      </Box>

      <Box>
        {motivationTypesWithLevel
          .filter((typeWithLevel) => typeWithLevel.level === MotivationLevel.MINOR)
          .map((typeWithLevel) => {
            return (
              <MotivationTypeBadge
                key={typeWithLevel.type}
                motivationTypeWithLevel={{ type: typeWithLevel.type, level: typeWithLevel.level }}
                alignLeft={alignLeft}
              />
            )
          })}
      </Box>

      <Box mb={2}>
        {motivationKeys.map((key) => {
          return <MotivationArchetypeCardBadge key={key} motivationKey={key} alignLeft={alignLeft} />
        })}
      </Box>
    </Box>
  )
}
