import { ReactNode, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'

/**
 * Component for providing a select all / clear selected funcionality
 */
type AllOrNothingSelectorProps = {
  someSelected: boolean
  deselectAll: () => void
  deselectAllText?: string | ReactNode
  disabled?: boolean
  selectAll: () => void
  selectAllText?: string | ReactNode
}

export const AllOrNothingSelector: FC<AllOrNothingSelectorProps> = ({ someSelected, deselectAll, deselectAllText, disabled, selectAll, selectAllText }) => {
  const { t } = useTranslation()

  return (
    <Button color={someSelected ? 'warning' : 'primary'} onClick={!someSelected ? selectAll : deselectAll} disabled={disabled}>
      {someSelected ? deselectAllText || t('common:clear_selected') : selectAllText || t('common:select_all')}
    </Button>
  )
}
