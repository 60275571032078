import { FC } from 'react'
import { Trans } from 'react-i18next'

import { FullscreenExit, Fullscreen } from '@mui/icons-material'
import { IconButton, Tooltip, TooltipProps } from '@mui/material'

type FullscreenTogglerProps = {
  active: boolean
  enterTooltip?: TooltipProps['title']
  exitTooltip?: TooltipProps['title']
  onClick: () => void
}

export const FullscreenToggler: FC<FullscreenTogglerProps> = ({
  active,
  enterTooltip = <Trans i18nKey={'common:fullscreen_enter'} />,
  exitTooltip = <Trans i18nKey={'common:fullscreen_exit'} />,
  onClick,
}) => {
  return (
    <Tooltip title={active ? exitTooltip : enterTooltip} disableInteractive>
      <IconButton color="secondary" size="small" onClick={onClick} sx={{ borderWidth: 1, borderStyle: 'solid', borderColor: 'secondary' }}>
        {active ? <FullscreenExit fontSize="inherit" /> : <Fullscreen fontSize="inherit" />}
      </IconButton>
    </Tooltip>
  )
}
