import { useMemo } from 'react'

import { Filters } from '..'
import { dateDifference } from '../../../helpers/date'
import { SubgenreMap } from '../../account'
import { Game } from '../../game'

/**
 * Custom hook for filtering given game array with given filters
 */
export const useSoftLaunchGameFilters = (games: Game[], filters: Filters) => {
  // filter by days since released
  const filteredByDaysSinceReleased = useDaysSinceReleasedFilter(games, filters.daysSinceReleased)

  // filter by subgenres
  const filteredBySubgenres = useSubgenreFilter(filteredByDaysSinceReleased, filters.subgenres)

  return { filteredGames: filteredBySubgenres }
}

const useDaysSinceReleasedFilter = (games: Game[], daysSinceReleased?: string) => {
  const filteredGames = useMemo(() => {
    const days: number = Number(daysSinceReleased)
    const today = new Date()
    return days > 0 && isFinite(days) ? games.filter((game) => dateDifference(new Date(game.released), today) <= days) : games
  }, [games, daysSinceReleased])

  return filteredGames
}

const useSubgenreFilter = (games: Game[], subgenres?: SubgenreMap) => {
  const filteredGames = useMemo(
    () => (subgenres && Object.entries(subgenres).length > 0 ? games.filter((game) => subgenres && subgenres[game.conventionalSubgenreId]) : games),
    [games, subgenres]
  )

  return filteredGames
}
