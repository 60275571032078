import classNames from 'classnames'
import { RefObject, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typewriter from 'typewriter-effect'

import BugReportIcon from '@mui/icons-material/BugReport'
import LinkIcon from '@mui/icons-material/Link'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import { Avatar, Box, Card, Fade, Grid, Chip, Badge, Typography } from '@mui/material'

import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { cuteRoboticThoughts } from '../../../pages/AIAnalyst/AIAnalystPageInfo'
import { useUserInitials } from '../../account/hooks/userHooks'
import { AIChatHistortyItemType, AIChatHistoryItem, BotAIChatHistoryItem } from '../types/AIChatHistoryItem'
import './ChatBubble.scss'

export interface ChatBubbleProps {
  item: AIChatHistoryItem
  showAdditionalData?: boolean
  bottomRef?: RefObject<HTMLElement>
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ item, showAdditionalData, bottomRef }) => {
  const { t } = useTranslation()
  const currentUserInitials = useUserInitials()
  const [isTyping, setIsTyping] = useState(item.type === AIChatHistortyItemType.Bot || item.type === AIChatHistortyItemType.Loading)
  const iconClassNames = classNames({
    'pulse-avatar': item.type === AIChatHistortyItemType.Loading,
    error: item.type === AIChatHistortyItemType.Error,
  })

  return (
    <Card className="ChatBubble">
      <Grid container spacing={0} p={2} gap={0}>
        <>
          <Grid item width="55px" justifyItems="center">
            {item.type === AIChatHistortyItemType.User && <Avatar sx={{ bgcolor: '#273143' }}>{currentUserInitials}</Avatar>}
            {(item.type === AIChatHistortyItemType.Bot || item.type === AIChatHistortyItemType.Loading) && (
              <Box sx={{ m: 0, position: 'relative' }}>
                <Avatar sx={{ bgcolor: '#bd65cf' }} className={iconClassNames}>
                  <SmartToyIcon />
                </Avatar>
              </Box>
            )}
          </Grid>
          <Grid item xs={10} sm={10} md={11}>
            <Typography component={'span'}>
              {item.type === AIChatHistortyItemType.User && item.message}

              {item.type === AIChatHistortyItemType.Bot && isTyping && (
                <Typewriter
                  options={{
                    strings: item.message,
                    cursor: '',
                    loop: false,
                    delay: 2.5,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(item.message)
                      .callFunction(() => {
                        setIsTyping(false)
                        if (bottomRef && bottomRef.current) {
                          const windowHeight = window.innerHeight || document.documentElement.clientHeight
                          const { y } = bottomRef.current.getBoundingClientRect()

                          if (y > windowHeight) {
                            bottomRef.current?.scrollIntoView({
                              behavior: 'smooth',
                            })
                          }
                        }
                      })
                      .start()
                  }}
                />
              )}

              {item.type === AIChatHistortyItemType.Bot && !isTyping && item.message && (
                <div dangerouslySetInnerHTML={{ __html: item.message.toString() }}></div>
              )}

              {item.type === AIChatHistortyItemType.Loading && (
                <Typewriter
                  options={{
                    cursor: '_',
                    loop: false,
                    delay: 5,
                  }}
                  onInit={(typewriter) => {
                    typewriter.typeString(cuteRoboticThoughts[Math.floor(Math.random() * cuteRoboticThoughts.length)]).start()
                  }}
                />
              )}
            </Typography>

            {item.type === AIChatHistortyItemType.Bot && !isTyping && item.source && !(item as BotAIChatHistoryItem).answerNotKnown && (
              <Fade in={true} timeout={500}>
                <Box mt={1}>
                  <Typography variant="subtitle1" mb={1}>
                    For more information, check out these sources:
                  </Typography>
                  {item.source.map((source, i) => (
                    <Fade in={true} timeout={500} key={i}>
                      <Box mt={0.5} alignItems="center" justifyItems="center" display="flex" height="34px" ml={2}>
                        <Badge
                          badgeContent={
                            <GRTooltip content={t('motivations:importance')}>
                              <span>{i + 1}</span>
                            </GRTooltip>
                          }
                          color="primary"
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Chip
                            label={source}
                            component="a"
                            target="_blank"
                            href={source}
                            sx={{ marginBottom: '0px' }}
                            clickable
                            avatar={<LinkIcon color="primary" />}
                          />
                        </Badge>
                      </Box>
                    </Fade>
                  ))}

                  {showAdditionalData && (
                    <Fade in={true} timeout={500}>
                      <Box mt={1} alignItems="top" display="flex">
                        <BugReportIcon color="primary" />
                        <Typography variant="caption" color="text.secondary" ml={1}>
                          {item.prompt}
                        </Typography>
                      </Box>
                    </Fade>
                  )}
                </Box>
              </Fade>
            )}
          </Grid>
        </>
      </Grid>
    </Card>
  )
}

export default ChatBubble
