import { useMemo } from 'react'

import { Keyword, Tag } from '../../../api/core'
import { AllFeatureCategoriesValue } from '../../feature/components/FeatureCategorySelector/FeatureCategorySelector'
import { isCompareFeatureChoice, isKeyword } from '../../feature/helpers/helpers'
import { FeatureAndKeywordSearchResult } from '../../feature/types/types'
import { compareFeatureSetOpts } from '../components/FeatureSetSelection/FeatureSetSelection'
import { CompareFeatureRow } from './useCompareFeatureRows'

type useCompareFeatureRowsParams = {
  rows: CompareFeatureRow[]
  featureSearchValue: FeatureAndKeywordSearchResult
  featureTags: Tag[]
  categoryId: string
  featureSetOption: compareFeatureSetOpts
  keywords?: Keyword[]
}
export const useCompareFeatureFilterRows = ({
  rows,
  featureTags,
  featureSearchValue,
  categoryId,
  featureSetOption,
  keywords,
}: useCompareFeatureRowsParams): CompareFeatureRow[] => {
  return useMemo(() => {
    return (
      rows
        .filter((featureChoice) => {
          // filter by category
          return categoryId !== AllFeatureCategoriesValue ? featureChoice.categoryId === categoryId : true
        })
        .filter((featureChoice) => {
          // filter by selected feature, keyword or string
          if (isKeyword(featureSearchValue)) {
            return featureSearchValue.linkedIds.includes(featureChoice.featureId)
          } else if (isCompareFeatureChoice(featureSearchValue)) {
            return featureSearchValue.featureId === featureChoice.featureId
          } else if (typeof featureSearchValue === 'string') {
            const keywordMatch = keywords
              ?.filter((keyword) => keyword.name.toLocaleLowerCase().includes(featureSearchValue.toLocaleLowerCase()))
              .some((keyword) => keyword.linkedIds.includes(featureChoice.featureId))
            return keywordMatch || featureChoice.featureName.toLowerCase().includes(featureSearchValue.toLowerCase())
          }

          return true
        })
        .filter((featureChoice) => {
          // filter by feature tags, if feature search value is provided this filter is skipped
          return featureSearchValue
            ? true
            : featureTags.reduce((acc, featureTag) => {
                return acc || !!featureSearchValue || featureTag.targets.includes(featureChoice.featureId)
              }, false as boolean)
        })
        .filter((featureChoice) => {
          switch (featureSetOption) {
            case compareFeatureSetOpts.similar:
              return [...new Set(featureChoice.gameChoiceList)].length === 1
            case compareFeatureSetOpts.different:
              return [...new Set(featureChoice.gameChoiceList)].length > 1
            default:
              return true
          }
        }) || []
    )
  }, [categoryId, featureSearchValue, featureSetOption, featureTags, keywords, rows])
}
