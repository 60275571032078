import { FC, useMemo } from 'react'

import { Typography } from '@mui/material'

import { MaskedTextGuard } from '../../../../../../../components/MaskedTextGuard/MaskedTextGuard'
import languageService from '../../../../../../../services/LanguageService'
import { useLiveEventsTrackerAccessCheck } from '../../../../../../account/hooks/roleHooks'
import { EventDialogTab } from '../../../../../../live-events/hooks/useEventDialogTabs'
import { useLiveEventModal } from '../../../../../../live-events/hooks/useLiveEventModal'
import { UnifiedNewsEntry } from '../../../../../types/UnifiedNewsEntry'
import { getLiveEventDialogConfig } from '../../../../../utils/utils'
import { LiveEventSubHeader } from '../LiveEventSubHeader'

type LiveEventNewEventSubHeaderProps = {
  newsEntry: UnifiedNewsEntry
}

export const LiveEventNewEventSubHeader: FC<LiveEventNewEventSubHeaderProps> = ({ newsEntry }) => {
  const hasAccessToLiveEventsTracker = useLiveEventsTrackerAccessCheck()
  const { showModal: showEventModal } = useLiveEventModal()
  const handleClick = () => {
    showEventModal({ ...getLiveEventDialogConfig(newsEntry), tab: EventDialogTab.Description })
  }

  const startDate = useMemo(() => {
    return newsEntry.feedEntry.entryData.durations?.reduce((acc, duration) => {
      if (!acc || duration.start < acc) {
        return duration.start
      }
      return acc
    }, 0)
  }, [newsEntry.feedEntry.entryData.durations])

  const liveEventTypeName = languageService.getTranslation('tags', newsEntry.feedEntry.entryData.typeId)

  return (
    <LiveEventSubHeader linkLabel={newsEntry.feedEntry.subtitle} date={startDate} onLinkClick={handleClick}>
      <Typography variant="body1">
        <MaskedTextGuard reveal={hasAccessToLiveEventsTracker} text={liveEventTypeName} />
      </Typography>
    </LiveEventSubHeader>
  )
}
