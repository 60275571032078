import classNames from 'classnames'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import EditIcon from '@mui/icons-material/Edit'
import { Box, Card, Grid, IconButton, MenuItem, Select, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

import { analyzerGridColumn } from '../..'
import { Category, Choice, Feature, useDeleteAnalysisNoteMutation, useUpdateAnalysisNotesMutation, useUpdateFeatureAnswerMutation } from '../../../../api/core'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { HtmlContentParser } from '../../../../components/HtmlContentParser/HtmlContentParser'
import { LockedDataIndicator } from '../../../../components/LockedDataIndicator/LockedDataIndicator'
import ProgressBar from '../../../../components/ProgressBar/ProgressBar'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import languageService from '../../../../services/LanguageService'
import { RoleEnum } from '../../../account'
import { useAnalyzerUnlockedCheck, useRoleCheck } from '../../../account/hooks/roleHooks'
import { UpdateAnalysisAnswerPayload } from '../../../analysis/types/Analysis'
import { AnalysisNote } from '../../../analysis/types/AnalysisNotes'
import { FeatureLabelLink, FeatureLink } from '../../../feature/components/FeatureLink'
import { CategoryImage } from '../../../game-features/components/CategoryImage/CategoryImage'
import { GameAnalysisStatus } from '../../../game/types/GameAnalysisStatus'
import RichTextDialog from '../../../note/components/RichTextDialog/RichTextDialog'
import { displaySnackBar } from '../../../snackbar/actions/displaySnackBar'
import './FeatureCard.scss'

/**
 * FeatureToggleButtons Show options of answers with Yes/No style
 */
interface FeatureToggleButtonsProps {
  onChange: (value: number | string) => void
  value?: number | string
  options: Choice[]
}
const FeatureToggleButtons: React.FC<FeatureToggleButtonsProps> = ({ value, onChange, options }) => {
  const { t } = useTranslation()
  const handleToggleButtonChange = (event: React.MouseEvent<HTMLElement>, value: number | null) => {
    if (value) {
      onChange(value)
    }
  }

  return (
    <ToggleButtonGroup fullWidth size="small" value={value} exclusive onChange={handleToggleButtonChange} className="CategoryFeaturesCard">
      {options.map((option) => (
        <ToggleButton sx={{ textTransform: 'none' }} color="secondary" size="small" key={option.weightId} value={option.weightId} aria-label={option.choice}>
          <Typography>{t(`common:${option.choice.toLowerCase().trim()}`)}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

/**
 * FeatureSelect Show options of answers with dropdown select list
 */
interface FeatureSelectProps {
  options: Choice[]
  value?: string | number
  onChange: (value: string | number) => void
}
const FeatureSelect: React.FC<FeatureSelectProps> = ({ options, value, onChange }) => {
  const { t } = useTranslation()

  return (
    <div className={classNames('FeatureSelect', { hasValue: !!value })}>
      <Select variant="outlined" size="small" color="secondary" fullWidth value={value || 0} onChange={(e) => onChange(e.target.value)}>
        {!value && (
          <MenuItem value={0} disabled>
            {t('common:select')}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.weightId} value={option.weightId}>
            {languageService.getTranslation('choices', option.weightId.toString())}
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

/**
 * ScoreBar show value of answer on top and progress bar under it
 */
interface ScoreBarProps {
  feature: Feature
  choice: Choice
  barValue?: number
  disabled?: boolean
}
const ScoreBar: React.FC<ScoreBarProps> = ({ choice, barValue, disabled, feature }) => {
  const translatedChoice = languageService.getTranslation('choices', choice.weightId.toString())
  const userHasAnalyzerUnlocked = useAnalyzerUnlockedCheck()
  const isGameAnalyzerUnlimited = useRoleCheck(RoleEnum.game_analyzer_unlimited)

  return (
    <Box display="flex" justifyContent="center" alignItems="flex-start" flexDirection="column" className={classNames('ScoreBar', { disabled })}>
      <Box sx={{ maxWidth: '100%' }}>
        <FeatureLink
          feature={{
            featureLegacyId: feature.legacyId,
            featureName: feature.name,
          }}
          choice={{ name: choice.choice, choiceLegacyId: choice.weightId }}
          initialTab="facts"
          forceShowFeatureInList
        >
          <GRTooltip content={translatedChoice}>
            <Typography pr={2} className={classNames('ScoreBar__text')}>
              {translatedChoice}
            </Typography>
          </GRTooltip>
        </FeatureLink>
      </Box>
      <GRTooltip className="ScoreBar__progressBarTooltip" content={t('gps-breakdown:effect_on_gps')}>
        {userHasAnalyzerUnlocked && isGameAnalyzerUnlimited ? (
          <Box sx={{ width: '100%' }}>
            <ProgressBar value={barValue} variant="small" />
          </Box>
        ) : (
          <div className="ProgressBar">
            <LockedDataIndicator />
          </div>
        )}
      </GRTooltip>
    </Box>
  )
}

/**
 * CategoryFeaturesCard Show feature row in categories with multiple column
 */
interface FeaturesCardProps {
  note?: AnalysisNote
  feature: Feature
  currentAnswer?: number
  latestAnswer?: number
  latestScore?: number
  benchmarkAnswer?: number
  benchmarkScore?: number
  analysisId: string
  gameAnalyzedStatus?: GameAnalysisStatus
  category: Category
}
const FeatureCard: React.FC<FeaturesCardProps> = ({
  note,
  feature,
  currentAnswer,
  latestAnswer,
  benchmarkAnswer,
  analysisId,
  benchmarkScore,
  latestScore: currentScore,
  gameAnalyzedStatus,
  category,
}) => {
  const { id: gameId } = useParams<{ id: string }>()
  const [openRichTextDialog, setOpenRichTextDialog] = useState(false)

  const sortedFeatureChoices = [...feature.choices]
    .sort((a, b) => {
      return a.ordr - b.ordr
    })
    .filter((choice) => choice.active)

  const selectedAnswer = feature.choices.find((choice) => choice.weightId === currentAnswer)
  const latestSelectedAnswer = feature.choices.find((choice) => choice.weightId === latestAnswer)
  const benchmarkSelectedAnswer = feature.choices.find((choice) => choice.weightId === benchmarkAnswer)

  const [updateFeatureAnswer] = useUpdateFeatureAnswerMutation()
  const submitAnswer = (featureAnswer: number) => {
    const updateAnalysisAswer: UpdateAnalysisAnswerPayload = {
      add: true,
      answers: [{ choiceId: featureAnswer, featureId: feature.id }],
      categoryId: feature.categoryId,
      id: analysisId,
      genreId: null,
      subGenreId: null,
    }

    updateFeatureAnswer({ gameId: gameId || '', id: analysisId, add: true, payload: updateAnalysisAswer })
  }

  const scoreBarDisabled = currentAnswer !== latestAnswer
  const isToggleButtonType = sortedFeatureChoices.length === 2

  const dispatch = useAppDispatch()
  const [isNewNote, setIsNewNote] = useState(false)
  const [updateNote, { isError: isUpdateNoteError, isSuccess: isUpdateNoteSuccess }] = useUpdateAnalysisNotesMutation()
  const [deleteNote, { isError: isDeleteNoteError, isSuccess: isDeleteNoteSuccess, isLoading: isDeleteNoteLoading }] = useDeleteAnalysisNoteMutation()

  useEffect(() => {
    if (isUpdateNoteSuccess) {
      if (isNewNote) {
        dispatch(
          displaySnackBar({
            message: (
              <HtmlContentParser
                rawHtml={`${t('feature:success_added_note_for_feature', {
                  featureName: languageService.getTranslation('features', feature.legacyId.toString()),
                })}`}
              />
            ),
            severity: 'success',
            open: true,
          })
        )
        return
      }
      if (!isDeleteNoteLoading) {
        if (isDeleteNoteSuccess) {
          dispatch(displaySnackBar({ message: t('feature:note_deleted'), severity: 'success', open: true }))
          return
        } else {
          dispatch(
            displaySnackBar({
              message: (
                <HtmlContentParser
                  rawHtml={`${t('feature:edit_note_for', { label: languageService.getTranslation('features', feature.legacyId.toString()) })}`}
                />
              ),
              severity: 'success',
              open: true,
            })
          )
        }
      }
    } else if (isUpdateNoteError) {
      dispatch(
        displaySnackBar({
          message: (
            <HtmlContentParser
              rawHtml={`${t('feature:error_could_not_save_note_for_feature', {
                featureName: languageService.getTranslation('features', feature.legacyId.toString()),
              })}`}
            />
          ),
          severity: 'error',
          open: true,
        })
      )
    } else if (isDeleteNoteError) {
      dispatch(
        displaySnackBar({
          message: (
            <Trans
              i18nKey="feature:error_could_not_delele_note_for_feature"
              values={{ featureName: languageService.getTranslation('features', feature.legacyId.toString()) }}
            />
          ),
          severity: 'error',
          open: true,
        })
      )
    }
  }, [
    isUpdateNoteError,
    dispatch,
    note,
    feature.name,
    isNewNote,
    isUpdateNoteSuccess,
    isDeleteNoteSuccess,
    isDeleteNoteError,
    isDeleteNoteLoading,
    feature.legacyId,
  ])

  const handleTextEditorSave = (content: string) => {
    if (!note) {
      updateNote({
        featureLegacyId: feature.legacyId,
        gameId: gameId || '',
        notes: content,
      })
      setIsNewNote(true)
    } else {
      const modifiedNotes = { ...note } as AnalysisNote
      modifiedNotes.notes = content
      updateNote(modifiedNotes)

      setIsNewNote(false)
    }
    setOpenRichTextDialog(false)
  }

  const handleTextEditorDelete = async (noteId: string | undefined) => {
    try {
      if (note && note.id) {
        deleteNote({ noteId: note.id, gameId: note.gameId })
        setIsNewNote(false)
      }
      setOpenRichTextDialog(false)
    } catch (error) {
      setOpenRichTextDialog(true)
    }
  }

  return (
    <>
      <RichTextDialog
        note={note?.notes}
        open={openRichTextDialog}
        onSave={handleTextEditorSave}
        onClose={() => setOpenRichTextDialog(false)}
        onDelete={handleTextEditorDelete}
        label={`${t('feature:edit_note_for', { label: languageService.getTranslation('features', feature.legacyId.toString()) })}`}
      />
      <Card className="FeatureCard" sx={{ padding: analyzerGridColumn.spacing, mb: analyzerGridColumn.spacing }}>
        <Grid container>
          <Grid item xs={analyzerGridColumn.xs.name} display="flex" alignItems="center">
            <Box sx={{ mr: 2 }}>
              <CategoryImage src={category.icon.url} alt={category.name} />
            </Box>
            <Box sx={{ mt: '2px' }}>
              <FeatureLabelLink
                feature={{
                  featureLegacyId: feature.legacyId,
                  featureName: languageService.getTranslation('features', feature.legacyId.toString()),
                }}
                choice={{ name: selectedAnswer?.choice || '', choiceLegacyId: selectedAnswer?.weightId || feature.legacyId }}
              />
            </Box>
          </Grid>
          <Grid item xs={analyzerGridColumn.xs.options} display="flex" alignItems="center" justifyContent="center" sx={{ mr: analyzerGridColumn.spacing }}>
            {isToggleButtonType ? (
              <FeatureToggleButtons value={selectedAnswer?.weightId} onChange={(value) => submitAnswer(Number(value))} options={sortedFeatureChoices} />
            ) : (
              <FeatureSelect value={selectedAnswer?.weightId} onChange={(value) => submitAnswer(Number(value))} options={sortedFeatureChoices} />
            )}
          </Grid>
          <Grid item xs={analyzerGridColumn.xs.score} sx={{ mr: analyzerGridColumn.spacing }}>
            {latestSelectedAnswer &&
              ((gameAnalyzedStatus === GameAnalysisStatus.game_not_analyzed && currentScore !== 0) || gameAnalyzedStatus === GameAnalysisStatus.analyzed) && (
                <ScoreBar feature={feature} choice={latestSelectedAnswer} barValue={currentScore} disabled={scoreBarDisabled} />
              )}
          </Grid>
          <Grid item xs={analyzerGridColumn.xs.compare}>
            {benchmarkSelectedAnswer &&
              ((gameAnalyzedStatus === GameAnalysisStatus.game_not_analyzed && benchmarkScore !== 0) || gameAnalyzedStatus === GameAnalysisStatus.analyzed) && (
                <ScoreBar feature={feature} choice={benchmarkSelectedAnswer} barValue={benchmarkScore} disabled={scoreBarDisabled} />
              )}
          </Grid>
          <Grid item xs={analyzerGridColumn.xs.edit}>
            <IconButton
              color="primary"
              className={classNames('FeatureCard__EditButton', { HasNote: !!note })}
              onClick={() => setOpenRichTextDialog(true)}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default FeatureCard
