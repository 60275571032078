import { t } from 'i18next'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, CardContent } from '@mui/material'

import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { SubgenreMap } from '../../../account/types/UserSettings'
import { marketShareRootPath } from '../../../implementation-examples/hooks/useInitialState'
import MarketShareTopGames from '../../../market-share/components/MarketShareTopGames/MarketShareTopGames'
import { useCurrentMarket } from '../../../markets'
import { QuarterSelectorType } from '../../../quarter/components/QuarterSelector'
import {
  marketShareNumberTypes,
  marketShareScopeTypes,
  useFilteredQuarters,
  useGetMarketShareData,
  useGetMarketShareScopeTypeFilters,
} from '../../../top-game/hooks/topGrossingGameHooks'
import { TopGame } from '../../../top-game/types/TopGame'
import GenreEssentialCardHeader, { GenreEssentialSection } from '../GenreEssentialCardHeader/GenreEssentialCardHeader'

/**
 * LatestMarketShareCard
 */
interface LatestMarketShareCardProps {
  topGames: TopGame[]
  selectedSubgenres: SubgenreMap
  conventionalGenreId?: string
}
const LatestMarketShareCard: React.FC<LatestMarketShareCardProps> = ({ topGames, selectedSubgenres, conventionalGenreId }) => {
  const navigate = useNavigate()
  const { currentMarketIso: marketIso } = useCurrentMarket()

  const marketShareScopeType = marketShareScopeTypes[1]
  const { marketShareData } = useGetMarketShareData(marketShareScopeType, topGames)
  const { filteredQuarters, isLoading: isFetchingQuarters } = useFilteredQuarters()
  const { marketShareScopeTypeFilters, isLoading: isMarketShareFiltersLoading } = useGetMarketShareScopeTypeFilters(topGames, marketShareScopeTypes[1])

  const marketShareScopeTypeFilter = useMemo(() => {
    if (!marketShareScopeTypeFilters) return

    // if conventionalGenreId is not null return corresponding filter
    return conventionalGenreId ? marketShareScopeTypeFilters.find((filter) => filter.id === conventionalGenreId) : marketShareScopeTypeFilters[0]
  }, [conventionalGenreId, marketShareScopeTypeFilters])

  const handleOpenMarketSharePage = () => {
    navigate(marketShareRootPath + '/top-500-games')
  }

  const sortedQuarters = useMemo(() => {
    if (!filteredQuarters) return
    return [...filteredQuarters].sort((a, b) => b.startTimestamp - a.startTimestamp)
  }, [filteredQuarters])

  const isLoading = isFetchingQuarters || isMarketShareFiltersLoading

  if (!marketShareData || !sortedQuarters?.length || !marketShareScopeTypeFilters?.length) {
    return null
  }

  return (
    <Card>
      <GenreEssentialCardHeader
        section={GenreEssentialSection.marketShare}
        onClick={handleOpenMarketSharePage}
        title={t('genre-essentials:market_share_comparison_title')}
        description={t('genre-essentials:market_share_comparison_description', { selectedMarket: marketIso.toUpperCase() })}
        buttonLabel={t('genre-essentials:open_in_market_share') as string}
      />
      <CardContent>
        <Card>
          {isLoading ? (
            <GRCircularProgress />
          ) : (
            <MarketShareTopGames
              maxRows={5}
              calculatedQuarters={marketShareData.calculatedQuarters}
              topGamesWithGameQuarters={marketShareData.topGamesWithGameQuarters}
              marketShareScopeType={marketShareScopeType}
              marketShareNumberType={marketShareNumberTypes[0]}
              selectedQuarters={sortedQuarters}
              marketShareScopeTypeFilter={marketShareScopeTypeFilter}
              marketShareScopeTypeFilters={marketShareScopeTypeFilters}
              selectedSubgenres={Object.keys(selectedSubgenres)}
              quarterType={QuarterSelectorType.string}
              filterGenreType="string"
            ></MarketShareTopGames>
          )}
        </Card>
      </CardContent>
    </Card>
  )
}

export default LatestMarketShareCard
