import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Add, AddCircle, Check } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'

import { useGetUserProfileQuery } from '../../../../api/combined'
import { Collection, CollectionVisibility, useCreateCollectionMutation, useUpdateCollectionMutation } from '../../../../api/core'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import analyticsService from '../../../../services/AnalyticsService'
import { AnalyticsProviders } from '../../../../types/IAnalyticsProvider'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { useCollectionsGroupedByVisibility } from '../../../collections/hooks/collectionHooks'
import { categorizeCollections, CollectionCategory } from '../../../collections/utils/collectionHelpers'
import { Game } from '../../../game/types/Game'
import { displaySnackBar } from '../../../snackbar'
import './CollectionMenu.scss'

interface CollectionMenuProps {
  anchorEl: Element
  onClose: () => void
  screenshotId: string
  game: Game
}

interface CreateCollectionProps {
  onCancel: (e: React.MouseEvent<HTMLElement>) => void
  onCreateCollection: (params: Pick<Collection, 'name' | 'visibility'>) => void
}

type AllowedVisibility = CollectionVisibility.restricted | CollectionVisibility.organization

/**
 * The Create Collection view.
 *
 * @param onCancel returns to previous view
 */
const CreateCollection: React.FC<CreateCollectionProps> = ({ onCancel, onCreateCollection }) => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [visibility, setVisibility] = useState<AllowedVisibility>(CollectionVisibility.restricted)
  const isInternalUser = useRoleCheck(RoleEnum.internal)

  return (
    <Box className="createCollection">
      <Typography variant="h3" gutterBottom>
        {t('implementations:label_create_collection')}
      </Typography>
      <Divider />
      <TextField
        required={!name.length}
        error={name.length < 3}
        helperText={name.length < 3 && t('implementations:label_name_is_required_and_it_must_be_three_chars')}
        fullWidth
        size="small"
        label={t('implementations:collection_name')}
        sx={{ my: 2 }}
        value={name}
        onChange={({ target }) => setName(target.value.replace(/^\s/, ''))}
      />
      <FormControl>
        <FormLabel>{t('implementations:label_who_will_see_this_collection')}</FormLabel>
        <RadioGroup value={visibility} onChange={({ target }) => setVisibility(target.value as AllowedVisibility)}>
          <FormControlLabel
            value={CollectionVisibility.organization}
            control={<Radio />}
            label={t<string>('implementations:label_everyone_in_my_organization')}
          />
          <FormControlLabel value={CollectionVisibility.restricted} control={<Radio />} label={t<string>('implementations:label_restricted_group_of_users')} />
          {isInternalUser && <FormControlLabel value={CollectionVisibility.public} control={<Radio />} label={t<string>('implementations:label_everyone')} />}
        </RadioGroup>
      </FormControl>
      <Button variant="contained" color="primary" fullWidth onClick={() => onCreateCollection({ name, visibility })} disabled={name.length < 3}>
        {t('implementations:label_create_collection')}
      </Button>
      <Button variant="outlined" color="primary" fullWidth onClick={onCancel} sx={{ marginTop: 1 }}>
        {t('common:cancel')}
      </Button>
    </Box>
  )
}

const CollectionMenu: React.FC<CollectionMenuProps> = ({ anchorEl, onClose, screenshotId, game }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { data: currentUser } = useGetUserProfileQuery()
  const { data, isFetching } = useCollectionsGroupedByVisibility(true)
  const [updateCollection] = useUpdateCollectionMutation()
  const [createCollection] = useCreateCollectionMutation()

  const [createMode, setCreateMode] = useState(false)

  const onCreated = (name: string) => {
    const message = t('implementations:notify_screenshot_added_to_collection', { collection: name })
    dispatch(displaySnackBar({ message, severity: 'success', open: true }))
    analyticsService.trackEvent('Collection created', { data: { collectionName: name } })

    onClose()
  }

  const onCreateCollection = ({ name, visibility }: Pick<Collection, 'name' | 'visibility'>) => {
    const allowedUsers: string[] = []
    createCollection({ allowedUsers, name, screenshots: [screenshotId], visibility })

    onCreated(name)
  }

  const onSelect = ({ id, name, allowedUsers, visibility, screenshots }: Collection) => {
    updateCollection({
      allowedUsers,
      id,
      name,
      visibility,
      screenshots: [...screenshots, screenshotId],
    })
    onCreated(name)

    analyticsService.trackEvent('Screenshot Pinned', {
      data: {
        gameName: game.resolvedName,
        gameId: game.id,
        screenshotId,
        collectionId: name,
      },
      serviceToExclude: [AnalyticsProviders.hubspot, AnalyticsProviders.intercom],
    })
  }

  const categorizedCollections = data && categorizeCollections(Object.values(data).flat(), currentUser?.email as string)

  const metaItems = [
    <MenuItem key={0} onClick={(e) => setCreateMode(true)}>
      <ListItemIcon>
        <AddCircle fontSize="small" color="primary" />
      </ListItemIcon>
      <ListItemText>{t('implementations:label_create_collection')}</ListItemText>
    </MenuItem>,
  ]
  if (isFetching) {
    metaItems.push(
      <MenuItem key={1} className="loadingCollections">
        <CircularProgress color="primary" />
      </MenuItem>
    )
  }
  const collectionItems =
    !data || createMode
      ? []
      : Object.keys(categorizedCollections)
          .map((category, i) => {
            const collections = categorizedCollections[category as CollectionCategory]
            return collections.length
              ? [
                  <Divider key={'i' + i}>
                    {category === 'MY' && <Typography className="divider-overlay">{t('implementations:label_my_collections')}</Typography>}
                  </Divider>,
                  ...collections.map((collection, j) => {
                    const alreadySelected = screenshotId && collection.screenshots?.includes(screenshotId)
                    return (
                      <MenuItem
                        key={'i' + i + 'j' + j}
                        onClick={(evt) => {
                          evt.stopPropagation()
                          !alreadySelected && onSelect(collection)
                        }}
                      >
                        <ListItemIcon>{alreadySelected ? <Check color="success" /> : <Add />}</ListItemIcon>
                        <ListItemText className="collectionMenu__listItem">{collection.name}</ListItemText>
                      </MenuItem>
                    )
                  }),
                ]
              : []
          })
          .flat()
  return (
    <Menu
      open={true}
      anchorEl={anchorEl}
      onClick={(evt) => evt.stopPropagation()}
      onClose={onClose}
      className="collectionMenu"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {createMode ? <CreateCollection onCancel={(e) => setCreateMode(false)} onCreateCollection={onCreateCollection} /> : metaItems.concat(collectionItems)}
    </Menu>
  )
}

export default CollectionMenu
