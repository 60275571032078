import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useParams } from 'react-router-dom'

import { Screenshot, useGetGameVersionInfoForVersionQuery, useGetScreenshotListQuery } from '../../../../api/core'
import ScreenshotsModal from '../../../../components/ScreenshotModal/ScreenshotModal'
import { uniq } from '../../../../helpers/uniq'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { useRouteModalPath } from '../../../../hooks/useRouteModalPath'
import analyticsService from '../../../../services/AnalyticsService'
import { useNewsFeedLimitedAccessCheck } from '../../../account/hooks/roleHooks'
import { useGames } from '../../../game/hooks/gameHooks'
import { useCurrentMarket } from '../../../markets'
import { displaySnackBar } from '../../../snackbar'
import RelatedFeatureList from '../RelatedFeatureList/RelatedFeatureList'

interface Props {
  closePath?: string
}

type PathParams = { screenshots: string; shot?: string }

const ScreenshotsModalContainer: React.FC<Props> = ({ closePath }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const params = useParams<PathParams>() as PathParams
  const { screenshots: screenshotIds, shot: shotId } = params
  const hasNewsFeedLimitedAccess = useNewsFeedLimitedAccessCheck()

  const currentModalPath = createScreenshotModalPath({
    screenshots: screenshotIds.split(','),
    shot: shotId,
  })
  const updateDialogPath = useRouteModalPath({ currentModalPath })

  const { currentMarketIso } = useCurrentMarket()
  const { data: screenshots = [], isLoading: isScreenshotsLoading, error: screenshotsError } = useGetScreenshotListQuery(screenshotIds.split(','))
  const gameIds = useMemo(() => uniq(screenshots?.map((screenshot) => screenshot.gameId).filter((gameId) => !!gameId)), [screenshots]) as string[]

  const sortedScreenshotByIds = useMemo(() => {
    if (!screenshots.length) return []
    if (gameIds.length === 1) return screenshots
    const screenshotIdList = screenshotIds.split(',')
    if (!screenshotIdList.length) return screenshots
    return [...screenshots].sort((a, b) => screenshotIdList.indexOf(a.id as string) - screenshotIdList.indexOf(b.id as string))
  }, [gameIds.length, screenshotIds, screenshots])

  const { games, isLoading: isGamesLoading, error: gamesError } = useGames(gameIds, currentMarketIso)
  const game = games[0]
  const { data: versionInfo } = useGetGameVersionInfoForVersionQuery(
    { appId: game?.appId, version: game?.version, marketIso: currentMarketIso },
    { skip: !game }
  )

  useEffect(() => {
    if (!game) return

    if (!!game.id && !!game.resolvedName) {
      analyticsService.trackEvent('Screenshots viewed', {
        data: {
          gameId: game.id,
          gameName: game.resolvedName,
        },
      })
    }
  }, [game])

  useEffect(() => {
    if (screenshotsError || gamesError) {
      dispatch(displaySnackBar({ message: t('common:general_error_message'), severity: 'error', open: true }))
      updateDialogPath()
    }
  }, [dispatch, t, screenshotsError, gamesError, updateDialogPath])

  const handleClose = () => {
    updateDialogPath()
  }

  return (
    <ScreenshotsModal
      onClose={handleClose}
      loading={isScreenshotsLoading || isGamesLoading || !game || !screenshots?.length || !currentMarketIso}
      screenshots={(sortedScreenshotByIds as Screenshot[]) || []}
      shotId={shotId}
      game={games[0]}
      games={games}
      versionInfo={versionInfo}
      renderExtra={({ features }) => <RelatedFeatureList features={features} locked={hasNewsFeedLimitedAccess} />}
    />
  )
}

export default ScreenshotsModalContainer

export const createScreenshotModalPath = ({ screenshots, shot }: { screenshots: string[]; shot?: string | null }) => {
  return generatePath('/screenshots/:screenshots/:shot?', {
    screenshots: screenshots.join(','),
    shot: shot || null,
  })
}
