// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '.'
import type { NewsEntry, NewsEntryType } from '../features/news'
import { NewsListPagination } from '../features/news/types/NewsListPagination'
import { UnifiedNewsEntriesNewsData, UnifiedNewsEntry } from '../features/news/types/UnifiedNewsEntry'
import languageService from '../services/LanguageService'

const REDUCER_PATH = 'feedApi'

export const feedApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_FEED,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ['Slack'],
  endpoints: (builder) => ({
    getNewsEntriesForGameIdsAndVersions: builder.query<NewsEntryByGameIdAndVersion, GetNewsEntriesForGameIdsAndVersionsQueryParams>({
      query: ({ enableCache, ...body }) => ({
        url: '/entries/versions',
        method: 'POST',
        params: { enableCache, language: 'en' },
        body: body,
      }),
      transformResponse: (response: { [gameId: string]: { [version: string]: NewsEntry } }) => localizeEntries(response),
    }),
    getNewsEntriesForGame: builder.query<NewsEntriesForGameResponse, GetNewsEntriesForGameQueryParams>({
      query: ({ marketIso, gameId, ...queryParams }) => ({
        url: `/entries/${marketIso}/games/${gameId}`,
        params: queryParams,
      }),
      transformResponse: (response: NewsEntriesForGameResponse) => ({
        ...response,
        entries: response.entries.map(localizeEntry),
      }),
    }),
    getNewsEntriesForMultipleGames: builder.query<NewsEntriesForGameResponse, GetNewsEntriesForMultipleGamesQueryParams>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(
          args.games.map(async ({ gameId, marketIso }) =>
            fetchWithBQ({ url: `/entries/${marketIso}/games/${gameId}`, params: { ts: args.ts, types: args.types } })
          )
        )

        const newsEntries = responses
          .map((res) => res.data as NewsEntriesForGameResponse)
          .reduce(
            (result, current) => {
              result.total += current.total
              result.count += current.count
              result.entries = result.entries.concat(current.entries)
              return result
            },
            { total: 0, count: 0, entries: [] }
          )

        const errorResponse = responses.find((r) => r.error)
        const clonedNewsEntries = { ...newsEntries }
        clonedNewsEntries.entries = clonedNewsEntries.entries.map(localizeEntry).sort((a, b) => a.publishedAt - b.publishedAt)

        return errorResponse?.error ? { error: errorResponse.error } : { data: clonedNewsEntries }
      },
    }),
    getUnifiedNewsEntries: builder.query<
      UnifiedNewsEntriesNewsData,
      { marketIso: string; language: string; conventionalSubgenres: string[]; types: string[]; limit: number; pagination: NewsListPagination }
    >({
      query: ({ marketIso, language, conventionalSubgenres, types, limit, pagination }) => ({
        url: `entries/unified/${marketIso}/v2`,
        params: {
          language,
          limit,
          displayingStickyEditorials: pagination.stickyEditorials,
          displayingOtherNewscards: pagination.other,
          types: types && types.length > 0 ? types.join(',') : undefined,
          conventionalSubgenres: conventionalSubgenres && conventionalSubgenres.length > 0 ? conventionalSubgenres.join(',') : undefined,
        },
      }),
    }),
    getSingleUnifiedNewsEntry: builder.query<UnifiedNewsEntry, { id: string; language: string }>({
      query: ({ id, language }) => `entries/unified/single/${id}/?language=${language}`,
      transformResponse: (response: UnifiedNewsEntry[]) => response[0],
    }),
    getSlackAuthorizationToken: builder.mutation<string, void>({
      query: () => `/webhooks/token`,
      transformResponse: (response: { token: string }) => {
        return response.token
      },
    }),
    getSlackbotHooks: builder.query<SlackbotList[], void>({
      query: () => `/webhooks/list`,
    }),
    saveSlackbotHookSettings: builder.mutation<SlackPreferenceResponse, SlackPreference>({
      query: (body) => ({
        url: `/webhooks/filter`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Slack'],
    }),
    getSlackDetails: builder.query<SlackPreferenceResponse, { teamId: string; channel: string }>({
      query: ({ teamId, channel }) => `/webhooks/filter/${teamId}/${channel}`,
      providesTags: ['Slack'],
    }),
    getUnseenNewsCount: builder.query<Number, GetUnseenNewsCountParams>({
      query: ({ timestamp, marketIso, types }) => ({
        url: `/entries/new/${marketIso}/${timestamp}/?types=${types.join(',')}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetNewsEntriesForGameIdsAndVersionsQuery,
  useGetNewsEntriesForGameQuery,
  useGetUnifiedNewsEntriesQuery,
  useGetSingleUnifiedNewsEntryQuery,
  useGetNewsEntriesForMultipleGamesQuery,
  useGetSlackAuthorizationTokenMutation,
  useGetSlackbotHooksQuery,
  useSaveSlackbotHookSettingsMutation,
  useGetSlackDetailsQuery,
  useGetUnseenNewsCountQuery,
} = feedApi

type GetNewsEntriesForGameIdsAndVersionsQueryParams = {
  marketIso: string
  fields?: string[]
  query?: {
    [gameId: string]: string[]
  }
  enableCache?: boolean
}

type NewsEntryByGameIdAndVersion = {
  [gameId: string]: { [version: string]: NewsEntry }
}

type GetNewsEntriesForGameQueryParams = {
  marketIso: string
  gameId?: string
  ts: number
  types: NewsEntryType[]
}

type GetNewsEntriesForMultipleGamesQueryParams = {
  games: { gameId: string; marketIso: string }[]
  ts: number
  types: NewsEntryType[]
}

export type NewsEntriesForGameResponse = {
  count: number
  total: number
  entries: NewsEntry[]
}

export type SlackPreference = {
  teamId: string
  channel: string
  subgenres?: string[]
  market: string
  filterType?: string
  eventTypes: string[]
  gameIds: string[]
  artists: string[]
}

export type SlackPreferenceResponse = SlackPreference & {
  id: string
  organizationId: string
  createdAt?: number
  createdBy?: string
  updatedAt?: number
  updatedBy?: string
}

export type GetUnseenNewsCountParams = {
  timestamp: number
  marketIso: string
  types: string[]
}

export interface SlackbotList {
  teamName: string
  teamId: string
  channel: string
}

const localizeEntries = (entries: NewsEntryByGameIdAndVersion): NewsEntryByGameIdAndVersion => {
  let localizedEntries: NewsEntryByGameIdAndVersion = {}
  Object.entries(entries).forEach(([gameId, versions]) => {
    localizedEntries[gameId] = versions
    Object.entries(versions).forEach(([versionKey, newsEntry]) => {
      localizedEntries[gameId][versionKey] = localizeEntry(newsEntry)
    })
  })

  return localizedEntries as NewsEntryByGameIdAndVersion
}

const localizeEntry = (newsEntry: NewsEntry): NewsEntry => {
  return {
    ...newsEntry,
    entryData: {
      ...newsEntry.entryData,
      features: newsEntry.entryData.features?.map((feature) => ({
        ...feature,
        featureName_translation: languageService.getTranslation('features', feature.featureLegacyId.toString()),
        choice1_translation: languageService.getTranslation('choices', feature.choice1LegacyId + ''),
        choice2_translation: languageService.getTranslation('choices', feature.choice2LegacyId + ''),
      })),
      screenshots: newsEntry.entryData.screenshots?.map((screenshot) => {
        const screenshotFeatures = screenshot.features || ({} as { [featureLegacyId: string]: number }[])
        return {
          ...screenshot,
          features: Object.entries(screenshotFeatures).map(([featureId, choiceId]) => {
            return {
              featureLegacyId: parseInt(featureId, 10),
              featureName: languageService.getTranslation('features', featureId),
              featureName_translation: languageService.getTranslation('features', featureId),
              choiceLegacyId: choiceId,
              choiceName: languageService.getTranslation('choices', choiceId + ''),
              choiceName_translation: languageService.getTranslation('choices', choiceId + ''),
            }
          }),
        }
      }),
    },
  }
}
