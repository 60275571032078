import { FC, ReactNode, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { ThemeProvider } from '@emotion/react'

import { useInternalAccessCheck } from '../../../features/account/hooks/roleHooks'
import useIntercomBootStatus from '../../../hooks/useIntercomBooted'
import { internalTheme } from '../../theme/theme-internal'

type InternalRouteProps = {
  children?: ReactNode
}

export const InternalRoute: FC<InternalRouteProps> = ({ children }) => {
  const navigate = useNavigate()
  const hasInternalAccess = useInternalAccessCheck()
  const intercomBooted = useIntercomBootStatus()
  const { update } = useIntercom()

  useEffect(() => {
    if (!hasInternalAccess) {
      // No access to internal tools, redirect to public app index page
      navigate('/')
    }
  }, [hasInternalAccess, navigate])

  useEffect(() => {
    if (intercomBooted) {
      update({ hideDefaultLauncher: true })

      return () => {
        update({ hideDefaultLauncher: false })
      }
    }
  }, [intercomBooted, update])

  return (
    <ThemeProvider theme={internalTheme}>
      <Outlet />
    </ThemeProvider>
  )
}
