import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Error, Info, Settings } from '@mui/icons-material'
import { Button, Card, CircularProgress, Divider, Grid, Stack, TableContainer, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { Game } from '../..'
import { useGetCategoriesMapQuery, useGetExplorerFeaturesForConventionalSubgenresQuery } from '../../../../api/core'
import FeatureTagSelector, { FilterTag } from '../../../../components/FeatureTagSelector/FeatureTagSelector'
import { GRBanner } from '../../../../components/GRBanner/GRBanner'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import GameNotAnalyzedCard from '../../../../components/GameNotAnalyzedCard/GameNotAnalyzedCard'
import IAPRevenueImpact from '../../../../components/IAPRevenueImpact/IAPRevenueImpact'
import { LinearProgressBar } from '../../../../components/LinearProgressBar/LinearProgressBar'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import featureService from '../../../../features/feature/services/FeatureService'
import analyticsService from '../../../../services/AnalyticsService'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { Analysis } from '../../../analysis/types/Analysis'
import { FeatureLabelLink } from '../../../feature/components/FeatureLink'
import { useFeatureTags } from '../../../feature/hooks/useFeatureTags'
import { TopFeature } from '../../../feature/types/top-feature'
import { CategoryImage } from '../../../game-features/components/CategoryImage/CategoryImage'
import { useCurrentMarket } from '../../../markets'
import { useIsGameOpenForEveryone } from '../../../settings'
import { useConventionalSubgenreIdsBasedOnGenre, useGameFollow } from '../../hooks/gameHooks'
import GameEditModal from '../GameEditModal/GameEditModal'
import { UnlockGame } from '../UnlockGame/UnlockGame'
import styles from './GameFeatureIdeas.module.scss'

export interface GameFeatureIdeasProps {
  game: Game
  analysis?: Analysis
  isGameAnalyzed?: boolean
}

interface FeatureIdeaEffectMap {
  [featureAndChoiceLegacyId: string]: number
}

const GameFeatureIdeas: React.FC<GameFeatureIdeasProps> = ({ game, analysis, isGameAnalyzed }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Feature Ideas', {
      data: {
        gameId: game.id,
        gameName: game.resolvedName,
        analysisId: analysis?.id,
      },
    })
  }, [analysis?.id, game.id, game.resolvedName])

  const { t } = useTranslation()
  const containerRef = useRef(null)
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { isFollowed: isGameFollowed } = useGameFollow(game)
  const allGamesUnlocked = useRoleCheck(RoleEnum.all_games_unlocked)
  const isGameOpenForEveryone = useIsGameOpenForEveryone(game)
  const featureUnlocked = useRoleCheck(RoleEnum.feature_ideas_unlimited)
  const displayUnlockGameCard = !(allGamesUnlocked || isGameFollowed || isGameOpenForEveryone) && featureUnlocked
  const displayFeatureIdeasData = featureUnlocked || isGameOpenForEveryone
  const gameHasSubgenre = game.conventionalSubgenreId !== null
  const conventionalSubgenreIdsBasedOnGameGenre = useConventionalSubgenreIdsBasedOnGenre({
    conventionalCategoryId: game.conventionalCategoryId,
    conventionalGenreId: game.conventionalGenreId,
  })
  const [editGame, setEditGame] = useState<Game | undefined>()

  const { data: featureIdeas } = useGetExplorerFeaturesForConventionalSubgenresQuery(
    { marketIso: mainMarketIso, conventionalSubgenreIds: conventionalSubgenreIdsBasedOnGameGenre, selectedGameId: game.id },
    { skip: !conventionalSubgenreIdsBasedOnGameGenre }
  )

  const { data: featureCategoriesMap } = useGetCategoriesMapQuery({ marketIso: mainMarketIso })

  const { possibleFeatureIdeas, featureIdeaEffectMap } = useMemo(() => {
    if (!featureIdeas || !analysis) {
      return { possibleFeatureIdeas: undefined, featureIdeaEffectMap: undefined }
    }

    const featureIdeaEffectMap: FeatureIdeaEffectMap = {}
    const analysisTopFeatures = analysis.topFeatures['']

    const possibleFeatureIdeas = featureIdeas.filter((featureIdea) => {
      const featureIndexInAnalysis = analysisTopFeatures.findIndex((topFeature) => {
        return topFeature.featureLegacyId === featureIdea.featureLegacyId
      })

      const featureWithChoiceIndexInAnalysis = analysisTopFeatures.findIndex((topFeature) => {
        return topFeature.featureLegacyId === featureIdea.featureLegacyId && topFeature.choiceLegacyId === featureIdea.choiceLegacyId
      })

      if (featureWithChoiceIndexInAnalysis !== -1 || featureIdea.featureLegacyId === 158 /* User Content Feature */) {
        return false
      }

      let hasTag = false

      featureIdea.tags.forEach((tag) => {
        if (featureService.defaultTagsSelectionForFeatureIdeas.includes(tag)) {
          hasTag = true
        }
      })

      if (hasTag) {
        const actualEffect =
          featureIndexInAnalysis !== -1 ? featureIdea.avgEffect - analysisTopFeatures[featureIndexInAnalysis].gpsEffect : featureIdea.avgEffect
        const differentiatingFeature = featureService.getTopFeatureIsDifferentiating(featureIdea)

        if (actualEffect > 0 && (actualEffect > 0.6 || differentiatingFeature)) {
          featureIdeaEffectMap[`${featureIdea.featureLegacyId}-${featureIdea.choiceLegacyId}`] = actualEffect
        } else {
          return false
        }
      }

      return hasTag
    })

    return {
      possibleFeatureIdeas,
      featureIdeaEffectMap,
    }
  }, [featureIdeas, analysis])

  const { data: tagGroups } = useFeatureTags()
  const [featureTags, setFeatureTags] = useState<FilterTag[]>([])
  useEffect(() => {
    const legacyIds: number[] = possibleFeatureIdeas?.map((featureIdea) => featureIdea.featureLegacyId) || []
    if (possibleFeatureIdeas) {
      setFeatureTags(
        tagGroups
          ?.map((group) => group.tags)
          .flat()
          .filter((tag) => !!tag.targets.filter((target) => legacyIds.includes(target)).length)
          .map((tag) => ({ ...tag, selected: false })) ?? []
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [possibleFeatureIdeas, tagGroups?.length])

  const onTagsClearSelection: MouseEventHandler = () => {
    setFeatureTags(featureTags.map((tag) => ({ ...tag, selected: false })))
  }

  const onToggleTag = (toggledTag: FilterTag) => {
    const newTags = [...featureTags]
    const existingTag = newTags.find((tag) => tag === toggledTag)
    if (existingTag) {
      existingTag.selected = !existingTag.selected
    }

    setFeatureTags(newTags)
  }

  const filteredFeatureIdeas = useMemo(() => {
    if (possibleFeatureIdeas && featureTags) {
      const enabledLegacyIds = featureTags
        .filter(({ selected }) => selected)
        .map(({ targets }) => targets)
        .flat()

      if (enabledLegacyIds.length === 0) {
        return possibleFeatureIdeas
      }

      return possibleFeatureIdeas.filter((featureIdea) => enabledLegacyIds.includes(featureIdea.featureLegacyId))
    }

    return []
  }, [possibleFeatureIdeas, featureTags])

  const activeFeatureTagsInGame = useMemo(() => {
    const analysisTopFeatures = analysis?.topFeatures['']

    if (!analysisTopFeatures) return []

    const trimmedFeatureTags = analysisTopFeatures
      .map((feature) => ({ ...feature, originalChoiceLabel: feature.choiceLabel }))
      .map((feature) => ({
        ...feature,
        originalChoiceLabel: feature.originalChoiceLabel ? feature.originalChoiceLabel.replace(/\s+$/, '') : null,
      }))
      .filter((feature) => !['No', 'na', '0'].includes(feature.originalChoiceLabel || ''))
      .filter((feature) => ![70, 103, 277].includes(feature.featureLegacyId))
      .map((feature) => feature.tags)
      .flat()

    return [...new Set(trimmedFeatureTags)]
  }, [analysis?.topFeatures])

  const existInTagGroup = (tags: string[]) => {
    if (!tags.length) return false
    return !!tags.find((tag) => activeFeatureTagsInGame.includes(tag))
  }

  const customProps = useMemo(() => {
    return {
      featureIdeaEffectMap,
      featureCategoriesMap,
    }
  }, [featureIdeaEffectMap, featureCategoriesMap])

  const tableColumns: GRTableColumn<TopFeature, typeof customProps>[] = [
    {
      columns: [
        {
          labelAccessor: t('common:feature'),
          accessor: ({ row: feature, customTableProps }) => (
            <Grid container alignItems="center" gap={2}>
              {customTableProps && customTableProps.featureCategoriesMap && (
                <Grid item ml={1}>
                  <GRTooltip
                    content={
                      <>
                        <Typography variant="body1">{customTableProps.featureCategoriesMap[feature.categoryId]?.name}</Typography>
                      </>
                    }
                  >
                    <CategoryImage
                      src={customTableProps.featureCategoriesMap[feature.categoryId]?.icon?.url || ''}
                      alt={customTableProps.featureCategoriesMap[feature.categoryId]?.name || ''}
                    />
                  </GRTooltip>
                </Grid>
              )}

              <Grid item xs>
                <FeatureLabelLink
                  feature={{ ...feature, featureName: feature.featureLabel }}
                  choice={{ ...feature, name: feature.choiceLabel, originalChoiceName: feature.originalChoiceLabel }}
                  topFeature={feature}
                />
              </Grid>

              <Grid item sx={{ minWidth: '20px', textAlign: 'right' }}>
                {!existInTagGroup(feature.tags) && (
                  <GRTooltip content={t('overview:doesnt_have_other_features')}>
                    <Error fontSize="small" color="error" />
                  </GRTooltip>
                )}
              </Grid>
            </Grid>
          ),
          sortable: true,
          sortAccessor: ({ row: feature }) => `${feature.featureLabel}: ${feature.choiceLabel}`,
          cellProps: { align: 'left' },
          headerCellProps: { align: 'left', sx: { minWidth: 200 } },
          defaultSortOrder: SortOrder.ASC,
        },
      ],
    },
    {
      columns: [
        {
          labelAccessor: () => <GRTooltip content={t('overview:tooltip_feature_effect_games_in_genre')}>{t('feature:estimated_revenue_impact')}</GRTooltip>,
          accessor: ({ row: feature, customTableProps }) =>
            customTableProps && customTableProps.featureIdeaEffectMap ? (
              <IAPRevenueImpact dotValue={customTableProps.featureIdeaEffectMap[`${feature.featureLegacyId}-${feature.choiceLegacyId}`]} />
            ) : (
              ''
            ),
          sortable: true,
          sortAccessor: ({ row: feature, customTableProps }) =>
            customTableProps && customTableProps.featureIdeaEffectMap
              ? customTableProps.featureIdeaEffectMap[`${feature.featureLegacyId}-${feature.choiceLegacyId}`]
              : 0,
          cellProps: { align: 'center' },
          sortOrder: SortOrder.DESC,
        },
      ],
    },
    {
      columns: [
        {
          labelAccessor: () => <GRTooltip content={t('overview:feature_popularity_in_genre_games')}>{t('common:overall_popularity')}</GRTooltip>,
          accessor: ({ row: feature }) => (
            <LinearProgressBar
              variant="determinate"
              color="success"
              value={feature.popularity.Overall}
              labelAfter={Math.round(feature.popularity.Overall) + '%'}
            />
          ),
          sortable: true,
          sortAccessor: ({ row: feature }) => feature.popularity.Overall,
          cellProps: { align: 'center' },
        },
      ],
    },
    {
      columns: [
        {
          labelAccessor: () => (
            <GRTooltip content={t('overview:tooltip_top_20_percent_genre_games_with_feature')}>{t('common:top_20_popularity_percent')}</GRTooltip>
          ),
          accessor: ({ row: feature }) => (
            <LinearProgressBar
              variant="determinate"
              color="success"
              value={feature.popularity['20Percent']}
              labelAfter={Math.round(feature.popularity['20Percent']) + '%'}
            />
          ),
          sortable: true,
          sortAccessor: ({ row: feature }) => feature.popularity['20Percent'],
          cellProps: { align: 'center' },
        },
      ],
    },
  ]

  const [columns, setColumns] = useState<GRTableColumn<TopFeature, typeof customProps>[]>(tableColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<TopFeature, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  const onGameEditDone = (game: Game) => {
    analyticsService.trackEvent('Modified game', { data: { gameName: game.resolvedName, gameId: game.id } })
    setEditGame(undefined)
  }

  return (
    <div className="GameFeatureIdeas">
      {!!editGame && <GameEditModal onGameEditDone={onGameEditDone} game={editGame} open={!!editGame} onClose={() => setEditGame(undefined)} />}

      {!isGameAnalyzed && <GameNotAnalyzedCard game={game} />}

      {!gameHasSubgenre && (
        <Box display="flex" justifyContent="center">
          <Box width="60vh">
            <GRBanner severity="guidance" icon={<Info />}>
              <Stack>
                <Box>
                  <Trans i18nKey="feature:feature_ideas_not_available_missing_conventional_subgenre_text" />
                </Box>

                {!game.isPublic() && (
                  <Box>
                    <Trans i18nKey="feature:feature_ideas_not_available_missing_conventional_subgenre_text_own_game" />
                  </Box>
                )}
              </Stack>
            </GRBanner>

            {!game.isPublic() && (
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Button sx={{ marginTop: '10px' }} color="primary" variant="contained" onClick={() => setEditGame(game)} startIcon={<Settings />}>
                  {t('game-edit:game_settings')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {isGameAnalyzed && gameHasSubgenre && (
        <>
          {displayUnlockGameCard && <UnlockGame.Card game={game} />}

          {!displayUnlockGameCard && (
            <>
              {!displayFeatureIdeasData && <LockedFeature.Card lockedFeatureId={LockedFeatureId.GameFeatureIdeas} />}

              {isGameAnalyzed && displayFeatureIdeasData && (
                <>
                  {t('overview:game_feature_ideas_description')}

                  <Box my={2}>
                    <Divider />
                  </Box>

                  {(!possibleFeatureIdeas || !featureCategoriesMap) && (
                    <div className="text-center top-margin">
                      <CircularProgress color="primary" />
                    </div>
                  )}

                  {possibleFeatureIdeas && featureCategoriesMap && featureIdeaEffectMap && (
                    <>
                      <Box mb={2}>
                        <FeatureTagSelector tags={featureTags} onToggleTag={onToggleTag} onClearSelection={onTagsClearSelection} />
                      </Box>

                      <TableContainer component={Card} ref={containerRef} className={styles.container}>
                        <GRTable
                          rows={filteredFeatureIdeas}
                          columns={columns}
                          striped
                          hoverable
                          onColumnsUpdated={handleColumnsUpdate}
                          rowIdKey={(row) => {
                            return `${row.featureLegacyId}-${row.choiceLegacyId}`
                          }}
                          noRowsLabel={t('common:no_features_found')}
                          scroller={containerRef}
                          customProps={customProps}
                        />
                      </TableContainer>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default GameFeatureIdeas
