import { useGetUserProfileQuery } from '../../../api/combined'

/**
 * Tells how many games can be in a slot
 */

export const useMaxGameSlotsCount = () => {
  const { data: currentUser } = useGetUserProfileQuery()
  return currentUser?.organization.maxGameSlots || 0
}
