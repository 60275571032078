import { useRef, useState, useEffect } from 'react'

/**
 * Hook for observing an element's sticky state.
 */
export const useSticky = <Target extends HTMLElement>(intersectionObserverOptions: IntersectionObserverInit) => {
  const ref = useRef<Target>(null)

  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const observer = new IntersectionObserver(([event]) => {
      setIsSticky(event.intersectionRatio < 1)
    }, intersectionObserverOptions)
    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [intersectionObserverOptions])

  return { ref, isSticky }
}
