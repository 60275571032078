import { t } from 'i18next'

import revenueService from '../../../services/RevenueService'
import utilsService from '../../../services/UtilsService'
import { Game } from '../../game/types/Game'

export const getAverageRevenueLabel = (game: Game, marketIso: string) => {
  const powerscore = game.gpsPerMarket[marketIso]
  if (!powerscore) return

  const revenueData = revenueService.getLabelByPowerScore(powerscore)
  if (!revenueData) {
    return t('overview:revenue_not_available')
  }

  return t('overview:label_avg_per_day', { revenue: utilsService.formatCurrency(revenueData.Average) })
}
