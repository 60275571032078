import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'

import { ComparisonInterval } from '../../types/ComparisonInterval'

/**
 * Component for rendering a selector for comparison interval (7, 14, 30 days)
 */
type ComparisonIntervalSelectorProps = {
  onChange: (value: string) => void
  value: string
}

export const ComparisonIntervalSelector: FC<ComparisonIntervalSelectorProps> = ({ onChange, value }) => {
  const { t } = useTranslation()
  const handleValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    },
    [onChange]
  )
  return (
    <FormControl component="fieldset" sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
      <FormLabel component="div">{t('common:show')}</FormLabel>
      <RadioGroup row value={value} onChange={handleValueChanged} defaultValue="6" aria-label={t('common:show')} name="comparison-interval-radio-buttons-group">
        <FormControlLabel value={ComparisonInterval.DAYS_7} control={<Radio />} label={t<string>('common:7_days')} />
        <FormControlLabel value={ComparisonInterval.DAYS_14} control={<Radio />} label={t<string>('common:14_days')} />
        <FormControlLabel value={ComparisonInterval.DAYS_30} control={<Radio />} label={t<string>('common:30_days')} />
      </RadioGroup>
    </FormControl>
  )
}
