import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ArticleOutlined, ContentCopy, Info, NotInterested } from '@mui/icons-material'
import {
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
} from '@mui/material'

import { useGetUserProfileQuery, useGetUserSettingsQuery, useUpdateUserSettingMutation } from '../../api/combined'
import { useGetRevenueAndDownloadAggregatesQuery } from '../../api/core'
import AddArea from '../../components/AddArea/AddArea'
import { GRTooltip } from '../../components/GRTooltip/GRTooltip'
import { LimitedFunctionalityBanner } from '../../components/LimitedFunctionalityBanner/LimitedFunctionalityBanner'
import { useRoleCheck } from '../../features/account/hooks/roleHooks'
import { useAvailableFollowedGamesCountLeftThisMonth, useMaxFollowedGamesCount, useMonthlyFollowedGamesStatus } from '../../features/account/hooks/userHooks'
import { FollowedGamesMode } from '../../features/account/types/FollowedGamesMode'
import { RoleEnum } from '../../features/account/types/RoleEnum'
import { UserSettingKeys } from '../../features/account/types/UserSettings'
import { GameLink } from '../../features/game'
import GameSearchDialog from '../../features/game-search/components/GameSearchDialog/GameSearchDialog'
import { GameSearchDialogTab, GameSearchDialogType } from '../../features/game-search/types/GameSearchDialogConfig'
import GameCard from '../../features/game/components/GameCard/GameCard'
import GameCloneDialog from '../../features/game/components/GameCloneDialog/GameCloneDialog'
import GameDashboardCard from '../../features/game/components/GameDashboardCard/GameDashboardCard'
import {
  useAvailabelGameSlotsCount,
  useFollowedGames,
  useFollowedGamesMode,
  useFollowedGamesPageGameIds,
  useGameFollow,
  useGames,
} from '../../features/game/hooks/gameHooks'
import { Game } from '../../features/game/types/Game'
import { useCurrentMarket } from '../../features/markets'
import { displaySnackBar } from '../../features/snackbar/actions/displaySnackBar'
import { SnackBarMessage } from '../../features/snackbar/types/SnackBarMessage'
import { useAppDispatch } from '../../hooks/storeHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import PageService from '../../services/PageService'
import { LockedFeatureId } from '../../types/LockedFeature'
import { INCLUDE } from '../DailyInsightsPage/DailyInsightsPage'
import './FollowedGamesPage.scss'

interface UnfollowButtonActionProps {
  game: Game
  followedGamesUnlocked?: boolean
}
const UnfollowButtonAction: React.FC<UnfollowButtonActionProps> = ({ game, followedGamesUnlocked }) => {
  const { toggleFollow: toggleGameFollow } = useGameFollow(game)
  const { canUserUnfollow } = useMonthlyFollowedGamesStatus()
  const { t } = useTranslation()

  return (
    <GRTooltip content={!followedGamesUnlocked ? t('services:unfollowing_not_available_text') : ''}>
      <MenuItem
        disabled={!canUserUnfollow}
        onClick={() => {
          toggleGameFollow()
        }}
      >
        <ListItemIcon>
          <NotInterested />
        </ListItemIcon>
        <ListItemText> {t('common:unfollow_game')}</ListItemText>
      </MenuItem>
    </GRTooltip>
  )
}

const FollowedGamesPage: React.FC = () => {
  const { t } = useTranslation()

  usePage(PageService.getPageWithId('followed-games'), 'Visited Followed Games')
  useDocumentTitle(t('sidebar:followed_games'))

  const { data: currentUser } = useGetUserProfileQuery()
  const { data: userSettings } = useGetUserSettingsQuery()
  const [updateUserSetting] = useUpdateUserSettingMutation()
  const followedGamesMode = useFollowedGamesMode()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const unlimitedFollowedGamesUnlocked = useRoleCheck(RoleEnum.follow_games_unlimited)
  const followedGamesUnlocked = unlimitedFollowedGamesUnlocked || (currentUser?.organization.maxFollowedGames || 0) > 0
  const [isLoading, setIsLoading] = useState(false)
  const [gameClone, setGameClone] = useState<Game | undefined>()
  const { followedGamesIds, isLoading: isLoadingFollowedGamesIds } = useFollowedGamesPageGameIds()
  const { games: followedGames, isLoading: isLoadingFollowedGames } = useGames(followedGamesIds, mainMarketIso)
  const { canUserFollow } = useMonthlyFollowedGamesStatus()
  const maxFollowedGamesCount = useMaxFollowedGamesCount()
  const availableFollowedGamesCountLeftThisMonth = useAvailableFollowedGamesCountLeftThisMonth()
  const followedGamesList = useFollowedGames(mainMarketIso, INCLUDE)
  const dispatch = useAppDispatch()
  const showError = useCallback((errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload)), [dispatch])

  const followedGamesListIds = useMemo(() => {
    return followedGamesList.games.map((game) => {
      return game.id
    })
  }, [followedGamesList])

  const [gameSearchModalOpen, setGameSearchModalOpen] = useState(false)
  const gameSearchDialogConfig = useMemo(() => {
    return {
      type: GameSearchDialogType.GameFollow,
      tabs: [GameSearchDialogTab.Search, GameSearchDialogTab.SoftLaunch],
    }
  }, [])

  const appIds = useMemo(() => {
    return (
      followedGames?.map((game) => {
        return game.appId
      }) || []
    )
  }, [followedGames])

  const marketIsos = useMemo(() => {
    return [mainMarketIso]
  }, [mainMarketIso])

  const { data: revenueAndDownloadByApp, isLoading: isLoadingRevenueAndDownloadByApp } = useGetRevenueAndDownloadAggregatesQuery(
    { appIds, marketIsos },
    { skip: appIds.length === 0 }
  )

  const followedGamesRevenueAndDownloadAggregates = useMemo(() => {
    return followedGames
      ?.map((game) => {
        const appAggregates = revenueAndDownloadByApp && revenueAndDownloadByApp[game.appId]
        const updatedGame = new Game(
          {
            ...game,
            ...{
              revenueAndDownloadAggregatesMap: appAggregates?.countryAggregates,
            },
          } as Game,
          {}
        )

        return updatedGame
      })
      .sort((a, b) => (a.resolvedName < b.resolvedName ? -1 : 1))
  }, [followedGames, revenueAndDownloadByApp])

  const handleFollowedGamesModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMode = (event.target as HTMLInputElement).value as FollowedGamesMode
    setIsLoading(true)

    const games = userSettings?.followedGamesSettings?.games || []

    const newValue = {
      privateMode: newMode === FollowedGamesMode.Private ? true : false,
      games,
    }
    updateUserSetting({ settingKey: UserSettingKeys.followedGamesSettings, value: newValue }).then(() => {
      setIsLoading(false)
    })
  }

  const gameCardClicked = (game: Game) => {
    const element = window.document.getElementById(`game-dashboard-${game.id}`)
    if (element) {
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.pageYOffset - 96

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const getNextResetDateForFollowedGames = () => {
    const date = new Date()
    date.setMonth(date.getMonth() + 1)
    date.setDate(1)

    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(date.getTime())
  }

  const availabelGameSlotsCount = useAvailabelGameSlotsCount() || 0

  return (
    <div className="FollowedGamesPage">
      <div className="FollowedGamesPage__header">
        {!followedGamesUnlocked && (
          <Grid item flexBasis="100%">
            <LimitedFunctionalityBanner lockedFeatureId={LockedFeatureId.FollowedGames} />
          </Grid>
        )}
        {!unlimitedFollowedGamesUnlocked && followedGamesUnlocked && (
          <>
            <h3 style={{ marginBottom: '10px' }}>
              <Trans
                i18nKey="followed-games:current_following_games_plural"
                values={{ count: `${followedGamesIds.length} / ${maxFollowedGamesCount}` }}
              ></Trans>
            </h3>
            <p style={{ marginTop: '0px' }} className="light-text-color">
              {t('followed-games:following_game_gives_you_24h_access')}{' '}
              <Trans
                i18nKey="followed-games:you_can_change_followed_games"
                values={{ count: availableFollowedGamesCountLeftThisMonth, nextreset: getNextResetDateForFollowedGames() }}
              ></Trans>
              .
            </p>
          </>
        )}
        {unlimitedFollowedGamesUnlocked && followedGamesUnlocked && (
          <>
            <h3 style={{ marginBottom: '10px' }}>{t('followed-games:current_following_games_plural', { count: followedGamesIds.length })}</h3>
            <p style={{ marginTop: '0px' }} className="light-text-color">
              {t('followed-games:following_game_gives_you_24h_access')}
            </p>

            <FormControl component="fieldset">
              <RadioGroup aria-label="gender" value={followedGamesMode} onChange={handleFollowedGamesModeChange}>
                <FormControlLabel
                  value="private"
                  className="FollowedGamesPage__header__followed-games-mode"
                  control={<Radio />}
                  label={
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <strong>{t('followed-games:private_follow_games_mode')}</strong>
                        <div className="FollowedGamesPage__header__help-text">{t('followed-games:private_follow_games_mode_help')}</div>
                      </Grid>
                      <Grid item>
                        <GRTooltip content={t('followed-games:private_follow_games_mode_tooltip')}>
                          <Info color="primary" fontSize="small" />
                        </GRTooltip>
                      </Grid>
                    </Grid>
                  }
                />
                <FormControlLabel
                  value="public"
                  className="FollowedGamesPage__header__followed-games-mode"
                  control={<Radio />}
                  label={
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <strong>{t('followed-games:public_follow_games_mode')}</strong>
                        <div className="FollowedGamesPage__header__help-text">{t('followed-games:public_follow_games_mode_help')}</div>
                      </Grid>
                      <Grid item>
                        <GRTooltip content={t('followed-games:public_follow_games_mode_tooltip')}>
                          <Info color="primary" fontSize="small" />
                        </GRTooltip>
                      </Grid>
                    </Grid>
                  }
                />
              </RadioGroup>
            </FormControl>
          </>
        )}

        <Divider className="vertical-margin" />
        {isLoading || isLoadingFollowedGamesIds || isLoadingFollowedGames || isLoadingRevenueAndDownloadByApp ? (
          <div className="text-center top-margin">
            <CircularProgress color="primary" />
          </div>
        ) : (
          <div>
            {!!gameClone && <GameCloneDialog originGame={gameClone} open={!!gameClone} onClose={() => setGameClone(undefined)} />}
            <div className="FollowedGamesPage__quick-links">
              <h4>{t('followed-games:game_quick_links')}</h4>
              <Grid container spacing={2} wrap="wrap">
                {followedGamesRevenueAndDownloadAggregates
                  .sort((a, b) => followedGamesListIds.indexOf(a.id) - followedGamesListIds.indexOf(b.id))
                  .map((game) => {
                    return (
                      <Grid item key={game.resolvedName} xs={12} md={4}>
                        {/* TODO-LATER-FEATURE-?: ADD SUPPORT FOR DRAG AND DROPPING CARDS */}
                        <GameCard game={game} disableGameLink={true} onCardClick={gameCardClicked}>
                          <GameCard.Actions>
                            <MenuList>
                              {!!game.gpsPerMarket[mainMarketIso] && (
                                <GameLink style={{ color: 'inherit' }} game={game}>
                                  <MenuItem>
                                    <ListItemIcon>
                                      <ArticleOutlined />
                                    </ListItemIcon>
                                    <ListItemText> {t('overview:game_overview')}</ListItemText>
                                  </MenuItem>
                                </GameLink>
                              )}
                              <MenuItem
                                onClick={() => {
                                  if (availabelGameSlotsCount > 0) {
                                    setGameClone(game)
                                  } else {
                                    showError({ message: t('game-header:clone_error_body_no_slot'), severity: 'warning', open: true })
                                  }
                                }}
                              >
                                <ListItemIcon>
                                  <ContentCopy fontSize="small" />
                                </ListItemIcon>
                                <ListItemText> {t('game-header:clone_game')}</ListItemText>
                              </MenuItem>
                              <UnfollowButtonAction game={game} followedGamesUnlocked={followedGamesUnlocked} />
                            </MenuList>
                          </GameCard.Actions>
                        </GameCard>
                      </Grid>
                    )
                  })}

                {canUserFollow && (
                  <Grid item xs={12} md={4}>
                    <AddArea
                      label={t('common:add_game')}
                      onClick={() => {
                        setGameSearchModalOpen(true)
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </div>

            {followedGamesRevenueAndDownloadAggregates.length > 0 && (
              <>
                <Divider className="vertical-margin" />

                <div className="FollowedGamesPage__game-dashboards">
                  <h4>{t('followed-games:game_dashboards')}</h4>
                  {followedGamesRevenueAndDownloadAggregates.map((game) => {
                    return <GameDashboardCard key={game.id} game={game} marketIso={mainMarketIso} />
                  })}
                </div>
              </>
            )}
            {gameSearchModalOpen && (
              <GameSearchDialog
                gameSearchDialogConfig={gameSearchDialogConfig}
                modalOpen={gameSearchModalOpen}
                defaultSelectedGames={followedGamesRevenueAndDownloadAggregates}
                cacheGamesOnClose={true}
                onClose={() => {
                  setGameSearchModalOpen(false)
                }}
                enableMarketSelector
                onlyGamesWithPowerscore
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default FollowedGamesPage
