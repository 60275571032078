/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog, DialogContent } from '@mui/material'

import { Game } from '../../features/game'
import useGetOtherSubgenreGames from '../../features/game/hooks/useGetOtherSubgenreGames'
import languageService from '../../services/LanguageService'
import GRDialogTitle from '../GRDialogTitle/GRDialogTitle'
import { GamesTableWith30DRevDlEstimates } from '../GamesTableWith30DRevDlEstimates/GamesTableWith30DRevDlEstimates'

interface OtherGamesWithTagDialogProps {
  modalOpen: boolean
  game: Game
  tagId: string
  marketIso: string
  onClose: () => void
  hasUndefinedSubgenre?: boolean
}

const OtherGamesWithTagDialog: React.FC<OtherGamesWithTagDialogProps> = ({ modalOpen, game, tagId, marketIso, onClose, hasUndefinedSubgenre }) => {
  const { t } = useTranslation()
  const { data: otherGamesWithTag, isFetching: isFetchingOtherGamesWithTag } = useGetOtherSubgenreGames(
    game.conventionalSubgenreId,
    marketIso,
    !modalOpen,
    tagId
  )

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <Dialog className="OtherSubgenreGamesDialog" onClose={handleClose} fullWidth={true} maxWidth="lg" open={modalOpen}>
      <GRDialogTitle onClose={handleClose}>
        {t('common:tag_games_modal_title', { subgenre: game.conventionalSubgenre, tag: languageService.getTranslation('tags', tagId) })}
      </GRDialogTitle>
      <DialogContent className="DialogContent" dividers>
        <GamesTableWith30DRevDlEstimates
          games={otherGamesWithTag}
          marketIso={marketIso}
          isLoading={isFetchingOtherGamesWithTag}
          hasUndefinedSubgenre={hasUndefinedSubgenre}
        />
      </DialogContent>
    </Dialog>
  )
}

export default OtherGamesWithTagDialog
