import React, { useMemo, useState } from 'react'

import { Card, Chip, Divider } from '@mui/material'
import { Box } from '@mui/system'

import { FileUploadResponse } from '../../../../api/core'
import { GameLink } from '../../../game'
import GameKeyIndicator from '../../../game/components/GameKeyIndicator/GameKeyIndicator'
import { useGetGameIconUrl } from '../../../game/hooks/gameHooks'
import { GameKeyIndicatorType } from '../../../game/types/GameKeyIndicatorType'
import { MarketExplorerGamesChartAxisType, MarketExplorerGamesChartAxisTypeId } from '../../types/MarketExplorerGamesChartAxisType'
import { MarketExplorerGamesChartNode } from '../../types/MarketExplorerGamesChartNode'
import './MarketExplorerGamesChartNodeItem.scss'

interface Props {
  node: MarketExplorerGamesChartNode
  yAxisType: MarketExplorerGamesChartAxisType
  xAxisType: MarketExplorerGamesChartAxisType
}

const MarketExplorerGamesChartNodeItem: React.FC<Props> = ({ node, yAxisType, xAxisType }) => {
  const [hovered, setHovered] = useState(false)

  const nodeItemDataCardPositionClass = useMemo(() => {
    let classAppendix = 'Top'

    if (node.y > 50) {
      classAppendix = 'Bottom'
    }

    if (node.x > 50) {
      classAppendix = `${classAppendix}Left`
    } else {
      classAppendix = `${classAppendix}Right`
    }

    return `MarketExplorerGamesChartNodeItem__Data__Card--${classAppendix}`
  }, [node])

  const gameKeyIndicatorTypes = useMemo(() => {
    const getGameKeyIndicatorTypeForAxisType = (axisType: MarketExplorerGamesChartAxisType) => {
      switch (axisType.id) {
        case MarketExplorerGamesChartAxisTypeId.Powerscore:
        case MarketExplorerGamesChartAxisTypeId.SkillThinking:
          return GameKeyIndicatorType.FeatureIndicators

        case MarketExplorerGamesChartAxisTypeId.SGR:
        case MarketExplorerGamesChartAxisTypeId.SDR:
          return GameKeyIndicatorType.SustainedRanks

        case MarketExplorerGamesChartAxisTypeId.RevenuePastMonth:
        case MarketExplorerGamesChartAxisTypeId.DownloadsPastMonth:
        case MarketExplorerGamesChartAxisTypeId.RevenueDownloadsRatioPastMonth:
          return GameKeyIndicatorType.Performance30Days

        case MarketExplorerGamesChartAxisTypeId.Revenue180Days:
        case MarketExplorerGamesChartAxisTypeId.Downloads180Days:
        case MarketExplorerGamesChartAxisTypeId.RevenueDownloads180DaysRatio:
          return GameKeyIndicatorType.Performance180Days

        case MarketExplorerGamesChartAxisTypeId.Revenue360Days:
        case MarketExplorerGamesChartAxisTypeId.Downloads360Days:
        case MarketExplorerGamesChartAxisTypeId.RevenueDownloads360DaysRatio:
          return GameKeyIndicatorType.Performance360Days

        case MarketExplorerGamesChartAxisTypeId.DaysSinceRelease:
          return GameKeyIndicatorType.ReleaseDate

        case MarketExplorerGamesChartAxisTypeId.DemographicsMale:
        case MarketExplorerGamesChartAxisTypeId.DemographicsFemale:
          return GameKeyIndicatorType.DemographicsGender

        case MarketExplorerGamesChartAxisTypeId.DemographicsAge16_24:
        case MarketExplorerGamesChartAxisTypeId.DemographicsAge25_44:
        case MarketExplorerGamesChartAxisTypeId.DemographicsAge45:
          return GameKeyIndicatorType.DemographicsAge

        default:
          return GameKeyIndicatorType.FeatureIndicators
      }
    }

    const gameKeyIndicatorTypeYAxis = getGameKeyIndicatorTypeForAxisType(yAxisType)
    const gameKeyIndicatorTypeXAxis = getGameKeyIndicatorTypeForAxisType(xAxisType)

    return gameKeyIndicatorTypeYAxis === gameKeyIndicatorTypeXAxis ? [gameKeyIndicatorTypeYAxis] : [gameKeyIndicatorTypeYAxis, gameKeyIndicatorTypeXAxis]
  }, [yAxisType, xAxisType])

  const nodeClass = useMemo(() => {
    let nodeClass = 'MarketExplorerGamesChartNodeItem'

    if (!node.showIcon) {
      nodeClass = nodeClass + ` MarketExplorerGamesChartNodeItem--DefaultBG`
    }

    if (node.topGame) {
      nodeClass = nodeClass + ` MarketExplorerGamesChartNodeItemColor MarketExplorerGamesChartNodeItemColor--top`
    } else if (node.showNodeColor) {
      nodeClass = nodeClass + ` MarketExplorerGamesChartNodeItemColor MarketExplorerGamesChartNodeItemColor--${node.segmentId}`
    }

    return nodeClass
  }, [node])

  const gameIconUrl = (): string | FileUploadResponse => {
    const iconUrl = node.game.getIcon(false)
    if (Array.isArray(iconUrl)) {
      return iconUrl[0]
    }

    return iconUrl || ''
  }

  const [icon] = useGetGameIconUrl(gameIconUrl(), !(node.showIcon || hovered))

  return (
    <div
      className={nodeClass}
      style={{
        top: node.y + '%',
        right: node.x + '%',
        backgroundImage: node.showIcon ? `url(${icon})` : 'url(null)',
      }}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {hovered && (
        <div className="MarketExplorerGamesChartNodeItem__Data">
          <GameLink game={node.game}>
            <div
              className={`MarketExplorerGamesChartNodeItem__Data__Icon MarketExplorerGamesChartNodeItemColor--${node.segmentId}`}
              style={{ backgroundImage: `url(${icon})` }}
            ></div>
          </GameLink>

          <Card className={`MarketExplorerGamesChartNodeItem__Data__Card ${nodeItemDataCardPositionClass}`}>
            <div className="MarketExplorerGamesChartNodeItem__Data__Card__Name">
              <GameLink game={node.game}>
                <strong>{node.game.resolvedName}</strong>
              </GameLink>
            </div>
            <div className="MarketExplorerGamesChartNodeItem__Data__Card__Publisher">{node.game.artist}</div>
            {node.game.conventionalSubgenre && (
              <Box mt={1}>
                <Chip className="Chip" size="small" label={node.game.conventionalSubgenre}></Chip>
              </Box>
            )}

            <Box mt={0.5}>
              <Divider />
            </Box>

            {gameKeyIndicatorTypes.map((type, index) => {
              return (
                <Box mt={1} key={`${node.id}-${type}-${index}`}>
                  <GameKeyIndicator type={type} game={node.game} version={node.version} marketIso={node.marketIso} smallSize />
                </Box>
              )
            })}
          </Card>
        </div>
      )}
    </div>
  )
}

export default MarketExplorerGamesChartNodeItem
