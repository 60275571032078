import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import { prepareDefaultHeaders } from '../../../api'
import {
  useFollowGameMutation,
  useGetUserProfileQuery,
  useGetUserSettingsQuery,
  useUnfollowGameMutation,
  useUpdateUserSettingMutation,
} from '../../../api/combined'
import {
  FileUploadResponse,
  useGetGameAndAnalysisQuery,
  useGetGameListQuery,
  useGetGameQuery,
  useGetGamesQuery,
  useGetGameVersionInfoQuery,
  useGetGenreTaxonomyQuery,
  useGetOwnGamesCountQuery,
  useGetSettingQuery,
  useSearchGamesMutation,
} from '../../../api/core'
import { useAppDispatch } from '../../../hooks/storeHooks'
import analyticsService from '../../../services/AnalyticsService'
import { PromiseWithAbort } from '../../../types/PromiseWithAbort'
import { useRoleCheck } from '../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import { useMaxGameSlotsCount } from '../../account/hooks/useMaxGameSlotsCount'
import { FollowedGamesMode } from '../../account/types/FollowedGamesMode'
import { RoleEnum } from '../../account/types/RoleEnum'
import { UserSettingKeys } from '../../account/types/UserSettings'
import { SearchGamesQueryParams } from '../../game-search/types/SearchGamesQueryParams'
import { useCurrentMarket } from '../../markets'
import { displaySnackBar } from '../../snackbar'
import { Game, GameArchiveStatus } from '../types/Game'
import { GameAndAnalysis } from '../types/GameAndAnalysis'

/**
 * Hook for fetching featured games
 */
export function useFeaturedGames(mainMarketIso: string, include?: Array<string>) {
  const { data: settingData, error: settingError, isLoading: isSettingLoading } = useGetSettingQuery('featured_games')
  const featuredGameIds: string[] = settingData?.data.featuredGames || []

  const { games: gamesData, error: gamesError, isLoading: isGamesLoading } = useGames(featuredGameIds, mainMarketIso)

  const games = (gamesData || []).map((game) => {
    const newGame = new Game(game)
    newGame.RIBBON_TYPE = 'featured'
    return newGame
  })

  return {
    games,
    isLoading: isGamesLoading || isSettingLoading,
    error: gamesError || settingError,
  }
}

/**
 *
 * Hook for retrieving ids of followed games
 */
export const useFollowedGameIds = () => {
  const followedGamesMode = useFollowedGamesMode()
  const { data: currentUser } = useGetUserProfileQuery()
  const { data: userSettings } = useGetUserSettingsQuery()

  if (followedGamesMode === FollowedGamesMode.Private) {
    return userSettings?.followedGamesSettings?.games || []
  } else {
    return currentUser?.organization.followedGames || []
  }
}

/**
 * Hook for fetching followed games
 */
export function useFollowedGames(mainMarketIso: string, include: Array<string>) {
  const gameIds = useFollowedGameIds()
  const { games, error, isLoading } = useGames(gameIds, mainMarketIso)

  return {
    games,
    isLoading,
    error,
  }
}

/**
 * Hook for fetching single game and latest completed analysis for it
 */
export function useGameAndAnalysis(gameId: string, marketIso: string) {
  const userLanguage = useCurrentUserLanguage()
  const {
    data: gameAndAnalysis,
    error: gameError,
    isLoading: isGameLoading,
    isFetching: isGameFetching,
    refetch: refetchGameAndAnalysis,
  } = useGetGameAndAnalysisQuery(
    {
      id: gameId,
      marketIso: marketIso,
      tags: true,
      userLanguage,
    },
    {}
  )

  return {
    gameAndAnalysis: gameAndAnalysis,
    error: gameError,
    isLoading: isGameLoading || isGameFetching,
    refetchGameAndAnalysis,
  }
}

export function useGetGameVersionInfo(appId?: number, marketIso?: string, version?: string, skip = false) {
  const { data, error, isLoading } = useGetGameVersionInfoQuery({ appId, marketIso, version }, { skip: !appId || !marketIso || skip })
  return {
    data,
    isLoading,
    error,
  }
}

/**
 * Hook for fetching game(s) with given ids in given market
 */
export const useGames = (gameIds: string[], marketIso?: string, tags = false, gameOnly = false) => {
  const userLanguage = useCurrentUserLanguage()
  const { currentMarketIso } = useCurrentMarket()
  // load one game only if gameIds array contains only one game id
  // you can use the skip parameter to delay / control the order of running the queries
  const {
    data: game,
    error: gameError,
    isLoading: isGameLoading,
    isFetching: isGameFetching,
  } = useGetGameQuery(
    {
      id: gameIds[0],
      marketIso: marketIso || currentMarketIso,
      tags,
      gameOnly,
      userLanguage,
    },
    { skip: gameIds.length !== 1 }
  )

  // load multiple games only if gameIds array contains more than one game id
  const {
    data: games,
    error: gamesError,
    isLoading: isGamesLoading,
    isFetching: isGamesFetching,
  } = useGetGamesQuery(
    {
      ids: gameIds,
      userLanguage,
    },
    { skip: gameIds.length <= 1 }
  )

  const gamesContructor = useMemo(() => {
    let gamesResult: Game[] = []
    if (game && gameIds.length === 1) {
      return [game]
    } else if (games && gameIds.length > 0) {
      gamesResult = games
    }

    return gamesResult
  }, [game, games, gameIds])

  return {
    games: gamesContructor,
    error: gameError || gamesError,
    isLoading: isGameLoading || isGamesLoading || isGameFetching || isGamesFetching,
  }
}

/**
 * Hook for toggling game follow/unfollow and observe status
 */
export const useGameFollow = (game: Game | undefined) => {
  const followedGameIds = useFollowedGameIds()
  const [isFollowed, setIsFollowed] = useState(false)
  const [isToggling, setIsToggling] = useState<boolean>(false)
  const [togglerPromise, setTogglerPromise] = useState<PromiseWithAbort>()
  const [follow] = useFollowGameMutation()
  const [unfollow] = useUnfollowGameMutation()
  const [updateUserSetting] = useUpdateUserSettingMutation()
  const followedGamesMode = useFollowedGamesMode()
  const dispatch = useAppDispatch()

  // set the initial value when game is received
  useEffect(() => {
    if (game) {
      const followed = followedGameIds.includes(game.id)

      setIsFollowed(followed)
    }
  }, [game, followedGameIds])

  // allow graceful teardown if e.g. component gets removed immediately after toggling
  useEffect(() => {
    if (togglerPromise) {
      togglerPromise.finally(() => {
        setIsToggling(false)
      })
    }
  }, [togglerPromise])

  // provide toggle trigger and expose it via hook
  const toggleFollow = useCallback(() => {
    if (game) {
      if (followedGamesMode === FollowedGamesMode.Private) {
        const updatedFollowedGames = isFollowed ? followedGameIds.filter((gameId) => gameId !== game.id) : [...followedGameIds, game.id]
        const followedGamesSetting = {
          games: updatedFollowedGames,
          privateMode: true,
        }
        updateUserSetting({ settingKey: UserSettingKeys.followedGamesSettings, value: followedGamesSetting })
      } else {
        setIsToggling(true)
        const toggler = isFollowed ? unfollow : follow
        const promise = toggler(game)
        setTogglerPromise(promise)
      }

      if (!isFollowed) {
        analyticsService.trackEvent('Followed game', { data: { gameId: game.id, gameName: game.resolvedName } })
      } else {
        analyticsService.trackEvent('Unfollowed game', { data: { gameId: game.id, gameName: game.resolvedName } })
      }

      setIsFollowed(!isFollowed)

      const message = (
        <Trans i18nKey={isFollowed ? 'game-header:notify_unfollowed_game' : 'game-header:notify_followed_game'} values={{ gameName: game.resolvedName }} />
      )
      dispatch(displaySnackBar({ message, severity: 'success', open: true }))
    }
  }, [follow, unfollow, dispatch, game, isFollowed, followedGameIds, updateUserSetting, followedGamesMode])

  return { isToggling, isFollowed, toggleFollow }
}

/**
 * Hook for fetching Followed Games or Game Open for Everybody Ids (Followed Games Page)
 */
export const useFollowedGamesPageGameIds = () => {
  const { data: currentUser } = useGetUserProfileQuery()
  const hasUnlimitedFollowedGames = useRoleCheck(RoleEnum.follow_games_unlimited)
  const followedGamesUnlocked = hasUnlimitedFollowedGames || (currentUser?.organization.maxFollowedGames || 0) > 0

  const { data: featuredGamesSetting, isLoading: isFeaturedGamesLoading } = useGetSettingQuery('featured_games', { skip: followedGamesUnlocked })
  const { data: openGamesSetting, isLoading: isOpenGamesLoading } = useGetSettingQuery('open_games', { skip: followedGamesUnlocked })
  const followedGameIds = useFollowedGameIds()

  const gamesOpenForEverybody = useMemo(() => {
    const featuredGameIds: string[] = featuredGamesSetting?.data.featuredGames || []
    const openGameIds: string[] = openGamesSetting?.data.openGames || []
    return [...featuredGameIds, ...openGameIds]
  }, [featuredGamesSetting, openGamesSetting])

  return {
    followedGamesIds: followedGamesUnlocked ? followedGameIds : gamesOpenForEverybody,
    isLoading: isFeaturedGamesLoading || isOpenGamesLoading,
  }
}

export const useFollowedGamesMode = () => {
  const { data: userSettings } = useGetUserSettingsQuery()
  const hasUnlimitedFollowedGames = useRoleCheck(RoleEnum.follow_games_unlimited)
  const [followedGamesMode, setFollowedGamesMode] = useState<FollowedGamesMode>(FollowedGamesMode.Public)

  useEffect(() => {
    const mode =
      userSettings?.followedGamesSettings && hasUnlimitedFollowedGames && userSettings.followedGamesSettings.privateMode
        ? FollowedGamesMode.Private
        : FollowedGamesMode.Public

    setFollowedGamesMode(mode)
  }, [userSettings, hasUnlimitedFollowedGames])

  return followedGamesMode
}

export const useOwnGameCheck = (game?: Game) => {
  const { data: currentUser } = useGetUserProfileQuery()
  return game && game.owner === currentUser?.organization.id ? true : false
}

export const useMultipleOwnGameCheck = (games?: Game[]) => {
  const { data: currentUser } = useGetUserProfileQuery()

  return games?.map((game) => game.owner === currentUser?.organization.id) || []
}

export const useContainsOwnGamesCheck = (games: Game[]) => {
  const { data: currentUser } = useGetUserProfileQuery()
  return games.reduce((acc, game) => {
    return acc || (game && game.owner === currentUser?.organization.id)
  }, false)
}

export const useGameAnalysisOutdatedForMarketIsoCheck = ({
  marketIso,
  game,
  includeLiveOpsTrackingStatusCheck = true,
}: {
  marketIso: string
  game?: Game
  includeLiveOpsTrackingStatusCheck?: boolean
}) => {
  const { data: currentUser } = useGetUserProfileQuery()
  return game && currentUser ? game.isOutdatedForMarket(marketIso, currentUser, includeLiveOpsTrackingStatusCheck) : false
}

export const useConventionalSubgenreIdsBasedOnGenre = ({
  conventionalCategoryId,
  conventionalGenreId,
}: {
  conventionalCategoryId: string
  conventionalGenreId: string
}) => {
  const userLanguage = useCurrentUserLanguage()
  const { data: genreTaxonomy } = useGetGenreTaxonomyQuery({ userLanguage })
  return useMemo(() => {
    if (genreTaxonomy) {
      const gameCategory = genreTaxonomy.find((category) => {
        return category.id === conventionalCategoryId
      })

      if (gameCategory) {
        const gameGenre = gameCategory.genres.find((genre) => {
          return genre.id === conventionalGenreId
        })

        if (gameGenre) {
          return gameGenre.subgenres.map((subgenre) => {
            return subgenre.id
          })
        }
      }
    }

    return []
  }, [genreTaxonomy, conventionalCategoryId, conventionalGenreId])
}

export const useIsGameUnlockedCheck = (gameAndAnalysis?: GameAndAnalysis) => {
  const allGamesUnlocked = useRoleCheck(RoleEnum.all_games_unlocked)
  const { isFollowed: isGameFollowed } = useGameFollow(gameAndAnalysis?.game)

  return allGamesUnlocked || isGameFollowed || (gameAndAnalysis?.game.unlocked && !!gameAndAnalysis?.analysis)
}

export const useAvailableGameFollows = () => {
  const { data: currentUser } = useGetUserProfileQuery()
  const maxFollowedGames = currentUser?.organization.maxFollowedGames || 0
  const hasUnlimitedFollowedGames = useRoleCheck(RoleEnum.follow_games_unlimited)
  const followedGameIds = useFollowedGameIds()

  return hasUnlimitedFollowedGames ? Infinity : maxFollowedGames - followedGameIds.length
}

const grApiServerCores = [
  'https://apiserver.gamerefinery.com',
  'https://apiserver-grsaas.rhcloud.com',
  'https://apiserver-grt1000.rhcloud.com',
  'https://apiserver-staging.gamerefinery.com',
  'https://apiserver-svc.gamerefinery.com',
  'https://saas-dev.gamerefinery.com/s/apiserver',
  'https://saas-staging.gamerefinery.com/s/apiserver',
  'https://saas.gamerefinery.com/s/apiserver',
  'https://services-eu.gamerefinery.com/s/apiserver',
  'https://services-us.gamerefinery.com/s/apiserver',
  'https://services-apac.gamerefinery.com/s/apiserver',
  'https://saas-eu.gamerefinery.com/s/apiserver',
  'https://saas-apac.gamerefinery.com/s/apiserver',
  'https://saas-us.gamerefinery.com/s/apiserver',
]

export const useGetGameIconUrl = (icon?: string | null | FileUploadResponse, skip: boolean = false): [string | null] => {
  const [preview, setPreview] = useState<string | null>(null)

  const authorizeImage = useCallback(async (url: string, signal: AbortSignal) => {
    try {
      const defaultHeader = await prepareDefaultHeaders()
      const imgBlob = await fetch(url, { headers: defaultHeader, signal }).then((resp) => resp.blob())

      return imgBlob
    } catch (err) {
      setPreview(null)
    }
  }, [])

  const fetchImage = useCallback(async (url: string, signal: AbortSignal) => {
    try {
      const imgBlob = await fetch(url, { signal }).then((resp) => resp.blob())

      return imgBlob
    } catch (err) {
      return null
    }
  }, [])

  const getURLGRApiServerCore = (url: string) => {
    for (let i = 0; i < grApiServerCores.length; i++) {
      if (url.indexOf(grApiServerCores[i]) !== -1) {
        return grApiServerCores[i]
      }
    }

    return null
  }

  const transformUrl = useCallback((url: string) => {
    const urlCore = getURLGRApiServerCore(url)
    if (!urlCore) return

    const transformedUrl = url.slice()
    const coreApiUrl = window.GR_API_URLS.API_URL_CORE
    transformedUrl.replace(urlCore, coreApiUrl)
    transformedUrl.replace('/v2/', '/')
    transformedUrl.replace('/v1/', '/')
    return transformedUrl
  }, [])

  useEffect(() => {
    if (skip) return () => null

    let objectUrl: string
    let mounted = true
    const controller = new AbortController()

    const selectImage = async () => {
      try {
        if (!icon) return
        if (!mounted) return

        const url = typeof icon === 'string' ? icon : icon.url
        const transformedUrl = transformUrl(url)
        const imgBlob = transformedUrl ? await authorizeImage(transformedUrl, controller.signal) : await fetchImage(url, controller.signal)
        if (!!imgBlob) {
          objectUrl = URL.createObjectURL(imgBlob)
          setPreview(objectUrl)
        }
      } catch (err) {
        setPreview(null)
      }
    }

    selectImage()
    return () => {
      mounted = false
      URL.revokeObjectURL(objectUrl)
      controller.abort()
    }
  }, [authorizeImage, fetchImage, icon, transformUrl, skip])

  if (!icon) {
    return [null]
  }

  return [preview]
}

/**
 * Hook for fetching own games count
 * @returns own games count or undefined if not loaded
 */
export const useOwnGamesCount = () => {
  const { data: ownGamesCount } = useGetOwnGamesCountQuery()
  return ownGamesCount !== undefined ? ownGamesCount : undefined
}

export const useAvailabelGameSlotsCount = () => {
  const ownGamesCount = useOwnGamesCount()
  const maxGameSlotCount = useMaxGameSlotsCount()
  const hasUnlimitedGameSlot = useRoleCheck(RoleEnum.game_slots_unlimited)

  const availableGameSlotCount = useMemo(() => {
    if (hasUnlimitedGameSlot) {
      return 1
    }

    return ownGamesCount !== undefined ? maxGameSlotCount - ownGamesCount : undefined
  }, [maxGameSlotCount, ownGamesCount, hasUnlimitedGameSlot])

  return availableGameSlotCount
}

type GetGameListHookParams = {
  gameIds: (string | number)[]
  include?: string
}

export const useGetGameList = ({ gameIds, include }: GetGameListHookParams, options?: { skip?: boolean }) => {
  const userLanguage = useCurrentUserLanguage()

  return useGetGameListQuery(
    {
      gameIds,
      include,
      userLanguage,
    },
    options
  )
}

export const useGameSearch = () => {
  const userLanguage = useCurrentUserLanguage()
  const [searchGames, result] = useSearchGamesMutation()

  const doSearch = useCallback(
    (searchParams: Omit<SearchGamesQueryParams, 'userLanguage'>) =>
      searchGames({
        ...searchParams,
        userLanguage,
      }),
    [searchGames, userLanguage]
  )

  return {
    searchGames: doSearch,
    result,
  }
}

export const useGetGameArchiveStatus = (game?: Game) => {
  const isOwnGame = useOwnGameCheck(game)

  if (!game) {
    return GameArchiveStatus.unknown
  }

  // note!! there is some weird black magic from the dark ages here
  // game.archive false, means the game is archived
  // game.archive true, means the game is not archived
  if (!game?.archive && !isOwnGame) {
    return GameArchiveStatus.archived
  }

  return GameArchiveStatus.active
}
