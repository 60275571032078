export interface VersionTag {
  name: string
  id: string
}

export const VersionTagContent = ['5c754efb7d198e5f16899e7c']
export const VersionTagEvent = ['5c754ebf7d198e5f16899e79']
export const VersionTagFeature = ['5c754e947d198e5f16899e78']
export const VersionTagFixes = ['5c754ee67d198e5f16899e7b']
export const VersionTagSeasonal = ['5c754ed27d198e5f16899e7a']
