import { t } from 'i18next'
import { FC, useEffect, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'

import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import { GameCardContent } from '../../../../../features/game/components/GameCard/GameCard'
import { TrackedGame } from '../../../../../features/live-events/types/TrackedGame'
import { useGetTrackedGameQuery, useUpdateGameMutation } from '../../../../api/internal'
import { Currency } from '../../../../types/Currency'
import { GameMutation } from '../../../../types/GameMutation'
import { CurrencyAdder } from '../CurrencyAdder/CurrencyAdder'
import { CurrencyDisplay } from '../CurrencyDisplay/CurrencyDisplay'

type EditPricingDataDialogPros = {
  open: boolean
  onClose: () => void
  trackedGameData: TrackedGame | undefined
  trackedGameId: string
}

export const EditPricingDataDialog: FC<EditPricingDataDialogPros> = ({ open, onClose, trackedGameData, trackedGameId }) => {
  const [postPricingDataChange] = useUpdateGameMutation()
  const getGameDataQuery = useGetTrackedGameQuery(trackedGameId)
  const defaultCurrencyValues: { currencies: Currency[] } = useMemo(
    () => ({ currencies: getGameDataQuery.data?.game.currencies || [] }),
    [getGameDataQuery.data?.game.currencies]
  )
  const form = useForm<{ currencies: Currency[] }>({
    defaultValues: defaultCurrencyValues,
  })
  const {
    control,
    getValues,
    reset,
    formState: { isDirty, isValid },
  } = form

  useEffect(() => {
    if (getGameDataQuery.data) {
      reset(defaultCurrencyValues)
    }
  }, [defaultCurrencyValues, getGameDataQuery.data, reset])

  const saveCurrencyValues = () => {
    const gameData = getGameDataQuery.data?.rawGame
    if (gameData) {
      const storeIds = gameData?.stores?.map((store) => {
        return store.id
      })
      const marketIds = gameData?.markets.map((markert) => {
        return markert.id
      })
      const currencyList = getValues('currencies')
      const savedGame: GameMutation = { ...gameData, currencies: currencyList, stores: storeIds, markets: marketIds }
      postPricingDataChange(savedGame)
      onClose()
      reset(defaultCurrencyValues)
    }
  }

  const handleDialogClose = () => {
    onClose()
    reset(defaultCurrencyValues)
  }

  const handleCancel = () => {
    onClose()
    reset(defaultCurrencyValues)
  }

  const isLoading = getGameDataQuery.isLoading

  return isLoading ? (
    <GRCircularProgress />
  ) : (
    <Dialog open={open} onClose={handleDialogClose} fullWidth={true} maxWidth="md">
      <FormProvider {...form}>
        <DialogTitle>
          <Grid item>{trackedGameData?.game && <GameCardContent game={trackedGameData?.game} disableGameLink />}</Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} wrap="wrap" direction="row" alignItems="center">
            <Controller
              name="currencies"
              control={control}
              render={({ field }) => {
                return (
                  <>
                    <CurrencyDisplay currencies={field.value} onChange={field.onChange} />
                    <CurrencyAdder currencies={field.value} onChange={field.onChange} />
                  </>
                )
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              <Button onClick={handleCancel} variant="text" size="small" color="primary">
                {t('internal-common:cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={saveCurrencyValues} variant="contained" color="primary" disabled={!isDirty || !isValid}>
                {t('internal-common:save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
}
