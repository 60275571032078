import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, TableContainer } from '@mui/material'

import { GRCountryFlag } from '../../../../components/GRCountryFlag/GRCountryFlag'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GlobalLaunchIndicator } from '../../../../components/GlobalLaunchIndicator/GlobalLaunchIndicator'
import { LinearProgressBar } from '../../../../components/LinearProgressBar/LinearProgressBar'
import { TrendIndicator } from '../../../../components/TrendIndicator/TrendIndicator'
import { ValueIndicator, ValueType } from '../../../../components/ValueIndicator/ValueIndicator'
import { TableSortValues } from '../../../../types/TableSortValues'
import { CellValue } from '../../../soft-launch/components/CellValue/CellValue'
import { GameRevenueAndDownloadsTableRow } from '../../types/GameRevenueAndDownloadsTableRow'
import './GameRevenueAndDownloadsTable.scss'

type GameRevenueAndDownloadsTableProps = {
  rows: GameRevenueAndDownloadsTableRow[]
  isLoading?: boolean
}

export const GameRevenueAndDownloadsTable: FC<GameRevenueAndDownloadsTableProps> = ({ rows, isLoading }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)

  const customProps = useMemo(() => {
    return {}
  }, [])

  const initialColumns: GRTableColumn<GameRevenueAndDownloadsTableRow, typeof customProps>[] = useMemo(() => {
    return [
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('common:market'),
            accessor: ({ row }) => <MarketCell marketIso={row.marketIso} marketName={row.marketName} />,
            sortAccessor: ({ row }) => sortAccessor(row.marketName, row),
            sortable: true,
            cellProps: { align: 'left' },
            headerCellProps: { sx: { minWidth: 150 } },
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('common:revenue_text'),
            accessor: ({ row }) => (
              <CellValue value={row.revenue} emphasized>
                <ValueIndicator value={row.revenue} type={ValueType.Currency} displayZero />
              </CellValue>
            ),
            sortAccessor: ({ row }) => sortAccessor(row.revenue, row),
            sortOrder: SortOrder.DESC,
            sortable: true,
            headerCellProps: { sx: { minWidth: 150 } },
          },
          {
            labelAccessor: t('common:market_total_revenue_share'),
            accessor: ({ row }) => <ShareIndicator value={row.revenueShare} />,
            sortAccessor: ({ row }) => sortAccessor(row.revenueShare, row),
            sortable: true,
            headerCellProps: { sx: { minWidth: 150 } },
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('common:downloads_text'),
            accessor: ({ row }) => (
              <CellValue value={row.downloads} emphasized>
                <ValueIndicator value={row.downloads} type={ValueType.Number} displayZero />
              </CellValue>
            ),
            sortAccessor: ({ row }) => sortAccessor(row.downloads, row),
            sortable: true,
            headerCellProps: { sx: { minWidth: 150 } },
          },
          {
            labelAccessor: t('common:market_total_downloads_share'),
            accessor: ({ row }) => <ShareIndicator value={row.downloadsShare} />,
            sortAccessor: ({ row }) => sortAccessor(row.downloadsShare, row),
            sortable: true,
            headerCellProps: { sx: { minWidth: 150 } },
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('common:revenue_downloads_ratio'),
            accessor: ({ row }) => (
              <CellValue value={row.revenueDownloadsRatio} emphasized>
                <ValueIndicator value={row.revenueDownloadsRatio} type={ValueType.Currency} maximumFractionDigits={2} displayZero />
              </CellValue>
            ),
            sortAccessor: ({ row }) => sortAccessor(row.revenueDownloadsRatio, row),
            sortable: true,
            headerCellProps: { sx: { minWidth: 160 } },
          },
        ],
      },
    ] as GRTableColumn<GameRevenueAndDownloadsTableRow, typeof customProps>[]
  }, [t])

  const getRowClass = useCallback((row: GameRevenueAndDownloadsTableRow, index: number) => (index === 0 ? 'TotalRow' : ''), [])

  const [columns, setColumns] = useState<GRTableColumn<GameRevenueAndDownloadsTableRow, typeof customProps>[]>(initialColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<GameRevenueAndDownloadsTableRow, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <TableContainer ref={containerRef} className="GameRevenueAndDownloadsTable">
      <GRTable
        columns={columns}
        rows={rows}
        scroller={containerRef}
        onColumnsUpdated={handleColumnsUpdate}
        rowIdKey="marketIso"
        isLoading={isLoading}
        hoverable
        getRowClass={getRowClass}
      />
    </TableContainer>
  )
}

const ShareIndicator: FC<{ value: number }> = ({ value }) => (
  <LinearProgressBar
    value={value * 100}
    variant="determinate"
    labelAfter={<TrendIndicator displayZero value={value} sign={false} direction={false} maximumFractionDigits={2} color={false} />}
  />
)

const MarketCell: FC<{ marketIso?: string; marketName?: string }> = ({ marketIso, marketName }) => {
  const { t } = useTranslation()
  const resolvedMarketName = marketIso ? marketName : t('common:total_market_name')

  return (
    <>
      <Grid container alignItems="center" columnGap={2}>
        {marketIso ? <GRCountryFlag countryCode={marketIso} title={marketName} /> : <GlobalLaunchIndicator sx={{ verticalAlign: 'middle' }} />}
        {resolvedMarketName}
      </Grid>
    </>
  )
}

const sortAccessor = (value: any, row: GameRevenueAndDownloadsTableRow) => {
  // if market iso is not defined it's the 'Total' row
  if (row.marketIso === undefined) {
    return TableSortValues.AlwaysFirst
  } else {
    return value
  }
}
