import classNames from 'classnames'
import { FC } from 'react'

import { Check } from '@mui/icons-material'
import { Chip } from '@mui/material'

import { MarketExplorerSegmentColorBadge } from '../MarketExplorerSegmentColorBadge/MarketExplorerSegmentColorBadge'
import './MarketExplorerGamesChartColorChip.scss'

export const MarketExplorerGamesChartColorChip: FC<{
  segmentId: number | string
  label: any
  selected: boolean
  onClick: (segmentId: number | string) => void
}> = ({ segmentId, label, selected, onClick }) => {
  const classes = classNames('MarketExplorerGamesChartColorChip', { selected })
  return (
    <Chip
      className={classes}
      label={
        <>
          <MarketExplorerSegmentColorBadge orderNumber={segmentId} /> {label}
        </>
      }
      icon={<Check color={selected ? 'success' : 'disabled'} className="icon" />}
      onClick={() => onClick(segmentId)}
      color={selected ? 'success' : undefined}
      variant="outlined"
    />
  )
}
