import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid, Typography } from '@mui/material'

import GRDotRating from '../../../../../../components/GRDotRating/GRDotRating'
import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import languageService from '../../../../../../services/LanguageService'
import { MotivationType } from '../../../../types/MotivationType'
import { PlayerArchetype } from '../../../../types/PlayerArchetype'

/**
 * Components for rendering markt explorer games data table headings. Exports a collection of components.
 */

const Heading: FC<{ label: ReactNode; tooltip?: ReactNode }> = ({ label, tooltip }) => {
  const tooltipToDisplay = typeof tooltip === 'string' ? <Typography variant="body1">{tooltip}</Typography> : tooltip
  return <GRTooltip content={tooltipToDisplay}>{label}</GRTooltip>
}

const PowerScore: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:game_power_score')} tooltip={t('common:tooltip_gps_description')} />
}

const SkillAndThinking: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:skill_thinking')} tooltip={t('common:skill_thinking_description')} />
}

const SustainedGrossingRank: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:grossing_rank')} tooltip={t('common:sustained_grossing_rank_description')} />
}

const SustainedDownloadRank: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:download_rank')} tooltip={t('common:sustained_download_rank_description')} />
}

const DaysSinceRelease: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:days_since_release_text')} tooltip={t('common:days_since_release_description')} />
}

type PerfromanceDays = 30 | 180 | 360

const PerformanceRevenue: FC<{ days: PerfromanceDays }> = ({ days }) => {
  const { t } = useTranslation()

  return <Heading label={t('common:revenue_text')} tooltip={t(`common:${resolveRevenueTooltip(days)}`)} />
}

const PerformanceDownloads: FC<{ days: PerfromanceDays }> = ({ days }) => {
  const { t } = useTranslation()

  return <Heading label={t('common:downloads_text')} tooltip={t(`common:${resolveDownloadsTooltip(days)}`)} />
}

const PerformanceRevenueDownloadsRatio: FC<{ days: PerfromanceDays }> = ({ days }) => {
  const { t } = useTranslation()

  return <Heading label={t('common:revenue_downloads_ratio_shorthand')} tooltip={t(`common:${resolveRevenueDownloadsRatioTooltip(days)}`)} />
}

const DemographicsMale: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:male')} tooltip={t('common:male_description')} />
}

const DemographicsFemale: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:female')} tooltip={t('common:female_description')} />
}

const DemographicsAge16To24: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:age16_24')} tooltip={t('common:age16_24_description')} />
}

const DemographicsAge25To44: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:age25_44')} tooltip={t('common:age25_44_description')} />
}

const DemographicsAge45Plus: FC<{}> = () => {
  const { t } = useTranslation()

  return <Heading label={t('common:age45_plus')} tooltip={t('common:age45_plus_description')} />
}

const PlayerArchetypeTooltip: FC<{ title: string; description: string }> = ({ title, description }) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h3">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">{description}</Typography>
      </Grid>
    </Grid>
  )
}

const Archetype: FC<{ archetype: PlayerArchetype }> = ({ archetype }) => {
  const label = languageService.getTranslation('motivations', archetype)
  const description = languageService.getTranslation('motivations', archetype + '_description')
  return <Heading label={label} tooltip={<PlayerArchetypeTooltip title={label} description={description} />} />
}

const MotivationTooltip: FC<{ title: string; description: string }> = ({ title, description }) => {
  const { t } = useTranslation()
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body1">{t('motivations:motivation-me-tooltip-description')}</Typography>
      </Grid>
      <Grid container item gap={2}>
        <Grid container direction="column">
          <GRDotRating maxDots={5} dotValue={1} />
          <Typography variant="body1">{t('motivations:motivation-me-tooltip-not-imporant')}</Typography>
        </Grid>
        <Grid container direction="column">
          <GRDotRating maxDots={5} dotValue={5} />
          <Typography variant="body1">{t('motivations:motivation-me-tooltip-very-imporant')}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h3">{title}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">{description}</Typography>
      </Grid>
    </Grid>
  )
}

const Motivation: FC<{ motivationType: MotivationType; groupLabel: string }> = ({ motivationType, groupLabel }) => {
  const motivationTypeLabelParts = languageService.getTranslation('motivations', motivationType).match(/.*(- |–|:|：)(.*)/)
  const groupLabelParts = groupLabel.match(/.*(- |–|:|：)(.*)/)

  const label = motivationTypeLabelParts?.length === 3 ? motivationTypeLabelParts[2].trim() : motivationTypeLabelParts?.input
  const description = languageService.getTranslation('motivations', motivationType + '_description')

  const tooltipTitle = groupLabelParts?.length === 3 ? groupLabelParts[2].trim() : groupLabelParts?.input
  return <Heading label={label} tooltip={<MotivationTooltip title={`${tooltipTitle} - ${label}`} description={description} />} />
}

export const MarketExplorerGamesDataTableHeading = {
  PowerScore,
  SkillAndThinking,
  SustainedGrossingRank,
  SustainedDownloadRank,
  DaysSinceRelease,
  Performance: {
    Revenue: PerformanceRevenue,
    Downloads: PerformanceDownloads,
    RevenueDownloadsRatio: PerformanceRevenueDownloadsRatio,
  },
  Demographics: {
    Male: DemographicsMale,
    Female: DemographicsFemale,
    Age16To24: DemographicsAge16To24,
    Age25To44: DemographicsAge25To44,
    Age45Plus: DemographicsAge45Plus,
  },
  Archetype,
  Motivation,
}

const resolveRevenueTooltip = (days: PerfromanceDays) => {
  switch (days) {
    case 30:
      return 'revenue_past_month_description'
    case 180:
      return 'revenue_past_half_year_description'
    case 360:
      return 'revenue_past_year_description'
  }
}

const resolveDownloadsTooltip = (days: PerfromanceDays) => {
  switch (days) {
    case 30:
      return 'downloads_past_month_description'
    case 180:
      return 'downloads_past_half_year_description'
    case 360:
      return 'downloads_past_year_description'
  }
}

const resolveRevenueDownloadsRatioTooltip = (days: PerfromanceDays) => {
  switch (days) {
    case 30:
      return 'revenue_downloads_ratio_description'
    case 180:
      return 'revenue_downloads_ratio_past_half_year_description'
    case 360:
      return 'revenue_downloads_ratio_past_year_description'
  }
}
