import classNames from 'classnames'
import { useMemo } from 'react'

import { Box } from '@mui/material'

import { Game, GameIcon } from '../../../game'

/**
 * MotivationTableIconWrapper Motivation Table Icon Wrapper for games
 */
interface MotivationTableIconWrapperProps {
  game: Game
  label: string
  selected?: boolean
}
const MotivationTableIconWrapper: React.FC<MotivationTableIconWrapperProps> = ({ selected = false, game, label }) => {
  const gameIconSrc = useMemo(() => {
    if (game?.icons) {
      return game.icons[game.icons.length - 1]
    }

    return game.icon || ''
  }, [game.icon, game.icons])

  return (
    <Box className={classNames('MotivationTable__iconWrapper', { selected })}>
      {label}
      <GameIcon size="small" gameName={game.resolvedName} src={gameIconSrc} />
    </Box>
  )
}

export default MotivationTableIconWrapper
