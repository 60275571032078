import { t } from 'i18next'
import querystring from 'query-string'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, CardContent, Grid } from '@mui/material'

import { useGetLatestFtueVideosByGameIdsQuery } from '../../../../api/core'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import FtueVideoStaticPreviewCard from '../../../ftue-videos/components/FtueVideoStaticPreviewCard/FtueVideoStaticPreviewCard'
import { ftueVideosRootPath } from '../../../implementation-examples/hooks/useInitialState'
import { TopGame } from '../../../top-game/types/TopGame'
import { useGetSubGenres } from '../../hooks/genreEssentialsHooks'
import { filterGameIds } from '../../utils/filterGameIds'
import GenreEssentialCardHeader, { GenreEssentialSection } from '../GenreEssentialCardHeader/GenreEssentialCardHeader'

const maxVideosCount = 3
/**
 * LatestFTUEVideos
 */
interface LatestFTUEVideosProps {
  topGames: TopGame[]
  conventionalGenreId?: string
}
const LatestFTUEVideos: React.FC<LatestFTUEVideosProps> = ({ topGames, conventionalGenreId }) => {
  const navigate = useNavigate()
  const filteredGameIds = filterGameIds(topGames, 0, conventionalGenreId ? topGames.length : 50)
  const { data: latestFTUEVideos, isLoading: isLoadingFTUEVideos } = useGetLatestFtueVideosByGameIdsQuery({ gameIds: filteredGameIds })
  const subGenres = useGetSubGenres()

  const sortedVideos = useMemo(() => {
    if (!latestFTUEVideos) return

    return [...latestFTUEVideos].sort((a, b) => b.createdAt - a.createdAt).slice(0, maxVideosCount)
  }, [latestFTUEVideos])

  const handleOpenFTUEPage = () => {
    const selectedGenres =
      conventionalGenreId &&
      subGenres['subGenres']
        ?.filter((subgenre) => subgenre.genreId === conventionalGenreId)
        .map((subgenre) => subgenre.id)
        .join(',')

    const url = querystring.stringifyUrl({ url: ftueVideosRootPath, query: { subgenres: selectedGenres } })
    navigate(url)
  }

  if (!sortedVideos) {
    return null
  }

  return (
    <Card>
      <GenreEssentialCardHeader
        section={GenreEssentialSection.ftueVideos}
        onClick={handleOpenFTUEPage}
        title={t('genre-essentials:ftue_videos_title')}
        description={t('genre-essentials:ftue_videos_description')}
        buttonLabel={t('genre-essentials:open_in_ftue_videos') as string}
      />
      <CardContent>
        {isLoadingFTUEVideos ? (
          <GRCircularProgress />
        ) : (
          <Grid container columnSpacing={2} sx={{ display: 'flex' }}>
            {sortedVideos?.map((video) => (
              <Grid item xs={4} key={video.id}>
                <FtueVideoStaticPreviewCard video={video} />
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

export default LatestFTUEVideos
