import { useState, useCallback } from 'react'
import { BookType } from 'xlsx'

import { generateExport } from '../features/export-data/util/workbook'
import { ExportRow } from './exportDataHooks'

/**
 * Hook for handling data export requests
 */
type ExportData = {
  exportRows: ExportRow[]
  headerRows: string[][]
  filename: string
  sheetName: string
}

export const useDataExport = () => {
  const [exportRequested, setExportRequested] = useState(false)
  const [exportFormat, setExportFormat] = useState<BookType>()

  const handleExportRequested = useCallback((format: BookType) => {
    setExportRequested(true)
    setExportFormat(format)
  }, [])

  const handleExport = useCallback(
    (exportData: ExportData) => {
      if (exportRequested && exportFormat) {
        generateExport(exportFormat, exportData.exportRows, exportData.sheetName, exportData.filename, exportData.headerRows)
        setExportRequested(false)
        setExportFormat(undefined)
      }
    },
    [exportFormat, exportRequested]
  )
  return { handleExportRequested, handleExport, exportRequested }
}
