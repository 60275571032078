import {
  useGetLatestMotivationProfileQuery,
  useGetMotivationProfileHistoryQuery,
  useGetMotivationProfileVersionQuery,
  useGetMotivationProfileVersionsQuery,
} from '../../../api/core'
import { Game } from '../../game'
import { useOwnGameCheck } from '../../game/hooks/gameHooks'

interface useGetMotivationProfileProps {
  game: Game
}

interface useGetMotivationProfileVersionProps extends useGetMotivationProfileProps {
  version: string
}

/**
 * useGetMotivationProfile hooks return motivation profile query based on public or private game
 */
export const useGetMotivationProfile = ({ game }: useGetMotivationProfileProps) => {
  const isOwnGame = useOwnGameCheck(game)
  const queryId = isOwnGame ? game.id : game.appId
  return useGetLatestMotivationProfileQuery({ id: queryId, isOwnGame }, { skip: !queryId })
}

/**
 * useGetMotivationProfileHistory hook return motivation profile history query based on public game
 * @param game: Game
 * @returns []
 */
export const useGetMotivationProfileHistory = ({ game }: useGetMotivationProfileProps) => {
  const isOwnGame = useOwnGameCheck(game)
  return useGetMotivationProfileHistoryQuery({ appId: game.appId }, { skip: !game.appId || isOwnGame })
}

/**
 * useGetMotivationProfileVersions hook return motivation profile versions query based on public game
 * @param game: Game
 * @returns string[]
 */
export const useGetMotivationProfileVersions = ({ game }: useGetMotivationProfileProps) => {
  const isOwnGame = useOwnGameCheck(game)
  return useGetMotivationProfileVersionsQuery({ appId: game.appId }, { skip: !game.appId || isOwnGame })
}

/**
 * useGetMotivationProfileVersion hook return motivation profile version query based on public game
 * @param Game
 * @returns motivationProfile
 */
export const useGetMotivationProfileVersion = ({ game, version }: useGetMotivationProfileVersionProps) => {
  const isOwnGame = useOwnGameCheck(game)
  return useGetMotivationProfileVersionQuery({ appId: game.appId, version }, { skip: !game.appId || !version || isOwnGame })
}
