import { Game } from '../../game'
import { useOwnGameCheck } from '../../game/hooks/gameHooks'
import { GameAnalysisStatus } from '../../game/types/GameAnalysisStatus'
import { useCurrentMarket } from '../../markets'
import { Analysis } from '../types/Analysis'

const useGetGameAnalyzedStatus = (game?: Game, analysis?: Analysis): [GameAnalysisStatus] => {
  const isOwnGame = useOwnGameCheck(game)
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()

  if ((analysis && analysis.powerScore > 0) || game?.isAnalyzedForMarket(mainMarketIso)) {
    return [GameAnalysisStatus.analyzed]
  }

  if ((game?.archive || isOwnGame) && !game?.isAnalyzedForMarket(mainMarketIso)) {
    return [GameAnalysisStatus.game_not_analyzed]
  }

  return [GameAnalysisStatus.unknown]
}

export default useGetGameAnalyzedStatus
