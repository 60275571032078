import { useMemo } from 'react'

import { MarketExplorerGamesDataTableRow } from '../components/MarketExplorerGamesData/MarketExplorerGamesDataTable/MarketExplorerGamesDataTable'
import { MarketExplorerSegmentBreakdownData } from '../types/MarketExplorerSegmentBreakdownData'

export const useSegmentBreakdownGamesData = (segmentBreakdownData?: MarketExplorerSegmentBreakdownData) => {
  return useMemo(() => {
    return (segmentBreakdownData?.games?.map((game) => {
      return {
        game,
        segmentIndex: segmentBreakdownData.segmentId,
        segmentConfiguration: segmentBreakdownData.segment!.segmentConfiguration,
        rowId: `${game.id}_${segmentBreakdownData.segmentId}`,
        segmentData: segmentBreakdownData.segment,
      }
    }) || []) as MarketExplorerGamesDataTableRow[]
  }, [segmentBreakdownData])
}
