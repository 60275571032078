import { FC, lazy, Suspense } from 'react'
import { Trans } from 'react-i18next'

import { AutoFixHigh, ContentCopy } from '@mui/icons-material'
import { Card, CardActionArea, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material'

import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import './InputTranslatorAIAssistant.scss'

const HTMLEditor = lazy(() => import('../../HTMLEditor/HTMLEditor').then((module) => ({ default: module.HTMLEditor })))

type InputTranslatorAIAssistantProps = {
  value: string
  onChange?: (value: string) => void
  onGenerate: () => void
  onCopy: (value: string) => void
  isLoading?: boolean
}

export const InputTranslatorAIAssistant: FC<InputTranslatorAIAssistantProps> = ({ value, onChange, onCopy, onGenerate, isLoading }) => {
  return (
    <Card className="InputTranslatorAIAssistant">
      <Grid container spacing={2} px={2} py={1} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title={<Trans i18nKey="internal-common:ai-analyze" />}>
                <span>
                  <IconButton size="small" onClick={onGenerate} color="primary" disabled={isLoading}>
                    <AutoFixHigh />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Copy">
                <span>
                  <IconButton size="small" onClick={() => onCopy(value)} color="primary" disabled={!value}>
                    <ContentCopy />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>{isLoading && <CircularProgress size={30} />}</Grid>
      </Grid>
      <Suspense fallback={<GRCircularProgress />}>
        <HTMLEditor
          value={value}
          onEditorChange={(editorName, editor) => onChange?.(editor.getContent())}
          init={{
            setup: (editor) => editor.mode.set('readonly'),
            plugins: ['autoresize'],
            height: 500,
            menubar: false,
            toolbar: false,
            convert_urls: false,
          }}
          disabled
        />
      </Suspense>
      <CardActionArea></CardActionArea>
    </Card>
  )
}
