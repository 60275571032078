import { FC, ReactNode, forwardRef, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Check, HowToReg } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Card, CardContent, Divider, Fade, Grid, TextField, Typography, Zoom } from '@mui/material'

import { useRegisterUserMutation } from '../../api/account'
import logo from '../../assets/images/gamerefinery-logo.png'
import GRCircularProgress from '../../components/GRCircularProgress/GRCircularProgress'
import { PasswordField } from '../../components/PasswordField/PasswordField'
import { UserLanguage } from '../../features/account/types/User'
import { displaySnackBar } from '../../features/snackbar'
import { isFetchBaseQueryError } from '../../helpers/isFetchBaseQueryError'
import { useAppDispatch } from '../../hooks/storeHooks'
import { useSearchParams } from '../../hooks/useSearchParams'
import keycloakService from '../../services/KeycloakService'
import languageService from '../../services/LanguageService'
import utilsService from '../../services/UtilsService'
import { RegisterUser } from '../../types/RegisterUser'
import { GoogleSignupButton } from './GoogleSignupButton/GoogleSignupButton'

type RegisterPageProps = {
  children?: ReactNode
}

type RegisterFormState = {
  firstname: string
  lastname: string
  email: string
  password: string
  language: UserLanguage
}

const defaultUser: RegisterUser = {
  firstName: '',
  lastName: '',
  email: '',
  organization: '',
  companyUrl: '',
  city: '',
  country: '',
  zipCode: '',
  password: '',
  captcha: '',
  phone: '',
  title: '',
  acceptedTerms: false,
  jobFunction: '',
  language: languageService.defaultLanguage,
}

export const RegisterPage: FC<RegisterPageProps> = ({ children }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { parsedParams } = useSearchParams<{ email: string; language: UserLanguage }, { email: string; language: UserLanguage }>()
  const [isGoogleOAuth, setIsGoogleOAuth] = useState<boolean>(false)
  const [formState, setFormState] = useState<RegisterFormState>({
    email: parsedParams.email || '',
    firstname: '',
    lastname: '',
    password: '',
    language: parsedParams.language || languageService.defaultLanguage,
  })
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true)
  const [registerUser, registerUserMutationResponse] = useRegisterUserMutation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'email' && !isEmailValid) {
      setIsEmailValid(utilsService.validateEmail(event.target.value))
    }
    setFormState((current) => ({ ...current, [event.target.name]: event.target.value }))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const user: RegisterUser = {
      ...defaultUser,
      firstName: formState.firstname,
      lastName: formState.lastname,
      email: formState.email,
      password: formState.password,
      acceptedTerms: true,
      language: formState.language,
    }

    handleRegisterUser(user)
  }

  const handleRegisterUser = useCallback(
    (user: RegisterUser) => {
      registerUser(user)
    },
    [registerUser]
  )

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.name === 'email') {
      setIsEmailValid(utilsService.validateEmail(formState.email))
    }
  }

  // if we've gone through Google OAuth populate fields with profile data
  useEffect(() => {
    const keycloak = keycloakService.instance
    const profile = keycloakService.profile
    if (keycloak && profile && profile.email) {
      setIsGoogleOAuth(true)
      const user: RegisterUser = {
        ...defaultUser,
        firstName: profile?.firstName || '',
        lastName: profile?.lastName || '',
        email: profile?.email || '',
        password: utilsService.getRandomPassword(),
        acceptedTerms: true,
        language: 'en',
      }

      handleRegisterUser(user)
    }
  }, [handleRegisterUser])

  // successfull registration
  useEffect(() => {
    let timeout: number
    if (registerUserMutationResponse?.isSuccess) {
      timeout = window.setTimeout(() => {
        navigate('/')
        window.location.reload()
      }, 1000)
    }

    return () => window.clearTimeout(timeout)
  }, [formState.email, formState.password, navigate, registerUserMutationResponse.data, registerUserMutationResponse?.isSuccess])

  // something went wrong with registration
  useEffect(() => {
    const { isError, error } = registerUserMutationResponse
    if (isError && error) {
      // eslint-disable-next-line no-console
      console.error('RegisterPage: registration error', error)
      setIsGoogleOAuth(false)
      if (isFetchBaseQueryError(error) && error.status === 409) {
        dispatch(displaySnackBar({ message: t('common:failed_creating_account_error_user_exists'), severity: 'error', open: true }))
      } else {
        dispatch(displaySnackBar({ message: t('common:failed_creating_account_error'), severity: 'error', open: true }))
      }
    }
  }, [dispatch, registerUserMutationResponse, registerUserMutationResponse.error, t])

  const passwordStrength = utilsService.getPasswordStrength(formState.password)
  const formIsValid =
    formState.email.length > 0 &&
    utilsService.validateEmail(formState.email) &&
    formState.firstname.length > 0 &&
    formState.lastname.length > 0 &&
    passwordStrength &&
    passwordStrength !== 'too-short'
  const isLoading = registerUserMutationResponse.isLoading
  const isSuccess = registerUserMutationResponse.isSuccess

  const displayPleaseWaitScreen = (isLoading || isSuccess) && isGoogleOAuth

  return (
    <Box
      className="RegisterPage"
      sx={{ height: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}
    >
      {displayPleaseWaitScreen ? (
        <Fade in>
          <WaitScreen isSuccess={isSuccess} />
        </Fade>
      ) : (
        <Fade in>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} wrap="wrap" flexDirection="column" sx={{ flexBasis: { md: '50%', lg: '33%' } }} p={4}>
                  <Grid item xs={12} alignSelf="center" mb={2}>
                    <img src={logo} alt="Company logo" style={{ maxWidth: 250 }} />
                  </Grid>
                  <Grid item xs={12} alignSelf="center" mb={2}>
                    <Typography variant="h1" display="inline-block">
                      <Trans i18nKey="common:register_step_one_title_1" />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <GoogleSignupButton />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider variant="middle" sx={{ py: 1 }}>
                      <Trans i18nKey="segment:or_text" />
                    </Divider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      type="text"
                      name="email"
                      label="Email"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={formState.email}
                      error={!isEmailValid}
                      color={isEmailValid ? 'success' : undefined}
                      helperText={!isEmailValid ? t('organization:not_valid_email') : ''}
                    />
                  </Grid>
                  <Grid item container xs={12} spacing={2} wrap="wrap">
                    <Grid item xs={12} md={6} flexGrow={1}>
                      <TextField
                        required
                        type="text"
                        name="firstname"
                        label="First Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        color={formState.firstname.length > 0 ? 'success' : undefined}
                        value={formState.firstname}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} flexGrow={1}>
                      <TextField
                        required
                        type="text"
                        name="lastname"
                        label="Last Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        color={formState.lastname.length > 0 ? 'success' : undefined}
                        value={formState.lastname}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordField
                      required
                      name="password"
                      label="Password"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={handleChange}
                      value={formState.password}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      <Trans
                        i18nKey="common:accept_terms_checkbox"
                        values={{ termsUrl: 'https://www.gamerefinery.com/terms-of-service', privacyPolicyUrl: 'https://www.gamerefinery.com/privacy-policy' }}
                        // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
                        components={{ a: <a /> }}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color={isSuccess ? 'success' : 'primary'}
                      fullWidth
                      startIcon={isSuccess ? <Check /> : <HowToReg />}
                      loadingPosition="start"
                      loading={isLoading}
                      disabled={!formIsValid || isLoading}
                    >
                      <Trans i18nKey="common:register_create_an_account" />
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Fade>
      )}
    </Box>
  )
}

type WaitScreenProps = {
  isSuccess?: boolean
}

const WaitScreen: FC<WaitScreenProps> = forwardRef<HTMLDivElement, WaitScreenProps>(({ isSuccess }, ref) => {
  return (
    <Grid ref={ref} container flexDirection="column" spacing={2} justifyContent="center" alignContent="center">
      <Grid item xs={12} alignSelf="center" mb={2}>
        <img src={logo} alt="Company logo" style={{ maxWidth: 250 }} />
      </Grid>
      <Grid item xs={12} alignSelf="center">
        <Typography variant="h1" display="inline-block">
          <Trans i18nKey="buy-more-credits:please_wait" />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {!isSuccess ? (
          <GRCircularProgress />
        ) : (
          <Box textAlign="center">
            <Zoom in>
              <Check color="success" sx={{ fontSize: 40 }} />
            </Zoom>
          </Box>
        )}
      </Grid>
    </Grid>
  )
})
