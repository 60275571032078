import React, { MouseEventHandler, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { AddCircle, Check, Clear, Delete, RemoveCircle, Search } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'

import { Collection, CollectionVisibility, useDeleteCollectionMutation, useUpdateCollectionMutation } from '../../../api/core'
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog'
import { arraysEqual } from '../../../helpers/arrays'
import { useAppDispatch } from '../../../hooks/storeHooks'
import { ConfirmDialogData } from '../../../types/ConfirmDialogData'
import { useRoleCheck } from '../../account/hooks/roleHooks'
import { useCurrentUserOrganizationUsers } from '../../account/hooks/userHooks'
import { RoleEnum } from '../../account/types/RoleEnum'
import { User } from '../../account/types/User'
import { displaySnackBar } from '../../snackbar'
import './CollectionAdmin.scss'

interface CollectionAdminProps {
  collection: Collection
  onClose: () => void
}

const SearchFilter =
  (keyword: string) =>
  ({ firstName, lastName, email }: User): boolean => {
    if (!keyword || keyword.length === 0) {
      return true
    }
    return `${firstName}${lastName}${email}`.includes(keyword)
  }

const CollectionAdmin: React.FC<CollectionAdminProps> = ({ collection, onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { users, usersLoading } = useCurrentUserOrganizationUsers()
  const [updateCollection] = useUpdateCollectionMutation()
  const [deleteCollection] = useDeleteCollectionMutation()

  const [name, setName] = useState(collection.name)
  const [search, setSearch] = useState('')
  const [allowedUsers, setAllowedUsers] = useState(collection.allowedUsers)
  const [selectedEmails, setSelectedEmails] = useState([] as string[])
  const [optionEmails, setOptionEmails] = useState([] as string[])
  const [visibility, setVisibility] = useState(collection.visibility)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const isInternalUser = useRoleCheck(RoleEnum.internal)
  const dispatch = useAppDispatch()

  const deleteConfirmDialogProps: ConfirmDialogData<Object> = {
    title: t('common:confirm'),
    confirmText: <Trans i18nKey={'implementations:notify_are_you_sure_delete_collection'} />,
    destructiveAction: true,
    actionText: t('common:Yes'),
    cancelText: t('common:No'),
  }

  const handleChangeOption = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target
    setOptionEmails(typeof value === 'string' ? [value] : value)
  }

  const handleChangeSelected = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target
    setSelectedEmails(typeof value === 'string' ? [value] : value)
  }

  const onRemoveUser: MouseEventHandler = () => {
    setAllowedUsers(allowedUsers.filter((email) => !selectedEmails.includes(email)))
    setSelectedEmails([])
  }

  const onAddUser: MouseEventHandler = () => {
    setAllowedUsers([...allowedUsers, ...optionEmails])
    setOptionEmails([])
    setSearch('')
  }

  const onClickSave: MouseEventHandler = () => {
    updateCollection({
      allowedUsers,
      id: collection.id,
      name,
      screenshots: collection.screenshots,
      visibility,
    })
    dispatch(displaySnackBar({ message: t('implementations:label_colllection_updated_succesfully'), severity: 'success', open: true }))
    onClose()
  }

  const onClickDelete = (confirmData?: ConfirmDialogData<Object>) => {
    if (!confirmData) {
      return setOpenDeleteConfirm(false)
    }
    deleteCollection(collection.id)
    navigate('/implementation-examples/collections', { replace: true })
    dispatch(displaySnackBar({ message: t('implementations:notify_collection_deleted_succesfully'), severity: 'success', open: true }))
    setOpenDeleteConfirm(false)
  }

  const isDirty = (name.length > 2 && name !== collection.name) || visibility !== collection.visibility || !arraysEqual(allowedUsers, collection.allowedUsers)
  const availableUsers = users?.filter((user) => !allowedUsers.includes(user.email)).filter(SearchFilter(search))

  return (
    <>
      <ConfirmDialog open={openDeleteConfirm} confirmDialogData={deleteConfirmDialogProps} onClose={onClickDelete} />
      <Card className="collectionAdmin" sx={{ mb: 3 }} elevation={1}>
        <CardContent className="collectionAdminHeader">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h6">{t('implementations:label_collection_admin')}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button variant="contained" color="primary" startIcon={<Check />} onClick={onClickSave} disabled={!isDirty}>
                {t('common:save')}
              </Button>
              <Button variant="contained" color="warning" startIcon={<Delete />} onClick={() => setOpenDeleteConfirm(true)}>
                {t('common:delete')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs={2} className="selectionLabel">
              {t('common:name')}
            </Grid>
            <Grid item xs={10}>
              <TextField
                required
                error={name.length < 3}
                helperText={name.length < 3 && t('implementations:label_name_is_required_and_it_must_be_three_chars')}
                size="small"
                fullWidth
                value={name}
                onChange={({ target: { value } }) => setName(value.replace(/^\s/, ''))}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid container alignItems="center">
            <Grid item xs={2} className="selectionLabel">
              {t('implementations:label_who_will_see_this_collection')}
            </Grid>
            <Grid item xs={5}>
              <FormControl>
                <Select
                  size="small"
                  id="visibility-select"
                  value={visibility}
                  onChange={({ target }) => setVisibility(target.value as typeof collection.visibility)}
                >
                  <MenuItem value={CollectionVisibility.organization}>{t('implementations:label_everyone_in_my_organization')}</MenuItem>
                  <MenuItem value={CollectionVisibility.restricted}>{t('implementations:label_restricted_group_of_users')}</MenuItem>
                  {isInternalUser && <MenuItem value={CollectionVisibility.public}>{t('implementations:label_everyone')}</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              {visibility === CollectionVisibility.restricted && (
                <TextField
                  size="small"
                  fullWidth
                  onChange={({ target }) => setSearch(target.value)}
                  placeholder={t('implementations:label_search_by_typing_email')}
                  value={search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setSearch('')} disabled={!search || !search.length}>
                          <Clear />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
        {visibility === CollectionVisibility.restricted && (
          <CardContent>
            {!allowedUsers.length && (
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={5}>
                  <Typography>{t('implementations:label_currently_only_you_can_view_this_collection')}</Typography>
                </Grid>
              </Grid>
            )}

            <Grid container alignItems="center" justifyContent="space-between" className="peopleFlow">
              <Grid item xs={2} className="selectionLabel">
                {t('implementations:label_who_will_see_this_collection')}
              </Grid>
              <Grid item xs={5}>
                <Select multiline={true} rows={4} fullWidth multiple native onChange={handleChangeSelected} value={selectedEmails}>
                  {[...allowedUsers].sort().map((email, j) => (
                    <option key={j} value={email}>
                      {email}
                    </option>
                  ))}
                  {!allowedUsers.length && <option disabled />}
                </Select>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  startIcon={<RemoveCircle />}
                  onClick={onRemoveUser}
                  disabled={selectedEmails.length === 0}
                >
                  {t('organization:remove_user')}
                </Button>
              </Grid>
              <Grid item xs={5} textAlign="center">
                {usersLoading ? (
                  <Box m={2}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Select multiline={true} rows={4} fullWidth multiple native onChange={handleChangeOption} value={optionEmails}>
                    {availableUsers?.map((user, j) => (
                      <option key={j} value={user.email}>
                        {`${user.firstName} ${user.lastName} <${user.email}>`}
                      </option>
                    ))}
                    {!availableUsers.length && <option disabled />}
                  </Select>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  disabled={usersLoading || optionEmails.length === 0}
                  fullWidth
                  startIcon={<AddCircle />}
                  onClick={onAddUser}
                >
                  {t('organization:add_user')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </>
  )
}

export default CollectionAdmin
