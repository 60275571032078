import { FC, useRef } from 'react'
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline'
import { Trans } from 'react-i18next'

import { lighten, Typography } from '@mui/material'

import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import { TrendIndicator } from '../../../../../../components/TrendIndicator/TrendIndicator'
import { PerformanceEffectTimelineItem } from '../../../../types/LiveEvents'
import { PerformanceEffectType } from '../../../../types/PerformanceEffect'

const positiveEffectColor = '#38a73e'
const negativeEffectColor = '#b13d37'
export const LiveEventsCalendarPerformanceEffectItem: FC<ReactCalendarItemRendererProps<PerformanceEffectTimelineItem>> = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}) => {
  const itemRef = useRef(null)
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
  const severity = item.performanceEffect.value >= 0 ? 'success' : 'warning'
  const backgroundColor = item.performanceEffect.value >= 0 ? positiveEffectColor : negativeEffectColor
  const borderColor = item.performanceEffect.value >= 0 ? positiveEffectColor : negativeEffectColor
  const showDirectionArrow = itemContext.dimensions.width > 60
  const showValueSign = itemContext.dimensions.width > 35
  return (
    <GRTooltip
      content={<TooltipContent itemType={item.group as PerformanceEffectType} value={item.performanceEffect.value} />}
      severity={severity}
      anchorRef={itemRef}
    >
      <div
        {...getItemProps({
          style: {
            background: lighten(backgroundColor, 0.9),
            color: 'white',
            border: `1px solid ${borderColor}`,
            borderRadius: 10,
            overflow: 'hidden',
          },
        })}
        ref={itemRef}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'relative',
            bottom: '2px',
            textAlign: 'center',
          }}
        >
          <TrendIndicator value={item.performanceEffect.value} sign={showValueSign} direction={showDirectionArrow} />
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    </GRTooltip>
  )
}

const TooltipContent: FC<{ itemType: PerformanceEffectType; value: number }> = ({ itemType, value }) => {
  return (
    <>
      <Typography variant="body1" mb={1}>
        <Trans i18nKey={`live-events:performance_effect_type_${itemType.toLowerCase()}`} />
      </Typography>
      <Typography variant="body1" textAlign="center" mr={2}>
        <TrendIndicator value={value} sign direction />
      </Typography>
    </>
  )
}
