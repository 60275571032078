import { FC } from 'react'

import { PanoramaFishEye, Adjust, Lens } from '@mui/icons-material'

import { ReviewStatus } from '../../types/ReviewStatus'

export const ReviewStatusIcon: FC<{ status: ReviewStatus }> = ({ status }) => {
  const commonStyles = { fontSize: '1rem' }
  if (status === ReviewStatus.Empty) {
    return <PanoramaFishEye sx={{ color: (theme) => theme.palette.grey[500], ...commonStyles }} />
  } else if (status === ReviewStatus.Draft) {
    return <Adjust color="primary" sx={{ ...commonStyles }} />
  } else {
    return <Lens color="success" sx={{ ...commonStyles }} />
  }
}
