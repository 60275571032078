import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Divider, Typography } from '@mui/material'

import GRCircularProgress from '../../components/GRCircularProgress/GRCircularProgress'
import GameSubgenrePicker from '../../components/GameSubgenrePicker/GameSubgenrePicker'
import { LockedFeature } from '../../components/LockedFeature/LockedFeature'
import { RoleEnum, SubgenreMap } from '../../features/account'
import { useRoleCheck } from '../../features/account/hooks/roleHooks'
import GenreEssentialBody from '../../features/genre-essentials/components/GenreEssentialBody/GenreEssentialBody'
import { useGetSubGenres } from '../../features/genre-essentials/hooks/genreEssentialsHooks'
import { useGetTopGames } from '../../features/top-game/hooks/topGrossingGameHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import { useSearchParams } from '../../hooks/useSearchParams'
import analyticsService from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import { LockedFeatureId } from '../../types/LockedFeature'
import './GenreEssentialsPage.scss'

const topGamesInclude = [
  'appId',
  'name',
  'artist',
  'rank',
  'gps',
  'genre',
  'genreId',
  'gpsChange',
  'tags',
  'images',
  'subGenre',
  'subGenreId',
  'srank',
  'sdrank',
  'drankYesterday',
  'gameId',
  'rankYesterday',
  'conventionalCategoryId',
  'conventionalGenreId',
  'conventionalSubgenreId',
  'reviewId',
  'reviewPublished',
  'market',
]
/**
 * HeadLine
 */
interface HeadLineProps {}
const HeadLine: React.FC<HeadLineProps> = () => {
  return (
    <Box>
      <Typography>{t('genre-essentials:genre_essentials_description')}</Typography>
      <Divider sx={{ my: 2 }} />
    </Box>
  )
}

type GenreEssentialSearchParam = {
  conventionalGenreId?: string
}

const GenreEssentialsPage: React.FC = () => {
  usePage(PageService.getPageWithId('genre-essentials'), 'Visited Genre Essentials')
  useDocumentTitle(t('sidebar:genre_essentials'))

  const isGenreEssentialUnlocked = useRoleCheck(RoleEnum.genre_essentials)
  const { parsedParams, setSearchParams } = useSearchParams<GenreEssentialSearchParam, GenreEssentialSearchParam>()
  const { topGames, isLoading: isTopGamesLoading } = useGetTopGames(topGamesInclude)

  const { subGenres, genreTaxonomy } = useGetSubGenres()

  const initialSelectedSubgenres = useMemo(() => {
    if (!subGenres || !parsedParams.conventionalGenreId) return {}

    return subGenres
      ?.filter((subgenre) => subgenre.genreId === parsedParams.conventionalGenreId)
      .reduce((result, subgenre) => {
        result[subgenre.id] = true
        return result
      }, {} as SubgenreMap)
  }, [parsedParams.conventionalGenreId, subGenres])
  const [selectedSubgenres, setSelectedSubgenres] = useState(initialSelectedSubgenres)

  const selectedConventionalGenreId = useMemo(() => {
    const firstSubgenres = Object.keys(selectedSubgenres)[0]
    if (!firstSubgenres || !subGenres) return

    const selectedGenre = subGenres.find((subgenre) => subgenre.id === firstSubgenres)

    return selectedGenre ? selectedGenre.genreId : undefined
  }, [selectedSubgenres, subGenres])

  const sendGenreAnalyticEvent = useCallback(() => {
    if (!genreTaxonomy || !parsedParams.conventionalGenreId) return

    const selectedGenre = genreTaxonomy.flatMap((category) => category.genres).find((genre) => genre.id === parsedParams.conventionalGenreId)
    if (selectedGenre) {
      analyticsService.trackEvent('Genre Essentials Selected Genre', {
        data: {
          genreId: selectedGenre.id,
          genreName: selectedGenre.name,
        },
      })
    }
  }, [genreTaxonomy, parsedParams.conventionalGenreId])

  useEffect(() => {
    if (selectedConventionalGenreId !== parsedParams.conventionalGenreId) {
      setSearchParams({ ...parsedParams, conventionalGenreId: selectedConventionalGenreId })
    }
  }, [parsedParams, selectedConventionalGenreId, sendGenreAnalyticEvent, setSearchParams, subGenres])

  useEffect(() => {
    if (genreTaxonomy && parsedParams.conventionalGenreId) {
      sendGenreAnalyticEvent()
    }
  }, [genreTaxonomy, parsedParams.conventionalGenreId, sendGenreAnalyticEvent])

  const isLoading = isTopGamesLoading

  if (!isGenreEssentialUnlocked) {
    return <LockedFeature.Card lockedFeatureId={LockedFeatureId.GenreEssentials} />
  }

  return (
    <Box className="GenreEssentialsPage">
      <HeadLine />
      <GameSubgenrePicker
        selectionChanged={setSelectedSubgenres}
        selectedSubgenres={selectedSubgenres}
        showSubgenreDefinitionBtn
        defaultExpand
        hideSubGenreSelectDropdown
        disableCategorySelect
        hideSetAsDefault
        toggleGenreSelectionMode
      />
      <Divider sx={{ mb: 2 }} />
      {isLoading && <GRCircularProgress />}
      {!isLoading && !!topGames && (
        <GenreEssentialBody conventionalGenreId={selectedConventionalGenreId} topGames={topGames} selectedSubgenres={selectedSubgenres} />
      )}
    </Box>
  )
}

export default GenreEssentialsPage
