import { VisualAnalysis } from '../../../api/connect'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import { getVisualAnalysisName } from '../util/helpers'

/**
 * Resolve game name from VisualAnalysis object
 */
type VisualAnalysisNameHookParams = {
  visualAnalysis: VisualAnalysis
}

export const useVisualAnalysisName = ({ visualAnalysis }: VisualAnalysisNameHookParams): string => {
  const userLanguage = useCurrentUserLanguage()
  return getVisualAnalysisName({ visualAnalysis, userLanguage })
}
