import React from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Grid, Typography } from '@mui/material'

import ProgressBar from '../../../../components/ProgressBar/ProgressBar'
import languageService from '../../../../services/LanguageService'
import { CategoryImage } from '../../../game-features/components/CategoryImage/CategoryImage'
import EffectImpactDiffText from '../EffectImpactDiffText/EffectImpactDiffText'
import './FeatureCategoryBreakDown.scss'

export interface categoryCard {
  id: string
  name: string
  icon: string
  currentEffect: number
  prevEffect: number
  sum: number
  legacyId: number
}

/**
 * FeatureCategoryBreakDown show list of categories as well as diff between latest update and previous
 */

interface FeatureCategoryBreakDownProps {
  categoryCards: categoryCard[]
}
const FeatureCategoryBreakDown: React.FC<FeatureCategoryBreakDownProps> = ({ categoryCards }) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h3" sx={{ mb: 2 }}>
        {t('analysis:feature_category_breakdown')}
      </Typography>
      {categoryCards.map((category, index) => {
        return (
          <Card sx={{ marginBottom: index !== categoryCards.length - 1 ? 2 : 0 }} key={category.id}>
            <Grid container columnSpacing={1} sx={{ padding: 2 }}>
              <Grid item lg={5} sm={5} xs={12} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <CategoryImage src={category.icon} alt={languageService.getTranslation('categories', category.legacyId.toString())} />
                <Typography sx={{ margin: '0 10px' }}>{languageService.getTranslation('categories', category.legacyId.toString())}</Typography>
              </Grid>
              <Grid item lg={1} sm={1}>
                <EffectImpactDiffText sx={{ mr: 1 }} value={category.sum} />
              </Grid>
              <Grid item lg={6} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <ProgressBar value={category.currentEffect} prevValue={category.prevEffect} variant="small" />
              </Grid>
            </Grid>
          </Card>
        )
      })}
    </>
  )
}

export default FeatureCategoryBreakDown
