import { useMemo } from 'react'

import { CategoriesMap } from '../../../api/core'
import { Analysis } from '../../analysis/types/Analysis'

/**
 * useGetAnswerProgress calculate answering progress using features and analysis
 */
const useGetAnswerProgress = (analysis?: Analysis, categoriesMap?: CategoriesMap): [number | undefined] => {
  const total = useMemo(() => {
    if (!!categoriesMap && !!analysis) {
      return Object.values(categoriesMap).reduce(
        (result, category) => {
          const totalCount = category.featureCount
          const answerCount = analysis?.progress[category.id] || 0
          const maxAnswerCount = answerCount > totalCount ? totalCount : answerCount
          return { featureCount: result.featureCount + category.featureCount, answerCount: result.answerCount + maxAnswerCount }
        },
        { featureCount: 0, answerCount: 0 }
      )
    }
  }, [analysis, categoriesMap])

  if (!total) {
    return [undefined]
  }

  const progressPercentage = Math.floor((total.answerCount / total.featureCount) * 100)
  return [progressPercentage]
}

export default useGetAnswerProgress
