import { useState, useCallback, useEffect } from 'react'

import { TrackingEventTimelineGroup } from '../types/LiveEvents'
import { EventDialogTab } from './useEventDialogTabs'
import { useLiveEventModal } from './useLiveEventModal'

/**
 * Hook for opening event modal when LET calendar group is clicked.
 *
 * This is a a bit of hack but needs to be done like this because calendar does not necessarily update the event handlers
 * when location is changed e.g. it uses stale state to open the modal which causes issues like https://vungle.atlassian.net/browse/GR-2050 and https://vungle.atlassian.net/browse/GR-2049
 */
export const useLiveEventsCalendarGroupClickHandler = () => {
  const { showModal: showEventModal } = useLiveEventModal()
  const [selectedTrackingEventTimelineGroup, setSelectedTrackingEventTimelineGroup] = useState<TrackingEventTimelineGroup>()

  const handleCalendarEventGroupClick = useCallback((group: TrackingEventTimelineGroup) => {
    setSelectedTrackingEventTimelineGroup(group)
  }, [])

  useEffect(() => {
    const group = selectedTrackingEventTimelineGroup
    if (group) {
      setSelectedTrackingEventTimelineGroup(undefined)
      showEventModal({ trackedGameId: group.trackedGame.game.id, eventTypeId: (group.id as string).split('_')[0], tab: EventDialogTab.AllEvents })
    }
  }, [selectedTrackingEventTimelineGroup, showEventModal])

  return handleCalendarEventGroupClick
}
