import { createSelector } from '@reduxjs/toolkit'

import { combinedApi } from '../../../api/combined'
import { UserSettingKeys } from '../types/UserSettings'

export const userSettingsResult = combinedApi.endpoints.getUserSettings.select()

export const selectUserSettings = createSelector(userSettingsResult, (userSettings) => userSettings?.data ?? {})

export const selectCurrentMarketIso = createSelector(selectUserSettings, (userSettings) => userSettings.userMarket?.iso || 'us')

export const selectFullscreenMode = createSelector(selectUserSettings, (userSettings) => {
  const value = userSettings[UserSettingKeys.fullscreenMode]
  return value === 'true' || value === true
})

const userProfile = combinedApi.endpoints.getUserProfile.select()

export const selectUserProfile = createSelector(userProfile, (result) => result.data)
