import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Divider } from '@mui/material'

import { Tag } from '../../../../api/core'
import { useAppSelector } from '../../../../hooks/storeHooks'
import { BooleanMap } from '../../../../types/BooleanMap'
import { useInternalAccessCheck } from '../../../account/hooks/roleHooks'
import { AppType } from '../../../game/types/Game'
import { useLiveEventsCommonFiltersAnalyticsEvents } from '../../hooks/useLiveEventsTrackerAnalyticsEvents'
import { selectSelectedTrackedGames } from '../../slices/liveEventsCalendarsSlice'
import {
  LiveEventsCommonFiltersData,
  TLiveEventsCommonFilters,
  LiveEventsCommonFilterKeys,
  LiveEventsCommonFilterGroup,
  LiveEventsCommonFiltersSelection,
} from '../../types/LiveEventsCommonFilters'
import LiveEventsAppearanceSelector from '../LiveEventsAppearanceSelector/LiveEventsAppearanceSelector'
import LiveEventsCalendarAdditionalDataSelector from '../LiveEventsCalendarAdditionalDataSelector/LiveEventsCalendarAdditionalDataSelector'
import LiveEventsDurationsSelector from '../LiveEventsDurationsSelector/LiveEventsDurationsSelector'
import LiveEventsMotivationsSelector from '../LiveEventsMotivationsSelector/LiveEventsMotivationsSelector'
import LiveEventsPricePointSelector from '../LiveEventsPricePointSelector/LiveEventsPricePointSelector'
import LiveEventsSecondaryTaxonomySelector from '../LiveEventsSecondaryTaxonomySelector/LiveEventsSecondaryTaxonomySelector'
import LiveEventsTagsSelector from '../LiveEventsTagsSelector/LiveEventsTagsSelector'

type LiveEventsCommonFiltersProps = {
  data: LiveEventsCommonFiltersData
  filters: TLiveEventsCommonFilters
  onChange: (filters: TLiveEventsCommonFilters) => void
  children?: ReactNode
}

type LiveEventFilterChangeData<T extends LiveEventsCommonFilterKeys> = {
  name: T
  newValue: TLiveEventsCommonFilters[T]
  selection?: LiveEventsCommonFiltersSelection
}

type FilterSelectAll = {
  name: LiveEventsCommonFilterKeys
  newValue: any
}

export const LiveEventsCommonFilters: FC<LiveEventsCommonFiltersProps> = ({ data, filters, onChange, children }) => {
  const { t } = useTranslation()

  const selectedGames = useAppSelector(selectSelectedTrackedGames)
  const hdGameSelected = selectedGames.some((game) => game.game.appType === AppType.PC_CONSOLE) //NOTE: Remove when hd game check when we want to release price points for mobile games
  // NOTE: Remove only the check from JSX, If releasing only one feature (Motivation or Price points)
  const internalUser = useInternalAccessCheck() // NOTE: Remove Internal role check when we want to release Live Event Motivations or LET Price Points

  const { handleTrackCommonFilterGroupSelectAllEvent, handleTrackFilterChangedEvent } = useLiveEventsCommonFiltersAnalyticsEvents()
  const handleChange = <T extends LiveEventsCommonFilterKeys>(filter: LiveEventFilterChangeData<T> | LiveEventFilterChangeData<T>[]) => {
    let newFilters = { ...filters }
    let filterArray = Array.isArray(filter) ? filter : [filter]

    filterArray.forEach((filterObject) => {
      if (filterObject.selection) {
        handleTrackFilterChangedEvent(filterObject.selection)
      }

      newFilters = {
        ...newFilters,
        [filterObject.name]: filterObject.newValue,
      }
    })

    onChange(newFilters)
  }

  const handleSelectAll = (filter: FilterSelectAll | FilterSelectAll[]) => {
    let filterArray = Array.isArray(filter) ? filter : [filter]
    filterArray.forEach((filter) => {
      handleTrackCommonFilterGroupSelectAllEvent(filter.name)
    })

    handleChange(filterArray)
  }

  return (
    <>
      <LiveEventsCalendarAdditionalDataSelector
        calendarAdditionalDatas={data.additionalDatas}
        selectedCalendarAdditionalDataIdsMap={filters.additionalDatas}
        onAdditionalDataSelectChange={(additionalDatas: { [dataId: string]: boolean }, data) =>
          handleChange({
            name: LiveEventsCommonFilterKeys.AdditionalDatas,
            newValue: additionalDatas,
            selection: { ...data, type: LiveEventsCommonFilterGroup.GameData },
          })
        }
        onAdditionalDataSelectionCleared={() => handleChange({ name: LiveEventsCommonFilterKeys.AdditionalDatas, newValue: {} })}
        onAdditionalDataSelectAll={() =>
          handleSelectAll({
            name: LiveEventsCommonFilterKeys.AdditionalDatas,
            newValue: data.additionalDatas.reduce((acc, data) => ({ ...acc, [data.id]: true }), {} as { [dataId: string]: boolean }),
          })
        }
        open
      />

      <Divider sx={{ marginBottom: 1, marginTop: 1 }} />

      <LiveEventsTagsSelector
        selectableLiveEventTags={data.liveEventTags}
        selectedLiveEventTags={filters.liveEventTags}
        onLiveEventsTagSelectChange={(selectedTags: Tag[], selection: { id: string; name: string }) =>
          handleChange({
            name: LiveEventsCommonFilterKeys.LiveEventTags,
            newValue: selectedTags,
            selection: { ...selection, type: LiveEventsCommonFilterGroup.EventType },
          })
        }
        onLiveEventsTagGroupSelected={(selectedTags: Tag[], selection: { id: string; name: string }) =>
          handleChange({
            name: LiveEventsCommonFilterKeys.LiveEventTags,
            newValue: selectedTags,
            selection: { ...selection, type: LiveEventsCommonFilterGroup.EventTypeGroup },
          })
        }
        onLiveEventsTagSelectionCleared={() => handleChange({ name: LiveEventsCommonFilterKeys.LiveEventTags, newValue: [] })}
        onLiveEventsTagSelectAll={() => handleSelectAll({ name: LiveEventsCommonFilterKeys.LiveEventTags, newValue: data.liveEventTags })}
        open
      />

      <>
        <Divider sx={{ marginBottom: 1, marginTop: 1 }} />

        <LiveEventsSecondaryTaxonomySelector
          selectableLiveEventSecondaryTags={data.liveEventSecondaryTags}
          onLiveEventsSecondaryTaxonomySelectChange={(selectedTags: Tag[], selection: { id: string; name: string }) =>
            handleChange({
              name: LiveEventsCommonFilterKeys.LiveEventSecondaryTags,
              newValue: selectedTags,
              selection: { ...selection, type: LiveEventsCommonFilterGroup.EventType },
            })
          }
          selectedLiveEventSecondaryTags={filters.liveEventSecondaryTags}
          onLiveEventsSecondaryTaxonomyCleared={() => handleChange({ name: LiveEventsCommonFilterKeys.LiveEventSecondaryTags, newValue: [] })}
          onLiveEventsSecondaryTaxonomySelectAll={() =>
            handleSelectAll({ name: LiveEventsCommonFilterKeys.LiveEventSecondaryTags, newValue: data.liveEventSecondaryTags })
          }
          open
        />
      </>

      {internalUser && (
        <>
          <Divider sx={{ marginBottom: 1, marginTop: 1 }} />

          <LiveEventsMotivationsSelector
            selectableMotivations={data.motivations}
            selectedMotivationsIdsMap={filters.motivations}
            selectableArchetypes={data.archetypes}
            selectedArchetypesIdsMap={filters.archetypes}
            onLiveEventsMotivationSelectChange={(motivations: BooleanMap, motivation) =>
              handleChange({
                name: LiveEventsCommonFilterKeys.Motivations,
                newValue: motivations,
                selection: {
                  id: motivation,
                  name: motivation,
                  type: LiveEventsCommonFilterGroup.Motivation,
                },
              })
            }
            onLiveEventsArchetypeSelectChange={(archetypes: BooleanMap, motivationKey) =>
              handleChange({
                name: LiveEventsCommonFilterKeys.Archetypes,
                newValue: archetypes,
                selection: {
                  id: motivationKey,
                  name: motivationKey,
                  type: LiveEventsCommonFilterGroup.Archetype,
                },
              })
            }
            onLiveEventsMotivationsSelectionCleared={() => {
              handleChange([
                { name: LiveEventsCommonFilterKeys.Motivations, newValue: {} },
                { name: LiveEventsCommonFilterKeys.Archetypes, newValue: {} },
              ])
            }}
            onLiveEventsMotivationsSelectAll={() => {
              handleSelectAll([
                {
                  name: LiveEventsCommonFilterKeys.Motivations,
                  newValue: data.motivations.reduce((acc, motivation) => ({ ...acc, [motivation]: true }), {} as BooleanMap),
                },
                {
                  name: LiveEventsCommonFilterKeys.Archetypes,
                  newValue: data.archetypes.reduce((acc, archetype) => ({ ...acc, [archetype]: true }), {} as BooleanMap),
                },
              ])
            }}
            open
          />
        </>
      )}

      <Divider sx={{ marginBottom: 1, marginTop: 1 }} />

      <LiveEventsDurationsSelector
        liveEventDurations={data.liveEventDurations}
        selectedDurationIdsMap={filters.liveEventDurations}
        onLiveEventsDurationSelectChange={(durations: BooleanMap, duration) =>
          handleChange({
            name: LiveEventsCommonFilterKeys.LiveEventDurations,
            newValue: durations,
            selection: { ...duration, type: LiveEventsCommonFilterGroup.Duration },
          })
        }
        onLiveEventsDurationSelectionCleared={() => handleChange({ name: LiveEventsCommonFilterKeys.LiveEventDurations, newValue: {} })}
        onLiveEventsDurationSelectAll={() =>
          handleSelectAll({
            name: LiveEventsCommonFilterKeys.LiveEventDurations,
            newValue: data.liveEventDurations.reduce((acc, duration) => ({ ...acc, [duration.id]: true }), {} as BooleanMap),
          })
        }
        open
      />

      <Divider sx={{ marginBottom: 1, marginTop: 1 }} />

      <LiveEventsAppearanceSelector
        liveEventAppearances={data.liveEventAppearances}
        selectedAppearanceIdsMap={filters.liveEventAppearances}
        onLiveEventsAppearanceSelectChange={(appearances: BooleanMap, appearance) =>
          handleChange({
            name: LiveEventsCommonFilterKeys.LiveEventAppearances,
            newValue: appearances,
            selection: {
              id: appearance,
              name: t('live-events:appearance_event_type_' + appearance),
              type: LiveEventsCommonFilterGroup.Appearance,
            },
          })
        }
        onLiveEventsAppearanceSelectionCleared={() => handleChange({ name: LiveEventsCommonFilterKeys.LiveEventAppearances, newValue: {} })}
        onLiveEventsAppearanceSelectAll={() =>
          handleSelectAll({
            name: LiveEventsCommonFilterKeys.LiveEventAppearances,
            newValue: data.liveEventAppearances.reduce((acc, appearance) => ({ ...acc, [appearance]: true }), {} as BooleanMap),
          })
        }
        open
      />

      {internalUser && hdGameSelected && (
        <>
          <Divider sx={{ marginBottom: 1, marginTop: 1 }} />

          <LiveEventsPricePointSelector
            liveEventPricePoints={data.liveEventPricePoints}
            selectedPricePointIdsMap={filters.liveEventPricePoints}
            onLiveEventsPricePointSelectChange={(pricePoints: BooleanMap, pricePoint) =>
              handleChange({
                name: LiveEventsCommonFilterKeys.LiveEventPricePoints,
                newValue: pricePoints,
                selection: {
                  ...pricePoint,
                  type: LiveEventsCommonFilterGroup.PricePoint,
                },
              })
            }
            onLiveEventsPricePointSelectionCleared={() => handleChange({ name: LiveEventsCommonFilterKeys.LiveEventPricePoints, newValue: {} })}
            onLiveEventsPricePointSelectAll={() =>
              handleSelectAll({
                name: LiveEventsCommonFilterKeys.LiveEventPricePoints,
                newValue: data.liveEventPricePoints.reduce((acc, pricePoint) => ({ ...acc, [pricePoint.id]: true }), {} as BooleanMap),
              })
            }
            open
          />
        </>
      )}
    </>
  )
}
