import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowForward, DescriptionOutlined, EditOutlined, SaveOutlined } from '@mui/icons-material'
import { Box, Dialog, DialogActions, DialogContent, Divider, Grid, Typography } from '@mui/material'

import Powerscore from '../../../../components/Powerscore/Powerscore'

interface GameNewIntroModalProps {
  open: boolean
  children: ReactNode
}
const GameNewIntroModal: React.FC<GameNewIntroModalProps> = ({ open, children }) => {
  const { t } = useTranslation()
  const stepIconSize = 90
  const introSteps = [
    {
      text: t('new-game:basic_info'),
      icon: <EditOutlined color="primary" sx={{ fontSize: stepIconSize, mb: 1 }} />,
    },
    {
      text: t('new-game:choose_features'),
      icon: <DescriptionOutlined color="primary" sx={{ fontSize: stepIconSize, mb: 1 }} />,
    },
    {
      text: t('new-game:save'),
      icon: <SaveOutlined color="primary" sx={{ fontSize: stepIconSize, mb: 1 }} />,
    },
    {
      text: t('new-game:done_confirm'),
      icon: (
        <Box sx={{ mt: 1, mb: 1 }}>
          <Powerscore powerscore={86} size={78} />
        </Box>
      ),
      // icon: <AnalyticsOutlined color="primary" sx={{ fontSize: stepIconSize, mb: 1 }} />,
    },
  ]

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogContent>
        <Typography sx={{ mb: 3 }}>{t('new-game:description')}</Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container justifyContent="space-around" sx={{ mb: 1 }}>
          {introSteps.map((step, index) => (
            <React.Fragment key={index}>
              <Grid container item sx={{ width: 180 }} alignItems="center" flexDirection="column">
                {step.icon}
                <Typography align="center">{step.text}</Typography>
              </Grid>
              {index < introSteps.length - 1 && <ArrowForward sx={{ marginTop: 12 }} />}
            </React.Fragment>
          ))}
        </Grid>
        <Divider sx={{ mb: 1 }} />
      </DialogContent>
      <DialogActions>{children}</DialogActions>
    </Dialog>
  )
}

export default GameNewIntroModal
