import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PublicIcon from '@mui/icons-material/Public'
import { LoadingButton } from '@mui/lab'
import { Box, FormControl, Grid } from '@mui/material'

import DateRangePicker, { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { GranularitySelector } from '../../../../components/GranularitySelector/GranularitySelector'
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu'
import { RegionMarketSelectorDialog } from '../../../region'
import { GameRevenueAndDownloadsFiltersValue, DeviceTypeValue, GranularityValue, DataTypeValue } from '../../types/Filters'

type GameRevenueAndDownloadsFiltersProps = {
  values: GameRevenueAndDownloadsFiltersValue
  marketIsos: string[]
  isLoading?: boolean
  onSelectedMarketsChanged: (marketIsos: string[]) => void
  onDateRangeChanged: (value: DateRangeValue) => void
  onDataTypeChanged: (value: DataTypeValue) => void
  onGranularityChanged: (value: GranularityValue) => void
  onDeviceTypeChanged: (value: DeviceTypeValue) => void
  minDate?: Date
  maxDate?: Date
  dateRangeDisabled?: boolean
}

export const GameRevenueAndDownloadsFilters: FC<GameRevenueAndDownloadsFiltersProps> = ({
  values,
  marketIsos,
  isLoading,
  onSelectedMarketsChanged,
  onDateRangeChanged,
  onDataTypeChanged,
  onGranularityChanged,
  onDeviceTypeChanged,
  minDate,
  maxDate,
  dateRangeDisabled,
}) => {
  const { t } = useTranslation()
  const [marketSelectorDialogOpen, setMarketSelectorDialogOpen] = useState<boolean>(false)

  const handleDialogClose = useCallback(
    (marketIsos: string[]) => {
      setMarketSelectorDialogOpen(false)
      onSelectedMarketsChanged(marketIsos)
    },
    [onSelectedMarketsChanged]
  )

  const handleDateRangeChange = useCallback(
    (value?: DateRangeValue) => {
      onDateRangeChanged(value as DateRangeValue)
    },
    [onDateRangeChanged]
  )

  const handleDataTypeChange = useCallback(
    (value: string) => {
      onDataTypeChanged(value as DataTypeValue)
    },
    [onDataTypeChanged]
  )

  const handleGranularityChange = useCallback(
    (value: string) => {
      onGranularityChanged(value as GranularityValue)
    },
    [onGranularityChanged]
  )

  const handleDeviceTypeChange = useCallback(
    (value: string) => {
      onDeviceTypeChanged(value as DeviceTypeValue)
    },
    [onDeviceTypeChanged]
  )

  return (
    <Grid container wrap="wrap" gap={2}>
      <Grid container justifyContent="space-between" alignItems="center" gap={2} wrap="wrap">
        <Box>
          {dateRangeDisabled ? (
            <GRTooltip content={t('overview:date_picker_disabled_rd_type')}>
              <DateRangePicker
                onChangeValue={handleDateRangeChange}
                value={values.dateRange}
                disabled={dateRangeDisabled}
                minDate={minDate}
                maxDate={maxDate}
              />
            </GRTooltip>
          ) : (
            <DateRangePicker onChangeValue={handleDateRangeChange} value={values.dateRange} disabled={dateRangeDisabled} minDate={minDate} maxDate={maxDate} />
          )}
        </Box>
        <DataTypeSelector value={values.dataType} onChange={handleDataTypeChange} />
      </Grid>
      <Grid container gap={2} justifyContent="space-between">
        <Grid container gap={2} wrap="wrap">
          <GranularitySelector value={values.granularity} onChange={handleGranularityChange} />
          <DeviceTypeSelector value={values.deviceType} onChange={handleDeviceTypeChange} />
        </Grid>
        <Grid item>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={() => setMarketSelectorDialogOpen(true)}
            startIcon={<PublicIcon />}
            disabled={marketIsos.length === 0}
            loading={isLoading}
            loadingPosition="start"
          >
            {t('common:markets')} ({values.selectedMarketIsos.length}/{marketIsos.length})
          </LoadingButton>
        </Grid>

        {!isLoading && (
          <RegionMarketSelectorDialog
            open={marketSelectorDialogOpen}
            onClose={handleDialogClose}
            marketIsos={marketIsos}
            selectedMarketIsos={values.selectedMarketIsos}
          />
        )}
      </Grid>
    </Grid>
  )
}

const DeviceTypeSelector: FC<{ value: DeviceTypeValue; onChange: (value: DeviceTypeValue) => void }> = ({ onChange, value }) => {
  const { t } = useTranslation()
  const options = useMemo(() => {
    return [
      {
        name: t('common:all_devices'),
        value: DeviceTypeValue.AllIOSDevices,
      },
      {
        name: t('common:iphone'),
        value: DeviceTypeValue.IPhone,
      },
      {
        name: t('common:ipad'),
        value: DeviceTypeValue.IPad,
      },
    ]
  }, [t])

  return (
    <FormControl size="small" variant="outlined" sx={{ minWidth: 100 }}>
      <SelectMenu
        label={t('common:device')}
        options={options}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    </FormControl>
  )
}

export const DataTypeSelector: FC<{ value: DataTypeValue; onChange: (value: DataTypeValue) => void }> = ({ onChange, value }) => {
  const { t } = useTranslation()
  const options = useMemo(() => {
    return [
      {
        name: `${t('common:revenue_text')}`,
        value: DataTypeValue.Revenue,
      },
      {
        name: `${t('common:downloads_text')}`,
        value: DataTypeValue.Downloads,
      },
      {
        name: `${t('common:all_time_revenue_downloads_ratio')}`,
        value: DataTypeValue.RevenueDownloadsRatioAllTime,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 7 })}`,
        value: DataTypeValue.RevenueDownloadsRatio7Days,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 30 })}`,
        value: DataTypeValue.RevenueDownloadsRatio30Days,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 90 })}`,
        value: DataTypeValue.RevenueDownloadsRatio90Days,
      },
    ]
  }, [t])

  return (
    <FormControl size="small" variant="outlined" sx={{ minWidth: 310 }}>
      <SelectMenu
        label={t('common:data')}
        options={options}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        value={value}
      />
    </FormControl>
  )
}
