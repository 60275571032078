import { useMemo } from 'react'

import { Currency } from '../../../internal/types/Currency'
import { TrackedGame } from '../types/TrackedGame'

type trackedGamesCurrenciesHookParams = {
  gameIds: string[]
  allTrackedGames: TrackedGame[] | undefined
}

export const useTrackedGamesCurrencies = ({ gameIds, allTrackedGames }: trackedGamesCurrenciesHookParams) => {
  const selectedGames = useMemo(
    () =>
      gameIds.map((gameId) => {
        return allTrackedGames?.find((trackedGame) => trackedGame.game.id === gameId)
      }),
    [allTrackedGames, gameIds]
  )
  const currencies = useMemo(
    () =>
      selectedGames.reduce((acc, game) => {
        if (game?.game) {
          if (!acc[game.game.id]) {
            acc[game.game.id] = { currencies: [] }
          }
          game.game.currencies.forEach((currency) => {
            acc[game.game.id].currencies.push(currency)
          })
          return acc
        }
        return acc
      }, {} as { [id: string]: { currencies: Currency[] } }),
    [selectedGames]
  )
  return currencies
}
