import { useEffect } from 'react'

export const useIntersectionObserverEntry = (
  ref: React.MutableRefObject<null>,
  options: IntersectionObserverInit | undefined,
  callback: (e: IntersectionObserverEntry) => void
) => {
  useEffect(() => {
    const refCurrent = ref.current
    const observer = new IntersectionObserver(([e]: IntersectionObserverEntry[]) => callback(e), options)
    if (ref.current) observer.observe(ref.current)

    return () => {
      if (refCurrent) observer.unobserve(refCurrent)
    }
  }, [ref, options, callback])
}
