import { setDefaultOptions } from 'date-fns'
import { enUS, ja, zhCN } from 'date-fns/locale'
import i18next from 'i18next'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/ja'
import 'moment/locale/zh-cn'

import { UserLanguage } from '../features/account/types/User'
import { isNil } from '../helpers/isNil'
import { LanguageMap } from '../types/LanguageMap'
import { VersionTag } from '../types/VersionTag'
import { fetchWithTokenUpdate } from './FetchService'

class LanguageService {
  defaultLanguage: UserLanguage = 'en'
  languageMaps: { main: any; fallback: any }
  languageCodeMap: LanguageCodeMap = {
    ab: 'Abkhaz',
    aa: 'Afar',
    af: 'Afrikaans',
    ak: 'Akan',
    sq: 'Albanian',
    am: 'Amharic',
    ar: 'Arabic',
    an: 'Aragonese',
    hy: 'Armenian',
    as: 'Assamese',
    av: 'Avaric',
    ae: 'Avestan',
    ay: 'Aymara',
    az: 'Azerbaijani',
    bm: 'Bambara',
    ba: 'Bashkir',
    eu: 'Basque',
    be: 'Belarusian',
    bn: 'Bengali',
    bh: 'Bihari',
    bi: 'Bislama',
    bs: 'Bosnian',
    br: 'Breton',
    bg: 'Bulgarian',
    my: 'Burmese',
    ca: 'Catalan',
    ch: 'Chamorro',
    ce: 'Chechen',
    ny: 'Chichewa',
    zh: 'Chinese',
    cv: 'Chuvash',
    kw: 'Cornish',
    co: 'Corsican',
    cr: 'Cree',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    dv: 'Divehi',
    nl: 'Dutch',
    en: 'English',
    eo: 'Esperanto',
    et: 'Estonian',
    ee: 'Ewe',
    fo: 'Faroese',
    fj: 'Fijian',
    fi: 'Finnish',
    fr: 'French',
    ff: 'Fula',
    gl: 'Galician',
    ka: 'Georgian',
    de: 'German',
    el: 'Greek, Modern',
    gn: 'Guaraní',
    gu: 'Gujarati',
    ht: 'Haitian',
    ha: 'Hausa',
    he: 'Hebrew (modern)',
    hz: 'Herero',
    hi: 'Hindi',
    ho: 'Hiri Motu',
    hu: 'Hungarian',
    ia: 'Interlingua',
    id: 'Indonesian',
    ie: 'Interlingue',
    ga: 'Irish',
    ig: 'Igbo',
    ik: 'Inupiaq',
    io: 'Ido',
    is: 'Icelandic',
    it: 'Italian',
    iu: 'Inuktitut',
    ja: 'Japanese',
    jv: 'Javanese',
    kl: 'Kalaallisut, Greenlandic',
    kn: 'Kannada',
    kr: 'Kanuri',
    ks: 'Kashmiri',
    kk: 'Kazakh',
    km: 'Khmer',
    ki: 'Kikuyu, Gikuyu',
    rw: 'Kinyarwanda',
    ky: 'Kirghiz, Kyrgyz',
    kv: 'Komi',
    kg: 'Kongo',
    ko: 'Korean',
    ku: 'Kurdish',
    kj: 'Kwanyama, Kuanyama',
    la: 'Latin',
    lb: 'Luxembourgish, Letzeburgesch',
    lg: 'Luganda',
    li: 'Limburgish, Limburgan, Limburger',
    ln: 'Lingala',
    lo: 'Lao',
    lt: 'Lithuanian',
    lu: 'Luba-Katanga',
    lv: 'Latvian',
    gv: 'Manx',
    mk: 'Macedonian',
    mg: 'Malagasy',
    ms: 'Malay',
    ml: 'Malayalam',
    mt: 'Maltese',
    mi: 'Māori',
    mr: 'Marathi (Marāṭhī)',
    mh: 'Marshallese',
    mn: 'Mongolian',
    na: 'Nauru',
    nv: 'Navajo, Navaho',
    nb: 'Norwegian Bokmål',
    nd: 'North Ndebele',
    ne: 'Nepali',
    ng: 'Ndonga',
    nn: 'Norwegian Nynorsk',
    no: 'Norwegian',
    ii: 'Nuosu',
    nr: 'South Ndebele',
    oc: 'Occitan',
    oj: 'Ojibwe, Ojibwa',
    cu: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    om: 'Oromo',
    or: 'Oriya',
    os: 'Ossetian, Ossetic',
    pa: 'Panjabi, Punjabi',
    pi: 'Pāli',
    fa: 'Persian',
    pl: 'Polish',
    ps: 'Pashto, Pushto',
    pt: 'Portuguese',
    qu: 'Quechua',
    rm: 'Romansh',
    rn: 'Kirundi',
    ro: 'Romanian, Moldavian, Moldovan',
    ru: 'Russian',
    sa: 'Sanskrit (Saṁskṛta)',
    sc: 'Sardinian',
    sd: 'Sindhi',
    se: 'Northern Sami',
    sm: 'Samoan',
    sg: 'Sango',
    sr: 'Serbian',
    gd: 'Scottish Gaelic',
    sn: 'Shona',
    si: 'Sinhala, Sinhalese',
    sk: 'Slovak',
    sl: 'Slovene',
    so: 'Somali',
    st: 'Southern Sotho',
    es: 'Spanish',
    su: 'Sundanese',
    sw: 'Swahili',
    ss: 'Swati',
    sv: 'Swedish',
    ta: 'Tamil',
    te: 'Telugu',
    tg: 'Tajik',
    th: 'Thai',
    ti: 'Tigrinya',
    bo: 'Tibetan Standard, Tibetan, Central',
    tk: 'Turkmen',
    tl: 'Tagalog',
    tn: 'Tswana',
    to: 'Tonga (Tonga Islands)',
    tr: 'Turkish',
    ts: 'Tsonga',
    tt: 'Tatar',
    tw: 'Twi',
    ty: 'Tahitian',
    ug: 'Uighur, Uyghur',
    uk: 'Ukrainian',
    ur: 'Urdu',
    uz: 'Uzbek',
    ve: 'Venda',
    vi: 'Vietnamese',
    vo: 'Volapük',
    wa: 'Walloon',
    cy: 'Welsh',
    wo: 'Wolof',
    fy: 'Western Frisian',
    xh: 'Xhosa',
    yi: 'Yiddish',
    yo: 'Yoruba',
    za: 'Zhuang, Chuang',
  }

  constructor() {
    this.languageMaps = { main: null, fallback: null }
  }

  loadLanguageMap(language: string) {
    const URL = window.GR_API_URLS.API_URL_CORE + `/language/${language}`
    const SETTINGS = {
      method: 'GET',
    }

    return fetchWithTokenUpdate(URL, SETTINGS)
      .then((response) => response.json())
      .then((data: LanguageMap) => {
        // eslint-disable-next-line no-console
        console.log(`Language Map Loaded / ${language}`, data)
        return data
      })
  }

  loadLanguageMaps(mainLanguage: string) {
    return new Promise((resolve) => {
      this.loadLanguageMap(mainLanguage).then((languageMap) => {
        this.languageMaps.main = languageMap

        if (mainLanguage === 'en') {
          resolve(null)
        } else {
          this.loadLanguageMap('en').then((fallbackLanguageMap) => {
            this.languageMaps.fallback = fallbackLanguageMap
            resolve(null)
          })
        }
      })
    })
  }

  getTranslation(localizationParentKey: string, objectKey: string, field?: string, forceCorrectField?: boolean): string {
    let translation: any = null

    // Check in the main language map first
    if (this.languageMaps.main && this.languageMaps.main[localizationParentKey] && this.languageMaps.main[localizationParentKey][objectKey]) {
      translation = this.languageMaps.main[localizationParentKey][objectKey]
    }

    // If the main translation is null or an empty string, check in the fallback language map
    if (
      (!translation || translation?.name === '') &&
      this.languageMaps.fallback &&
      this.languageMaps.fallback[localizationParentKey] &&
      this.languageMaps.fallback[localizationParentKey][objectKey]
    ) {
      translation = this.languageMaps.fallback[localizationParentKey][objectKey]
    }

    // If no translation found, return an empty string
    if (!translation) {
      return ''
    }

    // Handle specific field translations
    if (!!field && (isNil(translation[field]) || translation[field] === '') && !!forceCorrectField) {
      return ''
    } else if (!!field && !isNil(translation[field]) && translation[field] !== '') {
      return translation[field]
    } else if (translation.name === '' && !isNil(translation.alternateName) && translation.alternateName !== '') {
      return translation.alternateName
    } else if (!isNil(translation.name) && translation.name !== '') {
      return translation.name
    } else if (!isNil(translation.label) && translation.label !== '') {
      return translation.label
    } else {
      return translation
    }
  }

  getLanguagesArray(): { id: UserLanguage; name: string }[] {
    return [
      {
        id: 'en',
        name: 'English',
      },
      {
        id: 'ja',
        name: '日本語',
      },
      {
        id: 'zh',
        name: '中文',
      },
    ]
  }

  getVersionTags(): VersionTag[] {
    const tags: { [versionId: string]: { name: string } } = this.languageMaps.main.versionTags || this.languageMaps.fallback.versionTags
    return (
      Object.entries(tags).map(([key, value]) => {
        return {
          id: key,
          name: value.name,
        } as VersionTag
      }) || []
    )
  }

  updateUserLanguage(language: UserLanguage) {
    let localeString

    switch (language) {
      case 'zh':
        localeString = 'zh-cn'
        break
      case 'ja':
        localeString = 'ja'
        break
      case 'en':
      default:
        localeString = 'en-us'
        break
    }

    const map = {
      en: enUS,
      ja: ja,
      zh: zhCN,
    }

    // react-timeline-calendar uses momentjs for date localization
    moment.locale(localeString)
    // date-fns default locale
    setDefaultOptions({ locale: map[language], weekStartsOn: 1 })
    // i18next language setting
    i18next.changeLanguage(language)
  }

  getSupportedLanguages() {
    return this.getLanguagesArray().map((language) => language.id)
  }
}

const languageService = new LanguageService()

export default languageService

type LanguageCodeMap = {
  [languageCode: string]: string
}
