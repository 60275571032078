import React, { MouseEventHandler, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Delete, ModeComment, QuestionAnswer } from '@mui/icons-material'
import { Badge, Box, Button, Card, CardActions, CardMedia, Divider, Grid, IconButton, TooltipProps, Typography } from '@mui/material'

import { ConceptTag, Screenshot } from '../../../../api/core'
import AddToCollectionButton from '../../../../components/AddToCollectionButton/AddToCollectionButton'
import { AnalystCommentDialog } from '../../../../components/AnalystCommentDialog/AnalystCommentDialog'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import NotAvailableIcon from '../../../../components/NotAvailableIcon/NotAvailableIcon'
import { formatDate } from '../../../../helpers/date'
import { useAnalystReviewsAndCommentsAccessCheck } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/useCurrentUserLanguage'
import { isConceptTag, isEnrichedFeature } from '../../../feature/helpers/helpers'
import GameIcon from '../../../game/components/GameIcon/GameIcon'
import { FeatureGame } from '../../../game/types/Game'
import { NewsEntryScreenshotFeature } from '../../../news/types/UnifiedNewsEntry'
import { EnrichedFeature } from '../../types/ImplementationExamples'
import { screenshotsByConceptTagFilter, screenshotsByFeatureFilter } from '../../util/filters'
import { getCombinedScreenshots } from '../../util/screenshotHelpers'
import './ScreenshotPreview.scss'

interface ScreenshotPreviewProps {
  game: FeatureGame
  classifier?: EnrichedFeature | ConceptTag // Feature or ConceptTag used to filter screenshots
  onRemoveFromCollection?: (screenshotId: string) => void
  onSelect: (screenshots: Screenshot[]) => void
  latestVersion?: string
  hideAddToCollection?: boolean
  relatedFeatureScreenshots?: NewsEntryScreenshotFeature[]
}

const ScreenshotPreview: React.FC<ScreenshotPreviewProps> = ({
  classifier,
  game,
  onRemoveFromCollection,
  onSelect,
  hideAddToCollection,
  relatedFeatureScreenshots,
}) => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  const [analystCommentDialog, setAnalystCommentDialog] = useState(false)
  const hasAnalystReviewsAndCommentsAccessRights = useAnalystReviewsAndCommentsAccessCheck()
  const latestVersion = useMemo(
    () =>
      getCombinedScreenshots(game)
        .filter((item) => {
          return !!item.gameVersion
        })
        .sort((a, b) => {
          if (a.versionReleaseDate && b.versionReleaseDate) {
            return b.versionReleaseDate - a.versionReleaseDate
          }

          return -1
        })[0],
    [game]
  )

  const { artist, icons, resolvedName: name, featureScreenshots, conceptScreenshots } = game

  let renderedScreenshots: Screenshot[] = []

  if (isConceptTag(classifier)) {
    // concept tag
    renderedScreenshots = classifier ? conceptScreenshots?.filter(screenshotsByConceptTagFilter(classifier)) : conceptScreenshots
  } else if (isEnrichedFeature(classifier)) {
    // feature
    renderedScreenshots = classifier ? featureScreenshots?.filter(screenshotsByFeatureFilter(classifier)) : featureScreenshots
  } else {
    renderedScreenshots = getCombinedScreenshots(game)
  }

  const screenshotCount = renderedScreenshots?.length || 0
  if (screenshotCount === 0) {
    return null
  }
  // Let's pick the first one from array as an example for others
  const { gameVersion, url, versionReleaseDate } = renderedScreenshots[0]
  const screenshotId = renderedScreenshots[0].screenshotId || renderedScreenshots[0].id

  const onClickRemove: MouseEventHandler = (evt) => {
    evt.stopPropagation()
    onRemoveFromCollection && screenshotId && onRemoveFromCollection(screenshotId)
  }
  const comments = renderedScreenshots.filter(({ commentPublished }) => commentPublished).length
  const commentId = renderedScreenshots.filter(({ commentId }) => commentId)

  return (
    <Badge color="primary" badgeContent={screenshotCount > 1 ? screenshotCount : 0}>
      <Card className="preview">
        <CardMedia className="imageContainer" onClick={() => onSelect(renderedScreenshots)}>
          <LazyLoadImage src={url} alt="" />
          {(latestVersion !== undefined && !game.latestFeatureVersion && latestVersion.gameVersion === gameVersion) ||
          (game.latestFeatureVersion && game.latestFeatureVersion === gameVersion) ? (
            <Typography component="span" className="latest">
              {t('common:latest')}
            </Typography>
          ) : null}
          {screenshotCount === 1 && !hideAddToCollection && <AddToCollectionButton iconOnly={true} screenshotId={screenshotId} game={game} />}
          {onRemoveFromCollection && <Button className="remove" color="warning" variant="contained" startIcon={<Delete />} onClick={onClickRemove} />}
        </CardMedia>
        <CardActions disableSpacing>
          <Grid container spacing={1} alignItems="center" onClick={() => onSelect(renderedScreenshots)}>
            <Grid item xs={3}>
              <GameIcon gameName={name} size="normal" src={icons[0]} />
            </Grid>
            <Grid item xs={9}>
              <Typography variant="h3" color="primary">
                {name}
              </Typography>
              <Typography variant="body1" className="artist">
                {artist}
              </Typography>
              <Typography variant="body2" className="version" component={'span'}>
                {!gameVersion && <NotAvailableIcon tooltipContent={`${t('common:version')}: ${t('common:not_available_shorthand')}`} />}
                {gameVersion?.length && `${t('common:version')} ${gameVersion}`}
                {!!versionReleaseDate && ` - ${formatDate(versionReleaseDate, { language: userLanguage })}`}
              </Typography>
            </Grid>
          </Grid>
          {!!comments &&
            (comments > 1 ? (
              <QuestionAnswer className="commentIcon" />
            ) : (
              <>
                <GRTooltip content={t('reviews:tooltip_open_analyst_comment')} tooltipProps={{ placement: 'top' } as TooltipProps}>
                  <IconButton className="commentIcon" onClick={() => setAnalystCommentDialog(true)}>
                    <ModeComment />
                  </IconButton>
                </GRTooltip>
                <AnalystCommentDialog
                  commentId={commentId[0].commentId}
                  hasAccessRights={hasAnalystReviewsAndCommentsAccessRights}
                  gameName={game.resolvedName}
                  open={!!analystCommentDialog}
                  onClose={() => setAnalystCommentDialog(false)}
                  isScreenshotAnalysisComment
                />
              </>
            ))}
        </CardActions>
        {relatedFeatureScreenshots && !!relatedFeatureScreenshots.length && (
          <Box sx={{ px: 2, pt: 0, pb: 2 }} onClick={() => onSelect(renderedScreenshots)}>
            <Divider sx={{ mb: 1 }} />
            <Typography variant="h4" fontWeight={'bold'}>
              {t('feature:related_features')}:
            </Typography>
            {relatedFeatureScreenshots.map(({ featureName_translation, choiceName_translation }, index) => {
              return (
                <Box key={`${featureName_translation}-${choiceName_translation}-${index}`}>
                  <small>{`${featureName_translation}: `}</small>
                  <small style={{ fontWeight: 700 }}>{choiceName_translation}</small>
                </Box>
              )
            })}
          </Box>
        )}
      </Card>
      <Box></Box>
    </Badge>
  )
}

export default ScreenshotPreview
