import classNames from 'classnames'
import React, { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PushPin } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'

import { Game } from '../../features/game/types/Game'
import CollectionMenu from '../../features/implementation-examples/components/CollectionMenu/CollectionMenu'

export interface AddToCollectionButtonProps extends ButtonProps {
  iconOnly?: boolean
  screenshotId?: string
  game: Game
}

const AddToCollectionButton: React.FC<AddToCollectionButtonProps> = (props) => {
  const { t } = useTranslation()
  const [collectionAnchor, setCollectionAnchor] = useState<Element>()

  const onOpenCollectionSelector: MouseEventHandler = (evt) => {
    evt.stopPropagation()
    setCollectionAnchor(evt.currentTarget)
  }

  const onClose = () => {
    setCollectionAnchor(undefined)
  }

  if (!props.screenshotId) {
    return null
  }

  return (
    <>
      <Button
        className={classNames('AddToCollectionButton-root', props.className, { iconOnly: props.iconOnly })}
        color={props.color || 'primary'}
        onClick={onOpenCollectionSelector}
        startIcon={props.startIcon ?? <PushPin />}
        variant={props.variant || 'contained'}
      >
        {!props.iconOnly && (props.children || ' ' + t('implementations:label_add_to_collection'))}
      </Button>
      {collectionAnchor && <CollectionMenu anchorEl={collectionAnchor} onClose={onClose} screenshotId={props.screenshotId} game={props.game} />}
    </>
  )
}

export default AddToCollectionButton
