import React from 'react'

import { Box, Grid } from '@mui/material'

import { Game } from '../../types/Game'
import { GameKeyIndicatorType } from '../../types/GameKeyIndicatorType'
import GameKeyIndicator from '../GameKeyIndicator/GameKeyIndicator'
import './GameKeyIndicators.scss'

interface Props {
  game: Game
  marketIso: string
}

const GameKeyIndicators: React.FC<Props> = (props: Props) => {
  const marginBottom = 2.5

  return (
    <div className="GameKeyIndicators">
      <Grid container spacing={6} wrap="wrap">
        <Grid item xs={12} md={6}>
          <Box mb={marginBottom}>
            <GameKeyIndicator type={GameKeyIndicatorType.FeatureIndicators} game={props.game} marketIso={props.marketIso} />
          </Box>

          <Box mb={marginBottom}>
            <GameKeyIndicator type={GameKeyIndicatorType.DemographicsGender} game={props.game} marketIso={props.marketIso} />
          </Box>

          <GameKeyIndicator type={GameKeyIndicatorType.DemographicsAge} game={props.game} marketIso={props.marketIso} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Box mb={marginBottom}>
            <GameKeyIndicator type={GameKeyIndicatorType.SustainedRanks} game={props.game} marketIso={props.marketIso} />
          </Box>

          <Box mb={marginBottom}>
            <GameKeyIndicator type={GameKeyIndicatorType.Performance30Days} game={props.game} marketIso={props.marketIso} />
          </Box>

          <GameKeyIndicator type={GameKeyIndicatorType.Performance360Days} game={props.game} marketIso={props.marketIso} />
        </Grid>
      </Grid>
    </div>
  )
}

export default GameKeyIndicators
