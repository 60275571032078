import { useGetUserSettingsQuery } from '../../../api/combined'
import { useGetUnseenNewsCountQuery } from '../../../api/feed'
import { useCurrentMarket } from '../../markets/hooks/useCurrentMarket'
import { NewsEntryType } from '../types/UnifiedNewsEntry'

/**
 * Hook to get the last seen news timestamp
 * @returns last seen news timestamp
 */
export const useLastSeenNewsTimestamp = () => {
  const { data: userSettings, isFetching, isLoading, isSuccess, error } = useGetUserSettingsQuery()
  const lastSeenNewsTimestamp = userSettings?.lastSeenNews

  return {
    data: lastSeenNewsTimestamp,
    isFetching: isFetching,
    isLoading: isLoading,
    isSuccess: isSuccess,
    error: error,
  }
}

/**
 * Hook to get the last seen news count
 * @returns last seen news count
 */
export const useLastSeenNewsCount = () => {
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const types = Object.values(NewsEntryType)

  const {
    data: lastSeenNewsTimestamp,
    isFetching: isFetchingLastSeenNewsTimestamp,
    isLoading: isLoadingLastSeenNewsTimestamp,
    isSuccess: isSuccessLastSeenNewsTimestamp,
    error: lastSeenNewsTimestampError,
  } = useLastSeenNewsTimestamp()

  const {
    data: lastSeenNewsCount,
    isFetching: isFetchingLastSeenNewsCount,
    isLoading: isLoadingLastSeenNewsCount,
    isSuccess: isSuccessLastSeenNewsCount,
    error: lastSeenNewsCountError,
  } = useGetUnseenNewsCountQuery(
    {
      timestamp: lastSeenNewsTimestamp as number,
      marketIso: marketIso,
      types: types,
    },
    { skip: !lastSeenNewsTimestamp || !marketIso || !types }
  )

  return {
    data: lastSeenNewsCount as number | undefined,
    isLoading: isLoadingLastSeenNewsTimestamp || isLoadingLastSeenNewsCount,
    isFetching: isFetchingLastSeenNewsTimestamp || isFetchingLastSeenNewsCount,
    isSuccess: isSuccessLastSeenNewsTimestamp && isSuccessLastSeenNewsCount,
    error: lastSeenNewsTimestampError || lastSeenNewsCountError,
  }
}
