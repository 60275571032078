import { ColumnHeaderLabelWithFilter } from '../../../../components/GRTable/ColumnHeaderLabelWithFilter/ColumnHeaderLabelWithFilter'
import { GRTableColumn } from '../../../../components/GRTable/GRTable'
import { FormatOptions } from '../../../../types/FormatOptions'
import {
  AggregateValueColumnFilterName,
  EmptyAggregateValue,
  AggregateValueColumnFilterResult,
  AggregateValueColumnFilterValue,
  EMPTY_AGGREGATE_VALUE,
} from '../../types/ColumnFilter'
import { AggregateValueColumnFilter } from '../AggregateValueColumnFilter/AggregateValueColumnFilter'

/**
 * Helper component for rendering Update Impact table header labels with filters
 */
type AggregateValueColumnHeaderProps<RowType, CustomPropsType> = {
  name: AggregateValueColumnFilterName
  label: string
  column: GRTableColumn<RowType, CustomPropsType>
  percentChangeValues: readonly (number | EmptyAggregateValue)[]
  valueChangeValues: readonly (number | EmptyAggregateValue)[]
  onColumnFilterChange?: (value: AggregateValueColumnFilterResult) => void
  onSort?: (value: GRTableColumn<RowType, CustomPropsType>) => void
  valueFormatter: (value: number, options: FormatOptions) => string
  value?: AggregateValueColumnFilterValue
}

export const AggregateValueColumnHeader = <RowType extends object, CustomPropsType extends object>({
  name,
  label,
  column,
  value,
  percentChangeValues,
  valueChangeValues,
  onColumnFilterChange,
  onSort,
  valueFormatter,
}: AggregateValueColumnHeaderProps<RowType, CustomPropsType>) => {
  const { percentChange, valueChange } = value || {}
  const hasActiveFilters = !isEmptyOrUndefined(percentChange) || !isEmptyOrUndefined(valueChange)

  return (
    <ColumnHeaderLabelWithFilter label={label} column={column} onSort={onSort} active={hasActiveFilters} hideFilter={!onColumnFilterChange}>
      {onColumnFilterChange && (
        <AggregateValueColumnFilter
          name={name}
          initialValue={value}
          percentChangeValues={percentChangeValues}
          valueChangeValues={valueChangeValues}
          onChange={onColumnFilterChange}
          valueFormatter={valueFormatter}
        />
      )}
    </ColumnHeaderLabelWithFilter>
  )
}

const isEmptyOrUndefined = (value?: number | string) => {
  // eslint-disable-next-line eqeqeq
  return value == EMPTY_AGGREGATE_VALUE || value === undefined || value === null
}
