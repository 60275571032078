import { FC } from 'react'

import { useFullscreenMode } from '../../../hooks/useFullscreenMode'
import { FullscreenToggler } from '../../FullscreenToggler/FullscreenToggler'

/**
 * Button component for toggling fullscreen mode on/off
 */
export const FullscreenModeToggler: FC<{}> = () => {
  const [fullscreenMode, updateFullscreenMode, isFullscreenAvailable] = useFullscreenMode()

  const handleClick = () => {
    updateFullscreenMode(!fullscreenMode)
  }

  return isFullscreenAvailable ? <FullscreenToggler active={fullscreenMode} onClick={handleClick} /> : null
}
