import { format } from 'date-fns'
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Edit, PushPin } from '@mui/icons-material'
import { TableContainer, Card, Grid, Avatar, AvatarProps, Badge, Typography, Box, Button } from '@mui/material'

import { useUpdateUserSettingMutation } from '../../../../../api/combined'
import { GRTable, GRTableColumn, SortOrder } from '../../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { UserSettingKeys } from '../../../../../features/account/types/UserSettings'
import { GameIcon } from '../../../../../features/game'
import { TrackedGame } from '../../../../../features/live-events/types/TrackedGame'
import { displaySnackBar } from '../../../../../features/snackbar'
import { useAppDispatch } from '../../../../../hooks/storeHooks'
import { Analyst as TAnalyst } from '../../../../../types/Analyst'
import { TableSortValues } from '../../../../../types/TableSortValues'
import { ActionButton } from '../../../../components/ActionButton/ActionButton'
import { AnalystAvatar } from '../../../../components/AnalystAvatar/AnalystAvatar'
import { useInitials } from '../../../../hooks/useInitials'
import { ReviewStatusIndicator } from '../../../review/components/ReviewStatusIndicator/ReviewStatusIndicator'
import { useLiveEventsTrackingMarket } from '../../hooks/useLiveEventsTrackingMarket'
import { TrackedGameEventsLink } from '../TrackedGameEventsLink/TrackedGameEventsLink'

type TrackedGamesTableProps = {
  trackedGames?: TrackedGame[]
  isLoading?: boolean
  pinnedGamesData: string[] | undefined
  children?: ReactNode
}

export const TrackedGamesTable: FC<TrackedGamesTableProps> = ({ isLoading, trackedGames, pinnedGamesData, children }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const containerRef = useRef(null)
  const { currentMarketIso: marketIso } = useLiveEventsTrackingMarket()
  const [changeUserPinnedGames] = useUpdateUserSettingMutation()
  const handlePinnedGameUpdating = useCallback(
    (id: string) => {
      let filteredPinnedGames = []
      let dislpayMessage = ''
      if (pinnedGamesData?.includes(id)) {
        filteredPinnedGames = pinnedGamesData.filter((gameId) => gameId !== id)
        dislpayMessage = t('internal-live-events:game_unpinned')
      } else {
        filteredPinnedGames = pinnedGamesData ? [...pinnedGamesData, id] : [id]
        dislpayMessage = t('internal-live-events:game_pinned')
      }
      changeUserPinnedGames({
        settingKey: UserSettingKeys.liveeventstrackedPinnedGames,
        value: filteredPinnedGames,
      }).then(() => {
        dispatch(displaySnackBar({ message: t(dislpayMessage), severity: 'success', open: true }))
      })
    },
    [changeUserPinnedGames, dispatch, pinnedGamesData, t]
  )

  const trackedGamesTableColumns: GRTableColumn<TrackedGame, typeof customTableProps>[] = useMemo(
    () => [
      {
        headerCellProps: { sx: { minWidth: 80 } },
        labelAccessor: () => <Trans i18nKey="internal-common:analyst" />,
        accessor: ({ row }) => <AnalystAvatar analyst={row.analyst} />,
        sortable: true,
        sortAccessor: ({ row }) => {
          const name = row.analyst?.name
          const parts = name?.split(' ') || []
          return parts.map((part) => (part.length > 0 ? part[0] : '')).join('')
        },
      },
      {
        headerCellProps: { sx: { minWidth: 70 } },
        labelAccessor: () => <Trans i18nKey="internal-common:sustained_grossing_rank_abbr" />,
        accessor: ({ row }) => row.game.sranks[marketIso],
        sortable: true,
        sortOrder: SortOrder.ASC,
        sortAccessor: ({ row }) => {
          const srank = row.game.sranks[marketIso]
          return srank ? srank : TableSortValues.AlwaysLast
        },
      },
      {
        headerCellProps: { sx: { minWidth: 70 } },
        labelAccessor: () => <Trans i18nKey="internal-common:sustained_download_rank_abbr" />,
        accessor: ({ row }) => row.game.sdranks[marketIso],
        sortable: true,
        sortAccessor: ({ row }) => {
          const sdrank = row.game.sdranks[marketIso]
          return sdrank ? sdrank : TableSortValues.AlwaysLast
        },
      },
      {
        headerCellProps: { sx: { minWidth: 80 } },
        labelAccessor: () => <Trans i18nKey="internal-common:icon" />,
        accessor: ({ row: { game } }) => (
          <GameIcon src={game.getIcon() as string} gameName={game.resolvedName} size="small" gamePlatforms={game.platforms}></GameIcon>
        ),
      },
      {
        headerCellProps: { sx: { minWidth: 120 } },
        labelAccessor: () => <Trans i18nKey="internal-common:name" />,
        accessor: ({ row }) => <Box sx={{ fontWeight: 700 }}>{row.game.name}</Box>,
        sortable: true,
        sortAccessor: ({ row }) => row.game.name || '',
      },
      {
        headerCellProps: { sx: { minWidth: 120 } },
        labelAccessor: () => <Trans i18nKey="internal-common:publisher" />,
        accessor: ({ row }) => row.game.artist,
        sortable: true,
      },
      {
        headerCellProps: { sx: { minWidth: 70 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:tracking_started" />,
        accessor: ({ row }) => format(row.trackingStarted, 'dd.MM.yyyy'),
        sortable: true,
        sortAccessor: ({ row }) => row.trackingStarted,
      },
      {
        headerCellProps: { sx: { minWidth: 70 } },
        labelAccessor: () => <Trans i18nKey="internal-common:review" />,
        accessor: ({ row }) => <ReviewStatusIndicator game={row.game} />,
      },
      {
        headerCellProps: { sx: { minWidth: 70 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:days_missing" />,
        accessor: ({ row }) => row.daysMissing,
        sortable: true,
        sortAccessor: ({ row }) => row.daysMissing,
      },
      {
        headerCellProps: { sx: { minWidth: 70 } },
        labelAccessor: () => <Trans i18nKey="internal-common:published" />,
        accessor: ({ row }) => (row.published ? 'Yes' : ''),
        sortable: true,
        sortAccessor: ({ row }) => (row.published ? 2 : 1),
      },
      {
        headerCellProps: { sx: { minWidth: 100 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:actions" />,
        accessor: ({ row, customTableProps }) => (
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <ActionButton
                color={customTableProps?.pinnedGamesData?.includes(row.game.id) ? 'warning' : 'primary'}
                onClick={() => {
                  customTableProps?.handlePinnedGameUpdating(row.game.id)
                }}
              >
                <PushPin fontSize="small" />
              </ActionButton>
            </Grid>
            <Grid item>
              <Button
                component={TrackedGameEventsLink}
                color="primary"
                variant="contained"
                sx={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '0' }}
                disableElevation
                trackedGameId={row.game.id}
              >
                <Edit fontSize="small" />
              </Button>
            </Grid>
          </Grid>
        ),
      },
    ],
    [marketIso]
  )

  const customTableProps = useMemo(() => ({ handlePinnedGameUpdating, pinnedGamesData }), [handlePinnedGameUpdating, pinnedGamesData])

  const [columns, setColumns] = useState<GRTableColumn<TrackedGame, typeof customTableProps>[]>(trackedGamesTableColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<TrackedGame, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <TableContainer component={Card} ref={containerRef}>
      <GRTable
        columns={columns}
        customProps={customTableProps}
        isLoading={isLoading}
        onColumnsUpdated={handleColumnsUpdate}
        rowIdKey={(trackedGame) => trackedGame.game.id}
        rows={trackedGames || []}
        scroller={containerRef}
        striped
        gridlines
        noRowsLabel={t('internal-common:no_rows')}
      />
    </TableContainer>
  )
}

export const Analyst: FC<{ analyst?: TAnalyst; avatarProps?: AvatarProps }> = ({ analyst, avatarProps }) => {
  const analystInitials = useInitials(analyst?.name)
  return analyst ? (
    <GRTooltip content={analyst?.name}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.main,
              fontSize: 8,
              width: 20,
              height: 20,
              border: (theme) => `1px solid ${theme.palette.background.paper}`,
            }}
          >
            <Typography variant="body1" fontSize={8} letterSpacing={0.5}>
              {analystInitials}
            </Typography>
          </Avatar>
        }
      >
        <Avatar alt={analyst?.name} src={analyst?.picture} {...avatarProps} />
      </Badge>
    </GRTooltip>
  ) : null
}
