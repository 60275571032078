import { FC, useCallback } from 'react'

import { FormControl, SelectChangeEvent } from '@mui/material'

import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu'
import { SelectOption } from '../../types/ColumnFilter'

/**
 * Wrapper for Select component
 */
type ColumnFilterSelectProps = {
  label: string
  options: SelectOption[]
  onChange: (value: string) => void
  value?: string | number
}

export const ColumnFilterSelect: FC<ColumnFilterSelectProps> = ({ label, options = [], onChange, value }) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value)
    },
    [onChange]
  )

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <SelectMenu onChange={handleChange} options={options} label={label} value={value} />
    </FormControl>
  )
}
