import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { Magnify } from '../../../../components/Magnify/Magnify'
import { ChangeType } from '../../types/ChangeType'
import styles from './ChangeTypeIndicator.module.scss'

/**
 * Component for rendering an indicator for update change type
 */
type ChangeTypeIndicatorProps = {
  type: ChangeType
  tooltip: ReactNode
}

export const ChangeTypeIndicator: FC<ChangeTypeIndicatorProps> = ({ type, tooltip }) => {
  const classes = classNames(styles.root, styles[type])
  return (
    <GRTooltip content={tooltip}>
      <Magnify>
        <span className={classes}></span>
      </Magnify>
    </GRTooltip>
  )
}
