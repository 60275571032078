import React, { ReactNode, useMemo } from 'react'

import { FiberManualRecord, FiberManualRecordOutlined } from '@mui/icons-material'
import { Rating } from '@mui/material'
import { styled } from '@mui/material/styles'

import { GRTooltip } from '../GRTooltip/GRTooltip'
import './GRDotRating.scss'

interface Props {
  dotValue?: number
  maxDots: number
  tooltipContent?: ReactNode
  showZeroRating?: boolean
}

const minOpacity = 0.4

const GRDotRating: React.FC<Props> = ({ dotValue = 0, maxDots, tooltipContent, showZeroRating }) => {
  const adjustedOpacity = useMemo(() => {
    if (showZeroRating && dotValue === 0) {
      return minOpacity
    }
    if (dotValue) {
      const opacity = dotValue === maxDots ? 1 : 1 / ((maxDots / dotValue) * 0.9)
      return opacity > minOpacity ? opacity : minOpacity
    }
    return 0
  }, [dotValue, maxDots, showZeroRating])

  return (
    <div className="GRDotRating">
      <GRTooltip content={tooltipContent || ''}>
        <StyledRating
          style={{ opacity: adjustedOpacity }}
          value={dotValue}
          size="small"
          precision={1}
          max={maxDots}
          icon={<FiberManualRecord />}
          emptyIcon={<FiberManualRecordOutlined />}
          readOnly
        />
      </GRTooltip>
    </div>
  )
}

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#bd65cf',
  },
  '& .MuiRating-iconHover': {
    color: '#f1791a',
  },
})

export default GRDotRating
