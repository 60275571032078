import React, { ReactElement } from 'react'

import { ReactComponent as CollectingTreasureSvg } from '../../../assets/images/24px-collecting-treasure-01.svg'
import { ReactComponent as CompetingOthersSvg } from '../../../assets/images/24px-competing-against-others-01.svg'
import { ReactComponent as CompletingMilestonesSvg } from '../../../assets/images/24px-completing-milestones-01.svg'
import { ReactComponent as CustomizationDecorationSvg } from '../../../assets/images/24px-customisation-decoration-01.svg'
import { ReactComponent as DiscoveringNewWorldsSvg } from '../../../assets/images/24px-discovering-new-worlds-01.svg'
import { ReactComponent as ExcitementThrill } from '../../../assets/images/24px-excitement-thrill-01.svg'
import { ReactComponent as ImprovingSkills } from '../../../assets/images/24px-improving-skills-01.svg'
import { ReactComponent as ResourceOptimisation } from '../../../assets/images/24px-resource-optimisation-01.svg'
import { ReactComponent as RolePlaying } from '../../../assets/images/24px-role-playing-emotions-01.svg'
import { ReactComponent as StrategicPlanning } from '../../../assets/images/24px-strategic-planning-01.svg'
import { ReactComponent as ThinkingSolving } from '../../../assets/images/24px-thinking-solving-01.svg'
import { ReactComponent as WorkingWithOthers } from '../../../assets/images/24px-working-with-others-01.svg'
import { MotivationType } from '../../market-explorer/types/MotivationType'

type IconMap = {
  [key in MotivationType]: ReactElement
}

export const motivationIconMap: IconMap = {
  [MotivationType.ExplorationCollectingTreasure]: <CollectingTreasureSvg />,
  [MotivationType.SocialCompetingAgainstOthers]: <CompetingOthersSvg />,
  [MotivationType.MasteryCompletingMilestones]: <CompletingMilestonesSvg />,
  [MotivationType.ExpressionCustomizationAndDecoration]: <CustomizationDecorationSvg />,
  [MotivationType.ExplorationDiscoveringNewWorlds]: <DiscoveringNewWorldsSvg />,
  [MotivationType.EscapismExcitementAndThrill]: <ExcitementThrill />,
  [MotivationType.MasteryImprovingSkills]: <ImprovingSkills />,
  [MotivationType.ManagementResourceOptimization]: <ResourceOptimisation />,
  [MotivationType.ExpressionRolePlayingAndEmotions]: <RolePlaying />,
  [MotivationType.ManagementStrategicPlanning]: <StrategicPlanning />,
  [MotivationType.EscapismThinkingAndSolving]: <ThinkingSolving />,
  [MotivationType.SocialWorkingWithOthers]: <WorkingWithOthers />,
}

interface MotivationIconProps {
  motivation: MotivationType
}

export const MotivationIcon: React.FC<MotivationIconProps> = ({ motivation }) => {
  return motivationIconMap[motivation] as ReactElement
}
