import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetSingleUnifiedNewsEntryQuery, useGetUnifiedNewsEntriesQuery } from '../../../api/feed'
import { useGameUpdateImpactsAccessCheck } from '../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import { SubgenreMap } from '../../account/types/UserSettings'
import featureService from '../../feature/services/FeatureService'
import { useDateTimeFormatter } from '../../live-events/hooks/useDateTimeFormatter'
import { NewsListPagination } from '../types/NewsListPagination'
import { NewsEntryFeature, NewsEntryScreenshot, NewsEntryScreenshotFeature, NewsEntryType, UnifiedNewsEntry } from '../types/UnifiedNewsEntry'

type UnifiedNewsEntriesHookParams = {
  marketIso: string
  language: string
  selectedSubgenresMap: SubgenreMap
  newsfeedTypes: Array<string>
  limit: number
  pagination: NewsListPagination
}
export function useUnifiedNewsEntries({ marketIso, language, selectedSubgenresMap, newsfeedTypes, limit, pagination }: UnifiedNewsEntriesHookParams) {
  // use separate state to collect all paged news entries as they are loaded
  const [allNewsEntries, setAllNewsEntries] = useState<UnifiedNewsEntry[]>([])
  const conventionalSubgenres = Object.keys(selectedSubgenresMap)
  const {
    data: { newsData: unifiedNewsEntries, lastEvents: unifiedNewsEntriesLastEvents } = {},
    isLoading: unifiedNewsEntriesLoading,
    isFetching: unifiedNewsEntriesFetching,
    error: unifiedNewsEntriesError,
  } = useGetUnifiedNewsEntriesQuery({ marketIso, language, conventionalSubgenres, types: newsfeedTypes, limit, pagination })

  useEffect(() => {
    if (pagination.stickyEditorials === 0 && pagination.other === 0) {
      setAllNewsEntries([])
    }
  }, [pagination])

  useEffect(() => {
    if (unifiedNewsEntries) {
      setAllNewsEntries((previousValue) => {
        if (pagination.stickyEditorials === 0 && pagination.other === 0) {
          return [...unifiedNewsEntries]
        } else {
          // TODO: This is done so Show More button can be hided on Daily Insights after no new News Entries are found, this can be removed/changed when the backend supports pagination properly (now it seems to return final entry multiple times for some reason for some News Entries)
          const newsEntryIds: { [entryId: string]: boolean } = {}
          const uniqueNewsEntries = [...previousValue, ...unifiedNewsEntries].filter((entry) => {
            if (entry.feedEntry) {
              if (newsEntryIds[entry.feedEntry.id]) {
                return false
              }

              newsEntryIds[entry.feedEntry.id] = true
              return true
            } else {
              return true
            }
          })
          return uniqueNewsEntries
        }
      })
    }
    // do not run the efeect when pagination changes, only run when its effect (returned data) changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unifiedNewsEntries])

  return {
    unifiedNewsEntries: allNewsEntries,
    unifiedNewsEntriesLoading: unifiedNewsEntriesLoading || unifiedNewsEntriesFetching,
    unifiedNewsEntriesError,
    unifiedNewsEntriesLastEvents,
  }
}

export function useUnifiedNewsEntry(mainMarketIso: string, newsEntryId: string, language: string) {
  const {
    data: unifiedNewsEntry,
    isLoading: unifiedNewsEntryLoading,
    isFetching: unifiedNewsEntryFetching,
    error: unifiedNewsEntryError,
  } = useGetSingleUnifiedNewsEntryQuery({ id: newsEntryId, language })

  return { unifiedNewsEntry, unifiedNewsEntryLoading: unifiedNewsEntryLoading || unifiedNewsEntryFetching, unifiedNewsEntryError }
}

type NewsCardScreenshotsHookParams = {
  newsEntry: UnifiedNewsEntry
}
export const useNewsCardScreenshots = ({ newsEntry }: NewsCardScreenshotsHookParams) => {
  const entryType = newsEntry.feedEntry.type
  const gameUpdateScreenshots: NewsEntryScreenshot[] = newsEntry.feedEntry.entryData.screenshots || []
  const liveEventScreenshots = newsEntry.feedEntry.entryData.screenshotUrls || []
  let versionScreenshots: string[] = []

  if (gameUpdateScreenshots.length === 0 && (entryType === NewsEntryType.MajorGameRelease || entryType === NewsEntryType.SoftLaunchNonAnalyzed)) {
    versionScreenshots = newsEntry.versionInfo?.screenshots.map((url: string) => url) || []
  }

  return { gameUpdateScreenshots, versionScreenshots, liveEventScreenshots }
}

type NewsCardFeaturesHookParams = {
  newsEntry: UnifiedNewsEntry
}
export const useNewsCardFeatures = ({ newsEntry }: NewsCardFeaturesHookParams): [NewsEntryFeature[], NewsEntryScreenshotFeature[]] => {
  const entryType = newsEntry.feedEntry.type
  let changedFeatures: Array<NewsEntryFeature> = []
  let updatedFeatures: Array<NewsEntryScreenshotFeature> = []

  if (entryType === NewsEntryType.GpsUpdate || entryType === NewsEntryType.PerformanceChangeRevenue || entryType === NewsEntryType.PerformanceChangeDownload) {
    if (newsEntry.feedEntry.entryData.features && newsEntry.feedEntry.entryData.features.length) {
      changedFeatures = featureService.getFilteredFeaturesForNewsEntryFeatures(newsEntry.feedEntry.entryData.features)
    }

    if (newsEntry.versionInfo && newsEntry.feedEntry.entryData.screenshots && newsEntry.feedEntry.entryData.screenshots.length > 0) {
      const filterFeatures = featureService.getUpdatedFeaturesFromNewsEntryScreenshots(changedFeatures, newsEntry.feedEntry.entryData.screenshots)
      updatedFeatures = filterFeatures.filter((filterFeature) => {
        return !changedFeatures.find((changedFeature) => changedFeature.featureName === filterFeature.featureName_translation)
      })
    }
  }

  return [changedFeatures, updatedFeatures]
}

type NewsCardTitleHookParams = {
  newsEntry: UnifiedNewsEntry
}
export const useNewsCardTitle = ({ newsEntry }: NewsCardTitleHookParams) => {
  const { t } = useTranslation()
  const dateFormatter = useDateTimeFormatter()
  const currentUserLanguage = useCurrentUserLanguage()
  const entryType = newsEntry.feedEntry.type
  const entryData = newsEntry.feedEntry.entryData
  const gpsChange = Math.round((entryData.gpsChange || 0) * 10) / 10
  const rankValue = useMemo(() => {
    const rank = entryData.rank
    if (rank <= 10) {
      return 10
    } else if (rank <= 20) {
      return 20
    } else if (rank <= 50) {
      return 50
    } else if (rank <= 100) {
      return 100
    } else if (rank <= 200) {
      return 200
    } else if (rank > 200) {
      return 500
    }

    return 500
  }, [entryData.rank])

  let title = newsEntry.feedEntry.title || newsEntry.feedEntry.titles[currentUserLanguage]
  let subtitle: string | undefined = newsEntry.feedEntry.subtitle || newsEntry.feedEntry.subtitles[currentUserLanguage]

  switch (entryType) {
    case NewsEntryType.GpsNew:
    case NewsEntryType.GpsOutside:
      if (entryData.organizationId) {
        title = t('news-titles:first_analysis_own')
      } else if (rankValue > 200) {
        title = t('news-titles:first_analysis_out200')
      } else {
        title = t('news-titles:first_analysis_top100', { topListNumber: rankValue })
      }

      break

    case NewsEntryType.GpsUpdate:
      if (entryData.organizationId) {
        title = t('news-titles:updated_analysis_own')
      } else {
        title = t('news-titles:updated_analysis_public')
      }
      subtitle = t('news-titles:updated_analysis_subtitle', { change: gpsChange })
      break

    case NewsEntryType.GpsSystem:
      title = t('news-titles:updated_analysis_system', { gpsBefore: Math.round(entryData.gpsBefore || 0), gps: Math.round(entryData.gps || 0) })
      subtitle = t('news-titles:updated_analysis_system_subtitle')
      break

    case NewsEntryType.EntryTop10:
    case NewsEntryType.EntryTop20:
    case NewsEntryType.EntryTop50:
    case NewsEntryType.EntryTop100:
    case NewsEntryType.EntryTop200:
      title = t('news-titles:first_appearance_top_grossing', { topListNumber: rankValue })
      break
    case NewsEntryType.LiveEventNewEventType:
    case NewsEntryType.LiveEventAnalystNotes:
      title = t(`news-titles:${entryType}`)
      break
  }

  if (entryType === NewsEntryType.MajorGameRelease || entryType === NewsEntryType.SoftLaunchNonAnalyzed) {
    if (newsEntry.versionInfo) {
      subtitle = t('common:released_with_date_label', {
        dateString: dateFormatter.format(newsEntry.versionInfo.releaseDate),
      })
    } else {
      subtitle = t('common:released_with_date_label', {
        dateString: dateFormatter.format(newsEntry.feedEntry.publishedAt),
      })
    }
  }

  if (entryData.gpsChange === 0) {
    subtitle = undefined
  }

  return [title, subtitle]
}

export const useRevenueAndDownloadsDataAccessCheck = (entryType: NewsEntryType) => {
  const hasAccessToGameUpdateImpacts = useGameUpdateImpactsAccessCheck()
  return (entryType === NewsEntryType.PerformanceChangeRevenue || entryType === NewsEntryType.PerformanceChangeDownload) && !hasAccessToGameUpdateImpacts
}
