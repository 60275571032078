// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '.'
import type { Motivation, MotivationStatsWithGameScore } from '../features/market-explorer/types/MarketExplorerSegmentData'

const REDUCER_PATH = 'connectServerApi'

export const connectServerApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_CONNECT_SERVER,
    prepareHeaders: prepareDefaultHeaders,
  }),
  endpoints: (builder) => ({
    getFeatureAndCreativeMetadataTagsForGameAppId: builder.query<FeatureAndCreativeMetadataTags, number | undefined>({
      query: (appId) => `game/metadata/tags/${appId}`,
    }),
    getGameVisualAnalysis: builder.query<VisualAnalysis[], string>({
      query: (gameId) => `visualanalysis/game/${gameId}`,
    }),
    getGenreAnalysis: builder.query<GenreAnalysis[], { genreIds: number[]; marketIso: string }>({
      query: ({ genreIds, marketIso }) => `genreanalyses/${genreIds.join('%2C')}?marketIso=${marketIso}`,
      transformResponse(response: GenreAnalysis | GenreAnalysis[]): GenreAnalysis[] {
        return !Array.isArray(response) ? [response] : response
      },
    }),
    getGamesByVisualAttributes: builder.query<VisualAnalysis[], GamesByAttrsParams>({
      query: (body) => ({
        url: `visualanalysis/list?enableCache=true`,
        method: 'POST',
        body,
      }),
    }),
    getAllVisualAttributes: builder.query<AttributeModelNames, void>({
      query: () => ({
        url: `visualanalysis/list/attributes`,
        method: 'GET',
      }),
      transformResponse(response: { attributes: AttributeModelNames }): AttributeModelNames {
        return response.attributes
      },
    }),
    getMotivationStats: builder.query<MotivationStatsWithGameScore, motivationStatQueryParams>({
      query: ({ appId: gameId, conventionalSubgenreId }) => `/motivation/stats/${gameId}/${conventionalSubgenreId}`,
    }),
    getMultipleMotivationStats: builder.query<MotivationStatsWithGameScore[], { queryParams: motivationStatQueryParams[] }>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(
          args.queryParams.map(async ({ appId: gameId, conventionalSubgenreId }) =>
            fetchWithBQ({ url: `/motivation/stats/${gameId}/${conventionalSubgenreId}` })
          )
        )
        const data = responses.map((res) => res.data as MotivationStatsWithGameScore)
        const errorResponse = responses.find((r) => r.error)

        return errorResponse?.error ? { error: errorResponse.error } : { data }
      },
    }),
    /**
     * @deprecated This function is using deprecated algorithm in BE. Use useGetMotivationProfile instead.
     */
    getMotivationAnalysis: builder.query<Motivation, { appId: number }>({
      query: ({ appId }) => `/motivation/analysis/${appId}`,
    }),
    /**
     * @deprecated This function is using deprecated algorithm in BE. Use useGetMultipleMotivationProfile instead.
     */
    getMultipleMotivationAnalysis: builder.query<Motivation[], { appIds: Number[] }>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
        const responses = await Promise.all(args.appIds.map(async (appId) => fetchWithBQ({ url: `/motivation/analysis/${appId}` })))
        const data = responses.map((res) => res.data as Motivation)
        const errorResponse = responses.find((r) => r.error)

        return errorResponse?.error ? { error: errorResponse.error } : { data }
      },
    }),
  }),
})

export type GamesByAttrsField = {
  attributes?: string[]
  colors?: string[]
  colorGroups?: string[]
  model: VisualAnalysisModel
}

export type GamesByAttrsParams = {
  appGenre: string
  fields: GamesByAttrsField[]
  filterFields: boolean
  market: string
  rankType: 'grossing' | 'free'
}

type FeatureAndCreativeMetadataTags = {
  visualTags: {
    [name: string]: number
  }
  featureTags: {
    [name: string]: number
  }
}

export type AttrValues = { [key: string]: number }

export type VisualAnalysis = {
  appGenres: number[]
  appId: number
  attributes?: AttrValues
  colorGroups?: AttrValues
  colors?: AttrValues
  conventionalCategory: string
  conventionalCategoryId: string
  conventionalGenre: string
  conventionalGenreId: string
  conventionalSubgenre: string
  conventionalSubgenreId: string
  gameId: string
  gameNames: { [lang: string]: string }
  highestAttribute?: string
  highestColor?: string
  highestColorGroup?: string
  hues?: AttrValues
  id: string
  latest: boolean
  model: VisualAnalysisModel
  saturations?: AttrValues
  smallUrl: string
  type: string
  url: string
  version: string | null
}

export type VisualAnalysisModel = 'color' | 'colorGroups' | 'genre' | 'icon' | 'style'

export type GenreValues = {
  [key in VisualAnalysisModel]: AttrValues | null
}

export type AttributeModelNames = {
  [key in VisualAnalysisModel]: string[]
}

export type GenreAnalysis = {
  appGenre: number
  id: string
  market: string
} & {
  [genreTopOrOut: string]: GenreValues
}

export type motivationStatQueryParams = {
  appId: number
  conventionalSubgenreId: string
}

// Export hooks to use in function components
export const {
  useGetFeatureAndCreativeMetadataTagsForGameAppIdQuery,
  useGetGameVisualAnalysisQuery,
  useGetGenreAnalysisQuery,
  useGetGamesByVisualAttributesQuery,
  useGetAllVisualAttributesQuery,
  useGetMotivationStatsQuery,
  useGetMultipleMotivationStatsQuery,
  useGetMotivationAnalysisQuery,
  useGetMultipleMotivationAnalysisQuery,
} = connectServerApi
