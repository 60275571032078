import { FC, ReactNode } from 'react'

import { Button, ButtonProps, CircularProgress } from '@mui/material'

type CounterButtonProps = ButtonProps & {
  label?: string
  count?: number
  onClick: () => void
  loading?: boolean
  children?: ReactNode
}

export const CounterButton: FC<CounterButtonProps> = ({ label, count, onClick, loading, children, ...buttonProps }) => {
  return (
    <Button variant="contained" color="primary" size="large" onClick={onClick} {...buttonProps}>
      {label}
      {!!count && count > 0 && <span className="amount">{count}</span>}
      {loading && <CircularProgress size={16} sx={{ color: 'white', marginLeft: '.5em' }} />}
    </Button>
  )
}
