import React, { CSSProperties } from 'react'

import { Box } from '@mui/material'

interface ColorSquareProps {
  color: string
  style?: CSSProperties
}

const ColorSquare: React.FC<ColorSquareProps> = ({ color, style }) => {
  const backgroundColor = color === 'grey' ? '#ccc' : color
  return (
    <Box
      className="ColorSquare-root"
      sx={{
        backgroundColor,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'white',
        borderRadius: '2px',
        boxShadow: '0 0 4px rgba(0, 0, 0, .2)',
        display: 'inline-block',
        height: 12,
        mr: 1,
        width: 12,
      }}
      style={{ ...style }}
    />
  )
}

export default ColorSquare
