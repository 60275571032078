import React from 'react'

import { Box, Chip, Paper, Stack, Typography } from '@mui/material'

import { useResolvedLiveEventsSecondaryTagsWithGroups } from '../../hooks/liveEventsSecondaryTaxonomy'
import './LiveEventsSecondaryTaxonomyTagList.scss'

interface LiveEventsSecondaryTaxonomyTagListProps {
  tags: string[]
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
}

const LiveEventsSecondaryTaxonomyTagList: React.FC<LiveEventsSecondaryTaxonomyTagListProps> = ({ tags, justifyContent }) => {
  // sort groups on tags.length from low to high
  const groupsWithTags = useResolvedLiveEventsSecondaryTagsWithGroups(tags).sort((a, b) => a.tags.length - b.tags.length)

  return (
    <Box display="flex" flexDirection="row" className="LiveEventsSecondaryTaxonomyTagList" justifyContent={justifyContent || 'center'}>
      {groupsWithTags.map((group) => (
        <Stack key={group.groupId} direction="column">
          <Typography className="groupName" marginRight={2}>
            {group.groupName}
          </Typography>
          <Paper className="groupContainer" elevation={0}>
            {group.tags.map((tag) => (
              <Chip
                key={tag.id}
                className="tagChip"
                label={tag.name}
                sx={{
                  ...(group.tags.length === 1 && { width: '100%' }),
                }}
              />
            ))}
          </Paper>
        </Stack>
      ))}
    </Box>
  )
}

export default LiveEventsSecondaryTaxonomyTagList
