import { FC, useMemo } from 'react'

import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import { useTrackedGames } from '../../../hooks/useTrackedGames'
import { TrackedGame } from '../../../types/TrackedGame'
import { TrackedGamesGrid } from '../../TrackedGamesGrid/TrackedGamesGrid'

/**
 * Component representing event dialog Other games tab
 */
type OtherGamesTabProps = {
  eventTypeId?: string
  currentTrackedGame?: TrackedGame
  onTrackedGameClick: (trackedGame: TrackedGame) => void
}

export const OtherGamesTab: FC<OtherGamesTabProps> = ({ eventTypeId, currentTrackedGame, onTrackedGameClick }) => {
  const eventTypes = useMemo(() => {
    return eventTypeId ? [eventTypeId] : []
  }, [eventTypeId])
  const trackedGames = useTrackedGames({ eventTypes })

  const games = useMemo(() => {
    return (
      trackedGames.data
        ?.filter((trackedGame) => {
          return trackedGame.game.id !== currentTrackedGame?.game.id
        })
        ?.filter((trackedGame) => trackedGame.permission) || []
    )
  }, [currentTrackedGame, trackedGames.data])

  const isLoading = trackedGames.isFetching || trackedGames.isFetching

  return (
    <div className="LiveEventsEventDialog__OtherGamesTab">
      {isLoading ? <GRCircularProgress m={2} /> : <TrackedGamesGrid trackedGames={games} onTrackedGameClick={onTrackedGameClick} />}
    </div>
  )
}
