import { FC, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { v4 } from 'uuid'

import { Card, CardContent, CardHeader, Divider } from '@mui/material'

import { Currency } from '../../../../types/Currency'
import { EventEditForm, EventEditFormPricePoints } from '../../types/EventEditForm'
import { PricePointAdder } from './PricePointAdder/PricePointAdder'
import { PricePointTable } from './PricePointTable/PricePointTable'

type PricePointContainerProps = {
  onChange: (pricePoins: EventEditFormPricePoints) => void
  currencies: Currency[] | undefined
  pricePoints: EventEditFormPricePoints
}

export const PricePointContainer: FC<PricePointContainerProps> = ({ pricePoints, onChange, currencies }) => {
  //form.watch is used to keep updated list of pricepoints
  const form = useFormContext<EventEditForm>()
  const formPricePoints = form.watch('event.pricePoints')
  const handleAddPricePoints = useCallback(
    (newPricePoint: { value: string; currencyId: string; description: string }) => {
      const key = v4()
      onChange({
        ...formPricePoints,
        [key]: { value: newPricePoint.value, currencyId: newPricePoint.currencyId, description: newPricePoint.description, key: key },
      })
    },
    [onChange, formPricePoints]
  )
  const handleRemovePricePoint = useCallback(
    (pricePointId: string) => {
      const { [pricePointId]: removablePricePoint, ...rest } = formPricePoints
      onChange(rest)
    },
    [onChange, formPricePoints]
  )

  return (
    <>
      <Card>
        <CardHeader title={<Trans i18nKey="internal-live-events:price_points" />} />
        <Divider />
        <PricePointTable pricePoints={pricePoints} currencies={currencies} onRemovePricePoint={handleRemovePricePoint} />
        <CardHeader subheader={<Trans i18nKey="internal-live-events:add_new_price_point" />} sx={{ pb: 1 }} />
        <CardContent>
          <PricePointAdder pricePoints={pricePoints} currencies={currencies} onAddPricePoint={handleAddPricePoints} />
        </CardContent>
      </Card>
    </>
  )
}
