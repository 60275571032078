import React, { useState } from 'react'

import { Card, Divider } from '@mui/material'

import { MarketExplorerGamesChartAxisId } from '../../types/MarketExplorerGamesChartAxisId'
import { MarketExplorerGamesChartAxisScale, MarketExplorerGamesChartAxisType } from '../../types/MarketExplorerGamesChartAxisType'
import { MarketExplorerGamesChartNode } from '../../types/MarketExplorerGamesChartNode'
import { GamesDataTableColumnType } from '../../types/MarketExplorerGamesDataTableColumns'
import { MarketExplorerSegmentConfiguration } from '../../types/MarketExplorerSegmentConfiguration'
import MarketExplorerGamesChart from '../MarketExplorerGamesChart/MarketExplorerGamesChart'
import { SegmentQueryDataType } from '../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { SegmentsQueryReturnType } from '../MarketExplorerSegments/MarketExplorerSegments'
import MarketExplorerSegmentsBreakdown from '../MarketExplorerSegmentsBreakdown/MarketExplorerSegmentsBreakdown'

interface Props {
  segments: SegmentQueryDataType[]
  chartYAxisType: MarketExplorerGamesChartAxisType
  chartYAxisMax: MarketExplorerGamesChartAxisScale
  chartYAxisMin: MarketExplorerGamesChartAxisScale
  chartXAxisType: MarketExplorerGamesChartAxisType
  chartXAxisMax: MarketExplorerGamesChartAxisScale
  chartXAxisMin: MarketExplorerGamesChartAxisScale
  onAxisSelectChange: (id: MarketExplorerGamesChartAxisId, axisType: MarketExplorerGamesChartAxisType) => void
  onAxisMaxValueSelectChange: (
    id: MarketExplorerGamesChartAxisId,
    axisType: MarketExplorerGamesChartAxisType,
    axisScale: MarketExplorerGamesChartAxisScale,
    minAxisScale: MarketExplorerGamesChartAxisScale
  ) => void
  onAxisMinValueSelectChange: (
    id: MarketExplorerGamesChartAxisId,
    axisType: MarketExplorerGamesChartAxisType,
    axisScale: MarketExplorerGamesChartAxisScale,
    maxAxisScale: MarketExplorerGamesChartAxisScale
  ) => void
  selectedColumns: GamesDataTableColumnType[]
  onSelectedColumnsChange: (columnIds: GamesDataTableColumnType[]) => void
  onSegmentsChange: (segments: MarketExplorerSegmentConfiguration[]) => void
  onSegmentsQueryDataChange: (segmentsData: SegmentsQueryReturnType) => void
  segmentData: MarketExplorerSegmentConfiguration[]
}

const MarketExplorerGamesOverview: React.FC<Props> = ({
  segments,
  chartYAxisType,
  chartYAxisMax,
  chartYAxisMin,
  chartXAxisType,
  chartXAxisMax,
  chartXAxisMin,
  onAxisSelectChange,
  onAxisMaxValueSelectChange,
  onAxisMinValueSelectChange,
  selectedColumns,
  onSelectedColumnsChange,
  onSegmentsChange,
  onSegmentsQueryDataChange,
  segmentData,
}) => {
  const [chartNodes, setChartNodes] = useState<MarketExplorerGamesChartNode[]>([])

  return (
    <div className="MarketExplorerGamesOverview">
      <Card>
        <MarketExplorerGamesChart
          segments={segments}
          yAxis={chartYAxisType}
          yAxisMax={chartYAxisMax}
          yAxisMin={chartYAxisMin}
          xAxis={chartXAxisType}
          xAxisMax={chartXAxisMax}
          xAxisMin={chartXAxisMin}
          onAxisSelectChange={onAxisSelectChange}
          onAxisMaxValueSelectChange={onAxisMaxValueSelectChange}
          onAxisMinValueSelectChange={onAxisMinValueSelectChange}
          onNodesChange={(nodes: MarketExplorerGamesChartNode[]) => setChartNodes(nodes)}
        ></MarketExplorerGamesChart>
        <Divider light sx={{ my: 1 }} />
        <MarketExplorerSegmentsBreakdown
          segments={segments}
          yAxis={chartYAxisType}
          yAxisMax={chartYAxisMax}
          yAxisMin={chartYAxisMin}
          xAxis={chartXAxisType}
          xAxisMax={chartXAxisMax}
          xAxisMin={chartXAxisMin}
          chartNodes={chartNodes}
          selectedColumns={selectedColumns}
          onSelectedColumnsChange={onSelectedColumnsChange}
          onSegmentsChange={onSegmentsChange}
          onSegmentsQueryDataChange={onSegmentsQueryDataChange}
          segmentData={segmentData}
        />
      </Card>
    </div>
  )
}

export default MarketExplorerGamesOverview
