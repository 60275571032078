import { FC, ReactNode } from 'react'

import { Box, LinearProgress, LinearProgressProps } from '@mui/material'

export type LinearProgressBarProps = LinearProgressProps & {
  labelBefore?: ReactNode
  labelAfter?: ReactNode
  onClick?: () => void
}

/**
 * Component for displaying a linear progress bar with an optional value label
 */
export const LinearProgressBar: FC<LinearProgressBarProps> = ({ labelBefore, labelAfter, value = 0, onClick, ...linearProgressProps }) => {
  return (
    <Box alignItems="center" display="flex" className="ProgressBar">
      {labelBefore && <Box sx={{ minWidth: '4em', whiteSpace: 'nowrap' }}>{labelBefore}</Box>}
      <Box sx={{ width: '100%', mr: labelAfter ? 2 : 0, ml: labelBefore ? 2 : 0 }} onClick={onClick} className={onClick ? 'hoverable' : ''}>
        <LinearProgress value={value} variant="determinate" {...linearProgressProps} />
      </Box>
      {labelAfter && <Box sx={{ minWidth: '4em', whiteSpace: 'nowrap' }}>{labelAfter}</Box>}
    </Box>
  )
}
