import { HTMLReactParserOptions, domToReact } from 'html-react-parser'
import { FC } from 'react'
import { Trans } from 'react-i18next'

import { Grid, Typography, GridProps, TypographyProps } from '@mui/material'

import { useAnalystReviewsAndCommentsCheck } from '../../features/account/hooks/roleHooks'
import AskAnalystButton from '../../features/ask-analyst/components/AskAnalystButton/AskAnalystButton'
import { Analyst as TAnalyst } from '../../types/Analyst'
import { Analyst } from '../Analyst/Analyst'
import { HtmlContentParser } from '../HtmlContentParser/HtmlContentParser'
import { ScreenshotModalLink } from '../ScreenshotModalLink/ScreenshotModalLink'

/**
 * Components for build analyst review (comments and overviews) cards and dialogs
 */

type ReviewHeaderProps = GridProps & {
  title?: string
  titleProps?: TypographyProps
  subtitle?: string
  analyst?: TAnalyst
  gameName?: string
}

export const ReviewHeader: FC<ReviewHeaderProps> = ({ title, subtitle, analyst, titleProps, gameName, ...gridProps }) => {
  return (
    <Grid container direction="row" justifyContent="space-between" {...gridProps}>
      <Grid item>
        <Grid container direction="row" alignItems="center">
          {analyst && gameName && (
            <Grid item mr={3}>
              <AskAnalystButton gameName={gameName} analyst={analyst} />
            </Grid>
          )}
          <Grid item>
            <Analyst name={analyst?.name} picture={analyst?.picture} title={analyst?.title} direction={gridProps.direction} />
          </Grid>
        </Grid>
      </Grid>
      {title && (
        <Grid item>
          {title && (
            <Typography variant="h3" {...titleProps}>
              {title}
            </Typography>
          )}
          {subtitle && <Typography variant="h5">{subtitle}</Typography>}
        </Grid>
      )}
    </Grid>
  )
}

type ReviewContentProps = {
  contentHtml?: string
}

export const ReviewContent: FC<ReviewContentProps> = ({ contentHtml }) => {
  const hasAccessToAnalystOverviewsAndComments = useAnalystReviewsAndCommentsCheck()
  return hasAccessToAnalystOverviewsAndComments ? (
    <HtmlContentParser rawHtml={contentHtml} parserOptions={screenshotsModalParserOptions} />
  ) : (
    <Typography>
      <Trans i18nKey="reviews:label_not_for_free"></Trans>
    </Typography>
  )
}

// parser options to convert screenshot modal links to links known by router
export const screenshotsModalParserOptions: HTMLReactParserOptions = {
  replace: (domNode: any) => {
    if (domNode.type === 'tag' && domNode.name === 'a' && domNode.attribs && domNode.attribs.href && domNode.attribs.href.indexOf('/?shotModal=') >= 0) {
      return <ScreenshotModalLink originalLink={domNode.attribs.href}>{domToReact(domNode.children)}</ScreenshotModalLink>
    } else {
      return false
    }
  },
}
