import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'

import Info from '@mui/icons-material/Info'
import { Box, Collapse, Grid, Typography } from '@mui/material'

import { useDebouncedResize } from '../../../../hooks/useDebouncedResize'
import { CalendarTimeRangeChangeCallback, CalendarTimeRanges } from '../../types/Calendar'
import { TrackingEventTimelineGroup, TrackingEventTimelineItem } from '../../types/LiveEvents'
import { TrackedGame } from '../../types/TrackedGame'
import { FilteredTrackingEventsByGame, TrackingEventByGame } from '../../types/TrackingEvents'
import { LiveEventsCalendarByGameContainer } from '../LiveEventsCalendar/LiveEventsCalendarByGameContainer/LiveEventsCalendarByGameContainer'
import './LiveEventsCalendarList.scss'

interface Props {
  trackedGames: TrackedGame[]
  trackingEventsByGame: FilteredTrackingEventsByGame
  trackingAllEventsByGame: TrackingEventByGame
  calendarTimeRanges: CalendarTimeRanges
  timeStart?: number
  timeEnd?: number
  highlightedEventId?: string
  selectedCalendarAdditionalDataIdsMap: { [dataId: string]: boolean }
  minZoom?: number
  maxZoom?: number
  onEventItemClick: (clickedItem: TrackingEventTimelineItem) => void
  onEventGroupClick: (eventGroup: TrackingEventTimelineGroup) => void
  onDeselectGame: (trackedGame: TrackedGame, hdGameSelected: boolean) => void
  onOverviewTrackedGameChanged: (trackedGame: TrackedGame) => void
  onCalendarTimeRangeChange: CalendarTimeRangeChangeCallback
  onGamePerformanceDialogOpen: (trackedGame: TrackedGame) => void
  isLoading?: boolean
}

const emptyArray = [] as any[]

const LiveEventsCalendarList: React.FC<Props> = ({
  trackedGames,
  trackingEventsByGame,
  trackingAllEventsByGame,
  calendarTimeRanges,
  timeStart,
  timeEnd,
  highlightedEventId,
  selectedCalendarAdditionalDataIdsMap,
  minZoom,
  maxZoom,
  onEventItemClick,
  onEventGroupClick,
  onDeselectGame,
  onOverviewTrackedGameChanged,
  onCalendarTimeRangeChange,
  onGamePerformanceDialogOpen,
  isLoading,
}) => {
  const { t } = useTranslation()

  const debouncedResize = useDebouncedResize(50)

  // Whenever the items, groups or visibility provided for the calendar change we trigger a re-render of the calendar by changing the key of the calendar component
  // to reset the visible time range. This aims to fix GR-1101, GR-1618 and GR-1795.
  const handleCalendarRerender = useCallback(debouncedResize, [debouncedResize])

  return (
    <div className="LiveEventsCalendarList">
      <Box mb={1}>
        <Grid container spacing={1}>
          <Grid item>
            <Info color="action" />
          </Grid>
          <Grid item xs>
            <Typography color="action" variant="body1">
              {t('live-events:game_calendars_usability_instructions')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <TransitionGroup>
        {trackedGames.map((trackedGame, index) => {
          return (
            // We are useing index as key as using e.g. trackedGame.game.id causes the calendars' time ranges not to obey the params given to it. Currently assuming this is an issue with the 3rd party calendar component.
            <Collapse key={`${trackedGame.game.id}_${index}`}>
              <LiveEventsCalendarByGameContainer
                trackedGames={trackedGames}
                trackedGame={trackedGame}
                trackedEvents={trackingEventsByGame.events[trackedGame.game.id] || emptyArray}
                allTrackedEvents={trackingAllEventsByGame[trackedGame.game.id] || emptyArray}
                performanceEffects={trackingEventsByGame.performanceEffects[trackedGame.game.id] || emptyArray}
                gameTopIAPs={trackingEventsByGame.gameTopIAPs[trackedGame.game.id] || emptyArray}
                gameVersions={trackingEventsByGame.gameVersions[trackedGame.game.id] || emptyArray}
                analystReviews={trackingEventsByGame.comments?.[trackedGame.game.id] || emptyArray}
                calendarTimeRanges={calendarTimeRanges}
                onItemClick={onEventItemClick}
                onGroupClick={onEventGroupClick}
                onDeselectGame={onDeselectGame}
                onOverviewTrackedGameChanged={onOverviewTrackedGameChanged}
                timeStart={timeStart}
                timeEnd={timeEnd}
                minZoom={minZoom}
                maxZoom={maxZoom}
                highlightedEventId={highlightedEventId}
                isLoading={isLoading}
                onCalendarTimeRangeChange={onCalendarTimeRangeChange}
                selectedCalendarAdditionalDataIdsMap={selectedCalendarAdditionalDataIdsMap}
                onCalendarChange={handleCalendarRerender}
                onGamePerformanceDialogOpen={onGamePerformanceDialogOpen}
              />
            </Collapse>
          )
        })}
      </TransitionGroup>
    </div>
  )
}

export default LiveEventsCalendarList
