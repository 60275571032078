import { startOfMonth, startOfWeek } from 'date-fns'

import i18n from '../../../i18n'
import { DataTypeValue, GranularityValue } from '../types/Filters'
import { PlotType } from '../types/PlotType'

export const isDataTypeRevenueAndDownloadsRatio = (dataType: DataTypeValue) => {
  return !(dataType === DataTypeValue.Revenue || dataType === DataTypeValue.Downloads)
}

export const isPlotTypeCumulative = (plotType?: PlotType) => {
  return plotType === PlotType.CumulativeStacked || plotType === PlotType.CumulativeLine
}

export const isPlotTypeStacked = (plotType?: PlotType) => {
  return plotType === PlotType.CumulativeStacked || plotType === PlotType.Stacked
}

export const getDataTypeName = (dataType: DataTypeValue) => {
  switch (dataType) {
    case DataTypeValue.Revenue:
      return i18n.t('common:revenue_text')
    case DataTypeValue.Downloads:
      return i18n.t('common:downloads_text')
    case DataTypeValue.RevenueDownloadsRatioAllTime:
      return i18n.t('common:all_time_revenue_downloads_ratio')
    case DataTypeValue.RevenueDownloadsRatio7Days:
      return i18n.t('common:revenue_downloads_ratio_days', { days: 7 })
    case DataTypeValue.RevenueDownloadsRatio30Days:
      return i18n.t('common:revenue_downloads_ratio_days', { days: 30 })
    case DataTypeValue.RevenueDownloadsRatio90Days:
      return i18n.t('common:revenue_downloads_ratio_days', { days: 90 })
  }
}

export const getDateBeginingOfWeek = (date: Date) => {
  return startOfWeek(date)
}

export const getDateBeginningOfMonth = (date: Date) => {
  return startOfMonth(date)
}

export const getTimestampByGranularity = (granularity: GranularityValue, date: Date) => {
  switch (granularity) {
    case GranularityValue.Day:
      return new Date(date.setHours(0, 0, 0, 0))
    case GranularityValue.Week:
      return getDateBeginingOfWeek(date)
    case GranularityValue.Month:
      return getDateBeginningOfMonth(date)
  }
}

export const resolveTimeunitByGranularity = (granularity: GranularityValue) => {
  switch (granularity) {
    case GranularityValue.Day:
      return 'day'
    case GranularityValue.Week:
      return 'week'
    case GranularityValue.Month:
      return 'month'
  }
}
