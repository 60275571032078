import React from 'react'

import { ImageNotSupported } from '@mui/icons-material'

import { useGetImageObjectUrlQuery } from '../../api/core'

const GRImg = ({ alt, src, className, loadingElem }: { alt: string; src: string; className?: string; loadingElem?: JSX.Element }) => {
  const { data, error, isFetching } = useGetImageObjectUrlQuery({ url: src }, { skip: !src })
  return (
    <span className={className ? className : 'GRImg'}>
      {isFetching ? loadingElem ? loadingElem : <ImageNotSupported /> : !error && data ? <img alt={alt} src={data} /> : <ImageNotSupported />}
    </span>
  )
}

export default GRImg
