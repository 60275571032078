import { t } from 'i18next'
import React, { useMemo } from 'react'

import { CircularProgress } from '@mui/material'

import { useGetUserSettingsQuery } from '../../../../api/combined'
import { useGames } from '../../../game/hooks/gameHooks'
import { Game } from '../../../game/types/Game'
import GameSearchResult from '../GameSearchResult/GameSearchResult'
import './GameSearchRecentResult.scss'

/**
 * GameSearchRecentResult
 */
interface GameSearchRecentResultProps {}
const GameSearchRecentResult: React.FC<GameSearchRecentResultProps> = () => {
  const { data: userSettings = {} } = useGetUserSettingsQuery()
  const gameIds = userSettings.recentlySearchedGameIds?.filter((gameId) => !!gameId) as string[]
  const { games: recentlySearchedGameIds, isLoading } = useGames(gameIds || [])

  const sortedRecentSearchGames = useMemo(() => {
    if (!userSettings || !recentlySearchedGameIds || !userSettings.recentlySearchedGameIds) {
      return []
    }

    const searchGameIds = userSettings.recentlySearchedGameIds as string[]
    return recentlySearchedGameIds.slice(0, 5).sort((a, b) => searchGameIds.indexOf(a.id) - searchGameIds.indexOf(b.id))
  }, [recentlySearchedGameIds, userSettings])

  if (!gameIds) {
    return null
  }

  return (
    <>
      <div className="GameSearchRecentResult__GameSearchResult">{t('common:recent_games')}</div>

      {isLoading && (
        <div className="text-center" style={{ margin: '20px auto' }}>
          <CircularProgress color="primary" />
        </div>
      )}

      {!isLoading &&
        sortedRecentSearchGames.map((game: Game) => {
          return <GameSearchResult key={game.id} game={game} />
        })}
    </>
  )
}

export default GameSearchRecentResult
