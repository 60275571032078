import { Tag } from '../../../api/core'
import { BooleanMap } from '../../../types/BooleanMap'
import { MotivationKey, MotivationType } from '../../market-explorer/types/MotivationType'
import { LiveEventCalendarAdditionalData } from './LiveEventAdditionalCalendarData'
import { LiveEventAppearance } from './LiveEventAppearance'
import { LiveEventDuration } from './LiveEventDuration'
import { LiveEventPricePoint } from './LiveEventPricePoint'
import { LiveEventsTag } from './LiveEventsTag'

export enum LiveEventsCommonFilterKeys {
  LiveEventTags = 'liveEventTags',
  LiveEventSecondaryTags = 'liveEventSecondaryTags',
  LiveEventDurations = 'liveEventDurations',
  LiveEventAppearances = 'liveEventAppearances',
  LiveEventPricePoints = 'liveEventPricePoints',
  AdditionalDatas = 'additionalDatas',
  Motivations = 'motivations',
  Archetypes = 'archetypes',
}

export type TLiveEventsCommonFilters = {
  [LiveEventsCommonFilterKeys.LiveEventTags]: Tag[]
  [LiveEventsCommonFilterKeys.LiveEventSecondaryTags]: Tag[]
  [LiveEventsCommonFilterKeys.LiveEventDurations]: BooleanMap
  [LiveEventsCommonFilterKeys.LiveEventAppearances]: BooleanMap
  [LiveEventsCommonFilterKeys.LiveEventPricePoints]: BooleanMap
  [LiveEventsCommonFilterKeys.AdditionalDatas]: BooleanMap
  [LiveEventsCommonFilterKeys.Motivations]: BooleanMap
  [LiveEventsCommonFilterKeys.Archetypes]: BooleanMap
}

export type LiveEventsCommonFiltersData = {
  [LiveEventsCommonFilterKeys.LiveEventTags]: LiveEventsTag[]
  [LiveEventsCommonFilterKeys.LiveEventSecondaryTags]: LiveEventsTag[]
  [LiveEventsCommonFilterKeys.LiveEventDurations]: LiveEventDuration[]
  [LiveEventsCommonFilterKeys.LiveEventAppearances]: LiveEventAppearance[]
  [LiveEventsCommonFilterKeys.LiveEventPricePoints]: LiveEventPricePoint[]
  [LiveEventsCommonFilterKeys.AdditionalDatas]: LiveEventCalendarAdditionalData[]
  [LiveEventsCommonFilterKeys.Motivations]: MotivationType[]
  [LiveEventsCommonFilterKeys.Archetypes]: MotivationKey[]
}

export enum LiveEventsCommonFilterGroup {
  GameData = 'gameData',
  EventType = 'eventType',
  EventSecondaryTags = 'eventSecondaryTags',
  EventTypeGroup = 'eventTypeGroup',
  Duration = 'duration',
  Appearance = 'appearance',
  PricePoint = 'pricePoint',
  Motivation = 'motivation',
  Archetype = 'archetype',
}

export type LiveEventsCommonFiltersSelection = {
  id: string
  name: string
  type: LiveEventsCommonFilterGroup
}
