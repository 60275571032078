import { t } from 'i18next'
import { useEffect } from 'react'

import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

import { displaySnackBar } from '../features/snackbar'
import { SnackBarMessage } from '../features/snackbar/types/SnackBarMessage'
import { useAppDispatch } from './storeHooks'

type UseErrorHookParams = {
  error?: FetchBaseQueryError | SerializedError
  message?: SnackBarMessage
}

const AbortError = 'AbortError'

/**
 * A hook for using errors received from redux toolkit query hooks and displaying a snackbar message
 */
const messageDefaults: SnackBarMessage = {
  message: t('common:general_error_message'),
  severity: 'error',
  open: true,
}

export const useError = ({ error, message }: UseErrorHookParams) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (error && (error as SerializedError)?.name !== AbortError) {
      dispatch(displaySnackBar({ ...messageDefaults, ...message }))
    }
  }, [dispatch, message, error])
}
