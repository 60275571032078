import { t } from 'i18next'
import React, { useMemo, useState } from 'react'

import { Info } from '@mui/icons-material'
import { Box, Card, Typography } from '@mui/material'

import { GRCountryFlag } from '../../../../components/GRCountryFlag/GRCountryFlag'
import GRPowerScoreSlider from '../../../../components/GRPowerScoreSlider/GRPowerScoreSlider'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import revenueService from '../../../../services/RevenueService'
import utilsService from '../../../../services/UtilsService'
import { Analysis } from '../../../analysis/types/Analysis'
import { useCurrentMarket } from '../../../markets'
import { Game } from '../../types/Game'
import './GameRevenuePotential.scss'
import GameRevenuePotentialChart from './components/GameRevenuePotentialChart'

/**
 * GameRevenuePotential
 */
interface GameRevenuePotentialProps {
  game: Game
  analysis?: Analysis
}
const GameRevenuePotential: React.FC<GameRevenuePotentialProps> = ({ game, analysis }) => {
  const { currentMarketIso } = useCurrentMarket()
  const [powerscore, setPowerscore] = useState(analysis?.powerScore)

  const revenue = useMemo(() => {
    return revenueService.getLabelByPowerScore(powerscore)
  }, [powerscore])

  return (
    <Card sx={{ p: 2, marginBottom: '20px' }}>
      <Box sx={{ width: '100%', display: 'inline-flex' }}>
        <span style={{ marginRight: '8px', fontSize: '16px' }}>{`${t('common:market')}: ${t('common:apple_ios')}`}</span>
        <GRCountryFlag countryCode={currentMarketIso} />
      </Box>
      <Typography sx={{ my: 2 }}>{t('overview:label_revenue_potential_description')}</Typography>
      <GameRevenuePotentialChart revenue={revenue} />
      <Box sx={{ mt: 4, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Typography variant="h2" sx={{ mr: 0.2 }}>
          {t('overview:average_daily_revenue')}
        </Typography>
        <GRTooltip content={t('overview:info_avg_dailyrevenue', { gps: Math.round(powerscore || 0) })}>
          <Info fontSize="small" sx={{ mt: 0.3, mr: 1 }} />
        </GRTooltip>
        {revenue && (
          <Typography className="GameRevenuePotential__revenue" variant="h2">
            {utilsService.formatCurrency(revenue?.Average)}
          </Typography>
        )}
      </Box>
      <Box my={5}></Box>
      {powerscore && <GRPowerScoreSlider defaultValue={powerscore} onChange={setPowerscore} />}
    </Card>
  )
}

export default GameRevenuePotential
