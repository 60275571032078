import { t } from 'i18next'
import { FC, ReactNode } from 'react'
import { BookType } from 'xlsx'

import { Grid } from '@mui/material'

import { RoleEnum } from '../../features/account'
import { GranularityValue } from '../../features/revenue-and-downloads/types/Filters'
import DateRangePicker, { DateRangeValue } from '../DateRangePicker/DateRangePicker'
import { ExportDataButton } from '../ExportDataButton/ExportDataButton'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import { GranularitySelector } from '../GranularitySelector/GranularitySelector'
import { PerformanceChartV2DataType } from './PerformanceChartV2DataType'
import { YAxisConfig } from './PerformanceChartV2Types'
import { PerformanceDataTypeSelector } from './PerformanceDataTypeSelector'

type PerformanceChartV2FiltersProps = {
  yAxisLeftConfig: YAxisConfig
  yAxisRightConfig: YAxisConfig
  dateRange?: DateRangeValue
  onDateRangeChanged?: (value?: DateRangeValue) => void
  granularity?: GranularityValue
  onGranularityChanged?: (value: GranularityValue) => void
  onYAxisLeftConfigChanged: (value: YAxisConfig) => void
  onYAxisRightConfigChanged: (value: YAxisConfig) => void
  onExportFormatSelected?: (format: BookType) => void
  config?: {
    dateRangePickerDisabled?: boolean
    minDate?: number
  }
  isLoading?: boolean
  children?: ReactNode
}

export const PerformanceChartV2Filters: FC<PerformanceChartV2FiltersProps> = ({
  yAxisLeftConfig,
  yAxisRightConfig,
  config,
  dateRange,
  granularity,
  onDateRangeChanged,
  onGranularityChanged,
  onYAxisLeftConfigChanged,
  onYAxisRightConfigChanged,
  onExportFormatSelected,
  isLoading,
}) => {
  const minDate = config?.minDate ? new Date(config?.minDate || 0) : undefined
  return (
    <Grid container className="PerformanceChartV2Filters" wrap="wrap" spacing={2}>
      <Grid item container alignItems="center" spacing={2}>
        <Grid item>
          {config?.dateRangePickerDisabled ? (
            <GRTooltip content={t('overview:date_picker_disabled_rd_type')}>
              <DateRangePicker onChangeValue={onDateRangeChanged} value={dateRange} minDate={minDate} disabled={config?.dateRangePickerDisabled} />
            </GRTooltip>
          ) : (
            <DateRangePicker onChangeValue={onDateRangeChanged} value={dateRange} minDate={minDate} disabled={config?.dateRangePickerDisabled} />
          )}
        </Grid>
        <Grid item>
          <GranularitySelector value={granularity} onChange={onGranularityChanged} />
        </Grid>
        <Grid item container flexGrow={0} flexShrink={1} justifyContent="flex-end">
          <ExportDataButton
            accessRoles={[RoleEnum.csv_export_games, RoleEnum.csv_update_history]}
            onChooseFormat={onExportFormatSelected}
            disabled={isLoading}
            analyticsEventOrigin="Performance Chart"
          />
        </Grid>
      </Grid>

      <Grid item container alignItems="center" spacing={2}>
        <Grid item>
          <PerformanceDataTypeSelector
            onChange={(event) => onYAxisLeftConfigChanged({ ...yAxisLeftConfig, dataType: event.target.value as PerformanceChartV2DataType })}
            exclude={yAxisLeftConfig.excludedDataTypes}
            value={yAxisLeftConfig.dataType}
          />
        </Grid>
        <Grid item container flexGrow={0} flexShrink={1} justifyContent="flex-end">
          <PerformanceDataTypeSelector
            onChange={(event) => onYAxisRightConfigChanged({ ...yAxisRightConfig, dataType: event.target.value as PerformanceChartV2DataType })}
            exclude={yAxisLeftConfig.excludedDataTypes}
            value={yAxisRightConfig.dataType}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
