import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Checkbox, CircularProgress, Divider, FormControlLabel, Grid, Typography } from '@mui/material'

import { ConceptTag, useGetFeatureQuery, useGetScreenshotsByConceptIdsQuery, useGetScreenshotsByFeaturesQuery } from '../../../../api/core'
import DateRangePicker, { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import { SubgenreMap } from '../../../account'
import { useConceptsByTags } from '../../../concepts/hooks/conceptHooks'
import { Game } from '../../../game'
import { useGetGameList } from '../../../game/hooks/gameHooks'
import { FeatureGame } from '../../../game/types/Game'
import { useInitialGameFeatureState } from '../../hooks/useInitialState'
import { EnrichedFeature } from '../../types/ImplementationExamples'
import { gamesByVersionDateFilter, reGroupByVersion, subgenreFilter } from '../../util/filters'
import { convertLegacyScreenshots } from '../../util/screenshotHelpers'
import ScreenshotPreviews from '../ScreenshotPreviews/ScreenshotPreviews'

interface ImplementationPreviewsProps {
  breakDownVersions?: boolean
  games: FeatureGame[]
  features?: EnrichedFeature[]
  conceptTags?: ConceptTag[]
  loading?: boolean
  gameSorter?: (g1: Game, g2: Game) => number
  selectedGames?: FeatureGame[]
  hideListScreenshots?: boolean
  selectedSubgenreMap?: SubgenreMap
  fromDate?: Date
  minDate?: Date
  hasLimitedAccess?: boolean
}

const ImplementationPreviews: React.FC<ImplementationPreviewsProps> = ({
  breakDownVersions,
  features,
  conceptTags,
  games,
  loading,
  gameSorter,
  hideListScreenshots,
  selectedSubgenreMap,
  fromDate = null,
  minDate,
  hasLimitedAccess,
}) => {
  const { t } = useTranslation()
  const [latestOnly, setLatestOnly] = useState(false)
  const [dateRange, setDateRange] = useState<DateRangeValue | undefined>({ fromDate: fromDate, toDate: new Date() })

  const { games: initialGames, isLoading } = useGameFeatures()
  const renderedGames = useMemo(
    () => reGroupByVersion(games).filter(gamesByVersionDateFilter(dateRange)).filter(subgenreFilter(selectedSubgenreMap)),
    [games, dateRange, selectedSubgenreMap]
  )
  const implementationScreenshotGames = useMemo(() => reGroupByVersion(initialGames).filter(gamesByVersionDateFilter(dateRange)), [initialGames, dateRange])

  useEffect(() => {
    if (fromDate) {
      if (fromDate) setDateRange({ ...dateRange, fromDate: fromDate })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate])

  return (
    <>
      <Divider sx={{ my: 2 }} />
      {/* Filtering by date range or Latest only */}
      <Grid direction="row" container mb={2} spacing={2} alignItems="center">
        <Grid item>
          <Typography>{t('common:timespan')}</Typography>
        </Grid>
        <Grid item>
          <DateRangePicker onChangeValue={setDateRange} value={dateRange} minDate={minDate} />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox value={latestOnly} onClick={() => setLatestOnly(!latestOnly)} />}
            label={t<string>('implementations:label_latest_only')}
          />
        </Grid>
      </Grid>
      {loading || isLoading || !features ? (
        <Grid container justifyContent="center" my={2}>
          <CircularProgress />
        </Grid>
      ) : (
        <ScreenshotPreviews
          gameSorter={gameSorter}
          breakDownVersions={breakDownVersions}
          features={features}
          conceptTags={conceptTags}
          games={renderedGames}
          hideEmptyFeatures={true}
          latestOnly={latestOnly}
          hideListScreenshots={hideListScreenshots}
          implementationScreenshotGames={implementationScreenshotGames}
          hasLimitedAccess={hasLimitedAccess}
        />
      )}
    </>
  )
}

export default ImplementationPreviews

/**
 * Hook for fetching games with feature parsed from implementation examples screenshot modal url
 */
const useGameFeatures = () => {
  const [initial] = useInitialGameFeatureState()
  const { data: featureDetails, isFetching: isFetchingDetails } = useGetFeatureQuery(initial?.featureId as number, { skip: !initial?.featureId })
  const choiceIds = featureDetails?.choices.map(({ weightId }) => weightId)

  const { data: screenshotsDataFeatures, isFetching: isFetchingScreenshotsFeatures } = useGetScreenshotsByFeaturesQuery(
    { choices: choiceIds || [] },
    { skip: !choiceIds }
  )

  const concepts = useConceptsByTags(initial?.conceptTagIds || [])
  const conceptIds = useMemo(() => concepts?.map((concept) => concept.id) || [], [concepts])

  const { data: screenshotsDataConcepts, isFetching: isFetchingScreenshotsConcepts } = useGetScreenshotsByConceptIdsQuery(conceptIds, {
    skip: !conceptIds?.length,
  })

  const isFetchingScreenshots = isFetchingScreenshotsFeatures || isFetchingScreenshotsConcepts

  const screenshotsData = useMemo(() => {
    return [...(screenshotsDataFeatures || []), ...(screenshotsDataConcepts || [])]
  }, [screenshotsDataFeatures, screenshotsDataConcepts])

  const gameIds = useMemo(() => (initial?.gameId ? [initial?.gameId] : []), [initial?.gameId])

  const { data: gamesData, isFetching: isFetchingGames } = useGetGameList(
    {
      gameIds,
      include: 'appid,name,artist,icons,conventionalSubgenre,conventionalSubgenreId,sdranks,sranks',
    },
    { skip: gameIds.length === 0 }
  )

  const games = useMemo(
    () =>
      gameIds
        .map((gameId) => gamesData?.find((game) => game.id === gameId))
        .filter((game): game is Game => !!game)
        .map((game) => {
          const featureGame = new FeatureGame(game)
          featureGame.featureScreenshots = convertLegacyScreenshots((screenshotsData || []).filter(({ gameId }) => gameId === game.id))
          featureGame.conceptScreenshots = convertLegacyScreenshots((screenshotsData || []).filter(({ gameId }) => gameId === game.id))
          return featureGame
        }),
    [gameIds, gamesData, screenshotsData]
  )

  const isLoading = isFetchingDetails || isFetchingScreenshots || isFetchingGames

  return {
    games,
    isLoading,
  }
}
