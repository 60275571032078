import { useMemo } from 'react'
import React from 'react'

import { Box } from '@mui/material'

import './MaskedText.scss'

/**
 * MaskedText create gray area to cover text
 */
export type MaskedTextProps = {
  length?: number
  height?: string
  text?: string
  maxLength?: number
}

const defaultMaxLength = 40

export const MaskedText: React.FC<MaskedTextProps> = ({ text, length = 0, height = '16px', maxLength = defaultMaxLength }) => {
  const maskedTextLength = useMemo(() => {
    if (text) {
      return text.length <= maxLength ? text.length : maxLength
    } else if (length) {
      return length
    }
  }, [length, maxLength, text])

  return (
    <Box component="span" sx={{ display: 'block' }}>
      {[...new Array(maskedTextLength)].map((_, index) => (
        <Box key={index} sx={{ height }} component="span" className="MaskedText__box" title={text}></Box>
      ))}
    </Box>
  )
}
