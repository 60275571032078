import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog, DialogContent } from '@mui/material'

import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { Game } from '../../types/Game'
import GameFeatureIdeas, { GameFeatureIdeasProps } from '../GameFeatureIdeas/GameFeatureIdeas'

/**
 * GameFeatureIdeasModal Wrapper for GameFeatureIdeas
 */
interface GameFeatureIdeasModalProps extends GameFeatureIdeasProps {
  open: boolean
  onClose: () => void
  game: Game
}
const GameFeatureIdeasModal: React.FC<GameFeatureIdeasModalProps> = ({ open, onClose, game, analysis }) => {
  const { t } = useTranslation()

  const gameIsAnalyzedForMarket = useMemo(() => {
    return analysis && analysis?.powerScore > 0 ? true : false
  }, [analysis])

  return (
    <Dialog maxWidth={false} onClose={onClose} open={open}>
      <GRDialogTitle onClose={onClose}>{t('overview:feature_ideas')}</GRDialogTitle>
      <DialogContent sx={{ pt: '16px !important' }}>
        <GameFeatureIdeas game={game} analysis={analysis} isGameAnalyzed={gameIsAnalyzedForMarket} />
      </DialogContent>
    </Dialog>
  )
}

export default GameFeatureIdeasModal
