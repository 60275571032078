import { FC, ReactNode } from 'react'

import { Box } from '@mui/material'

import { NewsCardSectionDivider } from '../../NewsCardSectionDivider/NewsCardSectionDivider'

type NewsCardContentScreenshotsSectionProps = {
  title: ReactNode
  children?: ReactNode
}

export const NewsCardContentScreenshotsSection: FC<NewsCardContentScreenshotsSectionProps> = ({ title, children }) => {
  return (
    <Box className="NewsCardContentScreenshotsSection" mb={2}>
      <NewsCardSectionDivider>{title}</NewsCardSectionDivider>
      <div className="NewsCardContentScreenshotsSection__screenshots">{children}</div>
    </Box>
  )
}
