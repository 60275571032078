import { enGB } from 'date-fns/locale'
import { forwardRef } from 'react'

import { DatePickerProps, LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import './DatePicker.scss'

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps<Date>>(({ value, onChange, ...otherPickerProps }, ref) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <MuiDatePicker
        {...otherPickerProps}
        className="DatePicker"
        ref={ref}
        format="dd.MM.yyyy"
        slotProps={{
          field: {},
          textField: {
            fullWidth: true,
            size: 'small',
            inputProps: { sx: { boxSizing: 'content-box !important' } },
            ...otherPickerProps.slotProps?.textField,
          },
        }}
        onChange={onChange}
        value={value}
      />
    </LocalizationProvider>
  )
})
