import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Chip } from '@mui/material'

import { AllOrNothingSelector } from '../../../../components/AllOrNothingSelector/AllOrNothingSelector'
import { GRAccordion } from '../../../../components/GRAccordion/GRAccordion'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { LiveEventCalendarAdditionalData } from '../../types/LiveEventAdditionalCalendarData'
import { LiveEventTrackerTab } from '../../types/LiveEventTrackerTab'
import { resolveAdditionalDataDisabledState } from '../../utils/utils'
import './LiveEventsCalendarAdditionalDataSelector.scss'

type AdditionalDataSelection = { [dataId: string]: boolean }
interface Props {
  calendarAdditionalDatas: LiveEventCalendarAdditionalData[]
  selectedCalendarAdditionalDataIdsMap: AdditionalDataSelection
  isLoading?: boolean
  open?: boolean
  onAdditionalDataSelectChange: (newSelection: AdditionalDataSelection, selection: LiveEventCalendarAdditionalData) => void
  onAdditionalDataSelectionCleared: () => void
  onAdditionalDataSelectAll: () => void
}

const LiveEventsCalendarAdditionalDataSelector: React.FC<Props> = ({
  calendarAdditionalDatas,
  selectedCalendarAdditionalDataIdsMap,
  isLoading,
  open,
  onAdditionalDataSelectChange,
  onAdditionalDataSelectionCleared,
  onAdditionalDataSelectAll,
}) => {
  const { t } = useTranslation()
  const [liveEventAdditionalDataListOpen, setLiveEventAdditionalDataListOpen] = useState(open || false)
  const { subpage = LiveEventTrackerTab.Feed } = useParams<{ subpage: LiveEventTrackerTab }>()

  const handleAdditionalDataClick = (data: LiveEventCalendarAdditionalData) => {
    const { id: dataId } = data
    if (selectedCalendarAdditionalDataIdsMap[dataId]) {
      const { [dataId]: omitted, ...newSelection } = selectedCalendarAdditionalDataIdsMap
      onAdditionalDataSelectChange(newSelection, data)
    } else {
      const newSelection = { ...selectedCalendarAdditionalDataIdsMap }
      newSelection[dataId] = true
      onAdditionalDataSelectChange(newSelection, data)
    }
  }

  return (
    <div className="LiveEventsCalendarAdditionalDataSelector">
      <GRAccordion
        expanded={liveEventAdditionalDataListOpen}
        onChange={() => setLiveEventAdditionalDataListOpen(!liveEventAdditionalDataListOpen)}
        title={t('live-events:live_events_additional_data_toggles_title')}
        extra={
          <AllOrNothingSelector
            someSelected={Object.keys(selectedCalendarAdditionalDataIdsMap).length > 0}
            deselectAll={onAdditionalDataSelectionCleared}
            selectAll={onAdditionalDataSelectAll}
            disabled={isLoading || !liveEventAdditionalDataListOpen || subpage !== LiveEventTrackerTab.Games}
          />
        }
      >
        <div className="LiveEventsCalendarAdditionalDataSelector__Chips">
          {calendarAdditionalDatas.map((data) => {
            const disabled = resolveAdditionalDataDisabledState(subpage, data.id)
            return (
              <Chip
                key={data.id}
                label={t(data.name)}
                color={selectedCalendarAdditionalDataIdsMap[data.id] ? 'secondary' : 'default'}
                disabled={disabled}
                onClick={() => handleAdditionalDataClick(data)}
              />
            )
          })}
          {isLoading && <GRCircularProgress my={2} />}
        </div>
      </GRAccordion>
    </div>
  )
}

export default LiveEventsCalendarAdditionalDataSelector
