import classNames from 'classnames'
import { CSSProperties, FC, memo, ReactNode } from 'react'

import { TableCell, TableCellProps } from '@mui/material'

import styles from './BodyCell.module.scss'

/**
 * Component representing body cells of a table
 */

type VirtualTableCellProps = {
  children?: ReactNode
  columnIndex: number
  cellProps?: TableCellProps
  grouping?: boolean
  style?: CSSProperties
}

const Component: FC<VirtualTableCellProps> = ({ columnIndex, cellProps, grouping, style, children }) => {
  const cellClasses = classNames(styles.root, { [styles.grouping]: grouping })
  return (
    <TableCell variant="body" align="center" {...cellProps} className={cellClasses} style={style}>
      {children}
    </TableCell>
  )
}

export const BodyCell = memo(Component) as typeof Component
