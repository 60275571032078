import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@mui/material'

import { useCurrentMarket } from '../../../markets'
import { useGameAnalysisOutdatedForMarketIsoCheck } from '../../hooks/gameHooks'
import { Game, GameArchiveStatus } from '../../types/Game'
import { GameAnalysisStatus } from '../../types/GameAnalysisStatus'
import SuggestAnalysisButton from '../SuggestAnalysisButton/SuggestAnalysisButton'
import './GameAnalysisOutdatedAlert.scss'

interface Props {
  game: Game
  marketIso: string
  gameAnalysisStatus: GameAnalysisStatus
  gameArchiveStatus: GameArchiveStatus
}

const GameAnalysisOutdatedAlert: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const gameAnalysisOutdated = useGameAnalysisOutdatedForMarketIsoCheck({ marketIso: mainMarketIso, game: props.game })

  const gameHasPowerscore = () => {
    return props.game.gpsPerMarket[props.marketIso] > 0
  }

  const showOutdatedAlert = (!gameHasPowerscore() || gameAnalysisOutdated) && props.gameArchiveStatus === GameArchiveStatus.active

  return (
    <>
      {showOutdatedAlert && (
        <Alert
          className="GameAnalysisOutdatedAlert"
          severity="error"
          variant="outlined"
          action={<SuggestAnalysisButton game={props.game}></SuggestAnalysisButton>}
        >
          {gameHasPowerscore() && t('common:game_analysis_might_be_outdated_text')}
          {!gameHasPowerscore() && t('common:game_not_analyzed_description')}
        </Alert>
      )}
    </>
  )
}

export default GameAnalysisOutdatedAlert
