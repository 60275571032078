import { t } from 'i18next'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Masonry } from '@mui/lab'
import { FormControlLabel, Checkbox, Box, Card, CardContent, Divider } from '@mui/material'

import { Tag } from '../../../../api/core'
import { AllOrNothingSelector } from '../../../../components/AllOrNothingSelector/AllOrNothingSelector'
import { GRAccordion } from '../../../../components/GRAccordion/GRAccordion'
import { useLiveEventsSecondaryTagGroups } from '../../hooks/liveEventsSecondaryTaxonomy'
import { LiveEventsTag } from '../../types/LiveEventsTag'
import './LiveEventsSecondaryTaxonomySelector.scss'

interface LiveEventsSecondaryTaxonomySelectorProps {
  selectableLiveEventSecondaryTags: LiveEventsTag[]
  selectedLiveEventSecondaryTags: Tag[]
  isLoading?: boolean
  open?: boolean
  onLiveEventsSecondaryTaxonomySelectChange: (selectedTags: Tag[], selection: { id: string; name: string }) => void
  onLiveEventsSecondaryTaxonomyCleared: () => void
  onLiveEventsSecondaryTaxonomySelectAll: () => void
}

const LiveEventsSecondaryTaxonomySelector: React.FC<LiveEventsSecondaryTaxonomySelectorProps> = ({
  selectableLiveEventSecondaryTags,
  selectedLiveEventSecondaryTags,
  open,
  isLoading,
  onLiveEventsSecondaryTaxonomySelectChange,
  onLiveEventsSecondaryTaxonomyCleared,
  onLiveEventsSecondaryTaxonomySelectAll,
}) => {
  const [liveEventsSecondaryTaxonomyOpen, setLiveEventsSecondaryTaxonomyOpen] = useState(open || false)
  const [selectedTags, setSelectedTags] = useState<LiveEventsTag[]>(selectedLiveEventSecondaryTags)
  const { data: liveEventSecondaryTagGroups } = useLiveEventsSecondaryTagGroups()

  const selectableLiveEventSecondaryTagGroups = useMemo(() => {
    return (
      liveEventSecondaryTagGroups
        // Filter out groups that have no selectable tags
        ?.filter((group) => {
          return group.tags.some((tagId) => {
            return selectableLiveEventSecondaryTags?.some((tag) => tag.id === tagId)
          })
        })
        .map((group) => {
          return {
            ...group,
            tags: group.tags
              // Filter out tags that are not selectable
              .filter((tagId) => {
                return selectableLiveEventSecondaryTags?.some((tag) => tag.id === tagId)
              })
              // set checked state for tags
              .map((tagId) => {
                const tag = selectableLiveEventSecondaryTags?.find((tag) => tag.id === tagId)
                return {
                  ...tag,
                  checked: selectedTags.some((selectedTag) => selectedTag.id === tagId),
                }
              }),
          }
        })
        // Set checked and indeterminate state for groups
        .map((group) => {
          const checkedTags = group.tags.filter((tag) => tag.checked)
          return {
            ...group,
            checked: checkedTags.length === group.tags.length,
            indeterminate: checkedTags.length > 0 && checkedTags.length < group.tags.length,
          }
        })
        // sort groups by order
        .sort((a, b) => a.order - b.order)
    )
  }, [liveEventSecondaryTagGroups, selectableLiveEventSecondaryTags, selectedTags])

  // Handle tag selection logic here
  const handleSelectTag = useCallback(
    (groupId: string, tagId: string) => {
      const group = selectableLiveEventSecondaryTagGroups?.find((group) => group.id === groupId)
      const tag = selectableLiveEventSecondaryTags?.find((tag) => tag.id === tagId)

      if (!group || !tag) return

      return (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked

        // Create a new array to represent the updated state
        const updatedTags = [...selectedTags]

        if (isChecked) {
          updatedTags.push(tag)
        } else {
          const index = updatedTags.findIndex((tag) => tag.id === tagId)
          updatedTags.splice(index, 1)
        }

        onLiveEventsSecondaryTaxonomySelectChange(updatedTags as Tag[], tag as Tag)

        // Update the state with the new array
        setSelectedTags(updatedTags)
      }
    },
    [onLiveEventsSecondaryTaxonomySelectChange, selectableLiveEventSecondaryTagGroups, selectableLiveEventSecondaryTags, selectedTags]
  )

  // Handle group selection logic here
  const handleSelectGroup = useCallback(
    (groupId: string) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked

        // Create a new array to represent the updated state
        const updatedTags = [...selectedTags]

        const group = liveEventSecondaryTagGroups?.find((group) => group.id === groupId)

        if (!group) return

        const changedTags: LiveEventsTag[] = []

        if (isChecked) {
          // Add all tags from the group to the updated state
          group.tags.forEach((tagId) => {
            const tag = selectableLiveEventSecondaryTags?.find((tag) => tagId === tag.id)
            if (tag && !updatedTags.some((updatedTag) => updatedTag.id === tag.id)) {
              updatedTags.push(tag)
              changedTags.push(tag)
            }
          })
        } else {
          // Remove all tags from the group from the updated state
          group.tags.forEach((tagId) => {
            const index = updatedTags.findIndex((tag) => tagId === tag.id)

            if (index === -1) return // do nothing if the tag is not in the array

            updatedTags.splice(index, 1)

            const tag = selectableLiveEventSecondaryTags?.find((tag) => tagId === tag.id)
            if (tag) {
              changedTags.push(tag)
            }
          })
        }

        // Loop through chanhed tags and call onLiveEventsSecondaryTaxonomySelectChange for each
        changedTags.forEach((tag) => {
          onLiveEventsSecondaryTaxonomySelectChange(updatedTags as Tag[], tag as Tag)
        })

        // Update the state with the new array
        setSelectedTags(updatedTags)
      }
    },
    [liveEventSecondaryTagGroups, onLiveEventsSecondaryTaxonomySelectChange, selectableLiveEventSecondaryTags, selectedTags]
  )

  useEffect(() => {
    setSelectedTags(selectedLiveEventSecondaryTags)
  }, [selectedLiveEventSecondaryTags])

  return (
    <div className="LiveEventsSecondaryTaxonomySelector">
      <GRAccordion
        title={t('live-events:live_events_taxonomy')}
        expanded={liveEventsSecondaryTaxonomyOpen}
        onChange={() => setLiveEventsSecondaryTaxonomyOpen(!liveEventsSecondaryTaxonomyOpen)}
        extra={
          <AllOrNothingSelector
            someSelected={selectedTags.length > 0}
            deselectAll={onLiveEventsSecondaryTaxonomyCleared}
            selectAll={onLiveEventsSecondaryTaxonomySelectAll}
            disabled={isLoading || !liveEventsSecondaryTaxonomyOpen}
          />
        }
      >
        {!isLoading && selectableLiveEventSecondaryTagGroups?.length && selectableLiveEventSecondaryTags?.length && (
          <Masonry columns={4} spacing="1">
            {selectableLiveEventSecondaryTagGroups.map((group) => (
              <Card elevation={0} key={group.id} className="groupCard">
                <CardContent className="header">
                  <FormControlLabel
                    control={<Checkbox size="small" onChange={handleSelectGroup(group.id)} checked={group.checked} indeterminate={group.indeterminate} />}
                    label={group.name}
                    className="tagGroup"
                  />
                </CardContent>
                <Divider sx={{ marginBottom: '5px', marginTop: 0 }} variant="fullWidth" light />
                <CardContent className="content">
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 0 }}>
                    {group.tags.map((tag) => (
                      <FormControlLabel
                        className="tag"
                        key={tag.id}
                        control={<Checkbox size="small" onChange={handleSelectTag(group.id, tag.id || '')} checked={tag.checked} />}
                        label={tag.name || ''}
                      />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Masonry>
        )}

        {isLoading && <div>Loading...</div>}
      </GRAccordion>
    </div>
  )
}

export default LiveEventsSecondaryTaxonomySelector
