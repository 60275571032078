import { useEffect, useState } from 'react'

const INTERCOM_CHECK_INTERVAL = 1000 // 1 second interval for checking

const useIntercomBootStatus = () => {
  const [isIntercomBooted, setIsIntercomBooted] = useState<boolean>(false)

  useEffect(() => {
    const checkIntercomBootStatus = () => {
      if (window.Intercom?.booted) {
        setIsIntercomBooted(true)
      }
    }

    // Check the boot status initially
    checkIntercomBootStatus()

    // If Intercom is not booted, set up a periodic check
    if (!isIntercomBooted) {
      const intervalId = setInterval(checkIntercomBootStatus, INTERCOM_CHECK_INTERVAL)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [isIntercomBooted])

  return isIntercomBooted
}

export default useIntercomBootStatus
