import { FC, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CardContent, CardHeader, Grid } from '@mui/material'

import { CounterButton } from '../../../../components/CounterButton/CounterButton'
import { RoleEnum } from '../../../account'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { Game, GameIcon } from '../../../game'
import { AppType } from '../../../game/types/Game'
import { useTrackedGames } from '../../hooks/useTrackedGames'
import { TrackedGame } from '../../types/TrackedGame'
import { TrackedGamesSearchDialog } from '../TrackedGamesSearchDialog/TrackedGamesSearchDialog'

/**
 * Card component for displaying selected tracked games
 */
type LiveEventsGameSelectorProps = {
  selectedGames: TrackedGame[]
  onGameSelectionChange?: (games: TrackedGame[], hdGameSelected: boolean) => void
  children?: ReactNode
}

export const LiveEventsGameSelector: FC<LiveEventsGameSelectorProps> = ({ selectedGames, onGameSelectionChange, children }) => {
  const { t } = useTranslation()
  const liveEventsTrackerUnlocked = useRoleCheck(RoleEnum.live_events_tracker)
  const [gameSearchDialogOpen, setGameSearchDialogOpen] = useState<boolean>(false)
  const trackedGames = useTrackedGames({})

  const handleClose = useCallback(
    (games?: Game[]) => {
      const gamesAsTrackedGames: TrackedGame[] = (games
        ?.map((game) => trackedGames.data?.find((trackedGame) => trackedGame.game.id === game.id))
        .filter((trackedGame) => !!trackedGame) || []) as TrackedGame[]
      const hdGameSelected = gamesAsTrackedGames.some((game) => game.game.appType === AppType.PC_CONSOLE) //NOTE: Remove hd game check when we want to release LET Price Points for mobile
      onGameSelectionChange?.(gamesAsTrackedGames, hdGameSelected)
      setGameSearchDialogOpen(false)
    },
    [onGameSelectionChange, trackedGames.data]
  )

  const handleOpenDialog = useCallback(() => setGameSearchDialogOpen(true), [])

  const isLoading = trackedGames.isLoading || trackedGames.isFetching

  return (
    <>
      <CardHeader title={t('live-events:game_selector_title')} subheader={t('live-events:game_selector_subheader')}></CardHeader>
      <CardContent>
        <Grid container alignItems="flex-start" justifyContent="space-between" rowSpacing={2} columnSpacing={6} wrap="wrap">
          <Grid item>
            <CounterButton
              onClick={handleOpenDialog}
              label={t('live-events:game_selector_edit_games')}
              count={selectedGames.length}
              loading={isLoading}
              disabled={isLoading || !liveEventsTrackerUnlocked}
            />
          </Grid>
          <Grid item flex="1 1 auto">
            <Grid container wrap="wrap" spacing={2}>
              {selectedGames.map((trackedGame) => (
                <Grid item key={trackedGame.game.id}>
                  <GameIcon
                    src={trackedGame.game.getIcon() as string}
                    gameName={trackedGame.game.resolvedName}
                    size="normal"
                    key={trackedGame.game.id}
                    appType={trackedGame.game.appType}
                    gamePlatforms={trackedGame.game.platforms}
                  ></GameIcon>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>

      {gameSearchDialogOpen && (
        <TrackedGamesSearchDialog
          open={gameSearchDialogOpen}
          onClose={handleClose}
          selectedGames={selectedGames.reduce((acc, { game }) => {
            acc[game.id] = game
            return acc
          }, {} as { [gameId: string]: Game })}
        />
      )}
    </>
  )
}
