import React from 'react'

import { LockOutlined } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { MaskedText } from '../../../../components/MaskedText/MaskedText'
import './LimitedCardHeader.scss'

/**
 * LimitedCardHeader show News Card with masked information
 */
interface LimitedCardHeaderProps {
  name: string
  artist: string
}
const LimitedCardHeader: React.FC<LimitedCardHeaderProps> = ({ name, artist }) => {
  return (
    <Grid container columnSpacing={1}>
      <Grid item>
        <div className="LimitedCardHeader__icon">
          <LockOutlined fontSize="large" />
        </div>
      </Grid>
      <Grid item>
        <MaskedText length={name?.length} />
        <MaskedText length={artist?.length} height="12px" />
      </Grid>
    </Grid>
  )
}

export default LimitedCardHeader
