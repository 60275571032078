import { t } from 'i18next'
import querystring from 'query-string'
import React, { useEffect } from 'react'

import { Box } from '@mui/material'

import { useGetSlackAuthorizationTokenMutation } from '../../../api/feed'
import { useAppDispatch } from '../../../hooks/storeHooks'
import analyticsService, { ITrackEventOptions } from '../../../services/AnalyticsService'
import { AnalyticsProviders } from '../../../types/IAnalyticsProvider'
import { displaySnackBar } from '../../snackbar'

/**
 * SlackButton component to redirect to Slack integration
 */

const SlackButton: React.FC = () => {
  const [getToken, response] = useGetSlackAuthorizationTokenMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const token = response.data
    if (token) {
      const authUrl = querystring.stringifyUrl({
        url: 'https://slack.com/oauth/authorize',
        query: {
          scope: 'incoming-webhook',
          client_id: '2648391293.564550880644',
          redirect_uri: `${window.GR_API_URLS.API_URL_FEED}/public/webhooks/authorize`,
          state: token,
        },
      })

      window.location.href = authUrl
      return
    }
    if (response.error) {
      dispatch(
        displaySnackBar({
          message: t('common:slack_redirect_error'),
          severity: 'error',
          open: true,
        })
      )
    }
  }, [dispatch, response.data, response.error])

  const handleSlackBtnClick = async () => {
    analyticsService.trackEvent('Slackbot button', { serviceToExclude: [AnalyticsProviders.hubspot, AnalyticsProviders.intercom] } as ITrackEventOptions)
    getToken()
  }

  return (
    <Box sx={{ cursor: 'pointer', mt: '4px' }} onClick={handleSlackBtnClick}>
      <img
        style={{ width: '110px' }}
        alt="Add to Slack"
        src="https://platform.slack-edge.com/img/add_to_slack.png"
        srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
      />
    </Box>
  )
}

export default SlackButton
