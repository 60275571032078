import { useMemo } from 'react'

import { intersection } from '../../../helpers/intersection'
import { isNil } from '../../../helpers/isNil'
import { VersionTagFeature } from '../../../types/VersionTag'
import { FeatureChoiceMap } from '../../market-explorer/types/MarketExplorerSegmentConfiguration'
import { UpdateImpactAggregates } from '../services/UpdateImpactAggregates'
import { AggregateValueColumnFilterName, EMPTY_AGGREGATE_VALUE, FeaturesFilterValue } from '../types/ColumnFilter'
import { ComparisonInterval } from '../types/ComparisonInterval'
import { TableRowUpdateImpact } from '../types/TableRowUpdateImpact'
import { UpdateImpactRowFilters } from '../types/UpdateImpactRowFilters'

export const isFiltersNotEmpty = (filters: UpdateImpactRowFilters) => {
  return (
    (!!filters.downloads && Object.values(filters.downloads).some((value) => value !== EMPTY_AGGREGATE_VALUE)) ||
    (!!filters.revenue && Object.values(filters.revenue).some((value) => value !== EMPTY_AGGREGATE_VALUE)) ||
    (!!filters.revenueDownloadsRatio && Object.values(filters.revenueDownloadsRatio).some((value) => value !== EMPTY_AGGREGATE_VALUE)) ||
    (!!filters.features && filters.features.featureType !== FeaturesFilterValue.Any) ||
    (!!filters.versionTags && filters.versionTags.length > 0)
  )
}

/**
 * Hook that filters given update impacts with table and column filters
 */
export const useUpdateImpactFilters = (updateImpacts: TableRowUpdateImpact[], filters: UpdateImpactRowFilters) => {
  return useMemo(() => {
    let filteredUpdateImpacts
    const hasFeatureVersionTagFilter = intersection(VersionTagFeature, filters.versionTags?.map((versionTag) => versionTag.id) || []).length !== 0

    // filter by subgenres
    filteredUpdateImpacts =
      filters.subgenres && Object.entries(filters.subgenres).length > 0
        ? updateImpacts.filter((impact) => filters.subgenres && filters.subgenres[impact.conventionalSubgenreId])
        : updateImpacts

    // filter by version tags
    filteredUpdateImpacts =
      filters.versionTags && filters.versionTags.length > 0
        ? filteredUpdateImpacts.filter((impact) => {
            const { updatedFeatures, changedFeatures } = impact
            const matchesVersionTags = !!filters.versionTags?.find((tag) => impact.versionTags.find((impactTag) => tag.id === impactTag.id))

            // if Feature tag is selected as filter show impacts with updated or changed features even if the impact does not contain the version tag
            if ((updatedFeatures.length > 0 || changedFeatures.length > 0) && hasFeatureVersionTagFilter) {
              return matchesVersionTags || true
            }

            // if Feature tag is selected as filter hide impacts that do not have updated and changed features
            if (updatedFeatures.length === 0 && changedFeatures.length === 0 && hasFeatureVersionTagFilter) {
              return matchesVersionTags || false
            }

            return matchesVersionTags
          })
        : filteredUpdateImpacts

    // filter by column filters
    const aggregateValueFilter = filterByAggregateValue(filters, filters.comparisonInterval)
    filteredUpdateImpacts = filteredUpdateImpacts
      .filter((impact) => aggregateValueFilter(impact, AggregateValueColumnFilterName.revenue, 'revenueTrend', 'percentChange'))
      .filter((impact) => aggregateValueFilter(impact, AggregateValueColumnFilterName.revenue, 'revenueImpact', 'valueChange'))
      .filter((impact) => aggregateValueFilter(impact, AggregateValueColumnFilterName.downloads, 'downloadsTrend', 'percentChange'))
      .filter((impact) => aggregateValueFilter(impact, AggregateValueColumnFilterName.downloads, 'downloadsImpact', 'valueChange'))
      .filter((impact) => aggregateValueFilter(impact, AggregateValueColumnFilterName.revenueDownloadsRatio, 'revenueAndDownloadsRatioTrend', 'percentChange'))
      .filter((impact) => aggregateValueFilter(impact, AggregateValueColumnFilterName.revenueDownloadsRatio, 'revenueAndDownloadsRatio', 'valueChange'))
      .filter((impact) => filterByFeatures(impact, filters.features?.selectedFeatures, filters.features?.featureType))
      .filter((impact) => filterByFeatureType(impact, filters.features?.featureType))

    if (isFiltersNotEmpty(filters)) {
      filteredUpdateImpacts = filteredUpdateImpacts.filter((impact) => !impact.isFirstVersion && !impact.isTooOld)
    }

    return { filteredUpdateImpacts }
  }, [filters, updateImpacts])
}

const filterByAggregateValue =
  (filters: UpdateImpactRowFilters, comparisonInterval?: ComparisonInterval) =>
  (
    impact: TableRowUpdateImpact,
    columnName: AggregateValueColumnFilterName,
    aggregateValueName: keyof UpdateImpactAggregates,
    filterValueName: 'percentChange' | 'valueChange'
  ) => {
    const impactAggregates = new UpdateImpactAggregates(impact.aggregates, comparisonInterval)
    return filters[columnName] && !isNil(filters[columnName]?.[filterValueName]) && filters[columnName]?.[filterValueName] !== EMPTY_AGGREGATE_VALUE
      ? (impactAggregates[aggregateValueName] as number) > (filters[columnName]?.[filterValueName] as number)
      : true
  }

const filterByFeatureType = (impact: TableRowUpdateImpact, featureType?: FeaturesFilterValue) => {
  return featureType
    ? (featureType === FeaturesFilterValue.Changed && impact.changedFeatures.length > 0) ||
        (featureType === FeaturesFilterValue.Updated && impact.updatedFeatures.length > 0) ||
        featureType === FeaturesFilterValue.Any
    : true
}

const filterByFeatures = (impact: TableRowUpdateImpact, selectedFeatures?: FeatureChoiceMap, featureType?: FeaturesFilterValue) => {
  if (selectedFeatures && Object.keys(selectedFeatures).length > 0) {
    const impactsWithMatchingChangedFeatures = impact.changedFeatures.filter((feature) => {
      if (featureType === FeaturesFilterValue.Updated) return false
      const featureMatch = Object.keys(selectedFeatures || {}).find((featureId) => feature.featureLegacyId === +featureId)
      const choiceMatch = featureMatch ? intersection(selectedFeatures[+featureMatch], [feature.choice1LegacyId, feature.choice2LegacyId]).length > 0 : false

      return choiceMatch
    })

    const impactsWithMatchingUpdatedFeatures = impact.updatedFeatures.filter((feature) => {
      if (featureType === FeaturesFilterValue.Changed) return false
      const featureMatch = Object.keys(selectedFeatures || {}).find((featureId) => feature.featureLegacyId === +featureId)
      const choiceMatch = featureMatch ? intersection(selectedFeatures[+featureMatch], [feature.choiceLegacyId]).length > 0 : false

      return choiceMatch
    })

    return impactsWithMatchingChangedFeatures.length > 0 || impactsWithMatchingUpdatedFeatures.length > 0
  }

  return true
}
