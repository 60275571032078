import { t } from 'i18next'
import { FC, useMemo } from 'react'

import { FormControl } from '@mui/material'

import { useGetTagListQuery } from '../../../../../../api/core'
import { SelectMenu } from '../../../../../../components/SelectMenu/SelectMenu'
import { insertAtIndex } from '../../../../../../helpers/arrays'
import { useGetTrackedGameEventsQuery } from '../../../../../api/internal'
import { useLiveEventsTrackingUserLanguage } from '../../../hooks/useLiveEventsTrackingUserLanguage'

/**
 * Component for displaying a select box for a tracked game's event types
 */
export const gameEventTypesSelectMenuAllValue = 'all'
export const GameEventTypesSelectMenu: FC<{ trackedGameId: string; value: string; onChange: (eventTypeId: string) => void }> = ({
  trackedGameId,
  value,
  onChange,
}) => {
  const userLanguage = useLiveEventsTrackingUserLanguage()
  const trackedGameEventsQuery = useGetTrackedGameEventsQuery(trackedGameId)
  const eventTagsQuery = useGetTagListQuery({ type: 'event', userLanguage })

  const allGameEventTypes = useMemo(() => {
    const gameEventTypeIds = trackedGameEventsQuery.data?.map((event) => event.typeId) || []
    const gameEventTypes = eventTagsQuery.data?.filter((tag) => gameEventTypeIds.includes(tag.id))
    return gameEventTypes
  }, [eventTagsQuery.data, trackedGameEventsQuery.data])

  const eventTypeOptions = useMemo(() => {
    const allEventOptions = allGameEventTypes?.map((tag) => ({ value: tag.id, name: tag.name })).sort((a, b) => a.name.localeCompare(b.name)) || []
    return insertAtIndex(allEventOptions, { name: t('internal-live-events:all_events_for_this_game_label'), value: gameEventTypesSelectMenuAllValue }, 0)
  }, [allGameEventTypes])

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <SelectMenu options={eventTypeOptions} onChange={(e) => onChange(e.target.value)} value={value} label={t('internal-live-events:event_type')} />
    </FormControl>
  )
}
