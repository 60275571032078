import React, { MouseEventHandler, useEffect, useState } from 'react'

import FeatureTagSelector, { FilterTag } from '../../../../components/FeatureTagSelector/FeatureTagSelector'
import { useFeatureTags } from '../../../feature/hooks/useFeatureTags'
import { EnrichedFeature } from '../../types/ImplementationExamples'

interface FilterImplementationProps {
  features?: EnrichedFeature[]
  onChangeEnabledFeatures: (featureLegacyIds?: number[]) => void
}

const FilterByTags: React.FC<FilterImplementationProps> = ({ features, onChangeEnabledFeatures }) => {
  const { data: tagGroups } = useFeatureTags()
  const [tags, setTags] = useState<FilterTag[]>([])
  useEffect(() => {
    const legacyIds: number[] = features?.map(({ legacyId }) => legacyId) || []
    if (!tags.length) {
      setTags(
        tagGroups
          ?.map((group) => group.tags)
          .flat()
          .filter((tag) => !!tag.targets.filter((target) => legacyIds.includes(target)).length)
          .map((tag) => ({ ...tag, selected: false })) ?? []
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagGroups?.length, features?.length])

  const onClearSelection: MouseEventHandler = () => {
    setTags(tags.map((tag) => ({ ...tag, selected: false })))
    onChangeEnabledFeatures(undefined)
  }

  const onToggleTag = (toggledTag: FilterTag) => {
    const newTags = [...tags]
    const existingTag = newTags.find((tag) => tag === toggledTag)
    if (existingTag) {
      existingTag.selected = !existingTag.selected
    }
    const enabledLegacyIds = newTags
      .filter(({ selected }) => selected)
      .map(({ targets }) => targets)
      .flat()

    setTags(newTags)
    onChangeEnabledFeatures(enabledLegacyIds)
  }

  return <FeatureTagSelector tags={tags} onToggleTag={onToggleTag} onClearSelection={onClearSelection} />
}

export default FilterByTags
