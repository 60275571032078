import { t } from 'i18next'
import querystring from 'query-string'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, CardContent } from '@mui/material'

import { VersionTag, VersionTagFeature } from '../../../../types/VersionTag'
import { convertVersionTags } from '../../../../utils/convertVersionTags'
import { SubgenreMap } from '../../../account'
import { updateImpactsRootPath } from '../../../implementation-examples/hooks/useInitialState'
import { TopGame } from '../../../top-game/types/TopGame'
import { UpdateImpactsTableContainer } from '../../../update-impacts'
import { UpdateImpactRowFilters } from '../../../update-impacts/types/UpdateImpactRowFilters'
import GenreEssentialCardHeader, { GenreEssentialSection } from '../GenreEssentialCardHeader/GenreEssentialCardHeader'

/**
 * LatestGameUpdatesCard Latest update impact card shown in Genre essentials page
 */
interface LatestGameUpdatesCardProps {
  games: TopGame[]
  selectedSubgenres: SubgenreMap
}
const LatestGameUpdatesCard: React.FC<LatestGameUpdatesCardProps> = ({ games, selectedSubgenres }) => {
  const navigate = useNavigate()
  const [versionTags, setVersionTags] = useState<VersionTag[]>([])

  const handleOpenUpdateImpactsPage = () => {
    const redirectUrl = querystring.stringifyUrl({
      url: updateImpactsRootPath,
      query: {
        subgenres: Object.keys(selectedSubgenres).length ? Object.keys(selectedSubgenres).join(',') : undefined,
      },
    })

    navigate(redirectUrl)
  }

  const initialFilters: UpdateImpactRowFilters = useMemo(
    () => ({
      versionTags: [...convertVersionTags([...VersionTagFeature]), ...versionTags].filter((tag) => tag.name !== ''),

      subgenres: selectedSubgenres,
    }),
    [selectedSubgenres, versionTags]
  )

  const onFilterChange = useCallback((filters: UpdateImpactRowFilters) => {
    if (filters.versionTags) {
      setVersionTags(filters.versionTags)
    }
  }, [])

  return (
    <Card>
      <GenreEssentialCardHeader
        section={GenreEssentialSection.gameUpdateImpacts}
        onClick={handleOpenUpdateImpactsPage}
        title={t('genre-essentials:game_update_impacts_title')}
        description={t('genre-essentials:game_update_impacts_description')}
        buttonLabel={t('genre-essentials:open_in_game_update_impacts') as string}
      />
      <CardContent sx={{}}>
        <UpdateImpactsTableContainer initialFilters={initialFilters} maxRows={5} onFilterChange={onFilterChange} />
      </CardContent>
    </Card>
  )
}

export default LatestGameUpdatesCard
