import { t } from 'i18next'
import querystring from 'query-string'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BookType } from 'xlsx'

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TableContainer,
  Typography,
} from '@mui/material'

import { DetailedFeature, DetailedFeatureChoice, useGetFeatureFactsChoiceQuery } from '../../../../api/core'
import { ExportDataButton } from '../../../../components/ExportDataButton/ExportDataButton'
import { GRCountryFlag } from '../../../../components/GRCountryFlag/GRCountryFlag'
import { GREffectDots } from '../../../../components/GREffectDots/GREffectDots'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import GameSubgenrePicker, { SingleSubgenreSelection } from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import PowerScore from '../../../../components/Powerscore/Powerscore'
import { useExportDataFeatureFacts } from '../../../../hooks/exportDataHooks'
import languageService from '../../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../../account/hooks/useCurrentUserLanguage'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { generateExport } from '../../../export-data/util/workbook'
import { Game } from '../../../game'
import GameIcon from '../../../game/components/GameIcon/GameIcon'
import type { MarketTrendGraphData } from '../../../market-trends/components/MarketTrendGraph/MarketTrendGraph'
import { MarketTrendGraph } from '../../../market-trends/components/MarketTrendGraph/MarketTrendGraph'
import { movingAverage } from '../../../market-trends/util/calculations'
import { useCurrentMarket } from '../../../markets'
import { Choice } from '../FeatureLink'
import './FeatureFacts.scss'

interface FeatureFactsProps {
  feature?: DetailedFeature
  selectedSubgenre?: SingleSubgenreSelection
  defaultChoice?: Choice
  forceShowFeatureInList?: boolean //force to show features in list despite showInlists props
}

type Row = Pick<Game, 'appId' | 'artist' | 'conventionalSubgenre' | 'conventionalSubgenreId' | 'id' | 'icons' | 'resolvedName'> & {
  effect: number
  powerScore: number
}

/**
 * FeatureFactChoices show choices as options
 */
interface FeatureFactChoicesProps {
  defaultChoice?: DetailedFeatureChoice
  featureChoices: DetailedFeatureChoice[]
  onSelectChoice: (choice: DetailedFeatureChoice) => void
}
const FeatureFactChoices: React.FC<FeatureFactChoicesProps> = ({ defaultChoice, featureChoices, onSelectChoice }) => {
  const maxChoicesAmount = 5

  const filteredChoices = useMemo(() => featureChoices.filter((choice) => !['na', 'n/a'].includes(choice.choice.toLowerCase().trim())), [featureChoices])

  if (filteredChoices.length > maxChoicesAmount) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography display="inline" mr={1}>
          {t('common:choice')}:
        </Typography>
        <FormControl sx={{ minWidth: '300px' }}>
          <Select
            size="small"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={defaultChoice?.weightId || filteredChoices[0].weightId}
            onChange={(e) => {
              const selectedChoice = filteredChoices.find((featureChoice) => featureChoice.weightId === e.target.value)
              if (!!selectedChoice) {
                onSelectChoice(selectedChoice)
              }
            }}
          >
            {filteredChoices.map((choice) => (
              <MenuItem key={choice.weightId} value={choice.weightId}>
                {languageService.getTranslation('choices', choice.weightId.toString())}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  return (
    <>
      <Typography display="inline">{t('common:choice')}: </Typography>
      <ButtonGroup size="small" variant="outlined">
        {filteredChoices.map((choice) => (
          <Button key={choice.weightId} onClick={() => onSelectChoice(choice)} variant={choice.weightId === defaultChoice?.weightId ? 'contained' : 'outlined'}>
            {languageService.getTranslation('choices', choice.weightId.toString())}
          </Button>
        ))}
      </ButtonGroup>
    </>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
export const FeatureFacts: React.FC<FeatureFactsProps> = ({ feature, selectedSubgenre: initialSubgenre, defaultChoice, forceShowFeatureInList }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const containerRef = useRef(null)
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const userLanguage = useCurrentUserLanguage()
  const [selectedChoice, setSelectedChoice] = useState<DetailedFeatureChoice>()
  const [selectedSubgenre, setSelectedSubgenre] = useState<SingleSubgenreSelection | undefined>(initialSubgenre)

  const request = {
    choiceId: selectedChoice?.weightId || 0,
    conventionalCategoryId: null,
    conventionalGenreId: null,
    conventionalSubgenreId: null,
    marketIso,
    storeId: feature?.stores[0] || '',
    userLanguage,
    ...selectedSubgenre,
  }
  const { data: factData, isFetching } = useGetFeatureFactsChoiceQuery(request, { skip: !feature || !selectedChoice })

  useEffect(() => {
    if (!feature || !feature.choices) {
      return
    }

    const filteredChoices = feature.choices
    if (!!defaultChoice) {
      const defaultSelectedChoice = filteredChoices.find((choice) => {
        return choice.name === defaultChoice.name
      })

      if (!!defaultSelectedChoice) {
        setSelectedChoice(defaultSelectedChoice)
        return
      }
    }
    const initialChoice = filteredChoices[0]
    setSelectedChoice(initialChoice)
  }, [feature])

  const graph: (MarketTrendGraphData & { rows: Row[] }) | undefined = useMemo(() => {
    if (!factData) {
      return undefined
    }
    const { effects, games, graph, minmaxFeature } = factData

    const avgEffect = minmaxFeature?.avgEffect
    const labels = graph.in20Percent.map(({ ts }) => ts)
    const topValues = movingAverage(
      14,
      0,
      graph.in20Percent.map(({ value }) => value)
    )
    const outValues = movingAverage(
      14,
      0,
      graph.out20Percent.map(({ value }) => value)
    )

    // Popularity, current values 14d rolling
    const top = topValues ? topValues[topValues.length - 1] : 0
    const out = outValues ? outValues[outValues.length - 1] : 0
    const datasets = [
      {
        backgroundColor: '#ffcc99',
        borderColor: '#bd65cf',
        data: topValues || [],
        label: t('common:top_20_popularity_percent_short'),
        yAxisID: 'y1',
      },
      {
        borderColor: '#c7d5e1',
        data: outValues || [],
        label: t('common:top_20_popularity_out_percent_short'),
        yAxisID: 'y1',
      },
    ]

    const rows: Row[] = games.map(({ appId, artist, conventionalSubgenre, conventionalSubgenreId, gpsPerMarket, id, icons, resolvedName }) => ({
      appId,
      artist,
      conventionalSubgenre,
      conventionalSubgenreId,
      effect: effects[appId],
      id,
      icons,
      resolvedName,
      powerScore: gpsPerMarket[marketIso],
    }))

    return {
      avgEffect,
      chart: { labels, datasets },
      gamesInSet: factData.games.length,
      popularity: { out, top },
      rows,
      minmaxFeature,
    }
  }, [factData, selectedChoice])

  const customProps = useMemo(() => ({}), [])

  const initialColumns: GRTableColumn<Row, typeof customProps>[] = [
    {
      accessor: ({ row }) => (
        <Link to={`/game/${row.id}`} className="GameIconName">
          <GameIcon gameName={row.resolvedName} src={row.icons && row.icons.length > 2 ? row.icons[1] : row.icons} />
          <Box className="GameName">
            <Typography color="primary">{row.resolvedName}</Typography>
            <Typography variant="h5">{row.artist}</Typography>
            <Chip label={row.conventionalSubgenre} />
          </Box>
        </Link>
      ),
      labelAccessor: t('common:name'),
      sortable: true,
      sortAccessor: 'resolvedName',
      headerCellProps: { sx: { minWidth: '320px', textAlign: 'left', marginLeft: '12px' } },
    },
    {
      labelAccessor: t('common:game_power_score'),
      accessor: ({ row }) => <PowerScore powerscore={row.powerScore} />,
      headerCellProps: { sx: { minWidth: '120px' } },
      sortable: true,
      sortAccessor: 'powerScore',
    },
    {
      accessor: ({ row }) => <GREffectDots effect={row.effect || 0} />,
      labelAccessor: t('feature:feature_effect_in_this_game'),
      sortable: true,
      sortAccessor: 'effect',
      sortOrder: SortOrder.DESC,
    },
  ]

  const [columns, setColumns] = useState(initialColumns)
  const [scaleYAxis, setScaleYAxis] = useState<boolean>(true)
  const handleColumnsUpdate = useCallback(setColumns, [])

  const onSelectChoice = (choice: DetailedFeatureChoice) => {
    setSelectedChoice(choice)
    const query = { ...querystring.parse(location.search), choice: choice.weightId }
    navigate({ search: querystring.stringify(query) }, { replace: true })
  }

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)

  const { exportRows } = useExportDataFeatureFacts(feature, selectedChoice, factData, isExporting)

  useEffect(() => {
    if (!isExporting || !feature || !factData || !exportRows.length) {
      return
    }
    setIsExporting(false)

    generateExport(exportFormat, exportRows, 'Feature Facts', `feature-${feature?.id}-popularity-history-${marketIso}`)
  }, [isExporting, exportFormat, exportRows, feature, marketIso, factData])

  const handleDataExport = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  if (!feature) {
    return <CircularProgress />
  }

  return (
    <Box className="FeatureModalContent">
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h3">
            {t('common:feature')}: {languageService.getTranslation('features', feature.legacyId.toString())}
          </Typography>
        </Grid>
        <Grid item textAlign="right">
          <Typography>
            {t('common:data_from_market') + ' '}
            <GRCountryFlag size="small" countryCode={marketIso} />
          </Typography>
        </Grid>
      </Grid>
      <Box my={2}>
        <FeatureFactChoices featureChoices={feature.choices} defaultChoice={selectedChoice} onSelectChoice={onSelectChoice} />
      </Box>
      <Divider sx={{ mb: 2 }} />
      <GameSubgenrePicker selectedSubgenres={{}} selectedSubgenre={selectedSubgenre} singleSelectionChanged={setSelectedSubgenre} showSubgenreDefinitionBtn />
      <Divider />
      <Grid container alignItems="center">
        <Grid item xs={8}>
          <Typography variant="h3" mt={2} mb={1}>
            {t('common:popularity')}
          </Typography>
        </Grid>
        <Grid item xs={4} mt={1} mb={1} textAlign="right">
          <FormControlLabel
            control={<Checkbox checked={scaleYAxis} onClick={() => setScaleYAxis(!scaleYAxis)} />}
            label={t<string>('trending-features:scale_y_axis')}
          />

          <ExportDataButton
            accessRoles={[RoleEnum.csv_export_features]}
            analyticsEventOrigin="Feature Facts Modal"
            onChooseFormat={handleDataExport}
            disabled={isFetching}
          />
        </Grid>
      </Grid>
      {!!graph && !isFetching ? (
        <>
          <MarketTrendGraph data={graph} scaleYAxis={scaleYAxis} topAmount={20} showDetailEffect={true} />
          <Typography
            variant="h3"
            mb={1}
            dangerouslySetInnerHTML={{ __html: t('feature:number_of_games_in_top_20_having_feature', { inside: graph?.gamesInSet }) }}
          />
        </>
      ) : (
        <Box mt={2} mb={3} textAlign="center">
          <CircularProgress />
        </Box>
      )}
      {graph?.rows && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="h3" mb={1}>
            {t('common:tooltip_top_20_percent_segment_games_with_feature')}
          </Typography>
          <Card>
            <TableContainer ref={containerRef}>
              <GRTable
                columns={columns}
                onColumnsUpdated={handleColumnsUpdate}
                rows={graph?.rows || []}
                scroller={containerRef}
                rowIdKey="appId"
                noRowsLabel={t('feature:this_choice_is_not_in_any_game_in_this_genre')}
              />
            </TableContainer>
          </Card>
        </>
      )}
    </Box>
  )
}
