import React from 'react'
import { useTranslation } from 'react-i18next'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import './GameSkillThinkingMeter.scss'

interface Props {
  sensomotoric: number
  cognitive: number
}

const GameSkillThinkingMeter: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  const getBarWidth = () => {
    let value = 0
    if (props.sensomotoric === props.cognitive) {
      value = 0
    } else {
      value = Math.abs((props.sensomotoric - props.cognitive) / 2)
    }

    return `${Math.round(value * 100)}%`
  }

  const getTooltipText = () => {
    if (props.sensomotoric === props.cognitive) {
      return t('common:no_leaning_towards_based_text')
    } else {
      return props.sensomotoric > props.cognitive ? t('common:towards_skill_based_text') : t('common:towards_thinking_based_text')
    }
  }

  return (
    <GRTooltip content={getTooltipText()}>
      <div className="GameSkillThinkingMeter">
        <div
          className={props.sensomotoric > props.cognitive ? 'GameSkillThinkingMeter__bar GameSkillThinkingMeter__bar--right' : 'GameSkillThinkingMeter__bar'}
          style={{ width: getBarWidth() }}
        ></div>
        <div className="GameSkillThinkingMeter__center-divider"></div>
        <div className="GameSkillThinkingMeter__text GameSkillThinkingMeter__left">S</div>
        <div className="GameSkillThinkingMeter__text GameSkillThinkingMeter__right">T</div>
      </div>
    </GRTooltip>
  )
}

export default GameSkillThinkingMeter
