import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import ChatBubbleIcon from '@mui/icons-material/ChatBubble'

import { GRTooltip } from '../GRTooltip/GRTooltip'
import { Magnify } from '../Magnify/Magnify'
import styles from './ReviewIndicator.module.scss'

/**
 * Component for rendering a review indicator icon
 */
type ReviewIndicatorProps = {
  onClick: () => void
  tooltip?: ReactNode
}

export const ReviewIndicator: FC<ReviewIndicatorProps> = ({ onClick, tooltip }) => {
  const { t } = useTranslation()
  const tooltipContent = tooltip ? tooltip : t('reviews:tooltip_open_analyst_review')

  return (
    <GRTooltip content={tooltipContent}>
      <Magnify>
        <ChatBubbleIcon className={styles.icon} fontSize="small" color="primary" onClick={onClick} />
      </Magnify>
    </GRTooltip>
  )
}
