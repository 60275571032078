import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { GRCountryFlag } from '../../../../components/GRCountryFlag/GRCountryFlag'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import analyticsService from '../../../../services/AnalyticsService'
import { AnalyticsProviders } from '../../../../types/IAnalyticsProvider'
import { RoleEnum } from '../../../account'
import { useMarketPermission } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/useCurrentUserLanguage'
import { useCurrentMarket } from '../../hooks/useCurrentMarket'
import { selectPublishedMarkets } from '../../selectors/selectors'
import { Market } from '../../types/Market'
import './MarketSelector.scss'

interface MarketSelectorProps {
  onChange?: (marketIso: string) => void
  value?: string
}
const MarketSelector: React.FC<MarketSelectorProps> = ({ onChange, value }) => {
  const { t } = useTranslation()

  const userLanguage = useCurrentUserLanguage()
  const markets: Array<Market> = useSelector(selectPublishedMarkets(userLanguage))

  const { currentMarketIso: mainMarketIso } = useCurrentMarket()

  const [selectedMarketIso, setSelectedMarketIso] = React.useState('')
  const marketPermission = useMarketPermission()
  const [isInitialState, setIsInitialState] = useState(false)

  useEffect(() => {
    setSelectedMarketIso(value || mainMarketIso)
  }, [selectedMarketIso, mainMarketIso, value])

  useEffect(() => {
    if (mainMarketIso && !isInitialState) {
      analyticsService.trackEvent('Selected Market Initial Load', {
        data: { marketIso: mainMarketIso },
        serviceToExclude: [AnalyticsProviders.intercom, AnalyticsProviders.hubspot],
      })
      setIsInitialState(true)
    }
  }, [isInitialState, mainMarketIso])

  const handleChange = (event: SelectChangeEvent) => {
    const marketIso = event.target.value as string
    analyticsService.trackEvent('Selected Market Changed', {
      data: { previousMarketIso: mainMarketIso, marketIso },
      serviceToExclude: [AnalyticsProviders.intercom, AnalyticsProviders.hubspot],
    })
    setSelectedMarketIso(marketIso)
    if (onChange) {
      onChange(marketIso)
    }
  }

  const renderValue = (value: string): React.ReactNode => {
    const selectedMarket = markets.find((market) => market.iso === value)
    return (
      <div>
        <GRCountryFlag countryCode={selectedMarket?.iso.toUpperCase() as string | ''} className="flag" />
        {selectedMarket?.iso.toUpperCase()}
      </div>
    )
  }

  const marketsHtml = useMemo(() => {
    return markets.map((market) => {
      const marketRole = `market_${market.iso}` as RoleEnum
      const hasMarketAccess = marketPermission?.includes(marketRole)

      if (hasMarketAccess) {
        return (
          <MenuItem disabled={!hasMarketAccess} className="MarketSelector__MenuItem" key={market.id} value={market.iso}>
            <GRCountryFlag countryCode={market.iso.toUpperCase()} className="flag" />
            {market.name}
          </MenuItem>
        )
      }

      return (
        <GRTooltip key={market.id} content={t('common:limited_functionality_banner')}>
          <MenuItem disabled={!hasMarketAccess} className="MarketSelector__MenuItem" key={market.id} value={market.iso}>
            <GRCountryFlag countryCode={market.iso.toUpperCase()} className="flag" />
            {market.name}
          </MenuItem>
        </GRTooltip>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markets, marketPermission, t, userLanguage])

  return (
    <div className="MarketSelector">
      <FormControl size="small" variant="outlined">
        <InputLabel htmlFor="market-selector-label">{t('common:market')}</InputLabel>
        <Select
          id="market-selector"
          labelId="market-selector-label"
          value={selectedMarketIso}
          onChange={handleChange}
          label={t('common:market')}
          renderValue={renderValue}
        >
          {marketsHtml}
        </Select>
      </FormControl>
    </div>
  )
}

export default MarketSelector
