import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'

import { HighlightOff } from '@mui/icons-material'
import { Box, Button, CircularProgress } from '@mui/material'

import { compareNames, Tag } from '../../api/core'

export type FilterTag = Tag & { selected?: boolean }

interface Props {
  tags?: FilterTag[]
  onToggleTag: (tag: FilterTag) => void
  onClearSelection: MouseEventHandler
}

const FeatureTagSelector: React.FC<Props> = ({ tags, onToggleTag, onClearSelection }) => {
  const { t } = useTranslation()

  return tags ? (
    <Box sx={{ mt: 1 }} className="implementationTags">
      {tags.sort(compareNames).map((tag, j) => (
        <Button size="small" variant={tag.selected ? 'contained' : 'outlined'} color="secondary" key={j} onClick={() => onToggleTag(tag)}>
          {tag.name}
        </Button>
      ))}
      <Button
        style={{ marginLeft: 10 }}
        size="small"
        color="warning"
        startIcon={<HighlightOff />}
        onClick={onClearSelection}
        disabled={!tags.find(({ selected }) => selected)}
      >
        {t('tags-list:tags_clear_selection')}
      </Button>
    </Box>
  ) : (
    <Button disabled variant="outlined" color="secondary" size="small">
      {t('common:loading')}
      <CircularProgress size={12} sx={{ ml: 1 }} />
    </Button>
  )
}

export default FeatureTagSelector
