import { useMemo } from 'react'

import { useGetUserSettingQuery, useUpdateUserSettingMutation } from '../../../api/combined'
import { UserSettingKeys, UserSettings } from '../../account/types/UserSettings'
import { TrackedGame } from '../types/TrackedGame'

export const useLiveEventsSelectedGameIdsSetting = () => {
  const query = useGetUserSettingQuery(UserSettingKeys.liveEventsTrackerSelectedGameIds)

  return {
    ...query,
    data: query.data as UserSettings[UserSettingKeys.liveEventsTrackerSelectedGameIds],
  }
}

export const useUpdateLiveEventsSelectedGameIdsSetting = () => {
  const [updateUserSetting, query] = useUpdateUserSettingMutation()

  return useMemo(
    () => ({
      updateLiveEventsSelectedGameIdsSetting: (trackedGames: TrackedGame[]) =>
        updateUserSetting({ settingKey: UserSettingKeys.liveEventsTrackerSelectedGameIds, value: trackedGames.map((trackedGame) => trackedGame.game.id) }),
      ...query,
    }),
    [query, updateUserSetting]
  )
}
