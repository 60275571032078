import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'

import { useGameFollow } from '../../../game/hooks/gameHooks'
import { Game } from '../../../game/types/Game'

interface Props {
  game: Game
  onClick?: (game: Game) => void
}

const GameSearchResultFollowGameButton: React.FC<Props> = ({ game, onClick }) => {
  const { t } = useTranslation()
  const { toggleFollow: toggleGameFollow } = useGameFollow(game)

  const handleClick = () => {
    toggleGameFollow()
    if (onClick) {
      onClick(game)
    }
  }

  return (
    <Button size="small" variant="contained" color="success" onClick={handleClick}>
      {t('game-header:follow')}
    </Button>
  )
}

export default GameSearchResultFollowGameButton
