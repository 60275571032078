import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { TableContainer, Box, Grid } from '@mui/material'
import { Truncate } from '@re-dev/react-truncate'

import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { pricePointCurrencySymbol } from '../../../../internal/features/live-events-tracking/components/PricePointContainer/PricePointSelectMenu/PricePointSelectMenu'
import { PricePoint } from '../../../../internal/features/live-events-tracking/types/PricePoint'
import { Currency } from '../../../../internal/types/Currency'
import { getPricePointUnitValue } from '../../utils/utils'
import './LiveEventPricePoints.scss'

interface LiveEventPricePointsProps {
  pricePoints: PricePoint[]
  currencies: Currency[] | undefined
}

const TruncatedText: React.FC<{ text: string }> = ({ text }) => {
  const [isTruncated, setIsTruncated] = useState(false)

  return (
    <Grid container direction="row" wrap="nowrap" sx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Grid item xs>
        <Truncate
          lines={3}
          onTruncate={(didTruncate) => {
            setIsTruncated(didTruncate)
          }}
        >
          {text}
        </Truncate>
      </Grid>
      {isTruncated && (
        <Grid item marginLeft={1}>
          <GRTooltip content={text}>
            <Info color="primary" />
          </GRTooltip>
        </Grid>
      )}
    </Grid>
  )
}

const LiveEventPricePoints: React.FC<LiveEventPricePointsProps> = ({ pricePoints, currencies }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const customProps = useMemo(() => ({ currencies }), [currencies])

  const pricePointColumns: GRTableColumn<PricePoint, typeof customProps>[] = useMemo(
    () => [
      {
        headerCellProps: { sx: { fontWeight: 700, textAlign: 'center', maxWidth: 20 } },
        labelAccessor: () => t('live-events:price_point'),
        accessor: ({ customTableProps, row }) => {
          if (row.currencyId) {
            const unitPrice = getPricePointUnitValue(row, customTableProps?.currencies || [])
            if (unitPrice) {
              return (
                <Grid container spacing={0.5} direction="column" sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item>{row.value + ' ' + unitPrice.currencyName}</Grid>
                  <Grid item>
                    {'≈ '}
                    <strong>
                      {pricePointCurrencySymbol}
                      {unitPrice.value}
                    </strong>
                  </Grid>
                </Grid>
              )
            }
          }

          // DEFAULT USD CASE
          return (
            <strong>
              {pricePointCurrencySymbol.toUpperCase()}
              {Math.round(row.value * 100) / 100}
            </strong>
          )
        },
        cellProps: { sx: { textAlign: 'center' } },
        sortable: true,
        sortAccessor: ({ customTableProps, row }) => {
          if (row.currencyId) {
            const unitPrice = getPricePointUnitValue(row, customTableProps?.currencies || [])
            if (unitPrice) {
              return unitPrice.value
            }
          }
          return row.value
        },
        sortOrder: SortOrder.ASC,
      },
      {
        headerCellProps: { sx: { fontWeight: 700, textAlign: 'center' } },
        labelAccessor: () => t('common:description'),
        accessor: ({ customTableProps, row }) => {
          return <TruncatedText text={row.description} />
        },
        cellProps: { align: 'left' },
        sortable: false,
      },
    ],
    [t]
  )
  const [columns, setColumns] = useState<GRTableColumn<PricePoint, typeof customProps>[]>(pricePointColumns)

  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<PricePoint, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <TableContainer component={Box} ref={containerRef} className="LiveEventPricePoints">
      <GRTable
        columns={columns}
        rows={pricePoints}
        customProps={customProps}
        scroller={containerRef}
        onColumnsUpdated={handleColumnsUpdate}
        striped
        gridlines
        rowIdKey={(row, index) => {
          return `${row.value}-${index}`
        }}
      />
    </TableContainer>
  )
}

export default LiveEventPricePoints
