import { Quarter } from './Quarter'

export class GameQuarter extends Quarter {
  revenueValue: number = 0
  downloadsValue: number = 0
  revenueTop500Value: number = 0
  downloadsTop500Value: number = 0
  revenuePercentage: number = 0
  downloadsPercentage: number = 0
}
