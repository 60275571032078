import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import AllInclusiveIcon from '@mui/icons-material/AllInclusive'
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material'

import { ExportDataDialog } from '../../../../components/ExportDataDialog/ExportDataDialog'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import { MenuButton } from '../../../../components/MenuButton/MenuButton'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { MarketExplorerSegmentConfiguration } from '../../types/MarketExplorerSegmentConfiguration'
import { MarketExplorerSegmentColorBadge } from '../MarketExplorerSegmentColorBadge/MarketExplorerSegmentColorBadge'

/**
 * Component for rendering an export menu button for all visible segments
 */
type ExportSegmentsButtonProps = {
  accessRoles?: RoleEnum[]
  segments: MarketExplorerSegmentConfiguration[]
  onExport: (format: BookType, segments: MarketExplorerSegmentConfiguration[], index?: number | undefined) => void
  children?: ReactNode
}

export const ExportSegmentsButton: FC<ExportSegmentsButtonProps> = ({ segments, onExport, accessRoles = [] }) => {
  const { t } = useTranslation()
  const hasAccessToDataExport = useRoleCheck(accessRoles)
  const [showLockedDialog, setShowLockedDialog] = useState(false)
  const [exportDialogOpen, setExportDialogOpen] = useState(false)
  const [selectedSegments, setSelectedSegments] = useState<MarketExplorerSegmentConfiguration[]>([])
  const [singleSegmentIndex, setSingleSegmentIndex] = useState<number | undefined>(undefined)

  const handleDataExport = (segments: MarketExplorerSegmentConfiguration[], index: number | undefined = undefined) => {
    if (!hasAccessToDataExport) {
      setShowLockedDialog(true)
      return
    }

    setSingleSegmentIndex(index)
    setSelectedSegments(segments)
    setExportDialogOpen(true)
  }

  const handleClose = () => {
    setShowLockedDialog(false)
  }

  return (
    <>
      <MenuButton label={t('export-csv:export_button_text')}>
        {segments.map((segment, index) => {
          return (
            segment.visible && (
              <MenuItem onClick={() => handleDataExport([segment], index)} key={index}>
                <ListItemIcon>
                  <MarketExplorerSegmentColorBadge orderNumber={index + 1} />
                </ListItemIcon>
                <ListItemText>{t('segment:segment')}</ListItemText>
              </MenuItem>
            )
          )
        })}
        {segments.length > 1 && (
          <MenuItem onClick={() => handleDataExport(segments)}>
            <ListItemIcon>
              <AllInclusiveIcon />
            </ListItemIcon>
            <ListItemText>
              {t('common:all')} {t('segment:segments')}
            </ListItemText>
          </MenuItem>
        )}
      </MenuButton>

      <LockedFeature.Dialog lockedFeatureId={LockedFeatureId.DataExport} onClose={handleClose} open={showLockedDialog} />
      {exportDialogOpen && (
        <ExportDataDialog
          component="Export Segments Button"
          onChooseFormat={(format) => onExport(format, selectedSegments, singleSegmentIndex)}
          onClose={() => setExportDialogOpen(false)}
        />
      )}
    </>
  )
}
