import { FC, ReactNode, useCallback } from 'react'

import { useAppSelector } from '../../../../hooks/storeHooks'
import { useRouteModal } from '../../../route-modals/hooks/useRouteModal'
import { EModalType } from '../../../route-modals/types/modalTypes'
import { EventDialogTab } from '../../hooks/useEventDialogTabs'
import { useTrackedGames } from '../../hooks/useTrackedGames'
import { selectHighlightedEvent } from '../../slices/liveEventModalSlice'
import { TrackedGame } from '../../types/TrackedGame'
import { TrackingEvent } from '../../types/TrackingEvents'
import { LiveEventsEventDialog } from './LiveEventsEventDialog'

export type LiveEventsEventDialogParams = {
  liveEventTypeId: string
  liveEventId: string
  trackedGameId: string
  tab: EventDialogTab
  screenshotIndex: number
}

type LiveEventsEventDialogContainerProps = LiveEventsEventDialogParams & {
  children?: ReactNode
}

const initialScreenshotIndex = 0

export const LiveEventsEventDialogContainer: FC<LiveEventsEventDialogContainerProps> = ({
  liveEventTypeId: eventTypeId,
  liveEventId: eventId,
  trackedGameId,
  tab = EventDialogTab.AllEvents,
  screenshotIndex = initialScreenshotIndex,
  children,
}) => {
  const { popModal, replaceModal } = useRouteModal()
  const trackedGames = useTrackedGames({})
  const trackedGame = trackedGames.data?.find((trackedGame) => trackedGame.game.id === trackedGameId)
  const highlightedEventId = useAppSelector(selectHighlightedEvent)

  const handleTabChange = useCallback(
    (tab: EventDialogTab) => {
      replaceModal({
        type: EModalType.LIVE_EVENT,
        params: {
          trackedGameId: trackedGameId,
          liveEventTypeId: eventTypeId,
          liveEventId: eventId,
          tab: tab,
          screenshotIndex: +screenshotIndex,
        },
      })
    },
    [replaceModal, trackedGameId, eventTypeId, eventId, screenshotIndex]
  )

  const handleEventChange = useCallback(
    (event: TrackingEvent) => {
      replaceModal({
        type: EModalType.LIVE_EVENT,
        params: {
          trackedGameId: trackedGameId,
          liveEventTypeId: event.typeId,
          liveEventId: event.eventId,
          tab: EventDialogTab.Description,
          screenshotIndex: initialScreenshotIndex,
        },
      })
    },
    [replaceModal, trackedGameId]
  )

  const handleTrackedGameChange = useCallback(
    (trackedGame: TrackedGame) => {
      replaceModal({
        type: EModalType.LIVE_EVENT,
        params: {
          trackedGameId: trackedGame.game.id,
          liveEventTypeId: eventTypeId,
          tab: EventDialogTab.AllEvents,
          screenshotIndex: initialScreenshotIndex,
        },
      })
    },
    [eventTypeId, replaceModal]
  )

  const handleScreenshotIndexChange = useCallback(
    (index: number) => {
      replaceModal({
        type: EModalType.LIVE_EVENT,
        params: {
          trackedGameId,
          liveEventTypeId: eventTypeId,
          liveEventId: eventId,
          tab,
          screenshotIndex: index,
        },
      })
    },
    [replaceModal, trackedGameId, eventTypeId, eventId, tab]
  )

  const handleClose = () => {
    popModal()
  }

  return (
    <LiveEventsEventDialog
      data={{
        eventId: eventId,
        eventTypeId: eventTypeId,
        trackedGame: trackedGame,
        tab: tab,
        highlightedEventId: highlightedEventId,
        screenshotIndex: +screenshotIndex,
      }}
      open
      onClose={handleClose}
      onEventChange={handleEventChange}
      onTabChange={handleTabChange}
      onTrackedGameChange={handleTrackedGameChange}
      onScreenshotIndexChange={handleScreenshotIndexChange}
    />
  )
}
