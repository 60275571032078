import React, { ReactNode, useMemo, useState } from 'react'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Card, Grid, IconButton } from '@mui/material'

import { AnalystOverviewDialog } from '../../../../components/AnalystOverviewDialog/AnalystOverviewDialog'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { Game } from '../../../game'
import { GameCardContent } from '../../../game/components/GameCard/GameCard'

/**
 * MustKnowGameRows
 */
interface MustKnowGameRowsProps {
  title: ReactNode
  games: Game[]
  isLoading?: boolean
}
const MustKnowGameRows: React.FC<MustKnowGameRowsProps> = ({ title, games: topGames, isLoading }) => {
  const maxInititalGames = 4
  const maxRowGame = 4
  const [open, setOpen] = useState(false)
  const [analystOverviewDialogGame, setAnalystOverviewDialogGame] = useState<Game | null>(null)
  const shortenGames = useMemo(() => {
    return open ? topGames.slice(0, maxInititalGames * maxRowGame) : topGames.slice(0, maxInititalGames)
  }, [topGames, open])

  return (
    <>
      <Grid container>
        {/* title */}
        <Grid item xs={2} pt={3}>
          {title}
        </Grid>
        {isLoading ? (
          <GRCircularProgress />
        ) : (
          <>
            {/* game list */}
            <Grid item container xs={9.5}>
              <Grid item container spacing={2} flexWrap="wrap">
                {shortenGames.map((game, index) => (
                  <Grid key={game.id} item xs={12 / maxInititalGames}>
                    <Card sx={{ px: 1, pt: 1, pb: 0.5 }}>
                      <GameCardContent game={game} rank={index + 1} onReviewClick={setAnalystOverviewDialogGame} />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* toggle */}
            <Grid item xs={0.5} sx={{ textAlign: 'right' }}>
              {topGames.length > maxInititalGames && (
                <IconButton
                  sx={{ mt: 3 }}
                  onClick={() => {
                    setOpen(!open)
                  }}
                >
                  {open ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
            </Grid>
          </>
        )}
      </Grid>
      {analystOverviewDialogGame && analystOverviewDialogGame.reviewId && (
        <AnalystOverviewDialog
          reviewId={analystOverviewDialogGame?.reviewId}
          gameName={analystOverviewDialogGame?.resolvedName}
          open={!!analystOverviewDialogGame}
          onClose={() => setAnalystOverviewDialogGame(null)}
        />
      )}
    </>
  )
}

export default MustKnowGameRows
