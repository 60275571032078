import { ChartOptions } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'

import { revenueData } from '../../../../../services/RevenueService'
import utilsService from '../../../../../services/UtilsService'

/**
 * GameRevenuePotentialChart
 */
interface GameRevenuePotentialChartProps {
  revenue?: revenueData
}
const GameRevenuePotentialChart: React.FC<GameRevenuePotentialChartProps> = ({ revenue }) => {
  const options: ChartOptions<'bar'> = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      x: {
        max: 100,
        ticks: {
          callback: (tickValue) => tickValue + '%',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        font: {
          weight: 'bold',
        },
        formatter: (value) => {
          return `          ${value}%`
        },
      },
      tooltip: {
        enabled: false,
      },
    },
  }

  const filteredLabels = useMemo(() => {
    if (!revenue) return
    return Object.keys(revenue).filter((key) => key !== 'Average' && key !== 'POWERSCORE')
  }, [revenue])

  const formattedLabels = useMemo(() => {
    if (!filteredLabels) return
    const formatLabels: { [key: string]: string } = {
      '200+': utilsService.formatCurrency(200000),
      '100-199': `${utilsService.formatCurrency(100000)}-${utilsService.formatCurrency(199000)}`,
      '70-99': `${utilsService.formatCurrency(70000)}-${utilsService.formatCurrency(99000)}`,
      '50-69': `${utilsService.formatCurrency(50000)}-${utilsService.formatCurrency(69000)}`,
      '30-49': `${utilsService.formatCurrency(30000)}-${utilsService.formatCurrency(49000)}`,
      '10-29': `${utilsService.formatCurrency(10000)}-${utilsService.formatCurrency(29000)}`,
      '2-9': `${utilsService.formatCurrency(2000)}-${utilsService.formatCurrency(9000)}`,
      '<2': `<${utilsService.formatCurrency(2000)}`,
    }
    return filteredLabels.map((label) => formatLabels[label])
  }, [filteredLabels])

  const datasets = useMemo(() => {
    if (!filteredLabels || !revenue) return []
    const data = filteredLabels.map((label) => revenue[label])
    return [
      {
        label: 'Dataset 1',
        data,
        borderColor: 'rgba(0,0,0,0)',
        backgroundColor: '#bd65cf',
      },
    ]
  }, [filteredLabels, revenue])

  if (!revenue) {
    return null
  }

  return <Bar plugins={[ChartDataLabels]} options={options} data={{ labels: formattedLabels, datasets }} />
}

export default GameRevenuePotentialChart
