import { BarChart, Hardware, InfoOutlined } from '@mui/icons-material'

import { TabOption } from '../types/feature-modal-tabs'

// feature tags selected by default
export const defaultTagSelection = [
  '57bed2f5bc85fa005b70baae',
  '57bed2f5bc85fa005b70baad',
  '57bed2f5bc85fa005b70bab1',
  '57bed2f5bc85fa005b70bab2',
  '57bed2f5bc85fa005b70bab0',
  '57bed2f5bc85fa005b70bab3',
  '58d23407049648f910dc002f',
  '57bed2f5bc85fa005b70baaf',
  '57bed2f5bc85fa005b70bab6',
  '57bed2f5bc85fa005b70bab7',
  '57bed2f5bc85fa005b70bab8',
  '57bed2f5bc85fa005b70babb',
  '5e9d6c603ef43b00be56f344',
  '57ea2512bc85fa524865eac3',
  '57bed2f5bc85fa005b70babd',
  '57c0128abc85fa29538b4fbc',
  '57bed2f5bc85fa005b70baa9',
]

export const tabIcons = {
  definition: <InfoOutlined />,
  facts: <BarChart />,
  implementation: <Hardware />,
} as { [tab in TabOption]: JSX.Element }
