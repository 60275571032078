import { Analyst } from '../../../types/Analyst'
import { TrackedGame } from './TrackedGame'
import { LiveEventReview, TrackingEvent } from './TrackingEvents'

export enum LiveEventFeedItemType {
  LiveEvent = 'LiveEvent',
  AnalystNote = 'AnalystNote',
}

export type LiveEventFeedItem = {
  id: string
  start: number
  end: number
  trackedGame?: TrackedGame
  trackingEvent?: TrackingEvent
  analystNote?: LiveEventReview
  type: LiveEventFeedItemType
  analyst?: Analyst
  new?: boolean
}
