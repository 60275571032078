import { FC, lazy, ReactNode, Suspense, useEffect, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField } from '@mui/material'

import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { UserLanguage } from '../../../../features/account/types/User'
import { useRequiredParams } from '../../../../hooks/useRequiredParams'
import languageService from '../../../../services/LanguageService'
import { InputTranslatorType } from '../../../types/InputTranslatorType'
import { ContentLanguageTranslation } from '../../../types/InternalAnalystComment'
import './InputTranslatorDialog.scss'

// Lazy loading HTMLEditor component to avoid including TinyMCE in the main bundle
const HTMLEditor = lazy(() => import('../../HTMLEditor/HTMLEditor').then((module) => ({ default: module.HTMLEditor })))

type InputTranslatorDialogProps = {
  defaultValues?: ContentLanguageTranslation | null
  open: boolean
  onClose: () => void
  onChange: (values: ContentLanguageTranslation) => void
  title: ReactNode
  inputType: InputTranslatorType
  renderAIAssistant?: () => ReactNode
  selectedLanguages?: UserLanguage[]
}

export const InputTranslatorDialog: FC<InputTranslatorDialogProps> = ({
  open,
  onClose,
  defaultValues = { en: '', ja: '', zh: '' },
  onChange,
  title,
  inputType,
  selectedLanguages = languageService.getSupportedLanguages(),
  renderAIAssistant,
}) => {
  const { t } = useTranslation()
  const { trackedGameId } = useRequiredParams<{ trackedGameId: string }>()
  const pluginRef = useRef<any>()
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<ContentLanguageTranslation>({
    defaultValues: defaultValues!,
  })

  const onSubmit: SubmitHandler<ContentLanguageTranslation> = (data) => {
    onChange(data)
    onClose()
  }

  const handleModalClose = (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!reason) {
      onClose()
    }
  }

  useEffect(() => {
    reset(defaultValues!)
  }, [defaultValues, reset, onClose])

  // lazy loading TinyMCE plugin
  useEffect(() => {
    const loadPlugins = async () => {
      const LiveEventLinkPluginModule = (await import('./LiveEventLinkPlugin')).default
      pluginRef.current = LiveEventLinkPluginModule
    }

    loadPlugins()
  }, [])

  // to enable content scrolling with autorresize plugin we need to set overflow to auto manually
  const setIframeHtmlScrollable = () => {
    document.querySelectorAll<HTMLIFrameElement>('iframe[id^=tiny]').forEach((iframe) => {
      const htmlnode = iframe.contentDocument?.querySelector('html')
      if (htmlnode) {
        htmlnode.style.overflow = 'auto'
      }
    })
  }

  return (
    <Dialog open={open} onClose={handleModalClose} fullWidth maxWidth="lg" disableEnforceFocus className="InputTranslatorDialog">
      <GRDialogTitle onClose={() => handleModalClose()}>
        <Box>
          {t('internal-live-events:enter')}
          <span style={{ color: '#bd65cf' }}>{title}</span> {t('internal-live-events:in_supported_languages')}
        </Box>
      </GRDialogTitle>
      <DialogContent dividers>
        <form id="input-translator-dialog-form" onSubmit={handleSubmit(onSubmit)}>
          {renderAIAssistant && (
            <Grid container spacing={2} wrap="wrap" direction="row" alignItems="flex-start" mb={2}>
              <Grid item sm={12} md={2}>
                <Trans i18nKey={`internal-common:ai-assist`} />
              </Grid>
              <Grid item sm={12} md={10}>
                {renderAIAssistant?.()}
              </Grid>
            </Grid>
          )}
          {selectedLanguages.map((language) => {
            switch (inputType) {
              case 'text':
                return (
                  <Grid container spacing={2} wrap="wrap" direction="row" alignItems="flex-start" key={language} mb={2}>
                    <Grid item sm={12} md={2}>
                      <Trans i18nKey={`internal-common:${language}`} />
                    </Grid>
                    <Grid item sm={12} md={10}>
                      <Controller
                        name={language}
                        control={control}
                        render={({ field }) => {
                          return <TextField type="text" value={field.value} onChange={field.onChange} variant="outlined" size="small" fullWidth></TextField>
                        }}
                      />
                    </Grid>
                  </Grid>
                )
              case 'wysiwyg':
                return (
                  <Grid
                    container
                    spacing={2}
                    wrap="wrap"
                    direction="row"
                    alignItems="flex-start"
                    key={language}
                    mb={2}
                    className="InputTranslatorDialog__wysiwyg-editor"
                  >
                    <Grid item sm={12} md={2}>
                      <Trans i18nKey={`internal-common:${language}`} />
                    </Grid>
                    <Grid item sm={12} md={10}>
                      <Controller
                        name={language}
                        control={control}
                        render={({ field }) => {
                          return (
                            <Suspense fallback={<GRCircularProgress />}>
                              <HTMLEditor
                                value={field.value}
                                onEditorChange={(editorName, editor) => field.onChange(editor.getContent())}
                                onInit={() => {
                                  setIframeHtmlScrollable()
                                }}
                                init={{
                                  setup: (editor) => pluginRef.current?.(),
                                  menubar: false,
                                  plugins: ['link', 'lists', 'searchreplace', 'code', 'autoresize', 'liveEventLink'],
                                  toolbar: 'undo redo removeformat | blocks blockquote | outdent indent | bold italic underline bullist | liveEventLink | code',
                                  convert_urls: false,
                                  block_formats: 'Paragraph=p;',
                                  trackedGameId,
                                }}
                              />
                            </Suspense>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                )
              default:
                return null
            }
          })}
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item>
            <Button form="input-translator-dialog-form" type="submit" variant="contained" color="primary" disabled={!isDirty}>
              {t('internal-common:done')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                handleModalClose()
              }}
              variant="text"
              size="small"
              color="primary"
            >
              {t('internal-common:cancel')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
