import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'

import { TabRoute, TabsRouter } from '../../components/TabsRouter/TabsRouter'
import FeatureTabView from '../../features/data-glossary/components/FeatureTabView/FeatureTabView'
import GenresTabView from '../../features/data-glossary/components/GenresTabView/GenresTabView'
import LiveEventTypesTabView from '../../features/data-glossary/components/LiveEventTypesTabView/LiveEventTypesTabView'
import MotivationsTabView from '../../features/data-glossary/components/MotivationsTabView/MotivationsTabView'
import TutorialVideosTabView from '../../features/data-glossary/components/TutorialVideosTabView/TutorialVideosTabView'
import { dataGlossaryRootPath } from '../../features/implementation-examples/hooks/useInitialState'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import analyticsService from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import './DataGlossaryPage.scss'

const tabs: { [key: string]: string } = {
  features: '/features',
  genres: '/genres',
  liveEventTypes: '/live-event-types',
  motivation: '/motivation',
  tutorialVideo: '/tutorial-videos',
}

const DataGlossaryPage: React.FC = () => {
  const { t } = useTranslation()
  usePage(PageService.getPageWithId('data-glossary'), 'Visited data glossary page')
  useDocumentTitle(t('sidebar:data_glossary'))
  const { pathname } = useLocation()
  const needsRedirect = pathname === dataGlossaryRootPath

  useEffect(() => {
    analyticsService.trackEvent('Visited Data Glossary')
  }, [])

  useEffect(() => {
    const featureTab = pathname.split('/')[2]
    if (!!featureTab) {
      for (const tab in tabs) {
        if (Object.prototype.hasOwnProperty.call(tabs, tab)) {
          const path = tabs[tab]
          if (path.includes(featureTab)) {
            analyticsService.trackEvent('Data Glossary: changed Tab', {
              data: {
                tab,
              },
            })
            break
          }
        }
      }
    }
  }, [pathname])

  return needsRedirect ? (
    <Navigate replace to={`${dataGlossaryRootPath}/features`} />
  ) : (
    <div className="DataGlossaryPage">
      <TabsRouter path="/data-glossary" className="DataGlossaryPage__tabs">
        <TabRoute label={t('data-glossary:features_tab')} path={tabs.features} route={`${tabs.features}/*`}>
          <FeatureTabView />
        </TabRoute>
        <TabRoute label={t('data-glossary:genres_tab')} path={tabs.genres} route={`${tabs.genres}/*`}>
          <GenresTabView />
        </TabRoute>
        <TabRoute label={t('data-glossary:live_event_types_tab')} path={tabs.liveEventTypes} route={`${tabs.liveEventTypes}/*`}>
          <LiveEventTypesTabView />
        </TabRoute>
        <TabRoute label={t('data-glossary:motivations_tab')} path={tabs.motivation} route={`${tabs.motivation}/*`}>
          <MotivationsTabView />
        </TabRoute>
        <TabRoute label={t('data-glossary:tutorial_videos_tab')} path={tabs.tutorialVideo} route={`${tabs.tutorialVideo}/*`}>
          <TutorialVideosTabView />
        </TabRoute>
      </TabsRouter>
    </div>
  )
}

export default DataGlossaryPage
