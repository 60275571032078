import { FC, ReactNode } from 'react'

import { InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material'

export type SelectMenuProps<T> = {
  label: ReactNode
  value: T | ''
  onChange: (event: SelectChangeEvent<T | ''>) => void
  options: SelectMenuOption<T | ''>[]
  disabled?: boolean
  required?: boolean
  autoWidth?: boolean
}

export type SelectMenuOption<T> = {
  name: ReactNode
  value: T
}

type SelectMenuType<T = any> = FC<SelectMenuProps<T>>

export const SelectMenu: SelectMenuType = ({ label, value, onChange, options, disabled, required, autoWidth = true }) => {
  return (
    <>
      <InputLabel required={required}>{label}</InputLabel>
      <Select required={required} onChange={onChange} size="small" value={value} label={label} disabled={disabled} autoWidth={autoWidth}>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
