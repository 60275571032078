import { SubgenreMap } from '../../account'
import { MarketExplorerGamesChartAxisTypeId } from '../types/MarketExplorerGamesChartAxisType'
import {
  DemographicsFilterField,
  FeatureChoiceMap,
  MarketExplorerSegmentConfiguration,
  MarketExplorerSegmentFilterValue,
  PlayerArchetypesFilterField,
  RanksFilterField,
} from '../types/MarketExplorerSegmentConfiguration'
import { LegacySegments, MarketExplorerSegmentsDTO } from '../types/MarketExplorerSegmentsDTO'
import { MarketExplorerUserSegment, MarketExplorerUserSegmentChartConfiguration } from '../types/MarketExplorerUserSegment'
import { MotivationType } from '../types/MotivationType'

/**
 * Mapper functions for mapping a user segment saved in user settings
 */

const mapPercentageValuesToModel = (valueArray: number[] | undefined) => {
  if (valueArray && valueArray.length === 2) {
    return [valueArray[0] / 100, valueArray[1] / 100]
  }
}

export const mapDTOToSegmentConfiguration = (dto: MarketExplorerSegmentsDTO): MarketExplorerUserSegment => {
  const segmentConfiguration = Object.values(LegacySegments).reduce((acc, segment, segmentIndex) => {
    const marketIso = dto.params[`${segment}`]
    if (marketIso) {
      acc[segmentIndex] = {
        visible: true,
        marketIso,
        filters: {
          ranks: Object.values(RanksFilterField).reduce((acc, field) => {
            if (field === RanksFilterField.DaysSinceRelease) {
              const value = dto.params[`${segment}daysSinceRelease`]?.split(',').map((val) => +val)
              if (!isDefaultValue(value, 0, 1735)) {
                acc[field] = value
              }
            } else {
              const value = dto.params[`${segment}${field}`]?.split(',').map((val) => +val)
              if (!isDefaultValue(value, 1, 1500)) {
                acc[field] = value
              }
            }

            return acc
          }, {} as { [key in RanksFilterField]?: MarketExplorerSegmentFilterValue }),
          demographics: Object.values(DemographicsFilterField).reduce((acc, field) => {
            const value = dto.params[`${segment}${field}`]?.split(',').map((val) => +val)
            if (!isDefaultValue(value, 0, 100)) {
              acc[field] = mapPercentageValuesToModel(value)
            }

            return acc
          }, {} as { [key in DemographicsFilterField]?: MarketExplorerSegmentFilterValue }),
          motivations: Object.values(MotivationType).reduce((acc, field) => {
            const value = dto.params[`${segment}motivations${getMotivationTypeEnumKeyByValue(field)}`]?.split(',').map((val) => +val)
            if (!isDefaultValue(value, 1, 5)) {
              acc[field] = value
            }

            return acc
          }, {} as { [key in MotivationType]?: MarketExplorerSegmentFilterValue }),
          archetypes: Object.values(PlayerArchetypesFilterField).reduce((acc, field) => {
            const value = dto.params[`${segment}playerArchetype${PlayerArchetypesFilterFieldToDTOFieldMap[field]}`]?.split(',').map((val) => +val)
            if (!isDefaultValue(value, 0, 100)) {
              acc[field] = mapPercentageValuesToModel(value)
            }

            return acc
          }, {} as { [key in PlayerArchetypesFilterField]?: MarketExplorerSegmentFilterValue }),
        },
        subgenres: dto.params[`${segment}subgenres`]?.split(',').reduce((acc, subgenreId) => ({ ...acc, [subgenreId]: true }), {} as SubgenreMap),
        gameIds: dto.params[`${segment}games`]?.split(','),
        featureChoices: dto.params[`${segment}features`]?.split(',').reduce((acc, featureId, index) => {
          const featureChoices = dto.params[`${segment}choices`]!.split(',')[index].split('-')
          if (featureId) {
            acc[+featureId] = featureChoices?.map((value) => !!value && +value).filter((value) => value) as number[]
          }
          return acc
        }, {} as FeatureChoiceMap),
        ownGames: Boolean(dto.params[`${segment}ownGames`]),
      }
    }

    return acc
  }, [] as MarketExplorerSegmentConfiguration[])

  const chartConfiguration: MarketExplorerUserSegmentChartConfiguration = {
    yAxisType: (dto.params.axisY || MarketExplorerGamesChartAxisTypeId.SGR) as MarketExplorerGamesChartAxisTypeId,
    yAxisMin: dto.params.axisYMin,
    yAxisMax: dto.params.axisYMax,
    xAxisType: (dto.params.axisX || MarketExplorerGamesChartAxisTypeId.RevenueDownloadsRatioPastMonth) as MarketExplorerGamesChartAxisTypeId,
    xAxisMin: dto.params.axisXMin,
    xAxisMax: dto.params.axisXMax,
  }

  return {
    name: dto.name,
    description: dto.description,
    segments: segmentConfiguration,
    chart: chartConfiguration,
  }
}

const isDefaultValue = (value: number[] | undefined, min: number, max: number) => {
  return value && value.length === 2 && value[0] === min && value[1] === max
}

type MotivationTypeKey = keyof typeof MotivationType

const getMotivationTypeEnumKeyByValue = (value: MotivationType): MotivationTypeKey => {
  const indexOfS = Object.values(MotivationType).indexOf(value as unknown as MotivationType)

  const key = Object.keys(MotivationType)[indexOfS]

  return key as MotivationTypeKey
}

type ArchetypeDTOField = 'Expressionist' | 'Thinker' | 'TreasureHunter' | 'Strategist' | 'SkillMaster' | 'ThrillSeeker' | 'KingOfTheHill' | 'Networker'
const PlayerArchetypesFilterFieldToDTOFieldMap: { [key in PlayerArchetypesFilterField]: ArchetypeDTOField } = {
  [PlayerArchetypesFilterField.MotivationPlayerArchetypeExpressionist]: 'Expressionist',
  [PlayerArchetypesFilterField.MotivationPlayerArchetypeThinker]: 'Thinker',
  [PlayerArchetypesFilterField.MotivationPlayerArchetypeTreasureHunter]: 'TreasureHunter',
  [PlayerArchetypesFilterField.MotivationPlayerArchetypeStrategist]: 'Strategist',
  [PlayerArchetypesFilterField.MotivationPlayerArchetypeSkillMaster]: 'SkillMaster',
  [PlayerArchetypesFilterField.MotivationPlayerArchetypeThrillSeeker]: 'ThrillSeeker',
  [PlayerArchetypesFilterField.MotivationPlayerArchetypeKingOfTheHill]: 'KingOfTheHill',
  [PlayerArchetypesFilterField.MotivationPlayerArchetypeNetworker]: 'Networker',
}
