import { FC } from 'react'

import PublicIcon from '@mui/icons-material/Public'
import { SvgIconProps } from '@mui/material'

type GlobalLaunchIndicatorProps = SvgIconProps

export const GlobalLaunchIndicator: FC<GlobalLaunchIndicatorProps> = (props) => {
  return <PublicIcon {...props} />
}
