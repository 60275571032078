import React from 'react'
import { useTranslation } from 'react-i18next'

import { Search } from '@mui/icons-material'
import { Autocomplete, Box, Grid, MenuItem, Select, TextField } from '@mui/material'

import { Game } from '../../features/game'

const sortOptions = ['name', 'sustained_grossing_rank', 'sustained_download_rank']

interface GameSearchAndSortProps {
  options?: Game[]
  sortValue: string
  onChangeSearch: (value: string | Game) => void
  onChangeSort: (value: string) => void
  hideAutoComplete?: boolean
  disabledSort?: boolean
}

const GameSearchAndSort: React.FC<GameSearchAndSortProps> = ({ options, onChangeSearch, onChangeSort, sortValue, hideAutoComplete, disabledSort }) => {
  const { t } = useTranslation()

  const onChange = (event: React.SyntheticEvent, value: string | Game | null) => {
    if (!value) {
      onChangeSearch('')
      return
    }
    onChangeSearch(value)
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Grid container columnSpacing={1}>
        <Grid item lg={9} sm={6} xs={12}>
          {!hideAutoComplete && (
            <Autocomplete
              freeSolo
              fullWidth
              getOptionLabel={(game) => (typeof game === 'string' ? game : game.resolvedName)}
              groupBy={({ artist }) => artist}
              multiple={false}
              onChange={onChange}
              options={options || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    <span>
                      <Search sx={{ verticalAlign: 'middle' }} /> {t('common:search_game_by_name_publisher')}
                    </span>
                  }
                />
              )}
              size="small"
            />
          )}
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <Select fullWidth size="small" onChange={({ target }) => onChangeSort(target.value)} defaultValue={'sustained_grossing_rank'} disabled={disabledSort}>
            {sortOptions.map((value, i) => (
              <MenuItem key={i} value={value}>{`${t('common:sort_by')} ${t('common:' + value)}`}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Box>
  )
}

export default GameSearchAndSort
