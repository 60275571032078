import keycloakService from '../services/KeycloakService'

// TODO-LATER-REFACTOR: put this in some logical place once we decide the project structure
export const prepareDefaultHeaders = async (headers: Headers = new Headers()) => {
  if (keycloakService.isTokenExpired()) {
    await keycloakService.updateToken()
    headers.set('Authorization', 'Bearer ' + keycloakService.token)
  } else {
    headers.set('Authorization', 'Bearer ' + keycloakService.token)
  }

  return headers
}
