import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Block } from '@mui/icons-material'

import { GRTooltip } from '../GRTooltip/GRTooltip'
import './NotAvailableIcon.scss'

interface Props {
  fontSize?: string
  tooltipContent?: ReactNode
}

const NotAvailableIcon: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()

  return (
    <div className="NotAvailableIcon">
      <GRTooltip content={props.tooltipContent ? props.tooltipContent : t('common:data_not_available_for_market')}>
        <Block sx={{ fontSize: props.fontSize ? props.fontSize : '18px' }} />
      </GRTooltip>
    </div>
  )
}

export default NotAvailableIcon
