import classNames from 'classnames'
import { FC } from 'react'

import GRImg from '../../../../components/GRImg/GRImg'
import './CategoryImage.scss'

type CategoryImageProps = {
  src: string
  alt: string
  size?: 'small' | 'normal'
}

export const CategoryImage: FC<CategoryImageProps> = ({ src, alt, size = 'normal' }) => {
  const classes = classNames('CategoryImage', size)
  return <GRImg src={src} alt={alt} className={classes} />
}
