import { Collection, Screenshot } from '../../../api/core'
import { FeatureGame, Game } from '../../game/types/Game'
import { getCombinedScreenshots } from './screenshotHelpers'

type GameComparatorConstructor = (marketIso: string, criteria: string) => (g1: Game, g2: Game) => number

const CollectionSortOrder = {
  RESTRICTED: 3,
  ORGANIZATION: 2,
  PUBLIC: 1,
}

export const CollectionVisibilityAndNameSorter = (c1: Collection, c2: Collection): number => {
  const o1 = CollectionSortOrder[c1.visibility]
  const o2 = CollectionSortOrder[c2.visibility]
  if (o2 === o1) {
    return CollectionNameSorter(c1, c2)
  }
  return o2 - o1
}

export const CollectionNameSorter = (c1: Collection, c2: Collection): number => {
  const n1 = c1.name?.toLowerCase()
  const n2 = c2.name?.toLowerCase()
  return n1 > n2 ? 1 : n2 > n1 ? -1 : 0
}

export const ScreenshotSorter = (s1?: Screenshot, s2?: Screenshot): number => {
  if (!s1?.versionReleaseDate && s2?.versionReleaseDate) {
    return 1
  }
  if (!s2?.versionReleaseDate && s1?.versionReleaseDate) {
    return -1
  }
  if (!s2?.versionReleaseDate && !s1?.versionReleaseDate) {
    return 0
  }
  return ((s2 as Screenshot).versionReleaseDate as number) - ((s1 as Screenshot).versionReleaseDate as number)
}

export const ScreenshotVersionSorter = (g1: FeatureGame, g2: FeatureGame): number => {
  return ScreenshotSorter(getCombinedScreenshots(g1)[0], getCombinedScreenshots(g2)[0])
}

export const gameComparator: GameComparatorConstructor = (marketIso, criteria) => {
  let getter: (game: Game) => number | string
  switch (criteria) {
    case 'sustained_grossing_rank':
      getter = ({ sranks }: Game) => sranks[marketIso]
      break
    case 'sustained_download_rank':
      getter = ({ sdranks }: Game) => sdranks[marketIso]
      break
    case 'name':
    default:
      getter = ({ resolvedName }: Game) => resolvedName
  }
  return (g1, g2) => {
    const v1 = getter(g1)
    const v2 = getter(g2)
    return v1 === v2 ? 0 : v1 < v2 ? -1 : 1
  }
}
