import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation } from 'react-router-dom'

import { TabRoute, TabsRouter } from '../../components/TabsRouter/TabsRouter'
import { CollectionPage } from '../../features/collections/'
import ImplementationExamples from '../../features/implementation-examples/components/ImplementationExamples/ImplementationExamples'
import usePage from '../../hooks/usePage'
import pageService from '../../services/PageService'
import './ImplementationExamplesPage.scss'

const ImplementationExamplesPage: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const needsRedirect = pathname === '/implementation-examples'

  usePage(pageService.getPageWithId('implementation-examples'), 'Visited Feature Implementation Examples')

  return needsRedirect ? (
    <Navigate replace to="/implementation-examples/examples" />
  ) : (
    <div className="ImplementationExamplesPage">
      <TabsRouter path="/implementation-examples">
        <TabRoute label={t('sidebar:impl_examples')} path="/examples" route="/examples/*">
          <ImplementationExamples />
        </TabRoute>
        <TabRoute label={t('implementations:label_collections')} path="/collections" route="/collections/*">
          <CollectionPage />
        </TabRoute>
      </TabsRouter>
    </div>
  )
}

export default ImplementationExamplesPage
