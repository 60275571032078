import { DemographicsDataKey } from '../features/demographics/types/DemographicsDataKey'
import { RevenueAndDownloadEstimates } from '../features/estimates/services/RevenueAndDownloadEstimates'
import { Game } from '../features/game'
import { EstimateTypes } from '../features/game/types/Game'
import { MarketExplorerGamesChartAxisGroup } from '../features/market-explorer/types/MarketExplorerGamesChartAxisGroup'
import { MarketExplorerGamesChartAxisGroupId } from '../features/market-explorer/types/MarketExplorerGamesChartAxisGroup'
import {
  MarketExplorerGamesChartAxisScale,
  MarketExplorerGamesChartAxisType,
  MarketExplorerGamesChartAxisTypeId,
} from '../features/market-explorer/types/MarketExplorerGamesChartAxisType'
import {
  MarketExplorerSegmentBreakdownDataKey,
  MarketExplorerSegmentBreakdownData,
  MarketExplorerSegmentBreakdownDataData,
} from '../features/market-explorer/types/MarketExplorerSegmentBreakdownData'
import {
  MarketExplorerSegmentBreakdownValueType,
  MarketExplorerSegmentBreakdownValueTypeId,
} from '../features/market-explorer/types/MarketExplorerSegmentBreakdownValueType'
import { MotivationType } from '../features/market-explorer/types/MotivationType'
import { PlayerArchetype } from '../features/market-explorer/types/PlayerArchetype'
import { average } from '../helpers/average'
import { median } from '../helpers/median'
import utilsService from './UtilsService'

const powerscoreMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 90,
    label: '90',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 30,
    label: '30',
  },
]

const powerscoreMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 100,
    label: '100',
  },
  {
    value: 90,
    label: '90',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 70,
    label: '70',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 40,
    label: '40',
  },
]

const skillThinkingMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 0,
    label: 'S',
  },
]

const skillThinkingMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 100,
    label: 'T',
  },
]

const rankMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 500,
    label: '500',
  },
  {
    value: 1000,
    label: '1000',
  },
  {
    value: 1000,
    label: '1000+',
    showOverChartNode: true,
  },
]

const rankMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 200,
    label: '200',
  },
  {
    value: 500,
    label: '500',
  },
]

const revenue30DaysMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 10000000,
    label: '$10M',
  },
  {
    value: 5000000,
    label: '$5M',
  },
  {
    value: 2500000,
    label: '$2.5M',
  },
  {
    value: 1000000,
    label: '$1M',
  },
  {
    value: 750000,
    label: '$750K',
  },
  {
    value: 500000,
    label: '$500K',
  },
  {
    value: 250000,
    label: '$250K',
  },
  {
    value: 100000,
    label: '$100K',
  },
  {
    value: 50000,
    label: '$50K',
  },
  {
    value: 15000,
    label: '$15K',
  },
  {
    value: 5000,
    label: '$5K',
  },
]

const revenue30DaysMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 20000000,
    label: '$20M+',
    showOverChartNode: true,
  },
  {
    value: 10000000,
    label: '$10M+',
    showOverChartNode: true,
  },
  {
    value: 5000000,
    label: '$5M+',
    showOverChartNode: true,
  },
  {
    value: 2500000,
    label: '$2.5M',
  },
  {
    value: 1000000,
    label: '$1M',
  },
  {
    value: 750000,
    label: '$750K',
  },
  {
    value: 500000,
    label: '$500K',
  },
  {
    value: 250000,
    label: '$250K',
  },
  {
    value: 100000,
    label: '$100K',
  },
  {
    value: 50000,
    label: '$50K',
  },
  {
    value: 15000,
    label: '$15K',
  },
]

const downloads30DaysMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 750000,
    label: '750K',
  },
  {
    value: 500000,
    label: '500K',
  },
  {
    value: 250000,
    label: '250K',
  },
  {
    value: 100000,
    label: '100K',
  },
  {
    value: 50000,
    label: '50K',
  },
  {
    value: 15000,
    label: '15K',
  },
]

const downloads30DaysMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 1000000,
    label: '1M+',
    showOverChartNode: true,
  },
  {
    value: 750000,
    label: '750K+',
    showOverChartNode: true,
  },
  {
    value: 500000,
    label: '500K+',
    showOverChartNode: true,
  },
  {
    value: 250000,
    label: '250K',
  },
  {
    value: 100000,
    label: '100K',
  },
  {
    value: 50000,
    label: '50K',
  },
]

const revenue180DaysMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 100000000,
    label: '$100M',
  },
  {
    value: 50000000,
    label: '$50M',
  },
  {
    value: 10000000,
    label: '$10M',
  },
  {
    value: 1000000,
    label: '$1M',
  },
  {
    value: 5000,
    label: '$5K',
  },
]

const revenue180DaysMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 200000000,
    label: '$200M+',
    showOverChartNode: true,
  },
  {
    value: 100000000,
    label: '$100M',
  },
  {
    value: 50000000,
    label: '$50M',
  },
  {
    value: 10000000,
    label: '$10M',
  },
  {
    value: 1000000,
    label: '$1M',
  },
]

const downloads180DaysMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 5000000,
    label: '5M',
  },
  {
    value: 1000000,
    label: '1M',
  },
  {
    value: 500000,
    label: '500K',
  },
  {
    value: 100000,
    label: '100K',
  },
  {
    value: 50000,
    label: '50K',
  },
]

const downloads180DaysMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 10000000,
    label: '10M+',
    showOverChartNode: true,
  },
  {
    value: 5000000,
    label: '5M',
  },
  {
    value: 1000000,
    label: '1M',
  },
  {
    value: 500000,
    label: '500K',
  },
  {
    value: 100000,
    label: '100K',
  },
  {
    value: 50000,
    label: '50K',
  },
  {
    value: 15000,
    label: '15K',
  },
]

const revenue360DaysMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 200000000,
    label: '$200M',
  },
  {
    value: 100000000,
    label: '$100M',
  },
  {
    value: 50000000,
    label: '$50M',
  },
  {
    value: 10000000,
    label: '$10M',
  },
  {
    value: 1000000,
    label: '$1M',
  },
  {
    value: 5000,
    label: '$5K',
  },
]

const revenue360DaysMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 300000000,
    label: '$300M+',
    showOverChartNode: true,
  },
  {
    value: 200000000,
    label: '$200M+',
    showOverChartNode: true,
  },
  {
    value: 100000000,
    label: '$100M',
  },
  {
    value: 50000000,
    label: '$50M',
  },
  {
    value: 10000000,
    label: '$10M',
  },
  {
    value: 1000000,
    label: '$1M',
  },
]

const downloads360DaysMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 10000000,
    label: '10M',
  },
  {
    value: 5000000,
    label: '5M',
  },
  {
    value: 1000000,
    label: '1M',
  },
  {
    value: 500000,
    label: '500K',
  },
  {
    value: 100000,
    label: '100K',
  },
  {
    value: 50000,
    label: '50K',
  },
]

const downloads360DaysMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 20000000,
    label: '20M+',
    showOverChartNode: true,
  },
  {
    value: 10000000,
    label: '10M',
  },
  {
    value: 5000000,
    label: '5M',
  },
  {
    value: 1000000,
    label: '1M',
  },
  {
    value: 500000,
    label: '500K',
  },
  {
    value: 100000,
    label: '100K',
  },
  {
    value: 50000,
    label: '50K',
  },
  {
    value: 15000,
    label: '15K',
  },
]

const revenueDownloadsRatioMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 75,
    label: '75',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 0,
    label: '0',
  },
]

const revenueDownloadsRatioMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 100,
    label: '100+',
    showOverChartNode: true,
  },
  {
    value: 75,
    label: '75+',
    showOverChartNode: true,
  },
  {
    value: 50,
    label: '50+',
    showOverChartNode: true,
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 1,
    label: '1',
  },
]

const daysMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 180,
    label: '180',
  },
  {
    value: 365,
    label: '365',
  },
  {
    value: 730,
    label: '730',
  },
  {
    value: 1095,
    label: '1095',
  },
  {
    value: 1460,
    label: '1460',
  },
  {
    value: 1825,
    label: '1825',
  },
  {
    value: 1825,
    label: '1825+',
    showOverChartNode: true,
  },
]

const daysMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 180,
    label: '180',
  },
  {
    value: 365,
    label: '365',
  },
  {
    value: 730,
    label: '730',
  },
  {
    value: 1095,
    label: '1095',
  },
  {
    value: 1460,
    label: '1460',
  },
  {
    value: 1825,
    label: '1825',
  },
]

const percentageMaxScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 90,
    label: '90%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 70,
    label: '70%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 0,
    label: '0%',
  },
]

const percentageMinScales: MarketExplorerGamesChartAxisScale[] = [
  {
    value: 100,
    label: '100%',
  },
  {
    value: 90,
    label: '90% +',
    showOverChartNode: true,
  },
  {
    value: 80,
    label: '80% +',
    showOverChartNode: true,
  },
  {
    value: 70,
    label: '70% +',
    showOverChartNode: true,
  },
  {
    value: 60,
    label: '60% +',
    showOverChartNode: true,
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 10,
    label: '10%',
  },
]

const defaultPercentageMaxValue = 10
const defaultPercentageMinValue = 90

class MarketExplorerService {
  maxSustainedRank: number
  gamesChartAxes: MarketExplorerGamesChartAxisGroup[]

  constructor() {
    this.maxSustainedRank = 2000

    const axes: MarketExplorerGamesChartAxisGroup[] = [
      {
        id: MarketExplorerGamesChartAxisGroupId.FeatureIndicators,
        name: 'common:feature_indicators_text',
        types: [
          {
            id: MarketExplorerGamesChartAxisTypeId.Powerscore,
            name: 'common:game_power_score',
            description: 'common:tooltip_gps_description',
            maxScales: powerscoreMaxScales,
            minScales: powerscoreMinScales,
            defaultMaxScale: powerscoreMaxScales.find((scale) => scale.value === 50) || powerscoreMaxScales[0],
            defaultMinScale: powerscoreMinScales.find((scale) => scale.value === 100) || powerscoreMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.SkillThinking,
            name: 'common:skill_thinking',
            description: 'common:skill_thinking_description',
            maxScales: skillThinkingMaxScales,
            minScales: skillThinkingMinScales,
            defaultMaxScale: skillThinkingMaxScales.find((scale) => scale.value === 0) || skillThinkingMaxScales[0],
            defaultMinScale: skillThinkingMinScales.find((scale) => scale.value === 100) || skillThinkingMinScales[0],
            inversed: true,
          },
        ],
      },
      {
        id: MarketExplorerGamesChartAxisGroupId.SustainedRanks,
        name: 'common:sustained_ranks_text',
        types: [
          {
            id: MarketExplorerGamesChartAxisTypeId.SGR,
            name: 'common:sustained_grossing_rank',
            description: 'common:sustained_grossing_rank_description',
            maxScales: rankMaxScales,
            minScales: rankMinScales,
            defaultMaxScale: rankMaxScales.find((scale) => scale.value === 200) || rankMaxScales[0],
            defaultMinScale: rankMinScales.find((scale) => scale.value === 1) || rankMinScales[0],
            inversed: false,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.SDR,
            name: 'common:sustained_download_rank',
            description: 'common:sustained_download_rank_description',
            maxScales: rankMaxScales,
            minScales: rankMinScales,
            defaultMaxScale: rankMaxScales.find((scale) => scale.label === '1000+') || rankMaxScales[0],
            defaultMinScale: rankMinScales.find((scale) => scale.value === 1) || rankMinScales[0],
            inversed: false,
          },
        ],
      },
      {
        id: MarketExplorerGamesChartAxisGroupId.Revenue,
        name: 'common:revenue_text',
        types: [
          {
            id: MarketExplorerGamesChartAxisTypeId.RevenuePastMonth,
            name: 'common:revenue_past_month',
            description: 'common:revenue_past_month_description',
            maxScales: revenue30DaysMaxScales,
            minScales: revenue30DaysMinScales,
            defaultMaxScale: revenue30DaysMaxScales[revenue30DaysMaxScales.length - 1],
            defaultMinScale: revenue30DaysMinScales.find((scale) => scale.value === 10000000) || revenue30DaysMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.Revenue180Days,
            name: 'common:revenue_past_half_year',
            description: 'common:revenue_past_half_year_description',
            maxScales: revenue180DaysMaxScales,
            minScales: revenue180DaysMinScales,
            defaultMaxScale: revenue180DaysMaxScales[revenue180DaysMaxScales.length - 1],
            defaultMinScale: revenue180DaysMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.Revenue360Days,
            name: 'common:revenue_past_year',
            description: 'common:revenue_past_year_description',
            maxScales: revenue360DaysMaxScales,
            minScales: revenue360DaysMinScales,
            defaultMaxScale: revenue360DaysMaxScales[revenue360DaysMaxScales.length - 1],
            defaultMinScale: revenue360DaysMinScales[0],
            inversed: true,
          },
        ],
      },
      {
        id: MarketExplorerGamesChartAxisGroupId.Downloads,
        name: 'common:downloads_text',
        types: [
          {
            id: MarketExplorerGamesChartAxisTypeId.DownloadsPastMonth,
            name: 'common:downloads_past_month',
            description: 'common:downloads_past_month_description',
            maxScales: downloads30DaysMaxScales,
            minScales: downloads30DaysMinScales,
            defaultMaxScale: downloads30DaysMaxScales[downloads30DaysMaxScales.length - 1],
            defaultMinScale: downloads30DaysMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.Downloads180Days,
            name: 'common:downloads_past_half_year',
            description: 'common:downloads_past_half_year_description',
            maxScales: downloads180DaysMaxScales,
            minScales: downloads180DaysMinScales,
            defaultMaxScale: downloads180DaysMaxScales[downloads180DaysMaxScales.length - 1],
            defaultMinScale: downloads180DaysMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.Downloads360Days,
            name: 'common:downloads_past_year',
            description: 'common:downloads_past_year_description',
            maxScales: downloads360DaysMaxScales,
            minScales: downloads360DaysMinScales,
            defaultMaxScale: downloads360DaysMaxScales[downloads360DaysMaxScales.length - 1],
            defaultMinScale: downloads360DaysMinScales[0],
            inversed: true,
          },
        ],
      },
      {
        id: MarketExplorerGamesChartAxisGroupId.RevenueDownloadsRatio,
        name: 'common:revenue_downloads_ratio',
        types: [
          {
            id: MarketExplorerGamesChartAxisTypeId.RevenueDownloadsRatioPastMonth,
            name: 'common:revenue_downloads_ratio_past_month',
            description: 'common:revenue_downloads_ratio_description',
            maxScales: revenueDownloadsRatioMaxScales,
            minScales: revenueDownloadsRatioMinScales,
            defaultMaxScale: revenueDownloadsRatioMaxScales[revenueDownloadsRatioMaxScales.length - 1],
            defaultMinScale: revenueDownloadsRatioMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.RevenueDownloads180DaysRatio,
            name: 'common:revenue_downloads_ratio_past_half_year',
            description: 'common:revenue_downloads_ratio_past_half_year_description',
            maxScales: revenueDownloadsRatioMaxScales,
            minScales: revenueDownloadsRatioMinScales,
            defaultMaxScale: revenueDownloadsRatioMaxScales[revenueDownloadsRatioMaxScales.length - 1],
            defaultMinScale: revenueDownloadsRatioMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.RevenueDownloads360DaysRatio,
            name: 'common:revenue_downloads_ratio_past_year',
            description: 'common:revenue_downloads_ratio_past_year_description',
            maxScales: revenueDownloadsRatioMaxScales,
            minScales: revenueDownloadsRatioMinScales,
            defaultMaxScale: revenueDownloadsRatioMaxScales[revenueDownloadsRatioMaxScales.length - 1],
            defaultMinScale: revenueDownloadsRatioMinScales[0],
            inversed: true,
          },
        ],
      },
      {
        id: MarketExplorerGamesChartAxisGroupId.ReleaseDate,
        name: 'common:release_text',
        types: [
          {
            id: MarketExplorerGamesChartAxisTypeId.DaysSinceRelease,
            name: 'common:days_since_release',
            description: 'common:days_since_release_description',
            maxScales: daysMaxScales,
            minScales: daysMinScales,
            defaultMaxScale: daysMaxScales[daysMaxScales.length - 1],
            defaultMinScale: daysMinScales[0],
            inversed: false,
          },
        ],
      },
      {
        id: MarketExplorerGamesChartAxisGroupId.DemographicsGender,
        name: 'common:gender',
        types: [
          {
            id: MarketExplorerGamesChartAxisTypeId.DemographicsMale,
            name: 'common:male',
            description: 'common:male_description',
            maxScales: percentageMaxScales,
            minScales: percentageMinScales,
            defaultMaxScale: percentageMaxScales.find((scale) => scale.value === defaultPercentageMaxValue) || percentageMaxScales[0],
            defaultMinScale: percentageMinScales.find((scale) => scale.value === defaultPercentageMinValue) || percentageMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.DemographicsFemale,
            name: 'common:female',
            description: 'common:female_description',
            maxScales: percentageMaxScales,
            minScales: percentageMinScales,
            defaultMaxScale: percentageMaxScales.find((scale) => scale.value === defaultPercentageMaxValue) || percentageMaxScales[0],
            defaultMinScale: percentageMinScales.find((scale) => scale.value === defaultPercentageMinValue) || percentageMinScales[0],
            inversed: true,
          },
        ],
      },
      {
        id: MarketExplorerGamesChartAxisGroupId.DemographicsAge,
        name: 'common:age',
        types: [
          {
            id: MarketExplorerGamesChartAxisTypeId.DemographicsAge16_24,
            name: 'common:age16_24',
            description: 'common:age16_24_description',
            maxScales: percentageMaxScales,
            minScales: percentageMinScales,
            defaultMaxScale: percentageMaxScales.find((scale) => scale.value === defaultPercentageMaxValue) || percentageMaxScales[0],
            defaultMinScale: percentageMinScales.find((scale) => scale.value === defaultPercentageMinValue) || percentageMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.DemographicsAge25_44,
            name: 'common:age25_44',
            description: 'common:age25_44_description',
            maxScales: percentageMaxScales,
            minScales: percentageMinScales,
            defaultMaxScale: percentageMaxScales.find((scale) => scale.value === defaultPercentageMaxValue) || percentageMaxScales[0],
            defaultMinScale: percentageMinScales.find((scale) => scale.value === defaultPercentageMinValue) || percentageMinScales[0],
            inversed: true,
          },
          {
            id: MarketExplorerGamesChartAxisTypeId.DemographicsAge45,
            name: 'common:age45_plus',
            description: 'common:age45_plus_description',
            maxScales: percentageMaxScales,
            minScales: percentageMinScales,
            defaultMaxScale: percentageMaxScales.find((scale) => scale.value === defaultPercentageMaxValue) || percentageMaxScales[0],
            defaultMinScale: percentageMinScales.find((scale) => scale.value === defaultPercentageMinValue) || percentageMinScales[0],
            inversed: true,
          },
        ],
      },
    ]

    this.gamesChartAxes = axes
  }

  getChartAxisTypeByTypeId = (chartAxisTypeId: MarketExplorerGamesChartAxisTypeId) => {
    return this.gamesChartAxes
      .flatMap((chartAxis) => chartAxis.types)
      .find((chartAxisType) => chartAxisType.id === chartAxisTypeId) as MarketExplorerGamesChartAxisType
  }

  getScaleValueByAxisTypeIdAndScaleLabel = (chartAxisTypeId: MarketExplorerGamesChartAxisTypeId, label: string, scaleType: 'min' | 'max') => {
    const chartAxisType = this.getChartAxisTypeByTypeId(chartAxisTypeId)
    const scales = scaleType === 'min' ? chartAxisType.minScales : chartAxisType.maxScales

    return scales.find((scale) => scale.label === label) as MarketExplorerGamesChartAxisScale
  }

  getGamePositionOnChart(
    game: Game,
    axisType: MarketExplorerGamesChartAxisType,
    maxAxisScale: MarketExplorerGamesChartAxisScale,
    minAxisScale: MarketExplorerGamesChartAxisScale,
    marketIso: string
  ) {
    let value = 0

    switch (axisType.id) {
      case MarketExplorerGamesChartAxisTypeId.Powerscore:
        value = Math.round(game.gpsPerMarket[marketIso] || 0)
        break

      case MarketExplorerGamesChartAxisTypeId.SkillThinking:
        value = Math.round(game.cognitive * 100)
        break

      case MarketExplorerGamesChartAxisTypeId.SGR:
        value = game.sranks[marketIso] || this.maxSustainedRank
        break

      case MarketExplorerGamesChartAxisTypeId.SDR:
        value = game.sdranks[marketIso] || this.maxSustainedRank
        break

      case MarketExplorerGamesChartAxisTypeId.RevenuePastMonth:
        value = game.getEstimateValue(marketIso, EstimateTypes.REVENUE_30_DAY)
        break

      case MarketExplorerGamesChartAxisTypeId.Revenue180Days:
        value = game.getEstimateValue(marketIso, EstimateTypes.REVENUE_180_DAY)
        break

      case MarketExplorerGamesChartAxisTypeId.Revenue360Days:
        value = game.getEstimateValue(marketIso, EstimateTypes.REVENUE_360_DAY)
        break

      case MarketExplorerGamesChartAxisTypeId.DownloadsPastMonth:
        value = game.getEstimateValue(marketIso, EstimateTypes.DOWNLOADS_30_DAY)
        break

      case MarketExplorerGamesChartAxisTypeId.Downloads180Days:
        value = game.getEstimateValue(marketIso, EstimateTypes.DOWNLOADS_180_DAY)
        break

      case MarketExplorerGamesChartAxisTypeId.Downloads360Days:
        value = game.getEstimateValue(marketIso, EstimateTypes.DOWNLOADS_360_DAY)
        break

      case MarketExplorerGamesChartAxisTypeId.RevenueDownloadsRatioPastMonth:
        value = game.getEstimateRevenueDownloadsRatioValue(marketIso, EstimateTypes.REVENUE_30_DAY, EstimateTypes.DOWNLOADS_30_DAY)
        break

      case MarketExplorerGamesChartAxisTypeId.RevenueDownloads180DaysRatio:
        value = game.getEstimateRevenueDownloadsRatioValue(marketIso, EstimateTypes.REVENUE_180_DAY, EstimateTypes.DOWNLOADS_180_DAY)
        break

      case MarketExplorerGamesChartAxisTypeId.RevenueDownloads360DaysRatio:
        value = game.getEstimateRevenueDownloadsRatioValue(marketIso, EstimateTypes.REVENUE_360_DAY, EstimateTypes.DOWNLOADS_360_DAY)
        break

      case MarketExplorerGamesChartAxisTypeId.DaysSinceRelease:
        value = utilsService.getDayDifferenceFromTwoDates(game.released, +new Date())
        break

      case MarketExplorerGamesChartAxisTypeId.DemographicsMale:
        value = game.getDemographicsValue(marketIso, DemographicsDataKey.Male) || 0
        break

      case MarketExplorerGamesChartAxisTypeId.DemographicsFemale:
        value = game.getDemographicsValue(marketIso, DemographicsDataKey.Female) || 0
        break

      case MarketExplorerGamesChartAxisTypeId.DemographicsAge16_24:
        value = game.getDemographicsValue(marketIso, DemographicsDataKey.Age16_24) || 0
        break

      case MarketExplorerGamesChartAxisTypeId.DemographicsAge25_44:
        value = game.getDemographicsValue(marketIso, DemographicsDataKey.Age25_44) || 0
        break

      case MarketExplorerGamesChartAxisTypeId.DemographicsAge45:
        value = game.getDemographicsValue(marketIso, DemographicsDataKey.Age45_plus) || 0
        break
    }

    let showOnChart = false

    if (value <= maxAxisScale.value && value >= minAxisScale.value) {
      showOnChart = true
    } else if (
      (axisType.inversed && value >= maxAxisScale.value && value <= minAxisScale.value) ||
      (minAxisScale.showOverChartNode && value >= maxAxisScale.value)
    ) {
      showOnChart = true
    } else if (
      (!axisType.inversed && value <= maxAxisScale.value && value >= minAxisScale.value) ||
      (maxAxisScale.showOverChartNode && value >= minAxisScale.value)
    ) {
      showOnChart = true
    }

    let correctedValue = value

    if (axisType.inversed) {
      correctedValue = ((value - maxAxisScale.value) * 100) / (minAxisScale.value - maxAxisScale.value)
      correctedValue = 100 - correctedValue
    } else {
      correctedValue = ((value - minAxisScale.value) * 100) / (maxAxisScale.value - minAxisScale.value)
    }

    if (correctedValue < 0 || correctedValue > 100) {
      correctedValue = correctedValue < 0 ? 0 : 100
    }

    return { value: correctedValue, showOnChart }
  }

  calculateSegmentBreakdownDataForValue(breakdown: MarketExplorerSegmentBreakdownData, valueType: MarketExplorerSegmentBreakdownValueType) {
    const gameDataArrays: { [key in MarketExplorerSegmentBreakdownDataKey]: number[] } = {} as { [key in MarketExplorerSegmentBreakdownDataKey]: number[] }
    const dataKeys = { ...MarketExplorerGamesChartAxisTypeId, ...PlayerArchetype, ...MotivationType }

    Object.values(dataKeys).forEach((value) => {
      gameDataArrays[value] = []
    })

    const marketIso = breakdown.segment?.segmentConfiguration.marketIso || 'us'

    breakdown.games.forEach((game) => {
      if (game.sranks[marketIso] && game.sranks[marketIso] < this.maxSustainedRank) {
        gameDataArrays[MarketExplorerGamesChartAxisTypeId.SGR].push(game.sranks[marketIso])
      }

      if (game.sdranks[marketIso] && game.sdranks[marketIso] < this.maxSustainedRank) {
        gameDataArrays[MarketExplorerGamesChartAxisTypeId.SDR].push(game.sdranks[marketIso])
      }

      const gamePowerscore = game.gpsPerMarket[marketIso] || 0
      if (gamePowerscore > 0) {
        gameDataArrays[MarketExplorerGamesChartAxisTypeId.Powerscore].push(gamePowerscore)
        gameDataArrays[MarketExplorerGamesChartAxisTypeId.SkillThinking].push(game.cognitive > 0 ? game.cognitive * 100 : 50)
      }

      const estimates = new RevenueAndDownloadEstimates(game, marketIso)
      gameDataArrays[MarketExplorerGamesChartAxisTypeId.RevenuePastMonth].push(estimates.revenue30Day.value)
      gameDataArrays[MarketExplorerGamesChartAxisTypeId.DownloadsPastMonth].push(estimates.downloads30Day.value)

      gameDataArrays[MarketExplorerGamesChartAxisTypeId.Revenue180Days].push(estimates.revenue180Day.value)
      gameDataArrays[MarketExplorerGamesChartAxisTypeId.Downloads180Days].push(estimates.downloads180Day.value)

      gameDataArrays[MarketExplorerGamesChartAxisTypeId.Revenue360Days].push(estimates.revenue360Day.value)
      gameDataArrays[MarketExplorerGamesChartAxisTypeId.Downloads360Days].push(estimates.downloads360Day.value)

      if (utilsService.demographicsIsAvailableForMarket(marketIso) && game.demographics && game.demographics[marketIso]) {
        gameDataArrays[MarketExplorerGamesChartAxisTypeId.DemographicsMale].push(
          game.demographics[marketIso][MarketExplorerGamesChartAxisTypeId.DemographicsMale] || 0
        )
        gameDataArrays[MarketExplorerGamesChartAxisTypeId.DemographicsFemale].push(
          game.demographics[marketIso][MarketExplorerGamesChartAxisTypeId.DemographicsFemale] || 0
        )
        gameDataArrays[MarketExplorerGamesChartAxisTypeId.DemographicsAge16_24].push(
          game.demographics[marketIso][MarketExplorerGamesChartAxisTypeId.DemographicsAge16_24] || 0
        )
        gameDataArrays[MarketExplorerGamesChartAxisTypeId.DemographicsAge25_44].push(
          game.demographics[marketIso][MarketExplorerGamesChartAxisTypeId.DemographicsAge25_44] || 0
        )
        gameDataArrays[MarketExplorerGamesChartAxisTypeId.DemographicsAge45].push(
          game.demographics[marketIso][MarketExplorerGamesChartAxisTypeId.DemographicsAge45] || 0
        )
      }

      Object.values(PlayerArchetype).forEach((playerArchetype) => {
        const archetypeValue =
          breakdown.segment?.data?.motivationData.motivations?.find((motivation) => motivation.gameId === game.id)?.motivationSegmentValues[playerArchetype] ||
          0
        if (archetypeValue > 0) {
          gameDataArrays[playerArchetype].push(archetypeValue)
        }
      })

      Object.values(MotivationType).forEach((motivationType) => {
        const motivationValue =
          breakdown.segment?.data?.motivationData.motivations?.find((motivation) => motivation.gameId === game.id)?.motivationValues[motivationType] || 0
        if (motivationValue > 0) {
          gameDataArrays[motivationType].push(motivationValue)
        }
      })
    })

    Object.values(MarketExplorerGamesChartAxisTypeId).forEach((value) => {
      gameDataArrays[value] = gameDataArrays[value].sort()
    })

    const calculatedData = Object.values(MarketExplorerGamesChartAxisTypeId).reduce((acc, typeId: MarketExplorerGamesChartAxisTypeId) => {
      return { ...acc, [typeId]: 0 }
    }, {} as MarketExplorerSegmentBreakdownDataData)

    switch (valueType.id) {
      case MarketExplorerSegmentBreakdownValueTypeId.Average:
        Object.values(dataKeys).forEach((value) => {
          calculatedData[value] = average(gameDataArrays[value]) || defaultDataValues[value] || 0
        })

        break

      case MarketExplorerSegmentBreakdownValueTypeId.Median:
        Object.values(dataKeys).forEach((value) => {
          calculatedData[value] = median(gameDataArrays[value]) || defaultDataValues[value] || 0
        })

        break

      case MarketExplorerSegmentBreakdownValueTypeId.Best:
        Object.values(dataKeys).forEach((value) => {
          if (value === MarketExplorerGamesChartAxisTypeId.SGR || value === MarketExplorerGamesChartAxisTypeId.SDR) {
            calculatedData[value] = gameDataArrays[value].length > 0 ? Math.min(...gameDataArrays[value]) : defaultDataValues[value] || 0
          } else {
            calculatedData[value] = gameDataArrays[value].length > 0 ? Math.max(...gameDataArrays[value]) : defaultDataValues[value] || 0
          }
        })

        break

      case MarketExplorerSegmentBreakdownValueTypeId.Worst:
        Object.values(dataKeys).forEach((value) => {
          if (value === MarketExplorerGamesChartAxisTypeId.SGR || value === MarketExplorerGamesChartAxisTypeId.SDR) {
            calculatedData[value] = gameDataArrays[value].length > 0 ? Math.max(...gameDataArrays[value]) : defaultDataValues[value] || 0
          } else {
            calculatedData[value] = gameDataArrays[value].length > 0 ? Math.min(...gameDataArrays[value]) : defaultDataValues[value] || 0
          }
        })

        break
    }

    return calculatedData
  }
}

const marketExplorerService = new MarketExplorerService()

export default marketExplorerService

// mapping for default data values when they deviate from 0
const defaultDataValues: { [key in MarketExplorerGamesChartAxisTypeId | PlayerArchetype | MotivationType]?: number } = {
  [MarketExplorerGamesChartAxisTypeId.SkillThinking]: 50,
}
