import { TopGame } from '../../top-game/types/TopGame'
import { GameQuarter } from './GameQuarter'

export class MarketShareQuarter extends GameQuarter {
  totalRevenue: number = 0
  totalDownloads: number = 0
  grossingGames: number[] = []
  freeGames: number[] = []
  games: TopGame[] = []
}
