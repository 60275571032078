import { FC, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Dialog, DialogContent, DialogProps, Divider, Grid, Skeleton, Typography } from '@mui/material'

import { useGetReportsWithIdsQuery } from '../../../../../api/reports'
import { Analyst } from '../../../../../components/Analyst/Analyst'
import { GRBanner } from '../../../../../components/GRBanner/GRBanner'
import GRDialogTitle, { GRDialogCloseButton } from '../../../../../components/GRDialogTitle/GRDialogTitle'
import { HtmlContentParser } from '../../../../../components/HtmlContentParser/HtmlContentParser'
import { liveEventReviewModalParserOptions } from '../../../../../components/LiveEventModalLink/LiveEventModalLink'
import { SkeletonBlockOfText } from '../../../../../components/SkeletonBlockOfText/SkeletonBlockOfText'
import analyticsService from '../../../../../services/AnalyticsService'
import languageService from '../../../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../../../account/hooks/useCurrentUserLanguage'
import { GameCardContent } from '../../../../game/components/GameCard/GameCard'
import ReportCard from '../../../../reports/components/ReportCard/ReportCard'
import { onlyMixpanelTrackingServicesToExclude } from '../../../hooks/useLiveEventsTrackerAnalyticsEvents'
import { TrackedGame } from '../../../types/TrackedGame'
import { LiveEventsNotPublishedBadge } from '../../LiveEventsNotPublishedBadge/LiveEventsNotPublishedBadge'

/**
 * Displays a dialog with tabs for browsing single event details, all events related to a game and other games related to
 * the selected event type
 */
type LiveEventsOverviewDialogProps = DialogProps & {
  isLoading?: boolean
  trackedGame?: TrackedGame
}

export const LiveEventsOverviewDialog: FC<LiveEventsOverviewDialogProps> = ({ trackedGame, open, onClose, isLoading }) => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  const descriptionRawHtml = trackedGame?.review?.content[userLanguage] ? trackedGame?.review?.content[userLanguage] : trackedGame?.review?.content['en']
  const { data: linkedReports } = useGetReportsWithIdsQuery(
    { reportIds: trackedGame?.review?.reportIds || [] },
    { skip: !trackedGame?.review?.reportIds?.length }
  )

  useEffect(() => {
    const data = {
      gameName: trackedGame?.game.names['us'] || trackedGame?.game.name,
      gameAppId: trackedGame?.game.appId,
      analystName: trackedGame?.analyst?.name || '',
      conventionalSubgenre: trackedGame?.game.conventionalSubgenreId
        ? languageService.getTranslation('conventionalSubgenres', trackedGame?.game.conventionalSubgenreId)
        : '',
    }

    analyticsService.trackEvent('Live Events Tracker: Opened Analyst Overview', {
      data,
      serviceToExclude: onlyMixpanelTrackingServicesToExclude,
    })
  }, [trackedGame])

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth disableRestoreFocus className="LiveEventsEventOverviewDialog">
      <GRDialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            {isLoading ? (
              <Skeleton variant="circular" width={60} height={60} />
            ) : (
              <>
                {trackedGame && (
                  <div className="LiveEventsEventDialog__GameCardContent">
                    <GameCardContent game={trackedGame?.game} displayGameType />
                    {!trackedGame?.review?.published && <LiveEventsNotPublishedBadge />}
                  </div>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={6} container direction="column" alignItems="center">
            <Typography variant="h3">{isLoading ? <Skeleton variant="text" width={120} /> : t('live-events:live_events_overview_dialog_title')}</Typography>
          </Grid>
          <Grid item xs={3} container direction="column" alignItems="flex-end">
            <GRDialogCloseButton onClick={onClose} share />
          </Grid>
        </Grid>
      </GRDialogTitle>
      <Divider />
      <DialogContent>
        {!isLoading && !descriptionRawHtml && (
          <Box padding={1}>
            <GRBanner severity="guidance">
              <Trans i18nKey="live-events:event_not_available" />
            </GRBanner>
          </Box>
        )}

        {isLoading ? (
          <>
            <Skeleton variant="circular" width={60} height={60} sx={{ mb: 2 }} />
            <Typography variant="body1">
              <SkeletonBlockOfText lines={20} />
            </Typography>
          </>
        ) : (
          <>
            {trackedGame?.analyst && (
              <Analyst mb={2} name={trackedGame?.analyst?.name} title={trackedGame?.analyst?.title} picture={trackedGame?.analyst?.picture} />
            )}
            <Typography variant="body1" component="div">
              <HtmlContentParser
                rawHtml={descriptionRawHtml}
                parserOptions={trackedGame?.game.id ? liveEventReviewModalParserOptions(trackedGame?.game.id) : undefined}
              />
            </Typography>

            {linkedReports && linkedReports.length > 0 && (
              <>
                <Divider />

                {linkedReports?.map((report) => {
                  return (
                    <Box mt={2} key={report.id}>
                      <ReportCard report={report} useSideBySideLayout />
                    </Box>
                  )
                })}
              </>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
