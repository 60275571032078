import { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Divider, TextField } from '@mui/material'

import { includesCaseInsensitive } from '../../../../helpers/includesCaseInsensitive'
import { uniq } from '../../../../helpers/uniq'
import { Market } from '../../../markets'
import { useMarketsByRegions } from '../../constants/constants'
import { MarketRegion } from '../../types/Region'
import { Region } from './Region/Region'
import { RegionActions } from './RegionActions/RegionActions'

type RegionMarketSelectorProps = {
  marketIsos: string[]
  selectedMarketIsos: string[]
  onChange: (marketIsos: string[]) => void
}

export const RegionMarketSelector: FC<RegionMarketSelectorProps> = ({ marketIsos, selectedMarketIsos, onChange }) => {
  const { t } = useTranslation()
  const marketsByRegions = useMarketsByRegions()
  const [marketNameFilter, setMarketNameFilter] = useState<string>()

  // toggle a singlemarket selection
  const handleMarketSelect = useCallback(
    (marketIso: string) => {
      if (selectedMarketIsos.includes(marketIso)) {
        onChange(selectedMarketIsos.filter((mi) => mi !== marketIso))
      } else {
        onChange([marketIso, ...selectedMarketIsos])
      }
    },
    [onChange, selectedMarketIsos]
  )

  // select all available markets
  const handleSelectAll = useCallback(() => {
    onChange(marketIsos)
    setMarketNameFilter(undefined)
  }, [onChange, marketIsos])

  // clear all selected markets
  const handleClearSelected = useCallback(() => {
    onChange([])
    setMarketNameFilter(undefined)
  }, [onChange])

  // select all markets in given region
  const handleSelectRegion = useCallback(
    (region: MarketRegion) => {
      const newSelection = uniq([...selectedMarketIsos, ...region.marketIsos]).filter((marketIso) => marketIsos.includes(marketIso))
      onChange(newSelection)
      setMarketNameFilter(undefined)
    },
    [onChange, selectedMarketIsos, marketIsos]
  )

  // clear all selected markets in given region
  const handleClearRegion = useCallback(
    (region: MarketRegion) => {
      const newSelection = selectedMarketIsos.filter((selectedMarketIso) => !region.marketIsos.includes(selectedMarketIso))
      onChange(newSelection)
      setMarketNameFilter(undefined)
    },
    [onChange, selectedMarketIsos]
  )

  const marketFilter = useCallback(
    (market: Market | undefined) => {
      return marketNameFilter && market ? includesCaseInsensitive(marketNameFilter, market.name) : true
    },
    [marketNameFilter]
  )

  const selectAllDisabled = marketIsos.length === selectedMarketIsos.length
  const clearAllDisabled = selectedMarketIsos.length === 0

  return (
    <>
      <RegionActions
        onSelectAll={handleSelectAll}
        onClearSelected={handleClearSelected}
        selectAllDisabled={selectAllDisabled}
        clearAllDisabled={clearAllDisabled}
      >
        <TextField
          label={t('common:filter_markets')}
          variant="outlined"
          color="primary"
          size="small"
          onChange={(event) => setMarketNameFilter(event.target.value)}
          value={marketNameFilter || ''}
        />
      </RegionActions>

      {Object.entries(marketsByRegions).map(([region, marketRegion]) => {
        const regionMarkets = marketRegion.marketIsos.filter((marketIso) =>
          marketIsos.map((marketIso) => marketIso.toLowerCase()).includes(marketIso.toLowerCase())
        )

        return (
          regionMarkets.length > 0 && (
            <div key={region}>
              <Divider sx={{ my: 2 }} />
              <Region
                marketRegion={marketRegion}
                marketIsos={regionMarkets}
                selectedMarketIsos={selectedMarketIsos}
                onMarketSelect={handleMarketSelect}
                onRegionSelect={handleSelectRegion}
                onRegionClear={handleClearRegion}
                onFilter={marketFilter}
              />
            </div>
          )
        )
      })}
    </>
  )
}
