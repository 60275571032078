import { useEffect, useState } from 'react'

import { NavigationMode } from '../types/NavigationMode'
import { useInternalRouteMatch } from './useInternalRouteMatch'

export const useNavigationMode = () => {
  const isInternalRoute = useInternalRouteMatch()
  const navigationModeState = useState<NavigationMode>(isInternalRoute ? NavigationMode.Internal : NavigationMode.App)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [navigationMode, setNavigationMode] = navigationModeState
    setNavigationMode(isInternalRoute ? NavigationMode.Internal : NavigationMode.App)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInternalRoute])

  return navigationModeState
}
