import { ActiveElement, Point } from 'chart.js'
import { ChartEvent } from 'chart.js/dist/core/core.plugins'
import React, { useCallback, useRef } from 'react'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

import { Box, Grid } from '@mui/material'

import { TimelineChartProps } from '../TimelineChart/TimelineChart'
import { TimelineChart } from '../TimelineChart/TimelineChart'
import './SideBySideCharts.scss'

interface SideBySideChartsProps<VerticalMarkType extends object> {
  sharedHover?: boolean
  leftChartProps: TimelineChartProps<VerticalMarkType>
  rightChartProps: TimelineChartProps<VerticalMarkType>
}

const syncHover = (
  event: ChartEvent,
  chartSource: ChartJSOrUndefined<'line', (number | Point)[], any>,
  chartTarget: ChartJSOrUndefined<'line', (number | Point)[], any>
) => {
  if (!chartSource || !chartTarget || !event.native) return

  const sourceElements = chartSource.getElementsAtEventForMode(event.native, 'index', { intersect: false }, false)
  if (sourceElements.length === 0) return

  const { datasetIndex, index } = sourceElements[0]
  const sourceElement = chartSource.data.datasets[datasetIndex].data[index]
  const sourceTimestamp = (sourceElement as any).x

  const targetElements: { datasetIndex: number; index: number }[] = []

  chartTarget.data.datasets.forEach((dataset, datasetIndex) => {
    if (!chartTarget.isDatasetVisible(datasetIndex)) {
      return // Skip datasets that are not visible (legend toggled off)
    }

    const targetIndex = dataset.data.findIndex((dataPoint: any) => dataPoint.x === sourceTimestamp)
    if (targetIndex !== -1) {
      targetElements.push({ datasetIndex, index: targetIndex })
    }
  })

  if (targetElements.length > 0) {
    chartTarget.setActiveElements(targetElements)
    const firstTargetElement = chartTarget.getDatasetMeta(targetElements[0].datasetIndex).data[targetElements[0].index]
    if (chartTarget.tooltip) {
      chartTarget.tooltip.setActiveElements(targetElements, { x: firstTargetElement.x, y: firstTargetElement.y })
    }
    chartTarget.update()
  }
}

const clearHover = (chart: ChartJSOrUndefined<'line', (number | Point)[], any>) => {
  if (chart) {
    chart.setActiveElements([])
    if (chart.tooltip) {
      chart.tooltip.setActiveElements([], { x: 0, y: 0 })
    }
    chart.update()
  }
}

const SideBySideCharts = <VerticalMarkType extends object>({
  leftChartProps,
  rightChartProps,
  sharedHover = false,
}: SideBySideChartsProps<VerticalMarkType>) => {
  const leftChartRef = useRef<ChartJSOrUndefined<'line', (number | Point)[], any>>()
  const rightChartRef = useRef<ChartJSOrUndefined<'line', (number | Point)[], any>>()

  const handleLeftChartOnHover = useCallback(
    (event: any, chartElement: ActiveElement[]) => {
      syncHover(event, leftChartRef.current, rightChartRef.current)
    },
    [leftChartRef, rightChartRef]
  )

  const handleRightChartOnHover = useCallback(
    (event: any, chartElement: ActiveElement[]) => {
      syncHover(event, rightChartRef.current, leftChartRef.current)
    },
    [rightChartRef, leftChartRef]
  )

  const handleLeftChartMouseOut = useCallback(() => {
    if (sharedHover) {
      clearHover(leftChartRef.current)
      clearHover(rightChartRef.current)
    }
  }, [rightChartRef, sharedHover])

  const handleRightChartMouseOut = useCallback(() => {
    if (sharedHover) {
      clearHover(leftChartRef.current)
      clearHover(rightChartRef.current)
    }
  }, [leftChartRef, sharedHover])

  return (
    <Box className="SideBySideCharts" minHeight={500}>
      <Grid container>
        <Grid item xs={6}>
          <TimelineChart
            ref={leftChartRef}
            data={leftChartProps.data}
            scaleConfig={leftChartProps.scaleConfig}
            verticalMarks={leftChartProps.verticalMarks}
            onVerticalMarkClicked={leftChartProps.onVerticalMarkClicked as ((data: object) => void) | undefined}
            highlightedVerticalMarks={leftChartProps.highlightedVerticalMarks}
            hoverPropagation={sharedHover}
            onHover={handleLeftChartOnHover}
            onMouseOut={handleLeftChartMouseOut}
            tooltipCallbacks={leftChartProps.tooltipCallbacks}
          />
        </Grid>
        <Grid item xs={6}>
          <TimelineChart
            ref={rightChartRef}
            data={rightChartProps.data}
            scaleConfig={rightChartProps.scaleConfig}
            onVerticalMarkClicked={rightChartProps.onVerticalMarkClicked as ((data: object) => void) | undefined}
            highlightedVerticalMarks={rightChartProps.highlightedVerticalMarks}
            verticalMarks={rightChartProps.verticalMarks}
            hoverPropagation={sharedHover}
            onHover={handleRightChartOnHover}
            onMouseOut={handleRightChartMouseOut}
            tooltipCallbacks={rightChartProps.tooltipCallbacks}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default SideBySideCharts
