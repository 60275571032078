import classNames from 'classnames'
import { FC, MouseEvent, ReactNode } from 'react'

import { ArrowRightAlt } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'

import FeatureChoice from '../../features/feature/components/FeatureChoice/FeatureChoice'
import FeatureChoiceSimple from '../../features/feature/components/FeatureChoiceSimple/FeatureChoiceSimple'
import { FeatureLink } from '../../features/feature/components/FeatureLink'
import { NewsEntryFeature, NewsEntryScreenshotFeature } from '../../features/news/types/UnifiedNewsEntry'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import './Features.scss'

/**
 * Component representing a single changed feature
 */
type CommonFeatureProps = {
  onClick: (e: MouseEvent<HTMLElement>) => void
  variant?: 'card' | 'table'
}

type ChangedFeatureProps = CommonFeatureProps & {
  feature: NewsEntryFeature
  children?: (feature: NewsEntryFeature) => ReactNode
}
const ChangedFeature: FC<ChangedFeatureProps> = ({ feature, onClick, variant = 'card', children }) => {
  const rootClasses = classNames('ChangedFeature', variant)
  return (
    <Grid container alignItems="center" className={rootClasses}>
      <Grid item xs={variant === 'table' ? 7 : 8} className="action">
        <FeatureLink feature={{ featureLegacyId: feature.featureLegacyId, featureName: feature.featureName_translation || feature.featureName }}>
          {feature.featureName_translation || feature.featureName}
        </FeatureLink>
      </Grid>
      <Grid item container className="choices" xs={variant === 'table' ? 5 : 4} alignItems="center">
        <Grid className="action" item xs>
          <FeatureLink
            feature={{ featureLegacyId: feature.featureLegacyId, featureName: feature.featureName_translation }}
            choice={{ name: feature.choice2_translation, originalChoiceName: feature.choice2, choiceLegacyId: feature.choice2LegacyId }}
            initialTab="facts"
          >
            <GRTooltip content={feature.choice2_translation}>
              <FeatureChoice choiceLocalized={feature.choice2_translation} originalChoice={feature.choice2} visualizeAsNoType={true}></FeatureChoice>
            </GRTooltip>
          </FeatureLink>
        </Grid>
        <Grid item xs>
          <ArrowRightAlt className="subtle" fontSize="inherit" />
        </Grid>
        <Grid className="action" item xs>
          <FeatureLink
            feature={{ featureLegacyId: feature.featureLegacyId, featureName: feature.featureName_translation || feature.featureName }}
            choice={{ name: feature.choice1_translation, originalChoiceName: feature.choice1, choiceLegacyId: feature.choice1LegacyId }}
            initialTab="facts"
          >
            <GRTooltip content={feature.choice1_translation}>
              <FeatureChoice choiceLocalized={feature.choice1_translation} originalChoice={feature.choice1}></FeatureChoice>
            </GRTooltip>
          </FeatureLink>
        </Grid>
        <Grid item xs alignSelf="flex-end">
          {children && children(feature)}
        </Grid>
      </Grid>
    </Grid>
  )
}

/**
 * Component representing a single updated feature
 */
type UpdatedFeatureProps = CommonFeatureProps & {
  feature: NewsEntryScreenshotFeature
  children?: (feature: NewsEntryScreenshotFeature) => ReactNode
}
const UpdatedFeature: FC<UpdatedFeatureProps> = ({ feature, onClick, variant = 'card', children }) => {
  const rootClasses = classNames('UpdatedFeature', variant)
  return (
    <Grid container alignItems="center" justifyContent="space-between" className={rootClasses}>
      <Grid item className="action" onClick={onClick} flexShrink={1}>
        <FeatureLink
          feature={{ featureLegacyId: feature.featureLegacyId, featureName: feature.featureName_translation }}
          choice={{ name: feature.choiceName_translation, originalChoiceName: feature.choiceName, choiceLegacyId: feature.choiceLegacyId }}
        >
          {feature.featureName_translation}
          <FeatureChoiceSimple choiceLocalized={feature.choiceName_translation} originalChoice={feature.choiceName as string} />
        </FeatureLink>
      </Grid>
      <Grid item>{children && children(feature)}</Grid>
    </Grid>
  )
}

/**
 * Component representing a  list of changed features
 */

type CommonFeatureListProps = {
  onClick?: (e: MouseEvent<HTMLElement>) => void
  variant?: 'card' | 'table'
}

type ChangedFeaturesListProps = CommonFeatureListProps & {
  features?: NewsEntryFeature[]
  children?: (feature: NewsEntryFeature) => ReactNode
  onFeatureSelected?: (feature: NewsEntryFeature) => void
}

const ChangedFeaturesList: FC<ChangedFeaturesListProps> = ({ features = [], onClick, variant, children, onFeatureSelected }) => {
  return (
    <Box className="ChangedFeaturesList">
      {features.map((feature) => {
        return (
          <ChangedFeature
            feature={feature}
            onClick={() => {
              onFeatureSelected && onFeatureSelected(feature)
            }}
            variant={variant}
            key={feature.featureId}
          >
            {children}
          </ChangedFeature>
        )
      })}
    </Box>
  )
}

/**
 * Component representing a list of updated features
 */
type UpdatedFeaturesListProps = CommonFeatureListProps & {
  features?: NewsEntryScreenshotFeature[]
  children?: (feature: NewsEntryScreenshotFeature) => ReactNode
  onFeatureSelected?: (feature: NewsEntryScreenshotFeature) => void
}

const UpdatedFeaturesList: FC<UpdatedFeaturesListProps> = ({ features = [], variant, children, onFeatureSelected }) => {
  return (
    <Box className="UpdatedFeaturesList">
      {features.map((feature) => {
        return (
          <UpdatedFeature
            feature={feature}
            onClick={() => {
              onFeatureSelected && onFeatureSelected(feature)
            }}
            variant={variant}
            key={feature.featureLegacyId}
          >
            {children}
          </UpdatedFeature>
        )
      })}
    </Box>
  )
}

export const Features = {
  Changed: {
    List: ChangedFeaturesList,
    Item: ChangedFeature,
  },
  Updated: {
    List: UpdatedFeaturesList,
    Item: UpdatedFeature,
  },
}
