export interface DateRangeOptionType {
  id: string
  days: number | undefined
  text: string
  translationObject?: { days: number }
}

export const dateRangeOptionToday: DateRangeOptionType = {
  id: 'dateRangeOptionToday',
  days: 1,
  text: 'common:today',
}

export const dateRangeOption7Days: DateRangeOptionType = {
  id: 'dateRangeOption7Days',
  days: 7,
  text: 'common:last_x_days',
  translationObject: { days: 7 },
}

export const dateRangeOption30Days: DateRangeOptionType = {
  id: 'dateRangeOption30Days',
  days: 30,
  text: 'common:last_x_days',
  translationObject: { days: 30 },
}

export const dateRangeOption60Days: DateRangeOptionType = {
  id: 'dateRangeOption60Days',
  days: 60,
  text: 'common:last_x_days',
  translationObject: { days: 60 },
}

export const dateRangeOption90Days: DateRangeOptionType = {
  id: 'dateRangeOption90Days',
  days: 90,
  text: 'common:last_x_days',
  translationObject: { days: 90 },
}

export const dateRangeOption120Days: DateRangeOptionType = {
  id: 'dateRangeOption120Days',
  days: 120,
  text: 'common:last_x_days',
  translationObject: { days: 120 },
}

export const dateRangeOptionLastYear: DateRangeOptionType = {
  id: 'dateRangeOptionLastYear',
  days: 365,
  text: 'common:last_year',
}

export const dateRangeOptionAllAvailable: DateRangeOptionType = {
  id: 'dateRangeOptionAllAvailable',
  days: undefined,
  text: 'common:all_available_data',
}
