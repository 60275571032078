import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Grid, SelectChangeEvent, Typography } from '@mui/material'

import DateRangePicker, { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import { ExportDataButton } from '../../../../components/ExportDataButton/ExportDataButton'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { OverlayLoader } from '../../../../components/OverlayLoader/OverlayLoader'
import { TimelineChart } from '../../../../components/TimelineChart/TimelineChart'
import { valueIsBetween } from '../../../../helpers/valueIsBetween'
import { useExportDataPerformanceChart } from '../../../../hooks/exportDataHooks'
import { RoleEnum } from '../../../account'
import { PerformanceEstimate } from '../../../estimates'
import { generateExport } from '../../../export-data/util/workbook'
import { TopGame } from '../../../top-game/types/TopGame'
import { ChartDataFormat, DataTypeSelectorOptionValue, PerformanceChartDataType, PerformanceRank } from '../../types/types'
import { DataTypeSelector } from '../DataTypeSelector/DataTypeSelector'

type PerformanceChartProps<MarkDataType> = {
  topGame?: TopGame
  estimates?: PerformanceEstimate[]
  topGrossingRanks?: PerformanceRank[]
  freeRanks?: PerformanceRank[]
  dateRange?: DateRangeValue
  onDateRangeChanged?: (value?: DateRangeValue) => void
  dataType: DataTypeSelectorOptionValue
  onDataTypeChanged?: (value: DataTypeSelectorOptionValue) => void
  rollingDays?: number
  isLoading?: boolean
  verticalMarks?: { position: number; data: MarkDataType }[]
  highlightedVerticalMarks?: { position: number; data: MarkDataType }[]
  selectedVerticalMarks?: { position: number; data: MarkDataType }[]
  onMarkClicked?: (value: MarkDataType) => void
  config?: {
    showDateRangePicker?: boolean
    showDataTypeSelector?: boolean
    showExportButton?: boolean
  }
}

export enum PerformanceChartColor {
  Primary = '#bd65cf',
  Secondary = '#C7D6E1',
  Tertiary = '#6488B4',
}

const defaultConfig = {
  showDateRangePicker: true,
  showDataTypeSelector: true,
  showExportButton: true,
}

/**
 * Component for displaying performance data (download and revenue estimates and ranks) in a timeline chart.
 * @deprecated Use PerformanceChartV2 instead
 */
export const PerformanceChart = <MarkDataType extends object>({
  topGame,
  estimates = [],
  topGrossingRanks = [],
  freeRanks = [],
  dateRange,
  onDateRangeChanged,
  dataType,
  onDataTypeChanged,
  rollingDays,
  isLoading,
  verticalMarks,
  highlightedVerticalMarks,
  selectedVerticalMarks,
  onMarkClicked,
  config = defaultConfig,
}: PerformanceChartProps<MarkDataType>) => {
  const { t } = useTranslation()

  const handleDataTypeChange = useCallback(
    (event: SelectChangeEvent<DataTypeSelectorOptionValue>) => {
      onDataTypeChanged && onDataTypeChanged(event.target.value as DataTypeSelectorOptionValue)
    },
    [onDataTypeChanged]
  )

  // date range picker is disabled for certain data types
  const dateRangePickerDisabled = [
    PerformanceChartDataType.RevenueDownloadsRatio7Days,
    PerformanceChartDataType.RevenueDownloadsRatio30Days,
    PerformanceChartDataType.RevenueDownloadsRatio90Days,
    PerformanceChartDataType.RevenueDownloadsRatioAllTime,
  ].includes(dataType as PerformanceChartDataType)

  // generate datasets and other config for chart drawing
  const chartConfig = useMemo(() => {
    switch (dataType) {
      case PerformanceChartDataType.Estimates:
        return {
          datasets: [
            resolveDataset(
              'y1',
              `${t('common:revenue_text')} (${t('common:apple_ios')})`,
              PerformanceChartColor.Primary,
              ChartDataFormat.Currency,
              estimates?.map((estimate) => ({
                x: estimate.ts,
                y: estimate.revenue || 0,
              })),
              dateRange
            ),

            resolveDataset(
              'y2',
              `${t('common:downloads_text')} (${t('common:apple_ios')})`,
              PerformanceChartColor.Secondary,
              ChartDataFormat.Number,
              estimates?.map((estimate) => ({
                x: estimate.ts,
                y: estimate.downloads || 0,
              })),
              dateRange
            ),
          ],
          scaleConfig: {
            y1: {
              dataFormat: ChartDataFormat.Currency,
              title: `${t('common:revenue_text')} (${t('common:apple_ios')})`,
              shorten: true,
            },
            y2: {
              dataFormat: ChartDataFormat.Number,
              title: `${t('common:downloads_text')} (${t('common:apple_ios')})`,
              shorten: true,
            },
          },
        }
      case PerformanceChartDataType.RevenueDownloadsRatio7Days:
      case PerformanceChartDataType.RevenueDownloadsRatio30Days:
      case PerformanceChartDataType.RevenueDownloadsRatio90Days:
        return {
          datasets: [
            resolveDataset(
              'y1',
              `${t('common:revenue_downloads_ratio_days', { days: rollingDays })} (${t('common:apple_ios')})`,
              PerformanceChartColor.Primary,
              ChartDataFormat.Currency,
              estimates?.map((estimate) => ({
                x: estimate.ts,
                y: estimate.revenueAndDownloadsRatio || 0,
              })),
              dateRange
            ),
          ],
          scaleConfig: {
            y1: {
              dataFormat: ChartDataFormat.Currency,
              title: `${t('common:revenue_downloads_ratio_days', { days: rollingDays })} (${t('common:apple_ios')})`,
              shorten: true,
            },
          },
        }
      case PerformanceChartDataType.RevenueDownloadsRatioAllTime:
        return {
          datasets: [
            resolveDataset(
              'y1',
              `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
              PerformanceChartColor.Primary,
              ChartDataFormat.Currency,
              estimates?.map((estimate) => ({
                x: estimate.ts,
                y: estimate.revenueAndDownloadsRatio || 0,
              })),
              dateRange
            ),
          ],
          scaleConfig: {
            y1: {
              dataFormat: ChartDataFormat.Currency,
              title: `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
              shorten: true,
            },
          },
        }
      case PerformanceChartDataType.Ranks:
        return {
          datasets: [
            resolveDataset(
              'y1',
              t('common:top_grossing_rank'),
              PerformanceChartColor.Primary,
              ChartDataFormat.Number,
              topGrossingRanks?.map((rank) => ({
                x: rank.ts,
                y: rank.rank,
              })),
              dateRange
            ),
            resolveDataset(
              'y1',
              t('common:free_rank'),
              PerformanceChartColor.Secondary,
              ChartDataFormat.Number,
              freeRanks?.map((rank) => ({
                x: rank.ts,
                y: rank.rank,
              })),
              dateRange
            ),
          ],
          scaleConfig: {
            y1: {
              dataFormat: ChartDataFormat.Number,
              title: t('common:rank'),
              reverse: true,
            },
          },
        }
      default:
        return {
          datasets: [],
          scaleConfig: {},
        }
    }
  }, [estimates, topGrossingRanks, freeRanks, dataType, rollingDays, dateRange, t])

  // resolve min and max values for time range based on data
  const dateRangeLimits = useMemo(() => {
    if (dataType === PerformanceChartDataType.Ranks) {
      const sortedRanks = [...topGrossingRanks, ...freeRanks].sort((a, b) => a.ts - b.ts)
      const dateRangeMin = sortedRanks[0]?.ts || 0
      const dateRangeMax = sortedRanks[sortedRanks.length - 1]?.ts || 0

      return { dateRangeMin, dateRangeMax }
    } else {
      const sortedEstimates = [...estimates].sort((a, b) => a.ts - b.ts)
      const dateRangeMin = sortedEstimates[0]?.ts || 0
      const dateRangeMax = sortedEstimates[sortedEstimates.length - 1]?.ts || 0

      return { dateRangeMin, dateRangeMax }
    }
  }, [estimates, topGrossingRanks, freeRanks, dataType])

  // filter vertical marks to fit into selected time range
  const filteredVerticalMarks = useMemo(() => {
    return verticalMarks?.filter((mark) => valueIsBetween(mark.position, dateRange?.fromDate?.getTime(), dateRange?.toDate?.getTime()))
  }, [verticalMarks, dateRange])

  // filter highlighted vertical marks to fit into selected time range
  const filteredHighlightedVerticalMarks = useMemo(() => {
    return highlightedVerticalMarks?.filter((mark) => valueIsBetween(mark.position, dateRange?.fromDate?.getTime(), dateRange?.toDate?.getTime()))
  }, [highlightedVerticalMarks, dateRange])

  const filteredSelectedVerticalMarks = useMemo(() => {
    return selectedVerticalMarks?.filter((mark) => valueIsBetween(mark.position, dateRange?.fromDate?.getTime(), dateRange?.toDate?.getTime()))
  }, [dateRange?.fromDate, dateRange?.toDate, selectedVerticalMarks])

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)
  const { exportRows, headerRows, filename } = useExportDataPerformanceChart(topGame, estimates, isExporting)

  useEffect(() => {
    if (!isExporting || !exportRows.length || !headerRows) return
    setIsExporting(false)
    generateExport(exportFormat, exportRows, 'Game Performance', filename, headerRows)
  }, [exportRows, headerRows, filename, isExporting, exportFormat])

  const minDate = useMemo(() => {
    // find lowest timestamp from estimates + don't count 0 timestamps
    if (!estimates || !estimates.length) return new Date(dateRangeLimits.dateRangeMin)
    const minEstimateTs = estimates.reduce((min, estimate) => {
      if (estimate.ts && estimate.ts < min) {
        return estimate.ts
      }
      return min
    }, Number.MAX_SAFE_INTEGER)

    return new Date(minEstimateTs)
  }, [dateRangeLimits.dateRangeMin, estimates])

  const handleExportData = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  // set time range from chart data min and max values
  useEffect(() => {
    if (dateRangePickerDisabled) {
      const { dateRangeMin, dateRangeMax } = dateRangeLimits
      const from = dateRangeMin ? new Date(dateRangeMin) : null
      const to = dateRangeMax ? new Date(dateRangeMax) : null
      onDateRangeChanged && onDateRangeChanged({ fromDate: from, toDate: to })
    }
  }, [dateRangePickerDisabled, dateRangeLimits, onDateRangeChanged])

  if (isLoading) return <GRCircularProgress />

  if (dateRangeLimits.dateRangeMin === 0) return <Typography sx={{ textAlign: 'center', fontSize: '24px', margin: 8 }}>{t('common:not_ranked')}</Typography>

  return (
    <Grid container wrap="wrap" rowGap={3}>
      <Grid item container justifyContent="space-between" wrap="wrap" gap={3}>
        <Grid item lg={6}>
          {dateRangePickerDisabled ? (
            <GRTooltip content={t('overview:date_picker_disabled_rd_type')}>
              <DateRangePicker onChangeValue={onDateRangeChanged} value={dateRange} minDate={minDate} disabled={dateRangePickerDisabled} />
            </GRTooltip>
          ) : (
            <DateRangePicker onChangeValue={onDateRangeChanged} value={dateRange} minDate={minDate} disabled={dateRangePickerDisabled} />
          )}
        </Grid>
        <Grid item>
          <Grid container columnGap={3}>
            {config.showDataTypeSelector && (
              <Grid item>
                <DataTypeSelector value={dataType} onChange={handleDataTypeChange} />
              </Grid>
            )}
            {config.showExportButton && (
              <Grid item>
                <ExportDataButton
                  accessRoles={[RoleEnum.csv_export_games, RoleEnum.csv_update_history]}
                  onChooseFormat={handleExportData}
                  disabled={isLoading}
                  analyticsEventOrigin="Performance Chart"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={12}>
          <OverlayLoader isLoading={isLoading}>
            <TimelineChart
              chartLineOptions={{
                responsive: true,
                maintainAspectRatio: true,
                animation: false,
              }}
              data={{ datasets: chartConfig?.datasets }}
              scaleConfig={chartConfig.scaleConfig}
              verticalMarks={filteredVerticalMarks}
              highlightedVerticalMarks={filteredHighlightedVerticalMarks}
              selectedVerticalMarks={filteredSelectedVerticalMarks}
              onVerticalMarkClicked={onMarkClicked as ((data: object) => void) | undefined}
            />
          </OverlayLoader>
        </Grid>
      </Grid>
    </Grid>
  )
}

// helper for creating datasets for chart
const resolveDataset = (
  axisId: string,
  label: string,
  color: string,
  tooltipFormat: ChartDataFormat,
  data: { x: number; y: number }[] = [],
  dateRange?: DateRangeValue
) => {
  return {
    yAxisID: axisId,
    label: label,
    fill: false,
    spanGaps: 172800000, // 2 days
    tension: 0.1,
    backgroundColor: color,
    borderColor: color,
    data: data.filter((item) => valueIsBetween(item.x, dateRange?.fromDate?.getTime(), dateRange?.toDate?.getTime())),
    tooltipFormat,
  }
}
