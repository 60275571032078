import { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogContent, Grid, IconButton } from '@mui/material'

import { EventScreenshot } from '../../../types/EventScreenshot'

type EventScreenshotDialogProps = {
  open: boolean
  onClose: () => void
  screenshotData: EventScreenshot | undefined
}

export const EventScreenshotDialog: FC<EventScreenshotDialogProps> = ({ open, onClose, screenshotData }) => {
  const handleDialogClose = () => {
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="xl">
      <DialogContent dividers>
        <Grid container spacing={2} wrap="wrap" alignItems="center" justifyContent="center">
          <Grid item sx={{ width: '100%' }}>
            <IconButton
              onClick={handleDialogClose}
              sx={{
                position: 'absolute',
                top: '7px',
                right: '5px',
                backgroundColor: '#bd65cf',
                color: '#fff',
              }}
            >
              <CloseIcon />
            </IconButton>
            <img style={{ width: '100%', maxWidth: '100%', maxHeight: '85vh' }} alt="Screenshot" src={screenshotData?.name} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
