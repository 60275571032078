import { format } from 'date-fns'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Add, Delete, Mail, Person, Search, VpnKey } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, FormControlLabel, Grid, IconButton, Switch, TableContainer, TextField, Tooltip, Typography } from '@mui/material'

import {
  useDeleteOrganizationUserInviteMutation,
  useRemoveCurrentUserOrganizationUserMutation,
  useResetCurrentUserOrganizationUserPasswordMutation,
  useUpdateUserActiveStatusMutation,
  useUpdateUserAdminStatusMutation,
} from '../../api/account'
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog'
import { GRTable, GRTableColumn } from '../../components/GRTable/GRTable'
import { GRTooltip } from '../../components/GRTooltip/GRTooltip'
import { HtmlContentParser } from '../../components/HtmlContentParser/HtmlContentParser'
import { screenshotsModalParserOptions } from '../../components/Review/Review'
import InviteUsersDialog from '../../features/account/components/InviteUsersDialog/InviteUsersDialog'
import { useCurrentOrganizationSeatLimit, useCurrentOrganizationUserInvites } from '../../features/account/hooks/organizationHooks'
import { useGameSlotsUnlimitedAccessCheck, useUserIsAdminCheck } from '../../features/account/hooks/roleHooks'
import { useCurrentUserOrganizationUsers } from '../../features/account/hooks/userHooks'
import { User } from '../../features/account/types/User'
import { UserInvite } from '../../features/account/types/UserInvite'
import { displaySnackBar } from '../../features/snackbar'
import { useAppDispatch } from '../../hooks/storeHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import PageService from '../../services/PageService'
import utilsService from '../../services/UtilsService'
import { ConfirmDialogData } from '../../types/ConfirmDialogData'
import './OrganizationUsersPage.scss'

enum ConfirmDialogEnum {
  remove_user = 'remove_user',
  reset_user_password = 'reset_user_password',
}

interface OrganizationUsersProps extends User {
  title: string
  lastLoginAt: number
  active: boolean
  invitingEmail?: string
}

const OrganizationUsersPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  usePage(PageService.getPageWithId('organization-users'), 'Visited Organization Users')
  useDocumentTitle(t('organization:users'))

  const isOrganizationAdmin = useUserIsAdminCheck()
  const { users, usersLoading, usersError } = useCurrentUserOrganizationUsers()
  const [updateUserActiveStatus] = useUpdateUserActiveStatusMutation()
  const [updateUserAdminStatus] = useUpdateUserAdminStatusMutation()
  const [removeUserFromCurrentOrganization] = useRemoveCurrentUserOrganizationUserMutation()
  const [deleteOrganizationUserInvite] = useDeleteOrganizationUserInviteMutation()
  const [resetCurrentUserOrganizationUserPassword] = useResetCurrentUserOrganizationUserPasswordMutation()
  const { userInvites, userInvitesLoading, userInvitesError } = useCurrentOrganizationUserInvites()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmDialogData, setConfirmDialogData] = useState<ConfirmDialogData<User | UserInvite>>({})
  const [usersSearchText, setUsersSearchText] = useState('')
  const [inviteUsersDialogOpen, setInviteUsersDialogOpen] = useState(false)
  const organizationSeatLimit = useCurrentOrganizationSeatLimit()
  const hasUnlimitedGameSlots = useGameSlotsUnlimitedAccessCheck()

  const removeUser = useCallback(
    (user: User | UserInvite) => {
      setConfirmDialogData({
        actionId: ConfirmDialogEnum.remove_user,
        destructiveAction: true,
        actionText: t('organization:remove_user'),
        confirmText: <Trans i18nKey="organization:confirm_remove_user" values={{ firstName: user.firstName, lastName: user.lastName }} />,
        data: user,
      })
      setConfirmDialogOpen(true)
    },
    [t]
  )

  const resetUserPassword = useCallback(
    (user: User) => {
      setConfirmDialogData({
        actionId: ConfirmDialogEnum.reset_user_password,
        actionText: t('common:yes'),
        confirmText: t('organization:reset_and_resend_user_password'),
        data: user,
      })
      setConfirmDialogOpen(true)
    },
    [t]
  )

  const toggleUserActiveStatus = useCallback(
    (user: User) => {
      updateUserActiveStatus(user).then(() => {
        dispatch(displaySnackBar({ message: t('organization:success_user_active_status_changed'), severity: 'success', open: true }))
      })
    },
    [dispatch, t, updateUserActiveStatus]
  )

  const toggleUserAdminRights = useCallback(
    (user: User) => {
      updateUserAdminStatus(user).then(() => {
        dispatch(displaySnackBar({ message: t('organization:success_user_admin_status_changed'), severity: 'success', open: true }))
      })
    },
    [dispatch, t, updateUserAdminStatus]
  )

  const isLastAdmin = useMemo(() => users.filter((user) => user.oadmin).length === 1, [users])

  const usersSearchOnChange = (event: any) => {
    setUsersSearchText(event.target.value ? event.target.value.toLowerCase() : '')
  }

  const openAddUsersDialog = () => {
    setInviteUsersDialogOpen(true)
  }

  const handleAddUsersDialogClose = () => {
    setInviteUsersDialogOpen(false)
  }

  const handleConfirmDialogClose = (previousConfirmDialogData?: ConfirmDialogData<User | UserInvite>) => {
    if (previousConfirmDialogData) {
      const SELECTED_USER_OR_INVITE: any = previousConfirmDialogData.data

      switch (previousConfirmDialogData?.actionId) {
        case ConfirmDialogEnum.remove_user:
          if (SELECTED_USER_OR_INVITE.invitingEmail) {
            deleteOrganizationUserInvite(SELECTED_USER_OR_INVITE.id).then(() => {
              dispatch(displaySnackBar({ message: t('organization:success_user_removed_from_organization'), severity: 'success', open: true }))
            })
          } else {
            removeUserFromCurrentOrganization(SELECTED_USER_OR_INVITE as User).then(() => {
              dispatch(displaySnackBar({ message: t('organization:success_user_removed_from_organization'), severity: 'success', open: true }))
            })
          }
          break

        case ConfirmDialogEnum.reset_user_password:
          resetCurrentUserOrganizationUserPassword({ username: SELECTED_USER_OR_INVITE.username, password: utilsService.getRandomPassword() }).then(() => {
            dispatch(displaySnackBar({ message: t('organization:reset_and_resend_success'), severity: 'success', open: true }))
          })
          break
      }
    }
    setConfirmDialogOpen(false)
  }

  const userAdminStatusCanBeChanged = useCallback((user: any) => {
    return !user.invitingEmail
  }, [])

  const activeUsersCount = useMemo(() => {
    return users ? users.filter((user) => user.active).length : 0
  }, [users])

  const hasSeatsLeft = useMemo(() => activeUsersCount < organizationSeatLimit, [activeUsersCount, organizationSeatLimit])

  const userActiveStatusCanBeActivated = useCallback(
    (user?: any) => {
      if (user && user.invitingEmail) {
        return false
      } else if (organizationSeatLimit === 0) {
        return true
      }
      return hasSeatsLeft
    },
    [hasSeatsLeft, organizationSeatLimit]
  )

  const initialColumns: GRTableColumn<OrganizationUsersProps, OrganizationUsersProps>[] = useMemo(() => {
    return [
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('organization:users'),
            accessor: ({ row }) => {
              return (
                <Grid container>
                  <Grid item xs={9}>
                    <Box>
                      {row.firstName ? (
                        <Typography>
                          {row.firstName} {row.lastName}
                        </Typography>
                      ) : null}
                      <Box sx={{ display: 'inline-flex', alignItems: 'flex-end', width: '100%' }}>
                        <Typography>
                          <a href={`mailto:${row.email}`}>{row.email}</a>
                        </Typography>
                        {row.lastLoginAt === 0 || row.invitingEmail ? (
                          <Box className="right-margin" sx={{ marginLeft: 'auto' }}>
                            <GRTooltip content={t('common:user_invite_pending')}>
                              <Mail color="disabled" style={{ fontSize: '18px' }} />
                            </GRTooltip>
                          </Box>
                        ) : null}
                      </Box>
                      {row && row.title ? (
                        <Typography className="light-text-color" sx={{ marginTop: '4px', fontSize: 12 }}>
                          {row.title}
                        </Typography>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {row.oadmin && <Person />}
                  </Grid>
                </Grid>
              )
            },
            sortable: true,
            sortAccessor: ({ row }) => row.firstName,
            headerCellProps: { sx: { minWidth: 200 } },
            cellProps: { align: 'left' },
          },
        ],
      },
      {
        columns: [
          {
            labelAccessor: t('organization:last_login'),
            accessor: ({ row }) => {
              const convertDateTime = row?.lastLoginAt ? format(row.lastLoginAt, 'dd.MM.yyyy, kk:mm') : ''
              return <Typography>{row.lastLoginAt ? convertDateTime : ''}</Typography>
            },
            sortable: true,
            sortAccessor: ({ row }) => row.lastLoginAt,
            headerCellProps: { sx: { maxWidth: 100 } },
          },
        ],
      },
      {
        columns: [
          {
            accessor: ({ row }) => {
              return (
                <Grid container spacing={2} justifyContent="center" alignItems={'center'}>
                  {isOrganizationAdmin ? (
                    <Grid item>
                      <Tooltip title={row.invitingEmail ? '' : t<string>('organization:change_user_status')}>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={row.active}
                              onChange={() => toggleUserActiveStatus(row)}
                              name="active"
                              color="primary"
                              disabled={!row.active && !userActiveStatusCanBeActivated(row)}
                            />
                          }
                          label={t<string>('common:active')}
                        />
                      </Tooltip>
                    </Grid>
                  ) : null}
                  {isOrganizationAdmin ? (
                    <Grid item>
                      <Tooltip title={row.invitingEmail ? '' : t<string>('organization:change_admin_rights')}>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={row.oadmin}
                              onChange={() => {
                                if (isLastAdmin && row.oadmin) {
                                  return dispatch(
                                    displaySnackBar({
                                      message: `${t('organization:info_organization_must_have_admin')}. 
                                      ${t('organization:info_please_set_another_user_as_admin_first')}`,
                                      severity: 'error',
                                      open: true,
                                    })
                                  )
                                }

                                toggleUserAdminRights(row)
                              }}
                              name="active"
                              color="primary"
                              disabled={!row.active && !userAdminStatusCanBeChanged(row)}
                            />
                          }
                          label={t<string>('common:admin')}
                        />
                      </Tooltip>
                    </Grid>
                  ) : null}

                  {isOrganizationAdmin ? (
                    <Grid item>
                      <Tooltip title={row.invitingEmail ? '' : t<string>('organization:tooltip_reset_and_resend_user_password')}>
                        <span>
                          <IconButton
                            disabled={row.invitingEmail ? true : false}
                            onClick={() => {
                              resetUserPassword(row)
                            }}
                          >
                            <VpnKey color={row.invitingEmail ? 'disabled' : 'secondary'} style={{ fontSize: '18px' }} />
                          </IconButton>
                        </span>
                      </Tooltip>

                      <Tooltip title={t<string>('organization:remove_user')}>
                        <IconButton
                          onClick={() => {
                            removeUser(row)
                          }}
                        >
                          <Delete color="error" style={{ fontSize: '18px' }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : null}
                </Grid>
              )
            },
            headerCellProps: { sx: { minWidth: 250 } },
          },
        ],
      },
    ]
  }, [
    t,
    isOrganizationAdmin,
    userActiveStatusCanBeActivated,
    userAdminStatusCanBeChanged,
    toggleUserActiveStatus,
    isLastAdmin,
    dispatch,
    toggleUserAdminRights,
    resetUserPassword,
    removeUser,
  ]) as GRTableColumn<OrganizationUsersProps, OrganizationUsersProps>[]

  const containerRef = useRef(null)
  const [columns, setColumns] = useState(initialColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<OrganizationUsersProps, OrganizationUsersProps, void>[]) => {
    setColumns(updatedColumns)
  }, [])

  const filteredRows = useMemo(() => {
    if (!users || !userInvites) return []

    let filteredUsers: Array<any> = [...users, ...userInvites].sort((a: { email: string }, b: { email: string }) => (a.email < b.email ? -1 : 1))

    if (usersSearchText) {
      filteredUsers = filteredUsers.filter((user) => {
        let showUser = false

        if (user.firstName && user.firstName.toLowerCase().includes(usersSearchText)) {
          showUser = true
        } else if (user.lastName && user.lastName.toLowerCase().includes(usersSearchText)) {
          showUser = true
        } else if (user.email && user.email.toLowerCase().includes(usersSearchText)) {
          showUser = true
        }

        return showUser
      })
    }
    return filteredUsers
  }, [userInvites, users, usersSearchText])

  useEffect(() => {
    handleColumnsUpdate(initialColumns)
  }, [handleColumnsUpdate, initialColumns])

  return (
    <div className="OrganizationUsersPage">
      {usersLoading || userInvitesLoading ? (
        <div className="text-center top-margin bottom-margin">
          <CircularProgress color="primary" />
        </div>
      ) : null}

      {!usersLoading && !userInvitesLoading && (usersError || userInvitesError) && (
        <div className="text-center top-margin">{t('common:general_error_message')}</div>
      )}

      {!usersLoading && !usersError && !userInvitesLoading && !userInvitesError && users && userInvites ? (
        <div>
          <Grid container sx={{ alignItems: 'center', marginBottom: '16px' }}>
            <Grid item sm>
              <HtmlContentParser rawHtml={t('organization:admins_marked_with')} parserOptions={screenshotsModalParserOptions} />
              <Person sx={{ verticalAlign: 'bottom' }} />
            </Grid>
            <Grid item sm sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px' }}>
              <Typography>
                {t('organization:available_seats_text', {
                  seatsCount: activeUsersCount,
                  maxSeatsCount: organizationSeatLimit === 0 ? '∞' : organizationSeatLimit,
                })}
              </Typography>
            </Grid>
            {isOrganizationAdmin ? (
              <Grid item>
                <Button
                  disabled={!hasUnlimitedGameSlots && activeUsersCount >= organizationSeatLimit}
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={openAddUsersDialog}
                >
                  {t('organization:add_users')}
                </Button>

                <InviteUsersDialog
                  dialogOpen={inviteUsersDialogOpen}
                  activeUsersCount={activeUsersCount}
                  handleClose={handleAddUsersDialogClose}
                ></InviteUsersDialog>
              </Grid>
            ) : null}
          </Grid>

          <Grid container sx={{ marginBottom: '6px' }}>
            <Grid item sm>
              <div id="users-search">
                <TextField
                  className="bottom-margin"
                  size="small"
                  fullWidth={true}
                  label={
                    <div>
                      <Search style={{ fontSize: '18px', verticalAlign: 'middle', marginRight: '5px', marginTop: '-2px' }} />
                      <span>{t('common:search')}</span>
                    </div>
                  }
                  variant="outlined"
                  color="primary"
                  onChange={usersSearchOnChange}
                />
              </div>
            </Grid>
          </Grid>

          <TableContainer component={Card} ref={containerRef}>
            <GRTable
              columns={columns}
              rows={filteredRows}
              scroller={containerRef}
              onColumnsUpdated={handleColumnsUpdate}
              rowIdKey="id"
              hoverable
              striped
              noRowsLabel={t('organization:no_users_found')}
            />
          </TableContainer>
        </div>
      ) : null}

      <ConfirmDialog open={confirmDialogOpen} onClose={handleConfirmDialogClose} confirmDialogData={confirmDialogData}></ConfirmDialog>
    </div>
  )
}

export default OrganizationUsersPage
