import { FC } from 'react'

import { Chip, Grid } from '@mui/material'

import utilsService from '../../../../../../services/UtilsService'
import { useMarketExplorerSegmentFilterGroups, useMotivationFilterConfig } from '../../../../hooks/marketExplorerHooks'
import { MarketExplorerSegmentConfiguration, MarketExplorerSegmentFilterValue } from '../../../../types/MarketExplorerSegmentConfiguration'
import { MarketExplorerSegmentFilterGroup, MarketExplorerSegmentFilterValueType } from '../../../../types/MarketExplorerSegmentFilterConfig'

/**
 * Component for rendering market explorer segment slider filter chips to segment card
 */

type SegmentFilterChipsProps = {
  filterGroups: MarketExplorerSegmentConfiguration['filters']
}

export const SegmentFilterChips: FC<SegmentFilterChipsProps> = ({ filterGroups }) => {
  const marketExplorerSegmentMotivationsFilterGroups = useMotivationFilterConfig()
  const { marketExplorerSegmentRanksFilterGroups, marketExplorerSegmentDemographicsFilterGroups, marketExplorerSegmentPlayerArchetypesFilterGroups } =
    useMarketExplorerSegmentFilterGroups()

  return (
    <Grid container alignItems="center" wrap="wrap">
      <SegmentFilterGroupChips
        filterGroupConfig={marketExplorerSegmentRanksFilterGroups}
        filters={filterGroups.ranks as { [key: string]: MarketExplorerSegmentFilterValue }}
      />
      <SegmentFilterGroupChips
        filterGroupConfig={marketExplorerSegmentDemographicsFilterGroups}
        filters={filterGroups.demographics as { [key: string]: MarketExplorerSegmentFilterValue }}
      />
      <SegmentFilterGroupChips
        filterGroupConfig={marketExplorerSegmentMotivationsFilterGroups}
        filters={filterGroups.motivations as { [key: string]: MarketExplorerSegmentFilterValue }}
      />
      <SegmentFilterGroupChips
        filterGroupConfig={marketExplorerSegmentPlayerArchetypesFilterGroups}
        filters={filterGroups.archetypes as { [key: string]: MarketExplorerSegmentFilterValue }}
      />
    </Grid>
  )
}

type SegmentFilterGroupChipsProps<T> = {
  filterGroupConfig: MarketExplorerSegmentFilterGroup<T>[]
  filters: { [key: string]: MarketExplorerSegmentFilterValue }
}

const SegmentFilterGroupChips = <T extends {}>({ filterGroupConfig, filters }: SegmentFilterGroupChipsProps<T>) => {
  return (
    <>
      {Object.entries(filters)
        .filter(([key, value]) => !!value)
        .map(([filterId, filterValue]) => {
          const filterConfig = filterGroupConfig
            .flatMap((filterGroup) => filterGroup.filters)
            .find((filter) => (filter.id as unknown as string) === (filterId as string))

          const label = filterConfig?.label
          const [minValue, maxValue] = filterValue as number[]
          const formattedMinValue = filterConfig?.type === MarketExplorerSegmentFilterValueType.Percent ? utilsService.formatPercent(minValue) : minValue
          const formattedMaxValue = filterConfig?.type === MarketExplorerSegmentFilterValueType.Percent ? utilsService.formatPercent(maxValue) : maxValue

          return (
            <Grid item key={filterId}>
              <Chip color="primary" size="small" label={`${label}: ${formattedMinValue} - ${formattedMaxValue}`} key={label} />
            </Grid>
          )
        })}
    </>
  )
}
