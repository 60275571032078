import { t } from 'i18next'
import { FC, useCallback, useMemo } from 'react'

import { Search } from '@mui/icons-material'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'

import { CompareGameFeature, ConceptTag, Keyword } from '../../../../api/core'
import { useFeatureTagsAccessCheck } from '../../../account/hooks/roleHooks'
import { FeatureChoice } from '../../../game-features/types/types'
import { EnrichedFeature } from '../../../implementation-examples'
import { isCompareFeatureChoice, isConceptTag, isEnrichedFeature, isFeatureChoice, isKeyword } from '../../helpers/helpers'
import { FeatureAndKeywordSearchResult } from '../../types/types'

type FeaturesAndKeywordsAutocompleteProps = {
  value: FeatureAndKeywordSearchResult
  features?: EnrichedFeature[] | FeatureChoice[] | CompareGameFeature[]
  conceptTags?: ConceptTag[]
  keywords?: Keyword[]
  onChange?: (SearchResult: FeatureAndKeywordSearchResult) => void
  onFocus?: () => void
  isLoading?: boolean
}

/**
 * Component presenting an autocomplete with feature and keyword options
 */
export const FeaturesAndKeywordsAutocomplete: FC<FeaturesAndKeywordsAutocompleteProps> = ({
  value,
  onChange,
  onFocus,
  features = [],
  keywords = [],
  conceptTags = [],
  isLoading,
}) => {
  const hasAccessToFeatureTags = useFeatureTagsAccessCheck()

  const handleChange = useCallback(
    (e: React.SyntheticEvent, value: string | FeatureChoice | EnrichedFeature | Keyword | CompareGameFeature | ConceptTag | null) => {
      onChange && onChange(value)
    },
    [onChange]
  )

  const getOptionLabel = (value: EnrichedFeature | FeatureChoice | CompareGameFeature | ConceptTag | Keyword | string) => {
    if (isCompareFeatureChoice(value)) {
      return value.featureName
    }

    if (isEnrichedFeature(value)) {
      return value.name
    }

    if (isKeyword(value)) {
      return value.name
    }

    if (isFeatureChoice(value)) {
      return value.featureLabel
    }

    if (isConceptTag(value)) {
      return value.name
    }

    return value
  }

  let featuresConceptTagsAndKeywords = useMemo(() => {
    const sortedFeatures = [...features].sort((a, b) => (getOptionLabel(a) < getOptionLabel(b) ? -1 : 1))
    const sortedKeywords = [...keywords].sort((a, b) => (getOptionLabel(a) < getOptionLabel(b) ? -1 : 1))

    if (hasAccessToFeatureTags && conceptTags?.length > 0) {
      const sortedConceptTags = [...conceptTags].sort((a, b) => (a.name < b.name ? -1 : 1))

      return [...sortedFeatures, ...sortedConceptTags, ...sortedKeywords]
    }

    return [...sortedFeatures, ...sortedKeywords]
  }, [conceptTags, features, hasAccessToFeatureTags, keywords])

  return (
    <Autocomplete
      value={value}
      fullWidth
      freeSolo
      size="small"
      onChange={handleChange}
      onFocus={onFocus}
      options={featuresConceptTagsAndKeywords}
      getOptionLabel={getOptionLabel}
      groupBy={(value) => {
        switch (true) {
          case isEnrichedFeature(value):
          case isFeatureChoice(value):
            return t('common:features')
          case isKeyword(value):
            return t('common:feature_keywords')
          case isConceptTag(value):
            return t('implementations:feature_tag')
          default:
            return ''
        }
      }}
      loading={isLoading}
      disabled={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <span>
              {isLoading ? <CircularProgress size={24} sx={{ verticalAlign: 'middle', mr: 0.5 }} /> : <Search sx={{ verticalAlign: 'middle', mr: 0.5 }} />}
              {(!hasAccessToFeatureTags || (hasAccessToFeatureTags && !conceptTags.length)) && t('common:filter_features_with_name_keyword')}
              {hasAccessToFeatureTags && conceptTags?.length > 0 && t('common:filter_features_and_concepts_with_name_keyword')}
            </span>
          }
        />
      )}
    />
  )
}
