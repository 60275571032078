import { t } from 'i18next'
import { FC } from 'react'

import { PriceChangeOutlined } from '@mui/icons-material'
import { Box, BoxProps, Chip } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'

type PriceRangeIndicatorProps = BoxProps & {
  priceRange:
    | {
        minPricePointValue: number
        maxPricePointValue: number
      }
    | null
    | undefined
}

export const PriceRangeIndicator: FC<PriceRangeIndicatorProps> = ({ priceRange, ...boxProps }) => {
  if (priceRange) {
    const toolTipContent =
      priceRange.minPricePointValue === priceRange.maxPricePointValue
        ? t('live-events:price_range') + `: $${priceRange.maxPricePointValue}`
        : t('live-events:price_range') + `: $${priceRange.minPricePointValue} - $${priceRange.maxPricePointValue}`

    const chipLabelText =
      priceRange.minPricePointValue === priceRange.maxPricePointValue
        ? `$${priceRange.maxPricePointValue}`
        : `$${priceRange.minPricePointValue} - $${priceRange.maxPricePointValue}`

    return (
      <>
        <Box sx={{ mb: 1.5 }} {...boxProps}>
          <GRTooltip content={toolTipContent}>
            <Chip
              sx={{ '& .MuiChip-label': { fontSize: '13px' } }}
              variant="outlined"
              label={chipLabelText}
              avatar={<PriceChangeOutlined sx={{ margin: '0px -6px 0px 5px !important' }} />}
            />
          </GRTooltip>
        </Box>
      </>
    )
  }
  return null
}
