import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Collection from './Collection'
import Collections from './Collections'

const CollectionPage: React.FC = () => (
  <Routes>
    <Route path="" element={<Collections />} />
    <Route path=":category/:collectionId/*" element={<Collection />} />
  </Routes>
)

export default CollectionPage
