import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import GroupsIcon from '@mui/icons-material/Groups'
import { Badge, Box, Card, CardContent, CardMedia, CardProps, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material'

import { useDeleteBookmarkMutation } from '../../../api/core'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import ShowMore from '../../../components/ShowMore/ShowMore'
import { displaySnackBar } from '../../../features/snackbar/actions/displaySnackBar'
import { useAppDispatch } from '../../../hooks/storeHooks'
import { Bookmark } from '../types/Bookmark'
import './BookmarkCard.scss'
import EditBookMarkDialog from './EditBookmarkDialog'

type BookmarkCardProps = CardProps & {
  bookmark: Bookmark
  shared?: boolean
}

/**
 * Component that displays a bookmark card
 * @param bookmark
 * @returns BookmarkCard
 */
export const BookmarkCard: FC<BookmarkCardProps> = ({ bookmark, shared, onClick, ...cardProps }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [hidden, setHidden] = useState(true)
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [editBookmarkOpen, setEditBookmarkOpen] = useState(false)
  const [deleteBoomark, { isError: isDeleteError, isSuccess: isDeleteSuccss }] = useDeleteBookmarkMutation()

  const handleClose = () => {
    setSpeedDialOpen(false)
  }

  const handleOpen = () => {
    setSpeedDialOpen(true)
  }

  const handleSpeedDialClick: React.MouseEventHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDelete: React.MouseEventHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    deleteBoomark(bookmark.id)
  }

  const handleEdit: React.MouseEventHandler = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setEditBookmarkOpen(true)
  }

  useEffect(() => {
    if (isDeleteSuccss) {
      dispatch(displaySnackBar({ message: t('bookmarks:bookmark_deleted_notification'), severity: 'success', open: true }))
      handleClose()
    }
    if (isDeleteError) {
      dispatch(displaySnackBar({ message: t('common:general_error_message'), severity: 'error', open: true }))
    }
  }, [isDeleteError, isDeleteSuccss, dispatch, t])

  return (
    <>
      <Badge
        color="primary"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        badgeContent={
          bookmark.isPublic ? (
            <GRTooltip content={t('bookmarks:shared_with_your_organization')}>
              <GroupsIcon fontSize="small" />
            </GRTooltip>
          ) : null
        }
        variant="standard"
      >
        <a href={bookmark.urlPath}>
          <Card
            className="BookmarkCard"
            {...cardProps}
            sx={{ position: 'relative' }}
            onMouseEnter={() => {
              setHidden(false)
            }}
            onMouseLeave={() => {
              setHidden(true)
            }}
            onClick={onClick}
          >
            {!shared && (
              <SpeedDial
                ariaLabel="Bookmark Actions"
                sx={{ position: 'absolute', top: 15, right: 15 }}
                icon={<SpeedDialIcon />}
                onClose={handleClose}
                onOpen={handleOpen}
                onClick={handleSpeedDialClick}
                hidden={hidden}
                direction="down"
                open={speedDialOpen}
              >
                <SpeedDialAction icon={<EditIcon />} tooltipTitle={t('common:edit')} tooltipOpen onClick={handleEdit} />
                <SpeedDialAction icon={<DeleteIcon />} tooltipTitle={t('common:delete')} tooltipOpen onClick={handleDelete} />
              </SpeedDial>
            )}
            <CardMedia component="img" image={bookmark.imageUrl}></CardMedia>
            <CardContent>
              <Typography gutterBottom variant="h3" component="div" className="BookmarkCard--Name">
                <Box alignContent="center">{bookmark.name}</Box>
              </Typography>
              <ShowMore collapsedSize={100} stopPropagation={true}>
                <Typography gutterBottom variant="body1" component="div">
                  <pre>{bookmark.description}</pre>
                </Typography>
              </ShowMore>
            </CardContent>
          </Card>
        </a>
      </Badge>
      <EditBookMarkDialog open={editBookmarkOpen} bookmark={bookmark} onClose={() => setEditBookmarkOpen(false)} />
    </>
  )
}
