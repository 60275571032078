import querystring from 'query-string'

import { isNil } from '../../../helpers/isNil'
import { UrlSearchParamsMapper } from '../../../hooks/useSearchParams'
import languageService from '../../../services/LanguageService'
import { VersionTagFeature } from '../../../types/VersionTag'
import { SubgenreMap } from '../../account'
import { FeatureChoiceMap } from '../../market-explorer/types/MarketExplorerSegmentConfiguration'
import { getVersionTagById } from '../../news'
import { EMPTY_AGGREGATE_VALUE, FeaturesFilterValue } from '../types/ColumnFilter'
import { UpdateImpactRowFilters } from '../types/UpdateImpactRowFilters'
import { UpdateImpactSearchParams } from '../types/UpdateImpactSearchParams'

/**
 * Mappers for game update impact filters to/from url search params
 */

const mapFiltersToUrlSearchParams = (filters: UpdateImpactRowFilters): UpdateImpactSearchParams => {
  const defaultSearch = querystring.parse(window.location.search)
  return {
    ...defaultSearch,
    time: filters.timeSinceUpdated,
    subgenres: Object.entries(filters.subgenres || {})
      .filter(([key, value]) => !!value)
      .map(([key, value]) => key),
    updateTags: filters.versionTags?.map((tag) => tag.id),
    revenue: [filters.revenue?.percentChange, filters.revenue?.valueChange],
    download: [filters.downloads?.percentChange, filters.downloads?.valueChange],
    revDL: [filters.revenueDownloadsRatio?.percentChange, filters.revenueDownloadsRatio?.valueChange],
    featureType: filters.features?.featureType,
    features: Object.entries(filters.features?.selectedFeatures || {}).map(([featureId, choiceIds]) => [featureId, ...choiceIds].join('-')),
    comparisonInterval: filters.comparisonInterval,
  }
}

const mapUrlSearchParamsToFilters = (search: UpdateImpactSearchParams): UpdateImpactRowFilters => {
  return {
    timeSinceUpdated: search.time,
    subgenres: Array.isArray(search.subgenres)
      ? search.subgenres.reduce((acc: SubgenreMap, subgenre: string) => {
          acc[subgenre] = true

          return acc
        }, {})
      : !isNil(search.subgenres)
      ? { [search.subgenres as string]: true }
      : undefined,
    versionTags: search.updateTags
      ? languageService.getVersionTags().filter((tag) => search.updateTags?.includes(tag.id))
      : getVersionTagById(VersionTagFeature[0]),
    revenue: {
      percentChange: search.revenue?.[0] || EMPTY_AGGREGATE_VALUE,
      valueChange: search.revenue?.[1] || EMPTY_AGGREGATE_VALUE,
    },
    downloads: {
      percentChange: search.download?.[0] || EMPTY_AGGREGATE_VALUE,
      valueChange: search.download?.[1] || EMPTY_AGGREGATE_VALUE,
    },
    revenueDownloadsRatio: {
      percentChange: search.revDL?.[0] || EMPTY_AGGREGATE_VALUE,
      valueChange: search.revDL?.[1] || EMPTY_AGGREGATE_VALUE,
    },
    features: {
      featureType: search.featureType || FeaturesFilterValue.Any,
      selectedFeatures: Array.isArray(search.features)
        ? search.features?.reduce((acc, featureChoices) => {
            const parts = featureChoices.split('-')
            const [featureId, ...choiceIds] = parts
            if (featureId) {
              acc[+featureId] = choiceIds.map((value) => +value)
            }
            return acc
          }, {} as FeatureChoiceMap)
        : !isNil(search.features)
        ? parseFeatures(search.features)
        : undefined,
    },
    comparisonInterval: search.comparisonInterval,
  }
}

export const mapper: UrlSearchParamsMapper<UpdateImpactSearchParams, UpdateImpactRowFilters> = {
  mapFromUrl: mapUrlSearchParamsToFilters,
  mapToUrl: mapFiltersToUrlSearchParams,
}

const parseFeatures = (featureChoices: string) => {
  const parts = featureChoices.split('-')
  const [featureId, ...choiceIds] = parts

  return { [+featureId]: choiceIds.map((value) => +value) }
}
