import { CartesianScaleOptions, ChartData, ChartOptions } from 'chart.js'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'

import utilsService from '../../services/UtilsService'

type ScaleConfig = {
  title?: string
  precision?: number
  maxY?: number
}

enum YAxisId {
  Y1 = 'y1',
  Y2 = 'y2',
}

enum XAxisId {
  X = 'x',
}

type BarChartProps = {
  data?: ChartData<'bar'>
  scaleConfig?: {
    [YAxisId.Y1]?: ScaleConfig
    [YAxisId.Y2]?: ScaleConfig
    [XAxisId.X]?: CartesianScaleOptions
  }
}

export const BarChart: FC<BarChartProps> = ({ data = { datasets: [] }, scaleConfig }) => {
  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        ...scaleConfig?.[XAxisId.X],
      },
      y1: {
        position: 'left' as const,
        title: {
          text: scaleConfig?.[YAxisId.Y1]?.title,
          display: !!scaleConfig?.[YAxisId.Y1]?.title,
          padding: { bottom: 16 },
        },
        beginAtZero: true,
        display: !!data?.datasets.find((dataset) => dataset.yAxisID === YAxisId.Y1),
        ticks: {
          precision: scaleConfig?.[YAxisId.Y1]?.precision || 0,
          callback: (value, index, ticks) => {
            return utilsService.formatNumber(value as number, { mantissa: 0 }) + '%'
          },
        },
        suggestedMax: scaleConfig?.[YAxisId.Y1]?.maxY,
      },
      y2: {
        position: 'right' as const,
        title: {
          text: scaleConfig?.[YAxisId.Y2]?.title,
          display: !!scaleConfig?.[YAxisId.Y2]?.title,
          padding: { bottom: 16 },
        },
        beginAtZero: true,
        display: !!data?.datasets.find((dataset) => dataset.yAxisID === YAxisId.Y2),
        ticks: {
          precision: scaleConfig?.[YAxisId.Y2]?.precision || 0,
          callback: (value, index, ticks) => {
            return utilsService.formatNumber(value as number, { mantissa: 0 }) + '%'
          },
        },
        suggestedMax: scaleConfig?.[YAxisId.Y2]?.maxY,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        borderWidth: 2,
      },
    },
    interaction: {
      intersect: false,
      axis: 'x' as const,
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          pointStyle: 'circle' as const,
          usePointStyle: true,
          padding: 16,
        },
      },
      tooltip: {
        position: 'average',
        yAlign: 'bottom',
        titleFont: {
          size: 14,
          weight: 'bolder' as const,
        },
        titleMarginBottom: 14,
        bodySpacing: 6,
        boxPadding: 6,
        usePointStyle: true,
        padding: 10,
        itemSort: (a, b) => {
          return a.element.y - b.element.y
        },
        callbacks: {
          title: (tooltipItems) => {
            return tooltipItems[0].label
          },
          label: (tooltipItem) => {
            return ` ${tooltipItem.dataset.label}: ${(tooltipItem.raw as number).toFixed(1)}%`
          },
        },
      },
    },
  }

  return <Bar data={data} options={options} />
}
