import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InsertPhotoOutlined } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'

import { prepareDefaultHeaders } from '../../../../api'
import { FileUploadResponse, usePostUploadFileMutation } from '../../../../api/core'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import ImageCropDialog from '../../../../components/ImageCropDialog/ImageCropDialog'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { useIsUserLanguageJapanese } from '../../../account/hooks/userHooks'
import { displaySnackBar } from '../../../snackbar'
import { SnackBarMessage } from '../../../snackbar/types/SnackBarMessage'
import './GameIconSelect.scss'

// TODO-LATER-REFACTOR: Move Input selection to separate components in case functionality need to be reused
/**
 * GameIconPreview preview and upload icon image
 */
interface GameIconPreviewProps {
  preview: string
  onIconSelectClick: () => void
  onRemoveImageClick: () => void
}
const GameIconPreview: React.FC<GameIconPreviewProps> = ({ preview, onIconSelectClick, onRemoveImageClick }) => {
  const { t } = useTranslation()
  return (
    <Grid container flexDirection="column" alignItems="center" rowSpacing={1}>
      <Grid item sx={{ width: '100%' }}>
        <img src={preview} className="GameIconSelect__reviewIcon" alt="" />
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={onIconSelectClick}>
          {t('game-edit:change_icon')}
        </Button>
      </Grid>
      <Grid item>
        <Button onClick={onRemoveImageClick}>{t('game-edit:remove_icon')}</Button>
      </Grid>
    </Grid>
  )
}

/**
 * GameIconSelect
 */
interface GameIconSelectProps {
  onFileSelected: (selectedFile: FileUploadResponse) => void
  squareIcon?: boolean
  onFileRemoved: () => void
  selectedIcon?: string | null | FileUploadResponse
}
const GameIconSelect: React.FC<GameIconSelectProps> = ({ selectedIcon = null, onFileSelected, squareIcon = true, onFileRemoved }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const showError = (errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload))

  const [preview, setPreview] = useState<string | null>(null)

  const [uploadFile, { reset: resetUploadFile, isLoading: isFileUploading }] = usePostUploadFileMutation()
  const [selectedImage, setSeletectedImage] = useState<Blob>()

  const [imageCropDialogOpen, setImageCropDialogOpen] = useState(false)
  const [imageSrc, setImageSrc] = useState<string | null>(null)

  const fileInput = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    const selectImage = async () => {
      try {
        if (!selectedIcon) {
          return
        }
        const imgBlob = await authorizeImage(selectedIcon)
        setSeletectedImage(imgBlob)
      } catch (err) {
        setPreview(null)
      }
    }
    selectImage()
  }, [selectedIcon])

  useEffect(() => {
    let objectUrl: string
    if (!!selectedImage) {
      objectUrl = URL.createObjectURL(selectedImage)
      setPreview(objectUrl)
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl)
      }
    }
  }, [selectedImage])

  const onIconSelectClick = () => {
    fileInput.current?.click()
  }

  const onFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    if (!target.files) {
      return
    }

    const selectedFile = target.files[0]
    if (!selectedFile) {
      return
    }

    let imageDataUrl = URL.createObjectURL(selectedFile)
    setImageSrc(imageDataUrl)
    setImageCropDialogOpen(true)
  }

  const onFileUpload = async (file: Blob) => {
    try {
      setPreview(null)
      const formData = new FormData()
      formData.append('file', file)
      formData.append('type', 'gameIcon')
      const { data: uploadFileResp } = (await uploadFile({ body: formData })) as { data: FileUploadResponse }
      const imgBlob = await authorizeImage(uploadFileResp)

      onFileSelected(uploadFileResp)
      setSeletectedImage(imgBlob)
    } catch (err) {
      showError({ message: t('game-edit:error_image_dialog_body_unable_upload'), severity: 'error', open: true })
    }
  }

  const authorizeImage = async (data: FileUploadResponse | string) => {
    try {
      let url = typeof data === 'string' ? data : data.url
      const defaultHeader = await prepareDefaultHeaders()
      const imgBlob = await fetch(url, { headers: defaultHeader }).then((resp) => resp.blob())
      return imgBlob
    } catch (err) {
      setPreview(null)
    }
  }

  const removeImage = () => {
    setPreview(null)
    onFileRemoved()
    resetUploadFile()
  }

  const handleImageCropDialogClose = (croppedImage: any) => {
    if (croppedImage) {
      onFileUpload(croppedImage)
    }

    setImageCropDialogOpen(false)
  }

  const isUserLanguageJapanese = useIsUserLanguageJapanese()

  return (
    <>
      {preview ? (
        <GameIconPreview preview={preview} onIconSelectClick={onIconSelectClick} onRemoveImageClick={removeImage} />
      ) : (
        <>
          <div className="GameIconSelect" onClick={onIconSelectClick}>
            <Grid container alignItems="center" flexDirection="column" justifyContent="center" style={{ height: '100%', paddingTop: '50%' }}>
              <Grid item>{isFileUploading ? <GRCircularProgress /> : <InsertPhotoOutlined sx={{ p: 0, fontSize: 50, m: 0 }} />}</Grid>
              <Grid item alignItems="center" display="flex" flexDirection="column" padding={1}>
                <Typography variant="body1" style={{ fontSize: isUserLanguageJapanese ? '0.7rem' : '1rem' }}>
                  {t('game-edit:upload_icon')}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </>
      )}
      <input
        accept="image/*"
        type="file"
        ref={fileInput}
        onChange={onFileInputChange}
        onClick={(event) => (event.currentTarget.value = '')}
        style={{ display: 'none' }}
      />

      {imageSrc && (
        <ImageCropDialog
          open={imageCropDialogOpen}
          onApply={(croppedImage) => handleImageCropDialogClose(croppedImage)}
          onClose={handleImageCropDialogClose}
          imageSrc={imageSrc}
          previewShape="rounded"
          maxWidth={false}
          resize={{ width: 512, height: 512 }}
          PaperProps={{
            style: {
              maxHeight: 'none', // Allow the dialog to expand vertically
            },
          }}
        />
      )}
    </>
  )
}

export default GameIconSelect
