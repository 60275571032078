import { Page } from './Page'

export class SideNavigationGroupData {
  id: string
  type: string = 'group'
  localizationKey: string
  pages: Array<Page>

  constructor(id: string, localizationKey: string, pages: Array<Page>) {
    this.id = id
    this.localizationKey = localizationKey
    this.pages = pages
  }
}
