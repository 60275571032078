import { t } from 'i18next'
import React from 'react'

import { CircularProgress } from '@mui/material'

import GRPowerScoreLink from '../GRPowerScoreLink/GRPowerScoreLink'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import './Powerscore.scss'

interface Props {
  powerscore: number
  size?: number
  thickness?: number
  fontSize?: number
}

const Powerscore: React.FC<Props> = (props: Props) => {
  const POWERSCORE = Math.round(props.powerscore)
  const SIZE = props.size || 50
  const THICKNESS = props.thickness || 4
  const FONT_SIZE = props.fontSize || 22

  return (
    <GRTooltip content={t('common:tooltip_gps_description') || ''}>
      <GRPowerScoreLink>
        <div className="Powerscore">
          <CircularProgress className="Powerscore__circular-back" variant="determinate" size={SIZE} thickness={THICKNESS} value={100}></CircularProgress>

          <CircularProgress variant="determinate" size={SIZE} thickness={THICKNESS} value={POWERSCORE}></CircularProgress>

          <div className="Powerscore__value" style={{ fontSize: FONT_SIZE + 'px' }}>
            {POWERSCORE}
          </div>
        </div>
      </GRPowerScoreLink>
    </GRTooltip>
  )
}

export default Powerscore
