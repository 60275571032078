import {
  BarElement,
  CategoryScale,
  Chart,
  ChartData,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import Annotation from 'chartjs-plugin-annotation'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import LockOpenIcon from '@mui/icons-material/LockOpen'
import { Box, Button } from '@mui/material'

import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import { TimelineChart } from '../../../../components/TimelineChart/TimelineChart'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { PerformanceChartColor } from '../../../update-history/components/PerformanceChart/PerformanceChart'
import { ChartDataFormat } from '../../../update-history/types/types'
import { UnifiedNewsEntry } from '../../types/UnifiedNewsEntry'

Chart.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Annotation,
  RadialLinearScale,
  BarElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  // ChartDataLabels
)
Chart.defaults.font.family = 'Walsheim-Regular, sans-serif'

interface ChartProps {
  newsEntry: UnifiedNewsEntry
  locked?: boolean
}

const RevenueDownloadsChart: React.FC<ChartProps> = ({ newsEntry, locked = false }) => {
  const { t } = useTranslation()
  const [openLockedGameUpdateImpactsDialog, setOpenLockedGameUpdateImpactsDialog] = useState(false)

  const highlightedVersionMark = useMemo(() => {
    if (!newsEntry.versionInfo) return

    return { position: newsEntry.versionInfo?.versionReleaseDate, data: newsEntry.versionInfo }
  }, [newsEntry.versionInfo])

  return (
    <Box sx={{ height: '100%', position: 'relative' }}>
      {locked && (
        <>
          <Button
            startIcon={<LockOpenIcon />}
            onClick={() => setOpenLockedGameUpdateImpactsDialog(true)}
            variant="contained"
            sx={{ top: '40%', left: '50%', transform: 'translate(-50%, -50%)', position: 'absolute' }}
          >
            <Trans i18nKey="common:unlock" />
          </Button>
          <LockedFeature.Dialog
            open={openLockedGameUpdateImpactsDialog}
            onClose={() => setOpenLockedGameUpdateImpactsDialog(false)}
            lockedFeatureId={LockedFeatureId.GameUpdateImpacts}
          />
        </>
      )}
      <TimelineChart
        data={createChartData([
          {
            label: `${t('common:revenue_text')} (${t('common:apple_ios')})`,
            data: locked ? [] : getDataset(newsEntry.revenueDownloadsData, 'revenue'),
            color: PerformanceChartColor.Primary,
            axis: 'y1',
            tooltipFormat: 'USD',
          },
          {
            label: `${t('common:downloads_text')} (${t('common:apple_ios')})`,
            data: locked ? [] : getDataset(newsEntry.revenueDownloadsData, 'downloads'),
            color: PerformanceChartColor.Secondary,
            axis: 'y2',
          },
        ])}
        scaleConfig={{
          y1: {
            dataFormat: ChartDataFormat.Currency,
            title: `${t('common:revenue_text')} (${t('common:apple_ios')})`,
          },
          y2: {
            dataFormat: ChartDataFormat.Number,
            title: `${t('common:downloads_text')} (${t('common:apple_ios')})`,
            precision: 1,
          },
        }}
        selectedVerticalMarks={!locked && highlightedVersionMark ? [highlightedVersionMark] : []}
        chartLineOptions={{ responsive: true, maintainAspectRatio: false, animation: false }}
      />
    </Box>
  )
}

const RankChart: React.FC<ChartProps> = ({ newsEntry }) => {
  const { t } = useTranslation()

  return (
    <TimelineChart
      data={createChartData([
        {
          label: `${t('common:revenue_text')} (${t('common:top_grossing_rank')})`,
          data: newsEntry.history,
          color: PerformanceChartColor.Primary,
          axis: 'y1',
        },
        {
          label: `${t('common:downloads_text')} (${t('common:free_rank')})`,
          data: newsEntry.dhistory,
          color: PerformanceChartColor.Secondary,
          axis: 'y1',
        },
      ])}
      scaleConfig={{
        y1: {
          dataFormat: ChartDataFormat.Number,
          title: `${t('common:rank')}`,
          reverse: true,
        },
      }}
      chartLineOptions={{ responsive: true, maintainAspectRatio: false, animation: false }}
    />
  )
}

interface Dataset {
  axis?: string
  data: Array<{
    ts: number
    rank: number
  }>
  color: string
  label: string
  tooltipFormat?: 'USD'
}

function createChartData(datasets: Dataset[]): ChartData<'line'> {
  return {
    datasets: datasets
      .filter((dataset) => dataset.data)
      .map((dataset) => ({
        yAxisID: dataset.axis,
        label: dataset.label,
        data: dataset.data.map((history: { ts: number; rank: number }) => ({ x: history.ts, y: history.rank })),
        fill: false,
        spanGaps: 172800000, // 2 days
        tension: 0.1,
        backgroundColor: dataset.color,
        borderColor: dataset.color,
        tooltipFormat: dataset.tooltipFormat,
      })),
  }
}

const getDataset = (revenueDownloadsData: any, series: string): { ts: number; rank: number }[] => {
  return revenueDownloadsData && revenueDownloadsData.history
    ? Object.keys(revenueDownloadsData.history).map((key) => ({ ts: new Date(key).getTime(), rank: revenueDownloadsData.history[key][series] }))
    : []
}

export { RevenueDownloadsChart, RankChart }
