import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export enum SortGamesByOptionValue {
  SustainedGrossingRank = 'SustainedGrossingRank',
  SustainedDownloadRank = 'SustainedDownloadRank',
  GameName = 'GameName',
}

export const useSortGamesBySelectOptions = () => {
  const { t } = useTranslation()

  return useMemo(() => {
    return [
      {
        name: t('common:sustained_grossing_rank'),
        value: SortGamesByOptionValue.SustainedGrossingRank,
      },
      {
        name: t('common:sustained_download_rank'),
        value: SortGamesByOptionValue.SustainedDownloadRank,
      },
      {
        name: t('live-events:game_name'),
        value: SortGamesByOptionValue.GameName,
      },
    ]
  }, [t])
}
