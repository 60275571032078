import { useEffect } from 'react'
import { useNavigate, useParams, Routes, Route } from 'react-router-dom'

import { useRouteModal } from '../../../features/route-modals/hooks/useRouteModal'
import { EModalType } from '../../../features/route-modals/types/modalTypes'

/**
 *  Component for catching deprecated path based Live Event Statistics Dialog. This components
 * exists solely for redirecting to new url search params based modal.
 *
 * @deprecated
 */
export const LiveEventsEventStatisticsDialogContainerDeprecated = () => {
  const navigate = useNavigate()
  const params = useParams<{ gameIds: string; liveEventTypeId: string; tab: string; '*': string }>()
  const { generatePushModalParams } = useRouteModal()

  useEffect(() => {
    const urlSearchParams = generatePushModalParams({
      type: EModalType.LIVE_EVENT_STATISTICS,
      params: {
        trackedGameIds: params.gameIds?.split(',').join('-'),
        liveEventTypeId: params.liveEventTypeId,
        tab: params.tab,
      },
      reversed: true,
    })

    if (params['*']?.length === 0) {
      setTimeout(() => navigate({ pathname: '..', search: '?' + urlSearchParams.toString() }), 250)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params['*']])

  return (
    <Routes>
      <Route path="liveeventstatistics/:gameIds/:liveEventTypeId/:tab/*" element={<LiveEventsEventStatisticsDialogContainerDeprecated />}></Route>
      <Route
        path="liveevent/:trackedGameId/:liveEventTypeId/liveEventId?/:liveEventId?/tab?/:tab?/screenshotIndex?/:screenshotIndex?/*"
        element={<LiveEventsEventDialogContainerDeprecated />}
      />
    </Routes>
  )
}

/**
 * Component for catching deprecated path based Live Event Dialog. This components
 * exists solely for redirecting to new url search params based modal.
 * @deprecated
 */
export const LiveEventsEventDialogContainerDeprecated = () => {
  const navigate = useNavigate()
  const params = useParams<{ trackedGameId: string; liveEventTypeId: string; liveEventId?: string; tab?: string; screenshotIndex?: string; '*': string }>()
  const { generatePushModalParams } = useRouteModal()

  useEffect(() => {
    const urlSearchParams = generatePushModalParams({
      type: EModalType.LIVE_EVENT,
      params: {
        trackedGameId: params.trackedGameId,
        liveEventTypeId: params.liveEventTypeId,
        liveEventId: params.liveEventId,
        tab: params.tab,
        screenshotIndex: params.screenshotIndex,
      },
      reversed: true,
    })

    if (params['*']?.length === 0) {
      setTimeout(() => navigate({ pathname: '..', search: '?' + urlSearchParams.toString() }), 250)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params['*']])

  return (
    <Routes>
      <Route
        path="liveevent/:trackedGameId/:liveEventTypeId/liveEventId?/:liveEventId?/tab?/:tab?/screenshotIndex?/:screenshotIndex?/*"
        element={<LiveEventsEventDialogContainerDeprecated />}
      />
    </Routes>
  )
}
