import type {
  LiveEventAnalystNotesModalParams,
  LiveEventAnalystOverviewModalParams,
  LiveEventModalParams,
  LiveEventStatisticsModalParams,
} from '../const/const'

export enum EModalType {
  LIVE_EVENT = 'liveEvent',
  LIVE_EVENT_ANALYST_NOTES = 'liveEventAnalystNotes',
  LIVE_EVENT_ANALYST_OVERVIEW = 'liveEventAnalystOverview',
  LIVE_EVENT_STATISTICS = 'liveEventStatistics',
}

export type TModalParams = {
  [key in EModalType]: readonly string[]
}

export type TModalParamsByModalType = {
  [EModalType.LIVE_EVENT]: (typeof LiveEventModalParams)[number]
  [EModalType.LIVE_EVENT_ANALYST_NOTES]: (typeof LiveEventAnalystNotesModalParams)[number]
  [EModalType.LIVE_EVENT_ANALYST_OVERVIEW]: (typeof LiveEventAnalystOverviewModalParams)[number]
  [EModalType.LIVE_EVENT_STATISTICS]: (typeof LiveEventStatisticsModalParams)[number]
}
