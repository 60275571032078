import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ArrowBack, Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link as MuiLink,
  IconButton,
  TableContainer,
  Typography,
} from '@mui/material'

import { useGetGameVisualAnalysisQuery, useGetGenreAnalysisQuery } from '../../../api/connect'
import { GRTable, GRTableColumn, SortOrder } from '../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { LinearProgressBar } from '../../../components/LinearProgressBar/LinearProgressBar'
import { TrendIndicator, TrendIndicatorType } from '../../../components/TrendIndicator/TrendIndicator'
import analyticsService from '../../../services/AnalyticsService'
import { GameIcon } from '../../game'
import { useGames } from '../../game/hooks/gameHooks'
import { useCurrentMarket } from '../../markets'
import { RankType, VisualAnalysisWithGenre, VisualAnalysisWithRank, VisualsTableRow } from '../types'
import { formatPercentage, visualsTranslationMap } from '../util/helpers'
import { combineStatistics, AppStoreGenreMap } from '../util/visualStats'
import ColorSquare from './ColorSquare'
import GameIconAttributeModal from './GameIconAttributeModal'
import './GameVisualsModal.scss'
import './GameVisualsTable.scss'

interface GameVisualsTableProps {
  data: VisualAnalysisWithGenre
  genreId?: string
  isNested?: boolean
  rankType?: RankType
  topCount: number
}

/**
 * Note: This structure is recursive; There can be a GameVisualsModal (which contains another
 * GameVisualsTable) inside.
 *
 * @param data the analysis with all attribute rows
 * @param genreId from app store
 * @param isNested is opened by another modal
 * @param rankType from parent to pass on to children
 * @param topCount which top model is used as reference
 */
const GameVisualsTable: React.FC<GameVisualsTableProps> = ({ data, genreId, isNested, rankType, topCount }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const [selectedAttr, setSelectedAttr] = useState<VisualsTableRow>()
  const [selectedItem, setSelectedItem] = useState<VisualAnalysisWithRank>()
  const defaultColumns: GRTableColumn<VisualsTableRow, undefined>[] = useMemo(
    () => [
      {
        labelAccessor: t(visualsTranslationMap[data.model]),
        accessor: ({ row }) => (
          <MuiLink onClick={() => setSelectedAttr(row)}>
            {row.model.indexOf('color') < 0 ? (
              row.name
            ) : (
              <>
                <ColorSquare color={row.name} />
                {row.name}
              </>
            )}
          </MuiLink>
        ),
        cellProps: { align: 'left', sx: { textTransform: 'capitalize' } },
        headerCellProps: { align: 'left', width: '25%' },
      },
      {
        labelAccessor: '',
        accessor: ({ row }) => <LinearProgressBar variant="determinate" value={row.value * 100} labelBefore={formatPercentage(row.value)} />,
        headerCellProps: { align: 'center', width: '25%' },
        sortable: true,
        sortAccessor: 'value',
        sortOrder: SortOrder.DESC,
      },
      {
        labelAccessor: () => (
          <Box display="flex" alignItems="center" justifyContent="center">
            {t('common:top_' + topCount)}&nbsp;
            <GRTooltip content={t('visuals-explorer:top_x_prevalence', { rank: topCount })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </Box>
        ),
        accessor: ({ row }) => formatPercentage(row.top),
        headerCellProps: { align: 'center', width: '15%' },
      },
      {
        labelAccessor: () => (
          <Box display="flex" alignItems="center" justifyContent="center">
            {t('common:outside_top_' + topCount)}&nbsp;
            <GRTooltip content={t('visuals-explorer:outside_top_x_prevalence', { rank: topCount })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </Box>
        ),
        accessor: ({ row }) => formatPercentage(row.outside),
        headerCellProps: { align: 'center', width: '15%' },
      },
      {
        labelAccessor: () => (
          <Box display="flex" alignItems="center" justifyContent="center">
            {t('common:difference')}&nbsp;
            <GRTooltip content={t('visuals-explorer:prevalence_difference', { rank: topCount })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </Box>
        ),
        accessor: ({ row }) =>
          row.outside === null || row.top === null ? (
            '-'
          ) : (
            <TrendIndicator type={TrendIndicatorType.Value} direction={true} value={Math.round(100 * row.top) - Math.round(100 * row.outside)} />
          ),
        headerCellProps: { align: 'center', width: '20%' },
      },
    ],
    [data.model, t, topCount]
  )
  const [columns, setColumns] = useState(defaultColumns)

  useEffect(() => {
    setColumns(defaultColumns)
  }, [defaultColumns])

  const dimSmallValue = (row: VisualsTableRow) => (row.value < 0.1 ? { opacity: 0.5 } : {})

  const rows: VisualsTableRow[] = !data.attributes
    ? []
    : Object.keys(data.attributes).map((name) => ({
        name,
        model: data.model,
        value: data.attributes[name],
        top: data.top ? data.top[name] : null,
        outside: data.outside ? data.outside[name] : null,
      }))

  // Master 'Close All' when multiple modals open
  const keyDownHandler = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') {
      setSelectedItem(undefined)
      setSelectedAttr(undefined)
    }
  }

  useEffect(() => {
    if (isNested) {
      return
    }
    window.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardContent className="VisualsTableContainer">
      <GameIcon src={data.url} gameName={''} size="huge" />
      <TableContainer component={Box} ref={containerRef}>
        <GRTable
          columns={columns}
          rows={rows}
          onColumnsUpdated={setColumns}
          rowIdKey="name"
          defaultSortOrder={SortOrder.DESC}
          scroller={containerRef}
          getRowStyle={dimSmallValue}
        />
      </TableContainer>
      <div style={{ clear: 'left' }} />
      {selectedItem ? (
        <GameVisualsModal isNested={isNested} item={selectedItem} onClose={() => setSelectedItem(undefined)} />
      ) : (
        selectedAttr && (
          <GameIconAttributeModal
            attribute={selectedAttr}
            genreId={genreId}
            isNested={isNested}
            onClose={() => setSelectedAttr(undefined)}
            onSelectGame={setSelectedItem}
            rankType={rankType}
            top={topCount}
          />
        )
      )}
    </CardContent>
  )
}

export { GameVisualsTable }

interface GameVisualsModalProps {
  isNested?: boolean
  item: VisualAnalysisWithRank
  onClose: () => void
}

const GameVisualsModal: React.FC<GameVisualsModalProps> = ({ isNested, item, onClose }) => {
  const { t } = useTranslation()
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const {
    games: { 0: game },
  } = useGames([item.gameId], marketIso)
  const { data: visualsData } = useGetGameVisualAnalysisQuery(item.gameId)
  const { data: genreData } = useGetGenreAnalysisQuery({ genreIds: game?.appGenres || [], marketIso }, { skip: !game || !visualsData })

  useEffect(() => {
    if (!!game) {
      analyticsService.trackEvent('Opened Game Visuals Modal', {
        data: {
          gameName: game.resolvedName,
        },
      })
    }
  }, [game])

  const statsTables = useMemo(() => {
    const { genreId, rankType, top } = item
    return combineStatistics(genreId, top, rankType, visualsData, genreData)
  }, [item, genreData, visualsData])

  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth className="GameVisualsModal" disableEscapeKeyDown={true}>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            {isNested && (
              <IconButton aria-label="Back" onClick={onClose} sx={{ my: -1, mr: 1 }}>
                <ArrowBack />
              </IconButton>
            )}
            {game && game.resolvedName}
          </Grid>
          <Grid item textAlign="right">
            <IconButton aria-label="close" onClick={onClose} sx={{ m: -1 }}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      {!game ? (
        <Box mb={2} mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <DialogContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={6}>
              <Link to={`/game/${game.id}/visuals`}>
                <GameIcon gameName={game.resolvedName} src={game.icons[0]} size="normal" />
                <Box ml={2} display="inline-block">
                  <Typography variant="h3">{game.resolvedName}</Typography>
                  <Typography variant="subtitle2">{game.artist}</Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button variant="contained" disabled color="secondary">
                {AppStoreGenreMap[item.genreId] + ' / '}
                {item.rankType === 'free' ? t('common:sustained_download_rank') + ': ' + item.sdrank : t('common:sustained_grossing_rank') + ': ' + item.srank}
              </Button>
            </Grid>
          </Grid>
          <Card sx={{ mb: 1, mt: 2 }}>
            {statsTables?.map((data, index) => (
              <GameVisualsTable
                data={data}
                key={`stats-${item.gameId}-${index}`}
                rankType={item.rankType}
                genreId={item.genreId}
                topCount={item.top}
                isNested={true}
              />
            ))}
          </Card>
        </DialogContent>
      )}
    </Dialog>
  )
}

export { GameVisualsModal }
