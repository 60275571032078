import { useMemo } from 'react'

import { Tag, useGetTagGroupsListQuery, useGetTagListQuery } from '../../../api/core'
import languageService from '../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'

export const useFeatureTags = () => {
  const userLanguage = useCurrentUserLanguage()
  const tagListResult = useGetTagListQuery({ type: 'feature', userLanguage })
  const tagGroupsListResult = useGetTagGroupsListQuery({ type: 'feature', userLanguage })

  return useMemo(() => {
    const result = {
      isSuccess: tagListResult.isSuccess && tagGroupsListResult.isSuccess,
      isError: tagListResult.isError || tagGroupsListResult.isError,
      isFetching: tagListResult.isFetching || tagGroupsListResult.isFetching,
      isLoading: tagListResult.isLoading || tagGroupsListResult.isLoading,
    }
    if (tagListResult.data && tagGroupsListResult.data) {
      const data = [...tagGroupsListResult.data]
        .sort((a, b) => a.order - b.order)
        .map((group) => ({
          ...group,
          name: languageService.getTranslation('tagGroups', group.id),
          tags: group.tags
            .map((id) => tagListResult.data?.find((t) => t.id === id))
            ?.filter((t): t is Tag => t !== undefined && t.active)
            .map((tag) => ({
              ...tag,
              name: languageService.getTranslation('tags', tag.id),
            })),
        }))
      return {
        data,
        ...result,
      }
    } else {
      return {
        data: undefined,
        ...result,
      }
    }
  }, [
    tagGroupsListResult.data,
    tagGroupsListResult.isError,
    tagGroupsListResult.isFetching,
    tagGroupsListResult.isLoading,
    tagGroupsListResult.isSuccess,
    tagListResult.data,
    tagListResult.isError,
    tagListResult.isFetching,
    tagListResult.isLoading,
    tagListResult.isSuccess,
  ])
}
