import { KeyboardEvent, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import BugReportIcon from '@mui/icons-material/BugReport'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import SendIcon from '@mui/icons-material/Send'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'

import { useGetAIAnalystAnswerQuery } from '../../api/ai-analyst'
import GRCircularProgress from '../../components/GRCircularProgress/GRCircularProgress'
import { LockedFeature } from '../../components/LockedFeature/LockedFeature'
import { RoleEnum } from '../../features/account'
import { useAIAnalystAccessCheck, useRoleCheck } from '../../features/account/hooks/roleHooks'
import AIExampleCard from '../../features/ai-analyst/components/AIExampleCard'
import ChatBubble from '../../features/ai-analyst/components/ChatBubble'
import { AIAnalystAnswer } from '../../features/ai-analyst/types/AIAnalystAnswer'
import {
  AIChatHistortyItemType,
  AIChatHistoryItem,
  LoadingAIChatHistoryItem,
  createBotMessageFromAiAnalystAnswers,
  createErrorMessage,
  createLoadingMessage,
  createUserMessage,
} from '../../features/ai-analyst/types/AIChatHistoryItem'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import { ITrackEventOptions } from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import { AnalyticsProviders } from '../../types/IAnalyticsProvider'
import { LockedFeatureId } from '../../types/LockedFeature'
import './AIAnalystPage.scss'
import { AIAanalystPageExamples, AIAnalystPageCapabilites, AIAnalystPageLimitations } from './AIAnalystPageInfo'

const AIAnalystPage: React.FC = () => {
  const { t } = useTranslation()
  usePage(PageService.getPageWithId('ai-analyst'), 'Visited Ai Analyst', { serviceToExclude: [AnalyticsProviders.intercom] } as ITrackEventOptions)
  useDocumentTitle(t('sidebar:ai_analyst'))

  const hasAIAnalystAccess = useAIAnalystAccessCheck()
  const internalUser = useRoleCheck(RoleEnum.internal)

  const inputValue = useRef<HTMLInputElement>(null)
  let currentInputValue = inputValue?.current?.value || ''
  const [skip, setSkip] = useState(true)
  const [scrollToBottomRequested, setScrollToBottomRequested] = useState<boolean>(false)
  const [currentPrompt, setCurrentPrompt] = useState<string>('')
  const [showAdditionalData, setShowAdditionalData] = useState<boolean>(false)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [promptHistory, setPromptHistory] = useState<AIChatHistoryItem[]>([])
  const { data, isLoading, isFetching, isError, error } = useGetAIAnalystAnswerQuery({ prompt: currentPrompt }, { skip: skip || currentPrompt === '' })
  const bottomRef = useRef<HTMLElement>(null)

  const scrollToBottom = () => {
    if (bottomRef && bottomRef.current) {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight
      let { y } = bottomRef.current.getBoundingClientRect()

      y += 90 // 90px is the height of the footer

      if (y > windowHeight) {
        bottomRef.current?.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }

  const handleButtonSubmit = () => {
    currentInputValue = inputValue?.current?.value || ''

    if (isLoading || isFetching) {
      setShowSnackbar(true)
      return
    }

    if ((!isLoading || !isFetching) && currentInputValue !== '') {
      setPromptHistory((prevPromptHistory) => [...prevPromptHistory, createUserMessage(currentInputValue), createLoadingMessage()])
      setCurrentPrompt(currentInputValue)
      setSkip(false)
      setScrollToBottomRequested(true)
    }
  }

  const handleExampleClick = (example: string) => {
    setPromptHistory((prevPromptHistory) => [...prevPromptHistory, createUserMessage(example), createLoadingMessage()])
    setCurrentPrompt(example)
    setSkip(false)
  }

  const handleInputKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleButtonSubmit()
    }
  }

  useEffect(() => {
    // do nothing while the data is undefined or null
    if (data === undefined || data === null || isError) return

    // do nothing while we are loading or fetching
    if (isLoading || isFetching) return

    // find last AIChatBotHistoryItem from promptHistory
    const lastAIChatBotHistoryItemTimestamp = promptHistory.find((item) => item.type === AIChatHistortyItemType.Bot)?.timestamp || 0

    // find timestamp from first AIAnalystAnswer
    const dataTimestamp = (data[0] as AIAnalystAnswer)?.timestamp || 0

    // find last AIChatLoadingHistoryItem from promptHistory
    const lastAIChatLoadingHistoryItem = promptHistory.find((item) => item.type === AIChatHistortyItemType.Loading)

    // check that the data is new
    if (lastAIChatBotHistoryItemTimestamp !== dataTimestamp && !skip) {
      setSkip(true)

      // remove the last AIChatLoadingHistoryItem from promptHistory
      if (lastAIChatLoadingHistoryItem) {
        // createBotMessageFromAiAnalystAnswer
        setPromptHistory((prevPromptHistory) => [
          ...prevPromptHistory.filter((item) => item !== lastAIChatLoadingHistoryItem),
          createBotMessageFromAiAnalystAnswers(data),
        ])
      }

      setCurrentPrompt('')
      setScrollToBottomRequested(true)
    }
  }, [data, isLoading, currentInputValue, isFetching, promptHistory, skip, isError])

  useEffect(() => {
    if (!skip && (isLoading || isFetching)) {
      inputValue.current!.value = ''
    }
  }, [isFetching, isLoading, skip])

  useEffect(() => {
    if (scrollToBottomRequested) {
      scrollToBottom()
      setScrollToBottomRequested(false)
    }
  }, [scrollToBottomRequested])

  useEffect(() => {
    if (isError) {
      // find last AIChatLoadingHistoryItem from promptHistory
      const lastAIChatLoadingHistoryItem = promptHistory.find((item) => item.type === AIChatHistortyItemType.Loading) as LoadingAIChatHistoryItem

      setSkip(true)

      // remove the last AIChatLoadingHistoryItem from promptHistory
      if (lastAIChatLoadingHistoryItem && error !== undefined) {
        setPromptHistory((prevPromptHistory) => [
          ...prevPromptHistory.filter((item) => item !== lastAIChatLoadingHistoryItem),
          createErrorMessage(error as AIAnalystAnswer),
        ])
      }
    }
  }, [data, isError, error, promptHistory])

  return (
    <Box className="AIAnalystPage">
      {!hasAIAnalystAccess && <LockedFeature.Card lockedFeatureId={LockedFeatureId.AIAnalyst} />}

      {hasAIAnalystAccess && (
        <Box className="AIAnalystPage__root">
          {promptHistory.length === 0 && (
            // Intro messages when the page is first loaded
            <Box mb={2} className="AIAnalystPage__filler-container">
              <Grid container direction="row" columnGap={2} mt={2}>
                <Grid item xs={8}>
                  <Box>
                    <Box mb={2} display="flex" alignItems="center" justifyContent="center">
                      <Avatar sx={{ bgcolor: '#bd65cf' }}>
                        <TipsAndUpdatesIcon />
                      </Avatar>
                      <Typography variant="h6" ml={1}>
                        Examples & tips
                      </Typography>
                    </Box>

                    <Grid container direction="row" columnSpacing={2}>
                      <Grid item xs={6}>
                        <Typography textAlign="center">Games And Market</Typography>

                        <Card variant="outlined" sx={{ marginBottom: '10px' }}>
                          <List>
                            {AIAanalystPageExamples.gamesAndMarket.map((example, index) => (
                              <div key={index}>
                                <AIExampleCard text={example} onSend={() => handleExampleClick(example)} />
                                {index !== AIAanalystPageExamples.gamesAndMarket.length - 1 && <Divider />}
                              </div>
                            ))}
                          </List>
                        </Card>

                        <Typography textAlign="center" mt={2}>
                          Innovation
                        </Typography>
                        <Card variant="outlined" sx={{ marginBottom: '10px' }}>
                          <List>
                            {AIAanalystPageExamples.innovation.map((example, index) => (
                              <div key={index}>
                                <AIExampleCard text={example} onSend={() => handleExampleClick(example)} />
                                {index !== AIAanalystPageExamples.innovation.length - 1 && <Divider />}
                              </div>
                            ))}
                          </List>
                        </Card>
                        <Typography textAlign="center" mt={2}>
                          Live events & Updates
                        </Typography>
                        <Card variant="outlined" sx={{ marginBottom: '10px' }}>
                          <List>
                            {AIAanalystPageExamples.liveEventsAndUpdated.map((example, index) => (
                              <div key={index}>
                                <AIExampleCard text={example} onSend={() => handleExampleClick(example)} />
                                {index !== AIAanalystPageExamples.liveEventsAndUpdated.length - 1 && <Divider />}
                              </div>
                            ))}
                          </List>
                        </Card>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography textAlign="center">Tips & guidance</Typography>
                        <Card variant="outlined" sx={{ marginBottom: '10px' }}>
                          <List>
                            {AIAanalystPageExamples.tipsAndGuidance.map((example, index) => (
                              <div key={index}>
                                <AIExampleCard text={example} onSend={() => handleExampleClick(example)} />
                                {index !== AIAanalystPageExamples.tipsAndGuidance.length - 1 && <Divider />}
                              </div>
                            ))}
                          </List>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box>
                    <Box mb={5} display="flex" alignItems="center" justifyContent="center">
                      <Avatar sx={{ bgcolor: '#bd65cf' }}>
                        <WarningIcon />
                      </Avatar>
                      <Typography variant="h6" ml={1}>
                        Limitations
                      </Typography>
                    </Box>

                    <Card variant="outlined" sx={{ marginBottom: '10px' }}>
                      <List>
                        {AIAnalystPageLimitations.map((example, index) => (
                          <div key={index}>
                            <ListItem className="AIExampleCard AIExampleCard_no-hover">
                              <ListItemText className="text">{example}</ListItemText>
                            </ListItem>
                            {index !== AIAnalystPageLimitations.length - 1 && <Divider />}
                          </div>
                        ))}
                      </List>
                    </Card>

                    <Box mt={4}>
                      <Box mb={4} display="flex" alignItems="center" justifyContent="center">
                        <Avatar sx={{ bgcolor: '#bd65cf' }}>
                          <FlashOnIcon />
                        </Avatar>
                        <Typography variant="h6" ml={1}>
                          Capabilities
                        </Typography>
                      </Box>

                      <Card variant="outlined" sx={{ marginBottom: '10px' }}>
                        <List>
                          {AIAnalystPageCapabilites.map((example, index) => (
                            <div key={index}>
                              <ListItem className="AIExampleCard AIExampleCard_no-hover">
                                <ListItemText className="text">{example}</ListItemText>
                              </ListItem>
                              {index !== AIAnalystPageCapabilites.length - 1 && <Divider />}
                            </div>
                          ))}
                        </List>
                      </Card>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          {promptHistory.length > 0 && (
            <Box mb={2} className="AIAnalystPage__message-container">
              <Grid container spacing={2} direction="column">
                {promptHistory.map((chatItem, index) => (
                  <Grid item xs={12} key={index}>
                    <ChatBubble item={chatItem} showAdditionalData={showAdditionalData} bottomRef={bottomRef} />
                  </Grid>
                ))}
              </Grid>

              <Box id="scrollerHelper" ref={bottomRef} minHeight="90px"></Box>
            </Box>
          )}

          <Snackbar
            open={showSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            autoHideDuration={2000}
            onClose={() => {
              setShowSnackbar(false)
            }}
          >
            <Box mb="100px">
              <Card>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ bgcolor: '#bd65cf', marginRight: '20px' }}>
                      <SmartToyIcon />
                    </Avatar>
                    <Typography>Please wait a moment. I'm still processing your previous message.</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Snackbar>

          <div className="AIAnalystPage__footer">
            <div className="AIAnalystPage__input-container">
              <Card className="AIAnalystPage__input">
                <CardActions>
                  <TextField
                    fullWidth
                    placeholder="Send a message"
                    inputProps={{
                      ref: inputValue,
                    }}
                    onKeyPress={handleInputKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" onClick={handleButtonSubmit}>
                          {internalUser && (
                            <Tooltip title="Show debug data (Internal)">
                              <IconButton
                                color={showAdditionalData ? 'primary' : 'default'}
                                onClick={() => {
                                  setShowAdditionalData(!showAdditionalData)
                                }}
                              >
                                <BugReportIcon />
                              </IconButton>
                            </Tooltip>
                          )}

                          {!isLoading && !isFetching && (
                            <IconButton color="primary">
                              <SendIcon />
                            </IconButton>
                          )}

                          {(isLoading || isFetching) && <GRCircularProgress className="AIAnalystPage__progress" />}
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                </CardActions>
              </Card>
            </div>
          </div>
        </Box>
      )}
    </Box>
  )
}

export default AIAnalystPage
