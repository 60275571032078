// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '.'
import { Quarter } from '../features/quarter/types/Quarter'
import { TopGame } from '../features/top-game/types/TopGame'

const REDUCER_PATH = 'topGrossingServerApi'

export const topGrossingServerApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_TOP_GROSSING,
    prepareHeaders: prepareDefaultHeaders,
  }),
  endpoints: (builder) => ({
    getTopGrossingGames: builder.query<TopGame[], GetTopGrossingGamesQueryParams>({
      query: ({ marketIso, ...queryParams }) => ({
        url: `/top-grossing/${marketIso}`,
        params: queryParams,
      }),
      transformResponse: (topGames: TopGame[]) => {
        return topGames.map((game) => new TopGame(game))
      },
    }),
    getTopGrossingGame: builder.query<TopGame | null, GetTopGrossingGameQueryParams>({
      query: ({ appId, marketIso }) => ({
        url: `/top-grossing/${marketIso}/${appId}`,
      }),
      transformResponse: (topGame: TopGame) => {
        if (!topGame) return null
        return new TopGame(topGame)
      },
    }),
    getTopGamesWithAppIds: builder.query<TopGame[], GetTopGamesWithAppIdsQueryParams>({
      query: (params) => ({
        url: '/games/list?enableCache=true',
        method: 'POST',
        body: params,
      }),
      transformResponse: (topGames: TopGame[]) => {
        return topGames.map((game) => new TopGame(game))
      },
    }),
    getTopGamesMultiMarket: builder.query<TopGame[], { games: GetTopGamesMultiMarketQueryGame[]; include?: string[] }>({
      query: ({ games, include }) => ({
        url: `/top-grossing/multi-market`,
        method: 'POST',
        body: {
          games,
          include: include?.join(','),
        },
      }),
      transformResponse: (gameData: MultiMarketGames<TopGame>) => {
        const topGames: TopGame[] = []
        const games = gameData.games

        // Loop through gameData response
        for (const appId in games) {
          const countries = Object.keys(games[appId])

          // Loop through countries for each game
          for (const country of countries) {
            const value = games[appId][country]

            if (value) {
              // Create TopGame object
              const topGame: TopGame = new TopGame(value)

              // Add TopGame object to topGames array
              topGames.push(topGame)
            }
          }
        }
        return topGames
      },
    }),
    getQuarters: builder.query<Quarter[], { marketIso: string }>({
      query: ({ marketIso }) => ({
        url: `/quarters/list`,
        params: { marketIso },
      }),
      transformResponse: (quarters: Quarter[]) => {
        const result = quarters.map((quarter) => new Quarter(quarter))
        return result.sort((a, b) => a.startTimestamp - b.startTimestamp)
      },
    }),
    getRanksByGenre: builder.query<RanksByGenreResult, RanksByGenreParams>({
      query: ({ genreId, marketIso }) => `/games/${marketIso}/ranks/appgenre/${genreId}?enableCache=true`,
    }),
  }),
})

type RanksByGenreParams = {
  genreId: string
  marketIso: string
}

export type RanksByGenreResult = {
  sdranks: { [key: number]: number }
  sranks: { [key: number]: number }
}

type GetTopGrossingGamesQueryParams = {
  marketIso: string
  include: string
  limit: number
  enableCache: boolean
}

type GetTopGrossingGameQueryParams = {
  appId: number
  marketIso: string
}

type GetTopGamesWithAppIdsQueryParams = {
  marketIso: string
  include?: string
  appIds: number[]
}

type GetTopGamesMultiMarketQueryGame = {
  appId: number
  markets: string[]
}

type MultiMarketGames<T> = {
  games: {
    [appId: string]: {
      [country: string]: T | null
    }
  }
}

// Export hooks to use in function components
export const {
  useGetTopGrossingGamesQuery,
  useGetTopGrossingGameQuery,
  useGetTopGamesWithAppIdsQuery,
  useGetQuartersQuery,
  useGetRanksByGenreQuery,
  useGetTopGamesMultiMarketQuery,
} = topGrossingServerApi
