import React, { useMemo } from 'react'

import featureService from '../../../../features/feature/services/FeatureService'
import './FeatureChoiceSimple.scss'

interface Props {
  choiceLocalized: string
  originalChoice: string
}

const FeatureChoiceSimple: React.FC<Props> = (props: Props) => {
  const choiceType = useMemo(() => {
    return featureService.getChoiceTypeForFeatureOriginalChoice(props.originalChoice)
  }, [props.originalChoice])

  return <span className="FeatureChoiceSimple">{choiceType === 'text' ? `: ${props.choiceLocalized}` : null}</span>
}

export default FeatureChoiceSimple
