import { FC, forwardRef, ReactNode } from 'react'
import { Link, LinkProps, useLocation } from 'react-router-dom'

type LinkWithQueryProps = LinkProps & {
  children?: ReactNode
}

export const LinkWithQuery: FC<LinkWithQueryProps> = forwardRef<HTMLAnchorElement | null, LinkWithQueryProps>(({ children, to, ...otherLinkProps }, ref) => {
  const { search } = useLocation()

  return (
    <Link to={`${to}${search}`} ref={ref} {...otherLinkProps}>
      {children}
    </Link>
  )
})
