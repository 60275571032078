import { FC, ReactNode } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { DialogProps, DialogTitle, DialogTitleProps, IconButton } from '@mui/material'

import ShareUrlButton from '../ShareUrlButton/ShareUrlButton'

/**
 * GRDialogTitle Dialog Title wrapper including close button
 */
interface GRDialogTitleProps extends DialogTitleProps {
  children?: ReactNode
  onClose?: DialogProps['onClose']
  share?: boolean
}
const GRDialogTitle: FC<GRDialogTitleProps> = ({ children, onClose, share, ...dialogTitleProps }) => {
  return (
    <DialogTitle sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} {...dialogTitleProps}>
      {children}
      {onClose ? <GRDialogCloseButton onClick={(e) => onClose(e, 'escapeKeyDown')} share={share} /> : null}
    </DialogTitle>
  )
}

/**
 * Close button for dialogs with optional Share button
 */
type GRDialogCloseButtonProps = {
  onClick?: DialogProps['onClose']
  share?: boolean
}

export const GRDialogCloseButton: FC<GRDialogCloseButtonProps> = ({ onClick, share }) => {
  return (
    <>
      <IconButton aria-label="close" onClick={(e) => onClick?.(e, 'escapeKeyDown')}>
        <CloseIcon />
      </IconButton>
      {share && <ShareUrlButton />}
    </>
  )
}

export default GRDialogTitle
