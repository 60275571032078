import Expressionist from '../../../assets/images/personas-expressionist-02.png'
import KingOfTheHill from '../../../assets/images/personas-king-of-the-hill.png'
import Networker from '../../../assets/images/personas-networker.png'
import SkillMaster from '../../../assets/images/personas-skill-master.png'
import Strategist from '../../../assets/images/personas-strategist.png'
import Thinker from '../../../assets/images/personas-thinker.png'
import ThrillSeeker from '../../../assets/images/personas-thrill-seeker.png'
import TreasureHunter from '../../../assets/images/personas-treasure-hunter.png'
import { MotivationKey } from '../../market-explorer/types/MotivationType'

export const motivationArchetypeImages: { [key: string | MotivationKey]: string } = {
  [MotivationKey.Expressionist]: Expressionist,
  [MotivationKey.Thinker]: Thinker,
  [MotivationKey.TreasureHunter]: TreasureHunter,
  [MotivationKey.Strategist]: Strategist,
  [MotivationKey.SkillMaster]: SkillMaster,
  [MotivationKey.ThrillSeeker]: ThrillSeeker,
  [MotivationKey.KingOfTheHill]: KingOfTheHill,
  [MotivationKey.Networker]: Networker,
}
