import { startOfDay, Day, getDay, nextDay, add } from 'date-fns'
import { FC, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Add } from '@mui/icons-material'
import { Grid, TextField, FormControl } from '@mui/material'

import { SelectMenuProps, SelectMenu } from '../../../../../../components/SelectMenu/SelectMenu'
import { ActionButton } from '../../../../../components/ActionButton/ActionButton'
import { DatePicker } from '../../../../../components/DatePicker/DatePicker'

type EventDurationBulkAdderProps = {
  onAddDurations: (duration: { start: Date; end: Date }[]) => void
}

enum Weekday {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

type BulkAddDurationsForm = {
  start: Date
  weekdayStart: Weekday | ''
  weekdayEnd: Weekday | ''
  weeks: number
}

export const EventDurationBulkAdder: FC<EventDurationBulkAdderProps> = ({ onAddDurations }) => {
  const { t } = useTranslation()
  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm<BulkAddDurationsForm>({
    defaultValues: { start: new Date(), weekdayStart: '', weekdayEnd: '', weeks: 3 },
  })

  const handleAddDurations = (data: BulkAddDurationsForm) => {
    const weekdayToDateFnsDay: { [key in Weekday]: Day } = {
      [Weekday.Sunday]: 0,
      [Weekday.Monday]: 1,
      [Weekday.Tuesday]: 2,
      [Weekday.Wednesday]: 3,
      [Weekday.Thursday]: 4,
      [Weekday.Friday]: 5,
      [Weekday.Saturday]: 6,
    }

    const weekdayStart = weekdayToDateFnsDay[data.weekdayStart as Weekday]
    const weekdayEnd = weekdayToDateFnsDay[data.weekdayEnd as Weekday]

    const durations = []
    let currentDay = startOfDay(data.start)

    // find the next start weekday
    currentDay = getDay(currentDay) === weekdayStart ? currentDay : nextDay(currentDay, weekdayStart)

    // Generate durations for the specified number of weeks
    for (let i = 0; i < data.weeks; i++) {
      const start = currentDay
      const end = getDay(start) === weekdayEnd ? start : nextDay(start, weekdayEnd)
      durations.push({ start: start, end: end })
      currentDay = add(currentDay, { days: 7 })
    }

    onAddDurations(durations)
  }

  return (
    <Grid container spacing={2} alignItems="center" wrap="wrap">
      <Grid item xs={6} md={3}>
        <Controller
          name="start"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <DatePicker
              {...field}
              slotProps={{
                textField: {
                  label: t('internal-live-events:start_date_input_label'),
                  fullWidth: true,
                  size: 'small',
                  inputProps: { sx: { boxSizing: 'content-box !important' } },
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Controller
          name="weekdayStart"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <WeekdaySelectMenu value={field.value} onChange={field.onChange} label={t('internal-live-events:weekday_start_input_label')} />
          )}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Controller
          name="weekdayEnd"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <WeekdaySelectMenu value={field.value} onChange={field.onChange} label={t('internal-live-events:weekday_end_input_label')} />}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <Controller
          name="weeks"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField {...field} size="small" variant="outlined" label={t('internal-live-events:how_many_weeks_forward_input_label')} fullWidth />
          )}
        />
      </Grid>
      <Grid item sx={{ ml: 'auto' }}>
        <ActionButton onClick={() => handleAddDurations(getValues())} disabled={!isValid}>
          <Add fontSize="small" />
        </ActionButton>
      </Grid>
    </Grid>
  )
}

export const WeekdaySelectMenu: FC<{ value: Weekday | ''; onChange: (value: Weekday | '') => void } & Omit<SelectMenuProps<Weekday | ''>, 'options'>> = ({
  value,
  onChange,
  ...selectMenuProps
}) => {
  const options = useMemo(() => Object.values(Weekday).map((weekday) => ({ name: weekday.toString(), value: weekday })), [])

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <SelectMenu {...selectMenuProps} value={value} onChange={(e) => onChange(e.target.value)} options={options} />
    </FormControl>
  )
}
