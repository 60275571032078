import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, SelectChangeEvent } from '@mui/material'

import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu'
import { DataTypeSelectorOptionValue, PerformanceChartDataType } from '../../types/types'

type DataTypeSelectorProps = {
  onChange: (event: SelectChangeEvent<DataTypeSelectorOptionValue>) => void
  value: DataTypeSelectorOptionValue
}

/**
 * Data type selector used with prefromance chart
 */
export const DataTypeSelector: FC<DataTypeSelectorProps> = ({ onChange, value }) => {
  const { t } = useTranslation()
  const options = useMemo(() => {
    return [
      {
        name: `${t('common:revenue_and_downloads_estimates')} (${t('common:apple_ios')})`,
        value: PerformanceChartDataType.Estimates,
      },
      {
        name: `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
        value: PerformanceChartDataType.RevenueDownloadsRatioAllTime,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 7 })} (${t('common:apple_ios')})`,
        value: PerformanceChartDataType.RevenueDownloadsRatio7Days,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 30 })} (${t('common:apple_ios')})`,
        value: PerformanceChartDataType.RevenueDownloadsRatio30Days,
      },
      {
        name: `${t('common:revenue_downloads_ratio_days', { days: 90 })} (${t('common:apple_ios')})`,
        value: PerformanceChartDataType.RevenueDownloadsRatio90Days,
      },
      {
        name: `${t('common:grossing_and_free_ranks')}`,
        value: PerformanceChartDataType.Ranks,
      },
    ]
  }, [t])

  return (
    <FormControl size="small" variant="outlined" sx={{ minWidth: 310 }}>
      <SelectMenu label={t('common:data')} options={options} onChange={onChange} value={value} />
    </FormControl>
  )
}
