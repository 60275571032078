import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Close } from '@mui/icons-material'
import { Box, Card, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material'

import { VisualAnalysis } from '../../../api/connect'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import analyticsService from '../../../services/AnalyticsService'
import { GameIcon } from '../../game'
import { useCurrentMarket } from '../../markets'
import { useVisualAnalysisName } from '../../visuals/hooks/useVisualAnalysisName'
import { RankType } from '../../visuals/types'
import { getAnalysisRankComparator } from '../../visuals/util/comparators'
import { StatsWord } from '../types'
import './GameNameStatsModal.scss'
import { translations } from './Translations'

interface GameNameStatsModalProps {
  games?: VisualAnalysis[]
  onClose: () => void
  rankType: RankType
  ranks: { [appId: number]: number }
  word: StatsWord
  onGameSelected: (game: VisualAnalysis) => void
  selectedWord: StatsWord
}

interface GameIconWithNameProps {
  game: VisualAnalysis
  rankText: string
  marketIso: string
  onGameSelected: (game: VisualAnalysis) => void
}

const GameIconWithName: React.FC<GameIconWithNameProps> = ({ game, marketIso, rankText, onGameSelected }) => {
  const gameName = useVisualAnalysisName({ visualAnalysis: game })

  return (
    <GRTooltip
      content={
        <>
          <Typography variant="h3">
            {gameName}
            <br />
          </Typography>
          <Typography variant="subtitle2" component="small">
            {rankText}
          </Typography>
        </>
      }
    >
      <Card>
        <Box sx={{ cursor: 'pointer' }} onClick={() => onGameSelected(game)}>
          <GameIcon gameName={gameName} src={game.url} size="huge" />
        </Box>
        <Typography>{gameName}</Typography>
      </Card>
    </GRTooltip>
  )
}

const GameNameStatsModal: React.FC<GameNameStatsModalProps> = ({ games, onClose, ranks, rankType, word, onGameSelected, selectedWord }) => {
  useEffect(() => {
    analyticsService.trackEvent('Opened Game Title List Modal', {
      data: {
        type: rankType,
        word,
      },
    })
  }, [rankType, word])

  const { t } = useTranslation()
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const { appIds, label, medianRank, averageRank, frequency } = word.row

  const uniqueGames = games ? games.reduce((acc, game) => ({ ...acc, [game.appId]: game }), {} as { [appId: number]: VisualAnalysis }) : {}
  const comparator = useMemo(() => getAnalysisRankComparator(ranks), [ranks])

  const renderedGames = Object.values(uniqueGames)
    .filter(({ appId }) => appIds.includes(appId))
    .sort(comparator)

  const wordRenderedGames = renderedGames.filter((game) => {
    const rowWord = new RegExp(`\\b${word.row.label.toLowerCase()}\\b`)
    return game.gameNames[marketIso] && game.gameNames[marketIso].toLowerCase().search(rowWord) !== -1
      ? game.gameNames[marketIso].toLowerCase() || game.gameNames[Object.keys(game.gameNames)[0]].toLowerCase()
      : null
  })
  const rankText = rankType === 'free' ? t('common:sustained_download_rank') + ': ' : t('common:sustained_grossing_rank') + ': '

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xl" fullWidth className="GameNameStatsModal">
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography>{t(translations[word.statsType].title, { count: +label, word: label, length: `${label} - ${+label + 4}` })}</Typography>
          </Grid>
          <Grid item textAlign="right">
            <IconButton aria-label="close" onClick={onClose} sx={{ m: -1 }}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container justifyContent="center" className="wordStats">
          <Grid item>
            <Typography display="inline" component="label">
              {t('visuals-explorer:count') + ': '}
            </Typography>
            <Typography display="inline">{frequency}</Typography>
          </Grid>
          <Grid item mx={2} px={2} className="verticalDividers">
            <Typography display="inline" component="label">
              {t('common:average_rank') + ': '}
            </Typography>
            <Typography display="inline">{averageRank}</Typography>
          </Grid>
          <Grid item>
            <Typography display="inline" component="label">
              {t('common:median_rank') + ': '}
            </Typography>
            <Typography display="inline">{medianRank}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogContent className="iconList">
        {selectedWord.statsType === 'wordFrequency'
          ? wordRenderedGames.map((game) => (
              <GameIconWithName onGameSelected={onGameSelected} key={game.appId} game={game} marketIso={marketIso} rankText={rankText + ranks[game.appId]} />
            ))
          : renderedGames.map((game) => (
              <GameIconWithName onGameSelected={onGameSelected} key={game.appId} game={game} marketIso={marketIso} rankText={rankText + ranks[game.appId]} />
            ))}
      </DialogContent>
    </Dialog>
  )
}

export default GameNameStatsModal
