import React, { useMemo } from 'react'

import { Game } from '../..'
import { DemographicsTableBars } from '../../../demographics/components/DemographicsTableBars/DemographicsTableBars'
import { DemographicsColor } from '../../../demographics/types/DemographicsColor'
import { DemographicsDataKey } from '../../../demographics/types/DemographicsDataKey'

interface Props {
  colors: DemographicsColor[]
  dataKeys: DemographicsDataKey[]
  game: Game
  marketIso: string
}

/* eslint-disable react-hooks/exhaustive-deps */
const GameDemographicsTableDataBar: React.FC<Props> = ({ colors, game, marketIso, dataKeys }) => {
  const widths = useMemo(() => dataKeys.map((key) => game.getDemographicsValue(marketIso, key) || 0), [game, marketIso, dataKeys])
  return <DemographicsTableBars colors={colors} widths={widths} />
}

export default GameDemographicsTableDataBar
