import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { Grid, FormControl, Typography } from '@mui/material'

import DateRangePicker, { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu'
import { dateRangeOption30Days, dateRangeOption60Days, dateRangeOption7Days, dateRangeOption90Days } from '../../../../types/DateRangeOptions'
import { RoleEnum } from '../../../account'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { usePerformanceChangesByOptions } from '../../hooks/usePerformanceChangesByOptions'
import { SortGamesByOptionValue, useSortGamesBySelectOptions } from '../../hooks/useSortGamesBySelectOptions'
import { useThresholdOptions } from '../../hooks/useThresholdOptions'
import { PerformanceChangesByOptionValue } from '../../types/PerformanceChangesByOption'

type LiveEventsFiltersProps = {
  dateRange?: DateRangeValue
  minDate?: Date
  maxDate?: Date
  sortGamesBy: SortGamesByOptionValue
  threshold: number
  performanceChangesBy: PerformanceChangesByOptionValue
  onDateRangeChange: (value?: DateRangeValue) => void
  onSortGamesByChange?: (value: SortGamesByOptionValue) => void
  onThresholdChange: (value: number) => void
  onPerformanceChangesByChange: (value: PerformanceChangesByOptionValue) => void
  config: LiveEventsFiltersConfig
}

export type LiveEventsFiltersConfig = {
  performanceChangesByDisabled?: boolean
  thresholdDisabled?: boolean
  sortGamesByDisabled?: boolean
}

export const LiveEventsFilters: FC<LiveEventsFiltersProps> = ({
  dateRange,
  minDate,
  maxDate,
  sortGamesBy,
  threshold,
  performanceChangesBy,
  onDateRangeChange,
  onSortGamesByChange,
  onThresholdChange,
  onPerformanceChangesByChange,
  config,
}) => {
  const { t } = useTranslation()
  const liveEventsTrackerUnlocked = useRoleCheck(RoleEnum.live_events_tracker)
  const sortGamesByOptions = useSortGamesBySelectOptions()
  const thresholdOptions = useThresholdOptions()
  const performanceChangesByOptions = usePerformanceChangesByOptions()

  const dateRangeOptions = useMemo(() => {
    return [dateRangeOption7Days, dateRangeOption30Days, dateRangeOption60Days, dateRangeOption90Days]
  }, [])

  return (
    <Grid container alignItems="center" justifyContent="space-between" wrap="wrap" spacing={2}>
      <Grid item>
        <DateRangePicker
          value={dateRange}
          onChangeValue={(value) => onDateRangeChange(value)}
          minDate={minDate}
          maxDate={maxDate}
          disabled={!liveEventsTrackerUnlocked}
          dateRangeOptions={dateRangeOptions}
        />
      </Grid>
      <Grid item>
        <Grid container alignItems="center" wrap="wrap" spacing={2}>
          <Grid item>
            <Grid container alignItems="center" gap={1}>
              <FormControl size="small" variant="outlined" sx={{ minWidth: 200 }}>
                <SelectMenu
                  label={t('live-events:performance_changes_by_label')}
                  value={performanceChangesBy}
                  onChange={(event) => onPerformanceChangesByChange(event.target.value)}
                  options={performanceChangesByOptions}
                  disabled={config.performanceChangesByDisabled}
                />
              </FormControl>
              <GRTooltip
                content={
                  <Typography variant="body1" component="div">
                    <Trans i18nKey={'live-events:performance_changes_by_tooltip'} />
                  </Typography>
                }
                hidden={config.performanceChangesByDisabled}
              >
                <Info color="primary" sx={{ color: (theme) => (config.performanceChangesByDisabled ? theme.palette.grey[400] : 'primary') }} />
              </GRTooltip>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl size="small" variant="outlined" sx={{ minWidth: 100 }}>
              <SelectMenu
                label={t('live-events:threshold_label')}
                value={threshold}
                onChange={(event) => onThresholdChange(event.target.value)}
                options={thresholdOptions}
                disabled={config.thresholdDisabled}
              />
            </FormControl>
          </Grid>
          {onSortGamesByChange && (
            <Grid item>
              <FormControl size="small" variant="outlined" sx={{ minWidth: 100 }}>
                <SelectMenu
                  label={t('live-events:sort_games_by_label')}
                  value={sortGamesBy}
                  onChange={(event) => onSortGamesByChange(event.target.value)}
                  options={sortGamesByOptions}
                  disabled={config.sortGamesByDisabled}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}
