import { FC, ReactNode } from 'react'

import { CircularProgress, CircularProgressProps } from '@mui/material'

import { Overlay } from '../Overlay/Overlay'

/**
 * Component which draws a transparent layer with loader animation on top of a component.
 * Can be useful with e.g. charts that are loading data for display
 */
export const OverlayLoader: FC<{ isLoading?: boolean; children?: ReactNode } & Omit<CircularProgressProps, 'children'>> = ({
  isLoading,
  children,
  ...circularProgressProps
}) => {
  return (
    <Overlay in={isLoading} content={<CircularProgress color="primary" {...circularProgressProps} />}>
      {children}
    </Overlay>
  )
}
