import { FC, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { LockedFeature } from '../../components/LockedFeature/LockedFeature'
import { RoleEnum } from '../../features/account'
import { useRoleCheck } from '../../features/account/hooks/roleHooks'
import { useCurrentMarket } from '../../features/markets'
import { displaySnackBar } from '../../features/snackbar'
import { Filters } from '../../features/soft-launch'
import { SoftLaunchFilters } from '../../features/soft-launch/components/SoftLaunchFilters/SoftLaunchFilters'
import { SoftLaunchTable } from '../../features/soft-launch/components/SoftLaunchTable/SoftLaunchTable'
import { useSoftLaunchGames } from '../../features/soft-launch/hooks/useSoftLaunchGames'
import { useCurrentStore } from '../../features/store'
import { useAppDispatch } from '../../hooks/storeHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import pageService from '../../services/PageService'
import { LockedFeatureId } from '../../types/LockedFeature'

/**
 * Component for soft launch page
 */
export const SoftLaunchPage: FC<{}> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  usePage(pageService.getPageWithId('soft-launch'), 'Visited soft launch games')
  useDocumentTitle(t('sidebar:soft_launch'))

  const { currentMarket: activeMarket } = useCurrentMarket()
  const { currentStore } = useCurrentStore()

  const softLaunchUnlocked = useRoleCheck(RoleEnum.soft_launch)
  const { games, isLoading, isFetching, error: isError } = useSoftLaunchGames()

  useEffect(() => {
    if (isError) {
      dispatch(displaySnackBar({ message: t('soft-launch-games:error_could_not_load_soft_launch_games'), severity: 'error', open: true }))
    }
  }, [dispatch, t, isError])

  const [filters, setFilters] = useState<Filters>({})
  const handleFiltersChange = useCallback((filters: Filters) => {
    setFilters(filters)
  }, [])

  return softLaunchUnlocked ? (
    <>
      <Typography variant="body1" mb={3} mt={1}>
        <Trans
          i18nKey="soft-launch-games:soft_launch_by_top_publishers"
          values={{ activeMarketname: activeMarket?.name, activeStorename: currentStore?.name }}
        ></Trans>
      </Typography>
      <Typography variant="body1" my={2}>
        <Trans i18nKey="soft-launch-games:soft_launch_estimates_info_text"></Trans>
      </Typography>
      <Divider variant="fullWidth" />
      <Box my={3}>
        <SoftLaunchFilters onChange={handleFiltersChange} />
      </Box>

      <SoftLaunchTable filters={filters} games={games} isLoading={isLoading || isFetching} />
    </>
  ) : (
    <LockedFeature.Card lockedFeatureId={LockedFeatureId.SoftLaunch} />
  )
}
