import classNames from 'classnames'
import React from 'react'

import { Box } from '@mui/material'

import { GRCountryFlag } from '../../../../components/GRCountryFlag/GRCountryFlag'
import { SortOrder } from '../../../../components/GRTable/GRTable'
import { MarketExplorerSegmentColorBadge } from '../MarketExplorerSegmentColorBadge/MarketExplorerSegmentColorBadge'
import { SegmentQueryDataType } from '../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'

interface SegmentSortButtonProps {
  onChangeSort: (value: SortOrder) => void
  orderNumber: number
  segment: SegmentQueryDataType
  sortOrder?: SortOrder
}

export const SegmentSortButton: React.FC<SegmentSortButtonProps> = ({ onChangeSort, orderNumber, segment, sortOrder }) =>
  segment?.segmentConfiguration.visible ? (
    <Box
      className={classNames('SortOption', 'SortOption--' + orderNumber, { 'SortOption--active': !!sortOrder })}
      onClick={() => onChangeSort(sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC)}
    >
      <Box className="SortBadge">
        <MarketExplorerSegmentColorBadge orderNumber={orderNumber} />
        {sortOrder && <span className={`${sortOrder === SortOrder.ASC ? 'SortMark--up' : ''}`} />}
      </Box>
      <GRCountryFlag countryCode={segment.segmentConfiguration.marketIso} />
    </Box>
  ) : null
