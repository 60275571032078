import classNames from 'classnames'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd'
import { LoadingButton } from '@mui/lab'
import { ButtonProps } from '@mui/material'

import { useGetUserProfileQuery } from '../../../api/combined'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { useBookmarks } from '../hooks/bookmarkHooks'
import { Bookmark } from '../types/Bookmark'
import './AddBookmarkButton.scss'
import EditBookMarkDialog from './EditBookmarkDialog'

const AddBookmarkButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation()
  const { data: currentUser } = useGetUserProfileQuery()
  const { data: bookmarks, isLoading } = useBookmarks()
  const [open, setOpen] = useState(false)
  const location = useLocation()

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const bookmarkForCurrentPath = useMemo(() => {
    if (!bookmarks || !bookmarks.own.length) {
      return null
    }

    const fullURL = location.pathname + location.search

    // return existing bookmark for current path
    return bookmarks.own.find((bookmark) => bookmark.urlPath === fullURL)
  }, [location, bookmarks])

  return (
    <div className={classNames('AddBookmarkButton', { 'AddBookmarkButton--AddNewBookmark': !bookmarkForCurrentPath })}>
      <GRTooltip content={bookmarkForCurrentPath ? t('bookmarks:edit_bookmark') : t('bookmarks:create_new_bookmark')}>
        <LoadingButton
          {...props}
          startIcon={bookmarkForCurrentPath ? <BookmarkIcon /> : <BookmarkAddIcon />}
          variant={bookmarkForCurrentPath ? 'contained' : 'contained'}
          color={bookmarkForCurrentPath ? 'primary' : 'secondary'}
          loading={isLoading}
          disabled={isLoading}
          onClick={handleClick}
        />
      </GRTooltip>
      {open && (
        <EditBookMarkDialog
          open={open}
          onClose={handleClose}
          bookmark={bookmarkForCurrentPath || ({ name: document.title, email: currentUser?.email, urlPath: location.pathname + location.search } as Bookmark)}
        />
      )}
    </div>
  )
}

export default AddBookmarkButton
