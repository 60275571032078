import { t } from 'i18next'
import { FC, ReactNode } from 'react'

import { Info } from '@mui/icons-material'
import { Box } from '@mui/material'

import { GRPopover } from '../../../../components/GRPopover/GRPopover'
import { Game } from '../../types/Game'
import './GameAnalysisOutdatedIndicator.scss'

/**
 * Component for displaying an icon indicator when a game analysis is outdated
 */
type GameAnalysisOutdatedIndicatorProps = {
  game: Game
  marketIso: string
  children?: ReactNode
}

export const GameAnalysisOutdatedIndicator: FC<GameAnalysisOutdatedIndicatorProps> = ({ game, marketIso, children }) => {
  const gameHasPowerscore = marketIso && game.gpsPerMarket[marketIso] > 0 ? true : false

  return (
    <GRPopover
      component={Box}
      popperProps={{ style: { zIndex: 1500 } }}
      placement="right"
      trigger="hover"
      className="GameAnalysisOutdatedIndicator__popoverContainer"
      content={
        <div className="GameAnalysisOutdatedIndicator__popover">
          {t(gameHasPowerscore ? 'common:game_analysis_might_be_outdated_text' : 'common:game_not_analyzed_description')}
        </div>
      }
    >
      <Info color="error" fontSize="small"></Info>
    </GRPopover>
  )
}
