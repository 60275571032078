export type MarketExplorerSegmentFilterGroup<T> = {
  label: string
  filters: MarketExplorerSegmentFilterConfig<T>[]
}

export type MarketExplorerSegmentFilterConfig<T> = {
  id: T
  label: string
  min: number
  max: number
  steps: number | number[]
  type?: MarketExplorerSegmentFilterValueType
  minLabel?: string
  maxLabel?: string
}

export enum MarketExplorerSegmentFilterValueType {
  Number = 'Number',
  Percent = 'Percent',
}
