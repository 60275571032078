import { FC, memo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Block } from '@mui/icons-material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { LockedDataIndicator } from '../../../../components/LockedDataIndicator/LockedDataIndicator'
import { TrendIndicator } from '../../../../components/TrendIndicator/TrendIndicator'
import { ValueIndicator, ValueType } from '../../../../components/ValueIndicator/ValueIndicator'
import { useDateTimeFormatter } from '../../../live-events/hooks/useDateTimeFormatter'
import { UPDATE_IMPACTS_SINCE } from '../../../update-history/constants/constants'
import { ComparisonInterval } from '../../types/ComparisonInterval'

/**
 * Component for displaying aggregate values for revenue, downloads and their ratio
 */
type AggregateValueProps = {
  value: number
  trendValue: number
  type: ValueType
  releaseDate: number
  daysSinceReleased: number
  comparisonInterval?: ComparisonInterval
  maxTrendValue?: number
  isFirstVersion?: boolean
  isTooOld?: boolean
  isBeforeGlobalLaunch?: boolean
  isAvailableForCurrentMarket?: boolean
  isUnlocked?: boolean
}

export const AggregateValue: FC<AggregateValueProps> = memo(
  ({
    value,
    trendValue,
    type = ValueType.Number,
    releaseDate,
    daysSinceReleased,
    comparisonInterval = ComparisonInterval.DAYS_7,
    maxTrendValue,
    isFirstVersion,
    isTooOld,
    isBeforeGlobalLaunch,
    isAvailableForCurrentMarket,
    isUnlocked,
  }) => {
    if (!isUnlocked) {
      return <LockedDataIndicator />
    }

    const parsedComparisonInterval = parseInt(comparisonInterval)
    return (
      <div>
        <ExceptionValue
          isTooFresh={releaseDate + parsedComparisonInterval * 24 * 60 * 60 * 1000 >= new Date().getTime()}
          isFirstVersion={isFirstVersion}
          isTooOld={isTooOld}
          isBeforeGlobalLaunch={isBeforeGlobalLaunch}
          isAvailableForCurrentMarket={isAvailableForCurrentMarket}
          tsWhenAvailable={new Date(releaseDate).setDate(new Date(releaseDate).getDate() + parsedComparisonInterval)}
        >
          <TrendIndicator value={trendValue} direction maxValue={maxTrendValue} />
          <ValueIndicator value={value} type={type} maximumFractionDigits={2} shorten sign />
        </ExceptionValue>
      </div>
    )
  }
)

type ExceptionValueProps = {
  children?: ReactNode
  isFirstVersion?: boolean
  isTooOld?: boolean
  isBeforeGlobalLaunch?: boolean
  isTooFresh?: boolean
  isAvailableForCurrentMarket?: boolean
  tsWhenAvailable: number
}

const ExceptionValue: FC<ExceptionValueProps> = ({
  tsWhenAvailable,
  isFirstVersion,
  isTooOld,
  isBeforeGlobalLaunch,
  isTooFresh,
  isAvailableForCurrentMarket,
  children,
}) => {
  const { t } = useTranslation()
  const dateFormatter = useDateTimeFormatter({ month: 'short', day: 'numeric', year: 'numeric' })

  if (isTooFresh) {
    return (
      <GRTooltip
        content={t('common:impact_data_available_tooltip', {
          date: dateFormatter.format(tsWhenAvailable),
        })}
      >
        <AccessTimeIcon fontSize="small" color="disabled" />
      </GRTooltip>
    )
  }

  if (isFirstVersion) {
    return (
      <GRTooltip content={t('common:update_impacts_not_available_for_first_version')}>
        <Block fontSize="small" color="disabled" />
      </GRTooltip>
    )
  }

  if (isTooOld) {
    return (
      <GRTooltip
        content={t('common:update_impacts_available_date_description', {
          date: dateFormatter.format(UPDATE_IMPACTS_SINCE),
        })}
      >
        <Block fontSize="small" color="disabled" />
      </GRTooltip>
    )
  }

  if (!isAvailableForCurrentMarket) {
    return (
      <GRTooltip content={t('common:update_impacts_not_available_text')}>
        <Block fontSize="small" color="disabled" />
      </GRTooltip>
    )
  }

  if (isBeforeGlobalLaunch) {
    return (
      <GRTooltip content={t('common:update_impact_version_not_available_for_market_text')}>
        <Block fontSize="small" color="disabled" />
      </GRTooltip>
    )
  }

  return <>{children}</>
}
