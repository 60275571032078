import { t } from 'i18next'
import React, { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OpenInNew } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableContainer,
  Typography,
} from '@mui/material'

import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTableColumnSelector } from '../../../../components/GRTable/GRTableColumnSelector/GRTableColumnSelector'
import { Magnify } from '../../../../components/Magnify/Magnify'
import { ValueType } from '../../../../components/ValueIndicator/ValueIndicator'
import appStoreService from '../../../../services/AppStoreService'
import languageService from '../../../../services/LanguageService'
import marketExplorerService from '../../../../services/MarketExplorerService'
import { TableSortValues } from '../../../../types/TableSortValues'
import {
  demographicsCellProps,
  demographicsHeaderCellProps,
  DemographicsTableBars,
} from '../../../demographics/components/DemographicsTableBars/DemographicsTableBars'
import { DemographicsColor } from '../../../demographics/types/DemographicsColor'
import { GamePowerscore } from '../../../game/components/GamePowerscore/GamePowerscore'
import GameSkillThinkingMeter from '../../../game/components/GameSkillThinkingMeter/GameSkillThinkingMeter'
import { useMarketExplorerSegmentFilterGroups } from '../../hooks/marketExplorerHooks'
import { useSegmentBreakdownGamesData } from '../../hooks/useSegmentBreakdownGamesData'
import {
  MarketExplorerGamesChartAxisScale,
  MarketExplorerGamesChartAxisType,
  MarketExplorerGamesChartAxisTypeId,
} from '../../types/MarketExplorerGamesChartAxisType'
import { MarketExplorerGamesChartNode } from '../../types/MarketExplorerGamesChartNode'
import { GamesDataTableColumn, GamesDataTableColumnType } from '../../types/MarketExplorerGamesDataTableColumns'
import {
  MarketExplorerSegmentBreakdownBreakdownDataKey,
  MarketExplorerSegmentBreakdownBreakdownType,
  MarketExplorerSegmentBreakdownBreakdownTypeId,
} from '../../types/MarketExplorerSegmentBreakdownBreakdownType'
import { MarketExplorerSegmentBreakdownData, MarketExplorerSegmentBreakdownDataType } from '../../types/MarketExplorerSegmentBreakdownData'
import { MarketExplorerSegmentBreakdownValueType, MarketExplorerSegmentBreakdownValueTypeId } from '../../types/MarketExplorerSegmentBreakdownValueType'
import { MarketExplorerSegmentConfiguration } from '../../types/MarketExplorerSegmentConfiguration'
import { MotivationType } from '../../types/MotivationType'
import { PlayerArchetype } from '../../types/PlayerArchetype'
import { MarketExplorerGamesDataTableHeading } from '../MarketExplorerGamesData/MarketExplorerGamesDataTable/MarketExplorerGamesDataTableHeading/MarketExplorerGamesDataTableHeading'
import {
  EmptyRevDLRatioEstimate,
  MarketExplorerGamesDataTableValue,
} from '../MarketExplorerGamesData/MarketExplorerGamesDataTable/MarketExplorerGamesDataTableValue/MarketExplorerGamesDataTableValue'
import { MarketExplorerGamesDataDialog } from '../MarketExplorerGamesDataDialog/MarketExplorerGamesDataDialog'
import { MarketExplorerSegmentCardPopover } from '../MarketExplorerSegmentCardPopover/MarketExplorerSegmentCardPopover'
import { MarketExplorerSegmentColorBadge } from '../MarketExplorerSegmentColorBadge/MarketExplorerSegmentColorBadge'
import { SegmentQueryDataType } from '../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { MarketExplorerSegmentCard } from '../MarketExplorerSegments/MarketExplorerSegmentCard/MarketExplorerSegmentCard'
import MarketExplorerSegments, { SegmentsQueryReturnType } from '../MarketExplorerSegments/MarketExplorerSegments'
import './MarketExplorerSegmentsBreakdown.scss'

export interface MarketExplorerSegmentsBreakdownProps {
  segments: SegmentQueryDataType[]
  yAxis: MarketExplorerGamesChartAxisType
  yAxisMax: MarketExplorerGamesChartAxisScale
  yAxisMin: MarketExplorerGamesChartAxisScale
  xAxis: MarketExplorerGamesChartAxisType
  xAxisMax: MarketExplorerGamesChartAxisScale
  xAxisMin: MarketExplorerGamesChartAxisScale
  chartNodes: MarketExplorerGamesChartNode[]
  selectedColumns: GamesDataTableColumnType[]
  onSelectedColumnsChange: (columnIds: GamesDataTableColumnType[]) => void
  onSegmentsChange: (segments: MarketExplorerSegmentConfiguration[]) => void
  onSegmentsQueryDataChange: (segmentsData: SegmentsQueryReturnType) => void
  segmentData?: MarketExplorerSegmentConfiguration[]
  showDaysSinceRelease?: boolean
}

const breakdownTypes: MarketExplorerSegmentBreakdownBreakdownType[] = [
  {
    name: 'common:conventional_subgenre',
    id: MarketExplorerSegmentBreakdownBreakdownTypeId.ConventionalSubgenreId,
    dataKey: MarketExplorerSegmentBreakdownBreakdownDataKey.ConventionalSubgenre,
  },
  {
    name: 'common:genre',
    id: MarketExplorerSegmentBreakdownBreakdownTypeId.CoreId,
    dataKey: MarketExplorerSegmentBreakdownBreakdownDataKey.Core,
  },
  {
    name: 'common:subgenre',
    id: MarketExplorerSegmentBreakdownBreakdownTypeId.MetaId,
    dataKey: MarketExplorerSegmentBreakdownBreakdownDataKey.Meta,
  },
  {
    name: 'common:apple_genre',
    id: MarketExplorerSegmentBreakdownBreakdownTypeId.AppleStoreGenre,
    dataKey: MarketExplorerSegmentBreakdownBreakdownDataKey.AppleStoreGenre,
  },
]

const valueTypes: MarketExplorerSegmentBreakdownValueType[] = [
  {
    name: 'common:average_label',
    id: MarketExplorerSegmentBreakdownValueTypeId.Average,
  },
  {
    name: 'common:median_label',
    id: MarketExplorerSegmentBreakdownValueTypeId.Median,
  },
  {
    name: 'common:highest_label',
    id: MarketExplorerSegmentBreakdownValueTypeId.Best,
  },
  {
    name: 'common:lowest_label',
    id: MarketExplorerSegmentBreakdownValueTypeId.Worst,
  },
]

const MarketExplorerSegmentsBreakdown: React.FC<MarketExplorerSegmentsBreakdownProps> = ({
  segments,
  yAxis,
  yAxisMax,
  yAxisMin,
  xAxis,
  xAxisMax,
  xAxisMin,
  chartNodes,
  selectedColumns,
  onSelectedColumnsChange,
  onSegmentsChange,
  onSegmentsQueryDataChange,
  segmentData = [],
  showDaysSinceRelease = false,
}) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)

  const [breakdownType, setBreakdownType] = useState<MarketExplorerSegmentBreakdownBreakdownType>(breakdownTypes[0])
  const [valueType, setValueType] = useState<MarketExplorerSegmentBreakdownValueType>(valueTypes[0])
  const [gamesModalOpen, setGamesModalOpen] = useState<boolean>(false)
  const [selectedSegmentBreakdown, setSelectedSegmentBreakdown] = useState<MarketExplorerSegmentBreakdownData>()
  const [segmentBreakdownModalOpen, setSegmentBreakdownModalOpen] = useState<boolean>(false)
  const [segmentBreakdownModalChartNodes, setSegmentBreakdownModalChartNodes] = useState<MarketExplorerGamesChartNode[]>([])
  const [segmentBreakdownModalSegments, setSegmentBreakdownModalSegments] = useState<SegmentQueryDataType[]>([])
  const { marketExplorerSegmentMotivationTypes } = useMarketExplorerSegmentFilterGroups()
  const gamesModalData = useSegmentBreakdownGamesData(selectedSegmentBreakdown)

  const handleBreakdownTypeChange = (event: SelectChangeEvent) => {
    setBreakdownType(breakdownTypes.find((type) => type.id === (event.target.value as MarketExplorerSegmentBreakdownBreakdownTypeId)) || breakdownTypes[0])
  }

  const handleValueTypeChange = (event: SelectChangeEvent) => {
    setValueType(valueTypes.find((type) => type.id === (event.target.value as MarketExplorerSegmentBreakdownValueTypeId)) || valueTypes[0])
  }

  const handleGamesModalToggle = useCallback((row: MarketExplorerSegmentBreakdownData) => {
    setSelectedSegmentBreakdown(row)
    setGamesModalOpen((value) => !value)
  }, [])

  const handleSegmentBreakdownModalToggle = useCallback(
    (row: MarketExplorerSegmentBreakdownData) => {
      // filter only chart nodes with given segment id
      const segmentChartNodes = chartNodes.filter((chartNode) => {
        return chartNode.segmentId === row.segmentId
      })

      // set all but selected segment as null
      const modalSegments = segments.map((segment, index) => {
        return row.segmentId === index + 1 ? segment : null
      })

      setSegmentBreakdownModalSegments(modalSegments)
      setSegmentBreakdownModalChartNodes(segmentChartNodes)
      setSegmentBreakdownModalOpen((value) => !value)
    },
    [chartNodes, segments]
  )

  const segmentsWithDataCount = useMemo(() => {
    return segments.filter((segment) => {
      return segment?.currentData ? true : false
    }).length
  }, [segments])

  const segmentBreakdowns: MarketExplorerSegmentBreakdownData[] = useMemo(() => {
    const segmentBreakdowns = segments

      .map((segment, index) => {
        const segmentId = index + 1
        const segmentChartNodes = chartNodes.filter((node) => node.segmentId === segmentId)

        return {
          id: `segment-${segmentId}`,
          name: '',
          type: MarketExplorerSegmentBreakdownDataType.Segment,
          segmentId: segmentId,
          segment: segment,
          games: segmentChartNodes.map((node) => node.game),
          data: {},
        } as MarketExplorerSegmentBreakdownData
      })
      // filter out segments with no data
      .filter((segment) => !!segment?.segment?.currentData)

    const breakdownDataRows: MarketExplorerSegmentBreakdownData[] = []
    if (segmentBreakdowns.length === 1) {
      const segmentBreakdown = segmentBreakdowns[0]
      let dataRowMap: { [key: string]: string } = {}
      const dataKey = breakdownType.id as
        | MarketExplorerSegmentBreakdownBreakdownTypeId.ConventionalSubgenreId
        | MarketExplorerSegmentBreakdownBreakdownTypeId.CoreId
        | MarketExplorerSegmentBreakdownBreakdownTypeId.MetaId

      switch (breakdownType.id) {
        case MarketExplorerSegmentBreakdownBreakdownTypeId.AppleStoreGenre:
          segmentBreakdown.games.forEach((game) => {
            game[MarketExplorerSegmentBreakdownBreakdownTypeId.AppleStoreGenre]?.forEach((appGenreInt) => {
              const appGenreString = appGenreInt.toString()
              const appGenreName = appStoreService.appStoreGenreMap[appGenreString]
              if (!dataRowMap[appGenreString] && appGenreName) {
                dataRowMap[appGenreString] = appGenreName
              }
            })
          })
          break

        default:
          segmentBreakdown.games.forEach((game) => {
            if (!dataRowMap[game[dataKey]] && game[breakdownType.dataKey]) {
              dataRowMap[game[dataKey]] = game[breakdownType.dataKey] as
                | MarketExplorerSegmentBreakdownBreakdownDataKey.ConventionalSubgenre
                | MarketExplorerSegmentBreakdownBreakdownDataKey.Core
                | MarketExplorerSegmentBreakdownBreakdownDataKey.Meta
            }
          })
          break
      }

      Object.keys(dataRowMap).forEach((key) => {
        const rowName = dataRowMap[key]
        breakdownDataRows.push({
          id: `segment-breakdown-${segmentBreakdown.segmentId}-${key}`,
          name: rowName,
          type: MarketExplorerSegmentBreakdownDataType.DataRow,
          segmentId: segmentBreakdown.segmentId,
          segment: segmentBreakdown.segment,
          games: segmentBreakdown.games.filter((game) => {
            if (breakdownType.id === MarketExplorerSegmentBreakdownBreakdownTypeId.AppleStoreGenre) {
              return game[MarketExplorerSegmentBreakdownBreakdownTypeId.AppleStoreGenre]?.includes(parseInt(key))
            } else {
              return game[dataKey] ? game[dataKey].toString() === key : false
            }
          }),
          data: {},
        } as MarketExplorerSegmentBreakdownData)
      })
    }

    const combinedBreakdowns = [...segmentBreakdowns, ...breakdownDataRows.sort((a, b) => (a.name < b.name ? -1 : 1))]

    combinedBreakdowns.forEach((breakdown) => {
      breakdown.data = marketExplorerService.calculateSegmentBreakdownDataForValue(breakdown, valueType)
    })
    return combinedBreakdowns
  }, [segments, chartNodes, breakdownType, valueType])

  const sortAccessor = useCallback((value: any, row: MarketExplorerSegmentBreakdownData, customTableProps?: typeof customProps, sortOrder?: SortOrder) => {
    if (row.type === MarketExplorerSegmentBreakdownDataType.Segment && customTableProps?.segmentsWithDataCount === 1) {
      return TableSortValues.AlwaysFirst
    } else if (isNaN(value) || value === Infinity) {
      return sortOrder === 'asc' && Number.MIN_SAFE_INTEGER
    } else {
      return value
    }
  }, [])

  const tableColumns: GRTableColumn<MarketExplorerSegmentBreakdownData, typeof customProps, GamesDataTableColumnType>[] = [
    {
      labelAccessor: ({ customTableProps, columns }) => (
        <GRTableColumnSelector
          columns={columns}
          selectedColumns={
            showDaysSinceRelease
              ? customTableProps?.selectedColumns
              : customTableProps?.selectedColumns.filter((gameDataColumn) => gameDataColumn !== GamesDataTableColumn.DaysSinceRelease)
          }
          onConfirm={customTableProps?.onSelectedColumnsChange}
        />
      ),
      headerCellProps: { align: 'left', sx: { minWidth: 250 } },
      sticky: true,
      columns: [
        {
          labelAccessor: ({ customTableProps }) =>
            customTableProps && customTableProps.segmentsWithDataCount === 1 ? t('market-explorer:genre_breakdown_button') : t('segment:segments'),
          accessor: ({ row, customTableProps }) => (
            <>
              {row.type === MarketExplorerSegmentBreakdownDataType.Segment && (
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <MarketExplorerSegmentCardPopover
                      placement="right"
                      content={
                        <MarketExplorerSegmentCard
                          segment={row.segment!.segmentConfiguration}
                          segmentIndex={row.segmentId - 1}
                          games={row.games}
                          complementaryData={row.segment!.complementaryData}
                        />
                      }
                    >
                      <Magnify scale={1.25}>
                        <Box sx={{ cursor: 'pointer' }}>
                          <MarketExplorerSegmentColorBadge orderNumber={row.segmentId} />
                        </Box>
                      </Magnify>
                    </MarketExplorerSegmentCardPopover>
                  </Grid>
                  <Grid item>
                    {customTableProps && customTableProps.segmentsWithDataCount !== 1 && (
                      <Button
                        className="MarketExplorerSegmentsBreakdown__TinyButton"
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<OpenInNew />}
                        onClick={() => customTableProps.handleSegmentBreakdownModalToggle(row)}
                      >
                        {t('market-explorer:genre_breakdown_button')}
                      </Button>
                    )}
                    {customTableProps && customTableProps.segmentsWithDataCount === 1 && <strong>{t(customTableProps.valueType.name)}</strong>}
                  </Grid>
                </Grid>
              )}

              {row.type === MarketExplorerSegmentBreakdownDataType.DataRow && <strong>{row.name}</strong>}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(customProps?.segmentsWithDataCount === 1 ? row.name : row.segmentId, row, customTableProps),
          defaultSortOrder: SortOrder.ASC,
          cellProps: { align: 'left' },
          headerCellProps: { align: 'left' },
        },
      ],
    },
    {
      columns: [
        {
          labelAccessor: t('common:games'),
          accessor: ({ row }) => (
            <>
              <Button
                className="MarketExplorerSegmentsBreakdown__TinyButton"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<OpenInNew />}
                onClick={() => handleGamesModalToggle(row)}
              >
                {row.games.length}
              </Button>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.games.length, row, customTableProps),
          defaultSortOrder: SortOrder.DESC,
          cellProps: { align: 'center' },
          headerCellProps: { align: 'center', sx: { minWidth: 80 } },
        },
      ],
    },
    {
      id: GamesDataTableColumn.FeatureIndicators,
      labelAccessor: t('common:feature_indicators_text'),
      columns: [
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.PowerScore />,
          accessor: ({ row }) => <GamePowerscore value={row.data[MarketExplorerGamesChartAxisTypeId.Powerscore]} />,
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.Powerscore], row, customTableProps),
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.SkillAndThinking />,
          accessor: ({ row }) => (
            <GameSkillThinkingMeter
              sensomotoric={1 - row.data[MarketExplorerGamesChartAxisTypeId.SkillThinking] / 100}
              cognitive={row.data[MarketExplorerGamesChartAxisTypeId.SkillThinking] / 100}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.SkillThinking], row, customTableProps),
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.ASC,
        },
      ],
    },
    {
      id: GamesDataTableColumn.SustainedRanks,
      labelAccessor: t('common:sustained_ranks_text'),
      columns: [
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.SustainedGrossingRank />,
          accessor: ({ row }) => <MarketExplorerGamesDataTableValue.Rank value={row.data[MarketExplorerGamesChartAxisTypeId.SGR]} />,
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.SGR], row, customTableProps),
          sortOrder: SortOrder.ASC,
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.ASC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.SustainedDownloadRank />,
          accessor: ({ row }) => <MarketExplorerGamesDataTableValue.Rank value={row.data[MarketExplorerGamesChartAxisTypeId.SDR]} />,
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.SDR], row, customTableProps),
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.ASC,
        },
      ],
    },
    {
      id: GamesDataTableColumn.PerformanceMonthly,
      labelAccessor: t('common:monthly_performance_text'),
      columns: [
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Revenue days={30} />,
          accessor: ({ row }) => (
            <MarketExplorerGamesDataTableValue.Estimate
              value={row.data[MarketExplorerGamesChartAxisTypeId.RevenuePastMonth]}
              type={ValueType.Currency}
              maximumFractionDigits={2}
              shorten
              minValue={0}
              displayZero
              emptyValue="-"
              marketIso={row.segment!.segmentConfiguration.marketIso}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.RevenuePastMonth], row, customTableProps),
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Downloads days={30} />,
          accessor: ({ row }) => (
            <MarketExplorerGamesDataTableValue.Estimate
              value={row.data[MarketExplorerGamesChartAxisTypeId.DownloadsPastMonth]}
              type={ValueType.Number}
              displayZero
              emptyValue="-"
              marketIso={row.segment!.segmentConfiguration.marketIso}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.DownloadsPastMonth], row, customTableProps),
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.RevenueDownloadsRatio days={30} />,
          accessor: ({ row }) => (
            <MarketExplorerGamesDataTableValue.Estimate
              value={row.data[MarketExplorerGamesChartAxisTypeId.RevenuePastMonth] / row.data[MarketExplorerGamesChartAxisTypeId.DownloadsPastMonth]}
              type={ValueType.Currency}
              maximumFractionDigits={3}
              shorten
              minValue={0}
              displayZero={false}
              emptyValue={<EmptyRevDLRatioEstimate />}
              marketIso={row.segment!.segmentConfiguration.marketIso}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps, col: { sortOrder } }) =>
            sortAccessor(
              row.data[MarketExplorerGamesChartAxisTypeId.RevenuePastMonth] / row.data[MarketExplorerGamesChartAxisTypeId.DownloadsPastMonth],
              row,
              customTableProps,
              sortOrder
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { minWidth: 90 } },
          defaultSortOrder: SortOrder.DESC,
        },
      ],
    },
    {
      id: GamesDataTableColumn.PerformanceHalfYear,
      labelAccessor: t('common:half_year_performance_text'),
      columns: [
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Revenue days={180} />,
          accessor: ({ row }) => (
            <MarketExplorerGamesDataTableValue.Estimate
              value={row.data[MarketExplorerGamesChartAxisTypeId.Revenue180Days]}
              type={ValueType.Currency}
              maximumFractionDigits={2}
              shorten
              minValue={0}
              displayZero
              emptyValue="-"
              marketIso={row.segment!.segmentConfiguration.marketIso}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.Revenue180Days], row, customTableProps),
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Downloads days={180} />,
          accessor: ({ row }) => (
            <MarketExplorerGamesDataTableValue.Estimate
              value={row.data[MarketExplorerGamesChartAxisTypeId.Downloads180Days]}
              type={ValueType.Number}
              displayZero
              emptyValue="-"
              marketIso={row.segment!.segmentConfiguration.marketIso}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.Downloads180Days], row, customTableProps),
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.RevenueDownloadsRatio days={180} />,
          accessor: ({ row }) => (
            <MarketExplorerGamesDataTableValue.Estimate
              value={row.data[MarketExplorerGamesChartAxisTypeId.Revenue180Days] / row.data[MarketExplorerGamesChartAxisTypeId.Downloads180Days]}
              type={ValueType.Currency}
              maximumFractionDigits={3}
              shorten
              minValue={0}
              displayZero={false}
              emptyValue={<EmptyRevDLRatioEstimate />}
              marketIso={row.segment!.segmentConfiguration.marketIso}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps, col: { sortOrder } }) =>
            sortAccessor(
              row.data[MarketExplorerGamesChartAxisTypeId.Revenue180Days] / row.data[MarketExplorerGamesChartAxisTypeId.Downloads180Days],
              row,
              customTableProps,
              sortOrder
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { minWidth: 90 } },
          defaultSortOrder: SortOrder.DESC,
        },
      ],
    },
    {
      id: GamesDataTableColumn.PerformanceAnnual,
      labelAccessor: t('common:annual_perfomance_text'),
      columns: [
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Revenue days={360} />,
          accessor: ({ row }) => (
            <MarketExplorerGamesDataTableValue.Estimate
              value={row.data[MarketExplorerGamesChartAxisTypeId.Revenue360Days]}
              type={ValueType.Currency}
              maximumFractionDigits={2}
              shorten
              minValue={0}
              displayZero
              emptyValue="-"
              marketIso={row.segment!.segmentConfiguration.marketIso}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.Revenue360Days], row, customTableProps),
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.Downloads days={360} />,
          accessor: ({ row }) => (
            <MarketExplorerGamesDataTableValue.Estimate
              value={row.data[MarketExplorerGamesChartAxisTypeId.Downloads360Days]}
              type={ValueType.Number}
              displayZero
              emptyValue="-"
              marketIso={row.segment!.segmentConfiguration.marketIso}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.Downloads360Days], row, customTableProps),
          cellProps: { align: 'center' },
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Performance.RevenueDownloadsRatio days={360} />,
          accessor: ({ row }) => (
            <MarketExplorerGamesDataTableValue.Estimate
              value={row.data[MarketExplorerGamesChartAxisTypeId.Revenue360Days] / row.data[MarketExplorerGamesChartAxisTypeId.Downloads360Days]}
              type={ValueType.Currency}
              maximumFractionDigits={3}
              shorten
              minValue={0}
              displayZero={false}
              emptyValue={<EmptyRevDLRatioEstimate />}
              marketIso={row.segment!.segmentConfiguration.marketIso}
            />
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps, col: { sortOrder } }) =>
            sortAccessor(
              row.data[MarketExplorerGamesChartAxisTypeId.Revenue360Days] / row.data[MarketExplorerGamesChartAxisTypeId.Downloads360Days],
              row,
              customTableProps,
              sortOrder
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { minWidth: 90 } },
          defaultSortOrder: SortOrder.DESC,
        },
      ],
    },
    {
      id: GamesDataTableColumn.Gender,
      labelAccessor: t('common:gender'),
      columns: [
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Male />,
          accessor: ({ row }) => (
            <>
              <MarketExplorerGamesDataTableValue.Demographics value={row.data[MarketExplorerGamesChartAxisTypeId.DemographicsMale]} />
              <DemographicsTableBars
                widths={[row.data[MarketExplorerGamesChartAxisTypeId.DemographicsMale], row.data[MarketExplorerGamesChartAxisTypeId.DemographicsFemale]]}
                colors={[DemographicsColor.Male, DemographicsColor.Female]}
              />
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.DemographicsMale], row, customTableProps),
          cellProps: demographicsCellProps,
          headerCellProps: demographicsHeaderCellProps,
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Female />,
          accessor: ({ row }) => <MarketExplorerGamesDataTableValue.Demographics value={row.data[MarketExplorerGamesChartAxisTypeId.DemographicsFemale]} />,
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.DemographicsFemale], row, customTableProps),
          cellProps: demographicsCellProps,
          headerCellProps: demographicsHeaderCellProps,
          defaultSortOrder: SortOrder.DESC,
        },
      ],
    },
    {
      id: GamesDataTableColumn.Age,
      labelAccessor: t('common:age'),
      columns: [
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Age16To24 />,
          accessor: ({ row }) => (
            <>
              <MarketExplorerGamesDataTableValue.Demographics value={row.data[MarketExplorerGamesChartAxisTypeId.DemographicsAge16_24]} />
              <DemographicsTableBars
                widths={[
                  row.data[MarketExplorerGamesChartAxisTypeId.DemographicsAge16_24],
                  row.data[MarketExplorerGamesChartAxisTypeId.DemographicsAge25_44],
                  row.data[MarketExplorerGamesChartAxisTypeId.DemographicsAge45],
                ]}
                colors={[DemographicsColor.Age16_24, DemographicsColor.Age25_44, DemographicsColor.Age45_plus]}
              />
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.DemographicsAge16_24], row, customTableProps),
          cellProps: demographicsCellProps,
          headerCellProps: demographicsHeaderCellProps,
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Age25To44 />,
          accessor: ({ row }) => <MarketExplorerGamesDataTableValue.Demographics value={row.data[MarketExplorerGamesChartAxisTypeId.DemographicsAge25_44]} />,
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.DemographicsAge25_44], row, customTableProps),
          cellProps: demographicsCellProps,
          headerCellProps: demographicsHeaderCellProps,
          defaultSortOrder: SortOrder.DESC,
        },
        {
          labelAccessor: () => <MarketExplorerGamesDataTableHeading.Demographics.Age45Plus />,
          accessor: ({ row }) => <MarketExplorerGamesDataTableValue.Demographics value={row.data[MarketExplorerGamesChartAxisTypeId.DemographicsAge45]} />,
          sortable: true,
          sortAccessor: ({ row, customTableProps }) => sortAccessor(row.data[MarketExplorerGamesChartAxisTypeId.DemographicsAge45], row, customTableProps),
          cellProps: demographicsCellProps,
          headerCellProps: demographicsHeaderCellProps,
          defaultSortOrder: SortOrder.DESC,
        },
      ],
    },
    ...Object.values(PlayerArchetype).map((playerArchetype) => {
      return {
        id: playerArchetype,
        labelAccessor: t('segment:player_archetypes'),
        columnSelectorLabelAccessor: () => languageService.getTranslation('motivations', playerArchetype),
        columns: [
          {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Archetype archetype={playerArchetype} />,
            accessor: ({ row }: { row: MarketExplorerSegmentBreakdownData }) => (
              <MarketExplorerGamesDataTableValue.Archetype value={row.data[playerArchetype]} />
            ),
            headerCellProps: { sx: { minWidth: 75 } },
            sortable: true,
            sortAccessor: ({ row, customTableProps }: { row: MarketExplorerSegmentBreakdownData; customTableProps?: typeof customProps }) =>
              sortAccessor(row.data[playerArchetype], row, customTableProps),
          },
        ],
      }
    }),
    ...marketExplorerSegmentMotivationTypes.map((motivationGroup) => {
      return {
        id: motivationGroup.types.reduce((acc, type) => type.id, null as unknown as MotivationType) as MotivationType,
        labelAccessor: motivationGroup.label,
        columns: motivationGroup.types.map((motivation) => {
          return {
            labelAccessor: () => <MarketExplorerGamesDataTableHeading.Motivation motivationType={motivation.type} groupLabel={motivationGroup.label} />,
            accessor: ({ row }: { row: MarketExplorerSegmentBreakdownData }) => (
              <MarketExplorerGamesDataTableValue.Motivation value={row.data[motivation.type]} tooltipTranslationKey="motivations:motivation-avg-importance" />
            ),
            headerCellProps: { sx: { minWidth: 150 } },
            sortable: true,
            sortAccessor: ({ row, customTableProps }: { row: MarketExplorerSegmentBreakdownData; customTableProps?: typeof customProps }) =>
              sortAccessor(row.data[motivation.type], row, customTableProps),
          }
        }),
      }
    }),
  ]

  const [columns, setColumns] = useState<GRTableColumn<MarketExplorerSegmentBreakdownData, typeof customProps, GamesDataTableColumnType>[]>(tableColumns)
  const handleColumnsUpdate = useCallback(
    (updatedColumns: GRTableColumn<MarketExplorerSegmentBreakdownData, typeof customProps, GamesDataTableColumnType>[]) => {
      setColumns(updatedColumns)
    },
    []
  )

  const customProps = useMemo(() => {
    return {
      segmentsWithDataCount,
      breakdownType,
      valueType,
      handleSegmentBreakdownModalToggle,
      selectedColumns,
      onSelectedColumnsChange,
    }
  }, [segmentsWithDataCount, breakdownType, valueType, handleSegmentBreakdownModalToggle, selectedColumns, onSelectedColumnsChange])

  return (
    <Box className="MarketExplorerSegmentsBreakdown">
      <Box mb={2} p={2}>
        <Grid container wrap="wrap">
          <Grid item md={8} sm={12}>
            <Typography variant="body1">{t('segment:segment_genre_breakdown_axes_title')}</Typography>
            <Typography variant="body1" component="span">
              {t(yAxis.name)}: {yAxis.inversed ? `${yAxisMax.label} - ${yAxisMin.label}` : `${yAxisMin.label} - ${yAxisMax.label}`}
            </Typography>
            <Typography variant="body1" component="span" mx={2}>
              |
            </Typography>
            <Typography variant="body1" component="span">
              {t(xAxis.name)}: {xAxis.inversed ? `${xAxisMax.label} - ${xAxisMin.label}` : `${xAxisMin.label} - ${xAxisMax.label}`}
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              {t('market-explorer:figures_average_all_games_title', { number: chartNodes.length })}
            </Typography>
            {segmentsWithDataCount === 1 && (
              <FormControl size="small" variant="outlined" sx={{ width: 165, mt: 2, mr: 1 }}>
                <InputLabel>{t('common:breakdown_type')}</InputLabel>
                <Select value={breakdownType.id} label={t('common:breakdown_type')} size="small" onChange={handleBreakdownTypeChange}>
                  {breakdownTypes.map((type) => {
                    return (
                      <MenuItem key={type.id} value={type.id}>
                        {t(type.name)}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            )}
            <FormControl size="small" variant="outlined" sx={{ width: 110, mt: 2 }}>
              <InputLabel>{t('common:value_type')}</InputLabel>
              <Select value={valueType.id} label={t('common:value_type')} size="small" onChange={handleValueTypeChange}>
                {valueTypes.map((type) => {
                  return (
                    <MenuItem key={type.id} value={type.id}>
                      {t(type.name)}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={4} sm={12} className="SegmentBreakDown">
            {segmentData.length === 1 && (
              <MarketExplorerSegments
                onSegmentsChange={onSegmentsChange}
                onSegmentsQueryDataChange={onSegmentsQueryDataChange}
                segments={[segmentData[0]]}
                showToggleVisibility
                showAddNewSegment
                chartNodesCount={chartNodes.length}
                preventInit
              ></MarketExplorerSegments>
            )}
          </Grid>
        </Grid>
      </Box>
      <Divider light />
      <TableContainer ref={containerRef}>
        <GRTable
          rows={segmentBreakdowns}
          columns={columns}
          striped
          hoverable
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey="id"
          scroller={containerRef}
          customProps={customProps}
          selectedColumns={selectedColumns}
          noRowsLabel={t('compare:no_results_found')}
        />
      </TableContainer>

      <MarketExplorerGamesDataDialog
        open={gamesModalOpen}
        onClose={handleGamesModalToggle}
        games={gamesModalData}
        selectedColumns={selectedColumns}
        onSelectedColumnsChange={onSelectedColumnsChange}
      />
      <MarketExplorerSegmentBreakdownModal
        open={segmentBreakdownModalOpen}
        onClose={handleSegmentBreakdownModalToggle}
        segments={segmentBreakdownModalSegments}
        yAxis={yAxis}
        yAxisMax={yAxisMax}
        yAxisMin={yAxisMin}
        xAxis={xAxis}
        xAxisMax={xAxisMax}
        xAxisMin={xAxisMin}
        chartNodes={segmentBreakdownModalChartNodes}
        selectedColumns={selectedColumns}
        onSelectedColumnsChange={onSelectedColumnsChange}
        segmentData={segmentData}
        onSegmentsChange={onSegmentsChange}
        onSegmentsQueryDataChange={onSegmentsQueryDataChange}
      />
    </Box>
  )
}

export default MarketExplorerSegmentsBreakdown

type MarketExplorerSegmentBreakdownModalProps = DialogProps &
  MarketExplorerSegmentsBreakdownProps & {
    children?: ReactNode
  }

/**
 * Component for displaying market explorer segment breakdown for selected segment. Simply recycles MarketExplorerSegmentsBreakdown component.
 */
export const MarketExplorerSegmentBreakdownModal: FC<MarketExplorerSegmentBreakdownModalProps> = ({
  open,
  onClose,
  segments,
  yAxis,
  yAxisMax,
  yAxisMin,
  xAxis,
  xAxisMax,
  xAxisMin,
  chartNodes,
  selectedColumns,
  onSelectedColumnsChange,
  onSegmentsChange,
  onSegmentsQueryDataChange,
  segmentData,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <GRDialogTitle onClose={() => onClose && onClose({}, 'escapeKeyDown')}>{t('market-explorer:genre_breakdown_modal_title')}</GRDialogTitle>
      <DialogContent dividers>
        <Card>
          <MarketExplorerSegmentsBreakdown
            segments={segments}
            yAxis={yAxis}
            yAxisMax={yAxisMax}
            yAxisMin={yAxisMin}
            xAxis={xAxis}
            xAxisMax={xAxisMax}
            xAxisMin={xAxisMin}
            chartNodes={chartNodes}
            selectedColumns={selectedColumns}
            onSelectedColumnsChange={onSelectedColumnsChange}
            segmentData={segmentData}
            onSegmentsChange={onSegmentsChange}
            onSegmentsQueryDataChange={onSegmentsQueryDataChange}
          />
        </Card>
      </DialogContent>
    </Dialog>
  )
}
