import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import Powerscore from '../../../../components/Powerscore/Powerscore'
import styles from './GamePowerscore.module.scss'

type GamePowerscoreProps = {
  value: number
}

/**
 * Component for displaying powerscore in Top Grossing table
 */
export const GamePowerscore: FC<GamePowerscoreProps> = memo(({ value }) => {
  const { t } = useTranslation()
  return value ? (
    <GRTooltip content={t('common:tooltip_gps_description')}>
      <Powerscore powerscore={value} fontSize={11} size={24} />
    </GRTooltip>
  ) : (
    <GRTooltip content={t('common:analysis_not_available')}>
      <UnavailableIcon />
    </GRTooltip>
  )
})

const UnavailableIcon = () => <DoNotDisturbAltIcon className={styles['unavailable-icon']} />
