import { useGetEventsForTrackedGamesQuery } from '../../../api/core'
import { useTrackedEventsEndTimestamp } from './useTrackedEventsEndTimestamp'

type UseTrackedEventsHookParams = {
  gameIds: string[]
  startTimestamp: number
  endTimestamp: number
  marketIso: string
}

export const useTrackedEvents = ({ gameIds, startTimestamp, endTimestamp, marketIso }: UseTrackedEventsHookParams) => {
  const endTimestampBasedOnInternalRole = useTrackedEventsEndTimestamp(endTimestamp)
  return useGetEventsForTrackedGamesQuery({ gameIds, startTimestamp, endTimestamp: endTimestampBasedOnInternalRole, marketIso }, { skip: gameIds.length === 0 })
}
