import { FC, ReactNode, useMemo } from 'react'

import { Link } from '@mui/material'

import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { Magnify } from '../../../../../components/Magnify/Magnify'
import { Game } from '../../../../../features/game/types/Game'
import { useReviewTooltip } from '../../hooks/useReviewTooltip'
import { ReviewStatus } from '../../types/ReviewStatus'
import { ReviewStatusIcon } from '../ReviewStatusIcon/ReviewStatusIcon'

type ReviewStatusIndicatorProps = {
  game: Game
  children?: ReactNode
}

export const ReviewStatusIndicator: FC<ReviewStatusIndicatorProps> = ({ game, children }) => {
  const status = useMemo(() => {
    if (!game.reviewId) {
      return ReviewStatus.Empty
    } else if (game.reviewPublished) {
      return ReviewStatus.Published
    } else {
      return ReviewStatus.Draft
    }
  }, [game.reviewId, game.reviewPublished])

  const tooltip = useReviewTooltip(status)

  return (
    <GRTooltip content={tooltip}>
      <ReviewLink appId={game.appId} reviewId={game.reviewId}>
        <Magnify force>
          <ReviewStatusIcon status={status} />
        </Magnify>
      </ReviewLink>
    </GRTooltip>
  )
}

const ReviewLink: FC<{ appId: number; reviewId?: string; children?: ReactNode }> = ({ appId, reviewId, children }) => {
  const linkDomain = resolveDomain()
  const searchParams = new URLSearchParams()
  searchParams.set('appId', appId.toString())
  reviewId && searchParams.set('reviewId', reviewId)
  return (
    <Link href={`${linkDomain}/#/review/edit?${searchParams}`} target="_blank">
      {children}
    </Link>
  )
}

const resolveDomain = () => {
  const reactEnv = process.env.REACT_APP_ENV
  if (reactEnv?.includes('dev')) {
    return 'https://internal-dev.gamerefinery.com'
  } else if (reactEnv?.includes('qa')) {
    return 'https://internal-qa.gamerefinery.com'
  } else if (reactEnv?.includes('stage')) {
    return 'https://internal-staging.gamerefinery.com'
  } else if (reactEnv?.includes('prod')) {
    return 'https://internal.gamerefinery.com'
  } else {
    return 'https://internal-dev.gamerefinery.com'
  }
}
