import { VisualAnalysis, VisualAnalysisModel } from '../../../api/connect'
import { UserLanguage } from '../../account/types/User'
import { getMarketIsosByUserLanguage } from '../../markets/utils/utils'

export const mainAttributeModels: VisualAnalysisModel[] = ['icon', 'genre', 'style', 'colorGroups']
export const extendedAttributeModels: VisualAnalysisModel[] = [...mainAttributeModels, 'color']

export const visualsTranslationMap: { [model in VisualAnalysisModel]: string } = {
  icon: 'visuals-explorer:visual_model_objects',
  genre: 'visuals-explorer:visual_model_genres',
  style: 'visuals-explorer:visual_model_styles',
  color: 'visuals-explorer:visual_model_colors',
  colorGroups: 'visuals-explorer:visual_model_color_groups',
}

export const formatPercentage = (value?: number | null): string => (value === null || value === undefined ? '-' : Math.round(value * 100) + '%')

export const formatPercentageDiff = (value1?: number | null, value2?: number | null): string => {
  if ((!value1 && value1 !== 0) || (!value2 && value2 !== 0)) return '-'

  const diff = Math.round(value2 * 100) - Math.round(value1 * 100)

  if (diff === 0) return '0'
  if (diff > 0) return '+' + diff
  if (diff > -1 && diff < 0) return '' + diff

  return '' + diff
}

export const getVisualAnalysisName = ({ visualAnalysis, userLanguage }: { visualAnalysis: VisualAnalysis; userLanguage: UserLanguage }): string => {
  const marketIsos = getMarketIsosByUserLanguage(userLanguage)
  let resolvedName

  marketIsos.every((marketIso) => {
    resolvedName = visualAnalysis.gameNames[marketIso]
    return !resolvedName
  })

  return resolvedName || visualAnalysis.gameNames['us'] || Object.values(visualAnalysis.gameNames)[0] || ''
}
