import { ReactNode } from 'react'

import { FeatureChoiceMap } from '../../market-explorer/types/MarketExplorerSegmentConfiguration'

export type SelectOption = {
  name: ReactNode
  value: string
}

export const EMPTY_AGGREGATE_VALUE = '0'
export type EmptyAggregateValue = typeof EMPTY_AGGREGATE_VALUE
export type ColumnFilterValue = { name: string; value: any }

export type AggregateValueColumnFilterValue = { valueChange?: number | string | EmptyAggregateValue; percentChange?: number | string | EmptyAggregateValue }
export type AggregateValueColumnFilterResult = ColumnFilterValue & { value: AggregateValueColumnFilterValue }

export enum AggregateValueColumnFilterName {
  revenue = 'revenue',
  downloads = 'downloads',
  revenueDownloadsRatio = 'revenueDownloadsRatio',
}

export enum FeaturesFilterValue {
  Any = 'any',
  Changed = 'changedFeatures',
  Updated = 'updatedFeatures',
}
export type FeaturesColumnFilterValue = { featureType?: FeaturesFilterValue; selectedFeatures?: FeatureChoiceMap }
export type FeaturesColumnFilterResult = ColumnFilterValue & { value: FeaturesColumnFilterValue }
