import html2canvas from 'html2canvas'
import { useState } from 'react'

export type TakeScreenshotProps = {
  /**
   * Crop height in pixels.
   */
  cropHeight?: number
  /**
   * Crop width in pixels
   */
  cropWidth?: number
}

export const useScreenshot = (type: string = 'image/jpeg', quality: number = 1) => {
  const [image, setImage] = useState<string | null>(null)
  const [error, setError] = useState(null)

  const takeScreenshot = (node: HTMLElement, options?: TakeScreenshotProps) => {
    if (!node) {
      throw new Error('You should provide correct html node.')
    }
    return html2canvas(node, { logging: false })
      .then((canvas) => {
        const croppedCanvas = document.createElement('canvas')
        const croppedCanvasContext = croppedCanvas.getContext('2d')

        if (!croppedCanvasContext) return null

        const cropPositionTop = 0
        const cropPositionLeft = 0
        const cropWidth = options?.cropWidth ? options.cropWidth : canvas.width
        const cropHeight = options?.cropHeight ? options.cropHeight : canvas.height

        croppedCanvas.width = cropWidth
        croppedCanvas.height = cropHeight

        croppedCanvasContext.drawImage(canvas, cropPositionLeft, cropPositionTop)

        const base64Image = croppedCanvas.toDataURL(type, quality).replace('data:image/jpeg;base64,', '')

        setImage(base64Image)
        return base64Image
      })
      .catch(setError)
  }

  return {
    image,
    takeScreenshot,
    error,
  }
}
