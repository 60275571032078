import { createTheme, Theme } from '@mui/material'

/**
 * Theme options for internal tools. This theme will __extend__ the current theme.
 */
export const internalTheme = (theme: Theme) =>
  createTheme({
    ...theme,
    components: {
      ...theme.components,
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem',
          },
          head: {
            fontSize: '0.875rem',
            fontWeight: 700,
            color: theme.palette.text.primary,
          },
        },
      },
    },
  })
