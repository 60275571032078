import { t } from 'i18next'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Button } from '@mui/material'

type BackButtonProps = {
  url: string
}
export const BackButton: FC<BackButtonProps> = ({ url }) => {
  const navigate = useNavigate()
  return (
    <Box mb={2}>
      <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate(url)}>
        {t('internal-common:back')}
      </Button>
    </Box>
  )
}
