import { useMemo } from 'react'

import { useGetPublisherGameAppIdsQuery, useGetRevenueAndDownloadAggregatesQuery } from '../../../api/core'
import { useGetGameList } from '../../game/hooks/gameHooks'
import { Game } from '../../game/types/Game'

const gameIncludes = [
  'name',
  'names',
  'icon',
  'icons',
  'artist',
  'gpsPerMarket',
  'genreId',
  'subGenreId',
  'appId',
  'owner',
  'tags',
  'conventionalSubgenreId',
  'conventionalSubgenre',
  'archive',
  'reviewId',
  'reviewPublished',
]

const useGetPublisherGames = (game: Game, marketIso: string, skip: boolean = false) => {
  const marketIsos = useMemo(() => {
    return [marketIso]
  }, [marketIso])

  const { data: publisherGameAppIds, isFetching: isFetchingPublisherGameAppIds } = useGetPublisherGameAppIdsQuery({ appId: game.appId }, { skip })
  const { data: publisherGames, isFetching: isFetchingPublisherGames } = useGetGameList(
    { gameIds: publisherGameAppIds ? publisherGameAppIds : [], include: gameIncludes.join(',') },
    { skip: skip && isFetchingPublisherGameAppIds }
  )
  const { data: revenueAndDownloadByApp, isFetching: isFetchingRevenueAndDownloadByApp } = useGetRevenueAndDownloadAggregatesQuery(
    { appIds: publisherGameAppIds ? publisherGameAppIds : [], marketIsos },
    { skip: skip && isFetchingPublisherGameAppIds }
  )

  const publisherGamesRevenueAndDownloadAggregates = useMemo(() => {
    if (!publisherGames || !revenueAndDownloadByApp) {
      return []
    }

    return publisherGames
      ?.map((game) => {
        const appAggregates = revenueAndDownloadByApp && revenueAndDownloadByApp[game.appId]
        return new Game({
          ...game,
          ...{ revenueAndDownloadAggregatesMap: appAggregates?.countryAggregates },
        } as Game)
      })
      .sort((a, b) => (a.resolvedName < b.resolvedName ? -1 : 1))
  }, [publisherGames, revenueAndDownloadByApp])

  return {
    data: publisherGamesRevenueAndDownloadAggregates,
    isFetching: isFetchingPublisherGameAppIds || isFetchingPublisherGames || isFetchingRevenueAndDownloadByApp,
  }
}

export default useGetPublisherGames
