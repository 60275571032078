import classNames from 'classnames'
import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

import { Card, Tab, Tabs } from '@mui/material'

import { useMobileSize } from '../../features/responsiveness/hooks/responsivenessHooks'
import { useIntersectionObserverEntry } from '../../hooks/useIntersectionObserverEntry'
import './GRTabNavigation.scss'

export type GRTab = {
  id: string
  name: string
}
/**
 * GRTabNavigation Tab Navigation on top of page and under app header
 */
interface GRTabNavigationProps {
  tabs: GRTab[]
  selectedTab?: number
  onChange?: (tabIndex: number) => void
  sticky?: boolean
  pagePrefix?: string
  query?: string
  hasSecondaryStickyElement?: boolean
}

const observerOptions = { rootMargin: '-144px 0px 0px 0px' }

const GRTabNavigation: React.FC<GRTabNavigationProps> = ({
  tabs = [],
  selectedTab = 0,
  onChange,
  sticky = true,
  pagePrefix,
  query = '',
  hasSecondaryStickyElement = false,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const cardRef = useRef(null)
  const mobileSize = useMobileSize()

  useIntersectionObserverEntry(cardRef, observerOptions, (e) => {
    if (e && e.target) {
      const { target, isIntersecting } = e
      const shouldPin = hasSecondaryStickyElement && !mobileSize
      target.classList.toggle('GRTabNavigation--pinned', shouldPin && !isIntersecting)
    }
  })

  if (!tabs.length) {
    return null
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onChange && onChange(newValue)
    if (sticky) {
      const offsetTop = containerRef.current?.offsetTop || 0
      setTimeout(() => window.scrollTo({ top: (offsetTop || 0) - 95, behavior: 'smooth' }), 0)
    }
  }

  return (
    <>
      <div ref={containerRef}></div>
      <Card
        ref={cardRef}
        className={classNames('GRTabNavigation', { 'GRTabNavigation--mobile': mobileSize, 'GRTabNavigation--sticky': sticky })}
        sx={{ mb: 3 }}
      >
        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.name} component={Link} to={`${pagePrefix}/${tab.id}${query && query}`} />
          ))}
        </Tabs>
      </Card>
    </>
  )
}

export default GRTabNavigation
