import { ReactNode, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { GRSlider } from '../../../../../../components/GRSlider/GRSlider'
import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import { LockedDataIndicator } from '../../../../../../components/LockedDataIndicator/LockedDataIndicator'
import utilsService from '../../../../../../services/UtilsService'
import { MarketExplorerSegmentFilterValue } from '../../../../types/MarketExplorerSegmentConfiguration'
import { MarketExplorerSegmentFilterConfig, MarketExplorerSegmentFilterValueType } from '../../../../types/MarketExplorerSegmentFilterConfig'

/**
 * Component representing a single two ended slider filter for segment edit dialog
 */
type SegmentFilterProps<T> = {
  filterConfig: MarketExplorerSegmentFilterConfig<T>
  value: MarketExplorerSegmentFilterValue
  onChange: (value: MarketExplorerSegmentFilterValue, filterId: T) => void
  disabled?: boolean
  locked?: boolean
  children?: ReactNode
}

export const SegmentFilter = <T extends {}>({ filterConfig, value, onChange, disabled, locked }: SegmentFilterProps<T>) => {
  const { t } = useTranslation()
  const marks = Array.isArray(filterConfig.steps) ? filterConfig.steps : undefined
  const step = typeof filterConfig.steps === 'number' ? filterConfig.steps : null

  const handleChange = useCallback(
    (event: Event, newValue: number | number[]) => {
      if (!Array.isArray(newValue)) return

      if (newValue?.length === 2 && newValue[0] === filterConfig.min && newValue[1] === filterConfig.max) {
        onChange && onChange(undefined, filterConfig.id)
      } else {
        onChange && onChange(newValue, filterConfig.id)
      }
    },
    [filterConfig.id, filterConfig.max, filterConfig.min, onChange]
  )

  const handleValueLabelFormat = useCallback(
    (value: number) => {
      if (value === filterConfig.min && filterConfig.minLabel) {
        return filterConfig.minLabel
      } else if (value === filterConfig.max && filterConfig.maxLabel) {
        return filterConfig.maxLabel
      } else {
        return filterConfig.type === MarketExplorerSegmentFilterValueType.Percent ? utilsService.formatPercent(value, {}) : value
      }
    },
    [filterConfig.max, filterConfig.maxLabel, filterConfig.min, filterConfig.minLabel, filterConfig.type]
  )

  useEffect(() => {
    if (disabled) {
      onChange && onChange(undefined, filterConfig.id)
    }
  }, [disabled, filterConfig.id, onChange])

  return (
    <Grid container direction="row" alignItems="center" spacing={2} sx={{ minHeight: '58px' }}>
      <Grid item xs>
        {filterConfig.label}
      </Grid>
      <Grid item xs sx={{ margin: '0 0.5rem', textAlign: 'center' }}>
        <GRTooltip hidden={!disabled} content={t('common:data_not_available_for_market')}>
          {locked ? (
            <LockedDataIndicator />
          ) : (
            <GRSlider
              value={value || [filterConfig.min, filterConfig.max]}
              onChange={handleChange}
              min={filterConfig.min}
              max={filterConfig.max}
              step={step}
              marks={marks?.map((value) => ({ value }))}
              valueLabelDisplay="on"
              valueLabelFormat={handleValueLabelFormat}
              disabled={disabled}
              sx={{ mt: 3 }}
            />
          )}
        </GRTooltip>
      </Grid>
    </Grid>
  )
}
