import { FC } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Divider, Grid } from '@mui/material'

import { GameCardContent } from '../../../features/game/components/GameCard/GameCard'
import usePage from '../../../hooks/usePage'
import { useRequiredParams } from '../../../hooks/useRequiredParams'
import PageService from '../../../services/PageService'
import { useGetAnalystsListQuery, useGetTrackedGameAnalystCommentsQuery, useGetTrackedGameQuery } from '../../api/internal'
import { BackButton } from '../../components/BackButton/BackButton'
import { TrackedGameAnalystNotesTable } from '../../features/live-events-tracking/components/TrackedGameAnalystNotesTable/TrackedGameAnalystNotesTable'
import { useAnalystNotesSearchParams } from '../../features/live-events-tracking/hooks/useAnalystNotesSearchParams'

type TrackedGameAnalystNotesProps = {}

export const TrackedGameAnalystNotesPage: FC<TrackedGameAnalystNotesProps> = () => {
  usePage(PageService.getInternalPageWithId('internal-tracked-game-analyst-notes'))
  const { trackedGameId } = useRequiredParams<{ trackedGameId: string }>()
  const { parsedParams } = useAnalystNotesSearchParams()
  const getTrackedGameQuery = useGetTrackedGameQuery(trackedGameId, { skip: !trackedGameId })
  const trackedGameAnalystComments = useGetTrackedGameAnalystCommentsQuery({ trackedGameId: trackedGameId })
  const analystList = useGetAnalystsListQuery()
  const dateParam = parsedParams.date

  const isLoading = trackedGameAnalystComments.isFetching || analystList.isFetching
  return (
    <>
      <BackButton url={`/internal/live-events-tracking/events/${trackedGameId}?date=${dateParam}`} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>{getTrackedGameQuery.data?.game && <GameCardContent game={getTrackedGameQuery.data?.game} disableGameLink />}</Grid>
        <Grid item>
          <Button
            component={Link}
            to={`/internal/live-events-tracking/events/${getTrackedGameQuery.data?.game.id}/analyst-notes/edit/analyst-notes/?date=${dateParam}`}
            variant="contained"
          >
            <Trans i18nKey="internal-live-events:add_new_live_event_note" />
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <TrackedGameAnalystNotesTable
        dateParam={dateParam}
        isLoading={isLoading}
        trackedGameComments={trackedGameAnalystComments.data}
        analystList={analystList.data}
      />
    </>
  )
}
