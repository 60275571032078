export const analyzerGridColumn = {
  xs: {
    name: 3,
    options: 3,
    score: 2,
    compare: 2,
    edit: 1,
    spacing: 2,
  },
  spacing: 2,
}
