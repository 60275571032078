import React, { ReactNode } from 'react'

/**
 * GRPowerScoreLink
 */
interface GRPowerScoreLinkProps {
  children: ReactNode
}
const GRPowerScoreLink: React.FC<GRPowerScoreLinkProps> = ({ children }) => {
  return (
    <a style={{ color: 'inherit' }} href="http://docs.gamerefinery.com/en/articles/105947-powerscore" target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

export default GRPowerScoreLink
