import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Close, Save } from '@mui/icons-material'
import { Button, Dialog, DialogTitle, DialogContent, IconButton, Grid, TextField, Divider } from '@mui/material'

import { useUpdateCurrentUserOrganizationMutation } from '../../../../api/account'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { displaySnackBar } from '../../../snackbar/actions/displaySnackBar'
import { Organization } from '../../types/User'
import './OrganizationEditDialog.scss'

interface Props {
  modalOpen: boolean
  organization: Organization
  onClose: () => void
}

const OrganizationEditDialog: React.FC<Props> = ({ modalOpen, organization, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const [organizationFormData, setOrganizationFormData] = useState<{ [key: string]: any }>({
    name: organization.name,
    address1: organization.address1,
    address2: organization.address2,
    zipCode: organization.zipCode,
    city: organization.city,
    country: organization.country,
    contactName: organization.contactName,
    contactEmail: organization.contactEmail,
    contactPhone: organization.contactPhone,
    billingAddress1: organization.billingAddress1,
    billingAddress2: organization.billingAddress2,
    billingZipCode: organization.billingZipCode,
    billingCity: organization.billingCity,
    billingCountry: organization.billingCountry,
    billingContact: {
      name: organization.billingContact?.name,
      email: organization.billingContact?.email,
      phone: organization.billingContact?.phone,
    },
  })
  const [updateOrganization, { isLoading: isUpdating, isError, isSuccess }] = useUpdateCurrentUserOrganizationMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(displaySnackBar({ message: t('organization:success_saved_organiztion'), severity: 'success', open: true }))
      handleClose()
    } else if (isError) {
      dispatch(displaySnackBar({ message: t('organization:error_could_not_save_organization'), severity: 'error', open: true }))
    }
  }, [isError, isSuccess, dispatch, t, handleClose])

  const handleInput = (event: any) => {
    setOrganizationFormData((previousState) => {
      let value = event.target.value as string
      const NAME = event.target.name as string
      if (NAME.includes('.')) {
        const NAME_ARRAY = NAME.split('.')
        previousState[NAME_ARRAY[0]][NAME_ARRAY[1]] = value
      } else {
        previousState[NAME] = value
      }
      return Object.assign({}, previousState)
    })
  }

  const handleSave = useCallback(
    (event: any) => {
      event.preventDefault()
      if (isUpdating) {
        return
      }
      const newOrganizationObject = { ...organization, ...organizationFormData }
      updateOrganization(newOrganizationObject)
    },
    [isUpdating, organizationFormData, organization, updateOrganization]
  )

  return (
    <div>
      <Dialog className="OrganizationEditDialog" onClose={handleClose} fullWidth={true} maxWidth="md" open={modalOpen}>
        <DialogTitle id="customized-dialog-title">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>{t('organization:edit_organization')}</Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleClose} style={{ margin: '-10px' }}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="DialogContent" dividers>
          <form onSubmit={handleSave}>
            <Grid container>
              <Grid item xs>
                <TextField
                  name="name"
                  type="text"
                  label={t('organization:company')}
                  defaultValue={organizationFormData.name}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>
              </Grid>
            </Grid>

            <Divider className="vertical-margin" />

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <h3>{t('organization:company_address')}</h3>
                <TextField
                  name="address1"
                  type="text"
                  label={t('organization:street_address')}
                  defaultValue={organizationFormData.address1}
                  placeholder={t('organization:placeholder_address_1')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>

                <TextField
                  name="address2"
                  type="text"
                  label={t('organization:street_address')}
                  defaultValue={organizationFormData.address2}
                  placeholder={t('organization:placeholder_address_2')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      name="zipCode"
                      type="text"
                      label={t('organization:zip_code')}
                      defaultValue={organizationFormData.zipCode}
                      placeholder={t('organization:placeholder_zipcode')}
                      onChange={handleInput}
                      className="input-with-bottom-margin"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                    ></TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      name="city"
                      type="text"
                      label={t('organization:city')}
                      defaultValue={organizationFormData.city}
                      placeholder={t('organization:placeholder_city')}
                      onChange={handleInput}
                      className="input-with-bottom-margin"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                    ></TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      name="country"
                      type="text"
                      label={t('organization:country')}
                      defaultValue={organizationFormData.country}
                      placeholder={t('organization:placeholder_country')}
                      onChange={handleInput}
                      className="input-with-bottom-margin"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <h3>{t('organization:company_contact')}</h3>
                <TextField
                  name="contactName"
                  type="text"
                  label={t('organization:contact_name')}
                  defaultValue={organizationFormData.contactName}
                  placeholder={t('organization:placeholder_name')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>

                <TextField
                  name="contactEmail"
                  type="text"
                  label={t('organization:contact_email')}
                  defaultValue={organizationFormData.contactEmail}
                  placeholder={t('organization:placeholder_email')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>

                <TextField
                  name="contactPhone"
                  type="text"
                  label={t('organization:contact_phone')}
                  defaultValue={organizationFormData.contactPhone}
                  placeholder={t('organization:placeholder_phone_number')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>
              </Grid>
            </Grid>

            <Divider className="vertical-margin" />

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <h3>{t('organization:billing_address')}</h3>
                <TextField
                  name="billingAddress1"
                  type="text"
                  label={t('organization:street_address')}
                  defaultValue={organizationFormData.billingAddress1}
                  placeholder={t('organization:placeholder_address_1')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>

                <TextField
                  name="billingAddress2"
                  type="text"
                  label={t('organization:street_address')}
                  defaultValue={organizationFormData.billingAddress2}
                  placeholder={t('organization:placeholder_address_2')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      name="billingZipCode"
                      type="text"
                      label={t('organization:zip_code')}
                      defaultValue={organizationFormData.billingZipCode}
                      placeholder={t('organization:placeholder_zipcode')}
                      onChange={handleInput}
                      className="input-with-bottom-margin"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                    ></TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      name="billingCity"
                      type="text"
                      label={t('organization:city')}
                      defaultValue={organizationFormData.billingCity}
                      placeholder={t('organization:placeholder_city')}
                      onChange={handleInput}
                      className="input-with-bottom-margin"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                    ></TextField>
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      name="billingCountry"
                      type="text"
                      label={t('organization:country')}
                      defaultValue={organizationFormData.billingCountry}
                      placeholder={t('organization:placeholder_country')}
                      onChange={handleInput}
                      className="input-with-bottom-margin"
                      variant="outlined"
                      size="small"
                      fullWidth={true}
                    ></TextField>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <h3>{t('organization:billing_contact')}</h3>
                <TextField
                  name="billingContact.name"
                  type="text"
                  label={t('organization:contact_name')}
                  defaultValue={organizationFormData.billingContact.name}
                  placeholder={t('organization:placeholder_name')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>

                <TextField
                  name="billingContact.email"
                  type="text"
                  label={t('organization:contact_email')}
                  defaultValue={organizationFormData.billingContact.email}
                  placeholder={t('organization:placeholder_email')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>

                <TextField
                  name="billingContact.phone"
                  type="text"
                  label={t('organization:contact_phone')}
                  defaultValue={organizationFormData.billingContact.phone}
                  placeholder={t('organization:placeholder_phone_number')}
                  onChange={handleInput}
                  className="input-with-bottom-margin"
                  variant="outlined"
                  size="small"
                  fullWidth={true}
                ></TextField>
              </Grid>
            </Grid>

            <Divider className="vertical-margin" />

            <div className="text-center">
              <Button variant="contained" color="primary" type="submit" startIcon={<Save />} disabled={isUpdating}>
                {t('common:save')}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default OrganizationEditDialog
