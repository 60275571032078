import { Interval } from 'date-fns'
import { t } from 'i18next'
import { useState } from 'react'
import { Trans } from 'react-i18next'

import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'

import { useLiveEventInstances } from '../../hooks/useLiveEventInstances'
import { useLiveEventsByInterval } from '../../hooks/useLiveEventsByInterval'
import { EventAppearanceType } from '../../types/LiveEventInstanceTableFilters'
import { TrackedGame } from '../../types/TrackedGame'
import { TrackingEventByGame } from '../../types/TrackingEvents'
import { LiveEventInstanceTableTitle } from '../LiveEventInstanceTableTitle/LiveEventInstanceTableTitle'
import { LiveEventInstanceTable } from './LiveEventInstanceTable'

type LiveEventInstanceTableContainerProps = {
  appId?: number
  interval?: Interval
  events: TrackingEventByGame
  trackedGames?: TrackedGame[]
  isLoading?: boolean
}

export const LiveEventInstanceTableContainer: React.FC<LiveEventInstanceTableContainerProps> = ({ appId, interval, events, trackedGames, isLoading }) => {
  const [value, setValue] = useState<EventAppearanceType>(EventAppearanceType.All)
  const liveEventsInInterval = useLiveEventsByInterval({
    events,
    interval,
    appId,
    trackedGames,
    filters: {
      eventAppearance: value,
    },
  })
  const eventInstances = useLiveEventInstances({ events: liveEventsInInterval })

  return interval ? (
    <>
      <Divider sx={{ my: 2 }} />
      <Grid container alignItems="center" justifyContent="space-between" spacing={2} mb={2}>
        <Grid item>
          <LiveEventInstanceTableTitle interval={interval} mb={2} />
        </Grid>
        <Grid item>
          <FormControl component={Grid} sx={{ flexDirection: 'row', alignItems: 'center' }} container columnSpacing={2}>
            <Grid item>
              <RadioGroup
                row
                value={value}
                onChange={(event) => setValue(event.target.value as EventAppearanceType)}
                defaultValue={EventAppearanceType.All}
                aria-label="Days since released"
                name="days-since-released-radio-buttons-group"
              >
                <FormControlLabel value={EventAppearanceType.All} control={<Radio />} label={t<string>('common:all')} />
                <FormControlLabel value={EventAppearanceType.NonRecurring} control={<Radio />} label={t<string>('live-events:non-recurring')} />
                <FormControlLabel value={EventAppearanceType.Recurring} control={<Radio />} label={t<string>('live-events:recurring')} />
              </RadioGroup>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
      <LiveEventInstanceTable instances={eventInstances} isLoading={isLoading} />
    </>
  ) : (
    <Typography variant="body1" mt={2}>
      <Trans i18nKey="live-events:event_instance_table_no_date_selected_instruction" />
    </Typography>
  )
}
