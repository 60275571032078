import { PayloadAction } from '@reduxjs/toolkit'

export interface GRPopoverState {
  openingId: string | number
}

export const UPDATE_POPOVER_ID = 'UPDATE_POPOVER_ID'

const GRPopoverReducers = (state: GRPopoverState = { openingId: '' }, action: PayloadAction<GRPopoverState>) => {
  switch (action.type) {
    case UPDATE_POPOVER_ID:
      return action.payload
    default:
      return state
  }
}
export default GRPopoverReducers
