import { FC, ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  LiveEventsAnalystNotesDialogContainer,
  LiveEventsAnalystNotesDialogParams,
} from '../../../live-events/components/LiveEventsAnalystNotesDialog/LiveEventsAnalystNotesDialogContainer'
import {
  LiveEventsEventDialogContainer,
  LiveEventsEventDialogParams,
} from '../../../live-events/components/LiveEventsEventDialog/LiveEventsEventDialogContainer'
import {
  LiveEventsEventStatisticsDialogContainer,
  LiveEventsEventStatisticsDialogParams,
} from '../../../live-events/components/LiveEventsEventStatisticsDialog/LiveEventsEventStatisticsDialogContainer'
import {
  LiveEventsOverviewDialogContainer,
  LiveEventsOverviewDialogParams,
} from '../../../live-events/components/LiveEventsOverview/LiveEventsOverviewDialog/LiveEventsOverviewDialogContainer'
import { modalTypeParamKey } from '../../const/const'
import { EModalType } from '../../types/modalTypes'
import { resolveModalParams } from '../../utils/utils'

/**
 * Component responsible for rendering modals based on the URL search params
 */
type ModalControllerProps = {
  children?: ReactNode
}

export const ModalController: FC<ModalControllerProps> = () => {
  const [searchParams] = useSearchParams()
  const modalTypeParamValue = searchParams.get(modalTypeParamKey)
  const modalTypes = modalTypeParamValue?.includes(',') ? modalTypeParamValue.split(',') : modalTypeParamValue ? [modalTypeParamValue] : []

  const modals = resolveModalParams({ modalTypes: modalTypes as EModalType[], searchParams })

  return (
    <>
      {modals.map(({ modalType, params }, index) => {
        const childKey = `${modalType}-${index}`
        switch (modalType) {
          case EModalType.LIVE_EVENT:
            return <LiveEventsEventDialogContainer key={childKey} {...(params as LiveEventsEventDialogParams)} />
          case EModalType.LIVE_EVENT_ANALYST_NOTES:
            return <LiveEventsAnalystNotesDialogContainer key={childKey} {...(params as LiveEventsAnalystNotesDialogParams)} />
          case EModalType.LIVE_EVENT_ANALYST_OVERVIEW:
            return <LiveEventsOverviewDialogContainer key={childKey} {...(params as LiveEventsOverviewDialogParams)} />
          case EModalType.LIVE_EVENT_STATISTICS:
            return <LiveEventsEventStatisticsDialogContainer key={childKey} {...(params as LiveEventsEventStatisticsDialogParams)} />
          default:
            return null
        }
      })}
    </>
  )
}
