import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Grade } from '@mui/icons-material'
import { Card, Grid } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { TrendIndicator, TrendIndicatorType } from '../../../../components/TrendIndicator/TrendIndicator'
import utilsService from '../../../../services/UtilsService'
import { GameIcon } from '../../../game'
import quarterService, { QuarterDataKey } from '../../../quarter/services/QuarterService'
import { Quarter } from '../../../quarter/types/Quarter'
import { TopGame } from '../../../top-game/types/TopGame'
import { MarketShareNumberType, MarketShareNumberTypeId } from '../../types/MarketShareNumberType'
import { MarketShareScopeType } from '../../types/MarketShareScopeType'
import './MarketShareTopGameCard.scss'

interface Props {
  game: TopGame
  selectedQuarter: Quarter
  showScopeTypeShare: boolean
  marketShareScopeType: MarketShareScopeType
  marketShareNumberType: MarketShareNumberType
}

const MarketShareTopGameCard: React.FC<Props> = ({ game, selectedQuarter, showScopeTypeShare, marketShareScopeType, marketShareNumberType }) => {
  const { t } = useTranslation()

  const getGameMarketSharePercent = (valueKey: QuarterDataKey.RevenuePercentage | QuarterDataKey.DownloadsPercentage) => {
    const value = showScopeTypeShare
      ? game.selectedScopeTypeQuarters[selectedQuarter.quarterIdentifier][valueKey]
      : game.marketLevelQuarters[selectedQuarter.quarterIdentifier][valueKey]
    return utilsService.formatPercent(value, {
      maximumFractionDigits: 2,
    })
  }

  const getGameMarketShareValue = (valueKey: QuarterDataKey.RevenueTop500Value | QuarterDataKey.DownloadsTop500Value, isCurrency: boolean = false) => {
    const value = game.marketLevelQuarters[selectedQuarter.quarterIdentifier][valueKey]
    if (isCurrency) {
      return utilsService.formatCurrency(value, {
        shorten: true,
        mantissa: 2,
      })
    } else {
      return utilsService.formatNumber(value, {
        shorten: true,
        mantissa: 2,
      })
    }
  }

  const getGameMarketShareForKey = (valueKey: 'revenue' | 'downloads') => {
    switch (valueKey) {
      case 'revenue':
        return marketShareNumberType.id === MarketShareNumberTypeId.Percentage
          ? getGameMarketSharePercent(QuarterDataKey.RevenuePercentage)
          : getGameMarketShareValue(QuarterDataKey.RevenueTop500Value, true)

      case 'downloads':
        return marketShareNumberType.id === MarketShareNumberTypeId.Percentage
          ? getGameMarketSharePercent(QuarterDataKey.DownloadsPercentage)
          : getGameMarketShareValue(QuarterDataKey.DownloadsTop500Value)
    }
  }

  const displayDominantGameInSubgenreIndicator = (game: TopGame) => {
    return marketShareScopeType.id === 'subgenre' && game.selectedScopeTypeQuarters[selectedQuarter.quarterIdentifier].revenuePercentage >= 0.5
  }

  const getGameMarketShareChange = (valueKey: 'revenue' | 'downloads') => {
    const quarters = showScopeTypeShare ? game.selectedScopeTypeQuarters : game.marketLevelQuarters
    switch (valueKey) {
      case 'revenue':
        return marketShareNumberType.id === MarketShareNumberTypeId.Percentage
          ? quarterService.getChangeDataForPreviousQuarterValue(QuarterDataKey.RevenuePercentage, selectedQuarter.quarterIdentifier, quarters) * 100
          : quarterService.getChangeDataForPreviousQuarterValue(QuarterDataKey.RevenueTop500Value, selectedQuarter.quarterIdentifier, quarters)

      case 'downloads':
        return marketShareNumberType.id === MarketShareNumberTypeId.Percentage
          ? quarterService.getChangeDataForPreviousQuarterValue(QuarterDataKey.DownloadsPercentage, selectedQuarter.quarterIdentifier, quarters) * 100
          : quarterService.getChangeDataForPreviousQuarterValue(QuarterDataKey.DownloadsTop500Value, selectedQuarter.quarterIdentifier, quarters)
    }
  }

  return (
    <div className="MarketShareTopGameCard">
      <Card>
        <Grid container spacing={1}>
          <Grid item>
            <div style={{ position: 'relative' }}>
              <Link to={`/game/${game.gameId}`}>
                <GameIcon gameName={game.name} src={game.images.length > 0 ? game.images[0] : ''} size="small"></GameIcon>
              </Link>
              {displayDominantGameInSubgenreIndicator(game) && (
                <GRTooltip content={t('market-share:game_has_large_subgenre_share')}>
                  <div className="MarketShareTopGameCard__DominantGameIndicator">
                    <Grade style={{ fontSize: '15px' }}></Grade>
                  </div>
                </GRTooltip>
              )}
            </div>
          </Grid>
          <Grid item xs>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <div className="MarketShareTopGameCard__Data">
                  {t('common:revenue_single_character')}: <strong>{getGameMarketShareForKey('revenue')}</strong>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="MarketShareTopGameCard__Data">
                  <TrendIndicator value={getGameMarketShareChange('revenue')} maximumFractionDigits={2} type={TrendIndicatorType.Value} />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={7}>
                <div className="MarketShareTopGameCard__Data">
                  {t('common:downloads_single_character')}: <strong>{getGameMarketShareForKey('downloads')}</strong>
                </div>
              </Grid>
              <Grid item xs={5}>
                <div className="MarketShareTopGameCard__Data">
                  <TrendIndicator value={getGameMarketShareChange('downloads')} maximumFractionDigits={2} type={TrendIndicatorType.Value} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default MarketShareTopGameCard
