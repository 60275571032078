/**
 * isJSON util function to check if input as string is valid JSON
 * @param input string
 * @returns whether input is valid JSON
 */
export const isJSON = (input: string): boolean => {
  try {
    JSON.parse(input)
  } catch (e) {
    return false
  }
  return true
}
