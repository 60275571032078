import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Box, Button, Card, CardContent, CircularProgress, Divider, FormControl, Grid, Link, MenuItem, Select, TableContainer, Typography } from '@mui/material'

import { AttributeModelNames, VisualAnalysis, VisualAnalysisModel } from '../../../api/connect'
import { useGetRanksByGenreQuery } from '../../../api/top-grossing'
import { ExportDataButton } from '../../../components/ExportDataButton/ExportDataButton'
import { GRTable, GRTableColumn, SortOrder } from '../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { TrendIndicator, TrendIndicatorType } from '../../../components/TrendIndicator/TrendIndicator'
import { intersection } from '../../../helpers/intersection'
import { useExportDataVisualExplorerOverview } from '../../../hooks/exportDataHooks'
import { RoleEnum } from '../../account'
import { generateExport } from '../../export-data/util/workbook'
import { useCurrentMarket } from '../../markets'
import ColorSquare from '../../visuals/components/ColorSquare'
import { RankType } from '../../visuals/types'
import { extendedAttributeModels, formatPercentage, visualsTranslationMap } from '../../visuals/util/helpers'
import { IconStatsRow } from '../types'
import { getIconStats } from '../util/calculations'
import './VisualExplorerOverview.scss'

export const colorPercentageOptions = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9]
export const topAmountOptions = [50, 100, 200, 500]

interface VisualExplorerOverviewProps {
  attributes?: AttributeModelNames
  gameData?: VisualAnalysis[]
  genreId: string
  rankType: RankType
  onSetSelectedAttr: (attr?: IconStatsRow) => void
  setTop: (top: number) => void
  top: number
  attributeModels: VisualAnalysisModel[]
  setAttributeModels: (model: VisualAnalysisModel[]) => void
}

const VisualExplorerOverview: React.FC<VisualExplorerOverviewProps> = ({
  attributes,
  gameData,
  genreId,
  rankType,
  onSetSelectedAttr,
  setTop,
  top,
  attributeModels,
  setAttributeModels,
}) => {
  const { t } = useTranslation()
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const containerRef = useRef(null)
  const { data: rankData, isFetching: isRankFetching } = useGetRanksByGenreQuery({ marketIso, genreId })

  const defaultColumns: GRTableColumn<IconStatsRow, undefined>[] = useMemo(
    () => [
      {
        labelAccessor: t('visuals-explorer:icon_attribute_text'),
        accessor: ({ row }) => (
          <Link onClick={() => onSetSelectedAttr(row)}>
            {row.model.indexOf('color') < 0 ? (
              row.name
            ) : (
              <>
                <ColorSquare color={row.name} />
                {row.name}
              </>
            )}
          </Link>
        ),
        cellProps: { align: 'left', sx: { textTransform: 'capitalize' } },
        headerCellProps: { align: 'left', width: '25%' },
        sortable: true,
        sortAccessor: 'name',
      },
      {
        labelAccessor: () => (
          <>
            {t('common:top_' + top)}
            &nbsp;
            <GRTooltip content={t('visuals-explorer:top_x_prevalence', { rank: top })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </>
        ),
        accessor: ({ row }) => (row.top ? formatPercentage(row.top) : '-'),
        headerCellProps: { width: '15%' },
        sortAccessor: 'top',
        sortable: true,
        sortOrder: SortOrder.DESC,
      },
      {
        labelAccessor: () => (
          <>
            {t('common:outside_top_' + top)}
            &nbsp;
            <GRTooltip content={t('visuals-explorer:outside_top_x_prevalence', { rank: top })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </>
        ),
        accessor: ({ row }) => (row.out ? formatPercentage(row.out) : '-'),
        headerCellProps: { width: '15%' },
        sortAccessor: 'out',
        sortable: true,
      },
      {
        labelAccessor: () => (
          <>
            {t('common:difference')}
            &nbsp;
            <GRTooltip content={t('visuals-explorer:prevalence_difference', { rank: top })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </>
        ),
        accessor: ({ row }) => (!row.out || !row.top ? '-' : <TrendIndicator type={TrendIndicatorType.Value} direction={true} value={row.diff || 0} />),
        headerCellProps: { width: '15%' },
        sortable: true,
        sortAccessor: 'diff',
      },
      {
        labelAccessor: () => (
          <>
            {t('common:average_rank')}
            &nbsp;
            <GRTooltip content={t('visuals-explorer:visual_average_rank_description', { rank: top })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </>
        ),
        accessor: ({ row }) => row.averageRank || '-',
        headerCellProps: { width: '15%' },
        sortable: true,
        sortAccessor: 'averageRank',
        defaultSortOrder: SortOrder.ASC,
      },
      {
        labelAccessor: () => (
          <>
            {t('common:median_rank')}
            &nbsp;
            <GRTooltip content={t('visuals-explorer:visual_median_rank_description', { rank: top })}>
              <Typography color="primary" display="inline" variant="subtitle2">
                ?
              </Typography>
            </GRTooltip>
          </>
        ),
        accessor: ({ row }) => row.medianRank || '-',
        headerCellProps: { width: '15%' },
        sortable: true,
        sortAccessor: 'medianRank',
        defaultSortOrder: SortOrder.ASC,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [top]
  )
  const [colorAmount, setColorAmount] = useState(0.2)
  const [columns, setColumns] = useState(defaultColumns)

  useEffect(() => {
    setColumns(defaultColumns)
  }, [defaultColumns])

  const ranks = rankData ? rankData[rankType === 'free' ? 'sdranks' : 'sranks'] : undefined
  const rows: IconStatsRow[] = getIconStats(attributeModels, top, gameData, ranks, attributes, colorAmount)

  const handleToggleModel = (model: VisualAnalysisModel) => {
    if (attributeModels.includes(model)) {
      setAttributeModels(attributeModels.filter((name) => model !== name))
    } else {
      setAttributeModels([...attributeModels, model])
    }
  }

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)
  const { exportRows } = useExportDataVisualExplorerOverview(attributeModels, gameData, ranks, attributes, isExporting)

  useEffect(() => {
    if (!isExporting || !exportRows.length) return
    setIsExporting(false)
    generateExport(exportFormat, exportRows, 'Icon stats', 'visual-explorer-icon-stats')
  }, [exportRows, exportFormat, isExporting])

  const handleExportData = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  return (
    <Box className="VisualExplorerOverview">
      <Grid container>
        <Grid item sm={10}>
          <Typography variant="h2" mb={0.5}>
            {t('visuals-explorer:icon_visuals_title')}
          </Typography>
          <Typography mb={2}>{t('visuals-explorer:icon_stats_tab_description')}</Typography>
        </Grid>
        <Grid item sm={2} textAlign="right">
          <ExportDataButton
            onChooseFormat={handleExportData}
            disabled={!attributeModels || !attributeModels.length}
            accessRoles={[RoleEnum.csv_visual_explorer]}
            analyticsEventOrigin="Visual Explorer Overview"
          />
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <Grid container justifyContent="space-between" className="attributeGroups" alignItems="center">
            <Grid item xs={6}>
              <Typography display="inline" variant="body2">
                {t('visuals-explorer:icon_stats_attributes_help_text')}
              </Typography>
              {extendedAttributeModels.map((model) => (
                <Button
                  color="primary"
                  key={model}
                  sx={{ ml: 0.5 }}
                  onClick={() => handleToggleModel(model)}
                  size="small"
                  variant={attributeModels.includes(model) ? 'contained' : 'outlined'}
                >
                  {t(visualsTranslationMap[model])}
                </Button>
              ))}
            </Grid>
            <Grid item xs={6} textAlign="right">
              {intersection(attributeModels, ['color', 'colorGroups']).length ? (
                <FormControl size="small" variant="outlined">
                  <Select onChange={({ target }) => setColorAmount(target.value as number)} value={colorAmount}>
                    {colorPercentageOptions.map((colorValue) => (
                      <MenuItem value={colorValue} key={'color-' + colorValue}>
                        {t('visuals-explorer:color_amount_percentage', { percentage: colorValue * 100 })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
              <FormControl size="small" variant="outlined" sx={{ verticalAlign: 'middle', ml: 1 }}>
                <Select onChange={({ target }) => setTop(target.value as number)} value={top} autoWidth>
                  {topAmountOptions.map((value) => (
                    <MenuItem value={value} key={'top-select-' + value}>
                      {t('common:top_' + value)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          {isRankFetching ? (
            <Box sx={{ my: 4, textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Box} ref={containerRef}>
              <GRTable
                columns={columns}
                rows={rows}
                onColumnsUpdated={setColumns}
                rowIdKey="name"
                scroller={containerRef}
                noRowsLabel={t('visuals-explorer:icon_stats_visual_model_select_help_text')}
              />
            </TableContainer>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

export default VisualExplorerOverview
