import { Chart, ChartTypeRegistry, Plugin } from 'chart.js'

export type VerticalTooltipLineOptions = {
  color: string
  lineWidth: number
  lineDash?: [number, number]
}

const DEFAULT_OPTIONS = {
  color: '0, 0, 0',
  lineWidth: 1,
}

/**
 * Draws a vertical line in tooltips current position
 */
export default class VerticalTooltipLinePlugin implements Plugin<keyof ChartTypeRegistry, VerticalTooltipLineOptions> {
  id = 'verticalTooltipLine'

  afterDatasetDraw = (chart: Chart): any => {
    const pluginOptions = chart.options.plugins as unknown as { verticalTooltipLine: VerticalTooltipLineOptions | undefined }
    const verticalTooltipLineOptions = pluginOptions.verticalTooltipLine
    const startY = chart.chartArea.top
    const endY = chart.chartArea.top + chart.chartArea.height

    if (chart && chart.tooltip && chart.tooltip.opacity > 0 && verticalTooltipLineOptions) {
      const options = { ...DEFAULT_OPTIONS, ...verticalTooltipLineOptions }
      const x = chart.tooltip.caretX
      chart.ctx.save()
      chart.ctx.beginPath()
      chart.ctx.moveTo(x, startY)
      chart.ctx.lineTo(x, endY)
      chart.ctx.lineWidth = options.lineWidth
      if (options.lineDash) {
        chart.ctx.setLineDash(options.lineDash)
      }
      chart.ctx.strokeStyle = `rgba(${options.color}, ${chart.tooltip.opacity})`
      chart.ctx.stroke()
      chart.ctx.restore()
    }
  }
}
