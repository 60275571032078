import { average } from '../../../helpers/average'

const makeWindow =
  (before: number, after: number) =>
  (value: number, index: number, array: number[]): number[] => {
    const start = Math.max(0, index - before)
    const end = Math.min(array.length, index + after + 1)
    return array.slice(start, end)
  }

export const movingAverage = (before: number, after: number, numbers: number[]): number[] | undefined => {
  return !numbers ? undefined : numbers.map(makeWindow(before, after)).map(average)
}
