import { addYears } from 'date-fns'
import { FC, useEffect } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid, Checkbox, FormControlLabel, debounce } from '@mui/material'

import DateRangePicker from '../../../../../components/DateRangePicker/DateRangePicker'
import { InputTranslator, translationLanguagesToShow } from '../../../../components/InputTranslator/InputTranslator'
import { InputTranslatorType } from '../../../../types/InputTranslatorType'
import { InternalAnalystComment } from '../../../../types/InternalAnalystComment'
import { AnalystsSelectMenu } from '../AnalystsSelectMenu/AnalystsSelectMenu'

type AnalystNoteEditBodyProps = {
  onChange: (values: InternalAnalystComment) => void
  form: UseFormReturn<InternalAnalystComment, any, undefined>
}

export const AnalystNoteEditBody: FC<AnalystNoteEditBodyProps> = ({ form, onChange }) => {
  const { t } = useTranslation()
  const { handleSubmit, watch, control } = form

  // submit the form everytime it changes
  useEffect(() => {
    const onSubmit = debounce((data: any) => {
      onChange(data)
    }, 300)
    const subscription = watch(() => handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [handleSubmit, onChange, watch])

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Controller
            name="comment.content.title"
            control={control}
            render={({ field }) => (
              <InputTranslator
                value={field.value}
                onChange={field.onChange}
                inputType={InputTranslatorType.Text}
                dialogTitle={t('internal-live-events:title')}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="comment.analystId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return <AnalystsSelectMenu required value={field.value || ''} useAll={false} onChange={field.onChange} autoWidth={false} />
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="comment.content.comment"
            control={control}
            render={({ field }) => (
              <InputTranslator
                value={field.value}
                onChange={field.onChange}
                inputType={InputTranslatorType.WYSIWYG}
                dialogTitle={t('internal-live-events:comment')}
                selectedLanguages={translationLanguagesToShow}
              />
            )}
          />
        </Grid>

        <Grid item>
          <label>{t('internal-live-events:date_range')}</label>
        </Grid>
        <Grid item sx={{ paddingTop: '2px  !important' }}>
          <Controller
            name="dateRange"
            control={control}
            render={({ field }) => {
              return <DateRangePicker value={field.value} maxDate={addYears(Date.now(), 10)} onChangeValue={field.onChange} />
            }}
          />
        </Grid>

        <Grid item>
          <Controller
            name="active"
            control={control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={<Checkbox size="small" checked={field.value} onChange={field.onChange} />}
                  label={t('internal-live-events:active')}
                />
              )
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}
