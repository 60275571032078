import { PayloadAction } from '@reduxjs/toolkit'

const mainStoreIdReducer = (state = null, action: PayloadAction<string>) => {
  switch (action.type) {
    case 'MAIN_STORE_ID_CHANGED':
      return action.payload

    default:
      return state
  }
}
export default mainStoreIdReducer
