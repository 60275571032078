import { ChangeEvent, FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material'

import GameSubgenrePicker from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { useError } from '../../../../hooks/useError'
import { useSubgenrePicker } from '../../../../hooks/useSubgenrePicker'
import utilsService from '../../../../services/UtilsService'
import { SubgenreMap } from '../../../account'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { FeatureSelectDialog, FeatureSelectDialogResult } from '../../../feature/components/FeatureSelectDialog/FeatureSelectDialog'
import { FeatureSelectionMode } from '../../../feature/components/FeatureSelectDialog/FeatureSelectionMode'
import { Game } from '../../../game'
import GameSearchDialog from '../../../game-search/components/GameSearchDialog/GameSearchDialog'
import { GameSearchDialogTab, GameSearchDialogType } from '../../../game-search/types/GameSearchDialogConfig'
import { useContainsOwnGamesCheck, useGames } from '../../../game/hooks/gameHooks'
import { displaySnackBar } from '../../../snackbar'
import { useUpdateDefaultSegment } from '../../hooks/defaultSegmentHooks'
import { useMarketExplorerSegmentFilterGroups, useMotivationFilterConfig } from '../../hooks/marketExplorerHooks'
import {
  DemographicsFilterField,
  MarketExplorerSegmentConfiguration,
  MarketExplorerSegmentFilterValue,
  PlayerArchetypesFilterField,
  RanksFilterField,
} from '../../types/MarketExplorerSegmentConfiguration'
import { MotivationType } from '../../types/MotivationType'
import { SegmentQueryDataType } from '../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { MarketExplorerFeatureChoiceItem } from '../MarketExplorerSegments/MarketExplorerSegmentCard/MarketExplorerFeatureChoiceItem/MarketExplorerFeatureChoiceItem'
import { OptionalSegmentFilterCard } from './OptionalSegmentFilterCard/OptionalSegmentFilterCard'
import { SegmentFilterGroup } from './SegmentFilterGroup/SegmentFilterGroup'
import { SegmentGamesIconGrid } from './SegmentGamesIconGrid/SegmentGamesIconGrid'
import { SegmentMarketSelector } from './SegmentMarketSelector/SegmentMarketSelector'
import { SegmentSection } from './SegmentSection/SegmentSection'

type MarketExplorerSegmentEditDialogProps = {
  open: boolean
  title: ReactNode
  initialSegment: MarketExplorerSegmentConfiguration
  onClose: (segment?: MarketExplorerSegmentConfiguration) => void
  segmentQueryData?: SegmentQueryDataType
  isLoading?: boolean
  children?: ReactNode
  useRawResults?: boolean // if true, it will ignores filtering search results
}

export const MarketExplorerSegmentEditDialog: FC<MarketExplorerSegmentEditDialogProps> = ({
  open,
  title,
  initialSegment,
  onClose,
  segmentQueryData,
  isLoading,
  children,
  useRawResults,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [segment, setSegment] = useState(initialSegment)
  const { subgenres, handleSubgenresChange } = useSubgenrePicker(initialSegment.subgenres)
  const motivationFilterConfig = useMotivationFilterConfig()
  const hasMotivationsAccess = useRoleCheck(RoleEnum.motivations)
  const demographicsFiltersEnabled = segment.marketIso ? utilsService.demographicsIsAvailableForMarket(segment.marketIso) : false
  const motivationsFiltersEnabled = segment.marketIso ? utilsService.motivationsIsAvailableForMarket(segment.marketIso) : false
  const playerArchetypesFiltersEnabled = motivationsFiltersEnabled
  const [gameSelectDialogOpen, setGameSelectDialogOpen] = useState<boolean>(false)
  const [selectedGames, setSelectedGames] = useState<Game[]>(segment.gameIds && segment.gameIds.length > 0 ? segmentQueryData?.data?.games || [] : [])
  const { marketExplorerSegmentRanksFilterGroups, marketExplorerSegmentDemographicsFilterGroups, marketExplorerSegmentPlayerArchetypesFilterGroups } =
    useMarketExplorerSegmentFilterGroups()
  const selectedGamesContainOwnGames = useContainsOwnGamesCheck(selectedGames || [])
  const { updateDefaultSegment, ...updateDefaultSegmentMutationQuery } = useUpdateDefaultSegment()
  const [featureSelectDialogOpen, setFeatureSelectDialogOpen] = useState<boolean>(false)

  // display success / fail snackbar when default segment setting is saved
  useEffect(() => {
    if (updateDefaultSegmentMutationQuery.isSuccess) {
      dispatch(displaySnackBar({ message: t('market-explorer:setting_saved'), severity: 'success', open: true }))
    }
  }, [dispatch, t, updateDefaultSegmentMutationQuery.isSuccess])
  useError({ error: updateDefaultSegmentMutationQuery.error })

  // fetch the games for the given segment if they're not passed along with segmentQueryData
  const { games } = useGames(segment.gameIds && segment.gameIds.length > 0 && !segmentQueryData ? segment.gameIds : [], segment.marketIso)
  useEffect(() => {
    if (segment.gameIds && segment.gameIds.length > 0 && !segmentQueryData && games?.length) {
      setSelectedGames(games)
    }
  }, [games, segment.gameIds, segmentQueryData])

  const handleFeatureSelectionChange = useCallback((selectedFeatures: FeatureSelectDialogResult) => {
    setSegment((currentSegment) => ({ ...currentSegment, featureChoices: selectedFeatures.featureChoices }))
  }, [])

  const handleMarketChange = useCallback((marketIso: string) => {
    setSegment((currentSegment) => ({
      ...currentSegment,
      marketIso,
    }))
  }, [])

  const handleRanksFilterChange = useCallback((value: MarketExplorerSegmentFilterValue, filterId: RanksFilterField) => {
    setSegment((currentSegment) => {
      return {
        ...currentSegment,
        filters: {
          ...currentSegment.filters,
          ranks: {
            ...currentSegment.filters?.ranks,
            [filterId]: value,
          },
        },
      }
    })
  }, [])

  const handleDemographicsFilterChange = useCallback((value: MarketExplorerSegmentFilterValue, filterId: DemographicsFilterField) => {
    setSegment((currentSegment) => {
      return {
        ...currentSegment,
        filters: {
          ...currentSegment.filters,
          demographics: {
            ...currentSegment.filters?.demographics,
            [filterId]: value,
          },
        },
      }
    })
  }, [])

  const handleMotivationFilterChange = useCallback((value: MarketExplorerSegmentFilterValue, filterId: MotivationType) => {
    setSegment((currentSegment) => {
      return {
        ...currentSegment,
        filters: {
          ...currentSegment.filters,
          motivations: {
            ...currentSegment.filters?.motivations,
            [filterId]: value,
          },
        },
      }
    })
  }, [])

  const handlePlayerArchetypeFilterChange = useCallback((value: MarketExplorerSegmentFilterValue, filterId: PlayerArchetypesFilterField) => {
    setSegment((currentSegment) => {
      return {
        ...currentSegment,
        filters: {
          ...currentSegment.filters,
          archetypes: {
            ...currentSegment.filters?.archetypes,
            [filterId]: value,
          },
        },
      }
    })
  }, [])

  const handleGameSelectDialogClose = useCallback(
    (selectedGames?: Game[]) => {
      setSegment((currentSegment) => ({
        ...currentSegment,
        gameIds: selectedGames?.map((game) => game.id),
      }))
      setSelectedGames(selectedGames || [])

      const message = (
        <Typography component="span">
          <Trans i18nKey={'select-game:selected_games'} />
          <ul style={{ paddingInlineStart: '20px' }}>{selectedGames && selectedGames.map((game, i) => <li key={i}>{game.resolvedName}</li>)}</ul>
        </Typography>
      )
      selectedGames && selectedGames.length && dispatch(displaySnackBar({ message, severity: 'success', open: true }))
      setGameSelectDialogOpen(false)
    },
    [dispatch]
  )

  const handleClearSelectedGames = useCallback(() => {
    setSegment((currentSegment) => ({
      ...currentSegment,
      gameIds: [],
    }))
    setSelectedGames([])
  }, [])

  const handleSubgenreSelectionChange = useCallback(
    (selectedSubgenres: SubgenreMap) => {
      handleSubgenresChange(selectedSubgenres)
      // clear selected games whenever subgenre selection changes
      setSegment((currentSegment) => ({
        ...currentSegment,
        gameIds: [],
      }))
      setSelectedGames([])
    },
    [handleSubgenresChange]
  )

  const handleOwnGamesChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSegment((currentSegment) => ({
      ...currentSegment,
      ownGames: event.target.checked,
    }))
  }, [])

  const handleSetAsDefault = useCallback(
    (segment?: MarketExplorerSegmentConfiguration) => {
      updateDefaultSegment(segment)
    },
    [updateDefaultSegment]
  )

  const gameSearchResultFilter = useCallback(
    (games: Game[]) => {
      return games.filter((game) => game.sranks[segment.marketIso] <= 1000 || game.sdranks[segment.marketIso] <= 1000)
    },
    [segment.marketIso]
  )

  // update subgenres
  useEffect(() => {
    setSegment((currentSegment) => ({
      ...currentSegment,
      subgenres: subgenres,
    }))
  }, [subgenres])

  // update own games flag
  useEffect(() => {
    setSegment((currentSegment) => ({
      ...currentSegment,
      ownGames: currentSegment.ownGames || selectedGamesContainOwnGames,
    }))
  }, [selectedGamesContainOwnGames])

  // update selected games form segment query data when segment data is loaded
  useEffect(() => {
    if (segment.gameIds && segment.gameIds.length > 0 && segmentQueryData) {
      setSelectedGames(segmentQueryData?.data?.games || [])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentQueryData?.data?.games])

  const featureChoicesAmount = Object.keys(segment.featureChoices || {}).length

  return (
    <>
      <Dialog open={open} onClose={() => onClose()} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Grid container alignContent="center" justifyContent="space-between">
            {title}
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <SegmentSection title={t('segment:segment_create_phase1_topic')}>
            <SegmentMarketSelector selectedMarketIso={segment.marketIso} onChange={handleMarketChange} />
          </SegmentSection>
          <SegmentSection title={t('segment:segment_create_phase4_topic')}>
            <GameSubgenrePicker selectedSubgenres={subgenres} selectionChanged={handleSubgenreSelectionChange} showSubgenreDefinitionBtn hideSetAsDefault />
          </SegmentSection>
          <SegmentSection title={t('segment:segment_create_phase2_topic')} subtitle={t('segment:segment_phase_select_one')}>
            <Grid container columns={13} wrap="wrap" rowSpacing={2}>
              <Grid item md={6} xs={13}>
                <OptionalSegmentFilterCard
                  title={t('segment:filter_by_games_title')}
                  description={t('segment:filter_by_games_description')}
                  selectButtonLabel={(selectedGames?.length || 0) > 0 ? t('segment:filter_edit_selected_games') : t('segment:filter_select_games')}
                  onSelectClick={() => setGameSelectDialogOpen(true)}
                  clearButtonLabel={t('select-game:clear_selected_games')}
                  onClear={handleClearSelectedGames}
                  selectionCount={selectedGames?.length}
                  disabled={featureChoicesAmount > 0}
                  disabledTooltip={t('segment:filter_clear_selected_features')}
                  isLoading={isLoading}
                >
                  {selectedGames && selectedGames.length > 0 && <SegmentGamesIconGrid games={selectedGames} />}
                </OptionalSegmentFilterCard>
              </Grid>
              <Grid item md={1} xs={13} alignSelf="center" sx={{ textAlign: 'center' }}>
                {t('segment:or_text')}
              </Grid>
              <Grid item md={6} xs={13}>
                <OptionalSegmentFilterCard
                  title={t('segment:filter_by_features_title')}
                  description={t('segment:filter_by_features_description')}
                  selectButtonLabel={featureChoicesAmount > 0 ? t('segment:filter_edit_selected_features') : t('segment:filter_select_features')}
                  onSelectClick={() => setFeatureSelectDialogOpen(true)}
                  clearButtonLabel={t('feature-select-modal:clear_selected_features')}
                  onClear={() => setSegment(({ featureChoices, ...currentSegment }) => currentSegment)}
                  selectionCount={featureChoicesAmount}
                  disabled={(selectedGames?.length || 0) > 0}
                  disabledTooltip={t('segment:filter_clear_selected_games')}
                  isLoading={isLoading}
                >
                  {Object.entries(segment.featureChoices || {}).length > 0 && (
                    <List disablePadding>
                      {Object.entries(segment.featureChoices || {}).map(([featureId, choiceIds], index, array) => {
                        const isFirst = index === 0
                        const isLast = index === array.length - 1
                        return (
                          <ListItem key={featureId} divider={isLast ? false : true} disableGutters disablePadding sx={isFirst ? { pb: 2 } : { py: 2 }}>
                            <MarketExplorerFeatureChoiceItem featureLegacyId={+featureId} featureChoiceLegacyIds={choiceIds} />
                          </ListItem>
                        )
                      })}
                    </List>
                  )}
                </OptionalSegmentFilterCard>

                <FeatureSelectDialog
                  selectedFeatures={segment.featureChoices || {}}
                  concepts={false}
                  open={featureSelectDialogOpen}
                  onClose={() => setFeatureSelectDialogOpen(false)}
                  onConfirm={handleFeatureSelectionChange}
                  selectionMode={FeatureSelectionMode.Choices}
                  marketIso={segment.marketIso}
                  includeHiddenScreenshots
                />
              </Grid>
            </Grid>
          </SegmentSection>
          <SegmentSection title={t('segment:segment_create_phase5_topic')} collapsible>
            <SegmentFilterGroups title={t('segment:segment_filter_group_ranking')}>
              {marketExplorerSegmentRanksFilterGroups.map((filterGroup, index) => {
                return (
                  <Grid item md={4} sm={6} xs={12} key={index}>
                    <SegmentFilterGroup group={filterGroup} onFilterChange={handleRanksFilterChange} values={segment.filters.ranks} hideGroupLabel />
                  </Grid>
                )
              })}
            </SegmentFilterGroups>
            <SegmentFilterGroups title={t('segment:segment_filter_group_demographics')}>
              {marketExplorerSegmentDemographicsFilterGroups.map((filterGroup, index) => {
                return (
                  <Grid item md={4} sm={6} xs={12} key={index}>
                    <SegmentFilterGroup
                      group={filterGroup}
                      onFilterChange={handleDemographicsFilterChange}
                      values={segment.filters.demographics}
                      disabled={!demographicsFiltersEnabled}
                    />
                  </Grid>
                )
              })}
            </SegmentFilterGroups>
            <SegmentFilterGroups title={t('segment:segment_filter_group_motivations')}>
              {motivationFilterConfig.map((filterGroup, index) => {
                return (
                  <Grid item md={4} sm={6} xs={12} key={index}>
                    <SegmentFilterGroup
                      group={filterGroup}
                      onFilterChange={handleMotivationFilterChange}
                      values={segment.filters.motivations}
                      disabled={!motivationsFiltersEnabled}
                      locked={!hasMotivationsAccess}
                    />
                  </Grid>
                )
              })}
            </SegmentFilterGroups>
            <SegmentFilterGroups title={t('segment:player_archetypes')}>
              {marketExplorerSegmentPlayerArchetypesFilterGroups.map((filterGroup, index) => {
                return (
                  <Grid item md={4} sm={6} xs={12} key={index}>
                    <SegmentFilterGroup
                      group={filterGroup}
                      onFilterChange={handlePlayerArchetypeFilterChange}
                      values={segment.filters.archetypes}
                      disabled={!playerArchetypesFiltersEnabled}
                      locked={!hasMotivationsAccess}
                      hideGroupLabel
                    />
                  </Grid>
                )
              })}
            </SegmentFilterGroups>
            <SegmentFilterGroups title={t('segment:my_portfolio_title')}>
              <Grid item md={4} sm={6} xs={12}>
                <Card>
                  <CardContent>
                    <FormControlLabel
                      control={<Checkbox checked={segment.ownGames ? true : false} onChange={handleOwnGamesChange} />}
                      label={t('common:show_games') as string}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </SegmentFilterGroups>
          </SegmentSection>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="secondary"
            loading={updateDefaultSegmentMutationQuery.isLoading}
            onClick={() => handleSetAsDefault(segment)}
          >
            <Trans i18nKey="common:set_as_default_button" />
          </LoadingButton>
          <Button variant="contained" color="success" onClick={() => onClose(segment)}>
            {t('segment:segment_apply_filters')}
          </Button>
        </DialogActions>
      </Dialog>
      <GameSearchDialog
        modalOpen={gameSelectDialogOpen}
        onClose={handleGameSelectDialogClose}
        gameSearchDialogConfig={{
          type: GameSearchDialogType.GameSelector,
          tabs: [GameSearchDialogTab.Search, GameSearchDialogTab.MyPortfolio],
          gameSelectorCounts: { min: 1, max: 99 },
          hideSubgenrePicker: Object.keys(segment.subgenres || {}).map((key) => key).length > 0,
        }}
        defaultSelectedSubgenres={segment.subgenres}
        defaultSelectedGames={selectedGames}
        marketIso={segment.marketIso}
        searchResultFilter={gameSearchResultFilter}
        useRawResults
        showTopGames
        hideSetAsDefault
        onlyGamesWithPowerscoreOrRank
      />
    </>
  )
}

type SegmentFilterGroupsProps = {
  title: string
  children?: ReactNode
}

const SegmentFilterGroups: FC<SegmentFilterGroupsProps> = ({ title, children }) => {
  return (
    <Grid container direction="column" spacing={2} mb={3}>
      <Grid item>
        <Typography variant="h4">{title}</Typography>
      </Grid>
      <Grid item container direction="row" wrap="wrap" spacing={2}>
        {children}
      </Grid>
    </Grid>
  )
}
