import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Box, Grid, Typography } from '@mui/material'

import { useGetUserSettingQuery } from '../../api/combined'
import { FtueVideo, useGetLatestFtueVideosQuery } from '../../api/core'
import GameSearchAndSort from '../../components/GameSearchAndSort/GameSearchAndSort'
import GameSubgenrePicker from '../../components/GameSubgenrePicker/GameSubgenrePicker'
import { LockedFeature } from '../../components/LockedFeature/LockedFeature'
import MultiGameSelector from '../../components/MultiGameSelector/MultiGameSelector'
import { SubgenreMap } from '../../features/account'
import { useFTUEVideosAccessCheck } from '../../features/account/hooks/roleHooks'
import { UserSettingKeys } from '../../features/account/types/UserSettings'
import FtueVideoPlaceholder from '../../features/ftue-videos/components/FtueVideoPlayer/FtueVideoPlaceholder'
import FtueVideoPlayer from '../../features/ftue-videos/components/FtueVideoPlayer/FtueVideoPlayer'
import { SelectedChapterTag } from '../../features/ftue-videos/components/GameChapterList/GameChapterList'
import { useGetFtueGames } from '../../features/ftue-videos/hooks/useGetFtueGames'
import { Game, useFeaturedGames } from '../../features/game'
import { ILockedAccessGame, LockedAccessGame } from '../../features/game/types/Game'
import { gameComparator } from '../../features/implementation-examples/util/comparators'
import { subgenreFilter } from '../../features/implementation-examples/util/filters'
import { useCurrentMarket } from '../../features/markets'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import { useSearchParams } from '../../hooks/useSearchParams'
import PageService from '../../services/PageService'
import { LockedFeatureId } from '../../types/LockedFeature'

const maxSelectedAmount = 2
export const userDefaultConventionalSubgenresKey = 'userDefaultConventionalSubgenres'

const keywordFilter = (keyword: string | undefined) => {
  const needle = keyword?.toLowerCase()
  return (game: Game) => {
    if (!needle?.length) {
      return true
    }
    const haystack = (game.artist + game.resolvedName).toLowerCase()
    return haystack.includes(needle)
  }
}

type FTUESearchParams = {
  subgenres?: string
}

const FtueVideosPage: React.FC = () => {
  const { t } = useTranslation()
  const hasAccessFTUEVideos = useFTUEVideosAccessCheck()
  const navigate = useNavigate()

  const { setSearchParams, parsedParams } = useSearchParams<FTUESearchParams, FTUESearchParams>()

  const { currentMarketIso } = useCurrentMarket()
  const [sortCriteria, setSortCriteria] = useState<string>('sustained_grossing_rank')
  const [keyword, setKeyword] = useState<string>()

  const mappedSearchParams = useMemo(() => {
    if (parsedParams && parsedParams.subgenres) {
      return parsedParams.subgenres
        .toString()
        .split(',')
        .reduce((result, subgenre) => {
          result[subgenre] = true
          return result
        }, {} as SubgenreMap)
    }
    return {}
  }, [parsedParams])

  const [subgenres, setSubgenres] = useState<SubgenreMap>(mappedSearchParams)
  const [selectedChapterTag, setSelectedChapterTag] = useState<SelectedChapterTag>({ chapterId: 0, tagId: '' })

  const { data: latestVideos, isFetching: isFetchingVideos } = useGetLatestFtueVideosQuery()
  const { games: featuredGames } = useFeaturedGames(currentMarketIso)
  const [selectedGames, setSelectedGames] = useState<Game[]>(hasAccessFTUEVideos ? [] : featuredGames)
  const { data: ftueGames, isFetching: isFetchingGames } = useGetFtueGames(latestVideos)

  const gameSorter = useMemo(() => gameComparator(currentMarketIso, sortCriteria), [sortCriteria, currentMarketIso])

  const games = useMemo<LockedAccessGame[]>(() => {
    if (!ftueGames) return []

    if (!!hasAccessFTUEVideos || !featuredGames) return [...ftueGames].sort(gameSorter)

    // promote featured games for non unsubscribed users first
    const featuredGameIds = featuredGames.map((featuredGame) => featuredGame.id)
    const filteredFtueGames = ftueGames.filter((ftueGame) => !featuredGameIds.includes(ftueGame.id))

    return [...featuredGames, ...filteredFtueGames].map((game) => {
      const lockedGame = game as ILockedAccessGame
      lockedGame.lockedAccess = !featuredGameIds?.includes(game.id)
      return game
    })
  }, [featuredGames, ftueGames, gameSorter, hasAccessFTUEVideos])

  usePage(PageService.getPageWithId('ftue-videos'), 'Visited FTUE Videos')
  useDocumentTitle(t('sidebar:ftue-videos'))

  const { data: savedSubgenresMap } = useGetUserSettingQuery<{ data: SubgenreMap }>(UserSettingKeys.defaultConventionalSubgenresKey)

  useEffect(() => {
    if (!!savedSubgenresMap && !!Object.keys(savedSubgenresMap).length && !parsedParams.subgenres) {
      setSubgenres(savedSubgenresMap)
    }
  }, [parsedParams.subgenres, savedSubgenresMap])

  useEffect(() => {
    if (selectedGames.length) {
      navigate({ pathname: '/ftue-videos/' + selectedGames.map((game) => game.id).join(',') }, { replace: true })
    }
  }, [navigate, selectedGames])

  useEffect(() => {
    if (!hasAccessFTUEVideos && !isFetchingGames && featuredGames) {
      // preselect first 'maxSelected' amount of featured games
      setSelectedGames(featuredGames.slice(0, maxSelectedAmount))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingGames])

  const handleUnselectGame = (index: number) => {
    setSelectedGames(selectedGames.filter((game, i) => index !== i))
  }

  const selectedVideos: (FtueVideo | undefined)[] = useMemo(
    () => (selectedGames ? selectedGames.map(({ id }) => latestVideos?.find(({ gameId }) => gameId === id)) : []),
    [selectedGames, latestVideos]
  )

  const handleChangeSearch = (value: Game | string) => {
    if (typeof value === 'string') {
      setKeyword(value)
      return
    }

    setKeyword(value.resolvedName)
  }

  const handleSelectionChange = (subgenres: SubgenreMap) => {
    setSubgenres(subgenres)
    if (!Object.keys(subgenres).length && !!savedSubgenresMap) {
      setSearchParams({ ...parsedParams, subgenres: undefined })
    }
  }
  const selectedGamesByVideoId = useCallback(
    (gameId: string) => {
      return selectedGames.find((game) => game.id === gameId)
    },
    [selectedGames]
  )

  if (!hasAccessFTUEVideos) {
    return (
      <>
        <Box sx={{ mb: 3 }}>
          <LockedFeature.Card lockedFeatureId={LockedFeatureId.FtueVideos} />
        </Box>
        <GameSubgenrePicker showSubgenreDefinitionBtn selectedSubgenres={subgenres} selectionChanged={handleSelectionChange} />
        <GameSearchAndSort
          options={games}
          sortValue={sortCriteria}
          onChangeSearch={handleChangeSearch}
          onChangeSort={setSortCriteria}
          disabledSort={!hasAccessFTUEVideos}
        />
        <MultiGameSelector
          maxSelected={maxSelectedAmount}
          setSelectedGames={setSelectedGames}
          selectedGames={selectedGames || []}
          onClear={() => {
            setSelectedGames([])
            setKeyword(undefined)
          }}
          options={games?.filter(subgenreFilter(subgenres)).filter(keywordFilter(keyword))}
          loading={isFetchingGames || isFetchingVideos}
          lockedFeatureId={LockedFeatureId.FtueVideos}
          sortCriteria={'locked_access'}
        />
        <Grid container wrap="wrap" columnSpacing={2} mt={2}>
          {selectedVideos.map((video, index) => (
            <Grid item xs={12} lg={6} mt={{ xs: 2 }} key={`ftue-${video?.id}`}>
              {video && !!selectedGamesByVideoId(video.gameId) ? (
                <FtueVideoPlayer
                  selectedChapterTag={selectedChapterTag}
                  onChapterTagClick={setSelectedChapterTag}
                  game={selectedGamesByVideoId(video.gameId) as Game}
                  video={video as FtueVideo}
                  onUnselectGame={() => handleUnselectGame(index)}
                  hideChapterList
                />
              ) : (
                <FtueVideoPlaceholder />
              )}
            </Grid>
          ))}
        </Grid>
      </>
    )
  }

  return (
    <>
      <Typography variant="h3" mb={1}>
        {t('select-game:conventionals_picker_title')}
      </Typography>
      <GameSubgenrePicker showSubgenreDefinitionBtn selectedSubgenres={subgenres} selectionChanged={handleSelectionChange} />
      <GameSearchAndSort options={games} sortValue={sortCriteria} onChangeSearch={handleChangeSearch} onChangeSort={setSortCriteria} />
      <MultiGameSelector
        maxSelected={maxSelectedAmount}
        setSelectedGames={setSelectedGames}
        selectedGames={selectedGames || []}
        onClear={() => {
          setSelectedGames([])
          setKeyword(undefined)
        }}
        options={games?.filter(subgenreFilter(subgenres)).filter(keywordFilter(keyword))}
        loading={isFetchingGames || isFetchingVideos}
        lockedFeatureId={LockedFeatureId.FtueVideos}
        sortCriteria={sortCriteria}
      />
      <Grid container wrap="wrap" columnSpacing={2} mt={2}>
        {selectedVideos.map((video, index) => (
          <Grid item xs={12} lg={6} mt={{ xs: 2 }} key={`ftue-${video?.id}`}>
            {video && !!selectedGamesByVideoId(video.gameId) ? (
              <FtueVideoPlayer
                selectedChapterTag={selectedChapterTag}
                onChapterTagClick={setSelectedChapterTag}
                game={selectedGamesByVideoId(video.gameId) as Game}
                video={video as FtueVideo}
                onUnselectGame={() => handleUnselectGame(index)}
                hideChapterList
              />
            ) : (
              <FtueVideoPlaceholder />
            )}
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default FtueVideosPage
