import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const ENV_CONFIG = require(`../config.${process.env.REACT_APP_ENVIRONMENT}.json`)
const KEYCLOAK_CONFIG = ENV_CONFIG.KEYCLOAK_CONFIG

type KeycloakTokenResponse = {
  access_token: string
  expires_in: number
  refresh_expires_in: number
  refresh_token: string
  scope: string
  session_state: string
  token_type: string
}

export const keycloakApi = createApi({
  reducerPath: 'keycloakApi',
  baseQuery: fetchBaseQuery({
    baseUrl: KEYCLOAK_CONFIG['auth-server-url'],
  }),
  endpoints: (builder) => ({
    getToken: builder.mutation<KeycloakTokenResponse, { username: string; password: string }>({
      query: ({ username, password }) => ({
        url: `realms/${KEYCLOAK_CONFIG.realm}/protocol/openid-connect/token`,
        method: 'POST',
        body: Object.entries({
          client_id: KEYCLOAK_CONFIG.clientId,
          username,
          password,
          grant_type: 'password',
        })
          .map(([key, value]) => `${key}=${value}`)
          .join('&'),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }),
    }),
  }),
})

export const { useGetTokenMutation } = keycloakApi
