import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { GlobalLaunchIndicator } from '../../../../components/GlobalLaunchIndicator/GlobalLaunchIndicator'
import VersionTagsList from '../../../../components/VersionTagsList/VersionTagsList'
import { VersionTag } from '../../../../types/VersionTag'
import { useDateTimeFormatter } from '../../../live-events/hooks/useDateTimeFormatter'
import styles from './VersionInfo.module.scss'

/**
 * Component for rendering the content for Version Date column
 */
type VersionInfoProps = {
  releaseDate: number
  version: string
  versionTags: VersionTag[]
  globalLaunchDate?: number
}

export const VersionInfo: FC<VersionInfoProps> = memo(({ releaseDate, version, versionTags, globalLaunchDate }) => {
  const { t } = useTranslation()
  const dateFormatter = useDateTimeFormatter({ month: 'short', day: 'numeric', year: 'numeric' })
  const isGlobalLaunchVersion = releaseDate === globalLaunchDate

  return (
    <>
      <Box mb={0.5}>{dateFormatter.format(releaseDate)}</Box>
      <Box mb={1} className={styles.version}>
        <Grid container alignItems="center" justifyContent="center" gap={0.5}>
          {isGlobalLaunchVersion && (
            <Grid item>
              <GRTooltip content={t('common:global_launch')}>
                <GlobalLaunchIndicator color="primary" sx={{ fontSize: '0.9rem' }} />
              </GRTooltip>
            </Grid>
          )}
          <Grid item>{version}</Grid>
        </Grid>
      </Box>
      <VersionTagsList versionTags={versionTags} size="small" condensed />
    </>
  )
})
