import { useMemo } from 'react'

import { Game, GameIcon } from '../../game'
import { MotivationKey } from '../../market-explorer/types/MotivationType'
import { MotivationArcheTypeList } from '../components/MotivationArcheTypeCard/MotivationArcheTypeCard'
import { ProfileMotivation } from '../types/ProfileMotivation'
import { isWesternMarket } from './useMarketMotivationsValidation'

interface MotivationArcheTypeHookProps {
  gameLists?: Game[]
  gamesWithMarketIso?: { [key: string]: string[] }
  motivationKeys: MotivationKey[]
  motivationProfiles?: ProfileMotivation[]
}
export const useGamesArcheTypes = ({ gameLists, gamesWithMarketIso, motivationKeys, motivationProfiles }: MotivationArcheTypeHookProps) => {
  const gamesArcheTypes = useMemo<MotivationArcheTypeList>(() => {
    const archeTypes: MotivationArcheTypeList = {} as MotivationArcheTypeList
    motivationKeys.forEach((motivationKey) => {
      if (!archeTypes[motivationKey]) {
        archeTypes[motivationKey] = []
      }
      if (!!motivationProfiles && !!gameLists) {
        archeTypes[motivationKey] = motivationProfiles
          .filter((motivation, index) => !!gameLists[index])
          .map((motivation, index) => ({
            id: motivation.id!,
            sortValue: motivation.motivationSegmentValues?.[motivationKey] !== undefined ? motivation.motivationSegmentValues[motivationKey] : 0,
            value:
              motivation.motivationSegmentValues?.[motivationKey] !== undefined
                ? Math.round((motivation.motivationSegmentValues[motivationKey] as number) * 100) + '%'
                : '-',
            component: (
              <>
                {gameLists && (
                  <GameIcon
                    size={motivationProfiles.length <= 2 ? 'normal' : motivationProfiles.length <= 4 ? 'small' : 'tiny'}
                    gameName={gameLists[index].resolvedName}
                    src={gameLists[index].icons || gameLists[index].icon}
                  />
                )}
              </>
            ),
          }))

          // filter games out that are not western games
          .filter((motivation, index) => {
            const game = gameLists[index]
            const marketIso = gamesWithMarketIso?.[game.id]?.[0]
            const isWesternGame = isWesternMarket(marketIso || '')
            return isWesternGame
          })
          .sort((a, b) => {
            return b.sortValue - a.sortValue
          })
      }
    })
    return archeTypes
  }, [gameLists, gamesWithMarketIso, motivationKeys, motivationProfiles])
  return gamesArcheTypes
}
