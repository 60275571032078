import { t } from 'i18next'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import { InfoOutlined } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, Divider, MenuItem, Select, Typography } from '@mui/material'

import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { SubgenreMap } from '../../../account'
import { TopGame } from '../../../top-game/types/TopGame'
import { selectAllSubgenreOptionKey, useMustKnowGamesHooks } from '../../hooks/MustKnowGamesHooks'
import { useGetSubGenres } from '../../hooks/genreEssentialsHooks'
import MustKnowGameRows from '../MustKnowGameRows/MustKnowGameRows'

/**
 * MustKnowGamesCard
 */
interface MustKnowGamesCardProps {
  topGames: TopGame[]
  selectedSubgenres: SubgenreMap
  conventionalGenreId?: string
}
const MustKnowGamesCard: React.FC<MustKnowGamesCardProps> = ({ topGames, selectedSubgenres, conventionalGenreId }) => {
  const [selectedSubgenreOption, setSelectedSubgenreOption] = useState(selectAllSubgenreOptionKey)
  const containerRef = useRef<null | HTMLDivElement>(null)

  const { data: mustKnowGames, isLoading } = useMustKnowGamesHooks(topGames, selectedSubgenres, selectedSubgenreOption, conventionalGenreId || '')
  const { subGenres } = useGetSubGenres(selectedSubgenres)

  const filteredMustKnowGames = useMemo(() => Object.values(mustKnowGames).filter((mustKnowGame) => !!mustKnowGame.games.length), [mustKnowGames])

  // reset selectedSubgenreOption to default value when change genre ID
  useEffect(() => {
    if (selectedSubgenreOption === selectAllSubgenreOptionKey) return

    const selectedSubgenre = subGenres?.find((subGenre) => subGenre.id === selectedSubgenreOption)
    if (!selectedSubgenre) {
      setSelectedSubgenreOption(selectAllSubgenreOptionKey)
    }
  }, [conventionalGenreId, selectedSubgenreOption, subGenres])

  useEffect(() => {
    if (!!Object.keys(selectedSubgenres).length || !!conventionalGenreId) {
      if (containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [conventionalGenreId, selectedSubgenres])

  if (!Object.keys(selectedSubgenres).length || !conventionalGenreId) {
    return null
  }

  return (
    <div ref={containerRef} style={{ scrollMarginTop: '106px' }}>
      <Card>
        <CardHeader
          title={
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h3">{t('genre-essentials:must_to_know_games_title')}</Typography>
              {!isLoading && (
                <Select
                  size="small"
                  value={selectedSubgenreOption}
                  defaultValue={selectAllSubgenreOptionKey}
                  onChange={({ target }) => setSelectedSubgenreOption(target.value)}
                >
                  <MenuItem value={selectAllSubgenreOptionKey}>{t('common:all_subgenres')}</MenuItem>
                  {subGenres?.map((subGenre) => (
                    <MenuItem key={subGenre.id} value={subGenre.id}>
                      {subGenre.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
          }
          subheader={<span>{t('genre-essentials:must_to_know_games_description')}</span>}
        ></CardHeader>
        <Divider />
        <CardContent>
          {isLoading ? (
            <GRCircularProgress />
          ) : (
            filteredMustKnowGames.map((mustKnowGame, index) => (
              <Fragment key={mustKnowGame.id}>
                <MustKnowGameRows
                  title={
                    <GRTooltip content={mustKnowGame.tooltip}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: '1em' }}>{mustKnowGame.title}</Typography>
                        {mustKnowGame.tooltip && <InfoOutlined sx={{ ml: 0.5 }} fontSize="small" />}
                      </Box>
                    </GRTooltip>
                  }
                  games={mustKnowGame.games}
                  isLoading={mustKnowGame.isLoading}
                />

                {index < filteredMustKnowGames.length - 1 && <Divider sx={{ my: 2 }} />}
              </Fragment>
            ))
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default MustKnowGamesCard
