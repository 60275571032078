import querystring from 'query-string'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { Error } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'

import { SimpleFeature, useGetFeatureByConventionalSubgenreQuery } from '../../api/core'
import { displaySnackBar } from '../../features/snackbar'
import { useCurrentStore } from '../../features/store'
import { useAppDispatch } from '../../hooks/storeHooks'
import { GREffectDots } from '../GREffectDots/GREffectDots'
import { HtmlContentParser } from '../HtmlContentParser/HtmlContentParser'

/**
 * TODO-NOW-KALLE: Ei taida toimia, kannattaa korjata jotta vanhassakin paikassa toimis. Mahd. Access-error? (English: This might not work, should be fixed so that the this works on the old place. Possible Access-error?)
 *
 * @param feature
 * @constructor
 */
const ScreenshotFeatureDots: React.FC<{ feature: SimpleFeature }> = ({ feature }) => {
  const location = useLocation()
  const { currentStoreId } = useCurrentStore()

  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const shotModalIso = querystring.parse(location.search).shotModalIso

  const { data, isLoading, isFetching, error } = useGetFeatureByConventionalSubgenreQuery(
    {
      id: feature.choiceLegacyId as number,
      genre: 0,
      marketIso: shotModalIso && shotModalIso !== 'undefined' ? shotModalIso : 'us',
      store: currentStoreId as string,
      include: ['avgEffect', 'minEffect', 'maxEffect'].join(','),
    },
    { skip: !currentStoreId }
  )

  useEffect(() => {
    if (error) {
      dispatch(
        displaySnackBar({
          message: <HtmlContentParser rawHtml={`${t('feature:error_could_not_load_top_feature', { featureName: feature.featureName })}`} />,
          severity: 'error',
          open: true,
        })
      )
    }
  }, [dispatch, feature, t, error])

  if (isLoading || isFetching) {
    return <CircularProgress color="primary" size={12} />
  } else if (error) {
    return <Error />
  } else if (data) {
    return <GREffectDots effect={data.avgEffect} />
  }
  return null
}

export default ScreenshotFeatureDots
