import { t } from 'i18next'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Card, CardContent } from '@mui/material'

import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import ScreenshotPreview from '../../../implementation-examples/components/ScreenshotPreviews/ScreenshotPreview'
import { buildImplementationSearchUrl } from '../../../implementation-examples/util/buildImplementationSearchUrl'
import { createScreenshotModalPath } from '../../../news/components/ScreenshotsModalContainer/ScreenshotsModalContainer'
import { TopGame } from '../../../top-game/types/TopGame'
import { useGetFeatureWithScreenshotGames } from '../../hooks/LatestImplementationHooks'
import { filterGameIds } from '../../utils/filterGameIds'
import GenreEssentialCardHeader, { GenreEssentialSection } from '../GenreEssentialCardHeader/GenreEssentialCardHeader'

const maxScreenshotCount = 6

interface LatestImplementationCardProps {
  topGames: TopGame[]
  conventionalGenreId?: string
}
const LatestImplementationCard: React.FC<LatestImplementationCardProps> = ({ topGames, conventionalGenreId }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const filteredGameIds = filterGameIds(topGames, 0, conventionalGenreId ? topGames.length : 50)

  const { newsEntriesFeatureByGameIds, screenshotsUniqueByGameId, featureGames, isLoading } = useGetFeatureWithScreenshotGames({
    gameIds: filteredGameIds,
    maxScreenshotCount,
  })

  const handleOpenImplementationPage = () => {
    navigate(buildImplementationSearchUrl(featureGames.map((game) => game.id)))
  }

  const handleSelectScreenshotPreview = (gameId: string) => {
    const selectedGameScreenshot = screenshotsUniqueByGameId.find((screenshot) => screenshot.gameId === gameId)
    const pathToScreenshot = createScreenshotModalPath({
      screenshots: screenshotsUniqueByGameId.map((screenshot) => screenshot.id as string),
      shot: selectedGameScreenshot?.id,
    })
    navigate(`${location.pathname}${pathToScreenshot}`, { replace: true })
  }

  return (
    <>
      <Card>
        <GenreEssentialCardHeader
          section={GenreEssentialSection.implementationExample}
          onClick={handleOpenImplementationPage}
          title={t('genre-essentials:implementation_examples_title')}
          description={t('genre-essentials:implementation_examples_description')}
          buttonLabel={t('genre-essentials:open_in_implementation_examples') as string}
        />
        <CardContent sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {isLoading ? (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <GRCircularProgress />
            </Box>
          ) : (
            featureGames.map((game) => (
              <ScreenshotPreview
                key={game.id}
                game={game}
                relatedFeatureScreenshots={newsEntriesFeatureByGameIds && newsEntriesFeatureByGameIds[game.id]?.features}
                onSelect={() => handleSelectScreenshotPreview(game.id)}
              />
            ))
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default LatestImplementationCard
