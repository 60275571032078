import classNames from 'classnames'
import React, { FC, useCallback, useMemo } from 'react'

import CheckIcon from '@mui/icons-material/Check'
import { Chip } from '@mui/material'

import { VersionTag } from '../../types/VersionTag'
import styles from './VersionTagsList.module.scss'

/**
 * Components for displaying Version Tags for Games. If you wish to use the version tag list as a selector
 * use VersionTagSelector component instead.
 */
interface Props {
  versionTags: VersionTag[]
  selectedVersionTags?: VersionTag[]
  onSelect?: (selectedVersionTags: VersionTag) => void
  size?: 'small'
  condensed?: boolean
}

const VersionTagsList: FC<Props> = ({ versionTags, selectedVersionTags, onSelect, size, condensed }) => {
  const orderedVersionTags = useMemo(() => [...versionTags].sort((a, b) => (a.name < b.name ? -1 : 1)), [versionTags])

  return (
    <>
      {orderedVersionTags.map((versionTag) => (
        <VersionTagItem
          versionTag={versionTag}
          selected={!!selectedVersionTags?.find((tag) => tag.id === versionTag.id)}
          onSelect={onSelect}
          key={versionTag.id}
          size={size}
          condensed={condensed}
        />
      ))}
    </>
  )
}

export default VersionTagsList

type VersionTagItemProps = {
  versionTag: VersionTag
  selected?: boolean
  onSelect?: (tag: VersionTag) => void
  size?: 'small'
  condensed?: boolean
}

const VersionTagItem: FC<VersionTagItemProps> = ({ versionTag, selected, onSelect, size, condensed }) => {
  const selectable = !!onSelect
  const handleClick = useCallback(() => {
    onSelect && onSelect(versionTag)
  }, [onSelect, versionTag])

  const classes = classNames(styles.item, styles['_' + versionTag.id], {
    [styles.selectable]: selectable,
    [styles.selected]: selected,
    [styles.condensed]: condensed,
  })
  const props = {
    label: versionTag.name,
    className: classes,
    icon: selectable ? <CheckIcon fontSize="inherit" className={styles.icon} /> : undefined,
    onClick: selectable ? handleClick : undefined,
    size,
  }
  return <Chip {...props} />
}
