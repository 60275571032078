import { differenceInMonths, endOfDay } from 'date-fns'
import { t } from 'i18next'

import type { FileUploadResponse, Screenshot } from '../../../api/core'
import { dateDifference, monthDifference } from '../../../helpers/date'
import { intersection } from '../../../helpers/intersection'
import { Currency } from '../../../internal/types/Currency'
import languageService from '../../../services/LanguageService'
import utilsService from '../../../services/UtilsService'
import { BaseGame } from '../../../types/BaseGame'
import { SoftLaunchMarket } from '../../../types/SoftLaunchMarket'
import { User } from '../../account/types/User'
import { UserLanguage } from '../../account/types/User'
import { DemographicsDataKey } from '../../demographics/types/DemographicsDataKey'
import { DEFAULT_DOWNLOADS_VALUE, DEFAULT_REVENUE_VALUE } from '../../estimates/services/RevenueAndDownloadEstimates'
import type { Market } from '../../markets'
import { getMarketIsosByUserLanguage } from '../../markets/utils/utils'
import type { Store } from '../../store'
import { GameStageId } from './GameStageId'

export enum EstimateTypes {
  DOWNLOADS_30_DAY = 'downloads30d',
  DOWNLOADS_60_DAY = 'downloads60d',
  DOWNLOADS_90_DAY = 'downloads90d',
  DOWNLOADS_180_DAY = 'downloads180d',
  DOWNLOADS_360_DAY = 'downloads360d',
  DOWNLOADS_LATEST = 'downloadsLatest',
  REVENUE_30_DAY = 'revenue30d',
  REVENUE_60_DAY = 'revenue60d',
  REVENUE_90_DAY = 'revenue90d',
  REVENUE_180_DAY = 'revenue180d',
  REVENUE_360_DAY = 'revenue360d',
  REVENUE_LATEST = 'revenueLatest',
}

export enum RevenueDownloadsRatioTypes {
  REVENUE_DOWNLOADS_RATIO_30_DAY = 'revenueDownloadsRatio30d',
  REVENUE_DOWNLOADS_RATIO_180_DAY = 'revenueDownloadsRatio180d',
  REVENUE_DOWNLOADS_RATIO_360_DAY = 'revenueDownloadsRatio360d',
}

export enum GameVisibility {
  organization = 'organization',
  private = 'private',
  restricted = 'restricted',
}

export enum AppType {
  MOBILE = 'MOBILE',
  PC_CONSOLE = 'PC_CONSOLE',
  NON_GAME = 'NON_GAME',
}

export enum GameArchiveStatus {
  unknown = 'unknown',
  archived = 'archived',
  active = 'active',
}

export enum GamePlatform {
  // iOS and Android is supported by backend so those are for potential future use
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  PC = 'PC',
  XBOX = 'XBOX',
  PLAYSTATION = 'PLAYSTATION',
  NINTENDO_SWITCH = 'NINTENDO_SWITCH',
}

export const GamePlatformsForPCConsole = [GamePlatform.PC, GamePlatform.XBOX, GamePlatform.PLAYSTATION, GamePlatform.NINTENDO_SWITCH]

export interface Game extends BaseGame {
  RIBBON_TYPE?: string
  stores: Store[] | null
  markets: Array<Market>
  reviewId: string
  reviewPublished: boolean
  softLaunchMarket?: string
  mainMarket: null
  brandId: null
  cognitive: number
  sensomotoric: number
  coordinates: number[]
  stage: string
  stageId: number
  updatedAt: number
  updatedAtPerMarket: { [key: string]: number }
  globalLaunchDate: number
  version: string
  versions: { [key: string]: string }
  lastAnalyzedVersions: LastAnalyzedVersions
  lastVersionCheck: number
  icons: string[]
  srank: number
  locked: boolean
  coreId: number
  metaId: number
  appGenres?: number[]
  conventionalGenre: string
  conventionalSubgenre: string
  conventionalCategory: string
  conventionalGenreId: string
  conventionalSubgenreId: string
  conventionalCategoryId: string
  currencies: Currency[]
  overrideConventionals: boolean
  analysisProgressPerMarket: AnalysisProgressPerMarket
  similarTop100: AnalysisProgressPerMarket
  internal: boolean
  owner: string
  icon: string | null | FileUploadResponse
  coreLoop: null
  latestAnalysis: LastAnalyzedVersions
  createdFrom: null
  following: boolean
  ranks: LastRankUpdates
  ranksYesterday: LastRankUpdates
  sranks: LastRankUpdates
  dranks: AnalysisProgressPerMarket
  sdranks: AnalysisProgressPerMarket
  appGenreRanks: AppGenreRanks
  appGenreDranks: AppGenreRanks
  appGenreSranks: AppGenreRanks
  appGenreSdranks: AppGenreRanks
  names: { [key: string]: string }
  storeLinks: { [key: string]: string }
  descriptions: { [key: string]: string }
  tags?: Tags
  analysisOutdated: boolean
  changesInFeatures: boolean
  bundleId: string
  topEntries: TopEntries
  top10ProbabilityAvg: LastDrankUpdates
  top20ProbabilityAvg: LastDrankUpdates
  top50ProbabilityAvg: LastDrankUpdates
  top100ProbabilityAvg: LastDrankUpdates
  top200ProbabilityAvg: LastDrankUpdates
  expiresAt: number
  gpsPerMarket: AnalysisProgressPerMarket
  prevGpsPerMarket: AnalysisProgressPerMarket
  gpsChangePerMarket: AnalysisProgressPerMarket
  lastAnalysisAt: { [key: string]: number }
  lastScoreAt: LastScoreAt
  revenueAvgs: AnalysisProgressPerMarket
  revenueSums: AnalysisProgressPerMarket
  downloadAvgs: AnalysisProgressPerMarket
  downloadSums: AnalysisProgressPerMarket
  avgUserRating: { [key: string]: number }
  avgUserRatingCurrentVer: { [key: string]: number }
  userRatingCount: { [key: string]: number }
  userRatingCountCurrentVer: { [key: string]: number }
  visibility: GameVisibility
  unlocked: boolean
  size: number
  demographics: DemographicsMap
  similarTop100History: SimilarTop100History
  topProbabilities: TopProbabilities
  topProbabilityHistories: TopProbabilityHistories
  history: null
  lastRankUpdates: LastRankUpdates
  lastDrankUpdates: LastDrankUpdates
  powerScores: PowerScore[]
  languages: string[]
  revenueAndDownloadAggregatesMap: RevenueAndDownloadAggregatesMap
  softLaunchMarkets: SoftLaunchMarket[]
  competitorOrder: number | undefined
  ownOrder: number
  name?: string // private game still have their name only in name field
  desc?: string // private game still have their name only in name field
  resolvedName: string
  resolvedDescription: string
  appType?: AppType
  platforms?: GamePlatform[]
  isTracked?: boolean
  genreInnovator: number
  getPowerScoreForMarket(marketIso: string): number
  getSensomotoric(formatted: boolean): string
  getCognitive(formatted: boolean): string
  getSustainedGrossingRankForMarket(marketIso: string): number
  getSustainedDownloadRankForMarket(marketIso: string): number
  getRevenuePastForMarket(estimateType: EstimateTypes, marketIso: string, formatted: boolean, formattedNoRound: boolean): string | number
  getDownloadsPastForMarket(estimateType: EstimateTypes, marketIso: string, formatted: boolean, formattedNoRound: boolean): string | number
  getRevenueDownloadsRatioForMarket(revenueDownloadsRatio: RevenueDownloadsRatioTypes, marketIso: string, formatted: boolean): number | string
  getDemographicsPercentForIdAndMarket(id: string, marketIso: string, decimalAccuracy: boolean): number
  isUnlocked(): boolean
  isPublic(): boolean
  isPrivate(): boolean
  getResolvedName(userLanguage: UserLanguage): string
  getResolvedDescription(userLanguage: UserLanguage): string
}

export class Game extends BaseGame implements Game {
  data: Game
  constructor(data: Game, options: { userLanguage?: UserLanguage } = {}) {
    super(data)
    Object.assign(this, data)
    this.data = data
    this.conventionalSubgenre = this.conventionalSubgenreId ? languageService.getTranslation('conventionalSubgenres', this.conventionalSubgenreId) : ''
    this.conventionalGenre = this.conventionalGenreId ? languageService.getTranslation('conventionalGenres', this.conventionalGenreId) : ''
    this.conventionalCategory = this.conventionalCategoryId ? languageService.getTranslation('conventionalCategories', this.conventionalCategoryId) : ''
    this.resolvedName = data.resolvedName ? data.resolvedName : options.userLanguage ? this.getResolvedName(options.userLanguage) : this.name || ''
    this.resolvedDescription = data.resolvedDescription
      ? data.resolvedDescription
      : options.userLanguage
      ? this.getResolvedDescription(options.userLanguage)
      : ''
  }

  get gameData() {
    return this.data
  }

  getResolvedName(userLanguage: UserLanguage): string {
    return this.getValueByUserLanguage('names', userLanguage) || this.name || ''
  }

  getResolvedDescription(userLanguage: UserLanguage): string {
    return this.getValueByUserLanguage('descriptions', userLanguage) || this.desc || ''
  }

  private getValueByUserLanguage(collectionName: 'names' | 'descriptions', userLanguage: UserLanguage) {
    const marketIsos = getMarketIsosByUserLanguage(userLanguage)
    let resolvedValue
    marketIsos.every((marketIso) => {
      resolvedValue = this[collectionName][marketIso]
      return !resolvedValue
    })

    return resolvedValue || Object.values(this[collectionName])[0]
  }

  isUnlocked(): boolean {
    return this.unlocked
  }

  isPublic(): boolean {
    return this.internal && this.appId > 0
  }

  isPrivate(): boolean {
    return this.visibility === GameVisibility.private
  }

  isAnalyzedForMarket(marketIso: string) {
    return this.lastAnalysisAt[marketIso] ? true : false
  }

  isSoftLaunch() {
    return this.stageId === GameStageId.soft_launch
  }

  isFollowed(): boolean {
    return this.following
  }

  getIcon(useLargeIcon: boolean = true) {
    if (this.icons && this.icons.length > 0) {
      return useLargeIcon ? this.icons[this.icons.length - 1] : this.icons[0]
    }

    if (this.icon) {
      return this.icon
    }
  }

  getSize(): string {
    let size: string = ''

    if (this.size > 0) {
      size = `${Math.round(this.size / 100000) / 10} MB`
    }

    return size
  }

  getEstimateRevenueDownloadsRatioValue(marketIso: string, revenueEstimateKey: EstimateTypes, downloadsEstimateKey: EstimateTypes) {
    return this.revenueAndDownloadAggregatesMap &&
      this.revenueAndDownloadAggregatesMap[marketIso] &&
      this.revenueAndDownloadAggregatesMap[marketIso][revenueEstimateKey] &&
      this.revenueAndDownloadAggregatesMap[marketIso][downloadsEstimateKey]
      ? (this.revenueAndDownloadAggregatesMap[marketIso][revenueEstimateKey] || 0) /
          (this.revenueAndDownloadAggregatesMap[marketIso][downloadsEstimateKey] || 1)
      : 0
  }

  getEstimateValue(marketIso: string, estimateKey: EstimateTypes) {
    return (
      (this.revenueAndDownloadAggregatesMap &&
        this.revenueAndDownloadAggregatesMap[marketIso] &&
        this.revenueAndDownloadAggregatesMap[marketIso][estimateKey]) ||
      0
    )
  }

  getPowerscoreForMarket(marketIso: string) {
    return this.gpsPerMarket[marketIso]
  }

  getDemographicsValue(marketIso: string, demographicsKey: DemographicsDataKey) {
    if (utilsService.demographicsIsAvailableForMarket(marketIso) && this.demographics && this.demographics[marketIso]) {
      return Math.round(this.demographics[marketIso][demographicsKey] * 100)
    } else {
      return 0
    }
  }

  getDemographicsPercentForIdAndMarket(id: string, marketIso: string, decimalAccuracy: boolean = false): number {
    let value: number = 0

    if (marketIso in this.demographics && id in this.demographics[marketIso]) {
      value = (this.demographics[marketIso] as { [key: string]: number })[id]
    } else {
      return 0
    }

    if (value === 0) return 0
    return decimalAccuracy ? Math.round(value * 1000) / 10 : Math.round(value * 100)
  }

  getRevenueDownloadsRatioForMarket(revenueDownloadsRatioType: RevenueDownloadsRatioTypes, marketIso: string, formatted: boolean = false): string | number {
    let revenueDownloadsRatio: number = DEFAULT_DOWNLOADS_VALUE
    let revenue: number
    let downloads: number

    switch (revenueDownloadsRatioType) {
      case RevenueDownloadsRatioTypes.REVENUE_DOWNLOADS_RATIO_30_DAY: {
        revenue = this.getRevenuePastForMarket(EstimateTypes.REVENUE_30_DAY, marketIso) as number
        downloads = this.getDownloadsPastForMarket(EstimateTypes.DOWNLOADS_30_DAY, marketIso) as number
        break
      }
      case RevenueDownloadsRatioTypes.REVENUE_DOWNLOADS_RATIO_180_DAY: {
        revenue = this.getRevenuePastForMarket(EstimateTypes.REVENUE_180_DAY, marketIso) as number
        downloads = this.getDownloadsPastForMarket(EstimateTypes.DOWNLOADS_180_DAY, marketIso) as number
        break
      }
      case RevenueDownloadsRatioTypes.REVENUE_DOWNLOADS_RATIO_360_DAY: {
        revenue = this.getRevenuePastForMarket(EstimateTypes.REVENUE_360_DAY, marketIso) as number
        downloads = this.getDownloadsPastForMarket(EstimateTypes.DOWNLOADS_360_DAY, marketIso) as number
        break
      }
      default: {
        throw new Error('Invalid revenue downloads ratio type')
      }
    }

    if (formatted) {
      return `$ ${utilsService.shortenNumber(revenueDownloadsRatio)}`
    }

    return (revenueDownloadsRatio = revenue > 0 && (revenue > DEFAULT_REVENUE_VALUE || downloads > DEFAULT_DOWNLOADS_VALUE) ? revenue / downloads : 0)
  }

  getRevenuePastForMarket(estimateType: EstimateTypes, marketIso: string, formatted: boolean = false, formattedNoRound: boolean = false): string | number {
    if (!this.revenueAndDownloadAggregatesMap || !this.revenueAndDownloadAggregatesMap[marketIso][estimateType]) {
      return `${t('common:not_available_shorthand')}`
    }

    let revenue: number =
      this.revenueAndDownloadAggregatesMap[marketIso][estimateType]! > DEFAULT_REVENUE_VALUE
        ? (this.revenueAndDownloadAggregatesMap[marketIso][estimateType] as number)
        : DEFAULT_REVENUE_VALUE

    if (formatted) {
      if (revenue <= DEFAULT_REVENUE_VALUE) {
        return `< $${utilsService.shortenNumber(revenue)}`
      } else {
        return `$${utilsService.shortenNumber(revenue)}`
      }
    } else if (formattedNoRound) {
      if (revenue <= DEFAULT_REVENUE_VALUE) {
        return `< ${revenue}`
      }
    }

    return revenue
  }

  getDownloadsPastForMarket(estimateType: EstimateTypes, marketIso: string, formatted: boolean = false, formattedNoRound: boolean = false): string | number {
    if (!this.revenueAndDownloadAggregatesMap) {
      return `${t('common:not_available_shorthand')}`
    }

    let downloads: number =
      this.revenueAndDownloadAggregatesMap[marketIso][estimateType]! > DEFAULT_DOWNLOADS_VALUE
        ? (this.revenueAndDownloadAggregatesMap[marketIso][estimateType] as number)
        : DEFAULT_DOWNLOADS_VALUE

    if (formatted) {
      if (downloads <= DEFAULT_DOWNLOADS_VALUE) {
        return `< ${utilsService.shortenNumber(downloads)}`
      } else {
        return utilsService.shortenNumber(downloads)
      }
    } else if (formattedNoRound) {
      if (downloads <= DEFAULT_DOWNLOADS_VALUE) {
        return `< ${downloads}`
      }
    }

    return downloads
  }

  getSustainedDownloadRankForMarket(marketIso: string) {
    return this.sdranks && this.sdranks[marketIso] ? this.sdranks[marketIso] : 2000
  }

  getSustainedGrossingRankForMarket(marketIso: string): number {
    return this.sranks && this.sranks[marketIso] ? this.sranks[marketIso] : 2000
  }

  getDaysSinceRelease(): number {
    return dateDifference(new Date(this.released), new Date())
  }

  getCognitive(formatted: boolean = false): string {
    const value = Math.round(this.cognitive * 100)
    return (formatted ? value + '%' : value) as string
  }

  getSensomotoric(formatted: boolean = false): string {
    const value = Math.round(this.sensomotoric * 100)
    return (formatted ? value + '%' : value) as string
  }

  getPowerScoreForMarket(marketIso: string): number {
    return this.gpsPerMarket[marketIso]
  }

  isOwnGame(currentUser: User) {
    return this.owner === currentUser?.organization.id ? true : false
  }

  isOutdatedForMarket(marketIso: string, currentUser: User, includeLiveOpsTrackingStatusCheck: boolean = true): boolean {
    let outdated = false
    const isTracked = includeLiveOpsTrackingStatusCheck && this.isTracked
    if (!isTracked && !this.isOwnGame(currentUser) && this.lastAnalysisAt[marketIso] > 0 && this.stageId !== 5) {
      const currentGameVersion = this.lastAnalyzedVersions && this.lastAnalyzedVersions[marketIso] ? this.lastAnalyzedVersions[marketIso] : null
      const latestGameVersion = this.versions[marketIso]

      if (currentGameVersion && currentGameVersion !== latestGameVersion && monthDifference(new Date(this.lastAnalysisAt[marketIso]), new Date()) >= 2) {
        outdated = true
      }
    }

    return outdated
  }

  isAvailableOnPCOrConsole() {
    return this.appType === AppType.PC_CONSOLE || (this.platforms && intersection(this.platforms, GamePlatformsForPCConsole).length > 0) ? true : false
  }

  get coreName() {
    return languageService.getTranslation('genres', this.coreId + '')
  }

  get metaName() {
    return languageService.getTranslation('genres', this.metaId + '')
  }

  isMobileGame() {
    return this.appType === AppType.MOBILE
  }

  getLastAnalysisAtIsDoneOverMonthsAgo = (monthsAgoLimit: number, marketIso: string) => {
    if (this.lastAnalysisAt[marketIso]) {
      const monthsAgo = differenceInMonths(endOfDay(new Date()), this.lastAnalysisAt[marketIso])
      if (monthsAgo >= monthsAgoLimit) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}

export interface IFeatureGame extends Game {
  featureScreenshots: Screenshot[]
  conceptScreenshots: Screenshot[]
  latestFeatureVersion?: string
}

export class FeatureGame extends Game implements IFeatureGame {
  featureScreenshots: Screenshot[] // Screenshots with features
  conceptScreenshots: Screenshot[] // Screenshots with concepts
  latestFeatureVersion?: string

  constructor(game: Game) {
    super(game, {})
    this.featureScreenshots = []
    this.conceptScreenshots = []
  }
}

export class UpdateImpactGame extends Game {
  constructor(data: any) {
    super(data as Game, {})
    this.conventionalSubgenre = data.conventionalSubgenre
  }
}

export interface AnalysisProgressPerMarket {
  [key: string]: number
}

export interface AppGenreRanks {
  [key: string]: { [key: string]: number }
}

export enum DemographicTypes {
  demographicsMale = 'demographicsMale',
  demographicsFemale = 'demographicsFemale',
  demographicsAge16_24 = 'demographicsAge16_24',
  demographicsAge25_44 = 'demographicsAge25_44',
  demographicsAge45 = 'demographicsAge45',
}

export type Demographics = {
  [demographicType in DemographicTypes]?: number
}

export interface DemographicsMap {
  [marketIso: string]: {
    demographicsMale: number
    demographicsFemale: number
    demographicsAge16_24: number
    demographicsAge25_44: number
    demographicsAge45: number
  }
}

export interface LastAnalyzedVersions {
  [key: string]: string
}

export interface LastDrankUpdates {}

export interface LastRankUpdates {
  [key: string]: number
}

export interface LastScoreAt {
  [key: string]: number
}

export interface PowerScore {
  ts: number
  versionReleaseDate: number
  analysisId: string
  powerScore: number
  wset: string
  base: boolean
  checksum: string
  market: string
}

export interface RevenueAndDownloadAggregatesMap {
  [marketIso: string]: Estimates
}

export type Estimates = {
  [estimateType in EstimateTypes]?: number
}

export interface SimilarTop100History {
  [key: string]: { ts: number; value: number }
}

export interface Tags {
  [key: string]: string[]
}

export interface TopEntries {
  [key: string]: {
    entryTop50: number
    entryTop100: number
    entryTop200: number
    entryTop10: number
    entryTop20: number
  }
}

export interface TopProbabilities {
  [key: string]: {
    top10: number
    top20: number
    top50: number
    top100: number
    top200: number
  }
}

export interface TopProbabilityHistories {
  [key: string]: {
    top10: number[]
    top20: number[]
    top50: number[]
    top100: number[]
    top200: number[]
  }
}

export interface NewGameMetaData {
  name: string
  desc?: string
  conventionalSubgenreId?: string
  stageId: string
  visibility: GameVisibility
  icon?: FileUploadResponse
  stores: string[]
  markets: string[]
}

export interface ILockedAccessGame extends Game {
  lockedAccess?: boolean
}

export class LockedAccessGame extends Game implements ILockedAccessGame {
  lockedAccess?: boolean
}

export interface IGenreInnovatorGame extends Game {
  genreInnovator: number
}

export class GenreInnovatorGame extends Game {
  genreInnovator: number

  constructor(game: IGenreInnovatorGame, options: { userLanguage?: UserLanguage } = {}) {
    super(game, options)
    this.genreInnovator = game.genreInnovator ? game.genreInnovator : 0
  }
}
