import { FC, ReactNode, useEffect, useMemo, useRef } from 'react'
import { SidebarHeader } from 'react-calendar-timeline'

import { Box, Grid } from '@mui/material'

import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import { difference } from '../../../../../helpers/difference'
import { isNil } from '../../../../../helpers/isNil'
import { uniq } from '../../../../../helpers/uniq'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/storeHooks'
import { useDebouncedResize } from '../../../../../hooks/useDebouncedResize'
import { GameIcon } from '../../../../game'
import GameIconWithCount from '../../../../game/components/GameIcon/GameIconWithCount'
import { useCalendarEventsByEventType } from '../../../hooks/liveEventsCalendarEvents'
import { selectVisibleEventTypeGroups, visibleEventTypeGroupsChanged } from '../../../slices/liveEventsCalendarsSlice'
import { CalendarTimeRangeChangeCallback, CalendarTimeRanges } from '../../../types/Calendar'
import { TrackingEventTimelineGroup, TrackingEventTimelineItem, TrackingEventWithGameTimelineGroup } from '../../../types/LiveEvents'
import { TrackedGame } from '../../../types/TrackedGame'
import { FilteredTrackingEventsByGame, TrackingEventByGame } from '../../../types/TrackingEvents'
import LiveEventsCalendar from '../LiveEventsCalendar'

type LiveEventsCalendarByEventTypeContainerProps = {
  calendarTimeRanges: CalendarTimeRanges
  trackedGames: TrackedGame[]
  trackingEventsByGame: FilteredTrackingEventsByGame
  trackingAllEventsByGame: TrackingEventByGame
  onCalendarTimeRangeChange: CalendarTimeRangeChangeCallback
  onItemClick: (clickedItem: TrackingEventTimelineItem) => void
  onGroupClick: (clickedGroup: TrackingEventTimelineGroup) => void
  highlightedEventId?: string
  timeStart?: number
  timeEnd?: number
  minZoom?: number
  maxZoom?: number
  isLoading?: boolean
  children?: ReactNode
}

export const calendarId = 'eventCalendar'
const maxDisplayedGamesOnSidebarHeader = 12

export const LiveEventsCalendarByEventTypeContainer: FC<LiveEventsCalendarByEventTypeContainerProps> = ({
  calendarTimeRanges,
  trackedGames,
  trackingEventsByGame,
  trackingAllEventsByGame,
  highlightedEventId,
  onCalendarTimeRangeChange,
  onItemClick,
  onGroupClick,
  timeStart,
  timeEnd,
  minZoom,
  maxZoom,
  isLoading,
}) => {
  const dispatch = useAppDispatch()
  const visibleGroups = useAppSelector(selectVisibleEventTypeGroups)
  const { groups, items } = useCalendarEventsByEventType({ trackingEventsByGame, trackedGames, highlightedEventId, visibleGroups })
  const debouncedResize = useDebouncedResize(50)
  const allEventValues = Object.values(trackingAllEventsByGame).flat()

  // set initial open groups
  useEffect(() => {
    if (!isLoading && isNil(visibleGroups) && groups && groups.length > 0) {
      dispatch(visibleEventTypeGroupsChanged(groups.map((group) => group.trackingEventType)))
    }
  }, [visibleGroups, groups, dispatch, isLoading])

  // Whenever the items or groups provided for the calendar change we trigger a re-render of the calendar by changing the key of the calendar component
  // to reset the visible time range. This aims to fix GR-1101, GR-1618 and GR-1795.
  useEffect(() => {
    debouncedResize()
    return () => debouncedResize.clear()
  }, [debouncedResize, groups, items])

  const sidebarHeader = useSidebarHeader({ groups, isLoading })
  return (
    <LiveEventsCalendar
      allTrackedEvents={allEventValues}
      groups={groups}
      items={isLoading ? [] : items}
      onItemClick={onItemClick}
      onGroupClick={onGroupClick}
      sidebarHeader={sidebarHeader}
      timeStart={calendarTimeRanges[calendarId]?.timeStart || timeStart}
      timeEnd={calendarTimeRanges[calendarId]?.timeEnd || timeEnd}
      minZoom={minZoom}
      maxZoom={maxZoom}
      isLoading={isLoading}
      calendarHidden={false}
      lineHeight={34}
      onCalendarTimeRangeChange={(timeStart, timeEnd) => onCalendarTimeRangeChange(calendarId, timeStart, timeEnd)}
      showEmptyCalendarIndicator={!!(visibleGroups && visibleGroups.length > 0)}
    />
  )
}

const useSidebarHeader = ({ groups, isLoading }: { groups: TrackingEventWithGameTimelineGroup[]; isLoading?: boolean }) => {
  const trackedGamesWithEventsRef = useRef<TrackedGame[]>([])
  const trackedGamesWithEvents = useMemo(() => {
    const games = uniq(groups.flatMap((group) => group.trackedGamesOfEventType || []))
    const diff = difference(games, trackedGamesWithEventsRef.current)

    if (diff.length > 0 || games.length !== trackedGamesWithEventsRef.current.length) {
      trackedGamesWithEventsRef.current = games
      return games
    } else {
      return trackedGamesWithEventsRef.current
    }
  }, [groups])

  return useMemo(
    () => (
      <SidebarHeader>
        {({ getRootProps }) => (
          <Grid container wrap="wrap" gap={0.5} pt={1} pl={1} alignItems="flex-start" {...getRootProps()}>
            {isLoading && (
              <Box flex={1} alignSelf="center">
                <GRCircularProgress />
              </Box>
            )}

            {!isLoading &&
              trackedGamesWithEvents.length > maxDisplayedGamesOnSidebarHeader &&
              trackedGamesWithEvents.slice(0, 11).map((trackedGame) => {
                return (
                  trackedGame && (
                    <GameIcon
                      gameName={trackedGame.game.resolvedName}
                      src={trackedGame.game.getIcon()}
                      size="small"
                      key={trackedGame.game.id}
                      appType={trackedGame.game.appType}
                      gamePlatforms={trackedGame.game.platforms}
                    />
                  )
                )
              })}

            {!isLoading && trackedGamesWithEvents.length > maxDisplayedGamesOnSidebarHeader && (
              <GameIconWithCount count={trackedGamesWithEvents.length - (maxDisplayedGamesOnSidebarHeader - 1)} prefix="+" size="small" />
            )}

            {!isLoading &&
              trackedGamesWithEvents.length <= maxDisplayedGamesOnSidebarHeader &&
              trackedGamesWithEvents.map((trackedGame) => {
                return (
                  trackedGame && (
                    <GameIcon
                      gameName={trackedGame.game.resolvedName}
                      src={trackedGame.game.getIcon()}
                      size="small"
                      key={trackedGame.game.id}
                      appType={trackedGame.game.appType}
                      gamePlatforms={trackedGame.game.platforms}
                    />
                  )
                )
              })}
          </Grid>
        )}
      </SidebarHeader>
    ),
    [trackedGamesWithEvents, isLoading]
  )
}
