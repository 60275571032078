import { t } from 'i18next'
import React, { useEffect, useState } from 'react'

import { Box, Grid, Slider, Typography } from '@mui/material'

import GRPowerScoreLink from '../GRPowerScoreLink/GRPowerScoreLink'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import Powerscore from '../Powerscore/Powerscore'

const sliderConfigs = {
  min: 30,
  max: 96,
}
/**
 * GRPowerScoreSlider
 */
interface GRPowerScoreSliderProps {
  defaultValue: number
  onChange?: (powerscore: number) => void
}
const GRPowerScoreSlider: React.FC<GRPowerScoreSliderProps> = ({ defaultValue, onChange }) => {
  const [powerscore, setPowerscore] = useState(
    defaultValue > sliderConfigs.max ? sliderConfigs.max : defaultValue < sliderConfigs.min ? sliderConfigs.min : defaultValue
  )
  const handleUpdatescore = (value: number) => {
    setPowerscore(value)
    if (onChange) {
      onChange(value)
    }
  }

  // trigger onChange once when component is mounted
  useEffect(() => {
    if (onChange) {
      onChange(powerscore)
    }
  }, [onChange, powerscore])

  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <GRPowerScoreLink>
          <GRTooltip content={t('common:tooltip_gps_description')}>
            <Typography variant="h3">{t('common:game_power_score')}</Typography>
          </GRTooltip>
        </GRPowerScoreLink>
      </Box>
      <Grid container alignItems="center" paddingLeft={'20px'}>
        <Grid item xs={11.2}>
          <Slider step={1} {...sliderConfigs} value={powerscore} onChange={(_, value) => handleUpdatescore(value as number)} />
        </Grid>
        <Grid item ml={3}>
          <Powerscore powerscore={powerscore} />
        </Grid>
      </Grid>
    </>
  )
}

export default GRPowerScoreSlider
