import { AttrValues, GenreAnalysis, GenreValues, VisualAnalysis } from '../../../api/connect'
import { RankType, RankTypeCapital, VisualAnalysisWithGenre } from '../types'

// Exported from '/misc/utils.js' in the old client, so sorry; not translatable
export const AppStoreGenreMap: { [index: string]: string } = {
  '6014': 'Games',
  '7001': 'Action',
  '7002': 'Adventure',
  '7003': 'Arcade',
  '7004': 'Board',
  '7005': 'Card',
  '7006': 'Casino',
  '7009': 'Family',
  '7011': 'Music',
  '7012': 'Puzzle',
  '7013': 'Racing',
  '7014': 'Role Playing',
  '7015': 'Simulation',
  '7016': 'Sports',
  '7017': 'Strategy',
  '7018': 'Trivia',
  '7019': 'Word',
}

export const convertRankToCapitalType = (rank: RankType): RankTypeCapital => (rank === 'free' ? 'Free' : 'Grossing')

/**
 * Remove all entries that have values rounded to zero.
 *
 * @param values attribute keys with values
 */
const removeZeroes = (values?: AttrValues): AttrValues =>
  !values
    ? {}
    : Object.keys(values).reduce((acc, key) => {
        const value = values[key]
        if (Math.round(value * 100)) {
          acc[key] = value
        }
        return acc
      }, {} as AttrValues)

/**
 * 1) Convert all color and color group attributes to normal attributes.
 * 2) Add the top and outside standings from genre analysis data.
 *
 * @param genreId the id to show
 * @param count number top-X to compare (50, 100, 200, 500)
 * @param rank sustained downloads ('Free') or 'Grossing'
 * @param visualsData from connect API
 * @param genreData from API based on visuals-data app genres
 */
export const combineStatistics = (
  genreId: string,
  count: number,
  rank: RankType,
  visualsData?: VisualAnalysis[],
  genreData?: GenreAnalysis[]
): VisualAnalysisWithGenre[] => {
  if (!visualsData || !genreData) {
    return []
  }

  // unanalyzed games may have null values, filter them out
  const data = visualsData.filter(Boolean).reduce((acc, data = {} as VisualAnalysis) => {
    if (data.model !== 'color') {
      acc.push({ ...data, attributes: removeZeroes(data.attributes) })
    } else {
      acc.push({ ...data, attributes: data.colorGroups, model: 'colorGroups' })
      acc.push({ ...data, attributes: data.colors })
    }
    return acc
  }, [] as VisualAnalysis[])

  const comparison = genreData.find((data) => data && data.appGenre === parseInt(genreId, 10))
  if (!comparison) {
    return []
  }
  const rankCapital = convertRankToCapitalType(rank)
  const genreTop = comparison['top' + count + rankCapital] as GenreValues
  const genreOut = comparison['out' + count + rankCapital] as GenreValues

  return data.map((analysis) => {
    const { model } = analysis
    const top = genreTop[model]
    const outside = genreOut[model]
    return { ...analysis, attributes: analysis.attributes || {}, top, outside }
  })
}
