import React, { useCallback, useState } from 'react'

import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { VersionTagContent, VersionTagEvent, VersionTagFeature, VersionTagSeasonal } from '../../../../types/VersionTag'
import { convertVersionTags } from '../../../../utils/convertVersionTags'
import { SubgenreMap } from '../../../account'
import { useGameUpdateImpactsAccessCheck, useSubgenresAccessCheck } from '../../../account/hooks/roleHooks'
import { Filters, UpdateImpactFilters, UpdateImpactsTable } from '../../../update-impacts'
import { ColumnFilterValue } from '../../../update-impacts/types/ColumnFilter'
import { TableRowUpdateImpact } from '../../../update-impacts/types/TableRowUpdateImpact'

/**
 * ComparePerformanceTable table to show updates of games
 */
interface ComparePerformanceTableProps {
  updateImpactsMappedForTable?: TableRowUpdateImpact[]
  isLoading?: boolean
  subgenres?: SubgenreMap
  handleOpenUpdateClicked: (update: TableRowUpdateImpact) => void
}
const ComparePerformanceTable: React.FC<ComparePerformanceTableProps> = ({ updateImpactsMappedForTable, isLoading, subgenres, handleOpenUpdateClicked }) => {
  const [filters, setFilters] = useState<Filters>({
    versionTags: convertVersionTags([...VersionTagContent, ...VersionTagEvent, ...VersionTagFeature, ...VersionTagSeasonal]),
    subgenres: subgenres || {},
  })
  const hasUpdateImpactsAccessRights = useGameUpdateImpactsAccessCheck()
  const impactDataUnlocked = hasUpdateImpactsAccessRights
  const hasAccessToSubgenres = useSubgenresAccessCheck()
  const powerscoreUnlocked = hasAccessToSubgenres
  const handleFilterChange = useCallback((changedFilters: Filters) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...changedFilters }))
  }, [])
  const handleColumnFilterChange = useCallback(({ name, value }: ColumnFilterValue) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }))
  }, [])

  return (
    <>
      <UpdateImpactFilters filters={filters} onChange={handleFilterChange} showVersionTagSelector={impactDataUnlocked} showComparisonIntervalSelector />
      {isLoading || !updateImpactsMappedForTable ? (
        <GRCircularProgress />
      ) : (
        <UpdateImpactsTable
          rows={updateImpactsMappedForTable}
          filters={filters}
          onColumnFilterChange={handleColumnFilterChange}
          isLoading={isLoading}
          tableConfig={{
            impactDataUnlocked: true,
            showGameColumn: true,
            showExportButton: true,
            showFeaturesColumn: true,
            showOpenUpdateColumn: true,
            showAnalystCommentIndicator: true,
            powerscoreUnlocked: powerscoreUnlocked,
            analystReviewsAndCommentsUnlocked: true,
          }}
          onOpenUpdateClick={handleOpenUpdateClicked}
        />
      )}
    </>
  )
}

export default ComparePerformanceTable
