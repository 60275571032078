import { GamesByAttrsField, useGetGamesByVisualAttributesQuery, VisualAnalysisModel } from '../../../api/connect'
import { RankType } from '../types'

export const getAttributeNameByModel = (model: VisualAnalysisModel) => {
  switch (model) {
    case 'colorGroups':
      return 'colorGroups'
    case 'color':
      return 'colors'
    case 'style':
    case 'icon':
    case 'genre':
      return 'attributes'
  }
}

const mapModelToFields = (model: VisualAnalysisModel, attribute: string | string[]) => ({
  model: model === 'colorGroups' ? 'color' : model,
  [getAttributeNameByModel(model)]: Array.isArray(attribute) ? attribute : [attribute],
})

export const useGamesByVisualAttributes = (fields: GamesByAttrsField[], market: string, rankType: RankType, genreId: string, filterFields: boolean) => {
  return useGetGamesByVisualAttributesQuery(
    {
      appGenre: genreId,
      fields,
      filterFields,
      market,
      rankType,
    },
    { skip: !fields?.length }
  )
}

export const useGamesByVisualAttribute = (model: VisualAnalysisModel, attribute: string, market: string, rankType: RankType, genreId: string) => {
  const fields = [mapModelToFields(model, attribute)]
  return useGamesByVisualAttributes(fields, market, rankType, genreId, true)
}
