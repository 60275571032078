import { DefaultTFuncReturn } from 'i18next'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Info } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'

import { Tag } from '../../../../api/core'
import { AllOrNothingSelector } from '../../../../components/AllOrNothingSelector/AllOrNothingSelector'
import { GRAccordion } from '../../../../components/GRAccordion/GRAccordion'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { intersectionBy } from '../../../../helpers/intersection'
import { uniqBy } from '../../../../helpers/uniq'
import { TagList } from '../../../feature/components/TagList/TagList'
import { ExpandedTagGroup } from '../../../implementation-examples'
import { useLiveEventTagGroupsWithTags } from '../../hooks/useLiveEventTagGroups'
import { hiddenLiveEventTagGroups } from '../../types/LiveEventTagGroup'
import { LiveEventsTag } from '../../types/LiveEventsTag'
import './LiveEventsTagsSelector.scss'

interface Props {
  selectableLiveEventTags?: LiveEventsTag[]
  selectedLiveEventTags: Tag[]
  isLoading?: boolean
  open?: boolean
  title?: string | DefaultTFuncReturn
  hideDefinitionsBtn?: boolean
  onLiveEventsTagSelectChange: (selectedTags: Tag[], selection: { id: string; name: string }) => void
  onLiveEventsTagGroupSelected: (selectedTags: Tag[], selection: { id: string; name: string }) => void
  onLiveEventsTagSelectionCleared: () => void
  onLiveEventsTagSelectAll: () => void
}

const LiveEventsTagsSelector: React.FC<Props> = ({
  selectableLiveEventTags,
  selectedLiveEventTags,
  isLoading,
  open,
  title,
  hideDefinitionsBtn,
  onLiveEventsTagSelectChange,
  onLiveEventsTagGroupSelected,
  onLiveEventsTagSelectionCleared,
  onLiveEventsTagSelectAll,
}) => {
  const { t } = useTranslation()
  const [liveTagsListOpen, setLiveTagsListOpen] = useState(open || false)
  const { data: liveEventTagGroupsWithTags } = useLiveEventTagGroupsWithTags()

  const filteredTagGroupsWithTags = useMemo(() => {
    return liveEventTagGroupsWithTags ? liveEventTagGroupsWithTags.filter((tagGroup) => (hiddenLiveEventTagGroups.includes(tagGroup.id) ? false : true)) : []
  }, [liveEventTagGroupsWithTags])

  const handleSelectTagGroup = useCallback(
    (tags: Tag[], tagGroup: ExpandedTagGroup) => {
      const currentlySelectedInGroup = intersectionBy(selectedLiveEventTags, tags, (tag: Tag) => tag.id)
      const isWholeTagGroupSelected = currentlySelectedInGroup.length === tags.length
      if (isWholeTagGroupSelected) {
        onLiveEventsTagGroupSelected(
          selectedLiveEventTags.filter((selectedTag) => !tags.map((tag) => tag.id).includes(selectedTag.id)),
          tagGroup
        )
      } else {
        onLiveEventsTagGroupSelected(
          uniqBy([...selectedLiveEventTags, ...tags], (tag) => tag.id),
          tagGroup
        )
      }
    },
    [selectedLiveEventTags, onLiveEventsTagGroupSelected]
  )

  const selectableLiveEventTagsGroupsWithTags = useMemo(() => {
    if (!selectableLiveEventTags) {
      return filteredTagGroupsWithTags
    }

    const availableLiveEventTagGroupsWithTags: ExpandedTagGroup[] = []

    filteredTagGroupsWithTags?.forEach((tagGroup) => {
      const tagGroupCopy = { ...tagGroup }
      tagGroupCopy.tags = []

      tagGroup.tags.forEach((tag: Tag) => {
        if (selectableLiveEventTags.find((selectableTag) => selectableTag.id === tag.id)) {
          tagGroupCopy.tags.push(tag)
        }
      })

      if (tagGroupCopy.tags.length > 0) {
        availableLiveEventTagGroupsWithTags.push(tagGroupCopy)
      }
    })

    return availableLiveEventTagGroupsWithTags
  }, [filteredTagGroupsWithTags, selectableLiveEventTags])

  return (
    <GRAccordion
      expanded={liveTagsListOpen}
      onChange={() => setLiveTagsListOpen(!liveTagsListOpen)}
      title={title ? title : t('live-events:live_events_type_filter_title')}
      extra={
        <Grid container alignItems={'center'}>
          <Grid item xs>
            <AllOrNothingSelector
              someSelected={selectedLiveEventTags.length > 0}
              deselectAll={onLiveEventsTagSelectionCleared}
              selectAll={onLiveEventsTagSelectAll}
              disabled={isLoading || !liveTagsListOpen}
            />
          </Grid>
          {!hideDefinitionsBtn && (
            <Grid item>
              <Link to={'/data-glossary/live-event-types'}>
                <Button sx={{ mr: 1 }} size="small" color="secondary" variant="text" startIcon={<Info />}>
                  {t('common:live_events_types_definitions_button')}
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
      }
    >
      <div className="LiveEventTagsSelector__Tags">
        {!isLoading && selectableLiveEventTagsGroupsWithTags && (
          <TagList
            tagGroups={selectableLiveEventTagsGroupsWithTags}
            selectedTags={selectedLiveEventTags}
            onChange={onLiveEventsTagSelectChange}
            onSelectTagGroup={handleSelectTagGroup}
            variant="small"
          />
        )}
        {(selectableLiveEventTagsGroupsWithTags.length === 0 || isLoading) && <GRCircularProgress my={2} />}
      </div>
    </GRAccordion>
  )
}

export default LiveEventsTagsSelector
