import { useRouteModal } from '../../route-modals/hooks/useRouteModal'
import { EModalType } from '../../route-modals/types/modalTypes'
import { EventDialogTab } from './useEventDialogTabs'

export type UseLiveEventModalHookParams = {
  eventId?: string
  eventTypeId: string
  trackedGameId: string
  tab?: EventDialogTab
  screenshotIndex?: number
}

export const useLiveEventModal = () => {
  const { pushModal } = useRouteModal()
  const showLiveEventModal = ({ trackedGameId, eventTypeId, eventId, tab = EventDialogTab.AllEvents, screenshotIndex }: UseLiveEventModalHookParams) => {
    pushModal({
      type: EModalType.LIVE_EVENT,
      params: {
        trackedGameId,
        liveEventTypeId: eventTypeId,
        liveEventId: eventId,
        tab,
        screenshotIndex: screenshotIndex?.toString(),
      },
    })
  }

  return {
    showModal: showLiveEventModal,
  }
}
