import { FC, memo } from 'react'

import { RevenueAndDownloadEstimates } from '../../../estimates/services/RevenueAndDownloadEstimates'
import { CellValue } from '../CellValue/CellValue'

/**
 * Component for displaying currencies in tables
 */
export const CurrencyIndicator: FC<{ estimate: RevenueAndDownloadEstimates; maxFractionDigits?: number; threshold?: number; shorten?: boolean }> = memo(
  ({ estimate, maxFractionDigits = 0, threshold, shorten }) => {
    return (
      <CellValue value={estimate.value} emphasized>
        {estimate.formatCurrency({ maxFractionDigits, threshold, shorten })}
      </CellValue>
    )
  }
)
