import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Search } from '@mui/icons-material'
import { Autocomplete, TextField } from '@mui/material'

interface Props {
  value: string
  liveEventNames: string[]
  onChange?: (searchResult: string) => void
}

const LiveEventsEventSearchAutocomplete: React.FC<Props> = ({ value, onChange, liveEventNames }) => {
  const { t } = useTranslation()

  const handleChange = useCallback(
    (e: React.SyntheticEvent, value: string | null) => {
      onChange && onChange(value || '')
    },
    [onChange]
  )

  return (
    <div className="LiveEventsEventSearchAutocomplete">
      <Autocomplete
        value={value}
        fullWidth
        freeSolo
        blurOnSelect
        size="small"
        onChange={handleChange}
        options={liveEventNames}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              <>
                <Search sx={{ verticalAlign: 'middle', mr: 0.5 }} />
                {t('live-events:live_events_event_name_filter_title')}
              </>
            }
          />
        )}
      />
    </div>
  )
}

export default LiveEventsEventSearchAutocomplete
