import 'chartjs-adapter-date-fns'
import React, { useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { PushPin } from '@mui/icons-material'
import { Card, CardContent, Typography } from '@mui/material'

import { GameInfoDialog } from '../../../../components/GameInfoDialog/GameInfoDialog'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { RoleEnum } from '../../../account'
import {
  useFTUEVideosAccessCheck,
  useLiveEventsTrackerAccessCheck,
  useNewsFeedLimitedAccessCheck,
  useRoleCheck,
  useSoftLaunchAccessCheck,
} from '../../../account/hooks/roleHooks'
import { useIsOnFreePlan } from '../../../account/hooks/userHooks'
import FtueVideoFullscreenModal from '../../../ftue-videos/components/FtueVideoFullscreenModal/FtueVideoFullscreenModal'
import { SelectedChapterTag } from '../../../ftue-videos/components/GameChapterList/GameChapterList'
import { useGetFtueVideoForGame } from '../../../ftue-videos/hooks/useGetFtueVideoForGame'
import { Game } from '../../../game'
import { GameStageId } from '../../../game/types/GameStageId'
import { useNewsCardTitle } from '../../hooks/unifiedNewsEntryHooks'
import { NewsEntryType, UnifiedNewsEntry } from '../../types/UnifiedNewsEntry'
import './NewsCard.scss'
import { NewsCardContentSections } from './NewsCardContentSections/NewsCardContentSections'
import { NewsCardEditorialImage } from './NewsCardContentSections/NewsCardEditorialImage/NewsCardEditorialImage'
import { NewsCardHeader } from './NewsCardHeader/NewsCardHeader'
import { NewsCardRibbon } from './NewsCardRibbon/NewsCardRibbon'
import { NewsCardSubHeader } from './NewsCardSubHeader/NewsCardSubHeader'

interface Props {
  newsEntry: UnifiedNewsEntry
}

const NewsCard: React.FC<Props> = ({ newsEntry }) => {
  const hasNewsFeedLimitedAccess = useNewsFeedLimitedAccessCheck()
  const hasAccessToSoftLaunch = useSoftLaunchAccessCheck()
  const hasAccessToFTUEVideos = useFTUEVideosAccessCheck()
  const hasAccessToLiveEventsTracker = useLiveEventsTrackerAccessCheck()
  const isInternalUser = useRoleCheck(RoleEnum.internal)

  const mappedNewsEntryToGame = useMemo(
    () =>
      ({
        id: newsEntry.feedEntry.entryData.gameId || newsEntry.feedEntry.gameId,
        resolvedName: newsEntry.feedEntry.entryData.game,
        artist: newsEntry.feedEntry.entryData.artist,
        icons: [newsEntry.feedEntry.entryData.icon],
      } as Game),
    [newsEntry.feedEntry]
  )

  const { video } = useGetFtueVideoForGame(newsEntry.feedEntry.entryData.gameId, { skip: !newsEntry.hasFTUEVideo })

  const entryType = newsEntry.feedEntry.type
  const isEditorialCard = entryType === NewsEntryType.Editorial
  const showGameHeader = !isEditorialCard ? true : false
  const isSoftLaunchGame = newsEntry.feedEntry.entryData.stageId === GameStageId.soft_launch ? true : false

  const isOnFreePlan = useIsOnFreePlan()

  const [title, subtitle] = useNewsCardTitle({ newsEntry })
  const [openFTUEVideo, setOpenFTUEVideo] = useState(false)
  const [openGameInfo, setOpenGameInfo] = useState(false)
  const [openLockedFTUEVideos, setOpenLockedFTUEVideos] = useState(false)
  const [selectedChapterTag, setSelectedChapterTag] = useState<SelectedChapterTag>({ chapterId: 0, tagId: '' })

  const [hideGameDetails, limitedFunctionalityBannerType] = useMemo(() => {
    if (entryType === NewsEntryType.SoftLaunchNonAnalyzed && isSoftLaunchGame && !hasAccessToSoftLaunch) return [true, LockedFeatureId.SoftLaunch]
    if (entryType === NewsEntryType.MajorGameRelease && isOnFreePlan) return [true, LockedFeatureId.GameUnlock]
    if ((entryType === NewsEntryType.LiveEventNewEventType || entryType === NewsEntryType.LiveEventAnalystNotes) && !hasAccessToLiveEventsTracker)
      return [false, LockedFeatureId.LiveEventsTracker]
    return [false, undefined]
  }, [entryType, isSoftLaunchGame, hasAccessToSoftLaunch, isOnFreePlan, hasAccessToLiveEventsTracker])

  const handleFTUEVideoOpen = (open: boolean) => {
    hasAccessToFTUEVideos || !hasNewsFeedLimitedAccess ? setOpenFTUEVideo(open) : setOpenLockedFTUEVideos(open)
  }

  return (
    <div className="NewsCard">
      <Card className="Card">
        {isSoftLaunchGame && (
          <NewsCardRibbon sx={{ fontSize: 11 }}>
            <Trans i18nKey={'newsfeed-options:soft_launch'} />
          </NewsCardRibbon>
        )}

        {isEditorialCard && (
          <>
            <NewsCardRibbon>
              {newsEntry.feedEntry.entryData.sticky ? (
                <>
                  <PushPin sx={{ fontSize: '15px', position: 'absolute', left: '18px', top: '3px' }} />
                  <Typography sx={{ fontSize: '13px', fontWeight: 'bold', lineHeight: 1, marginLeft: '11px' }}>
                    <Trans i18nKey={'newsfeed:editorial_type'} />
                  </Typography>
                </>
              ) : (
                <Trans i18nKey={'newsfeed:editorial_type'} />
              )}
            </NewsCardRibbon>
            <NewsCardEditorialImage isInternalUser={isInternalUser} newsEntry={newsEntry} altText={title} />
          </>
        )}
        <CardContent>
          {showGameHeader && (
            <NewsCardHeader
              isInternalUser={isInternalUser}
              newsEntry={newsEntry}
              hideGameDetails={hideGameDetails}
              onOpenGameInfo={() => setOpenGameInfo(true)}
            />
          )}
          <NewsCardSubHeader newsEntry={newsEntry} title={title} subtitle={subtitle} onFTUEVideoOpen={handleFTUEVideoOpen} />
          <NewsCardContentSections
            newsEntry={newsEntry}
            hideGameDetails={hideGameDetails}
            hasNewsFeedLimitedAccess={hasNewsFeedLimitedAccess}
            limitedFunctionalityBannerType={limitedFunctionalityBannerType}
          />
        </CardContent>
      </Card>

      {openFTUEVideo && !!video && (
        <FtueVideoFullscreenModal
          game={mappedNewsEntryToGame}
          position={0}
          video={video}
          onClose={() => {
            setOpenFTUEVideo(false)
          }}
          selectedChapterTag={selectedChapterTag}
          onChapterTagClick={setSelectedChapterTag}
        />
      )}
      {openLockedFTUEVideos && (
        <LockedFeature.Dialog lockedFeatureId={LockedFeatureId.FtueVideos} open={openLockedFTUEVideos} onClose={() => setOpenLockedFTUEVideos(false)} />
      )}

      {openGameInfo && (
        <GameInfoDialog
          game={mappedNewsEntryToGame}
          open={openGameInfo}
          onClose={() => {
            setOpenGameInfo(false)
          }}
        />
      )}
    </div>
  )
}

export default NewsCard
