export enum PlayerArchetype {
  MotivationPlayerArchetypeExpressionist = 'Expressionist',
  MotivationPlayerArchetypeThinker = 'Thinker',
  MotivationPlayerArchetypeTreasureHunter = 'Treasure Hunter',
  MotivationPlayerArchetypeStrategist = 'Strategist',
  MotivationPlayerArchetypeSkillMaster = 'Skll master',
  MotivationPlayerArchetypeThrillSeeker = 'Thrill seeker',
  MotivationPlayerArchetypeKingOfTheHill = 'King of the hill',
  MotivationPlayerArchetypeNetworker = 'Networker',
}
