import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@mui/material'

import { GranularityValue } from '../../features/revenue-and-downloads/types/Filters'
import { SelectMenu } from '../SelectMenu/SelectMenu'

export const GranularitySelector: FC<{ value?: GranularityValue; onChange?: (value: GranularityValue) => void }> = ({ onChange, value }) => {
  const { t } = useTranslation()
  const options = useMemo(() => {
    return [
      {
        name: t('common:day'),
        value: GranularityValue.Day,
      },
      {
        name: t('common:week'),
        value: GranularityValue.Week,
      },
      {
        name: t('common:month'),
        value: GranularityValue.Month,
      },
    ]
  }, [t])

  return (
    <FormControl size="small" variant="outlined" sx={{ minWidth: 150 }}>
      <SelectMenu
        label={t('common:date_granularity')}
        options={options}
        value={value}
        onChange={(e) => {
          onChange?.(e.target.value)
        }}
      />
    </FormControl>
  )
}
