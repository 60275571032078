import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { useLocation, useNavigate } from 'react-router-dom'

import SellOutlinedIcon from '@mui/icons-material/SellOutlined'
import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material'

import { compareNames, ConceptTag, Screenshot, useGetCategoriesMapQuery } from '../../../../api/core'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import ScreenshotListModal from '../../../../components/ScreenshotModal/ScreenshotListModal'
import ScreenshotsModal from '../../../../components/ScreenshotModal/ScreenshotModal'
import analyticsService from '../../../../services/AnalyticsService'
import languageService from '../../../../services/LanguageService'
import { AnalyticsProviders } from '../../../../types/IAnalyticsProvider'
import { FeatureLink } from '../../../feature/components/FeatureLink'
import { Game } from '../../../game'
import { CategoryImage } from '../../../game-features/components/CategoryImage/CategoryImage'
import { FeatureGame } from '../../../game/types/Game'
import { useCurrentMarket } from '../../../markets/hooks/useCurrentMarket'
import { useInitialGameFeatureState } from '../../hooks/useInitialState'
import { EnrichedFeature } from '../../types/ImplementationExamples'
import { latestOnlyFilter, screenshotsByFeatureFilter, screenshotsByConceptTagFilter } from '../../util/filters'
import { getCombinedScreenshots } from '../../util/screenshotHelpers'
import ScreenshotPreview from './ScreenshotPreview'

export type SelectedPreview = {
  classifier?: EnrichedFeature | ConceptTag
  game: FeatureGame
  shot?: string | null
  screenshots: Screenshot[]
  version?: string
}

type SortOrder = 'alphabet'

interface ScreenshotPreviewsProps {
  breakDownVersions?: boolean
  features: EnrichedFeature[]
  conceptTags?: ConceptTag[]
  fromDate?: number
  games: FeatureGame[]
  implementationScreenshotGames?: FeatureGame[]
  hideEmptyFeatures?: boolean
  latestOnly?: boolean
  onRemoveFromCollection?: (screenshotId: string) => void
  toDate?: number
  gameSorter?: (g1: Game, g2: Game) => number
  sortOrder?: SortOrder
  hideListScreenshots?: boolean
  hasLimitedAccess?: boolean
  rootPath?: string
}

type PreviewSet = {
  classifier: EnrichedFeature | ConceptTag
  games: FeatureGame[]
  onRemoveFromCollection?: (screenshotId: string) => void
  onSelect: (preview: SelectedPreview) => void
  sortOrder?: SortOrder
}

const PreviewsGroupedByVersion: React.FC<PreviewSet> = ({ classifier, games, onRemoveFromCollection, onSelect, sortOrder }) => {
  const sortedGames: FeatureGame[] = useMemo(() => {
    switch (sortOrder) {
      case 'alphabet':
        return games.sort((a, b) => a.resolvedName.localeCompare(b.resolvedName))
      default:
        return games
    }
  }, [games, sortOrder])

  return (
    <Box className="screenshotPreviews">
      {sortedGames.map((game, j) => (
        <ScreenshotPreview
          classifier={classifier}
          game={game as FeatureGame}
          key={j}
          onRemoveFromCollection={onRemoveFromCollection}
          onSelect={(screenshots) => {
            onSelect({ classifier, game, screenshots, shot: screenshots.length > 1 ? null : screenshots[0].screenshotId, version: screenshots[0].gameVersion })
          }}
        />
      ))}
    </Box>
  )
}

const PreviewsVersionBreakdown: React.FC<PreviewSet> = ({ classifier, games, onRemoveFromCollection, onSelect }) => {
  const { t } = useTranslation()
  return (
    <Box className="screenshotPreviews">
      {games.map((game, j) => {
        const renderedScreenshots = game.featureScreenshots?.filter(screenshotsByFeatureFilter(classifier as EnrichedFeature))
        if (!renderedScreenshots?.length) {
          return undefined
        }
        const version = renderedScreenshots[0].gameVersion
        return (
          <LazyLoadComponent key={`${version}-group-${j}`}>
            <fieldset>
              <legend>
                <Typography variant="h3" className="versionTitle">
                  {t('common:version')} {version}
                </Typography>
              </legend>
              {renderedScreenshots.map((screenshot, k) => {
                const featureScreenshots = [screenshot]
                return (
                  <ScreenshotPreview
                    classifier={classifier}
                    game={{ ...game, featureScreenshots } as FeatureGame}
                    key={`preview-${k}`}
                    onRemoveFromCollection={onRemoveFromCollection}
                    onSelect={() =>
                      onSelect({
                        classifier,
                        game,
                        screenshots: renderedScreenshots,
                        shot: renderedScreenshots[k].screenshotId || renderedScreenshots[k].id,
                        version: renderedScreenshots[k].gameVersion,
                      })
                    }
                  />
                )
              })}
            </fieldset>
          </LazyLoadComponent>
        )
      })}
    </Box>
  )
}

/**
 * Show list of screenshots from selected games.
 *
 * Iterate through features:
 *   - Iterate through games per feature
 */
const ScreenshotPreviews: React.FC<ScreenshotPreviewsProps> = ({
  breakDownVersions,
  features,
  conceptTags,
  games,
  implementationScreenshotGames,
  hideEmptyFeatures,
  latestOnly,
  onRemoveFromCollection,
  gameSorter,
  sortOrder,
  hideListScreenshots,
  hasLimitedAccess = false,
  rootPath,
}) => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedPreview, setSelectedPreview] = useState<SelectedPreview | undefined>()
  const [initialSelection, setInitialSelection] = useInitialGameFeatureState()
  const [showShotIndexModal, setShowShotIndexModal] = useState<boolean>(false)
  const [basePath, setBasePath] = useState<string | undefined>(initialSelection?.basePath)

  const setSelectedShot = (shot?: string) => {
    setSelected(shot && selectedPreview ? { ...selectedPreview, shot } : undefined)
  }

  // For restoring the selection state from a direct link (shared URL)
  useEffect(() => {
    const allGames = implementationScreenshotGames ? [...games, ...implementationScreenshotGames] : games
    if (allGames.length && features.length && (initialSelection?.gameIds?.length || initialSelection?.gameId)) {
      const { gameId, gameIds, version, featureId, conceptTagId } = initialSelection
      const shot = new URLSearchParams(location.search).get('shot') as string
      const searchableGameId = gameId || gameIds?.[0]
      const versions = allGames.filter(({ id }) => id === searchableGameId)
      const game = version
        ? versions.find((g) => getCombinedScreenshots(g).find(({ gameVersion }) => gameVersion === version))
        : versions.find((g) => getCombinedScreenshots(g).find((ss) => ss.id === shot || ss.screenshotId === shot))
      // eslint-disable-next-line eqeqeq
      const feature = features.find(({ legacyId }) => legacyId == featureId)
      const conceptTag = conceptTags?.find(({ id }) => id === conceptTagId)

      // concept tag selected
      if (game && conceptTag && !feature) {
        const screenshots: Screenshot[] = game.conceptScreenshots?.filter(screenshotsByConceptTagFilter(conceptTag)) || []
        setSelected({ classifier: conceptTag, game, screenshots, shot, version })
        setShowShotIndexModal(screenshots.length > 1)
      }
      // feature selected
      else if (game && feature && !conceptTag) {
        const screenshots: Screenshot[] = game.featureScreenshots?.filter(screenshotsByFeatureFilter(feature)) || []
        setSelected({ classifier: feature, game, screenshots, shot, version })
        setShowShotIndexModal(screenshots.length > 1)
      }
      setBasePath(initialSelection.basePath)
      setInitialSelection(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games, features, conceptTags, initialSelection])

  /**
   * Reconstruct URL location and set preview to state after selecting a preview.
   */
  const setSelected = (preview?: SelectedPreview) => {
    const pathParts = location.pathname.split('/')
    const rootPathParts = pathParts.slice(0, basePath?.split('/').length || 5)
    let path = [...rootPathParts]
    let searchParams = new URLSearchParams(location.search)
    if (preview) {
      const { shot, game, classifier, version } = preview

      path.push(game.id)

      if (version) {
        path.push(version)
      }

      if ((classifier as EnrichedFeature)?.legacyId) {
        path.push('featureid')
        path.push((classifier as EnrichedFeature).legacyId + '')
      }

      if ((classifier as ConceptTag)?.type === 'concept') {
        path.push('featuretagid')
        path.push((classifier as ConceptTag).id + '')
      }

      if (shot) {
        searchParams.set('shot', shot)
      } else if (shot !== null) {
        searchParams.set('shot', preview.screenshots[0].id || '')
      } else {
        searchParams.delete('shot')
      }

      analyticsService.trackEvent('Implementation Preview Selected', {
        data: {
          gameId: game.id,
          gameName: game.resolvedName,
          featureId: classifier?.id,
          feature: classifier?.name,
          version,
        },
        serviceToExclude: [AnalyticsProviders.hubspot, AnalyticsProviders.intercom],
      })
    } else {
      searchParams.delete('shot')
    }

    setSelectedPreview(preview)
    navigate(
      {
        pathname: path.join('/'),
        search: searchParams.toString(),
      },
      { replace: true }
    )
  }

  const onCloseModal = () => {
    setSelected(undefined)
    hasLimitedAccess && navigate(rootPath || '')
  }

  const onCloseShotModal = () => {
    const preview = selectedPreview as SelectedPreview
    if (preview.screenshots.length <= 1) {
      onCloseModal()
    } else {
      setSelected({ ...preview, shot: null })
    }
  }

  const handleSelect = (preview: SelectedPreview) => {
    setShowShotIndexModal(false)
    setSelected(preview)
  }

  const { currentMarketIso: marketIso } = useCurrentMarket()
  const { data: categories } = useGetCategoriesMapQuery({ marketIso })

  return (
    <>
      <Grid direction="column" container spacing={2}>
        {
          // Feature Tags
          [...features].sort(compareNames).map((feature, i) => {
            const featureFilter = screenshotsByFeatureFilter(feature)

            const renderedGames = games
              .filter(latestOnlyFilter(latestOnly))
              .filter(({ featureScreenshots }) => featureScreenshots?.filter(featureFilter).length > 0)
              .sort(gameSorter)

            if (hideEmptyFeatures && !renderedGames.length) {
              return undefined
            }

            const count = renderedGames.reduce((acc, game) => acc + game.featureScreenshots.filter(featureFilter).length, 0)
            const category = categories?.[feature.categoryId]

            return (
              <Grid item key={`feature-${i}`}>
                <Card className="previewContainer">
                  <CardHeader
                    title={
                      <Grid container>
                        <Grid item alignItems="center" display="flex" marginRight={1}>
                          <GRTooltip content={category?.name || ''}>
                            <CategoryImage size="small" src={category?.icon.url || ''} alt={category?.name || ''} />
                          </GRTooltip>
                        </Grid>
                        <Grid item>
                          <FeatureLink feature={{ featureName: feature.name, featureLegacyId: feature.legacyId }}>
                            <span>{languageService.getTranslation('features', feature.legacyId.toString())}</span>
                            <small> {t('implementations:label_no_of_implementations', { count })}</small>
                          </FeatureLink>
                        </Grid>
                      </Grid>
                    }
                  />
                  <Divider />
                  <CardContent>
                    {!renderedGames.length ? (
                      <Typography>{t('common:no_games_available')}</Typography>
                    ) : breakDownVersions ? (
                      <PreviewsVersionBreakdown
                        games={renderedGames}
                        classifier={feature}
                        onSelect={handleSelect}
                        onRemoveFromCollection={onRemoveFromCollection}
                      />
                    ) : (
                      <PreviewsGroupedByVersion
                        sortOrder={sortOrder}
                        games={renderedGames}
                        classifier={feature}
                        onSelect={handleSelect}
                        onRemoveFromCollection={onRemoveFromCollection}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        }

        {
          // Concept tags
          conceptTags?.sort(compareNames).map((conceptTag, i) => {
            const conceptTagFilter = screenshotsByConceptTagFilter(conceptTag)

            const renderedGames = games
              .filter(latestOnlyFilter(latestOnly))
              .filter(({ conceptScreenshots }) => conceptScreenshots?.filter(conceptTagFilter).length > 0)
              .sort(gameSorter)

            if (hideEmptyFeatures && !renderedGames.length) {
              return undefined
            }

            const count = renderedGames.reduce((acc, game) => acc + game.conceptScreenshots.filter(conceptTagFilter).length, 0)

            return (
              <Grid item key={`conceptTag-${i}`}>
                <Card className="previewContainer">
                  <CardHeader
                    title={
                      <Grid container>
                        <Grid item alignItems="center" display="flex" marginRight={1}>
                          <GRTooltip content={t('implementations:feature_tag')}>
                            <SellOutlinedIcon sx={{ height: '16px', width: '16px', margin: '0 0px 0 0px' }} color="primary" />
                          </GRTooltip>
                        </Grid>
                        <Grid item>
                          <span>{conceptTag.name}</span>
                          <small> {t('implementations:label_no_of_implementations', { count })}</small>
                        </Grid>
                      </Grid>
                    }
                  />
                  <Divider />
                  <CardContent>
                    {!renderedGames.length ? (
                      <Typography>{t('common:no_games_available')}</Typography>
                    ) : breakDownVersions ? (
                      <PreviewsVersionBreakdown
                        games={renderedGames}
                        classifier={conceptTag}
                        onSelect={handleSelect}
                        onRemoveFromCollection={onRemoveFromCollection}
                      />
                    ) : (
                      <PreviewsGroupedByVersion
                        sortOrder={sortOrder}
                        games={renderedGames}
                        classifier={conceptTag}
                        onSelect={handleSelect}
                        onRemoveFromCollection={onRemoveFromCollection}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )
          })
        }
      </Grid>
      {selectedPreview && selectedPreview.screenshots.length > 1 && (
        <ScreenshotListModal
          title={selectedPreview.classifier?.name}
          game={selectedPreview.game}
          onClose={onCloseModal}
          onSelectShot={setSelectedShot}
          screenshots={selectedPreview.screenshots}
          hideListScreenshots={hideListScreenshots && !showShotIndexModal}
          selectedClassifier={selectedPreview.classifier}
        />
      )}
      {/* TODO: refactor to use route based screenshot modal */}
      {selectedPreview && /*parsedParams.shot*/ new URLSearchParams(location.search).get('shot') && (
        <ScreenshotsModal
          game={selectedPreview.game}
          screenshots={selectedPreview.screenshots}
          onClose={onCloseShotModal}
          title={selectedPreview.classifier?.name}
          classifier={selectedPreview.classifier}
        />
      )}
    </>
  )
}

export default ScreenshotPreviews
