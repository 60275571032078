import { FC, useCallback } from 'react'
import React from 'react'
import { generatePath, useParams, Route, Routes } from 'react-router-dom'

import { useRouteModalPath } from '../../../../hooks/useRouteModalPath'
import ScreenshotsModalContainer from '../../../news/components/ScreenshotsModalContainer/ScreenshotsModalContainer'
import { TableRowUpdateImpact } from '../../../update-impacts/types/TableRowUpdateImpact'
import { UpdateModal } from '../UpdateModal/UpdateModal'

/**
 * Main container for update modal handling the routing when another (prev, next) game update is requested
 */
type UpdateModalContainerProps = {}

type PathParams = { 0: string; gameId: string; gameVersion: string; marketIso: string }

export const UpdateModalContainer: FC<UpdateModalContainerProps> = () => {
  const { gameId, gameVersion, marketIso } = useParams<PathParams>() as PathParams

  const currentModalPath = createUpdateModalPath({
    gameId,
    gameVersion,
    marketIso,
  })
  const updateDialogPath = useRouteModalPath({ currentModalPath })

  const handleUpdateChange = useCallback(
    (update: TableRowUpdateImpact) => {
      const newPath = createUpdateModalPath({
        gameId,
        gameVersion: update.version,
        marketIso,
      })
      updateDialogPath(newPath)
    },
    [gameId, marketIso, updateDialogPath]
  )

  const handleClose = () => {
    updateDialogPath()
  }

  return (
    <>
      <UpdateModal gameId={gameId} gameVersion={gameVersion} marketIso={marketIso} onUpdateChange={handleUpdateChange} open onClose={handleClose} />
      <Routes>
        <Route path={`screenshots/:screenshots/:shot?/*`} element={<ScreenshotsModalContainer />} />
      </Routes>
    </>
  )
}

export const createUpdateModalPath = ({ gameId, gameVersion, marketIso }: { gameId: string; gameVersion: string; marketIso: string }) => {
  return generatePath('/gameupdate/:gameId/:gameVersion/:marketIso', {
    gameId,
    gameVersion,
    marketIso,
  })
}
