import { FC, ReactNode, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'

import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Collapse, Dialog, DialogContent, DialogProps, Grid, List, ListItem, Paper, Typography } from '@mui/material'

import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { useError } from '../../../../hooks/useError'
import { ConfirmDialogData } from '../../../../types/ConfirmDialogData'
import { displaySnackBar } from '../../../snackbar'
import { useSegmentsByGameRefinery, useUpdateUserSegments, useUserSegments } from '../../hooks/userSegmentHooks'
import { MarketExplorerUserSegment } from '../../types/MarketExplorerUserSegment'

/**
 * Component for displaying user segments saved by the user + predefined segment configurations. User can load any configuration
 * and delete those created by her.
 */
type LoadUserSegmentsDialogProps = DialogProps & {
  onSelectSegment: (segment: MarketExplorerUserSegment) => void
  children?: ReactNode
}

export const LoadUserSegmentsDialog: FC<LoadUserSegmentsDialogProps> = ({ open, onClose, onSelectSegment, children }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const segmentsByGameRefinery = useSegmentsByGameRefinery()
  const { data: userSegments, isLoading: isLoadingUserSegments } = useUserSegments()
  const { updateUserSegments, isLoading: isUserSegmentDeleting, isSuccess: isUserSegmentDeleteSuccess, error: userSegmentDeleteError } = useUpdateUserSegments()
  const [deletedUserSegment, setDeletedUserSegment] = useState<MarketExplorerUserSegment>()
  const [confirmDeleteUserSegmentDialogData, setConfirmDeleteUserSegmentDialogData] = useState<ConfirmDialogData<MarketExplorerUserSegment>>()

  const handleLoad = (userSegment: MarketExplorerUserSegment) => {
    onSelectSegment(userSegment)
    onClose && onClose({}, 'escapeKeyDown')
  }

  const handleDelete = (segment: MarketExplorerUserSegment) => {
    setConfirmDeleteUserSegmentDialogData({
      title: t('segment:delete_user_segment_selection_title'),
      confirmText: <Trans i18nKey={'segment:delete_user_segment_selection_text'} values={{ segmentSelectionName: segment.name }} />,
      destructiveAction: true,
      actionText: t('common:delete'),
      data: segment,
    })
  }

  const handleDeleteUserSegmentConfirm = (dialogData?: ConfirmDialogData<MarketExplorerUserSegment>) => {
    if (dialogData && dialogData.data) {
      const segment = dialogData.data
      setDeletedUserSegment(segment)

      const filteredSegments = userSegments.filter((userSegment) => userSegment !== segment)
      updateUserSegments(filteredSegments)
    }

    setConfirmDeleteUserSegmentDialogData(undefined)
  }

  useEffect(() => {
    if (isUserSegmentDeleteSuccess) {
      dispatch(displaySnackBar({ message: t('segment:user_segment_selection_deleted'), severity: 'success', open: true }))
    }
  }, [dispatch, isUserSegmentDeleteSuccess, t])
  useError({ error: userSegmentDeleteError })

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <GRDialogTitle onClose={onClose}>
          <Trans i18nKey="segment:user_segments_modal_title" />
        </GRDialogTitle>
        <DialogContent dividers>
          {isLoadingUserSegments ? (
            <GRCircularProgress />
          ) : (
            <>
              {userSegments && userSegments.length > 0 && (
                <Box mb={4}>
                  <Typography variant="h3" sx={{ mb: 2 }}>
                    <Trans i18nKey="segment:your_segments_title" />
                  </Typography>
                  <List component={Paper}>
                    <TransitionGroup>
                      {[...userSegments]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((segment, index, segments) => (
                          <Collapse key={`${segment.name}_${segment.description}_${index}`}>
                            <ListItem divider={index < segments.length - 1}>
                              <SegmentListItem
                                segment={segment}
                                onSelect={handleLoad}
                                onDelete={handleDelete}
                                isDeleting={deletedUserSegment === segment && isUserSegmentDeleting}
                              />
                            </ListItem>
                          </Collapse>
                        ))}
                    </TransitionGroup>
                  </List>
                </Box>
              )}
              <Typography variant="h3" sx={{ mb: 2 }}>
                <Trans i18nKey="segment:predefined_segments_title" />
              </Typography>
              <List component={Paper}>
                <TransitionGroup>
                  {segmentsByGameRefinery.map((segment, index, segments) => (
                    <Collapse key={segment.name}>
                      <ListItem divider={index < segments.length - 1}>
                        <SegmentListItem segment={segment} onSelect={handleLoad} />
                      </ListItem>
                    </Collapse>
                  ))}
                </TransitionGroup>
              </List>
            </>
          )}
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={!!confirmDeleteUserSegmentDialogData}
        confirmDialogData={confirmDeleteUserSegmentDialogData}
        onClose={handleDeleteUserSegmentConfirm}
      />
    </>
  )
}

type SegmentListItemProps = {
  segment: MarketExplorerUserSegment
  onSelect: (segment: MarketExplorerUserSegment) => void
  onDelete?: (segment: MarketExplorerUserSegment) => void
  isDeleting?: boolean
}

const SegmentListItem: FC<SegmentListItemProps> = ({ segment, onSelect, onDelete, isDeleting }) => {
  const { t } = useTranslation()
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item xs={onDelete ? 6 : 9}>
        <Typography variant="body1">{segment.name}</Typography>
        {segment.description && <Typography variant="body2">{segment.description}</Typography>}
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          {onDelete && (
            <Grid item>
              <LoadingButton variant="contained" color="warning" loading={isDeleting} loadingPosition="center" onClick={() => onDelete && onDelete(segment)}>
                <DeleteIcon fontSize="small" />
              </LoadingButton>
            </Grid>
          )}
          <Grid item>
            <Button variant="contained" color="primary" startIcon={<CheckIcon />} onClick={() => onSelect(segment)}>
              {t('segment:load_segment_selection_button')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
