import { FC, ReactNode } from 'react'

import styles from './FeaturesWithTitle.module.scss'

/**
 * Renders a list of features with given title. Renders nothing if item count is zero.
 */

type FeaturesWithTitleProps = {
  children?: ReactNode
  title: string
  itemCount: number
}

export const FeaturesWithTitle: FC<FeaturesWithTitleProps> = ({ title, itemCount, children }) => {
  return itemCount > 0 ? (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  ) : null
}
