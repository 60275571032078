import { ChangeEventHandler, FC } from 'react'
import { Trans } from 'react-i18next'

import { FileUpload } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Grid, Button } from '@mui/material'

type EventScreenshotsUploaderProps = {
  files: File[]
  onChange: ChangeEventHandler<HTMLInputElement>
  onUpload: () => void
  isUploading?: boolean
}
export const EventScreenshotsUploader: FC<EventScreenshotsUploaderProps> = ({ files, onChange, onUpload, isUploading }) => {
  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement
    element.value = ''
  }
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <Button component="label" variant="contained" color="primary" tabIndex={-1}>
          <Trans i18nKey="internal-common:select_files" />
          <input type="file" multiple accept="image/*" style={{ display: 'none' }} onClick={onInputClick} onChange={onChange} />
        </Button>
      </Grid>
      <Grid item>
        <LoadingButton
          onClick={onUpload}
          loading={isUploading}
          variant="contained"
          color="success"
          loadingPosition="start"
          startIcon={<FileUpload />}
          disabled={!files || files.length === 0}
        >
          <Trans i18nKey="internal-common:start_upload" />
        </LoadingButton>
      </Grid>
    </Grid>
  )
}
