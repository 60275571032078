import { AttributeModelNames, VisualAnalysis, VisualAnalysisModel } from '../../../api/connect'
import { UserLanguage } from '../../account/types/User'
import { getAttributeNameByModel } from '../../visuals/hooks/useIconAttributes'
import { formatPercentage, formatPercentageDiff, getVisualAnalysisName } from '../../visuals/util/helpers'
import { WordCountsType, GameNameAnalysis, GameNameStats, GameNameStatsType, IconStatsRow, Ranks, StatCounts } from '../types'

/**
 * All data for "Icon Stats" view in prepared table row format.
 */
export const getIconStats = (
  models: VisualAnalysisModel[],
  topAmount: number,
  gameData?: VisualAnalysis[],
  ranks?: Ranks,
  attributes?: AttributeModelNames,
  colorAmount?: number
): IconStatsRow[] => {
  if (!attributes || !gameData || !ranks) {
    return []
  }
  return models
    .map((model) => {
      const isColor = ['color', 'colorGroups'].includes(model)
      const fixedModel = isColor ? 'color' : model
      const filteredRows = gameData.filter((data) => data.model === fixedModel)
      const modelRows = [...new Set(filteredRows.map((data) => data.appId))].map((appId) =>
        filteredRows.find((data) => data.appId === appId)
      ) as VisualAnalysis[]

      // Iterate all attribute names under model
      return [...attributes[model]].sort().map((name) => {
        let count = { top: 0, out: 0 }
        let total = { top: 0, out: 0 }
        const rankArr: number[] = []
        const appIds: number[] = []
        const attrName = getAttributeNameByModel(model)
        modelRows.forEach((data) => {
          const rank = ranks[data.appId]
          if (!rank) {
            return
          }
          const topOrOut = rank <= topAmount ? 'top' : 'out'
          const attrs = data[attrName]
          total[topOrOut]++
          const compareValue = isColor && colorAmount ? colorAmount : 0.2
          if (attrs !== undefined && name in attrs && attrs[name] >= compareValue) {
            rankArr.push(rank)
            appIds.push(data.appId)
            count[topOrOut]++
          }
        })
        const out = count.out / (total.out || 1) || 0
        const top = count.top / (total.top || 1) || 0
        const diff = !!out && !!top ? Math.round(100 * top) - Math.round(100 * out) : 0
        return {
          appIds,
          model,
          name,
          averageRank: average(rankArr),
          medianRank: median(rankArr),
          out,
          top,
          diff,
        }
      })
    })
    .flat()
}

export const getAllTopCounts = (models: VisualAnalysisModel[], gameData?: VisualAnalysis[], ranks?: Ranks, attributes?: AttributeModelNames) => {
  if (!gameData || !ranks || !attributes) {
    return []
  }
  const top50 = getIconStats(models, 50, gameData, ranks, attributes)
  const top100 = getIconStats(models, 100, gameData, ranks, attributes)
  const top200 = getIconStats(models, 200, gameData, ranks, attributes)
  const top500 = getIconStats(models, 500, gameData, ranks, attributes)
  return top50.map(({ averageRank, medianRank, model, name, out, top }, index) => ({
    name,
    model,
    top50: formatPercentage(top),
    out50: formatPercentage(out),
    diff50: formatPercentageDiff(out, top),
    top100: formatPercentage(top100[index].top),
    out100: formatPercentage(top100[index].out),
    diff100: formatPercentageDiff(top100[index].out, top100[index].top),
    top200: formatPercentage(top200[index].top),
    out200: formatPercentage(top200[index].out),
    diff200: formatPercentageDiff(top200[index].out, top200[index].top),
    top500: formatPercentage(top500[index].top),
    out500: formatPercentage(top500[index].out),
    diff500: formatPercentageDiff(top500[index].out, top500[index].top),
    medianRank,
    averageRank,
  }))
}

const getCounts = (games: GameNameAnalysis[]): { [type in GameNameStatsType]: StatCounts } => {
  const addedAppIds: number[] = []
  return games.reduce(
    (acc, { appId, rank, originalLength, words }) => {
      if (addedAppIds.includes(appId)) {
        return acc
      }

      addedAppIds.push(appId)
      // Number of words
      const wordsCount = words.length
      if (wordsCount) {
        const numberOfWords = acc.numberOfWords[wordsCount] || { frequency: 0, ranks: [], appIds: [] }
        acc.numberOfWords[wordsCount] = {
          appIds: [...numberOfWords.appIds, appId],
          frequency: ++numberOfWords.frequency,
          ranks: [...numberOfWords.ranks, rank],
        }
      }

      // Title length: Group [1-5, 6-10, 11...]
      const group = '' + (Math.floor((originalLength - 1) / 5) * 5 + 1)
      const titleLength = acc.titleLength[group] || { frequency: 0, ranks: [], appIds: [] }
      acc.titleLength[group] = {
        appIds: [...titleLength.appIds, appId],
        frequency: ++titleLength.frequency,
        ranks: [...titleLength.ranks, rank],
      }

      // Word frequency
      words.forEach((word) => {
        const wordFrequency = acc.wordFrequency[word] || { frequency: 0, ranks: [], appIds: [] }
        acc.wordFrequency[word] = {
          appIds: [...addedAppIds],
          frequency: ++wordFrequency.frequency,
          ranks: [...wordFrequency.ranks, rank],
        }
      })

      return acc
    },
    {
      wordFrequency: {},
      numberOfWords: {},
      titleLength: {},
    } as WordCountsType
  )
}

/**
 * All data for "Game Name Stats" view in prepared table row format.
 */
export const getGameNameStats = (userLanguage: UserLanguage, analysis?: VisualAnalysis[], ranks?: Ranks): GameNameStats | undefined => {
  if (!analysis || !ranks) {
    return undefined
  }

  const games = analysis.map((visualAnalysis) => {
    const { appId } = visualAnalysis
    const name = getVisualAnalysisName({ visualAnalysis, userLanguage })
    return {
      appId,
      originalLength: name.length,
      rank: ranks[appId],
      words: tidyGameName(name),
    }
  })

  const counts = getCounts(games)

  return Object.keys(counts).reduce(
    (acc, type) => ({
      ...acc,
      [type]: Object.keys(counts[type as GameNameStatsType]).map((label) => {
        const { appIds, frequency, ranks } = counts[type as GameNameStatsType][label]
        return { appIds, label, frequency, averageRank: average(ranks), medianRank: median(ranks) }
      }),
    }),
    {} as GameNameStats
  )
}

/**
 * Helper: Remove all non-alphabetical and non-numerical characters, split the name into an array of words.
 */
export const tidyGameName = (name: string): string[] =>
  name
    .replace(/[^A-Za-z0-9| ]+/g, '')
    .trim()
    .toLowerCase()
    .split(' ')
    .filter((value, index, array) => array.indexOf(value) === index)
    .filter((str) => {
      return str !== ''
    })

/**
 * Average of array of numbers.
 */
export const average = (arr: number[]): number => {
  const value = arr.reduce((sum, value) => sum + value, 0) / arr.length
  return value ? Math.round(value) : 0
}

/**
 * Median of array of numbers.
 */
const median = (arr: number[]): number => {
  if (!arr.length) return 0

  const middle = Math.floor(arr.length / 2)
  arr = [...arr].sort((a, b) => a - b)
  return arr.length % 2 !== 0 ? arr[middle] : Math.round((arr[middle - 1] + arr[middle]) / 2)
}
