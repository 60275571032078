import React, { useEffect, useMemo, useState } from 'react'

import { Box, LinearProgress } from '@mui/material'

import { useAppSelector } from '../../../hooks/storeHooks'
import { selectApiQueries } from '../selectors/selectors'
import './ApiFetchingIndicator.scss'

/**
 * ApiFetchingIndicator Listen to pending states of apis and return loading indicator
 */
interface ApiFetchingIndicatorProps {}
const ApiFetchingIndicator: React.FC<ApiFetchingIndicatorProps> = () => {
  const store = useAppSelector(selectApiQueries)

  const statusPendingCount = useMemo(() => {
    let statusCount = 0
    store.forEach((apiStore) => {
      Object.values(apiStore).forEach((data) => {
        const value = data as { status: string }
        if (value?.status === 'pending') {
          statusCount += 1
        }
      })
    })
    return statusCount
  }, [store])

  const [maxPendingStatus, setMaxPendingStatus] = useState(0)
  const [showIndicator, setShowIndicator] = useState(false)

  useEffect(() => {
    if (statusPendingCount > maxPendingStatus || statusPendingCount === 0) {
      setMaxPendingStatus(statusPendingCount)
    }
  }, [maxPendingStatus, statusPendingCount])

  const calculatedPercentage = useMemo(() => {
    if (maxPendingStatus !== 0) {
      return 1 - statusPendingCount / maxPendingStatus
    }

    if (statusPendingCount === 0 && maxPendingStatus === statusPendingCount) {
      return 1
    }

    return 0
  }, [maxPendingStatus, statusPendingCount])

  useEffect(() => {
    let timeout: number
    if (calculatedPercentage > 0 && calculatedPercentage < 1) {
      setShowIndicator(true)
      return
    }

    if (calculatedPercentage === 1) {
      timeout = window.setTimeout(() => {
        setShowIndicator(false)
      }, 500)
    }

    return () => clearTimeout(timeout)
  }, [calculatedPercentage])

  if (!showIndicator) {
    return null
  }

  return (
    <Box className="ApiFetchingIndicator">
      <LinearProgress variant="determinate" value={calculatedPercentage * 100} color="secondary" />
    </Box>
  )
}

export default ApiFetchingIndicator
