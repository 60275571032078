export enum LiveEventCalendarAdditionalDataId {
  GameVersion = 'gameVersion',
  TopIAPs = 'topIAPs',
  RevenueChange = 'revenueChange',
  DownloadChange = 'downloadChange',
  AUChange = 'auChange',
  AnalystReview = 'analystReview',
}

export class LiveEventCalendarAdditionalData {
  id: LiveEventCalendarAdditionalDataId
  name: string

  constructor(id: LiveEventCalendarAdditionalDataId, name: string) {
    this.id = id
    this.name = name
  }
}
