import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@mui/material'

import { TimeScaleConfig, TimelineChart } from '../../../../../components/TimelineChart/TimelineChart'
import { useCurrentUserLanguage } from '../../../../account/hooks/useCurrentUserLanguage'
import { ChartDataFormat } from '../../../../update-history/types/types'
import { LiveEventCounts } from '../../../types/LiveEventStatistics'

type LiveEventsEventCountChartProps = {
  eventCounts?: LiveEventCounts
}

export const LiveEventsEventCountChart: FC<LiveEventsEventCountChartProps> = ({ eventCounts = {} }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()

  let timeDisplayFormats: any

  switch (userLanguage) {
    case 'ja':
    case 'zh':
      timeDisplayFormats = {
        day: 'yyyy年M月d日',
        month: 'yyyy年M月',
      }
      break

    case 'en':
      timeDisplayFormats = {
        day: 'MMM d, yyyy',
        week: 'LL / yyyy',
        month: 'MMM, yyyy',
      }
      break
  }

  const datasets = [
    {
      yAxisID: 'y1',
      label: t('live-events:event_statistics_event_count_chart_y_axis_title'),
      spanGaps: false,
      tension: 0.1,
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      data: Object.entries(eventCounts).map(([ts, count]) => ({ x: +ts, y: count })),
    },
  ]

  const xScaleConfig = useMemo(() => {
    return {
      time: {
        unit: 'day',
        displayFormats: timeDisplayFormats,
        isoWeekday: true,
      },
    } as TimeScaleConfig
  }, [timeDisplayFormats])

  const yScaleConfig = {
    dataFormat: ChartDataFormat.Number,
    title: t('live-events:event_statistics_event_count_chart_y_axis_title'),
  }

  return <TimelineChart chartLineOptions={{ responsive: true }} data={{ datasets: datasets }} scaleConfig={{ x: xScaleConfig, y1: yScaleConfig }} />
}
