import React from 'react'

import { Box, BoxProps, CircularProgress, CircularProgressProps } from '@mui/material'

type GRCircularProgressProps = BoxProps & {
  circularProgressProps?: CircularProgressProps
}

const GRCircularProgress: React.FC<GRCircularProgressProps> = ({ circularProgressProps, ...boxProps }) => {
  return (
    <Box textAlign="center" {...boxProps}>
      <CircularProgress color="primary" {...circularProgressProps} />
    </Box>
  )
}

export default GRCircularProgress
