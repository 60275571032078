import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PerformanceChangesByOptionValue } from '../types/PerformanceChangesByOption'

const peakValues = [1, 7, 14, 30] as const
const impactValues = [7, 14, 30] as const

export const defaultPerformanceChangesByValue = PerformanceChangesByOptionValue.Impact_7_days

export const usePerformanceChangesByOptions = () => {
  const { t } = useTranslation()

  return useMemo(() => {
    const peakOptions = peakValues.map((value) => ({
      name:
        value === 1
          ? t('live-events:performance_changes_by_peak_option_label_one', { count: value })
          : t('live-events:performance_changes_by_peak_option_label_other', { count: value }),
      value: PerformanceChangesByOptionValue[`Peak_${value}_days`],
    }))

    const impactOptions = impactValues.map((value) => ({
      name: t('live-events:performance_changes_by_impact_option_label_other', { count: value }),
      value: PerformanceChangesByOptionValue[`Impact_${value}_days`],
    }))

    return [...impactOptions, ...peakOptions]
  }, [t])
}
