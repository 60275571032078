import { FC, memo, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Grid, TableContainer } from '@mui/material'
import { Box } from '@mui/system'

import { AnalystOverviewDialog } from '../../../../components/AnalystOverviewDialog/AnalystOverviewDialog'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GamePerformaceDialogData, GamePerformanceDialog } from '../../../../components/GamePerformanceDialog/GamePerformanceDialog'
import { GamePerformanceDialogOpenButton } from '../../../../components/GamePerformanceDialogOpenButton/GamePerformanceDialogOpenButton'
import { LockedData } from '../../../../components/LockedData/LockedData'
import RankChange from '../../../../components/RankChange/RankChange'
import { ReviewIndicator } from '../../../../components/ReviewIndicator/ReviewIndicator'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { RevenueAndDownloadEstimates } from '../../../estimates/services/RevenueAndDownloadEstimates'
import { GamePowerscore } from '../../../game/components/GamePowerscore/GamePowerscore'
import { CellValue } from '../../../soft-launch/components/CellValue/CellValue'
import { CurrencyIndicator } from '../../../soft-launch/components/CurrencyIndicator/CurrencyIndicator'
import { TrendIndicator } from '../../../soft-launch/components/TrendIndicator/TrendIndicator'
import { TopGame } from '../../types/TopGame'
import { TopGameCardContent } from '../TopGameCard/TopGameCard'
import styles from './TopGrossingTable.module.scss'

type TopGrossingTableProps = {
  topGames: TopGame[]
  marketIso: string
  isLoading?: boolean
}

/**
 * Component for displaying Top Grossing table
 */
export const TopGrossingTable: FC<TopGrossingTableProps> = memo(({ topGames, marketIso, isLoading }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const maxRankValue = 200
  const estimatesUnlocked = useRoleCheck(RoleEnum.revenue_estimates)
  const [analystOverviewDialogGame, setAnalystOverviewDialogGame] = useState<TopGame | null>(null)
  const [gamePerformanceDialogData, setGamePerformanceDialogData] = useState<GamePerformaceDialogData>()

  const tableColumns: GRTableColumn<TopGame, typeof customTableProps>[] = [
    {
      labelAccessor: t('common:daily_ranks_text'),
      columns: [
        {
          labelAccessor: t('common:grossing_rank_shorthand'),
          accessor: ({ row: topGame }) => (
            <>
              <span className={styles['rank-value']}>{topGame.rank}</span>
              <RankChange rankNow={topGame.rank} rankPreviously={topGame.rankYesterday} />
            </>
          ),
          sortable: true,
          sortAccessor: 'rank',
          sortOrder: SortOrder.ASC,
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 } },
          defaultSortOrder: SortOrder.ASC,
        },
        {
          labelAccessor: t('common:free_rank_shorthand'),
          accessor: ({ row: topGame }) => (
            <>
              <span className={topGame.drank > maxRankValue ? `${styles['rank-value']} ${styles['rank-value--outside']}` : styles['rank-value']}>
                {topGame.drank > maxRankValue ? t('common:number_plus', { number: maxRankValue }) : topGame.drank}
              </span>
              <RankChange rankNow={topGame.drank} rankPreviously={topGame.drankYesterday} />
            </>
          ),
          sortable: true,
          sortAccessor: 'drank',
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 } },
          defaultSortOrder: SortOrder.ASC,
        },
        {
          labelAccessor: '',
          accessor: ({ row: topGame }) => (
            <GamePerformanceDialogOpenButton
              onClick={() => setGamePerformanceDialogData({ appId: topGame.appId, marketIso: topGame.market, gameName: topGame.name })}
            />
          ),
          sortable: false,
          cellProps: { align: 'center' },
          headerCellProps: { sx: { minWidth: 50 } },
        },
      ],
    },
    {
      labelAccessor: t('common:game'),
      columns: [
        {
          labelAccessor: t('common:name'),
          accessor: ({ row: topGame, rowIndex }) => (
            <Box>
              <TopGameCardContent topGame={topGame} rank={rowIndex + 1} variant="table" />
            </Box>
          ),
          sortable: true,
          sortAccessor: 'name',
          cellProps: { align: 'left' },
          headerCellProps: { sx: { minWidth: 200 } },
          defaultSortOrder: SortOrder.ASC,
        },
        {
          labelAccessor: t('common:game_power_score'),
          // TODO-NOW-FEATURE-JARKKA: Add Analyst Game Review to this cell when the component is done
          accessor: ({ row: topGame }) => (
            <Grid container alignItems="center" justifyContent="center">
              <Grid item width="40px">
                {topGame.reviewId && topGame.reviewPublished && <ReviewIndicator onClick={() => setAnalystOverviewDialogGame(topGame)} />}
              </Grid>
              <Grid item width="40px">
                <GamePowerscore value={topGame.gps} />
              </Grid>
            </Grid>
          ),
          sortable: true,
          sortAccessor: ({ row: topGame }) => topGame.gps || 0,
          cellProps: { align: 'center' },
        },
      ],
    },
    {
      labelAccessor: `${t('common:30_days_revenue')} (${t('common:apple_ios')})`,
      columns: [
        {
          labelAccessor: t('common:value_text'),
          accessor: ({ row, customTableProps }) => (
            <>
              {customTableProps?.estimatesUnlocked && customTableProps?.marketIso && (
                <CurrencyIndicator
                  estimate={new RevenueAndDownloadEstimates(row, customTableProps?.marketIso).revenue30Day}
                  maxFractionDigits={2}
                  shorten={true}
                />
              )}
              {!customTableProps?.estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) =>
            (customTableProps?.marketIso && new RevenueAndDownloadEstimates(row, customTableProps.marketIso).revenue30Day.value) || 0,
        },
        {
          labelAccessor: t('common:trend'),
          accessor: ({ row, customTableProps }) => (
            <>
              {customTableProps?.estimatesUnlocked && customTableProps?.marketIso && (
                <TrendIndicator estimate={new RevenueAndDownloadEstimates(row, customTableProps.marketIso).revenueTrend30Day} />
              )}
              {!customTableProps?.estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) =>
            customTableProps?.marketIso ? new RevenueAndDownloadEstimates(row, customTableProps.marketIso).revenueTrend30Day.value : 0,
        },
      ],
    },
    {
      labelAccessor: `${t('common:30_days_downloads')} (${t('common:apple_ios')})`,
      columns: [
        {
          labelAccessor: t('common:value_text'),
          accessor: ({ row, customTableProps }) => (
            <>
              {customTableProps?.estimatesUnlocked && customTableProps?.marketIso && (
                <CellValue value={new RevenueAndDownloadEstimates(row, customTableProps.marketIso).downloads30Day.value} emphasized>
                  {new RevenueAndDownloadEstimates(row, customTableProps?.marketIso).downloads30Day.format({ maxFractionDigits: 2, shorten: true })}
                </CellValue>
              )}
              {!customTableProps?.estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) =>
            customTableProps?.marketIso ? new RevenueAndDownloadEstimates(row, customTableProps.marketIso).downloads30Day.value : 0,
        },
        {
          labelAccessor: t('common:trend'),
          accessor: ({ row, customTableProps }) => (
            <>
              {customTableProps?.estimatesUnlocked && customTableProps?.marketIso && (
                <TrendIndicator estimate={new RevenueAndDownloadEstimates(row, customTableProps.marketIso).downloadsTrend30Day} />
              )}
              {!customTableProps?.estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) =>
            customTableProps?.marketIso ? new RevenueAndDownloadEstimates(row, customTableProps.marketIso).downloadsTrend30Day.value : 0,
        },
      ],
    },
    {
      labelAccessor: `${t('common:30_days_revenue_download_ratio')} (${t('common:apple_ios')})`,
      columns: [
        {
          labelAccessor: t('common:value_text'),
          accessor: ({ row, customTableProps }) => (
            <>
              {customTableProps?.estimatesUnlocked && customTableProps?.marketIso && (
                <CurrencyIndicator
                  estimate={new RevenueAndDownloadEstimates(row, customTableProps.marketIso).revenueAndDownloadsRatio30Day}
                  maxFractionDigits={2}
                  threshold={0}
                />
              )}
              {!customTableProps?.estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) =>
            customTableProps?.marketIso ? new RevenueAndDownloadEstimates(row, customTableProps.marketIso).revenueAndDownloadsRatio30Day.value : 0,
        },
        {
          labelAccessor: t('common:trend'),
          accessor: ({ row, customTableProps }) => (
            <>
              {customTableProps?.estimatesUnlocked && customTableProps?.marketIso && (
                <TrendIndicator estimate={new RevenueAndDownloadEstimates(row, customTableProps.marketIso).revenueAndDownloadsRatioTrend30Day} />
              )}
              {!customTableProps?.estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row, customTableProps }) =>
            customTableProps?.marketIso ? new RevenueAndDownloadEstimates(row, customTableProps.marketIso).revenueAndDownloadsRatioTrend30Day.value : 0,
        },
      ],
    },
  ]

  const customTableProps = useMemo(
    () => ({
      marketIso,
      estimatesUnlocked,
    }),
    [marketIso, estimatesUnlocked]
  )

  const [columns, setColumns] = useState<GRTableColumn<TopGame, typeof customTableProps>[]>(tableColumns)

  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<TopGame, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <>
      <TableContainer component={Card} ref={containerRef} className={styles.container}>
        <GRTable
          rows={topGames}
          columns={columns}
          striped
          hoverable
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey="id"
          noRowsLabel={t('market-explorer:no_games_available')}
          isLoading={isLoading}
          scroller={containerRef}
          customProps={customTableProps}
        />
      </TableContainer>
      {analystOverviewDialogGame && analystOverviewDialogGame.reviewId && (
        <AnalystOverviewDialog
          reviewId={analystOverviewDialogGame?.reviewId}
          gameName={analystOverviewDialogGame?.name}
          open={!!analystOverviewDialogGame}
          onClose={() => setAnalystOverviewDialogGame(null)}
        />
      )}
      {gamePerformanceDialogData && (
        <GamePerformanceDialog
          open={!!gamePerformanceDialogData}
          onClose={() => setGamePerformanceDialogData(undefined)}
          appId={gamePerformanceDialogData!.appId}
          marketIso={gamePerformanceDialogData!.marketIso}
          gameName={gamePerformanceDialogData!.gameName}
        />
      )}
    </>
  )
})
