import { Chart, ChartDataset, ChartOptions, TooltipModel } from 'chart.js'
import React, { useCallback, useMemo } from 'react'
import { Radar } from 'react-chartjs-2'

import { Box } from '@mui/material'

import { VerticalTooltipLineOptions } from '../../../../plugins/chartjs/VerticalTooltipLinePlugin'
import languageService from '../../../../services/LanguageService'
import utilsService, { ChartColorList } from '../../../../services/UtilsService'
import { MotivationType } from '../../../market-explorer/types/MotivationType'
import './MotivationRadarChart.scss'

/**
 * MotivationRadarChart show motivation radar chart
 */
export interface MotivationRadarChartProps {
  datasets: ChartDataset<'radar'>[]
  labels: string[]
  colorLists?: ChartColorList[]
  gameNotAnalyzed?: boolean
}

const MotivationRadarChart: React.FC<MotivationRadarChartProps> = ({
  labels,
  datasets = [],
  colorLists = utilsService.getChartColorList(),
  gameNotAnalyzed,
}) => {
  const colorSelector = useCallback(
    (index: number, opacity: number): string => {
      const combinedColorsList = [...colorLists, ...utilsService.getChartColorList()]
      const { r, g, b } = combinedColorsList[index]
      return `rgba(${r},${g},${b},${opacity})`
    },
    [colorLists]
  )

  const modifiedDatasets = useMemo(
    () =>
      datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor: dataset.backgroundColor || colorSelector(index, 0.05),
        borderColor: dataset.borderColor || colorSelector(index, 1),
        pointBackgroundColor: dataset.borderColor || colorSelector(index, 1),
        pointBorderColor: dataset.borderColor || colorSelector(index, 1),
        borderWidth: 1.2,
        font: 12,
        fill: true,
      })),
    [colorSelector, datasets]
  )

  const getMotivationTypeDescription = (motivationType: string) => {
    const mappedLabelsWithKeys = Object.values(MotivationType).reduce((result, type) => {
      result[languageService.getTranslation('motivations', type)] = type
      return result
    }, {} as { [key: string]: MotivationType })
    const selectedMotivationType = mappedLabelsWithKeys[motivationType]
    return selectedMotivationType ? languageService.getTranslation('motivations', mappedLabelsWithKeys[motivationType] + '_description') : ''
  }

  const data = useMemo(
    () => ({
      labels,
      datasets: modifiedDatasets,
    }),
    [labels, modifiedDatasets]
  )

  const externalTooltipHandler = (context: { chart: Chart; tooltip: TooltipModel<'radar'> }) => {
    // Tooltip Element
    let tooltipEl = document.getElementById('motivation-chart-tooltip')
    const { chart, tooltip: tooltipModel } = context

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.id = 'motivation-chart-tooltip'
      tooltipEl.innerHTML = '<div class="motivation-chart-tooltip__body"></div>'
      document.body.appendChild(tooltipEl)
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0'
      return
    }

    const root = tooltipEl.querySelector('.motivation-chart-tooltip__body')

    // create title
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || []
      let innerHTML = `<div class="motivation-chart-tooltip__title">${titleLines[0]}</div>`

      if (tooltipModel.beforeBody) {
        innerHTML += `<div class="motivation-chart-tooltip__description">${tooltipModel.beforeBody[0]}</div>`
      }

      tooltipModel.body.forEach((item, index) => {
        let color = tooltipModel.labelColors[index]
        innerHTML += `<div class="motivation-chart-tooltip__value"><div class="motivation-chart-tooltip__value__dot"><span class="dot" style="background-color:${color.backgroundColor};"></span></div>`
        innerHTML += `<div class="motivation-chart-tooltip__value__label">${item.lines[0]}</div></div>`
      })

      if (root) {
        root.innerHTML = innerHTML
      }
    }

    const position = chart.canvas.getBoundingClientRect()

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'
    tooltipEl.style.padding = tooltipModel.yAlign + 'px ' + tooltipModel.xAlign + 'px'
    tooltipEl.style.pointerEvents = 'none'
  }

  const options: ChartOptions<'radar'> & { plugins: { verticalTooltipLine?: VerticalTooltipLineOptions } } = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 0 },
    plugins: {
      tooltip: {
        enabled: false,
        external: externalTooltipHandler,
        position: 'nearest',
        mode: 'index',
        callbacks: {
          beforeBody: (context) => {
            const item = context[0]
            return getMotivationTypeDescription(item.label)
          },
        },
      },
      legend: {
        fullSize: false,
        labels: {
          pointStyle: 'circle' as const,
          usePointStyle: true,
          padding: 24,
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        position: 'bottom',
      },
    },
    scales: {
      r: {
        max: 5,
        min: 0,
        pointLabels: {
          font: {
            size: 13,
          },
        },
        ticks: {
          stepSize: 1,
          font: {
            size: 14,
          },
        },
      },
    },
  }

  if (!datasets.length) {
    return null
  }

  return (
    <Box sx={{ m: 2, height: '60vh' }} className="MotivationRadarChart__container">
      <Radar data={data} options={options} />
    </Box>
  )
}

export default MotivationRadarChart
