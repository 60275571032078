import { FC, useRef, useMemo, useState, useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Delete } from '@mui/icons-material'
import { TableContainer, Box, TextField, InputAdornment } from '@mui/material'

import ConfirmDialog from '../../../../../../components/ConfirmDialog/ConfirmDialog'
import { GRTableColumn, GRTable } from '../../../../../../components/GRTable/GRTable'
import { ConfirmDialogData } from '../../../../../../types/ConfirmDialogData'
import { ActionButton } from '../../../../../components/ActionButton/ActionButton'
import { ErrorStatusCircle } from '../../../../../components/ErrorStatusCircle/ErrorStatusCircle'
import { isNotEmptyIsNumber } from '../../../../../helpers/IsNotEmptyIsNumber'
import { Currency } from '../../../../../types/Currency'
import { EventEditForm, EventEditFormPricePoint, EventEditFormPricePoints } from '../../../types/EventEditForm'
import { PricePointSelectMenu } from '../PricePointSelectMenu/PricePointSelectMenu'

type PricePointTableProps = {
  pricePoints: EventEditFormPricePoints
  currencies: Currency[] | undefined
  onRemovePricePoint: (pricePointId: string) => void
}

type removePricePointDialogData = {
  pricePoint: EventEditFormPricePoint
}

export const PricePointTable: FC<PricePointTableProps> = ({ pricePoints, currencies, onRemovePricePoint }) => {
  const { t } = useTranslation()
  const form = useFormContext<EventEditForm>()
  const formPricePoints = form.watch('event.pricePoints')
  const containerRef = useRef(null)
  const [removePricePointDialogData, setRemovePricePointDialogData] = useState<ConfirmDialogData<removePricePointDialogData>>()

  const handleRemovePricePoint = useCallback(
    (pricePoint: EventEditFormPricePoint) => {
      setRemovePricePointDialogData({
        title: t('internal-live-events:remove_price_point_confirm_dialog_title'),
        confirmText: t('internal-live-events:remove_price_point_confirm_dialog_confirm_text'),
        actionText: t('internal-common:yes'),
        cancelText: t('internal-common:no'),
        destructiveAction: true,
        data: { pricePoint },
      })
    },
    [t]
  )

  const handleRemovePricePointConfirmed = (dialogData?: ConfirmDialogData<removePricePointDialogData>) => {
    if (dialogData?.data) {
      onRemovePricePoint(dialogData.data.pricePoint.key)
    }

    setRemovePricePointDialogData(undefined)
  }

  const isUniquePricePoint = useCallback(
    (value: string, currencyId: string) => {
      const duplicates = Object.values(formPricePoints).filter(
        (pricePoint) => pricePoint.value.trimEnd() === value.trimEnd() && pricePoint.currencyId === currencyId
      )
      return duplicates.length < 2 ? true : false
    },
    [formPricePoints]
  )

  const validPricePoints = useCallback(
    (value: string) => {
      if (!isNaN(Number(value)) && value.length !== 0) {
        return Object.values(formPricePoints).every((pricePointData) => {
          const otherPricePoints = Object.values(formPricePoints).filter(
            (filterPricePoint) => filterPricePoint.value === pricePointData.value && filterPricePoint.currencyId === pricePointData.currencyId
          )
          if (otherPricePoints.length > 1) return false
          return true
        })
      }
      return false
    },
    [formPricePoints]
  )

  const pricePointColums: GRTableColumn<EventEditFormPricePoint, typeof customProps>[] = useMemo(() => {
    return [
      {
        headerCellProps: { sx: { textAlign: 'left' } },
        labelAccessor: () => t('internal-live-events:price_point'),
        cellProps: { sx: { fontWeight: 700, textAlign: 'left' } },
        accessor: ({ row, customTableProps }) => {
          return (
            <Controller
              key={`${row.key}.value`}
              name={`event.pricePoints.${row.key}.value`}
              control={customTableProps?.form.control}
              rules={{ required: true, validate: customTableProps?.validPricePoints }}
              render={({ field }) => (
                <TextField
                  required
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  size="medium"
                  placeholder="Price Point"
                  fullWidth
                  error={!isNotEmptyIsNumber(field.value) || !customTableProps?.isUniquePricePoint(field.value, row.currencyId)}
                  InputProps={{
                    endAdornment:
                      !isNotEmptyIsNumber(field.value) || !customTableProps?.isUniquePricePoint(field.value, row.currencyId) ? (
                        <InputAdornment position="end">
                          {!isNotEmptyIsNumber(field.value) ? (
                            <ErrorStatusCircle errorText={t('internal-live-events:invalid_input')} />
                          ) : (
                            <ErrorStatusCircle errorText={t('internal-live-events:duplicate_price_point')} />
                          )}
                        </InputAdornment>
                      ) : null,
                  }}
                />
              )}
            />
          )
        },
        sortable: true,
        sortAccessor: ({ row }) => row.value,
      },
      {
        headerCellProps: { sx: { textAlign: 'left' } },
        labelAccessor: () => t('internal-common:currency'),
        cellProps: { sx: { textAlign: 'left' } },
        accessor: ({ row, customTableProps }) => {
          return (
            <Controller
              key={`${row.key}.currencyId`}
              name={`event.pricePoints.${row.key}.currencyId`}
              control={customTableProps?.form.control}
              render={({ field }) => (
                <PricePointSelectMenu
                  required
                  value={field.value || ''}
                  onChange={field.onChange}
                  currencies={customTableProps?.currencies || []}
                  autoWidth={false}
                />
              )}
            />
          )
        },
        sortable: true,
        sortAccessor: ({ row }) => row.currencyId,
      },
      {
        headerCellProps: { sx: { textAlign: 'left' } },
        labelAccessor: () => t('internal-live-events:description'),
        cellProps: { sx: { textAlign: 'left' } },
        accessor: ({ row, customTableProps }) => {
          return (
            <Controller
              key={`${row.key}.description`}
              name={`event.pricePoints.${row.key}.description`}
              control={customTableProps?.form.control}
              render={({ field }) => (
                <TextField
                  type="text"
                  sx={{
                    '.MuiInputBase-root': {
                      padding: '5px 14px',
                    },
                  }}
                  placeholder="Description"
                  value={field.value || ''}
                  multiline
                  onChange={field.onChange}
                  size="medium"
                  fullWidth
                />
              )}
            />
          )
        },
        sortable: true,
        sortAccessor: ({ row }) => row.description,
      },
      {
        labelAccessor: () => t('internal-live-events:actions'),
        accessor: ({ row, customTableProps }) => (
          <ActionButton color="warning" onClick={() => customTableProps?.handleRemovePricePoint(row)}>
            <Delete fontSize="small" />
          </ActionButton>
        ),
      },
    ]
  }, [t])

  const customProps = useMemo(
    () => ({ currencies, handleRemovePricePoint, form, isUniquePricePoint, validPricePoints }),
    [currencies, handleRemovePricePoint, form, isUniquePricePoint, validPricePoints]
  )

  const [columns, setColumns] = useState<GRTableColumn<EventEditFormPricePoint, typeof customProps>[]>(pricePointColums)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<EventEditFormPricePoint, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  const rows = useMemo(() => Object.values(formPricePoints), [formPricePoints])

  return (
    <>
      <TableContainer component={Box} ref={containerRef}>
        <GRTable
          columns={columns}
          isLoading={false}
          onColumnsUpdated={handleColumnsUpdate}
          customProps={customProps}
          rowIdKey={(pricePoint) => pricePoint.key}
          rows={rows}
          scroller={containerRef}
          noRowsLabel={t('internal-live-events:no_price_points')}
          striped
          gridlines
        />
      </TableContainer>
      <ConfirmDialog open={!!removePricePointDialogData} onClose={handleRemovePricePointConfirmed} confirmDialogData={removePricePointDialogData} />
    </>
  )
}
