/**
 * jpeg image/jpeg
webp image/webp
gif image/gif
png image/png
bmp image/bmp
 */
type ImageMimeType = 'image/jpeg' | 'image/webp' | 'image/gif' | 'image/png' | 'image/bmp'

/**
 * Resizes an image file to the specified dimensions, quality, and MIME type.
 *
 * @param file - The image file to be resized.
 * @param options - An object containing the resize options.
 * @param options.width - The desired width of the resized image. If not provided, the original width is used.
 * @param options.height - The desired height of the resized image. If not provided, the original height is used.
 * @param options.quality - The quality of the resized image, ranging from 0 to 1. Default is 1.
 * @param options.mimeType - The MIME type of the resized image. Default is 'image/jpeg'.
 *
 * @returns A promise that resolves to a data URL of the resized image.
 */

export const resize = (
  file: File,
  { width, height, quality = 1, mimeType = 'image/jpeg' }: { width?: number; height?: number; quality?: number; mimeType: ImageMimeType }
) => {
  const promise = new Promise<string>((resolve, reject) => {
    // create canvas and image elements
    var canvasElement = document.createElement('canvas')
    var imageElement = document.createElement('img')
    imageElement.setAttribute('style', 'visibility:hidden;position:fixed;z-index:-100000')
    document.body.appendChild(imageElement)

    imageElement.onload = () => {
      let imgWidth = imageElement.width
      let imgHeight = imageElement.height
      imageElement.parentNode?.removeChild(imageElement)

      try {
        if (!width || imgWidth < width) {
          width = imgWidth
        }
        if (!height || imgHeight < height) {
          height = imgHeight
        }
        var dimensions = calculateAspectRatioFit(imgWidth, imgHeight, width, height)
        canvasElement.width = Math.min(dimensions.width, width)
        canvasElement.height = Math.min(dimensions.height, height)
        var context = canvasElement.getContext('2d')
        context?.drawImage(imageElement, Math.min(0, -dimensions.marginX / 2), Math.min(0, -dimensions.marginY / 2), dimensions.width, dimensions.height)
        resolve(canvasElement.toDataURL(mimeType, quality))
      } catch (e) {
        reject(e)
      }
    }

    imageElement.onerror = () => {
      imageElement.parentNode?.removeChild(imageElement)
      reject('Error while loading image')
    }

    const reader = new FileReader()
    reader.onload = function (e) {
      imageElement.src = e.target?.result as string
    }

    reader.readAsDataURL(file)
  })
  return promise
}

/**
 * Converts a data URL to a Blob object.
 *
 * @param dataurl - The data URL to convert.
 * @returns A Blob object representing the data URL.
 */
export const dataUrltoBlob = (dataurl: string) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  var blob = new window.Blob([u8arr], { type: mime })
  return blob
}

/**
 * Retrieves the dimensions (width and height) of an image file.
 *
 * @param file - The image file for which to get the dimensions.
 * @returns A promise that resolves with an object containing the width and height of the image.
 */
export const getDimensions = (file: File) => {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    const image = new Image()
    image.src = URL.createObjectURL(file)

    image.onload = () => {
      resolve({ width: image.naturalWidth, height: image.naturalHeight })
    }

    image.onerror = (e) => {
      reject(e)
    }
  })
}

const calculateAspectRatioFit = (srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
  return {
    width: srcWidth * ratio,
    height: srcHeight * ratio,
    marginX: srcWidth * ratio - maxWidth,
    marginY: srcHeight * ratio - maxHeight,
  }
}
