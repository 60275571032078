import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import { Button, Divider, Grid } from '@mui/material'

import { useGetGamesWithFilterQuery } from '../../api/core'
import GRCircularProgress from '../../components/GRCircularProgress/GRCircularProgress'
import { LimitedFunctionalityBanner } from '../../components/LimitedFunctionalityBanner/LimitedFunctionalityBanner'
import { useCurrentUserLanguage } from '../../features/account/hooks/useCurrentUserLanguage'
import { useOwnGamesOrder } from '../../features/account/hooks/userHooks'
import { Game } from '../../features/game'
import GameStage, { Stage } from '../../features/game-analyzer/components/GameStage/GameStage'
import { useAllowAddGame } from '../../features/game-analyzer/hooks/useAllowAddGame'
import GameAddNewModal from '../../features/game/components/GameAddNewModal/GameAddNewModal'
import GameCloneDialog from '../../features/game/components/GameCloneDialog/GameCloneDialog'
import GameEditModal from '../../features/game/components/GameEditModal/GameEditModal'
import { useAvailabelGameSlotsCount } from '../../features/game/hooks/gameHooks'
import { GameStageId } from '../../features/game/types/GameStageId'
import { useCurrentMarket } from '../../features/markets'
import { displaySnackBar } from '../../features/snackbar/actions/displaySnackBar'
import { SnackBarMessage } from '../../features/snackbar/types/SnackBarMessage'
import { useAppDispatch } from '../../hooks/storeHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import analyticsService from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import { LockedFeatureId } from '../../types/LockedFeature'
import './GameAnalyzerPage.scss'

const DEFAULT_STAGE = GameStageId.concept.toString()

enum Stages {
  CONCEPTS = 'concepts',
  PRODUCTION = 'production',
  LIVE = 'live',
}

type gameListStageType = { [key in Stages]: Game[] }

const GameAnalyzerPage: React.FC = () => {
  const { t } = useTranslation()
  usePage(PageService.getPageWithId('game-analyzer'), 'Visited My Games List')
  useDocumentTitle(t('sidebar:game_analyzer'))
  const navigate = useNavigate()

  const { currentMarketIso: marketIso } = useCurrentMarket()
  const userLanguage = useCurrentUserLanguage()
  const [showAddGameModal, setShowAddGameModal] = useState(false)
  const [currentStage, setCurrentStage] = useState(DEFAULT_STAGE)
  const [editGame, setEditGame] = useState<Game | undefined>()
  const [cloneGame, setCloneGame] = useState<Game | undefined>()
  const ownGamesOrder = useOwnGamesOrder()
  const dispatch = useAppDispatch()
  const showError = useCallback((errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload)), [dispatch])
  const availabelGameSlotsCount = useAvailabelGameSlotsCount() || 0

  const { data: games, isLoading } = useGetGamesWithFilterQuery({
    filter: [],
    include: [],
    marketIso: marketIso,
    userLanguage,
  })

  const allowAddMoreGame = useAllowAddGame(games?.length)

  const gameListStage = useMemo((): gameListStageType => {
    const categorizedGames: gameListStageType = {
      [Stages.CONCEPTS]: [],
      [Stages.PRODUCTION]: [],
      [Stages.LIVE]: [],
    }
    if (!games) {
      return categorizedGames
    }

    // sort games by ownOrder
    const sortedGames = [...games]
      .sort((a, b) => a.resolvedName.localeCompare(b.resolvedName))
      .sort((a, b) => ownGamesOrder.indexOf(a.id) - ownGamesOrder.indexOf(b.id))

    sortedGames.forEach((game: Game) => {
      switch (game.stageId) {
        case GameStageId.concept:
          categorizedGames[Stages.CONCEPTS].push(game)
          break
        case GameStageId.production:
          categorizedGames[Stages.PRODUCTION].push(game)
          break
        case GameStageId.live:
        default:
          categorizedGames[Stages.LIVE].push(game)
      }
    })

    return categorizedGames
  }, [games, ownGamesOrder])

  const gameStages: Stage[] = [
    {
      title: t('game-analyzer:my_portfolio_concepts'),
      games: gameListStage[Stages.CONCEPTS],
      id: GameStageId.concept,
    },
    {
      title: t('game-analyzer:my_portfolio_productions'),
      games: gameListStage[Stages.PRODUCTION],
      id: GameStageId.production,
    },
    {
      title: t('game-analyzer:my_portfolio_lives'),
      games: gameListStage[Stages.LIVE],
      id: GameStageId.live,
    },
  ]

  const addNewModalStages = gameStages.map((stage) => ({ label: stage.title, id: stage.id }))

  const addMoreGame = (stageId?: number) => {
    setCurrentStage(stageId?.toString() || DEFAULT_STAGE)
    setShowAddGameModal(true)
  }

  const onNewGameCreated = (newGame: Game) => {
    setShowAddGameModal(false)
    navigate(`/game-analyzer/${newGame.id}`)
  }

  const onGameSettingClick = (game: Game) => {
    setEditGame(game)
  }

  const onGameEditDone = (game: Game) => {
    analyticsService.trackEvent('Modified game', { data: { gameName: game.resolvedName, gameId: game.id } })
    setEditGame(undefined)
  }

  const onGameCloneClick = (game: Game) => {
    if (availabelGameSlotsCount > 0) {
      setCloneGame(game)
    } else {
      showError({ message: t('game-header:clone_error_body_no_slot'), severity: 'warning', open: true })
    }
  }

  if (isLoading) return <GRCircularProgress />
  return (
    <>
      <GameAddNewModal
        open={showAddGameModal}
        onClose={() => setShowAddGameModal(false)}
        onNewGameCreated={onNewGameCreated}
        selectedStage={currentStage}
        stages={addNewModalStages}
      />
      {!!cloneGame && <GameCloneDialog originGame={cloneGame} open={!!cloneGame} onClose={() => setCloneGame(undefined)} />}
      {!!editGame && (
        <GameEditModal onGameEditDone={onGameEditDone} game={editGame} open={!!editGame} onClose={() => setEditGame(undefined)} stages={addNewModalStages} />
      )}

      <Grid container spacing={2} alignItems="center" justifyContent="space-between" mb={2}>
        <Grid item>
          <h2 className="GameAnalyzer__title">{`${t('game-analyzer:my_portfolio_title')} (${t('common:game_count', {
            count: games ? games.length : 0,
          })})`}</h2>
        </Grid>
        <Grid item>
          <Button disabled={!allowAddMoreGame} startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => addMoreGame()}>
            {t('common:add_game')}
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

      {!allowAddMoreGame && (
        <>
          <Grid item>
            <LimitedFunctionalityBanner lockedFeatureId={LockedFeatureId.GameUnlock} />
          </Grid>
          <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
        </>
      )}

      <Grid className="GameAnalyzer__game-list-container" container spacing={2} data-testid="big-container">
        {gameStages.map((stage, index) => (
          <GameStage
            allowAddMoreGame={allowAddMoreGame}
            key={stage.id}
            onAddMoreGameClick={addMoreGame}
            onGameSettingClick={onGameSettingClick}
            onGameCloneClick={onGameCloneClick}
            gridProps={{ item: true, xs: 4 }}
            stage={stage}
            showDivider={index < gameStages.length - 1}
          />
        ))}
      </Grid>
    </>
  )
}

export default GameAnalyzerPage
