import { add, endOfDay } from 'date-fns'
import { useMemo } from 'react'

import { useInternalAccessCheck } from '../../account/hooks/roleHooks'

export const useTrackedEventsEndTimestamp = (endTimestamp: number) => {
  const internalRoleCheck = useInternalAccessCheck()

  const endTimestampBasedOnInternalRole = useMemo(() => {
    if (internalRoleCheck) {
      return add(endOfDay(new Date()), { years: 1 }).getTime()
    } else {
      return endTimestamp
    }
  }, [internalRoleCheck, endTimestamp])

  return endTimestampBasedOnInternalRole
}
