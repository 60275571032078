import { useMemo } from 'react'

import { useSearchParams } from '../../../hooks/useSearchParams'
import marketExplorerService from '../../../services/MarketExplorerService'
import { SubgenreMap } from '../../account'
import { maxSegmentCount } from '../constants/constants'
import { FeaturesDataTableColumnType, FeaturesDataTableColumnValues } from '../types/MarketExplorerFeaturesDataTableColumn'
import {
  MarketExplorerGamesChartAxisType,
  MarketExplorerGamesChartAxisScale,
  MarketExplorerGamesChartAxisTypeId,
} from '../types/MarketExplorerGamesChartAxisType'
import { GamesDataTableColumnType, GamesDataTableColumnValues } from '../types/MarketExplorerGamesDataTableColumns'
import {
  DemographicsFilterField,
  FeatureChoiceMap,
  MarketExplorerSegmentConfiguration,
  MarketExplorerSegmentFilterValue,
  PlayerArchetypesFilterField,
  RanksFilterField,
} from '../types/MarketExplorerSegmentConfiguration'
import { MotivationType } from '../types/MotivationType'

type SearchParamSource = { [key: string]: any } & {
  chartYAxisType: string
  chartYAxisMax: string
  chartYAxisMin: string
  chartXAxisType: string
  chartXAxisMax: string
  chartXAxisMin: string
  gameDataColumns: string[]
  featureDataColumns: string[]
}

export type SearchParamDestination = {
  chartYAxisType: MarketExplorerGamesChartAxisType
  chartYAxisMax: MarketExplorerGamesChartAxisScale
  chartYAxisMin: MarketExplorerGamesChartAxisScale
  chartXAxisType: MarketExplorerGamesChartAxisType
  chartXAxisMax: MarketExplorerGamesChartAxisScale
  chartXAxisMin: MarketExplorerGamesChartAxisScale
  segments: MarketExplorerSegmentConfiguration[]
  gameDataColumns: GamesDataTableColumnType[]
  featureDataColumns: FeaturesDataTableColumnType[]
}

const chartAxesTypes: MarketExplorerGamesChartAxisType[] = marketExplorerService.gamesChartAxes.reduce((typesArray, chartAxis) => {
  return [...typesArray, ...chartAxis.types]
}, [] as MarketExplorerGamesChartAxisType[])
const defaultChartYAxis = chartAxesTypes.find((type) => type.id === MarketExplorerGamesChartAxisTypeId.SGR) || chartAxesTypes[0]
const defaultChartXAxis = chartAxesTypes.find((type) => type.id === MarketExplorerGamesChartAxisTypeId.SDR) || chartAxesTypes[1]

export const useMarketExplorerSearchParams = () => {
  const mapper = useMemo(() => {
    return {
      mapFromUrl: (source: SearchParamSource) => {
        return {
          ...source,
          chartYAxisType: chartAxesTypes.find((axisType) => axisType.id === source.chartYAxisType) || defaultChartYAxis,
          chartYAxisMin: marketExplorerService.gamesChartAxes.reduce((acc, axis) => {
            const axisType = axis.types.find((axisType) => axisType.id === (source.chartYAxisType || defaultChartYAxis.id))
            if (axisType) {
              const parsedSourceValue = source.chartYAxisMin
              return axisType.minScales.find((minScale) => minScale.label === parsedSourceValue) || axisType.defaultMinScale
            }

            return acc
          }, {} as MarketExplorerGamesChartAxisScale),
          chartYAxisMax: marketExplorerService.gamesChartAxes.reduce((acc, axis) => {
            const axisType = axis.types.find((axisType) => axisType.id === (source.chartYAxisType || defaultChartYAxis.id))
            if (axisType) {
              const parsedSourceValue = source.chartYAxisMax
              return axisType.maxScales.find((maxScale) => maxScale.label === parsedSourceValue) || axisType.defaultMaxScale
            }

            return acc
          }, {} as MarketExplorerGamesChartAxisScale),

          chartXAxisType: chartAxesTypes.find((axisType) => axisType.id === source.chartXAxisType) || defaultChartXAxis,
          chartXAxisMin: marketExplorerService.gamesChartAxes.reduce((acc, axis) => {
            const axisType = axis.types.find((axisType) => axisType.id === (source.chartXAxisType || defaultChartXAxis.id))
            if (axisType) {
              const parsedSourceValue = source.chartXAxisMin
              return axisType.minScales.find((minScale) => minScale.label === parsedSourceValue) || axisType.defaultMinScale
            }

            return acc
          }, {} as MarketExplorerGamesChartAxisScale),
          chartXAxisMax: marketExplorerService.gamesChartAxes.reduce((acc, axis) => {
            const axisType = axis.types.find((axisType) => axisType.id === (source.chartXAxisType || defaultChartXAxis.id))
            if (axisType) {
              const parsedSourceValue = source.chartXAxisMax
              return axisType.maxScales.find((maxScale) => maxScale.label === parsedSourceValue) || axisType.defaultMaxScale
            }

            return acc
          }, {} as MarketExplorerGamesChartAxisScale),
          segments: [...Array(maxSegmentCount).keys()]
            .map((segmentIndex) => mapSegmentConfigurationFromUrlObject(source, segmentIndex))
            .filter((val) => val) as MarketExplorerSegmentConfiguration[],
          gameDataColumns: Object.values(GamesDataTableColumnValues).filter((columnId) => source.gameDataColumns?.includes(columnId)),
          featureDataColumns: Object.values(FeaturesDataTableColumnValues).filter((columnId) => source.featureDataColumns?.includes(columnId)),
        }
      },
      mapToUrl: (destination: SearchParamDestination) => {
        const mappedSegments = destination.segments.reduce((acc, segmentConfiguration, index) => {
          const mappedSegment = mapSegmentConfigurationToUrlObject(segmentConfiguration, index)

          return {
            ...mappedSegment,
            ...acc,
          }
        }, {} as { [key: string]: any })

        return {
          // when delete, destination redundancies still exist, it does not disappear, so it prevents the UI to update correct segments for market explorer
          // GR-1201
          // ...destination,
          chartYAxisType: destination.chartYAxisType?.id,
          chartYAxisMin: destination.chartYAxisMin?.label,
          chartYAxisMax: destination.chartYAxisMax?.label,
          chartXAxisType: destination.chartXAxisType?.id,
          chartXAxisMin: destination.chartXAxisMin?.label,
          chartXAxisMax: destination.chartXAxisMax?.label,
          gameDataColumns: destination.gameDataColumns || [],
          featureDataColumns: destination.featureDataColumns || [],
          ...mappedSegments,
        }
      },
    }
  }, [])

  return useSearchParams<SearchParamSource, SearchParamDestination>(mapper)
}

const filterFieldNames = ['ranks', 'demographics', 'motivations', 'archetypes'] as (keyof MarketExplorerSegmentConfiguration['filters'])[]

// maps (flattens) a segment configuration object to an object that is serializable to url query params
export const mapSegmentConfigurationToUrlObject = (segmentConfiguration: MarketExplorerSegmentConfiguration, segmentIndex: number) => {
  return {
    [`s${segmentIndex}visible`]: segmentConfiguration.visible,
    [`s${segmentIndex}marketIso`]: segmentConfiguration.marketIso,
    [`s${segmentIndex}ownGames`]: segmentConfiguration.ownGames,
    [`s${segmentIndex}gameIds`]: segmentConfiguration.gameIds && segmentConfiguration.gameIds.length > 0 ? segmentConfiguration.gameIds : undefined,
    [`s${segmentIndex}subgenres`]:
      segmentConfiguration.subgenres && Object.keys(segmentConfiguration.subgenres).length > 0
        ? Object.entries(segmentConfiguration.subgenres)
            .filter(([subgenreId, selected]) => selected)
            .map(([subgenreId, selected]) => subgenreId)
            .join(',')
        : undefined,
    [`s${segmentIndex}features`]:
      segmentConfiguration.featureChoices && Object.keys(segmentConfiguration.featureChoices).length > 0
        ? Object.entries(segmentConfiguration.featureChoices).map(([featureId, choiceIds]) => [featureId, ...choiceIds].join('-'))
        : undefined,
    ...filterFieldNames.reduce((allFilters, filterFieldName) => {
      const filters = Object.entries(segmentConfiguration.filters[filterFieldName] || {}).reduce((acc, [filterField, filterValue]) => {
        if (filterValue) {
          acc[`s${segmentIndex}${filterFieldName}${filterField}`] = filterValue.join('-')
        }
        return acc
      }, {} as { [key: string]: string })

      return {
        ...filters,
        ...allFilters,
      }
    }, {} as { [key: string]: string }),
  }
}

// re-constructs a segment configuration object from a flat serializable object read from url query params
export const mapSegmentConfigurationFromUrlObject = (parsedSegments: any, segmentIndex: number): MarketExplorerSegmentConfiguration | undefined => {
  const hasMappingForSegment = Object.keys(parsedSegments).filter((key) => key.startsWith(`s${segmentIndex}`)).length > 0

  if (hasMappingForSegment) {
    const visible = parsedSegments[`s${segmentIndex}visible`] === 'true' ? true : false
    const ownGames = parsedSegments[`s${segmentIndex}ownGames`] === 'true' ? true : false

    const subgenresArray = asArray<string>(parsedSegments[`s${segmentIndex}subgenres`])
    const gameIdsArray = asArray<string>(parsedSegments[`s${segmentIndex}gameIds`])
    const featureChoicesArray = asArray<string>(parsedSegments[`s${segmentIndex}features`])

    return {
      visible: visible,
      marketIso: parsedSegments[`s${segmentIndex}marketIso`] as string,
      filters: {
        ranks: mapFilterValues<RanksFilterField>(Object.values(RanksFilterField), 'ranks', segmentIndex, parsedSegments),
        demographics: mapFilterValues<DemographicsFilterField>(Object.values(DemographicsFilterField), 'demographics', segmentIndex, parsedSegments),
        motivations: mapFilterValues<MotivationType>(Object.values(MotivationType), 'motivations', segmentIndex, parsedSegments),
        archetypes: mapFilterValues<PlayerArchetypesFilterField>(Object.values(PlayerArchetypesFilterField), 'archetypes', segmentIndex, parsedSegments),
      },
      subgenres: subgenresArray?.reduce((acc, subgenreId) => {
        acc[subgenreId] = true

        return acc
      }, {} as SubgenreMap),
      gameIds: gameIdsArray,
      featureChoices: featureChoicesArray?.reduce((acc, featureChoices) => {
        const [featureId, ...choiceIds] = featureChoices.split('-')
        acc[+featureId] = choiceIds.map((choiceId) => +choiceId)

        return acc
      }, {} as FeatureChoiceMap),
      ownGames: ownGames,
    }
  }
}

const mapFilterValues = <T extends string>(
  fields: T[],
  filterGroupName: (typeof filterFieldNames)[number],
  segmentIndex: number,
  parsedSegments: { [key: string]: any }
) => {
  return fields.reduce((acc, fieldName) => {
    const filterValue = parsedSegments[`s${segmentIndex}${filterGroupName}${fieldName}`] as string
    if (filterValue) {
      acc[fieldName] = filterValue.split('-').map((val) => +val)
    }

    return acc
  }, {} as { [key in T]?: MarketExplorerSegmentFilterValue })
}

const asArray = <T>(items?: T[]): T[] | undefined => {
  return Array.isArray(items) ? items : items ? [items] : undefined
}
