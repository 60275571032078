import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BuildIcon from '@mui/icons-material/Build'
import EditIcon from '@mui/icons-material/Edit'
import { Dialog, DialogContent, Fab, Grid, TableContainer, Tooltip, Typography } from '@mui/material'

import { ExportDataButton } from '../../../../components/ExportDataButton/ExportDataButton'
import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { HtmlContentParser } from '../../../../components/HtmlContentParser/HtmlContentParser'
import IAPRevenueImpact from '../../../../components/IAPRevenueImpact/IAPRevenueImpact'
import { screenshotsModalParserOptions } from '../../../../components/Review/Review'
import languageService from '../../../../services/LanguageService'
import FeatureChoice from '../../../feature/components/FeatureChoice/FeatureChoice'
import { FeatureLabelLink, FeatureLink } from '../../../feature/components/FeatureLink'
import { GameFeatureTableRow } from '../../types/types'
import { CategoryImage } from '../CategoryImage/CategoryImage'

type GameFeaturesTableProps = {
  rows: GameFeatureTableRow[]
  isLoading?: boolean
  gameId: string
  isOwnGame?: boolean
}

export const GameFeaturesTable: FC<GameFeaturesTableProps> = ({ rows, isLoading, gameId, isOwnGame }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)

  const customProps = useMemo(() => {
    return {}
  }, [])

  const [noteContent, setNoteContent] = useState<GameFeatureTableRow | undefined>()

  const initialColumns: GRTableColumn<GameFeatureTableRow, typeof customProps>[] = useMemo(() => {
    return [
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('common:feature'),
            accessor: ({ row }) => {
              return (
                <Grid container alignItems="center" gap={2}>
                  <Grid item ml={1}>
                    <GRTooltip
                      content={
                        <>
                          <Typography variant="body1">{row.categoryName}</Typography>
                          <Typography variant="body2">
                            {t('gps-breakdown:effect_on_gps')}: {row.categoryGpsEffect.toFixed(2)}
                          </Typography>
                        </>
                      }
                    >
                      <CategoryImage src={row.categoryIcon} alt={row.categoryName} />
                    </GRTooltip>
                  </Grid>
                  <Grid item flexShrink={1}>
                    <FeatureLabelLink
                      choice={{ ...row, name: languageService.getTranslation('choices', row.choiceLegacyId.toString()) }}
                      feature={{
                        featureName: languageService.getTranslation('features', row.featureLegacyId.toString()),
                        featureLegacyId: row.featureLegacyId,
                      }}
                    />
                  </Grid>
                </Grid>
              )
            },
            sortable: true,
            sortAccessor: ({ row }) => row.featureName,
            defaultSortOrder: SortOrder.ASC,
            headerCellProps: { sx: { minWidth: 250 } },
            cellProps: { align: 'left' },
          },
        ],
      },
      {
        columns: [
          {
            labelAccessor: t('feature:feature_revenue_impact'),
            accessor: ({ row }) => {
              return (
                <>
                  <FeatureLink
                    feature={{ featureLegacyId: row.featureLegacyId, featureName: row.featureName }}
                    choice={{ name: row.choiceName, originalChoiceName: row.originalChoiceName, choiceLegacyId: row.choiceLegacyId }}
                    initialTab="facts"
                  >
                    <GRTooltip content={row.choiceName}>
                      <FeatureChoice
                        choiceLocalized={row.choiceName}
                        originalChoice={row.originalChoiceName || row.choiceName}
                        visualizeAsNoType={true}
                      ></FeatureChoice>
                    </GRTooltip>
                  </FeatureLink>
                  <IAPRevenueImpact dotValue={row.gpsEffect} showZeroRating />
                </>
              )
            },
            sortable: true,
            sortAccessor: ({ row }) => row.gpsEffect,
            sortOrder: SortOrder.DESC,
            headerCellProps: { sx: { minWidth: 200 } },
          },
        ],
      },
      {
        columns: [
          {
            labelAccessor: <ExportDataButton analyticsEventOrigin="Game Features Table" hide />,
            accessor: ({ row }) => (
              <>
                {row.screenshotCount > 0 && (
                  <FeatureLink initialTab="implementation" feature={{ featureLegacyId: row.featureLegacyId, featureName: row.featureName }}>
                    <Fab color="primary" size="small" sx={{ whiteSpace: 'nowrap', mr: 0.5, boxShadow: (theme) => theme.shadows[2] }}>
                      <Tooltip title={t('common:open_feature_implementation') as string}>
                        <BuildIcon />
                      </Tooltip>
                    </Fab>
                  </FeatureLink>
                )}
                {row.note && isOwnGame && (
                  <Fab
                    color="primary"
                    size="small"
                    onClick={() => {
                      setNoteContent(row)
                    }}
                    sx={{ whiteSpace: 'nowrap', boxShadow: (theme) => theme.shadows[2] }}
                  >
                    <Tooltip title={t('overview:notes') as string}>
                      <EditIcon />
                    </Tooltip>
                  </Fab>
                )}
              </>
            ),
            headerCellProps: { sx: { width: 120, minWidth: 120 } },
          },
        ],
      },
    ] as GRTableColumn<GameFeatureTableRow, typeof customProps>[]
  }, [isOwnGame, t])

  const [columns, setColumns] = useState<GRTableColumn<GameFeatureTableRow, typeof customProps>[]>(initialColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<GameFeatureTableRow, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <>
      <TableContainer ref={containerRef}>
        <GRTable
          columns={columns}
          rows={rows}
          scroller={containerRef}
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey="featureId"
          isLoading={isLoading}
          hoverable
          striped
        />
      </TableContainer>
      <Dialog open={!!noteContent}>
        <GRDialogTitle onClose={() => setNoteContent(undefined)}>{`${t('common:title_notes_for_feature', {
          name: noteContent?.featureName,
        })}`}</GRDialogTitle>
        <DialogContent className="GameFeaturesTable__DialogContent" dividers>
          {noteContent && <HtmlContentParser rawHtml={noteContent.note} parserOptions={screenshotsModalParserOptions} />}
        </DialogContent>
      </Dialog>
    </>
  )
}
