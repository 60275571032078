import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Add } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import './FtueVideoPlaceholder.scss'

const FtueVideoPlaceholder: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  return (
    <Box className="FtueVideoPlaceholder">
      {children || (
        <>
          <Add />
          <Typography>{t('common:select_game_from_list')}</Typography>
        </>
      )}
    </Box>
  )
}

export default FtueVideoPlaceholder
