import { FC, ReactNode } from 'react'
import { Trans } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'

import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { OverlayLoader } from '../../../../../components/OverlayLoader/OverlayLoader'
import { TimelineChart } from '../../../../../components/TimelineChart/TimelineChart'
import { useLiveEventsEventStatisticsPerformanceChartData } from '../../../hooks/useLiveEventsEventStatisticsPerformanceChartData'

type LiveEventsEventStatisticsPerformanceTabProps = {
  eventTypeId: string
  trackedGameIds: string[]
  children?: ReactNode
}

export const LiveEventsEventStatisticsPerformanceTab: FC<LiveEventsEventStatisticsPerformanceTabProps> = ({ eventTypeId, trackedGameIds }) => {
  const { datasets, scaleConfig, isLoading } = useLiveEventsEventStatisticsPerformanceChartData({ eventTypeId, trackedGameIds })

  return (
    <Card>
      <CardHeader
        title={
          <Grid container spacing={1}>
            <Grid item>
              <Trans i18nKey="common:revenue_text" />
            </Grid>
            <Grid item>
              <GRTooltip content={<Trans i18nKey="live-events:event_statistics_dialog_title_performance_revenue_tooltip" />}>
                <Info color="primary" />
              </GRTooltip>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <OverlayLoader isLoading={isLoading} disableShrink>
          <TimelineChart chartLineOptions={{ responsive: true, maintainAspectRatio: true }} data={{ datasets: datasets }} scaleConfig={scaleConfig} />
        </OverlayLoader>
      </CardContent>
    </Card>
  )
}
