import { FC, ReactNode } from 'react'

import { FadeProps, Box, Fade, Grid } from '@mui/material'

/**
 * Component which draws a transparent layer with content on top of a component.
 * Can be useful with e.g. charts that are loading data or do not contain any data
 */
export const Overlay: FC<{ content: ReactNode; children?: ReactNode } & Omit<FadeProps, 'children' | 'content'>> = ({ content, children, ...fadeProps }) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Fade in timeout={300} {...fadeProps}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ position: 'absolute', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.75)' }}
        >
          {content}
        </Grid>
      </Fade>
      {children}
    </Box>
  )
}
