import { startOfDay, add, differenceInDays } from 'date-fns'
import { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Add } from '@mui/icons-material'
import { Box, Grid } from '@mui/material'

import { ActionButton } from '../../../../../components/ActionButton/ActionButton'
import { DatePicker } from '../../../../../components/DatePicker/DatePicker'
import { isValidEventDuration } from '../../../helpers/eventDuration'
import { EventEditFormDurations } from '../../../types/EventEditForm'
import { InvalidDurationIndicator } from '../InvalidDurationIndicator/InvalidDurationIndicator'

type EventDurationAdderProps = {
  durations: EventEditFormDurations
  onAddDuration: (duration: { start: Date; end: Date }) => void
}
export const EventDurationAdder: FC<EventDurationAdderProps> = ({ durations, onAddDuration }) => {
  const { t } = useTranslation()
  const defaultValues = useMemo(() => {
    const defaultStart = startOfDay(new Date())
    const defaultEnd = add(defaultStart, { weeks: 1 })
    return { start: defaultStart, end: defaultEnd }
  }, [])

  const { control, getValues, watch } = useForm<{ start: Date; end: Date }>({
    defaultValues,
  })

  const isValidDuration = isValidEventDuration({ start: getValues().start, end: getValues().end }, Object.values(durations))
  const formData = watch()
  const daysBetween = differenceInDays(formData.end, formData.start) + 1

  const handleAddDuration = () => {
    onAddDuration({ start: formData.start, end: formData.end })
  }

  return (
    <Grid container spacing={2} alignItems="center" wrap="wrap">
      <Grid item xs={6} md={4.8}>
        <Controller
          name="start"
          control={control}
          render={({ field }) => {
            return (
              <DatePicker
                {...field}
                slotProps={{
                  textField: {
                    label: t('internal-live-events:start_date_input_label'),
                    fullWidth: true,
                    size: 'small',
                    inputProps: { sx: { boxSizing: 'content-box !important' } },
                  },
                }}
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={6} md={4.5}>
        <Controller
          name="end"
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              slotProps={{
                textField: {
                  label: t('internal-live-events:end_date_input_label'),
                  fullWidth: true,
                  size: 'small',
                  inputProps: { sx: { boxSizing: 'content-box !important' } },
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Box sx={{ verticalAlign: 'middle', display: 'inline' }}>{t('internal-common:day', { count: daysBetween })}</Box>
        {!isValidDuration && <InvalidDurationIndicator />}
      </Grid>
      <Grid item sx={{ ml: 'auto' }}>
        <ActionButton onClick={() => handleAddDuration()} disabled={!isValidDuration}>
          <Add fontSize="small" />
        </ActionButton>
      </Grid>
    </Grid>
  )
}
