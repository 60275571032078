/**
 * Check if two DOM element layouts overlap
 */
export const elementsOverlap = (el1: Element, el2: Element) => {
  const domRect1 = el1.getBoundingClientRect()
  const domRect2 = el2.getBoundingClientRect()

  return !(domRect1.top > domRect2.bottom || domRect1.right < domRect2.left || domRect1.bottom < domRect2.top || domRect1.left > domRect2.right)
}

export const htmlDecode = (text: string, mime: DOMParserSupportedType = 'text/html') => {
  const decoded = new DOMParser().parseFromString(text, mime)
  return decoded.documentElement.textContent
}
