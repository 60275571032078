import type { IAnalyticsProvider } from '../types/IAnalyticsProvider'
import { AnalyticsProviders } from '../types/IAnalyticsProvider'
import type { IHubSpotTrackEventOptions } from './AnalyticsService'

class HubSpotService implements IAnalyticsProvider {
  type: AnalyticsProviders = AnalyticsProviders.hubspot
  enabled: boolean = process.env.REACT_APP_HUBSPOT_ENABLED === 'true' ? true : false
  hsq: any = null

  constructor() {
    if (this.enabled) {
      this.hsq = window._hsq = window._hsq || []
    }
  }

  trackEvent(eventName: string, options?: IHubSpotTrackEventOptions): void {
    if (this.enabled && this.hsq) {
      if (!options?.hubspotInternalName) {
        // Behavioral Events (Legacy)
        this.hsq.push([
          'trackEvent',
          {
            id: `GameRefinery: ${eventName}`,
          },
        ])
      } else {
        // Behavioral Events (Beta)
        this.hsq.push([
          'trackCustomBehavioralEvent',
          {
            name: options.hubspotInternalName,
            properties: options?.data,
          },
        ])
      }
    }
  }
}

const hubspotService = new HubSpotService()

export default hubspotService
