import { t } from 'i18next'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import { Tag } from '../../../../../api/core'
import GRDialogTitle from '../../../../../components/GRDialogTitle/GRDialogTitle'
import { TagList } from '../../../../feature/components/TagList/TagList'
import { ExpandedTagGroup } from '../../../../implementation-examples'

/**
 * Displays a dialog component for selecting feature groups
 */
type FeatureGroupDialogProps = {
  open: boolean
  onClose: (tags?: Tag[]) => void
  tagGroups?: ExpandedTagGroup[]
  selectedTags?: Tag[]
  children?: ReactNode
}

export const FeatureGroupDialog: FC<FeatureGroupDialogProps> = ({ open, onClose, tagGroups, selectedTags = [] }) => {
  const [tags, setTags] = useState<Tag[]>(selectedTags)

  useEffect(() => {
    if (open) {
      setTags(selectedTags)
    }
  }, [selectedTags, open])

  const allTags = tagGroups?.map(({ tags }) => tags).flat() || []

  return (
    <Dialog open={open} maxWidth="md" className="FeatureDialog">
      <GRDialogTitle onClose={() => onClose()}>{t('feature-select-modal:select_features')}</GRDialogTitle>
      <DialogContent dividers>
        <TagList tagGroups={tagGroups} selectedTags={tags} onSelectAll={() => setTags(allTags)} onChange={setTags} onClear={() => setTags([])} />
      </DialogContent>
      <DialogActions>
        <Typography variant="body1" mr={2}>
          <Trans i18nKey="common:items_selected_count" values={{ count: `${tags.length}/${allTags.length}` }} />
        </Typography>
        <Button variant="contained" color="primary" onClick={() => onClose(tags)}>
          {t('common:done')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
