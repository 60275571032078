/**
 * Generates a random wait time between 0 and the specified maximum wait time in milliseconds,
 * and returns a promise that resolves after the wait time has elapsed.
 *
 * @param maxWait - The maximum wait time in milliseconds. Defaults to 1000 ms.
 * @returns A promise that resolves after a random wait time.
 */
export const randomWait = (maxWait: number = 1000) => {
  const waitTime = Math.random() * maxWait // Generate a random wait time between 0 and maxWait ms
  return new Promise((resolve) => setTimeout(resolve, waitTime))
}
