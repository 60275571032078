import { areIntervalsOverlapping, endOfDay, Interval, startOfDay } from 'date-fns'

import { isValidInterval } from '../../../../helpers/date'
import { EventEditFormDuration, EventEditFormDurations } from '../types/EventEditForm'

/**
 * Checks if a list of durations contains overlapping durations
 */
//TODO move this check to BE on quick add button click?
export const hasOverlappingDurations = (durations: EventEditFormDurations) => {
  return !Object.values(durations).every((durationToCheck) => {
    const otherDurations = Object.values(durations).filter((duration) => duration !== durationToCheck)
    return !durationOverlapsDurations({ start: durationToCheck.start, end: durationToCheck.end }, otherDurations)
  })
}

/**
 * Checks if a duration interval is valid against an array of durations.
 */
export const isValidEventDuration = (duration: Interval, durations: EventEditFormDuration[] = []): boolean => {
  return isValidInterval(duration) && !durationOverlapsDurations(duration, durations)
}

/**
 * Checks if a duration interval overlaps with an array of durations.
 */
export const durationOverlapsDurations = (duration: Interval, durations: EventEditFormDuration[]): boolean => {
  return !durations.every((d) => {
    const refDuration = { start: startOfDay(duration.start), end: endOfDay(duration.end) }
    return !areIntervalsOverlapping(refDuration, { start: startOfDay(d.start), end: endOfDay(d.end) })
  })
}
