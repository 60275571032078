import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import GRDotRating from '../GRDotRating/GRDotRating'

interface Props {
  dotValue: number
  showZeroRating?: boolean
}

const IAPRevenueImpact: React.FC<Props> = ({ dotValue, showZeroRating = false }) => {
  const { t } = useTranslation()

  const roundedValue = useMemo(() => {
    return dotValue > 0 ? Math.round(dotValue * 100) / 100 : 0
  }, [dotValue])

  const adjustedValue = useMemo(() => {
    if (showZeroRating && roundedValue === 0) {
      return 0
    } else if (roundedValue > 0.002 && roundedValue <= 0.15) {
      return 1
    } else if (roundedValue > 0.15 && roundedValue <= 0.399) {
      return 2
    } else if (roundedValue > 0.399 && roundedValue <= 0.599) {
      return 3
    } else if (roundedValue > 0.599 && roundedValue <= 0.9) {
      return 4
    } else if (roundedValue > 0.9 && roundedValue <= 1.5) {
      return 5
    } else if (roundedValue > 1.5) {
      return 6
    }
  }, [roundedValue, showZeroRating])

  const IAPRevenueDescriptiveText = useMemo(() => {
    if (showZeroRating && adjustedValue === 0) {
      return ''
    }
    if (adjustedValue) {
      switch (adjustedValue) {
        case 1:
        case 2:
          return t('feature:impact_low')
        case 3:
        case 4:
          return t('feature:impact_moderate')
        case 5:
        case 6:
          return t('feature:impact_high')
        default:
          return ''
      }
    }

    return undefined
  }, [adjustedValue, showZeroRating, t])

  return (
    <div className="IAPRevenueImpact">
      {adjustedValue || showZeroRating ? (
        <GRDotRating
          showZeroRating={showZeroRating}
          dotValue={adjustedValue}
          maxDots={6}
          tooltipContent={`${t('gps-breakdown:effect_on_gps')}: ${IAPRevenueDescriptiveText} (${roundedValue})`}
        />
      ) : null}
    </div>
  )
}

export default IAPRevenueImpact
