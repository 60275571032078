import classNames from 'classnames'
import React from 'react'

import { Lock } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { Game } from '../../types/Game'
import GameIcon from '../GameIcon/GameIcon'
import './SelectableGameIconWithTooltip.scss'

type SimpleGame = Pick<Game, 'resolvedName' | 'artist' | 'icons'> & { selected?: boolean }

interface Props {
  game: SimpleGame
  onClick: () => void
  locked?: boolean
}

type TooltipProps = { game: SimpleGame }

const SelectionTooltip: React.FC<TooltipProps> = ({ game }) => (
  <>
    <Typography variant="h3">{game.resolvedName}</Typography>
    <Typography variant="subtitle2">{game.artist}</Typography>
  </>
)

const SelectableGameIconWithTooltip: React.FC<Props> = ({ game, onClick, locked }) => (
  <Box onClick={onClick} className={classNames('selectableGameIconWitHTooltip', { opaque: game.selected })}>
    <GRTooltip content={<SelectionTooltip game={game} />}>
      <Box sx={{ position: 'relative' }}>
        {locked && <Lock className="lockedIcon" fontSize="small" />}
        <GameIcon gameName={game.resolvedName} src={game.icons[0]} selected={game.selected} disabled={locked} />
      </Box>
    </GRTooltip>
    <Typography>{game.resolvedName}</Typography>
  </Box>
)

export default SelectableGameIconWithTooltip
