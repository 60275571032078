import React, { useCallback } from 'react'

import { Snackbar, Alert } from '@mui/material'

import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { displaySnackBar } from '../../actions/displaySnackBar'
import { SnackBarMessage } from '../../types/SnackBarMessage'
import './GRSnackBar.scss'

const GRSnackBar: React.FC = () => {
  const dispatch = useAppDispatch()

  const snackBarMessage: SnackBarMessage | undefined = useAppSelector((state) => {
    return state.snackBarMessage
  })

  const handleClose = useCallback(() => {
    dispatch(displaySnackBar({ message: snackBarMessage.message, severity: snackBarMessage.severity, open: false }))
  }, [dispatch, snackBarMessage])

  return (
    <div className="GRSnackBar">
      {snackBarMessage ? (
        <Snackbar
          open={snackBarMessage.open}
          autoHideDuration={snackBarMessage.displayTimeMilliseconds ? snackBarMessage.displayTimeMilliseconds : 3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleClose}
        >
          <Alert elevation={6} variant="filled" severity={snackBarMessage.severity} onClose={handleClose}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  )
}

export default GRSnackBar
