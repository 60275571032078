import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material'

import { Subgenre } from '../../../../api/core'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import GameSubgenrePicker from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import { SubgenreMap } from '../../../account'
import { GameIcon } from '../../../game'
import { useGetSubGenres } from '../../../genre-essentials/hooks/genreEssentialsHooks'
import { useGetCategoriesGenreMap, useGetGameInfoList } from '../../hooks/dataGlossaryHooks'
import './GenresTabView.scss'

/**
 * GenresTabView
 */

type GenresTabSearchParam = {
  conventionalGenreId?: string
}

const GenresTabView: React.FC = () => {
  const { t } = useTranslation()
  const categoriesGenreMap = useGetCategoriesGenreMap()

  const { subGenres } = useGetSubGenres()
  const { parsedParams, setSearchParams } = useSearchParams<GenresTabSearchParam, GenresTabSearchParam>()

  const initialSelectedSubgenres = useMemo(() => {
    if (!subGenres || !parsedParams.conventionalGenreId) return {}

    return subGenres
      ?.filter((subgenre) => subgenre.genreId === parsedParams.conventionalGenreId)
      .reduce((result, subgenre) => {
        result[subgenre.id] = true
        return result
      }, {} as SubgenreMap)
  }, [parsedParams.conventionalGenreId, subGenres])
  const [selectedSubgenres, setSelectedSubgenres] = useState(initialSelectedSubgenres)

  const selectedConventionalGenreId = useMemo(() => {
    const firstSubgenres = Object.keys(selectedSubgenres)[0]
    if (!firstSubgenres || !subGenres) return

    const selectedGenre = subGenres.find((subgenre) => subgenre.id === firstSubgenres)

    return selectedGenre ? selectedGenre.genreId : undefined
  }, [selectedSubgenres, subGenres])

  useEffect(() => {
    if (selectedConventionalGenreId !== parsedParams.conventionalGenreId) {
      setSearchParams({ ...parsedParams, conventionalGenreId: selectedConventionalGenreId })
    }
  }, [parsedParams, selectedConventionalGenreId, setSearchParams, subGenres])

  const filteredGenre = useMemo(() => {
    if (!Object.keys(selectedSubgenres).length) return categoriesGenreMap
    return categoriesGenreMap?.filter((category) => category.genreDescription?.subgenres?.includes(Object.keys(selectedSubgenres)[0]))
  }, [categoriesGenreMap, selectedSubgenres])

  const { gamesDataList, gamesData } = useGetGameInfoList()

  return (
    <Box sx={{ marginTop: 8 }} className="GenresTabView">
      <GameSubgenrePicker
        selectedSubgenres={selectedSubgenres}
        selectionChanged={setSelectedSubgenres}
        defaultExpand
        hideSubGenreSelectDropdown
        disableCategorySelect
        hideSetAsDefault
        toggleGenreSelectionMode
      />
      {filteredGenre?.map((genre, index) => {
        return (
          <Box key={genre.id + '-' + index}>
            <Divider sx={{ my: 3 }} />
            <Typography variant="h2">
              {genre.categoryName} | {genre.parentGenre}
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }}>
              {genre.genreDescription?.description}
            </Typography>
            <Grid container sx={{ flexWrap: 'wrap' }} spacing={2}>
              {genre.mappedSubgenres
                .filter((mappedSubgenre): mappedSubgenre is Subgenre => !!mappedSubgenre)
                .map((mappedSubgenre, index) => (
                  <Grid key={mappedSubgenre.id + '-' + index} container item xs={12} md={6}>
                    <Grid container component={Card} sx={{ padding: 2, justifyContent: 'space-between' }} key={mappedSubgenre.id + '-' + index}>
                      <Grid item xs={6}>
                        <img className="DataGlossaryPage__Screenshot" src={mappedSubgenre.imageUrl} alt="" style={{ width: '100%' }} />
                        <Typography className="DataGlossaryPage__ScreenshotText" sx={{ textAlign: 'center' }} mt={1}>
                          {mappedSubgenre.imageText}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <CardHeader sx={{ paddingTop: 0 }} title={mappedSubgenre.name} />
                        <CardContent sx={{ paddingTop: 0 }}>
                          <Typography variant="body2">{mappedSubgenre.description}</Typography>
                          <Typography variant="body1" sx={{ my: 2 }}>
                            {`${t('data-glossary:example_games')}: `}
                          </Typography>
                          {gamesData &&
                            gamesDataList &&
                            mappedSubgenre.exampleAppIds.map((appId) => {
                              if (gamesDataList[appId])
                                return (
                                  <GRTooltip key={appId} content={gamesDataList[appId].resolvedName}>
                                    <Link to={`/game/${appId}`} key={appId}>
                                      <GameIcon src={gamesDataList[appId].icons[0]} gameName={gamesDataList[appId].resolvedName} />
                                    </Link>
                                  </GRTooltip>
                                )
                              return null
                            })}
                        </CardContent>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )
      })}
    </Box>
  )
}

export default GenresTabView
