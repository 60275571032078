import classNames from 'classnames'
import React, { useEffect, useRef } from 'react'
import { MouseEventHandler, TouchEventHandler, useState } from 'react'

import { NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'

import './MultiSlider.scss'

/**
 * General purpose carousel / slider
 */
export type MultiSliderProps = {
  count: number
  padding: number
  children: React.ReactElement[]
  showIndex: boolean
  screenshotIndex?: number
  infinite?: boolean
  floatingButtons?: boolean
  browseAutomatically?: boolean
  restrictedWidth?: boolean
}

const MultiSlider: React.FC<MultiSliderProps> = ({
  count,
  padding,
  children,
  showIndex,
  screenshotIndex = 0,
  infinite,
  floatingButtons,
  browseAutomatically,
  restrictedWidth = false,
}) => {
  const viewPortRef = useRef<HTMLDivElement>(null)
  // eslint-disable-next-line
  const [windowWidth, setWindowWidth] = useState<number>(0)
  const [index, setIndex] = useState(screenshotIndex)
  const [movingStarted, setMovingStarted] = useState(false)
  const [moving, setMoving] = useState(false)
  const [moveStart, setMoveStart] = useState(0)
  const [moveEnd, setMoveEnd] = useState(0)
  const resolvedCount = count > children.length ? children.length : count
  const [width, setWidth] = useState(0)
  // State to track automatic browsing
  const [autoBrowseEnabled, setAutoBrowseEnabled] = useState(browseAutomatically)

  useEffect(() => {
    if (!viewPortRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      const clientWidth = viewPortRef.current?.clientWidth
      setWidth(clientWidth ? clientWidth / resolvedCount - 2 * padding : 100)
    })
    resizeObserver.observe(viewPortRef.current)
    return () => resizeObserver.disconnect()
  }, [padding, resolvedCount])

  const handleTouchStart: TouchEventHandler = (e) => {
    e.preventDefault()
    handleStart(e.targetTouches[0].clientX)
  }
  const handleTouchMove: TouchEventHandler = (e) => {
    handleMove(e.targetTouches[0].clientX)
  }
  const handleMouseDown: MouseEventHandler = (e) => {
    e.preventDefault()
    handleStart(e.clientX)
  }
  const handleMouseMove: MouseEventHandler = (e) => {
    handleMove(e.clientX)
  }
  const handleMouseEnd: MouseEventHandler = (e) => {
    e.preventDefault()
    handleEnd()
  }

  const handleStart = (x: number) => {
    setMovingStarted(true)
    setMoveStart(x)
    setAutoBrowseEnabled(false)
  }

  const handleMove = (x: number) => {
    if (movingStarted && Math.abs(x - moveStart) > 10) {
      setMoving(true)
      setMoveEnd(x)
    }
  }

  const handleEnd = () => {
    setMovingStarted(false)
    if (moving) {
      setMoving(false)
      const change = Math.round((moveEnd - moveStart) / (width + 2 * padding))
      setIndex(Math.min(Math.max(0, index - change), children.length - resolvedCount))
    }
  }

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    const startAutoBrowse = () => {
      intervalId = setInterval(() => {
        if (!moving && !movingStarted) {
          setIndex((prevIndex) => (infinite && prevIndex === children.length - resolvedCount ? 0 : prevIndex + 1))
        }
      }, 5000)
    }

    // Start automatic browsing if enabled
    if (autoBrowseEnabled) {
      startAutoBrowse()
    }

    return () => {
      // Clear interval on component unmount
      clearInterval(intervalId)
    }
  }, [autoBrowseEnabled, moving, movingStarted, infinite, children.length, resolvedCount])

  const prevAvailable = index > 0
  const nextAvailable = index < children.length - resolvedCount
  const buttonClassNames = classNames({ floating: floatingButtons })
  const disabledClassNames = classNames({ disabled: (!prevAvailable || !nextAvailable) && !infinite && infinite !== undefined })

  return (
    <div className="MultiSlider">
      <div className="slider">
        <IconButton
          className={`naviButton prev ${buttonClassNames} ${disabledClassNames}`}
          disabled={!prevAvailable && !infinite}
          onClick={() => {
            setAutoBrowseEnabled(false)
            if (infinite && index === 0) {
              setIndex(children.length - resolvedCount)
            } else {
              setIndex(index - 1)
            }
          }}
        >
          <NavigateBeforeRounded />
        </IconButton>

        <div className="viewport" ref={viewPortRef}>
          <div
            className="items"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleEnd}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseEnd}
            onMouseUp={handleMouseEnd}
            style={{
              width: children.length > 1 ? children.length * (width + 2 * padding) : restrictedWidth ? '50%' : width + 2 * padding,
              left: `calc(${index * -(width + 2 * padding)}px + ${moving ? moveEnd - moveStart : 0}px)`,
              transition: moving ? 'none' : '1s',
              animation: moving ? 'none' : `slide 1s forwards`,
            }}
          >
            {children.map((child, i) =>
              React.cloneElement(child, {
                key: i,
                className: `item ${child.props.className ? ' ' + child.props.className : ''}`,
                style: {
                  width,
                  padding: `0px ${padding}px`,
                  pointerEvents: moving ? 'none' : 'auto',
                  ...child.props.style,
                },
              })
            )}
          </div>
        </div>

        <IconButton
          className={`naviButton next ${buttonClassNames} ${disabledClassNames}`}
          disabled={!nextAvailable && !infinite}
          onClick={() => {
            setAutoBrowseEnabled(false)
            if (infinite && index === children.length - resolvedCount) {
              setIndex(0)
            } else {
              setIndex(index + 1)
            }
          }}
        >
          <NavigateNextRounded />
        </IconButton>
      </div>
      {showIndex && (
        <Box className="footer" mt={2}>
          {count > 1 && (
            <span className="index">
              {index + 1} - {index + resolvedCount} / {children.length}
            </span>
          )}
          {count === 1 && (
            <span className="index">
              {index + 1} / {children.length}
            </span>
          )}
        </Box>
      )}
    </div>
  )
}

export default MultiSlider
