import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetAnalysisCommentQuery } from '../api/core'
import { displaySnackBar } from '../features/snackbar'
import { useAppDispatch } from './storeHooks'

export const useAnalystComment = (commentId?: string | null, skip?: boolean) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { data: analystComment, isLoading, isFetching, isError, error } = useGetAnalysisCommentQuery(commentId || '', { skip: skip || !commentId })

  useEffect(() => {
    if (isError) {
      dispatch(displaySnackBar({ message: t('reviews:error_could_not_load_analyst_comment'), severity: 'error', open: true }))
    }
  }, [dispatch, t, isError])

  return {
    analystComment: !commentId ? undefined : analystComment,
    isLoading: isLoading || isFetching,
    isError,
    error,
  }
}
