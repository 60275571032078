class AppStoreService {
  appStoreGenreMap: AppStoreGenreMap = {
    '7001': 'Action',
    '7002': 'Adventure',
    '7003': 'Arcade',
    '7004': 'Board',
    '7005': 'Card',
    '7006': 'Casino',
    //'7007': 'Dice',
    //'7008': 'Educational',
    '7009': 'Family',
    //'7010': 'Kids',
    '7011': 'Music',
    '7012': 'Puzzle',
    '7013': 'Racing',
    '7014': 'Role Playing',
    '7015': 'Simulation',
    '7016': 'Sports',
    '7017': 'Strategy',
    '7018': 'Trivia',
    '7019': 'Word',
  }
}

const appStoreService = new AppStoreService()

export default appStoreService

type AppStoreGenreMap = {
  [key: string]: string
}
