import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Chip } from '@mui/material'

import { AllOrNothingSelector } from '../../../../components/AllOrNothingSelector/AllOrNothingSelector'
import { GRAccordion } from '../../../../components/GRAccordion/GRAccordion'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { isObjectEmpty } from '../../../../helpers/isObjectEmpty'
import { LiveEventDuration } from '../../types/LiveEventDuration'
import './LiveEventsDurationsSelector.scss'

type DurationSelection = { [durationId: string]: boolean }
interface Props {
  liveEventDurations: LiveEventDuration[]
  selectedDurationIdsMap: { [durationId: string]: boolean }
  isLoading?: boolean
  open?: boolean
  onLiveEventsDurationSelectChange: (newSelection: DurationSelection, duration: LiveEventDuration) => void
  onLiveEventsDurationSelectionCleared: () => void
  onLiveEventsDurationSelectAll: () => void
}

const LiveEventsDurationsSelector: React.FC<Props> = ({
  liveEventDurations,
  selectedDurationIdsMap,
  isLoading,
  open,
  onLiveEventsDurationSelectChange,
  onLiveEventsDurationSelectionCleared,
  onLiveEventsDurationSelectAll,
}) => {
  const { t } = useTranslation()
  const [liveEventDurationListOpen, setLiveEventDurationListOpen] = useState(open || false)

  const handleDurationClick = (duration: LiveEventDuration) => {
    const { id: durationId } = duration
    if (selectedDurationIdsMap[durationId]) {
      const { [durationId]: omitted, ...newSelection } = selectedDurationIdsMap
      onLiveEventsDurationSelectChange(newSelection, duration)
    } else {
      const newSelection = { ...selectedDurationIdsMap }
      newSelection[durationId] = true
      onLiveEventsDurationSelectChange(newSelection, duration)
    }
  }

  return (
    <div className="LiveEventsDurationsSelector">
      <GRAccordion
        expanded={liveEventDurationListOpen}
        onChange={() => setLiveEventDurationListOpen(!liveEventDurationListOpen)}
        title={t('live-events:live_events_duration_filter_title')}
        extra={
          <AllOrNothingSelector
            someSelected={!isObjectEmpty(selectedDurationIdsMap)}
            deselectAll={onLiveEventsDurationSelectionCleared}
            selectAll={onLiveEventsDurationSelectAll}
            disabled={isLoading || !liveEventDurationListOpen}
          />
        }
      >
        <div className="LiveEventsDurationsSelector__Chips">
          {liveEventDurations.map((duration) => {
            return (
              <Chip
                key={duration.id}
                label={duration.start > 1 ? `${duration.name} ${t('common:days')}` : `${duration.name} ${t('common:day')}`}
                color={selectedDurationIdsMap[duration.id] ? 'secondary' : 'default'}
                onClick={() => handleDurationClick(duration)}
              />
            )
          })}
          {isLoading && <GRCircularProgress my={2} />}
        </div>
      </GRAccordion>
    </div>
  )
}

export default LiveEventsDurationsSelector
