import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Card, CardContent, CircularProgress, FormControl, Grid, Link, MenuItem, Select, TableContainer, Typography } from '@mui/material'

import { VisualAnalysis } from '../../../api/connect'
import { RanksByGenreResult } from '../../../api/top-grossing'
import { GRTable, GRTableColumn, SortOrder } from '../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import ShowMore from '../../../components/ShowMore/ShowMore'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import { RankType } from '../../visuals/types'
import { GameNameStatsRow, GameNameStatsType, StatsWord } from '../types'
import { getGameNameStats } from '../util/calculations'
import GameNameStatsModal from './GameNameStatsModal'
import { translations } from './Translations'

type RowAccessor = { row: GameNameStatsRow }
type AccessorFunction = (props: RowAccessor) => string

interface VisualExplorerGameNamesProps {
  gameData?: VisualAnalysis[]
  rankType: RankType
  rankData?: RanksByGenreResult
  onGameSelected: (game: VisualAnalysis) => void
}

interface FrequencyRankTableProps {
  minFrequency?: number
  numberLabel?: boolean
  accessor?: AccessorFunction
  onSelect: (statsType: GameNameStatsType, row: GameNameStatsRow) => void
  rows?: GameNameStatsRow[]
  statsType: GameNameStatsType
}

const FrequencyRankTable: React.FC<FrequencyRankTableProps> = ({ accessor, numberLabel, rows, statsType, minFrequency: defaultMinFrequency, onSelect }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const { label, title } = translations[statsType]

  const defaultColumns: GRTableColumn<GameNameStatsRow, undefined>[] = [
    {
      labelAccessor: t(label),
      accessor: ({ row }) => <Link onClick={() => onSelect(statsType, row)}>{accessor ? accessor({ row }) : row.label}</Link>,
      cellProps: { sx: { textAlign: 'left', textTransform: 'capitalize' } },
      headerCellProps: { width: '20%', sx: { textAlign: 'left' } },
      sortable: true,
      sortAccessor: numberLabel ? (props: RowAccessor) => parseInt(props.row.label, 10) : 'label',
      sortOrder: numberLabel ? SortOrder.ASC : undefined,
      defaultSortOrder: SortOrder.ASC,
    },
    {
      labelAccessor: () => (
        <>
          {t('visuals-explorer:count')}
          &nbsp;
          <GRTooltip content={t('visuals-explorer:visual_frequency_rank_description')}>
            <Typography color="primary" display="inline" variant="subtitle2">
              ?
            </Typography>
          </GRTooltip>
        </>
      ),
      accessor: 'frequency',
      headerCellProps: { width: '27%' },
      sortable: true,
      sortOrder: numberLabel ? undefined : SortOrder.DESC,
      defaultSortOrder: SortOrder.DESC,
    },
    {
      labelAccessor: () => (
        <>
          {t('common:average_rank')}
          &nbsp;
          <GRTooltip content={t('visuals-explorer:visual_average_rank_description')}>
            <Typography color="primary" display="inline" variant="subtitle2">
              ?
            </Typography>
          </GRTooltip>
        </>
      ),
      accessor: 'averageRank',
      headerCellProps: { width: '27%' },
      sortable: true,
      defaultSortOrder: SortOrder.ASC,
    },
    {
      labelAccessor: () => (
        <>
          {t('common:median_rank')}
          &nbsp;
          <GRTooltip content={t('visuals-explorer:visual_median_rank_description')}>
            <Typography color="primary" display="inline" variant="subtitle2">
              ?
            </Typography>
          </GRTooltip>
        </>
      ),
      accessor: 'medianRank',
      headerCellProps: { width: '26%' },
      sortable: true,
      defaultSortOrder: SortOrder.ASC,
    },
  ]
  const [columns, setColumns] = useState(defaultColumns)
  const [minFrequency, setMinFrequency] = useState<number>(defaultMinFrequency || 2)

  return rows ? (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mb={1}>
        <Grid item>
          <Typography variant="h3">{t(title)}</Typography>
        </Grid>
        {!defaultMinFrequency && (
          <Grid item textAlign="right">
            <FormControl>
              <Select size="small" value={minFrequency} onChange={({ target }) => setMinFrequency(target.value as number)}>
                {[1, 2, 3, 4, 5, 10, 15, 20, 30, 40, 50].map((frequency) => (
                  <MenuItem key={frequency} value={frequency}>
                    {t('visuals-explorer:min_frequency') + frequency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Card>
        <CardContent>
          <TableContainer component={Box} ref={containerRef}>
            <ShowMore collapsedSize={500} stopPropagation={true}>
              <GRTable
                columns={columns}
                rows={rows.filter(({ frequency }) => frequency >= minFrequency)}
                onColumnsUpdated={setColumns}
                rowIdKey="label"
                scroller={containerRef}
                noRowsLabel={t('visuals-explorer:no_words_found')}
              />
            </ShowMore>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  ) : (
    <Box my={2} textAlign="center">
      <CircularProgress />
    </Box>
  )
}

const VisualExplorerGameNames: React.FC<VisualExplorerGameNamesProps> = ({ gameData, rankType, rankData, onGameSelected }) => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  const [selectedWord, setSelectedWord] = useState<StatsWord>()
  const ranks = rankData ? rankData[rankType === 'free' ? 'sdranks' : 'sranks'] : []
  const stats = getGameNameStats(userLanguage, gameData, ranks)

  const handleSelect = (statsType: GameNameStatsType, row: GameNameStatsRow) => {
    setSelectedWord({ statsType, row })
  }

  return (
    <Box className="VisualExplorerOverview">
      <Typography variant="h2" mb={0.5}>
        {t('visuals-explorer:game_names_tab')}
      </Typography>
      <Typography mb={2}>{t('visuals-explorer:game_name_tab_description')}</Typography>
      {!stats ? (
        <Box my={2} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <FrequencyRankTable onSelect={handleSelect} rows={stats?.wordFrequency} statsType="wordFrequency" />
          <Grid container mt={3} columnSpacing={3}>
            <Grid item xs={6}>
              <FrequencyRankTable numberLabel={true} minFrequency={1} onSelect={handleSelect} rows={stats?.numberOfWords} statsType="numberOfWords" />
            </Grid>
            <Grid item xs={6}>
              <FrequencyRankTable
                accessor={({ row }) => {
                  const nr = parseInt(row.label)
                  return nr + '-' + (nr + 4)
                }}
                minFrequency={1}
                numberLabel={true}
                onSelect={handleSelect}
                rows={stats?.titleLength}
                statsType="titleLength"
              />
            </Grid>
          </Grid>
        </>
      )}
      {selectedWord && (
        <GameNameStatsModal
          games={gameData}
          onClose={() => setSelectedWord(undefined)}
          onGameSelected={onGameSelected}
          ranks={ranks}
          rankType={rankType}
          word={selectedWord}
          selectedWord={selectedWord}
        />
      )}
    </Box>
  )
}

export default VisualExplorerGameNames
