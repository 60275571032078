import { format } from 'date-fns'
import { FC, ReactNode, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { Add, Edit } from '@mui/icons-material'
import { Box, Button, Card, CardHeader, Divider, Grid } from '@mui/material'

import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog'
import { DateSwitcher } from '../../../components/DateSwitcher/DateSwitcher'
import { GameCardContent } from '../../../features/game/components/GameCard/GameCard'
import { displaySnackBar } from '../../../features/snackbar'
import { useAppDispatch } from '../../../hooks/storeHooks'
import usePage from '../../../hooks/usePage'
import { useRequiredParams } from '../../../hooks/useRequiredParams'
import PageService from '../../../services/PageService'
import { ConfirmDialogData } from '../../../types/ConfirmDialogData'
import { useGetTrackedGameEventsByDateQuery, useGetTrackedGameQuery, useUpdateTrackedGameSaasStatusMutation } from '../../api/internal'
import { useUpdateTrackedGameEventDurationsMutation } from '../../api/internal'
import { ActionButton } from '../../components/ActionButton/ActionButton'
import { BackButton } from '../../components/BackButton/BackButton'
import { EditPricingDataDialog } from '../../features/live-events-tracking/components/EditPricingDataDialog/EditPricingDataDialog'
import { TrackedGameDailyEventsTable } from '../../features/live-events-tracking/components/TrackedGameDailyEventsTable/TrackedGameDailyEventsTable'
import { gameEventTypesSelectMenuAllValue } from '../../features/live-events-tracking/components/TrackedGamesQuickSearchEvent/GameEventTypesSelectMenu/GameEventTypesSelectMenu'
import {
  QuickSearchFilters,
  TrackedGamesQuickSearchEvent,
} from '../../features/live-events-tracking/components/TrackedGamesQuickSearchEvent/TrackedGamesQuickSearchEvent'
import { useLiveEventsTrackingEventsSearchParams } from '../../features/live-events-tracking/hooks/useLiveEventsTrackingEventsSearchParams'
import { InternalDuration } from '../../types/InternalTrackingEvent'

type TrackedGameEventsPageProps = {
  children?: ReactNode
}

type UnpublishFromSaasConfirmData = {
  gameId: string | undefined
  published: boolean | undefined
  name: string | undefined
}

export const TrackedGameEventsPage: FC<TrackedGameEventsPageProps> = ({ children }) => {
  usePage(PageService.getInternalPageWithId('internal-tracked-game-events'))
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { trackedGameId } = useRequiredParams<{ trackedGameId: string }>()
  const { parsedParams, setSearchParams } = useLiveEventsTrackingEventsSearchParams()
  const [updateEventDurations] = useUpdateTrackedGameEventDurationsMutation()
  const getTrackedGameQuery = useGetTrackedGameQuery(trackedGameId, { skip: !trackedGameId })
  const formattedDate = format(parsedParams.date, 'yyyy-MM-dd')
  const trackedGameEventsByDateQuery = useGetTrackedGameEventsByDateQuery({
    trackedGameId,
    date: parsedParams.date.getTime(),
  })
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const onOpenDialog = () => {
    setOpenDialog(false)
  }
  const [updateSaasStatus] = useUpdateTrackedGameSaasStatusMutation()
  const [updateSaasStatusData, setUpdateSaasStatusData] = useState<ConfirmDialogData<UnpublishFromSaasConfirmData>>()
  const quickSearchEventForm = useForm<QuickSearchFilters>({
    defaultValues: { eventName: '', eventTypeId: gameEventTypesSelectMenuAllValue },
  })

  const handleDateChange = (date: Date | null) => date && setSearchParams({ date: date })

  const isPublished = getTrackedGameQuery.data?.published
  const isLoading = getTrackedGameQuery.isFetching || trackedGameEventsByDateQuery.isFetching

  const handlePublishToSaas = (data: UnpublishFromSaasConfirmData) => {
    const title = data.published ? t('internal-live-events:publish') : t('internal-live-events:unpublish')
    const confirmText = data.published
      ? `${t('internal-live-events:are_you_sure_you_want_to_publish')}
        ${data.name}
        ${t('internal-live-events:to_saas')}`
      : `${t('internal-live-events:are_you_sure_you_want_to_unpublish')}
        ${data.name}
        ${t('internal-live-events:from_saas')}`

    setUpdateSaasStatusData({
      title: title,
      confirmText: confirmText,
      actionText: t('internal-common:yes'),
      cancelText: t('internal-common:no'),
      destructiveAction: true,
      data,
    })
  }
  const handleUpdateSaasStatusConfirm = (dialogData?: ConfirmDialogData<UnpublishFromSaasConfirmData>) => {
    if (dialogData?.data) {
      updateSaasStatus({ gameId: dialogData.data.gameId, published: dialogData.data.published }).then(() => {
        dialogData.data?.published
          ? dispatch(
              displaySnackBar({ message: `${dialogData.data?.name} ${t('internal-live-events:successfully_published')}`, severity: 'success', open: true })
            )
          : dispatch(
              displaySnackBar({ message: `${dialogData.data?.name} ${t('internal-live-events:successfully_unpublished')}`, severity: 'success', open: true })
            )
      })
    }

    setUpdateSaasStatusData(undefined)
  }

  const onEventQuickAdd = (id: string | undefined) => {
    const selectedDate = new Date(formattedDate)
    const dayStartTime = selectedDate.getTime()
    const dayEndTime = dayStartTime + 24 * 60 * 60 * 1000 - 1
    const durationObj: InternalDuration = {
      start: dayStartTime,
      end: dayEndTime,
      liveEventId: id,
    }

    updateEventDurations(durationObj)
      .unwrap()
      .then(() => {
        navigate(`event/${id}/edit`)
      })
      .catch((error) => {
        dispatch(displaySnackBar({ message: t('internal-live-events:error_event_overlap_quick_add'), severity: 'error', open: true }))
      })
  }

  return (
    <>
      <BackButton url={'/internal/live-events-tracking'} />
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>{getTrackedGameQuery.data?.game && <GameCardContent game={getTrackedGameQuery.data?.game} disableGameLink />}</Grid>
        <Grid item>
          <Grid container gap={2}>
            <Button onClick={() => setOpenDialog(true)} variant="contained">
              <Trans i18nKey="internal-live-events:edit_pricing_data_button_text" />
            </Button>
            <Button component={Link} to={`analyst-notes?date=${formattedDate}`} variant="contained">
              <Trans i18nKey="internal-live-events:edit_analyst_notes_button_text" />
            </Button>
            <Button
              component={Link}
              to={`/internal/live-events-tracking/events/${getTrackedGameQuery.data?.game.id}/overview?date=${formattedDate}`}
              variant="contained"
            >
              <Trans i18nKey="internal-live-events:edit_live_events_overview_button_text" />
            </Button>
            <Button
              variant="contained"
              color={isPublished ? 'warning' : 'success'}
              onClick={() => {
                handlePublishToSaas({ gameId: getTrackedGameQuery.data?.game.id, published: !isPublished, name: getTrackedGameQuery.data?.game.name })
              }}
            >
              {isPublished ? (
                <Trans i18nKey="internal-live-events:unpublish_from_saas_button_text" />
              ) : (
                <Trans i18nKey="internal-live-events:publish_to_saas_button_text" />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid container alignItems="center" justifyContent="space-between" mb={3}>
        <Grid item>
          <DateSwitcher value={parsedParams.date} onChange={handleDateChange} />
        </Grid>
        <Grid item>
          <Button component={Link} to={'event/edit'} variant="contained">
            <Trans i18nKey="internal-live-events:add_new_event_button_text" />
          </Button>
        </Grid>
      </Grid>
      <Box mb={3}>
        <TrackedGameDailyEventsTable events={trackedGameEventsByDateQuery.data} isLoading={isLoading} />
      </Box>
      <Card>
        <CardHeader title={<Trans i18nKey="internal-live-events:quick_add_event_header_text" />} />
        <Divider />
        <FormProvider {...quickSearchEventForm}>
          <TrackedGamesQuickSearchEvent
            renderActions={(event) => (
              <Grid container justifyContent="center" spacing={1}>
                <Grid item>
                  <ActionButton onClick={() => navigate(`event/${event.id}/edit`)}>
                    <Edit fontSize="small" />
                  </ActionButton>
                </Grid>
                <Grid item>
                  <ActionButton
                    onClick={() => {
                      onEventQuickAdd(event.id)
                    }}
                  >
                    <Add fontSize="small" />
                  </ActionButton>
                </Grid>
              </Grid>
            )}
          />
        </FormProvider>
      </Card>
      <ConfirmDialog open={!!updateSaasStatusData} onClose={handleUpdateSaasStatusConfirm} confirmDialogData={updateSaasStatusData} />
      <EditPricingDataDialog open={openDialog} onClose={onOpenDialog} trackedGameData={getTrackedGameQuery.data} trackedGameId={trackedGameId} />
    </>
  )
}
