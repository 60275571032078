import { useEffect, useState } from 'react'

/**
 * useGetScrollPosition Hooks to return scrolling offset Y
 */
const useGetScrollPosition = (): { scrollPosition: number } => {
  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }
  return { scrollPosition }
}

export default useGetScrollPosition
