import React, { useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Warning } from '@mui/icons-material'
import ErrorIcon from '@mui/icons-material/Error'
import { Box, Grid, Typography } from '@mui/material'

import { GRBanner } from '../../../../components/GRBanner/GRBanner'
import GRDotRating from '../../../../components/GRDotRating/GRDotRating'
import { GRPopover } from '../../../../components/GRPopover/GRPopover'
import { GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import { Magnify } from '../../../../components/Magnify/Magnify'
import languageService from '../../../../services/LanguageService'
import motivationService from '../../../../services/MotivationService'
import utilsService, { ChartColorList } from '../../../../services/UtilsService'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useMarketExplorerAccessCheck } from '../../../account/hooks/roleHooks'
import { ArchetypeDescriptionsModal } from '../../../motivations/components/ArchetypeDescriptionsModal/ArchetypeDescriptionsModal'
import MotivationArcheTypeCard, { MotivationArcheTypeList } from '../../../motivations/components/MotivationArcheTypeCard/MotivationArcheTypeCard'
import MotivationDriver from '../../../motivations/components/MotivationDriver/MotivationDriver'
import { InputDataRow, MotivationTableRow } from '../../../motivations/components/MotivationTable/MotivationTable'
import useGetSegmentMotivationsType from '../../../motivations/hooks/useGetSegmentMotivationsType'
import { MarketExplorerSegmentConfiguration } from '../../types/MarketExplorerSegmentConfiguration'
import { AttributeStatDetail, MotivationStatisticsAttributeStats } from '../../types/MarketExplorerSegmentData'
import { MotivationKey, MotivationType } from '../../types/MotivationType'
import { hasMotivationData } from '../../util/utils'
import { MarketExplorerSegmentCardPopover } from '../MarketExplorerSegmentCardPopover/MarketExplorerSegmentCardPopover'
import { MarketExplorerSegmentColorBadge } from '../MarketExplorerSegmentColorBadge/MarketExplorerSegmentColorBadge'
import { MarketExplorerSegmentEditDialog } from '../MarketExplorerSegmentEditDialog/MarketExplorerSegmentEditDialog'
import { SegmentQueryReturnType } from '../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { MarketExplorerSegmentCard } from '../MarketExplorerSegments/MarketExplorerSegmentCard/MarketExplorerSegmentCard'
import { SegmentsQueryReturnType } from '../MarketExplorerSegments/MarketExplorerSegments'

type valueOptionType = keyof AttributeStatDetail
type valueOptionsType = {
  value: valueOptionType
  label: string
}
/**
 * MarketExplorerMotivations Market Explorer Motivation tab
 */
interface MarketExplorerMotivationsProps {
  segments: SegmentQueryReturnType[]
  onSegmentsChange: (segments: MarketExplorerSegmentConfiguration[]) => void
}
const MarketExplorerMotivations: React.FC<MarketExplorerMotivationsProps> = ({ segments, onSegmentsChange }) => {
  const [editSegmentDialogOpen, setEditSegmentDialogOpen] = useState<boolean>(false)
  const [newSegment, setNewSegment] = useState<MarketExplorerSegmentConfiguration>()
  const [editSegmentDialogIndex, setEditSegmentDialogIndex] = useState<number>(0)
  const segmentConfigurations = segments.map((segment) => segment.segmentConfiguration)
  const [segmentsQueryData] = useState<SegmentsQueryReturnType>({ data: [] })
  const isEditSegmentLoading = segmentsQueryData.data[editSegmentDialogIndex]?.isFetching || segmentsQueryData.data[editSegmentDialogIndex]?.isLoading
  const hasMarketExplorerAccess = useMarketExplorerAccessCheck()
  const [lockedFeatureDialogOpen, setLockedFeatureDialogOpen] = useState<boolean>(false)

  const handleSegmentChange = useCallback(
    (updatedSegment: MarketExplorerSegmentConfiguration, segmentIndex: number) => {
      const newSegmentConfigurations = [...segmentConfigurations]

      newSegmentConfigurations[segmentIndex] = updatedSegment
      if (newSegmentConfigurations) {
        onSegmentsChange(newSegmentConfigurations)
      }
    },
    [onSegmentsChange, segmentConfigurations]
  )
  const handleEditDialogClose = useCallback(
    (updatedSegment: MarketExplorerSegmentConfiguration | undefined) => {
      setEditSegmentDialogOpen(false)
      updatedSegment && handleSegmentChange(updatedSegment, editSegmentDialogIndex)
    },
    [handleSegmentChange, editSegmentDialogIndex]
  )

  const handleCreateDialogClose = useCallback(
    (createdSegment: MarketExplorerSegmentConfiguration | undefined) => {
      const newSegments = createdSegment ? [...segments, createdSegment] : segments

      onSegmentsChange(newSegments as MarketExplorerSegmentConfiguration[])
      setNewSegment(undefined)
    },
    [onSegmentsChange, segments]
  )

  const motivationDatas = useMemo<MotivationStatisticsAttributeStats[]>(() => {
    const filteredMotivationDatas: MotivationStatisticsAttributeStats[] = []
    segments.forEach((segment) => {
      if (!!segment && segment.data && utilsService.isMotivationAllowedMarkets(segment.segmentConfiguration.marketIso) && hasMotivationData(segment)) {
        filteredMotivationDatas.push(segment.data.motivationData.motivationStatistics.attributeStats)
      }
    })
    return filteredMotivationDatas
  }, [segments])

  const { t } = useTranslation()

  /* This is a bit of a hack to resolve the actual colors of segments to be shown in child components. The segment has been detached from its data
   so this will do for a bug fix */
  const truncatedColorsList = useMemo(() => {
    const colorLists = utilsService.getChartColorListMarketExplorerSegments()
    return segments.reduce((acc, segment, index) => {
      if (!!segment && segment.data && utilsService.isMotivationAllowedMarkets(segment.segmentConfiguration.marketIso) && hasMotivationData(segment)) {
        acc.push(colorLists[index])
      }

      return acc
    }, [] as ChartColorList[])
  }, [segments])
  const valueOptions = useMemo<valueOptionsType[]>(
    () => [
      { value: 'avg', label: t('common:average_label') },
      { value: 'median', label: t('common:median_label') },
    ],
    [t]
  )
  const [valueOption, setValueOption] = useState<valueOptionType>(valueOptions[0].value)
  const motivationKeys = Object.keys(motivationService.keyMotivations) as MotivationKey[]

  const { segmentMotivationsType } = useGetSegmentMotivationsType()

  const [modalMotivationKey, setModalMotivationKey] = useState<MotivationKey>()

  const labels = useMemo(() => {
    return Object.values(MotivationType).map((motivationKey) => {
      return languageService.getTranslation('motivations', motivationKey)
    })
  }, [])

  const datasets = useMemo(() => {
    return motivationDatas.map((motivationData) => {
      const data = Object.values(MotivationType).map((motivationOrder) => Math.round((motivationData[motivationOrder]?.[valueOption] || 0) * 10) / 10)
      return {
        label: t('segment:segment'),
        data,
      }
    })
  }, [motivationDatas, t, valueOption])

  const dataColumns: GRTableColumn<MotivationTableRow, MotivationTableRow>[] = useMemo(() => {
    return segments
      .filter((segment) => utilsService.isMotivationAllowedMarkets(segment.segmentConfiguration.marketIso) && hasMotivationData(segment))
      .map<GRTableColumn<MotivationTableRow, MotivationTableRow>>((segment, index) => {
        return {
          labelAccessor: () => (
            <Box display={'flex'} alignItems={'center'}>
              <Typography mr={1} sx={{ fontSize: '12px' }}>
                {t('motivations:importance')}
              </Typography>
              <MarketExplorerSegmentColorBadge size="m" orderNumber={segments.findIndex((seg) => seg === segment) + 1} />
            </Box>
          ),
          accessor: ({ row }) => {
            const value = row.dataRow[index.toString()] || 0
            return (
              <Box>
                <GRTooltip content={t('motivations:motivation-avg-importance', { value: value.toFixed(1) })}>
                  <GRDotRating showZeroRating dotValue={value} maxDots={5} />
                </GRTooltip>
                <Typography variant="subtitle2" sx={{ display: 'flex', justifyContent: 'center' }}>
                  {value?.toFixed(1)}
                </Typography>
              </Box>
            )
          },
          sortable: true,
          sortAccessor: ({ row }: { row: MotivationTableRow }) => row.dataRow[index],
          sortOrder: index === 0 ? SortOrder.DESC : undefined,
        }
      })
  }, [segments, t])

  const dataRows = useMemo<InputDataRow>(() => {
    const rows: InputDataRow = {} as InputDataRow
    Object.keys(segmentMotivationsType).forEach((typeKey) => {
      const motivationType = typeKey as MotivationType
      motivationDatas.forEach((motivationData, index) => {
        if (!rows[motivationType]) {
          rows[motivationType] = {}
        }
        rows[motivationType][index.toString()] = motivationData[motivationType]?.[valueOption] || 0
      })
    })
    return rows
  }, [motivationDatas, segmentMotivationsType, valueOption])

  const handleEdit = useCallback(
    (segmentIndex: number) => {
      if (hasMarketExplorerAccess) {
        setEditSegmentDialogIndex(segmentIndex)
        setEditSegmentDialogOpen(true)
      } else {
        setLockedFeatureDialogOpen(true)
      }
    },
    [hasMarketExplorerAccess]
  )

  const sortedMotivationKeys = useMemo(() => {
    return motivationKeys.sort((a, b) => {
      if (segments[0] && segments[0].currentData && Object.keys(segments[0].currentData.motivationData).length > 0) {
        return segments[0].currentData.motivationData.averageMotivationSegments[b] - segments[0].currentData.motivationData.averageMotivationSegments[a]
      }
      return 0
    })
  }, [motivationKeys, segments])

  const motivationArcheTypeList = useMemo<MotivationArcheTypeList>(() => {
    const motivationArcheType: MotivationArcheTypeList = {} as MotivationArcheTypeList
    motivationKeys.forEach((motivationKey) => {
      if (!motivationArcheType[motivationKey]) {
        motivationArcheType[motivationKey] = []
      }

      motivationArcheType[motivationKey] = segments
        .filter((segment) => segment !== null)
        .map((segment, index) => ({
          value:
            segment.currentData && utilsService.isMotivationAllowedMarkets(segment.segmentConfiguration.marketIso) && hasMotivationData(segment) ? (
              Math.round(segment.currentData.motivationData.averageMotivationSegments[motivationKey] * 100) + '%'
            ) : (
              <GRPopover trigger="hover" content={<Trans i18nKey="common:no_data_available" />} placement="auto" triggerProps={{ style: { display: 'flex' } }}>
                <ErrorIcon color="disabled" />
              </GRPopover>
            ),
          id: index.toString(),
          component: (
            <>
              {utilsService.isMotivationAllowedMarkets(segment.segmentConfiguration.marketIso) ? (
                <MarketExplorerSegmentCardPopover
                  id={motivationKey + '-' + index.toString()}
                  placement="left"
                  content={
                    <MarketExplorerSegmentCard
                      segment={segment.segmentConfiguration}
                      segmentIndex={index}
                      games={segment.data?.games || []}
                      complementaryData={segment.complementaryData}
                      onEdit={handleEdit}
                    />
                  }
                >
                  <Magnify scale={1.25}>
                    <Box sx={{ cursor: 'pointer' }}>
                      <MarketExplorerSegmentColorBadge orderNumber={index + 1} size="xl" />
                    </Box>
                  </Magnify>
                </MarketExplorerSegmentCardPopover>
              ) : (
                <GRTooltip content={t('motivations:motivation_data_na_market')}>
                  <MarketExplorerSegmentColorBadge orderNumber={index + 1} size="xl" />
                </GRTooltip>
              )}
            </>
          ),
        }))
    })

    return motivationArcheType
  }, [handleEdit, motivationKeys, segments, t])

  if (!segments.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '50%' }}>
          <GRBanner severity="guidance" icon={<Warning />}>
            {t('market-explorer:no_segments_text')}
          </GRBanner>
        </Box>
      </Box>
    )
  }

  if (!datasets.length)
    return (
      <Grid container justifyContent="center">
        <Typography variant="body1" mt={1}>
          {t('common:no_data_available')}
        </Typography>
      </Grid>
    )

  return (
    <>
      <MotivationDriver
        datasets={datasets}
        labels={labels}
        dataColumns={dataColumns}
        dataRow={dataRows}
        colorLists={truncatedColorsList}
        valueOptions={valueOptions}
        onOptionSelected={(option) => {
          const selectedValue = option as valueOptionType
          setValueOption(selectedValue)
        }}
        defaultValue={valueOption}
        titleTranslationKey={'motivations:motivational_drivers_avg_values_per_segment'}
      />
      <MotivationArcheTypeCard
        archeTypeList={motivationArcheTypeList}
        motivationKeys={sortedMotivationKeys}
        onSelectModalMotivationKey={() => setModalMotivationKey(motivationKeys[0])}
        onSelectMotivation={setModalMotivationKey}
      />
      {modalMotivationKey && (
        <ArchetypeDescriptionsModal
          datasets={datasets}
          labels={labels}
          onSetMotivationKey={setModalMotivationKey}
          motivationKey={modalMotivationKey}
          colorLists={truncatedColorsList}
        />
      )}
      {editSegmentDialogOpen && (
        <MarketExplorerSegmentEditDialog
          open={editSegmentDialogOpen}
          title={t('segment:segment_details_title')}
          onClose={handleEditDialogClose}
          initialSegment={segmentConfigurations[editSegmentDialogIndex]}
          segmentQueryData={segmentsQueryData.data[editSegmentDialogIndex]}
          isLoading={isEditSegmentLoading}
        />
      )}
      {newSegment && (
        <MarketExplorerSegmentEditDialog
          open={!!newSegment}
          title={t('segment:segment_details_title')}
          onClose={handleCreateDialogClose}
          initialSegment={newSegment}
        />
      )}
      <LockedFeature.Dialog lockedFeatureId={LockedFeatureId.MarketExplorer} open={lockedFeatureDialogOpen} onClose={() => setLockedFeatureDialogOpen(false)} />
    </>
  )
}

export default MarketExplorerMotivations
