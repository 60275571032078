import { FC } from 'react'

import { Box } from '@mui/material'

import { LimitedFunctionalityBanner } from '../../../../../../components/LimitedFunctionalityBanner/LimitedFunctionalityBanner'
import { LockedFeatureId } from '../../../../../../types/LockedFeature'

type NewsCardContentUpgradePlanSectionSectionProps = {
  lockedFeatureId?: LockedFeatureId
}

export const NewsCardContentUpgradePlanSectionSection: FC<NewsCardContentUpgradePlanSectionSectionProps> = ({ lockedFeatureId }) => {
  return (
    <Box>
      <LimitedFunctionalityBanner lockedFeatureId={lockedFeatureId as LockedFeatureId} />
    </Box>
  )
}
