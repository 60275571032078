import { FC, useCallback } from 'react'

import { Grid, Typography } from '@mui/material'

import { intersection } from '../../../../../helpers/intersection'
import { Market } from '../../../../markets'
import { MarketRegion } from '../../../types/Region'
import { MarketChip } from '../MarketChip/MarketChip'
import { RegionActions } from '../RegionActions/RegionActions'

type RegionProps = {
  marketRegion: MarketRegion
  marketIsos: string[]
  selectedMarketIsos: string[]
  onMarketSelect: (marketIso: string) => void
  onRegionSelect: (region: MarketRegion) => void
  onRegionClear: (region: MarketRegion) => void
  onFilter: (market?: Market) => boolean
}

export const Region: FC<RegionProps> = ({ marketRegion, marketIsos, selectedMarketIsos, onMarketSelect, onRegionSelect, onRegionClear, onFilter }) => {
  const handleSelectAll = useCallback(() => {
    onRegionSelect(marketRegion)
  }, [onRegionSelect, marketRegion])

  const handleClearSelected = useCallback(() => {
    onRegionClear(marketRegion)
  }, [onRegionClear, marketRegion])

  const selectAllDisabled = intersection(marketIsos, selectedMarketIsos).length === marketIsos.length
  const clearAllDisabled = intersection(marketIsos, selectedMarketIsos).length === 0

  return (
    <Grid container wrap="wrap" gap={1} mb={2}>
      <RegionActions
        onSelectAll={handleSelectAll}
        onClearSelected={handleClearSelected}
        selectAllDisabled={selectAllDisabled}
        clearAllDisabled={clearAllDisabled}
      >
        <Typography variant="h3">{marketRegion.name}</Typography>
      </RegionActions>
      {marketIsos.map((marketIso) => {
        const selected = !!selectedMarketIsos.find((smi) => smi === marketIso)
        return <MarketChip marketIso={marketIso} selected={selected} onClick={onMarketSelect} onFilter={onFilter} key={marketIso} />
      })}
    </Grid>
  )
}
