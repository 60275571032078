import { PayloadAction } from '@reduxjs/toolkit'

import { SnackBarMessage } from '../types/SnackBarMessage'

const snackBarMessageReducer = (state: SnackBarMessage = { message: '', severity: 'success', open: false }, action: PayloadAction<SnackBarMessage>) => {
  switch (action.type) {
    case 'DISPLAY_SNACK_BAR':
      return action.payload

    default:
      return state
  }
}
export default snackBarMessageReducer
