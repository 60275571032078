import { t } from 'i18next'
import { Dispatch, FC, SetStateAction } from 'react'

import { Grid, TextField } from '@mui/material'

type EventScreenshotColumnAdderProps = {
  gridValue: number | string
  onChange: Dispatch<SetStateAction<number | string>>
}

export const EventScreenshotColumnAdder: FC<EventScreenshotColumnAdderProps> = ({ gridValue, onChange }) => {
  const handleChange = (inputValue: string) => {
    if (inputValue === '') {
      onChange('')
    } else {
      const numericValue = Number(inputValue)
      if (!isNaN(numericValue)) {
        onChange(numericValue)
      }
    }
  }
  return (
    <>
      <Grid item>{t('internal-live-events:columns')}</Grid>
      <Grid item>
        <TextField
          type="number"
          size="small"
          value={gridValue}
          onChange={(e) => handleChange(e.target.value)}
          InputProps={{ inputProps: { min: 1, max: 20 } }}
        />
      </Grid>
    </>
  )
}
