import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Navigate } from 'react-router-dom'

import { ArrowBack, Settings } from '@mui/icons-material'
import { Box, Button, Card, CardContent, CircularProgress, Collapse, Grid, Typography } from '@mui/material'

import { useGetUserProfileQuery } from '../../../api/combined'
import { Collection, CollectionVisibility, useGetMarketFeaturesQuery, useUpdateCollectionMutation } from '../../../api/core'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import ShareUrlButton from '../../../components/ShareUrlButton/ShareUrlButton'
import { FeatureGame } from '../../game/types/Game'
import ScreenshotPreviews from '../../implementation-examples/components/ScreenshotPreviews/ScreenshotPreviews'
import { collectionsRootPath, useInitialCollectionState } from '../../implementation-examples/hooks/useInitialState'
import { extractNeededLegacyIds } from '../../implementation-examples/util/screenshotHelpers'
import { useCurrentMarket } from '../../markets'
import { useCollectionAndGames } from '../hooks/collectionHooks'
import './Collection.scss'
import CollectionAdmin from './CollectionAdmin'

/**
 * One collection component.
 */
const CollectionComponent: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [showAdmin, setShowAdmin] = useState(false)
  const [{ collectionId }] = useInitialCollectionState()
  const { currentMarketIso: marketIso } = useCurrentMarket()

  const { data: collectionData, isFetching } = useCollectionAndGames(collectionId)
  const { data: currentUser } = useGetUserProfileQuery()

  const featureLegacyIds = useMemo(() => extractNeededLegacyIds(collectionData.games), [collectionData.games])
  const { data: features, isFetching: isFetchingFeatures } = useGetMarketFeaturesQuery({ marketIso, featureLegacyIds }, { skip: !featureLegacyIds.length })

  const [updateCollection] = useUpdateCollectionMutation()

  const collection = collectionData?.collection as Collection
  const games = collectionData?.games as FeatureGame[]

  const onRemoveFromCollection =
    collection && collection.visibility !== CollectionVisibility.public
      ? (screenshotId: string) => {
          if (!collection) {
            return
          }
          const { id, allowedUsers, name, visibility, screenshots } = collection
          updateCollection({
            allowedUsers,
            id,
            name,
            screenshots: screenshots.filter((id) => id !== screenshotId),
            visibility,
          })
        }
      : undefined

  if (collection === undefined) return <Navigate replace to={'/daily-insights'} />

  return isFetching || isFetchingFeatures ? (
    <Box mt={2} textAlign="center">
      <CircularProgress />
    </Box>
  ) : (
    <Box className="collection">
      <Grid container direction="row" justifyContent="space-between" my={2} alignItems="flex-end">
        <Grid item sm={8} md={6}>
          <GRTooltip content={collection?.name}>
            <Typography variant="h2" ml={2} sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {collection?.name}
            </Typography>
          </GRTooltip>
        </Grid>
        <Grid item textAlign="right">
          <Button variant="contained" color="primary" sx={{ mr: 1 }} startIcon={<ArrowBack />} onClick={() => navigate(collectionsRootPath, { replace: true })}>
            {t('common:back')}
          </Button>
          <ShareUrlButton />
          <Button
            className={classNames('ShowAdminButton', { active: showAdmin })}
            color="primary"
            disabled={currentUser?.email !== collection.createdBy}
            onClick={() => setShowAdmin(!showAdmin)}
            startIcon={<Settings />}
            sx={{ ml: 1 }}
            variant="contained"
          >
            {t('implementations:label_collection_admin')}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={showAdmin}>
        <CollectionAdmin collection={collection} onClose={() => setShowAdmin(false)} />
      </Collapse>
      <ScreenshotPreviews sortOrder="alphabet" features={features || []} games={games || []} onRemoveFromCollection={onRemoveFromCollection} />
      {!!games && !games.length && (
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography mt={1}>{t('implementations:label_there_are_no_implementations')}</Typography>
          </CardContent>
        </Card>
      )}
    </Box>
  )
}

export default CollectionComponent
