import { Game } from '../../game'
import { SegmentQueryDataType } from '../components/MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { MarketExplorerGamesChartAxisTypeId } from './MarketExplorerGamesChartAxisType'
import { MotivationType } from './MotivationType'
import { PlayerArchetype } from './PlayerArchetype'

export enum MarketExplorerSegmentBreakdownDataType {
  Segment = 'Segment',
  DataRow = 'DataRow',
}

export type MarketExplorerSegmentBreakdownDataKey = MarketExplorerGamesChartAxisTypeId | PlayerArchetype | MotivationType
export type MarketExplorerSegmentBreakdownDataData = { [key in MarketExplorerSegmentBreakdownDataKey]: number }

export type MarketExplorerSegmentBreakdownData = {
  id: string
  name: string
  type: MarketExplorerSegmentBreakdownDataType
  segment: SegmentQueryDataType
  segmentId: number
  games: Game[]
  data: MarketExplorerSegmentBreakdownDataData
}
