import { FC, ReactNode } from 'react'

import { Grid } from '@mui/material'

import GRDotRating from '../GRDotRating/GRDotRating'

type MotivationalImportanceProps = {
  value: number
  children?: ReactNode
}

export const MotivationalImportance: FC<MotivationalImportanceProps> = ({ value, children }) => {
  return (
    <Grid container spacing={1} alignItems="center" justifyContent="center">
      <Grid item>
        <GRDotRating dotValue={value} maxDots={5} />
      </Grid>
      <Grid item>{value.toFixed(1)}</Grid>
    </Grid>
  )
}
