import classNames from 'classnames'
import { ElementType, FC, MouseEvent, ReactNode, useEffect, useState } from 'react'

import { Box, ClickAwayListener, Fade, Grow, Paper, Popper, PopperProps } from '@mui/material'
import { CommonProps } from '@mui/material/OverridableComponent'

import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks'
import { updateOpeningPopoverId } from './actions/GRPopoverActions'

/**
 * Component representing a generic popover triggered by click event
 */
export type GRPopoverProps = {
  content: ReactNode
  className?: string
  placement?: PopperProps['placement']
  disablePortal?: PopperProps['disablePortal']
  component?: ElementType<any>
  trigger?: 'click' | 'hover'
  triggerProps?: CommonProps
  popperProps?: CommonProps
  wrapperProps?: CommonProps
  children?: ReactNode
  id?: string | number // provide id if one popover open at a time
  keepContentOpen?: boolean // Flag to enable interaction with component on hover
}

export const GRPopover: FC<GRPopoverProps> = ({
  content,
  className,
  placement = 'bottom-start',
  disablePortal = false,
  component = Paper,
  trigger = 'click',
  triggerProps,
  popperProps,
  wrapperProps,
  children,
  keepContentOpen,
  id = '',
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const { openingId: openingPopoverId } = useAppSelector((state) => state.grPopover)
  const dispatch = useAppDispatch()

  const handleOpenEvent = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setOpen(true)
    updatePopoverId(id)
  }

  useEffect(() => {
    if (openingPopoverId !== id) {
      setOpen(false)
    }
  }, [id, openingPopoverId])

  const updatePopoverId = (popoverId: string | number) => {
    if (openingPopoverId !== popoverId) {
      dispatch(updateOpeningPopoverId({ openingId: popoverId }))
    }
  }

  const handleClose = () => {
    setOpen(false)
    updatePopoverId('')
  }

  const Wrapper: FC<any> = component as FC<any>
  const classes = classNames('GRPopover', className)
  return (
    <Box className={classes}>
      <div
        {...triggerProps}
        onClick={trigger === 'click' ? handleOpenEvent : undefined}
        onMouseOver={trigger === 'hover' ? handleOpenEvent : undefined}
        onMouseLeave={trigger === 'hover' && !keepContentOpen ? handleClose : undefined}
      >
        {children}
      </div>
      <Popper
        {...popperProps}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        disablePortal={disablePortal}
        transition
        onMouseLeave={() => {
          if (keepContentOpen) {
            handleClose()
          }
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
            <Grow {...TransitionProps} timeout={250} style={{ transformOrigin: 'top left' }}>
              <Fade {...TransitionProps} timeout={250}>
                <Wrapper elevation={8} sx={{ p: 2 }} {...wrapperProps}>
                  {content}
                </Wrapper>
              </Fade>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  )
}
