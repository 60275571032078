import React, { ReactNode } from 'react'

import { Card, CardProps } from '@mui/material'

import './FeatureCardWrapper.scss'

/**
 * FeatureCardWrapper Simple card wrapper with gray background and padding
 */
interface FeatureCardWrapperProps extends CardProps {
  children?: ReactNode
}
const FeatureCardWrapper: React.FC<FeatureCardWrapperProps> = (props) => {
  return <Card className="FeatureCardWrapper" sx={{ padding: 2, pb: 0 }} {...props} />
}

export default FeatureCardWrapper
