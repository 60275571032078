import classNames from 'classnames'
import React, { CSSProperties, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Smartphone, SportsEsports } from '@mui/icons-material'

import { FileUploadResponse } from '../../../../api/core'
import IconPlaceholder from '../../../../assets/images/game-icon-placeholder.jpg'
import { useGetGameIconUrl } from '../../hooks/gameHooks'
import { AppType, GamePlatform } from '../../types/Game'
import './GameIcon.scss'

interface Props {
  gameName: string
  src?: string | FileUploadResponse | string[] | FileUploadResponse[]
  selected?: boolean
  size?: 'huge' | 'large' | 'normal' | 'small' | 'tiny' | 'xtiny'
  disabled?: boolean
  rootStyle?: CSSProperties
  appType?: AppType | undefined
  gamePlatforms?: GamePlatform[] | undefined
}

const GameIcon: React.FC<Props> = ({ gameName, src, selected, size, disabled, rootStyle, appType, gamePlatforms }: Props) => {
  const iconSize = 'GameIcon--' + (size || 'normal')
  const className = classNames('GameIcon', iconSize, { 'GameIcon--selected': selected, 'GameIcon--disabled': disabled })
  const { t } = useTranslation()

  const gameIconUrl = (): string | FileUploadResponse => {
    if (Array.isArray(src)) {
      return src[src.length - 1]
    }

    return src || ''
  }

  const appTypeTooltipText = useMemo(() => {
    let tooltipText = appType === AppType.MOBILE ? t('common:mobile_game') : t('common:pc_console_game')

    if (gamePlatforms && gamePlatforms.length > 0) {
      tooltipText =
        tooltipText + ` (${t('game-platforms:game_platforms')}: ` + gamePlatforms.map((platform) => t(`game-platforms:${platform}`)).join(', ') + ')'
    }

    return tooltipText
  }, [t, appType, gamePlatforms])

  const [icon] = useGetGameIconUrl(gameIconUrl())
  return (
    <div className={className} style={rootStyle}>
      <img alt={gameName} title={gameName} src={icon || IconPlaceholder} />
      {appType && (
        <div className="GameIcon__TypeIndicator" title={appTypeTooltipText}>
          {appType === AppType.MOBILE && <Smartphone />}
          {appType === AppType.PC_CONSOLE && <SportsEsports />}
        </div>
      )}
    </div>
  )
}

export default GameIcon
