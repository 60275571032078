import { FC, MouseEvent, ReactNode, useState } from 'react'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box, Button, ButtonProps, Menu } from '@mui/material'

/**
 * Component representing a button which opens a menu
 */
type MenuButtonProps = {
  label: string
  buttonProps?: ButtonProps
  children?: ReactNode
}

export const MenuButton: FC<MenuButtonProps> = ({ label, buttonProps, children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box className="DropdownButton">
      <Button variant="contained" onClick={handleClick} endIcon={<KeyboardArrowDownIcon />} {...buttonProps}>
        <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{label}</Box>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {children}
      </Menu>
    </Box>
  )
}
