import { useMemo } from 'react'

import { useMarketExplorerSegmentFilterGroups } from '../../market-explorer/hooks/marketExplorerHooks'
import type { SegmentMotivationsType } from '../components/MotivationsTabView/MotivationsTabView'

const useGetSegmentMotivationsType = () => {
  const { marketExplorerSegmentMotivationTypes } = useMarketExplorerSegmentFilterGroups()
  const segmentMotivationsType = useMemo(() => {
    return marketExplorerSegmentMotivationTypes.reduce<SegmentMotivationsType>((result, marketExplorerSegmentMotivationType) => {
      const typeLabel = marketExplorerSegmentMotivationType.label.split(' ')[2]
      for (let i = 0; i < marketExplorerSegmentMotivationType.types.length; i++) {
        const motivationType = marketExplorerSegmentMotivationType.types[i]
        result[motivationType.id] = typeLabel
      }

      return result
    }, {})
  }, [marketExplorerSegmentMotivationTypes])
  return { segmentMotivationsType }
}

export default useGetSegmentMotivationsType
