import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Chip } from '@mui/material'

import { AllOrNothingSelector } from '../../../../components/AllOrNothingSelector/AllOrNothingSelector'
import { GRAccordion } from '../../../../components/GRAccordion/GRAccordion'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { isObjectEmpty } from '../../../../helpers/isObjectEmpty'
import { BooleanMap } from '../../../../types/BooleanMap'
import { LiveEventAppearance } from '../../types/LiveEventAppearance'
import './LiveEventsAppearanceSelector.scss'

interface Props {
  liveEventAppearances: LiveEventAppearance[]
  selectedAppearanceIdsMap: { [appearanceId: string]: boolean }
  isLoading?: boolean
  open?: boolean
  onLiveEventsAppearanceSelectChange: (newSelection: BooleanMap, appearance: LiveEventAppearance) => void
  onLiveEventsAppearanceSelectionCleared: () => void
  onLiveEventsAppearanceSelectAll: () => void
}

const LiveEventsAppearanceSelector: React.FC<Props> = ({
  liveEventAppearances,
  selectedAppearanceIdsMap,
  isLoading,
  open,
  onLiveEventsAppearanceSelectChange,
  onLiveEventsAppearanceSelectionCleared,
  onLiveEventsAppearanceSelectAll,
}) => {
  const { t } = useTranslation()
  const [liveEventAppearanceListOpen, setLiveEventAppearanceListOpen] = useState(open || false)

  const handleAppearanceClick = (appearance: LiveEventAppearance) => {
    if (selectedAppearanceIdsMap[appearance]) {
      const { [appearance]: omitted, ...newSelection } = selectedAppearanceIdsMap
      onLiveEventsAppearanceSelectChange(newSelection, appearance)
    } else {
      const newSelection = { ...selectedAppearanceIdsMap }
      newSelection[appearance] = true
      onLiveEventsAppearanceSelectChange(newSelection, appearance)
    }
  }

  return (
    <div className="LiveEventsAppearanceSelector">
      <GRAccordion
        expanded={liveEventAppearanceListOpen}
        onChange={() => setLiveEventAppearanceListOpen(!liveEventAppearanceListOpen)}
        title={t('live-events:live_events_appearance_filter_title')}
        extra={
          <AllOrNothingSelector
            someSelected={!isObjectEmpty(selectedAppearanceIdsMap)}
            deselectAll={onLiveEventsAppearanceSelectionCleared}
            selectAll={onLiveEventsAppearanceSelectAll}
            disabled={isLoading || !liveEventAppearanceListOpen}
          />
        }
      >
        <div className="LiveEventsAppearanceSelector__Chips">
          {liveEventAppearances.map((appearance) => {
            return (
              <Chip
                key={appearance}
                label={`${t('live-events:appearance_event_type_' + appearance)}`}
                color={selectedAppearanceIdsMap[appearance] ? 'secondary' : 'default'}
                onClick={() => handleAppearanceClick(appearance)}
              />
            )
          })}
          {isLoading && <GRCircularProgress my={2} />}
        </div>
      </GRAccordion>
    </div>
  )
}

export default LiveEventsAppearanceSelector
