import React, { ReactNode } from 'react'
import { Trans } from 'react-i18next'

import { Box, Button, Card, Divider, Grid, Typography } from '@mui/material'

import languageService from '../../../../services/LanguageService'
import motivationService from '../../../../services/MotivationService'
import { MotivationKey } from '../../../market-explorer/types/MotivationType'
import { motivationArchetypeImages } from '../../utils/MotivationArchetypeImages'
import './MotivationArcheTypeCard.scss'

export const motivationParentTranslationKey = 'motivations'

type MotivationArcheTypeComponent = {
  value: any
  component: ReactNode
  id: string
}

export type MotivationArcheTypeList = {
  [key in MotivationKey]: MotivationArcheTypeComponent[]
}

/**
 * MotivationArcheType show motivation arche type card
 */
interface MotivationArcheTypeProps {
  motivationKey: MotivationKey
  onSelect?: (key: MotivationKey) => void
  archeTypeList?: MotivationArcheTypeComponent[]
}

export const MotivationArcheType: React.FC<MotivationArcheTypeProps> = ({ motivationKey, onSelect, archeTypeList }) => {
  const title = languageService.getTranslation(motivationParentTranslationKey, motivationKey)
  const description = languageService.getTranslation(motivationParentTranslationKey, motivationKey + '_description')

  return (
    <Grid container spacing={2} alignContent="space-between" sx={{ p: 2 }}>
      <Grid item xs={2} sx={{ cursor: onSelect ? 'pointer' : 'default' }} alignContent="center">
        <img
          src={motivationArchetypeImages[motivationKey]}
          className="MotivationArcheType__img"
          alt={motivationKey}
          onClick={() => onSelect && onSelect(motivationKey)}
        />
      </Grid>
      <Grid item xs={7} alignContent="center">
        <Typography
          variant="h2"
          fontWeight={900}
          mb={1}
          sx={{ cursor: onSelect ? 'pointer' : 'default' }}
          onClick={() => onSelect && onSelect(motivationKey)}
          color="primary"
        >
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </Grid>

      {archeTypeList && (
        <Grid item xs={3} justifyContent="center" display="flex">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            {archeTypeList.map((list) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mb: 1.5,
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                  key={list.id}
                >
                  <Typography variant="h2" mr={1} color="secondary" display={'flex'} alignItems={'center'}>
                    {list.value}
                  </Typography>
                  <Box mr={1}>{list.component}</Box>
                </Box>
              )
            })}
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

/**
 * MotivationArcheTypeCard display archetypes
 */
interface MotivationArcheTypeCardProps {
  onSelectMotivation: (key: MotivationKey) => void
  onSelectModalMotivationKey: () => void
  archeTypeList?: MotivationArcheTypeList
  motivationKeys?: MotivationKey[]
  titleTranslationKey?: string
}

const defaultMotivationKeys = Object.keys(motivationService.keyMotivations) as MotivationKey[]
const MotivationArcheTypeCard: React.FC<MotivationArcheTypeCardProps> = ({
  onSelectMotivation,
  onSelectModalMotivationKey,
  archeTypeList,
  motivationKeys = defaultMotivationKeys,
  titleTranslationKey = 'motivations:player_archetypes_avg_values_per_segment',
}) => {
  return (
    <>
      <Typography variant="h2" mt={4} mb={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={8}>
            <Trans i18nKey={titleTranslationKey} />
          </Grid>
          <Grid item xs={4} sx={{ textAlign: 'right' }}>
            <Button variant="contained" onClick={() => onSelectModalMotivationKey()}>
              <Trans i18nKey="motivations:archetype_descriptions" />
            </Button>
          </Grid>
        </Grid>
      </Typography>
      <Card>
        {motivationKeys.map((motivationKey) => {
          return (
            <Box key={motivationKey}>
              <MotivationArcheType motivationKey={motivationKey} archeTypeList={archeTypeList && archeTypeList[motivationKey]} onSelect={onSelectMotivation} />
              <Divider />
            </Box>
          )
        })}
      </Card>
    </>
  )
}

export default MotivationArcheTypeCard
