import languageService from '../../../services/LanguageService'
import { LockedFeatureId } from '../../../types/LockedFeature'
import { SoftLaunchMarket } from '../../../types/SoftLaunchMarket'
import { tier1Markets } from '../../soft-launch'
import { NewsEntry, NewsEntryType } from '../types/UnifiedNewsEntry'

export enum NewsEntryOptionsTypeId {
  Editorials = 'editorials',
  GameUpdates = 'game_updates',
  FirstApperances = 'first_apperances',
  NewGameAnalyzed = 'new_game_analyzed',
  PostUpdate = 'post_update',
  SoftLaunch = 'soft_launch',
  NewPublishersGame = 'new_publishers_game',
  LiveEvents = 'live_events',
}

class NewsEntriesService {
  newsOption = [{ id: 'only_game_type' }, { id: 'include_followed_games' }, { id: 'only_followed_games' }]

  getNewsEntryOptionTypes() {
    return [
      {
        id: NewsEntryOptionsTypeId.Editorials,
        types: [
          {
            type: NewsEntryType.Editorial,
          },
        ],
      },
      {
        id: NewsEntryOptionsTypeId.GameUpdates,
        types: [
          {
            type: NewsEntryType.GpsUpdate,
          },
        ],
      },
      {
        id: NewsEntryOptionsTypeId.FirstApperances,
        types: [
          {
            type: NewsEntryType.EntryTop10,
          },
          {
            type: NewsEntryType.EntryTop20,
          },
          {
            type: NewsEntryType.EntryTop50,
          },
          {
            type: NewsEntryType.EntryTop100,
          },
          {
            type: NewsEntryType.EntryTop200,
          },
        ],
      },
      {
        id: NewsEntryOptionsTypeId.NewGameAnalyzed,
        types: [
          {
            type: NewsEntryType.GpsNew,
          },
          {
            type: NewsEntryType.GpsOutside,
          },
          {
            type: NewsEntryType.SoftLaunch,
          },
        ],
      },
      {
        id: NewsEntryOptionsTypeId.PostUpdate,
        types: [
          {
            type: NewsEntryType.PerformanceChangeRevenue,
          },
          {
            type: NewsEntryType.PerformanceChangeDownload,
          },
        ],
      },
      {
        id: NewsEntryOptionsTypeId.SoftLaunch,
        types: [
          {
            type: NewsEntryType.SoftLaunchNonAnalyzed,
          },
        ],
      },
      {
        id: NewsEntryOptionsTypeId.NewPublishersGame,
        types: [
          {
            type: NewsEntryType.MajorGameRelease,
          },
        ],
      },
      {
        id: NewsEntryOptionsTypeId.LiveEvents,
        types: [
          {
            type: NewsEntryType.LiveEventNewEventType,
          },
          {
            type: NewsEntryType.LiveEventAnalystNotes,
          },
        ],
      },
    ]
  }

  getTypesByTypeId(typeIds: NewsEntryOptionsTypeId[]) {
    return this.getNewsEntryOptionTypes()
      .filter((entry) => typeIds.includes(entry.id))
      .flatMap((entry) => entry.types)
      .map((entry) => entry.type)
  }

  getNewsEntryTypes() {
    return this.getNewsEntryOptionTypes()
      .map((optionType) => optionType.types)
      .flat()
      .map((type) => type.type)
  }

  getTypeIdByTypes(types?: string[]) {
    if (!types) return []

    return this.getNewsEntryOptionTypes()
      .filter((entry) => !!entry.types.find((type) => types.includes(type.type)))
      .map((entry) => entry.id)
  }

  getMappedWithLockedFeature() {
    const mappedNewsEntryWithLockedFeatureIds: { [key in NewsEntryOptionsTypeId]?: LockedFeatureId } = {
      [NewsEntryOptionsTypeId.PostUpdate]: LockedFeatureId.GameUpdateImpacts,
      [NewsEntryOptionsTypeId.SoftLaunch]: LockedFeatureId.SoftLaunch,
      [NewsEntryOptionsTypeId.NewPublishersGame]: LockedFeatureId.NewGamePublished,
      [NewsEntryOptionsTypeId.LiveEvents]: LockedFeatureId.LiveEventsTracker,
    }

    return this.getNewsEntryOptionTypes().map((type) => {
      const modifiedType = { ...type, lockedFeature: '' }
      modifiedType.lockedFeature = mappedNewsEntryWithLockedFeatureIds[modifiedType.id] || ''

      return modifiedType
    })
  }

  getSoftLaunchMarketsFromNewsEntry(newsEntry: NewsEntry) {
    const MARKETS = tier1Markets

    return newsEntry.entryData.softlaunchMarkets
      ? [
          ...newsEntry.entryData.softlaunchMarkets
            .reduce((acc, marketIso) => {
              const key = marketIso.toLowerCase()
              if (!acc.has(key)) {
                acc.set(key, {
                  name: languageService.getTranslation('markets', marketIso),
                  iso: key,
                  order: MARKETS[key] ? MARKETS[key].order : 1000,
                  tier1Market: MARKETS[key] ? MARKETS[key].tier1Market : false,
                })
              }
              return acc
            }, new Map<string, SoftLaunchMarket>())
            .values(),
        ]
      : []
  }
}

const newsEntriesService = new NewsEntriesService()

export default newsEntriesService
