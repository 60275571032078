import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Lock } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Dialog, DialogContent, DialogProps, Divider, Grid } from '@mui/material'
import { Box } from '@mui/system'

import { useGetTutorialVideosQuery } from '../../api/core'
import GRDialogTitle from '../../components/GRDialogTitle/GRDialogTitle'
import FtueVideoPlayer from '../../features/ftue-videos/components/FtueVideoPlayer/FtueVideoPlayer'
import lockedFeatureService from '../../services/LockedFeatureService'
import { LockedFeature as ILockedFeature, LockedFeatureId } from '../../types/LockedFeature'
import { ContactButton } from '../ContactButton/ContactButton'
import GRCircularProgress from '../GRCircularProgress/GRCircularProgress'
import './LockedFeature.scss'

/**
 * Components for displaying Locked feature card and dialog
 */

interface LockedFeatureProps {
  lockedFeatureId: LockedFeatureId
  hideImage?: boolean // hide image in card
}

const LockedFeatureCard: FC<LockedFeatureProps> = ({ lockedFeatureId, hideImage = false }) => {
  const cardData = useMemo(() => {
    return lockedFeatureService.getLockedFeatureCardData(lockedFeatureId)
  }, [lockedFeatureId])

  return (
    <Card>
      <CardHeader title={<LockedFeatureTitle />} />
      <Divider />
      <CardContent>
        <LockedFeatureContent cardData={cardData} hideImage={hideImage} />
      </CardContent>
    </Card>
  )
}

type LockedFeatureDialogProps = DialogProps &
  LockedFeatureProps & {
    onClose?: () => void
  }

const LockedFeatureDialog: FC<LockedFeatureDialogProps> = ({ lockedFeatureId, onClose, ...dialogProps }) => {
  const { open } = dialogProps

  const cardData = useMemo(() => {
    return lockedFeatureService.getLockedFeatureCardData(lockedFeatureId)
  }, [lockedFeatureId])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <GRDialogTitle onClose={onClose}>
        <LockedFeatureTitle />
      </GRDialogTitle>
      <DialogContent dividers>
        <LockedFeatureContent cardData={cardData} />
      </DialogContent>
    </Dialog>
  )
}

const LockedFeatureTitle: FC<{}> = () => {
  const { t } = useTranslation()

  return (
    <Box>
      <Lock style={{ top: '5px', position: 'relative', marginRight: '5px' }} /> {t('feature:upgrade_your_plan_to_unlock_this_feature')}
    </Box>
  )
}

const LockedFeatureContent: FC<{ cardData: ILockedFeature; hideImage?: boolean }> = ({ cardData, hideImage }) => {
  const { t } = useTranslation()
  const { data: tutorialVideos, isLoading: isTutorialLoading } = useGetTutorialVideosQuery(undefined, { skip: !cardData.content.rightVideoId })
  const selectedVideo = useMemo(
    () => tutorialVideos?.find((video) => video.videoId === cardData.content.rightVideoId),
    [cardData.content.rightVideoId, tutorialVideos]
  )

  const gridMediumWidth = useMemo(() => {
    if (!!cardData.content.rightVideoId) {
      return 5
    }

    return hideImage || !cardData.content.rightUrl ? 12 : 5
  }, [cardData.content.rightUrl, cardData.content.rightVideoId, hideImage])

  return (
    <Grid container spacing={4} className="LockedFeatureContent">
      <Grid item xs={12} md={gridMediumWidth}>
        <h3>{t(cardData.titleTranslationKey)}</h3>
        <div
          className="LockedFeatureContent__Message"
          dangerouslySetInnerHTML={{
            __html: t(cardData.content.leftTranslationKey),
          }}
        ></div>
        {cardData.id !== LockedFeatureId.Empty && (
          <Box my={3}>
            <Divider />
          </Box>
        )}
        <p style={{ fontStyle: 'italic' }}>{t('locked-feature:cta_text')}</p>
        <ContactButton />
      </Grid>
      {cardData.content.rightUrl && !hideImage && (
        <Grid item xs={12} md={7}>
          <img className="LockedFeatureContent__Image" src={cardData.content.rightUrl} alt={cardData.titleTranslationKey}></img>
        </Grid>
      )}
      {!!selectedVideo && (
        <>
          {isTutorialLoading ? (
            <GRCircularProgress />
          ) : (
            <Grid item xs={12} md={7}>
              <FtueVideoPlayer screenRatio={16 / 9} hideChapterList hideCloseButton video={selectedVideo} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export const LockedFeature = {
  Dialog: LockedFeatureDialog,
  Card: LockedFeatureCard,
  Title: LockedFeatureTitle,
}
