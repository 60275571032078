import React from 'react'
import { Routes, useLocation } from 'react-router'
import { Route, Link } from 'react-router-dom'

import { Tab, Tabs } from '@mui/material'

interface TabRouteProps {
  path: string
  label: string
  route?: string
  children: JSX.Element
}

const TabRoute = ({ children }: TabRouteProps) => {
  return children
}

/**
 * Basically the amount of '/' characters in path
 */
const getPathDepth = (path: string): number => {
  return (path.match(/\//g) || []).length
}

const shallowPath = (path: string, depth: number): string => {
  const parts = path.split('/')
  return parts.slice(0, depth + 1).join('/')
}

const TabsRouter = ({ path, children, className }: { path: string; children: React.ReactElement<TabRouteProps>[]; className?: string }) => {
  const rootPathDepth = getPathDepth(path)
  const location = useLocation()
  return (
    <>
      <Tabs value={shallowPath(location.pathname, rootPathDepth + 1)} className={className}>
        {children.map((child, i) => (
          <Tab
            key={i}
            component={Link}
            label={child.props.label}
            value={shallowPath(path + child.props.path, rootPathDepth + 1)}
            to={path + child.props.path}
          />
        ))}
      </Tabs>
      <Routes>
        {children.map((child, i) => {
          const childPath = child.props.route || child.props.path
          return <Route key={i} path={childPath} element={child} />
        })}
      </Routes>
    </>
  )
}

export { TabsRouter, TabRoute }
