import languageService from '../../../services/LanguageService'
import utilsService from '../../../services/UtilsService'
import { BaseGame } from '../../../types/BaseGame'
import { Setting } from '../../../types/Setting'
import { DEFAULT_DOWNLOADS_VALUE, DEFAULT_REVENUE_VALUE } from '../../estimates/services/RevenueAndDownloadEstimates'
import { EstimateTypes, RevenueAndDownloadAggregatesMap } from '../../game/types/Game'
import { GameQuarter } from '../../quarter/types/GameQuarter'

export interface TopGame extends BaseGame {
  name: string
  rankMonth: number
  rankWeek: number
  rankYesterday: number
  drankWeek: number
  drankMonth: number
  drank: number
  drankYesterday: number
  sdrank: number
  dataCreated: number
  dataModified: number
  downloadDataModified: number
  price: number
  market: string
  images: string[]
  revenueAvg: number
  revenueSum: number
  revenueSumMonthHistory: History[]
  downloadAvg: number
  downloadSum: number
  downloadSumMonthHistory: History[]
  bundleId: string
  reviewId: null
  reviewPublished: boolean
  appGenres: number[]
  appGenreRanks: { [key: string]: number }
  appGenreHistories: { [key: string]: Dhistory[] }
  appGenreDranks: { [key: string]: number }
  appGenreDhistories: { [key: string]: Dhistory[] }
  appGenreSranks: { [key: string]: number }
  appGenreSdranks: { [key: string]: number }
  conventionalGenre: string
  conventionalSubgenre: string
  conventionalCategory: string
  conventionalGenreId: string
  conventionalSubgenreId: string
  conventionalCategoryId: string
  history: Dhistory[]
  dhistory: Dhistory[]
  historyWeek: Dhistory[]
  dhistoryWeek: Dhistory[]
  historyMonth: Dhistory[]
  dhistoryMonth: Dhistory[]
  srankHistory: Dhistory[]
  srankHistoryMonth: Dhistory[]
  sdrankHistory: Dhistory[]
  sdrankHistoryMonth: Dhistory[]
  gpsChange: number
  gps: number
  type: null
  gameId: string
  rankWeekAgo: null
  top10Entry: number
  top10Exit: number
  top20Entry: number
  top20Exit: number
  top50Entry: number
  top50Exit: number
  top100Entry: number
  top100Exit: number
  top200Entry: number
  top200Exit: number
  gpsHistory: History[]
  changesInFeatures: boolean
  lastAnalysisAt: number
  lastScoreAt: number
  lastRankTrendSent: number
  tags: string[]
  top200DropNotified: boolean
  revenueAndDownloadAggregatesMap: RevenueAndDownloadAggregatesMap
  marketLevelQuarters: {
    [quarterIdentifier: string]: GameQuarter
  }
  selectedScopeTypeQuarters: {
    [quarterIdentifier: string]: GameQuarter
  }
  getRevenueForMarket(estimateType: EstimateTypes, marketIso: string, formatted: boolean, formattedNoRound: boolean): number | string
  getDownloadsForMarket(estimateType: EstimateTypes, marketIso: string, formatted: boolean, formattedNoRound: boolean): number | string
}

export class TopGame extends BaseGame implements TopGame {
  conventionalGenre: string
  conventionalSubgenre: string
  conventionalCategory: string

  constructor(data: TopGame) {
    super(data)
    Object.assign(this, data)
    this.conventionalSubgenre = data.conventionalSubgenreId ? languageService.getTranslation('conventionalSubgenres', data.conventionalSubgenreId) : ''
    this.conventionalGenre = data.conventionalGenreId ? languageService.getTranslation('conventionalGenres', data.conventionalGenreId) : ''
    this.conventionalCategory = data.conventionalCategoryId ? languageService.getTranslation('conventionalCategories', data.conventionalCategoryId) : ''
  }

  public getRevenueForMarket(estimateType: EstimateTypes, marketIso: string, formatted: boolean = false, formattedNoRound: boolean = false): string | number {
    let revenue = this.revenueAndDownloadAggregatesMap
      ? this.revenueAndDownloadAggregatesMap[marketIso][estimateType] || DEFAULT_REVENUE_VALUE
      : DEFAULT_REVENUE_VALUE

    if (!formatted) return revenue

    if (formattedNoRound && revenue <= DEFAULT_REVENUE_VALUE) {
      return '< ' + revenue
    }

    if (revenue <= DEFAULT_REVENUE_VALUE) {
      return '< $' + utilsService.shortenNumber(revenue)
    }

    return '$' + utilsService.shortenNumber(revenue)
  }
  public getDownloadsForMarket(estimateType: EstimateTypes, marketIso: string, formatted: boolean = false, formattedNoRound: boolean = false): string | number {
    let downloads = this.revenueAndDownloadAggregatesMap
      ? this.revenueAndDownloadAggregatesMap[marketIso][estimateType] || DEFAULT_DOWNLOADS_VALUE
      : DEFAULT_DOWNLOADS_VALUE

    if (!formatted) return downloads

    if (formattedNoRound && downloads <= DEFAULT_DOWNLOADS_VALUE) {
      return '< ' + downloads
    }

    if (downloads <= DEFAULT_DOWNLOADS_VALUE) {
      return '< ' + utilsService.shortenNumber(downloads)
    }

    return utilsService.shortenNumber(downloads)
  }

  isOpenForEveryone(openGamesSetting: Setting | undefined, featuredGamesSetting: Setting | undefined): boolean {
    if (!openGamesSetting || !featuredGamesSetting) {
      return false
    } else {
      const GAME_IDS = [...openGamesSetting.data.openGames, ...featuredGamesSetting.data.featuredGames]
      return GAME_IDS.includes(this.gameId)
    }
  }
}

export interface Dhistory {
  ts: number
  rank: number
}

export interface History {
  ts: number
  value: number
  gps: number
}
