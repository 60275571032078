import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Card, Grid, Typography } from '@mui/material'

import { useGameAnalysisOutdatedForMarketIsoCheck, useGameAndAnalysis } from '../../hooks/gameHooks'
import { Game } from '../../types/Game'
import { GameCardContent } from '../GameCard/GameCard'
import GameKeyIndicators from '../GameKeyIndicators/GameKeyIndicators'
import { GameLink } from '../GameLink/GameLink'
import { RankDevelopmentChartContainer } from '../RankDevelopmentChartContainer/RankDevelopmentChartContainer'
import './GameDashboardCard.scss'

interface Props {
  game: Game
  marketIso: string
}

const GameDashboardCard: React.FC<Props> = ({ game, marketIso }) => {
  const { t } = useTranslation()
  const { gameAndAnalysis } = useGameAndAnalysis(game.id, marketIso)
  const gameAnalysisOutdated = useGameAnalysisOutdatedForMarketIsoCheck({ marketIso, game })

  const isGameSoftLaunch = () => {
    if (!gameAndAnalysis) {
      return false
    }

    return gameAndAnalysis.game.isSoftLaunch()
  }

  return (
    <div id={`game-dashboard-${game.id}`} className="GameDashboardCard">
      <Card sx={{ flexGrow: 1 }}>
        <Grid container className="GameDashboardCard__container">
          <Grid item xs={12} md={6} className="GameDashboardCard__left">
            <div className="GameDashboardCard__content-block GameDashboardCard__header">
              <Grid container spacing={2} alignItems="center" sx={{ position: 'relative' }}>
                <Grid item xs>
                  {isGameSoftLaunch() && <div className="GameDashboardCard__ribbon">{t('game-header:soft_launch')}</div>}
                  <GameCardContent
                    game={game}
                    gameAnalysisOutdated={gameAnalysisOutdated}
                    mainMarketIso={marketIso}
                    customStyles={{ display: 'inline-flex' }}
                    customLinkStyles={{ maxWidth: '200px' }}
                    hidePowerscore
                  ></GameCardContent>
                </Grid>
                <Grid item>
                  <GameLink game={game}>
                    <Button size="small" variant="contained" color="primary" fullWidth>
                      {t('overview:game_overview')}
                    </Button>
                  </GameLink>
                </Grid>
              </Grid>
            </div>

            <div className="GameDashboardCard__content-block">
              <GameKeyIndicators game={game} marketIso={marketIso} />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="GameDashboardCard__content-block">
              <Typography variant="h3" sx={{ mb: 2 }}>
                {t('common:rank_development')}
              </Typography>
              <RankDevelopmentChartContainer appId={game.appId} />
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}

export default GameDashboardCard
