import { useMemo } from 'react'

import { useSearchParams } from '../../../../hooks/useSearchParams'

type Source = {
  commentId: string
  date: string
}

type Destination = {
  commentId: string
  date: string
}

export const useAnalystNotesSearchParams = () => {
  const mapper = useMemo(() => {
    return {
      mapFromUrl: (source: Source) => ({
        date: source.date,
        commentId: source.commentId,
      }),
      mapToUrl: (destination: Destination) => ({
        date: destination.date,
        commentId: destination.commentId,
      }),
    }
  }, [])

  return useSearchParams<Source, Destination>(mapper)
}
