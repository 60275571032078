import { MarketsPerformanceEstimates } from '../../estimates'
import { GameRevenueAndDownloadsTableRow } from '../types/GameRevenueAndDownloadsTableRow'

/**
 * Hook that maps market performance estimates to table row objects
 */
export const useGameEstimateTotalsByMarket = (marketEstimates: MarketsPerformanceEstimates) => {
  const estimateTotalsByMarket: GameRevenueAndDownloadsTableRow[] = marketEstimates.markets.map((market) => {
    return {
      marketIso: market.marketIso,
      marketName: market.marketName,
      revenue: market.total?.revenue || 0,
      revenueShare: (market.total?.revenue || 0) / (marketEstimates.total?.revenue || 0),
      downloads: market.total?.downloads || 0,
      downloadsShare: (market.total?.downloads || 0) / (marketEstimates.total?.downloads || 0),
      revenueDownloadsRatio: market.total?.revenueAndDownloadsRatio || 0,
    }
  })

  // append values of all markets combined to list
  return [
    {
      revenue: marketEstimates.total?.revenue || 0,
      revenueShare: 1,
      downloads: marketEstimates.total?.downloads || 0,
      downloadsShare: 1,
      revenueDownloadsRatio: marketEstimates.total?.revenueAndDownloadsRatio || 0,
    },
    ...estimateTotalsByMarket,
  ]
}
