import { FC, ReactNode, useEffect, useRef } from 'react'

import { Slider, SliderProps } from '@mui/material'

import { elementsOverlap } from '../../helpers/dom'

/**
 * Slider component styled according to GR rules
 */

type GRSliderProps = SliderProps & {
  children?: ReactNode
}

export const GRSlider: FC<GRSliderProps> = ({ children, ...sliderProps }) => {
  const { value, valueLabelFormat } = sliderProps
  const ref = useRef<HTMLSpanElement>(null)

  /**
   * This effect changes the labels shown on top of the thumbs to be shown as "minValue - maxValue" if they are overlapping
   */
  useEffect(() => {
    const valueLabels = ref.current?.getElementsByClassName('MuiSlider-valueLabel') as HTMLCollectionOf<HTMLElement>
    const isRangeSelector = Array.isArray(value) && value.length === 2

    if (isRangeSelector && valueLabels && valueLabels?.length === 2) {
      const values = value as number[]
      const leftValue = values[0]
      const overlapping = elementsOverlap(valueLabels[0], valueLabels[1])
      const leftElement = valueLabels[0]
      const rightElement = valueLabels[1]
      if (overlapping) {
        rightElement.style.visibility = 'hidden'
        leftElement.textContent = values
          .map((val, index) => {
            return typeof valueLabelFormat === 'function' ? valueLabelFormat(val, index) : val
          })
          .join(' - ')
      } else {
        rightElement.style.visibility = 'visible'
        leftElement.textContent = valueLabelFormat && typeof valueLabelFormat === 'function' ? valueLabelFormat(leftValue, 0) + '' : leftValue + ''
      }
    }
  }, [value, valueLabelFormat])

  return (
    <Slider
      ref={ref}
      {...sliderProps}
      sx={{
        '& .MuiSlider-valueLabel': {
          fontSize: 12,
          fontWeight: 'normal',
          top: '-0.25em',
          backgroundColor: 'unset',
          color: (theme) => theme.palette.text.primary,
          '&:before': {
            display: 'none',
          },
          '& *': {
            background: 'transparent',
            color: (theme) => theme.palette.text.primary,
          },
        },
        ...sliderProps.sx,
      }}
    />
  )
}
