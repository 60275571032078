export type InvitedUser = {
  email: string
  password: string
  firstName: string
  lastName: string
  title: string
  active?: boolean
}

export enum InviteStatus {
  IDLE = 'idle',
  INVITED = 'invited',
  INVITING = 'inviting',
  ERROR = 'error',
}
