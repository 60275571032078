import type { ITrackEventOptions } from '../services/AnalyticsService'

export enum AnalyticsProviders {
  mixpanel = 'mixpanel',
  intercom = 'intercom',
  hubspot = 'hubspot',
}

export interface IAnalyticsProvider {
  /**
   * The type of the provider example mixpanel, intercom, hubspot
   */
  type: AnalyticsProviders
  /**
   * determines if the provider is enabled or not
   */
  enabled: boolean
  /**
   *
   * @param eventName Name of the event to track
   * @param options Options to pass to the provider
   */
  trackEvent(eventName: string, options?: ITrackEventOptions): void
}
