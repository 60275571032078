import { add, endOfDay, endOfMonth, endOfWeek, Interval, intervalToDuration, startOfDay, sub } from 'date-fns'

import { UTCDate } from '@date-fns/utc'

/**
 * Helper functions for date(s)
 */
import { UserLanguage } from '../features/account/types/User'
import { GranularityValue } from '../features/revenue-and-downloads/types/Filters'
import languageService from '../services/LanguageService'

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24

const userLanguageToLocaleMap = {
  en: 'en-US',
  ja: 'ja-JP',
  zh: 'zh-CN',
}

const defaultDateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

/**
 * Decides if the given date is an actual Date
 */
export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d.getTime()) && d.getTime() >= 0
}

/**
 * Creates a date time formatter with given language and DateTimeFormatOptions
 */
export const getDateTimeFormatter = (language: UserLanguage, options: Intl.DateTimeFormatOptions = defaultDateFormat) => {
  const locale = userLanguageToLocaleMap[language] || userLanguageToLocaleMap[languageService.defaultLanguage]
  return new Intl.DateTimeFormat(locale, options)
}

type DateTimeFormatterOptions = Intl.DateTimeFormatOptions & {
  language: UserLanguage
}

/**
 * Formats a date with given options
 */
export const formatDate = (date: number | Date, options: DateTimeFormatterOptions) => {
  const { language, ...otherOptions } = options
  const resolvedOptions = Object.keys(otherOptions).length > 0 ? otherOptions : undefined
  const formatter = getDateTimeFormatter(options.language, resolvedOptions)

  return formatter.format(date)
}

/**
 * Get difference in months between two dates
 */
export const monthDifference = (dateFrom: Date, dateTo: Date) => {
  return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
}

/**
 * Get difference in days between two dates
 */
export const dateDifference = (pastDate: Date, futureDate: Date) => {
  const utc1 = Date.UTC(pastDate.getFullYear(), pastDate.getMonth(), pastDate.getDate())
  const utc2 = Date.UTC(futureDate.getFullYear(), futureDate.getMonth(), futureDate.getDate())

  return Math.floor((utc2 - utc1) / MILLISECONDS_PER_DAY)
}

/**
 * Resolves to the start of the next day
 */
export const startOfNextDay = (ts: number | Date) => {
  return startOfDay(add(ts, { days: 1 })).getTime()
}

/**
 * Resolves to the start of the next day
 */
export const startOfPreviousDay = (ts: number | Date) => {
  return startOfDay(sub(ts, { days: 1 })).getTime()
}

/**
 * Resolves to the end of the previous monts
 */
export const endOfPreviousMonth = (ts: number | Date) => {
  return endOfMonth(sub(ts, { months: 1 })).getTime()
}

/**
 * Resolves to the end of the previous day
 */
export const endOfPreviousDay = (ts: number | Date) => {
  return endOfDay(sub(ts, { days: 1 })).getTime()
}

/**
 * Checks if give Interval (date-fns) is valid
 */
export const isValidInterval = (interval: Interval) => {
  return !!interval.start && !!interval.end && interval.start <= interval.end //&& +interval.start - +interval.end !== 0
}

/**
 * Converts an interval to duration so that hours are rounded to full days
 */
const millisecondsInDay = 24 * 60 * 60 * 1000
export const intervalToRoundedDuration = (interval: Interval) => {
  const duration = intervalToDuration(interval)
  if (duration.hours) {
    const durationInMilliseconds = +interval.end - +interval.start
    if (duration.hours >= 12) {
      // round up
      const secondsToFullDay = Math.round((millisecondsInDay - (durationInMilliseconds % millisecondsInDay)) / 1000)
      const roundedInterval = { start: interval.start, end: add(interval.end, { seconds: secondsToFullDay }).getTime() }
      return intervalToDuration(roundedInterval)
    } else {
      // round down
      const secondsToFullDay = Math.floor((durationInMilliseconds % millisecondsInDay) / 1000)
      const roundedInterval = { start: interval.start, end: sub(interval.end, { seconds: secondsToFullDay }).getTime() }
      return intervalToDuration(roundedInterval)
    }
  } else {
    return intervalToDuration(interval)
  }
}

export const formatISODate = (date: Date) => {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
}

export const getIntervalByGranularity = (granularity: GranularityValue, date: Date | number) => {
  const start = startOfDay(date)
  let end
  switch (granularity) {
    case GranularityValue.Day:
      end = endOfDay(start)
      break
    case GranularityValue.Week:
      end = endOfWeek(start)
      break
    case GranularityValue.Month:
      end = endOfMonth(start)
      break
  }

  return { start, end } as Interval
}

export const getUTCDate = (date: Date | number | string) => {
  if (date instanceof UTCDate) {
    return date
  } else {
    const dateObj = new Date(date)
    return new UTCDate(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate())
  }
}
