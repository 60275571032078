import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'

import { AttributeModelNames, VisualAnalysisModel } from '../../../api/connect'
import VisualIconsList from '../../visuals/components/VisualIconsList'
import { RankType, VisualAnalysisWithRank, VisualsAttribute, VisualsGroupedData, VisualSortType } from '../../visuals/types'

interface VisualExplorerIconsProps {
  data: VisualsGroupedData
  genreId: string
  rankType: RankType
  onSetTop: (value: number) => void
  onSetSortBy: (value: VisualSortType) => void
  onSetSelectedItem: (item?: VisualAnalysisWithRank) => void
  selectedAttrs: AttributeModelNames
  sortBy: VisualSortType
  top: number
}

const VisualExplorerIcons: React.FC<VisualExplorerIconsProps> = ({
  data,
  genreId,
  rankType,
  selectedAttrs,
  sortBy,
  top,
  onSetSortBy,
  onSetTop,
  onSetSelectedItem,
}) => {
  const { t } = useTranslation()
  const attributes: VisualsAttribute[] = Object.keys(selectedAttrs)
    .map((model) => selectedAttrs[model as VisualAnalysisModel].map((name) => ({ model: model as VisualAnalysisModel, name })))
    .flat()

  // If no attributes, only sortable by rank
  const sortable = attributes.length > 0
  const finalSortBy = sortable ? sortBy : 'rank'

  return (
    <Box className="VisualExplorerIcons">
      <Grid container justifyContent="space-between" alignItems="flex-end" wrap="wrap">
        <Grid item md={6} sm={12}>
          <Typography variant="h2" mb={0.5}>
            {t('visuals-explorer:icons_tab_title')}
          </Typography>
          <Typography>{t('visuals-explorer:icons_tab_description')}</Typography>
        </Grid>
        <Grid item md={6} sm={12} textAlign="right">
          <FormControl size="small" variant="outlined" disabled={!sortable}>
            <Select onChange={({ target }) => onSetSortBy(target.value as VisualSortType)} value={finalSortBy}>
              <MenuItem value="attribute">{t('visuals-explorer:sort_by_attribute')}</MenuItem>
              <MenuItem value="rank">{t('visuals-explorer:sort_by_rank')}</MenuItem>
            </Select>
          </FormControl>
          <FormControl size="small" variant="outlined">
            <Select onChange={({ target }) => onSetTop(target.value as number)} value={top} sx={{ ml: 1 }}>
              {[50, 100, 200, 500].map((value) => (
                <MenuItem value={value} key={'top-select-' + value}>
                  {t('common:top_' + value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <VisualIconsList
        attributes={attributes}
        data={data}
        genreId={genreId}
        rankType={rankType}
        onSelectAnalysis={onSetSelectedItem}
        sortBy={finalSortBy}
        top={top}
      />
    </Box>
  )
}

export default VisualExplorerIcons
