import { ConceptTag, KeywordList, Screenshot, SimpleFeature, Tag } from '../../../api/core'
import { DateRangeValue } from '../../../components/DateRangePicker/DateRangePicker'
import { SubgenreMap } from '../../account'
import { isConceptTag, isEnrichedFeature, isKeyword } from '../../feature/helpers/helpers'
import { FeatureAndKeywordSearchResult } from '../../feature/types/types'
import { FeatureGame } from '../../game/types/Game'
import { EnrichedFeature } from '../types/ImplementationExamples'
import { ScreenshotVersionSorter } from './comparators'
import { extractNeededLegacyIds, getCombinedScreenshots } from './screenshotHelpers'

type ItemWithGenre = { conventionalSubgenreId: string }

type FeaturesFilter = (feature: EnrichedFeature) => boolean
type FeatureGameFilter = (game: FeatureGame) => boolean
type ItemWithGenreFilter = (item: ItemWithGenre) => boolean
type ScreenshotFilter = (screenshot: Screenshot) => boolean

type EnabledFeaturesFilterConstructor = (featureLegacyIds?: number[]) => FeaturesFilter
type FeaturesByGameFilterConstructor = (games: FeatureGame[]) => FeaturesFilter
type GamesByVersionDateFilterConstructor = (dateRange?: DateRangeValue) => FeatureGameFilter
type ScreenshotsByFeatureFilterConstructor = (feature: EnrichedFeature) => ScreenshotFilter
type ScreenshotsByConceptTagFilterConstructor = (conceptTag: ConceptTag) => ScreenshotFilter
type SubgenreFilterConstructor = (subgenreMap?: SubgenreMap) => ItemWithGenreFilter
type LatestOnlyFilterConstructor = (latestOnly?: boolean) => FeatureGameFilter

/**
 * @return a filter function that filters games by given subgenreMap
 */
export const subgenreFilter: SubgenreFilterConstructor =
  (subgenreMap) =>
  ({ conventionalSubgenreId }) =>
    !subgenreMap || !Object.keys(subgenreMap).length || Object.keys(subgenreMap).includes(conventionalSubgenreId)

/**
 * @return a filter function that filters games by screenshot version dates
 */
export const gamesByVersionDateFilter: GamesByVersionDateFilterConstructor =
  (range) =>
  (game): boolean => {
    const combinedScreenshots = getCombinedScreenshots(game)

    if (!range || !combinedScreenshots?.length) {
      return true
    }
    const to = range.toDate?.getTime()
    const from = range.fromDate?.getTime()
    const { versionReleaseDate } = combinedScreenshots[0]
    if (!versionReleaseDate) {
      return true
    }
    if (from && from > versionReleaseDate) {
      return false
    }
    return !(to && to < versionReleaseDate)
  }
/**
 * Returns a filtering function that accepts all screenshots with a given feature only.
 *
 * @param legacyId the feature (legacy) id
 */
export const screenshotsByFeatureFilter: ScreenshotsByFeatureFilterConstructor =
  ({ legacyId }) =>
  (screenshot) =>
    (screenshot.features as SimpleFeature[]).map(({ featureLegacyId }) => featureLegacyId.toString()).includes(legacyId.toString())

/**
 * Returns a filtering function that accepts all screenshots with a given concept tag only.
 * @param conceptTag the concept tag
 * @returns
 */
export const screenshotsByConceptTagFilter: ScreenshotsByConceptTagFilterConstructor =
  ({ concept }) =>
  (screenshot) =>
    screenshot.conceptIds.includes(concept.id)

/**
 * Returns filter that filters features by given set of featureIds
 */
export const enabledFeaturesFilter: EnabledFeaturesFilterConstructor = (featureLegacyIds?) => (feature) =>
  !featureLegacyIds?.length || featureLegacyIds.includes(feature.legacyId)

/**
 * Include all features that include any of the given games in constructor.
 *
 * @param games to include
 * @constructor
 */
export const featuresByGamesFilter: FeaturesByGameFilterConstructor = (games) => (feature) => extractNeededLegacyIds(games).includes(feature.legacyId)

/**
 * A complex-looking function which only splits the different game versions as
 * different games in the array - AND adds the 'latestVersion' attribute in every game
 * containing the latest screenshot version.
 */
export const reGroupByVersion = (games: FeatureGame[]): FeatureGame[] => {
  const gamesWithLatestVersion = games.map((game) => {
    const featureVersionsAndDates = getCombinedScreenshots(game)
      .flatMap((screenshot) => {
        return screenshot?.gameVersion
          ? { gameVersion: screenshot.gameVersion, versionReleaseDate: screenshot.versionReleaseDate ? screenshot.versionReleaseDate : 0 }
          : []
      })
      .filter((item) => !!item.gameVersion)
      .sort((a, b) => b.versionReleaseDate - a.versionReleaseDate)

    const latestVersion = featureVersionsAndDates.length > 0 ? featureVersionsAndDates[0].gameVersion : null

    return { ...game, latestFeatureVersion: latestVersion } as FeatureGame
  })

  const grouped = gamesWithLatestVersion.map((game) => {
    let mapFeatureScreenshots = new Map<string, Screenshot[]>()
    let mapConceptScreenshots = new Map<string, Screenshot[]>()

    game?.featureScreenshots?.forEach((ss) => {
      const version = ss.gameVersion ?? ''
      const versions = mapFeatureScreenshots.get(version)
      if (!versions) {
        mapFeatureScreenshots.set(version, [ss])
      } else {
        versions.push(ss)
      }
    })

    game?.conceptScreenshots?.forEach((ss) => {
      const version = ss.gameVersion ?? ''
      const versions = mapConceptScreenshots.get(version)
      if (!versions) {
        mapConceptScreenshots.set(version, [ss])
      } else {
        versions.push(ss)
      }
    })

    // Merge all unique versions from both featureScreenshots and conceptScreenshots
    const allVersionsSet = new Set<string>([...mapFeatureScreenshots.keys(), ...mapConceptScreenshots.keys()])

    // Convert the set to an array for easier iteration
    const allVersions = Array.from(allVersionsSet)

    // Construct the result by iterating over allVersions
    const result = allVersions.map((version) => ({
      ...game,
      featureScreenshots: mapFeatureScreenshots.get(version) ?? [],
      conceptScreenshots: mapConceptScreenshots.get(version) ?? [],
    }))

    return result
  })
  return (grouped.flat() as FeatureGame[]).sort(ScreenshotVersionSorter)
}

/**
 * Include only games with latest found version screenshots.
 * Note: Only works if game is already grouped by screenshot versions and has the 'latestFeatureVersion' param set
 *
 * @param latestOnly include only latest version, false returns all
 */
export const latestOnlyFilter: LatestOnlyFilterConstructor = (latestOnly) => (game) =>
  !latestOnly ||
  game?.featureScreenshots[0]?.gameVersion === game.latestFeatureVersion ||
  game?.conceptScreenshots[0]?.gameVersion === game.latestFeatureVersion

/**
 * Include features that match primarily given search, secondarily the selected tag.
 *
 * @param defaultEmpty return empty result if no hits
 * @param selectedTags or empty
 * @param searchResult keyword or feature
 */
export const featureSearchResultFilter =
  (defaultEmpty: boolean, selectedTags?: Tag[] | null, searchResult?: FeatureAndKeywordSearchResult, keywords?: KeywordList) => (feature: EnrichedFeature) => {
    let tagsMatch = false
    let enrichedMatch = false
    let keywordMatch = false
    let stringMatch = false

    // if selectedTags exists and has more than 0 items, check if feature has any of the selected tags
    if (selectedTags && selectedTags.length) {
      const targets = selectedTags.map(({ targets }) => targets).flat()
      tagsMatch = targets.includes(feature.legacyId)
      if (!tagsMatch) {
        return false
      }
    }

    // if searchResult exists, check if feature matches the search
    if (searchResult) {
      if (isEnrichedFeature(searchResult)) {
        enrichedMatch = feature.id === searchResult.id
      } else if (isKeyword(searchResult)) {
        keywordMatch = searchResult.linkedIds.includes(feature.legacyId)
        // Old UI did search by name when keyword is selected.
        // Example search ('IAP')
        // "Boosting success rates of creating/upgrading with IAPs: Yes"
        // Above feature is not linked to IAP keyword but it should be included in search result as it contains IAP in name
        stringMatch = feature.name?.toLowerCase().includes(searchResult.name.toLowerCase())
      } else if (typeof searchResult === 'string') {
        stringMatch = feature.name?.toLowerCase().includes(searchResult.toLowerCase())
        // or if string matches combination of any choices feature.name + ": " + choice.name
        if (!stringMatch) {
          feature.choices.forEach((choice) => {
            if (searchResult.toLowerCase().includes(`${feature.name.toLowerCase()}: ${choice.choice.toLowerCase()}`)) {
              stringMatch = true
            }
          })
        }

        if (keywords && keywords.length > 0) {
          const keywordStringMatch = keywords.filter((keyword) => keyword.name.toLowerCase().includes(searchResult.toLowerCase()))
          if (keywordStringMatch.length > 0) {
            // match all features that are linked to any of the keywords that match the search string
            keywordMatch = keywordStringMatch.some((keyword) => keyword.linkedIds.includes(feature.legacyId))
          }
        }
      }
    }

    if (!searchResult && !selectedTags?.length && defaultEmpty) {
      return false
    }

    if (!searchResult && !selectedTags?.length && !defaultEmpty) {
      return true
    }

    if (!searchResult && tagsMatch) {
      return true
    }

    return enrichedMatch || keywordMatch || stringMatch
  }

export const conceptTagSearchResultFilter =
  (defaultEmpty: boolean, selectedTags?: Tag[], searchResult?: FeatureAndKeywordSearchResult) => (conceptTag: ConceptTag) => {
    let tagsMatch = false
    let conceptTagMatch = false
    let stringMatch = false

    if (selectedTags && selectedTags.length) {
      tagsMatch = selectedTags.some((tag) => conceptTag.concept.featureTagId === tag.id)
      if (!tagsMatch) {
        return false
      }
    }

    // if searchResult exists, check if feature matches the search
    if (searchResult) {
      if (isConceptTag(searchResult)) {
        conceptTagMatch = conceptTag.id === searchResult.id
      } else if (isKeyword(searchResult)) {
        stringMatch = conceptTag.name.toLowerCase().includes(searchResult.name.toLowerCase())
      } else if (typeof searchResult === 'string') {
        stringMatch = conceptTag.name?.toLowerCase().includes(searchResult.toLowerCase())
      }
    }

    if (!searchResult && !selectedTags?.length && defaultEmpty) {
      return false
    }

    if (!searchResult && !selectedTags?.length && !defaultEmpty) {
      return true
    }

    if (!searchResult && conceptTagMatch) {
      return true
    }

    return tagsMatch || stringMatch || conceptTagMatch
  }
