import { useMemo } from 'react'

import { ReviewStatus } from '../types/ReviewStatus'

export const useReviewTooltip = (status: ReviewStatus) => {
  return useMemo(() => {
    if (status === ReviewStatus.Empty) {
      return 'No review'
    } else if (status === ReviewStatus.Draft) {
      return 'Draft'
    } else {
      return 'Published'
    }
  }, [status])
}
