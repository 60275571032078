import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { CancelOutlined } from '@mui/icons-material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Grid, Button } from '@mui/material'

type RegionActionsProps = {
  children?: ReactNode
  onSelectAll: () => void
  onClearSelected: () => void
  selectAllDisabled?: boolean
  clearAllDisabled?: boolean
}

export const RegionActions: FC<RegionActionsProps> = ({ children, onSelectAll, onClearSelected, selectAllDisabled, clearAllDisabled }) => {
  const { t } = useTranslation()

  return (
    <Grid container gap={2} alignItems="center" justifyContent="space-between">
      <Grid item>{children}</Grid>
      <Grid container gap={2}>
        <Grid item>
          <Button size="small" variant="text" startIcon={<CheckCircleOutlineIcon />} onClick={onSelectAll} disabled={selectAllDisabled}>
            {t('common:select_all')}
          </Button>
        </Grid>
        <Grid item>
          <Button size="small" variant="text" startIcon={<CancelOutlined />} onClick={onClearSelected} disabled={clearAllDisabled}>
            {t('common:clear_selected')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}
