export enum JobFunction {
  Art = 'art',
  DataAnalysis = 'data_analysis',
  DevelopmentProgramming = 'development_programming',
  GameDesing = 'game_design',
  Production = 'production',
  ProductManagementLiveOps = 'product_management_live_ops',
  Management = 'management',
  MarketingUserAcquisition = 'marketing_user_acquisition',
  UserResearch = 'user_research',
  CorporateStrategy = 'corporate_strategy',
  BusinessDevelopment = 'business_development',
  Publishing = 'publishing',
  Other = 'other',
}
