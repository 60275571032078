export enum RightVideoId {
  onboarding = 1236,
}

export interface LockedFeature {
  id: LockedFeatureId
  titleTranslationKey: string
  content: {
    leftTranslationKey: string
    rightUrl?: string
    rightVideoId?: RightVideoId
  }
}

export enum LockedFeatureId {
  Empty = 'Empty',
  FollowedGames = 'FollowedGames',
  FtueVideos = 'FtueVideos',
  GameCompetitors = 'GameCompetitors',
  GameFeatureIdeas = 'GameFeatureIdeas',
  GameImplementations = 'GameImplementations',
  GameUpdateImpacts = 'GameUpdateImpacts',
  GameVisuals = 'GameVisuals',
  MarketExplorer = 'MarketExplorer',
  MarketShare = 'MarketShare',
  SoftLaunch = 'SoftLaunch',
  NewGamePublished = 'NewGamePublished',
  LiveEventsTracker = 'LiveEventsTracker',
  ImplementationExamples = 'ImplementationExamples',
  MarketTrends = 'MarketTrends',
  Motivations = 'Motivations',
  CompareGames = 'CompareGames',
  GameUnlock = 'GameUnlock',
  GenreEssentials = 'GenreEssentials',
  GameFeatureFacts = 'GameFeatureFacts',
  DataExport = 'DataExport',
  CompareMotivationTab = 'CompareMotivationTab',
  AIAnalyst = 'AIAnalyst',
}
