import { MongoDBObject } from './MongoDBObject'
import { Setting } from './Setting'

export interface BaseGame extends MongoDBObject {
  readonly appId: number
  //name: string
  artist: string
  archive: boolean
  released: number
  rank: number
  srank: number
  isOpenForEveryone(openGamesSetting: Setting | undefined, featuredGamesSetting: Setting | undefined): boolean
}

export class BaseGame extends MongoDBObject implements BaseGame {
  constructor(data: BaseGame) {
    super(data)
    Object.assign(this, data)
  }

  isOpenForEveryone(openGamesSetting: Setting | undefined, featuredGamesSetting: Setting | undefined): boolean {
    if (!openGamesSetting || !featuredGamesSetting) {
      return false
    } else {
      const GAME_IDS = [...openGamesSetting.data.openGames, ...featuredGamesSetting.data.featuredGames]
      return GAME_IDS.includes(this.id)
    }
  }
}
