import { FC } from 'react'

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import styles from './NoChangeIcon.module.scss'

/**
 * Icon depicting "no change". Used e.g. to display aggregate values
 */
export const NoChangeIcon: FC<{}> = () => (
  <span className={styles.root}>
    <ArrowLeftIcon className={styles['left-arrow']} />
    <ArrowRightIcon className={styles['right-arrow']} />
  </span>
)
