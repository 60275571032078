import { endOfDay, format } from 'date-fns'
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { OpenInNew } from '@mui/icons-material'
import { Box, Button, Card, Grid, IconButton, TableContainer } from '@mui/material'

import { GRTable, GRTableColumn } from '../../../../../components/GRTable/GRTable'
import { ValueIndicator, ValueType } from '../../../../../components/ValueIndicator/ValueIndicator'
import { Game, GameIcon } from '../../../../../features/game'
import { SearchedGame } from '../../../../../features/live-events/types/SearchedGame'
import { TableSortValues } from '../../../../../types/TableSortValues'
import { useTrackNewGameMutation } from '../../../../api/internal'
import { ReviewStatusIndicator } from '../../../review/components/ReviewStatusIndicator/ReviewStatusIndicator'
import { useLiveEventsTrackingMarket } from '../../hooks/useLiveEventsTrackingMarket'
import { LastAnalystStatusIndicator } from '../LastAnalystStatusIndicator/LastAnalystStatusIndicator'

type SearchGamesTableProps = {
  searchedGames?: SearchedGame[]
  isLoading?: boolean
  children?: ReactNode
}

export const SearchGamesTable: FC<SearchGamesTableProps> = ({ searchedGames, isLoading, children }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const monthsAgoLimit = 6
  const dateToday = format(endOfDay(new Date()), 'yyyy-MM-dd')
  const marketIso = useLiveEventsTrackingMarket().currentMarketIso
  const [startGameTracking, startGameTrackingResult] = useTrackNewGameMutation()

  const handleStartTracking = useCallback(
    (game: Game) => {
      startGameTracking({
        gameId: game.id,
        marketIso: marketIso,
      })
    },
    [marketIso, startGameTracking]
  )

  const searchedGamesTableColums: GRTableColumn<SearchedGame, typeof customTableProps>[] = useMemo(
    () => [
      {
        headerCellProps: { sx: { minWidth: 80 } },
        labelAccessor: () => <Trans i18nKey="internal-common:icon" />,
        accessor: ({ row }) => (
          <GameIcon src={row.game.getIcon() as string} gameName={row.game.resolvedName} size="small" gamePlatforms={row.game.platforms}></GameIcon>
        ),
      },
      {
        headerCellProps: { sx: { minWidth: 200 } },
        labelAccessor: () => <Trans i18nKey="internal-common:name" />,
        accessor: ({ row }) => (
          <Grid container justifyContent={'center'} alignItems="center" textAlign={'left'}>
            <Grid item xs sx={{ fontWeight: 700 }}>
              {row.game.name}
            </Grid>
            <Grid item sx={{ ml: 'auto' }}>
              <IconButton size="small" color="primary" onClick={() => openPrompt(row.game)}>
                <OpenInNew />
              </IconButton>
            </Grid>
          </Grid>
        ),
        sortable: true,
        sortAccessor: ({ row }) => row.game.name || '',
      },
      {
        headerCellProps: { sx: { minWidth: 120 } },
        labelAccessor: () => <Trans i18nKey="internal-common:publisher" />,
        accessor: ({ row }) => row.game.artist,
        sortable: true,
      },
      {
        headerCellProps: { sx: { minWidth: 70 } },
        labelAccessor: () => <Trans i18nKey="internal-common:powerscore" />,
        accessor: ({ row }) =>
          row.game.gpsPerMarket[marketIso] ? (
            <ValueIndicator value={row.game.gpsPerMarket[marketIso]} type={ValueType.Number} maximumFractionDigits={1} />
          ) : (
            '---'
          ),

        sortable: true,
        sortAccessor: ({ row }) => {
          const gps = row.game.gpsPerMarket[marketIso]
          return gps ? gps : TableSortValues.AlwaysLast
        },
      },
      {
        headerCellProps: { sx: { minWidth: 100 } },
        labelAccessor: () => <Trans i18nKey="internal-common:last_analysis" />,
        accessor: ({ row }) =>
          row.game.getLastAnalysisAtIsDoneOverMonthsAgo(monthsAgoLimit, marketIso) ? (
            <Grid container justifyContent={'center'} alignItems="center">
              <Box position="relative">
                <Grid item style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                  {format(row.game.lastAnalysisAt[marketIso], 'dd.MM.yyyy')}
                </Grid>
                <Grid item style={{ marginLeft: '90px' }}>
                  <LastAnalystStatusIndicator />
                </Grid>
              </Box>
            </Grid>
          ) : row.game.lastAnalysisAt[marketIso] ? (
            format(row.game.lastAnalysisAt[marketIso], 'dd.MM.yyyy')
          ) : (
            '---'
          ),
        sortable: true,
        sortAccessor: ({ row }) => row.game.lastAnalysisAt[marketIso],
      },
      {
        headerCellProps: { sx: { minWidth: 100 } },
        labelAccessor: () => <Trans i18nKey="internal-common:analyst_overview" />,
        accessor: ({ row }) => <ReviewStatusIndicator game={row.game} />,
      },
      {
        headerCellProps: { sx: { minWidth: 100 } },
        labelAccessor: () => <Trans i18nKey="internal-common:stage" />,
        accessor: ({ row }) => row.game.stage || '---',
        sortAccessor: ({ row }) => row.game.stage,
        sortable: true,
      },
      {
        headerCellProps: { sx: { minWidth: 100 } },
        labelAccessor: () => <Trans i18nKey="internal-common:markets" />,
        accessor: ({ row }) => (
          <div>
            {row.game.stores?.length || 0}, {row.game.markets.length}
          </div>
        ),
        sortable: true,
        sortAccessor: ({ row }) => row.game.markets.length,
      },
      {
        headerCellProps: { sx: { minWidth: 145 } },
        labelAccessor: () => '',
        accessor: ({ row }) =>
          row.tracked ? (
            <Button
              component={Link}
              to={`/internal/live-events-tracking/events/${row.game.id}?date=${dateToday}`}
              variant="contained"
              size="small"
              color="primary"
            >
              <Trans i18nKey="internal-live-events:continue_tracking" />
            </Button>
          ) : (
            <Button
              onClick={() => {
                handleStartTracking(row.game)
              }}
              variant="contained"
              size="small"
              color="primary"
            >
              <Trans i18nKey="internal-live-events:start_tracking" />
            </Button>
          ),
      },
    ],
    [dateToday, handleStartTracking, marketIso]
  )

  const customTableProps = useMemo(() => ({}), [])

  const [columns, setColumns] = useState<GRTableColumn<SearchedGame, typeof customTableProps>[]>(searchedGamesTableColums)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<SearchedGame, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  //Navigate to game events after successfull api call
  useEffect(() => {
    if (startGameTrackingResult.isSuccess) {
      const path = `/internal/live-events-tracking/events/${startGameTrackingResult.data.game.id}?date=${dateToday}`
      navigate(path)
    }
  }, [dateToday, navigate, startGameTrackingResult])

  return (
    <TableContainer component={Card} ref={containerRef}>
      <GRTable
        columns={columns}
        customProps={customTableProps}
        isLoading={isLoading}
        onColumnsUpdated={handleColumnsUpdate}
        rowIdKey={(searchedGame) => searchedGame.game.id}
        rows={searchedGames || []}
        scroller={containerRef}
        striped
        gridlines
        noRowsLabel={t('internal-common:no_rows')}
      />
    </TableContainer>
  )
}

const openPrompt = (game: Game) => {
  const link = `http://localhost:3000/game/${game.id}/overview`
  window.prompt(`SaaS link for ${game.name}, press Ctrl/Cmd + C to copy`, link)
}
