import compareGamesScreenshot from '../assets/images/locked/compare-games-locked.png'
import compareGamesMotivationScreenshot from '../assets/images/locked/compare-games-motivations.png'
import followedGamesScreenshot from '../assets/images/locked/followed-games-locked.png'
import ftueVideosLockedScreenshot from '../assets/images/locked/ftue-videos-locked.png'
import gameCompetitorsLockedScreenshot from '../assets/images/locked/game-competitors-locked.png'
import gameFeatureIdeasLockedScreenshot from '../assets/images/locked/game-feature-ideas-locked.png'
import gameMotivationsLockedScreenshot from '../assets/images/locked/game-motivations-locked.png'
import gameUpdateImpactsScreenshot from '../assets/images/locked/game-update-impacts-locked.png'
import gameVisualsScreenshot from '../assets/images/locked/game-visuals-locked.png'
import implementationExamplesScreenshot from '../assets/images/locked/implementation-examples-locked.png'
import implementationsLockedScreenshot from '../assets/images/locked/implementations-locked.png'
import liveEventsTrackerScreenshot from '../assets/images/locked/live-events-tracker-locked.png'
import marketExplorerScreenshot from '../assets/images/locked/market-explorer-locked.png'
import marketShareLockedScreenshot from '../assets/images/locked/market-share-locked.png'
import marketTrendsScreenshot from '../assets/images/locked/market-trends-locked.png'
import newGamePublishedScreenshot from '../assets/images/locked/new-game-published-locked.png'
import softLaunchLockedScreenshot from '../assets/images/locked/softlauch-locked.png'
import { LockedFeature, LockedFeatureId, RightVideoId } from '../types/LockedFeature'

class LockedFeatureService {
  contactUrl: string = 'https://www.gamerefinery.com/contact-us/'
  lockedFeatures: LockedFeaturesMap = {
    Empty: {
      id: LockedFeatureId.Empty,
      titleTranslationKey: '',
      content: {
        leftTranslationKey: '',
        rightUrl: undefined,
      },
    },
    FollowedGames: {
      id: LockedFeatureId.FollowedGames,
      titleTranslationKey: 'locked-feature:followed_games',
      content: {
        leftTranslationKey: 'locked-feature:followed_games_main',
        rightUrl: followedGamesScreenshot,
      },
    },
    FtueVideos: {
      id: LockedFeatureId.FtueVideos,
      titleTranslationKey: 'locked-feature:ftue_videos',
      content: {
        leftTranslationKey: 'locked-feature:ftue_videos_main',
        rightUrl: ftueVideosLockedScreenshot,
      },
    },
    GameCompetitors: {
      id: LockedFeatureId.GameCompetitors,
      titleTranslationKey: 'locked-feature:game_competitors',
      content: {
        leftTranslationKey: 'locked-feature:game_competitors_main',
        rightUrl: gameCompetitorsLockedScreenshot,
      },
    },
    GameFeatureIdeas: {
      id: LockedFeatureId.GameFeatureIdeas,
      titleTranslationKey: 'overview:feature_ideas',
      content: {
        leftTranslationKey: 'locked-feature:feature_ideas_main',
        rightUrl: gameFeatureIdeasLockedScreenshot,
      },
    },
    GameFeatureFacts: {
      id: LockedFeatureId.GameFeatureFacts,
      titleTranslationKey: 'locked-feature:feature_facts',
      content: {
        leftTranslationKey: 'locked-feature:feature_facts_main',
        rightUrl: undefined,
      },
    },
    GameImplementations: {
      id: LockedFeatureId.GameImplementations,
      titleTranslationKey: 'sidebar:impl_examples',
      content: {
        leftTranslationKey: 'locked-feature:impl_examples_main',
        rightUrl: implementationsLockedScreenshot,
      },
    },
    GameUpdateImpacts: {
      id: LockedFeatureId.GameUpdateImpacts,
      titleTranslationKey: 'locked-feature:game_update_impacts',
      content: {
        leftTranslationKey: 'locked-feature:game_update_impacts_main',
        rightUrl: gameUpdateImpactsScreenshot,
      },
    },
    GameVisuals: {
      id: LockedFeatureId.GameVisuals,
      titleTranslationKey: 'locked-feature:game_visuals',
      content: {
        leftTranslationKey: 'locked-feature:game_visuals_main',
        rightUrl: gameVisualsScreenshot,
      },
    },
    MarketExplorer: {
      id: LockedFeatureId.MarketExplorer,
      titleTranslationKey: 'locked-feature:market_explorer',
      content: {
        leftTranslationKey: 'locked-feature:market_explorer_main',
        rightUrl: marketExplorerScreenshot,
      },
    },
    MarketShare: {
      id: LockedFeatureId.MarketShare,
      titleTranslationKey: 'locked-feature:market_share',
      content: {
        leftTranslationKey: 'locked-feature:market_share_main',
        rightUrl: marketShareLockedScreenshot,
      },
    },
    SoftLaunch: {
      id: LockedFeatureId.SoftLaunch,
      titleTranslationKey: 'sidebar:soft_launch',
      content: {
        leftTranslationKey: 'locked-feature:soft_launch_main',
        rightUrl: softLaunchLockedScreenshot,
      },
    },
    NewGamePublished: {
      id: LockedFeatureId.NewGamePublished,
      titleTranslationKey: 'unlock-game-dialog:title',
      content: {
        leftTranslationKey: 'unlock-game-dialog:new_game_main',
        rightUrl: newGamePublishedScreenshot,
      },
    },
    LiveEventsTracker: {
      id: LockedFeatureId.LiveEventsTracker,
      titleTranslationKey: 'locked-feature:live_events_tracker',
      content: {
        leftTranslationKey: 'locked-feature:live_events_tracker_main',
        rightUrl: liveEventsTrackerScreenshot,
      },
    },
    ImplementationExamples: {
      id: LockedFeatureId.ImplementationExamples,
      titleTranslationKey: 'sidebar:impl_examples',
      content: {
        leftTranslationKey: 'locked-feature:impl_examples_main',
        rightUrl: implementationExamplesScreenshot,
      },
    },
    MarketTrends: {
      id: LockedFeatureId.NewGamePublished,
      titleTranslationKey: 'locked-feature:market_trends',
      content: {
        leftTranslationKey: 'locked-feature:market_trends_main',
        rightUrl: marketTrendsScreenshot,
      },
    },
    Motivations: {
      id: LockedFeatureId.Motivations,
      titleTranslationKey: 'locked-feature:motivations',
      content: {
        leftTranslationKey: 'locked-feature:motivations_main',
        rightUrl: gameMotivationsLockedScreenshot,
      },
    },
    CompareGames: {
      id: LockedFeatureId.CompareGames,
      titleTranslationKey: 'sidebar:game_comparison',
      content: {
        leftTranslationKey: 'locked-feature:game_comparison_main',
        rightUrl: compareGamesScreenshot,
      },
    },
    CompareMotivationTab: {
      id: LockedFeatureId.CompareMotivationTab,
      titleTranslationKey: 'locked-feature:game_comparison_motivations',
      content: {
        leftTranslationKey: 'locked-feature:game_comparison_motivations_main',
        rightUrl: compareGamesMotivationScreenshot,
      },
    },
    GameUnlock: {
      id: LockedFeatureId.GameUnlock,
      titleTranslationKey: 'unlock-game-dialog:title',
      content: {
        leftTranslationKey: 'unlock-game-dialog:new_game_main',
        rightUrl: newGamePublishedScreenshot,
      },
    },
    DataExport: {
      id: LockedFeatureId.DataExport,
      titleTranslationKey: 'export-csv:export_csv',
      content: {
        leftTranslationKey: 'locked-feature:export_csv_main',
        rightUrl: newGamePublishedScreenshot, // TODO: add correct screenshot
      },
    },
    GenreEssentials: {
      id: LockedFeatureId.GenreEssentials,
      titleTranslationKey: 'locked-feature:genre_essentials',
      content: {
        leftTranslationKey: 'locked-feature:genre_essentials_main',
        rightVideoId: RightVideoId.onboarding,
      },
    },
    AIAnalyst: {
      id: LockedFeatureId.AIAnalyst,
      // TODO CHANGE PLACEHOLDERS
      titleTranslationKey: '',
      content: {
        leftTranslationKey: '',
        rightUrl: undefined,
      },
    },
  }

  getLockedFeatureCardData(id: LockedFeatureId) {
    return this.lockedFeatures[id]
  }
}

const lockedFeatureService = new LockedFeatureService()

export default lockedFeatureService

type LockedFeaturesMap = {
  [id in LockedFeatureId]: LockedFeature
}
