import { useFeaturedGamesSetting } from './useFeaturedGamesSetting'
import { useOpenGamesSetting } from './useOpenGamesSetting'

/**
 * Hook for loading open and featured games settings
 */
export const useGamesOpenForEveryone = () => {
  const {
    data: openGamesSetting,
    isFetching: isFetchingOpenGamesSetting,
    isLoading: isLoadingOpenGamesSetting,
    isSuccess: isSuccessOpenGamesSetting,
    error: openGamesSettingError,
  } = useOpenGamesSetting()

  const {
    data: featuredGamesSetting,
    isFetching: isFetchingFeaturedGamesSetting,
    isLoading: isLoadingFeaturedGamesSetting,
    isSuccess: isSuccessFeaturedGamesSetting,
    error: featuredGamesSettingError,
  } = useFeaturedGamesSetting()

  return {
    openGamesSetting,
    featuredGamesSetting,
    isLoading: isLoadingOpenGamesSetting || isLoadingFeaturedGamesSetting,
    isFetching: isFetchingOpenGamesSetting || isFetchingFeaturedGamesSetting,
    isSuccess: isSuccessOpenGamesSetting && isSuccessFeaturedGamesSetting,
    error: openGamesSettingError && featuredGamesSettingError,
  }
}
