export const truncateText = (text: string, maxLength: number, inputType: 'wysiwyg' | 'text'): string => {
  if (!text || !text.length) {
    return ''
  } else if (inputType === 'wysiwyg') {
    // Truncate HTML to first element's contents
    const el = document.createElement('div')
    el.innerHTML = text

    return el.firstChild?.textContent?.substring(0, maxLength) || ''
  }

  return text.substring(0, maxLength)
}
