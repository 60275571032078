import { SubgenreMap } from '../../account'
import { MotivationType } from './MotivationType'

export type MarketExplorerSegmentConfiguration = {
  visible: boolean
  marketIso: string
  filters: {
    ranks: { [key in RanksFilterField]?: MarketExplorerSegmentFilterValue }
    demographics: { [key in DemographicsFilterField]?: MarketExplorerSegmentFilterValue }
    motivations: { [key in MotivationType]?: MarketExplorerSegmentFilterValue }
    archetypes: { [key in PlayerArchetypesFilterField]?: MarketExplorerSegmentFilterValue }
  }
  subgenres?: SubgenreMap
  gameIds?: string[]
  featureChoices?: FeatureChoiceMap
  ownGames?: boolean
}

export enum RanksFilterField {
  SRanks = 'sranks',
  SDRanks = 'sdranks',
  DaysSinceRelease = 'released',
}

export enum DemographicsFilterField {
  DemographicsMale = 'demographicsMale',
  DemographicsFemale = 'demographicsFemale',
  DemographicsAge16_24 = 'demographicsAge16_24',
  DemographicsAge25_44 = 'demographicsAge25_44',
  DemographicsAge45 = 'demographicsAge45',
}

export enum PlayerArchetypesFilterField {
  MotivationPlayerArchetypeExpressionist = 'Expressionist',
  MotivationPlayerArchetypeThinker = 'Thinker',
  MotivationPlayerArchetypeTreasureHunter = 'Treasure Hunter',
  MotivationPlayerArchetypeStrategist = 'Strategist',
  MotivationPlayerArchetypeSkillMaster = 'Skll master',
  MotivationPlayerArchetypeThrillSeeker = 'Thrill seeker',
  MotivationPlayerArchetypeKingOfTheHill = 'King of the hill',
  MotivationPlayerArchetypeNetworker = 'Networker',
}

export type MarketExplorerSegmentFilterValue = number[] | undefined

export type FeatureChoiceMap = { [featureId: number]: number[] }
