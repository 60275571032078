import { FC, ReactNode } from 'react'

import { Box, BoxProps } from '@mui/material'

import './NewsCardRibbon.scss'

type NewsCardRibbonProps = BoxProps & {
  children?: ReactNode
}

export const NewsCardRibbon: FC<NewsCardRibbonProps> = ({ children, ...boxProps }) => {
  return (
    <Box className="NewsCardRibbon" sx={{ fontSize: 13 }} {...boxProps}>
      {children}
    </Box>
  )
}
