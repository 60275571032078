import { FC } from 'react'

import { Error } from '@mui/icons-material'
import { SxProps } from '@mui/material'

import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'

type ErrorStatusCircleProps = {
  errorText: string
  sx?: SxProps
}

export const ErrorStatusCircle: FC<ErrorStatusCircleProps> = ({ errorText, sx }) => {
  return (
    <GRTooltip content={errorText}>
      <Error fontSize="small" color="error" sx={{ verticalAlign: 'middle', ml: 0.5, ...sx }} />
    </GRTooltip>
  )
}
