import { AnalyticsProviders, IAnalyticsProvider } from '../types/IAnalyticsProvider'
import hubspotService from './HubSpotService'
import intercomService from './IntercomService'
import mixPanelService from './MixPanelService'

export interface ITrackEventOptions {
  /**
   * Data object to be sent to analytics service
   */
  data: { [key: string]: any }
  /**
   * Exclude service from tracking
   */
  serviceToExclude?: AnalyticsProviders[]
}

export interface IHubSpotTrackEventOptions extends ITrackEventOptions {
  /**
   * Hubspot internal name for custom behavioral event
   * Note: this must be created from hubspot website as well
   */
  hubspotInternalName?: string
}
class AnalyticsService {
  // List of analytics services to be used
  // If you create a new analytics service, add it here
  analyticsServices: IAnalyticsProvider[] = [mixPanelService, hubspotService, intercomService]

  // Variables to store the last tracked event & data
  lastTrackedEvent: string | null = null
  lastTrackedEventData: { [key: string]: any } | undefined

  /**
   * Track event
   * @param eventName Event name
   * @param options Event options { data: { key: value } }
   * @param verbose Set true for debugging purpose
   */
  trackEvent(eventName: string, options?: ITrackEventOptions, verbose: boolean = false) {
    const serviceToExclude = options && Array.isArray(options.serviceToExclude) ? options.serviceToExclude : []

    // Check if the event is the same as the last tracked event
    if (eventName === this.lastTrackedEvent && this.lastTrackedEventData === options?.data) {
      return
    }

    if (verbose) {
      // eslint-disable-next-line no-console
      console.log('AnalyticsService.trackEvent', eventName, options)
    }

    // Track event to all services except the ones in serviceToExclude
    this.analyticsServices.forEach((provider) => {
      if (!serviceToExclude.includes(provider.type)) {
        provider.trackEvent(eventName, options)
      }
    })

    // Update the lastTrackedEvent to the current event
    this.lastTrackedEvent = eventName
    this.lastTrackedEventData = options?.data
  }
}

const analyticsService = new AnalyticsService()

export default analyticsService
