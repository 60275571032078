import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Divider, Typography } from '@mui/material'

import GameSearchAndSort from '../../../../components/GameSearchAndSort/GameSearchAndSort'
import GameSubgenrePicker from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import MultiGameSelector from '../../../../components/MultiGameSelector/MultiGameSelector'
import { useSubgenrePicker } from '../../../../hooks/useSubgenrePicker'
import utilsService from '../../../../services/UtilsService'
import { useConceptTagsByTagIds, useConceptsByTags } from '../../../concepts/hooks/conceptHooks'
import { useFeatures } from '../../../feature/hooks/useFeatures'
import { Game } from '../../../game'
import { FeatureGame } from '../../../game/types/Game'
import { useCurrentMarket } from '../../../markets'
import { useGamesByFeatureScreenshots } from '../../hooks/useGamesByFeatureScreenshots'
import { useInitialGameFeatureState } from '../../hooks/useInitialState'
import { gameComparator } from '../../util/comparators'
import { subgenreFilter } from '../../util/filters'
import ImplementationPreviews from '../ImplementationPreviews/ImplementationPreviews'

interface GameByFeatureSelectionProps {
  featureIds: number[]
  choiceIds: number[]
  conceptTagIds: string[]
  hasLimitedAccess?: boolean
}

export const defaultMarketIso = 2001

const GameByFeatureSelection: React.FC<GameByFeatureSelectionProps> = ({ featureIds, choiceIds, conceptTagIds, hasLimitedAccess }) => {
  const { t } = useTranslation()
  const { currentMarketIso } = useCurrentMarket()
  const featureResult = useFeatures()
  const [sortCriteria, setSortCriteria] = useState<string>('sustained_grossing_rank')
  const [initialSelection] = useInitialGameFeatureState()
  const gameSorter = useMemo(() => gameComparator(currentMarketIso, sortCriteria), [sortCriteria, currentMarketIso])

  const features = useMemo(() => {
    const features =
      featureResult.data
        ?.map(({ features }) => features)
        .flat()
        .filter(({ legacyId }) => featureIds.includes(legacyId)) || []
    return features
    // eslint-disable-next-line
  }, [featureResult.isFetching, featureIds.length])

  const conceptTags = useConceptTagsByTagIds(conceptTagIds)
  const concepts = useConceptsByTags(conceptTagIds)
  const conceptIds = useMemo(() => concepts?.map((concept) => concept.id) || [], [concepts])

  const { subgenres, handleSubgenresChange } = useSubgenrePicker()
  const { data: games, isFetching } = useGamesByFeatureScreenshots(choiceIds, conceptIds)
  const [selectedGames, setSelectedGames] = useState<Game[]>([])
  const [searchGame, setSearchGame] = useState<Game | undefined>()
  const [searchGameText, setSearchGameText] = useState<string | undefined>()

  const filteredSelectedGames = useMemo(() => selectedGames.filter(subgenreFilter(subgenres)), [selectedGames, subgenres])

  const onSelectSearchResult = (value: string | Game) => {
    if (typeof value === 'string') {
      setSearchGameText(value)
      setSelectedGames([])
      setSearchGame(undefined)
    } else {
      const game = value as Game
      setSelectedGames([...selectedGames, game])
      setSearchGame(game)
      setSearchGameText(undefined)
    }
  }

  const gameOptions = useMemo(() => {
    const options = games
      ?.filter(subgenreFilter(subgenres))
      .filter((game) => {
        if (!searchGameText) return true
        const filteredByGameName = game.resolvedName.toLowerCase().includes(searchGameText.toLowerCase())
        const filteredByArtistName = game.artist.toLowerCase().includes(searchGameText.toLowerCase())
        const gameIsInitialGame = initialSelection?.gameId === game.id
        return filteredByGameName || filteredByArtistName || gameIsInitialGame
      })
      .map((game) => {
        if (!game.sranks[currentMarketIso]) {
          game.sranks = { ...game.sranks, [currentMarketIso]: defaultMarketIso }
        }
        if (!game.sdranks[currentMarketIso]) {
          game.sdranks = { ...game.sdranks, [currentMarketIso]: defaultMarketIso }
        }
        return game
      })
      .sort(gameSorter)

    return options
  }, [currentMarketIso, gameSorter, games, initialSelection?.gameId, searchGameText, subgenres])

  const sortedReleaseDate = useMemo(() => {
    const featureGames = filteredSelectedGames as FeatureGame[]
    return utilsService.oldestReleaseDate(featureGames)
  }, [filteredSelectedGames])

  return (
    <>
      <Typography variant="h2" mt={4}>
        {t('implementations:label_filtermode_description')}
      </Typography>
      <Typography variant="body1">{t('select-game:conventionals_picker_title')}</Typography>
      <Divider sx={{ my: 2 }} />
      <GameSubgenrePicker selectedSubgenres={subgenres} selectionChanged={handleSubgenresChange} showSubgenreDefinitionBtn />
      <GameSearchAndSort options={games} onChangeSearch={onSelectSearchResult} onChangeSort={setSortCriteria} sortValue={sortCriteria} />
      <Typography variant="h2" gutterBottom>
        {t('implementations:label_filtermode_sub_description')}
      </Typography>
      <MultiGameSelector
        loading={isFetching}
        options={!!searchGame ? [searchGame] : gameOptions}
        selectedGames={filteredSelectedGames}
        setSelectedGames={setSelectedGames}
        onClear={() => {
          setSearchGame(undefined)
          setSelectedGames([])
        }}
        sortCriteria={sortCriteria}
      />
      {selectedGames?.length > 0 && (
        <ImplementationPreviews
          gameSorter={gameSorter}
          features={features}
          conceptTags={conceptTags}
          games={filteredSelectedGames as FeatureGame[]}
          fromDate={sortedReleaseDate}
          minDate={sortedReleaseDate}
          hasLimitedAccess={hasLimitedAccess}
        />
      )}
    </>
  )
}

export default GameByFeatureSelection
