import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography, Chip, Box } from '@mui/material'

import { useInternalAccessCheck } from '../../../../account/hooks/roleHooks'
import { Duration } from '../../../types/TrackingEvents'
import { EventHighlightButton } from '../../EventHighlightButton/EventHighlightButton'
import { PriceRangeIndicator } from '../../PriceRangeIndicator/PriceRangeIndicator'

/**
 * Component displaying an event header
 */
type EventHeaderProps = {
  duration?: Duration
  eventName?: string
  inactive?: boolean
  eventId?: string
  eventTypeId?: string
  trackedGameId?: string
  priceRange?: {
    minPricePointValue: number
    maxPricePointValue: number
  } | null
  onEventHighlighted?: (eventId: string) => void
}

export const EventHeader: FC<EventHeaderProps> = ({ duration, eventName, inactive, eventId, eventTypeId, trackedGameId, priceRange, onEventHighlighted }) => {
  const { t } = useTranslation()
  const internalUser = useInternalAccessCheck() // NOTE: Remove Internal role check when we want to release LET Price points

  return (
    <div className="EventHeader">
      <Typography variant="h2" sx={{ mb: 1, pt: 0 }}>
        {eventName}
        {inactive && <Chip label={t('live-events:event_inactive')} color="warning" size="small" sx={{ verticalAlign: 'middle', ml: 1, mb: '3px' }} />}
      </Typography>

      <Box sx={{ textAlign: 'center', mb: 1.5 }}>
        <EventHighlightButton
          eventId={eventId || ''}
          trackedGameId={trackedGameId}
          eventType={eventTypeId}
          eventStartTime={duration?.start}
          onEventHighlighted={onEventHighlighted}
          size="small"
        />
      </Box>
      {internalUser && <PriceRangeIndicator priceRange={priceRange} />}
      <Box sx={{ mb: 3 }}></Box>
    </div>
  )
}
