import parse, { HTMLReactParserOptions } from 'html-react-parser'
import { FC } from 'react'

type HtmlContentParserProps = {
  rawHtml?: string
  parserOptions?: HTMLReactParserOptions
}

export const HtmlContentParser: FC<HtmlContentParserProps> = ({ rawHtml, parserOptions }) => {
  return rawHtml ? <>{parse(rawHtml, parserOptions)}</> : null
}
