import { useGetFeatureAndCreativeMetadataTagsForGameAppIdQuery } from '../../../api/connect'
import languageService from '../../../services/LanguageService'
import { Tag } from '../../../types/Tag'

export function useGameFeatureAndCreativeMetadataTags(appId: number | undefined) {
  const { data: metadataTags, error, isLoading } = useGetFeatureAndCreativeMetadataTagsForGameAppIdQuery(appId, { skip: !appId })

  const parsedMetadataTags: Tag[] = []

  if (metadataTags) {
    Object.keys(metadataTags).forEach((metadataTagType) => {
      Object.keys(metadataTags[metadataTagType as 'featureTags' | 'visualTags']).forEach((tagId) => {
        const value = metadataTags[metadataTagType as 'featureTags' | 'visualTags'][tagId]
        const newTag: Tag = {
          id: tagId,
          name: languageService.getTranslation('metadataTags', tagId),
          value: value,
          type: metadataTagType,
        }

        if ((metadataTagType === 'featureTags' && value >= 0.6) || (metadataTagType === 'visualTags' && value >= 0.3)) {
          parsedMetadataTags.push(newTag)
        }
      })
    })
  }

  return {
    data: metadataTags ? parsedMetadataTags.sort((a, b) => (a.name < b.name ? -1 : 1)) : undefined,
    isLoading,
    error,
  }
}
