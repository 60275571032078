import { FC, ReactNode, useMemo } from 'react'

import { useError } from '../../../../../hooks/useError'
import { useRouteModal } from '../../../../route-modals/hooks/useRouteModal'
import { useTrackedGames } from '../../../hooks/useTrackedGames'
import { LiveEventsOverviewDialog } from './LiveEventsOverviewDialog'

export type LiveEventsOverviewDialogParams = {
  trackedGameId: string
}

type LiveEventsOverviewDialogContainerProps = LiveEventsOverviewDialogParams & {
  children?: ReactNode
}

export const LiveEventsOverviewDialogContainer: FC<LiveEventsOverviewDialogContainerProps> = ({ trackedGameId, children }) => {
  const { popModal } = useRouteModal()

  const trackedGamesQuery = useTrackedGames({})
  useError({ error: trackedGamesQuery.error })
  const game = useMemo(() => trackedGamesQuery.data?.find((trackedGame) => trackedGame.game.id === trackedGameId), [trackedGamesQuery.data, trackedGameId])

  const handleClose = () => {
    popModal()
  }

  return <LiveEventsOverviewDialog open onClose={handleClose} trackedGame={game} isLoading={trackedGamesQuery.isFetching || trackedGamesQuery.isError} />
}
