import classNames from 'classnames'
import { addDays, endOfDay, isAfter, isBefore, startOfDay, sub, subDays } from 'date-fns'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MoreHoriz } from '@mui/icons-material'
import { Box, FormControl, Grid, IconButton, Menu, MenuItem } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { isValidDate } from '../../helpers/date'
import {
  DateRangeOptionType,
  dateRangeOption30Days,
  dateRangeOption7Days,
  dateRangeOption90Days,
  dateRangeOptionAllAvailable,
  dateRangeOptionLastYear,
} from '../../types/DateRangeOptions'
import './DateRangePicker.scss'

export type DateRangeValue = {
  fromDate?: Date | null
  toDate?: Date | null
}

interface DateRangePickerProps {
  onChangeValue?: (value?: DateRangeValue) => void
  value?: DateRangeValue
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  dateRangeOptions?: DateRangeOptionType[]
  allowSameFromAndToDate?: boolean
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  value,
  onChangeValue,
  minDate,
  maxDate = new Date(),
  disabled,
  dateRangeOptions = [dateRangeOption7Days, dateRangeOption30Days, dateRangeOption90Days, dateRangeOptionLastYear, dateRangeOptionAllAvailable],
  allowSameFromAndToDate = true,
}) => {
  const { t } = useTranslation()
  const [fromIsOpen, setFromIsOpen] = useState(false)
  const [toIsOpen, setToIsOpen] = useState(false)
  const [showOptions, setShowOptions] = useState(false)
  const dateRangeContainer = useRef(null)
  const rangeOptionOpener = useRef(null)

  const handleValueChange = useCallback(
    (value?: DateRangeValue) => {
      onChangeValue && onChangeValue(value)
    },
    [onChangeValue]
  )

  const handleSelectRange = useCallback(
    (value?: number) => {
      const newToDate = value ? endOfDay(new Date()) : endOfDay(maxDate)
      let newFromDate
      if (!value) {
        newFromDate = minDate || endOfDay(1605615507000)
      } else {
        const fromDateCandidate = startOfDay(sub(newToDate, { days: value }))
        const isFromDateAfterMinDate = minDate && isAfter(fromDateCandidate, minDate) ? true : !minDate
        newFromDate = isFromDateAfterMinDate ? fromDateCandidate : minDate ? minDate : null
      }

      handleValueChange({ fromDate: newFromDate, toDate: newToDate })
      setShowOptions(false)
    },
    [handleValueChange, maxDate, minDate]
  )

  const handleShowOptionsClick = useCallback(() => {
    if (!disabled) {
      setShowOptions(!showOptions)
    }
  }, [showOptions, disabled])

  const dateRangeOptionsHtml = useMemo(() => {
    return dateRangeOptions.map((dateRangeOption) => {
      return (
        <MenuItem key={dateRangeOption.id} onClick={() => handleSelectRange(dateRangeOption.days)}>
          {t(dateRangeOption.text, dateRangeOption.translationObject)}
        </MenuItem>
      )
    })
  }, [t, handleSelectRange, dateRangeOptions])

  return (
    <FormControl className={classNames('DateRangePicker', { disabled })} ref={dateRangeContainer} disabled={disabled}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item>
            <DatePicker
              maxDate={allowSameFromAndToDate ? maxDate : value?.toDate ? subDays(value?.toDate, 1) : maxDate}
              minDate={minDate}
              onChange={(newValue: Date | null) => {
                const newFromDateValue = isValidDate(newValue) ? startOfDay(newValue as Date) : startOfDay(new Date())
                const newToDateValue = value?.toDate && isBefore(value?.toDate, newFromDateValue) ? endOfDay(newFromDateValue) : value?.toDate
                handleValueChange({ fromDate: newFromDateValue, toDate: newToDateValue })
              }}
              onClose={() => setFromIsOpen(false)}
              onOpen={() => setFromIsOpen(true)}
              slotProps={{
                inputAdornment: {
                  position: 'start',
                },
                textField: {
                  className: fromIsOpen ? 'active' : '',
                  hiddenLabel: true,
                  variant: 'filled',
                  size: 'small',
                  InputProps: {
                    disableUnderline: true,
                    sx: { backgroundColor: 'transparent', '&:hover, &:focus, &:active': { backgroundColor: 'transparent' } },
                  },
                },
              }}
              onAccept={(date: Date | null) => {
                setToIsOpen(true)
              }}
              value={value?.fromDate}
              disabled={disabled}
            />
          </Grid>
          <Grid item>
            <Box mx={1}> - </Box>
          </Grid>
          <Grid item>
            <DatePicker
              maxDate={maxDate}
              minDate={allowSameFromAndToDate ? value?.fromDate || minDate : value?.fromDate ? addDays(value?.fromDate, 1) : minDate}
              onChange={(newValue: Date | null) => {
                const newDateValue = isValidDate(newValue) ? endOfDay(newValue as Date) : endOfDay(new Date())
                handleValueChange({ fromDate: value?.fromDate, toDate: newDateValue })
              }}
              onClose={() => setToIsOpen(false)}
              onOpen={() => setToIsOpen(true)}
              open={toIsOpen}
              slotProps={{
                inputAdornment: {
                  position: 'start',
                  color: toIsOpen ? 'primary' : 'inherit',
                },
                textField: {
                  className: toIsOpen ? 'active' : '',
                  hiddenLabel: true,
                  variant: 'filled',
                  size: 'small',
                  InputProps: {
                    disableUnderline: true,
                    sx: { backgroundColor: 'transparent', '&:hover, &:focus, &:active': { backgroundColor: 'transparent' } },
                  },
                },
              }}
              value={value?.toDate}
              disabled={disabled}
            />
          </Grid>
          <IconButton onClick={handleShowOptionsClick} disabled={disabled}>
            <MoreHoriz sx={{ mx: 1 }} ref={rangeOptionOpener} aria-describedby="ImplExample__dateRangeOptions" />
          </IconButton>
          <Menu
            id="ImplExample__dateRangeOptions"
            open={showOptions}
            onClose={() => setShowOptions(false)}
            anchorEl={rangeOptionOpener.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            {dateRangeOptionsHtml}
          </Menu>
        </Grid>
      </LocalizationProvider>
    </FormControl>
  )
}

export default DateRangePicker
