import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, FormControlLabel, Grid, MenuItem, Select, SelectChangeEvent, Switch, TableContainer, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { AnalystOverviewDialog } from '../../../../components/AnalystOverviewDialog/AnalystOverviewDialog'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { ReviewIndicator } from '../../../../components/ReviewIndicator/ReviewIndicator'
import { TrendIndicator, TrendIndicatorType } from '../../../../components/TrendIndicator/TrendIndicator'
import utilsService from '../../../../services/UtilsService'
import { useMarketShareAceessCheck } from '../../../account/hooks/roleHooks'
import QuarterSelector, { QuarterSelectorType } from '../../../quarter/components/QuarterSelector'
import quarterService, { QuarterDataKey } from '../../../quarter/services/QuarterService'
import { Quarter } from '../../../quarter/types/Quarter'
import { TopGameCardContent } from '../../../top-game/components/TopGameCard/TopGameCard'
import { TopGame } from '../../../top-game/types/TopGame'
import { MarketShareNumberType, MarketShareNumberTypeId } from '../../types/MarketShareNumberType'
import { MarketShareScopeType } from '../../types/MarketShareScopeType'
import { MarketShareScopeTypeFilter, MarketShareScopeTypeFilterAll } from '../../types/MarketShareScopeTypeFilter'

interface Props {
  calculatedQuarters: Quarter[]
  topGamesWithGameQuarters: TopGame[]
  marketShareScopeType: MarketShareScopeType
  marketShareNumberType: MarketShareNumberType
  selectedQuarters: Quarter[]
  marketShareScopeTypeFilter?: MarketShareScopeTypeFilter
  marketShareScopeTypeFilters: MarketShareScopeTypeFilter[]
  onMarketShareScopeTypeFilterChangeCallback?: (filter: MarketShareScopeTypeFilter) => void
  onQuartersChangeCallback?: (quarterIdentifier1: string, quarterIdentifier2: string) => void
  maxRows?: number
  selectedSubgenres?: string[]
  quarterType?: QuarterSelectorType
  filterGenreType?: 'string' | 'select'
}

const MarketShareTopGames: React.FC<Props> = ({
  calculatedQuarters,
  topGamesWithGameQuarters,
  marketShareScopeType,
  marketShareNumberType,
  selectedQuarters,
  marketShareScopeTypeFilter,
  marketShareScopeTypeFilters,
  onMarketShareScopeTypeFilterChangeCallback,
  onQuartersChangeCallback,
  maxRows,
  selectedSubgenres,
  quarterType = QuarterSelectorType.select,
  filterGenreType,
}) => {
  const { t } = useTranslation()
  const [showScopeTypeShare, setShowScopeTypeShare] = useState(false)
  const [analystOverviewDialogGame, setAnalystOverviewDialogGame] = useState<TopGame | null>(null)
  const hasAccessToMarketShare = useMarketShareAceessCheck()

  const filterGamesBySelectedQuartersRevenue = useCallback(
    (topGame: TopGame) => {
      const marketShareDownLoadAndRevenue = selectedQuarters.filter(
        (quarter) => topGame.marketLevelQuarters[quarter.quarterIdentifier][QuarterDataKey.RevenuePercentage] > 0
      )
      return !!marketShareDownLoadAndRevenue.length
    },
    [selectedQuarters]
  )

  const filteredTopGames = useMemo(() => {
    if (selectedSubgenres && selectedSubgenres.length) {
      return topGamesWithGameQuarters.filter((game) => selectedSubgenres.includes(game.conventionalSubgenreId))
    }

    const filteredTopGameWithMarketShare =
      marketShareScopeTypeFilter?.id === MarketShareScopeTypeFilterAll
        ? topGamesWithGameQuarters
        : topGamesWithGameQuarters.filter((game) => {
            return game[marketShareScopeType.gameDataValueId] === marketShareScopeTypeFilter?.id
          })

    return filteredTopGameWithMarketShare.filter(filterGamesBySelectedQuartersRevenue)
  }, [selectedSubgenres, marketShareScopeTypeFilter?.id, topGamesWithGameQuarters, filterGamesBySelectedQuartersRevenue, marketShareScopeType.gameDataValueId])

  const getGameMarketSharePercent = useCallback(
    (game: TopGame, valueKey: QuarterDataKey.RevenuePercentage | QuarterDataKey.DownloadsPercentage, selectedQuarter: Quarter) => {
      const value =
        showScopeTypeShare && marketShareScopeTypeFilter && marketShareScopeTypeFilter.id !== MarketShareScopeTypeFilterAll
          ? game.selectedScopeTypeQuarters[selectedQuarter.quarterIdentifier][valueKey]
          : game.marketLevelQuarters[selectedQuarter.quarterIdentifier][valueKey]
      return utilsService.formatPercent(value, {
        maximumFractionDigits: 2,
      })
    },
    [showScopeTypeShare, marketShareScopeTypeFilter]
  )

  const handleQuarterChange = useCallback(
    (event: SelectChangeEvent, index: 0 | 1) => {
      const value = event.target.value as string
      let quarterIdentifierArray: string[] = quarterService.mirrorSelectedQuartersIfNeeded(value, index, selectedQuarters)
      if (onQuartersChangeCallback) {
        onQuartersChangeCallback(quarterIdentifierArray[0], quarterIdentifierArray[1])
      }
    },
    [selectedQuarters, onQuartersChangeCallback]
  )

  const handleMarketShareScopeTypeFilterChange = useCallback(
    (event: SelectChangeEvent, marketShareScopeTypeFilters: MarketShareScopeTypeFilter[]) => {
      const value = event.target.value as string
      const newFilter = marketShareScopeTypeFilters.find((filter) => {
        return filter.id === value
      })

      if (newFilter && onMarketShareScopeTypeFilterChangeCallback) {
        onMarketShareScopeTypeFilterChangeCallback(newFilter)
      }
    },
    [onMarketShareScopeTypeFilterChangeCallback]
  )

  const getGameMarketShareValue = useCallback(
    (
      game: TopGame,
      valueKey: QuarterDataKey.RevenueTop500Value | QuarterDataKey.DownloadsTop500Value,
      selectedQuarter: Quarter,
      isCurrency: boolean = false
    ) => {
      const value = game.marketLevelQuarters[selectedQuarter.quarterIdentifier][valueKey]
      if (isCurrency) {
        return utilsService.formatCurrency(value, {
          shorten: true,
          mantissa: 2,
        })
      } else {
        return utilsService.formatNumber(value, {
          shorten: true,
          mantissa: 2,
        })
      }
    },
    []
  )

  const getGameMarketShareForKey = useCallback(
    (game: TopGame, valueKey: 'revenue' | 'downloads', selectedQuarter: Quarter) => {
      switch (valueKey) {
        case 'revenue':
          return marketShareNumberType.id === MarketShareNumberTypeId.Percentage
            ? getGameMarketSharePercent(game, QuarterDataKey.RevenuePercentage, selectedQuarter)
            : getGameMarketShareValue(game, QuarterDataKey.RevenueTop500Value, selectedQuarter, true)

        case 'downloads':
          return marketShareNumberType.id === MarketShareNumberTypeId.Percentage
            ? getGameMarketSharePercent(game, QuarterDataKey.DownloadsPercentage, selectedQuarter)
            : getGameMarketShareValue(game, QuarterDataKey.DownloadsTop500Value, selectedQuarter, false)
      }
    },
    [getGameMarketSharePercent, getGameMarketShareValue, marketShareNumberType.id]
  )

  const getGameMarketShareChange = useCallback(
    (game: TopGame, valueKey: 'revenue' | 'downloads', quarterIdentifier1: string, quarterIdentifier2: string) => {
      const quarters =
        showScopeTypeShare && marketShareScopeTypeFilter && marketShareScopeTypeFilter.id !== MarketShareScopeTypeFilterAll
          ? game.selectedScopeTypeQuarters
          : game.marketLevelQuarters
      switch (valueKey) {
        case 'revenue':
          return marketShareNumberType.id === MarketShareNumberTypeId.Percentage
            ? quarterService.getChangeDataForComparisonQuarterValue(QuarterDataKey.RevenuePercentage, quarterIdentifier1, quarterIdentifier2, quarters) * 100
            : quarterService.getChangeDataForComparisonQuarterValue(QuarterDataKey.RevenueTop500Value, quarterIdentifier1, quarterIdentifier2, quarters)

        case 'downloads':
          return marketShareNumberType.id === MarketShareNumberTypeId.Percentage
            ? quarterService.getChangeDataForComparisonQuarterValue(QuarterDataKey.DownloadsPercentage, quarterIdentifier1, quarterIdentifier2, quarters) * 100
            : quarterService.getChangeDataForComparisonQuarterValue(QuarterDataKey.DownloadsTop500Value, quarterIdentifier1, quarterIdentifier2, quarters)
      }
    },
    [showScopeTypeShare, marketShareNumberType.id, marketShareScopeTypeFilter]
  )

  const containerRef = useRef(null)

  const customProps = useMemo(() => {
    return {
      marketShareScopeType,
      marketShareNumberType,
      selectedQuarters,
      showScopeTypeShare,
      setShowScopeTypeShare,
      marketShareScopeTypeFilters,
      marketShareScopeTypeFilter,
      onMarketShareScopeTypeFilterChangeCallback,
      getGameMarketShareForKey,
      getGameMarketShareChange,
      handleQuarterChange,
    }
  }, [
    marketShareScopeType,
    marketShareNumberType,
    selectedQuarters,
    showScopeTypeShare,
    marketShareScopeTypeFilters,
    marketShareScopeTypeFilter,
    onMarketShareScopeTypeFilterChangeCallback,
    getGameMarketShareForKey,
    getGameMarketShareChange,
    handleQuarterChange,
  ])

  const tableColumns: GRTableColumn<TopGame, typeof customProps>[] = useMemo(
    () => [
      {
        labelAccessor: ({ customTableProps }) => (
          <>
            {customTableProps && customTableProps.marketShareScopeTypeFilter && (
              <Box mx={2}>
                {filterGenreType === 'string' ? (
                  <Typography>{customTableProps.marketShareScopeTypeFilter.name}</Typography>
                ) : (
                  <FormControl size="small" variant="outlined" fullWidth>
                    <Select
                      disabled={!hasAccessToMarketShare}
                      value={customTableProps.marketShareScopeTypeFilter.id}
                      onChange={(event) => handleMarketShareScopeTypeFilterChange(event, customTableProps.marketShareScopeTypeFilters)}
                    >
                      {customTableProps.marketShareScopeTypeFilters.map((filter) => {
                        return (
                          <MenuItem key={filter.id} value={filter.id}>
                            {filter.name.length > 40 ? `${filter.name.substring(0, 40)}...` : filter.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
              </Box>
            )}
          </>
        ),
        columns: [
          {
            labelAccessor: ({ customTableProps }) => (
              <>
                {customTableProps && customTableProps.marketShareScopeTypeFilter && (
                  <Box mx={3} mt={1}>
                    <GRTooltip content={t(`market-share:${customTableProps.marketShareScopeType.id}_share_tooltip`)}>
                      <FormControlLabel
                        disabled={
                          customTableProps.marketShareScopeTypeFilter.id === MarketShareScopeTypeFilterAll ||
                          customTableProps.marketShareNumberType.id === 'value'
                        }
                        control={
                          <Switch
                            checked={
                              customTableProps.marketShareScopeTypeFilter.id === MarketShareScopeTypeFilterAll ||
                              customTableProps.marketShareNumberType.id === 'value'
                                ? false
                                : customTableProps.showScopeTypeShare
                            }
                            onChange={() => customTableProps.setShowScopeTypeShare(!customTableProps?.showScopeTypeShare)}
                            color="primary"
                            size="small"
                          />
                        }
                        label={t<string>(`market-share:show_${customTableProps.marketShareScopeType.id}_share`)}
                      />
                    </GRTooltip>
                  </Box>
                )}
              </>
            ),
            accessor: ({ row: topGame, rowIndex }) => (
              <Grid container alignItems="center" justifyContent="center" spacing={2}>
                <Grid item xs>
                  <TopGameCardContent topGame={topGame} rank={rowIndex + 1} variant="table" />
                </Grid>
                <Grid item>{topGame.reviewId && topGame.reviewPublished && <ReviewIndicator onClick={() => setAnalystOverviewDialogGame(topGame)} />}</Grid>
              </Grid>
            ),
            cellProps: { align: 'left' },
            headerCellProps: { align: 'left' },
          },
        ],
      },
      // Q1
      {
        labelAccessor: ({ customTableProps }) => (
          <>
            {customTableProps && (
              <QuarterSelector
                quarterType={quarterType}
                selectedQuarter={customTableProps.selectedQuarters[0]}
                quarters={calculatedQuarters}
                onQuarterChangeCallback={(e) => customTableProps.handleQuarterChange(e, 0)}
              />
            )}
          </>
        ),
        columns: [
          {
            labelAccessor: () => <GRTooltip content={t('market-share:revenue_tooltip')}>{t('common:revenue_shorthand')}</GRTooltip>,
            accessor: ({ row: topGame, customTableProps }) => {
              return (
                <strong>
                  {customTableProps && <>{customTableProps.getGameMarketShareForKey(topGame, 'revenue', customTableProps.selectedQuarters[0])}</>}
                </strong>
              )
            },
            sortable: true,
            sortAccessor: ({ row: topGame, customTableProps }) => {
              if (customTableProps) {
                return topGame.marketLevelQuarters[customTableProps.selectedQuarters[0].quarterIdentifier].revenueTop500Value
              }

              return 0
            },
            sortOrder: SortOrder.DESC,
            cellProps: { align: 'center' },
            headerCellProps: { sx: { width: 80 }, align: 'center' },
          },
          {
            labelAccessor: () => <GRTooltip content={t('market-share:revenue_change_compared_tooltip')}>{t('common:change_text')}</GRTooltip>,
            accessor: ({ row: topGame, customTableProps }) => (
              <>
                {customTableProps && (
                  <TrendIndicator
                    value={customTableProps.getGameMarketShareChange(
                      topGame,
                      'revenue',
                      customTableProps.selectedQuarters[0].quarterIdentifier,
                      customTableProps.selectedQuarters[1].quarterIdentifier
                    )}
                    maximumFractionDigits={2}
                    type={TrendIndicatorType.Value}
                  />
                )}
              </>
            ),
            sortable: true,
            sortAccessor: ({ row: topGame, customTableProps }) =>
              quarterService.grTableSortAccessorForTopGameRevenueChange(
                customTableProps?.selectedQuarters[0].quarterIdentifier,
                customTableProps?.selectedQuarters[1].quarterIdentifier,
                topGame.marketLevelQuarters,
                customTableProps?.marketShareNumberType.id
              ),
            cellProps: { align: 'center' },
            headerCellProps: { sx: { width: 80 }, align: 'center' },
          },
          {
            labelAccessor: () => <GRTooltip content={t('market-share:downloads_tooltip')}>{t('common:downloads_shorthand')}</GRTooltip>,
            accessor: ({ row: topGame, customTableProps }) => (
              <strong>
                {customTableProps && <>{customTableProps.getGameMarketShareForKey(topGame, 'downloads', customTableProps.selectedQuarters[0])}</>}
              </strong>
            ),
            sortable: true,
            sortAccessor: ({ row: topGame, customTableProps }) => {
              if (customTableProps) {
                return topGame.marketLevelQuarters[customTableProps.selectedQuarters[0].quarterIdentifier].downloadsTop500Value
              }

              return 0
            },
            cellProps: { align: 'center' },
            headerCellProps: { sx: { width: 80 }, align: 'center' },
          },
          {
            labelAccessor: () => <GRTooltip content={t('market-share:downloads_change_compared_tooltip')}>{t('common:change_text')}</GRTooltip>,
            accessor: ({ row: topGame, customTableProps }) => (
              <>
                {customTableProps && (
                  <TrendIndicator
                    value={customTableProps.getGameMarketShareChange(
                      topGame,
                      'downloads',
                      customTableProps.selectedQuarters[0].quarterIdentifier,
                      customTableProps.selectedQuarters[1].quarterIdentifier
                    )}
                    maximumFractionDigits={2}
                    type={TrendIndicatorType.Value}
                  />
                )}
              </>
            ),
            sortable: true,
            sortAccessor: ({ row: topGame, customTableProps }) =>
              quarterService.grTableSortAccessorForTopGameDownloadsChange(
                customTableProps?.selectedQuarters[0].quarterIdentifier,
                customTableProps?.selectedQuarters[1].quarterIdentifier,
                topGame.marketLevelQuarters,
                customTableProps?.marketShareNumberType.id
              ),
            cellProps: { align: 'center' },
            headerCellProps: { sx: { width: 80 }, align: 'center' },
          },
        ],
      },
      // Q2
      {
        labelAccessor: ({ customTableProps }) => (
          <>
            {customTableProps && (
              <QuarterSelector
                quarterType={quarterType}
                selectedQuarter={customTableProps.selectedQuarters[1]}
                quarters={calculatedQuarters}
                onQuarterChangeCallback={(e) => customTableProps.handleQuarterChange(e, 1)}
              />
            )}
          </>
        ),
        columns: [
          {
            labelAccessor: () => <GRTooltip content={t('market-share:revenue_tooltip')}>{t('common:revenue_shorthand')}</GRTooltip>,
            accessor: ({ row: topGame, customTableProps }) => (
              <strong>{customTableProps && <>{customTableProps.getGameMarketShareForKey(topGame, 'revenue', customTableProps.selectedQuarters[1])}</>}</strong>
            ),
            sortable: true,
            sortAccessor: ({ row: topGame, customTableProps }) => {
              if (customTableProps) {
                return topGame.marketLevelQuarters[customTableProps.selectedQuarters[1].quarterIdentifier].revenueTop500Value
              }

              return 0
            },
            cellProps: { align: 'center' },
            headerCellProps: { sx: { width: 80 }, align: 'center' },
          },
          {
            labelAccessor: () => <GRTooltip content={t('market-share:revenue_change_compared_tooltip')}>{t('common:change_text')}</GRTooltip>,
            accessor: ({ row: topGame, customTableProps }) => (
              <>
                {customTableProps && (
                  <TrendIndicator
                    value={customTableProps.getGameMarketShareChange(
                      topGame,
                      'revenue',
                      customTableProps.selectedQuarters[1].quarterIdentifier,
                      customTableProps.selectedQuarters[0].quarterIdentifier
                    )}
                    maximumFractionDigits={2}
                    type={TrendIndicatorType.Value}
                  />
                )}
              </>
            ),
            sortable: true,
            sortAccessor: ({ row: topGame, customTableProps }) =>
              quarterService.grTableSortAccessorForTopGameRevenueChange(
                customTableProps?.selectedQuarters[1].quarterIdentifier,
                customTableProps?.selectedQuarters[0].quarterIdentifier,
                topGame.marketLevelQuarters,
                customTableProps?.marketShareNumberType.id
              ),
            cellProps: { align: 'center' },
            headerCellProps: { sx: { width: 80 }, align: 'center' },
          },
          {
            labelAccessor: () => <GRTooltip content={t('market-share:downloads_tooltip')}>{t('common:downloads_shorthand')}</GRTooltip>,
            accessor: ({ row: topGame, customTableProps }) => (
              <strong>
                {customTableProps && <>{customTableProps.getGameMarketShareForKey(topGame, 'downloads', customTableProps.selectedQuarters[1])}</>}
              </strong>
            ),
            sortable: true,
            sortAccessor: ({ row: topGame, customTableProps }) => {
              if (customTableProps) {
                return topGame.marketLevelQuarters[customTableProps.selectedQuarters[1].quarterIdentifier].downloadsTop500Value
              }

              return 0
            },
            cellProps: { align: 'center' },
            headerCellProps: { sx: { width: 80 }, align: 'center' },
          },
          {
            labelAccessor: () => <GRTooltip content={t('market-share:downloads_change_compared_tooltip')}>{t('common:change_text')}</GRTooltip>,
            accessor: ({ row: topGame, customTableProps }) => (
              <>
                {customTableProps && (
                  <TrendIndicator
                    value={customTableProps.getGameMarketShareChange(
                      topGame,
                      'downloads',
                      customTableProps.selectedQuarters[1].quarterIdentifier,
                      customTableProps.selectedQuarters[0].quarterIdentifier
                    )}
                    maximumFractionDigits={2}
                    type={TrendIndicatorType.Value}
                  />
                )}
              </>
            ),
            sortable: true,
            sortAccessor: ({ row: topGame, customTableProps }) =>
              quarterService.grTableSortAccessorForTopGameDownloadsChange(
                customTableProps?.selectedQuarters[1].quarterIdentifier,
                customTableProps?.selectedQuarters[0].quarterIdentifier,
                topGame.marketLevelQuarters,
                customTableProps?.marketShareNumberType.id
              ),
            cellProps: { align: 'center' },
            headerCellProps: { sx: { width: 80 }, align: 'center' },
          },
        ],
      },
    ],
    [filterGenreType, hasAccessToMarketShare, handleMarketShareScopeTypeFilterChange, t, quarterType, calculatedQuarters]
  )

  const [columns, setColumns] = useState<GRTableColumn<TopGame, typeof customProps>[]>(tableColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<TopGame, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  useEffect(() => {
    handleColumnsUpdate(tableColumns)
  }, [handleColumnsUpdate, tableColumns, filteredTopGames])

  return (
    <div className="MarketShareTopGames">
      <TableContainer ref={containerRef}>
        <GRTable
          rows={filteredTopGames}
          maxRows={maxRows}
          columns={columns}
          striped
          hoverable
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey="id"
          scroller={containerRef}
          customProps={customProps}
          noRowsLabel={t('common:no_games_available')}
        />
      </TableContainer>

      {analystOverviewDialogGame && analystOverviewDialogGame.reviewId && (
        <AnalystOverviewDialog
          reviewId={analystOverviewDialogGame?.reviewId}
          gameName={analystOverviewDialogGame?.name}
          open={!!analystOverviewDialogGame}
          onClose={() => setAnalystOverviewDialogGame(null)}
        />
      )}
    </div>
  )
}

export default MarketShareTopGames
