import { format, parseISO } from 'date-fns'
import { useMemo } from 'react'

import { useSearchParams } from '../../../../hooks/useSearchParams'

type Source = {
  date: string
}

type Destination = {
  date: Date
}

export const useLiveEventsTrackingEventsSearchParams = () => {
  const mapper = useMemo(() => {
    return {
      mapFromUrl: (source: Source) => ({
        date: parseISO(source.date || format(Date.now(), 'yyyy-MM-dd')),
      }),
      mapToUrl: (destination: Destination) => ({
        date: format(destination.date, 'yyyy-MM-dd'),
      }),
    }
  }, [])

  return useSearchParams<Source, Destination>(mapper)
}
