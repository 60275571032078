import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Card, CardActionArea, CardContent, CardHeader, Chip, Divider, Grid, IconButton, Typography } from '@mui/material'

import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import { GRCountryFlag } from '../../../../../components/GRCountryFlag/GRCountryFlag'
import { MoreScrollContentIndicator } from '../../../../../components/MoreScrollContentIndicator/MoreScrollContentIndicator'
import { TrendIndicator } from '../../../../../components/TrendIndicator/TrendIndicator'
import { ValueIndicator, ValueType } from '../../../../../components/ValueIndicator/ValueIndicator'
import { Game } from '../../../../game'
import SelectedSubgenreChips from '../../../../news/components/SelectedSubgenresChips/SelectedSubgenreChips'
import { useSegmentData } from '../../../hooks/marketExplorerHooks'
import { MarketExplorerSegmentConfiguration } from '../../../types/MarketExplorerSegmentConfiguration'
import { MarketExplorerSegmentColorBadge } from '../../MarketExplorerSegmentColorBadge/MarketExplorerSegmentColorBadge'
import { SegmentGamesIconGrid } from '../../MarketExplorerSegmentEditDialog/SegmentGamesIconGrid/SegmentGamesIconGrid'
import { SegmentFilterChips } from '../MarketExplorerSegment/SegmentFilterChips/SegmentFilterChips'
import { MarketExplorerFeatureChoiceItem } from './MarketExplorerFeatureChoiceItem/MarketExplorerFeatureChoiceItem'

/**
 * A component for rendering a single market explorer segment card.
 */
export type MarketExplorerSegmentCardProps = {
  segment: MarketExplorerSegmentConfiguration
  segmentIndex: number
  onEdit?: (segmentIdex: number) => void
  onRemove?: (segmentIndex: number) => void
  onToggleVisibility?: (segmentIndex: number) => void
  games: Game[]
  isLoading?: boolean
  complementaryData: ReturnType<typeof useSegmentData>['complementaryData']
  onMouseOver?: () => void
  onMouseLeave?: () => void
  showToggleVisibility?: boolean
  chartNodesCount?: number
  overrideColorIndex?: number
}

export const MarketExplorerSegmentCard: FC<MarketExplorerSegmentCardProps> = ({
  segment,
  segmentIndex,
  onEdit,
  onRemove,
  onToggleVisibility,
  games,
  isLoading,
  complementaryData,
  onMouseLeave,
  onMouseOver,
  showToggleVisibility,
  chartNodesCount,
  overrideColorIndex,
}) => {
  const { t } = useTranslation()

  const isShowingAllGames =
    Object.values(segment?.subgenres || {}).filter((subgenreSelected) => !!subgenreSelected).length === 0 &&
    (!segment.gameIds || segment.gameIds?.length === 0) &&
    (!segment.featureChoices || Object.keys(segment.featureChoices || {}).length === 0) &&
    Object.values(segment.filters.ranks).filter((filterValue) => !!filterValue).length === 0 &&
    Object.values(segment.filters.demographics).filter((filterValue) => !!filterValue).length === 0 &&
    Object.values(segment.filters.motivations).filter((filterValue) => !!filterValue).length === 0 &&
    Object.values(segment.filters.archetypes).filter((filterValue) => !!filterValue).length === 0

  return (
    <Card sx={{ minHeight: '100%' }} elevation={3} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <CardHeader
        title={
          <Grid container spacing={2} alignItems="center" justifyContent="space-between" direction="row">
            <Grid item>
              <Grid container item spacing={2} alignItems="center" direction="row">
                <Grid item>
                  <MarketExplorerSegmentColorBadge orderNumber={overrideColorIndex ? overrideColorIndex + 1 : segmentIndex + 1} />
                </Grid>
                <Grid item>
                  <GRCountryFlag countryCode={segment.marketIso} style={{ display: 'block' }} />
                </Grid>
                <Grid item>
                  {isLoading ? (
                    <GRCircularProgress circularProgressProps={{ size: '1em', sx: { display: 'block' } }} />
                  ) : chartNodesCount || chartNodesCount === 0 ? (
                    <Typography variant="body1">{t('segment:games_count_chart', { number: chartNodesCount, total: games.length })}</Typography>
                  ) : (
                    <Typography variant="body1">{t('segment:games_count', { number: games.length })}</Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item alignSelf="flex-end">
              {onToggleVisibility && !showToggleVisibility && (
                <IconButton size="small" onClick={() => onToggleVisibility(segmentIndex)}>
                  {segment.visible ? <VisibilityIcon fontSize="inherit" /> : <VisibilityOffIcon fontSize="inherit" />}
                </IconButton>
              )}
              {onEdit && (
                <IconButton size="small" onClick={() => onEdit(segmentIndex)}>
                  <EditIcon fontSize="inherit" />
                </IconButton>
              )}
              {onRemove && (
                <IconButton size="small" onClick={() => onRemove(segmentIndex)}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        }
      />
      <Divider />

      {isLoading ? (
        <CardContent>
          <GRCircularProgress />
        </CardContent>
      ) : (
        <>
          <CardContent>
            <Grid container justifyContent="space-around" spacing={2}>
              <Grid item>
                <EstimatesSection
                  label={t('common:180_days_revenue_shorthand')}
                  value={complementaryData.estimates?.revenue180d || 0}
                  valueType={ValueType.Currency}
                  trend={complementaryData.revenueTrend180d}
                />
              </Grid>
              <Grid item>
                <EstimatesSection
                  label={t('common:180_days_downloads_shorthand')}
                  value={complementaryData.estimates?.downloads180d || 0}
                  valueType={ValueType.Number}
                  trend={complementaryData.downloadsTrend180d}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActionArea onClick={() => onEdit && onEdit(segmentIndex)} disableRipple>
            <MoreScrollContentIndicator component={CardContent} sx={{ maxHeight: '6rem', overflow: 'auto' }}>
              {isShowingAllGames && (
                <Grid container justifyContent="center">
                  <Typography variant="body1">{t('segment:no_games_or_features_selected')}</Typography>
                </Grid>
              )}
              <Grid container direction="column" gap={1}>
                {segment?.subgenres && <SelectedSubgenreChips selectedSubgenres={segment?.subgenres} smallChips showTitle={false} />}
                <SegmentFilterChips filterGroups={segment.filters} />
                {segment.ownGames && (
                  <Grid item>
                    <Chip color="primary" size="small" label={t('game-analyzer:my_portfolio_title')} />
                  </Grid>
                )}
                {segment.gameIds && segment.gameIds.length > 0 && (isLoading ? <GRCircularProgress /> : <SegmentGamesIconGrid games={games} />)}
                {Object.entries(segment.featureChoices || {}).map(([featureId, choiceIds]) => (
                  <MarketExplorerFeatureChoiceItem key={featureId} featureLegacyId={+featureId} featureChoiceLegacyIds={choiceIds} />
                ))}
              </Grid>
            </MoreScrollContentIndicator>
          </CardActionArea>
        </>
      )}
    </Card>
  )
}

type EstimatesSectionProps = {
  label: string
  value: number
  valueType: ValueType
  trend: number
}

export const EstimatesSection: FC<EstimatesSectionProps> = ({ label, value, valueType, trend }) => {
  return (
    <Grid container item direction="column" flexShrink={1} flexGrow={1} alignItems="center">
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body1" component="div">
        <Grid container gap={1}>
          <ValueIndicator value={value} type={valueType} maximumFractionDigits={2} shorten displayZero />
          <TrendIndicator value={trend} maximumFractionDigits={1} maxValue={10} />
        </Grid>
      </Typography>
    </Grid>
  )
}
