import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { AnalyticsOutlined, ContentCopy, ErrorOutline, SettingsOutlined, TableChartOutlined } from '@mui/icons-material'
import { Box, Chip, Divider, Grid, GridProps, ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'

import AddArea from '../../../../components/AddArea/AddArea'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import type { Game } from '../../../game'
import GameCard from '../../../game/components/GameCard/GameCard'
import { GameStageId } from '../../../game/types/GameStageId'
import { useCurrentMarket } from '../../../markets/hooks/useCurrentMarket'
import { getAverageRevenueLabel } from '../../utils/getAverageRevenueLabel'
import './GameStage.scss'

export interface Stage {
  games: Game[]
  title: string
  id: GameStageId
}

export interface GameCardButtons {
  text: string
  onClick: (game: Game) => void
  icon: ReactNode
  hide?: boolean
}

/**
 * Game Stage is component containing list games in card style + specific game action + add more games feature
 */
interface GameStageProps {
  stage: Stage
  showDivider?: boolean
  gridProps?: GridProps
  onAddMoreGameClick: (gameStageId: number) => void
  onGameSettingClick: (game: Game) => void
  allowAddMoreGame?: boolean
  onGameCloneClick: (game: Game) => void
}
const GameStage: React.FC<GameStageProps> = ({
  stage = { games: [], title: '', id: 0 },
  onGameSettingClick,
  showDivider,
  gridProps,
  onAddMoreGameClick,
  onGameCloneClick,
  allowAddMoreGame,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentMarketIso } = useCurrentMarket()
  const navigateToAnalyzer = (game: Game) => {
    navigate(`/game-analyzer/${game.id}`)
  }
  const gameCardButtonActions = (game: Game): GameCardButtons[] => {
    const defaultActions = [
      {
        text: t('overview:game_overview'),
        onClick: (game: Game) => {
          navigate(`/game/${game.id}`)
        },
        icon: <TableChartOutlined fontSize="small" />,
        hidden: !game.gpsPerMarket[currentMarketIso],
      },
      {
        text: game.gpsPerMarket[currentMarketIso] ? t('common:update') : t('common:continue_analysis'),
        onClick: navigateToAnalyzer,
        icon: <AnalyticsOutlined fontSize="small" />,
      },
      {
        text: t('game-header:clone_game'),
        onClick: (game: Game) => {
          onGameCloneClick(game)
        },
        icon: <ContentCopy fontSize="small" />,
      },
      {
        text: t('common:settings'),
        onClick: (game: Game) => {
          onGameSettingClick(game)
        },
        icon: <SettingsOutlined fontSize="small" />,
      },
    ]
    return defaultActions.filter((action) => !action.hidden)
  }

  const gameCards = () => {
    return stage.games.map((game) => (
      <Grid item key={game.id}>
        <GameCard
          game={game}
          onCardClick={() => {
            navigateToAnalyzer(game)
          }}
          showPrivateIcon
          extraTags={
            !!getAverageRevenueLabel(game, currentMarketIso)
              ? [
                  {
                    customComponent: (
                      <GRTooltip key="revenue" content={t('overview:tooltip_feature_set_revenue')}>
                        <Chip
                          size="small"
                          label={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <span> {getAverageRevenueLabel(game, currentMarketIso)}</span>
                              <ErrorOutline sx={{ ml: 0.5, width: '0.6em', height: '0.6em' }} fontSize="small" />
                            </Box>
                          }
                        />
                      </GRTooltip>
                    ),
                  },
                ]
              : undefined
          }
        >
          <GameCard.Actions className="GameStage__card-actions">
            <MenuList>
              {gameCardButtonActions(game).map((button) => (
                <MenuItem
                  onClick={() => button.onClick(game)}
                  sx={{
                    pl: 2,
                    pr: 2,
                  }}
                  key={button.text}
                >
                  <ListItemIcon>{button.icon}</ListItemIcon>
                  <ListItemText>{button.text}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </GameCard.Actions>
        </GameCard>
      </Grid>
    ))
  }

  return (
    <>
      <Grid container flexDirection="column" {...gridProps}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2} pr={2} mb={2}>
          <Grid item>
            <h3 className="GameStage__title">{`${stage.title}`}</h3>
          </Grid>
          <Grid item>
            <p className="GameStage__subtitle">{t('common:game_count', { count: stage.games.length })}</p>
          </Grid>
        </Grid>
        <Grid flexDirection={'column'} container item xs={12} rowSpacing={2} paddingRight={2}>
          {gameCards()}
          <Grid item>
            <AddArea
              label={t('common:add_game')}
              onClick={() => {
                if (allowAddMoreGame) {
                  onAddMoreGameClick(stage.id)
                }
              }}
              disabled={!allowAddMoreGame}
            />
          </Grid>
        </Grid>
      </Grid>
      {showDivider && <Divider orientation="vertical" flexItem />}
    </>
  )
}

export default GameStage
