export interface GameSearchDialogConfig {
  type: GameSearchDialogType
  tabs: GameSearchDialogTab[]
  gameSelectorCounts?: {
    min: number
    max: number
  }
  hideSubgenrePicker?: boolean
  trackedGamesPermissionMap?: TrackedGamesPermissionMap
  /**
   * If true, the dialog will show games that are not analyzed yet.
   * This affects only GameSearchDialogType.GameSelector, GameSearchDialogType.GameBenchmark, GameSearchDialogType.GameFollow types.
   * Default is false
   */
  showNonAnalyzedGames?: boolean
}

export interface TrackedGamesPermissionMap {
  [gameId: string]: boolean
}

export enum GameSearchDialogType {
  GameSearch = 'GameSearch',
  GameSelector = 'GameSelector',
  GameFollow = 'GameFollow',
  GameBenchmark = 'GameBenchmark',
  TrackedGames = 'TrackedGames',
}

export enum GameSearchDialogTab {
  Search = 'Search',
  SoftLaunch = 'SoftLaunch',
  MyPortfolio = 'MyPortfolio',
}
