import React from 'react'
import { Trans } from 'react-i18next'

import { CloseRounded } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material'

import { MotivationKey } from '../../../market-explorer/types/MotivationType'
import { ArchetypeDescriptions } from '../ArchetypeDescriptions/ArchetypeDescriptions'
import { MotivationRadarChartProps } from '../MotivationRadarChart/MotivationRadarChart'

interface ArchetypeDescriptionsModalProps extends MotivationRadarChartProps {
  motivationKey: MotivationKey
  onSetMotivationKey: (key?: MotivationKey) => void
}

export const ArchetypeDescriptionsModal: React.FC<ArchetypeDescriptionsModalProps> = ({ labels, motivationKey, onSetMotivationKey, datasets, colorLists }) => {
  const onClose = () => {
    onSetMotivationKey(undefined)
  }

  return (
    <Dialog open={true} maxWidth="lg" onClose={onClose} className="ArchetypeDescriptionsModal" disableRestoreFocus>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h3">
            <Trans i18nKey="motivations:archetype_descriptions" />
          </Typography>
          <IconButton className="closeButton" onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <ArchetypeDescriptions
          datasets={datasets}
          labels={labels}
          onSetMotivationKey={onSetMotivationKey}
          motivationKey={motivationKey}
          colorLists={colorLists}
        />
      </DialogContent>
    </Dialog>
  )
}
