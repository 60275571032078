import { t } from 'i18next'
import { FC } from 'react'
import { Controller, useForm, useFormContext } from 'react-hook-form'

import { Add } from '@mui/icons-material'
import { Grid, TextField } from '@mui/material'

import { ActionButton } from '../../../../../components/ActionButton/ActionButton'
import { Currency } from '../../../../../types/Currency'
import { EventEditForm, EventEditFormPricePoints } from '../../../types/EventEditForm'
import { PricePointSelectMenu } from '../PricePointSelectMenu/PricePointSelectMenu'

type PricePointAdderProps = {
  pricePoints: EventEditFormPricePoints
  onAddPricePoint: (newPricePoint: { value: string; currencyId: string; description: string }) => void
  currencies: Currency[] | undefined
}

export const PricePointAdder: FC<PricePointAdderProps> = ({ pricePoints, currencies, onAddPricePoint }) => {
  const form = useFormContext<EventEditForm>()
  const formPricePoints = form.watch('event.pricePoints')
  const defaultValues = { price: '', currencyId: '', description: '' }
  const {
    control,
    getValues,
    watch,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues: defaultValues,
  })

  watch()

  const isValidNumber = () => {
    return Number.isNaN(+getValues('price'))
  }

  const isDuplicate = () => {
    const duplicate = Object.values(formPricePoints).filter(
      (pricePoint) => pricePoint.currencyId === getValues('currencyId') && pricePoint.value === getValues('price')
    )
    return duplicate.length ? true : false
  }

  const addNewPricePoint = () => {
    const pricePointValues = {
      value: getValues('price'),
      currencyId: getValues('currencyId'),
      description: getValues('description'),
    }
    onAddPricePoint(pricePointValues)
    reset(defaultValues)
  }

  return (
    <Grid container spacing={2} alignItems="center" wrap="wrap">
      <Grid item xs={3.5}>
        <Controller
          name="price"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextField
                required
                type="text"
                value={field.value}
                onChange={field.onChange}
                variant="outlined"
                size="small"
                label={t('internal-live-events:price_point')}
                fullWidth
                error={isValidNumber() || isDuplicate()}
                helperText={
                  isValidNumber() ? t('internal-live-events:enter_valid_number') : isDuplicate() ? t('internal-live-events:duplicate_price_point') : null
                }
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={3.3}>
        <Controller
          name={'currencyId'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return <PricePointSelectMenu required value={field.value || ''} onChange={field.onChange} currencies={currencies || []} autoWidth={false} />
          }}
        />
      </Grid>
      <Grid item xs={3.7}>
        <Controller
          name="description"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                type="text"
                value={field.value}
                multiline
                onChange={field.onChange}
                variant="outlined"
                size="small"
                label={t('internal-live-events:description')}
                fullWidth
              />
            )
          }}
        />
      </Grid>
      <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
        <ActionButton
          sx={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '0', transform: 'translateX(5px)' }}
          onClick={addNewPricePoint}
          disabled={!(isValid && !isValidNumber() && !isDuplicate())}
        >
          <Add fontSize="small" />
        </ActionButton>
      </Grid>
    </Grid>
  )
}
