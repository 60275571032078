import { useMemo } from 'react'

import { Category, useGetCategoriesFeaturesQuery, useGetCategoriesMapQuery, useGetScreenshotCountsQuery } from '../../../api/core'
import languageService from '../../../services/LanguageService'
import { EnrichedFeature } from '../../implementation-examples/types/ImplementationExamples'
import { useCurrentMarket } from '../../markets'

export type FeatureCategory = Category & {
  features: EnrichedFeature[]
  name: string
}

/**
 * Get categories, features and optional counts of screenshots per feature. Leaving the counts out makes
 * the API call several times quicker, so please skip the count number always if it's needed.
 *
 * @param skipCounts to optionally leave screenshot counts out (faster, recommended)
 * @param includeHiddenScreenshots defaults to false, not include them
 */
interface UseFeatureProps {
  skipCounts?: boolean
  includeHiddenScreenshots?: boolean
  removeFeatureWithoutExamples?: boolean
  marketIso?: string
}
export const useFeatures = (options?: UseFeatureProps) => {
  const { skipCounts, includeHiddenScreenshots, marketIso, removeFeatureWithoutExamples = true } = options || {}

  const { currentMarketIso } = useCurrentMarket()
  const selectedMarketIso = marketIso ? marketIso : currentMarketIso
  const categoriesMapResult = useGetCategoriesMapQuery({ marketIso: selectedMarketIso })
  const categories = useMemo(() => (categoriesMapResult.data ? Object.keys(categoriesMapResult.data) : []), [categoriesMapResult.data])
  const categoriesFeaturesResult = useGetCategoriesFeaturesQuery({ categories, marketIso: selectedMarketIso })
  const screenshotCountsResults = useGetScreenshotCountsQuery({}, { skip: skipCounts })
  const result = {
    isSuccess: categoriesMapResult.isSuccess && categoriesFeaturesResult.isSuccess && (skipCounts || screenshotCountsResults.isSuccess),
    isError: categoriesMapResult.isError || categoriesFeaturesResult.isError || screenshotCountsResults.isError,
    isFetching: categoriesMapResult.isFetching || categoriesFeaturesResult.isFetching || (!skipCounts && screenshotCountsResults.isFetching),
    isLoading: categoriesMapResult.isLoading || categoriesFeaturesResult.isLoading || (!skipCounts && screenshotCountsResults.isLoading),
  }
  if (categoriesMapResult.data && categoriesFeaturesResult.data && (skipCounts || screenshotCountsResults.data)) {
    const data = Object.values(categoriesMapResult.data).map((category) => ({
      ...category,
      name: languageService.getTranslation('categories', category.legacyId.toString()),
      features:
        categoriesFeaturesResult.data !== undefined
          ? categoriesFeaturesResult.data[category.id]
            ? categoriesFeaturesResult.data[category.id]
                .filter((feature) => feature.active && !feature.hidden && (includeHiddenScreenshots || !feature.hideScreenshots))
                .map(
                  (feature) =>
                    ({
                      ...feature,
                      name: languageService.getTranslation('features', feature.legacyId.toString()),
                      screenshotCount: skipCounts
                        ? undefined
                        : screenshotCountsResults.data !== undefined
                        ? screenshotCountsResults.data[feature.legacyId]
                        : undefined,
                    } as EnrichedFeature)
                )
                .filter((feature) => {
                  if (skipCounts) {
                    return true
                  }

                  if (removeFeatureWithoutExamples) {
                    return feature.screenshotCount !== undefined && feature.screenshotCount > 0
                  }

                  return true
                })
            : ([] as EnrichedFeature[])
          : ([] as EnrichedFeature[]),
    }))
    return {
      data,
      ...result,
    }
  } else {
    return {
      data: undefined,
      ...result,
    }
  }
}
