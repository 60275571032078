import { useCallback } from 'react'

import { useRouteModal } from '../../route-modals/hooks/useRouteModal'
import { EModalType } from '../../route-modals/types/modalTypes'

export const useLiveEventsAnalystOverviewModal = () => {
  const { pushModal } = useRouteModal()
  const showModal = useCallback(
    ({ trackedGameId }: { trackedGameId: string }) => {
      pushModal({
        type: EModalType.LIVE_EVENT_ANALYST_OVERVIEW,
        params: {
          trackedGameId,
        },
      })
    },
    [pushModal]
  )

  return {
    showModal,
  }
}
