import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowForward } from '@mui/icons-material'
import { Box, Divider, Grid, Typography } from '@mui/material'
import { TypographyProps } from '@mui/system'

import { CategoriesFeatures, CategoriesMap, Feature } from '../../../../api/core'
import { Analysis } from '../../../analysis/types/Analysis'

type featureDiff = {
  latestChoice: string
  prevChoice: string
  feature: Feature
}

/**
 * FeatureSetChanges Show list of feature changes after update
 */
interface FeatureSetChangesProps {
  latestAnalysis?: Analysis
  prevAnalysis?: Analysis
  categoriesMap?: CategoriesMap
  features?: CategoriesFeatures
}
const FeatureSetChanges: React.FC<FeatureSetChangesProps> = ({ latestAnalysis = {}, prevAnalysis = {}, categoriesMap = {}, features = {} }) => {
  const categoriesKeys = Object.keys(categoriesMap)
  const { t } = useTranslation()

  const changedFeatures = useMemo(() => {
    const { answers = {} } = latestAnalysis
    const { answers: prevAnswers = {} } = prevAnalysis

    return categoriesKeys.reduce<featureDiff[]>((result, categoryId) => {
      if (JSON.stringify(answers[categoryId]) === JSON.stringify(prevAnswers[categoryId])) {
        return result
      }

      const changedFeatures = Object.keys(answers[categoryId]).reduce<featureDiff[]>((featuresDiffs, featureId) => {
        const selectedFeature = features[categoryId].find((feature) => feature.id === featureId)
        if (!selectedFeature) {
          return featuresDiffs
        }

        const latestAnswer = answers[categoryId][featureId]
        const latestChoice = selectedFeature.choices.find((choice) => choice.weightId === latestAnswer)?.choice.trim()
        const prevAnswer = prevAnswers[categoryId][featureId]
        const prevChoice = selectedFeature.choices.find((choice) => choice.weightId === prevAnswer)?.choice.trim()

        if (!latestChoice || !prevChoice) {
          return featuresDiffs
        }

        if (latestChoice !== prevChoice) {
          return [...featuresDiffs, { latestChoice, prevChoice, feature: selectedFeature }]
        }

        return featuresDiffs
      }, [])

      return [...result, ...changedFeatures]
    }, [])
  }, [categoriesKeys, features, latestAnalysis, prevAnalysis])

  const commonTextProps: TypographyProps = {
    fontWeight: 'bold',
    textAlign: 'center',
  }

  if (!changedFeatures.length) return null

  return (
    <>
      <Box mb={5}></Box>
      <Typography variant="h3" sx={{ mb: 1 }}>
        {t('newsfeed:changes_in_feature_set_topic')}
      </Typography>
      <Divider sx={{ mb: 2 }} />
      {changedFeatures.map((changedFeature, index) => (
        <div key={changedFeature.feature.id}>
          <Grid container>
            <Grid item xs={3}>
              <Typography>{changedFeature.feature.name}</Typography>
            </Grid>
            <Grid item display="flex" justifyContent="center" xs={4}>
              <Typography {...commonTextProps}>{changedFeature.prevChoice}</Typography>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="center">
              <ArrowForward />
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center">
              <Typography color="primary" {...commonTextProps}>
                {changedFeature.latestChoice}
              </Typography>
            </Grid>
          </Grid>
          {index !== changedFeatures.length - 1 && <Divider sx={{ mt: 1, mb: 1 }} />}
        </div>
      ))}
    </>
  )
}

export default FeatureSetChanges
