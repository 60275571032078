import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { t } from 'i18next'
import React, { useEffect, useMemo, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { Trans } from 'react-i18next'

import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material'

import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { ConfirmDialogData } from '../../../../types/ConfirmDialogData'
import './RichTextDialog.scss'

/**
 * RichTextDialog to create rich text dialog
 */
interface RichTextDialogProps {
  open: boolean
  onClose: () => void
  onSave: (content: string) => void
  onDelete?: (noteId: string | undefined) => void
  label: string
  note?: string
  noteId?: string
}

const RichTextDialog: React.FC<RichTextDialogProps> = ({ open, onClose, label, note, onSave, onDelete, noteId }) => {
  const initEditorState = useMemo(() => {
    if (!note) {
      return EditorState.createEmpty()
    }
    const blocksFromHtml = htmlToDraft(note || '')
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
    return EditorState.createWithContent(contentState)
  }, [note])

  const [editorState, setEditorState] = useState(initEditorState)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [disableOnSave, setDisableOnSave] = useState(true)
  const newContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))

  const deleteConfirmDialogProps: ConfirmDialogData<Object> = {
    title: t('common:confirm'),
    confirmText: <Trans i18nKey={'feature:do_you_really_want_to_delete_this_note'} />,
    destructiveAction: true,
    actionText: t('common:delete'),
  }
  const onConfirmDialogClose = (confirmDialog?: ConfirmDialogData<Object>) => {
    if (!!confirmDialog && onDelete) {
      onDelete(noteId)
      setEditorState(EditorState.createEmpty())
    }
    setOpenDeleteConfirm(false)
  }

  const handleClose = () => {
    if (note === undefined) setEditorState(EditorState.createEmpty())
    else if (note !== newContent) setEditorState(initEditorState)
    onClose()
  }

  useEffect(() => {
    if (note === newContent || newContent === '<p></p>\n') {
      setDisableOnSave(true)
      return
    }
    setDisableOnSave(false)
  }, [editorState, newContent, note])

  return (
    <>
      <ConfirmDialog open={openDeleteConfirm} confirmDialogData={deleteConfirmDialogProps} onClose={onConfirmDialogClose} />
      <Dialog className="RichTextDialog" open={open}>
        <GRDialogTitle onClose={handleClose}>{label}</GRDialogTitle>
        <DialogContent className="RichTextDialog__DialogContent" dividers>
          <Editor
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'link', 'textAlign', 'colorPicker'],
              inline: { options: ['bold', 'italic', 'underline'] },
            }}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            editorState={editorState}
            onEditorStateChange={(state) => {
              setEditorState(state)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button style={{ justifyContent: 'flex-start' }} disabled={!note} color="warning" variant="contained" onClick={() => setOpenDeleteConfirm(true)}>
            {t('common:delete')}
          </Button>

          <Box sx={{ flex: '1 0 0' }} />
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {t('common:cancel')}
          </Button>
          <Button
            variant="contained"
            disabled={disableOnSave}
            onClick={() => {
              onSave(draftToHtml(convertToRaw(editorState.getCurrentContent())))
            }}
          >
            {t('common:save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RichTextDialog
