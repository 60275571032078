import React, { Fragment, useMemo } from 'react'

import { Box } from '@mui/material'

import { SubgenreMap } from '../../../account'
import { marketShareScopeTypes, useGetMarketShareData } from '../../../top-game/hooks/topGrossingGameHooks'
import { TopGame } from '../../../top-game/types/TopGame'
import LatestFTUEVideos from '../LatestFTUEVideos/LatestFTUEVideos'
import LatestGameUpdatesCard from '../LatestGameUpdatesCard/LatestGameUpdatesCard'
import LatestImplementationCard from '../LatestImplementationCard/LatestImplementationCard'
import LatestMarketShareCard from '../LatestMarketShareCard/LatestMarketShareCard'
import MustKnowGamesCard from '../MustKnowGamesCard/MustKnowGamesCard'
import TopFeaturesCard from '../TopFeaturesCard/TopFeaturesCard'

/**
 * GenreEssentialBody
 */
interface GenreEssentialBodyProps {
  topGames: TopGame[]
  selectedSubgenres: SubgenreMap
  conventionalGenreId?: string
}
const GenreEssentialBody: React.FC<GenreEssentialBodyProps> = ({ topGames, selectedSubgenres, conventionalGenreId }) => {
  const { marketShareData } = useGetMarketShareData(marketShareScopeTypes[0], topGames)

  const gamesFilteredByGenreId = useMemo(() => {
    if (!marketShareData) return []
    return marketShareData.topGamesWithGameQuarters.filter((topGame) => {
      if (!conventionalGenreId) {
        return true
      }
      return topGame.conventionalGenreId === conventionalGenreId
    })
  }, [conventionalGenreId, marketShareData])

  const cardSections = useMemo(
    () => [
      <MustKnowGamesCard
        topGames={marketShareData?.topGamesWithGameQuarters || []}
        selectedSubgenres={selectedSubgenres}
        conventionalGenreId={conventionalGenreId}
      />,
      <LatestImplementationCard topGames={gamesFilteredByGenreId} conventionalGenreId={conventionalGenreId} />,
      <LatestFTUEVideos topGames={gamesFilteredByGenreId} conventionalGenreId={conventionalGenreId} />,
      <LatestGameUpdatesCard games={gamesFilteredByGenreId} selectedSubgenres={selectedSubgenres} />,
      <LatestMarketShareCard topGames={topGames} selectedSubgenres={selectedSubgenres} conventionalGenreId={conventionalGenreId} />,
      <TopFeaturesCard selectedSubgenres={selectedSubgenres} />,
    ],
    [marketShareData?.topGamesWithGameQuarters, selectedSubgenres, conventionalGenreId, gamesFilteredByGenreId, topGames]
  )

  return (
    <>
      {cardSections.map((section, index) => (
        <Fragment key={index}>
          {section}
          {index < cardSections.length - 1 && <Box sx={{ my: 2 }} />}
        </Fragment>
      ))}
    </>
  )
}

export default GenreEssentialBody
