import { useMemo } from 'react'

import { Collection, CollectionVisibility, useGetVisibleCollectionsQuery, useGetScreenshotsByIdsQuery } from '../../../api/core'
import { useGetGameList } from '../../game/hooks/gameHooks'
import { FeatureGame } from '../../game/types/Game'
import { CollectionVisibilityAndNameSorter } from '../../implementation-examples/util/comparators'

export const useCollections = () => {
  return useGetVisibleCollectionsQuery()
}

export const useCollectionsGroupedByVisibility = (editableOnly?: boolean) => {
  const result = useGetVisibleCollectionsQuery()
  const sorted = [...((result.data || []) as Collection[])].sort(CollectionVisibilityAndNameSorter)
  const data = sorted.reduce((obj, collection) => {
    if (editableOnly && collection.visibility === 'PUBLIC') {
      return obj
    }
    if (!(`${collection.visibility}` in obj)) {
      obj[`${collection.visibility}`] = []
    }
    obj[`${collection.visibility}`].push(collection)
    return obj
  }, {} as { [visibility in CollectionVisibility]: Collection[] })
  return { ...result, data }
}

export const useCollection = (collectionId: string) => {
  const result = useGetVisibleCollectionsQuery(undefined, { skip: !collectionId })
  return {
    ...result,
    data: result.data?.find((collection) => collection.id === collectionId),
  }
}

export const useCollectionAndGames = (collectionId: string) => {
  const { data: collection, isFetching: isFetchingCollection } = useCollection(collectionId)
  const screenshotIds = useMemo(() => {
    return (collection as Collection)?.screenshots || []
  }, [collection])

  // Get all screenshots for collection
  const { data: screenshots, isFetching: isFetchingScreenshots } = useGetScreenshotsByIdsQuery(screenshotIds, { skip: !collection })
  const gameIds = useMemo(() => {
    return (screenshots || []).map(({ gameId }) => gameId || '').filter((gameId) => !!gameId && gameId !== '')
  }, [screenshots])

  // Get all games from which screenshots are taken
  const { data: gamesData, isFetching: isFetchingGames } = useGetGameList(
    { gameIds, include: 'name,artist,icons,conventionalSubgenre,conventionalSubgenreId,sdranks,sranks,appId' },
    { skip: !screenshots }
  )

  // Add screenshots to games
  const games: FeatureGame[] = useMemo(() => {
    return (gamesData || []).map((game) => {
      const featureGame = new FeatureGame(game)
      featureGame.featureScreenshots = (screenshots || [])
        .filter(({ gameId }) => gameId === game.id)
        .map((ss) => ({ ...ss, features: Object.keys(ss.features).map((featureLegacyId: string) => ({ featureLegacyId: parseInt(featureLegacyId, 10) })) }))
      return featureGame
    })
  }, [gamesData, screenshots])

  // Ready when all queries have completed
  const isFetching = useMemo(() => {
    return isFetchingCollection || isFetchingScreenshots || isFetchingGames
  }, [isFetchingScreenshots, isFetchingCollection, isFetchingGames])

  return { isFetching, data: { games, collection } }
}
