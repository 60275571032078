import { ComparisonInterval } from '../types/ComparisonInterval'
import { VersionAggregates } from '../types/UpdateImpact'

/**
 * Encapsulates calculation logic for update impact aggregate values
 */
export class UpdateImpactAggregates {
  _aggregates: VersionAggregates
  _interval: ComparisonInterval
  _intervalMap: MappedImpactInternalValues

  constructor(aggregates: VersionAggregates = defaultAggregate, interval: ComparisonInterval = ComparisonInterval.DAYS_7) {
    this._aggregates = aggregates || {}
    this._interval = interval
    this._intervalMap = ImpactIntervalToAggregateValueMapper[interval]
  }

  get downloadsImpact() {
    return this._aggregates[this._intervalMap.downloadsImpact]
  }

  get downloadsTrend() {
    return normalizeValues(getTrendValue(this._aggregates[this._intervalMap.downloads] / this._aggregates[this._intervalMap.downloadsBase]))
  }

  get revenueImpact() {
    return this._aggregates[this._intervalMap.revenueImpact]
  }

  get revenueTrend() {
    return normalizeValues(getTrendValue(this._aggregates[this._intervalMap.revenue] / this._aggregates[this._intervalMap.revenueBase]))
  }

  get revenueAndDownloadsRatio() {
    const ratio = normalizeValues(this._aggregates[this._intervalMap.revenue] / this._aggregates[this._intervalMap.downloads])
    const baseRatio = normalizeValues(this._aggregates[this._intervalMap.revenueBase] / this._aggregates[this._intervalMap.downloadsBase])
    return ratio - baseRatio
  }

  get revenueAndDownloadsRatioTrend() {
    const ratio = this._aggregates[this._intervalMap.revenue] / this._aggregates[this._intervalMap.downloads]
    const baseRatio = this._aggregates[this._intervalMap.revenueBase] / this._aggregates[this._intervalMap.downloadsBase]
    return ratio / baseRatio !== 0 ? normalizeValues(getTrendValue(ratio / baseRatio)) : 0
  }

  get revenueAndDownloadsRatioImpact() {
    const baseRatio = normalizeValues(getTrendValue(this._aggregates[this._intervalMap.revenueBase] / this._aggregates[this._intervalMap.downloadsBase]))
    return this.revenueAndDownloadsRatio - baseRatio
  }
}

const ImpactIntervalToAggregateValueMapper: { [interval in ComparisonInterval]: MappedImpactInternalValues } = {
  [ComparisonInterval.DAYS_7]: {
    downloadsImpact: 'week1DownloadsImpact',
    revenueImpact: 'week1RevenueImpact',
    downloadsBase: 'baseWeek1Downloads',
    revenueBase: 'baseWeek1Revenue',
    downloads: 'week1Downloads',
    revenue: 'week1Revenue',
  },
  [ComparisonInterval.DAYS_14]: {
    downloadsImpact: 'week2DownloadsImpact',
    revenueImpact: 'week2RevenueImpact',
    downloadsBase: 'baseWeek2Downloads',
    revenueBase: 'baseWeek2Revenue',
    downloads: 'week2Downloads',
    revenue: 'week2Revenue',
  },
  [ComparisonInterval.DAYS_30]: {
    downloadsImpact: 'month1DownloadsImpact',
    revenueImpact: 'month1RevenueImpact',
    downloadsBase: 'baseMonth1Downloads',
    revenueBase: 'baseMonth1Revenue',
    downloads: 'month1Downloads',
    revenue: 'month1Revenue',
  },
}

type MappedImpactInternalValues = {
  downloadsImpact: keyof VersionAggregates
  revenueImpact: keyof VersionAggregates
  downloadsBase: keyof VersionAggregates
  revenueBase: keyof VersionAggregates
  downloads: keyof VersionAggregates
  revenue: keyof VersionAggregates
}

const normalizeValues = (value: number) => {
  if (isNaN(value) || !isFinite(value)) {
    return 0
  } else {
    return value
  }
}

const getTrendValue = (value: number) => {
  return (1 - value) * -1
}

const defaultAggregate = {
  baseWeek1Revenue: 0,
  baseWeek1Downloads: 0,
  baseWeek2Revenue: 0,
  baseWeek2Downloads: 0,
  baseMonth1Revenue: 0,
  baseMonth1Downloads: 0,
  week1Revenue: 0,
  week1Downloads: 0,
  week2Revenue: 0,
  week2Downloads: 0,
  month1Revenue: 0,
  month1Downloads: 0,
  week1RevenueImpact: 0,
  week1DownloadsImpact: 0,
  week2RevenueImpact: 0,
  week2DownloadsImpact: 0,
  month1RevenueImpact: 0,
  month1DownloadsImpact: 0,
}
