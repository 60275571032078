import { startOfWeek, addDays } from 'date-fns'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@mui/material'

import { BarChart } from '../../../../../components/BarChart/BarChart'
import { useDateTimeFormatter } from '../../../hooks/useDateTimeFormatter'

type LiveEventsDailyEventStatisticsChartProps = {
  statistics: number[]
}
export const LiveEventsDailyEventStatisticsChart: FC<LiveEventsDailyEventStatisticsChartProps> = ({ statistics }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const chartLabelFormatter = useDateTimeFormatter({ weekday: 'short' })
  const firstDayOfWeek = startOfWeek(new Date())
  const shortWeekDaysArray = Array.from(Array(7)).map((e, i) => chartLabelFormatter.format(addDays(firstDayOfWeek, i)))

  const data = {
    labels: shortWeekDaysArray,
    datasets: [
      {
        label: t('live-events:event_statistics_dialog_popularity'),
        data: statistics.map((value) => value * 100),
        yAxisID: 'y1',
        backgroundColor: theme.palette.primary.main,
      },
    ],
  }

  return <BarChart data={data} scaleConfig={{ y1: { maxY: 100, precision: 1, title: t('live-events:event_statistics_dialog_chart_y_axis_title') } }} />
}
