import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CircularProgress, Card, CardContent, Grid, Button, TableHead, TableRow, TableCell, TableBody, Table } from '@mui/material'

import { useGetCurrentUserOrganizationQuery } from '../../api/account'
import OrganizationEditDialog from '../../features/account/components/OrganizationEditDialog/OrganizationEditDialog'
import { useRoleCheck } from '../../features/account/hooks/roleHooks'
import { RoleEnum } from '../../features/account/types/RoleEnum'
import { Organization } from '../../features/account/types/User'
import { useDateTimeFormatter } from '../../features/live-events/hooks/useDateTimeFormatter'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import PageService from '../../services/PageService'
import './OrganizationPage.scss'

const OrganizationPage: React.FC = () => {
  const { t } = useTranslation()

  usePage(PageService.getPageWithId('organization'), 'Visited Organization View')
  useDocumentTitle(t('organization:organization'))

  const dateFormatter = useDateTimeFormatter()
  const isOrganizationAdmin = useRoleCheck(RoleEnum.oadmin)
  const [organizationEditDialogOpen, setOrganizationEditDialogOpen] = useState(false)
  const { data: organization, isLoading: organizationLoading } = useGetCurrentUserOrganizationQuery()

  const openOrganizationEdit = useCallback(() => {
    setOrganizationEditDialogOpen(true)
  }, [])

  const handleOrganizationEditDialogClose = useCallback((savedOrganization?: Organization) => {
    setOrganizationEditDialogOpen(false)
  }, [])

  return (
    <div className="OrganizationPage">
      {!organization && organizationLoading ? (
        <div className="text-center top-margin bottom-margin">
          <CircularProgress color="primary" />
        </div>
      ) : null}

      {organization ? (
        <OrganizationEditDialog
          organization={organization}
          modalOpen={organizationEditDialogOpen}
          onClose={handleOrganizationEditDialogClose}
        ></OrganizationEditDialog>
      ) : null}

      {organization && !organizationLoading ? (
        <div>
          <Card className="Card">
            <CardContent>
              <Grid className="GridContainer" container spacing={2}>
                <Grid item xs>
                  <h2>{t('organization:your_org_info')}</h2>
                </Grid>
                <Grid item>
                  {isOrganizationAdmin ? (
                    <Button size="small" variant="contained" color="primary" onClick={openOrganizationEdit}>
                      {t('common:edit')}
                    </Button>
                  ) : null}
                </Grid>
              </Grid>

              <Grid className="GridContainer" container spacing={2}>
                <Grid item xs={3}>
                  {t('organization:company')}:
                </Grid>
                <Grid item>
                  <strong>{organization?.name}</strong>
                </Grid>
              </Grid>

              <Grid className="GridContainer" container spacing={2}>
                <Grid item xs={3}>
                  {t('organization:company_address')}:
                </Grid>
                <Grid item>
                  <strong>
                    <div>{organization.address1}</div>
                    <div>{organization.address2 ? organization.address2 : null}</div>
                    <div>{organization.zipCode && organization.city ? `${organization.zipCode} ${organization.city}` : null}</div>
                    <div>{organization.country ? organization.country : null}</div>
                  </strong>
                </Grid>
              </Grid>

              <Grid className="GridContainer" container spacing={2}>
                <Grid item xs={3}>
                  {t('organization:company_contact')}:
                </Grid>
                <Grid item>
                  <strong>
                    <div>{organization.contactName}</div>
                    <div>{organization.contactEmail ? organization.contactEmail : null}</div>
                    <div>{organization.contactPhone ? organization.contactPhone : null}</div>
                  </strong>
                </Grid>
              </Grid>

              {organization.billingAddress1 ? (
                <Grid className="GridContainer" container spacing={2}>
                  <Grid item xs={3}>
                    {t('organization:billing_address')}:
                  </Grid>
                  <Grid item>
                    <strong>
                      <div>{organization.billingAddress1}</div>
                      <div>{organization.billingAddress2 ? organization.billingAddress2 : null}</div>
                      <div>{organization.billingZipCode && organization.billingCity ? `${organization.billingZipCode} ${organization.billingCity}` : null}</div>
                      <div>{organization.billingCountry ? organization.billingCountry : null}</div>
                    </strong>
                  </Grid>
                </Grid>
              ) : null}

              {organization.billingContact && organization.billingContact?.name ? (
                <Grid className="GridContainer" container spacing={2}>
                  <Grid item xs={3}>
                    {t('organization:billing_contact')}:
                  </Grid>
                  <Grid item>
                    <strong>
                      <div>{organization.billingContact?.name}</div>
                      <div>{organization.billingContact?.email ? organization.billingContact?.email : null}</div>
                      <div>{organization.billingContact?.phone ? organization.billingContact?.phone : null}</div>
                    </strong>
                  </Grid>
                </Grid>
              ) : null}
            </CardContent>
          </Card>

          <Card className="CardContainer">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>{t('organization:plans')}</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{t('organization:purchased')}</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{t('organization:valid_until')}</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{t('organization:monthly_price')}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organization.packages.map((planPackage) => {
                  return (
                    <TableRow key={planPackage.packageId}>
                      <TableCell>{planPackage.name}</TableCell>
                      <TableCell>{dateFormatter.format(planPackage.purchasedAt)}</TableCell>
                      <TableCell>{planPackage.expiration ? dateFormatter.format(planPackage.expiration) : '∞'}</TableCell>
                      <TableCell>{planPackage.price ? `${planPackage.price} €` : '-'}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Card>
        </div>
      ) : null}
    </div>
  )
}

export default OrganizationPage
