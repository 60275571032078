import querystring, { ParsedQuery } from 'query-string'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useNavigate, useLocation, matchRoutes } from 'react-router-dom'

import { AgnosticRouteMatch } from '@remix-run/router'

import { TabOption } from '../../feature/types/feature-modal-tabs'

// TODO move useInitialState into independent hooks
export const collectionsRootPath = '/implementation-examples/collections'
export const examplesRootPath = '/implementation-examples/examples'
export const gameRootPath = '/game'
export const marketTrendsRootPath = '/market-trends'
export const genreEssentialsRootPath = '/genre-essentials'
export const ftueVideosRootPath = '/ftue-videos'
export const updateImpactsRootPath = '/game-update-impacts'
export const marketExplorerRootPath = '/market-explorer'
export const marketShareRootPath = '/market-share'
export const dataGlossaryRootPath = '/data-glossary'
export const compareGamesRootPath = '/compare-games'
export const gameAnalyzerRootPath = '/game-analyzer'
export const dailyInsightsRootPath = '/daily-insights'
export const liveEventsTrackerRootPath = '/live-events-tracker'

/**
 * Here you need to list all the routes leading to an implementation examples screenshot modal. Make sure the last route in each array is the
 * base route for the view as it is used by getBasePath function to resolve the base path to return to once the modal is closed.
 *
 * TODO: we should try to figure out a way away from these mappings. e.g. use a more generic modal for the screenshots.
 *
 * NOTICE 1. the order of the routes is important, base paths are resolved based on the order of the routes.
 * NOTICE 2. do not use / at the end of the path
 */
const routeMap = {
  collectionsRoutes: [collectionsRootPath + '/:category/:collectionId/:gameId/:version/featureid/:featureId', collectionsRootPath + '/:category/:collectionId'],
  compareGamesFeaturesRoutes: [compareGamesRootPath + '/features/:gameId/:version/featureid/:featureId', compareGamesRootPath + '/features'],
  compareGamesMotivationsRoutes: [compareGamesRootPath + '/motivation/:gameId/:version/featureid/:featureId', compareGamesRootPath + '/motivation'],
  compareGamesPerformanceRoutes: [compareGamesRootPath + '/performance/:gameId/:version/featureid/:featureId', compareGamesRootPath + '/performance'],
  compareGamesPerformanceGameUpdateRoutes: [
    compareGamesRootPath + '/performance/gameupdate/:gameupdateId/:gameupdateVersion/:market/:version/:gameId/:featureId',
    compareGamesRootPath + '/performance/gameupdate/:gameupdateId/:gameupdateVersion/:market',
  ],
  dailyInsightsRoutes: [dailyInsightsRootPath + '/:gameId/:version/featureid/:featureId', dailyInsightsRootPath],
  dataGlossaryFeaturesRoutes: [dataGlossaryRootPath + '/features/:gameId/:version/featureid/:featureId', dataGlossaryRootPath + '/features'],
  dataGlossaryMotivationsRoutes: [dataGlossaryRootPath + '/motivation/:gameId/:version/featureid/:featureId', dataGlossaryRootPath + '/motivation'],
  plainExamplesRoutes: [examplesRootPath + '/:gameId/:version/featureid/:featureId', examplesRootPath],
  examplesFeaturesRoutes: [
    examplesRootPath + '/features/:featureIds/featuretags/:featureTags/:gameId/:version/featuretagid/:featureTagId',
    examplesRootPath + '/features/:featureIds/featuretags/:featureTags/:gameId/:version/featureid/:featureId',
    examplesRootPath + '/features/:featureIds/:gameId/:version/featureid/:featureId',
    examplesRootPath + '/features/:featureIds/:gameId/featureid/:featureId',
    examplesRootPath + '/features/:featureIds/:gameId/',
    examplesRootPath + '/featuretags/:featureTags/:gameId/:version/featuretagid/:featureTagId',
    examplesRootPath + '/features/:featureIds/featuretags/:featureTags', // base for both selected -3
    examplesRootPath + '/featuretags/:featureTags', // base for only feature tags -2
    examplesRootPath + '/features/:featureIds', // base for only features -1
  ],
  examplesGamesRoutes: [
    examplesRootPath + '/games/:gameIds/:gameId/:version/featureid/:featureId',
    examplesRootPath + '/games/:gameIds/featureid/:featureId',
    examplesRootPath + '/games/:gameIds/:gameId/:version/featureid/:featureId',
    examplesRootPath + '/games/:gameIds',
  ],
  gameAnalyzerRoutes: [gameAnalyzerRootPath + '/:analyzedGameId/:gameId/:version/featureid/:featureId', gameAnalyzerRootPath + '/:analyzedGameId'],
  gameFeaturesRoutes: [gameRootPath + '/:gameIds/features/:gameId/:version/featureid/:featureId', gameRootPath + '/:gameIds/features'],
  gameOverviewRoutes: [gameRootPath + '/:gameIds/overview/:gameId/:version/featureid/:featureId', gameRootPath + '/:gameIds/overview'],
  gameFeatureIdeasRoutes: [gameRootPath + '/:gameIds/feature-ideas/:gameId/:version/featureid/:featureId', gameRootPath + '/:gameIds/feature-ideas'],
  gameImplementationsRoutes: [gameRootPath + '/:gameIds/implementations/:gameId/:version/featureid/:featureId', gameRootPath + '/:gameIds/implementations'],
  gameMotivationsRoutes: [gameRootPath + '/:gameIds/motivations/:gameId/:version/featureid/:featureId', gameRootPath + '/:gameIds/motivations'],
  gameUpdateHistoryRoutes: [gameRootPath + '/:gameIds/updatehistory/:gameId/:version/featureid/:featureId', gameRootPath + '/:gameIds/updatehistory'],
  gameUpdateHistoryGameUpdateRoutes: [
    gameRootPath + '/:gameIds/updatehistory/gameupdate/:gameupdateId/:gameupdateVersion/:market/:gameId/:version/featureid/:featureId',
    gameRootPath + '/:gameIds/updatehistory/gameupdate/:gameupdateId/:gameupdateVersion/:market',
  ],
  gameUpdateImpactsRoutes: [updateImpactsRootPath + '/:gameId/:version/featureid/:featureId', updateImpactsRootPath],
  gameUpdateImpactsGameUpdateRoutes: [
    updateImpactsRootPath + '/gameupdate/:gameupdateId/:gameupdateVersion/:market/:gameId/:version/featureid/:featureId',
    updateImpactsRootPath + '/gameupdate/:gameupdateId/:gameupdateVersion/:market',
  ],
  genreEssentialsRoutes: [genreEssentialsRootPath + '/:gameId/:version/featureid/:featureId', genreEssentialsRootPath],
  genreEssentialsGameUpdateRoutes: [
    genreEssentialsRootPath + '/gameupdate/:gameupdateId/:gameupdateVersion/:market/:gameId/:version/featureid/:featureId',
    genreEssentialsRootPath + '/gameupdate/:gameupdateId/:gameupdateVersion/:market',
  ],
  liveEventsTrackerGamesRoutes: [liveEventsTrackerRootPath + '/games/:gameId/:version/featureid/:featureId', liveEventsTrackerRootPath + '/games'],
  liveEventsTrackerGamesGameUpdateRoutes: [
    liveEventsTrackerRootPath + '/games/gameupdate/:gameupdateId/:gameupdateVersion/:market/:gameId/:version/featureid/:featureId',
    liveEventsTrackerRootPath + '/games/gameupdate/:gameupdateId/:gameupdateVersion/:market',
  ],
  marketExplorerGamesOverviewRoutes: [
    marketExplorerRootPath + '/games-overview/:gameId/:version/featureid/:featureId',
    marketExplorerRootPath + '/games-overview',
  ],
  marketExplorerGamesDataRoutes: [marketExplorerRootPath + '/games-data/:gameId/:version/featureid/:featureId', marketExplorerRootPath + '/games-data'],
  marketExplorerFeaturesRoutes: [marketExplorerRootPath + '/features-data/:gameId/:version/featureid/:featureId', marketExplorerRootPath + '/features-data'],
  marketExplorerMotivationsRoutes: [marketExplorerRootPath + '/motivations/:gameId/:version/featureid/:featureId', marketExplorerRootPath + '/motivations'],
  marketTrendsRoutes: [marketTrendsRootPath + '/:gameId/:version/featureid/:featureId', marketTrendsRootPath],
}

const routePathMap = Object.entries(routeMap).reduce((acc, [key, value]) => {
  acc[key] = value.map((path) => ({ path }))

  return acc
}, {} as { [key: string]: { path: string }[] })

const routePathMapValues = Object.values(routePathMap).flat()
const examplesRoutes = [...routePathMap.examplesGamesRoutes, ...routePathMap.examplesFeaturesRoutes]

export const allScreenshotRoutes = routePathMapValues

export type FeaturesGamesMatchParams = {
  featureId?: string
  featureIds?: string
  gameId?: string
  gameIds?: string
  version?: string
}

type FeaturesOrGamesMode = 'features' | 'games'

export type FeatureGameState =
  | {
      basePath: string
      featureId?: number
      featureIds?: { [key: number]: number[] }
      conceptTagId?: string
      conceptTagIds?: string[]
      gameId?: string
      gameIds?: string[]
      mode: FeaturesOrGamesMode
      version?: string
    }
  | undefined

export type CollectionMatchParams = {
  collectionId: string
  gameId?: string
  featureId?: string
}

export type FeatureModalState = {
  featureModalChoice?: number
  featureModalFeature?: number
  games?: string[]
  featureModalTab?: TabOption
}

export type CollectionState = CollectionMatchParams

// NOTE: remember to add the logic to resolve the base path for a view that needs to show feature modal implementation screenshots here.
const getBasePath = (path: string): string => {
  if (path.startsWith(collectionsRootPath)) {
    return routeMap.collectionsRoutes[routeMap.collectionsRoutes.length - 1]
  }

  if (path.startsWith(gameRootPath)) {
    if (path.includes('implementations')) {
      return routeMap.gameImplementationsRoutes[routeMap.gameImplementationsRoutes.length - 1]
    } else if (path.includes('features')) {
      return routeMap.gameFeaturesRoutes[routeMap.gameFeaturesRoutes.length - 1]
    } else if (path.includes('feature-ideas')) {
      return routeMap.gameFeatureIdeasRoutes[routeMap.gameFeatureIdeasRoutes.length - 1]
    } else if (path.includes('motivations')) {
      return routeMap.gameMotivationsRoutes[routeMap.gameMotivationsRoutes.length - 1]
    } else if (path.includes('updatehistory')) {
      if (path.includes('gameupdate')) {
        return routeMap.gameUpdateHistoryGameUpdateRoutes[routeMap.gameUpdateHistoryGameUpdateRoutes.length - 1]
      } else {
        return routeMap.gameUpdateHistoryRoutes[routeMap.gameUpdateHistoryRoutes.length - 1]
      }
    } else if (path.includes('overview')) {
      return routeMap.gameOverviewRoutes[routeMap.gameOverviewRoutes.length - 1]
    }
  }

  if (path.startsWith(marketTrendsRootPath)) {
    return routeMap.marketTrendsRoutes[routeMap.marketTrendsRoutes.length - 1]
  }

  if (path.startsWith(compareGamesRootPath)) {
    if (path.includes('features')) {
      return routeMap.compareGamesFeaturesRoutes[routeMap.compareGamesFeaturesRoutes.length - 1]
    } else if (path.includes('performance')) {
      return routeMap.compareGamesPerformanceRoutes[routeMap.compareGamesPerformanceRoutes.length - 1]
    } else if (path.includes('motivation')) {
      return routeMap.compareGamesMotivationsRoutes[routeMap.compareGamesMotivationsRoutes.length - 1]
    }
  }

  if (path.startsWith(dataGlossaryRootPath)) {
    if (path.includes('features')) {
      return routeMap.dataGlossaryFeaturesRoutes[routeMap.compareGamesFeaturesRoutes.length - 1]
    } else if (path.includes('motivation')) {
      return routeMap.dataGlossaryMotivationsRoutes[routeMap.dataGlossaryMotivationsRoutes.length - 1]
    }
  }

  if (path.startsWith(gameAnalyzerRootPath)) {
    return routeMap.gameAnalyzerRoutes[routeMap.gameAnalyzerRoutes.length - 1]
  }

  if (path.startsWith(updateImpactsRootPath)) {
    if (path.includes('gameupdate')) {
      return routeMap.gameUpdateImpactsGameUpdateRoutes[routeMap.gameUpdateImpactsGameUpdateRoutes.length - 1]
    } else {
      return routeMap.gameUpdateImpactsRoutes[routeMap.gameUpdateImpactsRoutes.length - 1]
    }
  }

  if (path.startsWith(marketExplorerRootPath)) {
    if (path.includes('/games-overview')) {
      return routeMap.marketExplorerGamesOverviewRoutes[routeMap.marketExplorerGamesOverviewRoutes.length - 1]
    } else if (path.includes('/games-data')) {
      return routeMap.marketExplorerGamesDataRoutes[routeMap.marketExplorerGamesDataRoutes.length - 1]
    } else if (path.includes('/features-data')) {
      return routeMap.marketExplorerFeaturesRoutes[routeMap.marketExplorerFeaturesRoutes.length - 1]
    } else if (path.includes('/motivations')) {
      return routeMap.marketExplorerMotivationsRoutes[routeMap.marketExplorerMotivationsRoutes.length - 1]
    }
  }

  if (path.startsWith(dailyInsightsRootPath)) {
    return routeMap.dailyInsightsRoutes[routeMap.dailyInsightsRoutes.length - 1]
  }

  if (path.startsWith(liveEventsTrackerRootPath)) {
    if (path.includes('gameupdate')) {
      return routeMap.liveEventsTrackerGamesGameUpdateRoutes[routeMap.liveEventsTrackerGamesGameUpdateRoutes.length - 1]
    } else {
      return routeMap.liveEventsTrackerGamesRoutes[routeMap.liveEventsTrackerGamesRoutes.length - 1]
    }
  }

  if (path.startsWith(genreEssentialsRootPath)) {
    if (path.includes('gameupdate')) {
      return routeMap.genreEssentialsGameUpdateRoutes[routeMap.genreEssentialsGameUpdateRoutes.length - 1]
    } else {
      return routeMap.genreEssentialsRoutes[routeMap.genreEssentialsRoutes.length - 1]
    }
  }

  if (path.startsWith(examplesRootPath)) {
    if (path.includes('/games')) {
      return routeMap.examplesGamesRoutes[routeMap.examplesGamesRoutes.length - 1]
    }
    // both features and feature tags
    else if (path.includes('/featuretags') && path.includes('/features')) {
      return routeMap.examplesFeaturesRoutes[routeMap.examplesFeaturesRoutes.length - 3]
    }
    // only feature tags
    else if (path.includes('/featuretags')) {
      return routeMap.examplesFeaturesRoutes[routeMap.examplesFeaturesRoutes.length - 2]
    }
    // only features
    else if (path.includes('/features')) {
      return routeMap.examplesFeaturesRoutes[routeMap.examplesFeaturesRoutes.length - 1]
    } else {
      return routeMap.plainExamplesRoutes[routeMap.plainExamplesRoutes.length - 1]
    }
  }

  return path.startsWith(examplesRootPath + '/games')
    ? routeMap.examplesGamesRoutes[routeMap.examplesGamesRoutes.length - 1]
    : routeMap.examplesFeaturesRoutes[routeMap.examplesFeaturesRoutes.length - 1]
}

// FeaturesGamesMatchParams
const parseMatch = (match: AgnosticRouteMatch<string, { path: string }> | null): FeatureGameState => {
  if (match === null) {
    return undefined
  }
  const { params, pathname } = match
  const featureIdArr: string[] | undefined = params.featureIds?.split(',')
  const featureId: number | undefined = params.featureId ? parseInt(params.featureId, 10) : undefined
  const featureIds = featureIdArr?.reduce((acc, featureChoices) => {
    const featureChoicesArr = featureChoices.split('-')
    if (featureChoicesArr.length > 0) {
      const [featureLegacyId, ...choiceLegacyIds] = featureChoicesArr
      acc[+featureLegacyId] = choiceLegacyIds?.map((id) => +id)
    }

    return acc
  }, {} as { [key: number]: number[] })
  const conceptTagId: string | undefined = params.featureTagId
  const conceptTagIds: string[] | undefined = params.featureTags?.split(',')
  const gameId: string | undefined = params.gameId
  const gameIds: string[] | undefined = params.gameIds?.split(',')
  const mode: FeaturesOrGamesMode = pathname.startsWith(examplesRootPath + '/games') ? 'games' : 'features'
  const version = params.version

  return { featureId, featureIds, conceptTagId, conceptTagIds, gameId, gameIds, mode, basePath: getBasePath(pathname), version }
}

export const useInitialGameFeatureState = (): [FeatureGameState, Dispatch<SetStateAction<FeatureGameState>>] => {
  const location = useLocation()
  const matchedRoutes = matchRoutes(allScreenshotRoutes, location)
  const parsedMatch = parseMatch(matchedRoutes ? matchedRoutes[0] : null)
  return useState<FeatureGameState>(parsedMatch)
}

export const useGameFeatureMemo = () => {
  const location = useLocation()
  const matchedRoutes = matchRoutes(examplesRoutes, location)
  return useMemo(() => parseMatch(matchedRoutes ? matchedRoutes[0] : null), [matchedRoutes])
}

export const useInitialCollectionState = (): [CollectionState, Dispatch<SetStateAction<CollectionState>>] => {
  const location = useLocation()
  const collectionMatch = matchRoutes(routePathMap.collectionsRoutes, location)
  return useState<CollectionState>({
    collectionId: collectionMatch?.[0]?.params?.collectionId || '',
    gameId: collectionMatch?.[0]?.params?.gameId,
    featureId: collectionMatch?.[0]?.params?.featureId,
  })
}

export const useInitialFeatureModalState = (): [
  (
    | {
        featureModalChoice: number | undefined
        featureModalTab: TabOption
        featureModalFeature: number | undefined
        games: (string | null)[] | undefined
      }
    | undefined
  ),
  (state?: FeatureModalState | undefined) => void
] => {
  const navigate = useNavigate()
  const location = useLocation()
  const { choice, feature, games, tab }: ParsedQuery = querystring.parse(location.search)
  const initialState = useMemo(
    () =>
      tab
        ? {
            featureModalChoice: choice ? parseInt(choice as string, 10) : undefined,
            featureModalTab: tab as TabOption,
            featureModalFeature: feature ? parseInt(feature as string, 10) : undefined,
            games: games ? (typeof games === 'string' ? (games as string).split(',') : games) : undefined,
          }
        : undefined,
    [choice, feature, games, tab]
  )

  return useMemo(
    () => [
      initialState,
      (state?: FeatureModalState) => {
        const query = { ...querystring.parse(location.search), ...state }
        if (!state) {
          delete query.featureModalChoice
          delete query.featureModalFeature
          delete query.featureModalTab
        }
        const pathname = location.pathname.indexOf(marketTrendsRootPath) >= 0 ? marketTrendsRootPath : undefined
        navigate({ pathname, search: querystring.stringify(query) }, { replace: true })
      },
    ],
    [initialState, location.pathname, location.search, navigate]
  )
}
