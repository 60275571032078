/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'

import { Dialog, DialogContent } from '@mui/material'

import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { GamesTableWith30DRevDlEstimates } from '../../../../components/GamesTableWith30DRevDlEstimates/GamesTableWith30DRevDlEstimates'
import { Game } from '../../../game'
import useGetPublisherGames from '../../hooks/useGetPublisherGames'
import './PublisherGamesDialog.scss'

interface PublisherGamesDialogProps {
  modalOpen: boolean
  game: Game
  marketIso: string
  onClose: () => void
}

const PublisherGamesDialog: React.FC<PublisherGamesDialogProps> = ({ modalOpen, game, marketIso, onClose }) => {
  const { data: publisherGames, isFetching: isFetchingPublisherGames } = useGetPublisherGames(game, marketIso, !modalOpen)

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <Dialog className="PublisherGamesDialog" onClose={handleClose} fullWidth={true} maxWidth="lg" open={modalOpen}>
      <GRDialogTitle onClose={handleClose}>{game.artist}</GRDialogTitle>
      <DialogContent className="DialogContent" dividers>
        <GamesTableWith30DRevDlEstimates games={publisherGames} marketIso={marketIso} isLoading={isFetchingPublisherGames} />
      </DialogContent>
    </Dialog>
  )
}

export default PublisherGamesDialog
