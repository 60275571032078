import { Collection } from '../../../api/core'

export type CollectionCategory = 'MY' | 'SHARED' | 'PUBLIC'

export type CategorizedCollections = { [category in CollectionCategory]: Collection[] }

/**
 * Group by a criteria: My, Shared or Public
 */
export const categorizeCollections = (collections: Collection[], myEmail: string): CategorizedCollections =>
  collections.reduce(
    (categorized, collection) => {
      let category: CollectionCategory = 'SHARED'
      if (collection.visibility === 'PUBLIC') {
        category = 'PUBLIC'
      } else if (collection.createdBy === myEmail) {
        category = 'MY'
      }
      categorized[category].push(collection)
      return categorized
    },
    { MY: [], SHARED: [], PUBLIC: [] } as CategorizedCollections
  )
