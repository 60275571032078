import { ChartData } from 'chart.js'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, Box, Button, Card, CardContent, Grid, Typography } from '@mui/material'

import { SimpleFeature, useGetScreenshotCountsQuery } from '../../../../api/core'
import { GREffectDots } from '../../../../components/GREffectDots/GREffectDots'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { SingleSubgenreSelection } from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { TimelineChart } from '../../../../components/TimelineChart/TimelineChart'
import languageService from '../../../../services/LanguageService'
import { tabIcons } from '../../../feature'
import { FeatureLink } from '../../../feature/components/FeatureLink'
import type { ModalFeature } from '../../../feature/components/FeatureModal/FeatureModal'
import { tabOptions } from '../../../feature/types/feature-modal-tabs'
import { ChartDataFormat } from '../../../update-history/types/types'
import './MarketTrendGraph.scss'

type TopAndOut = { top: number; out: number }

export type MarketTrendGraphData = {
  avgEffect?: number
  change?: TopAndOut
  chart: ChartData<'line'>
  choice?: string
  feature?: SimpleFeature
  gamesInSet: number
  name?: string
  popularity?: TopAndOut
  minmaxFeature?: MarketTrendEffectProps
}

interface MarketTrendGraphProps {
  data: MarketTrendGraphData
  scaleYAxis: boolean
  topAmount: number
  showDetailEffect?: boolean
  selectedSubgenre?: SingleSubgenreSelection
}

interface PercentageProps {
  skipPlus?: boolean
  value: number
}

export interface MarketTrendEffectProps {
  maxEffect: number
  minEffect: number
}

const QuestionTooltip: React.FC<{ explanation: string }> = ({ explanation }) => (
  <GRTooltip content={explanation}>
    <Typography color="primary" display="inline" component="span">
      {' ?'}
    </Typography>
  </GRTooltip>
)

const Difference: React.FC<PercentageProps> = ({ value }) => (
  <Typography display="inline" className={`percentage difference ${value > 0 ? 'up' : 'down'}`}>
    {Math.round(value) > 0 ? '+' : ''}
    {Math.round(value)}
  </Typography>
)

const Percentage: React.FC<PercentageProps> = ({ skipPlus, value }) => (
  <Typography display="inline" className="percentage" color="primary">
    {!skipPlus && Math.round(value) > 0 ? '+' : ''}
    {Math.round(value)}%
  </Typography>
)

/**
 * The graph card including average effect and popularity data
 *
 * @param data including the line graph values
 * @param onOpenDetails when and which modal to open
 * @param scaleYAxis or keep from 0-100
 * @param topAmount which is shown
 * @param selectedSubgenre to filter by
 */
export const MarketTrendGraph: React.FC<MarketTrendGraphProps> = ({ data, scaleYAxis, topAmount, showDetailEffect, selectedSubgenre }) => {
  const { t } = useTranslation()
  const { data: featureScreenshotCounts = {} } = useGetScreenshotCountsQuery({ featureLegacyId: data.feature?.featureLegacyId })

  const minMax = useMemo(() => {
    if (scaleYAxis === true) {
      let allData: Array<number> = []

      // Merge all datasets to one array
      if (data.chart.datasets) {
        data.chart.datasets.forEach((dataset) => {
          allData = allData.concat(dataset.data as Array<number>)
        })
      }

      let min = Math.min.apply(Math, allData)

      // Add little room to bottom
      if (min >= 10) {
        min -= 10
      }

      // Round to nearest 10
      min = Math.floor((min + 1) / 10) * 10

      // make sure min is not negative
      if (min <= 10) {
        min = 0
      }

      let max = Math.max.apply(Math, allData)

      // Add little room to top
      if (max <= 90) {
        max += 10
      } else {
        max = 100
      }

      // Round to nearest 10
      max = Math.ceil((max + 1) / 10) * 10

      // make sure max is not over 100
      if (max >= 100) {
        max = 100
      }

      return {
        min,
        max,
      }
    } else {
      return {
        min: 0,
        max: 100,
      }
    }
  }, [scaleYAxis, data.chart.datasets])

  if (data.avgEffect === undefined) return null

  return (
    <Card className="MarketTrendGraph" sx={{ mb: 2 }}>
      {data.name && data.choice && (
        <CardContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h3">
                {data.name}: {data.feature && data.feature.choiceLegacyId && languageService.getTranslation('choices', data.feature.choiceLegacyId.toString())}
              </Typography>
            </Grid>
            <Grid item className="MarketTrendActions">
              {data.feature &&
                tabOptions.map((option) => {
                  const disabled = option === 'implementation' && !Object.keys(featureScreenshotCounts).length
                  return (
                    <Button
                      disabled={disabled}
                      className={disabled ? 'MarketTrendActions__button MarketTrendActions__disabled' : 'MarketTrendActions__button'}
                      key={option}
                    >
                      <FeatureLink
                        key={option}
                        feature={data.feature as ModalFeature}
                        initialTab={option}
                        choice={{ name: data.choice || '', choiceLegacyId: data.feature?.choiceLegacyId }}
                        selectedSubgenre={selectedSubgenre}
                      >
                        <GRTooltip content={t(`common:open_feature_${option}`)}>{tabIcons[option]}</GRTooltip>
                      </FeatureLink>
                    </Button>
                  )
                })}
            </Grid>
          </Grid>
        </CardContent>
      )}

      <CardContent>
        <Grid container wrap="wrap">
          <Grid sm={12} md={8} lg={9} item mr={2} sx={{ height: '450px' }}>
            <TimelineChart
              chartLineOptions={{ responsive: true, maintainAspectRatio: false, animation: false }}
              data={data.chart}
              scaleConfig={{
                y1: { dataFormat: ChartDataFormat.Percentage, precision: 1, title: t('common:popularity'), maxY: minMax.max, minY: minMax.min },
              }}
            />
          </Grid>
          <Grid item xs className="TrendChanges">
            {data.gamesInSet < 10 && (
              <Alert severity="warning" sx={{ mb: 2, ml: 2 }}>
                {t('trending-features:number_of_games_is_low')}
              </Alert>
            )}
            {!!data.change && (
              <Box textAlign="center">
                <Typography variant="h3">
                  {t('trending-features:change_in_popularity')}
                  <QuestionTooltip explanation={t('trending-features:tooltip_popularity')} />
                </Typography>
                <Box className="ChangeValue">
                  <Typography display="inline" component="label">
                    {t('trending-features:top_percent_games', { percent: topAmount * 100 })}:
                  </Typography>
                  <Percentage value={data.change.top} />
                </Box>
                <Box className="ChangeValue">
                  <Typography display="inline" component="label">
                    {t('trending-features:other_games')}:
                  </Typography>
                  <Percentage value={data.change.out} />
                </Box>
              </Box>
            )}
            {data.avgEffect !== undefined ? (
              showDetailEffect && !!data.minmaxFeature ? (
                <>
                  <Typography variant="h3">
                    {t('common:average_effect')}
                    <QuestionTooltip explanation={t('common:average_effect_feature_description')} />
                  </Typography>
                  <Typography align="center">
                    <GREffectDots size="small" effect={data.avgEffect} detailEffect={data.minmaxFeature} showDetailEffect />
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h3">
                    {t('common:average_effect')}
                    <QuestionTooltip explanation={t('common:average_effect_feature_description')} />
                  </Typography>
                  <Typography align="center">
                    <GREffectDots size="small" effect={data.avgEffect} />
                  </Typography>
                </>
              )
            ) : null}
            {!!data.popularity && (
              <Box className="ChangeValue TopPopularity">
                <Typography variant="h3">
                  {t('common:top_20_popularity_percent')}
                  <QuestionTooltip explanation={t('trending-features:tooltip_popularity')} />
                </Typography>
                <Percentage skipPlus={true} value={data.popularity.top} />
                <Typography variant="h3">
                  {t('common:top_20_popularity_out_percent_short')}
                  <QuestionTooltip
                    explanation={t('trending-features:tooltip_change_in_popularity', { tempTimeline: '', tempOrder: 'Top ' + topAmount + '%' })}
                  />
                </Typography>
                <Percentage skipPlus={true} value={data.popularity.out} />
                <Typography variant="h3">
                  {t('common:differentiating_factor')}
                  <QuestionTooltip explanation={t('common:popularity_difference_top20_tooltip')} />
                </Typography>
                <Difference value={data.popularity.top - data.popularity.out} />
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
