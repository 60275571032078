import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SelectChangeEvent, TableContainer } from '@mui/material'

import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { TrendIndicator, TrendIndicatorType } from '../../../../components/TrendIndicator/TrendIndicator'
import utilsService from '../../../../services/UtilsService'
import { useMarketShareAceessCheck } from '../../../account/hooks/roleHooks'
import QuarterSelector from '../../../quarter/components/QuarterSelector'
import quarterService, { QuarterDataKey } from '../../../quarter/services/QuarterService'
import { Quarter } from '../../../quarter/types/Quarter'
import { MarketShare } from '../../types/MarketShare'
import { MarketShareNumberType, MarketShareNumberTypeId } from '../../types/MarketShareNumberType'
import { MarketShareScopeType } from '../../types/MarketShareScopeType'

interface Props {
  calculatedQuarters: Quarter[]
  marketShares: MarketShare[]
  marketShareScopeType: MarketShareScopeType
  marketShareNumberType: MarketShareNumberType
  selectedQuarters: Quarter[]
  onQuartersChangeCallback: (quarterIdentifier1: string, quarterIdentifier2: string) => void
  onMarketShareLinkClickCallback: (marketShareName: string) => void
}

const MarketShareQuarterComparison: React.FC<Props> = ({
  calculatedQuarters,
  marketShares,
  marketShareScopeType,
  marketShareNumberType,
  selectedQuarters,
  onQuartersChangeCallback,
  onMarketShareLinkClickCallback,
}) => {
  const { t } = useTranslation()
  const [showScopeTypeShare, setShowScopeTypeShare] = useState(false)
  const hasAccessToMarketShare = useMarketShareAceessCheck()

  const handleQuarterChange = useCallback(
    (event: SelectChangeEvent, index: 0 | 1) => {
      const value = event.target.value as string
      let quarterIdentifierArray: string[] = quarterService.mirrorSelectedQuartersIfNeeded(value, index, selectedQuarters)
      onQuartersChangeCallback(quarterIdentifierArray[0], quarterIdentifierArray[1])
    },
    [selectedQuarters, onQuartersChangeCallback]
  )

  const containerRef = useRef(null)

  const customProps = useMemo(() => {
    return {
      marketShareScopeType,
      marketShareNumberType,
      selectedQuarters,
      showScopeTypeShare,
      setShowScopeTypeShare,
      handleQuarterChange,
      onMarketShareLinkClickCallback,
    }
  }, [
    marketShareScopeType,
    marketShareNumberType,
    selectedQuarters,
    showScopeTypeShare,
    setShowScopeTypeShare,
    handleQuarterChange,
    onMarketShareLinkClickCallback,
  ])

  const tableColumns: GRTableColumn<MarketShare, typeof customProps>[] = [
    {
      columns: [
        {
          labelAccessor: ({ customTableProps }) => (
            <>{customTableProps && customTableProps.marketShareScopeType ? t(customTableProps.marketShareScopeType.name) : ''}</>
          ),
          accessor: ({ row: marketShare, rowIndex, customTableProps }) => (
            <Link
              className={hasAccessToMarketShare ? 'MarketSharePage__MarketShareLink' : 'MarketSharePage__Disabled'}
              to={`/market-share/top-500-games`}
              onClick={(e) => (hasAccessToMarketShare ? customTableProps?.onMarketShareLinkClickCallback(marketShare.name) : e.preventDefault())}
            >
              <strong>
                {rowIndex + 1}. {marketShare.name}
              </strong>
            </Link>
          ),
          sortable: true,
          sortAccessor: 'name',
          cellProps: { align: 'left' },
          headerCellProps: { align: 'left' },
          defaultSortOrder: SortOrder.ASC,
        },
      ],
    },
    // Q1
    {
      labelAccessor: ({ customTableProps }) => (
        <>
          {customTableProps && (
            <QuarterSelector
              selectedQuarter={customTableProps.selectedQuarters[0]}
              quarters={calculatedQuarters}
              onQuarterChangeCallback={(e) => customTableProps.handleQuarterChange(e, 0)}
            />
          )}
        </>
      ),
      columns: [
        {
          labelAccessor: () => <GRTooltip content={t('market-share:revenue_tooltip')}>{t('common:revenue_shorthand')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <strong>
              {customTableProps && (
                <>
                  {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage &&
                    utilsService.formatPercent(marketShare.quarters[customTableProps.selectedQuarters[0].quarterIdentifier].revenuePercentage, {
                      maximumFractionDigits: 2,
                    })}

                  {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value &&
                    utilsService.formatCurrency(marketShare.quarters[customTableProps.selectedQuarters[0].quarterIdentifier].revenueTop500Value, {
                      shorten: true,
                      mantissa: 2,
                    })}
                </>
              )}
            </strong>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) =>
            quarterService.grTableSortAccessorForRevenue(
              marketShare,
              customTableProps?.selectedQuarters[0].quarterIdentifier,
              customTableProps?.marketShareNumberType.id
            ),
          sortOrder: SortOrder.DESC,
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
        {
          labelAccessor: () => <GRTooltip content={t('market-share:revenue_change_compared_tooltip')}>{t('common:change_text')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage && (
                      <TrendIndicator
                        value={
                          quarterService.getChangeDataForComparisonQuarterValue(
                            QuarterDataKey.RevenuePercentage,
                            customTableProps.selectedQuarters[0].quarterIdentifier,
                            customTableProps.selectedQuarters[1].quarterIdentifier,
                            marketShare.quarters
                          ) * 100
                        }
                        maximumFractionDigits={2}
                        type={TrendIndicatorType.Value}
                      />
                    )}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value && (
                      <TrendIndicator
                        value={quarterService.getChangeDataForComparisonQuarterValue(
                          QuarterDataKey.RevenueTop500Value,
                          customTableProps.selectedQuarters[0].quarterIdentifier,
                          customTableProps.selectedQuarters[1].quarterIdentifier,
                          marketShare.quarters
                        )}
                        maximumFractionDigits={2}
                        type={TrendIndicatorType.Value}
                      />
                    )}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) =>
            quarterService.grTableSortAccessorForRevenueChange(
              marketShare,
              customTableProps?.selectedQuarters[0].quarterIdentifier,
              customTableProps?.selectedQuarters[1].quarterIdentifier,
              customTableProps?.marketShareNumberType.id
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
        {
          labelAccessor: () => <GRTooltip content={t('market-share:downloads_tooltip')}>{t('common:downloads_shorthand')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage &&
                      utilsService.formatPercent(marketShare.quarters[customTableProps.selectedQuarters[0].quarterIdentifier].downloadsPercentage, {
                        maximumFractionDigits: 2,
                      })}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value &&
                      utilsService.formatNumber(marketShare.quarters[customTableProps.selectedQuarters[0].quarterIdentifier].downloadsTop500Value, {
                        shorten: true,
                        mantissa: 2,
                      })}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) =>
            quarterService.grTableSortAccessorForDownloads(
              marketShare,
              customTableProps?.selectedQuarters[0].quarterIdentifier,
              customTableProps?.marketShareNumberType.id
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
        {
          labelAccessor: () => <GRTooltip content={t('market-share:downloads_change_compared_tooltip')}>{t('common:change_text')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage && (
                      <>
                        <TrendIndicator
                          value={
                            quarterService.getChangeDataForComparisonQuarterValue(
                              QuarterDataKey.DownloadsPercentage,
                              customTableProps.selectedQuarters[0].quarterIdentifier,
                              customTableProps.selectedQuarters[1].quarterIdentifier,
                              marketShare.quarters
                            ) * 100
                          }
                          maximumFractionDigits={2}
                          type={TrendIndicatorType.Value}
                        />
                      </>
                    )}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value && (
                      <TrendIndicator
                        value={quarterService.getChangeDataForComparisonQuarterValue(
                          QuarterDataKey.DownloadsTop500Value,
                          customTableProps.selectedQuarters[0].quarterIdentifier,
                          customTableProps.selectedQuarters[1].quarterIdentifier,
                          marketShare.quarters
                        )}
                        maximumFractionDigits={2}
                        type={TrendIndicatorType.Value}
                      />
                    )}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) =>
            quarterService.grTableSortAccessorForDownloadsChange(
              marketShare,
              customTableProps?.selectedQuarters[0].quarterIdentifier,
              customTableProps?.selectedQuarters[1].quarterIdentifier,
              customTableProps?.marketShareNumberType.id
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
      ],
    },
    // Q2
    {
      labelAccessor: ({ customTableProps }) => (
        <>
          {customTableProps && (
            <QuarterSelector
              selectedQuarter={customTableProps.selectedQuarters[1]}
              quarters={calculatedQuarters}
              onQuarterChangeCallback={(e) => customTableProps.handleQuarterChange(e, 1)}
            />
          )}
        </>
      ),
      columns: [
        {
          labelAccessor: () => <GRTooltip content={t('market-share:revenue_tooltip')}>{t('common:revenue_shorthand')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage &&
                      utilsService.formatPercent(marketShare.quarters[customTableProps.selectedQuarters[1].quarterIdentifier].revenuePercentage, {
                        maximumFractionDigits: 2,
                      })}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value &&
                      utilsService.formatCurrency(marketShare.quarters[customTableProps.selectedQuarters[1].quarterIdentifier].revenueTop500Value, {
                        shorten: true,
                        mantissa: 2,
                      })}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) =>
            quarterService.grTableSortAccessorForRevenue(
              marketShare,
              customTableProps?.selectedQuarters[1].quarterIdentifier,
              customTableProps?.marketShareNumberType.id
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
        {
          labelAccessor: () => <GRTooltip content={t('market-share:revenue_change_compared_tooltip')}>{t('common:change_text')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage && (
                      <>
                        <TrendIndicator
                          value={
                            quarterService.getChangeDataForComparisonQuarterValue(
                              QuarterDataKey.RevenuePercentage,
                              customTableProps.selectedQuarters[1].quarterIdentifier,
                              customTableProps.selectedQuarters[0].quarterIdentifier,
                              marketShare.quarters
                            ) * 100
                          }
                          maximumFractionDigits={2}
                          type={TrendIndicatorType.Value}
                        />
                      </>
                    )}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value && (
                      <TrendIndicator
                        value={quarterService.getChangeDataForComparisonQuarterValue(
                          QuarterDataKey.RevenueTop500Value,
                          customTableProps.selectedQuarters[1].quarterIdentifier,
                          customTableProps.selectedQuarters[0].quarterIdentifier,
                          marketShare.quarters
                        )}
                        maximumFractionDigits={2}
                        type={TrendIndicatorType.Value}
                      />
                    )}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) =>
            quarterService.grTableSortAccessorForRevenueChange(
              marketShare,
              customTableProps?.selectedQuarters[1].quarterIdentifier,
              customTableProps?.selectedQuarters[0].quarterIdentifier,
              customTableProps?.marketShareNumberType.id
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
        {
          labelAccessor: () => <GRTooltip content={t('market-share:downloads_tooltip')}>{t('common:downloads_shorthand')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage &&
                      utilsService.formatPercent(marketShare.quarters[customTableProps.selectedQuarters[1].quarterIdentifier].downloadsPercentage, {
                        maximumFractionDigits: 2,
                      })}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value &&
                      utilsService.formatNumber(marketShare.quarters[customTableProps.selectedQuarters[1].quarterIdentifier].downloadsTop500Value, {
                        shorten: true,
                        mantissa: 2,
                      })}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) =>
            quarterService.grTableSortAccessorForDownloads(
              marketShare,
              customTableProps?.selectedQuarters[1].quarterIdentifier,
              customTableProps?.marketShareNumberType.id
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
        {
          labelAccessor: () => <GRTooltip content={t('market-share:downloads_change_compared_tooltip')}>{t('common:change_text')}</GRTooltip>,
          accessor: ({ row: marketShare, customTableProps }) => (
            <>
              <strong>
                {customTableProps && (
                  <>
                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Percentage && (
                      <>
                        <TrendIndicator
                          value={
                            quarterService.getChangeDataForComparisonQuarterValue(
                              QuarterDataKey.DownloadsPercentage,
                              customTableProps.selectedQuarters[1].quarterIdentifier,
                              customTableProps.selectedQuarters[0].quarterIdentifier,
                              marketShare.quarters
                            ) * 100
                          }
                          maximumFractionDigits={2}
                          type={TrendIndicatorType.Value}
                        />
                      </>
                    )}

                    {customTableProps.marketShareNumberType.id === MarketShareNumberTypeId.Value && (
                      <TrendIndicator
                        value={quarterService.getChangeDataForComparisonQuarterValue(
                          QuarterDataKey.DownloadsTop500Value,
                          customTableProps.selectedQuarters[1].quarterIdentifier,
                          customTableProps.selectedQuarters[0].quarterIdentifier,
                          marketShare.quarters
                        )}
                        maximumFractionDigits={2}
                        type={TrendIndicatorType.Value}
                      />
                    )}
                  </>
                )}
              </strong>
            </>
          ),
          sortable: true,
          sortAccessor: ({ row: marketShare, customTableProps }) =>
            quarterService.grTableSortAccessorForDownloadsChange(
              marketShare,
              customTableProps?.selectedQuarters[1].quarterIdentifier,
              customTableProps?.selectedQuarters[0].quarterIdentifier,
              customTableProps?.marketShareNumberType.id
            ),
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 }, align: 'center' },
        },
      ],
    },
  ]

  const [columns, setColumns] = useState<GRTableColumn<MarketShare, typeof customProps>[]>(tableColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<MarketShare, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <div className="MarketShareQuarterComparison">
      <TableContainer ref={containerRef}>
        <GRTable
          rows={marketShares}
          columns={columns}
          striped
          hoverable
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey="id"
          scroller={containerRef}
          customProps={customProps}
          maxRows={100}
        />
      </TableContainer>
    </div>
  )
}

export default MarketShareQuarterComparison
