import classNames from 'classnames'
import { FC, useState } from 'react'
import Cropper, { Area, Size } from 'react-easy-crop'
import { useTranslation } from 'react-i18next'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Grid } from '@mui/material'

import { displaySnackBar } from '../../features/snackbar'
import { SnackBarMessage } from '../../features/snackbar/types/SnackBarMessage'
import { useAppDispatch } from '../../hooks/storeHooks'
import './ImageCropDialog.scss'
import getCroppedImg, { resizeImage } from './cropImage'

const CROP_AREA_ASPECT = 1 / 1

const Output: React.FC<{ croppedArea: Area; imageSrc: string }> = ({ croppedArea, imageSrc }) => {
  const scale = 100 / croppedArea.width
  const transform = {
    x: `${-croppedArea.x * scale}%`,
    y: `${-croppedArea.y * scale}%`,
    scale,
    width: 'calc(100% + 0.5px)',
    height: 'auto',
  }

  const imageStyle = {
    transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
    width: transform.width,
    height: transform.height,
  }

  return (
    <div className="output" style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}>
      <img src={imageSrc} alt="" style={imageStyle} />
    </div>
  )
}

type ImageCropDialogProps = DialogProps & {
  imageSrc: string
  previewShape?: 'rect' | 'rounded'
  cropShape?: 'rect' | 'round'
  cropAspect?: number
  resize?: Size
  onApply: (blop: Blob) => void
}

const ImageDropDialog: FC<ImageCropDialogProps> = ({ imageSrc, cropAspect = 1, previewShape = 'rect', cropShape = 'rect', resize, onApply, ...props }) => {
  const { t } = useTranslation()
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState<Area | null>(null)
  const [blop, setBlop] = useState<Blob | null>(null)
  const dispatch = useAppDispatch()
  const showError = (errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload))

  const handleApplyClick = () => {
    if (!blop) return
    onApply(blop)
  }

  const handleCancelClick = () => {
    props.onClose?.({}, 'backdropClick')
  }

  const previewClasses = classNames({
    'ImageCropDialog__PreviewContainer--rect': previewShape === 'rect',
    'ImageCropDialog__PreviewContainer--rounded': previewShape === 'rounded',
  })

  return (
    <Dialog fullWidth className="ImageCropDialog" {...props}>
      <DialogTitle>{t('game-edit:crop_image')}</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Grid container width="100%" gap={0} padding={0}>
          <Grid item xs={8} padding={0}>
            <Box className="ImageCropDialog__CropperContainer">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                cropShape={cropShape}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                aspect={cropAspect}
                onCropAreaChange={(croppedArea: Area, cropperAreaPixels: Area) => {
                  setCroppedArea(croppedArea)
                }}
                onCropComplete={async (croppedArea: Area, croppedAreaPixels: Area) => {
                  try {
                    let cropBlop = await getCroppedImg(imageSrc, croppedAreaPixels)

                    if (cropBlop) {
                      if (resize) {
                        cropBlop = await resizeImage(cropBlop, resize.width, resize.height)
                      }

                      setBlop(cropBlop as Blob)
                    }
                  } catch (error) {
                    showError({ message: t('game-edit:error_image_dialog_body_unable_upload'), severity: 'error', open: true })
                  }
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={4} justifyContent="center" alignContent="center">
            <Box className="ImageCropDialog__PreviewContainer">
              <div className={previewClasses}> {croppedArea && <Output croppedArea={croppedArea} imageSrc={imageSrc} />}</div>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleCancelClick}>
          {t('common:cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleApplyClick}>
          {t('common:apply')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ImageDropDialog
