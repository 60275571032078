import classNames from 'classnames'
import { FC, MouseEventHandler, ReactNode } from 'react'

import { Alert, AlertProps, Box, Paper } from '@mui/material'

import './GRBanner.scss'

/**
 * Component for displaying a (clickable) banner with custom icon in different severities
 */
type GRBannerProps = {
  children?: ReactNode
  severity: AlertProps['severity'] | (typeof extendedSeverity)[number]
  icon?: ReactNode
  onClick?: MouseEventHandler<HTMLElement>
}

const extendedSeverity = ['guidance'] as const

export const GRBanner: FC<GRBannerProps> = ({ children, severity, icon, onClick }) => {
  if (Object.values(extendedSeverity).find((item) => item === severity)) {
    const classes = classNames('GRBanner', severity)
    return (
      <Paper
        className={classes}
        elevation={2}
        sx={{
          display: 'flex',
          padding: 2,
          alignItems: 'center',
          justifyContent: 'center',
          transition: (theme) =>
            theme.transitions.create('all', {
              duration: theme.transitions.duration.standard,
              easing: theme.transitions.easing.easeInOut,
            }),
          '&:hover': onClick && { cursor: 'pointer', opacity: 0.9 },
        }}
      >
        {icon && <Box mr={1}>{icon}</Box>}
        {children}
      </Paper>
    )
  } else {
    return (
      <Alert
        severity={severity as AlertProps['severity']}
        icon={icon}
        onClick={onClick}
        variant="filled"
        elevation={2}
        sx={{
          justifyContent: 'center',
          transition: (theme) =>
            theme.transitions.create('all', {
              duration: theme.transitions.duration.standard,
              easing: theme.transitions.easing.easeInOut,
            }),
          '&:hover': onClick && { cursor: 'pointer', opacity: 0.9 },
        }}
      >
        {children}
      </Alert>
    )
  }
}
