import React, { memo, useState } from 'react'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { Game } from '../../../game/types/Game'
import PublisherGamesDialog from '../PublisherGamesDialog/PublisherGamesDialog'
import './PublisherGamesDialogLink.scss'

type PublisherGamesDialogLinkProps = {
  game: Game
  marketIso: string
}

/**
 * Component for creating a link to game page
 */
export const PublisherGamesDialogLink: React.FC<PublisherGamesDialogLinkProps> = memo(({ game, marketIso }) => {
  const [publisherGamesModalOpen, setPublisherGamesModalOpen] = useState(false)

  const onClose = () => {
    setPublisherGamesModalOpen(false)
  }

  return (
    <div className="PublisherGamesDialogLink">
      <div
        className={game.appId ? 'PublisherGamesDialogLink__Link PublisherGamesDialogLink__Link--Active' : 'PublisherGamesDialogLink__Link'}
        onClick={() => {
          if (game.appId) {
            setPublisherGamesModalOpen(true)
          }
        }}
      >
        <GRTooltip content={game.artist}>{game.artist}</GRTooltip>
      </div>
      <PublisherGamesDialog modalOpen={publisherGamesModalOpen} game={game} onClose={onClose} marketIso={marketIso} />
    </div>
  )
})
