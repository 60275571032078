import { DefaultTFuncReturn } from 'i18next'
import { useRef, useEffect } from 'react'

type UseDocumentTitle = (title?: string | DefaultTFuncReturn, prevailOnUnmount?: boolean) => void

export const useDocumentTitle: UseDocumentTitle = (title, prevailOnUnmount = false) => {
  const defaultTitle = useRef(document.title)

  useEffect(() => {
    if (title) {
      document.title = 'GameRefinery | ' + title
    }
  }, [title])

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}
