import React from 'react'
import { Trans } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Box, Dialog, DialogContent, DialogProps, Divider, Grid, IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material'

import noBookmarksExampleImg from '../../../assets/images/no_bookmarks_example.png'
import GRDialogTitle from '../../../components/GRDialogTitle/GRDialogTitle'
import { useBookmarks } from '../hooks/bookmarkHooks'
import { BookmarkCard } from './BookmarkCard'

const BookmarksDialog: React.FC<DialogProps> = ({ open, onClose, ...dialogProps }) => {
  const { data: bookmarks, isLoading } = useBookmarks()

  const handleCardClick = (event: any) => {
    if (onClose) {
      onClose(event, 'escapeKeyDown')
    }
  }

  return (
    <Dialog maxWidth="lg" fullWidth disableRestoreFocus open={open} {...dialogProps}>
      <GRDialogTitle>
        <Typography>
          <Trans i18nKey={'bookmarks:my_bookmarks_title'} />
        </Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={(e) => onClose(e, 'escapeKeyDown')}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </GRDialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <h3>
            <Trans i18nKey={'bookmarks:my_bookmarks_title'} />
          </h3>
          {isLoading ? (
            <Grid container spacing={2} mb={2}>
              {[0, 1, 2].map((value) => (
                <Grid key={value} item>
                  <Paper variant="outlined">
                    <Stack spacing={0.2}>
                      <Skeleton variant="rectangular" width={325} height={230} />
                      <Skeleton variant="rectangular" width={325} height={40} />
                      <Skeleton variant="rectangular" width={325} height={40} />
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              {bookmarks && bookmarks.own?.length ? (
                <Grid container spacing={5} mb={2} wrap="wrap" columns={3}>
                  {bookmarks?.own.map((bookmark) => (
                    <Grid key={bookmark.id} item xs={1}>
                      <BookmarkCard bookmark={bookmark} onClick={handleCardClick} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box mb={2}>
                  <Typography mb={2}>
                    <Trans i18nKey={'bookmarks:no_my_bookmarks_yet_text'} />
                  </Typography>
                  <Box maxWidth={400}>
                    <Paper variant="outlined">
                      <img src={noBookmarksExampleImg} alt="{'bookmarks:no_my_bookmarks_yet_text'}" style={{ maxWidth: '400px' }} />
                    </Paper>
                  </Box>
                </Box>
              )}
            </>
          )}
          <Divider sx={{ marginBottom: '20px' }} />
          <h3>
            <Trans i18nKey={'bookmarks:bookmarks_shared_with_me_title'} />
          </h3>
          {isLoading ? (
            <Grid container spacing={2} mb={2}>
              {[0, 1].map((value) => (
                <Grid key={value} item>
                  <Paper variant="outlined">
                    <Stack spacing={0.2}>
                      <Skeleton variant="rectangular" width={325} height={230} />
                      <Skeleton variant="rectangular" width={325} height={40} />
                      <Skeleton variant="rectangular" width={325} height={40} />
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              {bookmarks && bookmarks.shared?.length ? (
                <Grid container spacing={5} mb={2} wrap="wrap" columns={3}>
                  {bookmarks?.shared.map((bookmark) => (
                    <Grid key={bookmark.id} item xs={1}>
                      <BookmarkCard bookmark={bookmark} onClick={handleCardClick} shared={true} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Box mb={2}>
                  <Typography mb={2}>
                    <Trans i18nKey={'bookmarks:no_shared_bookmarks_yet_text'} />
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default BookmarksDialog
