import React from 'react'

import SendIcon from '@mui/icons-material/Send'
import { ListItem, ListItemText } from '@mui/material'

import './AIExampleCard.scss'

type AIExampleCardProps = {
  text: string
  onSend: () => void
}

const AIExampleCard: React.FC<AIExampleCardProps> = ({ text, onSend }) => {
  return (
    <ListItem className="AIExampleCard" onClick={onSend} secondaryAction={<SendIcon className="send" />}>
      <ListItemText className="text">{text}</ListItemText>
    </ListItem>
  )
}

export default AIExampleCard
