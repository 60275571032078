import { addWeeks } from 'date-fns'
import { t } from 'i18next'
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material'

import GRCircularProgress from '../../../components/GRCircularProgress/GRCircularProgress'
import { displaySnackBar } from '../../../features/snackbar'
import { useAppDispatch } from '../../../hooks/storeHooks'
import usePage from '../../../hooks/usePage'
import { useRequiredParams } from '../../../hooks/useRequiredParams'
import pageService from '../../../services/PageService'
import { Analyst } from '../../../types/Analyst'
import { useGetAnalystsListQuery, useGetCommentTrackedMutation, useGetTrackedGameAnalystCommentsQuery, useGetTrackedGameQuery } from '../../api/internal'
import { BackButton } from '../../components/BackButton/BackButton'
import { AnalystNoteEditBody } from '../../features/live-events-tracking/components/AnalystNoteEditBody/AnalystNoteEditBody'
import { AnalystNoteEditHeader } from '../../features/live-events-tracking/components/AnalystNoteEditHeader/AnalystNoteEditHeader'
import { useAnalystNotesSearchParams } from '../../features/live-events-tracking/hooks/useAnalystNotesSearchParams'
import { InternalAnalystComment } from '../../types/InternalAnalystComment'

type AnalystNoteEditPageProps = {
  children?: ReactNode
}

export const AnalystNoteEditPage: FC<AnalystNoteEditPageProps> = () => {
  usePage(pageService.getInternalPageWithId('internal-tracked-game-analyst-note-edit'))
  const [swicthAnalyst, setSwitchAnalyst] = useState<Analyst>()
  const [newNoteData, setNewNoteData] = useState<InternalAnalystComment>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { trackedGameId } = useRequiredParams<{ trackedGameId: string }>()
  const { parsedParams } = useAnalystNotesSearchParams()
  const getTrackedGameQuery = useGetTrackedGameQuery(trackedGameId)
  const getCommentDataQuery = useGetTrackedGameAnalystCommentsQuery({ trackedGameId: trackedGameId })
  const getAnalystList = useGetAnalystsListQuery()
  const [postComment] = useGetCommentTrackedMutation()
  const dateParam = parsedParams.date

  const commentData = getCommentDataQuery.data?.find((comment) => {
    return comment.id === parsedParams.commentId
  })

  useEffect(() => {
    setSwitchAnalyst(
      getAnalystList.data?.find((analyst) => {
        return analyst.id === commentData?.comment.analystId
      })
    )
  }, [commentData?.comment.analystId, getAnalystList])

  const defaultNoteValues = useMemo(() => {
    const startDate = commentData ? convertToDate(commentData.start) : convertToDate(Date.now())
    const endDate = commentData ? convertToDate(commentData?.end) : addWeeks(Date.now(), 1)
    return {
      active: commentData?.active || false,
      appId: getTrackedGameQuery.data?.game.appId,
      comment: {
        analystId: commentData?.comment.analystId || '',
        content: {
          title: {
            en: commentData?.comment.content.title.en || '',
            ja: commentData?.comment.content.title.ja || '',
            zh: commentData?.comment.content.title.zh || '',
          },
          comment: {
            en: commentData?.comment.content.comment.en || '',
            ja: commentData?.comment.content.comment.ja || '',
            zh: commentData?.comment.content.comment.zh || '',
          },
        },
      },
      gameId: getTrackedGameQuery.data?.game.id,
      start: commentData?.start,
      end: commentData?.end,
      id: commentData?.id,
      dateRange: {
        fromDate: startDate,
        toDate: endDate,
      },
    }
  }, [commentData, getTrackedGameQuery.data?.game.appId, getTrackedGameQuery.data?.game.id])

  const form = useForm<InternalAnalystComment>({ defaultValues: defaultNoteValues })

  const {
    reset,
    formState: { isDirty, isValid },
  } = form

  const isLoading = getCommentDataQuery.isFetching || getTrackedGameQuery.isFetching || getAnalystList.isFetching

  const handleNoteDataChange = useCallback(
    (values: InternalAnalystComment) => {
      const analyst = getAnalystList.data?.find((analyst) => {
        return analyst.id === values.comment.analystId
      })
      setSwitchAnalyst(analyst)
      let newNote = values
      newNote.start = values.dateRange?.fromDate?.getTime()
      newNote.end = values.dateRange?.toDate?.getTime()
      setNewNoteData(newNote)
    },
    [getAnalystList.data]
  )

  const handleSave = () => {
    let dataToSave: InternalAnalystComment

    if (newNoteData) {
      const { dateRange, ...data } = newNoteData
      dataToSave = data

      postComment(dataToSave)
        .then(() => {
          dispatch(displaySnackBar({ message: t('internal-live-events:live_event_comment_saved_successfully'), severity: 'success', open: true }))
        })
        .catch((error) =>
          dispatch(displaySnackBar({ message: t('internal-live-events:live_event_comment_saved_successfully'), severity: 'error', open: true }))
        )
        .finally(() => navigate(`/internal/live-events-tracking/events/${dataToSave.gameId}/analyst-notes?date=${dateParam}`))
    } else {
      dispatch(displaySnackBar({ message: t('internal-live-events:live_event_comment_save_failed'), severity: 'error', open: true }))
    }
  }

  useEffect(() => {
    reset(defaultNoteValues)
  }, [defaultNoteValues, reset])

  return isLoading ? (
    <GRCircularProgress />
  ) : (
    <>
      <BackButton url={`/internal/live-events-tracking/events/${trackedGameId}/analyst-notes?date=${dateParam}`} />
      <form>
        <Card>
          <CardHeader title={<AnalystNoteEditHeader game={getTrackedGameQuery.data?.game} swicthAnalyst={swicthAnalyst} />} />
          <Divider sx={{ mb: 1 }} />
          <CardContent>
            <AnalystNoteEditBody form={form} onChange={handleNoteDataChange} />
          </CardContent>
        </Card>
        <Grid container spacing={2} justifyContent="flex-end" padding="10px">
          <Grid item>
            <Button
              variant="text"
              component={Link}
              to={`/internal/live-events-tracking/events/${trackedGameId}/analyst-notes?date=${dateParam}`}
              size="small"
              color="primary"
            >
              {t('internal-common:cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                handleSave()
              }}
              variant="contained"
              color="primary"
              disabled={!(isValid && isDirty)}
            >
              {t('internal-common:save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export const convertToDate = (value: number | undefined) => {
  if (value !== undefined) {
    return new Date(value)
  }
}
