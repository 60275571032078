export class LiveEventPricePoint {
  id: string
  name: string
  start: number
  end: number

  constructor(id: string, name: string, start: number, end: number) {
    this.id = id
    this.name = name
    this.start = start
    this.end = end
  }
}
