import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'

import { LinearProgressBar } from '../../../../components/LinearProgressBar/LinearProgressBar'
import './PowerScoreProgressDialog.scss'

/**
 * PowerScoreProgressDialog Show update powerscore status with progress bar
 */

interface PowerScoreProgressDialogProps {
  open: boolean
  process: number
}
const PowerScoreProgressDialog: React.FC<PowerScoreProgressDialogProps> = ({ open, process }) => {
  const { t } = useTranslation()

  const processText = () => {
    switch (process) {
      case 0:
        return t('analysis:scanning_features')
      case 20:
        return t('analysis:identifying_synergies')
      default:
        return t('analysis:calculating_gps')
    }
  }

  return (
    <Dialog className="PowerScoreProgressDialog" fullWidth={true} maxWidth="sm" open={open}>
      <DialogTitle>{t('analysis:performing_analysis')}</DialogTitle>
      <DialogContent className="PowerScoreProgressDialog__content" dividers>
        <div>
          <Box sx={{ mt: 2 }}>
            <LinearProgressBar labelAfter={`${process}%`} variant="determinate" value={process} color={process === 100 ? 'success' : 'primary'} />
          </Box>
          <Typography className="PowerScoreProgressDialog__text" sx={{ paddingTop: 1 }} textAlign="center">
            {processText()}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default PowerScoreProgressDialog
