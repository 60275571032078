import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Announcement, Send } from '@mui/icons-material'
import { Avatar, Button, ButtonProps, Divider, Grid, Popover, Rating, TextField } from '@mui/material'

import { useGetUserSettingsQuery, useUpdateUserSettingMutation } from '../../../../api/combined'
import { useSendGameAnalysisSuggestionMutation } from '../../../../api/core'
import JoelImage from '../../../../assets/images/joel.jpg'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { useAnalysisSuggestionStatus } from '../../../account/hooks/userHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { UserSettingKeys } from '../../../account/types/UserSettings'
import { useCurrentMarket } from '../../../markets'
import { displaySnackBar } from '../../../snackbar'
import { AnalysisSuggestionStatus } from '../../types/AnalysisSuggestionStatus'
import { Game } from '../../types/Game'
import { GameAnalysisSuggestion } from '../../types/GameAnalysisSuggestion'
import ContactSalesModal from '../ContactSalesModal/ContactSalesModal'
import './SuggestAnalysisButton.scss'

type SuggestAnalysisButtonProps = ButtonProps & {
  game: Game
}

const SuggestAnalysisButton: React.FC<SuggestAnalysisButtonProps> = ({ game, ...buttonProps }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { data: userSettings } = useGetUserSettingsQuery()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [ratingValue, setRatingValue] = React.useState<number>(1)
  const [suggestionMessage, setSuggestionMessage] = useState('')
  const [openContactSalesModal, setOpenContactSalesModal] = useState(false)
  const [analysisSuggestionStatus, setAnalysisSuggestionStatus] = useAnalysisSuggestionStatus(game, mainMarketIso)
  const hasAccessToRequestAnalysis = useRoleCheck(RoleEnum.request_analysis)

  const [sendGameAnalysisSuggestion] = useSendGameAnalysisSuggestionMutation()
  const [saveUserSetting] = useUpdateUserSettingMutation()

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (hasAccessToRequestAnalysis) {
      setAnchorEl(event.currentTarget)
      setPopoverOpen(!popoverOpen)
    } else {
      setOpenContactSalesModal(true)
    }
  }

  const handleSuggestionMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuggestionMessage(event.target.value)
  }

  const gameHasPowerscore = () => {
    return game.gpsPerMarket[mainMarketIso] > 0 ? true : false
  }

  const sendSuggestion = () => {
    setAnalysisSuggestionStatus(AnalysisSuggestionStatus.sending)
    const gameAnalysisSuggestion: GameAnalysisSuggestion = {
      gameId: game.id,
      importance: ratingValue,
      requestReason: suggestionMessage,
    }

    sendGameAnalysisSuggestion(gameAnalysisSuggestion).finally(() => {
      const analysisRequests = userSettings?.analysisRequests ? { ...userSettings?.analysisRequests } : {}
      analysisRequests[game.appId] = game.lastAnalyzedVersions[mainMarketIso] ? game.lastAnalyzedVersions[mainMarketIso] : true
      saveUserSetting({ settingKey: UserSettingKeys.analysisRequests, value: analysisRequests })
      setAnalysisSuggestionStatus(AnalysisSuggestionStatus.sent)
      setPopoverOpen(false)

      const displayTimeMilliseconds = 20000
      if (gameHasPowerscore()) {
        dispatch(
          displaySnackBar({
            message: <Trans i18nKey="common:request_analysis_update_message" />,
            severity: 'success',
            open: true,
            displayTimeMilliseconds,
          })
        )
      } else {
        dispatch(
          displaySnackBar({
            message: <Trans i18nKey="common:request_analysis_message" />,
            severity: 'success',
            open: true,
            displayTimeMilliseconds,
          })
        )
      }
    })
  }

  return (
    <div className="SuggestAnalysisButton">
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handlePopoverOpen}
        startIcon={<Announcement />}
        disabled={analysisSuggestionStatus === AnalysisSuggestionStatus.sent || analysisSuggestionStatus === AnalysisSuggestionStatus.sending}
        {...buttonProps}
      >
        {analysisSuggestionStatus !== AnalysisSuggestionStatus.sent && gameHasPowerscore() && t('common:request_analysis_update_text')}
        {analysisSuggestionStatus !== AnalysisSuggestionStatus.sent && !gameHasPowerscore() && t('common:request_analysis_text')}
        {analysisSuggestionStatus === AnalysisSuggestionStatus.sent && t('common:request_analysis_sent_text')}
      </Button>
      <Popover
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="SuggestAnalysisButton__popover">
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Avatar alt="Joel Julkunen" src={JoelImage}></Avatar>
            </Grid>
            <Grid item>
              <h3 className="bottom-margin-none">Joel Julkunen</h3>
              <div className="light-text-color">{t('analysis:vice_president_of_games')}</div>
            </Grid>
          </Grid>
          <p className="light-text-color">{gameHasPowerscore() ? t('analysis:analysis_update') : t('analysis:analysis_new')}</p>
          <p className="light-text-color">{t('analysis:before_sending')}</p>

          <Divider></Divider>
          <div className="small-top-margin">
            <h5 style={{ marginBottom: '10px' }}>{gameHasPowerscore() ? t('analysis:importance') : t('analysis:importance_new')}</h5>
            <Rating
              value={ratingValue}
              onChange={(event, newValue) => {
                setRatingValue(newValue || 1)
              }}
            />
          </div>
          <div className="small-top-margin">
            <h5 style={{ marginBottom: '10px' }}>{gameHasPowerscore() ? t('analysis:main_reason') : t('analysis:main_reason_new')}</h5>
            <TextField multiline rows={4} fullWidth value={suggestionMessage} onChange={handleSuggestionMessageChange} />
          </div>

          <Button className="small-top-margin" size="small" variant="contained" color="primary" onClick={sendSuggestion} startIcon={<Send />}>
            {t('common:send')}
          </Button>
        </div>
      </Popover>
      <ContactSalesModal open={openContactSalesModal} onClose={() => setOpenContactSalesModal(false)} />
    </div>
  )
}

export default SuggestAnalysisButton
