import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '.'
import { User, UserLanguage } from '../features/account/types/User'
import { UserSettingKeys, UserSettings } from '../features/account/types/UserSettings'
import type { FeatureExample } from '../features/feature/types/types'
import type { Game } from '../features/game'
import languageService from '../services/LanguageService'
import { UserProfileForm } from './../features/account/types/UserProfile'

const coreApiUrl = window.GR_API_URLS.API_URL_CORE
const accountApiUrl = window.GR_API_URLS.API_URL_ACCOUNT

const TAG_TYPE_USER_SETTING = 'userSetting' as const

/**
 * An API module combining endpoints from different services. To take advantage of redux toolkit query
 * management tools, the enspoints affecting each other need to be defined within the same module.
 */
export const combinedApi = createApi({
  reducerPath: 'combinedApi',
  baseQuery: fetchBaseQuery({ prepareHeaders: prepareDefaultHeaders }),
  tagTypes: ['userSetting'],
  endpoints: (builder) => ({
    getUserSettings: builder.query<UserSettings, void>({
      providesTags: [TAG_TYPE_USER_SETTING],
      query: () => `${coreApiUrl}/users/settings`,
      transformResponse: (response: any) =>
        Object.fromEntries(
          Object.keys(response).map((k) => [
            k,
            typeof response[k] === 'string' && (response[k][0] === '{' || response[k][0] === '[') ? JSON.parse(response[k]) : response[k],
          ])
        ),
    }),
    getUserSetting: builder.query<unknown, UserSettingKeys>({
      query: (settingKey) => `${coreApiUrl}/users/settings/${settingKey}`,
      providesTags: (result, error, settingKey) => [{ type: TAG_TYPE_USER_SETTING, id: settingKey }],
    }),
    updateUserSetting: builder.mutation<void, { settingKey: UserSettingKeys; value: any }>({
      query: ({ settingKey, value }) => ({
        url: `${coreApiUrl}/users/settings/${settingKey}`,
        method: 'PUT',
        body: value,
      }),
      invalidatesTags: (result, error, { settingKey }) => [{ type: TAG_TYPE_USER_SETTING, id: settingKey }],
      async onQueryStarted({ settingKey, value }, { dispatch, queryFulfilled }) {
        const userSettings = dispatch(
          combinedApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
            draft[settingKey] = value
          })
        )

        const userSetting = dispatch(combinedApi.util.updateQueryData('getUserSetting', settingKey, (draft) => value))
        try {
          await queryFulfilled
        } catch {
          // undo the optimistic query data (cache) update in case request fails
          userSettings.undo()
          userSetting.undo()
        }
      },
    }),
    // This is a special case where we don't want to invalidate any cache
    updateUserLastSeenNews: builder.mutation<void, { value: number }>({
      query: ({ value }) => ({
        url: `${coreApiUrl}/users/settings/${UserSettingKeys.lastSeenNews}`,
        method: 'PUT',
        body: value,
      }),
    }),
    updateDailyDigestSubscription: builder.mutation<void, boolean>({
      query: (value) => ({
        url: `${coreApiUrl}/users/settings`,
        method: 'PUT',
        body: { daily_digest: value },
      }),
      async onQueryStarted(value, { dispatch, queryFulfilled }) {
        const result = dispatch(
          combinedApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
            draft.daily_digest = value
          })
        )
        try {
          await queryFulfilled
        } catch {
          result.undo()
        }
      },
    }),
    followGame: builder.mutation<void, Game>({
      query: ({ ...game }) => ({
        url: `${coreApiUrl}/games/follow/${game.id}`,
        method: 'PUT',
      }),
      async onQueryStarted({ ...game }, { dispatch, queryFulfilled }) {
        const result = dispatch(
          combinedApi.util.updateQueryData('getUserProfile', undefined, (draft) => {
            draft.organization.followedGamesMonthCount++
            draft.organization.followedGames = [...draft.organization.followedGames, game.id]
          })
        )
        try {
          await queryFulfilled
        } catch {
          result.undo()
        }
      },
    }),
    unfollowGame: builder.mutation<void, Game>({
      query: ({ ...game }) => ({
        url: `${coreApiUrl}/games/unfollow/${game.id}`,
        method: 'PUT',
      }),
      async onQueryStarted({ ...game }, { dispatch, queryFulfilled }) {
        const result = dispatch(
          combinedApi.util.updateQueryData('getUserProfile', undefined, (draft) => {
            draft.organization.followedGames = draft.organization.followedGames.filter((gameId) => gameId !== game.id)
          })
        )
        try {
          await queryFulfilled
        } catch {
          result.undo()
        }
      },
    }),
    getFeatureExample: builder.query<FeatureExample, { featureId: string; storeId?: string; marketIso: string; userLanguage: UserLanguage }>({
      query: ({ featureId, storeId, marketIso }) => `${coreApiUrl}/examples/${featureId}?marketIso=${marketIso}&store=${storeId}`,
      transformResponse: (response: FeatureExample, _, { userLanguage }) => {
        return {
          ...response,
          desc: response.descriptions[userLanguage] ? response.descriptions[userLanguage] : response.desc,
          corrects: response.corrects.map((correct) => {
            return {
              ...correct,
              title: correct.titles[userLanguage] ? correct.titles[userLanguage] : correct.title,
              desc: correct.descriptions[userLanguage] ? correct.descriptions[userLanguage] : correct.desc,
            }
          }),
          wrongs: response.wrongs.map((wrong) => {
            return {
              ...wrong,
              title: wrong.titles[userLanguage] ? wrong.titles[userLanguage] : wrong.title,
              desc: wrong.descriptions[userLanguage] ? wrong.descriptions[userLanguage] : wrong.desc,
            }
          }),
        }
      },
    }),
    getUserProfile: builder.query<User, void>({
      query: () => `${accountApiUrl}/users/me`,
    }),
    updateUserProfile: builder.mutation<void, { profile: UserProfileForm; params?: { requireCurrentPassword?: boolean; firstPasswordChange?: boolean } }>({
      query: ({ profile, params }) => ({
        url: `${accountApiUrl}/users`,
        method: 'PUT',
        body: profile,
        params,
      }),
      async onQueryStarted(profile, { dispatch, queryFulfilled }) {
        const result = dispatch(
          combinedApi.util.updateQueryData('getUserProfile', undefined, (draft) => {
            draft = { ...draft, ...profile }
          })
        )
        try {
          await queryFulfilled
        } catch {
          result.undo()
        }
      },
    }),
    updateUserLanguage: builder.mutation<void, UserLanguage>({
      query: (language) => ({
        url: `${accountApiUrl}/users/me/language`,
        method: 'PUT',
        body: { language },
      }),
      async onQueryStarted(language, { dispatch, queryFulfilled }) {
        // update current cache
        const result = dispatch(
          combinedApi.util.updateQueryData('getUserProfile', undefined, (draft) => {
            draft.language = language
          })
        )

        try {
          await queryFulfilled
        } catch {
          result.undo()
        }

        queryFulfilled.finally(() => {
          // change the language setting
          languageService.updateUserLanguage(language)
        })
      },
    }),
  }),
})

export const {
  useGetUserProfileQuery,
  useGetUserSettingsQuery,
  useGetUserSettingQuery,
  useGetFeatureExampleQuery,
  useUpdateUserSettingMutation,
  useUpdateUserLastSeenNewsMutation,
  useUpdateDailyDigestSubscriptionMutation,
  useFollowGameMutation,
  useUnfollowGameMutation,
  useUpdateUserLanguageMutation,
  useUpdateUserProfileMutation,
} = combinedApi
