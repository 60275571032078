import { ReactNode } from 'react'

import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Grid } from '@mui/material'

import { GRPopover } from '../../GRPopover/GRPopover'
import { Magnify } from '../../Magnify/Magnify'
import { GRTableColumn } from '../GRTable'
import { SortLabel } from '../HeaderCell/SortLabel/SortLabel'
import styles from './ColumnHeaderLabelWithFilter.module.scss'

/**
 * Component for rendering a table header label with filter icon. Label will have
 * sorting functions attached if onSort and column are passed as props.
 */

type ColumnHeaderLabelWithFilterProps<RowType, CustomPropsType, ColumnIdType = void> = {
  label: ReactNode
  column?: GRTableColumn<RowType, CustomPropsType, ColumnIdType>
  onSort?: (column: GRTableColumn<RowType, CustomPropsType, ColumnIdType>) => void
  active?: boolean
  hideFilter?: boolean
  children?: ReactNode
}

export const ColumnHeaderLabelWithFilter = <RowType extends object, CustomPropsType extends object | undefined, ColumnIdType extends string | void>({
  column,
  label,
  onSort,
  active,
  hideFilter,
  children,
}: ColumnHeaderLabelWithFilterProps<RowType, CustomPropsType, ColumnIdType>) => {
  return (
    <Grid container wrap="nowrap" justifyContent="center" alignItems="center">
      {!hideFilter && (
        <GRPopover content={children} className={styles.popover}>
          <Magnify>
            <FilterAltIcon
              fontSize="small"
              color={active ? 'primary' : undefined}
              sx={{
                '&:hover': { color: (theme) => theme.palette.primary.main, cursor: 'pointer' },
              }}
            />
          </Magnify>
        </GRPopover>
      )}
      {onSort && column ? (
        <SortLabel
          active={!!column.sortOrder}
          direction={column.sortOrder || column.defaultSortOrder}
          onClick={() => onSort(column)}
          className={styles['sort-label']}
        >
          {label}
        </SortLabel>
      ) : (
        <span className={styles['sort-label']}>{label}</span>
      )}
    </Grid>
  )
}
