import { Analyst } from '../../../types/Analyst'
import { UserLanguage } from '../../account/types/User'

export interface GameReview {
  gameReview: Review
  analyst: Analyst
}

export interface Review {
  id: string
  createdAt: number
  modifiedAt: number
  createdBy: null
  modifiedBy: null
  appId: number
  analystId: string
  published: boolean
  content: Content
}

export enum ReviewContentFields {
  GenreAndStyle = 'genreAndStyle',
  FeaturesAndProgression = 'featuresAndProgression',
  Monetization = 'monetization',
  SpecialNotes = 'specialNotes',
}

export type Content = {
  [contentField in ReviewContentFields]: { [language in UserLanguage]: string }
}
