import classNames from 'classnames'
import { FC, memo, ReactNode } from 'react'

import styles from './CellValue.module.scss'

type CellValueProps = {
  children?: ReactNode
  value: ReactNode
  emptyValueIndicator?: ReactNode
  emphasized?: boolean
}

/**
 * Component for displaying cell value on tables
 */
export const CellValue: FC<CellValueProps> = memo(({ value, emptyValueIndicator = '-', emphasized, children }) => {
  const classes = classNames({ [styles.emphasized]: emphasized })
  return <span className={classes}>{value !== undefined ? children : emptyValueIndicator}</span>
})
