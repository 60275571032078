import { addWeeks, getISOWeek, getMonth, startOfDay, startOfWeek, endOfWeek, endOfMonth, subWeeks, subMonths, isAfter } from 'date-fns'

import { useGetActiveUsersQuery } from '../../../api/core'
import { average } from '../../../helpers/average'
import { getTimestampByGranularity } from '../../revenue-and-downloads/helpers/helpers'
import { GranularityValue } from '../../revenue-and-downloads/types/Filters'
import { MonthlyActiveUser } from '../types/ActiveUsersData'

export const usePerformanceChartActiveUserData = ({ appId, granularity }: { appId: number; granularity: GranularityValue }) => {
  const { data: activeUsers, error, isLoading } = useGetActiveUsersQuery({ appId })

  // Determine the last completed period based on granularity
  const now = new Date()
  let lastCompletedPeriodEnd: Date

  if (granularity === GranularityValue.Week) {
    lastCompletedPeriodEnd = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }) // Last completed week
  } else if (granularity === GranularityValue.Month) {
    lastCompletedPeriodEnd = endOfMonth(subMonths(now, 1)) // Last completed month
  } else {
    lastCompletedPeriodEnd = now // Use the current date for daily or finer granularities
  }

  // Group DAU data by granularity
  const dauGroupedByGranularity = activeUsers?.dailyActiveUsers?.reduce((acc, dau) => {
    const granularityParsedDate = new Date(dau.date)
    const parsedDate = getTimestampByGranularity(granularity, granularityParsedDate).getTime()

    // Ensure that only dates up until the last completed period are included
    if (isAfter(lastCompletedPeriodEnd, new Date(parsedDate))) {
      const dateFormattedByGranularity = parsedDate
      const current = acc[dateFormattedByGranularity]

      if (!current) {
        acc[dateFormattedByGranularity] = [{ ...dau, date: dateFormattedByGranularity }]
      } else {
        acc[dateFormattedByGranularity] = [...current, { ...dau, date: dateFormattedByGranularity }]
      }
    }

    return acc
  }, {} as { [granularityGroup: number]: { activeUsers: number; date: number }[] })

  // Compute the average DAU per granularity period
  const dauAverages = Object.entries(dauGroupedByGranularity || {}).map(([date, dau]) => {
    const averageValue = average(dau.map((dau) => dau.activeUsers))
    return { activeUsers: averageValue, date: +date }
  })

  // Filter MAU data based on the last completed month
  const monthlyActiveUsers =
    activeUsers?.monthlyActiveUsers
      ?.filter((mau) => {
        const mauDate = getMauDate(mau, granularity).getTime()
        return isAfter(lastCompletedPeriodEnd, new Date(mauDate))
      })
      .map((mau) => ({
        activeUsers: mau.activeUsers,
        date: getMauDate(mau, granularity).getTime(),
      })) || []

  const sortedDauAverages = dauAverages.sort((a, b) => a.date - b.date)
  const sortedMonthlyActiveUsers = monthlyActiveUsers.sort((a, b) => a.date - b.date)

  return {
    dailyActiveUsers: sortedDauAverages,
    monthlyActiveUsers: sortedMonthlyActiveUsers,
    error,
    isLoading,
  }
}

// Helper function to get the MAU date, handling weeks and months based on granularity
const getMauDate = (mau: MonthlyActiveUser, granularity: GranularityValue) => {
  const mauDate = startOfDay(new Date(mau.year, mau.month - 1))

  if (granularity === GranularityValue.Week) {
    // If it's the first month and the week number is greater than 1, adjust the date to the first completed week
    if (getMonth(mauDate) === 0 && getISOWeek(mauDate) > 1) {
      return startOfWeek(addWeeks(mauDate, 1))
    } else {
      return startOfWeek(mauDate)
    }
  }

  return mauDate
}
