import { DefaultTFuncReturn } from 'i18next'
import { FC, useEffect, useState } from 'react'

import { CloseRounded } from '@mui/icons-material'
import { Dialog, DialogTitle, Grid, Typography, IconButton, DialogContent } from '@mui/material'

import { GRCarousel } from '../GRCarousel/GRCarousel'
import styles from './ImageCarouselModal.module.scss'

type ImageCarouselModalProps = {
  open: boolean
  imageUrls: string[]
  onClose: () => void
  initialIndex?: number
  title?: string | DefaultTFuncReturn
}

export const ImageCarouselModal: FC<ImageCarouselModalProps> = ({ open, imageUrls = [], onClose, initialIndex, title }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex || 0)

  useEffect(() => {
    setCurrentIndex(initialIndex || 0)
  }, [initialIndex])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth closeAfterTransition keepMounted>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item sm={4} alignSelf="center">
            <Typography variant="h3">{title}</Typography>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <Typography>
              {currentIndex + 1}/{imageUrls.length}
            </Typography>
          </Grid>
          <Grid item sm={4} textAlign="right">
            <IconButton className="closeButton" onClick={onClose}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <GRCarousel
          previousButtonProps={{
            onClick: () => setCurrentIndex(currentIndex - 1),
            disabled: currentIndex <= 0,
          }}
          nextButtonProps={{
            onClick: () => setCurrentIndex(currentIndex + 1),
            disabled: currentIndex >= imageUrls.length - 1,
          }}
        >
          <img src={imageUrls[currentIndex]} className={styles.image} alt="Screenshot" />
        </GRCarousel>
      </DialogContent>
    </Dialog>
  )
}
