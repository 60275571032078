import { useGetCompareMultiGamesQuery } from '../../../api/core'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'

type CompareMultiGamesHookParams = {
  gameIds: string[]
  marketIsos: string[]
}
export const useCompareMultiGames = ({ gameIds, marketIsos }: CompareMultiGamesHookParams) => {
  const userLanguage = useCurrentUserLanguage()
  return useGetCompareMultiGamesQuery(
    {
      games: gameIds,
      marketIso: marketIsos,
      userLanguage,
    },
    { skip: !marketIsos.length || !gameIds.length }
  )
}
