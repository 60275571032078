import { useSelector } from 'react-redux'

import { useMediaQuery, useTheme } from '@mui/material'

import { useUpdateUserSettingMutation } from '../api/combined'
import { selectFullscreenMode } from '../features/account/selectors/selectors'
import { UserSettingKeys } from '../features/account/types/UserSettings'

/**
 * Custom hook for gathering fullscreen mode related properties and actions
 */
export const fullscreenBreakpoint = 1600

export const useFullscreenMode = (): [boolean, (value: boolean) => void, boolean] => {
  const theme = useTheme()
  const isFullscreenAvailable = useMediaQuery(theme.breakpoints.up(fullscreenBreakpoint))
  const fullscreenMode = useSelector(selectFullscreenMode)
  const [updateFullscreenMode] = useUpdateUserSettingMutation()

  return [fullscreenMode, (value: boolean) => updateFullscreenMode({ settingKey: UserSettingKeys.fullscreenMode, value }), isFullscreenAvailable]
}
