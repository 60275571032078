import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { AccountCircle, ExitToApp } from '@mui/icons-material'
import { Button, IconButton, Popover, SvgIcon } from '@mui/material'

import { useGetUserProfileQuery } from '../../../../api/combined'
import { useAppSelector } from '../../../../hooks/storeHooks'
import keycloakService from '../../../../services/KeycloakService'
import PageService from '../../../../services/PageService'
import { Page } from '../../../../types/Page'
import { useUserCurrentUserInvites } from '../../hooks/organizationHooks'
import { useRoleCheck } from '../../hooks/roleHooks'
import { RoleEnum } from '../../types/RoleEnum'
import './AccountBadge.scss'

const AccountBadge: React.FC = () => {
  const { t } = useTranslation()

  const currentPage = useAppSelector((state) => {
    return state.currentPage as Page
  })
  const { data: currentUser } = useGetUserProfileQuery()
  const { userInvites } = useUserCurrentUserInvites()
  const slackRoleUnlocked = useRoleCheck(RoleEnum.slack)

  // List of views which is restricted by roles
  const restrictedViews: { [key: string]: boolean } = useMemo(() => ({ slack: slackRoleUnlocked }), [slackRoleUnlocked])

  const accountViews = PageService.pages
    .filter((page) => page.type === 'account')
    .filter((page) => {
      if (restrictedViews[page.id] === undefined) return true
      return restrictedViews[page.id]
    })

  const [loggingOut, setLoggingOut] = useState(false)

  const getCurrentUserName = useCallback(() => {
    if (currentUser) {
      if (currentUser.firstName && currentUser.lastName) {
        return `${currentUser.firstName} ${currentUser.lastName}`
      } else if (currentUser.firstName) {
        return `${currentUser.firstName}`
      } else {
        return `?`
      }
    }
  }, [currentUser])

  const logout = useCallback(() => {
    setLoggingOut(true)
    keycloakService.logout()
  }, [])

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const viewLinks = accountViews.map((view) => {
    if (userInvites && userInvites.length === 0 && view.id === 'invites') {
      return null
    }

    return (
      <div key={view.id} className="AccountBadge__popover__content__link">
        <Link to={view.href}>
          <Button
            variant="text"
            color={currentPage.id === view.id ? 'primary' : 'secondary'}
            startIcon={
              view.iconComponent ? <SvgIcon component={view.iconComponent} /> : <img className="AccountBadge__icon" src={view.iconUrl} alt={view.id} />
            }
            onClick={handleClose}
          >
            {t(view.localizationKey)}
          </Button>
        </Link>
      </div>
    )
  })

  return (
    <div className="AccountBadge">
      <IconButton onClick={handleClick}>
        <AccountCircle color="secondary" style={{ fontSize: '45px' }} />
      </IconButton>
      <Popover
        id="AccountBadgePopover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="AccountBadge__popover">
          <div className="AccountBadge__popover__header">
            <div className="AccountBadge__popover__user-name">{getCurrentUserName()}</div>
            <div className="AccountBadge__popover__organization-name">{currentUser?.organization.name}</div>
          </div>
          <div className="AccountBadge__popover__content">{viewLinks}</div>
          <div className="AccountBadge__popover__footer">
            <Button variant="contained" size="small" color="secondary" disabled={loggingOut} startIcon={<ExitToApp />} onClick={logout}>
              {t('common:logout')}
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default AccountBadge
