import classNames from 'classnames'

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Box, BoxProps, Typography } from '@mui/material'

import './EffectImpactDiffText.scss'

/**
 * EffectImpactDiffText Show Text with direction arrow and different color for positive and negative impact
 */
interface EffectImpactDiffTextProps extends BoxProps {
  value: number
  showArrow?: boolean
}
const EffectImpactDiffText: React.FC<EffectImpactDiffTextProps> = ({ value, showArrow, ...boxProps }) => {
  if (value === 0) return null
  const selectedIcon = value > 0 ? <ArrowDropUp className="positive" /> : <ArrowDropDown className="negative" />

  return (
    <Box {...boxProps} className="EffectImpactDiffText">
      {showArrow && selectedIcon}
      <Typography className={classNames(value > 0 ? 'positive' : 'negative')}>{value}</Typography>
    </Box>
  )
}

export default EffectImpactDiffText
