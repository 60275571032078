export type PerformanceEffect = {
  type: PerformanceEffectType
  startTime: number
  endTime: number
  value: number
}

export enum PerformanceEffectType {
  Revenue = 'Revenue',
  Download = 'Download',
  DAU = 'DAU',
  MAU = 'MAU',
}

export type PerformanceEffectsByGame = {
  performanceEffects: { [gameId: string]: PerformanceEffect[] | undefined }
}
