import { FC } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { CardMedia } from '@mui/material'

import MultiSlider, { MultiSliderProps } from '../../../../components/MultiSlider/MultiSlider'

type ReportCoverSliderProps = Omit<MultiSliderProps, 'children'> & {
  coverUrls: string[]
}

export const ReportCoverSlider: FC<ReportCoverSliderProps> = ({ coverUrls, ...multiSlideProps }) => {
  return (
    <>
      {coverUrls.length === 1 && <CardMedia component="img" image={coverUrls[0]} />}

      {coverUrls.length > 1 && (
        <MultiSlider {...multiSlideProps} floatingButtons browseAutomatically>
          {coverUrls.map((url, i) => (
            <LazyLoadImage key={i} alt="Cover image" src={url} effect="opacity" />
          ))}
        </MultiSlider>
      )}
    </>
  )
}
