import React from 'react'

import { Box, TableCellProps } from '@mui/material'

import { DemographicsColor } from '../../types/DemographicsColor'
import './DemographicsTableBars.scss'

/** Use these in the GRTable particular column; Both in cellProps and headerCellProps sx */
export const demographicsHeaderCellProps: TableCellProps = {
  sx: {
    maxWidth: '110px !important',
    minWidth: '110px !important',
    width: '110px !important',
  },
}

export const demographicsCellProps: TableCellProps = {
  sx: {
    padding: '0px !important',
    position: 'relative',
  },
}

interface Props {
  colors: DemographicsColor[]
  widths: (number | undefined)[]
}

type Bar = { backgroundColor?: string; width: string }

/**
 * We assume, that all nested <th> elements are equal widths?
 * Does the total width calculating automatically.
 *
 * @colors the color overrides of the bars, make sure it has as many elements as the widths param
 * @widths the relative widths of the bars. Can be anything, like 0.01 or 9195185
 */
export const DemographicsTableBars: React.FC<Props> = ({ colors, widths }) => {
  const columns = widths.length
  const total: number = widths.reduce((acc, value) => (acc || 0) + (value || 0), 0 as number) || 0

  const bars: Bar[] = widths.map((width, index) => ({
    backgroundColor: colors ? colors[index] : undefined,
    width: ((width || 0) / total) * 100 + '%',
  }))

  return (
    <Box className="DemographicsTableBars" style={{ width: `${columns * 100}%` }}>
      {bars.map(({ backgroundColor, width }, index) => (
        <Box key={index} className={`DemographicsTableBar__${backgroundColor ? 0 : index + 1} `} sx={{ backgroundColor, width }} />
      ))}
    </Box>
  )
}
