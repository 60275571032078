import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Circle, Star } from '@mui/icons-material'
import { Box, Card, Checkbox, Divider, FormControlLabel, Grid, TableContainer, Typography } from '@mui/material'

import { Tag } from '../../../../api/core'
import { GREffectDots } from '../../../../components/GREffectDots/GREffectDots'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTableColumnSelector } from '../../../../components/GRTable/GRTableColumnSelector/GRTableColumnSelector'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { SearchInputWithDebounce } from '../../../../components/SearchInputWithDebounce/SearchInputWithDebounce'
import { ExportRow, useExportDataMarketExplorerFeatures } from '../../../../hooks/exportDataHooks'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { DemographicsColor } from '../../../demographics/types/DemographicsColor'
import { generateExport, generateExportMultipleSheets } from '../../../export-data/util/workbook'
import { FeatureLink } from '../../../feature/components/FeatureLink'
import { useFeatureTags } from '../../../feature/hooks/useFeatureTags'
import { FeatureDataRow, useFeatureData, useKeywordFilter, useTagFilter } from '../../hooks/featureDataHooks'
import { useMarketExplorerSegmentFilterGroups } from '../../hooks/marketExplorerHooks'
import { FeaturesDataTableColumn, FeaturesDataTableColumnType } from '../../types/MarketExplorerFeaturesDataTableColumn'
import { GamesDataTableColumnType } from '../../types/MarketExplorerGamesDataTableColumns'
import { DemographicsFilterField, MarketExplorerSegmentConfiguration } from '../../types/MarketExplorerSegmentConfiguration'
import { Popularity } from '../../types/MarketExplorerSegmentData'
import { MotivationType } from '../../types/MotivationType'
import {
  archetypeColumn,
  columnWidth,
  demographicColumns,
  differenceColumn,
  dotColumn,
  motivationColumn,
  percentageColumn,
} from '../../util/FeatureDataColumns'
import { ExportSegmentsButton } from '../ExportSegmentsButton/ExportSegmentsButton'
import { SegmentQueryDataType } from '../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { SegmentsQueryReturnType } from '../MarketExplorerSegments/MarketExplorerSegments'
import { FeatureGroupDialog } from './FeatureGroupDialog/FeatureGroupDialog'
import { FeatureGroupSelector } from './FeatureGroupSelector/FeatureGroupSelector'
import './MarketExplorerFeaturesData.scss'
import { SegmentColorIllustrators } from './SegmentColorIllustrators'
import { SegmentSortButton } from './SegmentSortButton'
import { TopPercentOfGamesDialog } from './TopPercentOfGamesDialog/TopPercentOfGamesDialog'

const defaultSortColumn = 'difference20Percent'

interface Props {
  segments: SegmentQueryDataType[]
  segmentConfiguration?: MarketExplorerSegmentConfiguration[]
  selectedColumns: FeaturesDataTableColumnType[]
  onSelectedColumnsChange?: (columnIds: FeaturesDataTableColumnType[]) => void
  selectedGamesDataColumns: GamesDataTableColumnType[]
  onSelectedGamesDataColumnsChange: (columnIds: GamesDataTableColumnType[]) => void
  selectedFeatureTags?: Tag[]
  onSelectedFeatureTagsChange?: (tags: Tag[]) => void
  hideFilterFeatures?: boolean
  hideShowOnlyDifferentFeatures?: boolean
  hideSortBySegments?: boolean
  maxRows?: number
  isLoading?: boolean
  onSegmentsChange?: (segments: MarketExplorerSegmentConfiguration[]) => void
  onSegmentsQueryDataChange?: (segmentsData: SegmentsQueryReturnType) => void
}

type SortSegment = number
type ColumnName = keyof FeatureDataRow

export type GameModalParams = {
  featureData: FeatureDataRow
  popularity: keyof Popularity
  percentValue: number
}

export type Column = GRTableColumn<FeatureDataRow, any, FeaturesDataTableColumnType> & {
  realSortAccessor?: string
  columns?: Column[]
}

export type SortBy = [SortSegment, SortOrder, ColumnName]

const MarketExplorerFeaturesData: React.FC<Props> = ({
  segments,
  segmentConfiguration,
  selectedColumns,
  onSelectedColumnsChange,
  selectedGamesDataColumns,
  onSelectedGamesDataColumnsChange,
  selectedFeatureTags = [],
  onSelectedFeatureTagsChange,
  hideFilterFeatures,
  hideShowOnlyDifferentFeatures,
  hideSortBySegments: hideShortBySegments,
  maxRows,
  isLoading: isRowsLoading,
  onSegmentsChange,
  onSegmentsQueryDataChange,
}) => {
  const { t } = useTranslation()
  const featureTags = useFeatureTags()
  const { marketExplorerSegmentMotivationTypes, marketExplorerSegmentPlayerArchetypesFilterGroups } = useMarketExplorerSegmentFilterGroups()
  const containerRef = useRef(null)
  const motivationsEnabled = useRoleCheck(RoleEnum.motivations)

  const [isFeatureGroupModalOpen, setFeatureGroupModalOpen] = useState(false)
  const [showDifferentiatingOnly, setShowDifferentiatingOnly] = useState(false)
  const [sortBy, setSortBy] = useState<SortBy>([0, SortOrder.DESC, defaultSortColumn])
  const [searchText, setSearchText] = useState<string>()
  const [gameModalParams, setGameModalParams] = useState<GameModalParams>()
  const [singleSegmentIndex, setSingleSegmentIndex] = useState<number | undefined>(undefined)

  const handleCloseFeatureGroupModal = (tags?: Tag[]) => {
    setFeatureGroupModalOpen(false)
    if (tags && onSelectedFeatureTagsChange) {
      onSelectedFeatureTagsChange(tags)
    }
  }

  const handleOpenFeatureGroupModal = () => {
    setFeatureGroupModalOpen(true)
  }

  // Change array of colors into a more convenient map format
  const colors = useMemo(
    () =>
      featureTags?.data?.reduce((acc, { colorHex, tags }) => {
        tags?.forEach(({ id, name }) => {
          acc[id] = { colorHex, name }
        })
        return acc
      }, {} as { [tagId: string]: { colorHex: string; name: string } }),
    [featureTags]
  )

  const handleToggleDifferentiating: React.MouseEventHandler = (event) => {
    setShowDifferentiatingOnly(!showDifferentiatingOnly)
    event.preventDefault()
    event.stopPropagation()
  }

  const handleOpenGameModal = (featureData: FeatureDataRow, popularity: keyof Popularity, value: number) => {
    setGameModalParams({
      featureData,
      popularity,
      percentValue: value,
    })
  }

  const handleCloseGameModal = () => {
    setGameModalParams(undefined)
  }

  const revenueImpactTooltipText = useMemo(() => {
    return (
      <GRTooltip
        content={
          <Box>
            {t('common:tooltip_feature_effect_games_in_segment')}
            <Divider sx={{ mt: 1.5, mb: 1 }} />
            <Grid container>
              <Grid item sm={6}>
                <Typography>{t('feature:impact_low')}</Typography>
                <GREffectDots size="small" effect={0.1} />
              </Grid>
              <Grid item sm={6}>
                <Typography>{t('feature:impact_high')}</Typography>
                <GREffectDots size="small" effect={2.5} />
              </Grid>
            </Grid>
          </Box>
        }
      >
        {t('feature:revenue_impact')}
      </GRTooltip>
    )
  }, [t])

  const columnsConfig: GRTableColumn<FeatureDataRow, typeof customProps, FeaturesDataTableColumnType>[] = useMemo(() => {
    return [
      {
        headerCellProps: columnWidth(253, 'left'),
        labelAccessor: ({ customTableProps, columns }) => (
          <>
            {!!onSelectedColumnsChange && (
              <GRTableColumnSelector
                columns={columns}
                selectedColumns={customTableProps?.selectedColumns}
                onConfirm={customTableProps?.handleSelectedColumnsChange}
              />
            )}
          </>
        ),
        sticky: true,
        columns: [
          {
            accessor: ({ row }) => (
              <FeatureLink
                feature={{ featureName: row.featureLabel, featureLegacyId: row.featureLegacyId }}
                choice={{ name: row.choiceLabel, choiceLegacyId: row.choiceLegacyId }}
              >
                <Box className="FeatureLabel">
                  <SegmentColorIllustrators tags={row.tags} colors={colors} />
                  {row.segmentGroup - 1 === row.firstSegmentIndex && (
                    <>
                      <Typography display="inline">{row.featureLabel}</Typography>
                      <Typography display="inline" variant="body2" color="primary">
                        {' ' + row.choiceLabel}
                      </Typography>
                    </>
                  )}
                </Box>
              </FeatureLink>
            ),
            labelAccessor: () => t('common:feature'),
            sortable: true,
            sortAccessor: ({ row }) => row.featureLabel || '',
            realSortAccessor: 'featureLabel',
            headerCellProps: { align: 'left', sx: { minWidth: 420 } },
            cellProps: { sx: { borderBottom: '0px solid #FFF' } },
          },
        ],
      },
      {
        columns: [
          {
            accessor: ({ row }) =>
              row.isDifferentiating ? (
                <GRTooltip content={t('market-explorer:differentiating_feature_in_segment_tooltip')}>
                  <Star className={`SegmentGroupIcon-${row.segmentGroup}`} />
                </GRTooltip>
              ) : (
                <Circle className={`InDifferentiating SegmentGroupIcon-${row.segmentGroup}`} />
              ),
            labelAccessor: () => <span />,
            headerCellProps: columnWidth(34),
          },
        ],
      },
      {
        id: FeaturesDataTableColumn.ImpactOverall,
        labelAccessor: t('common:market_explorer_feature_group_1'),
        columns: [
          dotColumn(revenueImpactTooltipText, 'avgEffect'),
          percentageColumn(
            <GRTooltip content={t('common:tooltip_share_of_games_with_feature')}>{t('common:overall_popularity')}</GRTooltip>,
            'Overall',
            handleOpenGameModal
          ),
        ],
      },
      {
        id: FeaturesDataTableColumn.Top20PercentPopularity,
        labelAccessor: t('common:market_explorer_feature_group_2'),
        columns: [
          percentageColumn(
            <GRTooltip content={t('common:tooltip_top_20_percent_segment_games_with_feature')}>{t('common:top_20_popularity_percent')}</GRTooltip>,
            '20Percent',
            handleOpenGameModal
          ),
          {
            ...differenceColumn(
              <GRTooltip content={t('common:popularity_difference_top20_tooltip')}>{t('common:top_20_popularity_difference')}</GRTooltip>,
              '20Percent'
            ),
            sortOrder: SortOrder.DESC,
          },
        ],
      },
      {
        id: FeaturesDataTableColumn.Top50PercentPopularity,
        labelAccessor: t('common:market_explorer_feature_group_4'),
        columns: [
          percentageColumn(
            <GRTooltip content={t('common:tooltip_top_50_percent_segment_games_with_feature')}>{t('common:top_50_popularity_percent')}</GRTooltip>,
            '50Percent',
            handleOpenGameModal
          ),
          differenceColumn(
            <GRTooltip content={t('common:popularity_difference_top50_tooltip')}>{t('common:top_50_popularity_difference')}</GRTooltip>,
            '50Percent'
          ),
        ],
      },
      // NOTE: Ask Kalle how to fix this
      {
        id: FeaturesDataTableColumn.GenderAppeal,
        labelAccessor: t('common:market_explorer_feature_group_gender'),
        columns: demographicColumns(
          [
            <GRTooltip content={t('common:demographics_male_proportion_feature_market')}>{t('common:male')}</GRTooltip>,
            <GRTooltip content={t('common:demographics_female_proportion_feature_market')}>{t('common:female')}</GRTooltip>,
          ],
          [DemographicsFilterField.DemographicsMale, DemographicsFilterField.DemographicsFemale],
          [DemographicsColor.Male, DemographicsColor.Female]
        ),
      },
      {
        id: FeaturesDataTableColumn.AgeAppeal,
        labelAccessor: t('common:market_explorer_feature_group_age'),
        columns: demographicColumns(
          [
            <GRTooltip content={t('common:demographics_age16_24_proportion_feature_market')}>{t('common:age16_24')}</GRTooltip>,
            <GRTooltip content={t('common:demographics_age25_44_proportion_feature_market')}>{t('common:age25_44')}</GRTooltip>,
            <GRTooltip content={t('common:demographics_age45_proportion_feature_market')}>{t('common:age45_plus')}</GRTooltip>,
          ],
          [DemographicsFilterField.DemographicsAge16_24, DemographicsFilterField.DemographicsAge25_44, DemographicsFilterField.DemographicsAge45],
          [DemographicsColor.Age16_24, DemographicsColor.Age25_44, DemographicsColor.Age45_plus]
        ),
      },
      ...marketExplorerSegmentMotivationTypes.map(({ label: labelAccessor, types }) => {
        return {
          id: types.reduce((acc, type) => type.id, undefined as unknown as MotivationType),
          labelAccessor,
          columns: types.map(({ id }) => id).map(motivationColumn),
        }
      }),
      ...marketExplorerSegmentPlayerArchetypesFilterGroups
        .map(({ filters }) => filters)
        .flat()
        .map(({ id, label: labelAccessor }) => ({
          id: id,
          labelAccessor,
          columns: [archetypeColumn(labelAccessor, id)],
        })),
    ]
  }, [colors, marketExplorerSegmentMotivationTypes, marketExplorerSegmentPlayerArchetypesFilterGroups, onSelectedColumnsChange, revenueImpactTooltipText, t])

  const { data: rows, isLoading: isFeatureDataLoading } = useFeatureData(sortBy, segments)
  const tagFilter = useTagFilter(selectedFeatureTags, showDifferentiatingOnly)
  const keywordFilter = useKeywordFilter(searchText)

  // Figure out the real sorting by checking if the column sort did change
  const handleColumnsUpdate = useCallback((cols: Column[]) => {
    let sortOrder = SortOrder.DESC
    let sortColumn: ColumnName = defaultSortColumn
    cols.forEach(({ columns }, i) =>
      columns?.forEach((subColumn, j) => {
        if (subColumn.sortOrder) {
          sortOrder = subColumn.sortOrder
          if ((subColumn as Column).realSortAccessor) {
            sortColumn = (subColumn as Column).realSortAccessor as ColumnName
          }
        }
      })
    )
    setSortBy((currentSortBy) => [currentSortBy[0], sortOrder, sortColumn])
    setColumns(cols)
  }, [])

  const getRowClass = useMemo(
    () =>
      ({ segmentGroup, firstSegmentIndex }: FeatureDataRow): string =>
        segmentGroup - 1 === firstSegmentIndex ? 'MainSegment' : '',
    []
  )

  const [columns, setColumns] = useState<GRTableColumn<FeatureDataRow, typeof customProps, FeaturesDataTableColumnType>[]>(columnsConfig)

  const handleSelectedColumnsChange = useCallback(
    (columnIds: FeaturesDataTableColumnType[]) => {
      if (!!onSelectedColumnsChange) {
        onSelectedColumnsChange(columnIds)
      }
    },
    [onSelectedColumnsChange]
  )

  const customProps = useMemo(() => {
    return { selectedColumns, handleSelectedColumnsChange, motivationsEnabled }
  }, [handleSelectedColumnsChange, motivationsEnabled, selectedColumns])

  // when segment sort is changed, change the column sort order to same direction
  useEffect(() => {
    setColumns((currentColumns) =>
      currentColumns.map((column) => {
        return {
          ...column,
          columns: column.columns?.map((subColumn) => {
            return {
              ...subColumn,
              sortOrder: subColumn.sortOrder ? sortBy[1] : subColumn.sortOrder,
            }
          }),
        }
      })
    )
  }, [sortBy])

  const filteredRows = useMemo(() => {
    return rows.filter(tagFilter).filter(keywordFilter)
  }, [keywordFilter, rows, tagFilter])

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)
  const [segmentConfigurations, setSegmentConfigurations] = useState<MarketExplorerSegmentConfiguration[]>([])
  const { segmentExportRows } = useExportDataMarketExplorerFeatures(segmentConfigurations, rows, isExporting, singleSegmentIndex)

  useEffect(() => {
    if (!isExporting || !segmentExportRows.length) return
    setIsExporting(false)
    switch (exportFormat) {
      case 'csv': {
        segmentExportRows.forEach((exportRows: ExportRow[], index: number) => {
          const sheetId =
            segmentConfigurations.length === 1
              ? `market-explorer-${singleSegmentIndex !== undefined ? singleSegmentIndex + 1 : ''}-${segmentConfigurations[index].marketIso}-features`
              : `market-explorer-${index + 1}-${segmentConfigurations[index].marketIso}-features`

          generateExport(exportFormat, exportRows, sheetId, sheetId)
        })
        break
      }
      case 'xlsx': {
        let sheetNames: string[] = []

        segmentConfigurations.forEach((segmentConfiguration, index) => {
          const sheetId =
            segmentConfigurations.length === 1
              ? `market-explorer-${singleSegmentIndex !== undefined ? singleSegmentIndex + 1 : ''}-${segmentConfiguration.marketIso}-features`
              : `market-explorer-${index + 1}-${segmentConfiguration.marketIso}-features`
          sheetNames.push(sheetId)
        })

        const allSegmentsSheetId = segmentConfigurations.length === 1 ? sheetNames[0] : 'market-explorer-allsegments-features'
        generateExportMultipleSheets(exportFormat, segmentExportRows, sheetNames, allSegmentsSheetId)
        break
      }
    }
  }, [exportFormat, isExporting, segmentConfigurations, segmentExportRows, t, singleSegmentIndex])

  const handleDataExport = (format: BookType, segmentConfigurations: MarketExplorerSegmentConfiguration[], singleSegmentIndex?: number) => {
    setExportFormat(format)
    setSegmentConfigurations(segmentConfigurations)
    singleSegmentIndex !== undefined ? setSingleSegmentIndex(singleSegmentIndex) : setSingleSegmentIndex(undefined)
    setIsExporting(true)
  }

  const isLoading = isFeatureDataLoading || featureTags.isLoading || featureTags.isFetching || isRowsLoading

  return (
    <Box className="MarketExplorerFeaturesData">
      <Grid container direction="row" className="SortAndSelection" spacing={2} mb={2}>
        {!!onSelectedFeatureTagsChange && (
          <Grid item flexShrink={1}>
            <FeatureGroupSelector featureTags={featureTags?.data} selectedTags={selectedFeatureTags} onOpenSelect={handleOpenFeatureGroupModal} />
          </Grid>
        )}
        {!hideShortBySegments && (
          <Grid item flexShrink={1}>
            <Typography display="inline" ml={2}>
              {t('market-explorer:sort_by_segment')}
            </Typography>
            <Grid container wrap="wrap" direction="row" spacing={1}>
              {segments?.map((segment, index) => (
                <Grid item key={index}>
                  <SegmentSortButton
                    sortOrder={sortBy[0] === index ? sortBy[1] : undefined}
                    onChangeSort={(direction) => {
                      setSortBy([index, direction, sortBy[2]])
                    }}
                    orderNumber={index + 1}
                    segment={segment}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Card className={`FeatureDataTable GroupedRows--${segments?.length}`}>
        <Grid container alignItems="center" justifyContent="space-between" wrap="wrap" spacing={2} p={2}>
          {!hideFilterFeatures && (
            <Grid item md={6} sm={12}>
              <SearchInputWithDebounce fieldText={t('common:filter_features')} onDebounce={setSearchText} />
            </Grid>
          )}
          {!hideShowOnlyDifferentFeatures && (
            <Grid item>
              <FormControlLabel
                control={<Checkbox size="small" checked={showDifferentiatingOnly} onClick={handleToggleDifferentiating} color="secondary" />}
                label={
                  <Grid container alignItems="center">
                    <GRTooltip content={t('market-explorer:show_differentitating_features_toggle_tooltip') || ''}>
                      <Typography display="inline-block" variant="body1" sx={{ verticalAlign: 'middle' }}>
                        {t('market-explorer:show_only_differentiating_features') + ' '}
                      </Typography>
                      <Star fontSize="small" sx={{ ml: 1, verticalAlign: 'text-bottom' }} />
                    </GRTooltip>
                  </Grid>
                }
              ></FormControlLabel>
            </Grid>
          )}
          {segmentConfiguration && (
            <Grid item sx={{ ml: 'auto' }}>
              <ExportSegmentsButton
                segments={segmentConfiguration}
                onExport={handleDataExport}
                accessRoles={[RoleEnum.csv_market_explorer_features, RoleEnum.csv_export_features]}
              />
            </Grid>
          )}
        </Grid>
        <Divider />
        <TableContainer component={Box} ref={containerRef}>
          <GRTable
            getRowClass={getRowClass}
            columns={columns}
            rows={filteredRows}
            onColumnsUpdated={handleColumnsUpdate}
            rowIdKey="uuid"
            scroller={containerRef}
            customProps={customProps}
            selectedColumns={selectedColumns}
            isLoading={isLoading}
            maxRows={maxRows}
          />
        </TableContainer>
      </Card>
      {gameModalParams && onSegmentsQueryDataChange && onSegmentsChange && (
        <TopPercentOfGamesDialog
          onClose={handleCloseGameModal}
          onSelectedColumnsChange={onSelectedGamesDataColumnsChange}
          selectedColumns={selectedGamesDataColumns}
          popularity={gameModalParams.popularity}
          percentValue={gameModalParams.percentValue}
          featureData={gameModalParams.featureData}
          segment={segments[gameModalParams.featureData.segmentGroup - 1]}
          segments={segments}
          onSegmentsQueryDataChange={onSegmentsQueryDataChange}
          onSegmentsChange={onSegmentsChange}
          onSegmentDelete={() => handleCloseGameModal()}
        />
      )}

      <FeatureGroupDialog
        open={isFeatureGroupModalOpen}
        onClose={handleCloseFeatureGroupModal}
        tagGroups={featureTags.data}
        selectedTags={selectedFeatureTags}
      />
    </Box>
  )
}

export default MarketExplorerFeaturesData
