import React, { useEffect, useMemo, useState } from 'react'

import { Close, Fullscreen, PauseCircle, PlayCircle, VolumeDown, VolumeOff, VolumeUp } from '@mui/icons-material'
import { Box, Card, CardContent, CardMedia, CircularProgress, ClickAwayListener, Divider, Grid, IconButton, Slide, Slider, Typography } from '@mui/material'
import Player from '@vimeo/player'

import { FtueVideo } from '../../../../api/core'
import analyticsService from '../../../../services/AnalyticsService'
import { AnalyticsProviders } from '../../../../types/IAnalyticsProvider'
import type { Game } from '../../../game'
import { Size, useDomSize } from '../../hooks/useDomSize'
import FtueVideoFullscreenModal from '../FtueVideoFullscreenModal/FtueVideoFullscreenModal'
import GameChapterList, { SelectedChapterTag } from '../GameChapterList/GameChapterList'
import './FtueVideoPlayer.scss'
import { formatSeconds } from './formatSeconds'

interface FtueVideoPlayerProps {
  game?: Game
  video: FtueVideo
  onUnselectGame?: () => void
  ftueUnlocked?: boolean
  selectedChapterTag?: SelectedChapterTag
  onChapterTagClick?: ({ chapterId, tagId }: SelectedChapterTag) => void
  hideCloseButton?: boolean
  hideChapterList?: boolean
  screenRatio?: number
}

const defaultScreenRatio = 1.5
const defaultVolume = 1.0

/* eslint-disable react-hooks/exhaustive-deps */
const FtueVideoPlayer: React.FC<FtueVideoPlayerProps> = ({
  game,
  onUnselectGame,
  video,
  ftueUnlocked,
  selectedChapterTag,
  onChapterTagClick,
  hideChapterList,
  hideCloseButton,
  screenRatio = defaultScreenRatio,
}) => {
  const [time, setTime] = useState<number>(0)
  const [playerSize, setPlayerSize] = useState<Size>()
  const [duration, setDuration] = useState<number>(0)
  const [volume, setVolume] = useState(defaultVolume)
  const [fullscreen, setFullscreen] = useState(false)
  const [isMuted, setMuted] = useState(false)
  const [isSeeking, setSeeking] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [showVolumeControl, setShowVolumeControl] = useState(false)
  const [hideVolumeControlDelayed, setHideVolumeControlDelayed] = useState(false)
  const [mediaContainer, setMediaContainer] = useState<HTMLDivElement | null>(null)
  const [playerElement, setPlayerElement] = useState<HTMLDivElement | null>(null)

  let closeVolumeControlDelay: ReturnType<typeof setTimeout>

  const [sentTrackEvent, setSentTrackEvent] = useState(false)
  useEffect(() => {
    if (game && !sentTrackEvent) {
      analyticsService.trackEvent('Viewed FTUE video', { data: { gameName: game.resolvedName }, serviceToExclude: [AnalyticsProviders.hubspot] })
      setSentTrackEvent(true)
    }
  }, [game, sentTrackEvent])

  const onPlay = () => {
    setIsPlaying(true)
  }

  const onPause = () => {
    setIsPlaying(false)
  }

  const onChangeVolume = ({ volume }: { volume: number }) => {
    setVolume(volume)
  }

  const onLoaded = async () => {
    const duration = await player?.getDuration()
    setDuration(duration || 0)
  }

  useDomSize(mediaContainer, screenRatio, setPlayerSize)

  const player = useMemo(() => {
    if (playerElement && playerSize) {
      const id = parseInt(video.id, 10)
      const options = { controls: false, id, ...playerSize }
      const player = new Player(playerElement, options)

      player.on('loaded', onLoaded)
      player.on('play', onPlay)
      player.on('pause', onPause)
      player.on('timeupdate', (playerTime) => setTime(playerTime.seconds))
      player.on('volumechange', onChangeVolume)
      player.on('seeking', () => setSeeking(true))
      player.on('seeked', () => setSeeking(false))
      player.setVolume(1)

      return player
    }
  }, [playerElement?.id, playerSize])

  const setPosition = (seconds: number, startPlay?: boolean) => {
    if (!player || !duration) {
      return
    }
    player.setCurrentTime(seconds)
    startPlay && player.play()
    setSeeking(true)
  }

  const handleShowVolumeClosing = () => {
    setShowVolumeControl(false)
  }

  useEffect(() => {
    closeVolumeControlDelay && clearTimeout(closeVolumeControlDelay)
    if (hideVolumeControlDelayed) {
      closeVolumeControlDelay = setTimeout(() => setShowVolumeControl(false), 500)
    }
    return () => {
      clearTimeout(closeVolumeControlDelay)
    }
  }, [hideVolumeControlDelayed])

  useEffect(() => setHideVolumeControlDelayed(false), [showVolumeControl])

  const handleToggleVolume = () => {
    if (!player) {
      return
    }
    const playerMuted = isMuted
    setMuted(!playerMuted)
    player.setMuted(!playerMuted)
    if (!playerMuted) {
      player.setVolume(0)
    }
    if (playerMuted) {
      player.setVolume(1)
      setShowVolumeControl(true)
    }
  }

  const onClose = () => {
    player?.destroy()
    if (onUnselectGame) {
      onUnselectGame()
    }
  }

  return (
    <Box className="ftueContainer">
      <Card className="ftueVideoPlayer">
        <CardMedia className="ftueMedia" ref={setMediaContainer}>
          {video && <div className="video" ref={setPlayerElement} style={playerSize || {}} data-vimeo-id={video.vimeoId} id={`video-${video.vimeoId}`} />}
          <ClickAwayListener onClickAway={handleShowVolumeClosing}>
            <Slide direction="up" in={showVolumeControl} container={mediaContainer}>
              <Box className="volumeControl" onMouseEnter={() => setHideVolumeControlDelayed(false)} onMouseLeave={() => setHideVolumeControlDelayed(true)}>
                <Typography>{isMuted ? 0 : Math.round(volume * 100)}%</Typography>
                <Slider
                  max={1.0}
                  step={0.01}
                  value={isMuted ? 0 : volume}
                  orientation="vertical"
                  onChange={(_, value) => {
                    setMuted(value === 0)
                    setVolume(value as number)
                  }}
                  onChangeCommitted={(_, value) => {
                    player?.setVolume(value as number)
                  }}
                  sx={{ height: 128 }}
                />
              </Box>
            </Slide>
          </ClickAwayListener>
        </CardMedia>
        <CardContent className="videoControls">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={1}>
              {isSeeking ? (
                <CircularProgress thickness={10} size={30} sx={{ m: '11px' }} />
              ) : isPlaying ? (
                <IconButton size="medium" className="playButton" onClick={() => player?.pause()} color="primary">
                  <PauseCircle />
                </IconButton>
              ) : (
                <IconButton size="medium" className="playButton" onClick={() => player?.play()} color="primary" disabled={!duration}>
                  <PlayCircle />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={7}>
              <Slider
                disabled={!duration}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => formatSeconds(value)}
                max={duration}
                value={time}
                onChange={(_, value) => setTime(value as number)}
                onChangeCommitted={(_, value) => setPosition(value as number)}
              />
            </Grid>
            <Grid item xs={3} textAlign="right">
              <Typography component="span" className="time">
                {formatSeconds(time)}
              </Typography>
              <IconButton
                size="small"
                color="primary"
                disabled={!duration}
                onClick={handleToggleVolume}
                onMouseEnter={() => setShowVolumeControl(true)}
                onMouseLeave={() => setHideVolumeControlDelayed(true)}
              >
                {isMuted ? <VolumeOff /> : volume < 0.5 ? <VolumeDown /> : <VolumeUp />}
              </IconButton>
              <IconButton disabled={!duration} size="small" color="primary" onClick={() => setFullscreen(true)}>
                <Fullscreen />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <GameChapterList
          selectedChapterTag={selectedChapterTag}
          onChapterTagClick={onChapterTagClick}
          game={game}
          video={video}
          setPosition={setPosition}
          currentPosition={time}
        />
        {!hideChapterList && (
          <>
            <Divider />
            <GameChapterList game={game} video={video} setPosition={setPosition} currentPosition={time} />
          </>
        )}
      </Card>
      {!hideCloseButton && (
        <IconButton className="remove" size="small" onClick={onClose}>
          <Close />
        </IconButton>
      )}
      {fullscreen && (
        <FtueVideoFullscreenModal
          game={game}
          video={video}
          onClose={() => setFullscreen(false)}
          position={time}
          autoPlay={isPlaying}
          volume={volume}
          onVolumeChange={setVolume}
          onTimeChange={(currentTime) => {
            setTime(currentTime)
            player?.setCurrentTime(currentTime)
          }}
          isMuted={isMuted}
          handleVolumeMuted={setMuted}
          selectedChapterTag={selectedChapterTag}
          onChapterTagClick={onChapterTagClick}
        />
      )}
    </Box>
  )
}

export default FtueVideoPlayer
