import { PlayerArchetypesFilterField } from './MarketExplorerSegmentConfiguration'
import { MotivationType } from './MotivationType'

export enum FeaturesDataTableColumn {
  ImpactOverall = 'ImpactOverall',
  Top20PercentPopularity = 'Top20PercentPopularity',
  Top50PercentPopularity = 'Top50PercentPopularity',
  GenderAppeal = 'GenderAppeal',
  AgeAppeal = 'AgeAppeal',
}

export type FeaturesDataTableColumnType = FeaturesDataTableColumn | PlayerArchetypesFilterField | MotivationType

export const FeaturesDataTableColumnValues = { ...FeaturesDataTableColumn, ...PlayerArchetypesFilterField, ...MotivationType }
