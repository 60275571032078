import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '.'
import { Report } from './../features/reports/types/Report'

const REDUCER_PATH = 'reportsApi'

type GetReportQueryParams = {
  id: string
}

export const reportsApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_CORE,
    prepareHeaders: prepareDefaultHeaders,
  }),
  tagTypes: ['Reports', 'Report'],
  endpoints: (builder) => ({
    getReports: builder.query<Report[], void>({
      query: () => ({ url: 'reports' }),
    }),
    getReportsWithIds: builder.query<Report[], { reportIds: string[] }>({
      query: ({ reportIds }) => ({
        url: '/reports',
        method: 'POST',
        body: { reportIds },
      }),
    }),
    getReport: builder.query<Report, GetReportQueryParams>({
      query: ({ id = [] }) => ({ url: `reports/${id}` }),
    }),
    getReportFile: builder.mutation({
      queryFn: async ({ id }, api, extraOptions, baseQuery) => {
        let filename = ''
        const result = await baseQuery({
          url: `reports/${id}/file`,
          method: 'GET',
          responseHandler: (response) => {
            const headers = response.headers
            filename = headers.get('Name') ? (headers.get('Name') as string) : ''
            return response.blob()
          },
        })

        const hiddenElement = document.createElement('a')
        const url = window.URL || window.webkitURL
        const blobPDF = url.createObjectURL(result.data as Blob)
        hiddenElement.href = blobPDF
        hiddenElement.target = '_blank'
        hiddenElement.download = filename ? filename : `${id}_report.pdf`
        hiddenElement.click()

        return { data: null }
      },
    }),
  }),
})

export const { useGetReportsQuery, useGetReportsWithIdsQuery, useGetReportQuery, useGetReportFileMutation } = reportsApi
