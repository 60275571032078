import { ReactNode, FC } from 'react'

import ErrorIcon from '@mui/icons-material/Error'
import { Card, CardHeader, CardContent, Grid, Button, Typography, Divider } from '@mui/material'

import { CounterButton } from '../../../../../components/CounterButton/CounterButton'
import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'

/**
 * Component for rendering optoinal segment filter card in segment edit dialog
 */
type OptionalSegmentFilterCardProps = {
  title: string
  description?: string
  selectButtonLabel: string
  onSelectClick: () => void
  clearButtonLabel: string
  onClear: () => void
  selectionCount?: number
  disabled?: boolean
  disabledTooltip?: ReactNode
  isLoading?: boolean
  children?: ReactNode
}

export const OptionalSegmentFilterCard: FC<OptionalSegmentFilterCardProps> = ({
  title,
  description,
  selectButtonLabel,
  onSelectClick,
  clearButtonLabel,
  onClear,
  selectionCount,
  disabled,
  disabledTooltip,
  isLoading,
  children,
}) => {
  return (
    <Card>
      <CardHeader title={title}></CardHeader>
      <CardContent>
        {isLoading ? (
          <GRCircularProgress />
        ) : (
          <>
            <Grid container gap={2} wrap="wrap">
              {description && (
                <Grid item xs={12}>
                  <Typography variant="body1">{description}</Typography>
                </Grid>
              )}
              <GRTooltip
                hidden={!disabled}
                content={
                  <Grid container gap={2} alignItems="center">
                    <ErrorIcon color="warning" />
                    {disabledTooltip}
                  </Grid>
                }
              >
                <CounterButton label={selectButtonLabel} count={selectionCount} onClick={onSelectClick} disabled={disabled} />
              </GRTooltip>
              {!!selectionCount && selectionCount > 0 && (
                <Button variant="contained" color="warning" onClick={onClear}>
                  {clearButtonLabel}
                </Button>
              )}
            </Grid>

            {Boolean(children) && (
              <>
                <Divider sx={{ my: 2 }} />
                {children}
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  )
}
