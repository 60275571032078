import { useTranslation } from 'react-i18next'

import { BarChart, Hardware, Timelapse, Timeline } from '@mui/icons-material'

export enum EventStatisticsDialogTab {
  Statistics = 'statistics',
  Implementations = 'implementations',
  Performance = 'performance',
  Durations = 'durations',
}

export const useEventStatisticsDialogTabs = () => {
  const { t } = useTranslation()

  return [
    {
      id: EventStatisticsDialogTab.Statistics,
      label: t('live-events:event_statistics_dialog_tab_statistics'),
      icon: <BarChart />,
    },
    {
      id: EventStatisticsDialogTab.Performance,
      label: t('live-events:event_statistics_dialog_tab_performance'),
      icon: <Timeline />,
    },
    {
      id: EventStatisticsDialogTab.Durations,
      label: t('live-events:event_statistics_dialog_tab_durations'),
      icon: <Timelapse />,
    },
    {
      id: EventStatisticsDialogTab.Implementations,
      label: t('live-events:event_statistics_dialog_tab_implementations'),
      icon: <Hardware />,
    },
  ]
}
