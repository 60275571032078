import { FC, ForwardedRef, forwardRef, useCallback, useEffect } from 'react'
import { useRef } from 'react'
import { ReactCalendarGroupRendererProps } from 'react-calendar-timeline'

import { Box, Grid, Link } from '@mui/material'

import { CollapseChevron } from '../../../../../../components/CollapseChevron/CollapseChevron'
import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import { useAppDispatch, useAppSelector } from '../../../../../../hooks/storeHooks'
import { GameIcon } from '../../../../../game'
import GameIconWithCount from '../../../../../game/components/GameIcon/GameIconWithCount'
import { selectVisibleEventTypeGroups, toggleEventTypeGroupVisibility } from '../../../../slices/liveEventsCalendarsSlice'
import { LiveEventTagGroup } from '../../../../types/LiveEventTagGroup'
import { TrackingEventWithGameTimelineGroup } from '../../../../types/LiveEvents'
import { EventTagGroupIndicator } from '../../../EventTagGroupIndicator/EventTagGroupIndicator'

type LiveEventsCalendarTrackingEventWithGameGroupProps = ReactCalendarGroupRendererProps<TrackingEventWithGameTimelineGroup> & {
  onClick: (group: TrackingEventWithGameTimelineGroup) => void
}

export const LiveEventsCalendarTrackingEventWithGameGroup: FC<LiveEventsCalendarTrackingEventWithGameGroupProps> = forwardRef<
  HTMLDivElement,
  LiveEventsCalendarTrackingEventWithGameGroupProps
>(({ group, onClick }, ref) => {
  const rootRef = useForwardRef<HTMLDivElement>(ref)
  const dispatch = useAppDispatch()
  const visibleGroups = useAppSelector(selectVisibleEventTypeGroups)
  const groupIsVisible = visibleGroups?.includes(group.trackingEventType)

  useEffect(() => {
    if (rootRef.current) {
      rootRef.current.parentElement?.classList.add(group.groupIndex % 2 === 0 ? 'group-even' : 'group-odd')
      group.firstOfGroup && rootRef.current.parentElement?.classList.add('first-of-group')
      group.lastOfGroup && rootRef.current.parentElement?.classList.add('last-of-group')
    }
  })

  const handleGroupVisibilityToggle = useCallback(() => {
    dispatch(toggleEventTypeGroupVisibility(group.trackingEventType))
  }, [dispatch, group])

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent={group.firstOfGroup ? 'space-between' : 'flex-end'}
      ref={rootRef}
      style={{ position: 'relative' }}
      sx={{ padding: '3px 0', height: 'inherit' }}
      id={(group.trackedGame.game.id + '-' + group.id) as string}
    >
      <Grid item flexShrink={1} flexGrow={0}>
        <EventTagGroupIndicator colorHex={group.colorHex} sx={{ left: '-8px' }} />
      </Grid>
      <Grid item flexShrink={1} flexGrow={0} display="inline-flex">
        <CollapseChevron
          sx={{ visibility: group.firstOfGroup ? 'visible' : 'hidden', '&:hover': { cursor: 'pointer' } }}
          collapsed={!groupIsVisible}
          onToggle={handleGroupVisibilityToggle}
        />
      </Grid>
      <Grid item flexShrink={0} flexGrow={1} display="inline-flex">
        {group.tagGroupId !== LiveEventTagGroup.DEFINING_OCCURRENCES ? (
          <Link
            underline="none"
            sx={{
              color: (theme) => theme.palette.text.primary,
              visibility: group.firstOfGroup ? 'visible' : 'hidden',
              '&:hover': { color: (theme) => theme.palette.primary.main, cursor: 'pointer' },
              height: 'auto',
              lineHeight: '25px',
            }}
            onClick={() => onClick(group as TrackingEventWithGameTimelineGroup)}
          >
            {group.title}
          </Link>
        ) : (
          <Box
            sx={{
              visibility: group.firstOfGroup ? 'visible' : 'hidden',
              height: 'auto',
              lineHeight: '25px',
            }}
          >
            {group.title}
          </Box>
        )}
      </Grid>
      <Grid item flexShrink={1} flexGrow={0} display="inline-flex">
        <GroupGameIcons group={group} />
      </Grid>
    </Grid>
  )
})

type GroupGameIconsProps = {
  group: TrackingEventWithGameTimelineGroup
}

const GroupGameIcons: FC<GroupGameIconsProps> = ({ group }) => {
  const visibleGroups = useAppSelector(selectVisibleEventTypeGroups)
  const groupIsVisible = visibleGroups?.includes(group.trackingEventType)
  const showGameCountIcon = !groupIsVisible && group.firstOfGroup
  if (showGameCountIcon) {
    return (
      <GRTooltip
        content={
          <Grid container columnSpacing={1} wrap="wrap">
            {group.trackedGamesOfEventType?.map((trackedGame) => (
              <Grid item key={trackedGame.game.id}>
                <GameIcon gameName={trackedGame.game.resolvedName} src={trackedGame.game.getIcon()} size="tiny" />
              </Grid>
            ))}
          </Grid>
        }
      >
        <GameIconWithCount count={group.trackedGamesOfEventType?.length || 0} size="xtiny" />
      </GRTooltip>
    )
  } else if (group.trackedGame) {
    return (
      <GameIcon gameName={group.trackedGame.game.resolvedName} src={group.trackedGame.game.getIcon()} size="xtiny" rootStyle={{ display: 'inline-flex' }} />
    )
  } else {
    return null
  }
}

const useForwardRef = <T,>(ref: ForwardedRef<T>, initialValue: any = null) => {
  const targetRef = useRef<T>(initialValue)

  useEffect(() => {
    if (!ref) return

    if (typeof ref === 'function') {
      ref(targetRef.current)
    } else {
      ref.current = targetRef.current
    }
  }, [ref])

  return targetRef
}
