import { FC, ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Chip, Dialog, DialogContent, DialogProps, Grid, Skeleton, Typography } from '@mui/material'

import { Analyst } from '../../../../components/Analyst/Analyst'
import GRDialogTitle, { GRDialogCloseButton } from '../../../../components/GRDialogTitle/GRDialogTitle'
import { HtmlContentParser } from '../../../../components/HtmlContentParser/HtmlContentParser'
import { liveEventReviewModalParserOptions } from '../../../../components/LiveEventModalLink/LiveEventModalLink'
import { SkeletonBlockOfText } from '../../../../components/SkeletonBlockOfText/SkeletonBlockOfText'
import analyticsService from '../../../../services/AnalyticsService'
import languageService from '../../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../../account/hooks/useCurrentUserLanguage'
import { onlyMixpanelTrackingServicesToExclude } from '../../hooks/useLiveEventsTrackerAnalyticsEvents'
import { LiveEventReview } from '../../types/TrackingEvents'

type LiveEventsAnalystNotesDialogProps = DialogProps & {
  review?: LiveEventReview
  isLoading?: boolean
  children?: ReactNode
}

export const LiveEventsAnalystNotesDialog: FC<LiveEventsAnalystNotesDialogProps> = ({ review, onClose, isLoading, children }) => {
  const { t } = useTranslation()
  const currentUserLanguage = useCurrentUserLanguage()
  const title = review?.comment.content.title[currentUserLanguage] || review?.comment.content.title[languageService.defaultLanguage]
  const contentHtml = review?.comment.content.comment[currentUserLanguage] || review?.comment.content.comment[languageService.defaultLanguage]
  const analyst = review?.comment.analyst
  const inactive = review?.active === false

  useEffect(() => {
    const trackingData = {
      title: review?.comment.content.title[currentUserLanguage] || review?.comment.content.title[languageService.defaultLanguage],
      analystName: review?.comment.analyst?.name,
      gameId: review?.gameId,
    }

    analyticsService.trackEvent('Live Events Tracker: Opened Analyst Notes', {
      data: trackingData,
      serviceToExclude: onlyMixpanelTrackingServicesToExclude,
    })
  }, [currentUserLanguage, review?.comment.content.title, review?.comment.analyst?.name, review?.gameId])

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth disableRestoreFocus>
      <GRDialogTitle>
        <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
          <Grid item container xs={9} alignItems="center" justifyContent="space-between" wrap="wrap" rowSpacing={2}>
            <Grid item sm={4} xs={12}>
              {isLoading ? (
                <Skeleton variant="circular" width={60} height={60} />
              ) : (
                <Analyst name={analyst?.name} picture={analyst?.picture} title={analyst?.title} />
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              sm={8}
              xs={12}
              sx={(theme) => ({ [theme.breakpoints.down('sm')]: { alignItems: 'flex-start' } })}
            >
              <Typography variant="h3" mb={1}>
                {isLoading ? <Skeleton variant="text" width={120} /> : title}
              </Typography>
              {inactive && <Chip label={t('live-events:event_inactive')} color="warning" size="small" sx={{ display: 'inline-block' }} />}
            </Grid>
          </Grid>
          <Grid item xs={3} container direction="column" alignItems="flex-end">
            <GRDialogCloseButton onClick={onClose} share />
          </Grid>
        </Grid>
      </GRDialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" component="div">
          {isLoading || !review ? (
            <SkeletonBlockOfText lines={15} />
          ) : (
            <HtmlContentParser rawHtml={contentHtml} parserOptions={liveEventReviewModalParserOptions(review.gameId)} />
          )}
        </Typography>
      </DialogContent>
    </Dialog>
  )
}
