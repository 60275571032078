import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material'

import { Tag } from '../../../../api/core'
import { intersectionBy } from '../../../../helpers/intersection'
import { ExpandedTagGroup } from '../../../implementation-examples'

type TagListProps = {
  tagGroups?: ExpandedTagGroup[]
  selectedTags: Tag[]
  onChange?: (selectedTags: Tag[], tag: Tag) => void
  onClear?: () => void
  onSelectAll?: () => void
  onSelectTagGroup?: (groupTags: Tag[], tagGroup: ExpandedTagGroup) => void
  disabled?: boolean
  variant?: 'normal' | 'small'
  selectedOnly?: boolean
  hideGroupTitles?: boolean
  chipTextColor?: string
  chipBackgroundColor?: string
}

export const TagList: React.FC<TagListProps> = ({
  selectedTags,
  tagGroups,
  onChange,
  onClear,
  onSelectAll,
  onSelectTagGroup,
  disabled,
  variant = 'normal',
  selectedOnly,
  hideGroupTitles,
  chipTextColor = 'white',
  chipBackgroundColor = 'white',
}) => {
  const { t } = useTranslation()
  return (
    <Box className="TagList">
      {tagGroups?.map((tagGroup, i) => {
        const { colorHex, name, tags } = tagGroup
        const hideGroup = selectedOnly && intersectionBy(tags, selectedTags, (tag) => tag.id).length === 0

        return (
          !!tags.length &&
          !hideGroup && (
            <Box sx={{ display: 'inline-flex', flexDirection: 'column', mr: 2 }} key={name}>
              {!hideGroupTitles && (
                <Box
                  sx={{
                    color: disabled ? '#bbb' : colorHex,
                    cursor: onSelectTagGroup ? 'pointer' : 'default',
                    fontSize: '15px',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    ml: '0.6rem',
                  }}
                  onClick={() => onSelectTagGroup && onSelectTagGroup(tags, tagGroup)}
                >
                  {name}
                </Box>
              )}
              <ToggleButtonGroup
                disabled={disabled}
                key={name}
                size="small"
                sx={{ mt: 0.75, mb: 1.5, mr: 0, '.MuiToggleButtonGroup-grouped:not(:first-of-type)': { ml: 0 }, position: 'relative' }}
              >
                {tags.map((tag) => {
                  const selected = selectedTags.find((selectedTag) => selectedTag.id === tag.id)
                  const hideTag = selectedOnly && !selected
                  return (
                    !hideTag && (
                      <ToggleButton
                        value={tag.id}
                        key={tag.name}
                        onClick={() => {
                          if (selected) {
                            onChange?.(
                              selectedTags.filter((selectedTag) => selectedTag.id !== tag.id),
                              tag
                            )
                          } else {
                            onChange?.([...selectedTags, tag], tag)
                          }
                        }}
                        sx={{
                          textTransform: 'capitalize',
                          borderColor: colorHex,
                          color: selected ? chipTextColor : colorHex,
                          '&:not(:last-of-type)': { borderRightColor: selected ? 'white' : colorHex },
                          backgroundColor: selected ? (colorHex ? colorHex : chipBackgroundColor) : 'white',
                          transition: 'all 250ms ease-in-out',
                          '&:hover': {
                            color: selected ? 'white' : colorHex,
                            borderColor: colorHex,
                            background: selected ? colorHex : 'rgba(0,0,0,0.03)',
                            cursor: onChange ? 'pointer' : 'default',
                          },
                          padding: variant === 'small' ? '1px 9px' : '4px 12px',
                          fontSize: variant === 'small' ? '12px' : '14px',
                          fontWeight: variant === 'small' ? 'bold' : 'normal',
                        }}
                        disableRipple={!onChange}
                      >
                        {tag.name}
                      </ToggleButton>
                    )
                  )
                })}
              </ToggleButtonGroup>
            </Box>
          )
        )
      })}
      {(onClear || onSelectAll) && (
        <Box>
          {onClear && (
            <Button variant="text" onClick={onClear} disabled={selectedTags.length === 0}>
              {t('tags-list:tags_clear_selection')}
            </Button>
          )}
          {onSelectAll && (
            <Button variant="text" onClick={onSelectAll} disabled={selectedTags.length === tagGroups?.flatMap((tagGroup) => tagGroup.tags).length}>
              {t('common:select_all')}
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}
