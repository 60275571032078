import { FC } from 'react'

import { useGetFeatureQuery } from '../../api/core'
import { FeatureModal } from '../../features/feature/components/FeatureModal/FeatureModal'
import { TabOption } from '../../features/feature/types/feature-modal-tabs'
import { useSearchParams } from '../../hooks/useSearchParams'

/**
 * Container component for feature modal. Reads related parameters from url and displays modal accordingly.
 */
type FeatureModalContainerProps = {}

type FeatureModalSearchParams = {
  featureModalTab?: TabOption
  featureModalFeature?: number
  featureModalChoice?: number
  featureModalCategory?: string
  featureModalGenre?: string
  featureModalSubgenre?: string
}

export const FeatureModalContainer: FC<FeatureModalContainerProps> = () => {
  const { parsedParams, setSearchParams } = useSearchParams<FeatureModalSearchParams, FeatureModalSearchParams>()
  const { data: feature } = useGetFeatureQuery(parsedParams.featureModalFeature as number, { skip: !parsedParams.featureModalFeature })

  return parsedParams.featureModalFeature ? (
    <FeatureModal
      feature={{ featureLegacyId: parsedParams.featureModalFeature, featureName: feature?.featureName }}
      choice={{
        name: feature?.choices.find((choice) => Number(choice.weightId) === Number(parsedParams.featureModalChoice))?.name || '',
        choiceLegacyId: parsedParams.featureModalChoice,
      }}
      initialTab={parsedParams.featureModalTab}
      onClose={() =>
        setSearchParams(
          ({ featureModalTab, featureModalFeature, featureModalChoice, featureModalCategory, featureModalGenre, featureModalSubgenre, ...rest }) => ({
            ...rest,
          })
        )
      }
      selectedSubgenre={{
        conventionalCategoryId: parsedParams.featureModalCategory,
        conventionalGenreId: parsedParams.featureModalGenre,
        conventionalSubgenreId: parsedParams.featureModalSubgenre,
      }}
    />
  ) : null
}
