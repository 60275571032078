import { ReactNode, FC } from 'react'

import { Grid, Typography } from '@mui/material'

import { CollapseChevron } from '../CollapseChevron/CollapseChevron'

/**
 * A simple Accordion component with title, caret icon and possibility to
 * add an extra element
 */
type GRAccordionProps = {
  expanded?: boolean
  extra?: ReactNode
  isLoading?: boolean
  onChange?: () => void
  title: string
  children?: ReactNode
}

export const GRAccordion: FC<GRAccordionProps> = ({ expanded, extra, isLoading, onChange, title, children }) => {
  return (
    <div className="Accordion">
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item className="Accordion__Toggle" onClick={onChange} sx={{ '&:hover': { cursor: 'pointer' } }}>
              <Typography>{title}</Typography>
            </Grid>
            <Grid item className="Accordion__Toggle" onClick={onChange} sx={{ '&:hover': { cursor: 'pointer' } }}>
              <CollapseChevron collapsed={!expanded} />
            </Grid>
          </Grid>
        </Grid>
        {extra && (
          <Grid item xs>
            {extra}
          </Grid>
        )}
      </Grid>
      {expanded && children}
    </div>
  )
}
