import { SubgenreMap } from '../features/account/types/UserSettings'

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SET_SUBGENRES_MAP = 'SET_SUBGENRES_MAP'
export const RESET_ALL_SEARCH = 'RESET_ALL_SEARCH'

export const setSearchTerm = (term: string) => {
  return {
    type: SET_SEARCH_TERM,
    payload: term,
  }
}

export const storeSubgenres = (subgenres: SubgenreMap) => {
  return {
    type: SET_SUBGENRES_MAP,
    payload: subgenres,
  }
}

export const resetAllSearch = () => {
  return {
    type: RESET_ALL_SEARCH,
  }
}
