import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Download } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { ButtonProps, CircularProgress, Fab, Tooltip } from '@mui/material'

type DownloadButtonProps = ButtonProps & {
  hideLabel?: boolean
  loading: boolean
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ hideLabel = false, loading = false, ...buttonProps }) => {
  const { t } = useTranslation()

  return (
    <>
      {hideLabel ? (
        <Tooltip title={t('common:download') as string}>
          <>
            <Fab
              size="small"
              disabled={loading}
              color="primary"
              onClick={buttonProps.onClick}
              sx={{ whiteSpace: 'nowrap', boxShadow: (theme) => theme.shadows[2] }}
            >
              <Download />
              {loading && (
                <CircularProgress
                  size={48}
                  sx={{
                    color: '#bd65cf',
                    position: 'absolute',
                    top: -4,
                    left: -4,
                    zIndex: 1,
                  }}
                />
              )}
            </Fab>
          </>
        </Tooltip>
      ) : (
        <LoadingButton loadingPosition="start" loading={loading} {...buttonProps} variant="contained" startIcon={<Download />}>
          <Trans i18nKey={'common:download'} />
        </LoadingButton>
      )}
    </>
  )
}
