import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseRounded } from '@mui/icons-material'
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material'

import { useGetFeatureQuery } from '../../../../api/core'
import { SingleSubgenreSelection } from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import ShareUrlButton from '../../../../components/ShareUrlButton/ShareUrlButton'
import analyticsService from '../../../../services/AnalyticsService'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useNewsFeedLimitedAccessCheck } from '../../../account/hooks/roleHooks'
import { useInitialFeatureModalState } from '../../../implementation-examples/hooks/useInitialState'
import { tabIcons } from '../../constants/constants'
import { TabOption, tabOptions } from '../../types/feature-modal-tabs'
import type { Choice } from '../FeatureLink'
import { FeatureDefinition } from './FeatureDefinition'
import { FeatureFacts } from './FeatureFacts'
import { FeatureImplementations } from './FeatureImplementations'
import './FeatureModal.scss'

export type ModalFeature = {
  featureLegacyId: number
  featureName?: string
}

export type DetailModal = {
  feature: ModalFeature
  initialTab?: TabOption
  selectedSubgenre?: SingleSubgenreSelection
}

interface FeatureModalProps extends DetailModal {
  onClose: () => void
  choice?: Choice
  forceShowFeatureInList?: boolean //force to show features in list despite showInlists props
  selectedGameIds?: string[]
}

/**
 * @param item
 * @param onClose
 */
export const FeatureModal: React.FC<FeatureModalProps> = ({
  feature,
  initialTab,
  onClose,
  selectedSubgenre,
  choice,
  forceShowFeatureInList,
  selectedGameIds,
}) => {
  useEffect(() => {
    analyticsService.trackEvent('Market Explorer: Feature Popularity Modal Opened')
  }, [])

  const { t } = useTranslation()
  const [modalState, setModalState] = useInitialFeatureModalState()

  const { featureLegacyId, featureName } = feature
  const { data: detailedFeature } = useGetFeatureQuery(featureLegacyId)
  const hasNewsFeedLimitedAccess = useNewsFeedLimitedAccessCheck()

  const [selectedTab, setSelectedTab] = useState<TabOption>(initialTab || modalState?.featureModalTab || 'definition')

  const switchTab = (tab: TabOption) => {
    setModalState({ featureModalFeature: feature.featureLegacyId, featureModalTab: tab })
    setSelectedTab(tab)
  }

  const mappedSelectedTabWithLock: { [key: string]: LockedFeatureId } = {
    facts: LockedFeatureId.GameFeatureFacts,
    implementation: LockedFeatureId.GameImplementations,
  }

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="lg" className="FeatureModal" disableRestoreFocus>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h3">{featureName}</Typography>
          <IconButton className="closeButton" onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Divider />
      <Grid container alignItems="center">
        <Grid item xs={10}>
          <Tabs value={selectedTab}>
            {tabOptions.map((tab) => (
              <Tab
                icon={tabIcons[tab]}
                iconPosition="start"
                key={tab}
                label={t(`feature:tab_${tab}`)}
                component={Button}
                onClick={() => switchTab(tab)}
                value={tab}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={2} textAlign="right" sx={{ pr: 2 }}>
          <ShareUrlButton />
        </Grid>
      </Grid>
      <DialogContent>
        {hasNewsFeedLimitedAccess && Object.keys(mappedSelectedTabWithLock).includes(selectedTab) ? (
          <Box mb={2}>
            <LockedFeature.Card lockedFeatureId={mappedSelectedTabWithLock[selectedTab]} />
          </Box>
        ) : (
          <>
            {selectedTab === 'definition' && <FeatureDefinition feature={detailedFeature} />}
            {selectedTab === 'facts' && (
              <FeatureFacts
                defaultChoice={choice}
                forceShowFeatureInList={forceShowFeatureInList}
                feature={detailedFeature}
                selectedSubgenre={selectedSubgenre}
              />
            )}
            {selectedTab === 'implementation' && detailedFeature && <FeatureImplementations feature={{ ...detailedFeature, legacyId: featureLegacyId }} />}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
