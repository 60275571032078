import classNames from 'classnames'
import React from 'react'
import { FC, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Button, ButtonGroup, Card, CardContent, Chip, Divider, Grid, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { Fade } from '@mui/material'
import { Box } from '@mui/system'

import AppLogo from '../../assets/images/gamerefinery-logo.png'
import AccountBadge from '../../features/account/components/AccountBadge/AccountBadge'
import { useInternalAccessCheck } from '../../features/account/hooks/roleHooks'
import AddBookmarkButton from '../../features/bookmarks/components/AddBookmarkButton'
import MyBookmarksButton from '../../features/bookmarks/components/MyBookmarksButton'
import { GameSearchInput } from '../../features/game'
import { dataGlossaryRootPath } from '../../features/implementation-examples/hooks/useInitialState'
import { useMobileSize } from '../../features/responsiveness/hooks/responsivenessHooks'
import { fullscreenBreakpoint } from '../../hooks/useFullscreenMode'
import PageService from '../../services/PageService'
import utilsService from '../../services/UtilsService'
import { NavigationMode } from '../../types/NavigationMode'
import { SideNavigationGroupData } from '../../types/SideNavigationGroupData'
import '../GRButtonGroup/GRButtonGroup.scss'
import SideNavigationGroup from '../SideNavigationGroup/SideNavigationGroup'
import { FullscreenModeToggler } from './FullscreenModeToggler/FullscreenModeToggler'
import { NavigationModeToggler } from './NavigationModeToggler/NavigationModeToggler'
import './SideNavigation.scss'

export type SideNavigationProps = {
  open: boolean
  mode: NavigationMode
  onNavigationModeChange: (mode: NavigationMode) => void
  onOpenChange: () => void
}

const SideNavigation: React.FC<SideNavigationProps> = ({ open, mode, onNavigationModeChange, onOpenChange }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isFullscreenAvailable = useMediaQuery(theme.breakpoints.up(fullscreenBreakpoint))

  const hideNavigationButtonClassNames = classNames({
    'SideNavigation__hideNavigationButton--rounded': !isFullscreenAvailable,
  })

  const fullscreenModeTogglerButtonClassNames = classNames({
    SideNavigation__FullscreenModeToggler: true,
    'SideNavigation__FullscreenModeToggler--hidden': !isFullscreenAvailable,
    'SideNavigation__FullscreenModeToggler--visible': isFullscreenAvailable,
  })
  const hasInternalAccess = useInternalAccessCheck()
  const [hovering, setHovering] = React.useState(false)
  const smallScreenSize = useMobileSize()
  const isBetaRelease = false
  const isInternal = mode === NavigationMode.Internal
  const cardClasses = classNames('SideNavigation', {
    'SideNavigation--mobile': smallScreenSize,
  })
  const navigationGroups = useMemo(() => (isInternal ? PageService.getInternalSideNavigationGroups() : PageService.getSideNavigationGroups()), [isInternal])

  return (
    <Card className={cardClasses} sx={{ overflow: 'visible' }} onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)}>
      <CardContent>
        <div className="SideNavigation__Logo">
          <Grid container alignItems="flex-start" justifyContent="center">
            <Grid item xs={12}>
              <NavLink to="/">
                <img src={AppLogo} alt="" />
              </NavLink>
            </Grid>
            <Grid item xs></Grid>
          </Grid>

          <div className="SideNavigation__Badges">
            {isBetaRelease && (
              <div className="SideNavigation__Badges__Badge SideNavigation__Badges__Badge--Beta">
                <Chip className="Chip" label="BETA" />
              </div>
            )}
            {utilsService.getEnvironmentType() !== 'prod' && (
              <div className="SideNavigation__Badges__Badge">
                <Chip className="Chip" label={utilsService.getEnvironmentType()?.toLocaleUpperCase()} />
              </div>
            )}
            {isInternal && (
              <div className="SideNavigation__Badges__Badge">
                <Chip className="Chip" label={mode.toLocaleUpperCase()} />
              </div>
            )}
          </div>
        </div>
        <Divider className="SideNavigation__divider" />

        <div className="SideNavigation__content">
          {smallScreenSize && (
            <div className="SideNavigation__content__mobile-extension">
              <Grid container spacing={1} alignItems="center">
                <Grid item xs>
                  {!isInternal && <GameSearchInput />}
                </Grid>

                <Grid item>
                  <div className="SideNavigation__content__mobile-extension__account">
                    <AccountBadge />
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
          {!isInternal && (
            <>
              <Link to={dataGlossaryRootPath}>
                <Button className="bottom-margin" variant="outlined" color="secondary" fullWidth={true} size="medium">
                  {t('data-glossary:data_glossary')}
                </Button>
              </Link>
              <div className="SideNavigation__BookmarksButtonsWrapper">
                <ButtonGroup className="GRButtonGroup" aria-label="split button" variant="outlined" fullWidth={true} size="medium">
                  <div className="SideNavigation__BookmarksButtonsWrapper__left">
                    <MyBookmarksButton variant="text" color="secondary" />
                  </div>
                  <div className="SideNavigation__BookmarksButtonsWrapper__right">
                    <AddBookmarkButton variant="text" color="secondary" />
                  </div>
                </ButtonGroup>
              </div>
            </>
          )}

          <Box mb={10}>
            <SideNavigationGroups groups={navigationGroups} />
          </Box>
        </div>
      </CardContent>
      {!smallScreenSize && (
        <Box sx={{ position: 'absolute', top: '50%', bottom: '50%', right: -11, margin: 'auto 0' }}>
          <ButtonGroup className="SideNavigation__Toolbar" orientation="vertical" variant="contained">
            <div className={fullscreenModeTogglerButtonClassNames}>
              <FullscreenModeToggler />
            </div>

            <Tooltip title={<Trans i18nKey={open ? 'common:sidenavigation_hide' : 'common:sidenavigation_show'} />}>
              <Button onClick={onOpenChange} className={hideNavigationButtonClassNames}>
                <KeyboardArrowLeftIcon
                  fontSize="inherit"
                  sx={{ transition: 'transform 300ms ease-in-out', transform: open ? 'rotate(0)' : 'rotate(-180deg)' }}
                />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>
      )}
      <Fade in={hasInternalAccess && (hovering || smallScreenSize)}>
        <Box sx={{ background: '#FFF', position: 'absolute', bottom: 0, left: 0, px: 3, pb: 2, boxSizing: 'border-box', width: '100%', borderRadius: 6 }}>
          <Divider sx={{ mb: 2, mx: -3 }} />
          <NavigationModeToggler value={mode} onChange={onNavigationModeChange} />
        </Box>
      </Fade>
    </Card>
  )
}

export default SideNavigation

type SideNavigationGroupsProps = {
  groups: SideNavigationGroupData[]
}

const SideNavigationGroups: FC<SideNavigationGroupsProps> = ({ groups }) => {
  return (
    <>
      {groups.map((sideNavigationGroup: SideNavigationGroupData) => {
        return <SideNavigationGroup key={sideNavigationGroup.id} group={sideNavigationGroup}></SideNavigationGroup>
      })}
    </>
  )
}
