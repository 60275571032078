const marketsWithRevenueData = [
  'au',
  'br',
  'ca',
  'cn',
  'dk',
  'fi',
  'fr',
  'de',
  'hk',
  'in',
  'id',
  'ie',
  'il',
  'it',
  'my',
  'mx',
  'nl',
  'nz',
  'no',
  'pk',
  'pl',
  'pt',
  'ru',
  'sa',
  'sg',
  'es',
  'se',
  'ch',
  'tr',
  'ua',
  'ae',
  'gb',
  'us',
  'jp',
]

export const revenueIsAvailableForMarket = (marketIso: string) => marketsWithRevenueData.includes(marketIso)

export type revenueData = {
  POWERSCORE: number
  Average: number
} & { [range: string]: number }

class RevenueService {
  private revenueData: revenueData[] = [
    {
      POWERSCORE: 96,
      '200+': 33,
      '100-199': 17,
      '70-99': 8,
      '50-69': 17,
      '30-49': 0,
      '10-29': 17,
      '2-9': 8,
      '<2': 0,
      Average: 107800,
    },
    {
      POWERSCORE: 95,
      '200+': 29,
      '100-199': 21,
      '70-99': 7,
      '50-69': 14,
      '30-49': 0,
      '10-29': 21,
      '2-9': 7,
      '<2': 0,
      Average: 102700,
    },
    {
      POWERSCORE: 94,
      '200+': 29,
      '100-199': 21,
      '70-99': 7,
      '50-69': 14,
      '30-49': 0,
      '10-29': 21,
      '2-9': 7,
      '<2': 0,
      Average: 102700,
    },
    {
      POWERSCORE: 93,
      '200+': 21,
      '100-199': 21,
      '70-99': 5,
      '50-69': 21,
      '30-49': 0,
      '10-29': 26,
      '2-9': 5,
      '<2': 0,
      Average: 90700,
    },
    {
      POWERSCORE: 92,
      '200+': 17,
      '100-199': 17,
      '70-99': 4,
      '50-69': 17,
      '30-49': 0,
      '10-29': 26,
      '2-9': 17,
      '<2': 0,
      Average: 76200,
    },
    {
      POWERSCORE: 91,
      '200+': 15,
      '100-199': 12,
      '70-99': 3,
      '50-69': 15,
      '30-49': 0,
      '10-29': 29,
      '2-9': 26,
      '<2': 0,
      Average: 62000,
    },
    {
      POWERSCORE: 90,
      '200+': 13,
      '100-199': 10,
      '70-99': 3,
      '50-69': 13,
      '30-49': 3,
      '10-29': 35,
      '2-9': 25,
      '<2': 0,
      Average: 55800,
    },
    {
      POWERSCORE: 89,
      '200+': 11,
      '100-199': 9,
      '70-99': 2,
      '50-69': 11,
      '30-49': 7,
      '10-29': 37,
      '2-9': 24,
      '<2': 0,
      Average: 51600,
    },
    {
      POWERSCORE: 88,
      '200+': 9,
      '100-199': 8,
      '70-99': 2,
      '50-69': 9,
      '30-49': 8,
      '10-29': 38,
      '2-9': 25,
      '<2': 2,
      Average: 46800,
    },
    {
      POWERSCORE: 87,
      '200+': 9,
      '100-199': 7,
      '70-99': 2,
      '50-69': 9,
      '30-49': 9,
      '10-29': 35,
      '2-9': 28,
      '<2': 2,
      Average: 44400,
    },
    {
      POWERSCORE: 86,
      '200+': 7,
      '100-199': 6,
      '70-99': 1,
      '50-69': 7,
      '30-49': 9,
      '10-29': 34,
      '2-9': 31,
      '<2': 4,
      Average: 38900,
    },
    {
      POWERSCORE: 85,
      '200+': 7,
      '100-199': 7,
      '70-99': 3,
      '50-69': 7,
      '30-49': 9,
      '10-29': 32,
      '2-9': 32,
      '<2': 5,
      Average: 38400,
    },
    {
      POWERSCORE: 84,
      '200+': 6,
      '100-199': 6,
      '70-99': 3,
      '50-69': 6,
      '30-49': 9,
      '10-29': 30,
      '2-9': 34,
      '<2': 7,
      Average: 35000,
    },
    {
      POWERSCORE: 83,
      '200+': 5,
      '100-199': 5,
      '70-99': 3,
      '50-69': 5,
      '30-49': 8,
      '10-29': 30,
      '2-9': 36,
      '<2': 7,
      Average: 32300,
    },
    {
      POWERSCORE: 82,
      '200+': 4,
      '100-199': 4,
      '70-99': 3,
      '50-69': 4,
      '30-49': 9,
      '10-29': 27,
      '2-9': 38,
      '<2': 10,
      Average: 29300,
    },
    {
      POWERSCORE: 81,
      '200+': 4,
      '100-199': 4,
      '70-99': 3,
      '50-69': 4,
      '30-49': 9,
      '10-29': 28,
      '2-9': 40,
      '<2': 10,
      Average: 25900,
    },
    {
      POWERSCORE: 80,
      '200+': 1,
      '100-199': 3,
      '70-99': 2,
      '50-69': 3,
      '30-49': 8,
      '10-29': 27,
      '2-9': 46,
      '<2': 11,
      Average: 18700,
    },
    {
      POWERSCORE: 79,
      '200+': 1,
      '100-199': 2,
      '70-99': 2,
      '50-69': 3,
      '30-49': 8,
      '10-29': 27,
      '2-9': 45,
      '<2': 12,
      Average: 17300,
    },
    {
      POWERSCORE: 78,
      '200+': 1,
      '100-199': 2,
      '70-99': 1,
      '50-69': 3,
      '30-49': 7,
      '10-29': 25,
      '2-9': 47,
      '<2': 13,
      Average: 16400,
    },
    {
      POWERSCORE: 77,
      '200+': 1,
      '100-199': 1,
      '70-99': 1,
      '50-69': 2,
      '30-49': 6,
      '10-29': 24,
      '2-9': 50,
      '<2': 14,
      Average: 14000,
    },
    {
      POWERSCORE: 76,
      '200+': 1,
      '100-199': 1,
      '70-99': 1,
      '50-69': 2,
      '30-49': 6,
      '10-29': 23,
      '2-9': 49,
      '<2': 16,
      Average: 13800,
    },
    {
      POWERSCORE: 75,
      '200+': 0,
      '100-199': 1,
      '70-99': 1,
      '50-69': 1,
      '30-49': 7,
      '10-29': 22,
      '2-9': 51,
      '<2': 17,
      Average: 12000,
    },
    {
      POWERSCORE: 74,
      '200+': 0,
      '100-199': 1,
      '70-99': 1,
      '50-69': 1,
      '30-49': 6,
      '10-29': 19,
      '2-9': 53,
      '<2': 18,
      Average: 11000,
    },
    {
      POWERSCORE: 73,
      '200+': 0,
      '100-199': 1,
      '70-99': 1,
      '50-69': 1,
      '30-49': 4,
      '10-29': 17,
      '2-9': 55,
      '<2': 20,
      Average: 10000,
    },
    {
      POWERSCORE: 72,
      '200+': 0,
      '100-199': 1,
      '70-99': 1,
      '50-69': 1,
      '30-49': 4,
      '10-29': 15,
      '2-9': 57,
      '<2': 20,
      Average: 9500,
    },
    {
      POWERSCORE: 71,
      '200+': 0,
      '100-199': 1,
      '70-99': 1,
      '50-69': 1,
      '30-49': 3,
      '10-29': 15,
      '2-9': 57,
      '<2': 21,
      Average: 9000,
    },
    {
      POWERSCORE: 70,
      '200+': 0,
      '100-199': 1,
      '70-99': 1,
      '50-69': 1,
      '30-49': 2,
      '10-29': 14,
      '2-9': 58,
      '<2': 22,
      Average: 8800,
    },
    {
      POWERSCORE: 69,
      '200+': 0,
      '100-199': 1,
      '70-99': 1,
      '50-69': 1,
      '30-49': 2,
      '10-29': 13,
      '2-9': 57,
      '<2': 25,
      Average: 7100,
    },
    {
      POWERSCORE: 68,
      '200+': 0,
      '100-199': 1,
      '70-99': 0,
      '50-69': 1,
      '30-49': 1,
      '10-29': 13,
      '2-9': 57,
      '<2': 27,
      Average: 6300,
    },
    {
      POWERSCORE: 67,
      '200+': 0,
      '100-199': 1,
      '70-99': 0,
      '50-69': 1,
      '30-49': 1,
      '10-29': 11,
      '2-9': 55,
      '<2': 31,
      Average: 5900,
    },
    {
      POWERSCORE: 66,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 1,
      '30-49': 0,
      '10-29': 10,
      '2-9': 55,
      '<2': 33,
      Average: 4500,
    },
    {
      POWERSCORE: 65,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 1,
      '30-49': 0,
      '10-29': 9,
      '2-9': 54,
      '<2': 36,
      Average: 4100,
    },
    {
      POWERSCORE: 64,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 1,
      '30-49': 0,
      '10-29': 9,
      '2-9': 48,
      '<2': 41,
      Average: 4000,
    },
    {
      POWERSCORE: 63,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 1,
      '30-49': 0,
      '10-29': 7,
      '2-9': 50,
      '<2': 42,
      Average: 3300,
    },
    {
      POWERSCORE: 62,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 7,
      '2-9': 47,
      '<2': 46,
      Average: 2700,
    },
    {
      POWERSCORE: 61,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 5,
      '2-9': 47,
      '<2': 49,
      Average: 2400,
    },
    {
      POWERSCORE: 60,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 5,
      '2-9': 45,
      '<2': 50,
      Average: 2300,
    },
    {
      POWERSCORE: 59,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 4,
      '2-9': 41,
      '<2': 55,
      Average: 2000,
    },
    {
      POWERSCORE: 58,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 4,
      '2-9': 37,
      '<2': 59,
      Average: 1800,
    },
    {
      POWERSCORE: 57,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 3,
      '2-9': 35,
      '<2': 62,
      Average: 1600,
    },
    {
      POWERSCORE: 56,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 3,
      '2-9': 32,
      '<2': 65,
      Average: 1500,
    },
    {
      POWERSCORE: 55,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 3,
      '2-9': 29,
      '<2': 68,
      Average: 1400,
    },
    {
      POWERSCORE: 54,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 2,
      '2-9': 27,
      '<2': 71,
      Average: 1200,
    },
    {
      POWERSCORE: 53,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 2,
      '2-9': 26,
      '<2': 72,
      Average: 1200,
    },
    {
      POWERSCORE: 52,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 1,
      '2-9': 26,
      '<2': 73,
      Average: 1100,
    },
    {
      POWERSCORE: 51,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 1,
      '2-9': 24,
      '<2': 75,
      Average: 900,
    },
    {
      POWERSCORE: 50,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 1,
      '2-9': 24,
      '<2': 75,
      Average: 900,
    },
    {
      POWERSCORE: 49,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 1,
      '2-9': 21,
      '<2': 78,
      Average: 800,
    },
    {
      POWERSCORE: 48,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 21,
      '<2': 79,
      Average: 600,
    },
    {
      POWERSCORE: 47,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 19,
      '<2': 81,
      Average: 600,
    },
    {
      POWERSCORE: 46,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 19,
      '<2': 81,
      Average: 600,
    },
    {
      POWERSCORE: 45,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 14,
      '<2': 86,
      Average: 400,
    },
    {
      POWERSCORE: 44,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 14,
      '<2': 86,
      Average: 400,
    },
    {
      POWERSCORE: 43,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 13,
      '<2': 87,
      Average: 400,
    },
    {
      POWERSCORE: 42,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 13,
      '<2': 87,
      Average: 400,
    },
    {
      POWERSCORE: 41,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 12,
      '<2': 88,
      Average: 400,
    },
    {
      POWERSCORE: 40,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 10,
      '<2': 90,
      Average: 300,
    },
    {
      POWERSCORE: 39,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 9,
      '<2': 91,
      Average: 300,
    },
    {
      POWERSCORE: 38,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 8,
      '<2': 92,
      Average: 200,
    },
    {
      POWERSCORE: 37,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 6,
      '<2': 94,
      Average: 200,
    },
    {
      POWERSCORE: 36,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 5,
      '<2': 95,
      Average: 200,
    },
    {
      POWERSCORE: 35,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 5,
      '<2': 95,
      Average: 100,
    },
    {
      POWERSCORE: 34,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 3,
      '<2': 97,
      Average: 100,
    },
    {
      POWERSCORE: 33,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 3,
      '<2': 97,
      Average: 100,
    },
    {
      POWERSCORE: 32,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 3,
      '<2': 97,
      Average: 100,
    },
    {
      POWERSCORE: 31,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 2,
      '<2': 98,
      Average: 100,
    },
    {
      POWERSCORE: 30,
      '200+': 0,
      '100-199': 0,
      '70-99': 0,
      '50-69': 0,
      '30-49': 0,
      '10-29': 0,
      '2-9': 2,
      '<2': 98,
      Average: 100,
    },
  ]

  /**
   * getDataLabelByPowerScore
   * get hardcoded data label by powerscore input
   */
  public getLabelByPowerScore(powerscore?: number) {
    if (!powerscore) return

    return this.revenueData.find((revenue) => revenue.POWERSCORE === Math.round(powerscore))
  }
}

const revenueService = new RevenueService()
export default revenueService
