import React from 'react'
import { useTranslation } from 'react-i18next'

import { Timeline } from '@mui/icons-material'
import { Button, ButtonProps, SvgIconProps } from '@mui/material'

import { GRTooltip } from '../GRTooltip/GRTooltip'

type Props = ButtonProps & {
  iconProps?: SvgIconProps
}

export const GamePerformanceDialogOpenButton: React.FC<Props> = ({ iconProps, ...iconButtonProps }) => {
  const { t } = useTranslation()
  const tooltipText = iconButtonProps.disabled ? t('live-events:performance_non_mobile_games_tooltip_text') : t('top-games:show_performance')
  return (
    <GRTooltip content={tooltipText}>
      <Button color="primary" className="GamePerformanceDialogOpenButton" sx={{ minWidth: 0, width: 32, height: 32 }} {...iconButtonProps}>
        <Timeline {...iconProps} />
      </Button>
    </GRTooltip>
  )
}
