import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, FormLabel, Grid } from '@mui/material'

import languageService from '../../services/LanguageService'
import { VersionTag } from '../../types/VersionTag'
import VersionTagsList from '../VersionTagsList/VersionTagsList'

/**
 * Component for using version tag list as a selector.
 */
type VersionTagSelectorProps = {
  selectedTags: VersionTag[]
  onChange: (selectedVersionTags: VersionTag[]) => void
}

export const VersionTagSelector: FC<VersionTagSelectorProps> = ({ selectedTags, onChange }) => {
  const { t } = useTranslation()
  const versionTags = useMemo(() => {
    return languageService.getVersionTags()
  }, [])
  const [selectedVersionTags, setSelectedVersionTags] = useState<VersionTag[]>(selectedTags || [])

  const handleSelect = useCallback(
    (selectedTag: VersionTag) => {
      const currentlySelected = !!selectedVersionTags.find((tag) => tag.id === selectedTag.id)
      if (currentlySelected) {
        setSelectedVersionTags(selectedVersionTags.filter((tag) => tag.id !== selectedTag.id))
      } else {
        setSelectedVersionTags([...selectedVersionTags, selectedTag])
      }
    },
    [selectedVersionTags]
  )

  useEffect(() => {
    onChange && onChange(selectedVersionTags)
  }, [selectedVersionTags, onChange, versionTags])

  return (
    <FormControl component="fieldset" sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
      <FormLabel component="div">{t('common:filter')}</FormLabel>
      <Grid container wrap="wrap" direction="row" sx={{ paddingTop: '11px' }}>
        <VersionTagsList versionTags={versionTags} selectedVersionTags={selectedVersionTags} onSelect={handleSelect} />
      </Grid>
    </FormControl>
  )
}
