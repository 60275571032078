import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, CircularProgress, Grid, Typography } from '@mui/material'

import { useGetScreenshotCommentQuery } from '../../api/core'

interface AnalystCommentProps {
  commentId: string
}

const AnalystComment: React.FC<AnalystCommentProps> = ({ commentId }) => {
  const { t } = useTranslation()
  const { data, isFetching } = useGetScreenshotCommentQuery(commentId)
  return (
    <Grid container alignItems="center" justifyContent="space-between" className="analystComment modalFooter">
      <Grid item md={8}>
        <Typography variant="h2" color="primary">
          {t('reviews:label_analyst_comment')}
        </Typography>
        {isFetching ? <CircularProgress size={16} /> : <Typography dangerouslySetInnerHTML={{ __html: data?.screenshotComment.content.comment.en || '' }} />}
      </Grid>
      <Grid item md={4} textAlign="right">
        <Box className="inline">
          <Typography variant="h2" color="primary">
            {data?.analyst.name}
          </Typography>
          <Typography variant="subtitle2">{data?.analyst.title}</Typography>
        </Box>
        <img src={data?.analyst.picture} alt={data?.analyst.name} className="analystImage" />
      </Grid>
    </Grid>
  )
}

export default AnalystComment
