import { format } from 'date-fns'
import { FC, useCallback, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Edit, Delete } from '@mui/icons-material'
import { TableContainer, Card, Grid, Box } from '@mui/material'

import ConfirmDialog from '../../../../../components/ConfirmDialog/ConfirmDialog'
import { GRTable, GRTableColumn } from '../../../../../components/GRTable/GRTable'
import { displaySnackBar } from '../../../../../features/snackbar'
import { useAppDispatch } from '../../../../../hooks/storeHooks'
import { Analyst } from '../../../../../types/Analyst'
import { ConfirmDialogData } from '../../../../../types/ConfirmDialogData'
import { useDeleteTrackedGameNoteByIdMutation } from '../../../../api/internal'
import { ActionButton } from '../../../../components/ActionButton/ActionButton'
import { AnalystAvatar } from '../../../../components/AnalystAvatar/AnalystAvatar'
import { CheckedStatusCircle } from '../../../../components/CheckedStatusCircle/CheckedStatusCircle'
import { InternalAnalystNote } from '../../../../types/InternalAnalystNotes'

type TrackedGameAnalystNotesTableProps = {
  isLoading: boolean
  trackedGameComments?: InternalAnalystNote[]
  analystList?: Analyst[]
  dateParam: string
}

type NoteDeleteConfirmData = {
  noteId: string
}

export const TrackedGameAnalystNotesTable: FC<TrackedGameAnalystNotesTableProps> = ({ dateParam, isLoading, trackedGameComments, analystList }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const dispatch = useAppDispatch()
  const [deleteNote] = useDeleteTrackedGameNoteByIdMutation()
  const [deleteNotesDialogData, setDeleteNotesDialogData] = useState<ConfirmDialogData<NoteDeleteConfirmData>>()

  const handleDeleteEvent = useCallback(
    (data: NoteDeleteConfirmData) => {
      setDeleteNotesDialogData({
        title: t('internal-live-events:delete_note_from_id_confirm_dialog_title'),
        confirmText: t('internal-live-events:delete_note_from_id_confirm_dialog_confirm_text'),
        actionText: t('internal-common:yes'),
        cancelText: t('internal-common:no'),
        destructiveAction: true,
        data,
      })
    },
    [t]
  )

  const trackedGameAnalystNotesTableColumns: GRTableColumn<InternalAnalystNote, typeof customTableProps>[] = useMemo(
    () => [
      {
        headerCellProps: { sx: { minWidth: 60 } },
        labelAccessor: () => <Trans i18nKey="internal-common:analyst" />,
        accessor: ({ row, customTableProps }) => {
          let analyst = customTableProps?.analystList?.find((analyst) => {
            return analyst.id === row.comment.analystId
          })
          return <AnalystAvatar analyst={analyst} />
        },
      },
      {
        headerCellProps: { sx: { minWidth: 120, textAlign: 'left' } },
        labelAccessor: () => (
          <Box sx={{ paddingLeft: '12px' }}>
            <Trans i18nKey="internal-live-events:title" />
          </Box>
        ),
        cellProps: { sx: { fontWeight: 700, textAlign: 'left' } },
        accessor: ({ row }) => row.comment.content.title.en,
      },
      {
        headerCellProps: { sx: { minWidth: 120 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:date_range" />,
        accessor: ({ row }) => (
          <div>
            {format(row.start, 'dd LLL yyyy')} - {format(row.end, 'dd LLL yyyy')}
          </div>
        ),
      },
      {
        headerCellProps: { sx: { minWidth: 60 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:active" />,
        accessor: ({ row }) => <CheckedStatusCircle value={row.active} />,
      },
      {
        headerCellProps: { sx: { minWidth: 60 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:actions" />,
        accessor: ({ row, customTableProps }) => (
          <Grid container justifyContent="center" spacing={1}>
            <Grid item>
              <ActionButton
                onClick={() =>
                  navigate(
                    `/internal/live-events-tracking/events/${row.gameId}/analyst-notes/edit/analyst-notes/?date=${customTableProps?.dateParam}&commentId=${row.id}`
                  )
                }
              >
                <Edit fontSize="small" />
              </ActionButton>
            </Grid>
            <Grid item>
              <ActionButton color="warning" onClick={() => handleDeleteEvent({ noteId: row.id })}>
                <Delete fontSize="small" />
              </ActionButton>
            </Grid>
          </Grid>
        ),
      },
    ],
    [handleDeleteEvent, navigate]
  )

  const handleDeleteNoteConfirm = (dialogData?: ConfirmDialogData<NoteDeleteConfirmData>) => {
    if (dialogData?.data) {
      deleteNote({ noteId: dialogData.data.noteId }).then(() => {
        dispatch(displaySnackBar({ message: t('internal-live-events:comment_deleted'), severity: 'success', open: true }))
      })
    }

    setDeleteNotesDialogData(undefined)
  }

  const customTableProps = useMemo(() => ({ analystList, dateParam }), [analystList, dateParam])
  const [columns, setColumns] = useState<GRTableColumn<InternalAnalystNote, typeof customTableProps>[]>(trackedGameAnalystNotesTableColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<InternalAnalystNote, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <>
      <TableContainer component={Card} ref={containerRef}>
        <GRTable
          columns={columns}
          customProps={customTableProps}
          isLoading={isLoading}
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey={(trackedGameComment) => trackedGameComment.id}
          rows={trackedGameComments || []}
          scroller={containerRef}
          striped
          noRowsLabel={t('internal-common:no_rows')}
        />
      </TableContainer>
      <ConfirmDialog open={!!deleteNotesDialogData} onClose={handleDeleteNoteConfirm} confirmDialogData={deleteNotesDialogData} />
    </>
  )
}
