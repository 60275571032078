// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '.'
import { InvitedUser } from '../features/account/types/InvitedUser'
import { Organization, User } from '../features/account/types/User'
import { UserInvite } from '../features/account/types/UserInvite'
import { RegisterUser } from '../types/RegisterUser'

const REDUCER_PATH = 'accountApi'

const TAG_TYPE_ORGANIZATION = 'organization' as const
const TAG_TYPE_ORGANIZATION_USERS = 'organizationUsers' as const
const TAG_TYPE_USER_INVITATION = 'userInvitation' as const
const TAG_ID_CURRENT_USER_ORGANISATION = 'currentUserOrganization'

export const accountApi = createApi({
  reducerPath: REDUCER_PATH,
  tagTypes: [TAG_TYPE_ORGANIZATION, TAG_TYPE_ORGANIZATION_USERS, TAG_TYPE_USER_INVITATION],
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_ACCOUNT,
    prepareHeaders: prepareDefaultHeaders,
  }),
  endpoints: (builder) => ({
    getCurrentUserOrganization: builder.query<Organization, void>({
      query: () => `organizations`,
      providesTags: () => [{ type: TAG_TYPE_ORGANIZATION, id: TAG_ID_CURRENT_USER_ORGANISATION }],
    }),
    updateCurrentUserOrganization: builder.mutation<Organization, Organization>({
      query: ({ ...body }) => ({
        url: `organizations`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: TAG_TYPE_ORGANIZATION, id: TAG_ID_CURRENT_USER_ORGANISATION }],
    }),
    getCurrentUserOrganizationUsers: builder.query<User[], void>({
      query: () => 'organizations/users',
      providesTags: (result) =>
        result ? [...result.map((user) => ({ type: TAG_TYPE_ORGANIZATION_USERS, id: user.id })), TAG_TYPE_ORGANIZATION_USERS] : [TAG_TYPE_ORGANIZATION_USERS],
    }),
    removeCurrentUserOrganizationUser: builder.mutation<void, User>({
      query: (user) => ({
        url: `organizations/users/${user.username}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: (response, error, args) => [{ type: TAG_TYPE_ORGANIZATION_USERS, id: args.id }],
    }),
    resetCurrentUserOrganizationUserPassword: builder.mutation<void, { username: string; password: string }>({
      query: ({ username, password }) => ({
        url: 'admin/users/sendmail',
        method: 'POST',
        body: { username, password },
      }),
    }),
    updateUserActiveStatus: builder.mutation<void, User>({
      query: (user) => ({
        url: `organizations/users/${user.username}/${user.active ? 'disable' : 'enable'}`,
        method: 'PUT',
      }),
      async onQueryStarted(user, { dispatch, queryFulfilled }) {
        const result = dispatch(
          accountApi.util.updateQueryData('getCurrentUserOrganizationUsers', undefined, (usersDraft) => {
            return usersDraft.map((userDraft) => {
              return userDraft.id === user.id ? { ...userDraft, active: !user.active } : userDraft
            })
          })
        )
        try {
          await queryFulfilled
        } catch {
          result.undo()
        }
      },
    }),
    updateUserAdminStatus: builder.mutation<void, User>({
      query: (user) => ({
        url: `organizations/admin/${user.username}`,
        method: user.oadmin ? 'DELETE' : 'PUT',
      }),
      async onQueryStarted(user, { dispatch, queryFulfilled }) {
        const result = dispatch(
          accountApi.util.updateQueryData('getCurrentUserOrganizationUsers', undefined, (usersDraft) => {
            return usersDraft.map((userDraft) => {
              return userDraft.id === user.id ? { ...userDraft, oadmin: !user.oadmin } : userDraft
            })
          })
        )
        try {
          await queryFulfilled
        } catch {
          result.undo()
        }
      },
    }),
    inviteNewUser: builder.mutation<{ message?: string }, { organization: Organization; invitedUser: InvitedUser }>({
      query: ({ organization, invitedUser }) => ({
        url: 'organizations/users/add',
        method: 'POST',
        body: {
          id: organization.id,
          email: invitedUser.email,
          password: invitedUser.password,
          firstName: invitedUser.firstName,
          lastName: invitedUser.lastName,
          title: invitedUser.title,
          active: true,
        },
      }),
      invalidatesTags: () => [{ type: TAG_TYPE_ORGANIZATION_USERS }],
    }),
    getCurrentUserInvites: builder.query<UserInvite[], void>({
      query: () => 'organization_invites/user',
      providesTags: (result) =>
        result ? [...result.map((invite) => ({ type: TAG_TYPE_USER_INVITATION, id: invite.id })), TAG_TYPE_USER_INVITATION] : [TAG_TYPE_USER_INVITATION],
    }),
    getCurrentOrganizationUserInvites: builder.query<UserInvite[], void>({
      query: () => 'organization_invites/organization',
      providesTags: (result) =>
        result ? [...result.map((invite) => ({ type: TAG_TYPE_USER_INVITATION, id: invite.id })), TAG_TYPE_USER_INVITATION] : [TAG_TYPE_USER_INVITATION],
    }),
    deleteOrganizationUserInvite: builder.mutation<string, string>({
      query: (invitationId) => ({
        url: `organization_invites/cancel/${invitationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (response, error, inviteId) => [{ type: TAG_TYPE_USER_INVITATION, inviteId }],
    }),
    acceptOrganizationUserInvite: builder.mutation<string, string>({
      query: (invitationId) => ({
        url: `organization_invites/accept/${invitationId}`,
        method: 'POST',
      }),
      invalidatesTags: (response, error, inviteId) => [{ type: TAG_TYPE_USER_INVITATION, inviteId }],
    }),
    saveLoginInfo: builder.mutation<{ isfirst: boolean }, { userAgent: string }>({
      query: (body) => ({
        url: 'users/login',
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const unauthenticatedAccountApi = createApi({
  reducerPath: 'unauthenticatedAccountApi',
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_ACCOUNT,
  }),
  endpoints: (builder) => ({
    registerUser: builder.mutation<void, RegisterUser>({
      query: (user) => ({
        url: '/public/register',
        method: 'POST',
        body: user,
      }),
    }),
  }),
})

// Export hooks to use in function components
export const {
  useGetCurrentUserOrganizationQuery,
  useUpdateCurrentUserOrganizationMutation,
  useGetCurrentUserOrganizationUsersQuery,
  useUpdateUserActiveStatusMutation,
  useUpdateUserAdminStatusMutation,
  useInviteNewUserMutation,
  useRemoveCurrentUserOrganizationUserMutation,
  useGetCurrentUserInvitesQuery,
  useDeleteOrganizationUserInviteMutation,
  useAcceptOrganizationUserInviteMutation,
  useResetCurrentUserOrganizationUserPasswordMutation,
  useGetCurrentOrganizationUserInvitesQuery,
  useSaveLoginInfoMutation,
} = accountApi

export const { useRegisterUserMutation } = unauthenticatedAccountApi
