import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Chip, CircularProgress, Grid, Skeleton, Typography } from '@mui/material'

import { Game, SelectableGameIconWithTooltip } from '../../features/game'
import { ILockedAccessGame, LockedAccessGame } from '../../features/game/types/Game'
import { useInitialGameFeatureState } from '../../features/implementation-examples/hooks/useInitialState'
import { useCurrentMarket } from '../../features/markets'
import { displaySnackBar } from '../../features/snackbar'
import { useAppDispatch } from '../../hooks/storeHooks'
import { LockedFeatureId } from '../../types/LockedFeature'
import { LockedFeature } from '../LockedFeature/LockedFeature'
import ShareUrlButton from '../ShareUrlButton/ShareUrlButton'
import './MultiGameSelector.scss'

interface ISelectableGame extends LockedAccessGame {
  selected?: boolean
}

interface SelectorProps {
  loading?: boolean
  maxSelected?: number
  onClear?: () => void
  options?: ILockedAccessGame[]
  setSelectedGames: (selected: Game[]) => void
  selectedGames: Game[]
  lockedFeatureId?: LockedFeatureId
  skipInitGameIds?: boolean
  sortCriteria: string
}

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Efficiency not tested or optimized for over 1000+ items.
 *
 * @param loading to show progress set to 'true'
 * @param onChangeSelected triggers on change
 * @param options list of available games
 */
const SHOW_AMOUNT_STEP = 32
const MIN_AMOUNT = 32

const MultiGameSelector: React.FC<SelectorProps> = ({
  loading,
  maxSelected,
  onClear,
  options,
  selectedGames,
  setSelectedGames,
  lockedFeatureId,
  skipInitGameIds,
  sortCriteria,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [initialSelection, setInitialSelection] = useInitialGameFeatureState()
  const [showAmount, setShowAmount] = useState<number>(60)
  const [lockedFeatureDialogOpen, setLockedFeatureDialogOpen] = useState<boolean>(false)

  const [initGameIds, setInitGameIds] = useState<string[]>([])
  const { currentMarketIso: marketIso } = useCurrentMarket()

  useEffect(() => {
    if (!skipInitGameIds && initialSelection && (initialSelection.gameIds || initialSelection.gameId)) {
      const gameId = initialSelection.gameId ? [initialSelection.gameId] : []
      const gameIdsToInit = [...(initialSelection.gameIds || []), ...gameId]
      setInitGameIds(gameIdsToInit)
    }
  }, [initialSelection, skipInitGameIds])

  const sortedOptions = useMemo(() => {
    if (!options) return []

    // sort by initial game ids
    if (initGameIds.length) {
      options.sort((a, b) => initGameIds.indexOf(b.id) - initGameIds.indexOf(a.id))
    }

    switch (sortCriteria) {
      case 'sustained_grossing_rank': {
        // sort by srank from lowest to highest
        return options.sort((a, b) => a.getSustainedGrossingRankForMarket(marketIso) - b.getSustainedGrossingRankForMarket(marketIso))
      }
      case 'sustained_download_rank': {
        // sort by sdrank from lowest to highest
        return options.sort((a, b) => a.getSustainedDownloadRankForMarket(marketIso) - b.getSustainedDownloadRankForMarket(marketIso))
      }
      case 'name': {
        // sort by name
        return options.sort((a, b) => a.resolvedName.localeCompare(b.resolvedName))
      }
      case 'locked_access': {
        // sort by locked access
        return options.sort((a, b) => {
          if (a.lockedAccess && !b.lockedAccess) return 1
          if (!a.lockedAccess && b.lockedAccess) return -1
          return 0
        })
      }
      default:
        return options
    }
  }, [options, sortCriteria, initGameIds, marketIso])

  const [games, setGames] = useState<ISelectableGame[] | null>(sortedOptions || null)

  const updateSelectedGames = (updatedGames: ISelectableGame[], selectedIds: string[]) => {
    if (!updatedGames) return

    const selectedIdSet = new Set(selectedIds)
    const initGameIdSet = new Set(initGameIds)

    const updatedGameList = updatedGames.map((game) => ({
      ...game,
      selected: selectedIdSet.has(game.id),
    }))

    updatedGameList.sort((a, b) => {
      const aInitIndex = initGameIdSet.has(a.id) ? initGameIds.indexOf(a.id) : -1
      const bInitIndex = initGameIdSet.has(b.id) ? initGameIds.indexOf(b.id) : -1

      return bInitIndex - aInitIndex
    })

    setGames(updatedGameList as ISelectableGame[])
  }

  const updateGames = (updatedGames: ISelectableGame[]) => {
    const selectedIds = selectedGames.map(({ id }) => id)
    updateSelectedGames(updatedGames, selectedIds)
  }

  useEffect(() => {
    games && updateGames(games)
  }, [selectedGames])

  useEffect(() => {
    if (!sortedOptions || sortedOptions.length === 0) {
      updateGames(sortedOptions as ISelectableGame[])
      return
    }

    if (initialSelection?.gameIds || initialSelection?.gameId) {
      const combinedGameIds = [...(initialSelection.gameIds || []), ...(initialSelection.gameId ? [initialSelection.gameId] : [])]
      updateSelectedGames(sortedOptions, combinedGameIds)
      setInitialSelection(undefined)
      const initialGame = sortedOptions.find(({ id }) => combinedGameIds?.includes(id))
      if (initialGame) {
        setSelectedGames([initialGame])
      }
    } else {
      updateGames(sortedOptions as ISelectableGame[])
    }
  }, [sortedOptions])

  const onSelectAll = () => {
    setSelectedGames(games ? games : [])
  }

  const onClearSelected = () => {
    onClear ? onClear() : setSelectedGames([])
  }

  const onClickGame = (index: number) => {
    const game = (games as ISelectableGame[])[index]
    if (maxSelected && selectedGames?.length >= maxSelected && !game.selected) {
      const message = t('select-game:maximum_game_amount_selected')
      const severity = 'warning'
      dispatch(displaySnackBar({ message, severity, open: true }))
      return
    }
    const newSelection = game.selected ? selectedGames.filter(({ id }) => game.id !== id) : [...selectedGames, game]
    setSelectedGames(newSelection)
  }

  const onShowLess = () => {
    setShowAmount(Math.max(showAmount - SHOW_AMOUNT_STEP, MIN_AMOUNT))
  }

  const onShowMore = () => {
    let newAmount = showAmount + SHOW_AMOUNT_STEP
    if (sortedOptions) {
      newAmount = Math.min(sortedOptions.length, newAmount)
    }
    setShowAmount(newAmount)
  }

  return (
    <>
      <Card className="MultiGameSelector">
        <CardContent sx={{ mr: -1 }}>
          {loading || !games ? (
            <>
              <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
                <Box display="flex" flexWrap="wrap">
                  {[...Array(40)].map((_, colIndex) => (
                    <Box key={colIndex} className="selectableGameIconWitHTooltip">
                      <Skeleton variant="rounded" width="62px" height="62px" sx={{ borderRadius: '22%' }} />
                    </Box>
                  ))}
                </Box>
                <Chip
                  label={t('common:this_may_take_a_while_please_wait')}
                  variant="filled"
                  avatar={<CircularProgress size={14} />}
                  sx={{
                    position: 'absolute',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    color: '#273143',
                    border: '1px solid #ddd',
                    '& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                      fontSize: '14px',
                    },
                  }}
                />
              </Box>
            </>
          ) : games?.length === 0 ? (
            <Typography>{t('common:no_games_available')}</Typography>
          ) : (
            games?.slice(0, showAmount).map((game, index) => (
              <SelectableGameIconWithTooltip
                locked={game.lockedAccess}
                key={index}
                game={game}
                onClick={() => {
                  if (game.lockedAccess) {
                    setLockedFeatureDialogOpen(true)
                    return
                  }

                  onClickGame(index)
                }}
              />
            ))
          )}
        </CardContent>
        <CardActions>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={5}>
              <ButtonGroup variant="contained">
                <Button onClick={() => onClearSelected()} disabled={loading || !selectedGames?.length}>
                  {t('common:clear_selected')}
                  {selectedGames?.length > 0 && <span className="amount">{selectedGames.length}</span>}
                </Button>
                {!maxSelected && (
                  <Button onClick={() => onSelectAll()} disabled={selectedGames?.length === sortedOptions?.length}>
                    {t('common:select_all')}
                  </Button>
                )}
                <ShareUrlButton />
              </ButtonGroup>
            </Grid>
            <Grid item xs={2}>
              {sortedOptions && (
                <Typography ml={1}>
                  {t('common:games')} {Math.min(showAmount, sortedOptions.length)}/{sortedOptions.length}
                </Typography>
              )}
            </Grid>
            <Grid item xs={5} textAlign="right">
              <ButtonGroup variant="outlined">
                <Button
                  color="secondary"
                  onClick={onShowLess}
                  disabled={!sortedOptions || loading || showAmount <= MIN_AMOUNT || sortedOptions?.length < MIN_AMOUNT || sortedOptions?.length === 0}
                >
                  {t('common:show_less')}
                </Button>
                <Button color="secondary" onClick={onShowMore} disabled={!sortedOptions || loading || (sortedOptions && showAmount >= sortedOptions.length)}>
                  {t('common:show_more')}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {lockedFeatureId && (
        <LockedFeature.Dialog lockedFeatureId={lockedFeatureId} open={lockedFeatureDialogOpen} onClose={() => setLockedFeatureDialogOpen(false)} />
      )}
    </>
  )
}

export default MultiGameSelector
