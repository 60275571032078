import { PricePoint } from '../../../internal/features/live-events-tracking/types/PricePoint'
import { Currency } from '../../../internal/types/Currency'
import { getPricePointUnitValue } from '../utils/utils'

export const liveEventPriceRange = (pricePoints?: PricePoint[], currencies?: Currency[]) => {
  if (pricePoints === undefined || currencies === undefined) return null
  const { minPricePointValue, maxPricePointValue } = pricePoints?.reduce(
    (acc, pricePoint) => {
      const unitPrice = getPricePointUnitValue(pricePoint, currencies)
      if (unitPrice) {
        return {
          minPricePointValue: Math.min(acc.minPricePointValue, unitPrice.value),
          maxPricePointValue: Math.max(acc.maxPricePointValue, unitPrice.value),
        }
      }
      const roundedUsdValue = Math.round(pricePoint.value * 100) / 100
      return {
        minPricePointValue: Math.min(acc.minPricePointValue, roundedUsdValue),
        maxPricePointValue: Math.max(acc.maxPricePointValue, roundedUsdValue),
      }
    },
    { minPricePointValue: Number.POSITIVE_INFINITY, maxPricePointValue: Number.NEGATIVE_INFINITY }
  ) || { minPricePointValue: Number.POSITIVE_INFINITY, maxPricePointValue: Number.NEGATIVE_INFINITY }
  if (minPricePointValue === Number.POSITIVE_INFINITY && maxPricePointValue === Number.NEGATIVE_INFINITY) return null
  return { minPricePointValue, maxPricePointValue }
}
