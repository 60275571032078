import { Interval } from 'date-fns'
import { ReactNode, FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Link, Typography } from '@mui/material'

import { MaskedTextGuard } from '../../../../../../components/MaskedTextGuard/MaskedTextGuard'
import { useLiveEventsTrackerAccessCheck } from '../../../../../account/hooks/roleHooks'
import { useDateTimeFormatter } from '../../../../../live-events/hooks/useDateTimeFormatter'

type LiveEventSubHeaderProps = {
  linkLabel?: string
  interval?: Interval
  date?: number | Date
  onLinkClick?: () => void
  children?: ReactNode
}

export const LiveEventSubHeader: FC<LiveEventSubHeaderProps> = ({ linkLabel, interval, date, onLinkClick, children }) => {
  const { t } = useTranslation()
  const hasAccessToLiveEventsTracker = useLiveEventsTrackerAccessCheck()
  const dateFormatter = useDateTimeFormatter({ year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' })
  const formattedDate = dateFormatter.format(date)
  const formattedInterval = t('common:interval_from_to', { from: dateFormatter.format(interval?.start), to: dateFormatter.format(interval?.end) })

  return (
    <Box className="LiveEventSubHeader">
      <Typography>
        <MaskedTextGuard reveal={hasAccessToLiveEventsTracker} text={linkLabel}>
          {onLinkClick ? (
            <Link underline="none" onClick={onLinkClick} sx={{ fontSize: '17px', fontWeight: 'bold' }}>
              {linkLabel}
            </Link>
          ) : (
            linkLabel
          )}
        </MaskedTextGuard>
      </Typography>
      {children}
      {interval && (
        <Typography variant="body1">
          <MaskedTextGuard reveal={hasAccessToLiveEventsTracker} text={formattedInterval} />
        </Typography>
      )}
      {date && (
        <Typography variant="body1">
          <MaskedTextGuard reveal={hasAccessToLiveEventsTracker} text={formattedDate} />
        </Typography>
      )}
    </Box>
  )
}
