import DOMPurify from 'dompurify'

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { prepareDefaultHeaders } from '.'
import { AIAnalystAnswer, createAIAnalystAnswer } from '../features/ai-analyst/types/AIAnalystAnswer'

const REDUCER_PATH = 'aiAnalystApi'

export interface GetAIAnalystAnswerQueryParams {
  prompt: string
}

export const aiAnalystApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: fetchBaseQuery({
    baseUrl: window.GR_API_URLS.API_URL_AI_ANALYST,
    prepareHeaders: prepareDefaultHeaders,
  }),
  endpoints: (builder) => ({
    getAIAnalystAnswer: builder.query<AIAnalystAnswer[], GetAIAnalystAnswerQueryParams>({
      query: ({ prompt }) => ({
        url: `/conversationQuery`,
        params: {
          prompt,
        },
      }),
      transformResponse: (response: AIAnalystAnswer[]) => {
        // trim the answers to remove any trailing whitespace
        return response.map((aiAnalystAnswer) => {
          aiAnalystAnswer.answer = DOMPurify.sanitize(aiAnalystAnswer.answer.trim())
          return createAIAnalystAnswer(aiAnalystAnswer)
        })
      },
      transformErrorResponse: (error) => {
        const data = {
          prompt: '',
          source: '',
          answer: `Sorry, something went wrong. Please try again later.`,
        }
        return createAIAnalystAnswer(data as AIAnalystAnswer)
      },
    }),
  }),
})

export const { useGetAIAnalystAnswerQuery } = aiAnalystApi
