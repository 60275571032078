import { useMemo } from 'react'
import { TFunction } from 'react-i18next'

import { TrendingHistory, TrendingResponse } from '../../../api/core'
import { MarketTrendGraphData } from '../components'
import { movingAverage } from '../util/calculations'
import { TrendHistories } from './useMarketTrendHooks'

type GraphSorterConstructor = (sortBy: string) => (d1: MarketTrendGraphData, d2: MarketTrendGraphData) => number

const graphSorter: GraphSorterConstructor = (sortBy) => (d1, d2) => {
  const t1 = d1.change?.top || 0
  const t2 = d2.change?.top || 0
  return (t1 - t2) * (sortBy === 'up' ? -1 : 1)
}

type UseTrendGraph = (
  t: TFunction<'translation', undefined>,
  chartsPerPage: number,
  currentPage: number,
  top: number,
  sortBy: string,
  trendData?: TrendingResponse[],
  trendHistories?: TrendHistories
) => MarketTrendGraphData[] | undefined
/**
 * The line graph calculations.
 *
 * @param chartsPerPage
 * @param currentPage
 * @param topAmount
 * @param sortBy
 * @param trendData
 * @param trendHistories
 */
export const useTrendGraph: UseTrendGraph = (t, chartsPerPage, currentPage, top, sortBy, trendData, trendHistories) => {
  return useMemo(() => {
    if (!trendData || !trendHistories) {
      return undefined
    }
    const { histories, minmaxFeatures, popularFeatures } = trendData[0]
    const percentGroup = `${top}Percent`
    const pData: TrendingHistory = histories ? histories[percentGroup] : {}
    const oData: TrendingHistory = histories ? histories[`Outside${percentGroup}`] : {}

    const values: MarketTrendGraphData[] = Object.keys(trendHistories).map((weightId) => {
      const { choice, name } = trendHistories[weightId]
      const labels = pData[weightId]?.map(({ ts }) => ts)
      const topValues = movingAverage(
        14,
        0,
        pData[weightId]?.map(({ value }) => value)
      )
      const outValues = movingAverage(
        14,
        0,
        oData[weightId]?.map(({ value }) => value)
      )
      const change =
        topValues && outValues
          ? {
              top: topValues[Math.max(0, topValues.length - 1)] - topValues[0],
              out: outValues[Math.max(0, outValues.length - 1)] - outValues[0],
            }
          : undefined
      const datasets = [
        {
          backgroundColor: '#ffcc99',
          borderColor: '#bd65cf',
          data: topValues || [],
          label: t(`common:top_${top}_popularity_percent_short`),
          yAxisID: 'y1',
        },
        {
          borderColor: '#c7d5e1',
          data: outValues || [],
          label: t(`common:top_${top}_popularity_out_percent_short`),
          yAxisID: 'y1',
        },
      ]
      const popularFeature = popularFeatures.find(({ choiceLegacyId }) => choiceLegacyId === Number(weightId))
      const minmaxFeature = minmaxFeatures[popularFeature?.choiceLegacyId || '']
      const gamesInSet = popularFeature?.gamesInSet[percentGroup] || 0
      const feature = popularFeature
        ? {
            choiceId: popularFeature.choiceId,
            choiceLegacyId: popularFeature.choiceLegacyId,
            choiceName: name,
            featureId: popularFeature.id,
            featureLegacyId: popularFeature.featureLegacyId,
            featureName: popularFeature.featureLabel,
            legacyId: popularFeature.featureLegacyId,
          }
        : undefined
      return {
        avgEffect: minmaxFeature?.avgEffect,
        change,
        chart: { labels, datasets },
        choice,
        feature,
        gamesInSet,
        name,
      }
    })
    const startIndex = (currentPage - 1) * chartsPerPage
    const endIndex = Math.min(startIndex + chartsPerPage, values.length)

    const sorter = graphSorter(sortBy)
    const filterValues = values.filter((value) => value.avgEffect !== undefined)

    return filterValues.sort(sorter).slice(startIndex, endIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendData, trendHistories, currentPage, sortBy])
}
