import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// internal localization imports (en only)
import internal_common_en from './internal/locales/en/internal-common.json'
import internal_live_events_en from './internal/locales/en/internal-live-events.json'
import internal_sidebar_en from './internal/locales/en/internal-sidebar.json'
// English imports
import analysis_en from './locales/en/analysis.json'
import bookmarks_en from './locales/en/bookmarks.json'
import buy_more_credits_en from './locales/en/buy-more-credits.json'
import common_en from './locales/en/common.json'
import compare_en from './locales/en/compare.json'
import data_glosasary_en from './locales/en/data-glossary.json'
import export_csv_en from './locales/en/export-csv.json'
import feature_descriptions_en from './locales/en/feature-descriptions.json'
import feature_select_modal_en from './locales/en/feature-select-modal.json'
import feature_en from './locales/en/feature.json'
import followed_games_en from './locales/en/followed-games.json'
import ftue_videos_en from './locales/en/ftue-videos.json'
import game_analyzer_en from './locales/en/game-analyzer.json'
import game_edit_en from './locales/en/game-edit.json'
import game_header_en from './locales/en/game-header.json'
import game_platforms_en from './locales/en/game-platforms.json'
import genre_essentials_en from './locales/en/genre-essentials.json'
import gps_breakdown_en from './locales/en/gps-breakdown.json'
import implementations_en from './locales/en/implementations.json'
import job_functions_en from './locales/en/job-functions.json'
import live_events_en from './locales/en/live-events.json'
import locked_feature_en from './locales/en/locked-feature.json'
import login_en from './locales/en/login.json'
import market_explorer_en from './locales/en/market-explorer.json'
import market_share_en from './locales/en/market-share.json'
import motivations_en from './locales/en/motivations.json'
import my_games_en from './locales/en/my-games.json'
import new_game_en from './locales/en/new-game.json'
import news_titles_en from './locales/en/news-titles.json'
import newsfeed_options_en from './locales/en/newsfeed-options.json'
import newsfeed_en from './locales/en/newsfeed.json'
import onboarding_en from './locales/en/onboarding.json'
import organization_en from './locales/en/organization.json'
import overview_en from './locales/en/overview.json'
import plans_en from './locales/en/plans.json'
import predefined_segments_en from './locales/en/predefined-segments.json'
import profile_en from './locales/en/profile.json'
import reports_en from './locales/en/reports.json'
import reviews_en from './locales/en/reviews.json'
import segment_en from './locales/en/segment.json'
import select_game_en from './locales/en/select-game.json'
import services_en from './locales/en/services.json'
import sidebar_en from './locales/en/sidebar.json'
import soft_lauunch_games_en from './locales/en/soft-launch-games.json'
import tags_list_en from './locales/en/tags-list.json'
import top_games_en from './locales/en/top-games.json'
import trending_features_en from './locales/en/trending-features.json'
import turbo_search_en from './locales/en/turbo-search.json'
import unlock_feature_ideas_dialog_en from './locales/en/unlock-feature-ideas-dialog.json'
import unlock_game_dialog_en from './locales/en/unlock-game-dialog.json'
import validation_en from './locales/en/validation.json'
import visual_explorer_en from './locales/en/visuals-explorer.json'
// Japanese imports
import analysis_ja from './locales/ja/analysis.json'
import bookmarks_ja from './locales/ja/bookmarks.json'
import buy_more_credits_ja from './locales/ja/buy-more-credits.json'
import common_ja from './locales/ja/common.json'
import compare_ja from './locales/ja/compare.json'
import data_glosasary_ja from './locales/ja/data-glossary.json'
import export_csv_ja from './locales/ja/export-csv.json'
import feature_descriptions_ja from './locales/ja/feature-descriptions.json'
import feature_select_modal_ja from './locales/ja/feature-select-modal.json'
import feature_ja from './locales/ja/feature.json'
import followed_games_ja from './locales/ja/followed-games.json'
import ftue_videos_ja from './locales/ja/ftue-videos.json'
import game_analyzer_ja from './locales/ja/game-analyzer.json'
import game_edit_ja from './locales/ja/game-edit.json'
import game_header_ja from './locales/ja/game-header.json'
import game_platforms_ja from './locales/ja/game-platforms.json'
import genre_essentials_ja from './locales/ja/genre-essentials.json'
import gps_breakdown_ja from './locales/ja/gps-breakdown.json'
import implementations_ja from './locales/ja/implementations.json'
import job_functions_ja from './locales/ja/job-functions.json'
import live_events_ja from './locales/ja/live-events.json'
import locked_feature_ja from './locales/ja/locked-feature.json'
import login_ja from './locales/ja/login.json'
import market_explorer_ja from './locales/ja/market-explorer.json'
import market_share_ja from './locales/ja/market-share.json'
import motivations_ja from './locales/ja/motivations.json'
import my_games_ja from './locales/ja/my-games.json'
import new_game_ja from './locales/ja/new-game.json'
import news_titles_ja from './locales/ja/news-titles.json'
import newsfeed_options_ja from './locales/ja/newsfeed-options.json'
import newsfeed_ja from './locales/ja/newsfeed.json'
import onboarding_ja from './locales/ja/onboarding.json'
import organization_ja from './locales/ja/organization.json'
import overview_ja from './locales/ja/overview.json'
import plans_ja from './locales/ja/plans.json'
import predefined_segments_ja from './locales/ja/predefined-segments.json'
import profile_ja from './locales/ja/profile.json'
import reports_ja from './locales/ja/reports.json'
import reviews_ja from './locales/ja/reviews.json'
import segment_ja from './locales/ja/segment.json'
import select_game_ja from './locales/ja/select-game.json'
import services_ja from './locales/ja/services.json'
import sidebar_ja from './locales/ja/sidebar.json'
import soft_lauunch_games_ja from './locales/ja/soft-launch-games.json'
import tags_list_ja from './locales/ja/tags-list.json'
import top_games_ja from './locales/ja/top-games.json'
import trending_features_ja from './locales/ja/trending-features.json'
import turbo_search_ja from './locales/ja/turbo-search.json'
import unlock_feature_ideas_dialog_ja from './locales/ja/unlock-feature-ideas-dialog.json'
import unlock_game_dialog_ja from './locales/ja/unlock-game-dialog.json'
import validation_ja from './locales/ja/validation.json'
import visual_explorer_ja from './locales/ja/visuals-explorer.json'
// Chinese imports
import analysis_zh from './locales/zh/analysis.json'
import bookmarks_zh from './locales/zh/bookmarks.json'
import buy_more_credits_zh from './locales/zh/buy-more-credits.json'
import common_zh from './locales/zh/common.json'
import compare_zh from './locales/zh/compare.json'
import data_glosasary_zh from './locales/zh/data-glossary.json'
import export_csv_zh from './locales/zh/export-csv.json'
import feature_descriptions_zh from './locales/zh/feature-descriptions.json'
import feature_select_modal_zh from './locales/zh/feature-select-modal.json'
import feature_zh from './locales/zh/feature.json'
import followed_games_zh from './locales/zh/followed-games.json'
import ftue_videos_zh from './locales/zh/ftue-videos.json'
import game_analyzer_zh from './locales/zh/game-analyzer.json'
import game_edit_zh from './locales/zh/game-edit.json'
import game_header_zh from './locales/zh/game-header.json'
import game_platforms_zh from './locales/zh/game-platforms.json'
import genre_essentials_zh from './locales/zh/genre-essentials.json'
import gps_breakdown_zh from './locales/zh/gps-breakdown.json'
import implementations_zh from './locales/zh/implementations.json'
import job_functions_zh from './locales/zh/job-functions.json'
import live_events_zh from './locales/zh/live-events.json'
import locked_feature_zh from './locales/zh/locked-feature.json'
import login_zh from './locales/zh/login.json'
import market_explorer_zh from './locales/zh/market-explorer.json'
import market_share_zh from './locales/zh/market-share.json'
import motivations_zh from './locales/zh/motivations.json'
import my_games_zh from './locales/zh/my-games.json'
import new_game_zh from './locales/zh/new-game.json'
import news_titles_zh from './locales/zh/news-titles.json'
import newsfeed_options_zh from './locales/zh/newsfeed-options.json'
import newsfeed_zh from './locales/zh/newsfeed.json'
import onboarding_zh from './locales/zh/onboarding.json'
import organization_zh from './locales/zh/organization.json'
import overview_zh from './locales/zh/overview.json'
import plans_zh from './locales/zh/plans.json'
import predefined_segments_zh from './locales/zh/predefined-segments.json'
import profile_zh from './locales/zh/profile.json'
import reports_zh from './locales/zh/reports.json'
import reviews_zh from './locales/zh/reviews.json'
import segment_zh from './locales/zh/segment.json'
import select_game_zh from './locales/zh/select-game.json'
import services_zh from './locales/zh/services.json'
import sidebar_zh from './locales/zh/sidebar.json'
import soft_lauunch_games_zh from './locales/zh/soft-launch-games.json'
import tags_list_zh from './locales/zh/tags-list.json'
import top_games_zh from './locales/zh/top-games.json'
import trending_features_zh from './locales/zh/trending-features.json'
import turbo_search_zh from './locales/zh/turbo-search.json'
import unlock_feature_ideas_dialog_zh from './locales/zh/unlock-feature-ideas-dialog.json'
import unlock_game_dialog_zh from './locales/zh/unlock-game-dialog.json'
import validation_zh from './locales/zh/validation.json'
import visual_explorer_zh from './locales/zh/visuals-explorer.json'

const japanese_translations = Object.assign(
  {},
  analysis_ja,
  bookmarks_ja,
  buy_more_credits_ja,
  common_ja,
  compare_ja,
  data_glosasary_ja,
  export_csv_ja,
  feature_descriptions_ja,
  feature_select_modal_ja,
  feature_ja,
  followed_games_ja,
  ftue_videos_ja,
  game_edit_ja,
  game_header_ja,
  game_analyzer_ja,
  genre_essentials_ja,
  gps_breakdown_ja,
  implementations_ja,
  job_functions_ja,
  locked_feature_ja,
  login_ja,
  market_explorer_ja,
  market_share_ja,
  motivations_ja,
  my_games_ja,
  new_game_ja,
  news_titles_ja,
  newsfeed_options_ja,
  newsfeed_ja,
  onboarding_ja,
  organization_ja,
  overview_ja,
  plans_ja,
  predefined_segments_ja,
  profile_ja,
  reports_ja,
  reviews_ja,
  segment_ja,
  select_game_ja,
  services_ja,
  sidebar_ja,
  soft_lauunch_games_ja,
  tags_list_ja,
  top_games_ja,
  trending_features_ja,
  turbo_search_ja,
  unlock_feature_ideas_dialog_ja,
  unlock_game_dialog_ja,
  validation_ja,
  visual_explorer_ja,
  live_events_ja,
  game_platforms_ja
)
const english_translations = Object.assign(
  {},
  analysis_en,
  bookmarks_en,
  buy_more_credits_en,
  common_en,
  compare_en,
  data_glosasary_en,
  export_csv_en,
  feature_descriptions_en,
  feature_select_modal_en,
  feature_en,
  followed_games_en,
  ftue_videos_en,
  game_edit_en,
  game_header_en,
  game_analyzer_en,
  genre_essentials_en,
  gps_breakdown_en,
  implementations_en,
  job_functions_en,
  locked_feature_en,
  login_en,
  market_explorer_en,
  market_share_en,
  motivations_en,
  my_games_en,
  new_game_en,
  news_titles_en,
  newsfeed_options_en,
  newsfeed_en,
  onboarding_en,
  organization_en,
  overview_en,
  plans_en,
  predefined_segments_en,
  profile_en,
  reports_en,
  reviews_en,
  segment_en,
  select_game_en,
  services_en,
  sidebar_en,
  soft_lauunch_games_en,
  tags_list_en,
  top_games_en,
  trending_features_en,
  turbo_search_en,
  unlock_feature_ideas_dialog_en,
  unlock_game_dialog_en,
  validation_en,
  visual_explorer_en,
  live_events_en,
  game_platforms_en,
  internal_sidebar_en,
  internal_common_en,
  internal_live_events_en,
  internal_sidebar_en
)
const chinese_translations = Object.assign(
  {},
  analysis_zh,
  bookmarks_zh,
  buy_more_credits_zh,
  common_zh,
  compare_zh,
  data_glosasary_zh,
  export_csv_zh,
  feature_descriptions_zh,
  feature_select_modal_zh,
  feature_zh,
  followed_games_zh,
  ftue_videos_zh,
  game_edit_zh,
  game_header_zh,
  genre_essentials_zh,
  game_analyzer_zh,
  gps_breakdown_zh,
  implementations_zh,
  job_functions_zh,
  locked_feature_zh,
  login_zh,
  market_explorer_zh,
  market_share_zh,
  motivations_zh,
  my_games_zh,
  new_game_zh,
  news_titles_zh,
  newsfeed_options_zh,
  newsfeed_zh,
  onboarding_zh,
  organization_zh,
  overview_zh,
  plans_zh,
  predefined_segments_zh,
  profile_zh,
  reports_zh,
  reviews_zh,
  segment_zh,
  select_game_zh,
  services_zh,
  sidebar_zh,
  soft_lauunch_games_zh,
  tags_list_zh,
  top_games_zh,
  trending_features_zh,
  turbo_search_zh,
  unlock_feature_ideas_dialog_zh,
  unlock_game_dialog_zh,
  validation_zh,
  visual_explorer_zh,
  live_events_zh,
  game_platforms_zh
)

// resource bundle
const resources = {
  en: english_translations,
  ja: japanese_translations,
  zh: chinese_translations,
}

// init i18next
i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
