/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog, DialogContent } from '@mui/material'

import { Game } from '../../features/game'
import useGetOtherSubgenreGames from '../../features/game/hooks/useGetOtherSubgenreGames'
import GRDialogTitle from '../GRDialogTitle/GRDialogTitle'
import { GamesTableWith30DRevDlEstimates } from '../GamesTableWith30DRevDlEstimates/GamesTableWith30DRevDlEstimates'
import './OtherSubgenreGamesDialog.scss'

interface OtherSubgenreGamesDialogProps {
  modalOpen: boolean
  game: Game
  marketIso: string
  onClose: () => void
}

const OtherSubgenreGamesDialog: React.FC<OtherSubgenreGamesDialogProps> = ({ modalOpen, game, marketIso, onClose }) => {
  const { t } = useTranslation()
  const { data: otherSubgenreGames, isFetching: isFetchingOtherSubgenreGames } = useGetOtherSubgenreGames(game.conventionalSubgenreId, marketIso, !modalOpen)

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <Dialog className="OtherSubgenreGamesDialog" onClose={handleClose} fullWidth={true} maxWidth="lg" open={modalOpen}>
      <GRDialogTitle onClose={handleClose}>{t('common:subgenre_games_modal_title', { subgenre: game.conventionalSubgenre })}</GRDialogTitle>
      <DialogContent className="DialogContent" dividers>
        <GamesTableWith30DRevDlEstimates games={otherSubgenreGames} marketIso={marketIso} isLoading={isFetchingOtherSubgenreGames} />
      </DialogContent>
    </Dialog>
  )
}

export default OtherSubgenreGamesDialog
