import { useTranslation } from 'react-i18next'

import { useGetUserSettingQuery, useUpdateUserSettingMutation } from '../../../api/combined'
import { useError } from '../../../hooks/useError'
import { UserSettingKeys } from '../../account/types/UserSettings'
import { MarketExplorerGamesChartAxisTypeId } from '../types/MarketExplorerGamesChartAxisType'
import { MarketExplorerSegmentsDTO } from '../types/MarketExplorerSegmentsDTO'
import { MarketExplorerUserSegment } from '../types/MarketExplorerUserSegment'
import { mapDTOToSegmentConfiguration } from '../util/segmentConfigurationMapper'

/**
 * Hooks for reading and writing user segments from / to user settings. When a user arrives to the market explorer
 * page for the first time in the new FE the user segments are read from the legacy user setting key which uses the format of user segments
 * used in the old AngularJS FE. The value from the legacy setting is mapped to a model the current FE understands and saved under a new setting key.
 * Only the new setting key is used after this meaning that this is a one-time operation when users transfer from the old frontend to the new.
 *
 * NOTE: This functionality is difficult to test manually since the mapping is done only once and there is no UI-way to re-read the user segments
 * provided by the old FE.
 */

export const useUserSegments = () => {
  const userSegmentsQueryResult = useGetUserSettingQuery(UserSettingKeys.userSegments, { refetchOnMountOrArgChange: true })
  // try to load legacy user segments only in case the new user segments are not found
  const legacyUserSegmentsQueryResult = useGetUserSettingQuery(UserSettingKeys.legacyUserSegments, {
    refetchOnMountOrArgChange: true,
    skip: userSegmentsQueryResult.isFetching || userSegmentsQueryResult.isLoading || !userSegmentsQueryResult.isSuccess || !!userSegmentsQueryResult.data,
  })
  useError({ error: legacyUserSegmentsQueryResult.error || userSegmentsQueryResult.error })
  const { updateUserSegments } = useUpdateUserSegments()

  const allIsLoaded =
    !userSegmentsQueryResult.isFetching &&
    !userSegmentsQueryResult.isLoading &&
    userSegmentsQueryResult.isSuccess &&
    !legacyUserSegmentsQueryResult.isFetching &&
    !legacyUserSegmentsQueryResult.isLoading &&
    legacyUserSegmentsQueryResult.isSuccess

  // If we find data under legacy settings key we will map the data to new format and save it under a new settigns key.
  // After this we will alwys just use the new settings key for loading and saving the user segments
  if (allIsLoaded) {
    if (legacyUserSegmentsQueryResult.data) {
      // map the legacy format user segments data to new format and save it under a new settings key
      const mappedUserSegments = ((legacyUserSegmentsQueryResult.data as MarketExplorerSegmentsDTO[]) || []).map((userSegment) =>
        mapDTOToSegmentConfiguration(userSegment)
      )
      updateUserSegments(mappedUserSegments)
    }
  }

  return {
    ...userSegmentsQueryResult,
    data: (userSegmentsQueryResult.data || []) as MarketExplorerUserSegment[],
    isLoading: userSegmentsQueryResult.isLoading || legacyUserSegmentsQueryResult.isLoading,
    isFetching: userSegmentsQueryResult.isFetching || legacyUserSegmentsQueryResult.isFetching,
  }
}

export const useUpdateUserSegments = () => {
  const [updateUserSetting, query] = useUpdateUserSettingMutation()

  return {
    updateUserSegments: (userSegments?: MarketExplorerUserSegment[]) => updateUserSetting({ settingKey: UserSettingKeys.userSegments, value: userSegments }),
    ...query,
  }
}

const defaultFilters = {
  ranks: {},
  demographics: {},
  motivations: {},
  archetypes: {},
}

/**
 * Predefined user segments "Segments by GameRefinery"
 */
export const useSegmentsByGameRefinery = () => {
  const { t } = useTranslation()
  const segmentsByGameRefinery: MarketExplorerUserSegment[] = [
    {
      name: t('predefined-segments:predefined_segment1_name'),
      description: t('predefined-segments:predefined_segment1_description'),
      segments: [
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            154: [439],
            155: [448],
          },
          filters: defaultFilters,
        },
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            154: [442],
            155: [448],
          },
          filters: defaultFilters,
        },
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            154: [444],
            155: [448],
          },
          filters: defaultFilters,
        },
      ],
      chart: {
        yAxisType: MarketExplorerGamesChartAxisTypeId.RevenueDownloadsRatioPastMonth,
        yAxisMin: '75+',
        yAxisMax: '0',
        xAxisType: MarketExplorerGamesChartAxisTypeId.SGR,
        xAxisMin: '1',
        xAxisMax: '500',
      },
    },
    {
      name: t('predefined-segments:predefined_segment2_name'),
      description: t('predefined-segments:predefined_segment2_description'),
      segments: [
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            155: [447],
            295: [1067],
          },
          filters: defaultFilters,
        },
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            154: [440],
            155: [447],
            295: [1066],
          },
          filters: defaultFilters,
        },
      ],
      chart: {
        yAxisType: MarketExplorerGamesChartAxisTypeId.SGR,
        yAxisMin: '1',
        yAxisMax: '200',
        xAxisType: MarketExplorerGamesChartAxisTypeId.RevenueDownloadsRatioPastMonth,
        xAxisMin: '50+',
        xAxisMax: '0',
      },
    },
    {
      name: t('predefined-segments:predefined_segment3_name'),
      description: t('predefined-segments:predefined_segment3_description'),
      segments: [
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            126: [326],
            155: [445, 447],
          },
          filters: defaultFilters,
        },
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            126: [327],
            155: [445, 447],
          },
          filters: defaultFilters,
        },
      ],
      chart: {
        yAxisType: MarketExplorerGamesChartAxisTypeId.RevenueDownloadsRatioPastMonth,
        yAxisMin: '50+',
        yAxisMax: '0',
        xAxisType: MarketExplorerGamesChartAxisTypeId.SGR,
        xAxisMin: '1',
        xAxisMax: '500',
      },
    },
    {
      name: t('predefined-segments:predefined_segment4_name'),
      description: t('predefined-segments:predefined_segment4_description'),
      segments: [
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            155: [445],
          },
          filters: defaultFilters,
        },
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            155: [447],
          },
          filters: defaultFilters,
        },
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            155: [448, 450],
          },
          filters: defaultFilters,
        },
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            155: [456],
          },
          filters: defaultFilters,
        },
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            155: [454],
          },
          filters: defaultFilters,
        },
        {
          marketIso: 'us',
          visible: true,
          featureChoices: {
            155: [451, 452, 453, 455],
          },
          filters: defaultFilters,
        },
      ],
      chart: {
        yAxisType: MarketExplorerGamesChartAxisTypeId.SGR,
        yAxisMin: '1',
        yAxisMax: '200',
        xAxisType: MarketExplorerGamesChartAxisTypeId.DaysSinceRelease,
        xAxisMin: '0',
        xAxisMax: '365',
      },
    },
  ]

  return segmentsByGameRefinery
}
