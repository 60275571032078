import { FC, ReactNode } from 'react'

import { Box } from '@mui/material'

import { GRPopover, GRPopoverProps } from '../../../../components/GRPopover/GRPopover'

/**
 * Specialized popover component for displaying market explorer segment card inside a popover and limit its size and functionality
 */
type MarketExplorerSegmentCardPopoverProps = GRPopoverProps & {
  children?: ReactNode
}

export const MarketExplorerSegmentCardPopover: FC<MarketExplorerSegmentCardPopoverProps> = ({ children, ...popoverProps }) => {
  return (
    <GRPopover component={Box} popperProps={{ style: { zIndex: 1500, maxWidth: '31rem' } }} keepContentOpen placement="auto" {...popoverProps}>
      {children}
    </GRPopover>
  )
}
