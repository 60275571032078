import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import InfoIcon from '@mui/icons-material/Info'
import { Box, Button, Card, CardActions, CardContent, Chip, Divider, FormControlLabel, Grid, Skeleton, Switch, Typography } from '@mui/material'

import { SlackPreference, SlackbotList, useGetSlackDetailsQuery, useSaveSlackbotHookSettingsMutation } from '../../api/feed'
import { CounterButton } from '../../components/CounterButton/CounterButton'
import { GRTooltip } from '../../components/GRTooltip/GRTooltip'
import GameSubgenrePicker from '../../components/GameSubgenrePicker/GameSubgenrePicker'
import { LockedFeature } from '../../components/LockedFeature/LockedFeature'
import { useSoftLaunchAccessCheck } from '../../features/account/hooks/roleHooks'
import { SubgenreMap } from '../../features/account/types/UserSettings'
import ArtistSearchInput from '../../features/artist-search/components/ArtistSearchInput/ArtistSearchInput'
import { Game, GameIcon } from '../../features/game'
import GameSearchDialog, { GameWithMarketIso } from '../../features/game-search/components/GameSearchDialog/GameSearchDialog'
import { GameSearchDialogConfig, GameSearchDialogTab, GameSearchDialogType } from '../../features/game-search/types/GameSearchDialogConfig'
import { useGames } from '../../features/game/hooks/gameHooks'
import { MarketSelector, useCurrentMarket } from '../../features/markets'
import newsEntriesService, { NewsEntryOptionsTypeId } from '../../features/news/services/NewsEntriesService'
import NewEntriesType from '../../features/slack/components/NewsEntriesTypes/NewEntriesType'
import { displaySnackBar } from '../../features/snackbar/actions/displaySnackBar'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import analyticsService from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import { AnalyticsProviders } from '../../types/IAnalyticsProvider'
import { LockedFeatureId } from '../../types/LockedFeature'
import './SlackPreferencesPage.scss'

type SlackPreferencesPageSearchParams = {
  teamId?: string
  channel?: string
  teamName?: string
}

interface NewsEventsOptionProps {
  newsType?: string
  selectedNewsEvent: string
  handleChange: (event: any) => void
}

export const NewsEventsOption: React.FC<NewsEventsOptionProps> = ({ newsType, selectedNewsEvent, handleChange }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ mb: 2 }}>
      <Box sx={{ display: 'inline-flex', alignItems: 'center', mb: '10px' }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>{t('newsfeed:show_news_events')}</Typography>
        <GRTooltip content={t('newsfeed:show_news_events_tooltip')}>
          <InfoIcon sx={{ ml: '4px', display: 'flex' }} color="primary" fontSize="small" />
        </GRTooltip>
      </Box>
      <Box sx={{ paddingLeft: '6px' }}>
        {newsEntriesService.newsOption.map((option, index) => (
          <FormControlLabel
            sx={{ display: 'inline-flex' }}
            key={index}
            label={t<string>(`newsfeed:${option.id}`)}
            control={
              <Switch size="small" checked={option.id === selectedNewsEvent} onChange={handleChange} value={option.id} name={option.id} color="primary" />
            }
          />
        ))}
      </Box>
    </Box>
  )
}

export enum SlackShowSelectedType {
  onlyGameType = 'only_game_type',
  includeFollowedGames = 'include_followed_games',
  onlyFollowedGames = 'only_followed_games',
}

const SlackPreferencesPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [gameSearchModalOpen, setGameSearchModalOpen] = useState(false)
  const newsEntryOptionTypes = useMemo(() => newsEntriesService.getMappedWithLockedFeature(), [])
  const [saveSlackSettings, saveSlackSettingResponse] = useSaveSlackbotHookSettingsMutation()

  useDocumentTitle(t('newsfeed:slack_preferences'))
  usePage(PageService.getPageWithId('slack-preferences'), 'Visited Slack Preferences')

  const params: SlackPreferencesPageSearchParams = useParams()

  const { data: slackDetails, isFetching: isSlackDetailsFetching } = useGetSlackDetailsQuery(
    { teamId: params.teamId || '', channel: encodeURIComponent(params.channel || '') },
    { skip: !params.teamId || !params.channel }
  )

  const initNewsEntryTypesObj = useMemo(() => {
    const selectedOptionTypes = newsEntriesService.getTypeIdByTypes(slackDetails?.eventTypes)

    return newsEntryOptionTypes.reduce((result, optionType) => {
      result[optionType.id] = selectedOptionTypes.includes(optionType.id)
      return result
    }, {} as { [k: string]: boolean })
  }, [newsEntryOptionTypes, slackDetails])

  const [selectedSlack, setSelectedSlack] = useState<SlackbotList | undefined>()
  const [selectedSubgenresMap, setSelectedSubgenresMap] = useState({} as SubgenreMap)

  const [selectedGames, setSelectedGames] = useState<Game[]>([])
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const [selectedMarket, setselectedMarket] = useState(marketIso)
  const [selectedEvent, setSelectedEvent] = useState(SlackShowSelectedType.onlyGameType)
  const [selectedNewsEntryTypes, setSelectedNewsEntryTypes] = useState(initNewsEntryTypesObj)
  const [lockedFeatureDialogType, setLockedFeatureDialogType] = useState('')
  const [lockedFeatureDialogOpen, setLockedFeatureDialogOpen] = useState(false)
  const hasAccessToSoftLaunch = useSoftLaunchAccessCheck()
  const { games: gamesData, isLoading: isGameLoading } = useGames(slackDetails?.gameIds || [], selectedMarket || '', false, true)

  const handleGoBack = useCallback(() => {
    navigate(`/slack`)
  }, [navigate])

  const selectedTypesByTypeIds = useMemo(() => {
    const typeIds = Object.entries(selectedNewsEntryTypes)
      .filter(([key, value]) => !!value)
      .map(([key]) => key) as NewsEntryOptionsTypeId[]
    return newsEntriesService.getTypesByTypeId(typeIds)
  }, [selectedNewsEntryTypes])

  const gameSearchDialogConfig = useMemo<GameSearchDialogConfig>((): GameSearchDialogConfig => {
    const tabs = [GameSearchDialogTab.Search]

    if (hasAccessToSoftLaunch) {
      tabs.push(GameSearchDialogTab.SoftLaunch)
    }

    return {
      type: GameSearchDialogType.GameSelector,
      tabs: tabs,
      gameSelectorCounts: { max: 100, min: 0 },
      showNonAnalyzedGames: true,
    }
  }, [hasAccessToSoftLaunch])

  useEffect(() => {
    if (slackDetails !== undefined && params.teamName !== undefined) {
      setSelectedSlack({
        teamId: slackDetails.teamId,
        teamName: params.teamName,
        channel: slackDetails.channel,
      })

      // Market selection
      setselectedMarket(slackDetails.market)

      // Subgenre selection
      if (slackDetails.subgenres) {
        const subgenresMap = slackDetails.subgenres?.reduce((result, subgenre) => {
          result[subgenre] = true
          return result
        }, {} as SubgenreMap)

        setSelectedSubgenresMap(subgenresMap)
      }

      // Event selection
      if (slackDetails.filterType) {
        setSelectedEvent(slackDetails.filterType as SlackShowSelectedType)
      }

      // Organizations
      if (slackDetails.artists) {
        setSelectedOrganizations(slackDetails.artists)
      }

      // News entry types selection
      if (slackDetails.eventTypes) {
        const selectedOptionTypes = newsEntriesService.getTypeIdByTypes(slackDetails?.eventTypes)

        const types = newsEntryOptionTypes.reduce((result, optionType) => {
          result[optionType.id] = selectedOptionTypes.includes(optionType.id)
          return result
        }, {} as { [k: string]: boolean })
        setSelectedNewsEntryTypes(types)
      }
    }
  }, [slackDetails, params.teamName, newsEntryOptionTypes])

  useEffect(() => {
    if (saveSlackSettingResponse.data) {
      dispatch(
        displaySnackBar({
          message: t('newsfeed:slack_success_saving_settings'),
          severity: 'success',
          open: true,
        })
      )
      handleGoBack()
      return
    }
    if (saveSlackSettingResponse.error) {
      dispatch(
        displaySnackBar({
          message: t('newsfeed:slack_error_saving_settings'),
          severity: 'error',
          open: true,
        })
      )
    }
  }, [dispatch, handleGoBack, navigate, saveSlackSettingResponse.data, saveSlackSettingResponse.error, t])

  useEffect(() => {
    if (isGameLoading) return
    if (slackDetails === undefined) return
    if (!gamesData || gamesData.length === 0) return

    setSelectedGames(gamesData)
  }, [gamesData, isGameLoading, slackDetails])

  const handleSaveSlackSettings = () => {
    if (!selectedSlack) return

    const submitSlackData: SlackPreference = {
      channel: selectedSlack.channel,
      eventTypes: selectedTypesByTypeIds,
      filterType: selectedEvent,
      market: selectedMarket,
      subgenres: Object.keys(selectedSubgenresMap),
      teamId: selectedSlack.teamId,
      gameIds: selectedGames.map((game) => game.id),
      artists: selectedOrganizations,
    }

    analyticsService.trackEvent('Slackbot Preferences', {
      data: submitSlackData,
      serviceToExclude: [AnalyticsProviders.hubspot],
    })

    saveSlackSettings(submitSlackData)
  }

  const handleGameSearchModalClose = (selectedGames?: Game[], selectedGamesWithMarketIso?: GameWithMarketIso, reason?: string) => {
    if (reason !== 'cancel' && reason !== 'closeButtonClick') {
      setSelectedGames(selectedGames || [])
    }

    setGameSearchModalOpen(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedNewsEntryTypes({ ...selectedNewsEntryTypes, [event.target.name]: event.target.checked })
  }

  const handleDelete = (artist: string) => {
    setSelectedOrganizations(selectedOrganizations.filter((org) => org !== artist))
  }

  return isSlackDetailsFetching ? (
    <div className="SlackPreferencesPage">
      <Card>
        <CardContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Skeleton variant="text" width="300px" height={30} />
            </Grid>
            <Grid item sx={{ display: 'inline-flex' }}>
              <Skeleton variant="text" width="50px" height={30} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardContent>
          <h4 className="bottom-margin">{t('newsfeed-options:game_type_preferences')}</h4>
          <Skeleton variant="rectangular" width="100%" height={200} sx={{ marginBottom: '20px' }} />
          <h4 className="bottom-margin">{t('newsfeed-options:news_type_preferences')}</h4>
          <Skeleton variant="rectangular" width="50%" height={200} sx={{ marginBottom: '20px' }} />
          <h4 className="bottom-margin">{t('common:games')}</h4>
          <Box display="flex" mb={2}>
            <Skeleton variant="rectangular" width={60} height={60} sx={{ marginRight: '10px' }} />
            <Skeleton variant="rectangular" width={60} height={60} sx={{ marginRight: '10px' }} />
            <Skeleton variant="rectangular" width={60} height={60} sx={{ marginRight: '10px' }} />
          </Box>
          <h4 className="bottom-margin">{t('common:organizations')}</h4>
          <Box display="flex">
            <Skeleton variant="text" width={160} height={30} sx={{ marginRight: '10px' }} />
            <Skeleton variant="text" width={160} height={30} sx={{ marginRight: '10px' }} />
            <Skeleton variant="text" width={160} height={30} sx={{ marginRight: '10px' }} />
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Button onClick={handleGoBack}>{t('common:back')}</Button>
            <Button disabled variant="contained" color="primary">
              {t('newsfeed-options:save_options')}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </div>
  ) : (
    <div className="SlackPreferencesPage">
      <Card>
        <CardContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <GRTooltip content={`${params?.teamName} - ${params?.channel}`}>
                <Box
                  fontSize={20}
                  fontWeight={'bold'}
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '700px' }}
                >{`${params?.teamName} - ${params?.channel}`}</Box>
              </GRTooltip>
            </Grid>
            <Grid item sx={{ display: 'inline-flex' }}>
              <MarketSelector
                value={selectedMarket}
                onChange={(marketIso: string) => {
                  setselectedMarket(marketIso)
                }}
              />
            </Grid>
          </Grid>
        </CardContent>

        <Divider />

        <CardContent>
          <Box alignContent="center">
            <h4 className="bottom-margin">{t('newsfeed-options:game_type_preferences')}</h4>
            <GameSubgenrePicker
              selectionChanged={(selectedSubgenre) => setSelectedSubgenresMap(selectedSubgenre)}
              selectedSubgenres={selectedSubgenresMap}
              hideSetAsDefault
              showSubgenreDefinitionBtn
            />

            <NewsEventsOption selectedNewsEvent={selectedEvent} handleChange={(event) => setSelectedEvent(event.target.value)} />

            <h4 className="bottom-margin">{t('newsfeed-options:news_type_preferences')}</h4>

            <Box mb={2}>
              <NewEntriesType
                selectedNewsEntryTypes={selectedNewsEntryTypes}
                handleChange={handleChange}
                lockedFeatureDialogOpen={lockedFeatureDialogOpen}
                setLockedFeatureDialogType={setLockedFeatureDialogType}
                setLockedFeatureDialogOpen={setLockedFeatureDialogOpen}
              />
            </Box>

            <h4 className="bottom-margin">{t('common:games')}</h4>
            <Box mb={4}>
              {selectedGames && selectedGames.length > 0 && (
                <Grid container spacing={2} mb={2} wrap="wrap">
                  {selectedGames.map((game) => (
                    <Grid key={game.id} item>
                      <GRTooltip key={game.id} content={game.resolvedName}>
                        <Grid>
                          <Grid container direction="column">
                            <Grid item>
                              <GameIcon src={game.icons || game.icon} gameName={game.resolvedName || ''} size="normal" />
                            </Grid>
                            <Grid item width="60px">
                              <Typography fontSize={12} overflow="hidden" textOverflow="ellipsis" noWrap>
                                {game.resolvedName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </GRTooltip>
                    </Grid>
                  ))}
                </Grid>
              )}

              <Grid container spacing={1} wrap="wrap">
                <Grid item>
                  <CounterButton
                    label={t('compare:select_games')}
                    count={selectedGames?.length}
                    onClick={() => {
                      setGameSearchModalOpen(true)
                    }}
                    size="medium"
                  ></CounterButton>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="warning"
                    disabled={!selectedGames || selectedGames.length === 0}
                    onClick={() => {
                      setSelectedGames([])
                    }}
                  >
                    {t('common:clear')}
                  </Button>
                </Grid>
              </Grid>
            </Box>

            <h4 className="bottom-margin">
              <Trans i18nKey="common:organizations" />
            </h4>
            <Box>
              <Grid>
                <Grid container spacing={1} wrap="wrap">
                  {selectedOrganizations.map((organization, index) => (
                    <Grid key={index} item>
                      <Chip label={organization} onDelete={() => handleDelete(organization)} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
            <Box mt={1} mb={2} maxWidth="300px" display="flex">
              <ArtistSearchInput
                onArtistClick={(artist) => {
                  // add artist to selectedOrganizations if not already in the list
                  if (!selectedOrganizations.includes(artist)) {
                    setSelectedOrganizations([...selectedOrganizations, artist])
                  }
                }}
              />
              <Button
                sx={{ marginLeft: '10px' }}
                variant="contained"
                color="warning"
                disabled={!selectedOrganizations || selectedOrganizations.length === 0}
                onClick={() => {
                  setSelectedOrganizations([])
                }}
              >
                {t('common:clear')}
              </Button>
            </Box>
          </Box>
        </CardContent>

        <Divider />
        <CardActions>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Button onClick={handleGoBack}>{t('common:back')}</Button>
            <Button onClick={handleSaveSlackSettings} variant="contained" color="primary">
              {t('newsfeed-options:save_options')}
            </Button>
          </Box>
        </CardActions>
      </Card>

      {lockedFeatureDialogType !== '' && !!lockedFeatureDialogType && (
        <LockedFeature.Dialog lockedFeatureId={lockedFeatureDialogType as LockedFeatureId} open onClose={() => setLockedFeatureDialogType('')} />
      )}

      {gameSearchModalOpen && (
        <GameSearchDialog
          gameSearchDialogConfig={gameSearchDialogConfig}
          modalOpen={gameSearchModalOpen}
          marketIso={selectedMarket}
          defaultSelectedGames={selectedGames}
          onClose={handleGameSearchModalClose}
          hideSetAsDefault
          hasDefaultSubgenre
        />
      )}
    </div>
  )
}

export default SlackPreferencesPage
