import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowBack, Close } from '@mui/icons-material'
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, MenuItem, Select } from '@mui/material'

import { useGetRanksByGenreQuery } from '../../../api/top-grossing'
import analyticsService from '../../../services/AnalyticsService'
import { useCurrentMarket } from '../../markets'
import { colorPercentageOptions, topAmountOptions } from '../../visual-explorer/components/VisualExplorerOverview'
import { useGamesByVisualAttribute } from '../hooks/useIconAttributes'
import { useIconGrouper } from '../hooks/useIconGrouper'
import { RankType, VisualAnalysisWithRank, VisualsAttribute, VisualSortType } from '../types'
import { AppStoreGenreMap } from '../util/visualStats'
import ColorSquare from './ColorSquare'
import './GameIconAttributeModal.scss'
import VisualIconsList from './VisualIconsList'

interface GameIconAttributeModalProps {
  attribute: VisualsAttribute
  genreId?: string
  isNested?: boolean
  onClose: () => void
  onSelectGame: (item: VisualAnalysisWithRank) => void
  rankType?: RankType
  top?: number
}

/**
 * The main modal view.
 *
 * @param attribute to examine and list games
 * @param nested if this modal is opened by another modal
 * @param onClose from close button
 * @param onSelectGame from clicking game icon
 */
const GameIconAttributeModal: React.FC<GameIconAttributeModalProps> = ({
  attribute,
  isNested,
  onClose,
  onSelectGame,
  genreId: defaultGenreId,
  rankType: defaultRankType,
  top: defaultTop,
}) => {
  useEffect(() => {
    analyticsService.trackEvent('Opened Visual Attribute Modal', {
      data: {
        name: attribute.name,
      },
    })
  }, [attribute.name])

  const { t } = useTranslation()
  const { currentMarketIso: marketIso } = useCurrentMarket()

  const [colorAmount, setColorAmount] = useState(0.2)
  const [rankType, setRankType] = useState<RankType>(defaultRankType || 'free')
  const [genreId, setGenreId] = useState<string>(defaultGenreId || '6014')
  const [sortBy, setSortBy] = useState<VisualSortType>('attribute')
  const [top, setTop] = useState(defaultTop || 200)
  const { data: attrData, isFetching: isAttrFetching } = useGamesByVisualAttribute(attribute.model, attribute.name, marketIso, rankType, genreId)
  const { data: rankData, isFetching: isRankFetching } = useGetRanksByGenreQuery({ marketIso, genreId })

  const isColor = attribute.model.startsWith('color')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const colorPercentage = useMemo(() => (isColor ? { [attribute.name]: colorAmount * 100 } : undefined), [colorAmount])

  const groupedData = useIconGrouper(rankType, top, genreId, attrData, rankData, colorPercentage)

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xl" fullWidth className="GameIconAttributeModal" disableEscapeKeyDown={true} disableRestoreFocus>
      <DialogTitle>
        <Grid container justifyContent="space-between" className="header" alignItems="flex-end">
          <Grid item>
            {isNested && (
              <IconButton aria-label="Back" onClick={onClose} sx={{ my: -1, mr: 1 }}>
                <ArrowBack />
              </IconButton>
            )}
            {t('visuals-explorer:visual_attribute_modal_title')}: {isColor && <ColorSquare color={attribute.name} />}
            <span>{attribute.name}</span>
          </Grid>
          <Grid item textAlign="right">
            <IconButton aria-label="close" onClick={onClose} style={{ margin: -10 }}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" className="formControls">
          <FormControl size="small" variant="outlined">
            <Select onChange={({ target }) => setSortBy(target.value as VisualSortType)} value={sortBy}>
              <MenuItem value="attribute">{t('visuals-explorer:sort_by_attribute')}</MenuItem>
              <MenuItem value="rank">{t('visuals-explorer:sort_by_rank')}</MenuItem>
            </Select>
          </FormControl>
          {['color', 'colorGroups'].includes(attribute.model) && (
            <FormControl size="small" variant="outlined">
              <Select onChange={({ target }) => setColorAmount(target.value as number)} value={colorAmount}>
                {colorPercentageOptions.map((colorValue) => (
                  <MenuItem value={colorValue} key={'color-' + colorValue}>
                    {t('visuals-explorer:color_amount_percentage', { percentage: colorValue * 100 })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl size="small" variant="outlined">
            <Select onChange={({ target }) => setGenreId(target.value)} value={genreId}>
              {Object.keys(AppStoreGenreMap).map((appGenre) => (
                <MenuItem value={appGenre} key={appGenre}>
                  {AppStoreGenreMap[appGenre]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" variant="outlined">
            <Select onChange={({ target }) => setTop(target.value as number)} value={top}>
              {topAmountOptions.map((value) => (
                <MenuItem value={value} key={'top-select-' + value}>
                  {t('common:top_' + value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" variant="outlined">
            <Select onChange={({ target }) => setRankType(target.value as RankType)} value={rankType}>
              <MenuItem value="free">{t('common:sustained_download_rank')}</MenuItem>
              <MenuItem value="grossing">{t('common:sustained_grossing_rank')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {isAttrFetching || isRankFetching ? (
          <Box sx={{ mb: 2, mt: 4, textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <VisualIconsList
            attributes={[attribute]}
            data={groupedData}
            genreId={genreId}
            rankType={rankType}
            onSelectAnalysis={onSelectGame}
            sortBy={sortBy}
            top={top}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default GameIconAttributeModal
