import { useMemo } from 'react'

import { Screenshot, useGetScreenshotsByGameIdsQuery } from '../../../api/core'
import { useGetNewsEntriesForGameIdsAndVersionsQuery } from '../../../api/feed'
import { Game } from '../../game'
import { useGetGameList } from '../../game/hooks/gameHooks'
import { FeatureGame } from '../../game/types/Game'
import { convertLegacyScreenshots, getCombinedScreenshots } from '../../implementation-examples/util/screenshotHelpers'
import { useCurrentMarket } from '../../markets'
import { NewsEntryScreenshotFeature } from '../../news/types/UnifiedNewsEntry'

export enum ScreenshotDataSortedOpts {
  ASC,
  DESC,
}

interface UseScreenshotDataProps {
  gameIds: string[]
  sorted?: ScreenshotDataSortedOpts
}
export const useScreenshotData = ({ gameIds, sorted }: UseScreenshotDataProps) => {
  const { data: screenshotsData = [], isFetching: isFetchingScreenshots } = useGetScreenshotsByGameIdsQuery(gameIds)
  const sortedScreenshotsData = useMemo(() => {
    return [...screenshotsData]
      .filter((screenshot) => !!screenshot.versionReleaseDate)
      .sort((a, b) => {
        if (a.versionReleaseDate && b.versionReleaseDate) {
          switch (sorted) {
            case ScreenshotDataSortedOpts.DESC:
              return b.versionReleaseDate - a.versionReleaseDate
            default:
              return a.versionReleaseDate - b.versionReleaseDate
          }
        }
        return 0
      })
  }, [screenshotsData, sorted])

  if (!sorted) {
    return { screenshotsData, isFetching: isFetchingScreenshots }
  }

  return { screenshotsData: sortedScreenshotsData, isFetching: isFetchingScreenshots }
}

const useScreenshotsUniqueByGameId = (sortedScreenshotsData: Screenshot[], maxScreenshotCount: number) => {
  const screenshotsUniqueByGameId = useMemo(() => {
    if (!sortedScreenshotsData.length) {
      return []
    }

    const tempScreenshotsData: { screenshots: Screenshot[]; uniqueGamesByScreenshots: string[] } = {
      screenshots: [],
      uniqueGamesByScreenshots: [],
    }

    for (let i = 0; i <= sortedScreenshotsData.length; i++) {
      const screenshot = sortedScreenshotsData[i]
      if (!screenshot) {
        continue
      }

      if (screenshot.gameId && screenshot.gameVersion && !tempScreenshotsData.uniqueGamesByScreenshots.includes(screenshot.gameId)) {
        tempScreenshotsData.uniqueGamesByScreenshots.push(screenshot.gameId)
        tempScreenshotsData.screenshots.push(screenshot)
      }

      if (tempScreenshotsData.screenshots.length === maxScreenshotCount) {
        break
      }
    }

    return tempScreenshotsData.screenshots
  }, [maxScreenshotCount, sortedScreenshotsData])
  return screenshotsUniqueByGameId
}

export type NewsEntriesFeatureByGameIds = {
  [gameId: string]: {
    features: NewsEntryScreenshotFeature[]
    version?: string
  }
}
const useNewsEntriesFeatureByGameIds = (screenshots: Screenshot[]) => {
  const NewsEntryFields = [
    'type',
    'entryData.gps',
    'entryData.gpsBefore',
    'entryData.features',
    'entryData.screenshots',
    'entryData.commentPublished',
    'entryData.commentId',
    'entryData.gameVersion',
    'entryData.versionReleaseDate',
  ]
  const versionsByGame = useMemo(() => {
    return screenshots.reduce((result, screenshot) => {
      if (screenshot.gameId && screenshot.gameVersion) {
        result[screenshot.gameId] = [screenshot.gameVersion]
      }
      return result
    }, {} as { [gameId: string]: string[] })
  }, [screenshots])
  const { currentMarketIso } = useCurrentMarket()
  const {
    data: newsEntriesForGameIdsAndVersions,
    isLoading: isLoadingNewsEntriesForGameIdsAndVersions,
    isFetching: isFetchingNewsEntriesForGameIdsAndVersions,
  } = useGetNewsEntriesForGameIdsAndVersionsQuery({ marketIso: currentMarketIso, fields: NewsEntryFields, query: versionsByGame, enableCache: true })

  const newsEntriesFeatureByGameIds = useMemo(() => {
    if (!newsEntriesForGameIdsAndVersions) return

    return screenshots.reduce((result, screenshot) => {
      if (
        screenshot.gameId &&
        screenshot.gameVersion &&
        newsEntriesForGameIdsAndVersions[screenshot.gameId] &&
        newsEntriesForGameIdsAndVersions[screenshot.gameId][screenshot.gameVersion]
      ) {
        const entryData = newsEntriesForGameIdsAndVersions[screenshot.gameId][screenshot.gameVersion].entryData
        const matchingScreenshot = entryData.screenshots?.find((feedScreenshot) => feedScreenshot.screenshotId === screenshot.id)
        if (matchingScreenshot && matchingScreenshot.features) {
          if (!result[screenshot.gameId]) {
            result[screenshot.gameId] = { features: [] }
          }

          result[screenshot.gameId].features = matchingScreenshot.features
          result[screenshot.gameId].version = screenshot.gameVersion
          return result
        }
      }
      return result
    }, {} as NewsEntriesFeatureByGameIds)
  }, [newsEntriesForGameIdsAndVersions, screenshots])

  return { newsEntriesFeatureByGameIds, isLoading: isLoadingNewsEntriesForGameIdsAndVersions || isFetchingNewsEntriesForGameIdsAndVersions }
}

interface UseGetFeatureWithScreenshotGames {
  gameIds: string[]
  maxScreenshotCount: number
}
export const useGetFeatureWithScreenshotGames = ({ gameIds, maxScreenshotCount }: UseGetFeatureWithScreenshotGames) => {
  const { data: gamesData = [], isFetching: isFetchingGames } = useGetGameList({
    gameIds,
    include: 'name,artist,icons,conventionalSubgenre,conventionalSubgenreId,sdranks,sranks,srank,appId',
  })

  const { screenshotsData: sortedScreenshotsData, isFetching: isFetchingScreenshots } = useScreenshotData({
    gameIds,
    sorted: ScreenshotDataSortedOpts.DESC,
  })

  const screenshotsUniqueByGameId = useScreenshotsUniqueByGameId(sortedScreenshotsData, maxScreenshotCount)
  const { newsEntriesFeatureByGameIds, isLoading: isNewsEntriesLoading } = useNewsEntriesFeatureByGameIds(screenshotsUniqueByGameId)

  const featureGames: FeatureGame[] = useMemo(() => {
    return (
      [...gamesData]
        .map((game: Game) => {
          const screenshotsForGame = convertLegacyScreenshots((screenshotsUniqueByGameId || []).filter(({ gameId }) => gameId === game.id))

          return {
            ...game,
            featureScreenshots: screenshotsForGame.filter((screenshot) => screenshot.features?.length),
            conceptScreenshots: screenshotsForGame.filter((screenshot) => screenshot.conceptIds?.length),
          } as FeatureGame
        })
        // filter games that do not have any feature screenshots or concept screenshots
        .filter((game) => game.featureScreenshots.length || game.conceptScreenshots.length)
        .sort((a, b) => {
          const aCombinedShots = getCombinedScreenshots(a)
          const bCombinedShots = getCombinedScreenshots(b)

          if (aCombinedShots[0].versionReleaseDate && bCombinedShots[0].versionReleaseDate) {
            return bCombinedShots[0].versionReleaseDate - aCombinedShots[0].versionReleaseDate
          }
          return 0
        })
    )
  }, [gamesData, screenshotsUniqueByGameId])

  const featureGameIds = useMemo(() => featureGames.map((featureGame) => featureGame.id), [featureGames])
  const sortedScreenshotUniqueByGameId = useMemo(() => {
    return screenshotsUniqueByGameId.sort((a, b) => featureGameIds.indexOf(a.gameId as string) - featureGameIds.indexOf(b.gameId as string))
  }, [featureGameIds, screenshotsUniqueByGameId])

  return {
    newsEntriesFeatureByGameIds,
    screenshotsUniqueByGameId: sortedScreenshotUniqueByGameId,
    featureGames,
    isLoading: isFetchingScreenshots || isFetchingGames || isNewsEntriesLoading,
  }
}
