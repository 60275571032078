import { FC, ReactNode, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import { useSaveLoginInfoMutation } from '../../../api/account'
import FinalizeProfileDialog from '../../../features/account/components/FinalizeProfileDialog/FinalizeProfileDialog'
import { AppLoader } from '../../../features/auth/components/AppLoader/AppLoader'
import { ModalController } from '../../../features/route-modals/components/ModalController/ModalController'
import analyticsService from '../../../services/AnalyticsService'
import keycloakService from '../../../services/KeycloakService'
import AppWrapper from '../../AppWrapper/AppWrapper'

type AuthenticatedRouteProps = {
  children?: ReactNode
}

/**
 * AuthenticatedRoute checks keycloak authentication status and guards drawing the rest of the UI until
 * user is authenticated
 */
export const AuthenticatedRoute: FC<AuthenticatedRouteProps> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState<boolean>(false)
  const [saveLoginInfo, { data, isLoading, isError }] = useSaveLoginInfoMutation()
  const userAgent = window.navigator.userAgent

  useEffect(() => {
    if (authenticated && !isLoading && !data && !isError) {
      saveLoginInfo({ userAgent: userAgent })
    }
  }, [authenticated, data, isError, isLoading, saveLoginInfo, userAgent])

  useEffect(() => {
    if (data && data.isfirst) {
      analyticsService.trackEvent('First login')
    }
  }, [data])

  // initiate keycloak when component mounts
  useEffect(() => {
    if (keycloakService.authenticated) {
      setAuthenticated(true)
    } else {
      setAuthenticated(false)
      keycloakService.login()
    }
  }, [])

  return authenticated ? (
    <AppLoader>
      <AppWrapper>
        <Outlet />
        <ModalController />
      </AppWrapper>
      <FinalizeProfileDialog />
    </AppLoader>
  ) : null
}
