import { t } from 'i18next'
import { FC, useCallback, useState } from 'react'
import { Trans } from 'react-i18next'
import { v4 } from 'uuid'

import { CardHeader, CardContent, Divider, Button } from '@mui/material'

import { EventEditFormDurations } from '../../types/EventEditForm'
import { EventDurationAdder } from './EventDurationAdder/EventDurationAdder'
import { EventDurationBulkAdder } from './EventDurationBulkAdder/EventDurationBulkAdder'
import { EventDurationsTable } from './EventDurationsTable/EventDurationsTable'

type EventDurationsContainerProps = {
  gameId: string
  liveEventId: string
  durations: EventEditFormDurations
  onChange: (durations: EventEditFormDurations) => void
}

export const EventDurationsContainer: FC<EventDurationsContainerProps> = ({ gameId, liveEventId, durations, onChange }) => {
  const [viewAll, setViewAll] = useState<boolean>(false)
  const handleRemoveDuration = useCallback(
    (durationId: string) => {
      const { [durationId]: removableDuration, ...rest } = durations
      onChange(rest)
    },
    [durations, onChange]
  )

  const handleAddDuration = useCallback(
    (duration: { start: Date; end: Date }) => {
      const key = v4()
      onChange({ ...durations, [key]: { gameId, liveEventId, start: duration.start, end: duration.end, key } })
    },
    [durations, gameId, liveEventId, onChange]
  )

  const handleAddDurations = useCallback(
    (newDurations: { start: Date; end: Date }[]) => {
      const newDurationObjects = newDurations.reduce<EventEditFormDurations>((acc, duration) => {
        const key = v4()
        acc[key] = { gameId, liveEventId, start: duration.start, end: duration.end, key }

        return acc
      }, {})

      onChange({
        ...durations,
        ...newDurationObjects,
      })
    },
    [durations, gameId, liveEventId, onChange]
  )

  return (
    <>
      <CardHeader subheader={<Trans i18nKey="internal-live-events:bulk_add_durations_title" />} sx={{ pb: 1 }} />
      <CardContent>
        <EventDurationBulkAdder onAddDurations={handleAddDurations} />
      </CardContent>
      <Divider />
      <CardHeader subheader={<Trans i18nKey="internal-live-events:add_duration_title" />} sx={{ pb: 1 }} />
      <CardContent>
        <EventDurationAdder durations={durations} onAddDuration={handleAddDuration} />
      </CardContent>
      <Divider />
      <CardContent>
        <Button variant="contained" onClick={() => setViewAll(!viewAll)}>
          {viewAll ? t('internal-live-events:view_less') : t('internal-live-events:view_all')}
        </Button>
      </CardContent>
      <Divider />
      <EventDurationsTable viewAll={viewAll} durations={durations} onRemoveDuration={handleRemoveDuration} />
    </>
  )
}
