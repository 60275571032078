import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DialogProps, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'

import { useGetGameReviewQuery } from '../../api/core'
import { useAnalystReviewsAndCommentsAccessCheck } from '../../features/account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../features/account/hooks/useCurrentUserLanguage'
import { displaySnackBar } from '../../features/snackbar'
import { useAppDispatch } from '../../hooks/storeHooks'
import { AnalystOverview } from '../AnalystOverview/AnalystOverview'
import GRCircularProgress from '../GRCircularProgress/GRCircularProgress'
import { ReviewHeader } from '../Review/Review'

/**
 * Component for loading and presenting analyst comment based on comment identifier
 */

type AnalystOverviewDialogProps = DialogProps & {
  reviewId?: string
  gameName?: string
}

export const AnalystOverviewDialog: FC<AnalystOverviewDialogProps> = ({ reviewId, gameName, ...dialogProps }) => {
  const { open } = dialogProps
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const language = useCurrentUserLanguage()
  const hasAnalystReviewsAndCommentsAccessRights = useAnalystReviewsAndCommentsAccessCheck()
  const {
    data: gameReview,
    isLoading,
    isFetching,
    isError,
  } = useGetGameReviewQuery(reviewId || '', { skip: !open || !reviewId || !hasAnalystReviewsAndCommentsAccessRights })

  useEffect(() => {
    if (isError) {
      dispatch(displaySnackBar({ message: t('reviews:error_could_not_load_analyst_comment'), severity: 'error', open: true }))
    }
  }, [dispatch, t, isError])

  return (
    <Dialog maxWidth="md" fullWidth {...dialogProps} disableRestoreFocus keepMounted>
      {isLoading || isFetching ? (
        <DialogContent>
          <GRCircularProgress />
        </DialogContent>
      ) : (
        <>
          <DialogTitle>
            <ReviewHeader
              analyst={gameReview?.analyst}
              title={t('reviews:label_analyst_overview')}
              subtitle={gameName}
              direction="row-reverse"
              alignItems="center"
              titleProps={{ color: 'primary' }}
            />
          </DialogTitle>
          <DialogContent dividers>
            {hasAnalystReviewsAndCommentsAccessRights ? (
              <AnalystOverview review={gameReview?.gameReview} language={language} />
            ) : (
              <Typography variant="body1">{t('reviews:label_not_for_free')}</Typography>
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
