import { useMemo } from 'react'

import { useCurrentMarket } from '../../markets'
import { TrackedGame } from '../types/TrackedGame'
import { SortGamesByOptionValue } from './useSortGamesBySelectOptions'

type UseSortedTrackedGamesHookParams = {
  trackedGames: TrackedGame[]
  sortBy: SortGamesByOptionValue
}

export const useSortedTrackedGames = ({ trackedGames, sortBy }: UseSortedTrackedGamesHookParams) => {
  const { currentMarketIso } = useCurrentMarket()

  return useMemo(() => {
    return [...trackedGames].sort((game1, game2) => {
      switch (sortBy) {
        case SortGamesByOptionValue.SustainedGrossingRank:
          return (game1.game.sranks[currentMarketIso] || 0) - (game2.game.sranks[currentMarketIso] || 0)
        case SortGamesByOptionValue.SustainedDownloadRank:
          return (game1.game.sdranks[currentMarketIso] || 0) - (game2.game.sdranks[currentMarketIso] || 0)
        case SortGamesByOptionValue.GameName:
          return game1.game.resolvedName.localeCompare(game2.game.resolvedName)
        default:
          return 0
      }
    })
  }, [currentMarketIso, trackedGames, sortBy])
}
