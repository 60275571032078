import { useMemo } from 'react'

import { debounce } from '@mui/material'

export const useDebouncedResize = (wait: number) =>
  useMemo(
    () =>
      debounce(() => {
        window.dispatchEvent(new Event('resize'))
      }, wait),
    [wait]
  )
