import { FC, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Language } from '@mui/icons-material'
import { TextField, InputAdornment } from '@mui/material'

import { UserLanguage } from '../../../features/account/types/User'
import { truncateText } from '../../helpers/text'
import { InputTranslatorType } from '../../types/InputTranslatorType'
import { ContentLanguageTranslation } from '../../types/InternalAnalystComment'
import { InputTranslatorDialog } from './InputTranslatorDialog/InputTranslatorDialog'

type InputTranslatorProps = {
  value?: ContentLanguageTranslation | null
  onChange: (value: ContentLanguageTranslation) => void
  inputType?: InputTranslatorType
  dialogTitle: ReactNode
  renderAIAssistant?: () => ReactNode
  selectedLanguages?: UserLanguage[]
  children?: ReactNode
}

export const translationLanguagesToShow: UserLanguage[] = ['en']

export const InputTranslator: FC<InputTranslatorProps> = ({
  value,
  onChange,
  inputType = InputTranslatorType.Text,
  dialogTitle,
  renderAIAssistant,
  selectedLanguages,
  children,
}) => {
  const [translatorDialogOpen, setTranslatorDialogOpen] = useState(false)
  const { t } = useTranslation()

  const handleTranslatorDialogClose = useCallback(() => {
    setTranslatorDialogOpen(false)
  }, [])

  const hasEnglishTranslation = value && value.en

  return (
    <>
      <TextField
        type="text"
        label={t('internal-live-events:comment')}
        value={hasEnglishTranslation ? truncateText(value.en!, 150, inputType) : t('internal-live-events:not_translated')}
        onClick={() => setTranslatorDialogOpen(true)}
        variant="outlined"
        size="small"
        inputProps={{ readOnly: true }}
        fullWidth
        sx={hasEnglishTranslation ? { input: { cursor: 'pointer' } } : { input: { cursor: 'pointer', color: 'red' } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Language color="secondary" />
            </InputAdornment>
          ),
        }}
      />
      {translatorDialogOpen && (
        <InputTranslatorDialog
          defaultValues={value}
          onChange={onChange!}
          open={translatorDialogOpen}
          onClose={handleTranslatorDialogClose}
          title={dialogTitle}
          inputType={inputType}
          renderAIAssistant={renderAIAssistant}
          selectedLanguages={selectedLanguages}
        />
      )}
    </>
  )
}
