import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@mui/material'

import { SelectMenu, SelectMenuProps } from '../../../../../components/SelectMenu/SelectMenu'
import { insertAtIndex } from '../../../../../helpers/arrays'
import { useError } from '../../../../../hooks/useError'
import { Analyst } from '../../../../../types/Analyst'
import { useGetAnalystsListQuery } from '../../../../api/internal'

type AnalystsSelectMenuProps = Omit<SelectMenuProps<Analyst['id']>, 'label' | 'options'> & {
  value: Analyst['id']
  useAll?: boolean
  onChange: (analystId: Analyst['id']) => void
}

export const analystsSelecMenuAllValue = 'all'
export const AnalystsSelectMenu: FC<AnalystsSelectMenuProps> = ({ value, useAll, onChange, ...selectMenuProps }) => {
  const { t } = useTranslation()
  const { data: analystsList, error: analystsListError, isFetching } = useGetAnalystsListQuery()
  useError({ error: analystsListError })

  let options = analystsList?.map((analyst) => ({ name: analyst.name, value: analyst.id })).sort((a, b) => a.name.localeCompare(b.name)) || []
  if (useAll) {
    options = insertAtIndex(options, { name: t('internal-live-events:all_analysts'), value: analystsSelecMenuAllValue }, 0)
  }

  return !isFetching ? (
    <FormControl size="small" variant="outlined" fullWidth>
      <SelectMenu {...selectMenuProps} label="Analyst" value={value} onChange={(e) => onChange(e.target.value)} options={options} />
    </FormControl>
  ) : null
}
