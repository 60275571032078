import { FC, ReactNode, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { useGetUserProfileQuery } from '../../../api/combined'
import keycloakService from '../../../services/KeycloakService'

type UnauthenticatedRouteProps = {
  children?: ReactNode
}

export const UnauthenticatedRoute: FC<UnauthenticatedRouteProps> = ({ children }) => {
  const navigate = useNavigate()
  const userProfileQuery = useGetUserProfileQuery()
  const isLoading = userProfileQuery.isLoading || userProfileQuery.isFetching

  // if user is authenticated navigate to root
  useEffect(() => {
    if (keycloakService.authenticated && userProfileQuery.isSuccess && userProfileQuery.data) {
      navigate('/')
    }
  }, [navigate, userProfileQuery.data, userProfileQuery.isSuccess])

  return isLoading ? null : <Outlet />
}
