import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { BookType } from 'xlsx'

import { Card, CardContent, Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import { GRCountryFlag } from '../../../../components/GRCountryFlag/GRCountryFlag'
import { PerformanceChartV2 } from '../../../../components/PerformanceChartV2/PerformanceChartV2'
import { PerformanceChartV2DataType } from '../../../../components/PerformanceChartV2/PerformanceChartV2DataType'
import { YAxisConfig } from '../../../../components/PerformanceChartV2/PerformanceChartV2Types'
import { useExportDataGameUpdateHistory } from '../../../../hooks/exportDataHooks'
import { useAppSelector } from '../../../../hooks/storeHooks'
import { useError } from '../../../../hooks/useError'
import analyticsService from '../../../../services/AnalyticsService'
import { useAnalystReviewsAndCommentsAccessCheck, useGameUpdateImpactsAccessCheck, useSubgenresAccessCheck } from '../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../account/hooks/useCurrentUserLanguage'
import { generateExport } from '../../../export-data/util/workbook'
import { GameAndAnalysis } from '../../../game/types/GameAndAnalysis'
import { selectMarketByIso, useCurrentMarket } from '../../../markets'
import { useAvailableMarketIsoForUserLanguage } from '../../../markets/hooks/useAvailableMarketIsoForUserLanguage'
import { GranularityValue } from '../../../revenue-and-downloads/types/Filters'
import { useIsGameOpenForEveryone } from '../../../settings'
import { UpdateImpactFilters, UpdateImpactsTable } from '../../../update-impacts'
import { ColumnFilterValue } from '../../../update-impacts/types/ColumnFilter'
import { TableRowUpdateImpact } from '../../../update-impacts/types/TableRowUpdateImpact'
import { UpdateImpactRowFilters } from '../../../update-impacts/types/UpdateImpactRowFilters'
import { useGameUpdateHistory } from '../../hooks/useGameUpdateHistory'

type GameUpdateHistoryProps = {
  gameAndAnalysis: GameAndAnalysis
}

/**
 * Component capsulating all Game Update History tab components
 */
export const GameUpdateHistory: FC<GameUpdateHistoryProps> = ({ gameAndAnalysis }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Update History', {
      data: {
        gameId: gameAndAnalysis.game.id,
        gameName: gameAndAnalysis.game.resolvedName,
        analysisId: gameAndAnalysis.analysis?.id,
      },
    })
  }, [gameAndAnalysis.analysis?.id, gameAndAnalysis.game.id, gameAndAnalysis.game.resolvedName])

  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const userLanguage = useCurrentUserLanguage()
  const { currentMarketIso } = useCurrentMarket()
  const marketIsoForUserLanguage = useAvailableMarketIsoForUserLanguage(gameAndAnalysis.game)
  const [filters, setFilters] = useState<UpdateImpactRowFilters>({
    subgenres: {
      [gameAndAnalysis.game.conventionalSubgenreId]: true,
    },
  })
  const isGameOpenForEveryone = useIsGameOpenForEveryone(gameAndAnalysis.game)
  const hasUpdateImpactsAccessRights = useGameUpdateImpactsAccessCheck()
  const impactDataUnlocked = hasUpdateImpactsAccessRights || isGameOpenForEveryone
  const hasAccessToSubgenres = useSubgenresAccessCheck()
  const hasAnalystReviewsAndCommentsAccessRights = useAnalystReviewsAndCommentsAccessCheck()
  const powerscoreUnlocked = hasAccessToSubgenres || isGameOpenForEveryone

  const initialFromDate = new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0))
  const initialToDate = new Date(new Date().setHours(0, 0, 0, 0))
  const [dateRange, setDateRange] = useState<DateRangeValue | undefined>({ fromDate: initialFromDate, toDate: initialToDate })

  const excludedDataTypes = [PerformanceChartV2DataType.DAU, PerformanceChartV2DataType.MAU]
  const [yAxisLeftConfig, setYAxisLeftConfig] = useState<YAxisConfig>({ dataType: PerformanceChartV2DataType.Revenue, excludedDataTypes })
  const [yAxisRightConfig, setYAxisRightConfig] = useState<YAxisConfig>({ dataType: PerformanceChartV2DataType.Downloads, excludedDataTypes })
  const [granularity, setGranularity] = useState(GranularityValue.Week)

  // resolve market used to display impact data
  const marketIso = useMemo(() => {
    let resolvedMarket
    if (gameAndAnalysis.game.isSoftLaunch()) {
      resolvedMarket = gameAndAnalysis.game.softLaunchMarket
      if (!resolvedMarket) {
        resolvedMarket = gameAndAnalysis.game.storeLinks[currentMarketIso] ? currentMarketIso : marketIsoForUserLanguage
      }
    } else {
      resolvedMarket = currentMarketIso
    }

    return resolvedMarket
  }, [gameAndAnalysis.game, currentMarketIso, marketIsoForUserLanguage])

  // get market data from state
  const market = useAppSelector((state) => selectMarketByIso(userLanguage)(state, marketIso))

  const { impacts, highlightedImpacts, updateImpactsMappedForTable, isLoading, isFetching, error } = useGameUpdateHistory(marketIso, gameAndAnalysis.game)

  const verticalMarks = useMemo(() => {
    return impacts.map((impact) => ({
      timestamp: impact.releaseDate,
      data: impact,
    }))
  }, [impacts])

  const highlightedVerticalMarks = useMemo(() => {
    return highlightedImpacts.map((impact) => ({
      timestamp: impact.releaseDate,
      data: impact,
    }))
  }, [highlightedImpacts])

  useError({ error })

  const handleColumnFilterChange = useCallback(({ name, value }: ColumnFilterValue) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }))
  }, [])

  const handleFilterChange = useCallback((changedFilters: UpdateImpactRowFilters) => {
    setFilters((currentFilters) => ({ ...currentFilters, ...changedFilters }))
  }, [])

  const handleOpenUpdateClicked = useCallback(
    (update: TableRowUpdateImpact) => {
      navigate(`${location.pathname}/gameupdate/${update.gameId}/${update.version}/${marketIso}`)
    },
    [navigate, location.pathname, marketIso]
  )

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)
  const { exportRows, filename } = useExportDataGameUpdateHistory(gameAndAnalysis.game, updateImpactsMappedForTable, isExporting)

  useEffect(() => {
    if (!isExporting || !exportRows.length) return
    setIsExporting(false)
    generateExport(exportFormat, exportRows, 'Game Update Impacts', filename)
  }, [exportFormat, exportRows, filename, isExporting])

  const handleDataExport = (format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }

  const handleDateRangeChange = useCallback((dateRange: DateRangeValue | undefined) => {
    setDateRange(dateRange)
  }, [])

  const handleGranularityChange = (granularity: GranularityValue) => {
    setGranularity(granularity)
  }

  const globalLaunchDate = gameAndAnalysis.game.globalLaunchDate || gameAndAnalysis.game.released
  return (
    <>
      <Card>
        <Grid container direction="row" p={2} justifyContent="space-between">
          <Typography variant="h3">{t('overview:update_history_chart_title')}</Typography>
          <Box flexDirection="row" display="flex">
            {gameAndAnalysis.game.isSoftLaunch() ? t('overview:data_from_soft_launch_market') : t('overview:data_from')}
            <Box ml={1}>
              <GRCountryFlag countryCode={marketIso} title={market?.name} />
            </Box>
          </Box>
        </Grid>
        <Divider />
        <CardContent sx={{ pt: 2 }}>
          <PerformanceChartV2
            dateRange={dateRange}
            granularity={granularity}
            appId={gameAndAnalysis.game.appId}
            marketIso={marketIso}
            onDateRangeChanged={handleDateRangeChange}
            onGranularityChanged={handleGranularityChange}
            verticalMarks={verticalMarks}
            highlightedVerticalMarks={highlightedVerticalMarks}
            onMarkClicked={handleOpenUpdateClicked}
            yAxisLeftConfig={yAxisLeftConfig}
            onYAxisLeftConfigChanged={(value) => setYAxisLeftConfig(value)}
            yAxisRightConfig={yAxisRightConfig}
            onYAxisRightConfigChanged={(value) => setYAxisRightConfig(value)}
          />
        </CardContent>
      </Card>
      <Divider sx={{ mt: 4, mb: 2 }} light />
      <UpdateImpactFilters filters={filters} onChange={handleFilterChange} showVersionTagSelector={impactDataUnlocked} showComparisonIntervalSelector />
      <UpdateImpactsTable
        rows={updateImpactsMappedForTable}
        filters={filters}
        onColumnFilterChange={handleColumnFilterChange}
        isLoading={isLoading || isFetching}
        tableConfig={{
          impactDataUnlocked,
          showGameColumn: false,
          showPowerscore: true,
          showExportButton: true,
          showFeaturesColumn: true,
          showOpenUpdateColumn: true,
          showAnalystCommentIndicator: true,
          globalLaunchDate,
          powerscoreUnlocked: powerscoreUnlocked,
          analystReviewsAndCommentsUnlocked: hasAnalystReviewsAndCommentsAccessRights,
        }}
        onOpenUpdateClick={handleOpenUpdateClicked}
        onDataExport={handleDataExport}
      />
    </>
  )
}
