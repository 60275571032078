import { useGetUserSettingsQuery, useUpdateUserSettingMutation } from '../../../api/combined'
import { UserSettingKeys } from '../../account/types/UserSettings'

type UnreadReport = {
  id: string // Report Id
  at: number // Report Published Date Unix Timestamp. This can be in the future.
}

/**
 * Hook to get the count of unread reports.
 * @returns {number} The count of unread reports.
 */
export const useGetReportsUnreadCount = (): number => {
  const unreadReports = useGetUnreadReports()
  const unreadCount = Array.isArray(unreadReports) ? unreadReports.length : 0
  return unreadCount
}

/**
 * Hook to get the IDs of unread reports.
 * @returns {string[]} An array of unread report IDs.
 */
export const useGetUnreadReportsIds = (): string[] => {
  const unreadReports = useGetUnreadReports()
  return unreadReports.map((report) => report.id)
}

/**
 * Hook to get the list of unread reports.
 * @returns {UnreadReport[]} An array of unread reports.
 */
export const useGetUnreadReports = (): UnreadReport[] => {
  const { data: userSettings } = useGetUserSettingsQuery()
  return userSettings?.unreadReports?.filter((report: UnreadReport) => report.at <= Date.now()) || ([] as UnreadReport[])
}

/**
 * Hook to update the user's unread reports by removing a specific report ID.
 * @param {string} [reportId] - The ID of the report to be removed from unread reports.
 * @returns {Function} A function to update the user's unread reports.
 */
export const useUpdateUserUnreadReports = (reportId?: string) => {
  const unreadReports: UnreadReport[] = useGetUnreadReports()
  const [updateUserSetting] = useUpdateUserSettingMutation()

  const updateUnreadReports = () => {
    if (!reportId || !unreadReports || unreadReports.length < 0 || !unreadReports.some((report) => report.id === reportId)) {
      return
    }

    const updatedUnreadReports = unreadReports.filter((report) => report.id !== reportId)
    updateUserSetting({ settingKey: UserSettingKeys.unreadReports, value: updatedUnreadReports })
  }

  return updateUnreadReports
}

/**
 * Hook to mark all reports as unread by their IDs. This is for debugging purposes.
 * @param {string[]} [reportIds] - An array of report IDs to be marked as unread.
 * @returns {Function} A function to mark the specified reports as unread.
 */
export const useMarkAllReportsAsUnRead = (reportIds?: string[]) => {
  const [updateUserSetting] = useUpdateUserSettingMutation()

  return () => {
    if (reportIds && reportIds.length > 0) {
      const unreadReports = reportIds.map((id) => ({ id, at: Date.now() }))
      updateUserSetting({ settingKey: UserSettingKeys.unreadReports, value: unreadReports })
    }
  }
}
