import { t } from 'i18next'
import querystring from 'query-string'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, CardContent } from '@mui/material'

import { NoSegmentsChecker } from '../../../../pages/MarketExplorerPage/MarketExplorerPage'
import { SubgenreMap } from '../../../account'
import { useFeatureTags } from '../../../feature/hooks/useFeatureTags'
import { marketExplorerRootPath as marketExplorerRoothPath } from '../../../implementation-examples/hooks/useInitialState'
import MarketExplorerFeaturesData from '../../../market-explorer/components/MarketExplorerFeaturesData/MarketExplorerFeaturesData'
import { SegmentQueryDataType } from '../../../market-explorer/components/MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { useSegmentData } from '../../../market-explorer/hooks/marketExplorerHooks'
import { mapSegmentConfigurationToUrlObject } from '../../../market-explorer/hooks/useMarketExplorerSearchParams'
import { FeaturesDataTableColumn } from '../../../market-explorer/types/MarketExplorerFeaturesDataTableColumn'
import { MarketExplorerSegmentConfiguration } from '../../../market-explorer/types/MarketExplorerSegmentConfiguration'
import { useCurrentMarket } from '../../../markets'
import GenreEssentialCardHeader, { GenreEssentialSection } from '../GenreEssentialCardHeader/GenreEssentialCardHeader'

/**
 * TopFeaturesCard display top feature cards on genre essential pages
 */
interface TopFeaturesCardProps {
  selectedSubgenres: SubgenreMap
}
const TopFeaturesCard: React.FC<TopFeaturesCardProps> = ({ selectedSubgenres }) => {
  const navigate = useNavigate()
  const { currentMarketIso } = useCurrentMarket()
  const featureTags = useFeatureTags()
  const selectedTags = useMemo(() => featureTags.data?.map((group) => group.tags).flat() || [], [featureTags.data])

  const segmentConfiguration: MarketExplorerSegmentConfiguration = useMemo(
    () => ({
      visible: true,
      marketIso: currentMarketIso,
      subgenres: selectedSubgenres,
      filters: {
        ranks: {},
        demographics: {},
        motivations: {},
        archetypes: {},
      },
    }),
    [currentMarketIso, selectedSubgenres]
  )
  const { segmentData, complementaryData } = useSegmentData(segmentConfiguration)
  const segmentQueryData: SegmentQueryDataType = useMemo(() => {
    if (!complementaryData || !segmentData) {
      return null
    }

    return { complementaryData, segmentConfiguration, data: segmentData.data }
  }, [complementaryData, segmentConfiguration, segmentData])

  const defaultSelectedFeatureDataColumns = [
    FeaturesDataTableColumn.ImpactOverall,
    FeaturesDataTableColumn.Top20PercentPopularity,
    FeaturesDataTableColumn.GenderAppeal,
  ]

  const handleOpenMarketExplorerPage = () => {
    const redirectUrl = querystring.stringifyUrl({
      url: marketExplorerRoothPath + '/features-data',
      query: mapSegmentConfigurationToUrlObject(segmentConfiguration, 0),
    })
    navigate(redirectUrl)
  }

  return (
    <Card>
      <GenreEssentialCardHeader
        section={GenreEssentialSection.marketExplorer}
        onClick={handleOpenMarketExplorerPage}
        title={t('genre-essentials:top_features_title')}
        description={t('genre-essentials:top_features_description')}
        buttonLabel={t('genre-essentials:open_in_market_explorer') as string}
      />
      <CardContent>
        <NoSegmentsChecker segmentsData={[segmentQueryData]}>
          <MarketExplorerFeaturesData
            isLoading={segmentData.isFetching}
            maxRows={10}
            hideFilterFeatures
            hideShowOnlyDifferentFeatures
            hideSortBySegments
            segments={[segmentQueryData]}
            selectedColumns={defaultSelectedFeatureDataColumns}
            selectedGamesDataColumns={[]}
            onSelectedGamesDataColumnsChange={() => {}}
            selectedFeatureTags={selectedTags}
          />
        </NoSegmentsChecker>
      </CardContent>
    </Card>
  )
}

export default TopFeaturesCard
