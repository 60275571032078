import React from 'react'
import { useTranslation } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { Box, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'

import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { Quarter } from '../types/Quarter'

export enum QuarterSelectorType {
  string = 'string',
  select = 'select',
}

interface Props {
  selectedQuarter: Quarter
  quarters: Quarter[]
  onQuarterChangeCallback: (event: SelectChangeEvent) => void
  disabled?: boolean
  quarterType?: QuarterSelectorType
}

const QuarterSelector: React.FC<Props> = ({ selectedQuarter, quarters, onQuarterChangeCallback, disabled, quarterType = QuarterSelectorType.select }) => {
  const { t } = useTranslation()

  const quarterLabel = (quarter: Quarter) => `${quarter.year} Q${quarter.quarter}`

  if (quarterType === QuarterSelectorType.string) {
    return (
      <Box>
        <Typography>{quarterLabel(selectedQuarter)}</Typography>
      </Box>
    )
  }

  return (
    <Grid container spacing={1} alignItems="center" justifyContent="center">
      <Grid item>
        <FormControl size="small" variant="outlined">
          <Select disabled={disabled} value={selectedQuarter.quarterIdentifier} onChange={onQuarterChangeCallback}>
            {quarters
              .sort((a, b) => (a.startTimestamp < b.startTimestamp ? 1 : -1))
              .map((quarter: Quarter) => {
                return (
                  <MenuItem key={quarter.quarterIdentifier} value={quarter.quarterIdentifier}>
                    {quarterLabel(quarter)}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      </Grid>
      {selectedQuarter.isQuarterComplete() && (
        <Grid item>
          <GRTooltip content={t('market-share:quarter_not_complete_tooltip')}>
            <Info color="warning" />
          </GRTooltip>
        </Grid>
      )}
    </Grid>
  )
}

export default QuarterSelector
