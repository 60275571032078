import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CreditCard } from '@mui/icons-material'
import { Button } from '@mui/material'

import lockedFeatureService from '../../services/LockedFeatureService'

type ContactButtonProps = {
  text?: string | null
  size?: 'small' | 'medium' | 'large'
}

/**
 * Component for rendering a "Pricing" button which opens a new tab/window to the configured pricing url.
 */
export const ContactButton: FC<ContactButtonProps> = ({ text, size = 'large' }) => {
  const { t } = useTranslation()
  return (
    <Button target="_blank" href={lockedFeatureService.contactUrl} size={size} variant="contained" color="success" startIcon={<CreditCard />}>
      {text ? text : t('common:contact')}
    </Button>
  )
}
