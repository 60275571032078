import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { GRTooltip } from '../GRTooltip/GRTooltip'
import { LockedData } from '../LockedData/LockedData'

type LockedDataIndicatorProps = {}

/**
 *
 * Component for displaying a lock icon with a tooltip.
 */
export const LockedDataIndicator: FC<LockedDataIndicatorProps> = () => {
  const { t } = useTranslation()
  return (
    <GRTooltip content={t('feature:upgrade_your_plan_to_unlock_this_feature')}>
      <LockedData />
    </GRTooltip>
  )
}
