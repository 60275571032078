import { FC } from 'react'

import { LoadingButtonProps, LoadingButton } from '@mui/lab'
import { CircularProgress, Grid, Typography } from '@mui/material'

type ButtonWithSubtitleProps = LoadingButtonProps & {
  title?: string
  subtitle?: string
}

/**
 * A button with title and subtitle. Supports default loading indicator as well.
 */
export const ButtonWithSubtitle: FC<ButtonWithSubtitleProps> = ({ title, subtitle, ...buttonProps }) => {
  return (
    <LoadingButton {...buttonProps} sx={{ ...buttonProps.sx, borderRadius: 10 }} loadingIndicator={<CircularProgress color="inherit" size={24} />}>
      <Grid container direction="column">
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </Grid>
    </LoadingButton>
  )
}
