/* eslint-disable no-console */
import mixpanel from 'mixpanel-browser'

import { User } from '../features/account/types/User'
import { AnalyticsProviders, IAnalyticsProvider } from '../types/IAnalyticsProvider'
import type { ITrackEventOptions } from './AnalyticsService'

class MixPanelService implements IAnalyticsProvider {
  type: AnalyticsProviders = AnalyticsProviders.mixpanel
  enabled: boolean = window.MIXPANEL_CONFIG.enable
  public init(userProfile?: User) {
    if (this.enabled) {
      console.log('mixpanel - init', userProfile)
      mixpanel.init(window.MIXPANEL_CONFIG.key, { debug: false, ignore_dnt: true })
      this.setPeopleIdentity(userProfile)
    }
  }

  private setPeopleIdentity(userProfile?: User) {
    try {
      if (!userProfile) return

      mixpanel.identify(userProfile.id)

      mixpanel.people.set('$first_name', userProfile.firstName)
      mixpanel.people.set('$last_name', userProfile.lastName)
      mixpanel.people.set('$created', userProfile.createdAt ? new Date(userProfile.createdAt).toISOString() : new Date())
      mixpanel.people.set('$email', userProfile.email)
      mixpanel.people.set('organization', { ...userProfile.organization })
      mixpanel.people.set('job_function', userProfile.jobFunction)
    } catch (err) {
      console.error('mixpanel - unable to set user ', err)
    }
  }

  trackEvent(eventName: string, options?: ITrackEventOptions) {
    if (this.enabled) {
      mixpanel.track(eventName, options?.data)
    }
  }
}

const mixPanelService = new MixPanelService()

export default mixPanelService
