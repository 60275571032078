import { FC } from 'react'
import { Trans } from 'react-i18next'

import { ViewList, ViewModule } from '@mui/icons-material'
import { SxProps, ToggleButtonGroup, ToggleButton, Theme } from '@mui/material'

import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import { EventScreenshotPreviewMode } from '../../../types/EventScreenshotPreviewMode'

export const EventScreenshotPreviewModeToggler: FC<{ value: EventScreenshotPreviewMode; onChange: (value: EventScreenshotPreviewMode) => void }> = ({
  value,
  onChange,
}) => {
  const itemSx: SxProps<Theme> = {
    borderColor: (theme) => theme.palette.primary.main,
    '&.Mui-selected': {
      color: (theme) => theme.palette.primary.contrastText,
      backgroundColor: (theme) => theme.palette.primary.main,
      '&:hover': {
        backgroundColor: (theme) => theme.palette.primary.dark,
      },
    },
  }

  return (
    <ToggleButtonGroup
      value={value}
      onChange={(event, newValue) => newValue && onChange(newValue)}
      exclusive
      size="small"
      sx={{ width: '100%' }}
      className="NavigationModeToggler"
    >
      <GRTooltip content={<Trans i18nKey="internal-live-events:screenshot_mode_list" />}>
        <ToggleButton value={EventScreenshotPreviewMode.List} sx={itemSx}>
          <ViewList fontSize="small" />
        </ToggleButton>
      </GRTooltip>
      <GRTooltip content={<Trans i18nKey="internal-live-events:screenshot_mode_grid" />}>
        <ToggleButton value={EventScreenshotPreviewMode.Grid} sx={itemSx}>
          <ViewModule fontSize="small" />
        </ToggleButton>
      </GRTooltip>
    </ToggleButtonGroup>
  )
}
