export enum MarketExplorerSegmentBreakdownBreakdownTypeId {
  ConventionalSubgenreId = 'conventionalSubgenreId',
  CoreId = 'coreId',
  MetaId = 'metaId',
  AppleStoreGenre = 'appGenres',
}

export enum MarketExplorerSegmentBreakdownBreakdownDataKey {
  ConventionalSubgenre = 'conventionalSubgenre',
  Core = 'coreName',
  Meta = 'metaName',
  AppleStoreGenre = 'appGenres',
}

export interface MarketExplorerSegmentBreakdownBreakdownType {
  id: MarketExplorerSegmentBreakdownBreakdownTypeId
  dataKey: MarketExplorerSegmentBreakdownBreakdownDataKey
  name: string
}
