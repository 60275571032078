import { Chart, ChartEvent, ChartTypeRegistry, Plugin } from 'chart.js'

export type MouseOutCatcherOptions = {
  onMouseOut: (event: ChartEvent) => void
}

/**
 * A Chart.js plugin that catches mouse out events and triggers a callback.
 * For some reason this needs to be a plugin and can't be done with the existing event system.
 * https://www.chartjs.org/docs/latest/configuration/interactions.html#event-option
 * @implements {Plugin<keyof ChartTypeRegistry, MouseOutCatcherOptions>}
 */
export default class MouseOutCatcherPlugin implements Plugin<keyof ChartTypeRegistry, MouseOutCatcherOptions> {
  id = 'mouseOutCatcher'
  beforeEvent(chart: Chart, args: { event: ChartEvent; replay: boolean; cancelable: true; inChartArea: boolean }): boolean | void {
    const pluginOptions = chart.options.plugins as unknown as { mouseOutCatcher: MouseOutCatcherOptions | undefined }
    if (args.event.type === 'mouseout') {
      pluginOptions.mouseOutCatcher?.onMouseOut(args.event)
    }
  }
}
