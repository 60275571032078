import { useMemo } from 'react'

import { useGameEstimateData } from '../../estimates'
import { useEstimatesListV2 } from '../../estimates/hooks/estimateHooksV2'
import { EstimatePlatformType } from '../../estimates/types/EstimatePlatformType'
import { GameAndAnalysis } from '../../game/types/GameAndAnalysis'
import { GranularityValue } from '../../revenue-and-downloads/types/Filters'

const defaultPlatformTypes = [EstimatePlatformType.Phone, EstimatePlatformType.Tablet]

type useCompareGamesPerformanceChartsDataHookParams = {
  gamesAndAnalysis: GameAndAnalysis[]
  rollingDays: number
  granularity?: GranularityValue
  platformTypes?: EstimatePlatformType[]
}

export const useCompareGamesPerformanceChartsData = ({
  gamesAndAnalysis,
  rollingDays,
  platformTypes = defaultPlatformTypes,
  granularity = GranularityValue.Week,
}: useCompareGamesPerformanceChartsDataHookParams) => {
  const appIds = useMemo(() => {
    return gamesAndAnalysis.map((gameAndAnalysis) => gameAndAnalysis.game.appId)
  }, [gamesAndAnalysis])

  const markets = useMemo(() => {
    return gamesAndAnalysis.map((gameAndAnalysis) => gameAndAnalysis.analysis?.marketIso).filter((marketIso): marketIso is string => marketIso !== undefined)
  }, [gamesAndAnalysis])

  const filters = useMemo(() => {
    return {
      rollingDays,
      platformTypes,
      marketIsos: markets,
      granularity,
    }
  }, [granularity, markets, platformTypes, rollingDays])

  const { estimatesList } = useGameEstimateData({ appIds, markets, platformTypes })
  const estimates = useEstimatesListV2({ gamesAndAnalysis, data: estimatesList, filters })

  return gamesAndAnalysis.map((gameAndAnalysis) => {
    const appId = gameAndAnalysis.game.appId
    const marketIso = gameAndAnalysis.analysis?.marketIso
    const appIdByMarket = `${appId}-${marketIso}`
    const marketEstimates = estimates.markets.find((marketEstimate) => {
      return marketEstimate.id === appIdByMarket
    })

    return {
      game: gameAndAnalysis.game,
      estimates: marketEstimates?.estimates || [],
      marketIso: marketIso,
    }
  })
}
