import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Download } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { ButtonProps, Card, Fab, ListItemIcon, ListItemText, MenuItem, MenuList, Popper, Tooltip, Typography } from '@mui/material'

import { RoleEnum } from '../../features/account'
import { useRoleCheck } from '../../features/account/hooks/roleHooks'
import analyticsService from '../../services/AnalyticsService'
import { LockedFeatureId } from '../../types/LockedFeature'
import { LockedFeature } from '../LockedFeature/LockedFeature'
import './ExportDataButton.scss'

type ExportDataButtonProps = ButtonProps & {
  analyticsEventOrigin: string
  onChooseFormat?: (format: BookType) => void
  hide?: boolean // disable show export button if not yet implemented
  hideLabel?: boolean // hide label and show only icon
  loading?: boolean // show loading indicator (use this if export needs to load data first or export takes a while)
  formats?: BookType[]
  /**
   * Roles that can export data
   */
  accessRoles?: RoleEnum[]
}

const defaultFormats: BookType[] = ['csv', 'xlsx']

/**
 * Component for Export CSV/XLSX Button. Opens popper menu for choosing the output format.
 */
export const ExportDataButton: React.FC<ExportDataButtonProps> = ({
  analyticsEventOrigin,
  onChooseFormat,
  formats = defaultFormats,
  accessRoles = [],
  hide,
  loading = false,
  hideLabel = false,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const [showExportOptions, setShowExportOptions] = useState(false)
  const [showLockedDialog, setShowLockedDialog] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const hasAccessToDataExport = useRoleCheck(accessRoles)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!hasAccessToDataExport) {
      setShowLockedDialog(true)
      return
    }

    if (showExportOptions) {
      handleClose()
    } else {
      setShowExportOptions(true)
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setShowExportOptions(false)
    setShowLockedDialog(false)
    setAnchorEl(null)
  }

  const handleMenuItemClick = (format: BookType) => {
    if (analyticsEventOrigin)
      analyticsService.trackEvent('Export Data', {
        data: {
          format,
          origin: analyticsEventOrigin,
        },
      })

    onChooseFormat && onChooseFormat(format)
    handleClose()
  }

  if (hide) return null

  return (
    <>
      {hideLabel ? (
        <Tooltip title={t('export-csv:export_button_text') as string}>
          <Fab size="small" color="primary" onClick={handleClick} sx={{ whiteSpace: 'nowrap', boxShadow: (theme) => theme.shadows[2], zIndex: 1 }}>
            <Download />
          </Fab>
        </Tooltip>
      ) : (
        <LoadingButton variant="contained" startIcon={<Download />} onClick={handleClick} className="ExportDataButton" loading={loading} {...buttonProps}>
          {t('export-csv:export_button_text')}
        </LoadingButton>
      )}

      <Popper open={showExportOptions} anchorEl={anchorEl} style={{ zIndex: 2000 }}>
        <Card>
          <MenuList>
            {formats.map((format) => (
              <MenuItem
                key={format}
                onClick={(event) => {
                  handleMenuItemClick(format)
                }}
              >
                <ListItemIcon>
                  <Download />
                </ListItemIcon>
                <ListItemText>
                  <Typography>{t(`export-csv:export_button_${format}`)}</Typography>
                </ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Card>
      </Popper>
      <LockedFeature.Dialog lockedFeatureId={LockedFeatureId.DataExport} onClose={handleClose} open={showLockedDialog} />
    </>
  )
}
