import React from 'react'

import { Check, Close } from '@mui/icons-material'

import './FeatureChoice.scss'

interface Props {
  choiceLocalized: string
  originalChoice: string
  visualizeAsNoType?: boolean
  variant?: 'card' | 'table'
}

const FeatureChoice: React.FC<Props> = (props: Props) => {
  const originalChoice = props.originalChoice.replace(' ', '').toLowerCase()

  let choiceType: string
  switch (originalChoice) {
    case 'yes':
      choiceType = 'yes'
      break

    case 'no':
    case 'none':
    case 'n/a':
    case 'na':
      choiceType = 'no'
      break

    default:
      choiceType = 'text'
      break
  }

  return (
    <div className="FeatureChoice">
      {choiceType === 'text' ? (
        <div className={!props.visualizeAsNoType ? 'FeatureChoice__text' : 'FeatureChoice__no'} title={props.choiceLocalized}>
          {props.choiceLocalized}
        </div>
      ) : null}

      {choiceType === 'no' ? (
        <div className="FeatureChoice__no">
          <Close fontSize="inherit" />
        </div>
      ) : null}

      {choiceType === 'yes' ? (
        <div className={!props.visualizeAsNoType ? 'FeatureChoice__yes' : 'FeatureChoice__no'}>
          <Check fontSize="inherit" />
        </div>
      ) : null}
    </div>
  )
}

export default FeatureChoice
