import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Chip } from '@mui/material'

import { AllOrNothingSelector } from '../../../../components/AllOrNothingSelector/AllOrNothingSelector'
import { GRAccordion } from '../../../../components/GRAccordion/GRAccordion'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { isObjectEmpty } from '../../../../helpers/isObjectEmpty'
import { BooleanMap } from '../../../../types/BooleanMap'
import { LiveEventPricePoint } from '../../types/LiveEventPricePoint'
import './LiveEventsPricePointSelector.scss'

interface Props {
  liveEventPricePoints: LiveEventPricePoint[]
  selectedPricePointIdsMap: { [pricePointId: string]: boolean }
  isLoading?: boolean
  open?: boolean
  onLiveEventsPricePointSelectChange: (newSelection: BooleanMap, pricePoint: LiveEventPricePoint) => void
  onLiveEventsPricePointSelectionCleared: () => void
  onLiveEventsPricePointSelectAll: () => void
}
const LiveEventsPricePointSelector: React.FC<Props> = ({
  liveEventPricePoints,
  selectedPricePointIdsMap,
  isLoading,
  open,
  onLiveEventsPricePointSelectAll,
  onLiveEventsPricePointSelectChange,
  onLiveEventsPricePointSelectionCleared,
}) => {
  const { t } = useTranslation()
  const [liveEventPricePointListOpen, setLiveEventPricePointListOpen] = useState(open || false)

  const handlePricePointClick = (pricePoint: LiveEventPricePoint) => {
    const { id: pricePointId } = pricePoint
    if (selectedPricePointIdsMap[pricePointId]) {
      const { [pricePointId]: omitted, ...newSelection } = selectedPricePointIdsMap
      onLiveEventsPricePointSelectChange(newSelection, pricePoint)
    } else {
      const newSelection = { ...selectedPricePointIdsMap }
      newSelection[pricePointId] = true
      onLiveEventsPricePointSelectChange(newSelection, pricePoint)
    }
  }

  return (
    <div className="LiveEventsPricePointSelector">
      <GRAccordion
        expanded={liveEventPricePointListOpen}
        onChange={() => setLiveEventPricePointListOpen(!liveEventPricePointListOpen)}
        title={t('live-events:live_events_price_point_filter_title')}
        extra={
          <AllOrNothingSelector
            someSelected={!isObjectEmpty(selectedPricePointIdsMap)}
            deselectAll={onLiveEventsPricePointSelectionCleared}
            selectAll={onLiveEventsPricePointSelectAll}
            disabled={isLoading || !liveEventPricePointListOpen}
          />
        }
      >
        <div className="LiveEventsPricePointSelector__Chips">
          {liveEventPricePoints.map((pricePoint) => {
            return (
              <Chip
                key={pricePoint.id}
                label={pricePoint.name}
                color={selectedPricePointIdsMap[pricePoint.id] ? 'secondary' : 'default'}
                onClick={() => handlePricePointClick(pricePoint)}
              />
            )
          })}
          {isLoading && <GRCircularProgress my={2} />}
        </div>
      </GRAccordion>
    </div>
  )
}

export default LiveEventsPricePointSelector
