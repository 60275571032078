import { formatDistanceToNowStrict } from 'date-fns'
import { t } from 'i18next'
import { FC, ReactNode, useMemo } from 'react'

import { PlayCircleOutline } from '@mui/icons-material'
import { Grid, Box, Typography } from '@mui/material'

import Powerscore from '../../../../../components/Powerscore/Powerscore'
import { TrendIndicator, TrendIndicatorType } from '../../../../../components/TrendIndicator/TrendIndicator'
import VersionTagsList from '../../../../../components/VersionTagsList/VersionTagsList'
import { useDateTimeFormatter } from '../../../../live-events/hooks/useDateTimeFormatter'
import { useMobileSize } from '../../../../responsiveness/hooks/responsivenessHooks'
import { useRevenueAndDownloadsDataAccessCheck } from '../../../hooks/unifiedNewsEntryHooks'
import { NewsEntryType, UnifiedNewsEntry } from '../../../types/UnifiedNewsEntry'
import { LiveEventAnalystNoteSubHeader } from './LiveEventSubHeader/LiveEventAnalystNoteSubHeader/LiveEventAnalystNoteSubHeader'
import { LiveEventNewEventSubHeader } from './LiveEventSubHeader/LiveEventNewEventSubHeader/LiveEventNewEventSubHeader'
import './NewsCardSubHeader.scss'

type NewsCardSubHeaderProps = {
  newsEntry: UnifiedNewsEntry
  title?: string
  subtitle?: string
  onFTUEVideoOpen: (open: boolean) => void
  children?: ReactNode
}

export const NewsCardSubHeader: FC<NewsCardSubHeaderProps> = ({ newsEntry, title, subtitle, onFTUEVideoOpen }) => {
  const dateFormatter = useDateTimeFormatter()
  const isSmallMobileSize = useMobileSize(1100)
  const publishedAt = dateFormatter.format(newsEntry.feedEntry.publishedAt)
  const entryType = newsEntry.feedEntry.type
  const gpsChange = newsEntry.feedEntry.entryData.gpsChange || 0
  const displayGpsChange = (newsEntry.feedEntry.entryData.gpsBefore || 0) > 0
  const handleOpenFTUEVideo = () => onFTUEVideoOpen(true)

  return (
    <Grid
      container
      alignItems="center"
      spacing={3}
      flexDirection={isSmallMobileSize ? 'column' : 'row'}
      sx={{ textAlign: isSmallMobileSize ? 'center' : 'left' }}
      className="NewsCardSubHeader"
    >
      <Grid item xs>
        <Box className="NewsCardSubHeader__date">
          {formatDistanceToNowStrict(newsEntry.feedEntry.publishedAt, { addSuffix: true })} / {publishedAt}
        </Box>
        {title && (
          <Typography variant="h2" className="NewsCardSubHeader__title">
            <Box display="inline-block" mr={1}>
              {title}
            </Box>
            <PerformanceChangeIndicator newsEntry={newsEntry} />
          </Typography>
        )}

        {subtitle && entryType !== NewsEntryType.LiveEventNewEventType && entryType !== NewsEntryType.LiveEventAnalystNotes && (
          <div className="NewsCardSubHeader__subtitle">{subtitle}</div>
        )}

        {newsEntry.feedEntry.entryData.versionTags &&
          newsEntry.feedEntry.entryData.versionTags.length > 0 &&
          newsEntry.feedEntry.type !== NewsEntryType.GpsNew &&
          newsEntry.feedEntry.type !== NewsEntryType.GpsOutside && <VersionTagsList versionTags={newsEntry.feedEntry.entryData.versionTags} />}

        {entryType === NewsEntryType.LiveEventNewEventType && <LiveEventNewEventSubHeader newsEntry={newsEntry} />}

        {entryType === NewsEntryType.LiveEventAnalystNotes && <LiveEventAnalystNoteSubHeader newsEntry={newsEntry} />}
      </Grid>
      <Grid item className="text-center">
        <Grid container>
          {/** FTUE VIDEO BUTTON */}
          {newsEntry.hasFTUEVideo && (
            <Grid item>
              <div className="NewsCardSubHeader__ftue-video">
                <div className="NewsCardSubHeader__ftue-video__title">{t('common:play_ftue_video')}</div>
                <div className="NewsCardSubHeader__ftue-video__action" onClick={handleOpenFTUEVideo}>
                  <PlayCircleOutline className="NewsCardSubHeader__ftue-video__action__icon" color="primary"></PlayCircleOutline>
                </div>
              </div>
            </Grid>
          )}
          {newsEntry.feedEntry.entryData?.gps !== undefined && newsEntry.feedEntry.entryData.gps > 0 && (
            <Grid item>
              {/** GAME POWER SCORE */}
              <div
                className={newsEntry.hasFTUEVideo ? 'NewsCardSubHeader__powerscore NewsCardSubHeader__powerscore--divider' : 'NewsCardSubHeader__powerscore'}
              >
                <div className="NewsCardSubHeader__powerscore__title">{t('common:game_power_score')}</div>
                <Grid container alignItems="center" justifyContent="space-between" columnGap={1}>
                  <Grid item flex="1 1 auto">
                    <Powerscore powerscore={newsEntry.feedEntry.entryData.gps}></Powerscore>
                  </Grid>
                  {displayGpsChange && gpsChange !== 0 && (
                    <TrendIndicator value={gpsChange} type={TrendIndicatorType.Value} sign direction maximumFractionDigits={1} />
                  )}
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

type PerformanceChangeIndicatorProps = {
  newsEntry: UnifiedNewsEntry
}
const PerformanceChangeIndicator: FC<PerformanceChangeIndicatorProps> = ({ newsEntry }) => {
  const entryType = newsEntry.feedEntry.type
  const hideRevenueAndDownloadsData = useRevenueAndDownloadsDataAccessCheck(entryType)

  const change = useMemo(() => {
    if (entryType === NewsEntryType.PerformanceChangeRevenue) {
      return (newsEntry.revenueDownloadsData?.percentageIncreaseRevenue || 0) / 100
    } else if (entryType === NewsEntryType.PerformanceChangeDownload) {
      return (newsEntry.revenueDownloadsData?.percentageIncreaseDownload || 0) / 100
    }
  }, [entryType, newsEntry.revenueDownloadsData])

  return change && !hideRevenueAndDownloadsData ? <TrendIndicator value={change} type={TrendIndicatorType.Percentage} sign maximumFractionDigits={2} /> : null
}
