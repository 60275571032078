import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'

import { useGetFeatureQuery, useGetScreenshotsByFeaturesQuery } from '../../../../api/core'
import MultiGameSelector from '../../../../components/MultiGameSelector/MultiGameSelector'
import utilsService from '../../../../services/UtilsService'
import { Game } from '../../../game'
import { useGetGameList } from '../../../game/hooks/gameHooks'
import { FeatureGame } from '../../../game/types/Game'
import { EnrichedFeature } from '../../../implementation-examples'
import ImplementationPreviews from '../../../implementation-examples/components/ImplementationPreviews/ImplementationPreviews'
import { useInitialFeatureModalState, useInitialGameFeatureState } from '../../../implementation-examples/hooks/useInitialState'
import { convertLegacyScreenshots } from '../../../implementation-examples/util/screenshotHelpers'
import './FeatureDefinition.scss'

interface FeatureImplementationsProps {
  feature: EnrichedFeature
}

export const FeatureImplementations: React.FC<FeatureImplementationsProps> = ({ feature }) => {
  const { t } = useTranslation()
  const [initial] = useInitialGameFeatureState()
  const [modalState] = useInitialFeatureModalState()

  const { data: featureDetails, isFetching: isFetchingDetails } = useGetFeatureQuery(feature.legacyId)

  const choiceIds = featureDetails?.choices.map(({ weightId }) => weightId)
  const { data: screenshotsData, isFetching: isFetchingScreenshots } = useGetScreenshotsByFeaturesQuery({ choices: choiceIds || [] }, { skip: !choiceIds })

  const modalStateGames = useMemo<(string | null)[]>(() => {
    if (!modalState || !modalState.games) {
      return []
    }

    if (typeof modalState.games === 'string') {
      return [modalState.games]
    }

    if (Array.isArray(modalState.games)) {
      return modalState.games
    }

    return []
  }, [modalState])

  const gameIds = useMemo(() => {
    const filteredGameIds = screenshotsData?.map(({ gameId }) => gameId as string) || []
    const gameId = initial?.gameId ? [initial?.gameId] : []
    return [...new Set([...modalStateGames, ...filteredGameIds, ...gameId])] as (string | number)[]
  }, [initial?.gameId, modalStateGames, screenshotsData])

  const { data: gamesData, isFetching: isFetchingGames } = useGetGameList(
    {
      gameIds,
      include: 'appId,name,artist,icons,conventionalSubgenre,conventionalSubgenreId,sdranks,sranks',
    },
    { skip: !gameIds }
  )

  const [selectedGames, setSelectedGames] = useState<Game[] | null>(null)

  const games = useMemo(
    () =>
      gameIds
        .map((gameId) => gamesData?.find((game) => game.id === gameId))
        .filter((game): game is Game => !!game)
        .map((game) => {
          const featureGame = new FeatureGame(game)
          featureGame.featureScreenshots = convertLegacyScreenshots((screenshotsData || []).filter(({ gameId }) => gameId === game.id))
          return featureGame
        }),
    [gameIds, gamesData, screenshotsData]
  )

  useEffect(() => {
    if (!screenshotsData || selectedGames !== null || !games?.length) {
      return
    }
    if ((initial && (initial.gameIds || initial.gameId)) || (modalState && modalState?.games)) {
      const initalGameIds = initial?.gameIds || []
      const gameId = initial?.gameId ? [initial?.gameId] : []
      const combinedGameIds = [...initalGameIds, ...modalStateGames, ...gameId]
      setSelectedGames(games.filter((game) => combinedGameIds.includes(game.id)))
    }
  }, [games, initial, modalState, modalStateGames, screenshotsData, selectedGames])

  const features = useMemo(() => {
    return [feature]
  }, [feature])

  const sortedReleaseDate = useMemo(() => {
    if (!selectedGames) return
    return utilsService.oldestReleaseDate(selectedGames as FeatureGame[])
  }, [selectedGames])

  return (
    <Box className="FeatureModalContent">
      <Typography variant="h2" mb={2}>
        {t('implementations:label_select_games_to_view_implementations')}
      </Typography>
      <MultiGameSelector
        loading={isFetchingGames || isFetchingScreenshots || isFetchingDetails}
        options={games}
        selectedGames={selectedGames || []}
        setSelectedGames={setSelectedGames}
        sortCriteria="sustained_grossing_rank"
      />
      {!!selectedGames?.length && (
        <ImplementationPreviews
          selectedGames={selectedGames as FeatureGame[]}
          features={features}
          games={selectedGames as FeatureGame[]}
          fromDate={sortedReleaseDate}
          minDate={sortedReleaseDate}
        />
      )}
    </Box>
  )
}
