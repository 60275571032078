import { FC, ReactNode, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { FormControl } from '@mui/material'

import { useGetTagListQuery } from '../../../../../api/core'
import { SelectMenu, SelectMenuProps } from '../../../../../components/SelectMenu/SelectMenu'
import { useLiveEventsTrackingUserLanguage } from '../../hooks/useLiveEventsTrackingUserLanguage'

type EventTypesSelectMenuProps = Omit<SelectMenuProps<string>, 'label' | 'options'> & {
  value: string
  onChange: (eventTypeId: string) => void
  children?: ReactNode
}

export const EventTypesSelectMenu: FC<EventTypesSelectMenuProps> = ({ value, onChange, ...selectMenuProps }) => {
  const userLanguage = useLiveEventsTrackingUserLanguage()
  const eventTagsQuery = useGetTagListQuery({ type: 'event', userLanguage })

  const eventTypeOptions = useMemo(() => {
    return eventTagsQuery.data?.map((tag) => ({ value: tag.id, name: tag.name })).sort((a, b) => a.name?.localeCompare(b.name)) || []
  }, [eventTagsQuery.data])

  return !eventTagsQuery.isFetching ? (
    <FormControl size="small" variant="outlined" fullWidth>
      <SelectMenu
        {...selectMenuProps}
        options={eventTypeOptions}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        label={<Trans i18nKey="internal-live-events:event_type" />}
      />
    </FormControl>
  ) : null
}
