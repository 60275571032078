import { t } from 'i18next'
import { FC, useState, useCallback, useMemo } from 'react'
import { Trans } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  Divider,
  DialogActions,
  Grid,
  Button,
  Box,
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'

import { Tag } from '../../../../api/core'
import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import GameSubgenrePicker from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { SearchInputWithDebounce } from '../../../../components/SearchInputWithDebounce/SearchInputWithDebounce'
import { SubgenreMap } from '../../../account'
import { Game } from '../../../game'
import { GameSearchResultsTable } from '../../../game-search/components/GameSearchResultsTable/GameSearchResultsTable'
import { GameSearchDialogType, TrackedGamesPermissionMap } from '../../../game-search/types/GameSearchDialogConfig'
import { AppType } from '../../../game/types/Game'
import { useCurrentMarket } from '../../../markets'
import { useLiveEventTags } from '../../hooks/useLiveEventTagGroups'
import { useTrackedGames } from '../../hooks/useTrackedGames'
import LiveEventsTagsSelector from '../LiveEventsTagsSelector/LiveEventsTagsSelector'

/**
 * A search dialog for tracked games
 */
type TrackedGamesSearchDialogProps = {
  open: boolean
  onClose: (games: Game[]) => void
  selectedGames: { [gameId: string]: Game }
}

const minSelectedGames = 1
const allAppTypesId = 'ALL_TYPES'
const appTypes: { id: string; languageKey: string }[] = [
  {
    id: allAppTypesId,
    languageKey: 'common:all',
  },
  {
    id: AppType.MOBILE,
    languageKey: 'common:mobile_game',
  },
  {
    id: AppType.PC_CONSOLE,
    languageKey: 'common:pc_console_game',
  },
]

export const TrackedGamesSearchDialog: FC<TrackedGamesSearchDialogProps> = ({ open, onClose, selectedGames: initialSelectedGames }) => {
  const [selectedLiveEventTags, setSelectedLiveEventTags] = useState<Tag[]>([])
  const trackedGames = useTrackedGames({ eventTypes: selectedLiveEventTags.map((tag) => tag.id) })
  const allTrackedGames = useTrackedGames({})
  const { currentMarketIso } = useCurrentMarket()
  const [selectedSubgenresMap, setSelectedSubgenresMap] = useState<SubgenreMap>({})
  const [searchText, setSearchText] = useState<string>('')
  const [selectedGames, setSelectedGames] = useState<{ [gameId: string]: Game }>(initialSelectedGames)
  const { data: liveEventTagsList } = useLiveEventTags()
  const [selectedAppTypeId, setSelectedAppTypeId] = useState<string>(appTypes[0].id)

  const onSearchTextChange = (newSearchText: string) => {
    setSearchText(newSearchText.toLowerCase())
  }

  const handleGameSelect = useCallback(
    (game: Game) => {
      if (selectedGames[game.id]) {
        const { [game.id]: excludedGame, ...remainingGames } = selectedGames
        setSelectedGames(remainingGames)
      } else {
        setSelectedGames((currentGames) => ({ ...currentGames, [game.id]: game }))
      }
    },
    [selectedGames]
  )

  const handleClose = useCallback(() => {
    onClose(Object.values(selectedGames))
  }, [onClose, selectedGames])

  const handleDismiss = useCallback(() => {
    onClose(Object.values(initialSelectedGames))
  }, [initialSelectedGames, onClose])

  const handleLiveEventsTagSelectChange = useCallback((selectedTags: Tag[], tag: Partial<Tag>) => {
    setSelectedLiveEventTags(selectedTags)
  }, [])

  const handleLiveEventsTagSelectAll = useCallback(() => {
    if (liveEventTagsList) {
      setSelectedLiveEventTags(liveEventTagsList)
    }
  }, [liveEventTagsList])

  const handleLiveEventsTagClear = useCallback(() => {
    setSelectedLiveEventTags([])
  }, [])

  const subgenresSelectionChanged = (selectedSubgenresMap: SubgenreMap) => {
    setSelectedSubgenresMap(selectedSubgenresMap)
  }

  const handleSelectedAppTypeChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    setSelectedAppTypeId(value)
  }

  const filteredGames = useMemo(() => {
    const allGames = trackedGames.data?.map((trackedGame) => trackedGame.game) || []
    return allGames
      .filter((game) => {
        switch (selectedAppTypeId) {
          case allAppTypesId:
            return true

          case AppType.MOBILE:
            return game.appType === AppType.MOBILE ? true : false

          case AppType.PC_CONSOLE:
            return game.isAvailableOnPCOrConsole()

          default:
            return true
        }
      })
      .filter((game) => (Object.keys(selectedSubgenresMap).length ? Object.keys(selectedSubgenresMap).includes(game.conventionalSubgenreId) : true))
      .filter((game) => (searchText.length < 1 ? true : game.resolvedName.toLowerCase().includes(searchText)) || game.artist.toLowerCase().includes(searchText))
  }, [searchText, trackedGames.data, selectedSubgenresMap, selectedAppTypeId])

  const gameSearchConfig = useMemo(() => {
    return {
      type: GameSearchDialogType.TrackedGames,
      gameSelectorCounts: { min: minSelectedGames, max: allTrackedGames.data?.length || 0 },
      trackedGamesPermissionMap: allTrackedGames.data
        ? allTrackedGames.data.reduce((acc, trackedGame) => {
            return { ...acc, [trackedGame.game.id as string]: trackedGame.permission } as TrackedGamesPermissionMap
          }, {} as TrackedGamesPermissionMap)
        : ({} as TrackedGamesPermissionMap),
    }
  }, [allTrackedGames.data])

  const handleSelectAllGames = useCallback(() => {
    const allGamesMap = filteredGames.reduce((acc, game, i) => {
      if (gameSearchConfig.trackedGamesPermissionMap[game.id]) {
        acc[game.id] = game
      }
      return acc
    }, {} as { [gameId: string]: Game })

    setSelectedGames((currentGames) => ({ ...currentGames, ...allGamesMap }))
  }, [filteredGames, gameSearchConfig.trackedGamesPermissionMap])

  const isLoading = trackedGames.isLoading || trackedGames.isFetching
  const selectedGamesCount = Object.keys(selectedGames).length

  return (
    <Dialog open={open} onClose={handleDismiss} maxWidth="md" fullWidth>
      <GRDialogTitle onClose={handleDismiss}>
        <Trans i18nKey="live-events:game_selector_title" />
      </GRDialogTitle>
      <DialogContent dividers sx={{ height: '75vh' }} style={{ overflowY: 'scroll' }}>
        <Box mb={1} style={{ marginTop: '-20px' }}>
          <LiveEventsTagsSelector
            selectedLiveEventTags={selectedLiveEventTags}
            isLoading={liveEventTagsList ? false : true}
            title={t('live-events:live_events_type_games_filter_title')}
            onLiveEventsTagSelectChange={handleLiveEventsTagSelectChange}
            onLiveEventsTagGroupSelected={handleLiveEventsTagSelectChange}
            onLiveEventsTagSelectionCleared={handleLiveEventsTagClear}
            onLiveEventsTagSelectAll={handleLiveEventsTagSelectAll}
            open={false}
          />
        </Box>

        <Divider light sx={{ marginBottom: 2, marginTop: 1 }} />

        <h4 className="bottom-margin">{t('select-game:conventionals_picker_title')}</h4>
        <GameSubgenrePicker selectionChanged={subgenresSelectionChanged} selectedSubgenres={selectedSubgenresMap} hideSetAsDefault showSubgenreDefinitionBtn />

        <Grid container spacing={2} pt={1}>
          <Grid item xs={9}>
            <SearchInputWithDebounce fieldText={t('common:search_game_by_name_publisher')} onDebounce={onSearchTextChange} />
          </Grid>
          <Grid item xs={3}>
            <FormControl size="small" variant="outlined" fullWidth>
              <InputLabel htmlFor="app-type-selector-label">{t('common:filter_by_type')}</InputLabel>
              <Select
                id="app-type-selector"
                labelId="app-type-selector-label"
                value={selectedAppTypeId}
                onChange={handleSelectedAppTypeChange}
                label={t('common:filter_by_type')}
              >
                {appTypes.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {t(type.languageKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Divider className="vertical-margin">
          {!isLoading && <Typography textAlign="center">{t('common:found_matching_the_search_plural', { count: filteredGames.length })}</Typography>}
        </Divider>
        <GameSearchResultsTable
          games={filteredGames}
          marketIso={currentMarketIso}
          isLoading={isLoading}
          onGameSelect={handleGameSelect}
          onSelectAllGames={handleSelectAllGames}
          gameSearchDialogConfig={gameSearchConfig}
          selectedGames={selectedGames}
          selectedGamesCount={selectedGamesCount}
          hidePowerscore
          displayAppType
          displayNotAvailableIcon
        />
      </DialogContent>
      <DialogActions>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={3}>
            <Button variant="contained" color="warning" disabled={selectedGamesCount === 0} onClick={() => setSelectedGames({})}>
              <Trans i18nKey="select-game:clear_selected_games" />
            </Button>
          </Grid>
          <Grid item container xs={6} justifyContent="center">
            <Trans i18nKey="select-game:games_selected" values={{ count: selectedGamesCount, max: allTrackedGames.data?.length || 0 }}></Trans>
          </Grid>
          <Grid item container xs={3} justifyContent="flex-end">
            <Button variant="contained" color="success" onClick={handleClose}>
              {t('common:done')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
