import { Screenshot, useGetScreenshotsByIdsQuery } from '../../../api/core'

export type ScreenshotMap = {
  [screenshotId: string]: Screenshot
}

export const useScreenshotsByIds = (screenshotIds: string[]) => {
  const result = useGetScreenshotsByIdsQuery(screenshotIds)
  if (result.isFetching || !result?.data) {
    return result
  }
  const data = result.data.reduce((acc, screenshot) => {
    return { ...acc, [screenshot.id as string]: screenshot }
  }, {} as ScreenshotMap)
  return { ...result, data }
}

export const useScreenshotsByConceptIds = (conceptIds: string[]) => {
  const result = useGetScreenshotsByIdsQuery(conceptIds)
  if (result.isFetching || !result?.data) {
    return result
  }
  const data = result.data.reduce((acc, screenshot) => {
    return { ...acc, [screenshot.id as string]: screenshot }
  }, {} as ScreenshotMap)
  return { ...result, data }
}
