import { CompareGameFeature, ConceptTag, Feature, Keyword } from '../../../api/core'
import { FeatureChoice } from '../../game-features/types/types'
import { EnrichedFeature } from '../../implementation-examples'

export const isEnrichedFeature = (value: any | null): value is EnrichedFeature => value?.choices !== undefined // TODO-?-KALLE: fragile
export const isKeyword = (value: any | null): value is Keyword => value?.linkedIds !== undefined // TODO-?-KALLE: fragile
export const isFeatureChoice = (value: any | null): value is FeatureChoice => value?.choiceLabel !== undefined // TODO-?-KALLE: fragile
export const isFeature = (value: any | null): value is Feature => value?.legacyId !== undefined // TODO-?-KALLE: fragile
export const isCompareFeatureChoice = (value: any | null): value is CompareGameFeature => value?.featureName !== undefined // TODO-?-KALLE: fragile
export const isConceptTag = (value: any | null): value is ConceptTag => value?.type === 'concept'
