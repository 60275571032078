import { useMemo } from 'react'

import { LiveEventTagGroup } from '../types/LiveEventTagGroup'
import { FilteredTrackingEventsByGame, TrackingEvent } from '../types/TrackingEvents'
import { useLiveEventTagGroupsMapByEventTypeId } from './useLiveEventTagGroups'

// Used for filtering out events that are "Special Events" in Live Events Tracker as we don't want to display those as part of actual Live Events in some cases
export const useNonSpecialEventsTrackingEventsByGame = (trackingEventsByGame: FilteredTrackingEventsByGame) => {
  const liveEventTagGroupsMapByEventTypeId = useLiveEventTagGroupsMapByEventTypeId()

  const filteredTrackingEventsByGame = useMemo(() => {
    const filteredTrackingEventsByGame = { ...trackingEventsByGame }

    const filteredEvents = Object.entries(filteredTrackingEventsByGame.events || {}).reduce((acc, [gameId, events]) => {
      const filteredEvents = events.filter((event) => {
        return (
          liveEventTagGroupsMapByEventTypeId[event.typeId] && liveEventTagGroupsMapByEventTypeId[event.typeId].id !== LiveEventTagGroup.DEFINING_OCCURRENCES
        )
      })

      acc[gameId] = filteredEvents

      return acc
    }, {} as { [gameId: string]: TrackingEvent[] })

    return { ...filteredTrackingEventsByGame, events: filteredEvents }
  }, [liveEventTagGroupsMapByEventTypeId, trackingEventsByGame])

  return filteredTrackingEventsByGame
}
