import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { Box } from '@mui/system'

import './MarketExplorerSegmentColorBadge.scss'

type MarketExplorerSegmentColorBadgeProps = {
  orderNumber?: number | string
  children?: ReactNode
  size?: 's' | 'm' | 'l' | 'xl'
}

export const MarketExplorerSegmentColorBadge: FC<MarketExplorerSegmentColorBadgeProps> = ({ orderNumber = 1, children, size = 's' }) => {
  const classes = classNames('MarketExplorerSegmentColorBadge', `MarketExplorerSegmentColorBadge--${orderNumber}`)
  const sizeMap = {
    s: '1em',
    m: '1.5em',
    l: '1.8em',
    xl: '2em',
  }

  return <Box sx={{ width: sizeMap[size], height: sizeMap[size] }} className={classes}></Box>
}
