// creates an array with unique values only e.g. [1, 2, 2, 3] => [1, 2, 3]
export const uniq = <T>(array: T[]) => [...new Set(array)] as T[]

// creates an array with unique values only compared with given iteratee function
export const uniqBy = <T>(array: T[], iteratee: (item: T) => any) => {
  const seen = new Set()
  return array.filter((item) => {
    const key = iteratee(item)
    if (seen.has(key)) return false
    seen.add(key)
    return true
  })
}
