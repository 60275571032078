import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'

import { Collection, CollectionVisibility, Screenshot } from '../../../api/core'
import { formatDate } from '../../../helpers/date'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import './CollectionPreviewCard.scss'

export interface CollectionPreviewCardProps {
  collection: Collection
  screenshots?: Screenshot[]
}

const CollectionPreviewCard: React.FC<CollectionPreviewCardProps> = ({ collection, screenshots }) => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  const renderedScreenshots = screenshots && screenshots.length ? screenshots.slice(0, Math.min(3, screenshots.length)) : []

  return (
    <Card className="collectionPreview">
      <CardMedia className={`images-${renderedScreenshots.length}`}>
        {renderedScreenshots
          .filter((ss) => !!ss)
          .map((ss, i) => (
            <Box className={`image-${i}`} key={i} sx={{ backgroundImage: 'url(' + ss.url + ')' }} />
          ))}
        {renderedScreenshots.length === 0 && <Typography component="span">{t('implementations:label_there_are_no_implementations')}</Typography>}
        {collection.visibility === CollectionVisibility.organization && (
          <Typography component="span" className="ribbon">
            {t('organization:organization')}
          </Typography>
        )}
        {collection.visibility === CollectionVisibility.public && (
          <Typography component="span" className="ribbon ribbon-public">
            GR
          </Typography>
        )}
      </CardMedia>
      <CardContent>
        <Typography variant="h3" sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
          {collection.name}
        </Typography>
        <Typography variant="body1">{t('implementations:label_no_of_implementations', { count: collection.screenshots?.length })}</Typography>
        <Typography variant="body2">
          {collection.modifiedAt ? formatDate(collection.modifiedAt, { language: userLanguage }) : formatDate(collection.createdAt, { language: userLanguage })}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CollectionPreviewCard
