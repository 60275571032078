import { t } from 'i18next'
import { ReactNode, FC, useRef, useMemo, useState, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { TableContainer, Box } from '@mui/material'

import { GRTableColumn, SortOrder, GRTable } from '../../../../../../components/GRTable/GRTable'
import languageService from '../../../../../../services/LanguageService'
import { InternalTrackingEventEvent } from '../../../../../types/InternalTrackingEvent'

/**
 * Component for listing given events in a table
 */
type EventListTableProps = {
  events?: InternalTrackingEventEvent[]
  isLoading?: boolean
  noRowsLabel?: ReactNode
  renderActions?: (event: InternalTrackingEventEvent) => ReactNode
}

export const EventListTable: FC<EventListTableProps> = ({ events, isLoading, noRowsLabel, renderActions }) => {
  const containerRef = useRef(null)

  const eventsColumns: GRTableColumn<InternalTrackingEventEvent, typeof customTableProps>[] = useMemo(
    () => [
      {
        headerCellProps: { sx: { width: 240, textAlign: 'left' } },
        labelAccessor: () => <Trans i18nKey="internal-common:name" />,
        cellProps: { sx: { fontWeight: 700, textAlign: 'left' } },
        accessor: ({ row }) => row.name,
        sortable: true,
        sortOrder: SortOrder.DESC,
        sortAccessor: ({ row }) => row.name,
      },
      {
        headerCellProps: { sx: { minWidth: 240, textAlign: 'left' } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:event_type" />,
        cellProps: { sx: { textAlign: 'left' } },
        accessor: ({ row }) => languageService.getTranslation('tags', row.typeId),
        sortable: true,
        sortAccessor: ({ row }) => languageService.getTranslation('tags', row.typeId),
      },
      {
        headerCellProps: { sx: { width: 160 } },
        labelAccessor: () => <Trans i18nKey="internal-live-events:actions" />,
        accessor: ({ row, customTableProps }) => customTableProps?.renderActions?.(row),
      },
    ],
    []
  )

  const customTableProps = useMemo(() => ({ renderActions }), [renderActions])
  const [columns, setColumns] = useState<GRTableColumn<InternalTrackingEventEvent, typeof customTableProps>[]>(eventsColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<InternalTrackingEventEvent, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <TableContainer component={Box} ref={containerRef}>
      <GRTable
        columns={columns}
        isLoading={isLoading}
        onColumnsUpdated={handleColumnsUpdate}
        rowIdKey={(event) => event.id as string}
        rows={events || []}
        scroller={containerRef}
        striped
        gridlines
        noRowsLabel={noRowsLabel || t('internal-common:no_rows')}
        customProps={customTableProps}
      />
    </TableContainer>
  )
}
