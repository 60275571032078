import { FC, ReactNode } from 'react'

import { Typography } from '@mui/material'

import languageService from '../../../../../../services/LanguageService'

type MarketExplorerFeatureChoiceItemProps = {
  featureLegacyId: number
  featureChoiceLegacyIds: number[]
  children?: ReactNode
}

export const MarketExplorerFeatureChoiceItem: FC<MarketExplorerFeatureChoiceItemProps> = ({ featureLegacyId, featureChoiceLegacyIds }) => {
  const featureTranslation = languageService.getTranslation('features', featureLegacyId + '')
  const featureChoiceTranslations = featureChoiceLegacyIds.map((choiceId) => languageService.getTranslation('choices', choiceId + '')).join(', ')
  return (
    <>
      <Typography variant="body2">
        {featureTranslation}:{' '}
        <Typography variant="body2" component="span" display="inline">
          {featureChoiceTranslations}
        </Typography>
      </Typography>
    </>
  )
}
