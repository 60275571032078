import { t } from 'i18next'
import { FC, ReactNode, useState } from 'react'
import { Trans } from 'react-i18next'

import { Tune } from '@mui/icons-material'
import { Button, DialogActions, DialogContent, Grid } from '@mui/material'

import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { useLiveEventCommonFilterCounts } from '../../hooks/useLiveEventCommonFilterCounts'
import { TLiveEventsCommonFilters, LiveEventsCommonFiltersData } from '../../types/LiveEventsCommonFilters'
import { LiveEventsCommonFilters } from '../LiveEventsCommonFilters/LiveEventsCommonFilters'

type LiveEventsCommonFiltersDialogProps = {
  filters: TLiveEventsCommonFilters
  data: LiveEventsCommonFiltersData
  onClose: () => void
  onConfirm: (filters: TLiveEventsCommonFilters) => void
  children?: ReactNode
}

export const LiveEventsCommonFiltersDialog: FC<LiveEventsCommonFiltersDialogProps> = ({ filters: defaultFilters, data, onClose, onConfirm, children }) => {
  const [filters, setFilters] = useState<TLiveEventsCommonFilters>(defaultFilters)
  const { activeCount, maxCount } = useLiveEventCommonFilterCounts({ filters, data })

  const handleConfirm = () => {
    onConfirm(filters)
    onClose()
  }

  return (
    <>
      <GRDialogTitle onClose={onClose}>
        <Grid container alignItems="center" columnSpacing={1}>
          <Grid item>
            <Tune sx={{ display: 'block' }} />
          </Grid>
          <Grid item>
            <Trans i18nKey="live-events:view_filters_dialog_title" />
          </Grid>
        </Grid>
      </GRDialogTitle>
      <DialogContent dividers>
        <LiveEventsCommonFilters filters={filters} onChange={setFilters} data={data} />
      </DialogContent>
      <DialogActions>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item container xs={3} spacing={1}>
            <Grid item>
              <Button variant="contained" color="warning" onClick={onClose}>
                <Trans i18nKey="common:cancel" />
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="warning" onClick={() => setFilters(defaultFilters)}>
                <Trans i18nKey="turbo-search:button_reset" />
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={6} justifyContent="center">
            <Trans i18nKey="live-events:filters_selected" values={{ count: activeCount, max: maxCount }}></Trans>
          </Grid>
          <Grid item container xs={3} justifyContent="flex-end">
            <Button variant="contained" color="success" onClick={handleConfirm}>
              {t('common:apply')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  )
}
