import { FC, ReactNode, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Box } from '@mui/material'

import GRCircularProgress from '../../../components/GRCircularProgress/GRCircularProgress'
import { AppType } from '../../../features/game/types/Game'
import { TrackedGame } from '../../../features/live-events/types/TrackedGame'
import { isNil } from '../../../helpers/isNil'
import usePage from '../../../hooks/usePage'
import PageService from '../../../services/PageService'
import { analystsSelecMenuAllValue } from '../../features/live-events-tracking/components/AnalystsSelectMenu/AnalystsSelectMenu'
import { TrackedGamesFilterFields, TrackedGamesFilters } from '../../features/live-events-tracking/components/TrackedGamesFilters/TrackedGamesFilters'
import { TrackedGamesTable } from '../../features/live-events-tracking/components/TrackedGamesTable/TrackedGamesTable'
import { useLiveEventsTrackingMarket } from '../../features/live-events-tracking/hooks/useLiveEventsTrackingMarket'
import { usePinnedTrackedGamesSetting, useTrackedGamesWithAnalystMapping } from '../../features/live-events-tracking/hooks/useTrackedGamesAnalysts'

type TrackedGamesPageProps = {
  children?: ReactNode
}

export const TrackedGamesPage: FC<TrackedGamesPageProps> = ({ children }) => {
  usePage(PageService.getInternalPageWithId('internal-tracked-games'))
  const trackedGamesQuery = useTrackedGamesWithAnalystMapping()
  const pinnedGamesQuery = usePinnedTrackedGamesSetting()
  const pinnedGames = pinnedGamesQuery.data
  const pinnedGamesCount = pinnedGamesQuery.data?.length || 0
  const [initialPinnedGamesOnly, setInitialPinnedGamesOnly] = useState<boolean | undefined>(pinnedGamesQuery.data ? pinnedGamesCount > 0 : undefined)
  const { currentMarketIso: marketIso } = useLiveEventsTrackingMarket()

  const form = useForm<TrackedGamesFilterFields>({
    defaultValues: { analyst: analystsSelecMenuAllValue, gameNameOrPublisherSearchString: '', pinnedGamesOnly: pinnedGamesCount > 0 },
  })
  const { reset } = form

  useEffect(() => {
    if ((isNil(initialPinnedGamesOnly) || pinnedGamesCount === 0) && pinnedGamesQuery.isSuccess) {
      setInitialPinnedGamesOnly(pinnedGamesCount > 0)
    }
  }, [initialPinnedGamesOnly, pinnedGamesCount, pinnedGamesQuery.isSuccess])

  useEffect(() => {
    reset(
      {
        analyst: analystsSelecMenuAllValue,
        pinnedGamesOnly: initialPinnedGamesOnly,
        gameNameOrPublisherSearchString: '',
      },
      { keepDirtyValues: true }
    )
  }, [reset, initialPinnedGamesOnly, pinnedGamesQuery.data?.length])

  const filterValues = form.watch()
  const filteredTableRows = trackedGamesQuery.data
    ?.filter((game) => analystFilter(game, filterValues.analyst))
    .filter((game) => gameNameOrPublisherFilter(game, filterValues.gameNameOrPublisherSearchString))
    .filter((game) => marketFilter(game, marketIso))
    .filter((game) => pinnedGamesFilter(game, filterValues.pinnedGamesOnly, pinnedGamesQuery.data))

  const isLoading = trackedGamesQuery.isLoading || pinnedGamesQuery.isLoading

  return isLoading ? (
    <GRCircularProgress />
  ) : (
    <>
      <Box mb={4}>
        <TrackedGamesFilters form={form} />
      </Box>

      <TrackedGamesTable trackedGames={filteredTableRows || []} isLoading={isLoading} pinnedGamesData={pinnedGames} />
    </>
  )
}

const analystFilter = (game: TrackedGame, analystId: string) => {
  return analystId === analystsSelecMenuAllValue || game.analyst?.id === analystId
}

const gameNameOrPublisherFilter = (game: TrackedGame, searchString: string) => {
  return game.game.name?.toLowerCase().includes(searchString.toLowerCase()) || game.game.artist?.toLowerCase().includes(searchString.toLowerCase())
}

const marketFilter = (game: TrackedGame, marketIso: string) => {
  return game.game.appType !== AppType.MOBILE || !isNil(game.game.gpsPerMarket[marketIso])
}

const pinnedGamesFilter = (game: TrackedGame, pinnedGamesOnly: boolean, pinnedGames: string[] | undefined) => {
  if (!pinnedGames?.length || !pinnedGamesOnly) return game

  return pinnedGames?.includes(game.game.id)
}
