export type FeatureChoice = {
  categoryId: string
  weightActual: number
  weightScaled: number
  gpsEffect: number
  choiceLabel: string
  featureLabel: string
  featureId: string
  choiceLegacyId: number
  featureLegacyId: number
  history: History[]
  tags?: string[]
  originalChoiceLabel?: string
}

export type History = {
  ts: number
  value: number
}

export enum PowerscoreBreakdownCategory {
  Genre = 'genre',
  Brand = 'brand',
  Publisher = 'publisher',
  Feature = 'feature',
  Total = 'total',
}

export type PowerscoreItem = {
  name: string
  description: string
  value: number
}

export type Breakdown = { [breakdownItem in PowerscoreBreakdownCategory]: PowerscoreItem }

export type GameFeatureTableRow = {
  featureId: string
  featureName: string
  featureLegacyId: number
  screenshotCount: number
  choiceLegacyId: number
  choiceName: string
  gpsEffect: number
  categoryId: string
  categoryName: string
  categoryIcon: string
  categoryGpsEffect: number
  featureGroupName: string
  originalChoiceName?: string
  popularityOverall?: number
  popularityTop20Percent?: number
  note?: string
}
