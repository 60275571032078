import { t } from 'i18next'
import { FC, useMemo } from 'react'

import { FormControl, Grid, SelectChangeEvent } from '@mui/material'

import { useGetCategoriesMapQuery } from '../../../../api/core'
import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu'
import languageService from '../../../../services/LanguageService'
import { CategoryImage } from '../../../game-features/components/CategoryImage/CategoryImage'
import { useCurrentMarket } from '../../../markets'

export const AllFeatureCategoriesValue = 'AllFeatureCategories'

type FeatureCategorySelectorProps = {
  value: string
  onChange: (event: SelectChangeEvent) => void
}

/**
 * A component presenting a dropdown selector menu for feature categories
 */
export const FeatureCategorySelector: FC<FeatureCategorySelectorProps> = ({ value, onChange }) => {
  const { currentMarketIso } = useCurrentMarket()
  const { data: featureCategories } = useGetCategoriesMapQuery({ marketIso: currentMarketIso })

  const categorySelectorOptions = useMemo(() => {
    const categories = Object.values(featureCategories || {})
      .sort((a, b) => a.ordr - b.ordr)
      .map((featureCategory) => {
        return {
          name: (
            <Grid container alignItems="center" gap={2}>
              <CategoryImage src={featureCategory.icon.url} alt={featureCategory.name} size="small" />
              {languageService.getTranslation('categories', featureCategory.legacyId.toString())}
            </Grid>
          ),
          value: featureCategory.id,
        }
      })

    return [{ name: t('common:all_categories'), value: AllFeatureCategoriesValue }, ...categories]
  }, [featureCategories])

  return (
    <FormControl size="small" variant="outlined" sx={{ width: 250 }}>
      <SelectMenu label={t('common:category')} value={value} onChange={onChange} options={categorySelectorOptions} />
    </FormControl>
  )
}
