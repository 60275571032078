import { FC, ReactNode, useCallback } from 'react'

import { useRouteModal } from '../../../route-modals/hooks/useRouteModal'
import { EModalType } from '../../../route-modals/types/modalTypes'
import { EventStatisticsDialogTab } from '../../hooks/useEventStatisticsDialogTabs'
import { LiveEventsEventStatisticsDialog } from './LiveEventsEventStatisticsDialog'

export type LiveEventsEventStatisticsDialogParams = {
  liveEventTypeId: string
  trackedGameIds: string
  tab?: EventStatisticsDialogTab
}

/**
 * Container component for Live Events Event Statistics Dialog accessed through url search params
 */
type LiveEventsEventStatisticsDialogContainerProps = LiveEventsEventStatisticsDialogParams & {
  closePath?: string
  children?: ReactNode
}

export const LiveEventsEventStatisticsDialogContainer: FC<LiveEventsEventStatisticsDialogContainerProps> = ({
  liveEventTypeId,
  trackedGameIds,
  tab = EventStatisticsDialogTab.Statistics,
}) => {
  const { popModal, replaceModal } = useRouteModal()
  const trackedGameIdArray = trackedGameIds.split('-')

  const handleTabChange = useCallback(
    (selectedTab: EventStatisticsDialogTab) => {
      replaceModal({
        type: EModalType.LIVE_EVENT_STATISTICS,
        params: {
          trackedGameIds: trackedGameIdArray.join('-'),
          liveEventTypeId: liveEventTypeId,
          tab: selectedTab,
        },
      })
    },
    [replaceModal, trackedGameIdArray, liveEventTypeId]
  )

  const handleClose = () => {
    popModal()
  }

  return (
    <>
      <LiveEventsEventStatisticsDialog
        open
        onClose={handleClose}
        trackedGameIds={trackedGameIdArray}
        eventTypeId={liveEventTypeId}
        tab={tab}
        onTabChange={handleTabChange}
      />
    </>
  )
}
