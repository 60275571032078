import { CSSProperties, FC, memo } from 'react'
import ReactCountryFlag from 'react-country-flag'

import styles from './GRCountryFlag.module.scss'

type GRCountryFlagProps = {
  countryCode?: string
  title?: string
  size?: 'small' | 'medium' | 'large'
  className?: string
  style?: CSSProperties
}

/**
 * Component for displaying a country flag
 */
export const GRCountryFlag: FC<GRCountryFlagProps> = memo(({ countryCode, title, size = 'medium', className, style }) => {
  const flagClasses = `GRCountryFlag ${styles.root} ${styles[size]} ${className}`

  return <ReactCountryFlag className={flagClasses} svg countryCode={countryCode?.toUpperCase() || 'us'} title={title} style={style} />
})
