import { Duration } from '../types/TrackingEvents'

type UseEventDialogDurationHookParams = {
  durationId?: string
  durations?: Duration[]
}

export const useEventDialogDuration = ({ durationId, durations = [] }: UseEventDialogDurationHookParams) => {
  const now = Date.now()

  const sortedDurations = [...durations].sort((a, b) => b.start - a.start)

  const durationsBeforeToday = sortedDurations.filter((duration) => duration.start < now)
  const duration = (sortedDurations.find((duration) => duration.id === durationId) || durationsBeforeToday?.[0] || sortedDurations?.[0]) as Duration | undefined

  return duration
}
