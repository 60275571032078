import { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Check, Lock } from '@mui/icons-material'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
} from '@mui/material'

import screenshot from '../../../../assets/images/see-unlocked-game.png'
import { ButtonWithSubtitle } from '../../../../components/ButtonWithSubtitle/ButtonWithSubtitle'
import { ContactButton } from '../../../../components/ContactButton/ContactButton'
import analyticsService from '../../../../services/AnalyticsService'
import { Analysis } from '../../../analysis/types/Analysis'
import { useAvailableGameFollows, useGameFollow } from '../../hooks/gameHooks'
import { Game } from '../../types/Game'

/**
 * Components for displaying unlock / follow game feature as a card and as a dialog
 */

type UnlockGameCardProps = {
  game?: Game
  analysis?: Analysis
}

const UnlockGameCard: FC<UnlockGameCardProps> = ({ game, analysis }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Unlocked', {
      data: {
        gameId: game?.id,
        gameName: game?.resolvedName,
        analysisId: analysis?.id,
      },
    })
  }, [analysis, game])

  return (
    <Card>
      <CardHeader title={<UnlockGameTitle />} titleTypographyProps={{ variant: 'h3' }} />
      <Divider sx={{ mx: -2 }} />
      <CardContent>
        <UnlockGameContent />
      </CardContent>
      <Divider sx={{ mx: -2 }} />
      <CardActions>
        <UnlockGameActions game={game} />
      </CardActions>
    </Card>
  )
}

interface UnlockGameDialogProps extends DialogProps {
  game?: Game
  onClose?: () => void
}

const UnlockGameDialog: FC<UnlockGameDialogProps> = ({ game, onClose, ...dialogProps }) => {
  const { open } = dialogProps
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <UnlockGameTitle />
      </DialogTitle>
      <DialogContent dividers>
        <UnlockGameContent />
      </DialogContent>
      <DialogActions>
        <UnlockGameActions game={game} onGameFollowed={onClose} />
      </DialogActions>
    </Dialog>
  )
}

const UnlockGameTitle = () => {
  const { t } = useTranslation()

  return (
    <Grid container direction="row" alignItems="center">
      <Lock sx={{ mr: 2 }} />
      {t('unlock-game-dialog:title')}
    </Grid>
  )
}

const UnlockGameContent = () => {
  const { t } = useTranslation()
  return (
    <Grid container wrap="wrap" spacing={4}>
      <Grid item xs={12} md={5}>
        <List disablePadding>
          {Array(6)
            .fill(null)
            .map((item, index) => (
              <ListItem disableGutters key={index}>
                <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
                  <Check color="success" />
                </ListItemIcon>
                {t(`unlock-game-dialog:bullet${index + 1}`)}
              </ListItem>
            ))}
        </List>
      </Grid>
      <Grid item container xs={12} md={7} direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Paper elevation={2} sx={{ borderRadius: 4, overflow: 'hidden' }}>
            <img src={screenshot} alt="" style={{ height: '100%', width: '100%', objectFit: 'contain', display: 'flex' }} />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  )
}

type UnlockGameActionsProps = {
  game?: Game
  onGameFollowed?: () => void
}

const UnlockGameActions: FC<UnlockGameActionsProps> = ({ game, onGameFollowed }) => {
  const { t } = useTranslation()
  const availableGameFollows = useAvailableGameFollows()
  const { isToggling, toggleFollow } = useGameFollow(game)

  const handleGameFollow = useCallback(() => {
    toggleFollow()
    onGameFollowed && onGameFollowed()
  }, [onGameFollowed, toggleFollow])

  return (
    <>
      {availableGameFollows > 0 ? (
        <Grid container direction="row" alignItems="center" gap={2}>
          <ButtonWithSubtitle
            variant="contained"
            color="primary"
            size="large"
            loading={isToggling}
            onClick={handleGameFollow}
            title={t('unlock-game-dialog:follow')}
            subtitle={t('unlock-game-dialog:follow_cost')}
          />
          <Typography variant="body2">
            {availableGameFollows === Infinity ? t('common:unlimited') : availableGameFollows} {t('common:followed_games_slots')} {t('common:available')}
          </Typography>
        </Grid>
      ) : (
        <Grid container direction="row" alignItems="center" gap={2}>
          <Grid item flexGrow={0}>
            <ContactButton />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export const UnlockGame = {
  Card: UnlockGameCard,
  Dialog: UnlockGameDialog,
}
