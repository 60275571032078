import { Interval, areIntervalsOverlapping } from 'date-fns'
import { useMemo } from 'react'

import { EventAppearanceType, LiveEventInstanceTableFilters } from '../types/LiveEventInstanceTableFilters'
import { TrackedGame } from '../types/TrackedGame'
import { TrackingEvent, TrackingEventByGame } from '../types/TrackingEvents'

export const useLiveEventsByInterval = ({
  events,
  interval,
  appId,
  trackedGames,
  filters,
}: {
  appId?: number
  events: TrackingEventByGame
  interval?: Interval
  trackedGames?: TrackedGame[]
  filters?: LiveEventInstanceTableFilters
}) => {
  const gameId = trackedGames?.find((trackedGame) => trackedGame.game.appId === appId)?.game.id
  const gameEvents = useMemo(() => (gameId && events[gameId]) || [], [gameId, events])

  const eventTypeOccurences = useMemo(() => {
    return gameId
      ? gameEvents.reduce((acc, event) => {
          return {
            ...acc,
            [event.eventId]: (acc[event.eventId] || 0) + 1,
          }
        }, {} as { [eventId: string]: number })
      : {}
  }, [gameEvents, gameId])

  return useMemo(() => {
    return gameId
      ? gameEvents
          .filter((event) => {
            return interval ? areIntervalsOverlapping({ start: event.start, end: event.end }, interval) : false
          })
          .filter(eventAppearanceFilter(eventTypeOccurences, filters?.eventAppearance))
      : []
  }, [eventTypeOccurences, filters?.eventAppearance, gameEvents, gameId, interval])
}

const eventAppearanceFilter =
  (eventTypeOccurences: { [eventId: string]: number }, filter?: LiveEventInstanceTableFilters['eventAppearance']) => (event: TrackingEvent) => {
    if (filter) {
      switch (filter) {
        case EventAppearanceType.NonRecurring:
          return eventTypeOccurences[event.eventId] === 1
        case EventAppearanceType.Recurring:
          return eventTypeOccurences[event.eventId] > 1
        default:
          return true
      }
    }

    return true
  }
