import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import './LiveEventsNotPublishedBadge.scss'

type LiveEventsNotPublishedBadgeProps = {
  children?: ReactNode
}

export const LiveEventsNotPublishedBadge: FC<LiveEventsNotPublishedBadgeProps> = ({ children }) => {
  const { t } = useTranslation()
  return (
    <div className="LiveEventsNotPublishedBadge">
      <div className="LiveEventsNotPublishedBadge__Badge">{t('live-events:not_published_badge')}</div>
    </div>
  )
}
