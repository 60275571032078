import { TrackedGame } from './TrackedGame'
import { LiveEventReview } from './TrackingEvents'

export enum LiveEventsGameAlertType {
  Event = 'Event',
  Comment = 'Comment',
}

export type LiveEventsGameAlert = {
  eventId?: string
  eventTypeId?: string
  commentId?: string
  type: LiveEventsGameAlertType
  name: string
  description: string
  gameId: string
  start: number
  trackedGame?: TrackedGame
  review?: LiveEventReview
}
