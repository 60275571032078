import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

import { useGetUserSettingsQuery, useUpdateUserSettingMutation } from '../../../../api/combined'
import { usePostCreateNewGameMutation } from '../../../../api/core'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import analyticsService from '../../../../services/AnalyticsService'
import { UserSettingKeys } from '../../../account/types/UserSettings'
import { useCurrentMarket } from '../../../markets'
import { displaySnackBar } from '../../../snackbar'
import { SnackBarMessage } from '../../../snackbar/types/SnackBarMessage'
import { useCurrentStore } from '../../../store'
import { Game, GameVisibility, NewGameMetaData } from '../../types/Game'
import { GameStageId } from '../../types/GameStageId'
import GameGenreSelect from '../GameGenreSelect/GameGenreSelect'
import GameIconSelect from '../GameIconSelect/GameIconSelect'
import GameNewIntroModal from '../GameNewIntroModal/GameNewIntroModal'
import './GameAddNewModal.scss'

export type GameAddNewModalStage = {
  id: GameStageId
  label: string
}

interface GameAddNewModalProps {
  stages: GameAddNewModalStage[]
  selectedStage?: string
  open: boolean
  onClose: () => void
  onNewGameCreated: (newGame: Game) => void
}
const GameAddNewModal: React.FC<GameAddNewModalProps> = ({ open, onClose, onNewGameCreated, selectedStage = GameStageId.concept.toString(), stages }) => {
  const { t } = useTranslation()

  const [showNewGameModal, setShowNewGameModal] = useState(open)

  const { data: userSettings } = useGetUserSettingsQuery()
  const [saveUserSetting] = useUpdateUserSettingMutation()
  const notShowCreateNewGameIntro = userSettings?.notShowCreateNewGameIntro === 'false' || !userSettings?.notShowCreateNewGameIntro ? false : true
  const [notShowIntro, setNotShowIntro] = useState(false)
  const showIntroModal = open && !notShowCreateNewGameIntro && !showNewGameModal

  const [createNewGame, { isLoading: isGameCreating }] = usePostCreateNewGameMutation()
  const { currentMarket } = useCurrentMarket()
  const { currentStore } = useCurrentStore()

  const dispatch = useAppDispatch()
  const showError = (errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload))

  const newGameMetaData: NewGameMetaData = {
    name: '',
    visibility: GameVisibility.organization,
    stageId: selectedStage,
    markets: [currentMarket?.id as string],
    stores: [currentStore?.id as string],
  }

  const [metaData, setMetaData] = useState<NewGameMetaData>(newGameMetaData)
  useEffect(() => {
    setMetaData({ ...metaData, stageId: selectedStage })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStage])

  useEffect(() => {
    if (open && notShowCreateNewGameIntro) {
      setShowNewGameModal(true)
    }

    if (!open) {
      setShowNewGameModal(false)
    }
  }, [open, notShowCreateNewGameIntro])

  const onDataChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLTextAreaElement
    setMetaData({ ...metaData, [target.name]: target.value })
  }

  const validateMetaData = (): boolean => {
    if (metaData['name'] === '') {
      return false
    }
    return true
  }

  const onNewGameCreateClick = async () => {
    try {
      const { data: newGame } = (await createNewGame(metaData)) as { data: Game }
      resetMetaData()
      onNewGameCreated(newGame)
      setShowNewGameModal(false)
      analyticsService.trackEvent('Created game', { data: { gameId: newGame.id, gameName: newGame.resolvedName } })
    } catch (err) {
      showError({ message: t('game-edit:error_create_new_game_body'), severity: 'error', open: true })
    }
  }

  const resetMetaData = () => {
    setMetaData(newGameMetaData)
  }

  return (
    <>
      <GameNewIntroModal open={showIntroModal}>
        <FormControlLabel
          control={
            <Checkbox
              checked={notShowIntro}
              onChange={() => {
                setNotShowIntro(!notShowIntro)
              }}
            />
          }
          label={t<string>('new-game:not_show_intro_again')}
        />

        <Button
          variant="contained"
          onClick={() => {
            saveUserSetting({ settingKey: UserSettingKeys.notShowCreateNewGameIntro, value: notShowIntro })
            setShowNewGameModal(true)
          }}
        >
          {t('new-game:create_new_game_start_analysis')}
        </Button>
        <Button
          onClick={() => {
            saveUserSetting({ settingKey: UserSettingKeys.notShowCreateNewGameIntro, value: notShowIntro })
            onClose()
          }}
        >
          {t('common:back')}
        </Button>
      </GameNewIntroModal>

      <Dialog open={showNewGameModal} maxWidth="md" fullWidth>
        <DialogTitle>{t('game-edit:general_information')}</DialogTitle>
        <DialogContent aria-disabled dividers sx={{ position: 'relative' }}>
          {isGameCreating && (
            <div className="GameAddNewModal__loadingCover">
              <GRCircularProgress fontSize={1} />
            </div>
          )}
          <Grid container columnSpacing={2} mt={2}>
            <Grid item xs={10}>
              <Box component="form" flexDirection="column" display="flex" noValidate autoComplete="off" onChange={onDataChange}>
                <TextField
                  required
                  autoFocus
                  name="name"
                  size="small"
                  label={t('common:name')}
                  value={metaData['name']}
                  variant="outlined"
                  color="primary"
                  sx={{ mb: 2 }}
                />

                <TextField
                  name="desc"
                  label={t('common:description')}
                  value={metaData['desc']}
                  variant="outlined"
                  color="primary"
                  sx={{ mb: 2 }}
                  multiline
                  rows={4}
                  size="small"
                />

                <Box sx={{ mb: 2 }}>
                  <GameGenreSelect
                    value={metaData['conventionalSubgenreId'] || ''}
                    onChange={(e) => {
                      setMetaData({ ...metaData, conventionalSubgenreId: e.target.value })
                    }}
                    showSubgenreDefinitionBtn
                  />
                </Box>

                <Box sx={{ mb: 1 }}>
                  <FormLabel>{t('game-edit:development_stage')}</FormLabel>
                  <RadioGroup aria-labelledby="development-stage" row value={metaData['stageId']} name="stageId">
                    {stages.map((stage) => (
                      <FormControlLabel sx={{ textTransform: 'capitalize' }} key={stage.id} value={stage.id} control={<Radio />} label={stage.label} />
                    ))}
                  </RadioGroup>
                </Box>

                <FormLabel>{t('game-edit:visibility')}</FormLabel>
                <RadioGroup row value={metaData['visibility']} name="visibility">
                  <FormControlLabel value={GameVisibility.organization} control={<Radio />} label={t('game-edit:share_with_team') as string} />
                  <FormControlLabel value={GameVisibility.private} control={<Radio />} label={t('game-edit:private') as string} />
                </RadioGroup>
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Box sx={{ mb: 1 }}>
                <FormLabel>{t('common:icon')}</FormLabel>
              </Box>
              <GameIconSelect
                onFileSelected={(icon) => {
                  setMetaData({ ...metaData, icon })
                }}
                onFileRemoved={() => {
                  delete metaData.icon
                  setMetaData(metaData)
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              onClose()
              resetMetaData()
            }}
          >
            {t('common:back')}
          </Button>
          <Button disabled={!validateMetaData() || isGameCreating} variant="contained" onClick={onNewGameCreateClick}>
            {t('game-edit:create_game_and_continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GameAddNewModal
