import classNames from 'classnames'
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { Box, BoxProps, debounce } from '@mui/material'

import './MoreScrollContentIndicator.scss'

/**
 * Component for displaying scroll container indicator gradients telling there's more content to scroll up/down to.
 */
type MoreScrollContentIndicatorProps = BoxProps & {
  children?: ReactNode
}

export const MoreScrollContentIndicator: FC<MoreScrollContentIndicatorProps> = ({ children, ...boxProps }) => {
  const containerRef = useRef<HTMLElement | null>(null)
  const [[showTopGradient, showBottomGradient], setGradients] = useState<[boolean, boolean]>([false, false])

  const handleScroll = (element?: HTMLElement) => {
    if (element) {
      setGradients([element.scrollTop > 0, Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) >= 1])
    }
  }

  useEffect(() => {
    const element = containerRef.current
    const debouncedScroll = debounce((event: any) => {
      handleScroll(event.target)
    }, 50)
    element?.addEventListener('scroll', debouncedScroll)
    debouncedScroll(element)

    return () => {
      debouncedScroll.clear()
      element?.removeEventListener('scroll', debouncedScroll)
    }
  }, [containerRef])

  const handleRefChange = useCallback((refNode: HTMLElement) => {
    if (refNode) {
      containerRef.current = refNode
      handleScroll(refNode)
    }
  }, [])

  const classes = classNames('MoreScrollContentIndicator', { showTopGradient, showBottomGradient })

  return (
    <Box {...boxProps} ref={handleRefChange} className={classes}>
      {children}
    </Box>
  )
}
