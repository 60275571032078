import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Info } from '@mui/icons-material'
import { Box, Button, FormLabel, Grid, ListSubheader, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import { useGetGenreTaxonomyQuery } from '../../../../api/core'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { useCurrentUserLanguage } from '../../../account/hooks/useCurrentUserLanguage'
import { ConventionalGenre, ConventionalSubgenre } from '../../../genre-taxonomy'

/**
 * GameGenreSelect Select game genres in list
 */
interface GameGenreSelectProps {
  value: string
  onChange: (e: SelectChangeEvent<any>) => void
  showSubgenreDefinitionBtn?: boolean
}
const GameGenreSelect: React.FC<GameGenreSelectProps> = ({ value, onChange, showSubgenreDefinitionBtn }) => {
  const { t } = useTranslation()
  const userLanguage = useCurrentUserLanguage()
  const { data: taxonomyGenres, isLoading } = useGetGenreTaxonomyQuery({ userLanguage })
  if (isLoading) return <GRCircularProgress />

  const generateSelectMap = () => {
    const generateSubGenre = (subGenres: ConventionalSubgenre[]) =>
      subGenres.map((subGenre) => {
        return (
          <MenuItem sx={{ ml: 4 }} value={subGenre.id}>
            {subGenre.name}
          </MenuItem>
        )
      })

    const generateGenreSelections = (genres: ConventionalGenre[]) =>
      genres.map((genre) => {
        return [
          <ListSubheader sx={{ ml: 2 }} key={genre.id}>
            {genre.name}
          </ListSubheader>,
          generateSubGenre(genre.subgenres),
        ]
      })

    return taxonomyGenres?.map((taxonomyGenre) => {
      return [<ListSubheader key={taxonomyGenre.id}>{taxonomyGenre.name}</ListSubheader>, generateGenreSelections(taxonomyGenre.genres)]
    })
  }

  return (
    <Box display="flex" flexDirection="column">
      <FormLabel sx={{ mb: 1 }}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs>
            {t('common:conventional_subgenre')}{' '}
          </Grid>

          <Grid item xs sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            {showSubgenreDefinitionBtn && (
              <Link to={'/data-glossary/genres'}>
                <Button sx={{ mr: 1 }} size="small" color="secondary" variant="text" startIcon={<Info />}>
                  {t('common:conventional_category_genre_definitions_button')}
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </FormLabel>
      <Select value={value} onChange={onChange} size="small">
        {generateSelectMap()}
      </Select>
    </Box>
  )
}

export default GameGenreSelect
