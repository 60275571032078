import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { useUpdateUserLastSeenNewsMutation } from '../../api/combined'
import { LockedFeature } from '../../components/LockedFeature/LockedFeature'
import { useNewsFeedAccessCheck } from '../../features/account/hooks/roleHooks'
import { GameCardList, useFeaturedGames, useFollowedGames } from '../../features/game'
import { useCurrentMarket } from '../../features/markets'
import { NewsList } from '../../features/news'
import { useLastSeenNewsCount } from '../../features/news/hooks/newsHooks'
import { useMobileSize } from '../../features/responsiveness/hooks/responsivenessHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import PageService from '../../services/PageService'
import { LockedFeatureId } from '../../types/LockedFeature'
import './DailyInsightsPage.scss'

export const INCLUDE = ['id', 'appId', 'icons', 'artist', 'name', 'gpsPerMarket', 'conventionalSubgenreId', 'version']

const DailyInsightsPage: React.FC<{}> = () => {
  const { t } = useTranslation()

  usePage(PageService.getPageWithId('daily-insights'), 'Visited Newsfeed')
  useDocumentTitle(t('common:daily_insights'))

  const [saveLastSeenNews] = useUpdateUserLastSeenNewsMutation()
  const { data: lastSeenNewsCount, isSuccess: isSuccessLastSeenNewsCount } = useLastSeenNewsCount()

  useEffect(() => {
    const isUpdated = isLastSeenNewsTimestampUpdatedRef.current

    if (!isUpdated && isSuccessLastSeenNewsCount) {
      isLastSeenNewsTimestampUpdatedRef.current = true
      const currentTimestamp = new Date().getTime()
      saveLastSeenNews({ value: currentTimestamp })
    }
  }, [lastSeenNewsCount, isSuccessLastSeenNewsCount, saveLastSeenNews])

  const hasNewsFeedAccess = useNewsFeedAccessCheck()

  const mobileSize = useMobileSize('sm')

  const { currentMarketIso: mainMarketIso } = useCurrentMarket()

  const featuredGames = useFeaturedGames(mainMarketIso, INCLUDE)
  const followedGames = useFollowedGames(mainMarketIso, INCLUDE)

  return hasNewsFeedAccess ? (
    <div className="DailyInsightsPage">
      <Grid container spacing={{ xs: 0, md: 1 }}>
        <Grid item xs>
          <NewsList />
        </Grid>
        <Grid item>
          {!mobileSize && (
            <div className="DailyInsightsPage__game-card-lists">
              <GameCardList title={t('newsfeed:featured_games')} games={featuredGames} />
              <GameCardList
                title={t('newsfeed:heading_followed_games')}
                games={followedGames}
                displayLastSeenBadge={true}
                showUnfollowButton={true}
                subpage="updatehistory"
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  ) : (
    <LockedFeature.Card lockedFeatureId={LockedFeatureId.Empty} />
  )
}

export default DailyInsightsPage

export const isLastSeenNewsTimestampUpdatedRef = { current: false }
