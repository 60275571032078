import React from 'react'

import { Circle } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { uniq } from '../../../../helpers/uniq'

type TagDescription = { colorHex: string; name: string }

interface ColorExplanationProps {
  descriptions: TagDescription[]
}

interface SegmentColorIllustratorProps {
  colors?: { [tagId: string]: TagDescription }
  tags?: string[]
}

const ColorExplanations: React.FC<ColorExplanationProps> = ({ descriptions }) => (
  <>
    {descriptions.map(({ colorHex: color, name }) => (
      <Typography key={color}>
        <Circle sx={{ color, fontSize: 14, verticalAlign: 'middle' }} />
        {' ' + name}
      </Typography>
    ))}
  </>
)

export const SegmentColorIllustrators: React.FC<SegmentColorIllustratorProps> = ({ colors, tags }) => {
  if (!colors || !tags) {
    return null
  }
  const boxColors = uniq(tags.map((tag) => colors[tag]?.colorHex))
  const descriptions = tags.map((tag) => colors[tag])
  return (
    <GRTooltip content={<ColorExplanations descriptions={descriptions} />}>
      {boxColors.map((backgroundColor, index) => (
        <Box key={index} className="GroupDefinition" sx={{ backgroundColor, left: index * 5 + 'px !important' }} />
      ))}
    </GRTooltip>
  )
}
