import { useCallback } from 'react'
import { useResolvedPath, useLocation, useNavigate } from 'react-router-dom'

import { replaceLastMatchWith } from '../helpers/string'

/**
 * Helper hook for updating route modal's path with updated route params or closing the route modal
 */
type RouteModalPathHookParams = { currentModalPath: string }

export const useRouteModalPath = ({ currentModalPath }: RouteModalPathHookParams) => {
  const resolvedPath = useResolvedPath({})
  const location = useLocation()
  const navigate = useNavigate()

  const updateDialogPath = useCallback(
    (newPath?: string) => {
      const dialogPath = replaceLastMatchWith(resolvedPath.pathname, currentModalPath, newPath)
      navigate({ ...location, pathname: dialogPath }, { replace: true })
    },
    [currentModalPath, location, navigate, resolvedPath.pathname]
  )

  return updateDialogPath
}
