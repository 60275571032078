import classNames from 'classnames'
import { t } from 'i18next'
import React from 'react'

import { Typography, TypographyProps } from '@mui/material'

import { GRTooltip } from '../GRTooltip/GRTooltip'
import { LinearProgressBar, LinearProgressBarProps } from '../LinearProgressBar/LinearProgressBar'
import './ProgressBar.scss'

type variantKeyOptions = 'small' | 'medium'

const defaultMaxValue = 9
const defaultMinValue = 0

/**
 * ProgressBar wrapper to calculate previous and current value to show differences
 */
interface ProgressBarProps {
  variant?: variantKeyOptions
  value?: number
  disabled?: boolean
  prevValue?: number
}
const ProgressBar: React.FC<ProgressBarProps> = ({ variant = 'medium', value = 0, prevValue = 0, disabled = false }) => {
  const isPositiveImpact = value > prevValue
  const showPrevValue = prevValue !== 0 && prevValue !== value

  const calculatedProgress = (input: number): number => {
    const maxValue = input > defaultMaxValue ? input : defaultMaxValue
    const calculateValue = input > defaultMinValue ? input : defaultMinValue
    const calculateProgress = (calculateValue / maxValue) * 100
    return calculateProgress
  }

  const calculateValueBuffer = () => {
    return calculatedProgress(isPositiveImpact ? value : prevValue)
  }

  const calculateValue = () => {
    if (!showPrevValue) {
      return calculatedProgress(value)
    }
    return calculatedProgress(isPositiveImpact ? prevValue : value)
  }

  const variantOptions: { [key in variantKeyOptions]: TypographyProps } = {
    small: {
      fontSize: '0.9rem',
    },
    medium: {
      fontSize: '1rem',
    },
  }

  const linearProgressOpts: LinearProgressBarProps = {
    variant: showPrevValue ? 'buffer' : 'determinate',
    value: calculateValue(),
    valueBuffer: showPrevValue ? calculateValueBuffer() : undefined,
    labelAfter: <Typography {...variantOptions[variant]}>{value}</Typography>,
  }

  return (
    <div className="ProgressBar">
      <GRTooltip content={t('gps-breakdown:effect_on_gps')}>
        <LinearProgressBar {...linearProgressOpts} className={classNames(isPositiveImpact ? 'positive-impact' : 'negative-impact')} />
      </GRTooltip>
    </div>
  )
}

export default ProgressBar
