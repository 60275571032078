import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box } from '@mui/material'

import GameIcon from '../../features/game/components/GameIcon/GameIcon'
import { Game } from '../../features/game/types/Game'
import { useCurrentMarket } from '../../features/markets'
import { GRCountryFlag } from '../GRCountryFlag/GRCountryFlag'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import Powerscore from '../Powerscore/Powerscore'

/**
 * GameIconWithPowerScore Show game icon with country flag and powerscore on the right side
 * Used in Feature Set Row
 */
interface GameIconWithPowerScoreProps {
  game: Game
  marketIso?: string
  powerscore?: number
  onClick?: (game: Game) => void
}
const GameIconWithPowerScore: React.FC<GameIconWithPowerScoreProps> = ({ game, marketIso, onClick, powerscore: powerscoreProps }) => {
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const selectedMarketIso = !!marketIso ? marketIso : mainMarketIso

  const { t } = useTranslation()
  const powerscore = useMemo(
    () => (powerscoreProps && powerscoreProps > 0 ? powerscoreProps : game.gpsPerMarket[selectedMarketIso]),
    [game.gpsPerMarket, powerscoreProps, selectedMarketIso]
  )

  return (
    <Box display="flex" sx={{ cursor: onClick ? 'pointer' : 'default' }} onClick={() => onClick && onClick(game)}>
      <Box sx={{ mr: 1 }}>
        <GameIcon src={game.icons || game.icon} gameName={game.resolvedName} size="normal" />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" sx={{ height: '60px' }}>
        <Box>
          <GRTooltip content={t('common:tooltip_gps_description') || ''}>
            {powerscore && <Powerscore size={30} fontSize={14} powerscore={powerscore} />}
          </GRTooltip>
        </Box>
        <GRCountryFlag countryCode={selectedMarketIso} />
      </Box>
    </Box>
  )
}

export default GameIconWithPowerScore
