import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
} from '@mui/material'

import { useGetUserSettingsQuery, useUpdateUserSettingMutation } from '../../../../api/combined'
import GameSubgenrePicker from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useGameUpdateImpactsAccessCheck, useLiveEventsTrackerAccessCheck, useSoftLaunchAccessCheck } from '../../../account/hooks/roleHooks'
import { useIsOnFreePlan } from '../../../account/hooks/userHooks'
import { SubgenreMap, UserSettingKeys } from '../../../account/types/UserSettings'
import newsEntriesService, { NewsEntryOptionsTypeId } from '../../services/NewsEntriesService'
import './NewsListPreferencesDialog.scss'

interface Props {
  modalOpen: boolean
  handleClose: (selectedSubgenresMap: SubgenreMap, selectedNewsEntryTypes: Array<string>) => void
  handleCancel: () => void
  selectedSubgenresMap: SubgenreMap
  onSelectedSubgenresChange?: (selectedSubgenresMap: SubgenreMap) => void
}

const NewsListPreferencesDialog: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation()
  const { data: userSettings } = useGetUserSettingsQuery()
  const [saveUserSetting] = useUpdateUserSettingMutation()
  const newsEntryOptionTypes = newsEntriesService.getMappedWithLockedFeature()
  const [isPreferencesChanged, setIsPreferencesChanged] = useState(false)

  const selectedNewsEntryTypesObj = Object.fromEntries(
    newsEntryOptionTypes.map((optionType) => {
      return [
        optionType.id,
        !(userSettings?.newsfeedTypes && userSettings?.newsfeedTypes.length) ||
        optionType.types.some((type) => userSettings?.newsfeedTypes?.includes(type.type))
          ? true
          : false,
      ]
    })
  )

  const [selectedNewsEntryTypes, setSelectedNewsEntryTypes] = useState(selectedNewsEntryTypesObj)
  const clonedSelectedNewsEntryTypes = { ...selectedNewsEntryTypesObj }

  const [selectedSubgenresMap, setSelectedSubgenresMap] = useState(props.selectedSubgenresMap)

  const [lockedFeatureDialogOpen, setLockedFeatureDialogOpen] = useState<boolean>(false)
  const [lockedFeatureDialogType, setLockedFeatureDialogType] = useState<string>()
  const hasAccessToGameUpdateImpacts = useGameUpdateImpactsAccessCheck()
  const hasAccessToSoftLaunch = useSoftLaunchAccessCheck()
  const hasAccessToLiveEvents = useLiveEventsTrackerAccessCheck()
  const isOnFreePlan = useIsOnFreePlan()
  const [selectedSubgenres, setSelectedSubgenres] = useState(props.selectedSubgenresMap)

  useEffect(() => {
    props.onSelectedSubgenresChange && props.onSelectedSubgenresChange(selectedSubgenres)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubgenres])

  const handleClose = () => {
    const newsEntryTypes = newsEntryOptionTypes
      .filter((optionType) => selectedNewsEntryTypes[optionType.id])
      .map((optionType) => optionType.types)
      .flat()
      .map((type) => type.type)
    saveUserSetting({ settingKey: UserSettingKeys.newsfeedTypes, value: newsEntryTypes })

    setIsPreferencesChanged(false)
    setSelectedSubgenres(selectedSubgenresMap)

    props.handleClose(selectedSubgenresMap, newsEntryTypes)
  }

  const handleCancel = () => {
    props.handleCancel()
    setSelectedSubgenresMap(selectedSubgenres)
    setSelectedNewsEntryTypes(clonedSelectedNewsEntryTypes)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPreferencesChanged(true)
    setSelectedNewsEntryTypes({ ...selectedNewsEntryTypes, [event.target.name]: event.target.checked })
  }

  const newsEntriesTypesHtml = newsEntryOptionTypes.map((type) => {
    const hasUpgradePlanButton = () => {
      switch (type.id) {
        case NewsEntryOptionsTypeId.PostUpdate:
          return !hasAccessToGameUpdateImpacts
        case NewsEntryOptionsTypeId.SoftLaunch:
          return !hasAccessToSoftLaunch
        case NewsEntryOptionsTypeId.NewPublishersGame:
          return isOnFreePlan
        case NewsEntryOptionsTypeId.LiveEvents:
          return !hasAccessToLiveEvents
        default:
          return false
      }
    }

    return (
      <Box key={type.id} sx={{ my: 0.5 }}>
        <FormControlLabel
          key={type.id}
          label={t<string>(`newsfeed-options:${type.id}`)}
          control={<Switch size="small" checked={selectedNewsEntryTypes[type.id]} onChange={handleChange} name={type.id} color="primary" />}
        />
        {hasUpgradePlanButton() && (
          <>
            <Button
              autoFocus
              onClick={() => setLockedFeatureDialogType(type.lockedFeature)}
              variant="contained"
              color="primary"
              size="small"
              style={{ float: 'right' }}
            >
              {t('common:unlock')}
            </Button>
            {type.lockedFeature !== '' && (
              <LockedFeature.Dialog
                lockedFeatureId={type.lockedFeature as LockedFeatureId}
                open={lockedFeatureDialogOpen}
                onClose={() => setLockedFeatureDialogOpen(false)}
              />
            )}
          </>
        )}
      </Box>
    )
  })

  const subgenresSelectionChanged = (selectedSubgenresMap: SubgenreMap) => {
    setIsPreferencesChanged(true)
    setSelectedSubgenresMap(selectedSubgenresMap)
  }

  return (
    <>
      <Dialog className="NewsListPreferencesDialog" fullWidth={true} maxWidth="md" open={props.modalOpen}>
        <DialogTitle id="customized-dialog-title">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>{t('newsfeed-options:page_title')}</Grid>
            <Grid item>
              <IconButton aria-label="close" style={{ margin: '-10px' }} onClick={handleCancel}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className="DialogContent" dividers>
          <h4 className="bottom-margin">{t('newsfeed-options:game_type_preferences')}</h4>
          <GameSubgenrePicker
            selectionChanged={subgenresSelectionChanged}
            selectedSubgenres={selectedSubgenresMap}
            showSubgenreDefinitionBtn
          ></GameSubgenrePicker>

          <h4 className="bottom-margin">{t('newsfeed-options:news_type_preferences')}</h4>
          <div className="NewsListPreferencesDialog__news-entry-options">
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <FormGroup>{newsEntriesTypesHtml}</FormGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions style={{ display: 'block' }} className="NewsListPreferencesDialog__actions">
          <Button autoFocus onClick={isPreferencesChanged ? handleClose : handleCancel} variant="contained" color="primary">
            {t('newsfeed-options:save_options')}
          </Button>
        </DialogActions>
      </Dialog>
      {lockedFeatureDialogType !== '' && !!lockedFeatureDialogType && (
        <LockedFeature.Dialog lockedFeatureId={lockedFeatureDialogType as LockedFeatureId} open onClose={() => setLockedFeatureDialogType('')} />
      )}
    </>
  )
}

export default NewsListPreferencesDialog
