import { AnalyticsProviders, IAnalyticsProvider } from '../types/IAnalyticsProvider'
import type { ITrackEventOptions } from './AnalyticsService'

class IntercomService implements IAnalyticsProvider {
  type: AnalyticsProviders = AnalyticsProviders.intercom
  config: any = null

  constructor() {
    this.config = window.INTERCOM_CONFIG
  }

  public get enabled(): boolean {
    return this.config?.enable ? true : false
  }

  trackEvent(eventName: string, options?: ITrackEventOptions | undefined): void {
    const Intercom = window.Intercom
    if (Intercom && this.enabled) {
      Intercom('trackEvent', this.formatIntercomEvent(eventName), options?.data)
    }
  }

  public getIntercomAppId() {
    return this.config.appId
  }

  formatIntercomEvent(eventName: string) {
    const str = eventName
      .toLowerCase()
      .trim()
      // eslint-disable-next-line no-useless-escape
      .replaceAll(/[-!$%^&*()#_+|~=`{}\[\]:";'<>?,.\/]/gm, ' ')
      .replaceAll(/\s+|\s+$|\s+(?=\s)/gm, '-')
    return str
  }
}

const intercomService = new IntercomService()

export default intercomService
