import { t } from 'i18next'
import { FC, ReactNode, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { EventAvailable } from '@mui/icons-material'
import { Button, ButtonProps, Fab } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { useAppDispatch, useAppSelector } from '../../../../hooks/storeHooks'
import { highlightEvent, selectLiveEventModalState } from '../../slices/liveEventModalSlice'
import { LiveEventTrackerTab } from '../../types/LiveEventTrackerTab'

/**
 * Button component for populating related redux state for the LiveEventsCalendar event highlighting
 */
type EventHighlightButtonProps = Omit<ButtonProps, 'variant'> & {
  eventId: string
  trackedGameId?: string
  eventStartTime?: number
  eventEndTime?: number
  eventType?: string
  onEventHighlighted?: (eventId: string) => void
  variant?: 'normal' | 'fab'
  children?: ReactNode
}

export const EventHighlightButton: FC<EventHighlightButtonProps> = ({
  eventId,
  trackedGameId,
  eventStartTime,
  eventEndTime,
  eventType,
  onEventHighlighted,
  variant = 'normal',
  children,
  ...buttonProps
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const liveEventModalState = useAppSelector(selectLiveEventModalState)
  const highlightedEventId = liveEventModalState?.id
  const isSameAsCurrentSelection = eventId === highlightedEventId

  const highlightEventClick = useCallback(
    (eventId: string) => {
      const liveEventsTrackerOpen = location.pathname.includes('/live-events-tracker')

      dispatch(
        highlightEvent(
          isSameAsCurrentSelection
            ? {}
            : {
                id: eventId,
                meta: {
                  trackedGameId,
                  eventStartTime,
                  eventEndTime,
                  eventType,
                  timeout: liveEventsTrackerOpen ? 0 : 1000,
                },
              }
        )
      )

      const searchParams = !liveEventsTrackerOpen ? `?gameIds=${trackedGameId}` : location.search

      if (!isSameAsCurrentSelection && !liveEventsTrackerOpen) {
        navigate(`/live-events-tracker/${LiveEventTrackerTab.Games}${searchParams}`)
      } else if (!isSameAsCurrentSelection) {
        onEventHighlighted?.(eventId)
      }
    },
    [
      location.pathname,
      location.search,
      dispatch,
      isSameAsCurrentSelection,
      trackedGameId,
      eventStartTime,
      eventEndTime,
      eventType,
      navigate,
      onEventHighlighted,
    ]
  )

  const isHiglightedEvent = highlightedEventId === eventId
  const buttonColor = isHiglightedEvent ? 'secondary' : 'success'
  const text = isHiglightedEvent
    ? t('live-events:highlight_these_events_in_calendar_button_remove')
    : t('live-events:highlight_these_events_in_calendar_button')

  return variant === 'normal' ? (
    <Button variant="contained" color={buttonColor} startIcon={<EventAvailable />} onClick={() => highlightEventClick(eventId)} {...buttonProps}>
      {text}
    </Button>
  ) : variant === 'fab' ? (
    <GRTooltip content={text}>
      <Fab
        variant="circular"
        size="small"
        sx={{
          width: 24,
          height: 24,
          minHeight: 24,
          boxShadow: (theme) => theme.shadows[2],
          backgroundColor: (theme) => (isHiglightedEvent ? theme.palette.secondary.main : theme.palette.success.main),
          color: (theme) => theme.palette.success.contrastText,
          '&:hover': {
            backgroundColor: (theme) => (isHiglightedEvent ? theme.palette.secondary.dark : theme.palette.success.dark),
            boxShadow: (theme) => theme.shadows[4],
          },
        }}
        onClick={() => highlightEventClick(eventId)}
      >
        <EventAvailable fontSize="inherit" />
      </Fab>
    </GRTooltip>
  ) : null
}
