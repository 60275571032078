import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material'

import { Tag } from '../../../../api/core'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import LiveEventsTagsSelector from '../../../live-events/components/LiveEventsTagsSelector/LiveEventsTagsSelector'
import { useLiveEventTagGroupsWithTags, useLiveEventTags } from '../../../live-events/hooks/useLiveEventTagGroups'
import { hiddenLiveEventTagGroups } from '../../../live-events/types/LiveEventTagGroup'

/**
 * LiveEventTypesTabView
 */
const LiveEventTypesTabView: React.FC = () => {
  const { t } = useTranslation()
  const [selectedLiveEventTags, setSelectedLiveEventTags] = useState<Tag[]>([])
  const { data: liveEventTagGroupsWithTags } = useLiveEventTagGroupsWithTags()
  const { data: liveEventTagsList } = useLiveEventTags()

  const handleLiveEventsTagSelectChange = useCallback((selectedTags: Tag[], tag: Partial<Tag>) => {
    setSelectedLiveEventTags(selectedTags)
  }, [])

  const handleLiveEventsTagSelectAll = useCallback(() => {
    if (liveEventTagsList) {
      setSelectedLiveEventTags(liveEventTagsList)
    }
  }, [liveEventTagsList])

  const handleLiveEventsTagClear = useCallback(() => {
    setSelectedLiveEventTags([])
  }, [])

  const filteredLiveEventTagGroupsWithTags = useMemo(() => {
    let filtered: typeof liveEventTagGroupsWithTags = []

    if (liveEventTagGroupsWithTags) {
      if (selectedLiveEventTags.length > 0) {
        liveEventTagGroupsWithTags
          ?.filter((tagGroup) => (hiddenLiveEventTagGroups.includes(tagGroup.id) ? false : true))
          .forEach((tagGroup) => {
            const editedTagGroup = {
              ...tagGroup,
              tags: tagGroup.tags.filter((tag) => selectedLiveEventTags.findIndex((selectedTag) => selectedTag.id === tag.id) !== -1),
            }

            if (editedTagGroup.tags.length > 0) {
              filtered!.push(editedTagGroup)
            }
          })
      } else {
        filtered = liveEventTagGroupsWithTags.filter((tagGroup) => (hiddenLiveEventTagGroups.includes(tagGroup.id) ? false : true))
      }
    }

    return filtered
  }, [liveEventTagGroupsWithTags, selectedLiveEventTags])

  return (
    <Box sx={{ marginTop: 9 }} className="LiveEventTypesTabView">
      <LiveEventsTagsSelector
        title={t('data-glossary:filter_by_event_type')}
        selectableLiveEventTags={liveEventTagsList}
        selectedLiveEventTags={selectedLiveEventTags}
        onLiveEventsTagSelectChange={handleLiveEventsTagSelectChange}
        onLiveEventsTagGroupSelected={handleLiveEventsTagSelectChange}
        onLiveEventsTagSelectionCleared={handleLiveEventsTagClear}
        onLiveEventsTagSelectAll={handleLiveEventsTagSelectAll}
        open
        hideDefinitionsBtn
      />

      {!filteredLiveEventTagGroupsWithTags && (
        <Box sx={{ my: 3 }}>
          <GRCircularProgress />
        </Box>
      )}

      {filteredLiveEventTagGroupsWithTags &&
        filteredLiveEventTagGroupsWithTags.map((tagGroup) => {
          return (
            <Box className="LiveEventTypesTabView__TagGroup" key={tagGroup.id}>
              <Divider sx={{ my: 3 }} />
              <Typography variant="h2" sx={{ mb: 3 }}>
                {tagGroup.name}
              </Typography>
              <Grid container sx={{ flexWrap: 'wrap' }} spacing={2}>
                {tagGroup.tags.map((tag, index: number) => {
                  return (
                    <Grid className="LiveEventTypesTabView__TagGroup__Tag" key={`${index}-${tag.id}`} container item xs={12} md={6}>
                      <Grid container component={Card} sx={{ padding: 2, justifyContent: 'space-between' }} key={tag.id}>
                        <Grid item xs={6}>
                          <img className="DataGlossaryPage__Screenshot" src={tag.imageUrl} alt="" style={{ width: '100%' }} />
                          <Typography className="DataGlossaryPage__ScreenshotText" sx={{ textAlign: 'center' }} mt={1}>
                            {tag.imageText}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <CardHeader sx={{ paddingTop: 0 }} title={tag.name} />
                          <CardContent sx={{ paddingTop: 0 }}>
                            <Typography variant="body2">{tag.description}</Typography>
                          </CardContent>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          )
        })}
    </Box>
  )
}

export default LiveEventTypesTabView
