import { Interval, add, isWithinInterval, startOfDay, sub } from 'date-fns'
import { ReactNode, useCallback, useState } from 'react'

import { Dialog, DialogContent, DialogProps } from '@mui/material'

import { GranularityValue } from '../../features/revenue-and-downloads/types/Filters'
import { getIntervalByGranularity } from '../../helpers/date'
import { DateRangeValue } from '../DateRangePicker/DateRangePicker'
import GRDialogTitle from '../GRDialogTitle/GRDialogTitle'
import { PerformanceChartV2 } from '../PerformanceChartV2/PerformanceChartV2'
import { PerformanceChartV2DataType } from '../PerformanceChartV2/PerformanceChartV2DataType'
import { YAxisConfig } from '../PerformanceChartV2/PerformanceChartV2Types'

export type TimelineChartVerticalMark<MarkData> = {
  timestamp: number | Date | string
  data?: MarkData
}

/**
 * Component that displays a dialog with game performance data
 */
export type GamePerformaceDialogData = {
  appId: number
  marketIso: string
  gameName: string
  highlightedTimestamp?: number
  initialYAxisLeftConfig?: YAxisConfig
  initialYAxisRightConfig?: YAxisConfig
  initialGranularity?: GranularityValue
}

type GamePerformanceDialogProps<VerticalMarkDataType extends TimelineChartVerticalMark<any>> = DialogProps &
  GamePerformaceDialogData & {
    verticalMarks?: VerticalMarkDataType[]
    highlightedVerticalMarks?: VerticalMarkDataType[]
    selectedVerticalMarks?: VerticalMarkDataType[]
    onChartClick?: (interval?: Interval) => void
    render?: (granularity: GranularityValue) => ReactNode
    children?: ReactNode
  }

export const GamePerformanceDialog = <VerticalMarkDataType extends TimelineChartVerticalMark<any>>({
  open,
  onClose,
  appId,
  marketIso,
  gameName,
  verticalMarks,
  highlightedVerticalMarks,
  selectedVerticalMarks,
  initialYAxisLeftConfig = {
    dataType: PerformanceChartV2DataType.Revenue,
    excludedDataTypes: [PerformanceChartV2DataType.DAU, PerformanceChartV2DataType.MAU],
  },
  initialYAxisRightConfig = {
    dataType: PerformanceChartV2DataType.Downloads,
    excludedDataTypes: [PerformanceChartV2DataType.DAU, PerformanceChartV2DataType.MAU],
  },
  initialGranularity = GranularityValue.Day,
  onChartClick,
  render,
  children,
}: GamePerformanceDialogProps<VerticalMarkDataType>) => {
  const selectedDate = startOfDay(selectedVerticalMarks && selectedVerticalMarks.length > 0 ? selectedVerticalMarks[0].timestamp : new Date())
  const initialDateRange = useGamePerformanceDialogInitialDateRange({ selectedDate })
  const [dateRange, setDateRange] = useState<DateRangeValue | undefined>(initialDateRange)
  const [granularity, setGranularity] = useState(initialGranularity)
  const [yAxisLeftConfig, setYAxisLeftConfig] = useState<YAxisConfig>(initialYAxisLeftConfig)
  const [yAxisRightConfig, setYAxisRightConfig] = useState<YAxisConfig>(initialYAxisRightConfig)

  const handleDateRangeChange = useCallback((dateRange: DateRangeValue | undefined) => {
    setDateRange(dateRange)
  }, [])

  const handleGranularityChange = (granularity: GranularityValue) => {
    setGranularity(granularity)
  }

  const handleChartClick = (xValue?: number) => {
    if (xValue) {
      const { start, end } = getIntervalByGranularity(granularity, xValue)
      onChartClick?.({ start, end })
    } else {
      onChartClick?.()
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth disableRestoreFocus>
      <GRDialogTitle onClose={onClose}>{gameName}</GRDialogTitle>
      <DialogContent dividers>
        <PerformanceChartV2
          dateRange={dateRange}
          granularity={granularity}
          appId={appId}
          marketIso={marketIso}
          onDateRangeChanged={handleDateRangeChange}
          onGranularityChanged={handleGranularityChange}
          verticalMarks={verticalMarks}
          highlightedVerticalMarks={highlightedVerticalMarks}
          selectedVerticalMarks={selectedVerticalMarks}
          yAxisLeftConfig={yAxisLeftConfig}
          onYAxisLeftConfigChanged={(value) => setYAxisLeftConfig(value)}
          yAxisRightConfig={yAxisRightConfig}
          onYAxisRightConfigChanged={(value) => setYAxisRightConfig(value)}
          onChartClick={handleChartClick}
        />
        {render?.(granularity)}
      </DialogContent>
    </Dialog>
  )
}

const useGamePerformanceDialogInitialDateRange = ({
  selectedDate,
  rangeLengthInMonths = 3,
}: {
  selectedDate: number | Date
  rangeLengthInMonths?: number
}): DateRangeValue => {
  const defaultFromDate = sub(startOfDay(new Date()), { months: rangeLengthInMonths })
  const defaultToDate = startOfDay(new Date())
  const isSelectedDateWithinDefaultInterval = isWithinInterval(selectedDate, { start: defaultFromDate, end: defaultToDate })
  const initialFromDate = isSelectedDateWithinDefaultInterval ? defaultFromDate : sub(selectedDate, { months: rangeLengthInMonths / 2 })
  const initialToDate = isSelectedDateWithinDefaultInterval ? defaultToDate : add(selectedDate, { months: rangeLengthInMonths / 2 })

  return { fromDate: initialFromDate, toDate: initialToDate }
}
