import { ReactNode, FC } from 'react'

import { Box } from '@mui/material'

import { useAvailableMarketIsos } from '../../../../account/hooks/userHooks'
import { MarketChip } from '../../../../region/components/RegionMarketSelector/MarketChip/MarketChip'

/**
 * Component representing market selector for segment edit dialog
 */
type SegmentMarketSelectorProps = {
  selectedMarketIso?: string
  onChange: (marketIso: string) => void
  children?: ReactNode
}

export const SegmentMarketSelector: FC<SegmentMarketSelectorProps> = ({ selectedMarketIso, onChange }) => {
  const marketIsos = useAvailableMarketIsos()

  return (
    <Box className="SegmentMarketSelector">
      {marketIsos?.map((marketIso) => {
        return <MarketChip marketIso={marketIso} selected={selectedMarketIso === marketIso} onClick={() => onChange(marketIso)} key={marketIso} />
      })}
    </Box>
  )
}
