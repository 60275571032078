import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useTheme } from '@mui/material'

import { BarChart } from '../../../../../components/BarChart/BarChart'
import { useDateTimeFormatter } from '../../../hooks/useDateTimeFormatter'

type LiveEventsMonthlyEventStatisticsChartProps = {
  statistics: { [month: number]: number }
}
export const LiveEventsMonthlyEventStatisticsChart: FC<LiveEventsMonthlyEventStatisticsChartProps> = ({ statistics }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const chartLabelFormatter = useDateTimeFormatter({ month: 'short', year: 'numeric' })
  const data = {
    labels: Object.keys(statistics).map((month) => chartLabelFormatter.format(+month)),
    datasets: [
      {
        label: t('live-events:event_statistics_dialog_popularity'),
        data: Object.values(statistics).map((value) => value * 100),
        yAxisID: 'y1',
        backgroundColor: theme.palette.primary.main,
      },
    ],
  }

  return <BarChart data={data} scaleConfig={{ y1: { maxY: 100, precision: 1, title: t('live-events:event_statistics_dialog_chart_y_axis_title') } }} />
}
