/* eslint-disable no-console */
const ENV_CONFIG = require(`../config.${process.env.REACT_APP_ENVIRONMENT}.json`)
const KEYCLOAK_CONFIG = ENV_CONFIG.KEYCLOAK_CONFIG

// dummy typings for keycloak
// TODO: use keycloak-js provided typings once nodejs environment is updated to use version >= 18
type Keycloak = any
type KeycloakInitOptions = any

const KEYCLOAK_INIT_CONFIG: KeycloakInitOptions = {
  onLoad: 'check-sso',
  checkLoginIframe: true,
}

const keycloak = new window.Keycloak(KEYCLOAK_CONFIG) as Keycloak

export enum KeycloakAuthStatus {
  AuthDone = 'AUTH_DONE',
}

class KeycloakService {
  get instance() {
    return keycloak
  }

  get profile() {
    return keycloak.profile
  }

  get token() {
    return keycloak.token
  }

  get authenticated() {
    return keycloak.authenticated
  }

  get googleSignupUrl() {
    return keycloak.createLoginUrl({
      idpHint: 'google',
    })
  }

  async init(options: Partial<KeycloakInitOptions> = KEYCLOAK_INIT_CONFIG) {
    const config = { ...KEYCLOAK_INIT_CONFIG, ...options }
    const authenticated = await keycloak.init(config)

    if (authenticated) {
      console.log('Keycloak: User Authenticated!')
      try {
        await keycloak.loadUserProfile()
      } catch (error) {
        console.error('Keycloak: User profile not loaded', error)
      } finally {
        return KeycloakAuthStatus.AuthDone
      }
    }
  }

  isTokenExpired() {
    return keycloak.isTokenExpired()
  }

  updateToken(): Promise<boolean> {
    return keycloak.updateToken()
  }

  login() {
    keycloak.login()
  }

  logout() {
    keycloak.logout()
  }
}

const keycloakService = new KeycloakService()

export default keycloakService
