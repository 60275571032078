import { modalParamPrefix, modalParamSeparator, modalParamsByModalType, modalTypeParamKey } from '../const/const'
import { EModalType, TModalParamsByModalType } from '../types/modalTypes'

/**
 * Resolves modal parameters for each modal in the stack by its type
 */
export const resolveModalParams = <T extends EModalType>({
  modalTypes,
  searchParams,
  paramPrefix = modalParamPrefix,
}: {
  modalTypes: EModalType[]
  searchParams: URLSearchParams
  paramPrefix?: string
}): { modalType: T; params: { [key in TModalParamsByModalType[T]]?: string | number | symbol | undefined } }[] => {
  // as we read modal parameters by type one by one, we need to keep track of the remaining parameters for each type
  const remainingParams: { [key: string]: string[] } = {}

  return modalTypes
    .reverse()
    .map((modalType) => {
      const modalParams = modalParamsByModalType[modalType] as unknown as TModalParamsByModalType[T][]
      const resolvedParams = modalParams.reduce((acc, param) => {
        const value = searchParams.get(`${paramPrefix}${param}`)
        const valueAsArray = remainingParams[param] ? remainingParams[param] : value ? value.split(',') : []
        const nextValue = valueAsArray.pop()

        if (param !== undefined && nextValue) {
          acc[param] = nextValue
        }

        remainingParams[param] = valueAsArray

        return acc
      }, {} as { [key in TModalParamsByModalType[T]]?: string | number | symbol | undefined })

      return { modalType: modalType as T, params: resolvedParams }
    })
    .reverse()
}

/**
 * Picks modal parameters from the given object
 *
 * @param param0
 * @returns url search params related to modals
 */
export const pickModalParams = ({ params, prefix = modalParamPrefix }: { params: Record<string, any>; prefix?: string }) => {
  return Object.entries(params)
    .filter(([key]) => key.startsWith(prefix) || key === modalTypeParamKey)
    .reduce((acc, [key, value]) => {
      acc[key] = value
      return acc
    }, {} as Record<string, any>)
}

/**
 * Adds a parameter to the search params with the given key. If the key already exists, the value is appended to the existing values.
 *
 * @param searchParams search params to add the parameter to
 * @param key key of the parameter
 * @param value value of the parameter
 * @param reversed  if true, the value is added to the beginning of the list of values
 */
export const addParamWithKey = ({
  searchParams,
  key,
  value,
  reversed = false,
}: {
  searchParams: URLSearchParams
  key: string
  value: string
  reversed?: boolean
}) => {
  if (key && value) {
    const currentValue = searchParams.get(key)
    const values = currentValue ? currentValue.split(modalParamSeparator) : []
    const newValue = reversed ? [value, ...values] : [...values, value]
    searchParams.set(key, newValue.join(modalParamSeparator))
  }

  return searchParams
}

/**
 * Removes the last parameter with the given key from the search params.
 * If the parameter has multiple values, the last value is removed.
 *
 * @param searchParams  search params to remove the parameter from
 * @param key   key of the parameter
 * @returns   the removed value
 */
export const removeLastParamWithKey = (searchParams: URLSearchParams, key: string) => {
  const value = searchParams.get(key)
  const values = value ? value.split(modalParamSeparator) : []
  let removedValue

  if (values.length > 1) {
    removedValue = values.pop()
    searchParams.set(key, values.join(modalParamSeparator))
  } else if (values.length === 1) {
    removedValue = values.pop()
    searchParams.delete(key)
  } else {
    searchParams.delete(key)
  }

  return removedValue
}
