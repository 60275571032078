import classNames from 'classnames'
import { FC, memo, ReactNode, useMemo } from 'react'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import utilsService from '../../services/UtilsService'
import { NoChangeIcon } from '../NoChangeIcon/NoChangeIcon'
import styles from './TrendIndicator.module.scss'

type TrendIndicatorProps = {
  value: number
  type?: TrendIndicatorType
  sign?: boolean
  direction?: boolean
  color?: boolean
  maximumFractionDigits?: number
  maxValue?: number
  displayZero?: boolean
  emptyValue?: ReactNode
  allowInfinity?: boolean
}

const defaultEmptyValue = ''

export enum TrendIndicatorType {
  Percentage = 'percentage',
  Value = 'value',
}

/**
 * Component for displaying trend as percentage value. Trend direction indicated with colors and optional direction arrows.
 * Value of 1 denotes 100% increase and value of -1 denotes 100% decrease.
 */
export const TrendIndicator: FC<TrendIndicatorProps> = memo(
  ({
    value,
    type = TrendIndicatorType.Percentage,
    sign = true,
    direction = false,
    color = true,
    maximumFractionDigits = 0,
    maxValue,
    displayZero = false,
    emptyValue = defaultEmptyValue,
    allowInfinity = false,
  }) => {
    const valueToFormat = maxValue && value > maxValue ? maxValue : isNaN(value) || (!allowInfinity && !isFinite(value)) ? 0 : value
    const fractionValue =
      type === TrendIndicatorType.Percentage
        ? +parseFloat((valueToFormat * 100).toFixed(maximumFractionDigits))
        : +parseFloat(valueToFormat.toFixed(maximumFractionDigits))

    const classes = classNames(styles.root, {
      [styles.positive]: color && (fractionValue > 0 || (allowInfinity && fractionValue === Infinity)),
      [styles.negative]: color && (fractionValue < 0 || (allowInfinity && fractionValue === -Infinity)),
      [styles.neutral]: color && fractionValue === 0,
    })

    const trendDirection = useMemo(() => {
      if (!direction) {
        return
      }

      if (fractionValue === 0) {
        return 'indecisive'
      } else if (fractionValue > 0) {
        return 'up'
      } else if (fractionValue < 0) {
        return 'down'
      }
    }, [fractionValue, direction])

    const relationalPrefix = maxValue && value > maxValue ? '> ' : ''
    const formattedValue = formatValue(valueToFormat, type, sign, maximumFractionDigits)

    const displayValue = useMemo(() => {
      if (allowInfinity && !isFinite(value)) {
        return
      } else {
        return fractionValue !== 0 || displayZero ? relationalPrefix + formattedValue : emptyValue
      }
    }, [allowInfinity, displayZero, emptyValue, formattedValue, fractionValue, relationalPrefix, value])

    return (
      <span className={classes}>
        <>
          <TrendDirectionIndicator direction={trendDirection} />
          {displayValue}
        </>
      </span>
    )
  }
)

const TrendDirectionIndicator: FC<{ direction?: 'up' | 'down' | 'indecisive' }> = ({ direction }) => {
  switch (direction) {
    case 'up':
      return <ArrowDropUpIcon />
    case 'down':
      return <ArrowDropDownIcon />
    case 'indecisive':
      return <NoChangeIcon />
    default:
      return null
  }
}

const formatValue = (value: number, type: TrendIndicatorType, sign: boolean, maximumFractionDigits: number) => {
  switch (type) {
    case TrendIndicatorType.Percentage:
      return utilsService.formatPercent(value, {
        signDisplay: sign && value !== 0 ? 'always' : 'never',
        maximumFractionDigits,
        useGrouping: value >= 100,
      })
    case TrendIndicatorType.Value:
      return utilsService.formatNumber(value, { sign, shorten: true, mantissa: maximumFractionDigits })
  }
}
