import { FC } from 'react'

import { Box, BoxProps } from '@mui/material'

import { TagGroup } from '../../../../api/core'
import './EventTagGroupIndicator.scss'

/**
 * Component for drawing a colored indicator for an event tag group
 */
type EventTagGroupIndicatorProps = BoxProps & {
  eventTypeId?: string
  mapping?: { [eventTypeId: string]: TagGroup }
  colorHex?: string
}
export const EventTagGroupIndicator: FC<EventTagGroupIndicatorProps> = ({ colorHex, eventTypeId, mapping, ...boxProps }) => {
  const color = colorHex || (mapping && eventTypeId && mapping[eventTypeId] && mapping[eventTypeId].colorHex)

  return color ? <Box className="EventTagGroupIndicator" {...boxProps} sx={{ backgroundColor: color, left: 0, ...boxProps.sx }}></Box> : null
}
