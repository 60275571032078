import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { Box, DialogProps, Dialog, DialogContent, DialogTitle, DialogActions, Typography, IconButton, Grid, Chip, Button } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

import { Analysis } from '../../features/analysis/types/Analysis'
import { Game } from '../../features/game'
import { useGameAndAnalysis } from '../../features/game/hooks/gameHooks'
import { useAvailableMarketIsoForUserLanguage } from '../../features/markets/hooks/useAvailableMarketIsoForUserLanguage'
import { useCurrentMarket } from '../../features/markets/hooks/useCurrentMarket'
import languageService from '../../services/LanguageService'

/**
 * Component that displays a dialog with game AppStore descriptions and links
 */

type GameInfoDialogProps = DialogProps & {
  game: Game
  analysis?: Analysis
}

export const GameInfoDialog: FC<GameInfoDialogProps> = ({ open, onClose, analysis, game, ...dialogProps }) => {
  const { t } = useTranslation()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { gameAndAnalysis, isLoading: gameAndAnalysisIsLoading, error } = useGameAndAnalysis(game.id, mainMarketIso)
  const marketIsoForUserLanguage = useAvailableMarketIsoForUserLanguage(gameAndAnalysis?.game)
  const isGamePublic = !!gameAndAnalysis?.game ? gameAndAnalysis?.game.isPublic() : true

  const tags = useMemo(() => {
    if (!gameAndAnalysis?.game.tags) return null

    // return first available tags if marketIsoForUserLanguage is not available
    if (gameAndAnalysisIsLoading || !gameAndAnalysis?.game.tags?.[marketIsoForUserLanguage])
      return gameAndAnalysis?.game.tags[Object.keys(gameAndAnalysis?.game.tags)[0] as string]

    // return tags for marketIsoForUserLanguage
    return gameAndAnalysis?.game.tags[marketIsoForUserLanguage]
  }, [gameAndAnalysis, marketIsoForUserLanguage, gameAndAnalysisIsLoading])

  const description = useMemo(() => {
    // return first available description if marketIsoForUserLanguage is not available
    if (gameAndAnalysisIsLoading || !gameAndAnalysis?.game.descriptions[marketIsoForUserLanguage])
      return gameAndAnalysis?.game.descriptions[Object.keys(gameAndAnalysis?.game.descriptions)[0] as string]

    // return description for marketIsoForUserLanguage
    return gameAndAnalysis?.game.descriptions[marketIsoForUserLanguage]
  }, [gameAndAnalysis, marketIsoForUserLanguage, gameAndAnalysisIsLoading])

  const link = useMemo(() => {
    return gameAndAnalysisIsLoading || !gameAndAnalysis?.game.storeLinks[marketIsoForUserLanguage]
      ? null
      : gameAndAnalysis.game.storeLinks[marketIsoForUserLanguage]
  }, [gameAndAnalysis, gameAndAnalysisIsLoading, marketIsoForUserLanguage])

  return (
    <Dialog open={open} maxWidth="md" fullWidth disableRestoreFocus {...dialogProps}>
      <DialogTitle sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography>
          {t('common:game_info')} {game?.resolvedName ? <span>- {game.resolvedName}</span> : null}
        </Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={(e) => onClose(e, 'escapeKeyDown')}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <div>
          <Box sx={{ mt: 2 }}>
            {(tags || gameAndAnalysisIsLoading) && <h3>{t('common:game_tags')}</h3>}
            {!gameAndAnalysis && gameAndAnalysisIsLoading && !error ? (
              <Grid container spacing={1}>
                {[0, 1, 2, 3, 4, 5].map((value) => (
                  <Grid key={value} item>
                    <Skeleton width={50} height={30} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              gameAndAnalysis &&
              !gameAndAnalysisIsLoading &&
              tags &&
              [...tags]
                .sort((tagIdA, tagIdB) => (languageService.getTranslation('tags', tagIdA) < languageService.getTranslation('tags', tagIdB) ? -1 : 1))
                .map((tagId) => {
                  return <Chip key={tagId} label={languageService.getTranslation('tags', tagId)}></Chip>
                })
            )}
          </Box>
          <Box sx={{ mt: 2 }}>
            <h3>
              {!isGamePublic && description && t('common:description')}
              {isGamePublic && description && t('common:store_description')}
            </h3>

            {!gameAndAnalysis && gameAndAnalysisIsLoading && !error ? (
              <Grid container spacing={2} direction="column">
                {[0, 1, 2].map((value) => (
                  <Grid key={value} item>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton width="50%" />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <pre>{description && <Typography>{description}</Typography>}</pre>
            )}
          </Box>
        </div>
      </DialogContent>
      {isGamePublic && !gameAndAnalysisIsLoading && link && (
        <DialogActions>
          <Button variant="contained" onClick={() => window.open(link)}>
            {t('common:open_in_app_store')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
