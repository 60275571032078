import React, { ReactNode } from 'react'

import './GameIcon.scss'

interface Props {
  count: number
  size?: 'huge' | 'large' | 'normal' | 'small' | 'tiny' | 'xtiny'
  prefix?: ReactNode
  disabled?: boolean
}

const GameIconWithCount: React.FC<Props> = ({ count, size, prefix, disabled }: Props) => {
  const iconSize = 'GameIcon--' + (size || 'normal')

  return (
    <div className={`GameIcon ${iconSize} GameIcon--Count`}>
      {prefix}
      {count}
    </div>
  )
}

export default GameIconWithCount
