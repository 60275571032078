import React from 'react'

import { Lock } from '@mui/icons-material'

import './LockedData.scss'

export const LockedData: React.FC = () => {
  return (
    <span className="LockedData">
      <Lock />
    </span>
  )
}
