import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Box, Button, Collapse } from '@mui/material'

import './ShowMore.scss'

const ShowMore: React.FC<{ collapsedSize?: number; children: React.ReactNode; stopPropagation?: boolean }> = ({
  collapsedSize,
  children,
  stopPropagation = false,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const [requiresCollapse, setRequiresCollapse] = useState<boolean>(false)
  const childRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  useEffect(() => {
    const shouldCollapse = !!collapsedSize && (childRef?.current?.clientHeight || 0) > collapsedSize
    setRequiresCollapse(shouldCollapse)
    // we want to recalculate whenever children are updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children])

  const handleClick: React.MouseEventHandler = (event) => {
    if (stopPropagation) {
      // We want to stop propagation of the click event to the parent element
      event.preventDefault()
      event.stopPropagation()
    }
    setExpanded(!expanded)
  }

  return requiresCollapse ? (
    <>
      <Collapse
        className="ShowMore__root"
        classes={{ entered: 'ShowMore__expanded', wrapperInner: 'ShowMore__inner' }}
        in={expanded}
        collapsedSize={collapsedSize}
      >
        <Box ref={childRef} mb={2}>
          {children}
        </Box>
      </Collapse>
      <Button variant="outlined" onClick={handleClick} endIcon={expanded ? <ExpandLess /> : <ExpandMore />}>
        {expanded ? t('common:show_less') : t('common:show_more')}
      </Button>
    </>
  ) : (
    <div ref={childRef}>{children}</div>
  )
}

export default ShowMore
