import { MarketShareQuarter } from '../../quarter/types/MarketShareQuarter'

export class MarketShare {
  id: string
  name: string
  quarters: { [key: string]: MarketShareQuarter } = {}

  constructor(id: string, name: string) {
    this.id = id
    this.name = name
  }
}
