import { FC, ReactNode } from 'react'

import { Box } from '@mui/material'

import { LockedFeature } from '../../../../../../components/LockedFeature/LockedFeature'
import { NewsCardSectionDivider } from '../../NewsCardSectionDivider/NewsCardSectionDivider'
import './NewsCardContentFeaturesSection.scss'

type NewsCardContentFeaturesSectionProps = {
  title: string
  locked?: boolean
  children?: ReactNode
}

export const NewsCardContentFeaturesSection: FC<NewsCardContentFeaturesSectionProps> = ({ title, locked, children }) => {
  return (
    <Box className="NewsCardContentFeaturesSection" mb={2}>
      <NewsCardSectionDivider>{title}</NewsCardSectionDivider>
      {locked ? <LockedFeature.Title /> : children}
    </Box>
  )
}
