import { useState } from 'react'
import { Trans } from 'react-i18next'

import { Box, Button, ButtonProps } from '@mui/material'

import BookmarksDialog from './BookmarksDialog'

const MyBookmarksButton: React.FC<ButtonProps> = (props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        {...props}
        onClick={() => {
          setOpen(true)
        }}
      >
        <Box paddingLeft={'32px'}>
          <Trans i18nKey={'bookmarks:my_bookmarks_title'}></Trans>
        </Box>
      </Button>
      <BookmarksDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default MyBookmarksButton
