import { UnifiedNewsEntry } from '../types/UnifiedNewsEntry'

const formatDateNumber = (value: number) => new Intl.DateTimeFormat('en-us', { dateStyle: 'medium' }).format(new Date(value))
const camelToSnakeCase = (camelcase: string) =>
  camelcase[0].replace(/[A-Z]/g, (letter) => `${letter.toLowerCase()}`) + camelcase.slice(1).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

const metricPrefix = (value: string) => {
  const val = parseInt(value)
  return val >= 1000000 ? val / 1000000 + 'M' : val >= 1000 ? val / 1000 + 'k' : val + ''
}
const metricPrefixCurrency = (value: string, currency: string) => currency + metricPrefix(value)

const stringBetween = (str: string, start: string, end: string) => {
  const startIndex = str.indexOf(start)
  const startIndex2 = startIndex >= 0 ? startIndex + start.length : str.length
  const endIndex = str.indexOf(end, startIndex2)
  const endIndex2 = endIndex >= 0 ? endIndex : str.length
  return str.substring(startIndex2, endIndex2)
}

const isEmpty = (text: string): boolean => {
  return text == null || text.match(/^\s*$/) !== null
}

const getLiveEventDialogConfig = (newsEntry: UnifiedNewsEntry) => {
  return {
    trackedGameId: newsEntry.feedEntry.gameId,
    eventTypeId: newsEntry.feedEntry.entryData.typeId,
    eventId: newsEntry.feedEntry.entryData.eventId,
    durationId: newsEntry.feedEntry.entryData.durations?.[0]?.id,
  }
}

export { formatDateNumber, camelToSnakeCase, metricPrefix, metricPrefixCurrency, stringBetween, isEmpty, getLiveEventDialogConfig }
