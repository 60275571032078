import { t } from 'i18next'
import { ReactNode, useEffect } from 'react'

import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { displaySnackBar } from '../features/snackbar'
import { useAppDispatch } from './storeHooks'
import { useError } from './useError'

/**
 * Hook for tracking RTK query/mutation result status and displaying a snackbar message according to the status
 */
type QueryStatusToastHookParams = {
  isSuccess?: boolean
  successMessage?: ReactNode
  isError?: boolean
  error?: FetchBaseQueryError | SerializedError
  errorMessage?: ReactNode
}

export const useQueryStatusSnackbarMessage = ({ isSuccess, successMessage, isError, error, errorMessage }: QueryStatusToastHookParams) => {
  const dispatch = useAppDispatch()
  useError({
    error,
    message: {
      message: errorMessage || t('common:general_error_message'),
      severity: 'error',
      open: true,
    },
  })
  useEffect(() => {
    if (isSuccess) {
      dispatch(displaySnackBar({ message: successMessage || t('common:success_message'), severity: 'success', open: true }))
    }
  }, [dispatch, isSuccess, successMessage])
}
