import { FC, ReactNode } from 'react'

import { Box } from '@mui/system'

type MagnifyProps = {
  children?: ReactNode
  scale?: number
  force?: boolean
}

/**
 * Magnifies child component with given scale factor in hover, focus and active states. Scale factor of 1
 * corresponds to no magnification.
 */
export const Magnify: FC<MagnifyProps> = ({ scale = 1.1, force = false, children }) => {
  return (
    <Box
      sx={{
        '& > *': { transition: `all 300ms ease-in-out ${force ? '!important' : ''}` },
        '& > :hover, & > :focus, & > :active': {
          transform: `scale(${scale})`,
        },
      }}
    >
      {children}
    </Box>
  )
}
