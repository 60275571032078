import keycloakService from './KeycloakService'

const getDefaultFetchHeaders = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + keycloakService.token,
  }
}

export const fetchWithTokenUpdate = (url: string, settings: any) => {
  if (!settings.headers) {
    settings.headers = getDefaultFetchHeaders()
  }

  if (keycloakService.isTokenExpired()) {
    return keycloakService.updateToken().then(() => {
      settings.headers.Authorization = 'Bearer ' + keycloakService.token
      return fetch(url, settings)
    })
  } else {
    return fetch(url, settings)
  }
}
