import React from 'react'
import { useTranslation } from 'react-i18next'

import { ChatBubble } from '@mui/icons-material'
import { Button } from '@mui/material'

import { useInternalAccessCheck } from '../../../../account/hooks/roleHooks'
import { TrackedGame } from '../../../types/TrackedGame'

interface Props {
  trackedGame: TrackedGame
  onClick: (trackedGame: TrackedGame) => void
}

const LiveEventsOverviewButton: React.FC<Props> = ({ trackedGame, onClick }) => {
  const internalUser = useInternalAccessCheck()
  const { t } = useTranslation()

  return (
    <Button
      disabled={trackedGame.review && (trackedGame.review.published || internalUser) ? false : true}
      variant="contained"
      size="small"
      color="primary"
      fullWidth
      startIcon={<ChatBubble />}
      onClick={() => onClick(trackedGame)}
    >
      {t('live-events:analyst_overview_button')}
    </Button>
  )
}

export default LiveEventsOverviewButton
