import { FC, ReactNode } from 'react'

export type RankIndicatorProps = {
  value: number
  maxValue?: number
  emptyValue?: ReactNode
  children?: ReactNode
  hasEmptyValue?: boolean
}

const defaultEmptyValue = ''

export const RankIndicator: FC<RankIndicatorProps> = ({ value, maxValue, emptyValue = defaultEmptyValue, hasEmptyValue = false }) => {
  let displayValue

  if (isNaN(value) || !isFinite(value) || (value === 0 && !hasEmptyValue)) {
    displayValue = emptyValue
  } else {
    const roundedValue = Math.round(value)
    displayValue = !isNaN(maxValue as number) ? (value > (maxValue as number) ? `${maxValue}+` : roundedValue) : roundedValue
  }

  return <span>{displayValue}</span>
}
