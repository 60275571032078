import { FC, ReactNode } from 'react'
import { Trans } from 'react-i18next'

import { AppType } from '../../../../../features/game/types/Game'
import { displaySnackBar } from '../../../../../features/snackbar'
import { useAppDispatch } from '../../../../../hooks/storeHooks'
import { useGetLiveEventDescriptionMutation } from '../../../../api/openai-chat'
import { InputTranslatorAIAssistant } from '../../../../components/InputTranslator/InputTranslatorAIAssistant/InputTranslatorAIAssistant'

type LiveEventDescriptionAIAssistantProps = {
  queryParams: {
    appName?: string
    appType?: AppType
    eventName?: string
    screenshotUrls?: string[]
  }
  onCopy: (value: string) => void
  children?: ReactNode
}

export const LiveEventDescriptionAIAssistant: FC<LiveEventDescriptionAIAssistantProps> = ({ queryParams, onCopy }) => {
  const [getGeneratedLiveEventDescription, getLiveEventDescriptionMutation] = useGetLiveEventDescriptionMutation()
  const validate = useValidateLiveEventDescriptionAIAssistantQueryParams(queryParams)

  const handleGenerate = () => {
    const hasValidQueryParams = validate()
    if (hasValidQueryParams) {
      getGeneratedLiveEventDescription({
        appName: queryParams.appName!,
        appType: queryParams.appType!,
        eventName: queryParams.eventName!,
        screenshotUrls: queryParams.screenshotUrls!,
      })
    }
  }

  return (
    <InputTranslatorAIAssistant
      value={getLiveEventDescriptionMutation.data?.eventDescription || ''}
      onGenerate={handleGenerate}
      onCopy={onCopy}
      isLoading={getLiveEventDescriptionMutation.isLoading}
    />
  )
}

const useValidateLiveEventDescriptionAIAssistantQueryParams = (queryParams: LiveEventDescriptionAIAssistantProps['queryParams']) => {
  const dispatch = useAppDispatch()
  return () => {
    if (!queryParams.appName) {
      dispatch(
        displaySnackBar({ message: <Trans i18nKey="internal-live-events:live_event_ai_analyze_app_name_validation_error" />, severity: 'error', open: true })
      )
      return false
    }

    if (!queryParams.appType) {
      dispatch(
        displaySnackBar({ message: <Trans i18nKey="internal-live-events:live_event_ai_analyze_app_type_validation_error" />, severity: 'error', open: true })
      )
      return false
    }

    if (!queryParams.eventName) {
      dispatch(
        displaySnackBar({ message: <Trans i18nKey="internal-live-events:live_event_ai_analyze_event_name_validation_error" />, severity: 'error', open: true })
      )
      return false
    }
    if (!queryParams.screenshotUrls || queryParams.screenshotUrls.length === 0) {
      dispatch(
        displaySnackBar({ message: <Trans i18nKey="internal-live-events:live_event_ai_analyze_screenshots_validation_error" />, severity: 'error', open: true })
      )
      return false
    }

    return true
  }
}
