import { CompareGameFeature, ConceptTag, Keyword } from '../../../api/core'
import { FeatureChoice } from '../../game-features/types/types'
import { EnrichedFeature } from '../../implementation-examples'

export type FeatureAndKeywordSearchResult = FeatureChoice | EnrichedFeature | Keyword | CompareGameFeature | ConceptTag | string | null

export enum InactiveFeatureChoice {
  No = 'no',
  None = 'none',
  NotAvailable = 'n/a',
  NotAvailableAlt = 'na',
}

export type ExampleExplanation = {
  desc: string // "<p>Merge Plane offers a subscription that along other perks also removes all the ads in the game.</p>"
  descriptions: { [lang: string]: string }
  imageCopyright: string | null
  imageId: string // "5e9829f23ef43b00be56e21e"
  imageUrl: string // "https://saasstatic.gamerefinery.com/examples/4-5e5e4924f4e81700bd553c1d-5e9829f23ef43b00be56e21e.jpg"
  itemId: number // 0
  title: string // "Merge Plane"
  titles: { [lang: string]: string } // {ja: "Merge Plane", en: "Merge Plane", zh: "Merge Plane"}
  type: 'correct' | 'wrong'
}

export type FeatureExample = {
  corrects: ExampleExplanation[]
  desc: string // "<p>Means the number of different ad-placements in the game.</p><p>For example, a match3 game has:</p><p>A) an incentivized ad upon level entrance (e.g. to get a temporary boost),</p><p>B) an incentivized ad appearing if you fail a level and need extra moves,</p><p>C) an incentivized ad placed in the home screen that gives free currency,</p><p>D) a banner ad that is visible in the home screen.</p><p>The total ad-placement count for this game is 4.</p><p><br/></p>"
  descriptions: { [lang: string]: string }
  featureId: string // "5e1c7e2cab1f7f00bee08f6c"
  featureLegacyId: number // 0
  id: string // "5e1db173ab1f7f00bee08fd3"
  market: string // "gb"
  name: string | null
  store: string // "565c92d39b75634154f213bc"
  wrongs: ExampleExplanation[]
}
