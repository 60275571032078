import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Warning } from '@mui/icons-material'
import BlockIcon from '@mui/icons-material/Block'
import { Button, Card, CardContent, CardHeader, Divider, Typography } from '@mui/material'

import MotivationPieLocked from '../../../../assets/images/locked-motivation-pie.png'
import { ContactButton } from '../../../../components/ContactButton/ContactButton'
import { GRBanner } from '../../../../components/GRBanner/GRBanner'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import languageService from '../../../../services/LanguageService'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { MotivationKey, MotivationType } from '../../../market-explorer/types/MotivationType'
import { ArchetypeDescriptionsModal } from '../../../motivations/components/ArchetypeDescriptionsModal/ArchetypeDescriptionsModal'
import MotivationArcheTypePieChart from '../../../motivations/components/MotivationArcheTypePieChart/MotivationArcheTypePieChart'
import { useGetMotivationProfile } from '../../../motivations/hooks/useGetMotivationProfile'
import { useMarketMotivationsValidation } from '../../../motivations/hooks/useMarketMotivationsValidation'
import { useIsGameOpenForEveryone } from '../../../settings'
import { Game } from '../../types/Game'
import './GameMotivationPieChart.scss'

/**
 * GameMotivationPieChart Wrapper for MotivationPieChartComponent
 */
interface GameMotivationPieChartProps {
  game: Game
  gameAnalyzed: boolean
}
const GameMotivationPieChart: React.FC<GameMotivationPieChartProps> = ({ game, gameAnalyzed }) => {
  const { t } = useTranslation()
  const isMotivationsAvailableForCurrentMarket = useMarketMotivationsValidation()
  const isGameOpenForEveryone = useIsGameOpenForEveryone(game)
  const motivationsUnlocked = useRoleCheck(RoleEnum.motivations) || isGameOpenForEveryone
  const { data: motivationData, isLoading: isMotivationDataLoading } = useGetMotivationProfile({ game })
  const [modalMotivationKey, setModalMotivationKey] = useState<MotivationKey>()
  const labels = useMemo(() => {
    return Object.values(MotivationType).map((motivationKey) => `${languageService.getTranslation('motivations', motivationKey)}`)
  }, [])
  const gameData = useMemo(() => {
    if (!motivationData) return []
    const data = Object.values(MotivationType).map((motivationOrder) => {
      if (!motivationData.motivationValues) {
        return 0
      }
      return Math.round(motivationData.motivationValues[motivationOrder] * 10) / 10
    })
    return [
      {
        label: game.resolvedName,
        data,
      },
    ]
  }, [game.resolvedName, motivationData])

  const sortedMotivationData = useMemo(() => {
    if (!motivationData || !motivationData.motivationSegmentValues) return []

    return Object.keys(motivationData.motivationSegmentValues)
      .map((key) => {
        const motivationKey = key as MotivationKey
        const data = motivationData.motivationSegmentValues ? motivationData.motivationSegmentValues[motivationKey] : 0
        return {
          key: motivationKey,
          label: languageService.getTranslation('motivations', motivationKey),
          data,
        }
      })
      .sort((a, b) => b.data - a.data)
  }, [motivationData])

  if (isMotivationDataLoading) {
    return <GRCircularProgress />
  }

  return isMotivationsAvailableForCurrentMarket ? (
    motivationsUnlocked ? (
      <Card className="GameMotivationPieChart__container">
        <CardContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <h3>{t('motivations:player_archetypes')}</h3>
          {!!sortedMotivationData.length ? (
            <>
              <MotivationArcheTypePieChart
                data={sortedMotivationData}
                onArcheTypeClicked={(motivationIndex) => {
                  setModalMotivationKey(sortedMotivationData[motivationIndex].key)
                }}
              />
              {modalMotivationKey && (
                <ArchetypeDescriptionsModal
                  datasets={gameAnalyzed ? gameData : []}
                  labels={labels}
                  onSetMotivationKey={setModalMotivationKey}
                  motivationKey={modalMotivationKey}
                />
              )}
              <Button
                variant={'contained'}
                sx={{ mt: 2 }}
                onClick={() => {
                  setModalMotivationKey(sortedMotivationData[0].key)
                }}
              >
                {t('motivations:archetype_descriptions')}
              </Button>
            </>
          ) : (
            <GRBanner severity="guidance" icon={<Warning />}>
              {t('motivations:motivation_data_na')}
            </GRBanner>
          )}
        </CardContent>
      </Card>
    ) : (
      <Card className="GameMotivationPieChart__container">
        <CardHeader title={<LockedFeature.Title />} />
        <Divider />
        <CardContent sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <h3>{t('motivations:player_archetypes')}</h3>
          <img src={MotivationPieLocked} alt="" className="motivationPieLockedImg" />
          <Typography variant="body1" mt={2} mb={2}>
            <Trans i18nKey="locked-feature:cta_text" />
          </Typography>
          <ContactButton></ContactButton>
        </CardContent>
      </Card>
    )
  ) : (
    <Card className="GameMotivationPieChart__container" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CardHeader
        title={
          <Typography fontSize="1.17rem" fontWeight="bold">
            {t('motivations:player_archetypes')}
          </Typography>
        }
      />
      <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', flex: 1 }}>
        <BlockIcon sx={{ fontSize: 150 }} color="primary" />
        <Typography fontSize="1rem">{t('motivations:motivation_na_for_market')}</Typography>
      </CardContent>
    </Card>
  )
}

export default GameMotivationPieChart
