import { FC } from 'react'

import { ButtonProps, Button } from '@mui/material'

type ActionButtonProps = {} & ButtonProps

export const ActionButton: FC<ActionButtonProps> = ({ ...buttonProps }) => {
  return (
    <Button color="primary" variant="contained" sx={{ paddingLeft: '6px', paddingRight: '6px', minWidth: '0' }} disableElevation {...buttonProps}>
      {buttonProps.children}
    </Button>
  )
}
