import React, { useMemo } from 'react'

import { Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import languageService from '../../../../services/LanguageService'
import { MotivationKey } from '../../../market-explorer/types/MotivationType'
import { motivationArchetypeImages } from '../../utils/MotivationArchetypeImages'
import './MotivationArchetypeCardBadge.scss'

interface Props {
  motivationKey: MotivationKey
  selected?: boolean
  alignLeft?: boolean
  onSelect?: (motivationKey: MotivationKey) => void
}

export const MotivationArchetypeCardBadge: React.FC<Props> = ({ motivationKey, selected, alignLeft, onSelect }) => {
  const archetypeName = languageService.getTranslation('motivations', motivationKey)
  const archetypeDescription = languageService.getTranslation('motivations', `${motivationKey}_description`)

  const rootClassNames = useMemo(() => {
    let classNames = 'MotivationArchetypeCardBadge'
    if (alignLeft) {
      classNames = `${classNames} MotivationArchetypeCardBadge--AlignLeft`
    }

    if (onSelect) {
      classNames = `${classNames} MotivationArchetypeCardBadge--Selectable`
    }

    if (selected) {
      classNames = `${classNames} MotivationArchetypeCardBadge--Selected`
    }

    return classNames
  }, [alignLeft, selected, onSelect])

  return (
    <GRTooltip
      content={
        <>
          <Typography variant="h3">{archetypeName}</Typography>
          <p style={{ marginBottom: '0px' }}>{archetypeDescription}</p>
        </>
      }
      styles={{ display: 'inline-block' }}
    >
      <div
        className={rootClassNames}
        onClick={() => {
          onSelect && onSelect(motivationKey)
        }}
      >
        <img className="MotivationArchetypeCardBadge__Image" src={motivationArchetypeImages[motivationKey]} alt={motivationKey} />
        <div className="MotivationArchetypeCardBadge__Name">{archetypeName}</div>
      </div>
    </GRTooltip>
  )
}
