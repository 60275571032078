import querystring from 'query-string'
import { FC, ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { stringBetween } from '../../features/news/utils/utils'

type ScreenshotModalLinkProps = {
  children?: ReactNode
  originalLink: string
  className?: string
}

export const ScreenshotModalLink: FC<ScreenshotModalLinkProps> = ({ originalLink, className, children }) => {
  const location = useLocation()
  // reverse the order of the screenshot and create a new link
  const target = `${location.pathname}/screenshots/${stringBetween(originalLink, 'shotModal=', '&').split(',').reverse().join(',')}`

  const newLocation = {
    ...location,
    pathname: target,
    search: `shotModalIso=${querystring.parse(originalLink).shotModalIso}`,
  }

  return (
    <Link className={className} to={newLocation}>
      {children}
    </Link>
  )
}
