export enum PlatformType {
  PhoneEstimates = 'phoneEstimates',
  TabletEstimates = 'tabletEstimates',
}

export type EstimatesList = {
  [key in PlatformType]?: AppEstimates
}

export interface FilteredPlatformEstimates {
  [appId: string]: MarketEstimates[]
}

export interface AppEstimates {
  [appId: string]: MarketEstimates
}

export interface MarketEstimates {
  [marketIso: string]: DailyEstimates
}

export interface DailyEstimates {
  [date: string]: Estimates
}

export interface Estimates {
  r?: number // revenue
  d?: number // downloads
}
