import { t } from 'i18next'
import { useMemo } from 'react'

import { CompareGameFeature, CompareMultiGames, useGetCategoriesMapQuery, useGetScreenshotCountsByGamesQuery } from '../../../api/core'
import languageService from '../../../services/LanguageService'
import { Game } from '../../game/types/Game'
import { useCurrentMarket } from '../../markets'

export type CompareFeatureRowGameColumn = {
  [gameId: string]: { gpsEffect: number; choice?: string; translatedChoice?: string }
}

export type CompareFeatureRow = {
  categoryId: string
  icon?: {
    url: string
    mimeType: string
  }
  featureName: string
  categoryName: string
  featureId: number
  uniqueId?: string
  gameColumns: CompareFeatureRowGameColumn
  showImplementationFeature?: boolean
  implementationWithGameIds?: string[]
  isDifferentChoice?: boolean
  gameChoiceList?: string[]
}

const constructGameColumns = (feature: CompareGameFeature, games: Game[]): CompareFeatureRowGameColumn => {
  return games.reduce<CompareFeatureRowGameColumn>((result, game) => {
    const gameIndex = games.map((game) => game.id).indexOf(game.id)
    const translatedChoice = feature.choiceIds.map((choice) => languageService.getTranslation('choices', choice.toString()))

    result[game.id] = {
      choice: feature.choices[gameIndex] || '',
      translatedChoice: translatedChoice[gameIndex] || '',
      gpsEffect: feature.effects[gameIndex] || 0,
    }
    return result
  }, {})
}

const useCompareFeatureScreenshots = (games: Game[]) => {
  const gameIds = useMemo(() => games.map((game) => game.id), [games])
  const { data: screenshotsCounts, isLoading: isScreenshotCountLoading } = useGetScreenshotCountsByGamesQuery({ gameIds: gameIds || [] }, { skip: !gameIds })

  const featuresHasScreenshots = useMemo(() => {
    if (!screenshotsCounts) return []

    const legacyHasScreenshots = Object.values(screenshotsCounts).reduce<number[]>((result, screenshotList) => {
      Object.keys(screenshotList).forEach((legacyId) => {
        result.push(Number(legacyId))
      })
      return result
    }, [])
    return [...new Set(legacyHasScreenshots)]
  }, [screenshotsCounts])

  const featuresHasScreenshotsWithGameIds = useMemo(() => {
    if (!screenshotsCounts) {
      return {}
    }
    return featuresHasScreenshots.reduce<{ [key: number]: string[] }>((result, featureLegacyId) => {
      Object.entries(screenshotsCounts).forEach(([gameId, screenshotList]) => {
        if (!!screenshotList[featureLegacyId] && screenshotList[featureLegacyId] > 0) {
          if (!result[featureLegacyId]) {
            result[featureLegacyId] = [gameId]
          } else {
            result[featureLegacyId].push(gameId)
          }
        }
      })
      return result
    }, {} as { [key: number]: string[] })
  }, [featuresHasScreenshots, screenshotsCounts])
  return { featuresHasScreenshotsWithGameIds, featuresHasScreenshots, isScreenshotCountLoading }
}

export const useCompareFeatureRows = (multiGamesCompare?: CompareMultiGames): [CompareFeatureRow[], boolean] => {
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { data: categoriesMaps, isLoading: isCategoriesMapsLoading } = useGetCategoriesMapQuery({ marketIso: mainMarketIso })
  const { featuresHasScreenshotsWithGameIds, featuresHasScreenshots, isScreenshotCountLoading } = useCompareFeatureScreenshots(multiGamesCompare?.games || [])
  const requiredData = !!multiGamesCompare
  const rows = useMemo<CompareFeatureRow[]>(() => {
    if (!requiredData) return []

    return [...multiGamesCompare.features].map((feature): CompareFeatureRow => {
      const gameColumns = constructGameColumns(feature, multiGamesCompare.games)
      const gameChoiceList = Object.values(gameColumns)
        .map((game) => game.choice)
        .filter((choice) => !!choice) as string[]

      return {
        categoryId: feature.categoryId,
        featureId: feature.featureId,
        uniqueId: `${feature.categoryId}-${feature.featureId}`,
        icon: categoriesMaps && categoriesMaps[feature.categoryId].icon,
        featureName: feature.featureName,
        categoryName: (categoriesMaps && categoriesMaps[feature.categoryId].name) || t('common:not_available_shorthand'),
        gameColumns,
        showImplementationFeature: featuresHasScreenshots.includes(feature.featureId),
        implementationWithGameIds: featuresHasScreenshotsWithGameIds[feature.featureId],
        isDifferentChoice: [...new Set(gameChoiceList)].length > 1,
        gameChoiceList,
      }
    })
  }, [categoriesMaps, featuresHasScreenshots, featuresHasScreenshotsWithGameIds, multiGamesCompare, requiredData])

  if (!requiredData) {
    return [[], false]
  }

  const isLoading = isCategoriesMapsLoading || isScreenshotCountLoading

  return [rows, isLoading]
}
