import { useCallback } from 'react'

import { useRouteModal } from '../../route-modals/hooks/useRouteModal'
import { EModalType } from '../../route-modals/types/modalTypes'

export const useLiveEventsAnalystNotesModal = () => {
  const { pushModal } = useRouteModal()
  const showModal = useCallback(
    ({ trackedGameId, commentId }: { trackedGameId: string; commentId: string }) => {
      pushModal({
        type: EModalType.LIVE_EVENT_ANALYST_NOTES,
        params: {
          trackedGameId,
          commentId,
        },
      })
    },
    [pushModal]
  )

  return {
    showModal,
  }
}
