import { t } from 'i18next'
import React, { ReactNode } from 'react'
import { Trans } from 'react-i18next'

import { Box, Divider, Grid, Typography } from '@mui/material'

import { DemographyGroup } from '../../../api/core'
import { GREffectDots, GREffectDotsProps } from '../../../components/GREffectDots/GREffectDots'
import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { LinearProgressBar } from '../../../components/LinearProgressBar/LinearProgressBar'
import { LockedDataIndicator } from '../../../components/LockedDataIndicator/LockedDataIndicator'
import NotAvailableIcon from '../../../components/NotAvailableIcon/NotAvailableIcon'
import languageService from '../../../services/LanguageService'
import utilsService from '../../../services/UtilsService'
import {
  DemographicsTableBars,
  demographicsCellProps,
  demographicsHeaderCellProps,
} from '../../demographics/components/DemographicsTableBars/DemographicsTableBars'
import { DemographicsColor } from '../../demographics/types/DemographicsColor'
import featureService from '../../feature/services/FeatureService'
import { motivationParentTranslationKey } from '../../motivations/components/MotivationArcheTypeCard/MotivationArcheTypeCard'
import { formatPercentage, formatPercentageDiff } from '../../visuals/util/helpers'
import type { Column } from '../components/MarketExplorerFeaturesData/MarketExplorerFeaturesData'
import { FeatureDataRow } from '../hooks/featureDataHooks'
import { AverageMotivationSegments, Popularity } from '../types/MarketExplorerSegmentData'

type DemographicAccessor = keyof DemographyGroup
type TableColumn = (
  labelAccessor: any,
  accessor: keyof Popularity,
  onClick?: (row: FeatureDataRow, accessor: keyof Popularity, value: number) => void
) => Column

export const columnWidth = (width: number, textAlign?: string) => ({ sx: { width, minWidth: width, textAlign: textAlign || 'center' } })

// We have to use a custom sorter since the compareValue used to sort rows requires it
const defaultSorter = (a: any, b: any) => {
  if (a < b) {
    return -1
  }

  if (a > b) {
    return 1
  }

  return 0
}

export const columnDefaults = {
  sortable: true,
  sortAccessor: 'compareValue',
  sortFn: defaultSorter,
}

export const columnDefaultsW = (width: number) => ({ ...columnDefaults, headerCellProps: columnWidth(width) })

export const percentageColumn: TableColumn = (labelAccessor, accessor, onClick) => ({
  accessor: ({ row }) =>
    featureService.fullFeaturePopularityDataAvailableForMarketIsoAndCategoryId(row.marketIso, row.categoryId) ? (
      <LinearProgressBar
        value={row[accessor]}
        labelAfter={formatPercentage(row[accessor] / 100)}
        onClick={() => (onClick ? onClick(row, accessor, row[accessor]) : {})}
      />
    ) : (
      <NotAvailableIcon />
    ),
  labelAccessor,
  realSortAccessor: accessor,
  ...columnDefaultsW(140),
})

export const dotColumn = (labelAccessor: any, accessor: 'avgEffect'): Column => ({
  accessor: ({ row }) =>
    featureService.fullFeaturePopularityDataAvailableForMarketIsoAndCategoryId(row.marketIso, row.categoryId) ? (
      <GREffectDots size="small" effect={row[accessor]} />
    ) : (
      <NotAvailableIcon />
    ),
  labelAccessor,
  realSortAccessor: accessor,
  ...columnDefaultsW(110),
})

const calculatePercentageDiff = (overallPopularity?: number, compareValue?: number) => {
  if (!overallPopularity) return '-'
  return formatPercentageDiff(overallPopularity, compareValue)
}

export const differenceColumn: TableColumn = (labelAccessor, accessor) => ({
  accessor: ({ row }) =>
    featureService.fullFeaturePopularityDataAvailableForMarketIsoAndCategoryId(row.marketIso, row.categoryId) ? (
      <Typography className={row['Overall'] < row[accessor] ? 'success' : 'warning'}>
        {calculatePercentageDiff(row['Overall'] / 100, row[accessor] / 100)}
      </Typography>
    ) : (
      <NotAvailableIcon />
    ),
  labelAccessor,
  realSortAccessor: 'difference' + accessor,
  ...columnDefaultsW(60),
})

export const motivationColumn = (motivationType: string): Column => {
  return {
    labelAccessor: <GRMotivationTooltip motivationType={motivationType} />,
    accessor: ({ row, customTableProps }) =>
      customTableProps.motivationsEnabled ? row.motivation ? <GRMotivationDots effect={row.motivation[motivationType]} /> : '-' : <LockedDataIndicator />,
    realSortAccessor: motivationType,
    ...columnDefaultsW(115),
  }
}

export const archetypeColumn = (labelAccessor: string, accessor?: string): Column => {
  const description = languageService.getTranslation(motivationParentTranslationKey, accessor + '_description')
  return {
    labelAccessor: <GRTooltip content={description}>{labelAccessor}</GRTooltip>,
    accessor: ({ row, customTableProps }) =>
      customTableProps.motivationsEnabled ? formatPercentage(row.archetypes?.[accessor as keyof AverageMotivationSegments] || 0) : <LockedDataIndicator />,
    realSortAccessor: accessor,
    ...columnDefaultsW(90),
  }
}

export const demographicColumnFirst = (labelAccessor: any, accessors: DemographicAccessor[], colors: DemographicsColor[]): Column => ({
  accessor: ({ row }) =>
    utilsService.demographicsIsAvailableForMarket(row.marketIso) ? (
      <>
        <strong>{formatPercentage(row[accessors[0]])}</strong>
        <DemographicsTableBars widths={accessors.map((accessor) => row[accessor])} colors={colors} />
      </>
    ) : (
      <NotAvailableIcon />
    ),
  labelAccessor,
  cellProps: demographicsCellProps,
  headerCellProps: { ...demographicsHeaderCellProps, sx: { ...demographicsHeaderCellProps.sx, px: 0 } },
  ...columnDefaults,
})

export const demographicColumnNext = (labelAccessor: any, accessor: DemographicAccessor): Column => ({
  accessor: ({ row }) => (utilsService.demographicsIsAvailableForMarket(row.marketIso) ? formatPercentage(row[accessor]) : <NotAvailableIcon />),
  labelAccessor,
  realSortAccessor: accessor,
  cellProps: demographicsCellProps,
  headerCellProps: { ...demographicsHeaderCellProps, sx: { ...demographicsHeaderCellProps.sx, px: 0 } },
  ...columnDefaults,
})

export const demographicColumns = (labelAccessors: ReactNode[], accessors: DemographicAccessor[], colors: DemographicsColor[]) =>
  labelAccessors.map((labelAccessor, index) =>
    index ? demographicColumnNext(labelAccessor, accessors[index]) : demographicColumnFirst(labelAccessor, accessors, colors)
  )

const GRMotivationDots: React.FC<GREffectDotsProps> = ({ effect }) => {
  const value = Math.min(Math.round(effect * 10) / 10, 5)
  const count = Math.round(effect)
  return (
    <GRTooltip content={<Trans i18nKey="motivations:motivation-avg-importance-feature" values={{ value }} />}>
      <span className={`GREffectDots GREffectDots__small`}>
        {[...Array(count)].map((e, i) => (
          <span key={i} className={`impact_${count}`} />
        ))}
        {[...Array(5 - count)].map((e, i) => (
          <span key={i} />
        ))}
        <Typography display="inline" className="value">
          {value}
        </Typography>
      </span>
    </GRTooltip>
  )
}

const GRMotivationTooltip: React.FC<{ motivationType: string }> = ({ motivationType }) => {
  const motivationTypeLabelParts = languageService.getTranslation('motivations', motivationType).match(/.*(- |–|:|：)(.*)/)
  const label = motivationTypeLabelParts?.length === 3 ? motivationTypeLabelParts[2].trim() : motivationTypeLabelParts?.input

  return (
    <GRTooltip
      content={
        <Box>
          {t('motivations:motivation-me-tooltip-description')}
          <Divider sx={{ mt: 1.5, mb: 1 }} />
          <Grid container>
            <Grid item sm={6}>
              <Typography>{t('motivations:motivation-me-tooltip-not-imporant')}</Typography>
              <GREffectDots size="small" effect={0.1} />
            </Grid>
            <Grid item sm={6}>
              <Typography>{t('motivations:motivation-me-tooltip-very-imporant')}</Typography>
              <GREffectDots size="small" effect={2.5} />
            </Grid>
          </Grid>
          <Box className="MarketExplorerFeaturesData__motivationTitle">{languageService.getTranslation('motivations', motivationType)}</Box>
          <Box>{languageService.getTranslation(motivationParentTranslationKey, motivationType + '_description')}</Box>
        </Box>
      }
    >
      {label}
    </GRTooltip>
  )
}
