import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Grid } from '@mui/material'

import GameIcon from '../../../game/components/GameIcon/GameIcon'
import { Game } from '../../../game/types/Game'
import './GameSearchResult.scss'

interface Props {
  game: Game
  onClick?: () => void
}

const GameSearchResult: React.FC<Props> = ({ game, onClick }) => {
  const selectedIcon = useMemo(() => {
    if (game.icon) return game.icon
    if (game.icons && !!game.icons.length) {
      return game.icons[1]
    }

    return ''
  }, [game.icon, game.icons])

  return (
    <div className="GameSearchResult" onClick={onClick}>
      <Link to={`/game/${game.id}`}>
        <div className="GameSearchResult__content">
          <Grid container spacing={1.2}>
            <Grid item>
              <GameIcon src={selectedIcon} gameName={game.resolvedName} size="small" />
            </Grid>
            <Grid item xs>
              <div className="GameSearchResult__name">{game.resolvedName}</div>
              <div className="GameSearchResult__publisher">{game.artist}</div>
            </Grid>
          </Grid>
        </div>
      </Link>
    </div>
  )
}

export default GameSearchResult
