import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Warning } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Card, CardContent, Typography } from '@mui/material'

import { useGetMotivationStatsQuery } from '../../../../api/connect'
import { GRBanner } from '../../../../components/GRBanner/GRBanner'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import GRDotRating from '../../../../components/GRDotRating/GRDotRating'
import { GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import analyticsService from '../../../../services/AnalyticsService'
import languageService from '../../../../services/LanguageService'
import motivationService from '../../../../services/MotivationService'
import { AnalyticsProviders } from '../../../../types/IAnalyticsProvider'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useMotivationsAccessCheck } from '../../../account/hooks/roleHooks'
import { AttributeStatDetail, MotivationStatsWithGameScore } from '../../../market-explorer/types/MarketExplorerSegmentData'
import { MotivationKey, MotivationType } from '../../../market-explorer/types/MotivationType'
import { useCurrentMarket } from '../../../markets'
import { ArchetypeDescriptionsModal } from '../../../motivations/components/ArchetypeDescriptionsModal/ArchetypeDescriptionsModal'
import MotivationArcheTypeCard, { MotivationArcheTypeList } from '../../../motivations/components/MotivationArcheTypeCard/MotivationArcheTypeCard'
import MotivationDriver from '../../../motivations/components/MotivationDriver/MotivationDriver'
import { InputDataRow, MotivationTableRow } from '../../../motivations/components/MotivationTable/MotivationTable'
import { useGetMotivationProfile, useGetMotivationProfileVersion, useGetMotivationProfileVersions } from '../../../motivations/hooks/useGetMotivationProfile'
import useGetSegmentMotivationsType from '../../../motivations/hooks/useGetSegmentMotivationsType'
import { useMarketMotivationsValidation } from '../../../motivations/hooks/useMarketMotivationsValidation'
import { useIsGameOpenForEveryone } from '../../../settings'
import { useGetGameList } from '../../hooks/gameHooks'
import { GameAndAnalysis } from '../../types/GameAndAnalysis'
import GameIcon from '../GameIcon/GameIcon'

const include = [
  'name',
  'icon',
  'icons',
  'rank',
  'internal',
  'appId',
  'gpsPerMarket',
  'genreId',
  'conventionalSubgenre',
  'conventionalSubgenreId',
  'conventionalGenre',
  'version',
].join(',')

type valueOptionType = keyof AttributeStatDetail
type valueOptionsType = {
  value: valueOptionType
  label: string
}
/**
 * GameMotivation Game motivation
 */
interface GameMotivationProps {
  gameAndAnalysis: GameAndAnalysis
}
const GameMotivation: React.FC<GameMotivationProps> = ({ gameAndAnalysis }) => {
  const { t } = useTranslation()

  const { game } = gameAndAnalysis
  const isGameOpenForEveryone = useIsGameOpenForEveryone(gameAndAnalysis?.game)
  const hasMotivationsAccess = useMotivationsAccessCheck() || isGameOpenForEveryone
  const isMotivationsAvailableForCurrentMarket = useMarketMotivationsValidation(gameAndAnalysis?.analysis?.marketIso)

  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Motivations', {
      data: {
        gameId: game.id,
        gameName: game.resolvedName,
      },
    })
  }, [game.id, game.resolvedName])

  const { data: gameMotivationStat, isLoading: gameMotivationStatLoading } = useGetMotivationStatsQuery({
    appId: game.appId,
    conventionalSubgenreId: game.conventionalSubgenreId,
  })

  const valueOptions = useMemo<valueOptionsType[]>(
    () => [
      { value: 'avg', label: t('common:average_label') },
      { value: 'median', label: t('common:median_label') },
    ],
    [t]
  )

  const [valueOption, setValueOption] = useState<valueOptionType>(valueOptions[0].value)
  const selectedValueOption = valueOptions.find((option) => option.value === valueOption)

  const { data: motivationsData } = useGetMotivationProfile({ game })
  const { data: gameMotivationProfileVersions } = useGetMotivationProfileVersions({ game })
  const [selectedHistoryVersion, setSelectedHistoryVersion] = useState<string>('')
  const { data: motivationsDataVersionCompare } = useGetMotivationProfileVersion({ game, version: selectedHistoryVersion })

  const modifiedGameMotivationStat = useMemo(() => {
    const modifiedMotivationStat = {} as MotivationStatsWithGameScore
    if (!gameMotivationStat) {
      return modifiedMotivationStat
    }

    if (!motivationsData) {
      return modifiedMotivationStat
    }

    for (const key in motivationsData.motivationValues) {
      const motivationTypeKey = key as MotivationType

      let data = { game: motivationsData?.motivationValues[motivationTypeKey], subgenreOverall: gameMotivationStat[motivationTypeKey]?.subgenreOverall }

      if (motivationsDataVersionCompare && motivationsDataVersionCompare?.motivationValues?.[motivationTypeKey]) {
        data = {
          ...data,
          ...{
            compareGameVersion: motivationsDataVersionCompare?.motivationValues[motivationTypeKey],
          },
        }
      }

      modifiedMotivationStat[motivationTypeKey] = data
    }

    return modifiedMotivationStat
  }, [gameMotivationStat, motivationsData, motivationsDataVersionCompare])

  const motivationKeys = Object.keys(motivationService.keyMotivations) as MotivationKey[]

  const sortedMotivationKeys = useMemo<MotivationKey[] | undefined>(() => {
    if (!motivationsData || !motivationsData.motivationSegmentValues || !Object.keys(motivationsData.motivationSegmentValues).length) {
      return
    }

    return Object.keys(motivationsData.motivationSegmentValues).sort((a, b) => {
      if (!motivationsData.motivationSegmentValues) {
        return 0
      }

      return motivationsData.motivationSegmentValues[b as MotivationKey] - motivationsData.motivationSegmentValues[a as MotivationKey]
    }) as MotivationKey[]
  }, [motivationsData])

  const gameArcheType = useMemo<MotivationArcheTypeList>(() => {
    const archeTypes: MotivationArcheTypeList = {} as MotivationArcheTypeList
    if (!motivationsData || !sortedMotivationKeys) {
      return archeTypes
    }

    sortedMotivationKeys.forEach((motivationKey) => {
      if (!archeTypes[motivationKey]) {
        archeTypes[motivationKey] = []
      }

      if (!!motivationsData && motivationsData.motivationSegmentValues) {
        archeTypes[motivationKey] = [
          {
            id: motivationsData.id || `${motivationsData.appId}-${motivationKey}`,
            value: Math.round(motivationsData.motivationSegmentValues[motivationKey] * 100) + '%',
            component: <GameIcon gameName={game.resolvedName} src={game.icons || game.icon} />,
          },
        ]
      }
    })
    return archeTypes
  }, [game.icon, game.icons, game.resolvedName, motivationsData, sortedMotivationKeys])

  const { data: gameLists } = useGetGameList({ gameIds: [game.id], include })
  const [modalMotivationKey, setModalMotivationKey] = useState<MotivationKey>()
  const { segmentMotivationsType } = useGetSegmentMotivationsType()
  const { currentMarketIso } = useCurrentMarket()

  // useEffect to set selectedHistoryVersion to the first version
  useEffect(() => {
    if (!gameMotivationProfileVersions) {
      return
    }
    setSelectedHistoryVersion(gameMotivationProfileVersions[gameMotivationProfileVersions.length - 1])
  }, [gameMotivationProfileVersions])

  const labels = useMemo(() => {
    return Object.values(MotivationType).map((motivationKey) => {
      return languageService.getTranslation('motivations', motivationKey)
    })
  }, [])

  useEffect(() => {
    if (modalMotivationKey) {
      analyticsService.trackEvent(
        'Motivations: Archetype descriptions Clicked',
        {
          data: {
            motivationKey: modalMotivationKey,
          },
          serviceToExclude: [AnalyticsProviders.hubspot, AnalyticsProviders.intercom],
        },
        true
      )
    }
  }, [modalMotivationKey])

  const subgenreData = useMemo(() => {
    if (!gameMotivationStat) return []

    const subData = Object.values(MotivationType)
      .filter((motivationOrder) => !!gameMotivationStat[motivationOrder] && !!gameMotivationStat[motivationOrder]?.subgenreOverall)
      .map((motivationOrder) => {
        const subgenreOverall =
          !!gameMotivationStat[motivationOrder] || !!gameMotivationStat[motivationOrder]?.subgenreOverall
            ? gameMotivationStat[motivationOrder]?.subgenreOverall
            : {}

        if (!subgenreOverall || !subgenreOverall[valueOption]) {
          return 0
        }

        return Math.round((subgenreOverall[valueOption] || 0) * 10) / 10
      })
    if (!subData.length) {
      return []
    }

    return [
      {
        label: `${game.conventionalSubgenre} (${selectedValueOption?.label})`,
        data: subData,
      },
    ]
  }, [game.conventionalSubgenre, gameMotivationStat, selectedValueOption?.label, valueOption])

  const gameHistoryData = useMemo(() => {
    if (!motivationsDataVersionCompare || !motivationsDataVersionCompare.motivationValues) return []

    const isFirstVersion = gameMotivationProfileVersions && selectedHistoryVersion === gameMotivationProfileVersions[gameMotivationProfileVersions.length - 1]

    const data = Object.values(MotivationType).map((motivationOrder) => {
      if (!motivationsDataVersionCompare.motivationValues) {
        return 0
      }
      return Math.round(motivationsDataVersionCompare.motivationValues[motivationOrder] * 10) / 10
    })

    return [
      {
        label: isFirstVersion
          ? `${game.resolvedName} (${t('motivations:first_version', { version: selectedHistoryVersion })})`
          : `${game.resolvedName} (${t('motivations:version_no', { version: selectedHistoryVersion })})`,
        data,
      },
    ]
  }, [motivationsDataVersionCompare, gameMotivationProfileVersions, selectedHistoryVersion, game.resolvedName, t])

  const gameData = useMemo(() => {
    if (!motivationsData || !motivationsData.motivationValues) return []

    const data = Object.values(MotivationType).map((motivationOrder) => {
      if (!motivationsData.motivationValues) {
        return 0
      }
      return Math.round(motivationsData.motivationValues[motivationOrder] * 10) / 10
    })

    return [
      {
        label: `${game.resolvedName}${game.version ? ` (${t('motivations:current_version', { version: game.version })})` : ''}`,
        data,
      },
    ]
  }, [game.resolvedName, game.version, motivationsData, t])

  const dataColumns: GRTableColumn<MotivationTableRow, MotivationTableRow>[] = useMemo(() => {
    if (!subgenreData.length) {
      return []
    }

    return [
      {
        labelAccessor: `${t('motivations:importance')} ${game.conventionalSubgenre} (${selectedValueOption?.label})`,
        accessor: ({ row }: { row: MotivationTableRow }) => {
          const value = row.dataRow.value
          return (
            <Box>
              <GRDotRating showZeroRating dotValue={value} maxDots={5} />
              <Typography variant="subtitle2" sx={{ display: 'flex', justifyContent: 'center' }}>
                {value?.toFixed(1)}
              </Typography>
            </Box>
          )
        },
        sortable: true,
        sortAccessor: ({ row }: { row: MotivationTableRow }) => row.dataRow.value,
        sortAnalyticsEvent: {
          eventName: 'Game Motivations Importance Clicked',
        },
        defaultSortOrder: SortOrder.DESC,
      },
    ]
  }, [game.conventionalSubgenre, selectedValueOption?.label, subgenreData.length, t])

  const dataRows = useMemo<InputDataRow | undefined>(() => {
    if (!gameMotivationStat) {
      return
    }

    const rows: InputDataRow = {} as InputDataRow
    Object.keys(segmentMotivationsType).forEach((typeKey) => {
      const motivationType = typeKey as MotivationType
      if (!rows[motivationType]) {
        rows[motivationType] = {}
      }

      if (!!gameMotivationStat[motivationType] || !!gameMotivationStat[motivationType]?.subgenreOverall) {
        const subGenreValue = gameMotivationStat[motivationType]?.subgenreOverall ? gameMotivationStat[motivationType]?.subgenreOverall : {}
        if (!!subGenreValue) {
          rows[motivationType].value = subGenreValue[valueOption] || 0
        }
      }

      if (!!motivationsDataVersionCompare?.motivationValues?.[motivationType]) {
        const compareGameVersion = motivationsDataVersionCompare?.motivationValues[motivationType]
        if (!!compareGameVersion) {
          rows[motivationType].compareGameVersion = motivationsDataVersionCompare?.motivationValues[motivationType]
        }
      }
    })
    return rows
  }, [gameMotivationStat, motivationsDataVersionCompare?.motivationValues, segmentMotivationsType, valueOption])

  if (gameMotivationStatLoading) {
    return <GRCircularProgress />
  }

  if (!gameMotivationStat || !gameLists) {
    return null
  }

  if (!Object.keys(gameMotivationStat).length || !motivationsData || !Object.keys(motivationsData).length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '50%' }}>
          <GRBanner severity="guidance" icon={<Warning />}>
            {t('motivations:motivation_data_na')}
          </GRBanner>
        </Box>
      </Box>
    )
  }

  if (!isMotivationsAvailableForCurrentMarket) {
    return (
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
            <InfoIcon color="primary" />
            <Typography marginLeft="5px">
              <Trans i18nKey={'motivations:motivation_na_for_market'} />
            </Typography>
          </Box>
        </CardContent>
      </Card>
    )
  }

  return (
    <>
      {hasMotivationsAccess ? (
        <>
          {!subgenreData.length && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Box sx={{ width: '50%' }}>
                <GRBanner severity="guidance" icon={<Warning />}>
                  {t('motivations:motivation_data_na')}
                </GRBanner>
              </Box>
            </Box>
          )}
          <MotivationDriver
            gameNotAnalyzed={gameAndAnalysis?.analysis ? false : true}
            datasets={
              gameAndAnalysis?.analysis
                ? gameHistoryData
                  ? gameHistoryData.concat(gameData).concat(subgenreData)
                  : gameData.concat(subgenreData)
                : subgenreData
            }
            labels={labels}
            motivationStats={[modifiedGameMotivationStat]}
            motivationProfileVersions={gameMotivationProfileVersions}
            gameLists={gameLists}
            gamesAndAnalysis={[gameAndAnalysis]}
            dataColumns={dataColumns}
            dataRow={dataRows}
            valueOptions={valueOptions}
            disableSelectOptions={!subgenreData.length}
            onOptionSelected={(option) => {
              const selectedValue = option as valueOptionType
              setValueOption(selectedValue)
            }}
            onVersionSelected={(version) => {
              setSelectedHistoryVersion(version)
            }}
            defaultValue={valueOption}
            defaultVersion={selectedHistoryVersion}
            sortBy={gameLists[0].id}
            sortByOrder={SortOrder.DESC}
            lastUpdatedDate={motivationsData?.createdAt}
            gamePowerScore={gameLists[0].gpsPerMarket[currentMarketIso]}
          />
          <MotivationArcheTypeCard
            motivationKeys={sortedMotivationKeys}
            archeTypeList={gameArcheType}
            onSelectModalMotivationKey={() => setModalMotivationKey(sortedMotivationKeys ? sortedMotivationKeys[0] : motivationKeys[0])}
            onSelectMotivation={setModalMotivationKey}
            titleTranslationKey={'motivations:player_archetypes'}
          />
          {modalMotivationKey && (
            <ArchetypeDescriptionsModal
              datasets={gameAndAnalysis?.analysis ? gameData.concat(subgenreData) : subgenreData}
              labels={labels}
              onSetMotivationKey={setModalMotivationKey}
              motivationKey={modalMotivationKey}
            />
          )}
        </>
      ) : (
        <LockedFeature.Card lockedFeatureId={LockedFeatureId.Motivations} />
      )}
    </>
  )
}

export default GameMotivation
