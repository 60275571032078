import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate, useParams } from 'react-router'
import { useLocation } from 'react-router-dom'

import { AddCircle, Compare, ContentCopy, RemoveCircle } from '@mui/icons-material'
import { Box, Button, CircularProgress, Tooltip } from '@mui/material'

import { useGetUserSettingsQuery, useUpdateUserSettingMutation } from '../../api/combined'
import GRTabNavigation from '../../components/GRTabNavigation/GRTabNavigation'
import ShareUrlButton from '../../components/ShareUrlButton/ShareUrlButton'
import { useMonthlyFollowedGamesStatus } from '../../features/account/hooks/userHooks'
import { UserSettingKeys } from '../../features/account/types/UserSettings'
import useGetGameAnalyzedStatus from '../../features/analysis/hooks/useGetGameAnalyzedStatus'
import { buildCompareGameLink } from '../../features/compare-games/utils/buildCompareGameLink'
import { Game } from '../../features/game'
import { GameFeatures } from '../../features/game-features'
import GameAnalysisOutdatedAlert from '../../features/game/components/GameAnalysisOutdatedAlert/GameAnalysisOutdatedAlert'
import GameCloneDialog from '../../features/game/components/GameCloneDialog/GameCloneDialog'
import GameCompetitors from '../../features/game/components/GameCompetitors/GameCompetitors'
import GameFeatureIdeas from '../../features/game/components/GameFeatureIdeas/GameFeatureIdeas'
import GameHeader from '../../features/game/components/GameHeader/GameHeader'
import GameLiveEvents from '../../features/game/components/GameLiveEvents/GameLiveEvents'
import GameMotivation from '../../features/game/components/GameMotivation/GameMotivation'
import GameOverview from '../../features/game/components/GameOverview/GameOverview'
import GameRevenuePotential from '../../features/game/components/GameRevenuePotential/GameRevenuePotential'
import SuggestAnalysisButton from '../../features/game/components/SuggestAnalysisButton/SuggestAnalysisButton'
import ToggleGameFollowDialog from '../../features/game/components/ToggleGameFollowDialog/ToggleGameFollowDialog'
import {
  useAvailabelGameSlotsCount,
  useGameAnalysisOutdatedForMarketIsoCheck,
  useGameAndAnalysis,
  useGameFollow,
  useGetGameArchiveStatus,
  useIsGameUnlockedCheck,
  useOwnGameCheck,
} from '../../features/game/hooks/gameHooks'
import { GameArchiveStatus } from '../../features/game/types/Game'
import { GameAnalysisStatus } from '../../features/game/types/GameAnalysisStatus'
import { GameSubpage } from '../../features/game/types/GameSubpage'
import GameImplementations from '../../features/implementations/components/GameImplementations'
import { useCurrentMarket } from '../../features/markets'
import { GameRevenueAndDownloads } from '../../features/revenue-and-downloads'
import { useIsGameOpenForEveryone } from '../../features/settings'
import { displaySnackBar } from '../../features/snackbar/actions/displaySnackBar'
import { SnackBarMessage } from '../../features/snackbar/types/SnackBarMessage'
import { GameUpdateHistory } from '../../features/update-history'
import GameVisuals from '../../features/visuals/components/GameVisuals'
import { useAppDispatch } from '../../hooks/storeHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import analyticsService, { ITrackEventOptions } from '../../services/AnalyticsService'
import pageService from '../../services/PageService'
import { AnalyticsProviders } from '../../types/IAnalyticsProvider'
import './GamePage.scss'

const GamePage: React.FC = () => {
  const { t } = useTranslation()
  usePage(pageService.getPageWithId('game'))

  const location = useLocation()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const params: { id?: string; subpage?: string } = useParams()
  const { gameAndAnalysis, isLoading: gameAndAnalysisIsLoading, error, refetchGameAndAnalysis } = useGameAndAnalysis(params.id || '', mainMarketIso)
  const isOwnGame = useOwnGameCheck(gameAndAnalysis?.game)

  const eventData = useMemo(
    () =>
      gameAndAnalysis?.game
        ? {
            gameId: gameAndAnalysis.game.id,
            gameName: gameAndAnalysis.game.name,
          }
        : undefined,
    [gameAndAnalysis]
  )

  useEffect(() => {
    if (!eventData) return
    analyticsService.trackEvent('Visited Game View', { serviceToExclude: [AnalyticsProviders.intercom], data: eventData } as ITrackEventOptions)
  }, [eventData])

  const gameSubpages: GameSubpage[] = useMemo(
    () => [
      {
        id: 'overview',
        name: t('overview:overview'),
        locked: true,
        show: true,
      },
      {
        id: 'live-events',
        name: t('overview:live_events'),
        locked: true,
        show: gameAndAnalysis?.game.isTracked ? true : false,
      },
      {
        id: 'updatehistory',
        name: t('overview:updatehistory'),
        locked: true,
        show: !isOwnGame,
      },
      {
        id: 'revenue-and-downloads',
        name: t('overview:revenue_download_estimates'),
        locked: true,
        show: !isOwnGame,
      },
      {
        id: 'competitors',
        name: t('overview:identified_competitors'),
        locked: true,
        show: true,
      },
      {
        id: 'features',
        name: t('overview:feature_list'),
        locked: true,
        show: true,
      },
      {
        id: 'revenue',
        name: t('overview:revenue'),
        locked: true,
        show: isOwnGame,
      },
      {
        id: 'feature-ideas',
        name: t('overview:feature_ideas'),
        locked: true,
        show: true,
      },
      {
        id: 'implementations',
        name: t('overview:implementations'),
        locked: true,
        show: !isOwnGame,
      },
      {
        id: 'motivations',
        name: t('overview:game_motivations'),
        locked: true,
        show: true,
      },
      {
        id: 'visuals',
        name: t('overview:game_visuals'),
        locked: true,
        show: !isOwnGame,
      },
    ],
    [isOwnGame, t, gameAndAnalysis]
  )

  const defaultSubPage = gameSubpages[0]

  const [selectedSubpage, setSelectedSubpage] = useState<GameSubpage>(gameSubpages.find((subpage) => subpage.id === params.subpage) || defaultSubPage)

  const [saveUserSetting] = useUpdateUserSettingMutation()
  const { data: userSettings } = useGetUserSettingsQuery()

  const [gameClone, setGameClone] = useState<Game | undefined>()
  const [gameFollow, setGameFollowed] = useState<Game | undefined>()
  const { isToggling: isTogglingGameFollow, isFollowed: isGameFollowed } = useGameFollow(gameAndAnalysis?.game)
  const { canUserFollow, canUserUnfollow } = useMonthlyFollowedGamesStatus()
  const isGameUnlocked = useIsGameUnlockedCheck(gameAndAnalysis)
  const isGameOpenForEveryone = useIsGameOpenForEveryone(gameAndAnalysis?.game)
  const dispatch = useAppDispatch()
  const showError = useCallback((errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload)), [dispatch])

  useEffect(() => {
    if (!gameAndAnalysis || !userSettings) return
    const appId = gameAndAnalysis?.game.appId

    if (!userSettings[UserSettingKeys.lastSeenGameVersionsMap]) {
      saveUserSetting({
        settingKey: UserSettingKeys.lastSeenGameVersionsMap,
        value: { [appId]: gameAndAnalysis.game.version },
      })
    }

    const currentUserSettings = userSettings[UserSettingKeys.lastSeenGameVersionsMap]?.[appId]

    if (currentUserSettings === gameAndAnalysis.game.version) return
    saveUserSetting({
      settingKey: UserSettingKeys.lastSeenGameVersionsMap,
      value: { ...userSettings[UserSettingKeys.lastSeenGameVersionsMap], [appId]: gameAndAnalysis.game.version },
    })
  }, [gameAndAnalysis, saveUserSetting, userSettings])

  useEffect(() => {
    const currentGameId = params.id
    if (!userSettings) return
    if (!userSettings.recentlySearchedGameIds) {
      saveUserSetting({ settingKey: UserSettingKeys.recentlySearchedGameIds, value: [currentGameId] })
      return
    }
    const filteredGameSearch = userSettings.recentlySearchedGameIds.filter((gameId) => gameId !== currentGameId)
    const newRecentGameSearch = [currentGameId, ...filteredGameSearch]
    saveUserSetting({ settingKey: UserSettingKeys.recentlySearchedGameIds, value: newRecentGameSearch.slice(0, 5) })
    // to prevent forever loop for userSettings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  useEffect(() => {
    const newSubPage = gameSubpages.find((subPage) => subPage.id === params.subpage) || defaultSubPage
    setSelectedSubpage(newSubPage)
  }, [params.subpage, gameSubpages, defaultSubPage])

  // refetch game and analysis when game is changed to unlocked (followed) as locked games do not have analysis attached to them
  useEffect(() => {
    if (isGameUnlocked) {
      refetchGameAndAnalysis()
    }
  }, [isGameUnlocked, refetchGameAndAnalysis])

  const navigate = useNavigate()
  const compareGame = () => {
    if (gameAndAnalysis) {
      const compareGameLink = buildCompareGameLink({ [gameAndAnalysis.game.id]: [mainMarketIso] }, true)
      navigate(compareGameLink)
    }
  }

  const [gameAnalyzedStatus] = useGetGameAnalyzedStatus(gameAndAnalysis?.game, gameAndAnalysis?.analysis)
  const gameAnalysisOutdated = useGameAnalysisOutdatedForMarketIsoCheck({
    marketIso: mainMarketIso,
    game: gameAndAnalysis?.game,
    includeLiveOpsTrackingStatusCheck: false,
  })
  const gameArchiveStatus = useGetGameArchiveStatus(gameAndAnalysis?.game)

  const gameHasPowerscore = () => {
    if (!gameAndAnalysis) return false
    return gameAndAnalysis?.game.gpsPerMarket[mainMarketIso] > 0
  }

  const gameIsAnalyzedForMarket = useMemo(() => {
    return gameAndAnalysis?.game && gameAndAnalysis.game.isAnalyzedForMarket(mainMarketIso)
  }, [gameAndAnalysis, mainMarketIso])

  const availabelGameSlotsCount = useAvailabelGameSlotsCount() || 0

  const onGameCloneClick = () => {
    if (gameAndAnalysis && availabelGameSlotsCount > 0) {
      setGameClone(gameAndAnalysis.game)
    } else {
      showError({ message: t('game-header:clone_error_body_no_slot'), severity: 'warning', open: true })
    }
  }

  const onToggleGameFollowClick = () => {
    if (gameAndAnalysis) {
      setGameFollowed(gameAndAnalysis.game)
    }
  }

  const tabs = useMemo(() => {
    return gameSubpages
      .filter((subpage) => subpage.show)
      .map((subpage) => ({
        id: subpage.id,
        name: subpage.name,
      }))
  }, [gameSubpages])

  useDocumentTitle(!gameAndAnalysis ? undefined : gameAndAnalysis?.game.resolvedName + (selectedSubpage ? ': ' + selectedSubpage?.name : ''))

  if (!params.subpage || !selectedSubpage) {
    return <Navigate replace to={`/game/${params.id}/overview`} />
  } else if (error) {
    return <Navigate replace to={`/`} />
  } else {
    return (
      <div className="GamePage">
        {!!gameClone && <GameCloneDialog originGame={gameClone} open={!!gameClone} onClose={() => setGameClone(undefined)} />}

        {!!gameFollow && <ToggleGameFollowDialog open={true} onClose={() => setGameFollowed(undefined)} game={gameFollow} />}

        {gameAndAnalysisIsLoading && (
          <div className="text-center top-margin">
            <CircularProgress color="primary" />
          </div>
        )}

        {!gameAndAnalysisIsLoading && gameAndAnalysis && !error && (
          <div className="GamePage__content">
            <GameHeader stickyContent gameAndAnalysis={gameAndAnalysis} isLoading={gameAndAnalysisIsLoading}>
              <GameHeader.Actions>
                {gameAnalyzedStatus === GameAnalysisStatus.analyzed && (
                  <>
                    {!isGameFollowed && !isOwnGame && (
                      <Tooltip title={!canUserFollow ? t<string>('common:you_dont_have_any_followed_game_slots_available') : ''}>
                        <span>
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddCircle />}
                            onClick={onToggleGameFollowClick}
                            disabled={isTogglingGameFollow || !canUserFollow}
                          >
                            {t('game-header:follow')}
                          </Button>
                        </span>
                      </Tooltip>
                    )}

                    {isGameFollowed && !isOwnGame && (
                      <Tooltip title={!canUserUnfollow ? t<string>('services:unfollowing_not_available_text') : ''}>
                        <span>
                          <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<RemoveCircle />}
                            onClick={onToggleGameFollowClick}
                            disabled={isTogglingGameFollow || !canUserUnfollow}
                          >
                            {t('common:unfollow')}
                          </Button>
                        </span>
                      </Tooltip>
                    )}

                    {isGameOpenForEveryone || isGameUnlocked ? (
                      <>
                        <Button variant="contained" color="primary" startIcon={<Compare />} onClick={compareGame}>
                          {t('common:compare_game')}
                        </Button>

                        <Button variant="contained" color="primary" startIcon={<ContentCopy />} onClick={onGameCloneClick}>
                          {t('game-header:clone')}
                        </Button>
                      </>
                    ) : null}
                  </>
                )}

                {(!gameHasPowerscore() || gameAnalysisOutdated) && gameArchiveStatus === GameArchiveStatus.active && (
                  <SuggestAnalysisButton game={gameAndAnalysis.game} size="medium"></SuggestAnalysisButton>
                )}

                <ShareUrlButton />
              </GameHeader.Actions>
              <GameHeader.Content>
                <GRTabNavigation
                  tabs={tabs}
                  selectedTab={tabs.findIndex((tab) => tab.id === selectedSubpage.id)}
                  pagePrefix={`/game/${params.id}`}
                  query={location.search}
                  sticky={true}
                  hasSecondaryStickyElement={true}
                />
              </GameHeader.Content>
            </GameHeader>

            <GameAnalysisOutdatedAlert
              game={gameAndAnalysis.game}
              marketIso={mainMarketIso}
              gameAnalysisStatus={gameAnalyzedStatus}
              gameArchiveStatus={gameArchiveStatus}
            ></GameAnalysisOutdatedAlert>

            <Box className="GamePage__subpage">
              {selectedSubpage.id === 'overview' && (
                <GameOverview
                  game={gameAndAnalysis.game}
                  analysis={gameAndAnalysis.analysis}
                  analysisProgress={gameAndAnalysis.game.analysisProgressPerMarket}
                />
              )}
              {selectedSubpage.id === 'live-events' && <GameLiveEvents game={gameAndAnalysis.game} analysis={gameAndAnalysis.analysis} />}
              {selectedSubpage.id === 'updatehistory' && <GameUpdateHistory gameAndAnalysis={gameAndAnalysis} />}
              {selectedSubpage.id === 'revenue-and-downloads' && <GameRevenueAndDownloads game={gameAndAnalysis.game} />}
              {selectedSubpage.id === 'revenue' && <GameRevenuePotential game={gameAndAnalysis.game} analysis={gameAndAnalysis.analysis} />}
              {selectedSubpage.id === 'implementations' && (
                <GameImplementations
                  game={gameAndAnalysis.game}
                  isGameUnlocked={isGameUnlocked}
                  isGameAnalyzed={gameIsAnalyzedForMarket}
                  analysis={gameAndAnalysis.analysis}
                />
              )}
              {selectedSubpage.id === 'visuals' && <GameVisuals game={gameAndAnalysis.game} />}
              {selectedSubpage.id === 'competitors' && <GameCompetitors game={gameAndAnalysis.game} analysis={gameAndAnalysis.analysis} />}
              {selectedSubpage.id === 'feature-ideas' && (
                <GameFeatureIdeas game={gameAndAnalysis.game} analysis={gameAndAnalysis.analysis} isGameAnalyzed={gameIsAnalyzedForMarket} />
              )}
              {selectedSubpage.id === 'features' && (
                <GameFeatures
                  game={gameAndAnalysis.game}
                  analysis={gameAndAnalysis.analysis}
                  isGameUnlocked={isGameUnlocked}
                  isGameAnalyzed={gameIsAnalyzedForMarket}
                />
              )}
              {selectedSubpage.id === 'motivations' && <GameMotivation gameAndAnalysis={gameAndAnalysis} />}
            </Box>
          </div>
        )}
      </div>
    )
  }
}

export default GamePage
