import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Dialog, DialogContent, Divider, Grid, Typography } from '@mui/material'

import { CategoriesFeatures, CategoriesMap, useGetGameLatestAndPrevAnalysisQuery } from '../../../../api/core'
import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import Powerscore from '../../../../components/Powerscore/Powerscore'
import analyticsService from '../../../../services/AnalyticsService'
import { Analysis } from '../../../analysis/types/Analysis'
import { GameLink } from '../../../game/components/GameLink/GameLink'
import { Game } from '../../../game/types/Game'
import { MarketSelector, useCurrentMarket } from '../../../markets'
import { getCategoryEffect } from '../../utils/getCategoryEffect'
import EffectImpactDiffText from '../EffectImpactDiffText/EffectImpactDiffText'
import FeatureCategoryBreakDown, { categoryCard } from '../FeatureCategoryBreakDown/FeatureCategoryBreakDown'
import FeatureSetChanges from '../FeatureSetChanges/FeatureSetChanges'

/**
 * PowerScoreProgressDialog Shows progress of analysis after update PowerScore
 */

interface PowerScoreAnalysisDialogProps {
  open: boolean
  game: Game
  onClose: () => void
  categoriesMap?: CategoriesMap
  features?: CategoriesFeatures
}
const PowerScoreAnalysisDialog: React.FC<PowerScoreAnalysisDialogProps> = ({ open, game, onClose, categoriesMap = {}, features }) => {
  const { t } = useTranslation()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const [selectedMarket, setselectedMarket] = useState(mainMarketIso)
  const { data: latestAndPrevAnalysis } = useGetGameLatestAndPrevAnalysisQuery({ id: game.id, marketIso: selectedMarket, onlyCompleted: true })

  const [latestAnalysis, setLatestAnalysis] = useState<Analysis | undefined>()
  const [prevAnalysis, setPrevAnalysis] = useState<Analysis | undefined>()

  useEffect(() => {
    setLatestAnalysis(latestAndPrevAnalysis?.latest)
    setPrevAnalysis(latestAndPrevAnalysis?.prev)
  }, [latestAndPrevAnalysis])

  useEffect(() => {
    if (latestAnalysis) {
      analyticsService.trackEvent('Calculated Power Score', { data: { gameId: game.id, gameName: game.resolvedName, powerScore: latestAnalysis.powerScore } })
    }
  }, [game.id, game.resolvedName, latestAnalysis])

  const categories = Object.values(categoriesMap)
  const categoryCards = useMemo(
    () =>
      categories.map<categoryCard>((category) => {
        const currentEffect = latestAnalysis ? getCategoryEffect(category, latestAnalysis) : 0
        const prevEffect = prevAnalysis ? getCategoryEffect(category, prevAnalysis) : currentEffect
        return {
          id: category.id,
          name: category.name,
          icon: category.icon.url,
          currentEffect,
          prevEffect,
          sum: Math.round((currentEffect - prevEffect) * 100) / 100,
          legacyId: category.legacyId,
        }
      }),
    [categories, latestAnalysis, prevAnalysis]
  )

  const sumCategoryDiffs = useMemo(() => {
    const sumDiff = categoryCards.reduce((sum, categoryCard) => {
      return (sum += categoryCard.sum)
    }, 0)

    return Math.round(sumDiff * 10) / 10
  }, [categoryCards])

  if (!latestAnalysis) {
    return null
  }

  const onMarketSelectChange = (marketIso: string) => {
    setselectedMarket(marketIso)
  }

  const showCategoryBreakDown = !!latestAnalysis && !!categoriesMap

  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <GRDialogTitle onClose={onClose}>{prevAnalysis ? t('analysis:analysis_updated') : t('analysis:analysis_complete')}</GRDialogTitle>
      <DialogContent className="PowerScoreAnalysisDialog__content" dividers>
        <Box sx={{ mb: 2 }}>
          <Grid container columnSpacing={6}>
            <Grid item lg={4} sm={4} xs={12} textAlign="center">
              <Box sx={{ position: 'relative', pb: 2 }}>
                <Powerscore powerscore={latestAnalysis.powerScore} fontSize={24} size={120} />
                <EffectImpactDiffText showArrow value={sumCategoryDiffs} sx={{ position: 'absolute', top: '30%', right: 0 }} />
                <Typography variant="h2" sx={{ mt: 1, mb: 2 }}>
                  {t('common:game_power_score')}
                </Typography>
                <MarketSelector value={selectedMarket} onChange={onMarketSelectChange} />
              </Box>
              <Divider />
              <GameLink game={game}>
                <Button sx={{ marginTop: 2, marginBottom: 1 }} variant="contained" color="primary">
                  {t('analysis:show_full_analysis')}
                </Button>
              </GameLink>
              <Button size="small" onClick={() => onClose()} variant="outlined" color="primary">
                {t('analysis:modify_analysis')}
              </Button>
            </Grid>
            <Grid item lg={8} sm={8} xs={12}>
              {showCategoryBreakDown && <FeatureCategoryBreakDown categoryCards={categoryCards} />}
            </Grid>
          </Grid>
          <FeatureSetChanges features={features} latestAnalysis={latestAnalysis} prevAnalysis={prevAnalysis || latestAnalysis} categoriesMap={categoriesMap} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default PowerScoreAnalysisDialog
