import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import { ExpandLess } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'

import './CollapseChevron.scss'

type CollapseChevronProps = SvgIconProps & {
  collapsed: boolean
  onToggle?: (open: boolean) => void
  children?: ReactNode
}

export const CollapseChevron: FC<CollapseChevronProps> = ({ onToggle, collapsed, children, ...iconProps }) => {
  const classes = classNames('CollapseChevron', { collapsed })
  return (
    <ExpandLess className={classes} onClick={() => onToggle?.(!collapsed)} sx={{ '&:hover': { cursor: onToggle ? 'pointer' : 'default' } }} {...iconProps} />
  )
}
