import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers'
import { FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query'

import { randomWait } from '../internal/helpers/randomWait'

type CustomReturnType<T> = MaybePromise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>>

/**
 * Uploads a file with retry logic.
 *
 * @template T - The type of the data returned by the upload function.
 * @param {function} uploadFile - The function to upload the file. It should return a `CustomReturnType<T>`.
 * @param {File} file - The file to be uploaded.
 * @param {number} [maxRetries=5] - The maximum number of retry attempts. Defaults to 5.
 * @returns {Promise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>>} - A promise that resolves to the result of the upload operation.
 * @throws {Error} - Throws an error if the loop exits unexpectedly.
 */
export const uploadFileWithRetry = async <T>(
  uploadFile: (file: File) => CustomReturnType<T>,
  file: File,
  maxRetries = 5
): Promise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>> => {
  let attempt = 0
  while (attempt < maxRetries) {
    const uploaded = await uploadFile(file)

    if (uploaded.error) {
      attempt++
      // eslint-disable-next-line no-console
      console.error(`Attempt ${attempt} failed for file ${file.name}:`, uploaded.error)
      if (attempt >= maxRetries) {
        return uploaded
      }
    } else if (uploaded.data) {
      // eslint-disable-next-line no-console
      console.log(`File ${file.name} uploaded successfully.`)
      return uploaded
    }

    // Wait for a random time between 0 and 1 second before retrying
    await randomWait()
  }
  // Fallback, in case the loop exits unexpectedly (although it shouldn't)
  throw new Error('Unexpected error')
}

/**
 * Uploads a batch of files using the provided upload function.
 *
 * @template T - The type of the custom return value from the upload function.
 * @param {File[]} filesBatch - An array of files to be uploaded, each with retry capabilities.
 * @param {(file: File) => CustomReturnType<T>} uploadFile - A function that uploads a single file and returns a custom result.
 * @returns {Promise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>[]>} A promise that resolves to an array of query return values, one for each file in the batch.
 *
 * @throws Will log an error to the console and return an empty array if there is a batch-level error.
 */
export const uploadBatch = async <T>(
  filesBatch: File[],
  uploadFile: (file: File) => CustomReturnType<T>
): Promise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>[]> => {
  try {
    const uploadPromises = filesBatch.map((file) => uploadFileWithRetry(uploadFile, file))
    const results = await Promise.all(uploadPromises) // Wait for all files in the batch to upload
    return results // Return the results of the batch upload
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error uploading batch:', error)
    return [] // Return empty result in case of a batch-level error
  }
}
