import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'

import { useGetScreenshotsByGameIdsQuery } from '../../../api/core'
import GameNotAnalyzedCard from '../../../components/GameNotAnalyzedCard/GameNotAnalyzedCard'
import { LockedFeature } from '../../../components/LockedFeature/LockedFeature'
import analyticsService from '../../../services/AnalyticsService'
import utilsService from '../../../services/UtilsService'
import { LockedFeatureId } from '../../../types/LockedFeature'
import { useRoleCheck } from '../../account/hooks/roleHooks'
import { RoleEnum } from '../../account/types/RoleEnum'
import { Analysis } from '../../analysis/types/Analysis'
import { useFeatures } from '../../feature/hooks/useFeatures'
import { Game } from '../../game'
import { UnlockGame } from '../../game/components/UnlockGame/UnlockGame'
import { useGameFollow } from '../../game/hooks/gameHooks'
import { FeatureGame } from '../../game/types/Game'
import FilterByTags from '../../implementation-examples/components/FilterByTags/FilterByTags'
import ImplementationPreviews from '../../implementation-examples/components/ImplementationPreviews/ImplementationPreviews'
import { enabledFeaturesFilter } from '../../implementation-examples/util/filters'
import { convertLegacyScreenshots, extractNeededLegacyIds, pickFeaturesWithLegacyId } from '../../implementation-examples/util/screenshotHelpers'
import { useIsGameOpenForEveryone } from '../../settings'

type GameImplementationsProps = {
  game: Game
  analysis?: Analysis
  isGameUnlocked?: boolean
  isGameAnalyzed?: boolean
  hideListScreenshots?: boolean
}
const GameImplementations: React.FC<GameImplementationsProps> = ({ game, isGameUnlocked, isGameAnalyzed, analysis, hideListScreenshots }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Overview: Implementations', {
      data: {
        gameId: game.id,
        gameName: game.resolvedName,
        analysisId: analysis?.id,
      },
    })
  }, [analysis?.id, game.id, game.resolvedName])
  const { t } = useTranslation()
  const isGameOpenForEveryone = useIsGameOpenForEveryone(game)
  const allGamesUnlocked = useRoleCheck(RoleEnum.all_games_unlocked)
  const { isFollowed: isGameFollowed } = useGameFollow(game)
  const isGameImplementationsFeatureUnlocked = useRoleCheck(RoleEnum.feature_implementation_examples_unlimited)
  const displayUnlockGameCard = !(allGamesUnlocked || isGameFollowed || isGameOpenForEveryone) && isGameImplementationsFeatureUnlocked
  const displayImplementations = isGameOpenForEveryone || isGameImplementationsFeatureUnlocked

  const { data: featuresData, isFetching: isFetchingFeatures } = useFeatures({ skipCounts: true })
  const { data: screenshotsData, isFetching: isFetchingScreenshots } = useGetScreenshotsByGameIdsQuery([game.id])
  const [enabledFeatureIds, setEnabledFeatureIds] = useState<number[]>()

  const featureGame: FeatureGame = useMemo(
    () =>
      ({
        ...game,
        featureScreenshots: convertLegacyScreenshots((screenshotsData || []).filter(({ gameId }) => gameId === game.id)),
      } as FeatureGame),
    [game, screenshotsData]
  )

  const features = useMemo(() => {
    if (!featuresData) {
      return undefined
    }
    const legacyIds = extractNeededLegacyIds([featureGame])
    return pickFeaturesWithLegacyId(legacyIds, featuresData)
  }, [featuresData, featureGame])

  const sortedReleaseDate = useMemo(() => {
    return utilsService.sortedReleaseDate(featureGame)
  }, [featureGame])

  if (!screenshotsData || !screenshotsData.length) return <Typography>{t('common:no_implementations_found')}</Typography>

  return (
    <Box>
      {!isGameAnalyzed && <GameNotAnalyzedCard game={game} />}
      {isGameAnalyzed && (
        <>
          {!displayImplementations && <LockedFeature.Card lockedFeatureId={LockedFeatureId.GameImplementations} />}
          {displayUnlockGameCard && <UnlockGame.Card game={game} />}
          {displayImplementations && (
            <>
              <Typography>{t('implementations:label_filter_implementations_2')}</Typography>
              <FilterByTags features={features} onChangeEnabledFeatures={setEnabledFeatureIds} />
              <ImplementationPreviews
                breakDownVersions={true}
                features={features?.filter(enabledFeaturesFilter(enabledFeatureIds))}
                games={[featureGame]}
                loading={isFetchingFeatures || isFetchingScreenshots}
                hideListScreenshots
                fromDate={sortedReleaseDate}
                minDate={sortedReleaseDate}
              />
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default GameImplementations
