import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Box, Card, CardContent, CardHeader, CircularProgress, Divider, Typography } from '@mui/material'

import { useGetUserProfileQuery } from '../../../api/combined'
import { useGetVisibleCollectionsQuery } from '../../../api/core'
import Collection from '../../../assets/images/collections_instruction_3.png'
import { useDocumentTitle } from '../../../hooks/useDocumentTitle'
import { CollectionNameSorter } from '../../implementation-examples/util/comparators'
import { ScreenshotMap, useScreenshotsByIds } from '../hooks/screenshotHooks'
import { categorizeCollections, CollectionCategory } from '../utils/collectionHelpers'
import CollectionPreviewCard from './CollectionPreviewCard'

type VisibilityTranslationKeys = { [cat in CollectionCategory]: string }

const categoryTranslationKeys: VisibilityTranslationKeys = {
  MY: 'implementations:label_my_collections',
  PUBLIC: 'implementations:label_gamerefinery_collections',
  SHARED: 'implementations:label_collections_shared_with_me',
}

const Collections: React.FC = () => {
  const { t } = useTranslation()
  const { data: currentUser } = useGetUserProfileQuery()
  const { data: collectionResult, isFetching: isFetchingCollections } = useGetVisibleCollectionsQuery()
  const { data: screenshotResult, isFetching: isFetchingScreenshots } = useScreenshotsByIds(
    (collectionResult || [])
      .map((collection) => collection.screenshots)
      .flat()
      .filter((value) => !!value)
  )
  useDocumentTitle(t('implementations:label_collections'), true)

  const collections = categorizeCollections(collectionResult || [], currentUser?.email as string)
  const screenshotMap = (isFetchingScreenshots ? undefined : screenshotResult) as ScreenshotMap

  const collectionHtml = (category: string) => {
    if (category === 'MY' && !collections['MY'].length) {
      return (
        <Box>
          <Typography>{t('implementations:label_empty_my_collections')}</Typography>
          <img alt="Collection" src={Collection} style={{ marginTop: '10px', maxWidth: '500px' }} />
        </Box>
      )
    }
    if (category !== 'MY') {
      if (!collections[category as CollectionCategory].length) {
        return (
          <Box>
            <Typography>{t('implementations:label_empty_collections')}</Typography>
          </Box>
        )
      }
    }
    return collections[category as CollectionCategory]?.sort(CollectionNameSorter).map((collection, j) => (
      <Link to={`/implementation-examples/collections/${category.toLowerCase()}/${collection.id}`} key={j}>
        <CollectionPreviewCard
          collection={collection}
          screenshots={screenshotMap && collection.screenshots.slice(0, Math.min(3, collection.screenshots.length)).map((id, index) => screenshotMap[id])}
        />
      </Link>
    ))
  }

  return (
    <Box>
      <Typography variant="h2" mt={3} mb={2} ml={2}>
        {t('implementations:heading_implementation_examples_collections')}
      </Typography>
      {isFetchingCollections || !collectionResult ? (
        <Box my={2} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        Object.keys(collections).map((category, i) => (
          <Card className="collectionCategoryCard" key={i} sx={{ mb: 4 }}>
            <CardHeader title={t(categoryTranslationKeys[category as CollectionCategory])} />
            <Divider />
            <CardContent sx={{ m: -1, mb: -2 }}>{collectionHtml(category)}</CardContent>
          </Card>
        ))
      )}
    </Box>
  )
}
export default Collections
