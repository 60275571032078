import classNames from 'classnames'
import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Button, Card, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { analyzerGridColumn } from '../..'
import { Category, Feature } from '../../../../api/core'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import ProgressBar from '../../../../components/ProgressBar/ProgressBar'
import languageService from '../../../../services/LanguageService'
import { RoleEnum } from '../../../account'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { Analysis } from '../../../analysis/types/Analysis'
import { AnalysisNotes } from '../../../analysis/types/AnalysisNotes'
import { CategoryImage } from '../../../game-features/components/CategoryImage/CategoryImage'
import { GameAnalysisStatus } from '../../../game/types/GameAnalysisStatus'
import { getCategoryEffect } from '../../utils/getCategoryEffect'
import FeatureCard from '../FeatureCard/FeatureCard'
import FeatureCardWrapper from '../FeatureCardWrapper/FeatureCardWrapper'
import './GameAnalysisCard.scss'

const marginBottom = 16
const sumClientHeight = (): number => {
  const elementIds = ['app-header', 'FeatureSetRow']

  const sum = elementIds.reduce((sum, elementId) => {
    const elementClientHeight = !!document.getElementById(elementId) ? document.getElementById(elementId)!.clientHeight : 0
    return (sum += elementClientHeight)
  }, 0)
  return sum + marginBottom
}

/**
 * GameAnalysisCard
 */
interface GameAnalysisCardProps {
  isLoading?: boolean
  open: boolean
  category: Category
  features: Feature[]
  gameAnalyzedStatus?: GameAnalysisStatus
  latestAnalysis?: Analysis // Can be also null!
  currentAnalysis: Analysis
  benchMarkAnalysis?: Analysis
  showNextFeatureBtn?: boolean
  onViewToggle: () => void
  onNextFeatureClicked?: () => void
  onRefBind?: (ref: React.MutableRefObject<HTMLDivElement | null>) => void
  notes: AnalysisNotes
}
const GameAnalysisCard: React.FC<GameAnalysisCardProps> = ({
  category,
  features,
  isLoading,
  gameAnalyzedStatus,
  open,
  currentAnalysis,
  latestAnalysis,
  benchMarkAnalysis,
  onViewToggle,
  onNextFeatureClicked,
  onRefBind,
  showNextFeatureBtn,
  notes,
}) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)

  const currentAnswers = currentAnalysis.answers[category.id] || {}
  const latestAnswers = latestAnalysis?.answers[category.id]
  const benchmarkAnswers = benchMarkAnalysis?.answers[category.id]
  const isGameAnalyzerUnlimited = useRoleCheck(RoleEnum.game_analyzer_unlimited)

  const answerCount = useMemo(() => {
    const answered = features.reduce((count, feature) => {
      if (currentAnalysis.answers[category.id] && currentAnalysis.answers[category.id][feature.id]) {
        return count + 1
      }
      return count
    }, 0)

    return answered
  }, [category.id, currentAnalysis.answers, features])

  useEffect(() => {
    if (onRefBind) {
      onRefBind(ref)
    }
  }, [onRefBind])

  if (isLoading) {
    return (
      <Card>
        <GRCircularProgress fontSize={1} />
      </Card>
    )
  }

  const onFeatureClicked = async () => {
    if (!onNextFeatureClicked) return
    onNextFeatureClicked()
  }

  return (
    <>
      <div style={{ scrollMargin: sumClientHeight() + 'px' }} ref={ref} />
      <Card sx={{ mb: 2 }}>
        <CategoryHeader
          open={open}
          category={category}
          answerCount={answerCount}
          featureCount={Object.keys(features).length}
          latestCategoryScore={latestAnalysis ? getCategoryEffect(category, latestAnalysis) : 0}
          benchmarkCategoryScore={benchMarkAnalysis && getCategoryEffect(category, benchMarkAnalysis)}
          onViewToggle={onViewToggle}
          isGameAnalyzerUnlimited={isGameAnalyzerUnlimited}
          gameAnalyzedStatus={gameAnalyzedStatus}
        />
        {open && (
          <FeatureCardWrapper>
            {features.map((feature) => (
              <FeatureCard
                note={notes[feature.legacyId]}
                currentAnswer={currentAnswers[feature.id]}
                latestAnswer={latestAnswers && latestAnswers[feature.id]}
                benchmarkAnswer={benchmarkAnswers && benchmarkAnswers[feature.id]}
                key={feature.id}
                feature={feature}
                analysisId={currentAnalysis.id}
                latestScore={latestAnalysis ? getCategoryEffect(category, latestAnalysis, feature) : 0}
                benchmarkScore={benchMarkAnalysis && getCategoryEffect(category, benchMarkAnalysis, feature)}
                gameAnalyzedStatus={gameAnalyzedStatus}
                category={category}
              />
            ))}
            {showNextFeatureBtn && (
              <Box display="flex" justifyContent="flex-end" sx={{ margin: 2, mr: 0 }}>
                <Button disabled={Object.keys(currentAnswers).length < Object.keys(features).length} variant="contained" onClick={onFeatureClicked}>
                  {t('analysis:next_category')}
                </Button>
              </Box>
            )}
          </FeatureCardWrapper>
        )}
      </Card>
    </>
  )
}

export default GameAnalysisCard

/**
 * CategoryHeader
 */
interface CategoryHeaderProps {
  open: boolean
  category: Category
  featureCount: number
  answerCount: number
  latestCategoryScore: number
  benchmarkCategoryScore?: number
  onViewToggle: () => void
  isGameAnalyzerUnlimited?: boolean
  gameAnalyzedStatus?: GameAnalysisStatus
}
const CategoryHeader: React.FC<CategoryHeaderProps> = ({
  open,
  category,
  onViewToggle,
  benchmarkCategoryScore,
  latestCategoryScore,
  featureCount,
  answerCount,
  isGameAnalyzerUnlimited,
  gameAnalyzedStatus,
}) => {
  return (
    <Grid className="CategoryHeader" container sx={{ paddingX: 4, paddingY: 2 }} flexDirection="row" alignItems="center">
      <Grid
        xs={analyzerGridColumn.xs.name}
        sx={{ cursor: 'pointer' }}
        item
        container
        flexDirection="row"
        onClick={() => {
          onViewToggle()
        }}
      >
        <Box sx={{ mr: analyzerGridColumn.spacing }}>
          <CategoryImage src={category.icon.url} alt={category.name} />
        </Box>
        <Typography variant="h3" sx={{ mt: '2px' }}>
          {languageService.getTranslation('categories', category.legacyId.toString())}
        </Typography>
        {open ? <ArrowDropDown /> : <ArrowDropUp />}
      </Grid>
      <Grid item display="flex" justifyContent="center" xs={analyzerGridColumn.xs.options} sx={{ mr: analyzerGridColumn.spacing }}>
        <Typography className={classNames('CategoryHeader__count', { fulfilled: answerCount === featureCount })} fontWeight={900}>
          {`${answerCount} / ${featureCount}`}
        </Typography>
      </Grid>
      <Grid item xs={analyzerGridColumn.xs.score} sx={{ minWidth: '16.7%', mr: analyzerGridColumn.spacing }}>
        {gameAnalyzedStatus === GameAnalysisStatus.analyzed && isGameAnalyzerUnlimited && <ProgressBar value={latestCategoryScore} />}
      </Grid>
      <Grid item xs={analyzerGridColumn.xs.compare}>
        {benchmarkCategoryScore !== undefined && isGameAnalyzerUnlimited && <ProgressBar value={benchmarkCategoryScore} />}
      </Grid>
      <Grid item xs={analyzerGridColumn.xs.edit}></Grid>
    </Grid>
  )
}
