import { useGetCurrentOrganizationUserInvitesQuery, useGetCurrentUserInvitesQuery } from '../../../api/account'
import { useGetUserProfileQuery } from '../../../api/combined'

export function useUserCurrentUserInvites() {
  const { data: userInvites, isLoading, error } = useGetCurrentUserInvitesQuery()

  return { userInvites: userInvites, userInvitesLoading: isLoading, userInvitesError: error }
}

export const useCurrentOrganizationUserInvites = () => {
  const { data: userInvites, isLoading, error } = useGetCurrentOrganizationUserInvitesQuery()

  return { userInvites, userInvitesLoading: isLoading, userInvitesError: error }
}

export const useCurrentOrganizationSeatLimit = () => {
  const { maxSeats } = useGetUserProfileQuery(undefined, { selectFromResult: ({ data }) => ({ maxSeats: data?.organization.maxSeats || 0 }) })

  return maxSeats
}
