import { endOfMonth, endOfWeek, isAfter, parse, set, subMonths, subWeeks } from 'date-fns'
import { useMemo } from 'react'

import { useGetEstimatesListQuery } from '../../../api/core'
import { average } from '../../../helpers/average'
import { uniq } from '../../../helpers/uniq'
import { valueIsBetween } from '../../../helpers/valueIsBetween'
import { Game } from '../../game'
import { GameWithMarketIso } from '../../game-search/components/GameSearchDialog/GameSearchDialog'
import { getMarketName } from '../../markets/utils/utils'
import { getTimestampByGranularity } from '../../revenue-and-downloads/helpers/helpers'
import { GranularityValue } from '../../revenue-and-downloads/types/Filters'
import { EstimatePlatformType } from '../types/EstimatePlatformType'
import { MarketPerformanceEstimates, MarketsPerformanceEstimates, MarketTotals, PerformanceEstimate } from '../types/Estimates'
import { DailyEstimates, Estimates, EstimatesList, MarketEstimates, PlatformType } from '../types/EstimatesList'

export type EstimateDataFilters = {
  rollingDays?: number
  platformTypes: EstimatePlatformType[]
  marketIsos: string[]
  granularity?: GranularityValue
  isCumulative?: boolean
  dateFrom?: number
  dateTo?: number
}

/**
 * Hook that loads estimate data for given app
 */

type UseGameEstimateDataHookParams = {
  appIds: number[]
  markets: string[]
  platformTypes?: EstimatePlatformType[]
  filters?: EstimateDataFilters
}

const defaultPlatformTypes = [EstimatePlatformType.Phone, EstimatePlatformType.Tablet]

export const useGameEstimateData = ({ appIds, markets, platformTypes = defaultPlatformTypes }: UseGameEstimateDataHookParams) => {
  const { data: estimatesList, isLoading, isFetching, error } = useGetEstimatesListQuery({ appIds, markets, platforms: platformTypes })

  return { estimatesList, isLoading: isLoading || isFetching, isFetching, error }
}

/**
 * Hook that constructs and prepares estimate data by market
 */

type UseEstimateListHookParams = {
  data?: EstimatesList
  filters?: EstimateDataFilters
}

const defaultFilters = {
  rollingDays: 1,
  platformTypes: [EstimatePlatformType.Phone, EstimatePlatformType.Tablet],
  marketIsos: [],
  granularity: GranularityValue.Day,
}

export const useEstimatesList = ({ data = {}, filters = defaultFilters }: UseEstimateListHookParams) => {
  const resolvedFilters = { ...defaultFilters, ...filters }
  const { rollingDays, granularity, dateFrom, dateTo, isCumulative } = resolvedFilters

  // filter only request platforms and markets
  const platformEstimates = usePlatformEstimates({ estimatesList: data, platformTypes: filters.platformTypes, marketIsos: filters.marketIsos })
  const { tsMin, tsMax } = useEstimateMinMaxDates({ marketEstimates: platformEstimates })

  // combine data from each platform
  const combinedEstimates = useCombinedPlatformEstimates({ marketEstimates: platformEstimates })
  const allMarketDates = useAllMarketDates({ marketEstimates: combinedEstimates })

  // fill inexisitng estimate data points with empty data values
  const filledEstimates = useFilledEstimates({ allMarketDates, marketEstimates: combinedEstimates })
  const marketTotals = useMarketTotals({ marketEstimates: filledEstimates, dateFrom: filters.dateFrom, dateTo: filters.dateTo })

  // count estimates with rolling averages
  const rollingAverageEstimates = useRollingAverages({ marketEstimates: filledEstimates, rollingDays })

  // group estimates by time granularity
  const estimatesGroupedByGranularity = useGranularityGrouping({ marketEstimates: rollingAverageEstimates, granularity, dateFrom, dateTo })

  // map estimate data to suitable format for displaying in charts and tables
  const marketPerformanceEstimates = useMarketPerformanceEstimates({ marketEstimates: estimatesGroupedByGranularity, marketTotals, isCumulative, tsMin, tsMax })

  // count totals from all markets and platforms
  const estimates = useMarketsPerformanceEstimates({ marketPerformanceEstimates, tsMin, tsMax })

  return estimates
}

export type iosEstimate = {
  date: string
  data: Estimates
}
export type GameEstimates = {
  game: Game
  marketIso: string
  iosEstimates: iosEstimate[]
}

export const useGameEstimates = ({
  data = {},
  filters = defaultFilters,
  gamesWithMarketIso,
  games,
}: UseEstimateListHookParams & { gamesWithMarketIso: GameWithMarketIso; games: Game[] }) => {
  const resolvedFilters = { ...defaultFilters, ...filters }
  const { dateFrom = 0, dateTo = new Date().getTime() } = resolvedFilters

  // filter only request platforms and markets
  const platformEstimates = usePlatformEstimates({ estimatesList: data, platformTypes: filters.platformTypes, marketIsos: filters.marketIsos })
  const { tsMin, tsMax } = useEstimateMinMaxDates({ marketEstimates: platformEstimates })

  type combinedEstimates = { [appId: number]: { [iso: string]: { data: Estimates; date: string }[] } }
  const combinedIosEstimates = useMemo<combinedEstimates>(() => {
    const combinedEstimates: combinedEstimates = {}
    const convertDateToTimestamp = (date: string) => {
      const d = set(new Date(date), { hours: 0, minutes: 0, seconds: 0 })
      return d.getTime()
    }
    const addDataToCombinedEstimates = (appId: number, marketIso: string, date: string, data: Estimates) => {
      if (!combinedEstimates[appId]) {
        combinedEstimates[appId] = {}
      }

      if (!combinedEstimates[appId][marketIso]) {
        combinedEstimates[appId][marketIso] = []
      }

      combinedEstimates[appId][marketIso].push({ data, date })
      combinedEstimates[appId][marketIso].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    }

    if (!data || !data.phoneEstimates) {
      return combinedEstimates
    }

    Object.entries(data.phoneEstimates).forEach(([appId, phoneEstimates]) => {
      Object.entries(phoneEstimates).forEach(([marketIso, marketIsoData]) => {
        Object.entries(marketIsoData).forEach(([date, dailyData]) => {
          const tabletData = data.tabletEstimates?.[appId]?.[marketIso]?.[date]
          if (dateFrom <= convertDateToTimestamp(date) && convertDateToTimestamp(date) <= dateTo) {
            const combinedTabletData = {
              r: tabletData && tabletData.r ? Number(dailyData.r || 0) + Number(tabletData.r) : Number(dailyData.r || 0),
              d: tabletData && tabletData.d ? Number(dailyData.d || 0) + Number(tabletData.d) : Number(dailyData.d || 0),
            }
            addDataToCombinedEstimates(Number(appId), marketIso, date, combinedTabletData)
          }
        })
      })
    })

    return combinedEstimates
  }, [data, dateFrom, dateTo])

  const allCombinedIosEstimates = useMemo<combinedEstimates>(() => {
    const combinedEstimates: combinedEstimates = {}
    const addDataToCombinedEstimates = (appId: number, marketIso: string, date: string, data: Estimates) => {
      if (!combinedEstimates[appId]) {
        combinedEstimates[appId] = {}
      }

      if (!combinedEstimates[appId][marketIso]) {
        combinedEstimates[appId][marketIso] = []
      }

      combinedEstimates[appId][marketIso].push({ data, date })
      combinedEstimates[appId][marketIso].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    }

    if (!data || !data.phoneEstimates) {
      return combinedEstimates
    }

    Object.entries(data.phoneEstimates).forEach(([appId, phoneEstimates]) => {
      Object.entries(phoneEstimates).forEach(([marketIso, marketIsoData]) => {
        Object.entries(marketIsoData).forEach(([date, dailyData]) => {
          const tabletData = data.tabletEstimates?.[appId]?.[marketIso]?.[date]

          const combinedTabletData = {
            r: tabletData && tabletData.r ? Number(dailyData.r || 0) + Number(tabletData.r) : Number(dailyData.r || 0),
            d: tabletData && tabletData.d ? Number(dailyData.d || 0) + Number(tabletData.d) : Number(dailyData.d || 0),
          }
          addDataToCombinedEstimates(Number(appId), marketIso, date, combinedTabletData)
        })
      })
    })

    return combinedEstimates
  }, [data])

  const gameEstimates: GameEstimates[] = []
  Object.entries(gamesWithMarketIso).forEach(([gameId, marketIsos]) => {
    marketIsos.forEach((marketIso) => {
      const game = games.find((game) => game.id === gameId)
      if (game && combinedIosEstimates[game.appId]?.[marketIso]) {
        gameEstimates.push({
          game,
          marketIso,
          iosEstimates: combinedIosEstimates[game.appId][marketIso],
        })
      }
    })
  })

  const allGameEstimates: GameEstimates[] = []
  Object.entries(gamesWithMarketIso).forEach(([gameId, marketIsos]) => {
    marketIsos.forEach((marketIso) => {
      const game = games.find((game) => game.id === gameId)
      if (game && allCombinedIosEstimates[game.appId]?.[marketIso]) {
        allGameEstimates.push({
          game,
          marketIso,
          iosEstimates: allCombinedIosEstimates[game.appId][marketIso],
        })
      }
    })
  })

  return { tsMin, tsMax, gameEstimates, allGameEstimates }
}

export const useGameEstimatesRollingData = (
  gameEstimates?: GameEstimates[],
  rollingDays: number = 0,
  granularity: GranularityValue = GranularityValue.Day
): GameEstimates[] | undefined => {
  if (!gameEstimates) return

  return gameEstimates.map((gameEstimate) => {
    const modifiedEstimates = gameEstimate.iosEstimates.map((estimate, index) => {
      // If rollingDays is 0, include all data, but respect the granularity
      if (rollingDays === 0) {
        let sliceStartIndex = 0

        if (granularity === GranularityValue.Day) {
          sliceStartIndex = 0 // No slicing for daily, all data is used
        } else if (granularity === GranularityValue.Week) {
          sliceStartIndex = Math.max(0, index + 1 - 7) // Use the last 7 days of data
        } else if (granularity === GranularityValue.Month) {
          sliceStartIndex = Math.max(0, index + 1 - 30) // Use the last 30 days of data
        }

        const slicedEstimates = gameEstimate.iosEstimates
          .slice(sliceStartIndex, index + 1)
          .map((sliceData) => ({ r: sliceData.data.r || 0, d: sliceData.data.d || 0 }))

        return {
          ...estimate,
          data: {
            r: average(slicedEstimates.map((slicedData) => slicedData.r)),
            d: average(slicedEstimates.map((slicedData) => slicedData.d)),
          },
        }
      }

      if (index + 1 < rollingDays) {
        return estimate
      }

      // Determine the slicing start index based on granularity when rollingDays is non-zero
      let sliceStartIndex = 0

      if (granularity === GranularityValue.Day) {
        sliceStartIndex = index + 1 - rollingDays
      } else if (granularity === GranularityValue.Week) {
        sliceStartIndex = Math.max(0, index + 1 - rollingDays * 7) // Assuming 7 days per week
      } else if (granularity === GranularityValue.Month) {
        sliceStartIndex = Math.max(0, index + 1 - rollingDays * 30) // Assuming 30 days per month
      }

      const slicedEstimates = gameEstimate.iosEstimates
        .slice(sliceStartIndex, index + 1)
        .map((sliceData) => ({ r: sliceData.data.r || 0, d: sliceData.data.d || 0 }))

      return {
        ...estimate,
        data: {
          r: average(slicedEstimates.map((slicedData) => slicedData.r)),
          d: average(slicedEstimates.map((slicedData) => slicedData.d)),
        },
      }
    })

    return { ...gameEstimate, iosEstimates: modifiedEstimates }
  })
}

/**
 * Hook that filters given platform types and markets from estimateDate
 */
const usePlatformEstimates = ({
  estimatesList = {},
  platformTypes,
  marketIsos,
}: {
  estimatesList?: EstimatesList
  platformTypes: EstimatePlatformType[]
  marketIsos: string[]
}) => {
  // map platform types to correspond to data fields
  const mappedPlatformTypes = useMemo(() => {
    return platformTypes.map((platformType) => {
      switch (platformType) {
        case EstimatePlatformType.Phone:
          return PlatformType.PhoneEstimates
        case EstimatePlatformType.Tablet:
          return PlatformType.TabletEstimates
        default:
          return PlatformType.PhoneEstimates
      }
    })
  }, [platformTypes])

  // map all estimates by market
  const platformEstimates = useMemo(() => {
    return Object.keys(estimatesList)
      .filter((platformType) => mappedPlatformTypes?.includes(platformType as PlatformType))
      .flatMap((platformType) => {
        const byPlatform = estimatesList[platformType as keyof EstimatesList] || {}

        return Object.keys(byPlatform).flatMap((appId) => {
          const byAppId = byPlatform[appId] || {}

          return Object.entries(byAppId)
            .filter(([marketIso, dailyEstimates]) => marketIsos.map((marketIso) => marketIso.toLowerCase())?.includes(marketIso))
            .reduce((acc, [marketIso, dailyEstimates]) => {
              acc[marketIso] = dailyEstimates

              return acc
            }, {} as MarketEstimates)
        }) as MarketEstimates[]
      })
  }, [estimatesList, mappedPlatformTypes, marketIsos])

  return platformEstimates
}

/**
 * Hook that resolves minimum and maximum timestamps from the total estimate data
 */
const useEstimateMinMaxDates = ({ marketEstimates }: { marketEstimates: MarketEstimates[] }) => {
  return useMemo(() => {
    const refDate = new Date().setHours(0, 0, 0, 0)

    const tsMin = marketEstimates.reduce((acc, platformEstimate) => {
      const min = Object.entries(platformEstimate).reduce((acc, [marketIso, dailyEstimates]) => {
        return Object.keys(dailyEstimates).sort()[0]
      }, '')

      return acc && acc < min ? acc : min
    }, '')

    const tsMax = marketEstimates.reduce((acc, platformEstimate) => {
      const max = Object.entries(platformEstimate).reduce((acc, [marketIso, dailyEstimates]) => {
        return Object.keys(dailyEstimates).sort().reverse()[0]
      }, '')

      return acc && acc > max ? acc : max
    }, '')

    return { tsMin: parse(tsMin, 'yyyy-MM-dd', refDate).getTime(), tsMax: parse(tsMax, 'yyyy-MM-dd', refDate).getTime() }
  }, [marketEstimates])
}

/**
 * Hook that combines the data from all platforms
 */
const useCombinedPlatformEstimates = ({ marketEstimates }: { marketEstimates: MarketEstimates[] }): MarketEstimates => {
  return useMemo(() => {
    return marketEstimates.reduce((acc, marketEstimate) => {
      Object.entries(marketEstimate).forEach(([marketIso, dailyEstimates]) => {
        Object.entries(dailyEstimates).forEach(([date, estimate]) => {
          if (!acc[marketIso]) {
            acc[marketIso] = {}
          }

          const current = acc[marketIso][date]
          if (current) {
            acc[marketIso][date] = {
              d: (current?.d || 0) + (estimate.d || 0),
              r: (current?.r || 0) + (estimate.r || 0),
            }
          } else {
            acc[marketIso][date] = { ...estimate }
          }
        })
      })

      return acc
    }, {} as MarketEstimates)
  }, [marketEstimates])
}

/**
 * Hook that extracts all market iso codes from estimate list
 */
export const useMarketIsosFromEstimates = (estimatesList?: EstimatesList) => {
  return useMemo(() => {
    const marketIsos = Object.entries(estimatesList || {}).flatMap(([platformType, apps]) => {
      return Object.entries(apps).flatMap(([appId, markets]) => {
        return Object.keys(markets)
      })
    })

    return uniq(marketIsos)
  }, [estimatesList])
}

/**
 * Hook that extracts all dates from market estimates and returns them as a sorted array
 */
const useAllMarketDates = ({ marketEstimates }: { marketEstimates: MarketEstimates }) => {
  const allMarketDates = useMemo(() => {
    return Object.entries(marketEstimates).reduce((acc, [marketIso, marketEstimate]) => {
      Object.keys(marketEstimate).forEach((date) => {
        acc.add(date)
      })

      return acc
    }, new Set<string>())
  }, [marketEstimates])

  return useMemo(() => Array.from(allMarketDates).sort(), [allMarketDates])
}

/**
 * Hook that maps estimate data to all datapoint dates - this is needed to fill in the gaps in data (all markets do not have data for all dates which makes a mess with further calculations)
 */
const useFilledEstimates = ({ allMarketDates, marketEstimates }: { allMarketDates: string[]; marketEstimates: MarketEstimates }): MarketEstimates => {
  return useMemo(() => {
    return Object.entries(marketEstimates).reduce((acc, [marketIso, marketEstimate]) => {
      const estimates = allMarketDates.reduce((acc, date) => {
        const estimate = marketEstimate[date]

        if (estimate) {
          acc[date] = {
            r: estimate.r || 0,
            d: estimate.d || 0,
          }
        } else {
          acc[date] = {
            r: 0,
            d: 0,
          }
        }

        return acc
      }, {} as DailyEstimates)

      acc[marketIso] = estimates

      return acc
    }, {} as MarketEstimates)
  }, [allMarketDates, marketEstimates])
}

/**
 * Hook that counts revenue and downloads total values for each market within given date range
 */
const useMarketTotals = ({ marketEstimates, dateFrom, dateTo }: { marketEstimates: MarketEstimates; dateFrom?: number; dateTo?: number }): MarketTotals => {
  return useMemo(() => {
    return Object.entries(marketEstimates).reduce((acc, [marketIso, marketEstimate]) => {
      // include estimates within the selected date range
      const estimatesFilteredByDateRange = Object.entries(marketEstimate).filter(([date, estimate]) =>
        valueIsBetween(new Date(new Date(date).setHours(0, 0, 0, 0)).getTime(), dateFrom, dateTo)
      )

      const revenueTotal = estimatesFilteredByDateRange.reduce((acc, [date, dailyEstimate]) => acc + (dailyEstimate.r || 0), 0)
      const downloadsTotal = estimatesFilteredByDateRange.reduce((acc, [date, dailyEstimate]) => acc + (dailyEstimate.d || 0), 0)

      acc[marketIso] = { revenueTotal, downloadsTotal }

      return acc
    }, {} as MarketTotals)
  }, [marketEstimates, dateFrom, dateTo])
}

/**
 * Hook that calculates rolling averages for market estimates based on given rolling days.
 */
const useRollingAverages = ({ marketEstimates, rollingDays }: { marketEstimates: MarketEstimates; rollingDays: number }): MarketEstimates => {
  return useMemo(() => {
    return Object.keys(marketEstimates).reduce((acc, marketIso) => {
      const dailyEstimates = marketEstimates[marketIso]
      const estimates = Object.keys(dailyEstimates)
        .sort()
        .reduce((acc, date, index, array) => {
          const estimate = dailyEstimates[date]
          const previousEstimate = acc[array[index - 1]]
          if (rollingDays === 0) {
            const revenue = (estimate?.r || 0) + (previousEstimate?.r || 0)
            const downloads = (estimate?.d || 0) + (previousEstimate?.d || 0)

            acc[date] = {
              r: revenue,
              d: downloads,
            }

            return acc
          }

          if (index + 1 < rollingDays) {
            return acc
          } else {
            const estimateDatesSlice = array.slice(index + 1 - rollingDays, index + 1)
            const revenue = average(estimateDatesSlice.map((estimateDate) => dailyEstimates[estimateDate].r || 0))
            const downloads = average(estimateDatesSlice.map((estimateDate) => dailyEstimates[estimateDate].d || 0))

            acc[date] = {
              r: revenue,
              d: downloads,
            }

            return acc
          }
        }, {} as DailyEstimates)

      acc[marketIso] = estimates

      return acc
    }, {} as MarketEstimates)
  }, [marketEstimates, rollingDays])
}

/**
 * Hook that groups market estimates by given granularity
 *  - week will be grouped under beginning of week timestamp (week starts on Monday)
 *  - month will be grouped under beginning of month timestamp
 */
const useGranularityGrouping = ({
  marketEstimates,
  granularity,
  dateFrom,
  dateTo,
}: {
  marketEstimates: MarketEstimates
  granularity: GranularityValue
  dateFrom?: number
  dateTo?: number
}): MarketEstimates => {
  // Determine the last fully completed period (previous week or month) based on granularity
  const now = new Date()
  let lastCompletedPeriodEnd: Date

  if (granularity === GranularityValue.Week) {
    lastCompletedPeriodEnd = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }) // End of the last completed week (Monday-Sunday)
  } else if (granularity === GranularityValue.Month) {
    lastCompletedPeriodEnd = endOfMonth(subMonths(now, 1)) // End of the last completed month
  } else {
    lastCompletedPeriodEnd = now // For other granularities, use the current date
  }

  // Group and combine estimate data points by date granularity
  return useMemo(() => {
    return Object.entries(marketEstimates).reduce(
      (acc, [marketIso, marketEstimate]) => {
        Object.entries(marketEstimate).forEach(([date, estimate]) => {
          const granularityParsedDate = new Date(date)
          const parsedDate = getTimestampByGranularity(granularity, granularityParsedDate).getTime()

          // Check if the parsed date is before or on the last fully completed period
          if (isAfter(lastCompletedPeriodEnd, new Date(parsedDate))) {
            const included = valueIsBetween(parsedDate, dateFrom, dateTo)
            const dateFormattedByGranularity = parsedDate

            if (included) {
              if (!acc[marketIso]) {
                acc[marketIso] = {}
              }

              if (acc[marketIso][dateFormattedByGranularity]) {
                const current = acc[marketIso][dateFormattedByGranularity]
                acc[marketIso][dateFormattedByGranularity] = {
                  d: (current?.d || 0) + (estimate.d || 0),
                  r: (current?.r || 0) + (estimate.r || 0),
                }
              } else {
                acc[marketIso][dateFormattedByGranularity] = { ...estimate }
              }
            }
          }
        })
        return acc
      },

      {} as MarketEstimates
    )
  }, [dateFrom, dateTo, granularity, marketEstimates, lastCompletedPeriodEnd])
}

/**
 * Hook that maps market estimates to market performance estimates and calculates cumulative values if it's requested.
 * Will also put other market key figures in place.
 */
const useMarketPerformanceEstimates = ({
  marketEstimates,
  marketTotals,
  tsMin,
  tsMax,
  isCumulative,
}: {
  marketEstimates: MarketEstimates
  marketTotals: MarketTotals
  tsMin: number
  tsMax: number
  isCumulative?: boolean
}): MarketPerformanceEstimates[] => {
  const marketPerformanceEstimates = useMemo(() => {
    return Object.entries(marketEstimates).reduce((acc, [marketIso, marketEstimate]) => {
      let estimates: PerformanceEstimate[] = Object.entries(marketEstimate).map(([date, dailyEstimates]) => {
        return {
          revenue: dailyEstimates.r || 0,
          downloads: dailyEstimates.d || 0,
          revenueAndDownloadsRatio: (dailyEstimates.r || 0) / (dailyEstimates.d || 0),
          ts: +date,
        }
      })

      if (isCumulative) {
        estimates = estimates.reduce((acc, estimate, index) => {
          const previousRevenue = acc?.[index - 1]?.revenue || 0
          const previousDownloads = acc?.[index - 1]?.downloads || 0
          const cumulativeRevenue = estimate.revenue + previousRevenue
          const cumulativeDownloads = estimate.downloads + previousDownloads
          return [
            ...acc,
            {
              revenue: cumulativeRevenue,
              downloads: cumulativeDownloads,
              revenueAndDownloadsRatio: cumulativeRevenue / cumulativeDownloads,
              ts: +estimate.ts,
            },
          ]
        }, [] as PerformanceEstimate[])
      }

      // rev & dl totals calculated from date range filtered estimates
      const revenueTotal = marketTotals[marketIso].revenueTotal
      const downloadsTotal = marketTotals[marketIso].downloadsTotal

      return [
        ...acc,
        {
          marketIso,
          marketName: getMarketName(marketIso),
          estimates: estimates.sort((a, b) => a.ts - b.ts),
          total: {
            revenue: revenueTotal,
            downloads: downloadsTotal,
            revenueAndDownloadsRatio: +(revenueTotal / downloadsTotal).toFixed(2),
          },
        },
      ]
    }, [] as MarketPerformanceEstimates[])
  }, [marketEstimates, isCumulative, marketTotals])

  return useMemo(() => {
    return marketPerformanceEstimates.map((marketPerformanceEstimate) => ({
      ...marketPerformanceEstimate,
      tsMin,
      tsMax,
    }))
  }, [marketPerformanceEstimates, tsMin, tsMax])
}

/**
 * Hook that prepares all market performance data and counts totals for all markets combined
 */
const useMarketsPerformanceEstimates = ({
  marketPerformanceEstimates,
  tsMin,
  tsMax,
}: {
  marketPerformanceEstimates: MarketPerformanceEstimates[]
  tsMin: number
  tsMax: number
}): MarketsPerformanceEstimates => {
  // calculate total values for all markets and order markets by total revenue
  return useMemo(() => {
    const revenue = marketPerformanceEstimates.reduce((acc, marketPerformanceEstimates) => acc + (marketPerformanceEstimates?.total?.revenue || 0), 0)
    const downloads = marketPerformanceEstimates.reduce((acc, marketPerformanceEstimates) => acc + (marketPerformanceEstimates?.total?.downloads || 0), 0)
    const revenueAndDownloadsRatio = revenue / downloads

    const totalEstimates = marketPerformanceEstimates.reduce((acc, marketPerformanceEstimate) => {
      marketPerformanceEstimate.estimates.forEach((estimate) => {
        if (acc[estimate.ts]) {
          const newRevenue = acc[estimate.ts].revenue + estimate.revenue
          const newDownloads = acc[estimate.ts].downloads + estimate.downloads
          acc[estimate.ts].revenue = newRevenue
          acc[estimate.ts].downloads = newDownloads
          acc[estimate.ts].revenueAndDownloadsRatio = +(newRevenue / newDownloads).toFixed(2)
        } else {
          acc[estimate.ts] = { ...estimate }
        }
      })

      return acc
    }, {} as { [date: number]: PerformanceEstimate })

    return {
      markets: marketPerformanceEstimates.sort((a, b) => (b.total?.revenue || 0) - (a.total?.revenue || 0)),
      estimates: Object.values(totalEstimates),
      total: {
        revenue,
        downloads,
        revenueAndDownloadsRatio,
      },
      tsMin,
      tsMax,
    }
  }, [marketPerformanceEstimates, tsMax, tsMin])
}
