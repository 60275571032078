import { FC, memo, useEffect, useRef } from 'react'

import { Feature } from '../../../../../api/core'
import { Game } from '../../../../game'
import { useSegmentData } from '../../../hooks/marketExplorerHooks'
import { MarketExplorerSegmentConfiguration } from '../../../types/MarketExplorerSegmentConfiguration'
import { MotivationType } from '../../../types/MotivationType'
import { MarketExplorerSegmentCard } from '../MarketExplorerSegmentCard/MarketExplorerSegmentCard'

/**
 * A component for rendering a single market explorer segment. Handles segment data loading and reports it
 * to a parent component
 */

export type SegmentMotivationData = {
  features: Feature[]
  games: Game[]
  motivationSegmentsStatistics: {
    attributeStats: {
      [key in MotivationType]: {
        avg: number
        min: number
        max: number
        median: number
      }
    }
  }
}

export type SegmentQueryReturnType = Partial<ReturnType<typeof useSegmentData>['segmentData']> & {
  complementaryData: ReturnType<typeof useSegmentData>['complementaryData']
  segmentConfiguration: MarketExplorerSegmentConfiguration
}

export type SegmentQueryDataType = SegmentQueryReturnType | null

type MarketExplorerSegmentProps = {
  segment: MarketExplorerSegmentConfiguration
  segmentIndex: number
  onSegmentQueryDataChange: (segmentQueryData: SegmentQueryDataType, segmentIndex: number) => void
  onEdit: (segmentIdex: number) => void
  onRemove: (segmentIndex: number) => void
  onUnmount?: (segmentIndex: number) => void
  onToggleVisibility: (segmentIndex: number) => void
  showToggleVisibility?: boolean
  chartNodesCount?: number
  overrideColorIndex?: number
}

export const MarketExplorerSegment: FC<MarketExplorerSegmentProps> = memo(
  ({
    segment,
    segmentIndex,
    onSegmentQueryDataChange,
    onEdit,
    onRemove,
    onUnmount,
    onToggleVisibility,
    showToggleVisibility,
    chartNodesCount,
    overrideColorIndex,
  }) => {
    const { segmentData, complementaryData } = useSegmentData(segment)
    const willUnmount = useRef(false)

    useEffect(() => {
      onSegmentQueryDataChange(
        segment.visible ? { ...segmentData, complementaryData, segmentConfiguration: segment } : { complementaryData, segmentConfiguration: segment },
        segmentIndex
      )
    }, [segmentData, onSegmentQueryDataChange, segment.visible, segmentIndex, segment, complementaryData, onRemove])

    // if we want to run the cleanup of another effect only when unmounting we need to mark it with a separate effect
    useEffect(() => {
      return () => {
        willUnmount.current = true
      }
    }, [])

    // when the component unmounts we need to erase the segment data
    useEffect(() => {
      return () => {
        if (willUnmount.current) {
          onUnmount && onUnmount(segmentIndex)
        }
      }
    }, [onUnmount, segmentIndex])

    const isLoading = segmentData.isLoading || segmentData.isFetching

    return (
      <MarketExplorerSegmentCard
        segment={segment}
        segmentIndex={segmentIndex}
        onEdit={onEdit}
        onRemove={onRemove}
        onToggleVisibility={onToggleVisibility}
        games={segmentData.data?.games || []}
        isLoading={isLoading}
        complementaryData={complementaryData}
        showToggleVisibility={showToggleVisibility}
        chartNodesCount={chartNodesCount}
        overrideColorIndex={overrideColorIndex}
      />
    )
  }
)
