import { FC, memo, useCallback, useEffect, useState } from 'react'

import GameSubgenrePicker from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { useSubgenrePicker } from '../../../../hooks/useSubgenrePicker'
import { Filters } from '../../types/Filters'
import { TimeSinceReleasedSelector } from '../TimeSinceReleasedSelector/TimeSinceReleasedSelector'

type SoftLaunchFiltersProps = {
  onChange: (value: Filters) => void
}

/**
 * Container component for Soft Launch filters
 */
export const SoftLaunchFilters: FC<SoftLaunchFiltersProps> = memo(({ onChange }) => {
  const { subgenres, handleSubgenresChange } = useSubgenrePicker()
  const [daysSinceReleased, setDaysSinceReleased] = useState<string>('-1')

  const handleTimeSinceReleasedChange = useCallback((daysSinceReleased: string) => {
    setDaysSinceReleased(daysSinceReleased)
  }, [])

  useEffect(() => {
    onChange({ subgenres, daysSinceReleased })
  }, [subgenres, daysSinceReleased, onChange])

  return (
    <>
      <GameSubgenrePicker selectionChanged={handleSubgenresChange} selectedSubgenres={subgenres} showSubgenreDefinitionBtn></GameSubgenrePicker>
      <TimeSinceReleasedSelector onChange={handleTimeSinceReleasedChange} value={daysSinceReleased} />
    </>
  )
})
