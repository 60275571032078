export enum ChatAnswerNotKnown {
  English = `Unfortunately I don't know the answer to your question. I am sorry.`,
  Chinese = `---`,
  Japanese = `---`,
}

export interface AIAnalystAnswer {
  answer: string
  prompt: string
  source: string
  timestamp: number // Store timestamp as a number (milliseconds since epoch)
}

export const createAIAnalystAnswer = (data: AIAnalystAnswer): AIAnalystAnswer => ({
  ...data,
  timestamp: Date.now(),
})
