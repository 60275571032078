import { FC, useRef } from 'react'
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import { Typography } from '@mui/material'

import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import analyticsService from '../../../../../../services/AnalyticsService'
import { useCurrentMarket } from '../../../../../markets'
import { onlyMixpanelTrackingServicesToExclude } from '../../../../hooks/useLiveEventsTrackerAnalyticsEvents'
import { GameVersion } from '../../../../types/GameVersion'
import { GameVersionTimelineItem } from '../../../../types/LiveEvents'

export const LiveEventsCalendarGameVersionItem: FC<ReactCalendarItemRendererProps<GameVersionTimelineItem>> = ({ item, itemContext, getItemProps }) => {
  const itemRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()
  const { currentMarketIso } = useCurrentMarket()

  const openUpdate = () => {
    const data = {
      version: item.version.version,
      gameAppId: item.version.appId,
    }

    analyticsService.trackEvent('Live Events Tracker: Opened Game Update', {
      data,
      serviceToExclude: onlyMixpanelTrackingServicesToExclude,
    })

    navigate({
      pathname: `${location.pathname}/gameupdate/${item.version.gameId}/${item.version.version}/${currentMarketIso}`,
      search: location.search,
    })
  }

  return (
    <GRTooltip content={<TooltipContent version={item.version} />} anchorRef={itemRef}>
      <div
        {...getItemProps({
          style: {
            background: item.version.featureChanges || item.version.updatedFeatures ? '#bd65cf' : '#777',
            color: 'white',
            border: `1px solid #fff`,
            borderRadius: 10,
            overflow: 'hidden',
          },
        })}
        ref={itemRef}
      >
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'relative',
            bottom: '1px',
            textAlign: 'center',
            padding: '0px 4px',
          }}
          onClick={openUpdate}
        >
          {item.version.version}
        </div>
      </div>
    </GRTooltip>
  )
}

const TooltipContent: FC<{ version: GameVersion }> = ({ version }) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h3" mb={1} textAlign="center">
        <Trans i18nKey={`common:version`} />
      </Typography>
      <Typography variant="body1" textAlign="center">
        {version.version}
      </Typography>
      {(version.featureChanges || version.updatedFeatures) && <div style={{ marginTop: '10px' }}>{t('live-events:updates_in_feature_set')}</div>}
    </>
  )
}
