import { FC, ChangeEvent } from 'react'

import { Masonry } from '@mui/lab'
import { Card, CardHeader, Divider, CardContent, FormControlLabel, Checkbox, Skeleton } from '@mui/material'

import { useLiveEventsSecondaryTags, useLiveEventsSecondaryTagGroups } from '../../../../../features/live-events/hooks/liveEventsSecondaryTaxonomy'

type LiveEventSecondaryTaxonomySelectorProps = {
  onChange: (tagIds: string[]) => void
  value?: string[]
}

export const LiveEventSecondaryTaxonomySelector: FC<LiveEventSecondaryTaxonomySelectorProps> = ({ onChange, value = [] }) => {
  const liveEventsSecondaryTagsQuery = useLiveEventsSecondaryTags()
  const liveEventsSecondaryTagGroupsQuery = useLiveEventsSecondaryTagGroups()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (value.includes(e.target.value)) {
      onChange(value.filter((tagId) => tagId !== e.target.value) || [])
    } else {
      onChange([...value, e.target.value])
    }
  }

  const tagGroups = liveEventsSecondaryTagGroupsQuery.data || []
  const isLoading = liveEventsSecondaryTagGroupsQuery.isFetching || liveEventsSecondaryTagsQuery.isFetching

  return (
    <Masonry columns={{ xs: 2, sm: 3, md: 3, lg: 4, xl: 5 }} spacing={1}>
      {isLoading
        ? Array.from(Array(5).keys()).map((_, index) => (
            <Card key={index} elevation={0} variant="outlined">
              <CardHeader title={<Skeleton width={'75%'}></Skeleton>}></CardHeader>
              <CardContent>
                {Array.from(Array(7).keys()).map((_, index) => (
                  <Skeleton key={index} width="85%" />
                ))}
              </CardContent>
            </Card>
          ))
        : tagGroups.map((tagGroup) => {
            return (
              <Card key={tagGroup.id} elevation={0} variant="outlined">
                <CardHeader title={tagGroup.name} sx={{ py: 2, fontSize: '0.875rem', fontWeight: 700 }} disableTypography></CardHeader>
                <Divider />
                <CardContent sx={{ py: 1 }}>
                  {tagGroup.tags.map((tagId, index) => {
                    const tag = liveEventsSecondaryTagsQuery.data?.find((tag) => tag.id === tagId)
                    return (
                      <FormControlLabel
                        key={tagId}
                        disableTypography
                        control={
                          <Checkbox
                            size="small"
                            onChange={handleChange}
                            value={tagId}
                            checked={!!value.find((tagId) => tagId === tag?.id)}
                            sx={{ py: 0, alignSelf: 'flex-start' }}
                          />
                        }
                        label={tag?.name || ''}
                        sx={{ display: 'flex', fontSize: '0.875rem', pb: 1 }}
                      />
                    )
                  })}
                </CardContent>
              </Card>
            )
          })}
    </Masonry>
  )
}
