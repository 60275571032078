import { FC, ReactNode } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Add } from '@mui/icons-material'
import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material'

import { AnalystsSelectMenu } from '../AnalystsSelectMenu/AnalystsSelectMenu'

type TrackedGamesFiltersProps = {
  form: UseFormReturn<TrackedGamesFilterFields>
  children?: ReactNode
}

export type TrackedGamesFilterFields = {
  analyst: string
  pinnedGamesOnly: boolean
  gameNameOrPublisherSearchString: string
}

export const TrackedGamesFilters: FC<TrackedGamesFiltersProps> = ({ form, children }) => {
  const { t } = useTranslation()
  const { control } = form

  return (
    <form>
      <Grid container spacing={2} wrap="wrap">
        <Grid item>
          <Controller
            name="analyst"
            control={control}
            render={({ field }) => {
              return <AnalystsSelectMenu value={field.value} useAll={true} onChange={field.onChange} />
            }}
          />
        </Grid>
        <Grid item>
          <Controller
            name="pinnedGamesOnly"
            control={control}
            render={({ field }) => {
              return (
                <FormControlLabel
                  control={<Checkbox size="small" checked={field.value} onChange={field.onChange} />}
                  label={t('internal-live-events:pinned_games_only')}
                />
              )
            }}
          />
        </Grid>
        <Grid item sx={{ ml: 'auto' }}>
          <Button component={Link} to={`gamesearch`} startIcon={<Add />} variant="contained">
            <Trans i18nKey="internal-live-events:track_new_game" />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="gameNameOrPublisherSearchString"
            control={control}
            render={({ field }) => {
              return (
                <TextField
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  variant="outlined"
                  size="small"
                  label={t('internal-live-events:search_by_game')}
                  fullWidth
                />
              )
            }}
          />
        </Grid>
      </Grid>
    </form>
  )
}
