import { ElementType, ReactNode } from 'react'

import { NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material'
import { IconButtonProps, Grid, IconButton } from '@mui/material'

import styles from './GRCarousel.module.scss'

type GRCarouselProps<ButtonComponentType extends ElementType<any>> = {
  previousButtonProps: IconButtonProps<ButtonComponentType>
  nextButtonProps: IconButtonProps<ButtonComponentType>
  children?: ReactNode
}

/**
 * Component for displaying a set of content e.g. images one at a time with navigation buttons
 */
export const GRCarousel = <ButtonComponentType extends ElementType<any>>({
  previousButtonProps,
  nextButtonProps,
  children,
}: GRCarouselProps<ButtonComponentType>) => {
  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ height: '100%' }}>
      <Grid item xs={1} textAlign="center">
        <IconButton className={styles.naviButton} {...previousButtonProps}>
          <NavigateBeforeRounded />
        </IconButton>
      </Grid>
      <Grid item xs={10} className={styles.contentContainer}>
        {children}
      </Grid>
      <Grid item xs={1} textAlign="center">
        <IconButton className={styles.naviButton} {...nextButtonProps}>
          <NavigateNextRounded />
        </IconButton>
      </Grid>
    </Grid>
  )
}
