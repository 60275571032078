import { EModalType, TModalParams } from '../types/modalTypes'

export const LiveEventModalParams = ['trackedGameId', 'liveEventTypeId', 'liveEventId', 'tab', 'screenshotIndex'] as const
export const LiveEventAnalystNotesModalParams = ['trackedGameId', 'commentId'] as const
export const LiveEventAnalystOverviewModalParams = ['trackedGameId'] as const
export const LiveEventStatisticsModalParams = ['trackedGameIds', 'liveEventTypeId', 'tab'] as const

export const modalParamsByModalType: TModalParams = {
  [EModalType.LIVE_EVENT]: LiveEventModalParams,
  [EModalType.LIVE_EVENT_ANALYST_NOTES]: LiveEventAnalystNotesModalParams,
  [EModalType.LIVE_EVENT_ANALYST_OVERVIEW]: LiveEventAnalystOverviewModalParams,
  [EModalType.LIVE_EVENT_STATISTICS]: LiveEventStatisticsModalParams,
}

export const modalTypeParamKey = 'modalType'
export const modalParamPrefix = 'm_'
export const modalParamSeparator = ','
