import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { LockedDataIndicator } from '../../../../components/LockedDataIndicator/LockedDataIndicator'
import Powerscore from '../../../../components/Powerscore/Powerscore'
import { TrendIndicator, TrendIndicatorType } from '../../../../components/TrendIndicator/TrendIndicator'

type GameUpdatePowerscoreProps = {
  value?: number
  valueChange?: number
  isUnlocked?: boolean
}

export const GameUpdatePowerscore: FC<GameUpdatePowerscoreProps> = ({ value, valueChange = 0, isUnlocked }) => {
  const { t } = useTranslation()

  if (!isUnlocked) {
    return <LockedDataIndicator />
  }

  return value ? (
    <Grid container columnGap={2} justifyContent="space-evenly">
      <Grid item>
        <GRTooltip content={t('common:tooltip_gps_description')}>
          <Powerscore powerscore={value} fontSize={11} size={24} />
        </GRTooltip>
      </Grid>
      <Grid item>
        <TrendIndicator value={valueChange} type={TrendIndicatorType.Value} direction maximumFractionDigits={1} />
      </Grid>
    </Grid>
  ) : null
}
