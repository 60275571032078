import { SET_SEARCH_TERM, SET_SUBGENRES_MAP } from '../actions/searchActions'

interface Action {
  type: string
  payload: any
}

const initialState = {
  searchTerm: '',
  subgenres: {},
}

const searchReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      }
    case SET_SUBGENRES_MAP:
      return {
        ...state,
        subgenres: action.payload,
      }
    case 'RESET_ALL_SEARCH':
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export default searchReducer
