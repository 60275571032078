import { FC, useRef } from 'react'
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline'
import { useTranslation } from 'react-i18next'

import { FiberNew, Paid } from '@mui/icons-material'
import { Grid } from '@mui/material'

import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import { GameTopIAPsByDate } from '../../../../types/GameTopIAPsByDate'
import { TopIAPTimelineItem } from '../../../../types/LiveEvents'

export const LiveEventsCalendarTopIAPItem: FC<ReactCalendarItemRendererProps<TopIAPTimelineItem>> = ({ item, itemContext, getItemProps }) => {
  const itemRef = useRef(null)
  const hasIAPChanges = item.gameTopIAPByDate.previousIAPs && item.gameTopIAPByDate.previousIAPs.length > 0 && item.gameTopIAPByDate.hasIAPChanges

  return (
    <GRTooltip disableMaxWidth={true} content={<TooltipContent gameTopIAPByDate={item.gameTopIAPByDate} />} severity="success" anchorRef={itemRef}>
      <div
        {...getItemProps({
          style: {
            background: 'transparent',
            color: hasIAPChanges ? '#38a73e' : '#888',
            border: `1px solid transparent`,
            borderRadius: 10,
            cursor: 'default',
          },
        })}
        ref={itemRef}
      >
        <div
          style={{
            height: itemContext.dimensions.height,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'relative',
            bottom: '1px',
            textAlign: 'center',
            padding: '0px 4px',
          }}
        >
          {hasIAPChanges ? (
            <Paid style={{ fontSize: '25px', verticalAlign: 'middle', position: 'relative', top: '-2px' }} />
          ) : (
            <Paid style={{ fontSize: '20px', verticalAlign: 'middle', position: 'relative', top: '-2px' }} />
          )}
        </div>
      </div>
    </GRTooltip>
  )
}

const TooltipContent: FC<{ gameTopIAPByDate: GameTopIAPsByDate }> = ({ gameTopIAPByDate }) => {
  const { t } = useTranslation()

  return (
    <>
      <div style={{ marginBottom: '10px', fontSize: '16px' }}>
        <strong>{t('live-events:top_iaps_calendar_group_name')}</strong>
      </div>
      {gameTopIAPByDate.currentIAPs.map((topIAP, index) => {
        const currentIAPChange = gameTopIAPByDate.IAPChanges[index] ? gameTopIAPByDate.IAPChanges[index].change : 0
        const isNew =
          gameTopIAPByDate.IAPChanges[index] && gameTopIAPByDate.previousIAPs && gameTopIAPByDate.previousIAPs.length > 0
            ? gameTopIAPByDate.IAPChanges[index].isNew
            : false

        return (
          <div key={index} style={{ marginBottom: '3px', fontSize: '14px', borderTop: '1px solid rgba(0,0,0,0.1)', padding: '3px 0px 1px' }}>
            <Grid container spacing={3} alignItems="flex-start">
              <Grid item xs={1}>
                {index + 1}.{' '}
              </Grid>
              <Grid item xs={7} style={{ wordBreak: 'break-word' }}>
                <strong>{topIAP.name}</strong>
              </Grid>
              <Grid item xs={2}>
                <strong>{topIAP.price}</strong>
              </Grid>
              {currentIAPChange !== 0 && (
                <Grid item xs={2} style={{ textAlign: 'right', fontSize: '12px' }}>
                  <strong style={{ color: currentIAPChange > 0 ? '#38a73e' : '#b13d37' }}>
                    ({currentIAPChange > 0 ? `+${currentIAPChange}` : currentIAPChange})
                  </strong>
                </Grid>
              )}

              {isNew && (
                <Grid item xs={2} style={{ textAlign: 'right', fontSize: '12px', color: '#38a73e' }}>
                  <FiberNew style={{ fontSize: '18px', marginBottom: '-4px' }} />
                </Grid>
              )}
            </Grid>
          </div>
        )
      })}
    </>
  )
}
