import { FC, ReactNode } from 'react'

import { SkipNext, SkipPrevious } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import { startOfNextDay, startOfPreviousDay } from '../../helpers/date'
import { ActionButton } from '../../internal/components/ActionButton/ActionButton'

type DateSwitcherProps = DatePickerProps<Date> & {
  onChange?: (date: Date) => void
  children?: ReactNode
}

export const DateSwitcher: FC<DateSwitcherProps> = ({ children, ...datePickerProps }) => {
  const { onChange, value } = datePickerProps

  const handleSkipPrevious = () => {
    if (value && onChange) {
      const previousDay = startOfPreviousDay(value)
      onChange(new Date(previousDay))
    }
  }

  const handleSkipNext = () => {
    if (value && onChange) {
      const nextDay = startOfNextDay(value)
      onChange(new Date(nextDay))
    }
  }

  return (
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      <Grid item>
        <ActionButton onClick={handleSkipPrevious}>
          <SkipPrevious fontSize="small" />
        </ActionButton>
      </Grid>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            {...datePickerProps}
            slotProps={{
              textField: { size: 'small' },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item>
        <ActionButton onClick={handleSkipNext}>
          <SkipNext fontSize="small" />
        </ActionButton>
      </Grid>
    </Grid>
  )
}
