import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import BusinessIcon from '@mui/icons-material/Business'
import { Box, Card, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popper, Skeleton } from '@mui/material'

import { useSearchArtistQuery } from '../../../../api/core'
import { SearchInputWithDebounce } from '../../../../components/SearchInputWithDebounce/SearchInputWithDebounce'
import './ArtistSearchInput.scss'

interface ArtistSearchInputProps {
  onArtistClick?: (artist: string) => void
}

const ArtistSearchInput: React.FC<ArtistSearchInputProps> = ({ onArtistClick }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [inputFocus, setInputFocus] = useState(false)
  const { t } = useTranslation()
  const inputContainerRef = useRef(null)
  const blurTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const { data: searchResult, isFetching: searchIsFetching } = useSearchArtistQuery({ term: searchQuery }, { skip: searchQuery.length < 2 })

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, artist: string) => {
    if (artist && onArtistClick) {
      onArtistClick(artist)
    }
  }

  return (
    <div className="ArtistSearchInput">
      <div className="ArtistSearchInput__inputContainer" ref={inputContainerRef}>
        <SearchInputWithDebounce
          fieldText={t('common:search_organization')}
          onDebounce={(searchText: string) => setSearchQuery(searchText)}
          onFocus={() => setInputFocus(true)}
          onBlur={() => {
            blurTimeoutRef.current = setTimeout(() => {
              setInputFocus(false)
            }, 100)
          }}
        />
      </div>

      <Popper className="ArtistSearchInput__popper" placement="right-start" open={searchQuery.length > 1 && inputFocus} anchorEl={inputContainerRef.current}>
        <Card elevation={3}>
          <div className="ArtistSearchInput__popper__scroller">
            <List>
              {searchIsFetching && (
                <>
                  <ListItem sx={{ width: '300px' }}>
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="100%" />
                    </ListItemText>
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                  <ListItem>
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="100%" />
                    </ListItemText>
                  </ListItem>
                  <Divider variant="fullWidth" component="li" />
                  <ListItem>
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="50%" />
                    </ListItemText>
                  </ListItem>
                </>
              )}

              {!searchIsFetching && searchResult && searchResult.length === 0 && (
                <ListItem>
                  <ListItemText>{t('compare:no_results_found')}</ListItemText>
                </ListItem>
              )}

              {!searchIsFetching && searchResult && searchResult.length > 0 && (
                <>
                  {searchResult?.map((artist, index) => (
                    <Box key={`listitem-${index}`}>
                      <ListItemButton className="ArtistSearchInput__popper__listItem" onClick={(event) => handleListItemClick(event, artist)}>
                        <ListItemIcon>
                          <BusinessIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText>{artist}</ListItemText>
                      </ListItemButton>
                      {index < searchResult.length - 1 && <Divider key={`divider-${index}`} variant="fullWidth" component="li" />}
                    </Box>
                  ))}
                </>
              )}
            </List>
          </div>
        </Card>
      </Popper>
    </div>
  )
}

export default ArtistSearchInput
