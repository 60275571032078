import { format } from 'date-fns'
import { FC, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Checkbox, Divider, FormControlLabel, Grid } from '@mui/material'

import GRCircularProgress from '../../../components/GRCircularProgress/GRCircularProgress'
import { GameCardContent } from '../../../features/game/components/GameCard/GameCard'
import { displaySnackBar } from '../../../features/snackbar'
import { useAppDispatch } from '../../../hooks/storeHooks'
import usePage from '../../../hooks/usePage'
import { useRequiredParams } from '../../../hooks/useRequiredParams'
import pageService from '../../../services/PageService'
import { useGetTrackedGameQuery, useUpdateTrackedGameReviewMutation } from '../../api/internal'
import { BackButton } from '../../components/BackButton/BackButton'
import { InputTranslator } from '../../components/InputTranslator/InputTranslator'
import { AnalystsSelectMenu } from '../../features/live-events-tracking/components/AnalystsSelectMenu/AnalystsSelectMenu'
import { TrackedGameLinkedReportTable } from '../../features/live-events-tracking/components/TrackedGameLinkedReportsTable.tsx/TrackedGameLinkedReportTable'
import { useLiveEventsTrackingEventsSearchParams } from '../../features/live-events-tracking/hooks/useLiveEventsTrackingEventsSearchParams'
import { InputTranslatorType } from '../../types/InputTranslatorType'
import { ContentLanguageTranslation } from '../../types/InternalAnalystComment'
import { InternalEventOverview } from '../../types/InternalEventOverview'

type TrackedGameEventsOverviewPageProps = {}

export const TrackedGameEventsOverviewPage: FC<TrackedGameEventsOverviewPageProps> = () => {
  usePage(pageService.getInternalPageWithId('internal-tracked-game-events-overview'))
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { trackedGameId } = useRequiredParams<{ trackedGameId: string }>()
  const { parsedParams } = useLiveEventsTrackingEventsSearchParams()

  const getTrackedGameQuery = useGetTrackedGameQuery(trackedGameId, { skip: !trackedGameId })
  const [postEventOverview] = useUpdateTrackedGameReviewMutation()
  const dateParam = format(parsedParams.date, 'yyyy-MM-dd')

  const defaultEventValues = useMemo(
    () => ({
      gameId: getTrackedGameQuery.data?.game.id,
      review: {
        analystId: getTrackedGameQuery.data?.review?.analystId || '',
        content: {
          en: getTrackedGameQuery.data?.review?.content.en || '',
          ja: getTrackedGameQuery.data?.review?.content.ja || '',
          zh: getTrackedGameQuery.data?.review?.content.zh || '',
        },
        published: getTrackedGameQuery.data?.review?.published || false,
        reportIds: getTrackedGameQuery.data?.review?.reportIds || [],
      },
    }),
    [
      getTrackedGameQuery.data?.game.id,
      getTrackedGameQuery.data?.review?.analystId,
      getTrackedGameQuery.data?.review?.content.en,
      getTrackedGameQuery.data?.review?.content.ja,
      getTrackedGameQuery.data?.review?.content.zh,
      getTrackedGameQuery.data?.review?.published,
      getTrackedGameQuery.data?.review?.reportIds,
    ]
  )

  const form = useForm<InternalEventOverview>({
    defaultValues: defaultEventValues,
  })

  const {
    reset,
    control,
    getValues,
    formState: { isDirty },
  } = form

  useEffect(() => {
    reset(defaultEventValues)
  }, [defaultEventValues, reset, getTrackedGameQuery])

  const isLoading = getTrackedGameQuery.isFetching

  const handleSave = () => {
    postEventOverview(getValues())
      .then(() =>
        dispatch(
          displaySnackBar({
            message: `${t('internal-live-events:saved_live_events_overview_for')} ${getTrackedGameQuery.data?.game.name}`,
            severity: 'success',
            open: true,
          })
        )
      )
      .finally(() => navigate(`/internal/live-events-tracking/events/${trackedGameId}?date=${dateParam}`))
  }

  return isLoading ? (
    <GRCircularProgress />
  ) : (
    <>
      <BackButton url={`/internal/live-events-tracking/events/${trackedGameId}?date=${dateParam}`} />
      <Grid container>
        <Grid item>{getTrackedGameQuery.data?.game && <GameCardContent game={getTrackedGameQuery.data?.game} disableGameLink />}</Grid>
      </Grid>
      <Divider sx={{ my: 2 }} />
      <form>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Controller
              name="review.analystId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return <AnalystsSelectMenu value={field.value} useAll={false} onChange={field.onChange} />
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              name="review.content"
              control={control}
              render={({ field }) => (
                <InputTranslator
                  value={field.value as ContentLanguageTranslation}
                  onChange={field.onChange}
                  inputType={InputTranslatorType.WYSIWYG}
                  dialogTitle={`${getTrackedGameQuery.data?.game.name} ${t('internal-live-events:live_events_overview')} `}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Divider sx={{ my: 2 }} />
            <TrackedGameLinkedReportTable form={form} />
          </Grid>
          <Grid item>
            <Controller
              name="review.published"
              control={control}
              render={({ field }) => {
                return (
                  <FormControlLabel
                    control={<Checkbox size="small" checked={field.value} onChange={field.onChange} />}
                    label={t('internal-live-events:published')}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2} direction="row" justifyContent="flex-end">
          <Grid item>
            <Button
              variant="text"
              component={Link}
              to={`/internal/live-events-tracking/events/${trackedGameId}?date=${dateParam}`}
              size="small"
              color="primary"
            >
              {t('internal-common:cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                handleSave()
              }}
              variant="contained"
              color="primary"
              disabled={!isDirty}
            >
              {t('internal-common:save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  )
}
