import React, { ReactNode } from 'react'

import { Box } from '@mui/material'

import { SingleSubgenreSelection } from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import { TabOption } from '../../types/feature-modal-tabs'
import type { ModalFeature } from '../FeatureModal/FeatureModal'
import './FeatureLabelLink.scss'

export type Choice = {
  choiceLegacyId?: number
  name: string
  originalChoiceName?: string
}

type FeatureLinkProps = {
  children?: ReactNode
  choice?: Choice
  feature: ModalFeature
  initialTab?: TabOption
  gameIds?: string[]
  forceShowFeatureInList?: boolean //force to show features in list despite showInlists props
  selectedSubgenre?: SingleSubgenreSelection
}

/**
 * A link that opens the feature modal.
 *
 * @param children to render as the link
 * @param choice optional
 * @param feature to show in modal
 * @param initialTab optional, defaults to 'definition'
 */

export const FeatureLink: React.FC<FeatureLinkProps> = ({
  children,
  choice,
  feature,
  initialTab = 'definition',
  gameIds,
  forceShowFeatureInList,
  selectedSubgenre,
}) => {
  const { setSearchParams } = useSearchParams()
  return (
    <Box
      className="FeatureLabelLink__Link"
      onClick={() =>
        setSearchParams((current) => ({
          ...current,
          featureModalFeature: feature.featureLegacyId,
          featureModalChoice: choice?.choiceLegacyId,
          featureModalTab: initialTab,
          featureModalCategory: selectedSubgenre?.conventionalCategoryId,
          featureModalGenre: selectedSubgenre?.conventionalGenreId,
          featureModalSubgenre: selectedSubgenre?.conventionalSubgenreId,
        }))
      }
    >
      {children}
    </Box>
  )
}
