import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useGetCurrentUserOrganizationQuery, useGetCurrentUserOrganizationUsersQuery } from '../../../api/account'
import { useGetUserProfileQuery, useGetUserSettingsQuery } from '../../../api/combined'
import type { Game } from '../../game'
import { useOwnGamesCount } from '../../game/hooks/gameHooks'
import { AnalysisSuggestionStatus } from '../../game/types/AnalysisSuggestionStatus'
import { selectAllMarketIsos } from '../../markets/selectors/selectors'
import { RoleEnum } from '../types/RoleEnum'
import { useRoleCheck } from './roleHooks'
import { useCurrentUserLanguage } from './useCurrentUserLanguage'
import { useMaxGameSlotsCount } from './useMaxGameSlotsCount'

export function useCurrentUserOrganizationUsers() {
  const { data: users, isLoading, error } = useGetCurrentUserOrganizationUsersQuery()
  const sortedUsers = users ? [...users].sort((a, b) => (a.email < b.email ? -1 : 1)) : []

  return { users: sortedUsers, usersLoading: isLoading, usersError: error }
}

/**
 * Checks if user language is Asian
 */
export const useIsUserLanguageAsian = (): boolean => {
  const userLanguage = useCurrentUserLanguage()
  return userLanguage === 'zh' || userLanguage === 'ja'
}

/**
 * Check if user language is Japanese
 */
export const useIsUserLanguageJapanese = (): boolean => {
  const userLanguage = useCurrentUserLanguage()
  return userLanguage === 'ja'
}

/**
 * Tells how many games can be followed at the same time
 */
export const useMaxFollowedGamesCount = () => {
  const { data: currentUser } = useGetUserProfileQuery()
  return currentUser?.organization.maxFollowedGames || 0
}

/**
 * Tells if user can follow or unfollow more games
 */
export const useMonthlyFollowedGamesStatus = () => {
  const availableFollowedGamesCountLeftThisMonth = useAvailableFollowedGamesCountLeftThisMonth()
  const availableFollowedGamesSlotCount = useAvailableFollowedGamesSlotCount()
  const hasUnlimitedFollowedGames = useRoleCheck(RoleEnum.follow_games_unlimited)
  const canUserFollow = availableFollowedGamesCountLeftThisMonth > 0 && availableFollowedGamesSlotCount ? true : false
  const canUserUnfollow = availableFollowedGamesCountLeftThisMonth > 0 ? true : false

  return {
    canUserFollow: hasUnlimitedFollowedGames || canUserFollow,
    canUserUnfollow: hasUnlimitedFollowedGames || canUserUnfollow,
  }
}

/**
 * Tells how many games can still be followed during the ongoing time period (month)
 */
export const useAvailableFollowedGamesCountLeftThisMonth = () => {
  const maxFollowedGamesCount = useMaxFollowedGamesCount()
  const { data: currentUser } = useGetUserProfileQuery()
  const hasUnlimitedFollowedGames = useRoleCheck(RoleEnum.follow_games_unlimited)

  if (hasUnlimitedFollowedGames) {
    return 1
  } else {
    return currentUser ? maxFollowedGamesCount - currentUser.organization.followedGamesMonthCount : 0
  }
}

/**
 * Resolves analysis suggestion status for given gamein given market
 */
export const useAnalysisSuggestionStatus = (game: Game, marketIso: string): [AnalysisSuggestionStatus, Dispatch<SetStateAction<AnalysisSuggestionStatus>>] => {
  const { data: userSettings } = useGetUserSettingsQuery()

  const initialStatus = useMemo(() => {
    if (userSettings?.analysisRequests?.[game.appId]) {
      if (!game.lastAnalyzedVersions[marketIso]) {
        return AnalysisSuggestionStatus.sent
      } else {
        return game.lastAnalyzedVersions[marketIso] === userSettings.analysisRequests[game.appId]
          ? AnalysisSuggestionStatus.sent
          : AnalysisSuggestionStatus.waiting
      }
    } else {
      return AnalysisSuggestionStatus.waiting
    }
  }, [game, marketIso, userSettings])

  const [analysisSuggestionStatus, setAnalysisSuggestionStatus] = useState<AnalysisSuggestionStatus>(initialStatus)

  return [analysisSuggestionStatus, setAnalysisSuggestionStatus]
}

const useAvailableFollowedGamesSlotCount = () => {
  const maxFollowedGamesCount = useMaxFollowedGamesCount()
  const { data: currentUser } = useGetUserProfileQuery()
  const hasUnlimitedFollowedGames = useRoleCheck(RoleEnum.follow_games_unlimited)

  if (hasUnlimitedFollowedGames) {
    return 1
  } else {
    return currentUser ? maxFollowedGamesCount - currentUser.organization.followedGames.length : 0
  }
}

/**
 * Tells how many game slots still available
 */
export const useAvailableGameSlot = () => {
  const ownGamesCount = useOwnGamesCount()
  const maxGameSlotCount = useMaxGameSlotsCount()
  const hasUnlimitedGameSlot = useRoleCheck(RoleEnum.game_slots_unlimited)

  const availableGameSlotCount = useMemo(() => {
    if (hasUnlimitedGameSlot) {
      return 1
    }

    return !!ownGamesCount ? maxGameSlotCount - ownGamesCount : -1
  }, [maxGameSlotCount, ownGamesCount, hasUnlimitedGameSlot])

  return availableGameSlotCount
}

/**
 * Resolves an array of market isos available to current user according to user roles
 */
export const useAvailableMarketIsos = () => {
  const userLanguage = useCurrentUserLanguage()
  const allMarketIsos = useSelector(selectAllMarketIsos(userLanguage))
  const { data: currentUser } = useGetUserProfileQuery()

  return currentUser?.roles
    .filter((role) => role.includes('market_'))
    .map((role) => role.split('_')[1])
    .filter((marketIso) => allMarketIsos.includes(marketIso))
}

/**
 * Checks if current user is on a free plan
 * @returns true if user is on free plan
 */
export const useIsOnFreePlan = () => {
  const { data: organization } = useGetCurrentUserOrganizationQuery()

  if (!organization) return false

  const freePlan = organization.packages.find(({ type, tier }) => type === 1 && tier === 0)

  return !!freePlan
}

/**
 * Returns current user's own games order
 * @returns map of game ids to their order
 */
export const useOwnGamesOrder = () => {
  const { data: currentUser } = useGetUserProfileQuery()
  return currentUser?.ownGamesOrder || []
}

/**
 * Return current user's name initials
 * @returns string of initials
 */
export const useUserInitials = () => {
  const { data: currentUser } = useGetUserProfileQuery()

  const initials = useMemo(() => {
    if (!currentUser) {
      return ''
    }
    const { firstName, lastName } = currentUser
    // check if firstName or lastName is null or undefined
    if (!firstName || !lastName) {
      return ''
    }

    return `${firstName[0]}${lastName[0]}`
  }, [currentUser])

  return initials
}
