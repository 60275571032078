import { TooltipCallbacks } from 'chart.js'
import { format } from 'date-fns'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Grid } from '@mui/material'

import { ExportDataButton } from '../../../../components/ExportDataButton/ExportDataButton'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { TimelineChart, TimeScaleConfig } from '../../../../components/TimelineChart/TimelineChart'
import utilsService from '../../../../services/UtilsService'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { MarketsPerformanceEstimates, PerformanceEstimate } from '../../../estimates'
import { ChartDataFormat } from '../../../update-history/types/types'
import { getDataTypeName, isDataTypeRevenueAndDownloadsRatio, isPlotTypeStacked, resolveTimeunitByGranularity } from '../../helpers/helpers'
import { DataTypeValue, GranularityValue } from '../../types/Filters'
import { PlotType } from '../../types/PlotType'
import { PlotTypeSelector } from '../PlotTypeSelector/PlotTypeSelector'

type GameRevenueAndDownloadsChartProps = {
  estimates: MarketsPerformanceEstimates
  dataType: DataTypeValue
  granularity: GranularityValue
  onPlotTypeChange: (value: PlotType) => void
  onExportData: (bookType: BookType) => void
  plotType: PlotType
  isLoading?: boolean
}

const datasetColors = utilsService.getRGBChartColorList()

const dataTypeToEstimateFieldName: { [key in DataTypeValue]: keyof PerformanceEstimate } = {
  [DataTypeValue.Revenue]: 'revenue',
  [DataTypeValue.Downloads]: 'downloads',
  [DataTypeValue.RevenueDownloadsRatioAllTime]: 'revenueAndDownloadsRatio',
  [DataTypeValue.RevenueDownloadsRatio7Days]: 'revenueAndDownloadsRatio',
  [DataTypeValue.RevenueDownloadsRatio30Days]: 'revenueAndDownloadsRatio',
  [DataTypeValue.RevenueDownloadsRatio90Days]: 'revenueAndDownloadsRatio',
}

export const GameRevenueAndDownloadsChart: FC<GameRevenueAndDownloadsChartProps> = ({
  estimates,
  dataType,
  granularity,
  onPlotTypeChange,
  onExportData,
  plotType,
  isLoading,
}) => {
  const { t } = useTranslation()
  const [plotTypeDisabled, setPlotTypeDisabled] = useState<boolean>(false)
  const datasets = useMemo(() => {
    const tooltipFormat = dataType === DataTypeValue.Downloads ? ChartDataFormat.Number : ChartDataFormat.Currency
    const fill = isPlotTypeStacked(plotType)

    if (dataType === DataTypeValue.Revenue || dataType === DataTypeValue.Downloads) {
      const estimateFieldName: keyof PerformanceEstimate = dataTypeToEstimateFieldName[dataType]

      // get the first 10 markets separately
      const separateMarkets = estimates.markets
        .filter((e, index) => index < 9)
        .map((marketEstimates, index) => {
          return {
            yAxisID: 'y1',
            label: marketEstimates.marketName,
            fill,
            spanGaps: false,
            tension: 0.1,
            backgroundColor: datasetColors[index % 10],
            borderColor: datasetColors[index % 10],
            data: marketEstimates.estimates.map((estimate) => ({ y: estimate[estimateFieldName], x: estimate.ts })),
            tooltipFormat,
          }
        })

      // combine other markets (from 10th market with most revenue) to a single dataset
      const combinedMarketData = estimates.markets
        .filter((e, index) => index >= 9)
        .reduce((acc, marketEstimates) => {
          marketEstimates.estimates.forEach((estimate) => {
            if (acc[estimate.ts]) {
              acc[estimate.ts] += estimate[estimateFieldName]
            } else {
              acc[estimate.ts] = estimate[estimateFieldName]
            }
          })

          return acc
        }, {} as { [ts: number]: number })

      const combinedMarkets = {
        yAxisID: 'y1',
        label: t('common:other_markets_name'),
        fill,
        spanGaps: false,
        tension: 0.1,
        backgroundColor: datasetColors[9],
        borderColor: datasetColors[9],
        data: Object.entries(combinedMarketData)
          .map(([ts, value]) => ({ x: parseInt(ts), y: value }))
          .sort((a, b) => b.x - a.x),
        tooltipFormat,
      }

      return [...separateMarkets, combinedMarkets].reverse()
    } else {
      return [
        {
          yAxisID: 'y1',
          label: t('common:total_text'),
          fill: false,
          spanGaps: false,
          tension: 0.1,
          backgroundColor: datasetColors[9],
          borderColor: datasetColors[9],
          data: estimates.estimates.map((estimate) => ({ x: estimate.ts, y: estimate.revenueAndDownloadsRatio })).sort((a, b) => b.x - a.x),
          tooltipFormat,
        },
      ]
    }
  }, [estimates, dataType, plotType, t])

  // disabled plot type selection for rev/dl data types
  useEffect(() => {
    if (isDataTypeRevenueAndDownloadsRatio(dataType)) {
      setPlotTypeDisabled(true)
    } else {
      setPlotTypeDisabled(false)
    }
  }, [dataType])

  const dataFormat = useMemo(() => {
    return dataType === DataTypeValue.Downloads ? ChartDataFormat.Number : ChartDataFormat.Currency
  }, [dataType])

  // configuration for y scale
  const yScaleConfig = useMemo(
    () => ({
      stacked: isPlotTypeStacked(plotType),
      dataFormat,
      precision: 2,
      title: getDataTypeName(dataType),
    }),
    [plotType, dataFormat, dataType]
  )

  // configuration for x scale
  const xScaleConfig = useMemo(() => {
    return {
      time: {
        unit: resolveTimeunitByGranularity(granularity),
        isoWeekday: true,
      },
    } as TimeScaleConfig
  }, [granularity])

  const tooltipCallbacks = useMemo(() => {
    return {
      beforeBody: (tooltipItems) => {
        if (!isDataTypeRevenueAndDownloadsRatio(dataType)) {
          const total = tooltipItems.reduce((acc, item) => acc + item.parsed.y, 0)

          return `${t('common:total_text')}:  ${
            dataFormat === ChartDataFormat.Currency ? utilsService.formatCurrency(total) : utilsService.formatNumber(total)
          }`
        }
      },
      title: (tooltipItems) => {
        switch (granularity) {
          case GranularityValue.Day:
            return format(tooltipItems[0].parsed.x, 'LLL d, yyyy')
          case GranularityValue.Week:
            return format(tooltipItems[0].parsed.x, 'I/yyyy')
          case GranularityValue.Month:
            return format(tooltipItems[0].parsed.x, 'MMM, yyyy')
        }
      },
    } as Partial<TooltipCallbacks<'line'>>
  }, [dataFormat, dataType, granularity, t])

  const handleDataExport = (format: BookType) => {
    onExportData && onExportData(format)
  }

  if (isLoading) {
    return <GRCircularProgress />
  }

  return (
    <>
      <Grid container justifyContent="flex-end" gap={2} mb={2}>
        <PlotTypeSelector value={plotType} onChange={onPlotTypeChange} disabled={plotTypeDisabled} />
        <ExportDataButton
          accessRoles={[RoleEnum.csv_game_revenue_downloads]}
          onChooseFormat={handleDataExport}
          analyticsEventOrigin="Game Revenue And DownLoads Chart"
        />
      </Grid>
      <TimelineChart
        chartLineOptions={{ responsive: true, maintainAspectRatio: false }}
        data={{ datasets: datasets }}
        scaleConfig={{ x: xScaleConfig, y1: yScaleConfig }}
        tooltipCallbacks={tooltipCallbacks}
      />
    </>
  )
}
