import { FC } from 'react'

import { Error } from '@mui/icons-material'
import { SxProps } from '@mui/material'

import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'

type InvalidDurationIndicatorProps = {
  sx?: SxProps
}

export const InvalidDurationIndicator: FC<InvalidDurationIndicatorProps> = ({ sx }) => {
  return (
    <GRTooltip content={'Invalid duration'}>
      <Error fontSize="small" color="error" sx={{ verticalAlign: 'middle', ml: 0.5, ...sx }} />
    </GRTooltip>
  )
}
