import { t } from 'i18next'
import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { Info } from '@mui/icons-material'
import { Box, Chip, Grid } from '@mui/material'

import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { useNewsFeedLimitedAccessCheck } from '../../../../account/hooks/roleHooks'
import { GameIcon } from '../../../../game'
import { UnifiedNewsEntry } from '../../../types/UnifiedNewsEntry'
import LimitedCardHeader from '../../LimitedCardHeader/LimitedCardHeader'
import NewsCardLink from '../NewsCardLink/NewsCardLink'
import './NewsCardHeader.scss'

type NewsCardHeaderProps = {
  newsEntry: UnifiedNewsEntry
  hideGameDetails?: boolean
  onOpenGameInfo: (open: boolean) => void
  children?: ReactNode
  isInternalUser?: boolean
}

export const NewsCardHeader: FC<NewsCardHeaderProps> = ({ hideGameDetails, newsEntry, onOpenGameInfo, isInternalUser }) => {
  const hasNewsFeedLimitedAccess = useNewsFeedLimitedAccessCheck()
  const gameId = newsEntry.feedEntry.gameId || newsEntry.feedEntry.entryData.gameId

  return (
    <div className="NewsCardHeader">
      {hasNewsFeedLimitedAccess ? (
        <LimitedCardHeader name={newsEntry.feedEntry.entryData.game} artist={newsEntry.feedEntry.entryData.artist} />
      ) : (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Grid container spacing={1}>
              <Grid item>
                {gameId ? (
                  <Link to={`/game/${gameId}`}>
                    <GameIcon gameName={newsEntry.feedEntry.entryData.game} src={newsEntry.feedEntry.entryData.icon}></GameIcon>
                  </Link>
                ) : (
                  <GameIcon gameName={newsEntry.feedEntry.entryData.game} src={newsEntry.feedEntry.entryData.icon}></GameIcon>
                )}
              </Grid>
              <Grid item xs>
                <div className="NewsCardHeader__game-name">
                  {gameId ? <Link to={`/game/${gameId}`}>{newsEntry.feedEntry.entryData.game}</Link> : <span>{newsEntry.feedEntry.entryData.game}</span>}
                  {gameId && !hideGameDetails && <Info className="NewsCardHeader__game-info" color="primary" onClick={() => onOpenGameInfo(true)}></Info>}
                </div>
                <div className="NewsCardHeader__publisher">{newsEntry.feedEntry.entryData.artist}</div>
                {newsEntry.feedEntry.entryData.conventionalSubgenre && (
                  <div className="NewsCardHeader__subgenre">
                    <Chip className="Chip" size="small" label={newsEntry.feedEntry.entryData.conventionalSubgenre}></Chip>
                  </div>
                )}
              </Grid>
              {isInternalUser && !newsEntry.feedEntry.entryData.rank && <NewsCardLink newsEntry={newsEntry} sx={{ mr: 4, mt: 2 }} />}
            </Grid>
          </Grid>
          {newsEntry.feedEntry.entryData.rank > 0 && (
            <Grid item>
              <Box sx={{ textAlign: 'center', display: 'inline-flex' }}>
                {isInternalUser && <NewsCardLink newsEntry={newsEntry} />}
                <GRTooltip content={t('newsfeed:games_top_grossing_rank') || ''}>
                  <div className="NewsCardHeader__rank">{newsEntry.feedEntry.entryData.rank < 500 ? newsEntry.feedEntry.entryData.rank : '+500'}</div>
                  <div className="NewsCardHeader__rank-text">{t('common:rank')}</div>
                </GRTooltip>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  )
}
