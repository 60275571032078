import { cuteRoboticThoughts } from '../../../pages/AIAnalyst/AIAnalystPageInfo'
import { AIAnalystAnswer, ChatAnswerNotKnown } from './AIAnalystAnswer'

export enum AIChatHistortyItemType {
  Bot = 'bot',
  User = 'user',
  Loading = 'loading',
  Error = 'error',
}

export interface AIChatHistoryItem {
  type: 'bot' | 'user' | 'loading' | 'error'
  message: string
  source?: string[]
  prompt?: string
  timestamp?: number
}

export interface BotAIChatHistoryItem extends AIChatHistoryItem {
  type: 'bot'
  answerNotKnown?: boolean
}

export interface LoadingAIChatHistoryItem extends AIChatHistoryItem {
  type: 'loading'
}

export interface ErrorAIChatHistoryItem extends AIChatHistoryItem {
  type: 'error'
}

export const createUserMessage = (message: string): AIChatHistoryItem => ({
  type: 'user',
  message,
  timestamp: Date.now(),
})

export const createBotMessage = (message: string, source?: string[], prompt?: string): BotAIChatHistoryItem => ({
  type: 'bot',
  message,
  source,
  prompt,
  timestamp: Date.now(),
  answerNotKnown: Object.values(ChatAnswerNotKnown).includes(message.trim() as ChatAnswerNotKnown),
})

// create BotMessage from AiAnalystAnswers
export const createBotMessageFromAiAnalystAnswers = (data: AIAnalystAnswer[]): BotAIChatHistoryItem => {
  // pick data from first answer and combine all sources
  const firstAnswer = data[0]
  const source = data.map((answer) => answer.source).flat()

  return createBotMessage(firstAnswer.answer, source, firstAnswer.prompt)
}

export const createLoadingMessage = (): LoadingAIChatHistoryItem => ({
  type: 'loading',
  message: cuteRoboticThoughts[Math.floor(Math.random() * cuteRoboticThoughts.length)],
})

export const createErrorMessage = (data: AIAnalystAnswer): ErrorAIChatHistoryItem => ({
  ...createBotMessage(data.answer, [], data.prompt),
  type: 'error',
})
