import { startOfDay, endOfDay } from 'date-fns'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { startOfNextDay } from '../../../helpers/date'
import languageService from '../../../services/LanguageService'
import { TrackingEvent } from '../types/TrackingEvents'
import { useLiveEventTagGroupsMapByEventTypeId } from './useLiveEventTagGroups'

type UseLiveEventInstancesParams = {
  events: TrackingEvent[]
}

export const useLiveEventInstances = ({ events }: UseLiveEventInstancesParams) => {
  const liveEventTagGroupsMapByEventTypeId = useLiveEventTagGroupsMapByEventTypeId()

  const eventInstances = useMemo(() => {
    const instanceCounts = events.reduce((acc, instance) => {
      if (acc[instance.eventId]) {
        acc[instance.eventId] += 1
      } else {
        acc[instance.eventId] = 1
      }

      return acc
    }, {} as { [eventId: string]: number })

    return events.map((instance) => {
      const start = startOfDay(instance.start).getTime()
      const end = endOfDay(instance.end).getTime()
      const durationStart = 0
      const durationEnd = startOfNextDay(instance.end) - start
      const offsetDiffMillis = (new Date(start).getTimezoneOffset() - new Date(end).getTimezoneOffset()) * 60 * 1000
      return {
        id: uuidv4(), // TODO: use a better id (duration id etc.)
        liveEventId: instance.eventId,
        start: start,
        end: end,
        duration: { start: durationStart, end: durationEnd + offsetDiffMillis },
        gameId: instance.gameId,
        eventTypeId: instance.typeId,
        name: instance.name,
        colorHex: liveEventTagGroupsMapByEventTypeId[instance.typeId].colorHex,
        tagGroupId: liveEventTagGroupsMapByEventTypeId[instance.typeId].id,
        instanceCount: instanceCounts[instance.eventId],
        eventTypeName: languageService.getTranslation('tags', instance.typeId),
      }
    })
  }, [events, liveEventTagGroupsMapByEventTypeId])

  return eventInstances
}
