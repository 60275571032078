export type Currency = {
  name: string
  usd: number
  value: number
  id?: string
}

export function getUnitPrice(currency: Currency) {
  return (currency.usd * 100) / (currency.value * 100)
}
