import { FC } from 'react'

import { AccountCircle } from '@mui/icons-material'
import { Grid, Avatar, Typography, GridProps, AvatarGroupProps, AvatarGroup, Box } from '@mui/material'

import { Analyst as TAnalyst } from '../../types/Analyst'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import './Analyst.scss'

/**
 * Component for displaying analyst image, name and title
 */
type AnalystProps = GridProps & {
  name?: string
  picture?: string
  title?: string
  icon?: React.ReactElement
}

export const Analyst: FC<AnalystProps> = ({ name = 'GameRefinery', picture, title = 'Analyst', icon, ...gridProps }) => {
  return (
    <Grid container alignItems="center" spacing={2} {...gridProps} className="Analyst">
      <Grid item>
        {picture ? (
          <Avatar sx={{ height: 60, width: 60, border: 'solid 1px' }} alt={name} src={picture} />
        ) : icon ? (
          <Avatar sx={{ height: 60, width: 60, border: 'solid 1px' }} className="icon" alt={name}>
            {icon}
          </Avatar>
        ) : (
          <AccountCircle color="secondary" sx={{ fontSize: 60 }} />
        )}
      </Grid>
      <Grid item>
        <Typography variant="h3" textAlign={gridProps.direction === 'row-reverse' ? 'right' : undefined}>
          {name}
        </Typography>
        <Typography variant="h5" sx={{ mb: 0.5 }} textAlign={gridProps.direction === 'row-reverse' ? 'right' : undefined} className="Analyst__Title">
          {title}
        </Typography>
      </Grid>
    </Grid>
  )
}

/**
 * Component for displaying multiple analysts as avatar group
 */
type AnalystsProps = AvatarGroupProps & {
  analysts: TAnalyst[]
}

export const Analysts: FC<AnalystsProps> = ({ analysts, ...avatarGroupProps }) => {
  return (
    <AvatarGroup max={4} {...avatarGroupProps}>
      {analysts.map((analyst) => {
        return (
          <GRTooltip
            key={analyst.id}
            content={
              <Box>
                <Typography variant="h3">{analyst.name}</Typography>
                <Typography variant="h4">{analyst.title}</Typography>
              </Box>
            }
          >
            <Avatar alt={analyst.name} src={analyst.picture} />
          </GRTooltip>
        )
      })}
    </AvatarGroup>
  )
}
