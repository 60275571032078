import { areIntervalsOverlapping } from 'date-fns'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Visibility } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

import { useGetAnalystsMapQuery } from '../../../../api/core'
import { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { LiveEventCalendarAdditionalDataId } from '../../types/LiveEventAdditionalCalendarData'
import { LiveEventFeedItem, LiveEventFeedItemType } from '../../types/LiveEventFeedItem'
import { TrackedGame } from '../../types/TrackedGame'
import { TrackingEventsByGame } from '../../types/TrackingEvents'
import LiveEventsFeedItemCard from '../LiveEventFeedItemCard/LiveEventFeedItemCard'

interface Props {
  trackedGames: TrackedGame[]
  trackingEventsByGame: TrackingEventsByGame
  dateRange?: DateRangeValue
  isLoading?: boolean
  selectedCalendarAdditionalDataIdsMap: { [dataId: string]: boolean }
  onOpenFeedItemClick: (feedItem: LiveEventFeedItem) => void
}

const feedItemsPerPage = 20

const LiveEventsFeed: React.FC<Props> = ({
  trackedGames,
  trackingEventsByGame,
  dateRange,
  isLoading,
  selectedCalendarAdditionalDataIdsMap,
  onOpenFeedItemClick,
}) => {
  const { t } = useTranslation()
  const [feedItemsDisplayedCount, setFeedItemsDisplayedCount] = useState(feedItemsPerPage)
  const { data: analystsMap } = useGetAnalystsMapQuery()

  const feedItems = useMemo(() => {
    if (isLoading || !analystsMap) {
      return undefined
    }

    const items: LiveEventFeedItem[] = []
    const timestampFrom: number = dateRange?.fromDate?.getTime() || 0
    const timestampTo: number = dateRange?.toDate?.getTime() || 0
    let feedItemId: number = 0

    trackedGames.forEach((trackedGame) => {
      const firstLiveEventInstanceByKeyAndTimestamp: { [eventId: string]: number } = {}

      if (trackingEventsByGame.events[trackedGame.game.id]) {
        trackingEventsByGame.events[trackedGame.game.id].forEach((event) => {
          if (!event.highlighted) return

          if (!firstLiveEventInstanceByKeyAndTimestamp[event.eventId]) {
            firstLiveEventInstanceByKeyAndTimestamp[event.eventId] = event.start
          } else if (event.start < firstLiveEventInstanceByKeyAndTimestamp[event.eventId]) {
            firstLiveEventInstanceByKeyAndTimestamp[event.eventId] = event.start
          }
        })

        trackingEventsByGame.events[trackedGame.game.id].forEach((event) => {
          const liveEventFeedItem: LiveEventFeedItem = {
            id: `${feedItemId}`,
            start: event.start,
            end: event.end,
            trackedGame,
            trackingEvent: event,
            type: LiveEventFeedItemType.LiveEvent,
            analyst: event.comment?.analystId ? analystsMap[event.comment?.analystId] : undefined,
            new: firstLiveEventInstanceByKeyAndTimestamp[event.eventId] === event.start ? true : false,
          }

          items.push(liveEventFeedItem)
          feedItemId++
        })
      }

      if (
        selectedCalendarAdditionalDataIdsMap[LiveEventCalendarAdditionalDataId.AnalystReview] &&
        trackingEventsByGame.comments &&
        trackingEventsByGame.comments[trackedGame.game.id]
      ) {
        trackingEventsByGame.comments[trackedGame.game.id].forEach((comment) => {
          const liveEventFeedItem: LiveEventFeedItem = {
            id: `${feedItemId}`,
            start: comment.start,
            end: comment.end,
            trackedGame,
            analystNote: comment,
            type: LiveEventFeedItemType.AnalystNote,
            analyst: analystsMap[comment.comment.analystId],
          }

          items.push(liveEventFeedItem)
          feedItemId++
        })
      }
    })

    return items
      .filter((item) => areIntervalsOverlapping({ start: item.start, end: item.end }, { start: timestampFrom, end: timestampTo }))
      .sort((a, b) => b.start - a.start)
  }, [trackedGames, trackingEventsByGame.events, trackingEventsByGame.comments, selectedCalendarAdditionalDataIdsMap, dateRange, analystsMap, isLoading])

  useEffect(() => {
    setFeedItemsDisplayedCount(feedItemsPerPage)
  }, [trackingEventsByGame.events, trackingEventsByGame.comments, setFeedItemsDisplayedCount])

  return (
    <div className="LiveEventsFeed">
      {isLoading && !feedItems && <GRCircularProgress />}
      {!isLoading && feedItems && (
        <>
          <Box mb={2} textAlign="center">
            <Typography variant="body1" className="light-text-color">
              {t('live-events:feed_title_active_events', { count: feedItems.length })}
            </Typography>
          </Box>
          {feedItems &&
            feedItems.slice(0, feedItemsDisplayedCount).map((feedItem) => {
              return <LiveEventsFeedItemCard key={feedItem.id} feedItem={feedItem} onOpenFeedItemClick={onOpenFeedItemClick} />
            })}

          {feedItems.length > 0 && feedItemsDisplayedCount < feedItems.length && (
            <Box textAlign="center" mt={3}>
              <Button
                disabled={feedItems.length <= feedItemsDisplayedCount}
                onClick={() =>
                  setFeedItemsDisplayedCount(
                    feedItemsDisplayedCount + feedItemsPerPage > feedItems.length ? feedItems.length : feedItemsDisplayedCount + feedItemsPerPage
                  )
                }
                startIcon={<Visibility />}
                size="large"
                variant="contained"
                color="primary"
              >
                {t('common:show_more')} ({feedItemsDisplayedCount}/{feedItems.length})
              </Button>
            </Box>
          )}
        </>
      )}
    </div>
  )
}

export default LiveEventsFeed
