import { FC, ReactNode } from 'react'

import { Box } from '@mui/material'

import GameSkillThinkingMeter from '../../features/game/components/GameSkillThinkingMeter/GameSkillThinkingMeter'
import NotAvailableIcon from '../NotAvailableIcon/NotAvailableIcon'

type GameSkillThinkingMeterIndicatorProps = {
  sensomotoric: number
  cognitive: number
  gps: number
  children?: ReactNode
}

export const GameSkillThinkingMeterIndicator: FC<GameSkillThinkingMeterIndicatorProps> = ({ sensomotoric, cognitive, gps }) => {
  return <Box>{gps ? <GameSkillThinkingMeter sensomotoric={sensomotoric} cognitive={cognitive}></GameSkillThinkingMeter> : <NotAvailableIcon />}</Box>
}
