import { ReactNode } from 'react'

import { Card, Grid, List, ListItem, Typography } from '@mui/material'

import { MarketExplorerSegmentFilterValue } from '../../../types/MarketExplorerSegmentConfiguration'
import { MarketExplorerSegmentFilterGroup } from '../../../types/MarketExplorerSegmentFilterConfig'
import { SegmentFilter } from './SegmentFilter/SegmentFilter'

/**
 * Component representing a filter group in segment edit dialog
 */
type SegmentFilterGroupProps<T> = {
  group: MarketExplorerSegmentFilterGroup<T>
  onFilterChange: (value: MarketExplorerSegmentFilterValue, filterId: T) => void
  values: { [key: string]: MarketExplorerSegmentFilterValue }
  hideGroupLabel?: boolean
  disabled?: boolean
  locked?: boolean
  children?: ReactNode
}

export const SegmentFilterGroup = <T extends {}>({ group, onFilterChange, values, hideGroupLabel, disabled, locked }: SegmentFilterGroupProps<T>) => {
  return (
    <Grid container direction="column" spacing={2}>
      {!hideGroupLabel && (
        <Grid item>
          <Typography variant="h5">{group.label}</Typography>
        </Grid>
      )}
      <Grid item>
        <Card>
          <List disablePadding>
            {group.filters.map((filter, index) => {
              return (
                <ListItem divider key={index}>
                  <SegmentFilter
                    filterConfig={filter}
                    onChange={onFilterChange}
                    value={values[filter.id as unknown as string]}
                    disabled={disabled}
                    locked={locked}
                  />
                </ListItem>
              )
            })}
          </List>
        </Card>
      </Grid>
    </Grid>
  )
}
