import { FC, ReactNode, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, Chip, Dialog, DialogContent, DialogProps, Divider, Grid, Tab, Tabs, Typography } from '@mui/material'

import { GRBanner } from '../../../../components/GRBanner/GRBanner'
import GRDialogTitle, { GRDialogCloseButton } from '../../../../components/GRDialogTitle/GRDialogTitle'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { OverlayLoader } from '../../../../components/OverlayLoader/OverlayLoader'
import languageService from '../../../../services/LanguageService'
import { useEventTypeMonthlyStatisticsForGames, useEventTypeDailyStatisticsForGames } from '../../hooks/liveEventsEventTypeStatistics'
import { EventStatisticsDialogTab, useEventStatisticsDialogTabs } from '../../hooks/useEventStatisticsDialogTabs'
import { useLiveEventModal } from '../../hooks/useLiveEventModal'
import { useLiveEventTagGroupWithEventTypeId } from '../../hooks/useLiveEventTagGroups'
import { useTrackedGames } from '../../hooks/useTrackedGames'
import { TrackedGame } from '../../types/TrackedGame'
import { TrackedGamesGrid } from '../TrackedGamesGrid/TrackedGamesGrid'
import { LiveEventsDailyEventStatisticsChart } from './LiveEventsDailyEventStatisticsChart/LiveEventsDailyEventStatisticsChart'
import { LiveEventsEventStatisticsDurationsTab } from './LiveEventsEventStatisticsDurationsTab/LiveEventsEventStatisticsDurationsTab'
import { LiveEventsEventStatisticsPerformanceTab } from './LiveEventsEventStatisticsPerformanceTab/LiveEventsEventStatisticsPerformanceTab'
import { LiveEventsMonthlyEventStatisticsChart } from './LiveEventsMonthlyEventStatisticsChart/LiveEventsMonthlyEventStatisticsChart'

/**
 * Component displaying Live Event statistics
 */
type LiveEventsEventStatisticsDialogProps = DialogProps & {
  eventTypeId: string
  trackedGameIds: string[]
  tab?: EventStatisticsDialogTab
  onTabChange: (tab: EventStatisticsDialogTab) => void
  children?: ReactNode
}

export const LiveEventsEventStatisticsDialog: FC<LiveEventsEventStatisticsDialogProps> = ({ trackedGameIds, eventTypeId, tab, onClose, open, onTabChange }) => {
  const tabs = useEventStatisticsDialogTabs()
  const { showModal: showEventModal } = useLiveEventModal()

  const [monthlyStatistics, isMonthlyStatisticsLoading] = useEventTypeMonthlyStatisticsForGames({ trackedGameIds, eventTypeId })
  const [dailyStatistics, isDailyStatisticsLoading] = useEventTypeDailyStatisticsForGames({ trackedGameIds, eventTypeId })
  const trackedGamesImplementingEventType = useTrackedGames({ eventTypes: [eventTypeId] }).data || []
  const selectedTrackedGamesImplementingEventType = trackedGamesImplementingEventType.filter((trackedGame) => trackedGameIds.includes(trackedGame.game.id))
  const tagGroup = useLiveEventTagGroupWithEventTypeId(eventTypeId)

  const isDataAvailable = Object.keys(monthlyStatistics).length > 0

  const handleTrackedGameClick = useCallback(
    (trackedGame: TrackedGame) => {
      showEventModal({ trackedGameId: trackedGame.game.id, eventTypeId })
    },
    [eventTypeId, showEventModal]
  )

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <GRDialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={9}>
            {languageService.getTranslation('tags', eventTypeId)}

            {tagGroup && (
              <Chip
                label={tagGroup.name}
                style={{ marginBottom: '4px', color: 'white', backgroundColor: tagGroup.colorHex, marginLeft: '15px' }}
                size="small"
              ></Chip>
            )}
          </Grid>

          <Grid item xs={3} container direction="column" alignItems="flex-end">
            <GRDialogCloseButton onClick={onClose} share />
          </Grid>
        </Grid>
      </GRDialogTitle>
      <Divider />

      <Tabs value={tab}>
        {tabs.map((tab) => {
          return (
            <Tab
              icon={tab.icon}
              iconPosition="start"
              key={tab.id}
              label={tab.label}
              onClick={() => onTabChange(tab.id)}
              value={tab.id}
              sx={{ minHeight: 56 }}
            />
          )
        })}
      </Tabs>

      <DialogContent dividers>
        {!isDataAvailable && (
          <Box padding={1}>
            <GRBanner severity="guidance">
              <Trans i18nKey="live-events:event_not_available" />
            </GRBanner>
          </Box>
        )}

        {tab === EventStatisticsDialogTab.Statistics && isDataAvailable && (
          <>
            <Typography variant="h3" mb={2}>
              <Trans i18nKey="live-events:event_statistics_dialog_popularity" />
            </Typography>

            <Card sx={{ mb: 4 }}>
              <CardHeader
                title={
                  <Grid container spacing={1}>
                    <Grid item>
                      <Trans i18nKey="live-events:event_statistics_dialog_title_monthly" />
                    </Grid>
                    <Grid item>
                      <GRTooltip content={<Trans i18nKey="live-events:event_statistics_dialog_title_monthly_tooltip" />}>
                        <Info color="primary" />
                      </GRTooltip>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                <OverlayLoader isLoading={isMonthlyStatisticsLoading}>
                  <LiveEventsMonthlyEventStatisticsChart statistics={monthlyStatistics} />
                </OverlayLoader>
              </CardContent>
            </Card>
            <Card>
              <CardHeader
                title={
                  <Grid container spacing={1}>
                    <Grid item>
                      <Trans i18nKey="live-events:event_statistics_dialog_title_daily" />
                    </Grid>
                    <Grid item>
                      <GRTooltip content={<Trans i18nKey="live-events:event_statistics_dialog_title_daily_tooltip" />}>
                        <Info color="primary" />
                      </GRTooltip>
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                <OverlayLoader isLoading={isDailyStatisticsLoading}>
                  <LiveEventsDailyEventStatisticsChart statistics={dailyStatistics} />
                </OverlayLoader>
              </CardContent>
            </Card>
          </>
        )}
        {tab === EventStatisticsDialogTab.Performance && isDataAvailable && (
          <LiveEventsEventStatisticsPerformanceTab eventTypeId={eventTypeId} trackedGameIds={trackedGameIds} />
        )}
        {tab === EventStatisticsDialogTab.Durations && isDataAvailable && (
          <LiveEventsEventStatisticsDurationsTab eventTypeId={eventTypeId} trackedGameIds={trackedGameIds} />
        )}

        {tab === EventStatisticsDialogTab.Implementations && isDataAvailable && (
          <TrackedGamesGrid trackedGames={selectedTrackedGamesImplementingEventType} onTrackedGameClick={handleTrackedGameClick} />
        )}
      </DialogContent>
    </Dialog>
  )
}
