import classNames from 'classnames'
import { FC, ReactNode, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import { Box, Typography, Card, CardContent, Divider, Grid, useMediaQuery, useTheme } from '@mui/material'

import { useGetTrackedEventQuery } from '../../../../../api/core'
import { Analyst } from '../../../../../components/Analyst/Analyst'
import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import GalleryCarousel, { GalleryCarouselImageListingType, GalleryCarouselImagePreviewMode } from '../../../../../components/GalleryCarousel/GalleryCarousel'
import { HtmlContentParser } from '../../../../../components/HtmlContentParser/HtmlContentParser'
import { ModalMode, liveEventReviewModalParserOptions } from '../../../../../components/LiveEventModalLink/LiveEventModalLink'
import { useError } from '../../../../../hooks/useError'
import { useInternalAccessCheck } from '../../../../account/hooks/roleHooks'
import { useCurrentUserLanguage } from '../../../../account/hooks/useCurrentUserLanguage'
import { liveEventPriceRange } from '../../../hooks/liveEventPriceRange'
import { useLiveEventDialogDescriptionTabAnalyticsEvents } from '../../../hooks/useLiveEventsTrackerAnalyticsEvents'
import { TrackedGame } from '../../../types/TrackedGame'
import { Duration } from '../../../types/TrackingEvents'
import { LiveEventMotivationsSection } from '../../LiveEventMotivationsSection/LiveEventMotivationsSection'
import LiveEventPricePoints from '../../LiveEventPricePoints/LiveEventPricePoints'
import LiveEventsSecondaryTaxonomyTagList from '../../LiveEventsSecondaryTaxonomyTagList/LiveEventsSecondaryTaxonomyTagList'
import { EventHeader } from '../EventHeader/EventHeader'
import './DescriptionTab.scss'

export type DescriptionTabImageListingType = 'carousel' | 'thumbnails'
/**
 * Component representing event dialog Description tab
 */
type DescriptionTabProps = {
  currentTrackedGame?: TrackedGame
  eventId?: string
  eventTypeId?: string
  eventTypeName?: string
  children?: ReactNode
  screenshotIndex?: number
  duration?: Duration
  onScreenshotIndexChange: (screenshotIndex: number) => void
  onScrollParentTop: () => void
  onEventHighlighted?: (eventId: string) => void
}

export const DescriptionTab: FC<DescriptionTabProps> = ({
  currentTrackedGame,
  eventId,
  eventTypeId,
  eventTypeName,
  screenshotIndex = 0,
  duration,
  onScreenshotIndexChange,
  onScrollParentTop,
  onEventHighlighted,
}) => {
  const eventQuery = useGetTrackedEventQuery(eventId as string, { skip: !eventId })
  useError({ error: eventQuery.error })

  const internalUser = useInternalAccessCheck() // NOTE: Remove Internal role check when we want to release LET Price points

  const { t } = useTranslation()
  const theme = useTheme()
  const { handleTrackImageListingTypeChangeEvent } = useLiveEventDialogDescriptionTabAnalyticsEvents()
  const userLanguage = useCurrentUserLanguage()
  const isFullscreenAvailable = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true })
  const [imageListingType, setImageListingType] = useState<GalleryCarouselImageListingType>('carousel')
  const [imagePreviewMode, setImagePreviewMode] = useState<GalleryCarouselImagePreviewMode>('default')
  const [flexDirection, setFlexDirection] = useState<'row' | 'column-reverse' | 'column'>(isFullscreenAvailable ? 'row' : 'column')
  const isLoading = eventQuery.isLoading || eventQuery.isFetching
  const isImagePreviewFullscreenMode = imagePreviewMode === 'fullscreen'
  const priceRange = liveEventPriceRange(eventQuery.data?.event.pricePoints, currentTrackedGame?.game?.currencies)

  const screenshots = useMemo(() => {
    if (!eventQuery.data?.event.screenshotUrls) return []

    return eventQuery.data?.event.screenshotUrls?.filter((url) => url)
  }, [eventQuery.data?.event.screenshotUrls])
  const descriptionRawHtml = eventQuery.data?.event.comment?.content?.comment?.[userLanguage]
    ? eventQuery.data?.event.comment?.content?.comment?.[userLanguage]
    : eventQuery.data?.event.comment?.content?.comment?.['en']
  const eventName = eventQuery.data?.event.name
  const inactive = eventQuery.data?.event.active === false
  const analyst = eventQuery.data?.analyst

  const handleImageListingTypeChange = (newType: GalleryCarouselImageListingType) => {
    setImageListingType(newType)
    if (newType === 'thumbnails') {
      onScrollParentTop()
    }

    handleTrackImageListingTypeChangeEvent({
      imageListingType,
      trackedGame: currentTrackedGame,
      eventId,
      eventName,
      eventTypeId,
      eventTypeName,
    })
  }

  const thumbnailGridSize = useMemo(() => {
    const count = screenshots.length
    if (count === 1) return 6
    if (count <= 4) return isImagePreviewFullscreenMode ? 4 : 6
    if (count <= 9) return isImagePreviewFullscreenMode ? 3 : 4
    return 3
  }, [isImagePreviewFullscreenMode, screenshots.length])

  const descriptionItemClasses = classNames('DescriptionTab__description', {
    'DescriptionTab__description--fullscreen': isImagePreviewFullscreenMode,
  })

  const cardContent = () => {
    return (
      <Card>
        <CardContent>
          <Box sx={{ textAlign: 'center' }}>
            <EventHeader
              eventName={eventName}
              inactive={inactive}
              eventId={eventId}
              eventTypeId={eventTypeId}
              trackedGameId={currentTrackedGame?.game.id}
              duration={duration}
              priceRange={priceRange}
              onEventHighlighted={onEventHighlighted}
            />
          </Box>
          {eventQuery?.data?.event.tags && eventQuery.data.event.tags.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} style={{ marginTop: '0' }}>
                {t('live-events:live_events_taxonomy')}
              </Divider>

              <Box mb={2} display="flex" justifyContent="center" flexWrap="wrap">
                <LiveEventsSecondaryTaxonomyTagList tags={eventQuery.data.event.tags || []} />
              </Box>
            </>
          )}

          {eventQuery.data && eventQuery.data.motivations && (
            <LiveEventMotivationsSection
              motivationTypes={eventQuery.data?.filteredMotivations?.types}
              motivationSegments={eventQuery.data?.filteredMotivations?.archetypes}
            />
          )}

          <Divider sx={{ my: 2 }} style={{ marginTop: '0' }}>
            <Trans i18nKey="common:description" />
          </Divider>
          <Analyst mb={2} name={analyst?.name} title={analyst?.title} picture={analyst?.picture} />
          {descriptionRawHtml && descriptionRawHtml.length > 0 && (
            <Typography variant="body1" component="div">
              <HtmlContentParser
                rawHtml={descriptionRawHtml}
                parserOptions={liveEventReviewModalParserOptions(currentTrackedGame?.game.id || '', { liveEventModalMode: ModalMode.Replace })}
              />
            </Typography>
          )}
          {internalUser && currentTrackedGame && eventQuery?.data?.event.pricePoints && eventQuery.data.event.pricePoints.length > 0 && (
            <>
              <Divider sx={{ mt: 3.5, mb: 2 }}>{t('live-events:price_points')}</Divider>

              <LiveEventPricePoints pricePoints={eventQuery.data.event.pricePoints} currencies={currentTrackedGame?.game?.currencies} />
            </>
          )}
        </CardContent>
      </Card>
    )
  }

  return isLoading ? (
    <GRCircularProgress />
  ) : screenshots.length > 0 ? (
    <>
      <Grid className="DescriptionTab" container columnSpacing={2} flexDirection={flexDirection}>
        <Grid item flexShrink={1} className={descriptionItemClasses} mb={2}>
          <Card>
            <CardContent>
              <Box sx={{ textAlign: 'center' }}>
                <EventHeader
                  eventName={eventName}
                  inactive={inactive}
                  eventId={eventId}
                  eventTypeId={eventTypeId}
                  trackedGameId={currentTrackedGame?.game.id}
                  duration={duration}
                  priceRange={priceRange}
                  onEventHighlighted={onEventHighlighted}
                />
              </Box>
              {eventQuery?.data?.event.tags && eventQuery.data.event.tags.length > 0 && (
                <>
                  <Divider sx={{ my: 2 }} style={{ marginTop: '0' }}>
                    {t('live-events:live_events_taxonomy')}
                  </Divider>

                  <Box mb={2} display="flex" justifyContent="center" flexWrap="wrap">
                    <LiveEventsSecondaryTaxonomyTagList tags={eventQuery.data.event.tags || []} />
                  </Box>
                </>
              )}

              {eventQuery.data && eventQuery.data.motivations && (
                <LiveEventMotivationsSection
                  motivationTypes={eventQuery.data?.filteredMotivations?.types}
                  motivationSegments={eventQuery.data?.filteredMotivations?.archetypes}
                />
              )}

              <Divider sx={{ my: 2 }} style={{ marginTop: '0' }}>
                <Trans i18nKey="common:description" />
              </Divider>

              <Analyst mb={2} name={analyst?.name} title={analyst?.title} picture={analyst?.picture} />
              {descriptionRawHtml && descriptionRawHtml.length > 0 && (
                <Typography variant="body1" component="div">
                  <HtmlContentParser
                    rawHtml={descriptionRawHtml}
                    parserOptions={liveEventReviewModalParserOptions(currentTrackedGame?.game.id || '', { liveEventModalMode: ModalMode.Replace })}
                  />
                </Typography>
              )}

              {internalUser && currentTrackedGame && eventQuery?.data?.event.pricePoints && eventQuery.data.event.pricePoints.length > 0 && (
                <>
                  <Divider sx={{ mt: 3.5, mb: 2 }}>{t('live-events:price_points')}</Divider>

                  <LiveEventPricePoints pricePoints={eventQuery.data.event.pricePoints} currencies={currentTrackedGame?.game?.currencies} />
                </>
              )}
            </CardContent>
          </Card>
        </Grid>

        <CSSTransition
          in={imagePreviewMode === 'default'}
          timeout={300}
          classNames="animate"
          onEnter={() => setFlexDirection('row')}
          onExited={() => setFlexDirection('column-reverse')}
          appear
        >
          <Grid item className="DescriptionTab__gridItemImage">
            <div className={imageListingType === 'carousel' && imagePreviewMode === 'default' ? 'DescriptionLeftSticky' : ''}>
              <GalleryCarousel
                screenshots={screenshots}
                screenshotIndex={screenshotIndex}
                carouselImageCount={1}
                thumbnailGridSize={thumbnailGridSize}
                imageListingType={imageListingType}
                imagePreviewMode={imagePreviewMode}
                onImageListingTypeChange={(newType: GalleryCarouselImageListingType) => handleImageListingTypeChange(newType)}
                onImagePreviewModeChange={(newMode: GalleryCarouselImagePreviewMode) => setImagePreviewMode(newMode)}
                filename={`let-${currentTrackedGame?.game.name}_${eventTypeName}`
                  .toLocaleLowerCase()
                  .trim()
                  .replace(/ /g, '_') // Replaces spaces with underscores
                  .replace(/[^a-zA-Z0-9_-]/g, '')} // Removes all other special characters except _ and -
              />
            </div>
          </Grid>
        </CSSTransition>
      </Grid>
    </>
  ) : (
    <>{cardContent()}</>
  )
}
