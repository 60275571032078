import { BookType, JSON2SheetOpts, utils, WorkBook, WorkSheet, writeFile } from 'xlsx'

type GenerateExport<T> = (format: BookType, data: T[], sheetName: string, filename: string, headerData?: T[][], opts?: JSON2SheetOpts) => void
type GenerateExportMultipleSheets<T> = (
  format: BookType,
  data: T[][],
  sheetNames: string[],
  filename: string,
  headerData?: T[][],
  opts?: JSON2SheetOpts
) => void

/**
 * Generate a workbook from data. Export it from browser as a file.
 *
 * @param bookType one of the supported formats
 * @param data in json format, the key being the column header
 * @param sheetName of the data in workbook
 * @param filename the name without suffix
 * @param headerData optional header data, in array of arrays format
 * @param opts optional for json_to_sheet
 */
export const generateExport: GenerateExport<any> = (bookType, data, sheetName, filename, headerData, opts = {}) => {
  const sheet: WorkSheet = utils.aoa_to_sheet(headerData || [], opts)
  const startRow = headerData ? headerData.length + 2 : 1
  utils.sheet_add_json(sheet, data, { origin: `A${startRow}` })

  const workbook: WorkBook = {
    SheetNames: [sheetName],
    Sheets: { [sheetName]: sheet },
  }
  writeFile(workbook, `${filename}.${bookType}`, { bookType })
}

/**
 *  Generate a workbook from data to multiple sheets in XSXL (multiple files CSV). Export it from browser as a file.
 * @param bookType  one of the supported formats
 * @param data in json format, the key being the column header
 * @param sheetNames of the data in workbook
 * @param filename the name without suffix
 * @param headerData optional header data, in array of arrays format
 * @param opts optional for json_to_sheet
 */
export const generateExportMultipleSheets: GenerateExportMultipleSheets<any> = (bookType, data, sheetNames, filename, headerData, opts = {}) => {
  const workbook: WorkBook = {
    SheetNames: sheetNames,
    Sheets: {},
  }

  sheetNames.forEach((sheetName: string, index: number) => {
    const sheet: WorkSheet = utils.aoa_to_sheet(headerData || [], opts)
    const startRow = headerData ? headerData.length + 2 : 1
    utils.sheet_add_json(sheet, data[index], { origin: `A${startRow}` })
    workbook.Sheets[sheetName] = sheet
  })

  writeFile(workbook, `${filename}.${bookType}`, { bookType })
}
