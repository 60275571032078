import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { StarBorderRounded, StarRateRounded } from '@mui/icons-material'
import { Divider, Grid, Typography } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import languageService from '../../../../services/LanguageService'
import { MotivationType } from '../../../market-explorer/types/MotivationType'
import { MotivationIcon } from '../../utils/svgs'
import './MotivationTypeBadge.scss'

export enum MotivationLevel {
  MAJOR = 'major',
  MINOR = 'minor',
}

export type MotivationTypeWithLevel = {
  type: MotivationType
  level?: MotivationLevel
}

interface Props {
  motivationTypeWithLevel: MotivationTypeWithLevel
  selected?: boolean
  onSelect?: (motivationType: MotivationType) => void
  alignLeft?: boolean
}

export const MotivationTypeBadge: React.FC<Props> = ({ motivationTypeWithLevel, selected, alignLeft, onSelect }) => {
  const { t } = useTranslation()
  const motivationTypeName = languageService.getTranslation('motivations', motivationTypeWithLevel.type)
  const motivationTypeDescription = languageService.getTranslation('motivations', `${motivationTypeWithLevel.type}_description`)

  const componentClassNames = useMemo(() => {
    let classNames = 'MotivationTypeBadge'

    if (onSelect) {
      classNames = `${classNames} MotivationTypeBadge--Selectable`
    }

    if (alignLeft) {
      classNames = `${classNames} MotivationTypeBadge--AlignLeft`
    }

    return classNames
  }, [alignLeft, onSelect])

  return (
    <div className={componentClassNames}>
      <GRTooltip
        content={
          <>
            <Typography variant="h3">
              <Grid container spacing={1.5} alignItems={'center'}>
                <Grid item>
                  <MotivationIcon motivation={motivationTypeWithLevel.type} />
                </Grid>
                <Grid item xs>
                  {motivationTypeName}
                </Grid>
              </Grid>
            </Typography>
            <p style={{ marginBottom: '0px' }}>{motivationTypeDescription}</p>
            {motivationTypeWithLevel.level && (
              <>
                <Divider sx={{ my: 2 }} />
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <div className="MotivationTypeBadge__Level">
                      {motivationTypeWithLevel.level === MotivationLevel.MAJOR && <StarRateRounded />}
                      {motivationTypeWithLevel.level === MotivationLevel.MINOR && <StarBorderRounded />}
                    </div>
                  </Grid>

                  <Grid item>
                    <div style={{ fontWeight: 'bold' }}>{t(`motivations:${motivationTypeWithLevel.level}_motivation_level`)}</div>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        }
      >
        <div
          className={selected ? 'MotivationTypeBadge__Content MotivationTypeBadge__Content--Selected' : 'MotivationTypeBadge__Content'}
          onClick={() => onSelect && onSelect(motivationTypeWithLevel.type)}
        >
          <Grid container alignItems={'center'}>
            {motivationTypeWithLevel.level && (
              <Grid item>
                <div className="MotivationTypeBadge__Level">
                  {motivationTypeWithLevel.level === MotivationLevel.MAJOR && <StarRateRounded />}
                  {motivationTypeWithLevel.level === MotivationLevel.MINOR && <StarBorderRounded />}
                </div>
              </Grid>
            )}

            <Grid item>
              <div className="MotivationTypeBadge__Type">
                <MotivationIcon motivation={motivationTypeWithLevel.type} />
              </div>
            </Grid>
            <Grid item xs>
              {motivationTypeName}
            </Grid>
          </Grid>
        </div>
      </GRTooltip>
    </div>
  )
}
