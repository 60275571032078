import { useTranslation } from 'react-i18next'

import { SportsEsports, Description, List, Flare } from '@mui/icons-material'

export enum EventDialogTab {
  Description = 'Description',
  Instances = 'Instances',
  AllEvents = 'AllEvents',
  OtherGames = 'OtherGames',
}

export const useEventDialogTabs = () => {
  const { t } = useTranslation()

  return [
    {
      id: EventDialogTab.Description,
      label: t('live-events:event_dialog_tab_description'),
      icon: <Description />,
      tooltip: t('live-events:event_dialog_tab_description_tooltip'),
    },
    {
      id: EventDialogTab.Instances,
      label: t('live-events:event_dialog_tab_instances'),
      icon: <Flare />,
      tooltip: t('live-events:event_dialog_tab_instances_tooltip'),
    },
    {
      id: EventDialogTab.AllEvents,
      label: t('live-events:event_dialog_tab_all_events'),
      icon: <List />,
    },
    {
      id: EventDialogTab.OtherGames,
      label: t('live-events:event_dialog_tab_other_games'),
      icon: <SportsEsports />,
    },
  ]
}
