import { FC, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import InfoIcon from '@mui/icons-material/Info'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'

type TimeSinceReleasedSelectorProps = {
  onChange: (value: string) => void
  value: string
}

/**
 * Component for displaying a radio button group selection to filter by release date
 */
export const TimeSinceReleasedSelector: FC<TimeSinceReleasedSelectorProps> = memo(({ onChange, value }) => {
  const { t } = useTranslation()
  const handleValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    },
    [onChange]
  )
  return (
    <FormControl component="fieldset" sx={{ flexDirection: 'row', alignItems: 'baseline' }}>
      <FormLabel component="legend">{t('common:show_games')}</FormLabel>
      <RadioGroup
        row
        value={value}
        onChange={handleValueChanged}
        defaultValue="-1"
        aria-label="Days since released"
        name="days-since-released-radio-buttons-group"
      >
        <FormControlLabel value="-1" control={<Radio />} label={t<string>('common:all')} />
        <FormControlLabel value="7" control={<Radio />} label={t<string>('common:7_days')} />
        <FormControlLabel value="14" control={<Radio />} label={t<string>('common:14_days')} />
        <FormControlLabel value="30" control={<Radio />} label={t<string>('common:30_days')} />
      </RadioGroup>
      <GRTooltip content={t('soft-launch-games:show_games_tooltip')}>
        <InfoIcon color="primary" />
      </GRTooltip>
    </FormControl>
  )
})
