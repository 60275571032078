import { useMemo } from 'react'

import { FtueVideo, useGetLatestFtueVideosQuery } from '../../../api/core'

interface GetFtueVideoForGameHookOptions {
  skip?: boolean
}
export const useGetFtueVideoForGame = (gameId: string, options?: GetFtueVideoForGameHookOptions) => {
  const { skip } = options || {}
  const { data: latestVideos, isFetching: isFetchingVideos } = useGetLatestFtueVideosQuery(undefined, { skip })

  const video = useMemo<FtueVideo | undefined>(() => {
    if (!latestVideos || skip) return

    return latestVideos.find((video) => video.gameId === gameId)
  }, [gameId, latestVideos, skip])

  return { video, isFetchingVideos }
}
