import classNames from 'classnames'
import { FC, ReactNode, useMemo } from 'react'

import { isNil } from '../../helpers/isNil'
import utilsService from '../../services/UtilsService'
import './ValueIndicator.scss'

export enum ValueType {
  Number = 'number',
  Currency = 'currency',
}

export type ValueIndicatorProps = {
  value: number
  type: ValueType
  maximumFractionDigits?: number
  shorten?: boolean
  sign?: boolean
  displayZero?: boolean
  minValue?: number
  emptyValue?: ReactNode
}

const defaultEmptyValue = ''

export const ValueIndicator: FC<ValueIndicatorProps> = ({
  value,
  type,
  displayZero = false,
  maximumFractionDigits,
  shorten = false,
  sign = false,
  minValue,
  emptyValue = defaultEmptyValue,
}) => {
  const isValid = useMemo(() => {
    const isValidNumber = isFinite(value) && !isNaN(value)
    const shouldDisplayZero = displayZero ? true : value !== 0
    return isValidNumber && shouldDisplayZero
  }, [value, displayZero])
  const classes = classNames('ValueIndicator', { empty: !isValid && emptyValue === defaultEmptyValue })

  return <span className={classes}>{isValid ? getFormattedValue(value, type, maximumFractionDigits, shorten, sign, minValue) : emptyValue}</span>
}

const getFormattedValue = (
  value: number,
  type: ValueType,
  maximumFractionDigits: number | undefined,
  shorten: boolean,
  sign: boolean,
  minValue: undefined | number
) => {
  const prefix = !isNil(minValue) && value < (minValue as number) ? '< ' : ''
  const valueToFormat = !isNil(minValue) && value < (minValue as number) ? (minValue as number) : value
  if (type === 'number') {
    return prefix + utilsService.formatNumber(valueToFormat, { shorten, mantissa: maximumFractionDigits, sign })
  } else if (type === 'currency') {
    return prefix + utilsService.formatCurrency(valueToFormat, { shorten, mantissa: maximumFractionDigits, sign })
  }
}
