import { VisualAnalysis } from '../../../api/connect'
import { getAttributeNameByModel } from '../hooks/useIconAttributes'
import { RankType, VisualAnalysisWithRank, VisualsAttribute, VisualSortType } from '../types'

type IconComparatorConstructor = (
  sortBy: VisualSortType,
  rankBy: RankType,
  attributes: VisualsAttribute[]
) => (i1: VisualAnalysisWithRank, i2: VisualAnalysisWithRank) => number

type AnalysisRankComparatorConstructor = (ranks: { [appId: number]: number }) => (a1: VisualAnalysis, a2: VisualAnalysis) => number

/**
 * Compare VisualAnalysisWithRank objects by named attribute value or rank.
 *
 * @param sortBy 'attribute' or 'rank'
 * @param rankBy free or grossing
 * @param model of visual analysis
 * @param attributeName to compare if 'attribute' selected
 */
export const getIconComparator: IconComparatorConstructor = (sortBy, rankBy, attributes) => {
  if (sortBy === 'rank') {
    return (i1, i2) => (rankBy === 'free' ? i1.sdrank - i2.sdrank : i1.srank - i2.srank)
  }
  if (!attributes) {
    return (i1, i2) => 0
  }
  return (i1, i2) => {
    let a1 = 0
    let a2 = 0
    attributes.forEach((attribute) => {
      const key = getAttributeNameByModel(attribute.model)
      const k1 = i1[key]
      const k2 = i2[key]
      a1 += k1 ? k1[attribute.name] : 0
      a2 += k2 ? k2[attribute.name] : 0
    })
    return a1 > a2 ? -1 : 1
  }
}

/**
 * A simpler comparator for VisualAnalysis objects: Better rank first.
 *
 * @param ranks map of ranks by appId
 */
export const getAnalysisRankComparator: AnalysisRankComparatorConstructor = (ranks) => (a1, a2) => {
  return ranks[a2.appId] - ranks[a1.appId] > 0 ? -1 : 1
}
