import { TooltipCallbacks } from 'chart.js'
import { startOfDay } from 'date-fns'
import { FC, ReactNode, useMemo } from 'react'
import { Trans } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Grid } from '@mui/material'

import { DoughnutChart } from '../../../../../components/DoughnutChart/DoughnutChart'
import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { OverlayLoader } from '../../../../../components/OverlayLoader/OverlayLoader'
import utilsService from '../../../../../services/UtilsService'
import { useDurationsChartData, useEventStatisticsLimitedTimeRange, useLiveEventTypeDurationCategories } from '../../../hooks/liveEventsEventTypeStatistics'

/**
 * Component that represents Live Events Event Type Statistics Dialog's Durations tab
 */
type LiveEventsEventStatisticsDurationsTabProps = {
  trackedGameIds: string[]
  eventTypeId: string
  children?: ReactNode
}

export const LiveEventsEventStatisticsDurationsTab: FC<LiveEventsEventStatisticsDurationsTabProps> = ({ trackedGameIds, eventTypeId, children }) => {
  const timeRange = useEventStatisticsLimitedTimeRange({ timeStart: 0, timeEnd: startOfDay(new Date()).getTime() })
  const { trackingEventsByDurationCategory, isLoading } = useLiveEventTypeDurationCategories({ trackedGameIds, eventTypeId, dateRange: timeRange })
  const chartData = useDurationsChartData({ trackingEventsByDurationCategory })

  // define how chart tooltip values are formatted
  const tooltipCallbacks: Partial<TooltipCallbacks<'doughnut'>> = useMemo(
    () => ({
      label: (tooltipItem) => `${tooltipItem.label}: ${utilsService.formatPercent(tooltipItem.parsed, { maximumFractionDigits: 1 })}`,
    }),
    []
  )

  return (
    <Card>
      <CardHeader
        title={
          <Grid container spacing={1}>
            <Grid item>
              <Trans i18nKey="live-events:event_statistics_dialog_title_session_lengths" />
            </Grid>
            <Grid item>
              <GRTooltip content={<Trans i18nKey="live-events:event_statistics_dialog_title_durations_chart_tooltip" />}>
                <Info color="primary" />
              </GRTooltip>
            </Grid>
          </Grid>
        }
      />
      <CardContent sx={{ width: '50%', margin: '0 auto' }}>
        <OverlayLoader isLoading={isLoading} disableShrink>
          <DoughnutChart data={chartData} tooltipCallbacks={tooltipCallbacks} />
        </OverlayLoader>
      </CardContent>
    </Card>
  )
}
