import { add } from 'date-fns'

import { IntervalsByDurationCategory, LiveEventTypeDurationCategory } from '../types/LiveEventStatistics'

export const defaultTrackedEventsStartTimestamp = new Date('2022-08-01T00:00:00Z')
export const lockedLiveEventsTrackerStartTimestamp = Date.UTC(2022, 10, 1)
export const lockedLiveEventsTrackerEndTimestamp = Date.UTC(2022, 10, 14)

export const eventStatisticsTimeStartMin = Date.UTC(2023, 1, 15)
export const eventStatisticsPerformanceTimeStartMin = add(eventStatisticsTimeStartMin, { days: 14 }).getTime()

const millisecondsInDay = 24 * 60 * 60 * 1000
export const durationByDurationCategory: IntervalsByDurationCategory = {
  [LiveEventTypeDurationCategory.Days0To1]: { start: 0, end: 1 * millisecondsInDay },
  [LiveEventTypeDurationCategory.Days2To3]: { start: 1 * millisecondsInDay, end: 3 * millisecondsInDay },
  [LiveEventTypeDurationCategory.Days4To5]: { start: 3 * millisecondsInDay, end: 5 * millisecondsInDay },
  [LiveEventTypeDurationCategory.Days6To7]: { start: 5 * millisecondsInDay, end: 7 * millisecondsInDay },
  [LiveEventTypeDurationCategory.Days8To22]: { start: 7 * millisecondsInDay, end: 22 * millisecondsInDay },
  [LiveEventTypeDurationCategory.Days23To30]: { start: 22 * millisecondsInDay, end: 30 * millisecondsInDay },
  [LiveEventTypeDurationCategory.Days31OrMore]: { start: 30 * millisecondsInDay, end: Infinity },
}
