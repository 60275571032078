import { MarketExplorerGamesChartAxisType } from './MarketExplorerGamesChartAxisType'

export enum MarketExplorerGamesChartAxisGroupId {
  FeatureIndicators = 'feature-indicators',
  SustainedRanks = 'sustained-ranks',
  Revenue = 'revenue',
  Downloads = 'downloads',
  RevenueDownloadsRatio = 'revenue-downloads-ratio',
  ReleaseDate = 'release-date',
  DemographicsGender = 'demographics-gender',
  DemographicsAge = 'demographics-age',
}

export interface MarketExplorerGamesChartAxisGroup {
  id: MarketExplorerGamesChartAxisGroupId
  name: string
  types: MarketExplorerGamesChartAxisType[]
}
