import { FC, useCallback, useEffect, useState } from 'react'

import { Grid } from '@mui/material'

import GameSubgenrePicker from '../../../../components/GameSubgenrePicker/GameSubgenrePicker'
import { VersionTagSelector } from '../../../../components/VersionTagSelector/VersionTagSelector'
import { useSubgenrePicker } from '../../../../hooks/useSubgenrePicker'
import analyticsService from '../../../../services/AnalyticsService'
import { AnalyticsProviders } from '../../../../types/IAnalyticsProvider'
import { VersionTag } from '../../../../types/VersionTag'
import { SubgenreMap } from '../../../account'
import { ComparisonInterval } from '../../types/ComparisonInterval'
import { TimeSinceUpdated } from '../../types/TimeSinceUpdated'
import { UpdateImpactRowFilters } from '../../types/UpdateImpactRowFilters'
import { ComparisonIntervalSelector } from '../ComparisonIntervalSelector/ComparisonIntervalSelector'
import { TimeSinceUpdatedSelector } from '../TimeSinceUpdatedSelector/TimeSinceUpdatedSelector'

/**
 * Container component for update impact table filters
 */
type UpdateImpactFiltersProps = {
  filters: UpdateImpactRowFilters
  onChange: (value: UpdateImpactRowFilters) => void
  showVersionTagSelector?: boolean
  showSubgenrePicker?: boolean
  showTimeSinceUpdatedSelector?: boolean
  showComparisonIntervalSelector?: boolean
  moduleId?: string
  setSelectedSubgenres?: (subgenres: SubgenreMap) => void
}

export const UpdateImpactFilters: FC<UpdateImpactFiltersProps> = ({
  filters,
  onChange,
  showVersionTagSelector,
  showSubgenrePicker,
  showTimeSinceUpdatedSelector,
  showComparisonIntervalSelector,
  moduleId,
  setSelectedSubgenres,
}) => {
  const { subgenres, handleSubgenresChange } = useSubgenrePicker(filters.subgenres)
  const [timeSinceUpdated, setTimeSinceUpdated] = useState<TimeSinceUpdated>(filters.timeSinceUpdated || TimeSinceUpdated.MONTHS_6)
  const [comparisonInterval, setComparisonInterval] = useState<ComparisonInterval>(filters.comparisonInterval || ComparisonInterval.DAYS_7)
  const [selectedVersionTags, setSelectedVersionTags] = useState<VersionTag[]>(filters.versionTags || [])

  const handleTimeSinceUpdatedChange = useCallback(
    (value: string) => {
      if (moduleId) {
        analyticsService.trackEvent(`${moduleId}: Changed Shown Data`, {
          data: {
            typeId: value,
          },
          serviceToExclude: [AnalyticsProviders.hubspot],
        })
      }
      setTimeSinceUpdated(value as TimeSinceUpdated)
    },
    [moduleId]
  )

  const handleVersionTagSelectionChange = useCallback((value: VersionTag[]) => {
    setSelectedVersionTags(value)
  }, [])

  const handleComparisonIntervalChange = useCallback(
    (value: string) => {
      if (moduleId) {
        analyticsService.trackEvent(`${moduleId}: Changed Show Data`, {
          data: {
            typeId: value,
          },
          serviceToExclude: [AnalyticsProviders.hubspot],
        })
      }
      setComparisonInterval(value as ComparisonInterval)
    },
    [moduleId]
  )

  useEffect(() => {
    setSelectedSubgenres && setSelectedSubgenres(subgenres)
  }, [setSelectedSubgenres, subgenres])

  useEffect(() => {
    onChange({ subgenres, timeSinceUpdated, versionTags: selectedVersionTags, comparisonInterval })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subgenres, timeSinceUpdated, selectedVersionTags, comparisonInterval])

  return (
    <>
      {showSubgenrePicker && <GameSubgenrePicker selectedSubgenres={subgenres} selectionChanged={handleSubgenresChange} showSubgenreDefinitionBtn />}
      <Grid container wrap="wrap" rowSpacing={2}>
        {showTimeSinceUpdatedSelector && (
          <Grid item>
            <TimeSinceUpdatedSelector value={timeSinceUpdated} onChange={handleTimeSinceUpdatedChange} />
          </Grid>
        )}
        <Grid item container wrap="wrap" justifyContent="space-between" spacing={2}>
          <Grid item>{showVersionTagSelector && <VersionTagSelector selectedTags={selectedVersionTags} onChange={handleVersionTagSelectionChange} />}</Grid>
          <Grid item>
            {showComparisonIntervalSelector && <ComparisonIntervalSelector value={comparisonInterval} onChange={handleComparisonIntervalChange} />}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
