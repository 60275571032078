import { useState, useEffect } from 'react'

import { debounce } from '@mui/material'

// a generic custom hook that reports client width and height on resize event (debounced for performance)
export const useClientSize = () => {
  const width = useClientWidth()
  const height = useClientHeight()

  return [width, height]
}

export const useClientWidth = () => {
  const [width, setWidth] = useState(document.documentElement.clientWidth)

  useEffect(() => {
    const debouncedResize = debounce(() => {
      setWidth(document.documentElement.clientWidth)
    }, 100)

    window.addEventListener('resize', debouncedResize)
    return () => {
      debouncedResize.clear()
      window.removeEventListener('resize', debouncedResize)
    }
  }, [])

  return width
}

export const useClientHeight = () => {
  const [height, setHeight] = useState(document.documentElement.clientHeight)

  useEffect(() => {
    const debouncedResize = debounce(() => {
      setHeight(document.documentElement.clientHeight)
    }, 100)

    window.addEventListener('resize', debouncedResize)
    return () => {
      debouncedResize.clear()
      window.removeEventListener('resize', debouncedResize)
    }
  }, [])

  return height
}
