import { useRouteModal } from '../../route-modals/hooks/useRouteModal'
import { EModalType } from '../../route-modals/types/modalTypes'
import { EventStatisticsDialogTab } from './useEventStatisticsDialogTabs'

type UseLiveEventStatisticsModalHookParams = {
  eventTypeId: string
  trackedGameIds: string[]
  tab?: EventStatisticsDialogTab
}

export const useLiveEventEventStatisticsModal = () => {
  const { pushModal } = useRouteModal()
  const showLiveEventStatisticsModal = ({ trackedGameIds, eventTypeId, tab = EventStatisticsDialogTab.Statistics }: UseLiveEventStatisticsModalHookParams) => {
    pushModal({
      type: EModalType.LIVE_EVENT_STATISTICS,
      params: {
        trackedGameIds: trackedGameIds.join('-'),
        liveEventTypeId: eventTypeId,
        tab,
      },
    })
  }

  return {
    showModal: showLiveEventStatisticsModal,
  }
}
