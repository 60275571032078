import { useMemo } from 'react'

import { useGetUserSettingQuery } from '../../../../api/combined'
import { UserSettingKeys, UserSettings } from '../../../../features/account/types/UserSettings'
import { useError } from '../../../../hooks/useError'
import { Analyst } from '../../../../types/Analyst'
import { Setting } from '../../../../types/Setting'
import { useGetAnalystsListQuery, useGetSettingQuery, useGetTrackedGamesQuery } from '../../../api/internal'

export const useTrackedGamesAnalysts = () => {
  const gameAnalystMapQuery = useGameAnalystMapSetting()
  const getAnalystsListQuery = useGetAnalystsListQuery()
  useError({ error: gameAnalystMapQuery.error || getAnalystsListQuery.error })

  return useMemo(
    () => ({
      data: Object.entries(gameAnalystMapQuery.data?.data || {}).reduce<{ [appId: number]: Analyst | undefined }>((acc, [appId, analystId]) => {
        acc[+appId] = getAnalystsListQuery.data?.find(({ id }) => id === analystId)
        return acc
      }, {}),
      isLoading: gameAnalystMapQuery.isFetching || getAnalystsListQuery.isFetching,
    }),
    [gameAnalystMapQuery.data?.data, gameAnalystMapQuery.isFetching, getAnalystsListQuery.data, getAnalystsListQuery.isFetching]
  )
}

export const useTrackedGamesWithAnalystMapping = () => {
  const getTrackedGamesQuery = useGetTrackedGamesQuery()
  const gameAnalystMap = useTrackedGamesAnalysts()

  return useMemo(() => {
    const trackedGames = getTrackedGamesQuery.data?.map((trackedGame) => {
      return {
        ...trackedGame,
        analyst: gameAnalystMap.data[trackedGame.game.appId],
      }
    })

    return {
      data: trackedGames,
      isLoading: getTrackedGamesQuery.isFetching || gameAnalystMap.isLoading,
    }
  }, [gameAnalystMap.data, gameAnalystMap.isLoading, getTrackedGamesQuery.data, getTrackedGamesQuery.isFetching])
}

export const usePinnedTrackedGamesSetting = () => {
  const query = useGetUserSettingQuery(UserSettingKeys.liveeventstrackedPinnedGames)
  return useMemo(
    () => ({
      ...query,
      data: query.data as UserSettings[UserSettingKeys.liveeventstrackedPinnedGames],
    }),
    [query]
  )
}

const useGameAnalystMapSetting = () => {
  const query = useGetSettingQuery({ service: 'apiserver', settingKey: 'game_analyst_map' })
  return useMemo(
    () => ({
      ...query,
      data: query.data as Setting<{ [key: number]: string }> | undefined,
    }),
    [query]
  )
}
