export type MarketExplorerSegmentsDTO = {
  name: string
  description?: string
  params: MarketExplorerSegmentsParams
}

export type MarketExplorerSegmentsParams = {
  axisY: string
  axisYMin: string
  axisYMax: string
  axisX: string
  axisXMin: string
  axisXMax: string

  s1?: string
  s1subgenres?: string
  s1demographicsMale?: string
  s1demographicsFemale?: string
  s1demographicsAge16_24?: string
  s1demographicsAge25_44?: string
  s1demographicsAge45?: string
  s1sranks?: string
  s1sdranks?: string
  s1daysSinceRelease?: string
  s1motivationsEscapismExcitementAndThrill?: string
  s1motivationsEscapismThinkingAndSolving?: string
  s1motivationsExplorationCollectingTreasure?: string
  s1motivationsExplorationDiscoveringNewWorlds?: string
  s1motivationsExpressionCustomizationAndDecoration?: string
  s1motivationsExpressionRolePlayingAndEmotions?: string
  s1motivationsManagementResourceOptimization?: string
  s1motivationsManagementStrategicPlanning?: string
  s1motivationsSocialCompetingAgainstOthers?: string
  s1motivationsSocialWorkingWithOthers?: string
  s1motivationsMasteryImprovingSkills?: string
  s1motivationsMasteryCompletingMilestones?: string
  s1playerArchetypeExpressionist?: string
  s1playerArchetypeThinker?: string
  s1playerArchetypeTreasureHunter?: string
  s1playerArchetypeStrategist?: string
  s1playerArchetypeSkillMaster?: string
  s1playerArchetypeThrillSeeker?: string
  s1playerArchetypeKingOfTheHill?: string
  s1playerArchetypeNetworker?: string
  s1ownGames?: string
  s1choices?: string
  s1features?: string
  s1games?: string

  s2?: string
  s2subgenres?: string
  s2demographicsMale?: string
  s2demographicsFemale?: string
  s2demographicsAge16_24?: string
  s2demographicsAge25_44?: string
  s2demographicsAge45?: string
  s2sranks?: string
  s2sdranks?: string
  s2daysSinceRelease?: string
  s2motivationsEscapismExcitementAndThrill?: string
  s2motivationsEscapismThinkingAndSolving?: string
  s2motivationsExplorationCollectingTreasure?: string
  s2motivationsExplorationDiscoveringNewWorlds?: string
  s2motivationsExpressionCustomizationAndDecoration?: string
  s2motivationsExpressionRolePlayingAndEmotions?: string
  s2motivationsManagementResourceOptimization?: string
  s2motivationsManagementStrategicPlanning?: string
  s2motivationsSocialCompetingAgainstOthers?: string
  s2motivationsSocialWorkingWithOthers?: string
  s2motivationsMasteryImprovingSkills?: string
  s2motivationsMasteryCompletingMilestones?: string
  s2playerArchetypeExpressionist?: string
  s2playerArchetypeThinker?: string
  s2playerArchetypeTreasureHunter?: string
  s2playerArchetypeStrategist?: string
  s2playerArchetypeSkillMaster?: string
  s2playerArchetypeThrillSeeker?: string
  s2playerArchetypeKingOfTheHill?: string
  s2playerArchetypeNetworker?: string
  s2ownGames?: string
  s2choices?: string
  s2features?: string
  s2games?: string

  s3?: string
  s3subgenres?: string
  s3demographicsMale?: string
  s3demographicsFemale?: string
  s3demographicsAge16_24?: string
  s3demographicsAge25_44?: string
  s3demographicsAge45?: string
  s3sranks?: string
  s3sdranks?: string
  s3daysSinceRelease?: string
  s3motivationsEscapismExcitementAndThrill?: string
  s3motivationsEscapismThinkingAndSolving?: string
  s3motivationsExplorationCollectingTreasure?: string
  s3motivationsExplorationDiscoveringNewWorlds?: string
  s3motivationsExpressionCustomizationAndDecoration?: string
  s3motivationsExpressionRolePlayingAndEmotions?: string
  s3motivationsManagementResourceOptimization?: string
  s3motivationsManagementStrategicPlanning?: string
  s3motivationsSocialCompetingAgainstOthers?: string
  s3motivationsSocialWorkingWithOthers?: string
  s3motivationsMasteryImprovingSkills?: string
  s3motivationsMasteryCompletingMilestones?: string
  s3playerArchetypeExpressionist?: string
  s3playerArchetypeThinker?: string
  s3playerArchetypeTreasureHunter?: string
  s3playerArchetypeStrategist?: string
  s3playerArchetypeSkillMaster?: string
  s3playerArchetypeThrillSeeker?: string
  s3playerArchetypeKingOfTheHill?: string
  s3playerArchetypeNetworker?: string
  s3ownGames?: string
  s3choices?: string
  s3features?: string
  s3games?: string

  s4?: string
  s4subgenres?: string
  s4demographicsMale?: string
  s4demographicsFemale?: string
  s4demographicsAge16_24?: string
  s4demographicsAge25_44?: string
  s4demographicsAge45?: string
  s4sranks?: string
  s4sdranks?: string
  s4daysSinceRelease?: string
  s4motivationsEscapismExcitementAndThrill?: string
  s4motivationsEscapismThinkingAndSolving?: string
  s4motivationsExplorationCollectingTreasure?: string
  s4motivationsExplorationDiscoveringNewWorlds?: string
  s4motivationsExpressionCustomizationAndDecoration?: string
  s4motivationsExpressionRolePlayingAndEmotions?: string
  s4motivationsManagementResourceOptimization?: string
  s4motivationsManagementStrategicPlanning?: string
  s4motivationsSocialCompetingAgainstOthers?: string
  s4motivationsSocialWorkingWithOthers?: string
  s4motivationsMasteryImprovingSkills?: string
  s4motivationsMasteryCompletingMilestones?: string
  s4playerArchetypeExpressionist?: string
  s4playerArchetypeThinker?: string
  s4playerArchetypeTreasureHunter?: string
  s4playerArchetypeStrategist?: string
  s4playerArchetypeSkillMaster?: string
  s4playerArchetypeThrillSeeker?: string
  s4playerArchetypeKingOfTheHill?: string
  s4playerArchetypeNetworker?: string
  s4ownGames?: string
  s4choices?: string
  s4features?: string
  s4games?: string

  s5?: string
  s5subgenres?: string
  s5demographicsMale?: string
  s5demographicsFemale?: string
  s5demographicsAge16_24?: string
  s5demographicsAge25_44?: string
  s5demographicsAge45?: string
  s5sranks?: string
  s5sdranks?: string
  s5daysSinceRelease?: string
  s5motivationsEscapismExcitementAndThrill?: string
  s5motivationsEscapismThinkingAndSolving?: string
  s5motivationsExplorationCollectingTreasure?: string
  s5motivationsExplorationDiscoveringNewWorlds?: string
  s5motivationsExpressionCustomizationAndDecoration?: string
  s5motivationsExpressionRolePlayingAndEmotions?: string
  s5motivationsManagementResourceOptimization?: string
  s5motivationsManagementStrategicPlanning?: string
  s5motivationsSocialCompetingAgainstOthers?: string
  s5motivationsSocialWorkingWithOthers?: string
  s5motivationsMasteryImprovingSkills?: string
  s5motivationsMasteryCompletingMilestones?: string
  s5playerArchetypeExpressionist?: string
  s5playerArchetypeThinker?: string
  s5playerArchetypeTreasureHunter?: string
  s5playerArchetypeStrategist?: string
  s5playerArchetypeSkillMaster?: string
  s5playerArchetypeThrillSeeker?: string
  s5playerArchetypeKingOfTheHill?: string
  s5playerArchetypeNetworker?: string
  s5ownGames?: string
  s5choices?: string
  s5features?: string
  s5games?: string

  s6?: string
  s6subgenres?: string
  s6demographicsMale?: string
  s6demographicsFemale?: string
  s6demographicsAge16_24?: string
  s6demographicsAge25_44?: string
  s6demographicsAge45?: string
  s6sranks?: string
  s6sdranks?: string
  s6daysSinceRelease?: string
  s6motivationsEscapismExcitementAndThrill?: string
  s6motivationsEscapismThinkingAndSolving?: string
  s6motivationsExplorationCollectingTreasure?: string
  s6motivationsExplorationDiscoveringNewWorlds?: string
  s6motivationsExpressionCustomizationAndDecoration?: string
  s6motivationsExpressionRolePlayingAndEmotions?: string
  s6motivationsManagementResourceOptimization?: string
  s6motivationsManagementStrategicPlanning?: string
  s6motivationsSocialCompetingAgainstOthers?: string
  s6motivationsSocialWorkingWithOthers?: string
  s6motivationsMasteryImprovingSkills?: string
  s6motivationsMasteryCompletingMilestones?: string
  s6playerArchetypeExpressionist?: string
  s6playerArchetypeThinker?: string
  s6playerArchetypeTreasureHunter?: string
  s6playerArchetypeStrategist?: string
  s6playerArchetypeSkillMaster?: string
  s6playerArchetypeThrillSeeker?: string
  s6playerArchetypeKingOfTheHill?: string
  s6playerArchetypeNetworker?: string
  s6ownGames?: string
  s6choices?: string
  s6features?: string
  s6games?: string
}

export enum LegacySegments {
  Segment1 = 's1',
  Segment2 = 's2',
  Segment3 = 's3',
  Segment4 = 's4',
  Segment5 = 's5',
  Segment6 = 's6',
}
