import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { displaySnackBar } from '../features/snackbar'
import { useAppDispatch } from './storeHooks'

export const useCopyToClipboard = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const copyToClipboard = useCallback(
    (url?: string) => {
      const location = url ? url : window.location.href
      if (navigator.clipboard) {
        navigator.clipboard.writeText(location)
        const NOTIFY_TEXT = t('common:notify_url_copied_clipboard')
        dispatch(displaySnackBar({ message: NOTIFY_TEXT, severity: 'success', open: true }))
      } else {
        dispatch(displaySnackBar({ message: t('common:notify_url_not_copied_clipboard'), severity: 'error', open: true }))
      }
    },
    [dispatch, t]
  )
  return copyToClipboard
}
