import { FC, ReactNode } from 'react'

import { Grid } from '@mui/material'

import { Game, GameIcon } from '../../../../game'

/**
 * A component rendering a grid of game icons
 */
type SegmentGamesIconGridProps = {
  games?: Game[]
  children?: ReactNode
}

export const SegmentGamesIconGrid: FC<SegmentGamesIconGridProps> = ({ games = [] }) => {
  return games.length > 0 ? (
    <Grid container wrap="wrap" spacing={1}>
      {games?.map((game) => {
        return (
          <Grid item key={game.id}>
            <GameIcon src={game.icons ? game.icons[game.icons.length - 1] : (game.icon as string)} gameName={game.resolvedName} />
          </Grid>
        )
      })}
    </Grid>
  ) : null
}
