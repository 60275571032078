import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactCalendarTimeline, {
  DateHeader,
  TimelineHeaders,
  ReactCalendarItemRendererProps,
  TimelineMarkers,
  ReactCalendarGroupRendererProps,
  Unit,
  ReactCalendarTimelineProps,
  Id,
} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import { useTranslation } from 'react-i18next'

import { Card, Typography } from '@mui/material'

import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { useCurrentUserLanguage } from '../../../account/hooks/useCurrentUserLanguage'
import { useMobileSize } from '../../../responsiveness/hooks/responsivenessHooks'
import { LiveEventTagGroup } from '../../types/LiveEventTagGroup'
import {
  LiveEventsCalendarTimelineGroup,
  LiveEventsCalendarTimelineType,
  LiveEventsCalendarTimeLineItem,
  TrackingEventTimelineGroup,
  TrackingEventTimelineItem,
  TrackingEventWithGameTimelineGroup,
} from '../../types/LiveEvents'
import { TrackingEvent } from '../../types/TrackingEvents'
import { getNextCalendarHeaderUnit } from '../../utils/utils'
import './LiveEventsCalendar.scss'
import { LiveEventsCalendarGroup } from './LiveEventsCalendarGroup/LiveEventsCalendarGroup'
import { LiveEventsCalendarItem } from './LiveEventsCalendarItem/LiveEventsCalendarItem'
import { TimeRangeMarker } from './TimeRangeMarker/TimeRangeMarker'

const today = +new Date()

interface Props {
  allTrackedEvents: TrackingEvent[]
  groups: LiveEventsCalendarTimelineGroup[]
  items: LiveEventsCalendarTimeLineItem[]
  timeStart?: number
  timeEnd?: number
  minZoom?: number
  maxZoom?: number
  onItemClick: (clickedItem: TrackingEventTimelineItem) => void
  onGroupClick: (clickedGroup: TrackingEventTimelineGroup) => void
  onCalendarTimeRangeChange?: ReactCalendarTimelineProps['onTimeChange']
  isLoading?: boolean
  calendarHidden?: boolean
  lineHeight?: number
  sidebarHeader: JSX.Element
  datePrimaryHeaderHeight?: number
  showEmptyCalendarIndicator?: boolean
}

const LiveEventsCalendar: React.FC<Props> = ({
  allTrackedEvents,
  groups,
  items,
  timeStart,
  timeEnd,
  minZoom,
  maxZoom,
  onItemClick,
  onGroupClick,
  onCalendarTimeRangeChange,
  isLoading,
  calendarHidden,
  lineHeight,
  sidebarHeader,
  showEmptyCalendarIndicator = true,
}) => {
  const { t } = useTranslation()
  const mobileSize = useMobileSize()
  const scrollRef = useRef<HTMLDivElement>()
  const headerTimeoutRef = useRef<number>()
  const mouseDownTime = useRef<number>(0)
  const userLanguage = useCurrentUserLanguage()
  const [primaryUnit, setPrimaryUnit] = useState<Unit | 'primaryHeader'>('primaryHeader')

  // resolve date range min/max values based on Tracking Event data
  const trackingEventTimeRangeByData = useMemo(() => {
    const timestamps = allTrackedEvents.reduce(
      (acc, item) => {
        if (item) {
          acc.minTimeData = item.start < acc.minTimeData || acc.minTimeData === 0 ? item.start : acc.minTimeData
          acc.maxTimeData = item.end > acc.maxTimeData ? item.end : acc.maxTimeData
        }

        return acc
      },
      { minTimeData: 0, maxTimeData: 0 } as { minTimeData: number; maxTimeData: number }
    )
    if (timestamps.maxTimeData === 0 && timestamps.minTimeData === 0) return timestamps
    const minDate = new Date(timestamps.minTimeData)
    const maxDate = new Date(timestamps.maxTimeData)
    minDate.setHours(0, 0, 0, 0)
    maxDate.setHours(23, 59, 59, 999)
    const modifiedTimestamps = {
      minTimeData: minDate.getTime(),
      maxTimeData: maxDate.getTime(),
    }
    return modifiedTimestamps
  }, [allTrackedEvents])

  // resolve filtered date range min/max values based on filtered Tracking Event data
  const filteredTrackingEventTimeRangeByData = useMemo(() => {
    return items.reduce(
      (acc, item) => {
        if (item.type === LiveEventsCalendarTimelineType.TrackingEvent) {
          acc.minTimeData = item.start_time < acc.minTimeData || acc.minTimeData === 0 ? item.start_time : acc.minTimeData
          acc.maxTimeData = item.end_time > acc.maxTimeData ? item.end_time : acc.maxTimeData
        }

        return acc
      },
      { minTimeData: 0, maxTimeData: 0 } as { minTimeData: number; maxTimeData: number }
    )
  }, [items])

  const itemRenderer = useCallback((itemProps: ReactCalendarItemRendererProps<LiveEventsCalendarTimeLineItem>) => <LiveEventsCalendarItem {...itemProps} />, [])

  const groupRenderer = useCallback(
    (groupProps: ReactCalendarGroupRendererProps<LiveEventsCalendarTimelineGroup>) => <LiveEventsCalendarGroup {...groupProps} onClick={onGroupClick} />,
    [onGroupClick]
  )

  const handleHorizontalLineClassNamesForGroup = useCallback((group: LiveEventsCalendarTimelineGroup) => {
    if (group.type === LiveEventsCalendarTimelineType.TrackingEventWithGame) {
      const typedGroup = group as TrackingEventWithGameTimelineGroup
      const classes = []
      classes.push(typedGroup.groupIndex % 2 === 0 ? 'group-even' : 'group-odd')
      typedGroup.firstOfGroup && classes.push('first-of-group')
      return [
        ...classes,
        group.type,
        typedGroup.tagGroupId && typedGroup.tagGroupId === LiveEventTagGroup.DEFINING_OCCURRENCES ? `${typedGroup.type}--SpecialEvent` : '',
      ]
    } else {
      const typedGroup = group as TrackingEventWithGameTimelineGroup
      return [
        typedGroup.type,
        typedGroup.tagGroupId && typedGroup.tagGroupId === LiveEventTagGroup.DEFINING_OCCURRENCES ? `${typedGroup.type}--SpecialEvent` : '',
      ]
    }
  }, [])

  const formatTimelineDateHeaders = useCallback(
    ([timeStart, timeEnd]: [any, any], unit: Unit, labelWidth: number) => {
      const formatOptions = userLanguage === 'zh' || userLanguage === 'ja' ? defaultHeaderFormatsZHJA : defaultHeaderFormatsEN
      const resolvedUnit = unit as 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year'
      let format
      if (labelWidth >= 175) {
        format = formatOptions[resolvedUnit]?.['long']
      } else if (labelWidth >= 125) {
        format = formatOptions[resolvedUnit]?.['mediumLong']
      } else if (labelWidth >= 50) {
        format = formatOptions[resolvedUnit]?.['medium']
      } else {
        format = formatOptions[resolvedUnit]?.['short'] || 'll'
      }
      return timeStart.format(format)
    },
    [userLanguage]
  )

  const formatSecondaryHeader = useCallback(
    ([timeStart, timeEnd]: [any, any], unit: Unit, labelWidth: number) => {
      // this needs to be inside a timeout since the calendar component calls its callbacks directly on render function
      headerTimeoutRef.current = window.setTimeout(() => setPrimaryUnit(getNextCalendarHeaderUnit(unit)), 0)
      return formatTimelineDateHeaders([timeStart, timeEnd], unit, labelWidth)
    },
    [formatTimelineDateHeaders]
  )

  useEffect(() => () => clearTimeout(headerTimeoutRef.current), [])

  // track mouse down time to determine if it's a click or a drag
  useEffect(() => {
    const handleMouseDown = () => {
      mouseDownTime.current = +new Date()
    }

    const element = scrollRef.current
    element?.addEventListener('mousedown', handleMouseDown)

    return () => {
      element?.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  const handleItemClick = useCallback(
    (itemId: Id) => {
      const delay = +new Date() - mouseDownTime.current
      const item = items.find((item) => item.id === itemId)
      if (item && delay <= 300) {
        onItemClick(item as TrackingEventTimelineItem)
      }
    },
    [items, onItemClick]
  )

  const timelineClasses = classNames('LiveEventsCalendar__Timeline', { calendarHidden })

  return (
    <div className="LiveEventsCalendar">
      <Card className="LiveEventsCalendar__Card">
        <div className={timelineClasses}>
          <ReactCalendarTimeline
            scrollRef={(ref) => (scrollRef.current = ref)}
            groups={groups}
            items={items}
            visibleTimeStart={timeStart}
            visibleTimeEnd={timeEnd}
            onTimeChange={onCalendarTimeRangeChange}
            itemRenderer={itemRenderer}
            groupRenderer={groupRenderer}
            minZoom={minZoom}
            maxZoom={maxZoom}
            sidebarWidth={300}
            lineHeight={lineHeight}
            horizontalLineClassNamesForGroup={handleHorizontalLineClassNamesForGroup}
            onItemClick={handleItemClick}
            onItemSelect={handleItemClick}
          >
            <TimelineHeaders className={mobileSize ? '' : 'LiveEventsCalendar__StickyHeader'}>
              {sidebarHeader}
              <DateHeader className="LiveEventsCalendar__PrimaryDateHeader" unit={primaryUnit} height={94} labelFormat={formatTimelineDateHeaders} />
              <DateHeader labelFormat={formatSecondaryHeader} height={33} />
            </TimelineHeaders>
            <TimelineMarkers>
              <TimeRangeMarker time={today} tooltipLabel={t('live-events:today')} />
              {trackingEventTimeRangeByData.minTimeData && (
                <TimeRangeMarker time={trackingEventTimeRangeByData.minTimeData} tooltipLabel={t('live-events:tracking_started')} severity="success" />
              )}
              {trackingEventTimeRangeByData.maxTimeData && (
                <TimeRangeMarker time={trackingEventTimeRangeByData.maxTimeData} tooltipLabel={t('live-events:tracking_ends')} severity="warning" />
              )}
              {trackingEventTimeRangeByData.minTimeData !== filteredTrackingEventTimeRangeByData.minTimeData &&
                filteredTrackingEventTimeRangeByData.minTimeData && (
                  <TimeRangeMarker
                    time={filteredTrackingEventTimeRangeByData.minTimeData}
                    tooltipLabel={t('live-events:filtered_tracking_started')}
                    styleOverrides={{ backgroundColor: '#273143' }}
                  />
                )}
              {trackingEventTimeRangeByData.maxTimeData !== filteredTrackingEventTimeRangeByData.maxTimeData &&
                filteredTrackingEventTimeRangeByData.maxTimeData && (
                  <TimeRangeMarker
                    time={filteredTrackingEventTimeRangeByData.maxTimeData}
                    tooltipLabel={t('live-events:filtered_tracking_ends')}
                    styleOverrides={{ backgroundColor: '#273143' }}
                  />
                )}
            </TimelineMarkers>
          </ReactCalendarTimeline>
        </div>
        {isLoading ? (
          <GRCircularProgress circularProgressProps={{ size: 24 }} m={1} />
        ) : (
          groups.length === 0 &&
          items.length === 0 &&
          showEmptyCalendarIndicator && (
            <Typography className="LiveEventsCalendar__Info" variant="body2" m={1} textAlign="center">
              {t('live-events:no_live_events_found_for_selected_filters')}
            </Typography>
          )
        )}
      </Card>
    </div>
  )
}

export default LiveEventsCalendar

export const defaultHeaderFormatsEN = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY',
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY',
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w',
  },
  day: {
    long: 'ddd, ll',
    mediumLong: 'ddd, ll',
    medium: 'dd D',
    short: 'D',
  },
  hour: {
    long: 'dddd, LL, HH:00',
    mediumLong: 'L, HH:00',
    medium: 'HH:00',
    short: 'HH',
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  },
}

export const defaultHeaderFormatsZHJA = {
  year: {
    long: 'YYYY年',
    mediumLong: 'YYYY年',
    medium: 'YYYY年',
    short: 'YY年',
  },
  month: {
    long: 'YYYY年M月',
    mediumLong: 'M月',
    medium: 'M月',
    short: 'YY年MM月',
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w',
  },
  day: {
    long: 'D (dd)',
    mediumLong: 'D (dd)',
    medium: 'D (dd)',
    short: 'D (dd)',
  },
  hour: {
    long: 'dddd, LL, HH:00',
    mediumLong: 'L, HH:00',
    medium: 'HH:00',
    short: 'HH',
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm',
  },
}
