import { useMemo } from 'react'

import { Tag, TagGroup, useGetTagGroupsListQuery, useGetTagListQuery } from '../../../api/core'
import languageService from '../../../services/LanguageService'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import { LiveEventTagGroup } from '../types/LiveEventTagGroup'

export const useLiveEventTagGroups = () => {
  const userLanguage = useCurrentUserLanguage()
  const liveEventTagGroups = useGetTagGroupsListQuery({ type: 'event', userLanguage })
  return liveEventTagGroups
}

export const useLiveEventTags = () => {
  const userLanguage = useCurrentUserLanguage()
  const liveEventTags = useGetTagListQuery({ type: 'event', userLanguage })
  return liveEventTags
}

export const useNonSpecialLiveEventTags = () => {
  const userLanguage = useCurrentUserLanguage()
  const liveEventTags = useGetTagListQuery({ type: 'event', userLanguage })
  const liveEventTagGroupsMapByEventTypeId = useLiveEventTagGroupsMapByEventTypeId()

  const filteredEventTags = useMemo(() => {
    if (liveEventTags.data && liveEventTagGroupsMapByEventTypeId) {
      return liveEventTags.data.reduce((acc, tag) => {
        if (liveEventTagGroupsMapByEventTypeId[tag.id] && liveEventTagGroupsMapByEventTypeId[tag.id].id !== LiveEventTagGroup.DEFINING_OCCURRENCES) {
          acc.push(tag)
        }

        return acc
      }, [] as Tag[])
    }

    return []
  }, [liveEventTags.data, liveEventTagGroupsMapByEventTypeId])

  return { data: filteredEventTags }
}

export const useLiveEventTagGroupsMapByEventTypeId = () => {
  const liveEventsTagGroups = useLiveEventTagGroups()

  const liveEventTagGroupsMapByEventTypeId: { [eventTypeId: string]: TagGroup } = useMemo(() => {
    const map: { [eventTypeId: string]: TagGroup } = {}
    if (liveEventsTagGroups.data) {
      liveEventsTagGroups.data.forEach((liveEventTagGroup: TagGroup) => {
        liveEventTagGroup.tags.forEach((liveEventTypeId) => {
          map[liveEventTypeId] = { ...liveEventTagGroup, name: languageService.getTranslation('tagGroups', liveEventTagGroup.id) }
        })
      })
    }

    return map
  }, [liveEventsTagGroups])

  return liveEventTagGroupsMapByEventTypeId
}

export const useLiveEventTagGroupWithEventTypeId = (eventTypeId: string) => {
  const liveEventTagGroupsMapByEventTypeId = useLiveEventTagGroupsMapByEventTypeId()

  return liveEventTagGroupsMapByEventTypeId[eventTypeId]
}

export const useLiveEventTagGroupsWithTags = () => {
  const liveEventTags = useLiveEventTags()
  const liveEventTagGroups = useLiveEventTagGroups()

  return useMemo(() => {
    const result = {
      isSuccess: liveEventTags.isSuccess && liveEventTagGroups.isSuccess,
      isError: liveEventTags.isError || liveEventTagGroups.isError,
      isFetching: liveEventTags.isFetching || liveEventTagGroups.isFetching,
      isLoading: liveEventTags.isLoading || liveEventTagGroups.isLoading,
    }
    if (liveEventTags.data && liveEventTagGroups.data) {
      const data = [...liveEventTagGroups.data]
        .sort((a, b) => a.order - b.order)
        .map((group) => ({
          ...group,
          name: languageService.getTranslation('tagGroups', group.id),
          tags: group.tags
            .map((id) => liveEventTags.data?.find((t) => t.id === id))
            ?.filter((t): t is Tag => t !== undefined && t.active)
            .map((tag) => ({
              ...tag,
              name: languageService.getTranslation('tags', tag.id),
              description: languageService.getTranslation('tags', tag.id, 'description') || undefined,
              imageText: languageService.getTranslation('tags', tag.id, 'imageText') || undefined,
            })),
        }))
      return {
        data,
        ...result,
      }
    } else {
      return {
        data: undefined,
        ...result,
      }
    }
  }, [
    liveEventTagGroups.data,
    liveEventTagGroups.isError,
    liveEventTagGroups.isFetching,
    liveEventTagGroups.isLoading,
    liveEventTagGroups.isSuccess,
    liveEventTags.data,
    liveEventTags.isError,
    liveEventTags.isFetching,
    liveEventTags.isLoading,
    liveEventTags.isSuccess,
  ])
}
