import { createSelector } from '@reduxjs/toolkit'
import { CombinedState } from '@reduxjs/toolkit/query'

import { RootState } from '../../../store'

export const selectApiQueries = createSelector(
  (state: RootState) => state,
  (state: RootState) =>
    Object.values(state)
      .filter((store) => !!(store as CombinedState<any, any, any>).queries)
      .map((store) => (store as CombinedState<any, any, any>).queries)
)
