export enum GameKeyIndicatorType {
  FeatureIndicators = 'feature-indicators',
  SustainedRanks = 'sustained-ranks',
  Performance30Days = 'performance-30-days',
  Performance180Days = 'performance-180-days',
  Performance360Days = 'performance-360-days',
  ReleaseDate = 'release-date',
  DemographicsGender = 'demographics-gender',
  DemographicsAge = 'demographics-age',
}
