import classNames from 'classnames'
import { CSSProperties, memo, ReactNode, useRef } from 'react'
import { VirtualItem } from 'react-virtual/types'

import { SxProps, TableRow } from '@mui/material'

import { BodyCell } from '../BodyCell/BodyCell'
import type { GRTableColumn } from '../GRTable'
import { findParentColumn, isLastVisibleColumnOfParent, getChildColumns, stickyColumnProps } from '../helpers/tableHelpers'
import styles from './VirtualRow.module.scss'

/**
 * Component representing a virtual row in a virtualized table
 */

type VirtualizedRowProps<RowType, CustomPropsType, ColumnIdType = void> = {
  row: RowType
  virtualRow: VirtualItem & { style?: CSSProperties }
  columns: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  parentColumns?: GRTableColumn<RowType, CustomPropsType, ColumnIdType>[]
  getCellValue: ({ ...args }: { row: RowType; col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>; rowIndex: number }) => ReactNode
  getColumnHiddenValue: (col: GRTableColumn<RowType, CustomPropsType, ColumnIdType>) => boolean
  hoverable?: boolean
  striped?: boolean
  style?: CSSProperties
  className?: string
  gridlines?: boolean
}

const RecursiveVirtualRow = <RowType extends object, CustomPropsType extends object | undefined, ColumnIdType extends string | void>({
  row,
  virtualRow,
  columns,
  parentColumns = [],
  getCellValue,
  getColumnHiddenValue,
  striped,
  style,
  hoverable,
  className,
  gridlines,
}: VirtualizedRowProps<RowType, CustomPropsType, ColumnIdType>) => {
  const childColumns = getChildColumns(columns)
  const rowRef = useRef<HTMLTableRowElement>(null)
  const rowClassName = classNames(className, { [styles.even]: striped && virtualRow.index % 2 === 0 })

  return childColumns.length > 0 ? (
    <RecursiveVirtualRow
      row={row}
      virtualRow={virtualRow}
      columns={childColumns}
      className={rowClassName}
      parentColumns={columns}
      getCellValue={getCellValue}
      getColumnHiddenValue={getColumnHiddenValue}
      striped={striped}
      style={style}
      hoverable={hoverable}
    />
  ) : (
    <TableRow
      ref={virtualRow.measureRef}
      style={{
        height: `${rowRef!.current?.offsetHeight}px`,
        transform: `translateY(${virtualRow.start}px)`,
        position: 'absolute',
        ...style,
      }}
      hover={hoverable}
      className={rowClassName}
    >
      {columns.map((column, columnIndex) => {
        const rowIndex = virtualRow.index
        const isGrouping = isLastVisibleColumnOfParent(parentColumns, column, getColumnHiddenValue) || childColumns.length > 0
        const parentColumn = findParentColumn(parentColumns, column)
        const isStickyColumn = column.sticky || parentColumn?.sticky
        const cellProps = isStickyColumn ? { ...column.cellProps, sx: { ...column.cellProps?.sx, ...stickyColumnProps.sx } as SxProps } : column.cellProps
        const isHidden = (parentColumn && getColumnHiddenValue(parentColumn)) || getColumnHiddenValue(column)

        return isHidden ? null : (
          <BodyCell
            key={columnIndex}
            columnIndex={columnIndex}
            cellProps={cellProps}
            grouping={isGrouping || gridlines}
            style={{ width: column.width, minWidth: column.width, maxWidth: column.width, paddingRight: isStickyColumn ? 24 : undefined }}
          >
            {getCellValue({ row, col: column, rowIndex })}
          </BodyCell>
        )
      })}
    </TableRow>
  )
}

export const VirtualRow = memo(RecursiveVirtualRow) as typeof RecursiveVirtualRow
