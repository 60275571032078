import { useMemo } from 'react'

import { useGetGameUpdateImpactsQuery } from '../../../api/core'
import { useGetNewsEntriesForGameIdsAndVersionsQuery } from '../../../api/feed'
import { dateDifference } from '../../../helpers/date'
import { uniq } from '../../../helpers/uniq'
import languageService from '../../../services/LanguageService'
import { VersionTagFeature } from '../../../types/VersionTag'
import featureService from '../../feature/services/FeatureService'
import type { NewsEntry } from '../../news'
import { NewsEntryFeature, NewsEntryScreenshot, NewsEntryScreenshotFeature, NewsEntryType } from '../../news/types/UnifiedNewsEntry'
import { UPDATE_IMPACTS_SINCE, UPDATE_IMPACT_ANALYSIS_DATA_SINCE } from '../../update-history/constants/constants'
import { ChangeType } from '../types/ChangeType'
import { TableRowUpdateImpact } from '../types/TableRowUpdateImpact'
import { TimeSinceUpdated } from '../types/TimeSinceUpdated'
import { UpdateImpact } from '../types/UpdateImpact'

type GameVersion = string

type VersionsByGame = {
  [gameId: string]: GameVersion[]
}

type UseUpdateImpactsArgs = {
  marketIso: string
  minRevenue?: number
  timeSinceUpdated?: TimeSinceUpdated
}

const NewsEntryFields = [
  'type',
  'entryData.gps',
  'entryData.gpsBefore',
  'entryData.features',
  'entryData.screenshots',
  'entryData.commentPublished',
  'entryData.commentId',
  'entryData.gameVersion',
  'entryData.versionReleaseDate',
]

/**
 * Hook that requests and gathers data to be displayed in update impacts table
 */
export const useUpdateImpacts = ({ marketIso, minRevenue = 40000, timeSinceUpdated = TimeSinceUpdated.MONTHS_6 }: UseUpdateImpactsArgs) => {
  // generate timestamp timeSinceUpdated months from now
  const ts = useMemo(() => {
    const queryDate = new Date()
    return timeSinceUpdated !== TimeSinceUpdated.ALL_TIME ? queryDate.setMonth(new Date().getMonth() - Number(timeSinceUpdated)) : undefined
  }, [timeSinceUpdated])

  // fetch update impacts for given market
  const {
    data: updateImpacts,
    isLoading: isLoadingUpdateImpacts,
    isFetching: iFetchingUpdateImpacts,
    error: updateImpactsError,
  } = useGetGameUpdateImpactsQuery({ marketIso, minRevenue, ts, enableCache: true })

  // map impacts by game and version [gameId][version]
  const versionsByGame: VersionsByGame | undefined = useMemo(() => {
    return (
      updateImpacts?.reduce((acc, impact) => {
        acc[impact.gameId] = [...(acc[impact.gameId] || []), impact.version]
        return acc
      }, {} as VersionsByGame) || {}
    )
  }, [updateImpacts])

  // fetch news entries by previously mapped games and versions
  const {
    data: newsEntriesForGameIdsAndVersions,
    isLoading: isLoadingNewsEntriesForGameIdsAndVersions,
    isFetching: isFetchingNewsEntriesForGameIdsAndVersions,
    error: newsEntriesForGameIdsAndVersionsError,
  } = useGetNewsEntriesForGameIdsAndVersionsQuery({ marketIso, fields: NewsEntryFields, query: versionsByGame, enableCache: true })

  // maps impacts and news entries to table row objects
  const impacts: TableRowUpdateImpact[] = useMemo(() => {
    const newsEntryGetter = getNewsEntryByGameIdAndVersion(newsEntriesForGameIdsAndVersions)

    return (
      updateImpacts?.map((impact) => {
        const newsEntry = newsEntryGetter(impact.gameId, impact.version)
        const newsEntryArray = [newsEntry].filter((entry) => !!entry)

        return mapUpdateImpactToTableRow(impact, newsEntryArray)
      }) || []
    )
  }, [updateImpacts, newsEntriesForGameIdsAndVersions])

  return {
    updateImpacts,
    updateImpactsMappedForTable: impacts,
    isLoading: isLoadingUpdateImpacts || isLoadingNewsEntriesForGameIdsAndVersions,
    isFetching: iFetchingUpdateImpacts || isFetchingNewsEntriesForGameIdsAndVersions,
    error: updateImpactsError || newsEntriesForGameIdsAndVersionsError,
  }
}

// retrieves a news entry by given game and version
const getNewsEntryByGameIdAndVersion =
  (newsEntries: { [gameId: string]: { [version: string]: NewsEntry } } = {}) =>
  (gameId: string, version: string) => {
    return newsEntries?.[gameId]?.[version]
  }

export const mapUpdateImpactToTableRow = (impact: UpdateImpact, newsEntries: NewsEntry[]): TableRowUpdateImpact => {
  // omit changed features if entry type is gpssystem
  const features = newsEntries?.reduce((acc, newsEntry) => {
    return newsEntry?.type !== NewsEntryType.GpsSystem && newsEntry?.entryData?.features ? [...acc, ...newsEntry?.entryData?.features] : acc
  }, [] as NewsEntryFeature[])

  // omit updated features if entry type is other than gpsupdate
  const screenshots = newsEntries?.reduce((acc, newsEntry) => {
    return newsEntry?.type === NewsEntryType.GpsUpdate && newsEntry?.entryData?.screenshots ? [...acc, ...newsEntry?.entryData?.screenshots] : acc
  }, [] as NewsEntryScreenshot[])

  const versionReleaseDate = impact?.versionReleaseDate || new Date().getTime()
  const { changedFeatures, updatedFeatures } = resolveGameVersionFeatures(features, screenshots, versionReleaseDate)

  return {
    id: impact?.id,
    releaseDate: versionReleaseDate,
    version: impact?.version,
    versionTags: resolveVersionTags(impact?.tags, updatedFeatures, changedFeatures),
    gameId: impact?.gameId,
    // TODO: game name should be resolved by user language
    gameName: impact?.gameName,
    gameArtist: impact?.artist,
    conventionalSubgenre: impact?.conventionalSubgenre,
    conventionalSubgenreId: impact?.conventionalSubgenreId,
    icons: impact?.icons,
    aggregates: impact?.versioningAggregate,
    changedFeatures,
    updatedFeatures,
    changeTypes: uniq(newsEntries?.flatMap((newsEntry) => resolveChangeTypes(newsEntry, changedFeatures))),
    commentId: newsEntries.reduce((acc, value) => (value.entryData.commentPublished ? value.entryData.commentId : acc), undefined as any),
    daysSinceReleased: dateDifference(new Date(versionReleaseDate), new Date()),
  }
}

// resolves version tags based on given tags
export const resolveVersionTags = (versionTags: string[] = [], updatedFeatures: NewsEntryScreenshotFeature[], changedFeatures: NewsEntryFeature[]) => {
  let tags = versionTags.map((tag) => ({
    id: tag,
    name: languageService.getTranslation('versionTags', tag),
  }))

  // Remove feature tag from impacts where no changed or updated features exist
  if (updatedFeatures.length === 0 && changedFeatures.length === 0) {
    tags = tags.filter((tag) => updatedFeatures.length === 0 && changedFeatures.length === 0 && tag.id !== VersionTagFeature[0])
  }

  return tags
}

// resolves which change types are active based on the given news entry
export const resolveChangeTypes = (newsEntry: NewsEntry | undefined, changedFeatures: NewsEntryFeature[]) => {
  return Object.entries(ChangeType).reduce((acc, [key, value]) => {
    switch (value) {
      case ChangeType.FeatureChanges: {
        return [NewsEntryType.GpsNew, NewsEntryType.GpsUpdate, NewsEntryType.GpsOutside].includes(newsEntry?.type as NewsEntryType) &&
          changedFeatures.length > 0
          ? [...acc, value]
          : acc
      }
      case ChangeType.SystemUpdate: {
        return newsEntry?.entryData.versionReleaseDate &&
          newsEntry?.entryData.versionReleaseDate >= UPDATE_IMPACT_ANALYSIS_DATA_SINCE &&
          newsEntry?.type === NewsEntryType.GpsSystem &&
          newsEntry?.entryData?.gps !== newsEntry?.entryData?.gpsBefore
          ? [...acc, value]
          : acc
      }
      case ChangeType.ScreenshotsAvailable: {
        return (newsEntry?.entryData?.screenshots?.length || 0) > 0 ? [...acc, value] : acc
      }

      default:
        return acc
    }
  }, [] as ChangeType[])
}

export const resolveGameVersionFeatures = (
  features: NewsEntryFeature[] | undefined,
  screenshots: NewsEntryScreenshot[] | undefined,
  versionReleaseDate: number
) => {
  // include updated and changed features only if version release is fresh enough
  const changedFeatures = versionReleaseDate >= UPDATE_IMPACTS_SINCE ? featureService.getFilteredFeaturesForNewsEntryFeatures(features) : []
  const updatedFeatures =
    versionReleaseDate >= UPDATE_IMPACTS_SINCE ? featureService.getUpdatedFeaturesFromNewsEntryScreenshots(changedFeatures, screenshots) : []

  return { changedFeatures, updatedFeatures }
}
