import React, { ChangeEventHandler, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, Route, Routes, useLocation, useMatch } from 'react-router-dom'

import { ArrowDropDown, ArrowUpward, Clear } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useScrollTrigger,
} from '@mui/material'

import { AttributeModelNames, GamesByAttrsField, useGetAllVisualAttributesQuery, VisualAnalysis, VisualAnalysisModel } from '../../api/connect'
import { useGetRanksByGenreQuery } from '../../api/top-grossing'
import GameSubgenrePicker from '../../components/GameSubgenrePicker/GameSubgenrePicker'
import { subgenreFilter } from '../../features/implementation-examples/util/filters'
import { useCurrentMarket } from '../../features/markets'
import VisualExplorerGameNames from '../../features/visual-explorer/components/VisualExplorerGameNames'
import VisualExplorerIcons from '../../features/visual-explorer/components/VisualExplorerIcons'
import VisualExplorerOverview from '../../features/visual-explorer/components/VisualExplorerOverview'
import { useVisualExplorerSubPages } from '../../features/visual-explorer/hooks/useVisualExplorerSubPages'
import { ColorPercentage, IconStatsRow } from '../../features/visual-explorer/types'
import ColorSquare from '../../features/visuals/components/ColorSquare'
import GameIconAttributeModal from '../../features/visuals/components/GameIconAttributeModal'
import { GameVisualsModal } from '../../features/visuals/components/GameVisualsTable'
import { getAttributeNameByModel, useGamesByVisualAttributes } from '../../features/visuals/hooks/useIconAttributes'
import { useIconGrouper, useMapVisualAnalysisWithRank } from '../../features/visuals/hooks/useIconGrouper'
import { RankType, VisualAnalysisWithRank, VisualSortType } from '../../features/visuals/types'
import { mainAttributeModels, visualsTranslationMap } from '../../features/visuals/util/helpers'
import { AppStoreGenreMap } from '../../features/visuals/util/visualStats'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import { useSubgenrePicker } from '../../hooks/useSubgenrePicker'
import analyticsService from '../../services/AnalyticsService'
import PageService from '../../services/PageService'
import { AnalyticsProviders } from '../../types/IAnalyticsProvider'
import './VisualExplorerPage.scss'

type RankTranslation = { [key in RankType]: string }

type OpenModels = { [model in VisualAnalysisModel]: boolean }

const pageRoot = '/visual-explorer/'

const rankTranslation: RankTranslation = {
  free: 'common:sustained_download_rank',
  grossing: 'common:sustained_grossing_rank',
}

const getSearchTextFilter =
  (searchText?: string, marketIso?: string) =>
  ({ gameNames }: VisualAnalysis): boolean => {
    return (
      !searchText ||
      searchText === '' ||
      Object.values((marketIso && gameNames[marketIso]) || gameNames)
        .join('')
        .toLowerCase()
        .includes(searchText.toLowerCase())
    )
  }

/**
 * The page, having three tab views inside.
 */
const VisualExplorerPage: React.FC = () => {
  const { t } = useTranslation()

  usePage(PageService.getPageWithId('visual-explorer'), 'Visited Visuals Explorer')
  useDocumentTitle(t('sidebar:visuals_explorer'))

  const { currentMarketIso: marketIso } = useCurrentMarket()
  const location = useLocation()
  const match = useMatch(pageRoot + ':subPageId')
  const headerElement = useRef<HTMLDivElement>(null)
  const headerTop = useRef<HTMLDivElement>(null)
  const tabNavigation = useRef<HTMLDivElement>(null)

  const { data: attributes, isLoading: isAttrsFetching } = useGetAllVisualAttributesQuery()
  const { subgenres, handleSubgenresChange } = useSubgenrePicker()

  const subPages = useVisualExplorerSubPages()
  const [colorOptionOpener, setColorOptionOpener] = useState<HTMLElement | null>(null)
  const [colorPercentage, setColorPercentage] = useState<ColorPercentage>({})
  const [fixedOffset, setFixedOffset] = useState(-452)
  const [genreId, setGenreId] = useState('6014')
  const [initialLoadComplete, setInitialLoadComplete] = useState(false)
  const [openModels, setOpenModels] = useState<OpenModels>({} as OpenModels)
  const [rankType, setRankType] = useState<RankType>('free')
  const [searchText, setSearchText] = useState<string>()
  const [selectedAttr, setSelectedAttr] = useState<IconStatsRow>()
  const [selectedAttrs, setSelectedAttrs] = useState<AttributeModelNames>({} as AttributeModelNames)
  const [selectedItem, setSelectedItem] = useState<VisualAnalysisWithRank>()
  const [sortBy, setSortBy] = useState<VisualSortType>('attribute')
  const [top, setTop] = useState(200)
  const [attributeModels, setAttributeModels] = useState<VisualAnalysisModel[]>(mainAttributeModels)

  useEffect(() => {
    const joinArr = (list?: string[]) => list && list.join(', ')
    const trackData: { [key: string]: any } = {
      genreId,
      rankId: rankType,
      icon: joinArr(selectedAttrs.icon),
      genre: joinArr(selectedAttrs.genre),
      style: joinArr(selectedAttrs.style),
      color: joinArr(selectedAttrs.color),
    }

    const filteredTrackData = Object.keys(trackData).reduce((result, trackKey) => {
      if (!!trackData[trackKey]) {
        result[trackKey] = trackData[trackKey]
      }
      return result
    }, {} as { [key: string]: any })

    analyticsService.trackEvent('Visuals Explorer: Search', {
      data: filteredTrackData,
      serviceToExclude: [AnalyticsProviders.hubspot],
    })
  }, [attributeModels, genreId, rankType, selectedAttrs])

  useEffect(() => {
    analyticsService.trackEvent('Visuals Explorer: Sorted List', {
      data: {
        sortValue: top,
        type: sortBy,
      },
      serviceToExclude: [AnalyticsProviders.hubspot],
    })
  }, [sortBy, top])

  const showSearchButton = useScrollTrigger({
    disableHysteresis: true,
    threshold: -fixedOffset + 200,
  })

  const fields: GamesByAttrsField[] = useMemo(
    () =>
      mainAttributeModels.map((key) => {
        const aModel = key as VisualAnalysisModel
        const attributeName = getAttributeNameByModel(aModel)
        const model = aModel === 'colorGroups' ? 'color' : aModel
        return selectedAttrs[aModel]?.length ? { model, [attributeName]: selectedAttrs[aModel] } : { model }
      }),
    [selectedAttrs]
  )

  const selectedSubpage = useMemo(() => {
    if (match?.params.subPageId) {
      const found = subPages.find((item) => item.id === match.params.subPageId)
      if (found) {
        return found
      }
    }
    return subPages[0]
  }, [match, subPages])

  useEffect(() => {
    analyticsService.trackEvent('Visuals Explorer: Changed Tab', {
      data: {
        name: selectedSubpage.id,
      },
    })
  }, [selectedSubpage])

  const { data: gameData, isFetching: isGamesFetching } = useGamesByVisualAttributes(fields, marketIso, rankType, genreId, false)
  const { data: rankData, isFetching: isRankFetching } = useGetRanksByGenreQuery({ marketIso, genreId })
  const filteredGameData = useMemo(
    () => gameData?.filter(getSearchTextFilter(searchText, marketIso)).filter(subgenreFilter(subgenres)),
    [gameData, marketIso, searchText, subgenres]
  )

  const mappedVisualAnalysisWithRank = useMapVisualAnalysisWithRank(rankType, top, genreId, filteredGameData, rankData)
  const groupedData = useIconGrouper(rankType, top, genreId, filteredGameData, rankData, colorPercentage)

  const handleToggleAttribute = (model: VisualAnalysisModel, name: string) => {
    let attrs: string[] = selectedAttrs[model] ? [...selectedAttrs[model]] : []
    if (attrs.includes(name)) {
      attrs = attrs.filter((n) => n !== name)
    } else {
      attrs.push(name)
      if (model.startsWith('color')) {
        setColorPercentage({ ...colorPercentage, [name]: 20 })
      }
    }
    setSelectedAttrs({ ...selectedAttrs, [model]: attrs })
  }

  const handleHeaderHeightChange = () => {
    if (!headerElement?.current) {
      return
    }
    setFixedOffset(-headerElement.current.offsetHeight + 138)
  }

  useEffect(() => {
    setTimeout(handleHeaderHeightChange, 550)
  }, [openModels])

  const handleScrollUp = () => {
    headerTop?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const keyDownHandler = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') {
      setSelectedAttr(undefined)
      setSelectedItem(undefined)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleColorChangeOpener: MouseEventHandler<HTMLDivElement> = (evt) => {
    evt.stopPropagation()
    setColorOptionOpener(evt.currentTarget.parentElement)
  }

  const handleChangeSearch: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setSearchText(target.value)
  }

  const changeColorPercentage = (percentage: number) => {
    if (colorOptionOpener) {
      setColorPercentage({ ...colorPercentage, [colorOptionOpener?.id]: percentage })
    }
    setColorOptionOpener(null)
  }

  const isFetching = isAttrsFetching || isRankFetching || isGamesFetching

  useEffect(() => {
    if (gameData && !initialLoadComplete) {
      setTimeout(() => tabNavigation?.current?.scrollIntoView({ behavior: 'smooth' }), 333)
      setInitialLoadComplete(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameData])

  const needsRedirect = location.pathname === '/visual-explorer'

  return needsRedirect || (subPages.length < 3 && match?.pathname === '/visual-explorer/game_names') ? (
    <Navigate replace to="/visual-explorer/search" />
  ) : (
    <Box className="VisualExplorerPage">
      <Typography variant="h3" gutterBottom ref={headerTop} component="div">
        1. {t('visuals-explorer:search_title_2')}
      </Typography>
      <Box>
        <FormControl size="small" variant="outlined" sx={{ mr: 1 }}>
          <Select onChange={({ target }) => setGenreId(target.value)} value={genreId}>
            {Object.keys(AppStoreGenreMap).map((genreId) => (
              <MenuItem value={genreId} key={genreId}>
                {AppStoreGenreMap[genreId]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" variant="outlined">
          <Select onChange={({ target }) => setRankType(target.value as RankType)} value={rankType}>
            {['free', 'grossing'].map((rankType) => (
              <MenuItem key={rankType} value={rankType}>
                {t(rankTranslation[rankType as RankType])}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Typography variant="h3" gutterBottom>
          2. {t('visuals-explorer:search_title_4')}
        </Typography>
        <GameSubgenrePicker selectionChanged={handleSubgenresChange} selectedSubgenres={subgenres} showSubgenreDefinitionBtn />
      </Box>
      <Card sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
        <CardContent>
          <Typography variant="h3" gutterBottom>
            3. {t('visuals-explorer:search_title_1')}
          </Typography>
          <Box className="selectableAttributes">
            {attributes &&
              mainAttributeModels.map((model) => (
                <Box key={model}>
                  <Button
                    className={'dropdown ' + (openModels[model] ? 'open' : '')}
                    color="secondary"
                    endIcon={<ArrowDropDown color="primary" />}
                    variant="text"
                    onClick={() => setOpenModels({ ...openModels, [model]: !openModels[model] })}
                  >
                    <Typography>{t(visualsTranslationMap[model])}</Typography>
                    <Chip
                      size="small"
                      color={selectedAttrs[model]?.length ? 'primary' : 'default'}
                      label={`${selectedAttrs[model]?.length || '0'}/${attributes[model].length}`}
                    />
                  </Button>
                  <Collapse in={openModels[model]}>
                    <Box pb={1}>
                      {[...attributes[model]].sort().map((name, index) => {
                        const selected = selectedAttrs[model]?.includes(name)
                        return (
                          <Button
                            id={name}
                            size="small"
                            variant={selected ? 'contained' : 'outlined'}
                            className={selected ? 'selected' : ''}
                            key={'attr-' + index}
                            onClick={() => handleToggleAttribute(model, name)}
                          >
                            {model === 'colorGroups' ? (
                              <>
                                <ColorSquare color={name} style={{ boxShadow: 'none', borderRadius: 3, borderColor: 'rgba(0, 0, 0, 0.12' }} />
                                {name}
                                <Chip
                                  onClick={handleColorChangeOpener}
                                  label={t('visuals-explorer:color_amount_percentage', { percentage: colorPercentage[name] })}
                                />
                              </>
                            ) : (
                              name
                            )}
                          </Button>
                        )
                      })}
                      {selectedAttrs[model]?.length > 0 && (
                        <Button className="clear" size="small" onClick={() => setSelectedAttrs({ ...selectedAttrs, [model]: [] })} startIcon={<Clear />}>
                          {t('common:clear')}
                        </Button>
                      )}
                    </Box>
                  </Collapse>
                  <Menu
                    id="VisualExplorerPage__colorOptions"
                    open={!!colorOptionOpener}
                    onClose={() => setColorOptionOpener(null)}
                    anchorEl={colorOptionOpener}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  >
                    {[10, 20, 30, 40, 50, 60, 70, 80, 90].map((percentage) => (
                      <MenuItem key={`color-amount-${percentage}`} onClick={() => changeColorPercentage(percentage)}>
                        {t('visuals-explorer:color_amount_percentage', { percentage })}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ))}
          </Box>
          <Typography variant="h3" gutterBottom mt={3} ref={tabNavigation}>
            4. {t('visuals-explorer:search_title_3')}
          </Typography>
          <TextField type="search" variant="outlined" size="small" label={t('visuals-explorer:search_term_input_placeholder')} onChange={handleChangeSearch} />
        </CardContent>
        <Divider />
      </Card>

      <Card className="VisualExplorerPage__header" ref={headerElement} style={{ top: fixedOffset }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Tabs className="VisualExplorerPage__navigation" value={selectedSubpage.id}>
              {subPages.map((subpage) => (
                <Tab key={subpage.id} label={t(subpage.translation)} component={Link} to={pageRoot + subpage.id} value={subpage.id} />
              ))}
            </Tabs>
          </Grid>
          <Grid item textAlign="right" mr={3}>
            <Button color="primary" className={showSearchButton ? '' : 'hidden'} startIcon={<ArrowUpward />} onClick={handleScrollUp}>
              {t('common:search')}
            </Button>
          </Grid>
        </Grid>
      </Card>

      <Box mt={3} className="selectedParams">
        {Object.keys(selectedAttrs).map((model) =>
          selectedAttrs[model as VisualAnalysisModel].map((name) => (
            <Chip className="capitalize" key={name} label={model === 'colorGroups' ? `${name}: ${colorPercentage[name]}%` : name} color="primary" />
          ))
        )}
        {searchText === undefined || searchText === '' ? null : <Chip label={`${t('visuals-explorer:search_term_input_placeholder')}: ${searchText}`} />}
        <Chip label={`${t('common:apple_genre')}: ${AppStoreGenreMap[genreId]}`} />
        <Chip label={`${t('common:rank_type')}: ${t(rankTranslation[rankType])}`} />
        <Chip label={t(`common:top_${top}`) + ': ' + groupedData['top'].length} className="numbersChip" />
        <Chip label={t(`common:outside_top_${top}`) + ': ' + groupedData['out'].length} className="numbersChip" />
      </Box>

      <Divider sx={{ my: 2 }} />

      {isFetching ? (
        <Box my={4} textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <Routes>
          {/* Tab #1 */}
          <Route
            path={'search'}
            element={
              <VisualExplorerIcons
                data={groupedData}
                genreId={genreId}
                rankType={rankType}
                sortBy={sortBy}
                selectedAttrs={selectedAttrs}
                top={top}
                onSetSelectedItem={(item) => {
                  setSelectedItem(item)
                }}
                onSetTop={setTop}
                onSetSortBy={setSortBy}
              />
            }
          />
          {/* Tab #2 */}
          <Route
            path={'overview'}
            element={
              <VisualExplorerOverview
                attributes={attributes}
                gameData={filteredGameData}
                genreId={genreId}
                rankType={rankType}
                onSetSelectedAttr={setSelectedAttr}
                setTop={setTop}
                top={top}
                attributeModels={attributeModels}
                setAttributeModels={setAttributeModels}
              />
            }
          />
          {/* Tab #3 */}
          <Route
            path={'game_names'}
            element={
              <VisualExplorerGameNames
                gameData={mappedVisualAnalysisWithRank}
                rankType={rankType}
                rankData={rankData}
                onGameSelected={(game) => setSelectedItem(game as VisualAnalysisWithRank)}
              />
            }
          />
        </Routes>
      )}
      {selectedItem && <GameVisualsModal item={selectedItem} onClose={() => setSelectedItem(undefined)} />}
      {selectedAttr && (
        <GameIconAttributeModal
          attribute={selectedAttr}
          genreId={genreId}
          rankType={rankType}
          top={top}
          onClose={() => setSelectedAttr(undefined)}
          onSelectGame={setSelectedItem}
        />
      )}
    </Box>
  )
}

export default VisualExplorerPage
