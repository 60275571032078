import { t } from 'i18next'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import ReportCardPreview from '../../features/reports/components/ReportCardPreview/ReportCardPreview'
import ReportList from '../../features/reports/components/ReportList/ReportList'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import PageService from '../../services/PageService'

const ReportsPage: React.FC = () => {
  usePage(PageService.getPageWithId('reports'), 'Visited Reports')
  useDocumentTitle(t('sidebar:reports'))

  return (
    <Routes>
      <Route path="" element={<ReportList />} />
      <Route path=":reportId/*" element={<ReportCardPreview />} />
    </Routes>
  )
}

export default ReportsPage
