import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import utilsService from '../../../../services/UtilsService'
import { FormatOptions } from '../../../../types/FormatOptions'
import { EmptyAggregateValue, EMPTY_AGGREGATE_VALUE, AggregateValueColumnFilterResult, AggregateValueColumnFilterValue } from '../../types/ColumnFilter'
import { ColumnFilterSelect } from '../ColumnFilterSelect/ColumnFilterSelect'

/**
 * Component for rendering filters for aggregate value columns (change % and change value at least)
 */

type AggregateValueColumnFilterProps = {
  name: string
  percentChangeValues: readonly (number | EmptyAggregateValue)[]
  valueChangeValues: readonly (number | EmptyAggregateValue)[]
  onChange?: (value: AggregateValueColumnFilterResult) => void
  valueFormatter: (value: number, options: FormatOptions) => string
  initialValue?: AggregateValueColumnFilterValue
}

export const AggregateValueColumnFilter: FC<AggregateValueColumnFilterProps> = ({
  initialValue = { percentChange: EMPTY_AGGREGATE_VALUE, valueChange: EMPTY_AGGREGATE_VALUE },
  name,
  onChange,
  percentChangeValues,
  valueChangeValues,
  valueFormatter,
}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<AggregateValueColumnFilterValue>(initialValue)

  const percentChangeOptions = useMemo(() => {
    return percentChangeValues.map((value) => ({
      name: value === EMPTY_AGGREGATE_VALUE ? t('common:not_limited') : '+' + utilsService.formatPercent(value, { maximumFractionDigits: 0 }),
      value: value + '',
    }))
  }, [t, percentChangeValues])

  const valueChangeOptions = useMemo(() => {
    return valueChangeValues.map((value) => ({
      name: value === EMPTY_AGGREGATE_VALUE ? t('common:not_limited') : '+' + valueFormatter(value, { shorten: true, mantissa: 2 }),
      value: value + '',
    }))
  }, [t, valueChangeValues, valueFormatter])

  const handlePercentChange = useCallback(
    (newPercentChange: string) => {
      const newValue = {
        ...value,
        percentChange: newPercentChange || EMPTY_AGGREGATE_VALUE,
      }

      const result = {
        name,
        value: newValue,
      }
      setValue(newValue)
      onChange && onChange(result)
    },
    [onChange, name, value]
  )

  const handleValueChange = useCallback(
    (newValueChange: string) => {
      const newValue = {
        ...value,
        valueChange: newValueChange || EMPTY_AGGREGATE_VALUE,
      }

      const result = {
        name,
        value: newValue,
      }
      setValue(newValue)
      onChange && onChange(result)
    },
    [onChange, name, value]
  )

  return (
    <Grid container wrap="wrap" spacing={2} sx={{ width: 200 }}>
      <Grid item xs={12}>
        <ColumnFilterSelect
          value={value.percentChange}
          label={t('common:percent_change_at_least')}
          options={percentChangeOptions}
          onChange={handlePercentChange}
        />
      </Grid>
      <Grid item xs={12}>
        <ColumnFilterSelect value={value.valueChange} label={t('common:value_change_at_least')} options={valueChangeOptions} onChange={handleValueChange} />
      </Grid>
    </Grid>
  )
}
