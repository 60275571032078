import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { useGetScreenshotsByGameIdsQuery } from '../../../../api/core'
import GameSearchAndSort from '../../../../components/GameSearchAndSort/GameSearchAndSort'
import MultiGameSelector from '../../../../components/MultiGameSelector/MultiGameSelector'
import utilsService from '../../../../services/UtilsService'
import { useFeatures } from '../../../feature/hooks/useFeatures'
import { useGetGameList } from '../../../game/hooks/gameHooks'
import { FeatureGame, Game, IFeatureGame } from '../../../game/types/Game'
import { useCurrentMarket } from '../../../markets'
import { gameComparator } from '../../util/comparators'
import { enabledFeaturesFilter } from '../../util/filters'
import { convertLegacyScreenshots, extractNeededLegacyIds, pickFeaturesWithLegacyId } from '../../util/screenshotHelpers'
import FilterByTags from '../FilterByTags/FilterByTags'
import ImplementationPreviews from '../ImplementationPreviews/ImplementationPreviews'
import { defaultMarketIso } from './GameByFeatureSelection'
import './GameSelection.scss'

interface GameSelectionProps {
  gameIds: string[]
}

const GameSelection: React.FC<GameSelectionProps> = ({ gameIds }) => {
  const { t } = useTranslation()
  const { currentMarketIso } = useCurrentMarket()

  const { data: featuresData, isFetching: isFetchingFeatures } = useFeatures()
  const { data: gamesData, isFetching: isFetchingGames } = useGetGameList({
    gameIds,
    include: 'name,artist,icons,conventionalSubgenre,conventionalSubgenreId,sdranks,sranks,appId',
  })
  const { data: screenshotsData, isFetching: isFetchingScreenshots } = useGetScreenshotsByGameIdsQuery(gameIds)

  const [selectedGames, setSelectedGames] = useState<Game[]>([])
  const [enabledFeatureIds, setEnabledFeatureIds] = useState<number[]>()
  const [sortCriteria, setSortCriteria] = useState<string>('sustained_grossing_rank')

  const isFetching = useMemo(() => isFetchingGames || isFetchingFeatures || isFetchingFeatures, [isFetchingFeatures, isFetchingGames])

  const games = useMemo(
    () =>
      ((gamesData || []) as Game[]).map((game) => {
        const featureGame = new FeatureGame(game)
        featureGame.featureScreenshots = convertLegacyScreenshots((screenshotsData || []).filter(({ gameId }) => gameId === game.id))
        return featureGame
      }),
    [gamesData, screenshotsData]
  )

  const features = useMemo(() => {
    const legacyIds = extractNeededLegacyIds(selectedGames as FeatureGame[])
    return pickFeaturesWithLegacyId(legacyIds, featuresData)
  }, [selectedGames, featuresData])

  useEffect(() => games && setSelectedGames(games), [games, isFetchingFeatures])

  const gameSorter = useMemo(() => gameComparator(currentMarketIso, sortCriteria), [sortCriteria, currentMarketIso])

  const gameOptions = useMemo(
    () =>
      games
        .map((game) => {
          if (!game.sranks[currentMarketIso]) {
            game.sranks = { ...game.sranks, [currentMarketIso]: defaultMarketIso }
          }
          if (!game.sdranks[currentMarketIso]) {
            game.sdranks = { ...game.sdranks, [currentMarketIso]: defaultMarketIso }
          }
          return game
        })
        .sort(gameSorter),
    [currentMarketIso, gameSorter, games]
  )

  const sortedReleaseDate = useMemo(() => {
    const featureGames = selectedGames as FeatureGame[]
    return utilsService.oldestReleaseDate(featureGames)
  }, [selectedGames])

  return (
    <>
      <Typography variant="h2" mt={4}>
        {t('compare:select_games')}
      </Typography>
      <Typography variant="h3" mb={1} className="test">
        {t('implementations:label_filtermode_sub_description')}
      </Typography>
      <GameSearchAndSort hideAutoComplete onChangeSort={setSortCriteria} sortValue={sortCriteria} onChangeSearch={() => {}} />
      <MultiGameSelector
        loading={isFetchingGames || isFetchingScreenshots}
        options={gameOptions}
        selectedGames={selectedGames}
        setSelectedGames={setSelectedGames}
        sortCriteria={sortCriteria}
        skipInitGameIds
      />
      {selectedGames?.length > 0 && (
        <>
          <Typography variant="h2" mt={4}>
            {t('implementations:label_filter_implementations_2')}
          </Typography>
          <FilterByTags features={features} onChangeEnabledFeatures={setEnabledFeatureIds} />
          <ImplementationPreviews
            features={features.filter(enabledFeaturesFilter(enabledFeatureIds))}
            games={selectedGames as IFeatureGame[]}
            loading={isFetching}
            fromDate={sortedReleaseDate}
            minDate={sortedReleaseDate}
            gameSorter={gameSorter}
          />
        </>
      )}
    </>
  )
}

export default GameSelection
