import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { useAnalystReviewsAndCommentsCheck } from '../../features/account/hooks/roleHooks'
import { UserLanguage } from '../../features/account/types/User'
import { Review, ReviewContentFields } from '../../features/game/types/GameReview'
import { camelToSnakeCase } from '../../features/news/utils/utils'
import languageService from '../../services/LanguageService'
import GRCircularProgress from '../GRCircularProgress/GRCircularProgress'
import { ReviewContent } from '../Review/Review'

/**
 * Component displaying analyst oveview html content in sections
 */

type AnalystOverviewProps = {
  review?: Review
  contentFields?: ReviewContentFields[]
  language?: UserLanguage
  isLoading?: boolean
}

const defaultContentFields = [
  ReviewContentFields.GenreAndStyle,
  ReviewContentFields.FeaturesAndProgression,
  ReviewContentFields.Monetization,
  ReviewContentFields.SpecialNotes,
]

export const AnalystOverview: FC<AnalystOverviewProps> = ({
  review,
  contentFields = defaultContentFields,
  language = languageService.defaultLanguage,
  isLoading,
}) => {
  const { t } = useTranslation()
  const hasAccessToAnalystOverviewsAndComments = useAnalystReviewsAndCommentsCheck()

  return (
    <>
      {!hasAccessToAnalystOverviewsAndComments && (
        <Typography>
          <Trans i18nKey="reviews:label_not_for_free"></Trans>
        </Typography>
      )}

      {hasAccessToAnalystOverviewsAndComments && isLoading && <GRCircularProgress />}

      {hasAccessToAnalystOverviewsAndComments && !isLoading && (
        <>
          {contentFields.map((key) => {
            if (review?.content[key]) {
              const rawHtml = review.content[key][language] || review.content[key][languageService.defaultLanguage]
              if (typeof rawHtml === 'string' && rawHtml !== '') {
                return (
                  <div key={key}>
                    <Typography variant="h3">{t(`reviews:label_${camelToSnakeCase(key)}`)}</Typography>
                    <ReviewContent contentHtml={rawHtml} />
                  </div>
                )
              } else {
                return null
              }
            } else {
              return null
            }
          })}
        </>
      )}
    </>
  )
}
