import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogProps, Grid, TextField, Typography } from '@mui/material'

import GRDialogTitle from '../../../../components/GRDialogTitle/GRDialogTitle'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { useError } from '../../../../hooks/useError'
import analyticsService from '../../../../services/AnalyticsService'
import { displaySnackBar } from '../../../snackbar'
import { useUpdateUserSegments, useUserSegments } from '../../hooks/userSegmentHooks'
import { MarketExplorerSegmentConfiguration } from '../../types/MarketExplorerSegmentConfiguration'
import { MarketExplorerUserSegmentChartConfiguration } from '../../types/MarketExplorerUserSegment'

/**
 * Dialog component for saving a segment configuration defined by user (user segment). Configuration can be named
 * and given an optionla description.
 */
type SaveUserSegmentDialogProps = DialogProps & {
  segments: MarketExplorerSegmentConfiguration[]
  chartConfiguration: MarketExplorerUserSegmentChartConfiguration
  children?: ReactNode
}

export const SaveUserSegmentDialog: FC<SaveUserSegmentDialogProps> = ({ open, onClose, segments, chartConfiguration, children }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()
  const currentUserSegmentsQuery = useUserSegments()
  const {
    updateUserSegments,
    isSuccess: isUpdateUserSegmentsSuccess,
    isLoading: isUpdateUserSegmentsLoading,
    error: updateUserSegmentsError,
    reset: resetUpdateUserSegmentsQuery,
  } = useUpdateUserSegments()
  useError({ error: updateUserSegmentsError })
  const isLoading = isUpdateUserSegmentsLoading || currentUserSegmentsQuery.isLoading || currentUserSegmentsQuery.isFetching

  const handleClose = useCallback(
    (event: {}) => {
      onClose && onClose(event, 'escapeKeyDown')
    },
    [onClose]
  )

  useEffect(() => {
    if (isUpdateUserSegmentsSuccess) {
      handleClose({})
      resetUpdateUserSegmentsQuery()
      dispatch(displaySnackBar({ message: t('segment:user_segment_selection_saved'), severity: 'success', open: true }))
    }
  }, [dispatch, handleClose, isUpdateUserSegmentsSuccess, resetUpdateUserSegmentsQuery, t])

  const handleSave = () => {
    if (title && !isLoading) {
      const newSegment = { name: title, description: description, segments, chart: chartConfiguration }
      const userSegmentsToSave = [...currentUserSegmentsQuery.data, newSegment]
      analyticsService.trackEvent('Market Explorer: Saved Segment', { data: { newSegment } })
      updateUserSegments(userSegmentsToSave)
    }
  }

  const isTitleValid = useMemo(() => {
    if (!title || isLoading) return true
    if (title.trim()) return false
    return false
  }, [isLoading, title])

  return (
    <Dialog open={open} onClose={onClose}>
      <GRDialogTitle onClose={onClose}>
        <Trans i18nKey="segment:save_segments_modal_title" />
      </GRDialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" gap={2}>
          <Typography variant="body1">
            <Trans i18nKey="segment:save_segments_modal_description" />
          </Typography>
          <TextField
            name="title"
            type="text"
            label={t('segment:save_segments_input_placeholder')}
            onChange={(e) => setTitle(e.target.value.trim())}
            variant="outlined"
            size="small"
            fullWidth
          ></TextField>
          <TextField
            name="title"
            type="text"
            label={t('segment:save_segments_description_input_placeholder')}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            size="small"
            fullWidth
          ></TextField>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          <Trans i18nKey="common:close" />
        </Button>
        <LoadingButton variant="contained" color="success" disabled={isTitleValid} loading={isLoading} onClick={handleSave}>
          <Trans i18nKey="common:save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
