import React from 'react'
import { useTranslation } from 'react-i18next'

import { ChatBubble, FiberNew } from '@mui/icons-material'
import { Button, Card, Grid } from '@mui/material'

import { GameIcon } from '../../../../game'
import { useDateTimeFormatter } from '../../../hooks/useDateTimeFormatter'
import { LiveEventsGameAlert, LiveEventsGameAlertType } from '../../../types/LiveEventsGameAlert'
import './LiveEventsGameAlertCard.scss'

interface Props {
  alert: LiveEventsGameAlert
  onButtonClick: (alert: LiveEventsGameAlert) => void
}

const dateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

const LiveEventsGameAlertCard: React.FC<Props> = ({ alert, onButtonClick }) => {
  const { t } = useTranslation()
  const dateFormatter = useDateTimeFormatter(dateFormat)

  return (
    <Card className="LiveEventsGameAlertCard">
      <Grid container spacing={2}>
        <Grid item>
          <GameIcon src={alert.trackedGame ? alert.trackedGame?.game.getIcon() : ''} gameName={alert.trackedGame ? alert.trackedGame?.game.resolvedName : ''} />
        </Grid>
        <Grid item xs>
          <div className="LiveEventsGameAlertCard__Name">{alert.name}</div>
          <div className="LiveEventsGameAlertCard__Description">{alert.description}</div>
          <div className="LiveEventsGameAlertCard__Date">{dateFormatter.format(alert.start)}</div>
        </Grid>
      </Grid>
      {alert.type === LiveEventsGameAlertType.Event && (
        <Button onClick={() => onButtonClick(alert)} variant="contained" color="primary" size="small" startIcon={<FiberNew />} fullWidth>
          {t('live-events:game_alert_action_open_event_description')}
        </Button>
      )}
      {alert.type === LiveEventsGameAlertType.Comment && (
        <Button onClick={() => onButtonClick(alert)} variant="contained" color="success" size="small" startIcon={<ChatBubble />} fullWidth>
          {t('live-events:game_alert_action_open_comment_notes')}
        </Button>
      )}
    </Card>
  )
}

export default LiveEventsGameAlertCard
