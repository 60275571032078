import { t } from 'i18next'
import { FC } from 'react'

import { Delete } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'

import { checkDataTypeString } from '../../../helpers/checkDataTypeString'
import { EventScreenshot } from '../../../types/EventScreenshot'
import { EventScreenshotThumbnail } from '../EventScreenshotThumbnail/EventScreenshotThumbnail'
import './EventScreenshotsGallery.scss'

type EventScreenshotsGalleryProps = {
  screenshots: EventScreenshot[]
  rowLengthValue: number | string
  onRemoveScreenshot: (screenshot: EventScreenshot) => void
  onOpenScreenshotDialog: (screenshot: EventScreenshot) => void
}

export const EventScreenshotsGallery: FC<EventScreenshotsGalleryProps> = ({ screenshots, rowLengthValue, onRemoveScreenshot, onOpenScreenshotDialog }) => {
  const rowLength = typeof rowLengthValue === 'number' && rowLengthValue > 0 && rowLengthValue < 21 ? rowLengthValue : 1
  const imgWidth = '100%'
  const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>, screenshot: EventScreenshot) => {
    event.stopPropagation()
    onRemoveScreenshot(screenshot)
  }

  return (
    <>
      {screenshots.length !== 0 ? (
        <Grid
          className="EventScreenshotGallery"
          container
          sx={{
            padding: '10px',
            display: 'grid',
            gridGap: '10px',
            gridTemplateColumns: `repeat(${rowLength}, 1fr)`,
          }}
        >
          {screenshots.map((screenshot) => (
            <Grid
              className="ScreenshotContainer"
              item
              textAlign="center"
              key={screenshot.name}
              onClick={checkDataTypeString(screenshot.originalData) ? () => onOpenScreenshotDialog(screenshot) : undefined}
              sx={checkDataTypeString(screenshot.originalData) ? { cursor: 'pointer' } : undefined}
            >
              <EventScreenshotThumbnail screenshot={screenshot} imgWidth={imgWidth} />
              <Button className="ScreenshotGalleryDeleteBtn" variant="contained" color="warning" onClick={(event) => onDeleteClick(event, screenshot)}>
                <Delete fontSize="small" />
              </Button>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ marginTop: '20px', textAlign: 'center', fontSize: 12 }}>
            {t('internal-live-events:screenshot_table_no_rows')}
          </Grid>
        </Grid>
      )}
    </>
  )
}
