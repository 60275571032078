import { useEffect, useState } from 'react'

import { useCurrentMarket } from '../../markets'

/**
 * Returns true if the provided marketIso is valid for motivations
 * Valid markets are all markets except 'jp' and 'cn'
 * In case of an array, all markets in the array must be valid
 * @param marketIso Either a string, an array of strings or undefined
 * @returns boolean
 */
export const useMarketMotivationsValidation = (marketIso?: string | string[]): boolean => {
  const { currentMarketIso } = useCurrentMarket()
  const [isValid, setIsValid] = useState<boolean>(false)

  useEffect(() => {
    const validateMarketIsos = (markets: string | string[] | undefined): boolean => {
      if (!markets) {
        // If no markets are provided, use the currentMarketIso
        return isWesternMarket(currentMarketIso)
      }

      if (typeof markets === 'string') {
        return isWesternMarket(markets)
      }

      if (Array.isArray(markets)) {
        // Check if any of the markets in the array is 'jp' or 'cn'
        return !markets.includes('jp') && !markets.includes('cn')
      }

      // Default to false if invalid input is provided
      return false
    }

    setIsValid(validateMarketIsos(marketIso))
  }, [marketIso, currentMarketIso])

  return isValid
}

export const isWesternMarket = (marketIso: string): boolean => {
  return marketIso !== 'jp' && marketIso !== 'cn'
}

/**
 * Function that returns true if all markets in the array are not western markets
 */
export const useAllMarketIsosAsian = (marketIsos: string[]): boolean => {
  return marketIsos.every((marketIso) => !isWesternMarket(marketIso))
}
