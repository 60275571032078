import { useGetUserProfileQuery } from '../../../api/combined'
import { useGetMultipleLatestMotivationProfileQuery } from '../../../api/core'
import { Game } from '../../game'

/**
 * useGetMotivationProfile hooks return multiple motivation profile query based on public or private game
 */
interface useGetMultipleMotivationProfileProps {
  games: Game[]
  skip?: boolean
}
const useGetMultipleMotivationProfile = ({ games, skip }: useGetMultipleMotivationProfileProps) => {
  const { data: currentUser } = useGetUserProfileQuery()

  const queryParams = games.map((game) => {
    const isOwnGame = currentUser?.organization.id === game.owner

    return {
      isOwnGame,
      id: isOwnGame ? game.id : game.appId,
    }
  })

  return useGetMultipleLatestMotivationProfileQuery({ queryParams }, { skip })
}

export default useGetMultipleMotivationProfile
