import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'

import { GamesDataTableColumnType } from '../../types/MarketExplorerGamesDataTableColumns'
import { MarketExplorerSegmentConfiguration } from '../../types/MarketExplorerSegmentConfiguration'
import { SegmentQueryDataType } from '../MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import { MarketExplorerGamesDataTable, MarketExplorerGamesDataTableRow } from './MarketExplorerGamesDataTable/MarketExplorerGamesDataTable'

type MarketExplorerGamesDataProps = {
  segments: SegmentQueryDataType[]
  segmentsConfiguration?: MarketExplorerSegmentConfiguration[]
  isLoading?: boolean
  selectedColumns: GamesDataTableColumnType[]
  onSelectedColumnsChange: (columnIds: GamesDataTableColumnType[]) => void
  showDaysSinceRelease?: boolean
}

const MarketExplorerGamesData: React.FC<MarketExplorerGamesDataProps> = ({
  segments,
  segmentsConfiguration,
  isLoading,
  selectedColumns,
  onSelectedColumnsChange,
  showDaysSinceRelease,
}) => {
  const { t } = useTranslation()

  const segmentGames = useMemo(() => {
    if (!segments) return []
    let uniqueSegment: MarketExplorerGamesDataTableRow[] = []
    let existingSegment: string[] = []
    segments
      .flatMap((segment, index) => {
        return (
          segment?.data?.games.map((game) => ({
            game,
            segmentIndex: index,
            segmentIndexes: [index],
            segmentConfiguration: segment.segmentConfiguration,
            rowId: `${game.id}_${index}`,
            segmentData: segment,
            segments: [segment],
          })) || []
        )
      })
      .forEach((segmentGame) => {
        const segmentKey = `${segmentGame.game.appId}-${segmentGame.segmentConfiguration.marketIso}`
        if (!existingSegment.includes(segmentKey)) {
          existingSegment.push(segmentKey)
          uniqueSegment.push(segmentGame)
        } else {
          const segmentWithGameId = uniqueSegment.map((segment) => segment.game.id)
          const selectedRow = segmentWithGameId.indexOf(segmentGame.game.id)

          if (selectedRow === -1) return
          uniqueSegment[selectedRow].segmentIndexes = uniqueSegment[selectedRow].segmentIndexes?.concat(segmentGame.segmentIndex)
          uniqueSegment[selectedRow].segments = uniqueSegment[selectedRow].segments?.concat(segmentGame.segmentData)
        }
      })
    return uniqueSegment
  }, [segments])

  const tableConfig = useMemo(
    () => ({
      showSegment: true,
    }),
    []
  )

  return (
    <Box className="MarketExplorerGamesData">
      <MarketExplorerGamesDataTable
        rows={segmentGames}
        segmentsConfiguration={segmentsConfiguration}
        tableConfig={tableConfig}
        isLoading={isLoading}
        selectedColumns={selectedColumns}
        onSelectedColumnsChange={onSelectedColumnsChange}
        title={<Typography variant="h3">{t('market-explorer:games_list_tab')}</Typography>}
        showDaysSinceRelease={showDaysSinceRelease}
      />
    </Box>
  )
}

export default MarketExplorerGamesData
