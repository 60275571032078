import { endOfDay, startOfDay, formatDuration, intervalToDuration } from 'date-fns'
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Card, TableContainer } from '@mui/material'

import { useGetTrackedEventQuery } from '../../../../../api/core'
import GRCircularProgress from '../../../../../components/GRCircularProgress/GRCircularProgress'
import { GRTable, GRTableColumn, SortOrder } from '../../../../../components/GRTable/GRTable'
import { useError } from '../../../../../hooks/useError'
import { useDateTimeFormatter } from '../../../hooks/useDateTimeFormatter'
import { useEventInstanceDurations } from '../../../hooks/useEventInstanceDurations'
import { EventInstanceDuration } from '../../../types/EventInstanceDuration'
import { TrackedGame } from '../../../types/TrackedGame'
import { Duration } from '../../../types/TrackingEvents'
import { EventHeader } from '../EventHeader/EventHeader'

type InstancesTabProps = {
  currentTrackedGame?: TrackedGame
  duration?: Duration
  eventId?: string
  eventTypeId?: string
  onEventHighlighted?: (eventId: string) => void
  children?: ReactNode
}

const dateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

export const InstancesTab: FC<InstancesTabProps> = ({ currentTrackedGame, duration, eventId, eventTypeId, onEventHighlighted }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const dateFormatter = useDateTimeFormatter(dateFormat)
  const eventQuery = useGetTrackedEventQuery(eventId as string, { skip: !eventId })
  useError({ error: eventQuery.error })
  const eventInstanceDurations = useEventInstanceDurations(eventQuery.data?.durations)
  const eventName = eventQuery.data?.event.name
  const inactive = eventQuery.data?.event.active === false
  const isLoading = eventQuery.isLoading || eventQuery.isFetching

  const eventInstanceColumns: GRTableColumn<EventInstanceDuration, undefined>[] = useMemo(
    () => [
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:duration_start'),
            accessor: ({ row }) => dateFormatter.format(row.start),
            cellProps: { align: 'left' },
            sortable: true,
            sortOrder: SortOrder.DESC,
            sortAccessor: ({ row }) => row.start,
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:duration_end'),
            accessor: ({ row }) => dateFormatter.format(row.end),
            cellProps: { align: 'left' },
            sortable: true,
            sortAccessor: ({ row }) => row.end,
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:duration'),
            accessor: ({ row }) => {
              return formatDuration(intervalToDuration(row.duration), { format: ['months', 'days', 'hours'] })
            },
            cellProps: { align: 'left' },
            sortable: true,
            sortAccessor: ({ row }) => endOfDay(row.end).getTime() - startOfDay(row.start).getTime(),
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:interval'),
            accessor: ({ row }) => {
              const intervalLength = (row.interval?.end || 0) - (row.interval?.start || 0)
              return (
                row.interval &&
                (intervalLength === 0
                  ? t('live-events:zero_interval')
                  : formatDuration(intervalToDuration(row.interval), { format: ['months', 'days', 'hours'] }))
              )
            },
            cellProps: { align: 'left' },
            sortable: true,
            sortAccessor: ({ row }) => (row.interval ? endOfDay(row.interval.end).getTime() - startOfDay(row.interval.start).getTime() : 0),
          },
        ],
      },
    ],
    [dateFormatter, t]
  )

  const [columns, setColumns] = useState<GRTableColumn<EventInstanceDuration, undefined>[]>(eventInstanceColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<EventInstanceDuration, undefined>[]) => {
    setColumns(updatedColumns)
  }, [])

  return isLoading ? (
    <GRCircularProgress />
  ) : (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <EventHeader
          eventName={eventName}
          inactive={inactive}
          eventId={eventId}
          eventTypeId={eventTypeId}
          trackedGameId={currentTrackedGame?.game.id}
          duration={duration}
          onEventHighlighted={onEventHighlighted}
        />
      </Box>
      <TableContainer ref={containerRef} component={Card}>
        <GRTable
          rows={eventInstanceDurations || []}
          columns={columns}
          onColumnsUpdated={handleColumnsUpdate}
          isLoading={isLoading}
          rowIdKey="id"
          scroller={containerRef}
        />
      </TableContainer>
    </>
  )
}
