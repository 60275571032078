import { Skeleton } from '@mui/material'

export const SkeletonBlockOfText = ({ lines = 5 }: { lines?: number }) => {
  return (
    <>
      {Array.from(Array(lines).keys()).map((line, index) => {
        const width = Math.floor(Math.random() * 100)
        return <Skeleton variant="text" width={`${width}%`} key={index} />
      })}
    </>
  )
}
