/*** estimateHooksV2 contains support for multiple games (Compare Games) ***/
import { endOfMonth, endOfWeek, isAfter, parse, subMonths, subWeeks } from 'date-fns'
import { useMemo } from 'react'

import { average } from '../../../helpers/average'
import { valueIsBetween } from '../../../helpers/valueIsBetween'
import { GameAndAnalysis } from '../../game/types/GameAndAnalysis'
import { getMarketName } from '../../markets/utils/utils'
import { getTimestampByGranularity } from '../../revenue-and-downloads/helpers/helpers'
import { GranularityValue } from '../../revenue-and-downloads/types/Filters'
import { EstimatePlatformType } from '../types/EstimatePlatformType'
import { MarketPerformanceEstimates, MarketsPerformanceEstimates, MarketTotalsByAppId, PerformanceEstimate } from '../types/Estimates'
import { AppEstimates, DailyEstimates, EstimatesList, FilteredPlatformEstimates, MarketEstimates, PlatformType } from '../types/EstimatesList'
import { EstimateDataFilters } from './estimateHooks'

const defaultFilters = {
  rollingDays: 1,
  platformTypes: [EstimatePlatformType.Phone, EstimatePlatformType.Tablet],
  marketIsos: [],
  granularity: GranularityValue.Day,
}

type UseEstimateListHookParamsV2 = {
  gamesAndAnalysis: GameAndAnalysis[]
  data?: EstimatesList
  filters?: EstimateDataFilters
}

export const useEstimatesListV2 = ({ gamesAndAnalysis = [], data = {}, filters = defaultFilters }: UseEstimateListHookParamsV2) => {
  const resolvedFilters = { ...defaultFilters, ...filters }
  const { rollingDays, granularity, dateFrom, dateTo, isCumulative } = resolvedFilters

  // filter only request platforms, markets and games
  const platformEstimates = usePlatformEstimatesV2({
    gamesAndAnalysis,
    estimatesList: data,
    platformTypes: filters.platformTypes,
    marketIsos: filters.marketIsos,
  })
  const { tsMin, tsMax } = useEstimateMinMaxDatesV2({ filteredPlatformEstimates: platformEstimates })

  // combine data from each platform
  const combinedEstimates = useCombinedPlatformEstimatesV2({ appEstimates: platformEstimates })
  const allMarketDates = useAllMarketDatesV2({ marketEstimates: combinedEstimates })

  // fill inexisitng estimate data points with empty data values
  const filledEstimates = useFilledEstimatesV2({ allMarketDates, appEstimates: combinedEstimates })
  const marketTotals = useMarketTotalsV2({ appEstimates: filledEstimates, dateFrom: filters.dateFrom, dateTo: filters.dateTo })

  // count estimates with rolling averages
  const rollingAverageEstimates = useRollingAveragesV2({ appEstimates: filledEstimates, rollingDays })

  // group estimates by time granularity
  const estimatesGroupedByGranularity = useGranularityGroupingV2({ appEstimates: rollingAverageEstimates, granularity, dateFrom, dateTo })

  // map estimate data to suitable format for displaying in charts and tables
  const marketPerformanceEstimates = useMarketPerformanceEstimatesV2({
    appEstimates: estimatesGroupedByGranularity,
    marketTotalsByAppId: marketTotals,
    isCumulative,
    tsMin,
    tsMax,
  })

  // count totals from all markets and platforms
  const estimates = useMarketsPerformanceEstimatesV2({ marketPerformanceEstimates, tsMin, tsMax })

  return estimates
}

/**
 * Hook that filters given games, platform types and markets from estimateDate
 */
const usePlatformEstimatesV2 = ({
  estimatesList = {},
  platformTypes,
  gamesAndAnalysis = [],
}: {
  estimatesList?: EstimatesList
  platformTypes: EstimatePlatformType[]
  marketIsos: string[]
  gamesAndAnalysis: GameAndAnalysis[]
}) => {
  // map platform types to correspond to data fields
  const mappedPlatformTypes = useMemo(() => {
    return platformTypes.map((platformType) => {
      switch (platformType) {
        case EstimatePlatformType.Phone:
          return PlatformType.PhoneEstimates
        case EstimatePlatformType.Tablet:
          return PlatformType.TabletEstimates
        default:
          return PlatformType.PhoneEstimates
      }
    })
  }, [platformTypes])

  // map all estimates by market and return in AppEstimates format
  const platformEstimates = useMemo(() => {
    const appEstimates: FilteredPlatformEstimates = {}

    // Loop through each game and its analysis
    gamesAndAnalysis.forEach((gameAnalysis) => {
      const appId = gameAnalysis.game.appId // Assuming the game object has an id field
      const marketIso = gameAnalysis.analysis?.marketIso as string
      const appIdbyMarketId = `${appId}-${marketIso}`
      appEstimates[appIdbyMarketId] = []

      // Now filter the estimatesList based on the mapped platform types
      mappedPlatformTypes.forEach((platformType) => {
        const byPlatform = estimatesList[platformType as keyof EstimatesList] || {}
        const byAppId = byPlatform[appId] || {}

        Object.entries(byAppId)
          .filter((entry) => {
            return entry[0] === marketIso
          })
          .forEach((entry) => {
            const marketEstimates = {
              [marketIso]: entry[1],
            } as MarketEstimates
            appEstimates[appIdbyMarketId].push(marketEstimates)
          })
      })
    })

    return appEstimates
  }, [estimatesList, mappedPlatformTypes, gamesAndAnalysis])

  return platformEstimates
}

/**
 * Hook that resolves minimum and maximum timestamps from the total estimate data
 */
const useEstimateMinMaxDatesV2 = ({ filteredPlatformEstimates }: { filteredPlatformEstimates: FilteredPlatformEstimates }) => {
  return useMemo(() => {
    const refDate = new Date().setHours(0, 0, 0, 0)

    const tsMin = Object.values(filteredPlatformEstimates).reduce((acc, platformEstimates) => {
      const min = platformEstimates.reduce((innerAcc, platformEstimate) => {
        const marketMin = Object.entries(platformEstimate).reduce((innerInnerAcc, [marketIso, dailyEstimates]) => {
          return Object.keys(dailyEstimates).sort()[0] // Get the minimum date for this platform
        }, '')

        return innerAcc && innerAcc < marketMin ? innerAcc : marketMin // Compare with the current minimum
      }, '')

      return acc && acc < min ? acc : min // Update the overall minimum
    }, '')

    const tsMax = Object.values(filteredPlatformEstimates).reduce((acc, platformEstimates) => {
      const max = platformEstimates.reduce((innerAcc, platformEstimate) => {
        const marketMax = Object.entries(platformEstimate).reduce((innerInnerAcc, [marketIso, dailyEstimates]) => {
          return Object.keys(dailyEstimates).sort().reverse()[0] // Get the maximum date for this platform
        }, '')

        return innerAcc && innerAcc > marketMax ? innerAcc : marketMax // Compare with the current maximum
      }, '')

      return acc && acc > max ? acc : max // Update the overall maximum
    }, '')

    return {
      tsMin: parse(tsMin, 'yyyy-MM-dd', refDate).getTime(),
      tsMax: parse(tsMax, 'yyyy-MM-dd', refDate).getTime(),
    }
  }, [filteredPlatformEstimates])
}

/**
 * Hook that combines the data from all platforms
 */
const useCombinedPlatformEstimatesV2 = ({ appEstimates }: { appEstimates: FilteredPlatformEstimates }): AppEstimates => {
  return useMemo(() => {
    return Object.entries(appEstimates).reduce((appAcc, [appId, marketEstimatesArray]) => {
      if (!appAcc[appId]) {
        appAcc[appId] = {}
      }

      // Combine all market estimates for this appId
      const combinedMarketEstimates = marketEstimatesArray.reduce((marketAcc, marketEstimates) => {
        Object.entries(marketEstimates).forEach(([marketIso, dailyEstimates]) => {
          if (!marketAcc[marketIso]) {
            marketAcc[marketIso] = {}
          }

          Object.entries(dailyEstimates).forEach(([date, estimate]) => {
            const current = marketAcc[marketIso][date]
            if (current) {
              marketAcc[marketIso][date] = {
                d: (current?.d || 0) + (estimate.d || 0),
                r: (current?.r || 0) + (estimate.r || 0),
              }
            } else {
              marketAcc[marketIso][date] = { ...estimate }
            }
          })
        })

        return marketAcc
      }, {} as MarketEstimates)

      appAcc[appId] = combinedMarketEstimates
      return appAcc
    }, {} as AppEstimates)
  }, [appEstimates])
}

/**
 * Hook that extracts all dates from market estimates and returns them as a sorted array
 */
const useAllMarketDatesV2 = ({ marketEstimates }: { marketEstimates: AppEstimates }) => {
  const allMarketDates = useMemo(() => {
    return Object.values(marketEstimates).reduce((acc, marketEstimates) => {
      Object.values(marketEstimates).forEach((dailyEstimates) => {
        Object.keys(dailyEstimates).forEach((date) => {
          acc.add(date) // Add each date to the Set to ensure uniqueness
        })
      })

      return acc
    }, new Set<string>())
  }, [marketEstimates])

  return useMemo(() => Array.from(allMarketDates).sort(), [allMarketDates]) // Convert Set to array and sort
}

/**
 * Hook that maps estimate data to all datapoint dates - this is needed to fill in the gaps in data (all markets do not have data for all dates which makes a mess with further calculations)
 */
const useFilledEstimatesV2 = ({ allMarketDates, appEstimates }: { allMarketDates: string[]; appEstimates: AppEstimates }): AppEstimates => {
  return useMemo(() => {
    return Object.entries(appEstimates).reduce((appAcc, [appId, marketEstimates]) => {
      // Process market estimates for each appId
      const filledMarketEstimates = Object.entries(marketEstimates).reduce((marketAcc, [marketIso, marketEstimate]) => {
        const estimates = allMarketDates.reduce((dateAcc, date) => {
          const estimate = marketEstimate[date]

          if (estimate) {
            dateAcc[date] = {
              r: estimate.r || 0,
              d: estimate.d || 0,
            }
          } else {
            dateAcc[date] = {
              r: 0,
              d: 0,
            }
          }

          return dateAcc
        }, {} as DailyEstimates)

        marketAcc[marketIso] = estimates
        return marketAcc
      }, {} as MarketEstimates)

      appAcc[appId] = filledMarketEstimates
      return appAcc
    }, {} as AppEstimates)
  }, [allMarketDates, appEstimates])
}

/**
 * Hook that counts revenue and downloads total values for each market within given date range
 */
const useMarketTotalsV2 = ({
  appEstimates,
  dateFrom,
  dateTo,
}: {
  appEstimates: AppEstimates
  dateFrom?: number
  dateTo?: number
}): { [appId: string]: { [marketIso: string]: { revenueTotal: number; downloadsTotal: number } } } => {
  return useMemo(() => {
    return Object.entries(appEstimates).reduce((appAcc, [appId, marketEstimates]) => {
      const marketTotals = Object.entries(marketEstimates).reduce((marketAcc, [marketIso, marketEstimate]) => {
        // Filter estimates within the selected date range
        const estimatesFilteredByDateRange = Object.entries(marketEstimate).filter(([date, estimate]) =>
          valueIsBetween(new Date(new Date(date).setHours(0, 0, 0, 0)).getTime(), dateFrom, dateTo)
        )

        const revenueTotal = estimatesFilteredByDateRange.reduce((revAcc, [date, dailyEstimate]) => revAcc + (dailyEstimate.r || 0), 0)

        const downloadsTotal = estimatesFilteredByDateRange.reduce((dlAcc, [date, dailyEstimate]) => dlAcc + (dailyEstimate.d || 0), 0)

        marketAcc[marketIso] = { revenueTotal, downloadsTotal }

        return marketAcc
      }, {} as { [marketIso: string]: { revenueTotal: number; downloadsTotal: number } })

      appAcc[appId] = marketTotals
      return appAcc
    }, {} as { [appId: string]: { [marketIso: string]: { revenueTotal: number; downloadsTotal: number } } })
  }, [appEstimates, dateFrom, dateTo])
}

/**
 * Hook that calculates rolling averages for market estimates based on given rolling days.
 */
const useRollingAveragesV2 = ({ appEstimates, rollingDays }: { appEstimates: AppEstimates; rollingDays: number }): AppEstimates => {
  return useMemo(() => {
    return Object.entries(appEstimates).reduce((appAcc, [appId, marketEstimates]) => {
      // Process each market for the current appId
      const marketRollingAverages = Object.keys(marketEstimates).reduce((marketAcc, marketIso) => {
        const dailyEstimates = marketEstimates[marketIso]
        const estimates = Object.keys(dailyEstimates)
          .sort()
          .reduce((dateAcc, date, index, array) => {
            const estimate = dailyEstimates[date]
            const previousEstimate = dateAcc[array[index - 1]]

            if (rollingDays === 0) {
              const revenue = (estimate?.r || 0) + (previousEstimate?.r || 0)
              const downloads = (estimate?.d || 0) + (previousEstimate?.d || 0)

              dateAcc[date] = {
                r: revenue,
                d: downloads,
              }

              return dateAcc
            }

            if (index + 1 < rollingDays) {
              return dateAcc
            } else {
              const estimateDatesSlice = array.slice(index + 1 - rollingDays, index + 1)
              const revenue = average(estimateDatesSlice.map((estimateDate) => dailyEstimates[estimateDate].r || 0))
              const downloads = average(estimateDatesSlice.map((estimateDate) => dailyEstimates[estimateDate].d || 0))

              dateAcc[date] = {
                r: revenue,
                d: downloads,
              }

              return dateAcc
            }
          }, {} as DailyEstimates)

        marketAcc[marketIso] = estimates

        return marketAcc
      }, {} as MarketEstimates)

      appAcc[appId] = marketRollingAverages
      return appAcc
    }, {} as AppEstimates)
  }, [appEstimates, rollingDays])
}

const useGranularityGroupingV2 = ({
  appEstimates,
  granularity,
  dateFrom,
  dateTo,
}: {
  appEstimates: AppEstimates
  granularity: GranularityValue
  dateFrom?: number
  dateTo?: number
}): AppEstimates => {
  // Determine the last fully completed period (previous week or month) based on granularity
  const now = new Date()
  let lastCompletedPeriodEnd: Date

  if (granularity === GranularityValue.Week) {
    lastCompletedPeriodEnd = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 }) // End of the last completed week (Monday-Sunday)
  } else if (granularity === GranularityValue.Month) {
    lastCompletedPeriodEnd = endOfMonth(subMonths(now, 1)) // End of the last completed month
  } else {
    lastCompletedPeriodEnd = now // For other granularities, use the current date
  }

  // Group and combine estimate data points by date granularity
  return useMemo(() => {
    return Object.entries(appEstimates).reduce((appAcc, [appId, marketEstimates]) => {
      const groupedMarketEstimates = Object.entries(marketEstimates).reduce((marketAcc, [marketIso, marketEstimate]) => {
        Object.entries(marketEstimate).forEach(([date, estimate]) => {
          const granularityParsedDate = new Date(date)
          const parsedDate = getTimestampByGranularity(granularity, granularityParsedDate).getTime()
          const dateFormattedByGranularity = parsedDate

          // Check if the parsed date is before or on the last fully completed period
          if (isAfter(lastCompletedPeriodEnd, new Date(parsedDate))) {
            const included = valueIsBetween(parsedDate, dateFrom, dateTo)
            if (included) {
              if (!marketAcc[marketIso]) {
                marketAcc[marketIso] = {}
              }

              if (marketAcc[marketIso][dateFormattedByGranularity]) {
                const current = marketAcc[marketIso][dateFormattedByGranularity]
                marketAcc[marketIso][dateFormattedByGranularity] = {
                  d: (current?.d || 0) + (estimate.d || 0),
                  r: (current?.r || 0) + (estimate.r || 0),
                }
              } else {
                marketAcc[marketIso][dateFormattedByGranularity] = { ...estimate }
              }
            }
          }
        })

        return marketAcc
      }, {} as MarketEstimates)

      appAcc[appId] = groupedMarketEstimates
      return appAcc
    }, {} as AppEstimates)
  }, [dateFrom, dateTo, granularity, appEstimates, lastCompletedPeriodEnd])
}

const useMarketPerformanceEstimatesV2 = ({
  appEstimates,
  marketTotalsByAppId,
  tsMin,
  tsMax,
  isCumulative,
}: {
  appEstimates: AppEstimates
  marketTotalsByAppId: MarketTotalsByAppId
  tsMin: number
  tsMax: number
  isCumulative?: boolean
}): MarketPerformanceEstimates[] => {
  const marketPerformanceEstimates = useMemo(() => {
    return Object.entries(appEstimates).reduce((appAcc, [appId, marketEstimates]) => {
      // Process each app's market estimates
      const appMarketPerformance = Object.entries(marketEstimates).reduce((acc, [marketIso, marketEstimate]) => {
        let estimates: PerformanceEstimate[] = Object.entries(marketEstimate).map(([date, dailyEstimates]) => {
          return {
            revenue: dailyEstimates.r || 0,
            downloads: dailyEstimates.d || 0,
            revenueAndDownloadsRatio: (dailyEstimates.r || 0) / (dailyEstimates.d || 0),
            ts: +date,
          }
        })

        if (isCumulative) {
          estimates = estimates.reduce((acc, estimate, index) => {
            const previousRevenue = acc?.[index - 1]?.revenue || 0
            const previousDownloads = acc?.[index - 1]?.downloads || 0
            const cumulativeRevenue = estimate.revenue + previousRevenue
            const cumulativeDownloads = estimate.downloads + previousDownloads
            return [
              ...acc,
              {
                revenue: cumulativeRevenue,
                downloads: cumulativeDownloads,
                revenueAndDownloadsRatio: cumulativeRevenue / cumulativeDownloads,
                ts: +estimate.ts,
              },
            ]
          }, [] as PerformanceEstimate[])
        }

        // Revenue & download totals calculated from date range filtered estimates
        const marketTotals = marketTotalsByAppId[appId][marketIso]
        const revenueTotal = marketTotals.revenueTotal
        const downloadsTotal = marketTotals.downloadsTotal

        return [
          ...acc,
          {
            id: appId,
            marketIso,
            marketName: getMarketName(marketIso),
            estimates: estimates.sort((a, b) => a.ts - b.ts),
            total: {
              revenue: revenueTotal,
              downloads: downloadsTotal,
              revenueAndDownloadsRatio: +(revenueTotal / downloadsTotal).toFixed(2),
            },
          },
        ]
      }, [] as MarketPerformanceEstimates[])

      appAcc.push(...appMarketPerformance)
      return appAcc
    }, [] as MarketPerformanceEstimates[])
  }, [appEstimates, isCumulative, marketTotalsByAppId])

  return useMemo(() => {
    return marketPerformanceEstimates.map((marketPerformanceEstimate) => ({
      ...marketPerformanceEstimate,
      tsMin,
      tsMax,
    }))
  }, [marketPerformanceEstimates, tsMin, tsMax])
}

/**
 * Hook that prepares all market performance data and counts totals for all markets combined
 */
const useMarketsPerformanceEstimatesV2 = ({
  marketPerformanceEstimates,
  tsMin,
  tsMax,
}: {
  marketPerformanceEstimates: MarketPerformanceEstimates[]
  tsMin: number
  tsMax: number
}): MarketsPerformanceEstimates => {
  // calculate total values for all markets and order markets by total revenue
  return useMemo(() => {
    const revenue = marketPerformanceEstimates.reduce((acc, marketPerformanceEstimates) => acc + (marketPerformanceEstimates?.total?.revenue || 0), 0)
    const downloads = marketPerformanceEstimates.reduce((acc, marketPerformanceEstimates) => acc + (marketPerformanceEstimates?.total?.downloads || 0), 0)
    const revenueAndDownloadsRatio = revenue / downloads

    const totalEstimates = marketPerformanceEstimates.reduce((acc, marketPerformanceEstimate) => {
      marketPerformanceEstimate.estimates.forEach((estimate) => {
        if (acc[estimate.ts]) {
          const newRevenue = acc[estimate.ts].revenue + estimate.revenue
          const newDownloads = acc[estimate.ts].downloads + estimate.downloads
          acc[estimate.ts].revenue = newRevenue
          acc[estimate.ts].downloads = newDownloads
          acc[estimate.ts].revenueAndDownloadsRatio = +(newRevenue / newDownloads).toFixed(2)
        } else {
          acc[estimate.ts] = { ...estimate }
        }
      })

      return acc
    }, {} as { [date: number]: PerformanceEstimate })

    return {
      markets: marketPerformanceEstimates.sort((a, b) => (b.total?.revenue || 0) - (a.total?.revenue || 0)),
      estimates: Object.values(totalEstimates),
      total: {
        revenue,
        downloads,
        revenueAndDownloadsRatio,
      },
      tsMin,
      tsMax,
    }
  }, [marketPerformanceEstimates, tsMax, tsMin])
}
