import React, { useState } from 'react'

import { Card, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { useGetUserProfileQuery } from '../../../../api/combined'
import { FtueVideo, useGetTutorialVideosQuery } from '../../../../api/core'
import analyticsService from '../../../../services/AnalyticsService'
import { useFTUEVideosAccessCheck, useNewsFeedLimitedAccessCheck } from '../../../account/hooks/roleHooks'
import FtueVideoFullscreenModal from '../../../ftue-videos/components/FtueVideoFullscreenModal/FtueVideoFullscreenModal'
import './TutorialVideosTabView.scss'

/**
 * TutorialVideosTabView
 */
const TutorialVideosTabView: React.FC = () => {
  const { data: tutorialVideos } = useGetTutorialVideosQuery()
  const { data: currentUser } = useGetUserProfileQuery()
  const userLanguage = currentUser?.language || 'en'
  const hasAccessToFTUEVideos = useFTUEVideosAccessCheck()
  const hasNewsFeedLimitedAccess = useNewsFeedLimitedAccessCheck()

  const [openFTUEVideo, setOpenFTUEVideo] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState<FtueVideo | undefined>()

  const handleOpenFTUEVideo = (video: FtueVideo) => {
    if (hasAccessToFTUEVideos || hasNewsFeedLimitedAccess) {
      setOpenFTUEVideo(true)
      setSelectedVideo(video)
    }

    if (video.type === 'onboarding') {
      analyticsService.trackEvent('Viewed Onboarding video', {
        data: {
          video: video.id,
        },
      })
    }
  }

  return (
    <>
      <Box className="TutorialVideosTabView" sx={{ marginTop: 10 }}>
        <Grid container spacing={3} sx={{ flexWrap: 'wrap' }}>
          {tutorialVideos &&
            tutorialVideos.map((tutorialVideo) => {
              return (
                <Grid item sm={6} xs={12} key={tutorialVideo.id}>
                  <Card className="TutorialVideosTabView__cardContainer" onClick={() => handleOpenFTUEVideo(tutorialVideo)}>
                    <img src={tutorialVideo.thumbnails[3].linkWithPlayButton} alt="" style={{ width: '100%' }} />
                    <Typography variant="h3" sx={{ textAlign: 'center', my: 2 }}>
                      {tutorialVideo.names[userLanguage]}
                    </Typography>
                  </Card>
                </Grid>
              )
            })}
        </Grid>
      </Box>
      {openFTUEVideo && !!selectedVideo && (
        <FtueVideoFullscreenModal
          position={0}
          video={selectedVideo}
          onClose={() => {
            setOpenFTUEVideo(false)
          }}
          title={selectedVideo.names[userLanguage]}
        />
      )}
    </>
  )
}

export default TutorialVideosTabView
