import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SelectChangeEvent, FormControl } from '@mui/material'

import { SelectMenu } from '../SelectMenu/SelectMenu'
import { PerformanceChartV2DataType } from './PerformanceChartV2DataType'

type DataTypeSelectorProps = {
  onChange: (event: SelectChangeEvent<PerformanceChartV2DataTypeSelectorOptionValue>) => void
  value: PerformanceChartV2DataTypeSelectorOptionValue
  exclude?: PerformanceChartV2DataType[]
}

export type PerformanceChartV2DataTypeSelectorOptionValue = PerformanceChartV2DataType | ''

export const PerformanceDataTypeSelector: FC<DataTypeSelectorProps> = ({ onChange, value, exclude = [] }) => {
  const { t } = useTranslation()
  const options = useMemo(() => {
    const allOptions = [
      {
        name: `${t('common:revenue_text')} (${t('common:apple_ios')})`,
        value: PerformanceChartV2DataType.Revenue,
      },
      {
        name: `${t('common:downloads_text')} (${t('common:apple_ios')})`,
        value: PerformanceChartV2DataType.Downloads,
      },
      ...[7, 30, 90].map((days) => ({
        name: `${t('common:revenue_downloads_ratio_days', { days })} (${t('common:apple_ios')})`,
        value: PerformanceChartV2DataType[`RevenueDownloadsRatio${days}Days` as keyof typeof PerformanceChartV2DataType],
      })),
      {
        name: `${t('common:all_time_revenue_downloads_ratio')} (${t('common:apple_ios')})`,
        value: PerformanceChartV2DataType.RevenueDownloadsRatioAllTime,
      },
      {
        name: `${t('common:dau')}`,
        value: PerformanceChartV2DataType.DAU,
      },
      {
        name: `${t('common:mau')}`,
        value: PerformanceChartV2DataType.MAU,
      },
      {
        name: `${t('common:grossing_and_free_ranks')}`,
        value: PerformanceChartV2DataType.Ranks,
      },
    ]
    return allOptions.filter((option) => !exclude.includes(option.value))
  }, [t, exclude])

  return (
    <FormControl size="small" variant="outlined" sx={{ minWidth: 310 }}>
      <SelectMenu label={t('common:data')} options={options} onChange={onChange} value={value} />
    </FormControl>
  )
}
