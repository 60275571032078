import { format, startOfToday } from 'date-fns'
import { FC, forwardRef, ReactNode } from 'react'
import { Link } from 'react-router-dom'

type TrackedGameEventsLinkProps = {
  trackedGameId: string
  date?: number
  children?: ReactNode
}

export const TrackedGameEventsLink: FC<TrackedGameEventsLinkProps> = forwardRef<any, TrackedGameEventsLinkProps>(
  ({ trackedGameId, date = startOfToday().getTime(), children, ...rest }, ref) => {
    const dateParam = format(date, 'yyyy-MM-dd')
    return (
      <Link to={`events/${trackedGameId}?date=${dateParam}`} ref={ref} {...rest}>
        {children}
      </Link>
    )
  }
)
