import { FC, useState, useEffect } from 'react'

import { EventScreenshot } from '../../../types/EventScreenshot'

type EventScreenshotThumbnailProps = {
  screenshot: EventScreenshot
  imgWidth?: number | string
}

export const EventScreenshotThumbnail: FC<EventScreenshotThumbnailProps> = ({ screenshot, imgWidth = 100 }) => {
  const [thumbnail, setThumbnail] = useState<string | File>()

  useEffect(() => {
    let objectUrl: string
    if (screenshot.originalData instanceof File) {
      objectUrl = URL.createObjectURL(screenshot.originalData)
      setThumbnail(objectUrl)
    } else {
      setThumbnail(screenshot.originalData)
    }

    return () => (objectUrl ? URL.revokeObjectURL(objectUrl) : undefined)
  }, [screenshot])

  return <img src={thumbnail as string} alt=" " style={{ width: imgWidth, height: 'auto' }} />
}
