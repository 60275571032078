import { useMemo } from 'react'

import utilsService from '../../../services/UtilsService'

export const defaultPerformanceEffectThresholdValue = 0.1
const percentageValues = [0.05, defaultPerformanceEffectThresholdValue, 0.15, 0.2, 0.25, 0.3]

export const useThresholdOptions = () => {
  return useMemo(() => {
    return percentageValues.map((value) => ({
      name: utilsService.formatPercent(value, { maximumFractionDigits: 0 }),
      value,
    }))
  }, [])
}
