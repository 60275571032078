import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'

import { CloseRounded, ModeComment } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TooltipProps, Typography } from '@mui/material'

import { ConceptTag, Screenshot } from '../../api/core'
import { useAnalystReviewsAndCommentsAccessCheck } from '../../features/account/hooks/roleHooks'
import { isConceptTag } from '../../features/feature/helpers/helpers'
import { Game } from '../../features/game'
import GameIcon from '../../features/game/components/GameIcon/GameIcon'
import { EnrichedFeature } from '../../features/implementation-examples'
import languageService from '../../services/LanguageService'
import AddToCollectionButton from '../AddToCollectionButton/AddToCollectionButton'
import { AnalystCommentDialog } from '../AnalystCommentDialog/AnalystCommentDialog'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import NotAvailableIcon from '../NotAvailableIcon/NotAvailableIcon'
import ShareUrlButton from '../ShareUrlButton/ShareUrlButton'
import './ScreenshotListModal.scss'

interface ScreenshotListModalProps {
  game: Game
  onClose: () => void
  onSelectShot: (shot: string) => void
  screenshots: Screenshot[]
  title?: string
  hideListScreenshots?: boolean
  selectedClassifier?: EnrichedFeature | ConceptTag | undefined
}

const ScreenshotListModal: React.FC<ScreenshotListModalProps> = ({
  game,
  onClose,
  onSelectShot,
  screenshots,
  title,
  hideListScreenshots = false,
  selectedClassifier,
}) => {
  const { t } = useTranslation()
  const [analystCommentDialog, setAnalystCommentDialog] = useState(false)
  const [commentId, setCommentId] = useState<Screenshot['commentId']>()
  const hasAnalystReviewsAndCommentsAccessRights = useAnalystReviewsAndCommentsAccessCheck()
  const { gameVersion } = screenshots[0]

  if (hideListScreenshots) {
    return <></>
  }

  return (
    <Dialog className="screenshotListModal" fullWidth maxWidth="lg" open={true} onClose={onClose}>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            {selectedClassifier
              ? isConceptTag(selectedClassifier)
                ? (selectedClassifier as ConceptTag).name
                : languageService.getTranslation('features', (selectedClassifier as EnrichedFeature).legacyId.toString())
              : title}
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={onClose} sx={{ m: -1 }}>
              <CloseRounded />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
          <Grid item>
            <Link to={`/game/${game.id}`}>
              <GameIcon gameName={game.resolvedName} size="normal" src={game.icons[0]} />
            </Link>
          </Grid>
          <Grid item>
            <Link to={`/game/${game.id}`}>
              <Typography variant="h3" color="primary">
                {game.resolvedName}
              </Typography>
              <Typography variant="body1" className="artist">
                {game.artist}
              </Typography>
              <Typography variant="body2" className="version">
                {!gameVersion && <NotAvailableIcon tooltipContent={`${t('common:version')}: ${t('common:not_available_shorthand')}`} />}
                {gameVersion && `${t('common:version')} ${gameVersion}`}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={2} wrap="wrap">
          {screenshots.map((screenshot, index) => (
            <Grid item md={4} sm={6} className="imageContainer" key={index}>
              <LazyLoadImage
                src={screenshot.url}
                effect="blur"
                alt={t('common:screenshots')}
                onClick={() => onSelectShot((screenshot.id || screenshot.screenshotId) as string)}
              />
              <AddToCollectionButton iconOnly={true} screenshotId={screenshot.id} game={game} />
              {screenshot.commentPublished && (
                <GRTooltip content={t('reviews:tooltip_open_analyst_comment')} tooltipProps={{ placement: 'top' } as TooltipProps}>
                  <IconButton
                    className="commentIcon"
                    onClick={() => {
                      setCommentId(screenshot.commentId)
                      setAnalystCommentDialog(true)
                    }}
                  >
                    <ModeComment />
                  </IconButton>
                </GRTooltip>
              )}
            </Grid>
          ))}
        </Grid>
        <AnalystCommentDialog
          commentId={commentId}
          hasAccessRights={hasAnalystReviewsAndCommentsAccessRights}
          gameName={game.resolvedName}
          open={!!analystCommentDialog}
          onClose={() => setAnalystCommentDialog(false)}
          isScreenshotAnalysisComment
        />
      </DialogContent>
      <DialogActions>
        <ShareUrlButton />
      </DialogActions>
    </Dialog>
  )
}

export default ScreenshotListModal
