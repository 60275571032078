import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'

import { Warning } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { Tag } from '../../api/core'
import { GRBanner } from '../../components/GRBanner/GRBanner'
import { LimitedFunctionalityBanner } from '../../components/LimitedFunctionalityBanner/LimitedFunctionalityBanner'
import { LinkWithQuery } from '../../components/LinkWithQuery/LinkWithQuery'
import { LockedFeature } from '../../components/LockedFeature/LockedFeature'
import ShareUrlButton from '../../components/ShareUrlButton/ShareUrlButton'
import { useMarketExplorerAccessCheck } from '../../features/account/hooks/roleHooks'
import { useFeatureTags } from '../../features/feature/hooks/useFeatureTags'
import { LoadUserSegmentsDialog } from '../../features/market-explorer/components/LoadUserSegmentsDialog/LoadUserSegmentsDialog'
import MarketExplorerFeaturesData from '../../features/market-explorer/components/MarketExplorerFeaturesData/MarketExplorerFeaturesData'
import MarketExplorerGamesData from '../../features/market-explorer/components/MarketExplorerGamesData/MarketExplorerGamesData'
import MarketExplorerGamesOverview from '../../features/market-explorer/components/MarketExplorerGamesOverview/MarketExplorerGamesOverview'
import MarketExplorerMotivations from '../../features/market-explorer/components/MarketExplorerMotivations/MarketExplorerMotivations'
import {
  SegmentQueryDataType,
  SegmentQueryReturnType,
} from '../../features/market-explorer/components/MarketExplorerSegments/MarketExplorerSegment/MarketExplorerSegment'
import MarketExplorerSegments, { SegmentsQueryReturnType } from '../../features/market-explorer/components/MarketExplorerSegments/MarketExplorerSegments'
import { SaveUserSegmentDialog } from '../../features/market-explorer/components/SaveUserSegmentDialog/SaveUserSegmentDialog'
import { useMarketExplorerSearchParams } from '../../features/market-explorer/hooks/useMarketExplorerSearchParams'
import { FeaturesDataTableColumn, FeaturesDataTableColumnType } from '../../features/market-explorer/types/MarketExplorerFeaturesDataTableColumn'
import { MarketExplorerGamesChartAxisId } from '../../features/market-explorer/types/MarketExplorerGamesChartAxisId'
import { MarketExplorerGamesChartAxisScale, MarketExplorerGamesChartAxisType } from '../../features/market-explorer/types/MarketExplorerGamesChartAxisType'
import { GamesDataTableColumn, GamesDataTableColumnType } from '../../features/market-explorer/types/MarketExplorerGamesDataTableColumns'
import { MarketExplorerSegmentConfiguration } from '../../features/market-explorer/types/MarketExplorerSegmentConfiguration'
import { MarketExplorerSubpage } from '../../features/market-explorer/types/MarketExplorerSubpage'
import { MarketExplorerUserSegment } from '../../features/market-explorer/types/MarketExplorerUserSegment'
import { useMobileSize } from '../../features/responsiveness/hooks/responsivenessHooks'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import usePage from '../../hooks/usePage'
import analyticsService from '../../services/AnalyticsService'
import marketExplorerService from '../../services/MarketExplorerService'
import PageService from '../../services/PageService'
import { AnalyticsProviders } from '../../types/IAnalyticsProvider'
import { LockedFeatureId } from '../../types/LockedFeature'
import './MarketExplorerPage.scss'

const defaultGameDataSelectedColumns = [GamesDataTableColumn.FeatureIndicators, GamesDataTableColumn.SustainedRanks, GamesDataTableColumn.Gender]
const defaultSelectedFeatureDataColumns = [
  FeaturesDataTableColumn.ImpactOverall,
  FeaturesDataTableColumn.Top20PercentPopularity,
  FeaturesDataTableColumn.Top50PercentPopularity,
  FeaturesDataTableColumn.GenderAppeal,
  FeaturesDataTableColumn.AgeAppeal,
]
const LOW_POSITION_EVENT_NAME = 'low'
const HIGH_POSITION_EVENT_NAME = 'high'

const MarketExplorerPage: React.FC = () => {
  const { t } = useTranslation()
  usePage(PageService.getPageWithId('market-explorer'), 'Visited Market Explorer')
  useDocumentTitle(t('market-explorer:page_title'))
  const mobileSize = useMobileSize()
  const { setSearchParams, parsedParams } = useMarketExplorerSearchParams()
  const hasMarketExplorerAccess = useMarketExplorerAccessCheck()
  const [loadUserSegmentsDialogOpen, setLoadUserSegmentsDialogOpen] = useState<boolean>(false)
  const [saveUserSegmentDialogOpen, setSaveUserSegmentDialogOpen] = useState<boolean>(false)
  const featureTags = useFeatureTags()
  const [selectedFeatureTags, setSelectedFeatureTags] = useState<Tag[]>()
  const [lockedFeatureDialogOpen, setLockedFeatureDialogOpen] = useState<boolean>(false)

  // assign default selected columns on initial render if they are not defined in the url
  useEffect(() => {
    if (parsedParams.gameDataColumns.length === 0) {
      setSearchParams((currentParams) => ({ ...currentParams, gameDataColumns: defaultGameDataSelectedColumns }))
    }

    if (parsedParams.featureDataColumns.length === 0) {
      setSearchParams((currentParams) => ({ ...currentParams, featureDataColumns: defaultSelectedFeatureDataColumns }))
    }
  }, [parsedParams.featureDataColumns.length, parsedParams.gameDataColumns.length, setSearchParams])

  // set initial feature tags once the data is loaded
  useEffect(() => {
    if (!selectedFeatureTags && featureTags.isSuccess) {
      const initialTags = featureTags.data?.map((group) => group.tags).flat() || []
      setSelectedFeatureTags(initialTags)
    }
  }, [featureTags.data, featureTags.isSuccess, selectedFeatureTags])

  const marketExplorerSubpages: MarketExplorerSubpage[] = useMemo(() => {
    return [
      {
        id: 'games-overview',
        name: t('market-explorer:games_chart_tab'),
      },
      {
        id: 'games-data',
        name: t('market-explorer:games_list_tab'),
      },
      {
        id: 'features-data',
        name: t('market-explorer:features_list_tab'),
      },
      {
        id: 'motivations',
        name: t('motivations:motivations'),
      },
    ]
  }, [t])
  const urlParams: { id?: string; subpage?: string } = useParams()
  const currentSubpage: MarketExplorerSubpage | undefined = marketExplorerSubpages.find((subpage) => subpage.id === urlParams.subpage)
  const [selectedSubpage, setSelectedSubpage] = useState(currentSubpage ? marketExplorerSubpages.findIndex((subpage) => subpage.id === urlParams.subpage) : 0)
  const [segmentsQueryData, setSegmentsQueryData] = useState<SegmentsQueryReturnType>({ data: [] })
  const segmentsData = useMemo(() => {
    return (
      segmentsQueryData.data.reduce((acc, sqd: SegmentQueryDataType) => {
        return [...acc, sqd]
      }, [] as SegmentQueryDataType[]) || []
    )
  }, [segmentsQueryData])

  useEffect(() => {
    setSelectedSubpage(currentSubpage ? marketExplorerSubpages.findIndex((subpage) => subpage.id === currentSubpage.id) : 0)
  }, [currentSubpage, marketExplorerSubpages])

  useEffect(() => {
    analyticsService.trackEvent('Market Explorer: Changed Tab', {
      data: {
        tab: marketExplorerSubpages[selectedSubpage].id,
      },
    })
  }, [marketExplorerSubpages, selectedSubpage])

  const handleSubpageChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedSubpage(newValue)
  }

  const handleSegmentsQueryDataChange = useCallback((segmentsQueryData: SegmentsQueryReturnType) => {
    setSegmentsQueryData(segmentsQueryData)
  }, [])

  const handleAxisMinMaxSelectChange = useCallback(
    (
      id: MarketExplorerGamesChartAxisId,
      axisType: MarketExplorerGamesChartAxisType,
      scaleType: 'max' | 'min',
      maxAxisScale: MarketExplorerGamesChartAxisScale,
      minAxisScale: MarketExplorerGamesChartAxisScale
    ) => {
      let newMaxScale = maxAxisScale
      let newMinScale = minAxisScale

      analyticsService.trackEvent('Market Explorer: Changed Axis', {
        data: { axis: id, type: axisType },
        serviceToExclude: [AnalyticsProviders.hubspot],
      })

      if ((minAxisScale.value >= maxAxisScale.value || maxAxisScale.value <= minAxisScale.value) && !axisType.inversed) {
        switch (scaleType) {
          case 'max':
            newMinScale = axisType.minScales.sort((a, b) => (a.value < b.value ? 1 : -1)).find((scale) => scale.value < maxAxisScale.value) || minAxisScale
            analyticsService.trackEvent('Market Explorer: Changed Scale', {
              data: { axis: id, type: axisType, position: LOW_POSITION_EVENT_NAME, value: newMinScale.label },
              serviceToExclude: [AnalyticsProviders.hubspot],
            })
            break

          case 'min':
            newMaxScale = axisType.maxScales.sort((a, b) => (a.value < b.value ? 1 : -1)).find((scale) => scale.value > maxAxisScale.value) || maxAxisScale
            analyticsService.trackEvent('Market Explorer: Changed Scale', {
              data: { axis: id, type: axisType, position: LOW_POSITION_EVENT_NAME, value: newMaxScale.label },
              serviceToExclude: [AnalyticsProviders.hubspot],
            })
            break
        }
      } else if ((minAxisScale.value <= maxAxisScale.value || maxAxisScale.value >= minAxisScale.value) && axisType.inversed) {
        switch (scaleType) {
          case 'max':
            analyticsService.trackEvent('Market Explorer: Changed Scale', {
              data: { axis: id, type: axisType, position: HIGH_POSITION_EVENT_NAME, value: newMaxScale.label },
              serviceToExclude: [AnalyticsProviders.hubspot],
            })
            newMinScale = axisType.minScales.sort((a, b) => (a.value > b.value ? 1 : -1)).find((scale) => scale.value > maxAxisScale.value) || minAxisScale
            break

          case 'min':
            newMaxScale = axisType.minScales.sort((a, b) => (a.value < b.value ? 1 : -1)).find((scale) => scale.value < maxAxisScale.value) || maxAxisScale
            analyticsService.trackEvent('Market Explorer: Changed Scale', {
              data: { axis: id, type: axisType, position: HIGH_POSITION_EVENT_NAME, value: newMaxScale.label },
              serviceToExclude: [AnalyticsProviders.hubspot],
            })
            break
        }
      }

      switch (id) {
        case MarketExplorerGamesChartAxisId.Y:
          setSearchParams((currentParams) => ({ ...currentParams, chartYAxisType: axisType, chartYAxisMin: newMinScale, chartYAxisMax: newMaxScale }))
          break

        case MarketExplorerGamesChartAxisId.X:
          setSearchParams((currentParams) => ({ ...currentParams, chartXAxisType: axisType, chartXAxisMin: newMinScale, chartXAxisMax: newMaxScale }))
          break
      }
    },
    [setSearchParams]
  )

  const handleAxisMaxSelectChange = useCallback(
    (
      id: MarketExplorerGamesChartAxisId,
      axisType: MarketExplorerGamesChartAxisType,
      axisScale: MarketExplorerGamesChartAxisScale,
      minAxisScale: MarketExplorerGamesChartAxisScale
    ) => {
      handleAxisMinMaxSelectChange(id, axisType, 'max', axisScale, minAxisScale)
    },
    [handleAxisMinMaxSelectChange]
  )

  const handleAxisMinSelectChange = useCallback(
    (
      id: MarketExplorerGamesChartAxisId,
      axisType: MarketExplorerGamesChartAxisType,
      axisScale: MarketExplorerGamesChartAxisScale,
      maxAxisScale: MarketExplorerGamesChartAxisScale
    ) => {
      handleAxisMinMaxSelectChange(id, axisType, 'min', maxAxisScale, axisScale)
    },
    [handleAxisMinMaxSelectChange]
  )

  const handleAxisSelectChange = useCallback(
    (id: MarketExplorerGamesChartAxisId, axisType: MarketExplorerGamesChartAxisType) => {
      switch (id) {
        case MarketExplorerGamesChartAxisId.Y:
          if (axisType.id === parsedParams.chartXAxisType.id) {
            handleAxisMaxSelectChange(MarketExplorerGamesChartAxisId.X, parsedParams.chartYAxisType, parsedParams.chartYAxisMax, parsedParams.chartYAxisMin)
            handleAxisMinSelectChange(MarketExplorerGamesChartAxisId.X, parsedParams.chartYAxisType, parsedParams.chartYAxisMin, parsedParams.chartYAxisMax)
          }

          break

        case MarketExplorerGamesChartAxisId.X:
          if (axisType.id === parsedParams.chartYAxisType.id) {
            handleAxisMaxSelectChange(MarketExplorerGamesChartAxisId.Y, parsedParams.chartXAxisType, parsedParams.chartXAxisMax, parsedParams.chartXAxisMin)
            handleAxisMinSelectChange(MarketExplorerGamesChartAxisId.Y, parsedParams.chartXAxisType, parsedParams.chartXAxisMin, parsedParams.chartXAxisMax)
          }

          break
      }

      handleAxisMaxSelectChange(id, axisType, axisType.defaultMaxScale, axisType.defaultMinScale)
      handleAxisMinSelectChange(id, axisType, axisType.defaultMinScale, axisType.defaultMaxScale)
    },
    [
      handleAxisMaxSelectChange,
      handleAxisMinSelectChange,
      parsedParams.chartXAxisMax,
      parsedParams.chartXAxisMin,
      parsedParams.chartXAxisType,
      parsedParams.chartYAxisMax,
      parsedParams.chartYAxisMin,
      parsedParams.chartYAxisType,
    ]
  )

  const handleSegmentsChange = useCallback(
    (segments: MarketExplorerSegmentConfiguration[]) => {
      setSearchParams((currentParams) => ({ ...currentParams, segments }))
    },
    [setSearchParams]
  )

  const handleGamesDataColumnSelectionChange = useCallback(
    (columnIds: GamesDataTableColumnType[]) => {
      setSearchParams((currentParams) => ({ ...currentParams, gameDataColumns: columnIds }))
    },
    [setSearchParams]
  )

  const handleFeaturesDataColumnSelectionChange = useCallback(
    (columnIds: FeaturesDataTableColumnType[]) => {
      setSearchParams((currentParams) => ({ ...currentParams, featureDataColumns: columnIds }))
    },
    [setSearchParams]
  )

  const handleSelectedUserSegmentLoad = useCallback(
    (userSegment: MarketExplorerUserSegment) => {
      analyticsService.trackEvent('Market Explorer: Loaded Segment', { data: { userSegment } })
      setSearchParams((currentParams) => {
        const chartXAxisType = marketExplorerService.getChartAxisTypeByTypeId(userSegment.chart!.xAxisType)
        const chartYAxisType = marketExplorerService.getChartAxisTypeByTypeId(userSegment.chart!.yAxisType)
        const chartXAxisMin = marketExplorerService.getScaleValueByAxisTypeIdAndScaleLabel(userSegment.chart!.xAxisType, userSegment.chart!.xAxisMin, 'min')
        const chartXAxisMax = marketExplorerService.getScaleValueByAxisTypeIdAndScaleLabel(userSegment.chart!.xAxisType, userSegment.chart!.xAxisMax, 'max')
        const chartYAxisMin = marketExplorerService.getScaleValueByAxisTypeIdAndScaleLabel(userSegment.chart!.yAxisType, userSegment.chart!.yAxisMin, 'min')
        const chartYAxisMax = marketExplorerService.getScaleValueByAxisTypeIdAndScaleLabel(userSegment.chart!.yAxisType, userSegment.chart!.yAxisMax, 'max')

        return {
          ...currentParams,
          segments: userSegment.segments,
          chartXAxisType,
          chartYAxisType,
          chartXAxisMin,
          chartXAxisMax,
          chartYAxisMin,
          chartYAxisMax,
        }
      })
    },
    [setSearchParams]
  )

  const handleSaveSegmentsClick = useCallback(() => {
    if (hasMarketExplorerAccess) {
      setSaveUserSegmentDialogOpen(true)
    } else {
      setLockedFeatureDialogOpen(true)
    }
  }, [hasMarketExplorerAccess])

  const hadnleLoadSegmentsClick = useCallback(() => {
    if (hasMarketExplorerAccess) {
      setLoadUserSegmentsDialogOpen(true)
    } else {
      setLockedFeatureDialogOpen(true)
    }
  }, [hasMarketExplorerAccess])

  const handleSelectedFeatureTagsChange = useCallback((tags: Tag[]) => {
    setSelectedFeatureTags(tags)
  }, [])

  const chartConfiguration = useMemo(
    () => ({
      yAxisType: parsedParams.chartYAxisType.id,
      yAxisMin: parsedParams.chartYAxisMin.label,
      yAxisMax: parsedParams.chartYAxisMax.label,
      xAxisType: parsedParams.chartXAxisType.id,
      xAxisMin: parsedParams.chartXAxisMin.label,
      xAxisMax: parsedParams.chartXAxisMax.label,
    }),
    [
      parsedParams.chartXAxisMax.label,
      parsedParams.chartXAxisMin.label,
      parsedParams.chartXAxisType.id,
      parsedParams.chartYAxisMax.label,
      parsedParams.chartYAxisMin.label,
      parsedParams.chartYAxisType.id,
    ]
  )

  if (!urlParams.subpage || !currentSubpage) {
    return <Navigate replace to={`/market-explorer/games-overview`} />
  } else {
    return (
      <div className="MarketExplorerPage">
        {!hasMarketExplorerAccess && (
          <Box mb={2}>
            <LimitedFunctionalityBanner lockedFeatureId={LockedFeatureId.MarketExplorer} />
          </Box>
        )}
        <LoadUserSegmentsDialog
          open={loadUserSegmentsDialogOpen}
          onClose={() => setLoadUserSegmentsDialogOpen(false)}
          onSelectSegment={handleSelectedUserSegmentLoad}
        />
        <SaveUserSegmentDialog
          open={saveUserSegmentDialogOpen}
          onClose={() => setSaveUserSegmentDialogOpen(false)}
          segments={parsedParams.segments}
          chartConfiguration={chartConfiguration}
        />

        <LockedFeature.Dialog
          lockedFeatureId={LockedFeatureId.MarketExplorer}
          open={lockedFeatureDialogOpen}
          onClose={() => setLockedFeatureDialogOpen(false)}
        />

        <Card className="MarketExplorerPage__header">
          <CardHeader
            title={
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>{t('market-explorer:segments_header_title')}</Grid>
                <Grid item>
                  <Grid container gap={2}>
                    {parsedParams.segments.length > 0 && <ShareUrlButton />}
                    <Button variant="contained" color="success" disabled={parsedParams.segments.length === 0} onClick={handleSaveSegmentsClick}>
                      {t('market-explorer:save_segments_button')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={hadnleLoadSegmentsClick}>
                      {t('market-explorer:load_segments_button')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          <CardContent>
            <MarketExplorerSegments
              onSegmentsQueryDataChange={handleSegmentsQueryDataChange}
              onSegmentsChange={handleSegmentsChange}
              segments={parsedParams.segments}
            ></MarketExplorerSegments>
          </CardContent>
        </Card>

        <Card
          className={!mobileSize ? 'MarketExplorerPage__header-sticky' : 'MarketExplorerPage__header-sticky MarketExplorerPage__header-sticky--mobile'}
          sx={{ mb: 3 }}
        >
          <Tabs value={selectedSubpage} onChange={handleSubpageChange} variant="scrollable" scrollButtons="auto">
            {marketExplorerSubpages.map((subpage) => (
              <Tab key={subpage.id} label={subpage.name} component={LinkWithQuery} to={`/market-explorer/${subpage.id}`} />
            ))}
          </Tabs>
        </Card>

        {segmentsQueryData.isLoading && (
          <div className="text-center">
            <Box m={6}>
              <p>{t('market-explorer:label_fetching_data')}</p>
              <Box mt={4}>
                <CircularProgress color="primary" />
              </Box>
            </Box>
          </div>
        )}

        {!segmentsQueryData.isLoading && currentSubpage && (
          <div className="MarketExplorerPage__subpage">
            {currentSubpage.id === 'games-overview' && (
              <NoSegmentsChecker segmentsData={segmentsData}>
                <MarketExplorerGamesOverview
                  segments={segmentsData}
                  chartYAxisType={parsedParams.chartYAxisType}
                  chartYAxisMax={parsedParams.chartYAxisMax}
                  chartYAxisMin={parsedParams.chartYAxisMin}
                  chartXAxisType={parsedParams.chartXAxisType}
                  chartXAxisMax={parsedParams.chartXAxisMax}
                  chartXAxisMin={parsedParams.chartXAxisMin}
                  onAxisSelectChange={handleAxisSelectChange}
                  onAxisMaxValueSelectChange={handleAxisMaxSelectChange}
                  onAxisMinValueSelectChange={handleAxisMinSelectChange}
                  selectedColumns={parsedParams.gameDataColumns}
                  onSelectedColumnsChange={handleGamesDataColumnSelectionChange}
                  onSegmentsQueryDataChange={handleSegmentsQueryDataChange}
                  onSegmentsChange={handleSegmentsChange}
                  segmentData={parsedParams.segments}
                ></MarketExplorerGamesOverview>
              </NoSegmentsChecker>
            )}

            {currentSubpage.id === 'games-data' && (
              <NoSegmentsChecker segmentsData={segmentsData}>
                <MarketExplorerGamesData
                  segments={segmentsData}
                  segmentsConfiguration={parsedParams.segments}
                  isLoading={segmentsQueryData.isLoading}
                  selectedColumns={parsedParams.gameDataColumns}
                  onSelectedColumnsChange={handleGamesDataColumnSelectionChange}
                  showDaysSinceRelease
                ></MarketExplorerGamesData>
              </NoSegmentsChecker>
            )}

            {currentSubpage.id === 'features-data' && (
              <NoSegmentsChecker segmentsData={segmentsData}>
                <MarketExplorerFeaturesData
                  segments={segmentsData}
                  segmentConfiguration={parsedParams.segments}
                  selectedColumns={parsedParams.featureDataColumns}
                  onSelectedColumnsChange={handleFeaturesDataColumnSelectionChange}
                  selectedGamesDataColumns={parsedParams.gameDataColumns}
                  onSelectedGamesDataColumnsChange={handleGamesDataColumnSelectionChange}
                  selectedFeatureTags={selectedFeatureTags}
                  onSelectedFeatureTagsChange={handleSelectedFeatureTagsChange}
                  onSegmentsQueryDataChange={handleSegmentsQueryDataChange}
                  onSegmentsChange={handleSegmentsChange}
                />
              </NoSegmentsChecker>
            )}

            {currentSubpage.id === 'motivations' && (
              <NoSegmentsChecker segmentsData={segmentsData}>
                <MarketExplorerMotivations
                  segments={segmentsData.filter((segment): segment is SegmentQueryReturnType => segment !== null)}
                  onSegmentsChange={handleSegmentsChange}
                ></MarketExplorerMotivations>
              </NoSegmentsChecker>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default MarketExplorerPage

export const NoSegmentsChecker: React.FC<{ segmentsData: SegmentQueryDataType[]; children?: ReactNode }> = ({ segmentsData, children }) => {
  return segmentsData.length === 0 ? (
    <GRBanner severity="guidance" icon={<Warning />}>
      <Typography variant="body1" sx={{ ml: 2 }}>
        <Trans i18nKey="market-explorer:no_segments_text" />
      </Typography>
    </GRBanner>
  ) : (
    <>{children}</>
  )
}
