export const tier1MarketIsos = ['gb', 'ca', 'au', 'nz']

export const tier1Markets: Tier1Market = tier1MarketIsos.reduce((acc, value, index) => {
  acc[value] = { order: index + 1, tier1Market: true }
  return acc
}, {} as Tier1Market)

export const tier1MarketIso = tier1MarketIsos.join('-')

type Tier1Market = {
  [marketIso: string]: {
    order: number
    tier1Market: boolean
  }
}
