import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'

import { useCreateBookmarkMutation, useUpdateBookmarkMutation } from '../../../api/core'
import { displaySnackBar } from '../../../features/snackbar/actions/displaySnackBar'
import { useAppDispatch } from '../../../hooks/storeHooks'
import { TakeScreenshotProps, useScreenshot } from '../../../hooks/useScreenshot'
import analyticsService from '../../../services/AnalyticsService'
import { OnePixelBlueImageBase64 } from '../../../services/UtilsService'
import { AnalyticsProviders } from '../../../types/IAnalyticsProvider'
import { Bookmark } from '../types/Bookmark'

type EditBookMarkDialogProps = DialogProps & {
  bookmark: Bookmark
}

/**
 * Component that displays a dialog to edit a bookmark.
 */
const EditBookMarkDialog: React.FC<EditBookMarkDialogProps> = ({ bookmark, open, onClose, ...dialogProps }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [updateBookmark, { isLoading: isUpdateBookmarkLoading, isSuccess: updateIsSuccess, isError: updateIsError, reset: resetUpdateBookmark }] =
    useUpdateBookmarkMutation()
  const [takingScreenshot, setTakingScreenshot] = useState(false)
  const [createBookmark, { isLoading: isCreateBookMarkLoading, isSuccess: createIsSuccess, isError: createIsError }] = useCreateBookmarkMutation()
  const { takeScreenshot } = useScreenshot()
  const ref = useRef(document.getElementsByClassName('AppWrapper__content')[0])
  const [bookmarkData, setBookmarkData] = useState<Bookmark>({
    id: bookmark.id,
    name: bookmark.name,
    description: bookmark.description,
    email: bookmark.email,
    imageUrl: bookmark.imageUrl,
    urlPath: bookmark.urlPath,
    isPublic: bookmark.isPublic,
  } as Bookmark)

  const takeScrenshotOptions: TakeScreenshotProps = {
    cropHeight: 2000,
  }

  const handleSave = () => {
    if (bookmarkData.id) {
      updateBookmark(bookmarkData)

      analyticsService.trackEvent('Bookmark Updated', {
        data: Object.assign({}, bookmarkData),
        serviceToExclude: [AnalyticsProviders.hubspot, AnalyticsProviders.intercom],
      })
    } else {
      setTakingScreenshot(true)
      takeScreenshot(ref.current as HTMLElement, takeScrenshotOptions).then((base64) => {
        setTakingScreenshot(false)
        createBookmark({ bookmark: bookmarkData, imageDataBase64: base64 ? (base64 as string) : OnePixelBlueImageBase64 })

        analyticsService.trackEvent('Bookmark Created', {
          data: Object.assign({}, bookmarkData),
          serviceToExclude: [AnalyticsProviders.hubspot, AnalyticsProviders.intercom],
        })
      })
    }
  }

  const handleClose = useCallback(() => {
    if (onClose) {
      resetUpdateBookmark()
      onClose({}, 'escapeKeyDown')
    }
  }, [onClose, resetUpdateBookmark])

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBookmarkData((previousState) => {
      const { name, value, checked, type } = event.target
      return {
        ...previousState,
        [name as keyof Bookmark]: type === 'checkbox' ? checked : value,
      }
    })
  }

  const isBookmarkDataValid = useMemo(() => {
    if (!bookmarkData) return false
    if (!bookmarkData.name || !bookmarkData?.name.length) return false
    return true
  }, [bookmarkData])

  useEffect(() => {
    if (updateIsSuccess) {
      dispatch(displaySnackBar({ message: t('bookmarks:bookmark_saved_notification'), severity: 'success', open: true }))
      handleClose()
    }
    if (updateIsError) {
      dispatch(displaySnackBar({ message: t('common:general_error_message'), severity: 'error', open: true }))
    }
  }, [updateIsError, updateIsSuccess, dispatch, t, handleClose])

  useEffect(() => {
    if (createIsSuccess) {
      dispatch(displaySnackBar({ message: t('bookmarks:bookmark_created_notification'), severity: 'success', open: true }))
      handleClose()
    }
    if (createIsError) {
      dispatch(displaySnackBar({ message: t('common:general_error_message'), severity: 'error', open: true }))
    }
  }, [createIsError, createIsSuccess, dispatch, t, handleClose])

  return (
    <Dialog maxWidth="sm" fullWidth open={open} {...dialogProps}>
      <DialogTitle sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography>
          <Trans i18nKey={'bookmarks:bookmark_settings'} />
        </Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={(e) => onClose(e, 'escapeKeyDown')}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1} direction="column">
          <Grid item>
            <TextField required name="name" label={t('bookmarks:bookmark_name')} fullWidth defaultValue={bookmarkData.name} onChange={handleInput} />
          </Grid>
          <Grid item>
            <TextField
              id="description"
              name="description"
              label={t('bookmarks:bookmark_description')}
              fullWidth
              multiline
              rows={4}
              defaultValue={bookmarkData.description}
              onChange={handleInput}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              name="isPublic"
              control={<Checkbox defaultChecked={bookmarkData.isPublic} onChange={handleInput} />}
              label={t('bookmarks:share_bookmark_with_organization') as string}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isCreateBookMarkLoading || isUpdateBookmarkLoading || takingScreenshot}>
          <Trans i18nKey="common:cancel" />
        </Button>
        <LoadingButton
          variant="contained"
          endIcon={<SaveIcon />}
          disabled={!isBookmarkDataValid}
          loadingPosition="end"
          loading={isCreateBookMarkLoading || isUpdateBookmarkLoading || takingScreenshot}
          onClick={handleSave}
        >
          <Trans i18nKey="common:save" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default EditBookMarkDialog
