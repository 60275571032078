import classNames from 'classnames'
import React, { useState } from 'react'

import { PlayCircle } from '@mui/icons-material'
import { Box, Button, CardContent, CircularProgress, IconButton, Typography } from '@mui/material'

import { useGetUserProfileQuery } from '../../../../api/combined'
import { FtueVideo } from '../../../../api/core'
import languageService from '../../../../services/LanguageService'
import type { Game } from '../../../game'
import GameIcon from '../../../game/components/GameIcon/GameIcon'
import { GameLink } from '../../../game/components/GameLink/GameLink'
import { useVideoChapterTags } from '../../hooks/useVideoTags'
import { formatSeconds } from '../FtueVideoPlayer/formatSeconds'
import './GameChapterList.scss'

export type SelectedChapterTag = {
  chapterId: number
  tagId: string
}

interface GameChapterListProps {
  game?: Game
  video: FtueVideo
  currentPosition: number
  setPosition: (seconds: number, startPlay?: boolean) => void
  title?: string
  selectedChapterTag?: SelectedChapterTag
  onChapterTagClick?: ({ chapterId, tagId }: SelectedChapterTag) => void
}

const formatMillis = (millis: number): string => formatSeconds(millis / 1000)

/**
 * The game label with icon, followed by a list of video chapters with tags.
 * Using CardContent blocks here, so please wrap inside <Card /> or change blocks to something else e.g. MenuItems
 *
 * @constructor
 */
const GameChapterList: React.FC<GameChapterListProps> = ({
  currentPosition,
  game,
  video: { chapters },
  setPosition,
  title,
  selectedChapterTag,
  onChapterTagClick,
}) => {
  const { data: chapterTags, isFetching } = useVideoChapterTags()
  const { data: currentUser } = useGetUserProfileQuery()
  const userLanguage = currentUser?.language || languageService.defaultLanguage
  const [videoChapterHighlight, setVideoChapterHighlight] = useState<number | undefined>(undefined)

  return (
    <Box className="chapterList">
      <Box>
        {game ? (
          <CardContent>
            <GameLink game={game}>
              <GameIcon gameName={game.resolvedName} src={game.icons[0] || ''} />
            </GameLink>
            <Box className="gameDetails">
              <Typography variant="h3" color="primary">
                {game.resolvedName}
              </Typography>
              <Typography variant="subtitle2">{game.artist}</Typography>
            </Box>
          </CardContent>
        ) : (
          <CardContent sx={{ padding: '16px' }}>
            <Typography variant="h3">{title}</Typography>
          </CardContent>
        )}
      </Box>
      <Box maxHeight={'87.2vh'} overflow={'scroll'}>
        {isFetching ? (
          <CardContent sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </CardContent>
        ) : (
          chapters.map((chapter, i) => {
            const chapterStartTime = chapter.dataSeek / 1000
            const classnames = classNames(videoChapterHighlight === chapter.chapterId ? `${'videoChapter'} ${'videoChapterHighlight'}` : 'videoChapter')

            return (
              <CardContent className={classnames} key={`chapter-${i}`} sx={{ display: 'flex' }}>
                <IconButton
                  size="small"
                  onClick={() => {
                    setPosition(chapterStartTime, true)
                    setVideoChapterHighlight(chapter.chapterId)
                  }}
                  sx={{ lineHeight: '16px' }}
                >
                  <PlayCircle color="primary" />
                </IconButton>
                <Typography className="time">
                  {chapter.titles && !chapter.tags.length ? (
                    <Box onClick={() => setPosition(chapterStartTime, true)} sx={{ cursor: 'pointer' }}>
                      <span style={{ marginRight: '8px' }}>{formatMillis(chapter.dataSeek)}</span>
                      <span>{chapter.titles[userLanguage]}</span>
                    </Box>
                  ) : (
                    formatMillis(chapter.dataSeek)
                  )}
                </Typography>
                <Box sx={{ display: 'flex', mt: 0.5 }}>
                  {chapterTags &&
                    chapter.tags.map((tag, j) => {
                      const chapterTagSelected = chapter.chapterId === selectedChapterTag?.chapterId && selectedChapterTag.tagId === tag
                      const chapterTagHighlight = chapter.chapterId !== selectedChapterTag?.chapterId && selectedChapterTag?.tagId === tag

                      return (
                        <Button
                          size="small"
                          className={classNames('tag')}
                          key={j}
                          color={chapterTagSelected || chapterTagHighlight ? 'primary' : 'secondary'}
                          variant={chapterTagHighlight ? 'outlined' : 'contained'}
                          onClick={() => {
                            onChapterTagClick &&
                              onChapterTagClick({ chapterId: chapterTagSelected ? 0 : chapter.chapterId, tagId: chapterTagSelected ? '' : tag })
                          }}
                        >
                          {chapterTags[tag] ?? '?'}
                        </Button>
                      )
                    })}
                </Box>
              </CardContent>
            )
          })
        )}
      </Box>
    </Box>
  )
}

export default GameChapterList
