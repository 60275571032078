import { FC, ReactNode, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'

import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material'

import { useUnsubscribeDailyDigestMutation } from '../../api/core'
import logo from '../../assets/images/gamerefinery-logo.png'
import { isFetchBaseQueryError } from '../../helpers/isFetchBaseQueryError'
import { useSearchParams } from '../../hooks/useSearchParams'

/**
 * Page component for users unsubscribing daily digest from emails
 */
type UnsubscribeDailyDigestPageProps = {
  children?: ReactNode
}

enum UnsubscribeStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
  ALREADY_UNSUBSCRIBED = 'alreadyunsubscribed',
}

export const UnsubscribeDailyDigestPage: FC<UnsubscribeDailyDigestPageProps> = () => {
  const { parsedParams } = useSearchParams<{ token?: string }, { token?: string }>()
  const { token } = parsedParams
  const [unsubscribe, unsubscribeResult] = useUnsubscribeDailyDigestMutation()
  const [unsubscribeStatus, setUnsubscribeStatus] = useState<UnsubscribeStatus | undefined>()

  useEffect(() => {
    if (token) {
      setTimeout(() => unsubscribe(token), 1000)
    }
  }, [token, unsubscribe])

  useEffect(() => {
    const { isSuccess, isError, error } = unsubscribeResult
    if (isError && error) {
      if (isFetchBaseQueryError(error) && error.data === 1002) {
        setUnsubscribeStatus(UnsubscribeStatus.ALREADY_UNSUBSCRIBED)
      } else {
        setUnsubscribeStatus(UnsubscribeStatus.FAIL)
      }
    } else if (isSuccess) {
      setUnsubscribeStatus(UnsubscribeStatus.SUCCESS)
    }
  }, [unsubscribeResult])

  const showLoader = unsubscribeResult.isLoading || unsubscribeResult.isUninitialized || !unsubscribeStatus
  const messageKey = `common:unsubscribe_daily_digest_status_message_${unsubscribeStatus}`

  return (
    <Box
      className="UnsubscribeDailyDigestPage"
      sx={{ height: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}
    >
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <img src={logo} alt="Company logo" style={{ maxWidth: 250 }} />
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item>
          {showLoader ? (
            <CircularProgress />
          ) : (
            <Typography variant="h3">
              <Trans i18nKey={messageKey} />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
