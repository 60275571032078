import { useSelector } from 'react-redux'

import { BaseGame } from '../../../types/BaseGame'
import { selectFeaturedGames, selectOpenGames } from '../selectors/selectors'
import { useGamesOpenForEveryone } from './useGamesOpenForEveryone'

/**
 * Hook for checking if given game is open for everyone
 */
export const useIsGameOpenForEveryone = (game?: BaseGame) => {
  const { openGamesSetting, featuredGamesSetting } = useGamesOpenForEveryone()

  if (!(game instanceof BaseGame)) {
    return false
  }

  return game ? game.isOpenForEveryone(openGamesSetting, featuredGamesSetting) : false
}

export const useIsGameOpenForEveryoneSelector = (game?: BaseGame) => {
  const openGamesSetting = useSelector(selectOpenGames)
  const featuredGamesSetting = useSelector(selectFeaturedGames)

  if (!(game instanceof BaseGame)) {
    return false
  }

  return game.isOpenForEveryone(openGamesSetting, featuredGamesSetting)
}
