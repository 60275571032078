import { useMemo } from 'react'

import { useGetUserSettingQuery, useUpdateUserSettingMutation } from '../../../api/combined'
import { useError } from '../../../hooks/useError'
import { useMarketExplorerAccessCheck } from '../../account/hooks/roleHooks'
import { UserSettingKeys } from '../../account/types/UserSettings'
import { useCurrentMarket } from '../../markets'
import { useGamesOpenForEveryone } from '../../settings'
import { MarketExplorerSegmentConfiguration } from '../types/MarketExplorerSegmentConfiguration'

/**
 * Hooks for loading and updating market explorer default segment
 */
export const useDefaultSegment = () => {
  const userDefaultSegmentQuery = useGetUserSettingQuery(UserSettingKeys.marketExplorerDefaultSegment)
  const gamesOpenForEveryone = useGamesOpenForEveryone()
  const hasMarketExplorerAccess = useMarketExplorerAccessCheck()
  const { currentMarketIso } = useCurrentMarket()
  const isLoading = userDefaultSegmentQuery.isLoading || userDefaultSegmentQuery.isFetching || gamesOpenForEveryone.isLoading || gamesOpenForEveryone.isFetching
  const isSuccess = userDefaultSegmentQuery.isSuccess && gamesOpenForEveryone.isSuccess

  // format of a default segment when creating new segments
  const defaultUserSegment: MarketExplorerSegmentConfiguration | undefined = useMemo(() => {
    if (!isLoading && isSuccess) {
      // if default segment from user settings contains data use it, otherwise use a predefined segment
      if (!hasMarketExplorerAccess) {
        return {
          visible: true,
          marketIso: currentMarketIso,
          filters: {
            ranks: {},
            demographics: {},
            motivations: {},
            archetypes: {},
          },
          // allow only open games when user has no market explorer role
          gameIds: [
            ...(gamesOpenForEveryone.openGamesSetting?.data?.openGames || []),
            ...(gamesOpenForEveryone.featuredGamesSetting?.data?.featuredGames || []),
          ],
        }
      } else if (userDefaultSegmentQuery.data) {
        return userDefaultSegmentQuery.data as MarketExplorerSegmentConfiguration
      }
    }
  }, [
    currentMarketIso,
    gamesOpenForEveryone.featuredGamesSetting?.data?.featuredGames,
    gamesOpenForEveryone.openGamesSetting?.data?.openGames,
    hasMarketExplorerAccess,
    isLoading,
    isSuccess,
    userDefaultSegmentQuery.data,
  ])

  useError({ error: userDefaultSegmentQuery.error })

  return { data: defaultUserSegment, isLoading, isSuccess }
}

export const useUpdateDefaultSegment = () => {
  const [updateUserSetting, query] = useUpdateUserSettingMutation()

  return {
    updateDefaultSegment: (defaultSegment?: MarketExplorerSegmentConfiguration) =>
      updateUserSetting({ settingKey: UserSettingKeys.marketExplorerDefaultSegment, value: defaultSegment }),
    ...query,
  }
}
