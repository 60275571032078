import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, ButtonGroup, Checkbox, FormControlLabel, Grid, List, ListItem, Typography } from '@mui/material'

import { Keyword, Tag } from '../../../../../api/core'
import { MenuButton } from '../../../../../components/MenuButton/MenuButton'
import languageService from '../../../../../services/LanguageService'
import { CategoryImage } from '../../../../game-features/components/CategoryImage/CategoryImage'
import { EnrichedFeature, ExtendedCategory } from '../../../../implementation-examples'
import { featureSearchResultFilter } from '../../../../implementation-examples/util/filters'
import { FeatureAndKeywordSearchResult } from '../../../types/types'
import { FeatureLink } from '../../FeatureLink'
import type { FeatureChoices } from '../FeatureSelectDialog'
import { FeatureSelectionMode } from '../FeatureSelectionMode'
import './FeatureList.scss'

/**
 * Component for displaying a list of features with option to select feature or a separate choice based on
 * selection mode.
 */
type FeatureListProps = {
  searchResult: FeatureAndKeywordSearchResult
  selectedTag: Tag | null
  selectionMode: FeatureSelectionMode
  selection: FeatureChoices
  onSelectionChange: (selection: FeatureChoices) => void
  features?: ExtendedCategory[]
  showScreenshotCounts?: boolean
  optionalFiltered?: (feature: EnrichedFeature) => void
  keywords?: Keyword[]
}

export const FeatureList: FC<FeatureListProps> = ({
  features,
  searchResult,
  selectedTag,
  selectionMode,
  selection,
  onSelectionChange,
  showScreenshotCounts,
  optionalFiltered,
  keywords,
}) => {
  const { t } = useTranslation()
  const searchFilter = featureSearchResultFilter(true, selectedTag ? [selectedTag] : undefined, searchResult, keywords)

  const handleChangeSelection = useCallback(
    (featureLegacyId: number, choiceLegacyIds: any) => {
      if (!choiceLegacyIds || choiceLegacyIds.length === 0) {
        delete selection[featureLegacyId]
      } else {
        selection[featureLegacyId] = [...choiceLegacyIds]
      }

      onSelectionChange({ ...selection })
    },
    [onSelectionChange, selection]
  )

  const flatFeatures = useMemo(() => {
    if (!features) return []

    const filteredFeatures = features?.flatMap((category) => category.features).filter(searchFilter)

    if (optionalFiltered) return filteredFeatures.filter(optionalFiltered)
    return filteredFeatures.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  }, [features, optionalFiltered, searchFilter])

  // if neither a tag or a serach result has been selected, show info text only
  if (!selectedTag && !searchResult) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <Typography>{t('common:feature_modal_help_text')}</Typography>
      </Box>
    )
  }

  if (!flatFeatures.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <Typography>{t('common:no_features_found')}</Typography>
      </Box>
    )
  }

  return (
    <Box className="FeatureList" mt={2} flexDirection="column">
      {flatFeatures.map((feature) => {
        const category = features?.find((category) => category.id === feature.categoryId)
        return (
          <Grid className="FeatureList__feature" key={feature.id} container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Grid container alignItems="center">
                {category && <CategoryImage src={category.icon.url} alt={category.name} />}
                <FeatureLink feature={{ featureName: feature.name, featureLegacyId: feature.legacyId }}>
                  <span>{feature.name}</span>
                </FeatureLink>
              </Grid>
            </Grid>
            {showScreenshotCounts && <Grid item>{t('implementations:label_examples_count_plural', { count: feature.screenshotCount })}</Grid>}
            <Grid item xs={3}>
              <FeatureChoiceSelect
                selectedChoices={selection[feature.legacyId] || []}
                feature={feature}
                onChange={(choices) => handleChangeSelection(feature.legacyId, choices)}
                selectionMode={selectionMode}
              />
            </Grid>
          </Grid>
        )
      })}
    </Box>
  )
}

type FeatureChoiceSelectProps = {
  selectedChoices: number[]
  feature: EnrichedFeature
  onChange: (choices: number[]) => void
  selectionMode: FeatureSelectionMode
}

const buttonColor = 'secondary'

const FeatureChoiceSelect: FC<FeatureChoiceSelectProps> = ({ selectedChoices, feature, onChange, selectionMode }) => {
  const { t } = useTranslation()

  const activeChoices = feature.choices.filter((choice) => choice.active)

  const handleChoicesChange = useCallback(
    (value: string | number[]) => {
      const newChoices = typeof value === 'string' ? value.split(',').map((val) => +val) : value
      onChange(newChoices)
    },
    [onChange]
  )

  const handleSelectChoice = useCallback(
    (choiceLegacyId: number) => {
      if (selectedChoices.includes(choiceLegacyId)) {
        handleChoicesChange(selectedChoices.filter((selectedChoice) => selectedChoice !== choiceLegacyId))
      } else {
        handleChoicesChange([...selectedChoices, choiceLegacyId])
      }
    },
    [handleChoicesChange, selectedChoices]
  )

  const renderValue = useMemo(() => {
    if (selectedChoices.length === 0) {
      return t('common:select_choice')
    } else if (selectedChoices.length === 1) {
      const oneChoice = activeChoices.find((choice) => choice.weightId === selectedChoices[0])
      return (
        (oneChoice && languageService.getTranslation('choices', oneChoice.weightId.toString())) ||
        t('common:items_selected_count', { count: selectedChoices.length })
      )
    }
    return t('common:items_selected_count', { count: selectedChoices.length })
  }, [activeChoices, selectedChoices, t])

  const allowChoiceSelection = useMemo(() => {
    if (selectionMode === FeatureSelectionMode.Features && ![154, 155].includes(feature.legacyId)) {
      return false
    }
    return true
  }, [feature.legacyId, selectionMode])

  const isFeatureSelected = selectedChoices.length > 0

  return allowChoiceSelection ? (
    activeChoices.length === 2 ? (
      <ButtonGroup fullWidth>
        {activeChoices.map((choice) => (
          <Button
            key={choice.weightId}
            color={buttonColor}
            onClick={() => (selectedChoices.includes(choice.weightId) ? handleChoicesChange([]) : handleChoicesChange([choice.weightId]))}
            variant={selectedChoices.includes(choice.weightId) ? 'contained' : 'outlined'}
          >
            {languageService.getTranslation('choices', choice.weightId.toString())}
          </Button>
        ))}
      </ButtonGroup>
    ) : (
      <MenuButton
        label={renderValue}
        buttonProps={{
          color: buttonColor,
          variant: selectedChoices.length > 0 ? 'contained' : 'outlined',
          fullWidth: true,
          sx: { whiteSpace: 'nowrap', minWidth: 'auto' },
        }}
      >
        <List>
          {activeChoices.map((choice) => (
            <ListItem key={choice.weightId}>
              <FormControlLabel
                control={<Checkbox size="small" checked={selectedChoices.includes(choice.weightId)} onChange={() => handleSelectChoice(choice.weightId)} />}
                label={languageService.getTranslation('choices', choice.weightId.toString())}
              />
            </ListItem>
          ))}
        </List>
      </MenuButton>
    )
  ) : (
    <Button
      variant={isFeatureSelected ? 'contained' : 'outlined'}
      color={buttonColor}
      fullWidth
      onClick={() => {
        isFeatureSelected ? handleChoicesChange([]) : handleChoicesChange(activeChoices.map((choice) => choice.weightId))
      }}
    >
      {t(isFeatureSelected ? 'common:selected' : 'common:select')}
    </Button>
  )
}
