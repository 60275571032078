import React from 'react'
import { useTranslation } from 'react-i18next'

import { Add } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import './BenchmarkGameSelector.scss'

/**
 * BenchmarkGameSelector Icon to select game to benchmark in analyzer detail page
 */
interface BenchmarkGameSelectorProps {
  onClick: () => void
}
const BenchmarkGameSelector: React.FC<BenchmarkGameSelectorProps> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <Box onClick={onClick} className="BenchmarkGameSelector">
      <div className="BenchmarkGameSelector__compareIcon">
        <Add fontSize="large" />
      </div>
      <Typography sx={{ mt: 1 }} className="BenchmarkGameSelector__benchmarkText">
        {t('analysis:benchmark_game')}
      </Typography>
    </Box>
  )
}

export default BenchmarkGameSelector
