import { t } from 'i18next'
import { useMemo } from 'react'

import { useGetExplorerDataWithFeaturesQuery, useGetGameInnovatorListQuery, useGetSoftLaunchGamesQuery } from '../../../api/core'
import utilsService from '../../../services/UtilsService'
import { SubgenreMap } from '../../account'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import { Game } from '../../game'
import { useGetGameList } from '../../game/hooks/gameHooks'
import { useCurrentMarket } from '../../markets'
import quarterService, { QuarterDataKey } from '../../quarter/services/QuarterService'
import { useFilteredQuarters } from '../../top-game/hooks/topGrossingGameHooks'
import { TopGame } from '../../top-game/types/TopGame'

const getIconURLForName = (name: string) => {
  const SAAS_URL = `${window.location.protocol}//${window.location.host}`
  return `${SAAS_URL}/assets/images/icon-${name}.svg`
}

enum MustKnowGameTypeKey {
  topGames,
  genreInnovators,
  trendingByRevenue,
  trendingByDownloads,
  basedOnBrandIp,
  fromMajorPublishers,
  softLaunchGames,
}

type MustKnowGameType = {
  [gameType in MustKnowGameTypeKey]: {
    id: string
    title: string
    tooltip?: string
    icon: string
    games: Game[]
    isLoading?: boolean
  }
}

export const selectAllSubgenreOptionKey = 'all-subgenre'

const useGetGenreInnovatorGames = (conventionalGenreId: string, selectedSubgenreOption: string, skip?: boolean) => {
  const userLanguage = useCurrentUserLanguage()
  const { data: gameInnovators = [], isLoading: isGameInnovatorLoading } = useGetGameInnovatorListQuery(
    {
      userLanguage,
      conventionalGenreId,
      fields: [
        'appId',
        'name',
        'artist',
        'conventionalSubgenreId',
        'conventionalSubgenre',
        'conventionalGenreId',
        'icon',
        'icons',
        'reviewId',
        'reviewPublished',
        'genreInnovator',
        'reviewId',
      ],
    },
    { skip }
  )
  const sortedGameInnvovators = useMemo(
    () =>
      utilsService.orderBy(
        [...gameInnovators].filter((game) => {
          if (selectedSubgenreOption === selectAllSubgenreOptionKey) {
            return true
          }
          return game.conventionalSubgenreId === selectedSubgenreOption
        }),
        ['genreInnovator', 'name'],
        ['desc', 'asc']
      ),

    [gameInnovators, selectedSubgenreOption]
  )
  return { genreInnovatorGames: sortedGameInnvovators, isLoading: isGameInnovatorLoading }
}

const useGetTopGamesData = (gameIds: string[], topGames: TopGame[], skip?: boolean) => {
  const { filteredQuarters, isLoading: isFetchingQuarters } = useFilteredQuarters()
  const lastestQuarter = useMemo(() => filteredQuarters && filteredQuarters[filteredQuarters.length - 1], [filteredQuarters])

  const include = ['name', 'artist', 'icons', 'conventionalSubgenre', 'conventionalSubgenreId', 'sdranks', 'sranks', 'srank', 'appId', 'reviewId'].join(',')

  const { data: topGamesData = [], isFetching: isFetchingGames } = useGetGameList(
    {
      gameIds: gameIds.filter((gameId) => !!gameId),
      include,
    },
    { skip }
  )

  const sortedTopGames = useMemo(() => {
    if (!lastestQuarter) {
      return []
    }

    const sortedGamesIds = [...topGames]
      .sort(
        (a, b) =>
          b.marketLevelQuarters[lastestQuarter.quarterIdentifier].revenueTop500Value -
          a.marketLevelQuarters[lastestQuarter.quarterIdentifier].revenueTop500Value
      )
      .map((topGame) => topGame.gameId)

    return [...topGamesData].sort((a, b) => sortedGamesIds.indexOf(a.id) - sortedGamesIds.indexOf(b.id))
  }, [lastestQuarter, topGames, topGamesData])

  return { topGamesData: sortedTopGames, isLoading: isFetchingGames || isFetchingQuarters }
}

const useGetExplorerDataWithFeatures = (subgenres: SubgenreMap, choiceLegacyIds: number[], selectedSubgenreOption: string, skip?: boolean) => {
  const { currentMarketIso } = useCurrentMarket()
  const userLanguage = useCurrentUserLanguage()
  const { data: explorerDataWithFeatures, isFetching } = useGetExplorerDataWithFeaturesQuery(
    {
      marketIso: currentMarketIso,
      conventionalSubgenreIds: subgenres,
      choiceLegacyIds,
      userLanguage,
    },
    { skip }
  )
  const sortedGamesBasedOnBrandIp = useMemo(() => {
    return explorerDataWithFeatures
      ? [...explorerDataWithFeatures.games]
          .filter((game) => {
            if (selectedSubgenreOption === selectAllSubgenreOptionKey) {
              return true
            }
            return game.conventionalSubgenreId === selectedSubgenreOption
          })
          .sort((a, b) => b.gpsPerMarket[currentMarketIso] - a.gpsPerMarket[currentMarketIso])
      : []
  }, [currentMarketIso, explorerDataWithFeatures, selectedSubgenreOption])
  return { data: sortedGamesBasedOnBrandIp, isLoading: isFetching }
}

const useGetGameOrderByField = (topGames: TopGame[], games: Game[], field: 'revenue' | 'downloads') => {
  const { filteredQuarters, isLoading: isFetchingQuarters } = useFilteredQuarters()
  const lastestQuarter = useMemo(() => filteredQuarters && filteredQuarters[filteredQuarters.length - 1], [filteredQuarters])
  const secondLastQuarter = useMemo(() => filteredQuarters && filteredQuarters[filteredQuarters.length - 2], [filteredQuarters])

  const _sortedGames = useMemo(() => {
    if (!lastestQuarter || !secondLastQuarter) {
      return games
    }

    const sortedTopGamesIds = [...topGames]
      .sort((a, b) => {
        switch (field) {
          case 'revenue':
            return (
              quarterService.getChangeDataForComparisonQuarterValue(
                QuarterDataKey.RevenuePercentage,
                lastestQuarter.quarterIdentifier,
                secondLastQuarter.quarterIdentifier,
                b.marketLevelQuarters
              ) -
              quarterService.getChangeDataForComparisonQuarterValue(
                QuarterDataKey.RevenuePercentage,
                lastestQuarter.quarterIdentifier,
                secondLastQuarter.quarterIdentifier,
                a.marketLevelQuarters
              )
            )

          default:
            return (
              quarterService.getChangeDataForComparisonQuarterValue(
                QuarterDataKey.DownloadsPercentage,
                lastestQuarter.quarterIdentifier,
                secondLastQuarter.quarterIdentifier,
                b.marketLevelQuarters
              ) -
              quarterService.getChangeDataForComparisonQuarterValue(
                QuarterDataKey.DownloadsPercentage,
                lastestQuarter.quarterIdentifier,
                secondLastQuarter.quarterIdentifier,
                a.marketLevelQuarters
              )
            )
        }
      })
      .map((topGame) => topGame.gameId)

    return [...games].sort((a, b) => sortedTopGamesIds.indexOf(a.id) - sortedTopGamesIds.indexOf(b.id))
  }, [field, games, lastestQuarter, secondLastQuarter, topGames])

  return { data: _sortedGames, isLoading: isFetchingQuarters }
}

const useGetSoftlaunchGames = (subgenres: SubgenreMap, skip?: boolean) => {
  const { currentMarketIso } = useCurrentMarket()
  const userLanguage = useCurrentUserLanguage()
  const includes = [
    'appId',
    'name',
    'artist',
    'conventionalSubgenre',
    'conventionalSubgenreId',
    'conventionalGenreId',
    'icon',
    'icons',
    'reviewId',
    'reviewPublished',
  ]
  return useGetSoftLaunchGamesQuery({ marketIso: currentMarketIso, conventionalSubgenreIds: Object.keys(subgenres), includes, userLanguage }, { skip })
}

export const useMustKnowGamesHooks = (
  topGames: TopGame[],
  selectedSubgenres: SubgenreMap,
  selectedSubgenreOption: string,
  conventionalGenreId: string
): { data: MustKnowGameType; isLoading: boolean } => {
  const skipQuery = !Object.keys(selectedSubgenres).length

  const filteredTopGames = useMemo(
    () =>
      topGames.filter((game) => {
        if (selectedSubgenreOption === selectAllSubgenreOptionKey) {
          return game.conventionalGenreId === conventionalGenreId
        }
        return game.conventionalSubgenreId === selectedSubgenreOption
      }),
    [conventionalGenreId, selectedSubgenreOption, topGames]
  )

  const filteredGameIds = useMemo(() => {
    return filteredTopGames.map((game) => game.gameId)
  }, [filteredTopGames])

  const { genreInnovatorGames, isLoading: isLoadingGameInnovators } = useGetGenreInnovatorGames(conventionalGenreId, selectedSubgenreOption, skipQuery)
  const { topGamesData, isLoading: isLoadingTopGamesData } = useGetTopGamesData(filteredGameIds, filteredTopGames, skipQuery)

  const brandIPChoices = [1039, 1040, 1041, 1029]
  const { data: gamesBasedOnBrandIp, isLoading: isLoadingGamesBasedOnBrandIp } = useGetExplorerDataWithFeatures(
    selectedSubgenres,
    brandIPChoices,
    selectedSubgenreOption,
    skipQuery
  )

  const publisherChoices = [670, 671, 672]
  const { data: gamesByPublishers, isLoading: isLoadingGamesByPublishers } = useGetExplorerDataWithFeatures(
    selectedSubgenres,
    publisherChoices,
    selectedSubgenreOption,
    skipQuery
  )

  const { data: gamesOrderedByRevenue = [], isLoading: isLoadingGamesOrderedByRevenue } = useGetGameOrderByField(filteredTopGames, topGamesData, 'revenue')
  const { data: gamesOrderedByDownload = [], isLoading: isLoadingGamesOrderedByDownload } = useGetGameOrderByField(filteredTopGames, topGamesData, 'downloads')
  const { data: gameSoftlaunch = [], isFetching: isLoadingGameSoftlaunch } = useGetSoftlaunchGames(selectedSubgenres, skipQuery)
  const sortedGameSoftLaunch = useMemo(() => [...gameSoftlaunch].sort((a, b) => a.resolvedName.localeCompare(b.resolvedName)), [gameSoftlaunch])

  const isLoading =
    isLoadingTopGamesData ||
    isLoadingGameInnovators ||
    isLoadingGameInnovators ||
    isLoadingGamesBasedOnBrandIp ||
    isLoadingGamesByPublishers ||
    isLoadingGamesOrderedByRevenue ||
    isLoadingGamesOrderedByDownload ||
    isLoadingGameSoftlaunch

  const mustKnowGameTypes: MustKnowGameType = useMemo(() => {
    return {
      [MustKnowGameTypeKey.topGames]: {
        id: 'topGames',
        title: t('genre-essentials:must_to_know_games_top_games_title'),
        tooltip: t('genre-essentials:must_to_know_games_top_games_description') as string,
        icon: getIconURLForName('trophy'),
        games: [],
      },
      [MustKnowGameTypeKey.genreInnovators]: {
        id: 'genreInnovators',
        title: t('genre-essentials:must_to_know_games_genre_innovators_title'),
        tooltip: t('genre-essentials:must_to_know_games_genre_innovators_description') as string,
        icon: getIconURLForName('diploma'),
        games: [],
      },
      [MustKnowGameTypeKey.trendingByRevenue]: {
        id: 'trendingByRevenue',
        title: t('genre-essentials:must_to_know_games_trending_by_revenue_title'),
        tooltip: t('genre-essentials:must_to_know_games_trending_by_revenue_description') as string,
        icon: getIconURLForName('money-burn'),
        games: [],
      },
      [MustKnowGameTypeKey.trendingByDownloads]: {
        id: 'trendingByDownloads',
        title: t('genre-essentials:must_to_know_games_trending_by_downloads_title'),
        tooltip: t('genre-essentials:must_to_know_games_trending_by_downloads_description') as string,
        icon: getIconURLForName('trending'),
        games: [],
      },
      [MustKnowGameTypeKey.basedOnBrandIp]: {
        id: 'basedOnBrandIp',
        title: t('genre-essentials:must_to_know_games_based_on_brand_ip_title'),
        icon: getIconURLForName('diamond-on-side'),
        games: [],
      },
      [MustKnowGameTypeKey.fromMajorPublishers]: {
        id: 'fromMajorPublishers',
        title: t('genre-essentials:must_to_know_games_from_major_publishers_title'),
        icon: getIconURLForName('crown'),
        games: [],
      },
      [MustKnowGameTypeKey.softLaunchGames]: {
        id: 'softLaunchGames',
        title: t('genre-essentials:must_to_know_games_soft_launch_title'),
        icon: getIconURLForName('rocket-up'),
        games: [],
      },
    }
  }, [])

  const data: MustKnowGameType = useMemo(
    () => ({
      ...mustKnowGameTypes,
      [MustKnowGameTypeKey.topGames]: { ...mustKnowGameTypes[MustKnowGameTypeKey.topGames], games: topGamesData, isLoading: isLoadingTopGamesData },
      [MustKnowGameTypeKey.genreInnovators]: {
        ...mustKnowGameTypes[MustKnowGameTypeKey.genreInnovators],
        games: genreInnovatorGames,
        isLoading: isLoadingGameInnovators,
      },
      [MustKnowGameTypeKey.basedOnBrandIp]: {
        ...mustKnowGameTypes[MustKnowGameTypeKey.basedOnBrandIp],
        games: gamesBasedOnBrandIp,
        isLoading: isLoadingGamesBasedOnBrandIp,
      },
      [MustKnowGameTypeKey.fromMajorPublishers]: {
        ...mustKnowGameTypes[MustKnowGameTypeKey.fromMajorPublishers],
        games: gamesByPublishers,
        isLoading: isLoadingGamesByPublishers,
      },
      [MustKnowGameTypeKey.trendingByRevenue]: {
        ...mustKnowGameTypes[MustKnowGameTypeKey.trendingByRevenue],
        games: gamesOrderedByRevenue,
        isLoading: isLoadingGamesOrderedByRevenue,
      },
      [MustKnowGameTypeKey.trendingByDownloads]: {
        ...mustKnowGameTypes[MustKnowGameTypeKey.trendingByDownloads],
        games: gamesOrderedByDownload,
        isLoading: isLoadingGamesOrderedByDownload,
      },
      [MustKnowGameTypeKey.softLaunchGames]: {
        ...mustKnowGameTypes[MustKnowGameTypeKey.softLaunchGames],
        games: sortedGameSoftLaunch,
        isLoading: isLoadingGameSoftlaunch,
      },
    }),
    [
      sortedGameSoftLaunch,
      gamesBasedOnBrandIp,
      gamesByPublishers,
      gamesOrderedByDownload,
      gamesOrderedByRevenue,
      genreInnovatorGames,
      isLoadingGameInnovators,
      isLoadingGameSoftlaunch,
      isLoadingGamesBasedOnBrandIp,
      isLoadingGamesByPublishers,
      isLoadingGamesOrderedByDownload,
      isLoadingGamesOrderedByRevenue,
      isLoadingTopGamesData,
      mustKnowGameTypes,
      topGamesData,
    ]
  )

  return {
    data,
    isLoading,
  }
}
