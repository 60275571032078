/**
 * @returns intersection of the two given arrays
 */
export const intersection = <T>(array1: T[], array2: T[]) => array1.filter((value) => array2.includes(value))

/**
 * @returns intersection of the two given arrays, compared with given resolver function value
 */
export const intersectionBy = <T>(array1: T[], array2: T[], resolver: (item: T) => any) => {
  const mappedArray = array2.map((item) => resolver(item))
  return array1.filter((item: T) => mappedArray.includes(resolver(item)))
}
