import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import { ChangeType } from '../../types/ChangeType'
import { TableRowUpdateImpact } from '../../types/TableRowUpdateImpact'
import { ChangeTypeIndicator } from '../ChangeTypeIndicator/ChangeTypeIndicator'

/**
 * Container component for all indicators related to an update (change types and comments)
 */
type ContentIndicatorsProps = {
  impact: TableRowUpdateImpact
}

export const ContentIndicators: FC<ContentIndicatorsProps> = ({ impact }) => {
  const { t } = useTranslation()
  return (
    <Grid container alignItems="center" spacing={0.5}>
      <Grid container direction="column" spacing={0.1}>
        {impact.changeTypes.map((changeType: ChangeType) => {
          return (
            <Grid item key={changeType}>
              {changeType === ChangeType.FeatureChanges && (
                <ChangeTypeIndicator type={ChangeType.FeatureChanges} tooltip={t('newsfeed:changes_in_feature_set_topic')} />
              )}
              {changeType === ChangeType.SystemUpdate && (
                <ChangeTypeIndicator type={ChangeType.SystemUpdate} tooltip={t('news-titles:updated_analysis_system_subtitle')} />
              )}
              {changeType === ChangeType.ScreenshotsAvailable && (
                <ChangeTypeIndicator type={ChangeType.ScreenshotsAvailable} tooltip={t('common:screenshots_available_for_version')} />
              )}
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}
