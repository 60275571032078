import { createSelector } from '@reduxjs/toolkit'

import { coreApi } from '../../../api/core'
import type { RootState } from '../../../store'

const storesResult = coreApi.endpoints.getStores.select()

export const selectAllStores = createSelector(storesResult, (stores) => stores?.data ?? [])

export const selectStoreById = createSelector(
  selectAllStores,
  (state: RootState, id: string | null) => id,
  (stores, id) => stores.find((store) => store.id === id)
)
