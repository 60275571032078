import { FC, useCallback, useState } from 'react'

import { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import { useCurrentMarket } from '../../../markets'
import { useTopGrossingGameRanks } from '../../../top-game/hooks/topGrossingGameHooks'
import { PerformanceChart } from '../../../update-history/components/PerformanceChart/PerformanceChart'
import { PerformanceChartDataType } from '../../../update-history/types/types'

type RankDevelopmentChartContainerProps = {
  appId: number
}

/**
 * Container component for game rank development chart
 */
export const RankDevelopmentChartContainer: FC<RankDevelopmentChartContainerProps> = ({ appId }) => {
  const { currentMarketIso } = useCurrentMarket()

  const initialFromDate = new Date(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0))
  const [dateRange, setDateRange] = useState<DateRangeValue | undefined>({ fromDate: initialFromDate, toDate: new Date() })

  const { topGrossingRanks, freeRanks, isLoading } = useTopGrossingGameRanks({
    marketIso: currentMarketIso,
    appId,
  })

  const handleDateRangeChange = useCallback(
    (value?: DateRangeValue) => {
      setDateRange(value)
    },
    [setDateRange]
  )

  return (
    <PerformanceChart
      topGrossingRanks={topGrossingRanks}
      freeRanks={freeRanks}
      isLoading={isLoading}
      dataType={PerformanceChartDataType.Ranks}
      dateRange={dateRange}
      onDateRangeChanged={handleDateRangeChange}
      config={{ showDataTypeSelector: false, showExportButton: false }}
    />
  )
}
