import { FC, useRef, useMemo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Delete } from '@mui/icons-material'
import { TableContainer, Box, Grid } from '@mui/material'

import { GRTableColumn, GRTable } from '../../../../../../components/GRTable/GRTable'
import { ActionButton } from '../../../../../components/ActionButton/ActionButton'
import { ErrorStatusCircle } from '../../../../../components/ErrorStatusCircle/ErrorStatusCircle'
import { checkDataTypeString } from '../../../helpers/checkDataTypeString'
import { EventScreenshot } from '../../../types/EventScreenshot'
import { EventScreenshotThumbnail } from '../EventScreenshotThumbnail/EventScreenshotThumbnail'

type EventScreenshotsTableProps = {
  screenshots: EventScreenshot[]
  onRemoveScreenshot: (screenshot: EventScreenshot) => void
  onOpenScreenshotDialog: (screenshot: EventScreenshot) => void
}

const byteValueNumberFormatter = Intl.NumberFormat('en', {
  notation: 'compact',
  style: 'unit',
  unit: 'byte',
  unitDisplay: 'narrow',
})

export const EventScreenshotsTable: FC<EventScreenshotsTableProps> = ({ screenshots, onRemoveScreenshot, onOpenScreenshotDialog }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)

  const screenshotColumns: GRTableColumn<EventScreenshot, typeof customProps>[] = useMemo(() => {
    return [
      {
        headerCellProps: { sx: { width: 200 } },
        labelAccessor: () => t('internal-live-events:screenshot_table_thumbnail_column_title'),
        accessor: ({ row, customTableProps }) => (
          <Grid
            onClick={checkDataTypeString(row.originalData) ? () => customTableProps?.onOpenScreenshotDialog(row) : undefined}
            sx={checkDataTypeString(row.originalData) ? { cursor: 'pointer' } : undefined}
          >
            <EventScreenshotThumbnail screenshot={row} />
          </Grid>
        ),
      },
      {
        headerCellProps: { sx: { minWidth: 240, textAlign: 'left' } },
        labelAccessor: () => <Box sx={{ paddingLeft: '12px' }}>{t('internal-live-events:screenshot_table_filename_column_title')}</Box>,
        cellProps: { sx: { textAlign: 'left' } },
        accessor: ({ row }) => row.name,
      },
      {
        headerCellProps: { sx: { width: 80 } },
        labelAccessor: () => t('internal-live-events:screenshot_table_size_column_title'),
        accessor: ({ row }) =>
          row.size ? (
            <Grid container direction="column" spacing={1}>
              <Grid item> {byteValueNumberFormatter.format(row.size)}</Grid>
              <Grid item>
                <ErrorStatusCircle errorText={t('internal-live-events:screenshot_has_not_been_uploaded_yet')} />
              </Grid>
            </Grid>
          ) : null,
      },
      {
        headerCellProps: { sx: { width: 80 } },
        labelAccessor: () => t('internal-live-events:actions'),
        accessor: ({ row, customTableProps }) => (
          <ActionButton color="warning" onClick={() => customTableProps?.onRemoveScreenshot(row)}>
            <Delete fontSize="small" />
          </ActionButton>
        ),
      },
    ]
  }, [t])

  const customProps = useMemo(
    () => ({
      onRemoveScreenshot,
      onOpenScreenshotDialog,
    }),
    [onOpenScreenshotDialog, onRemoveScreenshot]
  )

  const [columns, setColumns] = useState<GRTableColumn<EventScreenshot, typeof customProps>[]>(screenshotColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<EventScreenshot, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <TableContainer component={Box} ref={containerRef}>
      <GRTable
        columns={columns}
        isLoading={false}
        onColumnsUpdated={handleColumnsUpdate}
        customProps={customProps}
        rowIdKey={(screenshot) => screenshot.name}
        rows={screenshots}
        scroller={containerRef}
        noRowsLabel={t('internal-live-events:screenshot_table_no_rows')}
        striped
        gridlines
      />
    </TableContainer>
  )
}
