import { MotivationType } from './MotivationType'
import { PlayerArchetype } from './PlayerArchetype'

export enum GamesDataTableColumn {
  FeatureIndicators = 'FeatureIndicators',
  SustainedRanks = 'SustainedRanks',
  DaysSinceRelease = 'DaysSinceRelease',
  PerformanceMonthly = 'PerformanceMonthly',
  PerformanceHalfYear = 'PerformanceHalfYear',
  PerformanceAnnual = 'PerformanceAnnual',
  Gender = 'Gender',
  Age = 'Age',
}

export const GamesDataTableColumnValues = { ...GamesDataTableColumn, ...MotivationType, ...PlayerArchetype }

export type GamesDataTableColumnType = GamesDataTableColumn | MotivationType | PlayerArchetype
