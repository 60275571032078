import { FC, ReactNode } from 'react'

import { TableCell, TableRow } from '@mui/material'

/**
 * Component representing a status row in a table e.g. empty table, loading state
 */
export const StatusRow: FC<{ columnCount: number; children?: ReactNode }> = ({ columnCount, children }) => (
  <TableRow>
    <TableCell colSpan={columnCount} align="center">
      {children}
    </TableCell>
  </TableRow>
)
