import { domToReact, Element, HTMLReactParserOptions } from 'html-react-parser'
import { ReactNode, FC, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { EventDialogTab } from '../../features/live-events/hooks/useEventDialogTabs'
import { useRouteModal } from '../../features/route-modals/hooks/useRouteModal'
import { EModalType } from '../../features/route-modals/types/modalTypes'

export enum ModalMode {
  Push = 'push',
  Replace = 'replace',
}
/**
 * Component for rendering a link to live event modal
 */
export type LiveEventModalLinkProps = {
  trackedGameId: string
  eventTypeId: string
  eventId?: string
  screenshotIndex?: number
  className?: string
  modalMode?: ModalMode
  children?: ReactNode
}

export const LiveEventModalLink: FC<LiveEventModalLinkProps> = ({
  trackedGameId,
  eventTypeId,
  eventId,
  screenshotIndex,
  className,
  modalMode = ModalMode.Push,
  children,
}) => {
  const { generatePushModalParams, generateReplaceModalParams } = useRouteModal()
  const operation = modalMode === ModalMode.Push ? generatePushModalParams : generateReplaceModalParams
  const [newUrlSearchParams, setNewUrlSearchParams] = useState<URLSearchParams>()

  useEffect(() => {
    const newParams = operation({
      type: EModalType.LIVE_EVENT,
      params: {
        trackedGameId,
        liveEventTypeId: eventTypeId,
        liveEventId: eventId,
        tab: EventDialogTab.Description,
        screenshotIndex: screenshotIndex?.toString(),
      },
    })

    setNewUrlSearchParams(newParams)
  }, [eventId, eventTypeId, operation, screenshotIndex, trackedGameId])

  return (
    <Link to={{ search: `?${newUrlSearchParams}` }} className={className}>
      {children}
    </Link>
  )
}

// parser options to transform links to point to live event modal
export const liveEventReviewModalParserOptions = (trackedGameId: string, options?: { liveEventModalMode: ModalMode }): HTMLReactParserOptions => ({
  replace: (node: any) => {
    const domNode = node as Element
    if (domNode.type === 'tag' && domNode.name === 'a' && domNode.attribs && domNode.attribs.href && domNode.attribs.href.indexOf('/?liveEventId') >= 0) {
      const params = new URLSearchParams(domNode.attribs.href.split('?')?.[1])
      return (
        <LiveEventModalLink
          trackedGameId={trackedGameId}
          eventTypeId={params.get('liveEventType') as string}
          eventId={params.get('liveEventId') as string}
          modalMode={options?.liveEventModalMode}
        >
          {domToReact(domNode.children)}
        </LiveEventModalLink>
      )
    } else {
      return false
    }
  },
})
