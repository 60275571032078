export enum PerformanceChartV2DataType {
  Revenue,
  Downloads,
  RevenueDownloadsRatio7Days,
  RevenueDownloadsRatio30Days,
  RevenueDownloadsRatio90Days,
  RevenueDownloadsRatioAllTime,
  DAU,
  MAU,
  Ranks,
}
