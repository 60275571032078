import { Trans } from 'react-i18next'

import { Box, Button, Grid } from '@mui/material'

import keycloakService from '../../../services/KeycloakService'
import './GoogleSignupButton.scss'

export const GoogleSignupButton = () => {
  const googleSignupUrl = keycloakService.googleSignupUrl

  return (
    <Button className="GoogleSignupButton" variant="contained" color="info" fullWidth href={googleSignupUrl}>
      <Grid container alignItems="center" sx={{ lineHeight: 0 }}>
        <Grid item flexGrow={0}>
          <Box className="GoogleSignupButton__logo" />
        </Grid>
        <Grid item flexGrow={1} sx={{ textAlign: 'center' }}>
          <Trans i18nKey="common:sign_up_with_google_button" />
        </Grid>
      </Grid>
    </Button>
  )
}
