import { differenceInDays } from 'date-fns'
import { FC, useRef, useState, useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { Delete } from '@mui/icons-material'
import { TableContainer, Box } from '@mui/material'

import ConfirmDialog from '../../../../../../components/ConfirmDialog/ConfirmDialog'
import { GRTableColumn, SortOrder, GRTable } from '../../../../../../components/GRTable/GRTable'
import { ConfirmDialogData } from '../../../../../../types/ConfirmDialogData'
import { ActionButton } from '../../../../../components/ActionButton/ActionButton'
import { DatePicker } from '../../../../../components/DatePicker/DatePicker'
import { isValidEventDuration } from '../../../helpers/eventDuration'
import { EventEditForm, EventEditFormDuration, EventEditFormDurations } from '../../../types/EventEditForm'
import { InvalidDurationIndicator } from '../InvalidDurationIndicator/InvalidDurationIndicator'

type RemoveDurationConfirmDialogData = {
  duration: EventEditFormDuration
  index: number
}

type EventDurationsTableProps = {
  viewAll: boolean
  durations: EventEditFormDurations
  onRemoveDuration: (durationId: string) => void
}

/**
 * Table component for displaying and editing event durations
 *
 * Few notes on using the react hook form with the table:
 * - Using the useFieldArray hook with GRTable does not work as expected. The fields are accessed with indexing numbers meaning which ever way we sort the table data it will remain in its original order. One solution is to transform the
 *   array into and object with ids as keys and use that object as a separate form.
 * - Using an individual key for Controller is mandatory
 * - Good luck!
 */
export const EventDurationsTable: FC<EventDurationsTableProps> = ({ viewAll, durations, onRemoveDuration }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const [removeDurationConfirmDialogData, setRemoveDurationConfirmDialogData] = useState<ConfirmDialogData<RemoveDurationConfirmDialogData>>()
  const form = useFormContext<EventEditForm>()
  const formDurations = form.watch('durations')

  const handleRemoveDuration = useCallback(
    (duration: EventEditFormDuration, index: number) => {
      setRemoveDurationConfirmDialogData({
        title: t('internal-live-events:remove_duration_confirm_dialog_title'),
        confirmText: t('internal-live-events:remove_duration_confirm_dialog_confirm_text'),
        actionText: t('internal-common:yes'),
        cancelText: t('internal-common:no'),
        destructiveAction: true,
        data: { duration, index },
      })
    },
    [t]
  )

  const handleRemoveDurationConfirmed = (dialogData?: ConfirmDialogData<RemoveDurationConfirmDialogData>) => {
    if (dialogData?.data) {
      onRemoveDuration(dialogData.data.duration.key)
    }

    setRemoveDurationConfirmDialogData(undefined)
  }

  const durationColumns: GRTableColumn<EventEditFormDuration, typeof customProps>[] = useMemo(
    () => [
      {
        labelAccessor: () => <Trans i18nKey="internal-live-events:duration_table_start_time_column_title" />,
        accessor: ({ row, customTableProps }) => {
          return (
            <Controller
              key={`${row.key}.start`}
              name={`durations.${row.key}.start`}
              control={customTableProps?.form.control}
              render={({ field }) => {
                return <DatePicker {...field} />
              }}
            />
          )
        },
        sortable: true,
        sortOrder: SortOrder.DESC,
        sortAccessor: ({ row }) => +row.start,
      },
      {
        labelAccessor: () => <Trans i18nKey="internal-live-events:duration_table_end_time_column_title" />,
        accessor: ({ row, customTableProps }) => (
          <Controller
            key={`${row.key}.end`}
            name={`durations.${row.key}.end`}
            control={customTableProps?.form.control}
            render={({ field }) => <DatePicker {...field} />}
          />
        ),
        sortable: true,
        sortAccessor: ({ row }) => +row.end,
      },
      {
        labelAccessor: () => <Trans i18nKey="internal-live-events:duration_table_info_column_title" />,
        cellProps: { sx: { textAlign: 'left' } },
        accessor: ({ row, customTableProps }) => {
          const daysBetween = differenceInDays(row.end, row.start) + 1
          const otherDurations = customTableProps?.durations.filter((duration) => duration !== row)
          const isValidDuration = isValidEventDuration({ start: row.start, end: row.end }, otherDurations)
          return (
            <>
              <Box sx={{ verticalAlign: 'middle', display: 'inline' }}>{daysBetween} days</Box>
              {!isValidDuration && <InvalidDurationIndicator />}
            </>
          )
        },
      },
      {
        labelAccessor: () => <Trans i18nKey="internal-live-events:actions" />,
        accessor: ({ row, rowIndex, customTableProps }) => (
          <ActionButton color="warning" onClick={() => customTableProps?.handleRemoveDuration(row, rowIndex)}>
            <Delete fontSize="small" />
          </ActionButton>
        ),
      },
    ],
    []
  )

  const customProps = useMemo(
    () => ({
      handleRemoveDuration,
      form,
      durations: Object.values(formDurations),
    }),
    [form, formDurations, handleRemoveDuration]
  )

  const [columns, setColumns] = useState<GRTableColumn<EventEditFormDuration, typeof customProps>[]>(durationColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<EventEditFormDuration, typeof customProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  const rows = useMemo(() => Object.values(formDurations), [formDurations])

  return (
    <>
      <TableContainer component={Box} ref={containerRef}>
        <GRTable
          columns={columns}
          isLoading={false}
          onColumnsUpdated={handleColumnsUpdate}
          customProps={customProps}
          rowIdKey={(duration) => duration.key}
          rows={rows}
          scroller={containerRef}
          noRowsLabel={t('internal-live-events:event_durations_table_no_durations')}
          maxRows={viewAll ? rows.length : 10}
          gridlines
          striped
        />
      </TableContainer>
      <ConfirmDialog open={!!removeDurationConfirmDialogData} onClose={handleRemoveDurationConfirmed} confirmDialogData={removeDurationConfirmDialogData} />
    </>
  )
}
