import { startOfDay, endOfDay } from 'date-fns'

import { startOfNextDay } from '../../../helpers/date'
import { endOfDayTimestamp } from '../../../helpers/endOfDayTimestamp'
import { useInternalAccessCheck } from '../../account/hooks/roleHooks'
import { EventInstanceDuration } from '../types/EventInstanceDuration'
import { Duration } from '../types/TrackingEvents'

/**
 * Hook for preparing live event durations data for showing
 */
export const useEventInstanceDurations = (durations?: Duration[]) => {
  const internalRoleCheck = useInternalAccessCheck()

  return durations
    ?.map((duration) => {
      const start = startOfDay(duration.start).getTime()
      const end = endOfDay(duration.end).getTime()
      const durationStart = 0
      const durationEnd = startOfNextDay(duration.end) - start
      const offsetDiffMillis = (new Date(start).getTimezoneOffset() - new Date(end).getTimezoneOffset()) * 60 * 1000
      return {
        ...duration,
        start,
        end,
        duration: { start: durationStart, end: durationEnd + offsetDiffMillis },
      }
    })
    .filter((duration) => {
      if (internalRoleCheck) {
        return true
      } else {
        return duration.start < endOfDayTimestamp() ? true : false
      }
    })
    .sort((a, b) => a.start - b.start)
    .map((duration, index, durations) => {
      return {
        ...duration,
        interval: index > 0 && { start: startOfNextDay(durations[index - 1].end), end: duration.start },
      } as EventInstanceDuration
    })
}
