import { Trans } from 'react-i18next'

import { Lens } from '@mui/icons-material'

import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'

export const LastAnalystStatusIndicator = () => {
  const commonStyles = { fontSize: '1rem' }

  return (
    <GRTooltip content={<Trans i18nKey="internal-common:last_analysis_over_6_months_ago" />}>
      <Lens color="error" sx={{ ...commonStyles }} />
    </GRTooltip>
  )
}
