import React, { useEffect, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'

import InfoIcon from '@mui/icons-material/Info'
import { Box, Card, CardContent, Typography } from '@mui/material'

import { motivationStatQueryParams, useGetMultipleMotivationStatsQuery } from '../../../../api/connect'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { SortOrder } from '../../../../components/GRTable/GRTable'
import { LockedFeature } from '../../../../components/LockedFeature/LockedFeature'
import analyticsService from '../../../../services/AnalyticsService'
import languageService from '../../../../services/LanguageService'
import motivationService from '../../../../services/MotivationService'
import { LockedFeatureId } from '../../../../types/LockedFeature'
import { useCompareGamesAccessCheck, useCompareGamesMotivationsAccessCheck } from '../../../account/hooks/roleHooks'
import { Game } from '../../../game'
import { GameWithMarketIso } from '../../../game-search/components/GameSearchDialog/GameSearchDialog'
import { useGetGameList } from '../../../game/hooks/gameHooks'
import { GameAndAnalysis } from '../../../game/types/GameAndAnalysis'
import { MotivationKey, MotivationType } from '../../../market-explorer/types/MotivationType'
import { useGamesArcheTypes } from '../../hooks/useGamesArcheTypes'
import useGetMultipleMotivationProfile from '../../hooks/useGetMultipleMotivationProfile'
import { isWesternMarket, useAllMarketIsosAsian } from '../../hooks/useMarketMotivationsValidation'
import { ArchetypeDescriptionsModal } from '../ArchetypeDescriptionsModal/ArchetypeDescriptionsModal'
import MotivationArcheTypeCard from '../MotivationArcheTypeCard/MotivationArcheTypeCard'
import MotivationDriver from '../MotivationDriver/MotivationDriver'

export type SegmentMotivationsType = {
  [key in MotivationType]?: string
}

const include = [
  'name',
  'icon',
  'icons',
  'rank',
  'internal',
  'appId',
  'gpsPerMarket',
  'genreId',
  'conventionalSubgenre',
  'conventionalSubgenreId',
  'conventionalGenre',
].join(',')

/**
 * MotivationsTabView show motivation tab view
 */
interface MotivationsTabViewProps {
  gamesWithMarketIso: GameWithMarketIso
  gamesAndAnalysis: GameAndAnalysis[]
}

const MotivationsTabView: React.FC<MotivationsTabViewProps> = ({ gamesWithMarketIso, gamesAndAnalysis }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Comparison: Motivations')
  }, [])

  const gameIds = useMemo(() => Object.keys(gamesWithMarketIso), [gamesWithMarketIso])
  const motivationKeys = Object.keys(motivationService.keyMotivations) as MotivationKey[]
  const [modalMotivationKey, setModalMotivationKey] = useState<MotivationKey>()

  const { data: gameLists, isLoading: gameListLoading } = useGetGameList({ gameIds, include }, { skip: !gameIds.length })

  const hasCompareGamesAccess = useCompareGamesAccessCheck()
  const hasCompareGamesMotivationsAccess = useCompareGamesMotivationsAccessCheck()

  const allGamesAsian = useAllMarketIsosAsian(Object.values(gamesWithMarketIso).map((game) => game[0]))

  const motivationStatsQueries: motivationStatQueryParams[] = useMemo(() => {
    if (!gameLists) {
      return []
    }
    return gameLists.map((game: Game) => ({
      appId: game.appId,
      conventionalSubgenreId: game.conventionalSubgenreId,
    }))
  }, [gameLists])

  const { data: originMotivationStats, isLoading: motivationStatsLoading } = useGetMultipleMotivationStatsQuery(
    { queryParams: motivationStatsQueries },
    { skip: !motivationStatsQueries.length }
  )

  const { data: motivationProfiles } = useGetMultipleMotivationProfile({ games: gameLists || [], skip: !gameLists })

  const gamesArcheTypes = useGamesArcheTypes({ gameLists, gamesWithMarketIso, motivationKeys, motivationProfiles })

  const motivationStats = useMemo(() => {
    if (!originMotivationStats || !motivationProfiles) return

    return [...originMotivationStats].map((motivationStat, motivationIndex) => {
      const modifiedMotivationStat = { ...motivationStat }
      Object.values(MotivationType).forEach((motivationTypeKey) => {
        if (motivationProfiles[motivationIndex] && motivationProfiles[motivationIndex].motivationValues) {
          modifiedMotivationStat[motivationTypeKey] = {
            ...modifiedMotivationStat[motivationTypeKey],
            game: motivationProfiles[motivationIndex].motivationValues?.[motivationTypeKey],
          }
        }
      })
      return modifiedMotivationStat
    })
  }, [motivationProfiles, originMotivationStats])

  const labels = useMemo(() => {
    if (!motivationStats) {
      return []
    }

    return Object.values(MotivationType).map((motivationKey) => {
      return languageService.getTranslation('motivations', motivationKey)
    })
  }, [motivationStats])

  const datasets = useMemo(() => {
    if (!gameLists || !motivationStats) {
      return []
    }

    return motivationStats.map((motivationStat, index) => {
      const data = Object.values(MotivationType).map((motivationOrder) => Math.round((motivationStat[motivationOrder]?.game || 0) * 10) / 10)

      const gameId = gameLists?.[index]?.id ?? null
      const marketIso = gamesWithMarketIso?.[gameId]?.[0] || ''
      const isWesternGame = isWesternMarket(marketIso)

      return {
        label: gameLists[index]?.resolvedName,
        data: isWesternGame ? data : [], // Return empty data for non-Western games
      }
    })
  }, [gameLists, gamesWithMarketIso, motivationStats])

  const isLoading = gameListLoading && motivationStatsLoading
  if (isLoading) {
    return <GRCircularProgress />
  }
  if (!gameLists) {
    return null
  }

  if (!hasCompareGamesAccess) {
    return (
      <Box sx={{ mb: 3 }}>
        <LockedFeature.Card lockedFeatureId={LockedFeatureId.CompareGames} />
      </Box>
    )
  } else if (!hasCompareGamesMotivationsAccess) {
    return (
      <Box sx={{ mb: 3 }}>
        <LockedFeature.Card lockedFeatureId={LockedFeatureId.CompareMotivationTab} />
      </Box>
    )
  }

  if (allGamesAsian) {
    return (
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
            <InfoIcon color="primary" />
            <Typography marginLeft="5px">
              <Trans i18nKey={'motivations:motivation_na_for_market'} />
            </Typography>
          </Box>
        </CardContent>
      </Card>
    )
  }

  return (
    <Box>
      {!!motivationStats && (
        <MotivationDriver
          datasets={datasets}
          labels={labels}
          motivationStats={motivationStats}
          gameLists={gameLists}
          gamesWithMarketIso={gamesWithMarketIso}
          gamesAndAnalysis={gamesAndAnalysis}
          sortBy={gameLists[0].id}
          sortByOrder={SortOrder.DESC}
        />
      )}

      <MotivationArcheTypeCard
        onSelectModalMotivationKey={() => setModalMotivationKey(motivationKeys[0])}
        onSelectMotivation={setModalMotivationKey}
        archeTypeList={gamesArcheTypes}
        titleTranslationKey={'motivations:player_archetypes'}
      />

      {modalMotivationKey && (
        <ArchetypeDescriptionsModal datasets={datasets} labels={labels} onSetMotivationKey={setModalMotivationKey} motivationKey={modalMotivationKey} />
      )}
    </Box>
  )
}

export default MotivationsTabView
