import React, { ReactNode, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'

import { useDeleteGameMutation, useUpdateGameMutation } from '../../../../api/core'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { displaySnackBar } from '../../../snackbar'
import { SnackBarMessage } from '../../../snackbar/types/SnackBarMessage'
import { Game, GameVisibility } from '../../types/Game'
import { GameStageId } from '../../types/GameStageId'
import { GameAddNewModalStage } from '../GameAddNewModal/GameAddNewModal'
import GameGenreSelect from '../GameGenreSelect/GameGenreSelect'
import GameIconSelect from '../GameIconSelect/GameIconSelect'

/**
 * DeleteGameConfirmDialog
 */
interface DeleteGameConfirmDialogProps {
  loading?: boolean
  open: boolean
  title: string
  body: string | ReactNode
  game: Game
  onDeleteClick: (game: Game) => void
  onClose: () => void
}
const DeleteGameConfirmDialog: React.FC<DeleteGameConfirmDialogProps> = ({ game, onDeleteClick, onClose, open, title, body, loading }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>{loading ? <GRCircularProgress /> : body}</Box>
      </DialogContent>
      {!loading && (
        <DialogActions>
          <Button color="warning" variant="contained" onClick={() => onDeleteClick(game)}>
            {t('common:delete_game')}
          </Button>
          <Button onClick={() => onClose()}>{t('common:close')}</Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

/**
 * GameEditModal Edit game based on input game info
 */
interface GameEditModalProps {
  open: boolean
  game: Game
  stages?: GameAddNewModalStage[]
  onClose: (game?: Game) => void
  onGameEditDone: (game: Game, isGameDeleted?: boolean) => void
}
const GameEditModal: React.FC<GameEditModalProps> = ({ open = false, game, stages, onClose, onGameEditDone }) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const showError = (errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload))

  const gameStages = !!stages
    ? stages
    : [
        {
          label: t('game-analyzer:my_portfolio_concepts'),
          id: GameStageId.concept,
        },
        {
          label: t('game-analyzer:my_portfolio_productions'),
          id: GameStageId.production,
        },
        {
          label: t('game-analyzer:my_portfolio_lives'),
          id: GameStageId.live,
        },
      ]

  const [updateGame, { isLoading: isGameEditing }] = useUpdateGameMutation()
  const [deleteGame, { isLoading: isGameDeleting }] = useDeleteGameMutation()

  const [metaData, setMetaData] = useState<Game>(game)
  const [disabled, setDisabled] = useState(false)

  const [openDeleteGameConfirmDialog, setOpenDeleteGameConfirmDialog] = useState(false)

  const resetMetaData = () => {
    setMetaData(game)
  }

  const onSave = async () => {
    try {
      await updateGame({ game: metaData, gameId: game.id }).unwrap()
      onGameEditDone(game)
    } catch (err) {
      showError({ message: t('game-edit:error_edit_game_body'), severity: 'error', open: true })
    }
  }

  const onCloseClick = () => {
    onClose(game)
    resetMetaData()
  }

  const onGameDeleteClick = async () => {
    try {
      await deleteGame(game.id)
      onGameEditDone(game, true)
      dispatch(displaySnackBar({ message: t('game-edit:game_deleted'), severity: 'success', open: true }))
    } catch (err) {
      setOpenDeleteGameConfirmDialog(false)
      showError({ message: t('game-edit:error_edit_game_body'), severity: 'error', open: true })
    }
  }

  const validateMetaData = (): boolean => {
    return JSON.stringify(game) !== JSON.stringify(metaData)
  }

  const onDataChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLTextAreaElement

    if (target.name === 'name') {
      target.value.length < 1 ? setDisabled(true) : setDisabled(false)
    }

    switch (target.name) {
      case 'stageId':
        setMetaData({ ...metaData, [target.name]: Number(target.value) } as Game)
        break
      default:
        setMetaData({ ...metaData, [target.name]: target.value } as Game)
    }
  }

  return (
    <>
      <DeleteGameConfirmDialog
        open={openDeleteGameConfirmDialog}
        title={t('game-edit:delete_game')}
        body={<Trans i18nKey="game-edit:confirm_delete_game" values={{ gameName: game.resolvedName }} />}
        game={game}
        onDeleteClick={onGameDeleteClick}
        onClose={() => setOpenDeleteGameConfirmDialog(false)}
        loading={isGameDeleting}
      />
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>{t('game-edit:game_settings')}</DialogTitle>
        <DialogContent aria-disabled dividers sx={{ position: 'relative' }}>
          {isGameEditing && (
            <div className="GameAddNewModal__loadingCover">
              <GRCircularProgress fontSize={1} />
            </div>
          )}
          <Grid container columnSpacing={2} mt={2}>
            <Grid item xs={10}>
              <Box component="form" flexDirection="column" display="flex" noValidate autoComplete="off" onChange={onDataChange}>
                <TextField
                  required
                  autoFocus
                  name="name"
                  size="small"
                  label={t('common:name')}
                  value={metaData.name}
                  variant="outlined"
                  color="primary"
                  sx={{ mb: 2 }}
                  error={disabled}
                />

                <TextField
                  name="desc"
                  label={t('common:description')}
                  value={metaData.desc ? metaData.desc : ''}
                  variant="outlined"
                  color="primary"
                  sx={{ mb: 2 }}
                  multiline
                  rows={4}
                  size="small"
                />

                <Box sx={{ mb: 2 }}>
                  <GameGenreSelect
                    value={metaData.conventionalSubgenreId || ''}
                    onChange={(e) => {
                      setMetaData({ ...metaData, conventionalSubgenreId: e.target.value } as Game)
                    }}
                    showSubgenreDefinitionBtn
                  />
                </Box>

                <Box sx={{ mb: 1 }}>
                  <FormLabel>{t('game-edit:development_stage')}</FormLabel>
                  <RadioGroup aria-labelledby="development-stage" row value={metaData.stageId} name="stageId">
                    {gameStages.map((stage) => (
                      <FormControlLabel
                        sx={{ textTransform: 'capitalize' }}
                        key={stage.id}
                        itemType="number"
                        value={stage.id}
                        control={<Radio />}
                        label={stage.label}
                      />
                    ))}
                  </RadioGroup>
                </Box>

                <FormLabel>{t('game-edit:visibility')}</FormLabel>
                <RadioGroup row value={metaData.visibility} name="visibility">
                  <FormControlLabel value={GameVisibility.organization} control={<Radio />} label={t('game-edit:share_with_team') as string} />
                  <FormControlLabel value={GameVisibility.private} control={<Radio />} label={t('game-edit:private') as string} />
                </RadioGroup>
              </Box>
              <Button sx={{ mt: 1 }} disabled={isGameEditing} color="warning" variant="contained" onClick={() => setOpenDeleteGameConfirmDialog(true)}>
                {t('game-edit:delete_game')}
              </Button>
            </Grid>

            <Grid item xs={2}>
              <Box sx={{ mb: 1 }}>
                <FormLabel>{t('common:icon')}</FormLabel>
              </Box>
              <GameIconSelect
                selectedIcon={game.icon}
                onFileSelected={(icon) => {
                  setMetaData({ ...metaData, icon } as Game)
                }}
                onFileRemoved={() => {
                  setMetaData({ ...metaData, icon: null } as Game)
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Typography color="GrayText" sx={{ mr: 1 }}>
            {`${t('game-edit:created_by')} ${game.createdBy}`}
          </Typography>
          <Button disabled={!validateMetaData() || isGameEditing || disabled} variant="contained" onClick={onSave}>
            {t('common:save')}
          </Button>
          <Button onClick={onCloseClick}>{t('common:back')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default GameEditModal
