import { t } from 'i18next'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Add, Check, Close, Delete, Info, Send } from '@mui/icons-material'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField } from '@mui/material'
import { Box } from '@mui/system'

import { useInviteNewUserMutation } from '../../../../api/account'
import { useGetUserProfileQuery } from '../../../../api/combined'
import GRCircularProgress from '../../../../components/GRCircularProgress/GRCircularProgress'
import { HtmlContentParser } from '../../../../components/HtmlContentParser/HtmlContentParser'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import utilsService from '../../../../services/UtilsService'
import { displaySnackBar } from '../../../snackbar'
import { useCurrentOrganizationSeatLimit } from '../../hooks/organizationHooks'
import { InvitedUser, InviteStatus } from '../../types/InvitedUser'
import './InviteUsersDialog.scss'

type NewUserInfo = InvitedUser & {
  status: InviteStatus
}

const useValidateUserInfo = (userInfoList: { [key: string]: NewUserInfo }) => {
  const isNewUsersValid = useMemo(() => {
    const emptyEmail = Object.values(userInfoList).filter((userInfo) => userInfo.email !== '')
    if (!emptyEmail.length) return <></>

    const uniqueUserInfoEmailList = [...new Set(Object.values(userInfoList).map((userInfo) => userInfo.email))]
    if (Object.values(userInfoList).length !== uniqueUserInfoEmailList.length)
      return <Box className="InviteUsersDialog__messageContainer">{t('organization:duplicate_email')}</Box>

    const validUsers = Object.values(userInfoList).map((newUserData) => (utilsService.validateEmail(newUserData.email) ? true : false))

    return validUsers.includes(false) ? <></> : null
  }, [userInfoList])

  return isNewUsersValid
}

interface Props {
  dialogOpen: boolean
  activeUsersCount: number
  handleClose: () => void
}

/**
 * InviteUsersDialog
 */
interface AddUserProps {
  handleInput: (event: any) => void
  invitationSendStatus: InviteStatus
  seatLimitFull: () => boolean
  userInfo: NewUserInfo
  handleRemove: () => void
  disabledRemove?: boolean
}
const AddUser: React.FC<AddUserProps> = ({ handleInput, invitationSendStatus, seatLimitFull, userInfo, handleRemove, disabledRemove }) => {
  const { t } = useTranslation()

  const validateEmail = (input: string) => {
    if (!input.length) return null
    return userInfo.email.length && !utilsService.validateEmail(userInfo.email) ? 'wrong email' : null
  }

  const validateTextField = (input: string) => {
    if (!input.length) return null
    return utilsService.containForbiddenChars(input) ? t('validation:invalid_special_characters') : null
  }

  return (
    <Box sx={{ display: 'inline-flex', mb: 2, mr: 4 }}>
      {userInfo.status === InviteStatus.INVITED && <Check color="success" sx={{ mr: 1, alignSelf: 'center' }} />}
      {userInfo.status === InviteStatus.ERROR && <Info color="warning" sx={{ mr: 1, alignSelf: 'center' }} />}
      <Grid container spacing={2}>
        <Grid item sm>
          <TextField
            name="email"
            type="email"
            label={t('common:user_email')}
            placeholder="example@email.com"
            onChange={handleInput}
            variant="outlined"
            size="small"
            disabled={invitationSendStatus === 'inviting' || seatLimitFull() || userInfo.status === InviteStatus.INVITED}
            fullWidth={true}
            value={userInfo.email}
            error={!!validateEmail(userInfo.email)}
            helperText={validateEmail(userInfo.email)}
          ></TextField>
        </Grid>
        <Grid item sm>
          <TextField
            name="firstName"
            type="text"
            label={t('common:user_firstname')}
            onChange={handleInput}
            variant="outlined"
            size="small"
            disabled={invitationSendStatus === 'inviting' || seatLimitFull() || userInfo.status === InviteStatus.INVITED}
            fullWidth={true}
            value={userInfo.firstName}
            error={!!validateTextField(userInfo.firstName)}
            helperText={validateTextField(userInfo.firstName)}
            placeholder={t('common:optional')}
          ></TextField>
        </Grid>
        <Grid item sm>
          <TextField
            name="lastName"
            type="text"
            label={t('common:user_lastname')}
            onChange={handleInput}
            variant="outlined"
            size="small"
            disabled={invitationSendStatus === 'inviting' || seatLimitFull() || userInfo.status === InviteStatus.INVITED}
            fullWidth={true}
            value={userInfo.lastName}
            error={!!validateTextField(userInfo.lastName)}
            helperText={validateTextField(userInfo.lastName)}
            placeholder={t('common:optional')}
          ></TextField>
        </Grid>
      </Grid>
      <IconButton onClick={handleRemove} sx={{ backgroundColor: 'transparent', position: 'absolute', right: 8 }} disabled={disabledRemove}>
        <Delete />
      </IconButton>
    </Box>
  )
}

const InviteUsersDialog: React.FC<Props> = (props: Props) => {
  const generateId = () => {
    return new Date().getTime()
  }
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { data: currentUser } = useGetUserProfileQuery()
  const [inviteNewUser] = useInviteNewUserMutation()
  const organizationSeatLimit = useCurrentOrganizationSeatLimit()
  const [addingUser, setAddingUser] = useState(false)

  const handleClose = () => {
    setUserInfoList({ [generateId()]: newUserInfo })
    props.handleClose()
  }

  const newUserInfo: NewUserInfo = {
    email: '',
    firstName: '',
    lastName: '',
    password: utilsService.getRandomPassword(),
    title: '',
    status: InviteStatus.IDLE,
  }
  const [userInfoList, setUserInfoList] = useState<{ [key: string]: NewUserInfo }>({ [generateId()]: newUserInfo })
  const isNewUsersValid = useValidateUserInfo(userInfoList)

  const sendInvite = useCallback(() => {
    if (!currentUser) return

    const invitingUserInfoList = { ...userInfoList }
    for (const key in invitingUserInfoList) {
      if (Object.prototype.hasOwnProperty.call(invitingUserInfoList, key)) {
        const userInfo = invitingUserInfoList[key]
        invitingUserInfoList[key] = { ...userInfo, status: InviteStatus.INVITING }
      }
    }

    setAddingUser(true)
    setUserInfoList(invitingUserInfoList)
    const clonedUserInfo = { ...userInfoList }
    Object.entries(userInfoList).forEach(async ([id, userInfo]) => {
      const resp = await inviteNewUser({
        organization: currentUser.organization,
        invitedUser: userInfo,
      }).unwrap()

      // TODO-LATER-REFACTOR: the message can be done with status code from BE: https://github.com/Gamerefinery/saas-v2/pull/159
      const duplicatedMessage = 'This user has already been invited to this organization.'
      if (resp.message === duplicatedMessage) {
        dispatch(
          displaySnackBar({
            message: <HtmlContentParser rawHtml={`${t('implementations:notify_user_exists', { email: userInfo.email })}`} />,
            severity: 'error',
            open: true,
          })
        )
        setAddingUser(false)
        setUserInfoList({ ...userInfoList, [id]: { ...userInfoList[id], status: InviteStatus.ERROR } })
        return
      }
      setAddingUser(false)
      clonedUserInfo[id].status = InviteStatus.INVITED
    })
    setUserInfoList(clonedUserInfo)
  }, [currentUser, dispatch, inviteNewUser, t, userInfoList])

  const handleUpdateUserInfo = (key: number, field: string, value: string) => {
    setUserInfoList({ ...userInfoList, [key]: { ...userInfoList[key], [field]: value, status: InviteStatus.IDLE } })
  }

  const handleAddAnother = () => {
    setUserInfoList({ ...userInfoList, [generateId()]: newUserInfo })
  }

  const handleRemove = (id: string) => {
    const clonedUser = { ...userInfoList }
    delete clonedUser[id]
    setUserInfoList(clonedUser)
  }

  const seatLimitFull = () => {
    if (organizationSeatLimit === 0) {
      return false
    } else {
      return organizationSeatLimit - props.activeUsersCount <= 0 ? true : false
    }
  }

  return (
    <Dialog className="InviteUsersDialog" onClose={handleClose} fullWidth={true} maxWidth="sm" open={props.dialogOpen}>
      <DialogTitle id="customized-dialog-title">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>{t('organization:users_invite_users_title')}</Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose} style={{ margin: '-10px' }}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className="DialogContent" dividers>
        <p style={{ margin: '0px 0px 16px' }}>
          {t('organization:available_seats_text', {
            seatsCount: props.activeUsersCount,
            maxSeatsCount: organizationSeatLimit === 0 ? '∞' : organizationSeatLimit,
          })}
        </p>
        {Object.entries(userInfoList).map(([id, userInfo]) => (
          <AddUser
            key={id}
            userInfo={userInfo}
            handleInput={(event) => handleUpdateUserInfo(Number(id), event.target.name, event.target.value)}
            invitationSendStatus={userInfo.status}
            seatLimitFull={seatLimitFull}
            handleRemove={() => handleRemove(id)}
            disabledRemove={Object.entries(userInfoList).length <= 1}
          />
        ))}

        {
          <Button
            disabled={props.activeUsersCount >= organizationSeatLimit || Object.entries(userInfoList).length >= organizationSeatLimit - props.activeUsersCount}
            onClick={handleAddAnother}
            variant="contained"
            color="primary"
            startIcon={<Add />}
          >
            {t('organization:users_add_another')}
          </Button>
        }
        <Divider sx={{ my: 2 }} />

        <div className="text-center">
          {isNewUsersValid}
          {addingUser ? (
            <GRCircularProgress />
          ) : (
            <Button onClick={sendInvite} variant="contained" color="primary" disabled={isNewUsersValid !== null || seatLimitFull()} startIcon={<Send />}>
              {t('organization:send_invite')}
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default InviteUsersDialog
