import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { Overlay } from '../../../../components/Overlay/Overlay'
import { OverlayLoader } from '../../../../components/OverlayLoader/OverlayLoader'
import { useEventCounts, useEventStatistics, useEventStatisticsLimitedTimeRange } from '../../hooks/liveEventsEventTypeStatistics'
import { useNonSpecialEventsTrackingEventsByGame } from '../../hooks/useNonSpecialEventsTrackingEventsByGame'
import { TrackedGame } from '../../types/TrackedGame'
import { FilteredTrackingEventsByGame } from '../../types/TrackingEvents'
import { LiveEventStatisticsTable } from './LiveEventStatisticsTable/LiveEventStatisticsTable'
import { LiveEventsEventCountChart } from './LiveEventsEventCountChart/LiveEventsEventCountChart'

type LiveEventsEventStatisticsProps = {
  trackedGames: TrackedGame[]
  trackingEventsByGame: FilteredTrackingEventsByGame
  timeStart: number
  timeEnd: number
  isLoading?: boolean
  children?: ReactNode
}

export const LiveEventsEventStatistics: FC<LiveEventsEventStatisticsProps> = ({ trackedGames, trackingEventsByGame, timeStart, timeEnd, isLoading }) => {
  const { t } = useTranslation()
  const timeRange = useEventStatisticsLimitedTimeRange({ timeStart, timeEnd })
  const filteredTrackingEventsByGame = useNonSpecialEventsTrackingEventsByGame(trackingEventsByGame)
  const eventCounts = useEventCounts({ trackingEventsByGame: filteredTrackingEventsByGame, timeStart: timeRange.start, timeEnd: timeRange.end })
  const eventStatistics = useEventStatistics({ trackingEventsByGame: filteredTrackingEventsByGame, timeStart: timeRange.start, timeEnd: timeRange.end })
  const trackedGamesCount = Object.keys(filteredTrackingEventsByGame.events).length

  return (
    <Card>
      <CardHeader
        title={
          <>
            <Box mb={1}>{t('live-events:event_statistics_event_count_chart_title', { gameCount: trackedGamesCount })}</Box>
            <Grid container spacing={1}>
              <Grid item>
                <Info color="action" />
              </Grid>
              <Grid item xs>
                <Typography color="action" variant="body1">
                  {t('live-events:event_statistics_disclaimer')}
                </Typography>
              </Grid>
            </Grid>
          </>
        }
      ></CardHeader>
      <CardContent>
        <OverlayLoader isLoading={isLoading}>
          <Overlay in={!eventCounts && !isLoading} content={t('common:no_data_available')}>
            <LiveEventsEventCountChart eventCounts={eventCounts} />
          </Overlay>
        </OverlayLoader>
      </CardContent>
      <Divider sx={{ mt: 2 }} />
      <LiveEventStatisticsTable rows={eventStatistics} trackedGames={trackedGames} isLoading={isLoading} />
    </Card>
  )
}
