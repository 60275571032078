import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useGameAndAnalysis, useIsGameUnlockedCheck } from '../../features/game/hooks/gameHooks'
import { GameStageId } from '../../features/game/types/GameStageId'
import { useCurrentMarket } from '../../features/markets'
import GamePage from './GamePage'

/**
 * GamePageWrapper
 */
const GamePageWrapper: React.FC = () => {
  const navigate = useNavigate()
  const params: { id?: string; subpage?: string } = useParams()
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const { gameAndAnalysis } = useGameAndAnalysis(params.id || '', mainMarketIso)
  const isGameUnlocked = useIsGameUnlockedCheck(gameAndAnalysis)
  const isGameSoftLaunch = gameAndAnalysis?.game.stageId === GameStageId.soft_launch ? true : false

  useEffect(() => {
    if (isGameSoftLaunch === true && isGameUnlocked === false) {
      navigate('/daily-insights', { replace: true })
    }
  }, [isGameSoftLaunch, isGameUnlocked, navigate])

  return isGameSoftLaunch === true && isGameUnlocked === false ? null : <GamePage />
}

export default GamePageWrapper
