import React from 'react'
import { Trans } from 'react-i18next'

import { Card, CardContent } from '@mui/material'
import { Box } from '@mui/system'

import { Game } from '../../features/game'
import SuggestAnalysisButton from '../../features/game/components/SuggestAnalysisButton/SuggestAnalysisButton'
import './GameNotAnalyzedCard.scss'

interface Props {
  game: Game
}

const GameNotAnalyzedCard: React.FC<Props> = ({ game }) => {
  return (
    <Card className="GameNotAnalyzedCard">
      <CardContent>
        <Box mb={4} className="GameNotAnalyzedCard__Message">
          <Trans i18nKey={'common:game_not_analyzed_description'} />
        </Box>

        <SuggestAnalysisButton game={game}></SuggestAnalysisButton>
      </CardContent>
    </Card>
  )
}

export default GameNotAnalyzedCard
