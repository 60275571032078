import React, { useCallback, useEffect, useState } from 'react'

import { Tag } from '../../../../api/core'
import { TagList } from '../../../feature/components/TagList/TagList'
import { useFeatureTags } from '../../../feature/hooks/useFeatureTags'

/**
 * FilterTagList operate filtering tags for game analyzer detail page
 */
interface FilterTagListProps {
  onTagsSelected: (tags: Tag[]) => void
}
const FilterTagList: React.FC<FilterTagListProps> = ({ onTagsSelected }) => {
  const { data: featureTagGroups } = useFeatureTags()
  const [selectedFeatureTags, setSelectedFeatureTags] = useState<Tag[]>([])

  useEffect(() => {
    onTagsSelected(selectedFeatureTags)
  }, [onTagsSelected, selectedFeatureTags])

  const handleSelectedFeatureTagsChange = useCallback((selectedTags: Tag[], tag?: Tag) => {
    if (tag) {
      setSelectedFeatureTags([tag])
    }
  }, [])

  const handleClearSelectedFeatureTags = useCallback(() => {
    setSelectedFeatureTags([])
  }, [])

  return (
    <TagList
      tagGroups={featureTagGroups}
      onChange={handleSelectedFeatureTagsChange}
      selectedTags={selectedFeatureTags}
      onClear={handleClearSelectedFeatureTags}
    />
  )
}

export default FilterTagList
