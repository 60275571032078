import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { URLSearchParams } from 'url'

import { modalParamPrefix, modalParamsByModalType, modalTypeParamKey } from '../const/const'
import { EModalType, TModalParamsByModalType } from '../types/modalTypes'
import { addParamWithKey, removeLastParamWithKey } from '../utils/utils'

type modalParamsType<E extends EModalType> = { [key in TModalParamsByModalType[E]]?: string | number | symbol | undefined }

type newModalParams<E extends EModalType> = {
  type: E // type of modal
  params: modalParamsType<E> // search parameters of the modal
  reversed?: boolean // should the modal be added to the stack in reverse order
}

/**
 * Hook for managing modals with url search params. Modals are treated as a stack with push, pop and replace operations.
 * Modals are defined by paramter named modalType and each type of modal has its set of parameters. Modal parameters are prefixed with 'm_' in the urls.
 */
export const useRouteModal = (initialSearchParams?: URLSearchParams) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams(initialSearchParams)

  /**
   * Helper function for generating url search params for pushing a new modal to the modal stack.
   * Can be used e.g. if you need to do separate navigation in one of the components and need to address
   * modal addition at the same time.
   *
   * @param opts
   * @param urlSearchParams URLSearchParams to modify
   * @returns altered search params object as @type {URLSearchParams}
   */
  const generatePushModalParams = useCallback(
    <E extends EModalType>(opts: newModalParams<E>, urlSearchParams: URLSearchParams = searchParams) => {
      addParamWithKey({ searchParams: urlSearchParams, key: modalTypeParamKey, value: opts.type, reversed: opts.reversed })
      Object.entries(opts.params).forEach(([key, value]) => {
        addParamWithKey({ searchParams: urlSearchParams, key: `${modalParamPrefix}${key}`, value: value as string, reversed: opts.reversed })
      })

      return urlSearchParams
    },
    [searchParams]
  )

  /**
   * Helper function for generating url search params for popping a modal from the modal stack.
   * Can be used e.g. if you need to do separate navigation in one of the components and need to address
   * modal removal at the same time.
   *
   * @param urlSearchParams URLSearchParams to mo modify
   * @returns altered search params object as @type {URLSearchParams}
   */
  const generatePopModalParams = useCallback(
    (urlSearchParams: URLSearchParams = searchParams) => {
      const topmostModalType = removeLastParamWithKey(urlSearchParams, modalTypeParamKey) as EModalType
      const modalParams = modalParamsByModalType[topmostModalType]

      modalParams.forEach((key) => {
        removeLastParamWithKey(urlSearchParams, `${modalParamPrefix}${key}`)
      })

      return urlSearchParams
    },
    [searchParams]
  )

  /**
   * Helper function for generating url search params for pushing a new modal to the modal stack.
   * Can be used e.g. if you need to do separate navigation in one of the components and need to address
   * modal addition at the same time.
   *
   * @param opts
   * @param urlSearchParams URLSearchParams to modify
   * @returns altered search params object as @type {URLSearchParams}
   */
  const generateReplaceModalParams = useCallback(
    <E extends EModalType>(opts: newModalParams<E>, urlSearchParams: URLSearchParams = searchParams) => {
      // remove topmost modalType
      const removedModalType = removeLastParamWithKey(urlSearchParams, modalTypeParamKey) as EModalType
      const modalParams = modalParamsByModalType[removedModalType]
      // remove topmost modals params
      modalParams.forEach((key) => {
        removeLastParamWithKey(urlSearchParams, `${modalParamPrefix}${key}`)
      })

      // add params of new replacing modal
      addParamWithKey({ searchParams: urlSearchParams, key: modalTypeParamKey, value: opts.type, reversed: opts.reversed })
      Object.entries(opts.params).forEach(([key, value]) => {
        value !== undefined &&
          addParamWithKey({ searchParams: urlSearchParams, key: `${modalParamPrefix}${key}`, value: value as string, reversed: opts.reversed })
      })

      return urlSearchParams
    },
    [searchParams]
  )

  /**
   * Helper function for generating url search params for popping all modals at once from the modal stack.
   * Can be used e.g. if you need to do separate navigation in one of the components and need to address
   * modal removal at the same time.
   *
   * @param urlSearchParams URLSearchParams to modify
   * @returns altered search params object as @type {URLSearchParams}
   */
  const generatePopAllModalParams = useCallback(
    (urlSearchParams: URLSearchParams = searchParams) => {
      const modalTypes = (urlSearchParams.get(modalTypeParamKey)?.split(',') || []) as EModalType[]

      const paramsToDelete = new Set<string>()
      modalTypes.forEach((modalType) => {
        const modalParams = modalParamsByModalType[modalType]
        modalParams.forEach((param) => paramsToDelete.add(param))
      })

      urlSearchParams.delete(modalTypeParamKey)
      paramsToDelete.forEach((key) => {
        urlSearchParams.delete(key)
      })

      return urlSearchParams
    },
    [searchParams]
  )

  /**
   *  Adds a new modal to the top of the modal stack.
   * @param opts parameters for the modal to be added to url search
   */
  const pushModal = useCallback(
    <E extends EModalType>(opts: newModalParams<E>) => {
      setSearchParams((currentSearchParams) => {
        return generatePushModalParams(opts, currentSearchParams)
      })
    },
    [generatePushModalParams, setSearchParams]
  )

  /**
   * Removes the topmost modal from the modal stack.
   */
  const popModal = useCallback(() => {
    setSearchParams((currentSearchParams) => {
      return generatePopModalParams(currentSearchParams)
    })
  }, [generatePopModalParams, setSearchParams])

  /**
   *
   * @param opts parameters for the new modal
   */
  const replaceModal = useCallback(
    <E extends EModalType>(opts: newModalParams<E>) => {
      setSearchParams(
        (currentSearchParams) => {
          return generateReplaceModalParams(opts, currentSearchParams)
        },
        { replace: false }
      )
    },
    [generateReplaceModalParams, setSearchParams]
  )

  /**
   * Removes all modals from the modal stack.
   */
  const popAllModals = useCallback(() => {
    setSearchParams((currentSearchParams) => {
      return generatePopAllModalParams(currentSearchParams)
    })
  }, [generatePopAllModalParams, setSearchParams])

  return {
    pushModal,
    popModal,
    replaceModal,
    popAllModals,
    generatePushModalParams,
    generatePopModalParams,
    generateReplaceModalParams,
    generatePopAllModalParams,
  }
}
