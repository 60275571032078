export enum PerformanceChartDataType {
  Estimates,
  RevenueDownloadsRatioAllTime,
  RevenueDownloadsRatio7Days,
  RevenueDownloadsRatio30Days,
  RevenueDownloadsRatio90Days,
  Ranks,
}

export type DataTypeSelectorOptionValue = PerformanceChartDataType | ''

export enum ChartDataFormat {
  Currency,
  Number,
  Percentage,
}

export type PerformanceRank = {
  rank: number
  ts: number
}
