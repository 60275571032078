import { MongoDBObject } from '../../../types/MongoDBObject'

export interface Quarter extends MongoDBObject {
  name: string
  quarterIdentifier: string
  startTimestamp: number
  endTimestamp: number
  quarter: number
  year: number
  grossingGames: number[]
  freeGames: number[]
  totalRevenue: number
  totalDownloads: number
  market: string
  revenueTop500Value: number
  downloadsTop500Value: number
  revenuesAndDownloads: { [key: string]: { revenue: number; downloads: number } }
  isQuarterComplete(): boolean
}

export class Quarter extends MongoDBObject implements Quarter {
  name: string
  quarterIdentifier: string
  startTimestamp: number
  endTimestamp: number

  constructor(data: Quarter) {
    super(data)
    Object.assign(this, data)
    this.quarterIdentifier = `${this.quarter}-${this.year}`
    const { startTimestamp, endTimestamp } = this.getQuarterStartAndEndTimestamp()
    this.startTimestamp = startTimestamp
    this.endTimestamp = endTimestamp
    this.name = this.getQuarterName()
  }

  isQuarterComplete(): boolean {
    const quarterEndDate = new Date(this.endTimestamp)
    quarterEndDate.setDate(quarterEndDate.getDate() + 7)
    const nowTimestamp = +new Date()
    return nowTimestamp < quarterEndDate.getTime()
  }

  private getQuarterName(): string {
    return `${this.year} Q${this.quarter}`
  }

  private getQuarterStartAndEndTimestamp() {
    let quarterStartMonth = 0
    let quarterEndMonth = 0

    switch (this.quarter) {
      case 1:
        quarterStartMonth = 0
        quarterEndMonth = 2
        break
      case 2:
        quarterStartMonth = 3
        quarterEndMonth = 5
        break
      case 3:
        quarterStartMonth = 6
        quarterEndMonth = 8
        break
      case 4:
        quarterStartMonth = 9
        quarterEndMonth = 11
        break
    }

    return {
      startTimestamp: new Date(Date.UTC(this.year, quarterStartMonth, 1)).getTime(),
      endTimestamp: new Date(Date.UTC(this.year, quarterEndMonth + 1, 0, 23, 59, 59)).getTime(),
    }
  }
}
