import { FC } from 'react'

import { Badge, Avatar, Typography } from '@mui/material'

import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { Analyst } from '../../../types/Analyst'
import { useInitials } from '../../hooks/useInitials'

export const AnalystAvatar: FC<{ analyst?: Analyst }> = ({ analyst }) => {
  const analystInitials = useInitials(analyst?.name)
  return analyst ? (
    <GRTooltip content={analyst?.name}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Avatar
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.main,
              fontSize: 8,
              width: 20,
              height: 20,
              border: (theme) => `1px solid ${theme.palette.background.paper}`,
            }}
          >
            <Typography variant="body1" fontSize={8} letterSpacing={0.5}>
              {analystInitials}
            </Typography>
          </Avatar>
        }
      >
        <Avatar alt={analyst?.name} src={analyst?.picture} />
      </Badge>
    </GRTooltip>
  ) : null
}
