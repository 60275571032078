import { t } from 'i18next'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans } from 'react-i18next'

import { Add } from '@mui/icons-material'
import { Grid, Typography, TextField, Box } from '@mui/material'

import { ActionButton } from '../../../../components/ActionButton/ActionButton'
import { isNumber } from '../../../../helpers/isNumber'
import { Currency } from '../../../../types/Currency'
import '../CurrencyDisplay/PricingDataFlexContainer.scss'
import { pricePointDefaultCurrency } from '../PricePointContainer/PricePointSelectMenu/PricePointSelectMenu'

type CurrencyAdderProps = {
  currencies: Currency[] | undefined
  onChange: (currency: Currency[]) => void
}

export const CurrencyAdder: FC<CurrencyAdderProps> = ({ currencies, onChange }) => {
  const defaultValues = { name: '', [pricePointDefaultCurrency]: '', value: '' }
  const {
    control,
    getValues,
    reset,
    watch,
    formState: { isValid },
  } = useForm({
    defaultValues: defaultValues,
  })

  const formData = watch()

  const addNewCurrency = () => {
    const currencyValues = {
      name: getValues('name').trimEnd(),
      usd: +getValues(pricePointDefaultCurrency),
      value: +getValues('value'),
    }
    if (currencies) {
      const newCurrencyList = [...currencies, currencyValues]
      onChange(newCurrencyList)
      reset(defaultValues)
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h3">
          <Trans i18nKey="internal-live-events:add_new_currency" />
        </Typography>
      </Grid>
      <Box className="PricingDataFlexContainer">
        <Box className="item item-large">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <TextField
                  required
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  variant="outlined"
                  size="small"
                  label={t('internal-live-events:currency_name')}
                  fullWidth
                  error={includesName(formData, currencies)}
                  helperText={includesName(formData, currencies) ? t('internal-live-events:duplicate_currency_error_message') : ' '}
                />
              )
            }}
          />
        </Box>
        <Box className="item item-large">
          <Controller
            name={pricePointDefaultCurrency}
            control={control}
            rules={{ required: true, validate: isNumber }}
            render={({ field }) => {
              return (
                <TextField
                  required
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  variant="outlined"
                  size="small"
                  label={t('internal-live-events:usd')}
                  fullWidth
                  error={!isNumber(field.value)}
                  helperText={!isNumber(field.value) ? t('internal-live-events:enter_number_error_message') : ' '}
                />
              )
            }}
          />
        </Box>
        <Box className="item item-large">
          <Controller
            name="value"
            control={control}
            rules={{ required: true, validate: isNumber }}
            render={({ field }) => {
              return (
                <TextField
                  required
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  variant="outlined"
                  size="small"
                  label={t('internal-live-events:in_game_currency')}
                  fullWidth
                  error={!isNumber(field.value)}
                  helperText={!isNumber(field.value) ? t('internal-live-events:enter_number_error_message') : ' '}
                />
              )
            }}
          />
        </Box>
        <Box className="item item-small" sx={{ paddingTop: '0 !important' }}>
          <ActionButton onClick={addNewCurrency} disabled={!(isValid && !includesName(formData, currencies))}>
            <Add fontSize="small" />
          </ActionButton>
        </Box>
      </Box>
    </>
  )
}

export const includesName = (formData: any, currencies: Currency[] | undefined) => {
  if (formData.name.trimEnd().length === 0) return false
  const result = currencies?.some((currency) => currency.name === formData.name.trimEnd())
  if (result) return result
  return false
}
