import { format } from 'date-fns'
import { t } from 'i18next'
import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'

import { Box, Card, FormControl, Typography } from '@mui/material'

import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu'
import MotivationRadarChart, { MotivationRadarChartProps } from '../MotivationRadarChart/MotivationRadarChart'
import MotivationTable, { MotivationTableProps } from '../MotivationTable/MotivationTable'

interface MotivationDriverHeaderProps {
  motivationProfileVersions?: string[]
  valueOptions?: {
    value: any
    label: string
  }[]
  onOptionSelected?: (option: string) => void
  onVersionSelected?: (version: string) => void
  defaultValue?: string
  defaultVersion?: string
  lastUpdatedDate?: number
  disableSelectOptions?: boolean
  titleTranslationKey?: string
  gamePowerScore?: number | undefined
}

export const MotivationDriverHeader: React.FC<MotivationDriverHeaderProps> = ({
  motivationProfileVersions,
  valueOptions,
  onOptionSelected,
  onVersionSelected,
  defaultValue,
  defaultVersion,
  lastUpdatedDate,
  disableSelectOptions = false,
  titleTranslationKey = 'motivations:motivational_drivers',
  gamePowerScore,
}) => {
  const versionOptions = useMemo(
    () =>
      (motivationProfileVersions || []).map((version, index, array) => ({
        value: version,
        name: index === array.length - 1 ? t('motivations:first_version', { version }) : t('motivations:version_no', { version }),
      })),
    [motivationProfileVersions]
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h2" sx={{ mr: 1 }}>
            {t(titleTranslationKey)}
          </Typography>
          {lastUpdatedDate ? <Typography>({t('common:updated_at', { date: format(lastUpdatedDate, 'd LLL yyyy') })})</Typography> : null}
        </Box>

        <Box>
          {!!motivationProfileVersions && motivationProfileVersions.length > 0 && !!gamePowerScore && (
            <FormControl sx={{ minWidth: '100px', marginRight: '15px' }}>
              <SelectMenu
                label={t('common:compare_game')}
                options={versionOptions}
                onChange={({ target }) => {
                  if (onVersionSelected) {
                    onVersionSelected(typeof target.value === 'string' ? target.value : target.value.value)
                    return
                  }
                  throw new Error('onVersionSelected is not defined')
                }}
                value={defaultVersion ? defaultVersion : motivationProfileVersions[0]}
              />
            </FormControl>
          )}

          {!!valueOptions && !!onOptionSelected && (
            <FormControl>
              <SelectMenu
                label={t('common:value_text')}
                disabled={disableSelectOptions}
                options={valueOptions.map((option) => ({ value: option.value, name: option.label }))}
                onChange={({ target }) => onOptionSelected(typeof target.value === 'string' ? target.value : target.value.value)}
                value={defaultValue ? defaultValue : valueOptions[0]}
              />
            </FormControl>
          )}
        </Box>
      </Box>
      <Typography>
        <Trans i18nKey={'motivations:description'} />
      </Typography>
    </>
  )
}

/**
 * MotivationDriver Card for showing motivation radar chart and motivation table
 */
interface MotivationDriverProps extends MotivationRadarChartProps, MotivationTableProps, MotivationDriverHeaderProps {
  motivationProfileVersions?: string[]
  lastUpdatedDate?: number
  titleTranslationKey?: string
  gamePowerScore?: number | undefined
  gamesWithMarketIso?: { [key: string]: string[] }
}
const MotivationDriver: React.FC<MotivationDriverProps> = ({
  labels,
  datasets,
  motivationStats,
  motivationProfileVersions,
  gameLists,
  gamesAndAnalysis,
  dataColumns,
  dataRow,
  colorLists,
  valueOptions,
  disableSelectOptions,
  onOptionSelected,
  onVersionSelected,
  defaultValue,
  defaultVersion,
  sortBy,
  sortByOrder,
  lastUpdatedDate,
  gameNotAnalyzed,
  titleTranslationKey,
  gamePowerScore,
  gamesWithMarketIso,
}) => {
  return (
    <Card sx={{ my: 2 }}>
      <Box p={2}>
        <MotivationDriverHeader
          motivationProfileVersions={motivationProfileVersions}
          disableSelectOptions={disableSelectOptions}
          valueOptions={valueOptions}
          onOptionSelected={onOptionSelected}
          onVersionSelected={onVersionSelected}
          defaultValue={defaultValue}
          defaultVersion={defaultVersion}
          lastUpdatedDate={lastUpdatedDate}
          titleTranslationKey={titleTranslationKey}
          gamePowerScore={gamePowerScore}
        />
      </Box>
      <MotivationRadarChart gameNotAnalyzed={gameNotAnalyzed} datasets={datasets} labels={labels} colorLists={colorLists} />
      <MotivationTable
        motivationProfileVersions={motivationProfileVersions}
        motivationStats={motivationStats}
        gameLists={gameLists}
        gamesWithMarketIso={gamesWithMarketIso}
        gamesAndAnalysis={gamesAndAnalysis}
        dataColumns={dataColumns}
        dataRow={dataRow}
        sortBy={sortBy}
        sortByOrder={sortByOrder}
        gameNotAnalyzed={gameNotAnalyzed}
        selectedVersion={defaultVersion}
        gamePowerScore={gamePowerScore}
      />
    </Card>
  )
}

export default MotivationDriver
