import { useMemo } from 'react'

import { useAnalyzerFreeCheck, useAnalyzerUnlockedCheck, useGameSlotsUnlimitedAccessCheck } from '../../account/hooks/roleHooks'

export const useAllowAddGame = (gameLength: number = 0) => {
  const userHasFreeAnalyzerAccess = useAnalyzerFreeCheck()
  const userHasAnalyzerUnlocked = useAnalyzerUnlockedCheck()
  const userHasUnlimitedGameSlots = useGameSlotsUnlimitedAccessCheck()

  const allowAddMoreGame = useMemo(() => {
    if (!userHasAnalyzerUnlocked) return false

    if (userHasUnlimitedGameSlots) return true

    if (!!userHasFreeAnalyzerAccess && gameLength >= 1) {
      return false
    }
    return true
  }, [gameLength, userHasAnalyzerUnlocked, userHasFreeAnalyzerAccess, userHasUnlimitedGameSlots])
  return allowAddMoreGame
}
