import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'

import { TimeSinceUpdated } from '../../types/TimeSinceUpdated'

/**
 * Component for selecting the time after which the update was released
 */
type TimeSinceUpdatedSelectorProps = {
  onChange: (value: string) => void
  value: string
}

export const TimeSinceUpdatedSelector: FC<TimeSinceUpdatedSelectorProps> = ({ onChange, value }) => {
  const { t } = useTranslation()
  const handleValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    },
    [onChange]
  )
  return (
    <FormControl component="fieldset" sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
      <FormLabel component="div">{t('common:shown_update_from')}</FormLabel>
      <RadioGroup
        row
        value={value}
        onChange={handleValueChanged}
        defaultValue="6"
        aria-label={t('common:shown_update_from')}
        name="time-since-updated-radio-buttons-group"
      >
        <FormControlLabel
          value={TimeSinceUpdated.MONTHS_6}
          control={<Radio />}
          label={t<string>('common:x_months', { monthsCount: TimeSinceUpdated.MONTHS_6 })}
        />
        <FormControlLabel
          value={TimeSinceUpdated.MONTHS_12}
          control={<Radio />}
          label={t<string>('common:x_months', { monthsCount: TimeSinceUpdated.MONTHS_12 })}
        />
        <FormControlLabel value={TimeSinceUpdated.ALL_TIME} control={<Radio />} label={t<string>('common:all_time')} />
      </RadioGroup>
    </FormControl>
  )
}
