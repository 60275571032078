import querystring from 'query-string'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'

import { pickModalParams } from '../features/route-modals/utils/utils'
import { containSameElements } from '../helpers/arrays'

/**
 * Hook for parsing browser location search parameter string to js object.
 * setSearchParams allows you reflect new search parameters to browser url without page reload.
 *
 * If you provide a mapper the search parameter will be mapped back and forth with defined mappings
 */
const defaultParams = {}
const querystringOptions = { arrayFormat: 'comma' as 'comma' }
export const useSearchParams = <Source extends DefaultSourceType, Destination extends Record<string, any>>(
  mapper?: UrlSearchParamsMapper<Source, Destination>
) => {
  const navigate = useNavigate()
  const location = useLocation()
  const initialState = useMemo(() => {
    const parsedQuery = querystring.parse(location.search, querystringOptions) as Source
    // we want to retain all possible parmas related to modals prefixed with m_
    const modalParams = pickModalParams({ params: parsedQuery })
    const result = mapper
      ? { ...modalParams, ...mapper.mapFromUrl(parsedQuery) }
      : (querystring.parse(location.search, querystringOptions) as unknown as Destination)

    return result
  }, [location.search, mapper])

  // we are using a ref to duplicate searchParams state, this is to avoid using the actual state as dependency in effects below
  const searchParamsRef = useRef<Destination>(initialState)
  const [searchParams, setSearchParams] = useState<Destination>(initialState)
  const [parsedParams, setParsedParams] = useState<Destination>(initialState)

  useEffect(() => {
    const parsedQuery = querystring.parse(location.search, querystringOptions) as Source
    // we want to retain all possible params related to modals prefixed with m_
    const modalParams = pickModalParams({ params: parsedQuery })
    const parsed = mapper
      ? { ...modalParams, ...mapper.mapFromUrl(parsedQuery) }
      : (querystring.parse(location.search, querystringOptions) as unknown as Destination)

    // do not update array references if the current items and parsed items are identical
    const parsedWithRetainedArrays = Object.entries(parsed).reduce((acc, [key, item]) => {
      if (Array.isArray(item) && Array.isArray(searchParamsRef.current[key]) && containSameElements(item, searchParamsRef.current[key])) {
        acc[key] = searchParamsRef.current[key]
      } else {
        acc[key] = item
      }

      return acc
    }, {} as Record<string, any>)

    searchParamsRef.current = parsedWithRetainedArrays as Destination
    setParsedParams(parsedWithRetainedArrays as Destination)
    setSearchParams(parsedWithRetainedArrays as Destination)
  }, [location.search, mapper])

  useEffect(() => {
    if (searchParams !== defaultParams && parsedParams !== searchParams) {
      // we want to retain all possible parmas related to modals prefixed with m_
      const modalParams = pickModalParams({ params: searchParams })
      const stringifiedSearch = mapper
        ? querystring.stringify({ ...modalParams, ...mapper.mapToUrl(searchParams) }, querystringOptions)
        : querystring.stringify(searchParams, querystringOptions)
      navigate({ search: stringifiedSearch }, { replace: true })
    }
  }, [navigate, location.pathname, mapper, searchParams, parsedParams])

  return { setSearchParams, parsedParams }
}

export type UrlSearchParamsMapper<Source, Destination> = {
  mapFromUrl: (source: Source) => Destination
  mapToUrl: (destination: Destination) => Source
}

type DefaultSourceType = { [key: string]: string | number | (string | number | undefined)[] | null | undefined }
