import classNames from 'classnames'
import { ChangeEvent, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import SendIcon from '@mui/icons-material/Send'
import { LoadingButton } from '@mui/lab'
import { Button, ButtonProps, DialogActions, Popover, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { useGetUserProfileQuery } from '../../../../api/combined'
import { IntercomConversationQueryParams, useStartIntercomConversationQuery } from '../../../../api/core'
import { Analyst } from '../../../../components/Analyst/Analyst'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { useAppDispatch } from '../../../../hooks/storeHooks'
import { Analyst as IAnalyst } from '../../../../types/Analyst'
import { useAskAnalystAccessCheck } from '../../../account/hooks/roleHooks'
import { displaySnackBar } from '../../../snackbar'

export interface AskAnalystButtonProps extends ButtonProps {
  /**
   * Name of the game to be displayed in the dialog.
   */
  gameName: string
  /**
   * The Analyst to whom the question is asked.
   */
  analyst: IAnalyst
}

/**
 * Ask Analyst Button
 * Displays a button that opens a dialog to ask an analyst a question via Intercom.
 * @param props AskAnalystButtonProps
 * @returns React component
 */
const AskAnalystButton: React.FC<AskAnalystButtonProps> = (props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const hasAccessToAskAnalyst = useAskAnalystAccessCheck()
  const [sendingMessage, setSendingMessage] = useState(false)
  const { data: currentUser } = useGetUserProfileQuery()
  const [intercomConversationData, setIntercomConversationData] = useState<IntercomConversationQueryParams>({
    userMessage: '',
    userEmail: currentUser?.email || '',
    assigneeIntercomId: props.analyst?.intercomId || '',
    assigneeReplyMessage: t('analysis:ask_analyst_autoreply', { firstName: currentUser?.firstName, gameName: props.gameName }),
  })
  const { isSuccess, isError } = useStartIntercomConversationQuery(intercomConversationData, { skip: !sendingMessage })
  const open = Boolean(anchorEl)
  const id = open ? 'askanalyst-popover' : undefined

  useEffect(() => {
    setSendingMessage(false)
    if (isSuccess) {
      handleClose()
    }
    if (isError) {
      handleClose()
      dispatch(displaySnackBar({ message: t('common:general_error_message'), severity: 'error', open: true }))
    }
  }, [isSuccess, isError, dispatch, t])

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!hasAccessToAskAnalyst || !props.analyst.intercomId) {
    return null
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSend = () => {
    setSendingMessage(true)
  }

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setIntercomConversationData((previousState) => {
      previousState[event.target.name as keyof IntercomConversationQueryParams] = event.target.value
      return Object.assign({}, previousState)
    })
  }

  return (
    <>
      <GRTooltip content={t('common:ask_analyst_tooltip') as String}>
        <Button
          aria-describedby={id}
          variant={props.variant || 'contained'}
          className={classNames('AskAnalystButton-root', props.className)}
          startIcon={<ContactSupportIcon />}
          onClick={handleClick}
        >
          {t('common:ask_analyst')}
        </Button>
      </GRTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box m={2} maxWidth={550}>
          <Analyst name={props.analyst?.name} picture={props.analyst?.picture} title={props.analyst?.title} mb={2} />
          <Typography>
            <Box mb={2}>
              <Trans i18nKey="analysis:ask_analyst_confirm_text" values={{ analystName: props?.analyst.name, gameName: props?.gameName }} />
            </Box>
          </Typography>
          <TextField multiline rows={4} label={t('analysis:ask_analyst_write_question')} name="userMessage" fullWidth onChange={handleInput}></TextField>
          <DialogActions>
            <Button onClick={handleClose} disabled={sendingMessage}>
              <Trans i18nKey="common:cancel" />
            </Button>
            <LoadingButton variant="contained" endIcon={<SendIcon />} loadingPosition="end" loading={sendingMessage} onClick={handleSend}>
              <Trans i18nKey="common:send" />
            </LoadingButton>
          </DialogActions>
        </Box>
      </Popover>
    </>
  )
}

export default AskAnalystButton
