import { FC, ReactNode } from 'react'

import { Divider } from '@mui/material'

import './NewsCardSectionDivider.scss'

type NewsCardSectionDividerProps = {
  children?: ReactNode
}

export const NewsCardSectionDivider: FC<NewsCardSectionDividerProps> = ({ children }) => {
  return <Divider className="NewsCardSectionDivider">{children}</Divider>
}
