import { FC } from 'react'

import { UnifiedNewsEntry } from '../../../../types/UnifiedNewsEntry'
import NewsCardLink from '../../NewsCardLink/NewsCardLink'
import './NewsCardEditorialImage.scss'

type NewsCardEditorialImageProps = {
  newsEntry: UnifiedNewsEntry
  altText?: string
  isInternalUser?: boolean
}
export const NewsCardEditorialImage: FC<NewsCardEditorialImageProps> = ({ newsEntry, altText, isInternalUser }) => {
  return (
    <div className="NewsCardEditorialImage">
      {isInternalUser && <NewsCardLink newsEntry={newsEntry} sx={{ position: 'absolute' }} />}
      {newsEntry.feedEntry.entryData.image ? (
        <img src={newsEntry.feedEntry.entryData.image.url} alt={altText} className="NewsCardEditorialImage__image" />
      ) : null}
    </div>
  )
}
