import { FC } from 'react'
import { Trans } from 'react-i18next'

import { Grid, Typography } from '@mui/material'

import { GameIcon } from '../../../game'
import { TrackedGame } from '../../types/TrackedGame'

/**
 * Component that displays tracked games as a grid
 */
type TrackedGamesGridProps = {
  trackedGames?: TrackedGame[]
  onTrackedGameClick?: (trackedGame: TrackedGame) => void
}

export const TrackedGamesGrid: FC<TrackedGamesGridProps> = ({ trackedGames = [], onTrackedGameClick }) => {
  const trackedGamesWithPermission = trackedGames.filter((trackedGame) => trackedGame.permission)

  return (
    <Grid container spacing={1.5} wrap="wrap">
      {trackedGamesWithPermission.length === 0 ? (
        <Grid item container justifyContent="center" my={2}>
          <Typography variant="body1">
            <Trans i18nKey="common:no_games_found" />
          </Typography>
        </Grid>
      ) : (
        trackedGamesWithPermission.map((trackedGame) => {
          return (
            <Grid
              item
              key={trackedGame.game.id}
              className="LiveEventsEventDialog__OtherGamesTab__Game"
              onClick={() => onTrackedGameClick && onTrackedGameClick(trackedGame)}
            >
              <GameIcon
                gameName={trackedGame.game.resolvedName}
                src={trackedGame.game.getIcon()}
                appType={trackedGame.game.appType}
                gamePlatforms={trackedGame.game.platforms}
              />
            </Grid>
          )
        })
      )}
    </Grid>
  )
}
