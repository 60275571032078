import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

import { useAppDispatch } from '../../../../hooks/storeHooks'
import { useSearchParams } from '../../../../hooks/useSearchParams'
import analyticsService from '../../../../services/AnalyticsService'
import { useAnalystReviewsAndCommentsAccessCheck, useGameUpdateImpactsAccessCheck } from '../../../account/hooks/roleHooks'
import { useCurrentMarket } from '../../../markets'
import { displaySnackBar } from '../../../snackbar'
import { useUpdateImpacts } from '../../hooks/useUpdateImpacts'
import { mapper } from '../../mappers/URLFilterMapper'
import { ColumnFilterValue } from '../../types/ColumnFilter'
import { TableRowUpdateImpact } from '../../types/TableRowUpdateImpact'
import { UpdateImpactRowFilters } from '../../types/UpdateImpactRowFilters'
import { UpdateImpactSearchParams } from '../../types/UpdateImpactSearchParams'
import { UpdateImpactFilters } from '../UpdateImpactFilters/UpdateImpactFilters'
import { UpdateImpactsTable } from '../UpdateImpactsTable/UpdateImpactsTable'

/**
 * Container component for update impacts table
 */
type UpdateImpactsTableContainerProps = {
  onFilterChange?: (filters: UpdateImpactRowFilters) => void
  initialFilters?: UpdateImpactRowFilters
  showSubgenrePicker?: boolean
  showTimeSinceUpdatedSelector?: boolean
  maxRows?: number
}

export const UpdateImpactsTableContainer: FC<UpdateImpactsTableContainerProps> = ({
  initialFilters,
  showSubgenrePicker,
  showTimeSinceUpdatedSelector,
  maxRows,
  onFilterChange,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [selectedSubgenre, setSelectedSubgenre] = useState({})

  const { parsedParams, setSearchParams } = useSearchParams<UpdateImpactSearchParams, UpdateImpactRowFilters>(mapper)

  const hasUpdateImpactsAccessRights = useGameUpdateImpactsAccessCheck()
  const hasAnalystReviewsAndCommentsAccessRights = useAnalystReviewsAndCommentsAccessCheck()
  const { currentMarketIso } = useCurrentMarket()

  const filtersWithSelectedSubgenres = useMemo(() => {
    return {
      ...initialFilters,
      ...parsedParams,
      subgenres: initialFilters?.subgenres || parsedParams.subgenres || selectedSubgenre,
      versionTags: initialFilters?.versionTags || parsedParams.versionTags,
    }
  }, [initialFilters, parsedParams, selectedSubgenre])

  // parameters used for the actual backend query
  const updateImpactsQueryParams = useMemo(
    () => ({
      marketIso: currentMarketIso,
      timeSinceUpdated: parsedParams.timeSinceUpdated,
    }),
    [currentMarketIso, parsedParams.timeSinceUpdated]
  )

  const { updateImpactsMappedForTable, isLoading, isFetching, error } = useUpdateImpacts(updateImpactsQueryParams)

  useEffect(() => {
    if (error) {
      dispatch(displaySnackBar({ message: t('common:general_error_message'), severity: 'error', open: true }))
    }
  }, [dispatch, t, error])

  const handleColumnFilterChange = useCallback(
    ({ name, value }: ColumnFilterValue) => {
      setSearchParams((currentParams) => ({
        ...currentParams,
        [name]: value,
      }))
    },
    [setSearchParams]
  )

  const handleFilterChange = useCallback(
    (changedFilters: UpdateImpactRowFilters) => {
      analyticsService.trackEvent('Game Update Impacts: Changed Shown Data', { data: { filters: changedFilters } })
      if (onFilterChange) {
        onFilterChange(changedFilters as UpdateImpactRowFilters)
      }

      setSearchParams((currentParams) => ({ ...currentParams, ...changedFilters }))
    },
    [onFilterChange, setSearchParams]
  )

  const handleOpenUpdateClicked = useCallback(
    (update: TableRowUpdateImpact) => {
      analyticsService.trackEvent('Game Update Impacts: Show Update', {
        data: {
          gameId: update.gameId,
          gameName: update.gameName,
          version: update.version,
        },
      })
      navigate({
        pathname: `${location.pathname}/gameupdate/${update.gameId}/${update.version}/${currentMarketIso}`,
        search: location.search,
      })
    },
    [navigate, location.pathname, location.search, currentMarketIso]
  )

  return (
    <>
      <UpdateImpactFilters
        moduleId="Update Impacts"
        filters={parsedParams}
        onChange={handleFilterChange}
        showSubgenrePicker={showSubgenrePicker}
        showComparisonIntervalSelector
        showTimeSinceUpdatedSelector={showTimeSinceUpdatedSelector}
        showVersionTagSelector={hasUpdateImpactsAccessRights}
        setSelectedSubgenres={setSelectedSubgenre}
      />
      <UpdateImpactsTable
        rows={updateImpactsMappedForTable}
        maxRows={maxRows}
        filters={filtersWithSelectedSubgenres}
        onColumnFilterChange={handleColumnFilterChange}
        isLoading={isLoading || isFetching}
        tableConfig={{
          impactDataUnlocked: true,
          showGameColumn: true,
          showVersionTags: true,
          showFeaturesColumn: true,
          showOpenUpdateColumn: true,
          showAnalystCommentIndicator: true,
          analystReviewsAndCommentsUnlocked: hasAnalystReviewsAndCommentsAccessRights,
        }}
        onOpenUpdateClick={handleOpenUpdateClicked}
      />
    </>
  )
}
