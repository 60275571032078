import classNames from 'classnames'
import { FC, memo } from 'react'

import { RevenueAndDownloadEstimates } from '../../../estimates/services/RevenueAndDownloadEstimates'
import { CellValue } from '../CellValue/CellValue'
import styles from './TrendIndicator.module.scss'

type TrendIndicatorProps = {
  estimate: RevenueAndDownloadEstimates
}

/**
 * Component for displaying trend as percentage value. Trend direction indicated with colors.
 */
export const TrendIndicator: FC<TrendIndicatorProps> = memo(({ estimate }) => {
  const classes = classNames({
    [styles.positive]: estimate.value > 0,
    [styles.negative]: estimate.value < 0,
  })
  return (
    <CellValue value={estimate.value} emptyValueIndicator={''}>
      <span className={classes}>{formatPercent(estimate.value)}</span>
    </CellValue>
  )
})

const formatPercent = (value: number): string => {
  return new Intl.NumberFormat('us', { style: 'percent', signDisplay: !value ? 'never' : 'always', maximumFractionDigits: 1 }).format(value)
}
