import querystring from 'query-string'

import { CompareGameTabSelection } from '../../../pages/CompareGamesPage/CompareGamesPage'
import { GameWithMarketIso } from '../../game-search/components/GameSearchDialog/GameSearchDialog'

export const buildCompareGameLink = (gamesWithMarketIso: GameWithMarketIso, force?: boolean) => {
  return querystring.stringifyUrl({
    url: `/compare-games/${CompareGameTabSelection.FEATURES}`,
    query: {
      gamesWithMarketIso: JSON.stringify(gamesWithMarketIso),
      force,
    },
  })
}
