import React from 'react'

import { OpenInNew } from '@mui/icons-material'
import { Box, Button, CardHeader, Divider, Typography } from '@mui/material'

import analyticsService from '../../../../services/AnalyticsService'

export enum GenreEssentialSection {
  implementationExample = 'implementation_examples',
  ftueVideos = 'ftue-videos',
  gameUpdateImpacts = 'game-update-impacts',
  marketExplorer = 'market-explorer',
  marketShare = 'market-share',
}

/**
 * GenreEssentialCardHeader
 */
interface GenreEssentialCardHeaderProps {
  section: GenreEssentialSection
  onClick?: () => void
  title: string
  description: string
  buttonLabel?: string
}
const GenreEssentialCardHeader: React.FC<GenreEssentialCardHeaderProps> = ({ section, onClick, title, buttonLabel, description }) => {
  return (
    <>
      <CardHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h3">{title}</Typography>
            {buttonLabel && onClick && (
              <Button
                onClick={() => {
                  analyticsService.trackEvent(`Genre Essentials Clicked: ${section}`)
                  onClick()
                }}
                color="primary"
                variant="outlined"
                size="small"
              >
                <Typography sx={{ mr: 1 }}>{buttonLabel}</Typography>
                <OpenInNew fontSize="small" />
              </Button>
            )}
          </Box>
        }
        subheader={<span>{description}</span>}
      ></CardHeader>
      <Divider />
    </>
  )
}

export default GenreEssentialCardHeader
