import { useState, useCallback, ChangeEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import EditIcon from '@mui/icons-material/Edit'
import { Button, Chip, Menu, Divider, Typography, ListItem, FormControlLabel, Checkbox, Box } from '@mui/material'

import { GRTableColumn } from '../GRTable'

/**
 * Component for rendering column editing menu for GRTable
 */

type GRTableColumnSelectorProps<ColumnIdType, CustomPropsType> = {
  columns?: GRTableColumn<any, CustomPropsType, ColumnIdType>[]
  selectedColumns?: ColumnIdType[]
  customTableProps?: CustomPropsType
  onChange?: (columnIds: ColumnIdType[]) => void
  onConfirm?: (columnIds: ColumnIdType[]) => void
}

export const GRTableColumnSelector = <ColumnIdType extends string, CustomPropsType extends object>({
  columns,
  selectedColumns: intialSelectedColumns = [],
  customTableProps,
  onChange,
  onConfirm,
}: GRTableColumnSelectorProps<ColumnIdType, CustomPropsType>) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectedColumns, setSelectedColumns] = useState<ColumnIdType[]>(intialSelectedColumns)

  const handleChangeSelection = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let newSelectedColumns = selectedColumns
      const selection = event.target.name as unknown as ColumnIdType
      if (selectedColumns.includes(selection)) {
        newSelectedColumns = selectedColumns.filter((columnId) => columnId !== selection)
      } else {
        newSelectedColumns = [...selectedColumns, selection]
      }

      setSelectedColumns(newSelectedColumns)
      onChange && onChange(newSelectedColumns)
    },
    [onChange, selectedColumns]
  )

  const handleConfirmSelection = useCallback(() => {
    setAnchorEl(null)
    onConfirm && onConfirm(selectedColumns)
  }, [onConfirm, selectedColumns])

  const handleMenuClose = useCallback(() => {
    // reset the changes in selection if it's not confirmed
    if (onConfirm) {
      setSelectedColumns(intialSelectedColumns)
    }

    setAnchorEl(null)
  }, [intialSelectedColumns, onConfirm])

  // filter out the columns which are hidden by column definition
  const selectableColumns = useMemo(() => columns?.filter((col) => !isColumnHidden(col, customTableProps) && !!col.id), [columns, customTableProps])
  const counter = selectedColumns?.length + ' / ' + selectableColumns?.length

  return (
    <Box className="GRTableColumnSelector">
      <Button
        startIcon={<EditIcon />}
        endIcon={<Chip size="small" label={counter} sx={{ m: 0 }} />}
        variant="outlined"
        color="secondary"
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
      >
        {t('turbo-search:button_edit_columns')}
      </Button>
      {anchorEl && (
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClick={(evt) => evt.stopPropagation()}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <ListItem secondaryAction={<Chip size="small" label={counter} sx={{ m: 0 }} />}>
            <Typography variant="h3">{t('turbo-search:button_edit_columns')}</Typography>
          </ListItem>
          <Divider sx={{ my: 1 }} />
          {selectableColumns?.map((column, index) =>
            !column.id ? undefined : (
              <ListItem key={index} dense sx={{ py: '2px' }}>
                <FormControlLabel
                  control={<Checkbox size="small" checked={selectedColumns.includes(column.id)} onChange={handleChangeSelection} />}
                  label={getLabelValue(column)}
                  name={column.id}
                />
              </ListItem>
            )
          )}
          <Divider sx={{ my: 1 }} />
          <ListItem>
            <Button variant="contained" color="success" onClick={handleConfirmSelection} fullWidth>
              {t(onConfirm ? 'common:confirm' : 'common:done')}
            </Button>
          </ListItem>
        </Menu>
      )}
    </Box>
  )
}

const isColumnHidden = <CustomTableProps extends object>(column: GRTableColumn<any, CustomTableProps, any>, customTableProps?: CustomTableProps) => {
  if (typeof column.hidden === 'function') {
    return column.hidden({ col: column, customTableProps })
  } else {
    return !!column.hidden
  }
}

const getLabelValue = <CustomTableProps extends object>(column: GRTableColumn<any, CustomTableProps, any>, customTableProps?: CustomTableProps) => {
  if (column.columnSelectorLabelAccessor) {
    if (typeof column.columnSelectorLabelAccessor === 'function') {
      return column.columnSelectorLabelAccessor({ col: column, customTableProps })
    } else {
      return column.columnSelectorLabelAccessor
    }
  }

  if (typeof column.labelAccessor === 'function') {
    return column.labelAccessor({ col: column, customTableProps })
  } else {
    return column.labelAccessor || ''
  }
}
