import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Download } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'

import analyticsService from '../../services/AnalyticsService'
import GRDialogTitle from '../GRDialogTitle/GRDialogTitle'

type ExportDataDialogProps = {
  component: string
  formats?: BookType[]
  onChooseFormat?: (format: BookType) => void
  onClose: () => void
}

const defaultFormats: BookType[] = ['csv', 'xlsx']

/**
 * A modal for selecting the output format of a data export.
 *
 * @param formats optional, defaults to 'csv' and 'xlsx'
 * @param onClose when modal closed
 * @param onChooseFormat callback for decision
 */
export const ExportDataDialog: React.FC<ExportDataDialogProps> = ({ formats = defaultFormats, onClose, onChooseFormat, component }) => {
  const { t } = useTranslation()

  const handleSelectFormat: MouseEventHandler<HTMLButtonElement> = (event) => {
    const format = event.currentTarget.id as BookType
    analyticsService.trackEvent('Export Data', {
      data: {
        format,
        origin: component,
      },
    })

    onChooseFormat && onChooseFormat(format)
    onClose()
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <GRDialogTitle>{t('export-csv:export_modal_title')}</GRDialogTitle>
      <DialogContent>
        <Box mt={2} mx={4}>
          <Typography>{t('export-csv:export_modal_text')}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {formats.map((format) => (
          <Button key={format} id={format} variant="contained" startIcon={<Download />} onClick={handleSelectFormat}>
            {t(`export-csv:export_button_${format}`)}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  )
}
