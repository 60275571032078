import classNames from 'classnames'
import React, { FC } from 'react'

import { AddCircle } from '@mui/icons-material'
import { Grid } from '@mui/material'

import './AddArea.scss'

/**
 * A component for rendering a larger area that indicates adding a new item when clicked.
 */

type AddAreaProps = {
  onClick: () => void
  label?: string
  disabled?: boolean
}
const AddArea: FC<AddAreaProps> = ({ label, onClick, disabled }) => {
  return (
    <Grid
      container
      alignContent="center"
      wrap="wrap"
      justifyContent="center"
      onClick={onClick}
      className={classNames('AddArea', { AddArea__disabled: disabled })}
      style={{ height: '100%' }}
    >
      <Grid item mr={1}>
        <AddCircle />
      </Grid>
      <Grid item>{label}</Grid>
    </Grid>
  )
}

export default AddArea
