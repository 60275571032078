import React from 'react'
import { useTranslation } from 'react-i18next'

import { Share } from '@mui/icons-material'
import { Button, ButtonProps } from '@mui/material'

import { displaySnackBar } from '../../features/snackbar'
import { useAppDispatch } from '../../hooks/storeHooks'
import analyticsService from '../../services/AnalyticsService'
import { AnalyticsProviders } from '../../types/IAnalyticsProvider'
import './ShareUrlButton.scss'

type ShareUrlButtonProps = ButtonProps & {
  trackingId?: string
  label?: string
}

const ShareUrlButton: React.FC<ShareUrlButtonProps> = ({ label, trackingId, ...buttonProps }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const buttonLabel = !label ? t('common:share_button_label') : label

  const copyUrlToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(window.location.href)
      const NOTIFY_TEXT = t('common:notify_url_copied_clipboard')
      dispatch(displaySnackBar({ message: NOTIFY_TEXT, severity: 'success', open: true }))
    } else {
      dispatch(displaySnackBar({ message: t('common:notify_url_not_copied_clipboard'), severity: 'error', open: true }))
    }

    if (trackingId) {
      analyticsService.trackEvent('Share Button', {
        data: { shareId: trackingId },
        serviceToExclude: [AnalyticsProviders.intercom, AnalyticsProviders.hubspot],
      })
    }
  }

  return (
    <Button {...buttonProps} variant="contained" color="primary" startIcon={<Share />} onClick={copyUrlToClipboard} className="ShareUrlButton">
      {buttonLabel}
    </Button>
  )
}

export default ShareUrlButton
