import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { GameEstimates } from '../../estimates'
import { TableRowUpdateImpact } from '../../update-impacts/types/TableRowUpdateImpact'

export const useOpenUpdateModal = (gameEstimates: GameEstimates[]) => {
  const location = useLocation()
  const navigate = useNavigate()

  const handleOpenUpdateClicked = useCallback(
    (update: TableRowUpdateImpact) => {
      const selectedMarketIso = gameEstimates?.find(({ game }) => game.id === update.gameId)?.marketIso
      navigate(`${location.pathname}/gameupdate/${update.gameId}/${update.version}/${selectedMarketIso}`)
    },
    [gameEstimates, navigate, location.pathname]
  )
  return { handleOpenUpdateClicked }
}
