import { t } from 'i18next'
import { FC } from 'react'

import { Box } from '@mui/material'

import { SoftLaunchMarket } from '../../../../../../types/SoftLaunchMarket'
import { SoftLaunchMarketList } from '../../../../../soft-launch'
import { NewsCardSectionDivider } from '../../NewsCardSectionDivider/NewsCardSectionDivider'
import './NewsCardContentSofLaunchMarketsSection.scss'

type NewsCardContentSofLaunchMarketsSectionProps = {
  softLaunchMarkets: SoftLaunchMarket[]
}

export const NewsCardContentSofLaunchMarketsSection: FC<NewsCardContentSofLaunchMarketsSectionProps> = ({ softLaunchMarkets }) => {
  return (
    <Box className="NewsCardContentSofLaunchMarketsSection" mb={2}>
      <NewsCardSectionDivider>{t('common:soft_launch_markets_label')}</NewsCardSectionDivider>
      <div className="NewsCardContentSofLaunchMarketsSection__list">
        <SoftLaunchMarketList softLaunchMarkets={softLaunchMarkets} />
      </div>
    </Box>
  )
}
