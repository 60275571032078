import { useGetUserProfileQuery } from '../../../api/combined'
import { UserLanguage } from '../types/User'

/**
 * Resolve current user language
 */

export const useCurrentUserLanguage = (): UserLanguage => {
  const { language } = useGetUserProfileQuery(undefined, { selectFromResult: ({ data }) => ({ language: data?.language }) })

  return language || 'en'
}
