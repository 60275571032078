import { createRoot } from 'react-dom/client'

import { StyledEngineProvider, ThemeProvider } from '@mui/material'

import './config'

import App from './App'
import { StoreProvider } from './StoreProvider'
import './index.scss'
import reportWebVitals from './reportWebVitals'
import { theme } from './themes/theme'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <StoreProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </StoreProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
