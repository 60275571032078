import { useMemo } from 'react'

import { usePerformanceChartActiveUserData } from '../../active-users/hooks/usePerformanceChartActiveUserData'
import { useGameEstimateData, useEstimatesList } from '../../estimates/hooks/estimateHooks'
import { EstimatePlatformType } from '../../estimates/types/EstimatePlatformType'
import { GranularityValue } from '../../revenue-and-downloads/types/Filters'
import { useTopGrossingGameRanks } from '../../top-game/hooks/topGrossingGameHooks'

const defaultPlatformTypes = [EstimatePlatformType.Phone, EstimatePlatformType.Tablet]

/**
 * Hook that collects all the necessary data for performance chart
 */

type UseGameUpdatePerformanceDataHookParams = {
  appId: number
  marketIso: string
  rollingDays: number
  granularity?: GranularityValue
  platformTypes?: EstimatePlatformType[]
}

export const useGameUpdatePerformanceData = ({
  appId,
  marketIso,
  platformTypes = defaultPlatformTypes,
  rollingDays,
  granularity = GranularityValue.Day,
}: UseGameUpdatePerformanceDataHookParams) => {
  const appIds = useMemo(() => {
    return [appId]
  }, [appId])

  const markets = useMemo(() => {
    return [marketIso]
  }, [marketIso])

  const filters = useMemo(() => {
    return {
      rollingDays,
      platformTypes,
      marketIsos: markets,
      granularity,
    }
  }, [rollingDays, platformTypes, markets, granularity])

  const { estimatesList, isLoading: isEstimatesLoading, error: estimatesError } = useGameEstimateData({ appIds, markets, platformTypes })
  const estimates = useEstimatesList({ data: estimatesList, filters })
  const marketEstimates = estimates.markets.find((estimate) => estimate?.marketIso === marketIso)
  const { topGrossingRanks, freeRanks, isLoading: isRanksLoading, error: rankError } = useTopGrossingGameRanks({ appId, marketIso, granularity })
  const {
    dailyActiveUsers,
    monthlyActiveUsers,
    error: dauAveragesError,
    isLoading: isDauAveragesLoading,
  } = usePerformanceChartActiveUserData({ appId, granularity })

  const [minDate, maxDate] = useMemo(() => {
    const { tsMin = Number.MAX_SAFE_INTEGER, tsMax = 0 } = marketEstimates || {}
    const dates = topGrossingRanks
      .map((tgr) => tgr.ts)
      .concat(freeRanks.map((fr) => fr.ts))
      .concat(dailyActiveUsers.map((dau) => dau.date))
      .concat(monthlyActiveUsers.map((mau) => mau.date))
      .concat([tsMin, tsMax])

    return [Math.min(...dates), Math.max(...dates)]
  }, [dailyActiveUsers, freeRanks, marketEstimates, monthlyActiveUsers, topGrossingRanks])

  return {
    estimates: marketEstimates?.estimates || [],
    topGrossingRanks,
    freeRanks,
    dailyActiveUsers,
    monthlyActiveUsers,
    minDate,
    maxDate,
    isLoading: isEstimatesLoading || isRanksLoading || isDauAveragesLoading,
    error: estimatesError || rankError || dauAveragesError,
  }
}
