import { Category, Feature } from '../../../api/core'
import { Analysis } from './../../analysis/types/Analysis'

export const getCategoryEffect = (category: Category, analysis: Analysis, feature?: Feature): number => {
  if (analysis && analysis.topFeatures && analysis.topFeatures.hasOwnProperty(category.id)) {
    let categoryEffects = analysis.topFeatures[category.id]

    if (feature) {
      let featureEffect = categoryEffects.find((effect) => effect.featureId === feature.id)

      if (featureEffect) {
        return +Math.max(featureEffect.gpsEffect, 0).toFixed(2)
      }
      return 0
    }

    let totalEffect = 0
    categoryEffects.forEach((effect) => {
      totalEffect += Math.max(effect.gpsEffect, 0)
    })
    return +totalEffect.toFixed(2)
  }

  return 0
}
