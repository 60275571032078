import { formatDuration, intervalToDuration, endOfDay, startOfDay } from 'date-fns'
import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TableContainer, Card, Link } from '@mui/material'

import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { useDateTimeFormatter } from '../../hooks/useDateTimeFormatter'
import { EventDialogTab } from '../../hooks/useEventDialogTabs'
import { useLiveEventModal } from '../../hooks/useLiveEventModal'
import { EventInstance } from '../../types/EventInstance'
import { EventHighlightButton } from '../EventHighlightButton/EventHighlightButton'
import { EventTagGroupIndicator } from '../EventTagGroupIndicator/EventTagGroupIndicator'

type LiveEventInstanceTableProps = {
  instances: EventInstance[]
  isLoading?: boolean
  children?: ReactNode
}

const dateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
}

export const LiveEventInstanceTable: FC<LiveEventInstanceTableProps> = ({ instances, isLoading, children }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const dateFormatter = useDateTimeFormatter(dateFormat)
  const { showModal: showEventModal } = useLiveEventModal()

  const eventInstanceColumns: GRTableColumn<EventInstance, typeof customTableProps>[] = useMemo(
    () => [
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:event_instance_table_column_event_type'),
            accessor: ({ row, customTableProps }) => (
              <>
                <EventTagGroupIndicator colorHex={row.colorHex} />
                <Link
                  underline="none"
                  sx={{ color: (theme) => theme.palette.text.primary, '&:hover': { color: (theme) => theme.palette.primary.main, cursor: 'pointer' } }}
                  onClick={() =>
                    customTableProps?.showEventModal({
                      trackedGameId: row.gameId,
                      eventTypeId: row.eventTypeId,
                      tab: EventDialogTab.AllEvents,
                    })
                  }
                >
                  {row.eventTypeName}
                </Link>
              </>
            ),
            cellProps: { align: 'left' },
            sortable: true,
            sortAccessor: ({ row }) => row.eventTypeName,
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:event_instance_table_column_event_name'),
            accessor: ({ row, customTableProps }) => (
              <Link
                underline="none"
                sx={{ color: (theme) => theme.palette.text.primary, '&:hover': { color: (theme) => theme.palette.primary.main, cursor: 'pointer' } }}
                onClick={() =>
                  customTableProps?.showEventModal({
                    trackedGameId: row.gameId,
                    eventTypeId: row.eventTypeId,
                    eventId: row.liveEventId,
                    tab: EventDialogTab.Description,
                  })
                }
              >
                {row.name}
              </Link>
            ),
            cellProps: { align: 'left' },
            sortable: true,
            sortAccessor: ({ row }) => row.name,
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:duration_start'),
            accessor: ({ row }) => dateFormatter.format(row.start),
            cellProps: { align: 'left' },
            sortable: true,
            sortOrder: SortOrder.DESC,
            sortAccessor: ({ row }) => row.start,
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:duration_end'),
            accessor: ({ row }) => dateFormatter.format(row.end),
            cellProps: { align: 'left' },
            sortable: true,
            sortAccessor: ({ row }) => row.end,
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:duration'),
            accessor: ({ row }) => {
              return formatDuration(intervalToDuration(row.duration), { format: ['months', 'days', 'hours'] })
            },
            cellProps: { align: 'left' },
            sortable: true,
            sortAccessor: ({ row }) => endOfDay(row.end).getTime() - startOfDay(row.start).getTime(),
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: t('live-events:event_instance_table_column_instance_count'),
            accessor: ({ row, customTableProps }) => row.instanceCount,
            cellProps: { align: 'center' },
            headerCellProps: { width: 100 },
            sortable: true,
            sortAccessor: ({ row }) => row.instanceCount,
          },
        ],
      },
      {
        labelAccessor: '',
        columns: [
          {
            labelAccessor: '',
            accessor: ({ row }) => (
              <EventHighlightButton
                variant="fab"
                eventId={row.liveEventId}
                eventType={row.eventTypeId}
                eventStartTime={row.start}
                eventEndTime={row.end}
                trackedGameId={row.gameId}
              />
            ),
            cellProps: { align: 'center' },
            sortable: false,
            sortAccessor: ({ row }) => endOfDay(row.end).getTime() - startOfDay(row.start).getTime(),
          },
        ],
      },
    ],
    [dateFormatter, t]
  )

  const customTableProps = useMemo(
    () => ({
      showEventModal,
    }),
    [showEventModal]
  )

  const [columns, setColumns] = useState<GRTableColumn<EventInstance, typeof customTableProps>[]>(eventInstanceColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<EventInstance, typeof customTableProps>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <TableContainer ref={containerRef} component={Card}>
      <GRTable
        rows={instances || []}
        columns={columns}
        onColumnsUpdated={handleColumnsUpdate}
        isLoading={isLoading}
        rowIdKey="id"
        scroller={containerRef}
        customProps={customTableProps}
        noRowsLabel={t('compare:no_results_found')}
      />
    </TableContainer>
  )
}
