import { t } from 'i18next'
import { FC, ReactNode, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Box, Divider, Grid, TextField } from '@mui/material'

import { includesIgnoreCase } from '../../../../../helpers/string'
import { useRequiredParams } from '../../../../../hooks/useRequiredParams'
import { useGetTrackedGameEventsQuery } from '../../../../api/internal'
import { InternalTrackingEventEvent } from '../../../../types/InternalTrackingEvent'
import { EventListTable } from './EventListTable/EventListTable'
import { gameEventTypesSelectMenuAllValue, GameEventTypesSelectMenu } from './GameEventTypesSelectMenu/GameEventTypesSelectMenu'
import './TrackedGamesQuickSearchEvent.scss'

/**
 * Component for adding events to a tracked game
 */
type TrackedGamesQuickSearchEventProps = {
  trackedGameId?: string
  renderActions?: (event: InternalTrackingEventEvent) => ReactNode
}

export type QuickSearchFilters = {
  eventName: string
  eventTypeId: string
}

export const TrackedGamesQuickSearchEvent: FC<TrackedGamesQuickSearchEventProps> = ({ renderActions, trackedGameId: trackedGameIdProp }) => {
  const { trackedGameId: trackedGameIdRouteParam } = useRequiredParams<{ trackedGameId: string }>()
  const trackedGameId = (trackedGameIdProp || trackedGameIdRouteParam) as string
  const trackedGameEventsQuery = useGetTrackedGameEventsQuery(trackedGameId)
  const { watch, control } = useFormContext<QuickSearchFilters>()
  const eventName = watch('eventName')
  const eventTypeId = watch('eventTypeId')

  const filteredEvents = useMemo(() => {
    const allEvents = trackedGameEventsQuery.data || []
    return allEvents
      .filter((event) => eventTypeId !== gameEventTypesSelectMenuAllValue || eventName)
      .filter((event) => includesIgnoreCase(event.name, eventName))
      .filter((event) => event.typeId === eventTypeId || eventTypeId === gameEventTypesSelectMenuAllValue)
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [eventName, eventTypeId, trackedGameEventsQuery.data])

  const noRowsLabel = eventTypeId === gameEventTypesSelectMenuAllValue && !eventName ? t('internal-live-events:quick_add_event_help_text') : null

  return (
    <Box className="TrackedGamesQuickSearchEvent">
      <form>
        <Grid container spacing={2} wrap="wrap" p={2}>
          <Grid item sm={8} xs={12}>
            <Controller
              name="eventName"
              control={control}
              render={({ field }) => {
                return <TextField {...field} size="small" label={t('internal-live-events:event_name_input_field_label')} fullWidth />
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <Controller
              name="eventTypeId"
              control={control}
              render={({ field }) => {
                return <GameEventTypesSelectMenu trackedGameId={trackedGameId} value={field.value} onChange={field.onChange} />
              }}
            />
          </Grid>
        </Grid>
      </form>
      <Divider />
      <EventListTable events={filteredEvents} noRowsLabel={noRowsLabel} renderActions={renderActions} />
    </Box>
  )
}
