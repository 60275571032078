import { FC, memo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Block } from '@mui/icons-material'
import { Box } from '@mui/material'

import { lightColorHex } from '../../themes/theme'
import { GRTooltip } from '../GRTooltip/GRTooltip'
import Powerscore from '../Powerscore/Powerscore'

type PowerScoreIndicatorProps = {
  value: number
  children?: ReactNode
}

export const PowerScoreIndicator: FC<PowerScoreIndicatorProps> = memo(({ value }) => {
  const { t } = useTranslation()
  return (
    <Box className="PowerScoreIndicator">
      {value ? (
        <GRTooltip content={t('common:tooltip_gps_description')}>
          <Powerscore powerscore={value} fontSize={11} size={24} />
        </GRTooltip>
      ) : (
        <GRTooltip content={t('common:analysis_not_available')}>
          <Block sx={{ fontSize: '18px', fontWeight: 'bold', color: lightColorHex }} />
        </GRTooltip>
      )}
    </Box>
  )
})
