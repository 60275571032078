import { FC, ReactNode, MouseEvent } from 'react'
import { Trans } from 'react-i18next'

import { SxProps, Theme, ToggleButton, ToggleButtonGroup } from '@mui/material'

import { NavigationMode } from '../../../types/NavigationMode'

type NavigationModeTogglerProps = {
  value: NavigationMode
  onChange: (value: NavigationMode) => void
  children?: ReactNode
}

export const NavigationModeToggler: FC<NavigationModeTogglerProps> = ({ value, onChange, children }) => {
  const handleChange = (event: MouseEvent<HTMLElement>, newValue: NavigationMode) => {
    if (newValue !== null) {
      onChange(newValue)
    }
  }

  const itemSx: SxProps<Theme> = {
    flex: '1 1 100%',
    borderColor: (theme) => theme.palette.primary.main,
    '&.Mui-selected': {
      color: (theme) => theme.palette.primary.contrastText,
      backgroundColor: (theme) => theme.palette.primary.main,
      '&:hover': {
        backgroundColor: (theme) => theme.palette.primary.dark,
      },
    },
  }

  return (
    <ToggleButtonGroup value={value} onChange={handleChange} exclusive size="small" sx={{ width: '100%' }} className="NavigationModeToggler">
      <ToggleButton value={NavigationMode.App} sx={itemSx}>
        <Trans i18nKey="sidebar:mode_app" />
      </ToggleButton>
      <ToggleButton value={NavigationMode.Internal} sx={itemSx}>
        <Trans i18nKey="sidebar:mode_internal" />
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
