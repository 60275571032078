import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@mui/material'

import { SelectMenu } from '../../../../components/SelectMenu/SelectMenu'
import { PlotType } from '../../types/PlotType'

type PlotTypeSelectorProps = {
  value: PlotType
  onChange: (value: PlotType) => void
  disabled?: boolean
}

export const PlotTypeSelector: FC<PlotTypeSelectorProps> = ({ value, onChange, disabled }) => {
  const { t } = useTranslation()
  const options = useMemo(() => {
    return [
      {
        name: t('common:plot_type_line_stacked'),
        value: PlotType.Stacked,
      },
      {
        name: t('common:plot_type_stacked_cumulative'),
        value: PlotType.CumulativeStacked,
      },
      {
        name: t('common:plot_type_line'),
        value: PlotType.Line,
      },
      {
        name: t('common:plot_type_line_cumulative'),
        value: PlotType.CumulativeLine,
      },
    ]
  }, [t])

  return (
    <FormControl size="small" variant="outlined" sx={{ minWidth: 150 }} disabled={disabled}>
      <SelectMenu
        label={t('common:plot_type')}
        options={options}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        disabled={disabled}
      />
    </FormControl>
  )
}
