import { createSelector } from '@reduxjs/toolkit'

import { coreApi } from '../../../api/core'
import type { RootState } from '../../../store'
import { UserLanguage } from '../../account/types/User'

const marketsResult = (userLanguage: UserLanguage) => coreApi.endpoints.getMarkets.select({ userLanguage })

export const selectAllMarkets = (userLanguage: UserLanguage) => createSelector(marketsResult(userLanguage), (markets) => markets?.data ?? [])

export const selectAllMarketIsos = (userLanguage: UserLanguage) =>
  createSelector(selectAllMarkets(userLanguage), (markets) => markets.map((market) => market.iso))

export const selectMarketByIso = (userLanguage: UserLanguage) =>
  createSelector(
    selectAllMarkets(userLanguage),
    (state: RootState, iso: string) => iso,
    (markets, iso) => markets.find((market) => market.iso === iso)
  )

export const selectMarketByCaseInsensitiveIso = (userLanguage: UserLanguage) =>
  createSelector(
    selectAllMarkets(userLanguage),
    (state: RootState, iso: string | undefined) => iso,
    (markets, iso) => markets.find((market) => market.iso.toLowerCase() === iso?.toLowerCase())
  )

export const selectPublishedMarkets = (userLanguage: UserLanguage) =>
  createSelector(selectAllMarkets(userLanguage), (markets) => markets.filter((market) => market.published))
