export interface MongoDBObject {
  readonly id: string
  readonly createdAt: number
  readonly modifiedAt: number
  readonly createdBy: null
  readonly modifiedBy: null
}

export class MongoDBObject implements MongoDBObject {
  constructor(mongoDBObject: MongoDBObject) {
    Object.assign(this, mongoDBObject)
  }
}
