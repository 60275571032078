import { difference } from './../../../helpers/difference'

export type paramObj = {
  [key: string]: string[]
}
/**
 * shallowCompare compare 2 objects to check if they are identical. It's mainly used for comparing parsed params list of games to compare and user settings
 * @param firstObj
 * @param secondObj
 * @returns whether 2 objects is identical
 */
export const shallowCompare = (firstObj?: paramObj, secondObj?: paramObj): boolean => {
  if (!firstObj || !secondObj) return false

  if (Object.keys(firstObj).length !== Object.keys(secondObj).length) {
    return false
  }

  for (let i = 0; i < Object.keys(firstObj).length; i++) {
    const gameId = Object.keys(firstObj)[i]
    if (!Object.keys(secondObj).includes(gameId)) {
      return false
    }

    if (difference(firstObj[gameId], secondObj[gameId]).length) {
      return false
    }
  }

  return true
}
