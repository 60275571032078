import languageService from '../../../services/LanguageService'
import { UserLanguage } from '../../account/types/User'

export const getMarketName = (marketIso: string) => {
  const translation = languageService.getTranslation('markets', marketIso.toLowerCase())

  return translation ? translation : languageService.getTranslation('markets', marketIso.toUpperCase())
}

export const getMarketIsosByUserLanguage = (userLanguage: UserLanguage) => {
  const englishLanguageMarketIsos = ['us', 'gb', 'au', 'ca', 'nz', 'sg', 'fi', 'se', 'ph']
  const languageToMarketIsosMap = {
    en: englishLanguageMarketIsos,
    ja: ['jp', ...englishLanguageMarketIsos],
    zh: ['cn', ...englishLanguageMarketIsos],
  }

  return languageToMarketIsosMap[userLanguage]
}
