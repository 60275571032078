import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import SellOutlinedIcon from '@mui/icons-material/SellOutlined'
import { Box, Button, Grid, Typography } from '@mui/material'

import { ConceptTag, Keyword, Tag } from '../../../../../api/core'
import { conceptTagSearchResultFilter } from '../../../../implementation-examples/util/filters'
import { FeatureAndKeywordSearchResult } from '../../../types/types'

type ConceptListProps = {
  searchResult: FeatureAndKeywordSearchResult
  selection: ConceptTag[]
  selectedTag: Tag | null
  showScreenshotCounts?: boolean
  onSelectionChange: (selection: string[]) => void
  conceptTags?: ConceptTag[]
  keywords?: Keyword[]
}

export const ConceptList: FC<ConceptListProps> = ({ selection, searchResult, selectedTag, showScreenshotCounts, onSelectionChange, conceptTags, keywords }) => {
  const { t } = useTranslation()
  const searchFilter = conceptTagSearchResultFilter(true, selectedTag ? [selectedTag] : [], searchResult)

  const filteredConceptTags = useMemo(() => {
    if (!conceptTags) return []

    const filteredResult = conceptTags.filter(searchFilter)

    const result = [...filteredResult].sort((a, b) => {
      return a.name.localeCompare(b.name)
    })

    return result
  }, [conceptTags, searchFilter])

  if (!filteredConceptTags?.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
        <Typography>{t('feature-select-modal:no_granular_features_tags_found')}</Typography>
      </Box>
    )
  }

  const handleChangeSelection = (conceptTag: ConceptTag, isSelected: boolean) => {
    const updatedSelection = [...selection]

    if (isSelected) {
      // remove the concept tag from the selection
      const index = updatedSelection.findIndex((selectedConcept) => selectedConcept.id === conceptTag.id)
      updatedSelection.splice(index, 1)
    } else {
      // add the concept tag to the selection
      updatedSelection.push(conceptTag)
    }

    // update the selection
    onSelectionChange(updatedSelection.map((selectedConcept) => selectedConcept.id))
  }

  return (
    <Box className="FeatureList" mt={2} flexDirection="column">
      {filteredConceptTags.map((conceptTag) => {
        return (
          <Grid className="FeatureList__feature" key={conceptTag.id} container justifyContent="space-between" alignItems="center">
            <Grid item xs={6}>
              <Grid container alignItems="center">
                <SellOutlinedIcon sx={{ height: '25px', width: '25px', margin: '0 12px 0 8px' }} color="primary" />
                <span>{conceptTag.name}</span>
              </Grid>
            </Grid>

            {showScreenshotCounts && <Grid item>{t('implementations:label_examples_count_plural', { count: conceptTag.concept.screenshotIds.length })}</Grid>}

            <Grid item xs={3}>
              <ConceptSelect
                selectedConcepts={selection}
                conceptTag={conceptTag}
                onChange={(conceptTag, isSelected) => handleChangeSelection(conceptTag, isSelected)}
              />
            </Grid>
          </Grid>
        )
      })}
    </Box>
  )
}

type ConceptSelectProps = {
  conceptTag: ConceptTag
  selectedConcepts: ConceptTag[]
  onChange: (conceptTag: ConceptTag, isSelected: boolean) => void
}

const buttonColor = 'secondary'

export const ConceptSelect: FC<ConceptSelectProps> = ({ conceptTag, selectedConcepts, onChange }) => {
  const { t } = useTranslation()
  const isConceptSelected = selectedConcepts.some((selectedConcept) => selectedConcept.id === conceptTag.id)

  return (
    <Button
      color={buttonColor}
      variant={isConceptSelected ? 'contained' : 'outlined'}
      fullWidth
      onClick={() => {
        onChange(conceptTag, isConceptSelected)
      }}
    >
      {t(isConceptSelected ? 'common:selected' : 'common:select')}
    </Button>
  )
}
