import classNames from 'classnames'
import { t } from 'i18next'
import React, { ReactNode, useMemo, useState } from 'react'

import { Box, Card, Chip, Grid } from '@mui/material'

import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import OtherSubgenreGamesDialog from '../../../../components/OtherSubgenreGamesDialog/OtherSubgenreGamesDialog'
import Powerscore from '../../../../components/Powerscore/Powerscore'
import { useRoleCheck } from '../../../account/hooks/roleHooks'
import { RoleEnum } from '../../../account/types/RoleEnum'
import { useCurrentMarket } from '../../../markets'
import { isEmpty } from '../../../news/utils/utils'
import { PublisherGamesDialogLink } from '../../../publisher/components/PublisherGamesDialogLink/PublisherGamesDialogLink'
import { useMobileSize } from '../../../responsiveness/hooks/responsivenessHooks'
import { useIsGameOpenForEveryone } from '../../../settings'
import { GameAndAnalysis } from '../../types/GameAndAnalysis'
import GameIcon from '../GameIcon/GameIcon'
import './GameHeader.scss'

const gameHeaderActionsName = 'game-header-actions'
const gameHeaderContentName = 'game-header-content'

interface SubComponents {
  Actions: React.FC<GameHeaderActionsProps>
  Content: React.FC<GameHeaderContentProps>
}

/**
 * GameHeader Show game generic information
 */
interface GameHeaderProps {
  children?: ReactNode
  gameAndAnalysis: GameAndAnalysis
  isLoading: boolean
  stickyContent?: boolean
}
const GameHeader: React.FC<GameHeaderProps> & SubComponents = ({ children, gameAndAnalysis, stickyContent }) => {
  const { currentMarketIso: mainMarketIso } = useCurrentMarket()
  const isGameOpenForEveryone = useIsGameOpenForEveryone(gameAndAnalysis?.game)
  const hideSubgenre = useRoleCheck(RoleEnum.hide_subgenres)
  const [otherSubgenreGamesDialogOpen, setOtherSubgenreGamesDialogOpen] = useState(false)
  const mobileSize = useMobileSize()
  const isGameSoftLaunch = () => {
    if (!gameAndAnalysis) {
      return false
    }

    return gameAndAnalysis.game.isSoftLaunch()
  }

  const gameHeaderActions = React.Children.map(children, (child: any) => {
    if (child?.type?.displayName !== gameHeaderActionsName) return null
    return child
  })

  const gameHeaderContent = React.Children.map(children, (child: any) => {
    if (child?.type?.displayName !== gameHeaderContentName) return null
    return child
  })
  const withContent = gameHeaderContent && gameHeaderContent.length !== 0

  const powerscore = useMemo(() => {
    if (!!gameAndAnalysis.game && gameAndAnalysis.game.gpsPerMarket[mainMarketIso] > 0) {
      return gameAndAnalysis.game.gpsPerMarket[mainMarketIso]
    }

    if (!!gameAndAnalysis.analysis && gameAndAnalysis.analysis.powerScore > 0) {
      return gameAndAnalysis.analysis.powerScore
    }

    return 0
  }, [gameAndAnalysis.analysis, gameAndAnalysis.game, mainMarketIso])

  return (
    <>
      <Card className={classNames('GameHeader__header', { withContent })} sx={{ padding: 2 }}>
        {isGameOpenForEveryone && <div className="GameHeader__ribbon">{t('common:free')}</div>}
        {isGameSoftLaunch() && <div className="GameHeader__ribbon GameHeader__ribbon--soft-launch">{t('game-header:soft_launch')}</div>}
        <Grid container spacing={2} alignItems="center" wrap="wrap">
          <Grid item xs={12} md={12} lg={6}>
            <Grid container spacing={2} wrap="wrap">
              <Grid item>
                <GameIcon
                  src={gameAndAnalysis.game.icons || gameAndAnalysis.game.icon}
                  gameName={gameAndAnalysis ? gameAndAnalysis?.game.resolvedName : ''}
                  size="large"
                ></GameIcon>
              </Grid>
              <Grid item lg={7}>
                <div className="GameHeader__game-details__name">
                  <GRTooltip
                    content={
                      !isEmpty(gameAndAnalysis.game.resolvedName)
                        ? gameAndAnalysis.game.resolvedName
                        : t('common:game_by', {
                            createdBy: gameAndAnalysis.game.createdBy,
                          })
                    }
                  >
                    {!isEmpty(gameAndAnalysis.game.resolvedName)
                      ? gameAndAnalysis?.game.resolvedName
                      : t('common:game_by', {
                          createdBy: gameAndAnalysis.game.createdBy,
                        })}
                  </GRTooltip>
                </div>
                <div className="GameHeader__game-details__publisher light-text-color">
                  {gameAndAnalysis && <PublisherGamesDialogLink game={gameAndAnalysis.game} marketIso={mainMarketIso} />}
                </div>
                <div className="GameHeader__game-details__subgenre">
                  {(!hideSubgenre || isGameOpenForEveryone) && gameAndAnalysis?.game.conventionalSubgenre && (
                    <>
                      <Chip
                        className="Chip"
                        size="small"
                        label={gameAndAnalysis.game.conventionalSubgenre}
                        onClick={() => setOtherSubgenreGamesDialogOpen(true)}
                      ></Chip>

                      <OtherSubgenreGamesDialog
                        modalOpen={otherSubgenreGamesDialogOpen}
                        game={gameAndAnalysis.game}
                        onClose={() => setOtherSubgenreGamesDialogOpen(false)}
                        marketIso={mainMarketIso}
                      />
                    </>
                  )}
                </div>
              </Grid>
              {powerscore > 0 && (
                <Grid item>
                  <Box className="GameHeader__game-details__powerscore">
                    <Powerscore powerscore={powerscore}></Powerscore>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} lg>
            {/* Hide it from GameHeader for now as it is not needed, but keep it for a while in case we need it later */}
            {/* <div className="GameHeader__game-details__analyzed-time">
              {gameArchiveStatus === GameArchiveStatus.archived && (
                <span>
                  <Chip icon={<ErrorOutline />} className="Chip Chip--warning" label={t('common:game_removed_from_app_store')} variant="outlined" />
                </span>
              )}
              {(isOwnGame || gameArchiveStatus === GameArchiveStatus.active) && gameAnalyzedStatus === GameAnalysisStatus.game_not_analyzed && (
                <span>
                  <Chip icon={<ErrorOutline />} className="Chip Chip--warning" label={t('common:game_not_analyzed')} variant="outlined" />
                </span>
              )}
              {(isOwnGame || gameArchiveStatus === GameArchiveStatus.active) && gameAnalyzedStatus === GameAnalysisStatus.analyzed && (
                <span>
                  <Tooltip title={isGameAnalysisOutdated() ? t<string>('common:game_analysis_might_be_outdated_text') : ''}>
                    <Chip
                      icon={isGameAnalysisOutdated() ? <ErrorOutline /> : undefined}
                      className={isGameAnalysisOutdated() ? 'Chip Chip--warning' : 'Chip'}
                      label={`${t('game-header:analyzed')}: ${dateFormatter.format(gameAndAnalysis.game.lastAnalysisAt[mainMarketIso])}`}
                      variant="outlined"
                    />
                  </Tooltip>
                </span>
              )}
            </div> */}
            {gameHeaderActions}
          </Grid>
        </Grid>
      </Card>
      <div className={classNames('GameHeader__content', mobileSize && 'GameHeader__content--mobile', { sticky: stickyContent })}>{gameHeaderContent}</div>
    </>
  )
}

/**
 * GameHeaderActions
 */
interface GameHeaderActionsProps {
  children?: ReactNode
}
const GameHeaderActions: React.FC<GameHeaderActionsProps> = ({ children }) => {
  return <div className="GameHeader__game-details__actions">{children}</div>
}
GameHeaderActions.displayName = gameHeaderActionsName

/**
 * GameHeaderContent
 */
interface GameHeaderContentProps {
  children?: ReactNode
}
const GameHeaderContent: React.FC<GameHeaderContentProps> = ({ children }) => {
  return <Box sx={{}}>{children}</Box>
}
GameHeaderContent.displayName = gameHeaderContentName

GameHeader.Actions = GameHeaderActions
GameHeader.Content = GameHeaderContent
export default GameHeader
