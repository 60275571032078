import classNames from 'classnames'
import React, { FC, memo } from 'react'

import { Lock } from '@mui/icons-material'
import { Card, CardContent, Chip, Grid } from '@mui/material'

import Powerscore from '../../../../components/Powerscore/Powerscore'
import { ReviewIndicator } from '../../../../components/ReviewIndicator/ReviewIndicator'
import { GameIcon } from '../../../game'
import { useShowSubgenreForGame } from '../../hooks/topGrossingGameHooks'
import { TopGame } from '../../types/TopGame'
import { TopGameLink } from '../TopGameLink/TopGameLink'
import styles from './TopGameCard.module.scss'

interface Props {
  topGame: TopGame
  mainMarketIso?: string
  rank?: number
  onReviewClick?: (topGame: TopGame) => void
}

const TopGameCard: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.root}>
      <Card className={styles['card-container']}>
        <CardContent className={styles['card-content']}>
          <TopGameCardContent {...props} variant="card" />
        </CardContent>
      </Card>
    </div>
  )
}

export default TopGameCard

type TopGameGameCardContentProps = Props & {
  variant?: 'card' | 'table'
}

export const TopGameCardContent: FC<TopGameGameCardContentProps> = memo(({ topGame, mainMarketIso, rank, variant = 'card', onReviewClick }) => {
  const rootClasses = classNames(styles['game-card-content'], styles[variant])
  const showSubgenreForGame = useShowSubgenreForGame(topGame)

  return (
    <Grid className={rootClasses} container alignItems="stretch" spacing={1}>
      <Grid item>
        <TopGameLink topGame={topGame}>
          <div className={styles.icon}>
            <GameIcon src={topGame.images[topGame.images.length - 1]} gameName={topGame.name} size="normal"></GameIcon>
          </div>
        </TopGameLink>
      </Grid>
      <Grid item container xs direction="column" justifyContent="space-between">
        <Grid item>
          <TopGameLink topGame={topGame}>
            <div className={styles['game-name']} title={topGame.name}>
              {rank ? `${rank}. ` : ''}
              {topGame.name}
            </div>
          </TopGameLink>
          <div className={styles.publisher} title={topGame.artist}>
            {topGame.artist}
          </div>
        </Grid>
        {topGame.conventionalSubgenre && showSubgenreForGame && (
          <Grid item className={styles.subgenre}>
            <Chip className={styles.Chip} size="small" label={topGame.conventionalSubgenre}></Chip>
          </Grid>
        )}

        {!showSubgenreForGame && (
          <Grid item className={styles.subgenre}>
            <Lock fontSize="small" color="primary" />
          </Grid>
        )}
      </Grid>
      <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        {mainMarketIso && topGame.gps ? <Powerscore powerscore={topGame.gps} size={40} fontSize={18}></Powerscore> : null}
        {topGame.reviewId && onReviewClick && <ReviewIndicator onClick={() => onReviewClick(topGame)} />}
      </Grid>
    </Grid>
  )
})
