import { useCurrentMarket } from '../../markets'

type SubPage = { id: string; translation: string }

const IconsTab: SubPage = {
  id: 'search',
  translation: 'visuals-explorer:search_tab',
}

const IconStatsTab: SubPage = {
  id: 'overview',
  translation: 'visuals-explorer:overview_tab',
}

const GameNameStats: SubPage = {
  id: 'game_names',
  translation: 'visuals-explorer:game_names_tab',
}

export const useVisualExplorerSubPages = () => {
  const { currentMarketIso } = useCurrentMarket()
  const marketsWithoutGameNameStats = ['cn', 'jp']

  if (marketsWithoutGameNameStats.includes(currentMarketIso)) {
    return [IconsTab, IconStatsTab]
  } else {
    return [IconsTab, IconStatsTab, GameNameStats]
  }
}
