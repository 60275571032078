import { useMemo } from 'react'

import { RanksByGenreResult } from '../../../api/top-grossing'
import { ColorPercentage } from '../../visual-explorer/types'
import { VisualAnalysisWithRank, VisualsGroupedData } from '../types'
import { VisualAnalysis } from './../../../api/connect'
import { RankType } from './../types/index'

type UseIconGrouper = (
  rankType: RankType,
  top: number,
  genreId: string,
  attrData?: VisualAnalysis[],
  rankData?: RanksByGenreResult,
  colorPercentage?: ColorPercentage
) => VisualsGroupedData

const createUniqueByAppId = (rankType: RankType, top: number, genreId: string, attrData: VisualAnalysis[], rankData: RanksByGenreResult) => {
  const initial: VisualsGroupedData = { top: [], out: [] }
  if (!rankData || !attrData) {
    return initial
  }

  return attrData.reduce((acc, data) => {
    let item = acc[data.appId]
    if (item) {
      const attributes = { ...item.attributes, ...data.attributes }
      const colorGroups = { ...item.colorGroups, ...data.colorGroups }
      const colors = { ...item.colors, ...data.colors }
      item = { ...item, attributes, colorGroups, colors }
    } else {
      const sdrank: number = rankData.sdranks[data.appId]
      const srank: number = rankData.sranks[data.appId]
      item = { ...data, genreId, rankType, top, sdrank, srank }
    }
    return { ...acc, [data.appId]: item }
  }, {} as { [appId: number]: VisualAnalysisWithRank })
}

export const useIconGrouper: UseIconGrouper = (rankType, top, genreId, attrData, rankData, colorPercentage) => {
  return useMemo(() => {
    const initial: VisualsGroupedData = { top: [], out: [] }
    if (!rankData || !attrData) {
      return initial
    }

    const uniqueByAppId = createUniqueByAppId(rankType, top, genreId, attrData, rankData)
    return Object.values(uniqueByAppId).reduce((acc, analysis) => {
      const rank = rankType === 'free' ? analysis.sdrank : analysis.srank
      const topOrOut = rank <= top ? 'top' : 'out'
      let include = true
      if (colorPercentage) {
        Object.keys(colorPercentage).forEach((color) => {
          const foundColor = (analysis.colorGroups && analysis.colorGroups[color]) || (analysis.colors && analysis.colors[color])

          if (foundColor && colorPercentage[color] > foundColor * 100) {
            include = false
          }
        })
      }
      return include ? { ...acc, [topOrOut]: [...acc[topOrOut], analysis] } : acc
    }, initial)
  }, [attrData, rankData, top, genreId, rankType, colorPercentage])
}

type UseMapVisualAnalysisWithRank = (
  rankType: RankType,
  top: number,
  genreId: string,
  attrData?: VisualAnalysis[],
  rankData?: RanksByGenreResult
) => VisualAnalysisWithRank[]
export const useMapVisualAnalysisWithRank: UseMapVisualAnalysisWithRank = (rankType, top, genreId, attrData, rankData) => {
  return useMemo(() => {
    const initial: VisualAnalysisWithRank[] = []
    if (!rankData || !attrData) {
      return initial
    }

    const uniqueByAppId = createUniqueByAppId(rankType, top, genreId, attrData, rankData)
    return Object.values(uniqueByAppId)
  }, [attrData, rankData, top, genreId, rankType])
}
