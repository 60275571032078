/* eslint-disable no-console */
import { User } from '../features/account/types/User'
import type { Game } from '../features/game'
import { GameVisibility } from '../features/game/types/Game'

class UserService {
  public hasAccessToGame(user: User, game: Game, isAdmin: boolean): boolean {
    if (game.visibility === GameVisibility.private && user.email !== game.createdBy) {
      return isAdmin
    } else if (game.isOwnGame(user) && !game.internal) {
      return true
    }

    return false
  }
}

const userService = new UserService()
export default userService
