import React from 'react'
import { useTranslation } from 'react-i18next'

import LinkIcon from '@mui/icons-material/Link'
import { Box, IconButton, SxProps, Theme } from '@mui/material'

import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard'
import { UnifiedNewsEntry } from '../../../types/UnifiedNewsEntry'
import './NewsCardLink.scss'

/**
 * NewsCardLink
 */
interface NewsCardLinkProps {
  sx?: SxProps<Theme>
  newsEntry: UnifiedNewsEntry
}
const NewsCardLink: React.FC<NewsCardLinkProps> = ({ sx, newsEntry }) => {
  const { t } = useTranslation()
  const copyToClipboard = useCopyToClipboard()

  const copyUrlToClipboard = () => {
    const locationHref = window.location.origin + '/news-card'
    const url = locationHref + '/' + newsEntry.feedEntry.id

    copyToClipboard(url)
  }

  return (
    <Box className="NewsCardLink" sx={sx}>
      <GRTooltip content={t('newsfeed:copy_card_url') || ''}>
        <Box className="NewsCardLink__container">
          <IconButton onClick={copyUrlToClipboard} className="NewsCardLink__button">
            <LinkIcon />
          </IconButton>
        </Box>
      </GRTooltip>
    </Box>
  )
}

export default NewsCardLink
