import { t } from 'i18next'
import { FC } from 'react'

import { Box } from '@mui/material'

import ShowMore from '../../../../../../components/ShowMore/ShowMore'
import { VersionInfo } from '../../../../../../types/VersionInfo'
import { NewsCardSectionDivider } from '../../NewsCardSectionDivider/NewsCardSectionDivider'
import './NewsCardContentReleaseNotesSection.scss'

type NewsCardContentReleaseNotesSectionProps = {
  versionInfo: VersionInfo
}

export const NewsCardContentReleaseNotesSection: FC<NewsCardContentReleaseNotesSectionProps> = ({ versionInfo }) => {
  return (
    <Box className="NewsCardContentReleaseNotesSection" mb={2}>
      <NewsCardSectionDivider>{t('common:release_notes')}</NewsCardSectionDivider>
      <div className="NewsCardContentReleaseNotesSection__version">
        {t('common:version')} {versionInfo.version}
      </div>
      <ShowMore collapsedSize={200}>
        <pre style={{ marginBottom: '16px' }}>{versionInfo.releaseNotes || versionInfo.description}</pre>
      </ShowMore>
    </Box>
  )
}
