import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import { Button, Card, CardContent, CircularProgress, Grid } from '@mui/material'

import { useAcceptOrganizationUserInviteMutation } from '../../api/account'
import { useUserCurrentUserInvites } from '../../features/account/hooks/organizationHooks'
import { UserInvite } from '../../features/account/types/UserInvite'
import usePage from '../../hooks/usePage'
import PageService from '../../services/PageService'
import './InvitesPage.scss'

const InvitesPage: React.FC = () => {
  usePage(PageService.getPageWithId('invites'), 'Visited Invites')

  const { t } = useTranslation()
  const { userInvites, userInvitesLoading } = useUserCurrentUserInvites()
  const [acceptOrganizationUserInvite] = useAcceptOrganizationUserInviteMutation()
  const [acceptedInvite, setAcceptedInvite] = useState<UserInvite | undefined>()

  const handleUserInviteClick = (userInvite: UserInvite) => {
    setAcceptedInvite(userInvite)
    acceptOrganizationUserInvite(userInvite.id).then(() => {
      setTimeout(() => {
        window.location.reload()
      }, 4000)
    })
  }

  return (
    <div className="InvitesPage">
      {!userInvitesLoading && userInvites && userInvites.length === 0 ? <Navigate replace to="/daily-insights" /> : null}

      {userInvitesLoading ? (
        <div className="text-center top-margin bottom-margin">
          <CircularProgress color="primary" />
        </div>
      ) : null}

      {acceptedInvite ? (
        <div className="text-center top-margin bottom-margin">
          <p
            dangerouslySetInnerHTML={{
              __html: t('organization:accepting_invite_wait_text', { inviterOrganizationName: acceptedInvite.invitingOrganizationName }),
            }}
          ></p>
          <CircularProgress color="primary" />
        </div>
      ) : null}

      {!userInvitesLoading && !acceptedInvite && userInvites
        ? userInvites.map((userInvite: UserInvite) => {
            return (
              <Card key={userInvite.id} className="bottom-margin">
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('organization:invite_info_text', {
                            inviterName: userInvite.invitingName,
                            inviterEmail: userInvite.invitingEmail,
                            inviterOrganizationName: userInvite.invitingOrganizationName,
                          }),
                        }}
                      ></div>
                    </Grid>

                    <Grid item>
                      <Button variant="contained" color="primary" onClick={() => handleUserInviteClick(userInvite)}>
                        {t('organization:accept_invite')}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )
          })
        : null}
    </div>
  )
}

export default InvitesPage
