import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BookType } from 'xlsx'

import { Check, Close, Hardware } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Divider, Grid, IconButton, TableContainer, Typography } from '@mui/material'

import { useGetUserProfileQuery } from '../../../../api/combined'
import { Tag } from '../../../../api/core'
import { ExportDataButton } from '../../../../components/ExportDataButton/ExportDataButton'
import { GRTable, GRTableColumn, SortOrder } from '../../../../components/GRTable/GRTable'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import GameIconWithPowerScore from '../../../../components/GameIconWithPowerScore/GameIconWithPowerScore'
import IAPRevenueImpact from '../../../../components/IAPRevenueImpact/IAPRevenueImpact'
import { intersection } from '../../../../helpers/intersection'
import { uniq } from '../../../../helpers/uniq'
import { useExportDataCompareGamesPageFeatureTabView } from '../../../../hooks/exportDataHooks'
import useGetScrollPosition from '../../../../hooks/useGetScrollPosition'
import analyticsService from '../../../../services/AnalyticsService'
import { RoleEnum } from '../../../account'
import { User } from '../../../account/types/User'
import { generateExport } from '../../../export-data/util/workbook'
import { AllFeatureCategoriesValue, FeatureCategorySelector } from '../../../feature/components/FeatureCategorySelector/FeatureCategorySelector'
import { FeatureLabelLink, FeatureLink } from '../../../feature/components/FeatureLink'
import { FeaturesAndKeywordsAutocomplete } from '../../../feature/components/FeaturesAndKeywordsAutocomplete/FeaturesAndKeywordsAutocomplete'
import { TagList } from '../../../feature/components/TagList/TagList'
import { useFeatureTags } from '../../../feature/hooks/useFeatureTags'
import { FeatureAndKeywordSearchResult } from '../../../feature/types/types'
import { Game, GameLink } from '../../../game'
import { CategoryImage } from '../../../game-features/components/CategoryImage/CategoryImage'
import { useGameFeatureKeywords } from '../../../game-features/hooks/gameFeaturesHooks'
import { GameWithMarketIso } from '../../../game-search/components/GameSearchDialog/GameSearchDialog'
import { GameAndAnalysis } from '../../../game/types/GameAndAnalysis'
import { useMobileSize } from '../../../responsiveness/hooks/responsivenessHooks'
import { useCompareFeatureFilterRows } from '../../hooks/useCompareFeatureFilterRows'
import { CompareFeatureRow, useCompareFeatureRows } from '../../hooks/useCompareFeatureRows'
import { useCompareMultiGames } from '../../hooks/useCompareMultiGames'
import FeatureSetSelection, { compareFeatureSetOpts as CompareFeatureSetOpts } from '../FeatureSetSelection/FeatureSetSelection'
import './FeatureTabView.scss'

const gameColumnHeaderClassName = 'GameComparisonTable__gameIconContainer'
interface SortedGameProps {
  selectedSortGameAndAnalysis?: GameAndAnalysis
  onSortGameAndAnalysisSelect?: (gameAndAnalysis?: GameAndAnalysis) => void
}

/**
 * FeatureTagList Wrap TagList and interact with it
 */
interface FeatureTagListProps {
  onSelectedTagsChange: (selectedTags: Tag[]) => void
  selectedTags: Tag[]
}
const FeatureTagList: React.FC<FeatureTagListProps> = ({ selectedTags, onSelectedTagsChange }) => {
  const { data: featureTagGroups } = useFeatureTags()
  const allFeatureTags = useMemo(() => featureTagGroups?.flatMap((featureTag) => featureTag.tags) || [], [featureTagGroups])

  const handleSelectedFeatureTagsChange = useCallback(
    (tags: Tag[]) => {
      onSelectedTagsChange(tags)
    },
    [onSelectedTagsChange]
  )
  const handleClearSelectedFeatureTags = useCallback(() => {
    onSelectedTagsChange([])
  }, [onSelectedTagsChange])

  const handleSelectAllFeatureTags = useCallback(() => {
    onSelectedTagsChange(allFeatureTags)
  }, [allFeatureTags, onSelectedTagsChange])

  const handleSelectTagGroup = useCallback(
    (tags: Tag[]) => {
      const currentlySelectedInGroup = intersection(selectedTags, tags)
      const isWholeTagGroupSelected = currentlySelectedInGroup.length === tags.length

      if (isWholeTagGroupSelected) {
        onSelectedTagsChange(selectedTags.filter((selectedFeatureTag) => !tags.includes(selectedFeatureTag)))
      } else {
        onSelectedTagsChange(uniq([...selectedTags, ...tags]))
      }
    },
    [onSelectedTagsChange, selectedTags]
  )

  return (
    <TagList
      tagGroups={featureTagGroups}
      onChange={handleSelectedFeatureTagsChange}
      selectedTags={selectedTags}
      onClear={handleClearSelectedFeatureTags}
      onSelectAll={handleSelectAllFeatureTags}
      onSelectTagGroup={handleSelectTagGroup}
    />
  )
}

/**
 * GameIconWrapper
 */
interface GameIconWrapperProps extends SortedGameProps {
  gameAndAnalysis: GameAndAnalysis
}
const GameIconWrapper: React.FC<GameIconWrapperProps> = ({ selectedSortGameAndAnalysis, onSortGameAndAnalysisSelect, gameAndAnalysis }) => {
  const isGameSelected =
    selectedSortGameAndAnalysis &&
    selectedSortGameAndAnalysis.game.id === gameAndAnalysis.game.id &&
    selectedSortGameAndAnalysis.analysis?.marketIso === gameAndAnalysis.analysis?.marketIso

  return (
    <Box
      onClick={() => {
        if (!onSortGameAndAnalysisSelect) return
        if (isGameSelected) {
          onSortGameAndAnalysisSelect()
        } else {
          onSortGameAndAnalysisSelect(gameAndAnalysis)
        }
      }}
      className={classNames('GameComparisonTable__gameIconWrapper', {
        selected: isGameSelected,
      })}
      sx={{ p: 1, border: '1px solid', borderRadius: '5px' }}
    >
      <GameIconWithPowerScore game={gameAndAnalysis.game} marketIso={gameAndAnalysis.analysis?.marketIso} />
    </Box>
  )
}

type CompareGameTableRow = {
  keyValue: {
    [key: string]: number
  }
}

/**
 * GameComparisonTable
 */
interface GameComparisonTableProps extends SortedGameProps {
  isLoading?: boolean
  gamesAndAnalysis: GameAndAnalysis[]
  rows: CompareFeatureRow[]
}
const GameComparisonTable: React.FC<GameComparisonTableProps> = ({
  isLoading,
  gamesAndAnalysis,
  rows,
  selectedSortGameAndAnalysis,
  onSortGameAndAnalysisSelect,
}) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const { data: currentUser } = useGetUserProfileQuery()

  const initialColumns: GRTableColumn<CompareFeatureRow, CompareGameTableRow>[] = useMemo(() => {
    const rowContent = (game: Game, choice?: string, translatedChoice?: string) => {
      switch (choice?.trim().toLowerCase()) {
        case 'yes':
          return (
            <GameLink game={game} disabled={game.isOwnGame(currentUser as User)} subpage="implementations">
              <Check className="GameComparisonTable__rowContentIcon" fontSize="small" color="success" />
            </GameLink>
          )
        case 'no':
          return (
            <GameLink game={game} disabled={game.isOwnGame(currentUser as User)} subpage="implementations">
              <Close className="GameComparisonTable__rowContentIcon" fontSize="small" color="error" />
            </GameLink>
          )
        default:
          return (
            <GameLink game={game} disabled={game.isOwnGame(currentUser as User)} subpage="implementations" style={{ color: 'black' }}>
              {translatedChoice}
            </GameLink>
          )
      }
    }
    const gameColumns = gamesAndAnalysis.map(({ game, analysis }) => ({
      headerCellProps: { sx: { backgroundColor: 'white' } },
      columns: [
        {
          labelAccessor: (
            <Box className={gameColumnHeaderClassName}>
              <GameIconWrapper
                onSortGameAndAnalysisSelect={onSortGameAndAnalysisSelect}
                selectedSortGameAndAnalysis={selectedSortGameAndAnalysis}
                gameAndAnalysis={{ game, analysis }}
              />
            </Box>
          ),
          accessor: ({ row }: { row: CompareFeatureRow }) => {
            if (!row.gameColumns[game.id]) return null

            const { choice, gpsEffect, translatedChoice } = row.gameColumns[game.id]
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <GRTooltip content={translatedChoice}>
                  <Typography className={classNames('GameComparisonTable__choiceText', { zerovalue: gpsEffect === 0 })}>
                    {rowContent(game, choice, translatedChoice)}
                  </Typography>
                </GRTooltip>

                <IAPRevenueImpact showZeroRating dotValue={gpsEffect} />
              </Box>
            )
          },
          headerCellProps: { sx: { minWidth: '20px', backgroundColor: 'white' } },
        },
      ],
    }))

    return [
      {
        headerCellProps: { sx: { backgroundColor: 'white' } },
        columns: [
          {
            labelAccessor: t('common:feature'),
            accessor: ({ row }) => (
              <Grid container alignItems="center" gap={2}>
                <Grid item ml={1}>
                  <GRTooltip
                    content={
                      <>
                        <Typography variant="body1">{row.categoryName}</Typography>
                      </>
                    }
                  >
                    {row.icon && <CategoryImage src={row.icon.url} alt={row.featureName} />}
                  </GRTooltip>
                </Grid>
                <Grid item flexShrink={1}>
                  <FeatureLabelLink feature={{ featureName: row.featureName, featureLegacyId: row.featureId }} forceShowFeatureInList />
                </Grid>
                {row.isDifferentChoice && (
                  <Grid item>
                    <GRTooltip content={t('compare:differing_features')}>
                      <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: (theme) => theme.palette.error.main }} />
                    </GRTooltip>
                  </Grid>
                )}
              </Grid>
            ),
            sortable: true,
            sortAccessor: ({ row }) => row.featureName,
            defaultSortOrder: SortOrder.ASC,
            headerCellProps: { sx: { minWidth: 250, backgroundColor: 'white' } },
            cellProps: { align: 'left' },
          },
          {
            accessor: ({ row }) => (
              <>
                {row.showImplementationFeature && (
                  <FeatureLink
                    feature={{ featureName: row.featureName, featureLegacyId: row.featureId }}
                    initialTab="implementation"
                    gameIds={row.implementationWithGameIds}
                    forceShowFeatureInList
                  >
                    <GRTooltip content={t('common:open_feature_implementation')}>
                      <IconButton className="FeatureTabView__icon" color="primary" component="span" size="small" sx={{ p: '4px' }}>
                        <Hardware sx={{ width: '16px', height: '16px' }} />
                      </IconButton>
                    </GRTooltip>
                  </FeatureLink>
                )}
              </>
            ),
            sortable: false,
            headerCellProps: { sx: { minWidth: 40, backgroundColor: 'white' } },
          },
        ],
      },
      ...gameColumns,
    ] as GRTableColumn<CompareFeatureRow, CompareGameTableRow>[]
  }, [currentUser, gamesAndAnalysis, onSortGameAndAnalysisSelect, selectedSortGameAndAnalysis, t])

  const [columns, setColumns] = useState<GRTableColumn<CompareFeatureRow, CompareGameTableRow>[]>(initialColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<CompareFeatureRow, CompareGameTableRow>[]) => {
    setColumns(updatedColumns)
  }, [])

  useEffect(() => {
    handleColumnsUpdate(initialColumns)
  }, [handleColumnsUpdate, initialColumns])

  return (
    <TableContainer ref={containerRef}>
      <GRTable
        tableProps={{ stickyHeader: true }}
        columns={columns}
        rows={rows}
        scroller={containerRef}
        onColumnsUpdated={handleColumnsUpdate}
        rowIdKey="uniqueId"
        isLoading={isLoading}
        hoverable
        striped
      />
    </TableContainer>
  )
}

/**
 * StickyGameIconBar
 */
interface StickyGameIconBarProps extends SortedGameProps {
  gamesAndAnalysis: GameAndAnalysis[]
}
const StickyGameIconBar: React.FC<StickyGameIconBarProps> = ({ gamesAndAnalysis, onSortGameAndAnalysisSelect, selectedSortGameAndAnalysis }) => {
  const { t } = useTranslation()
  const { scrollPosition } = useGetScrollPosition()
  const topBarHeight: number = document.getElementById('app-header')?.clientHeight as number
  const mobileSize = useMobileSize('lg')
  const showBar = scrollPosition > 780 && !mobileSize

  const gameContainerWidth = (index: number) =>
    document.getElementsByClassName(gameColumnHeaderClassName)[index] ? document.getElementsByClassName(gameColumnHeaderClassName)[index].clientWidth + 32 : 0
  return (
    <Box
      className="StickyGameIconBar__container"
      sx={{
        display: showBar ? 'flex' : 'none',
        opacity: showBar ? 1 : 0,
        position: 'sticky',
        top: topBarHeight + 'px',
        zIndex: 5,
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
      }}
    >
      <Box>
        <Button
          variant="contained"
          sx={{ ml: 2 }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        >
          {t('common:back_to_top')}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {gamesAndAnalysis.map(({ game, analysis }, index) => (
          <Box
            key={`${game.id}-${analysis?.marketIso}`}
            className="StickyGameIconBar__iconContainer"
            sx={{ width: gameContainerWidth(index) + 'px', py: 2, display: 'flex', justifyContent: 'center' }}
          >
            <GameIconWrapper
              onSortGameAndAnalysisSelect={onSortGameAndAnalysisSelect}
              selectedSortGameAndAnalysis={selectedSortGameAndAnalysis}
              gameAndAnalysis={{ game, analysis }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

/**
 * FeatureTabView
 */
interface FeatureTabViewProps {
  gamesAndAnalysis: GameAndAnalysis[]
  gamesWithMarketIso: GameWithMarketIso
}
const FeatureTabView: React.FC<FeatureTabViewProps> = ({ gamesAndAnalysis, gamesWithMarketIso }) => {
  useEffect(() => {
    analyticsService.trackEvent('Visited Game Comparison: Features')
  }, [])

  const [selectedSortGameAndAnalysis, setSelectedSortGameAndAnalysis] = useState<GameAndAnalysis | undefined>()

  const gamesAndMarketIso = useMemo(() => {
    return Object.entries(gamesWithMarketIso).reduce<{ gameId: string; marketIso: string }[]>((result, [gameId, marketIsoList]) => {
      marketIsoList.forEach((marketIso) => {
        result.push({ gameId: gameId, marketIso })
      })
      return result
    }, [])
  }, [gamesWithMarketIso])

  const { data: multiGamesCompare, isLoading: isMultiGamesCompareLoading } = useCompareMultiGames({
    gameIds: gamesAndMarketIso.map(({ gameId }) => gameId),
    marketIsos: gamesAndMarketIso.map(({ marketIso }) => marketIso),
  })

  const [featureSearchValue, setFeatureSearchValue] = useState<FeatureAndKeywordSearchResult>(null)
  const [categoryFilter, setCategoryFilter] = useState<string>(AllFeatureCategoriesValue)
  const { data: featureTagGroups } = useFeatureTags()
  const allFeatureTags = useMemo(() => featureTagGroups?.flatMap((featureTag) => featureTag.tags) || [], [featureTagGroups])
  const [selectedFeatureTags, setSelectedFeatureTags] = useState<Tag[]>(allFeatureTags)

  const [featureSetOption, setFeatureSetOption] = useState<CompareFeatureSetOpts>(CompareFeatureSetOpts.all)
  const keywords = useGameFeatureKeywords()
  const [rows, isRowLoading] = useCompareFeatureRows(multiGamesCompare)

  const filteredRows = useCompareFeatureFilterRows({
    rows,
    featureTags: selectedFeatureTags,
    featureSearchValue,
    categoryId: categoryFilter,
    featureSetOption,
    keywords,
  })

  const [isExporting, setIsExporting] = useState(false)
  const [exportFormat, setExportFormat] = useState('csv' as BookType)

  const { exportRows } = useExportDataCompareGamesPageFeatureTabView(rows, gamesAndAnalysis, isExporting)

  const handleDataExport = useCallback((format: BookType) => {
    setExportFormat(format)
    setIsExporting(true)
  }, [])

  useEffect(() => {
    if (!isExporting || !exportRows.length) return
    setIsExporting(false)
    generateExport(exportFormat, exportRows, 'Game Comparison', `game-comparison`)
  }, [isExporting, exportRows, exportFormat])

  const filteredSelectedTags = useMemo(() => {
    const selectedTargets = selectedFeatureTags.map((selectedTag) => selectedTag.targets).flat()
    return [...new Set(selectedTargets)]
  }, [selectedFeatureTags])

  const sortedFilteredRows = useMemo(() => {
    if (!!selectedSortGameAndAnalysis) {
      return [...filteredRows].sort(
        (a, b) => b.gameColumns[selectedSortGameAndAnalysis.game.id].gpsEffect - a.gameColumns[selectedSortGameAndAnalysis.game.id].gpsEffect
      )
    }
    return filteredRows.filter((feature) => {
      if (!filteredSelectedTags || !filteredSelectedTags.length || !feature.featureId) {
        return true
      }
      return filteredSelectedTags.includes(feature.featureId)
    })
  }, [filteredRows, filteredSelectedTags, selectedSortGameAndAnalysis])

  useEffect(() => {
    setSelectedFeatureTags(allFeatureTags)
  }, [allFeatureTags])

  const onSortGameAndAnalysisSelect = useCallback((gameAndAnalysis: GameAndAnalysis | undefined) => {
    setSelectedSortGameAndAnalysis(gameAndAnalysis)
  }, [])

  if (isRowLoading || isMultiGamesCompareLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  if (!multiGamesCompare) {
    return null
  }

  return (
    <>
      <FeatureTagList selectedTags={selectedFeatureTags} onSelectedTagsChange={(selectedTags) => setSelectedFeatureTags(selectedTags)} />
      <StickyGameIconBar
        selectedSortGameAndAnalysis={selectedSortGameAndAnalysis}
        onSortGameAndAnalysisSelect={(gameAndAnalysis) => setSelectedSortGameAndAnalysis(gameAndAnalysis)}
        gamesAndAnalysis={gamesAndAnalysis}
      />
      <Card sx={{ pt: 2, mt: 2 }}>
        <Grid container direction="row" justifyContent="space-between">
          <Box sx={{ px: 2 }}>
            <FeaturesAndKeywordsAutocomplete
              value={featureSearchValue}
              features={multiGamesCompare.features}
              keywords={keywords}
              onChange={(value) => {
                setFeatureSearchValue(value)
              }}
            />
            <Box sx={{ my: 2, display: 'flex', justifyContent: 'flex-start' }}>
              <Box sx={{ mr: 2 }}>
                <FeatureCategorySelector value={categoryFilter} onChange={(event) => setCategoryFilter(event.target.value)} />
              </Box>
              <FeatureSetSelection value={featureSetOption} onChange={(option) => setFeatureSetOption(option)} />
            </Box>
          </Box>
          <Box sx={{ mr: 2 }}>
            <ExportDataButton
              accessRoles={[RoleEnum.csv_export_games, RoleEnum.csv_game_comparison]}
              loading={!rows.length}
              onChooseFormat={handleDataExport}
              analyticsEventOrigin="Feature Tab View"
            />
          </Box>
        </Grid>
        {!!rows && (
          <>
            <Divider />
            <GameComparisonTable
              selectedSortGameAndAnalysis={selectedSortGameAndAnalysis}
              onSortGameAndAnalysisSelect={onSortGameAndAnalysisSelect}
              rows={sortedFilteredRows}
              gamesAndAnalysis={gamesAndAnalysis}
              isLoading={!rows.length}
            />
          </>
        )}
      </Card>
    </>
  )
}

export default FeatureTabView
