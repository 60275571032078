import { FC, ReactNode, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Box, FormControl, Grid, TextField, debounce } from '@mui/material'

import { SelectMenu } from '../../../components/SelectMenu/SelectMenu'
import { Game } from '../../../features/game'
import usePage from '../../../hooks/usePage'
import PageService from '../../../services/PageService'
import { useGetSearchGamesQuery } from '../../api/internal'
import { BackButton } from '../../components/BackButton/BackButton'
import { SearchGamesTable } from '../../features/live-events-tracking/components/SearchGamesTable/SearchGamesTable'

type SearchGamesPageProps = {
  children?: ReactNode
}

type SearchGamesFilter = {
  gameNameOrPublisherSearchString: string
  analysedFilter: AnalysedFilterOption
}

enum AnalysedFilterOption {
  ShowAnalysedGames = 'show_analysed_games',
  ShowNotAnalysedGames = 'show_not_analysed_games',
  ShowAllGames = 'show_all_games',
}

export const SearchGamesPage: FC<SearchGamesPageProps> = ({ children }) => {
  usePage(PageService.getInternalPageWithId('internal-tracked-games-search'))
  const { t } = useTranslation()
  const [formData, setFormData] = useState<SearchGamesFilter>()
  const searchGamesQuery = useGetSearchGamesQuery(
    { term: formData?.gameNameOrPublisherSearchString || '', marketIso: 'us' },
    { skip: !formData?.gameNameOrPublisherSearchString }
  )
  const { handleSubmit, watch, control } = useForm<SearchGamesFilter>({
    defaultValues: {
      gameNameOrPublisherSearchString: '',
      analysedFilter: AnalysedFilterOption.ShowAnalysedGames,
    },
  })

  let analysedFilterOptions = [
    { name: t('internal-live-events:show_analysed_games'), value: AnalysedFilterOption.ShowAnalysedGames },
    { name: t('internal-live-events:show_not_analysed_games'), value: AnalysedFilterOption.ShowNotAnalysedGames },
    { name: t('internal-live-events:show_all_games'), value: AnalysedFilterOption.ShowAllGames },
  ]

  useEffect(() => {
    const onSubmit = debounce((data: SearchGamesFilter) => {
      setFormData(data)
    }, 300)
    const subscription = watch(() => handleSubmit(onSubmit)())
    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  const filteredGames = searchGamesQuery.data?.filter((game) => searchFilter(game.game, formData?.analysedFilter))

  const isLoading = searchGamesQuery.isFetching

  return (
    <>
      <BackButton url={`/internal/live-events-tracking`} />
      <Box mb={4}>
        <form>
          <Grid container spacing={2} wrap="wrap">
            <Grid item xs={6}>
              <Controller
                name="gameNameOrPublisherSearchString"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      type="text"
                      value={field.value}
                      onChange={field.onChange}
                      variant="outlined"
                      size="small"
                      label={t('internal-live-events:search_by_game')}
                      fullWidth
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="analysedFilter"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl>
                      <SelectMenu label={''} value={field.value} onChange={field.onChange} options={analysedFilterOptions} />
                    </FormControl>
                  )
                }}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <SearchGamesTable searchedGames={filteredGames} isLoading={isLoading} />
    </>
  )
}

//TODO remeber to check marketIso
const searchFilter = (game: Game, analysedFilter?: AnalysedFilterOption) => {
  switch (analysedFilter) {
    case AnalysedFilterOption.ShowAnalysedGames:
      return Object.keys(game.latestAnalysis).length > 0
    case AnalysedFilterOption.ShowNotAnalysedGames:
      return Object.keys(game.latestAnalysis).length <= 0
    case AnalysedFilterOption.ShowAllGames:
      return game
  }
}
