import { FC, useState } from 'react'
import { Trans } from 'react-i18next'

import { ContentCopy } from '@mui/icons-material'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

/**
 * Component for displaying an "event cloner" that allows the user to clone events from a specific date
 */
type EventClonerProps = {
  onClone: (date: Date) => void
}

export const EventCloner: FC<EventClonerProps> = ({ onClone }) => {
  const [date, setDate] = useState<Date | null>(null)
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={<Trans i18nKey="internal-live-events:event_cloner_date_field_label" />}
            value={date}
            onChange={(date) => setDate(date)}
            slotProps={{
              textField: { size: 'small', inputProps: { sx: { boxSizing: 'content-box !important' } } },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item>
        <Tooltip
          title={
            !date ? (
              <Trans i18nKey="internal-live-events:event_cloner_select_date_tooltip_text" />
            ) : (
              <Trans i18nKey="internal-live-events:event_cloner_clone_tooltip_text" />
            )
          }
        >
          <span>
            <IconButton disabled={!date} onClick={() => date && onClone(date)} color="primary">
              <ContentCopy />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
