import { useGetAnalystsListQuery } from '../api/internal'

export const useAnalystById = (analystId: string) => {
  const getAnalystsListQuery = useGetAnalystsListQuery()

  return {
    data: getAnalystsListQuery.data?.find(({ id }) => id === analystId),
    isLoading: getAnalystsListQuery.isFetching,
  }
}
