import React, { useMemo, useState } from 'react'

import { Box, Card } from '@mui/material'

import languageService from '../../../../services/LanguageService'
import { MotivationKey, MotivationType } from '../../../market-explorer/types/MotivationType'
import { ArchetypeDescriptions } from '../../../motivations/components/ArchetypeDescriptions/ArchetypeDescriptions'
import MotivationTable from '../../../motivations/components/MotivationTable/MotivationTable'

/**
 * MotivationsTabView
 */
const MotivationsTabView: React.FC = () => {
  const [motivationKey, setMotivationKey] = useState<MotivationKey>(MotivationKey.Expressionist)

  const labels = useMemo(() => {
    return Object.values(MotivationType).map((motivationKey) => {
      return languageService.getTranslation('motivations', motivationKey)
    })
  }, [])

  return (
    <Box>
      <Card sx={{ my: 7 }}>
        <MotivationTable showDescriptionColumn />
      </Card>
      {motivationKey && (
        <ArchetypeDescriptions
          datasets={[]}
          labels={labels}
          onSetMotivationKey={(motivationKey) => {
            if (motivationKey) setMotivationKey(motivationKey)
          }}
          motivationKey={motivationKey}
        />
      )}
    </Box>
  )
}

export default MotivationsTabView
