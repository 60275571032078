import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { VisibilityOff, Visibility } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'

import utilsService from '../../services/UtilsService'

/**
 * Passowrd field component with show/hide password functionality
 */
type PasswordFieldProps = TextFieldProps & {
  value?: string
  children?: ReactNode
}

type PasswordStrengthValue = ReturnType<typeof utilsService.getPasswordStrength>

export const PasswordField: FC<PasswordFieldProps> = ({ children, ...textFieldProps }) => {
  const { value, onChange } = textFieldProps
  const { t } = useTranslation()
  const [fieldType, setFieldType] = useState<'text' | 'password'>('password')
  const [passwordStrength, setPasswordStrength] = useState<PasswordStrengthValue>()
  const showPassword = fieldType === 'text'

  const handleClickShowPassword = () => {
    setFieldType(fieldType === 'text' ? 'password' : 'text')
  }

  useEffect(() => {
    if (value) {
      const passwordStrength = utilsService.getPasswordStrength(value)
      setPasswordStrength(passwordStrength)
    } else {
      setPasswordStrength(undefined)
    }
  }, [value])

  return (
    <TextField
      type={fieldType}
      label={t('profile:type_current_password')}
      onChange={onChange}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      color={passwordStrengthToColor(passwordStrength)}
      error={passwordStrength === 'too-short'}
      helperText={passwordStrength ? t(`common:password_${passwordStrength}`) : ' '}
      {...textFieldProps}
    ></TextField>
  )
}

const passwordStrengthToColor = (passwordStrength: PasswordStrengthValue) => {
  switch (passwordStrength) {
    case 'too-short':
      return 'error'
    case 'weak':
      return 'warning'
    case 'moderate':
      return 'warning'
    case 'strong':
      return 'success'
    default:
      return
  }
}
