import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CheckIcon from '@mui/icons-material/Check'
import { Card, CardActions, CardContent, Chip, CircularProgress, Divider, Grid, Typography } from '@mui/material'

import { Tag } from '../../../../../api/core'
import { ExpandedTagGroup } from '../../../../implementation-examples'
import './FeatureGroupSelector.scss'

/**
 * Displays a summary of selected feature groups
 */
interface FeatureGroupsProps {
  onOpenSelect: () => void
  featureTags?: ExpandedTagGroup[]
  selectedTags: Tag[]
}

export const FeatureGroupSelector: React.FC<FeatureGroupsProps> = ({ onOpenSelect, featureTags, selectedTags }) => {
  const { t } = useTranslation()
  const totalTags = useMemo(() => featureTags?.reduce((acc, group) => acc + group.tags.length, 0), [featureTags])
  return (
    <Card className="FeatureGroupSelector">
      <CardContent>
        <Typography>
          {t('common:select_feature_tags_button')}: {selectedTags.length}/{totalTags}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions onClick={onOpenSelect}>
        {featureTags ? (
          <Grid container direction="row" wrap="wrap" alignItems="center" spacing={2}>
            {featureTags.map(({ colorHex: color, id, name, tags }) => {
              const totalAmount = tags.length
              const selectedAmount = tags.reduce((acc, tag) => {
                return acc + selectedTags.filter(({ id }) => id === tag.id).length
              }, 0)
              const label = selectedAmount !== totalAmount ? selectedAmount + '/' + totalAmount : <CheckIcon sx={{ fontSize: '1rem' }} />
              return tags.length ? (
                <Grid item key={id} className="FeatureGroupSelector__FeatureOption" sx={{ color }}>
                  <Chip label={label} sx={{ background: color, py: selectedAmount !== totalAmount ? '5px' : 'initial', mb: 0 }} />
                  <span>{name}</span>
                </Grid>
              ) : null
            })}
          </Grid>
        ) : (
          <CircularProgress />
        )}
      </CardActions>
    </Card>
  )
}
