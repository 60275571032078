import { useTranslation } from 'react-i18next'

import { Button, Dialog, DialogActions, DialogContent, Fade } from '@mui/material'

import { ConfirmDialogData } from '../../types/ConfirmDialogData'
import GRDialogTitle from '../GRDialogTitle/GRDialogTitle'
import './ConfirmDialog.scss'

interface ConfirmDialogProps<DataType> {
  open: boolean
  confirmDialogData?: ConfirmDialogData<DataType>
  onClose: (previousConfirmDialogData?: ConfirmDialogData<DataType>) => void
}

const ConfirmDialog = <DataType extends object | string | number>({ open, confirmDialogData, onClose }: ConfirmDialogProps<DataType>) => {
  const { t } = useTranslation()
  const handleClose = (currentConfirmDialogData?: ConfirmDialogData<DataType>) => {
    onClose(currentConfirmDialogData)
  }

  return (
    <Dialog className="ConfirmDialog" onClose={handleClose} fullWidth={true} maxWidth="xs" open={open} TransitionComponent={Fade}>
      {confirmDialogData?.title && <GRDialogTitle onClose={() => onClose()}>{confirmDialogData.title}</GRDialogTitle>}
      <DialogContent className="DialogContent" dividers>
        {confirmDialogData?.confirmText && confirmDialogData.confirmText}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={() => onClose()} variant="text">
          {confirmDialogData?.cancelText ? confirmDialogData.cancelText : t('common:cancel')}
        </Button>

        <Button
          className={confirmDialogData?.destructiveAction ? 'destructive-button' : ''}
          onClick={() => handleClose(confirmDialogData)}
          variant="contained"
          color="primary"
        >
          {confirmDialogData?.actionText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
