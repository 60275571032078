import { CSSProperties, FC, RefObject } from 'react'
import { Trans } from 'react-i18next'

import { ExpandCircleDown, RemoveCircle } from '@mui/icons-material'
import { Box, Grid, IconButton } from '@mui/material'

import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'
import { GamePerformanceDialogOpenButton } from '../../../../../components/GamePerformanceDialogOpenButton/GamePerformanceDialogOpenButton'
import { useAppSelector } from '../../../../../hooks/storeHooks'
import { useTimeout } from '../../../../../hooks/useTimeout'
import { GameCardContent } from '../../../../game/components/GameCard/GameCard'
import { AppType } from '../../../../game/types/Game'
import { selectVisibleGameCalendars } from '../../../slices/liveEventsCalendarsSlice'
import { TrackedGame } from '../../../types/TrackedGame'
import { LiveEventsNotPublishedBadge } from '../../LiveEventsNotPublishedBadge/LiveEventsNotPublishedBadge'
import LiveEventsOverviewButton from '../../LiveEventsOverview/LiveEventsOverviewButton/LiveEventsOverviewButton'

type LiveEventsCalendarCustomSidebarHeaderProps = {
  trackedGames: TrackedGame[]
  trackedGame: TrackedGame
  onDeselectGame?: (trackedGame: TrackedGame, hdGameSelected: boolean) => void
  onToggleVisibility: (trackedGame: TrackedGame) => void
  onOverviewTrackedGameChanged: (trackedGame: TrackedGame) => void
  onGamePerformanceDialogOpen: (trackedGame: TrackedGame) => void
  calendarContainerRef: RefObject<HTMLDivElement>
} & { style: CSSProperties }

export const LiveEventsCalendarCustomSidebarHeader: FC<LiveEventsCalendarCustomSidebarHeaderProps> = ({
  trackedGames,
  trackedGame,
  onDeselectGame,
  onToggleVisibility,
  onOverviewTrackedGameChanged,
  calendarContainerRef,
  onGamePerformanceDialogOpen,
  ...rootProps
}) => {
  const visibleGameCalendars = useAppSelector(selectVisibleGameCalendars)
  const calendarHidden = !visibleGameCalendars?.includes(trackedGame.game.id)

  const [openCalendar] = useTimeout(() => {
    const offsetTop = calendarContainerRef.current?.offsetTop || 0
    const topHeaderHeight = 148
    window.scrollTo({ top: (offsetTop || 0) - topHeaderHeight, behavior: 'smooth' })
  }, 100)

  const handleToggleVisibility = () => {
    onToggleVisibility(trackedGame)

    if (calendarHidden) {
      openCalendar()
    }
  }

  //NOTE: Remove hd game check when we want to release LET Price Points for mobile
  const handleDeselectGame = (trackedGame: TrackedGame) => {
    const filteredTrackedGames = trackedGames.filter((game) => game.game.id !== trackedGame.game.id)
    const hdGameSelected = filteredTrackedGames.some((game) => game.game.appType === AppType.PC_CONSOLE)
    if (onDeselectGame) {
      onDeselectGame(trackedGame, hdGameSelected)
    }
  }
  return (
    <>
      <Grid
        container
        {...rootProps}
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="wrap"
        className="LiveEventsCalendar__Timeline__SidebarHeader"
        p={1}
      >
        <Grid item flex="1 1 auto">
          <Grid container>
            <Grid item flex="1 1 auto">
              <GameCardContent game={trackedGame.game} displayGameType />
            </Grid>
            {onDeselectGame && (
              <Grid item flex="0 0 68px" mr="-3px">
                <Box mt="-5px" mb="5px">
                  <GRTooltip content={<Trans i18nKey="live-events:remove_game" />}>
                    <IconButton size="small" onClick={() => handleDeselectGame(trackedGame)}>
                      <RemoveCircle />
                    </IconButton>
                  </GRTooltip>

                  <GRTooltip content={<Trans i18nKey={calendarHidden ? 'live-events:show_calendar' : 'live-events:hide_calendar'} />}>
                    <IconButton size="small" color="primary" onClick={handleToggleVisibility}>
                      <ExpandCircleDown style={{ rotate: calendarHidden ? '0deg' : '-180deg', transition: 'rotate 0.1s ease-in-out' }} />
                    </IconButton>
                  </GRTooltip>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
          <Grid item flexGrow={1}>
            <LiveEventsOverviewButton trackedGame={trackedGame} onClick={onOverviewTrackedGameChanged} />
          </Grid>
          <Grid item flexShrink={1}>
            <GamePerformanceDialogOpenButton
              onClick={() => onGamePerformanceDialogOpen(trackedGame)}
              variant="contained"
              size="small"
              iconProps={{ fontSize: 'small' }}
              disabled={!trackedGame.game.isMobileGame()}
            />
          </Grid>
        </Grid>
      </Grid>

      {!trackedGame.published && <LiveEventsNotPublishedBadge />}
    </>
  )
}
