export interface MarketExplorerGamesChartAxisScale {
  value: number
  label: string
  showOverChartNode?: boolean
}

export enum MarketExplorerGamesChartAxisTypeId {
  Powerscore = 'powerscore',
  SkillThinking = 'skillThinking',
  SGR = 'sgr',
  SDR = 'sdr',
  RevenuePastMonth = 'revenuePastMonth',
  Revenue180Days = 'revenue180Days',
  Revenue360Days = 'revenue360Days',
  DownloadsPastMonth = 'downloadsPastMonth',
  Downloads180Days = 'downloads180Days',
  Downloads360Days = 'downloads360Days',
  RevenueDownloadsRatioPastMonth = 'revenueDownloadsRatio',
  RevenueDownloads180DaysRatio = 'revenueDownloads180DaysRatio',
  RevenueDownloads360DaysRatio = 'revenueDownloads360DaysRatio',
  DaysSinceRelease = 'daysSinceRelease',
  DemographicsMale = 'demographicsMale',
  DemographicsFemale = 'demographicsFemale',
  DemographicsAge16_24 = 'demographicsAge16_24',
  DemographicsAge25_44 = 'demographicsAge25_44',
  DemographicsAge45 = 'demographicsAge45',
}

export interface MarketExplorerGamesChartAxisType {
  name: string
  description: string
  id: MarketExplorerGamesChartAxisTypeId
  maxScales: MarketExplorerGamesChartAxisScale[]
  minScales: MarketExplorerGamesChartAxisScale[]
  defaultMinScale: MarketExplorerGamesChartAxisScale
  defaultMaxScale: MarketExplorerGamesChartAxisScale
  inversed: boolean
}
