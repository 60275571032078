import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { useAppDispatch } from '../../../../hooks/storeHooks'
import { displaySnackBar } from '../../../snackbar'
import { SnackBarMessage } from '../../../snackbar/types/SnackBarMessage'
import { useGameFollow } from '../../hooks/gameHooks'
import { Game } from '../../types/Game'

/**
 * Dialog component to Toggle game follow state
 */

interface ToggleGameFollowDialogProps {
  open: boolean
  onClose: () => void
  game: Game
}

const ToggleGameFollowDialog: React.FC<ToggleGameFollowDialogProps> = ({ open, onClose, game }) => {
  const { t } = useTranslation()
  const { isToggling: isTogglingGameFollow, isFollowed: isGameFollowed, toggleFollow: toggleGameFollow } = useGameFollow(game)
  const dispatch = useAppDispatch()
  const showError = useCallback((errorPayload: SnackBarMessage) => dispatch(displaySnackBar(errorPayload)), [dispatch])

  const handleToggleGameFollowClick = async () => {
    try {
      toggleGameFollow()
      onClose()
    } catch (error) {
      showError({ message: t('game-header:follow_error_body'), severity: 'error', open: true })
      onClose()
    }
  }

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>{isGameFollowed ? t('common:unfollow_game') : t('common:follow_game')}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Trans i18nKey={isGameFollowed ? 'common:confirm_unfollow_text' : 'common:confirm_follow_text'} values={{ gameName: game.resolvedName }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => handleToggleGameFollowClick()} disabled={isTogglingGameFollow}>
            {t('common:yes')}
          </Button>
          <Button onClick={() => onClose()} disabled={isTogglingGameFollow}>
            {t('common:no')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ToggleGameFollowDialog
