export enum GameStageId {
  concept = 1,
  prototype = 2,
  alpha = 3,
  beta = 4,
  soft_launch = 5,
  global_launch = 6,
  update = 7,
  production = 8,
  live = 9,
}
