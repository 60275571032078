import classNames from 'classnames'
import { CSSProperties, FC, ForwardRefExoticComponent, ReactNode, RefAttributes, RefObject, forwardRef, useRef } from 'react'

import { Paper, PaperProps, Tooltip, TooltipProps } from '@mui/material'

import './GRTooltip.scss'

/**
 * Component for displaying a tooltip while hovering an element.
 */

type GRTooltipProps = {
  children?: ReactNode
  content: ReactNode
  hidden?: boolean
  className?: string
  severity?: 'success' | 'warning'
  styles?: CSSProperties
  popoverBorderColor?: string
  anchorRef?: RefObject<HTMLElement>
  tooltipProps?: TooltipProps
  usePointerPosition?: boolean
  disableInteractive?: boolean
  disableMaxWidth?: boolean
}

export const GRTooltip: FC<GRTooltipProps> = ({
  content,
  hidden = false,
  children,
  className,
  severity,
  styles,
  popoverBorderColor,
  anchorRef,
  tooltipProps,
  usePointerPosition,
  disableInteractive,
  disableMaxWidth,
}) => {
  const positionRef = useRef<{ x?: number; y?: number }>()

  const handleMouseMove = (event: React.MouseEvent) => {
    positionRef.current = { x: event.clientX, y: event.clientY }
  }

  const triggerClasses = classNames('GRTooltip', className)
  const tooltipClasses = classNames(disableMaxWidth ? 'Tooltip Tooltip--NoMaxWidth' : 'Tooltip', severity)
  // if anchorRef is given, use it to calculate position for the tooltip
  const customAnchorElement =
    anchorRef || usePointerPosition
      ? {
          anchorEl: {
            getBoundingClientRect: () => {
              if (anchorRef) {
                return new DOMRect(positionRef?.current?.x, anchorRef?.current?.getBoundingClientRect().y, 16, 24)
              } else {
                return new DOMRect(positionRef?.current?.x, positionRef?.current?.y, 16, 24)
              }
            },
          },
        }
      : undefined

  return content && !hidden ? (
    <Tooltip
      title={content}
      components={{ Tooltip: TooltipPaper }}
      componentsProps={{ tooltip: { className: tooltipClasses, style: popoverBorderColor ? { borderColor: popoverBorderColor } : {} } }}
      PopperProps={customAnchorElement}
      disableInteractive={disableInteractive === false ? false : true}
      {...tooltipProps}
    >
      <span onMouseMove={handleMouseMove} onMouseEnter={handleMouseMove} style={styles} className={triggerClasses}>
        {children}
      </span>
    </Tooltip>
  ) : !content || hidden ? (
    <>{children}</>
  ) : null
}

const TooltipPaper: ForwardRefExoticComponent<Omit<PaperProps<'div', {}>, 'ref'> & RefAttributes<HTMLDivElement>> = forwardRef(
  ({ className, style, children }, ref) => {
    return (
      <Paper className={className} style={style} elevation={8} sx={{ p: 2, borderRadius: '20px' }} ref={ref}>
        {children}
      </Paper>
    )
  }
)
