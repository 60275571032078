import { t } from 'i18next'
import React from 'react'
import { Trans } from 'react-i18next'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { ContactButton } from '../../../../components/ContactButton/ContactButton'

/**
 * ContactSalesModal to be displayed when a user tries to request analysis for a game that is not in their plan
 */
interface ContactSalesModalProps {
  open: boolean
  onClose: () => void
}

const ContactSalesModal: React.FC<ContactSalesModalProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{t('plans:polite_contact_sales')}</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Trans i18nKey="plans:request_analysis_not_in_plan_modal_text" />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onClose()} sx={{ mr: 1 }}>
          {t('common:no')}
        </Button>
        <ContactButton size="medium" text={t('plans:polite_contact_sales')} />
      </DialogActions>
    </Dialog>
  )
}

export default ContactSalesModal
