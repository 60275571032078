import { BrowserRouter } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import { ThemeProvider } from '@emotion/react'

import './App.scss'
import { KeycloakInitializer } from './features/auth/components/KeycloakInitializer/KeycloakInitializer'
import { GRSnackBar } from './features/snackbar'
import './i18n'
import { AppRoutes } from './pages/AppRoutes/AppRoutes'
import intercomService from './services/IntercomService'
import { theme } from './themes/theme'

/**
 * Wrapper for the whole app initiating authentication and background data fetching
 */
const App: React.FC = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <IntercomProvider appId={intercomService.getIntercomAppId()}>
            <KeycloakInitializer>
              <AppRoutes />
              <GRSnackBar></GRSnackBar>
            </KeycloakInitializer>
          </IntercomProvider>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  )
}

export default App
