import { FC, useRef } from 'react'
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline'
import { Trans } from 'react-i18next'

import { ChatBubble } from '@mui/icons-material'
import { Typography } from '@mui/material'

import { GRTooltip } from '../../../../../../components/GRTooltip/GRTooltip'
import { AnalystReviewTimelineItem } from '../../../../types/LiveEvents'

const itemBgColor = '#38a73e'
const inactiveItemBgColor = '#b13d37'

export const LiveEventsCalendarAnalystReviewItem: FC<ReactCalendarItemRendererProps<AnalystReviewTimelineItem>> = ({
  item,
  itemContext,
  getItemProps,
  getResizeProps,
}) => {
  const itemRef = useRef(null)
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()

  return (
    <GRTooltip
      content={
        <>
          <Typography variant="body1" textAlign="center" mb={1}>
            <Trans i18nKey="live-events:analyst_review_timeline_group" />
          </Typography>
          <Typography variant="body2" textAlign="center">
            {item.title}
          </Typography>
        </>
      }
      severity="success"
      anchorRef={itemRef}
    >
      <div
        {...getItemProps({
          style: {
            background: itemBgColor,
            color: 'white',
            border: '1px solid white',
            borderTop: item.review.active === false ? `3px solid ${inactiveItemBgColor}` : '1px solid white',
            borderRadius: 10,
          },
        })}
        className="rct-item"
        title=""
        ref={itemRef}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <div
          className="rct-item-content"
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            padding: '0px 6px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            verticalAlign: 'top',
            marginTop: !item.review.active ? '-2px' : 'initial',
          }}
        >
          <ChatBubble sx={{ position: 'relative', fontSize: '14px', verticalAlign: 'middle', top: '-1px', mr: 1 }} />
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    </GRTooltip>
  )
}
