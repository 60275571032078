import { FC, ReactNode, useMemo } from 'react'

import { useRouteModal } from '../../../route-modals/hooks/useRouteModal'
import { useDefaultLiveEvents } from '../../hooks/useTrackedGamesEvents'
import { LiveEventsAnalystNotesDialog } from './LiveEventsAnalystNotesDialog'

export type LiveEventsAnalystNotesDialogParams = {
  trackedGameId: string
  commentId: string
}

type LiveEventsAnalystNotesDialogContainerProps = LiveEventsAnalystNotesDialogParams & {
  children?: ReactNode
}

export const LiveEventsAnalystNotesDialogContainer: FC<LiveEventsAnalystNotesDialogContainerProps> = ({ trackedGameId, commentId, children }) => {
  const { popModal } = useRouteModal()

  const trackedEvents = useDefaultLiveEvents({ trackedGameIds: [trackedGameId] })

  const review = useMemo(
    () => trackedEvents.comments[trackedGameId]?.find((comment) => comment.id === commentId),
    [trackedEvents.comments, trackedGameId, commentId]
  )

  const handleClose = () => {
    popModal()
  }

  return <LiveEventsAnalystNotesDialog open onClose={handleClose} review={review} isLoading={trackedEvents.isLoading || trackedEvents.isError} />
}
