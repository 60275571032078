import { useEffect } from 'react'

import { changePage } from '../actions/changePage'
import analyticsService, { ITrackEventOptions } from '../services/AnalyticsService'
import intercomService from '../services/IntercomService'
import { Page } from '../types/Page'
import { useAppDispatch } from './storeHooks'
import useIntercomBootStatus from './useIntercomBooted'

let lastPageId: string | undefined

const usePage = (view: Page | undefined, analyticsEventName?: string, trackEventOptions?: ITrackEventOptions) => {
  const dispatch = useAppDispatch()
  const intercomBooted = useIntercomBootStatus()

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Rendering view:', view?.id)
  }, [view?.id])

  useEffect(() => {
    if (view?.id === lastPageId) {
      return
    }

    if (analyticsEventName && (intercomBooted || !intercomService.enabled)) {
      analyticsService.trackEvent(analyticsEventName, trackEventOptions)
    }
  }, [analyticsEventName, trackEventOptions, intercomBooted, view?.id])

  useEffect(() => {
    dispatch(changePage(view))

    if (intercomBooted || !intercomService.enabled) {
      lastPageId = view?.id
    }
  }, [dispatch, view, intercomBooted])
}

export default usePage
