import { ArcElement, Chart as ChartJS, ChartOptions } from 'chart.js'
import { t } from 'i18next'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import { Box } from '@mui/system'

import utilsService, { ChartColorList } from '../../../../services/UtilsService'
import './MotivationArcheTypePieChart.scss'

type colorlist = ChartColorList & { o?: number }
const defaultColorLists: colorlist[] = utilsService
  .getChartColorList()
  .slice(0, 3)
  .map((color) => ({ ...color, o: 1 }))

const backgroundColors = (fillLength: number = 0) => {
  if (fillLength <= 0) {
    return defaultColorLists.map((color) => `rgba(${color.r},${color.g},${color.b},${color.o || 1})`)
  }

  const filledColor = Array(fillLength).fill('rgba(119,119,119, 0.1)')
  return defaultColorLists.map((color) => `rgba(${color.r},${color.g},${color.b},${color.o || 1})`).concat(filledColor)
}
/**
 * CustomLegend
 */
interface CustomLegendProps {
  data: MotivationArcheTypeData[]
  onArcheTypeClicked?: (arecheTypeIndex: number) => void
}
const CustomLegend: React.FC<CustomLegendProps> = ({ data, onArcheTypeClicked }) => {
  const topData = data.slice(0, 3)
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
      <Box className="CustomLegend__title" sx={{ mb: 1 }}>
        {t('motivations:top_3_segments')}
      </Box>
      {topData.map((archeType, index) => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', mb: 0.5, cursor: 'pointer' }}
          onClick={() => onArcheTypeClicked && onArcheTypeClicked(index)}
          key={archeType.label}
        >
          <Box sx={{ mr: 1, width: '14px', height: '14px', borderRadius: '50%', backgroundColor: backgroundColors()[index] }} />
          <strong>{`${archeType.label} (${Math.round(archeType.data * 100)}%)`}</strong>
        </Box>
      ))}
    </Box>
  )
}

ChartJS.register(ArcElement)

export interface MotivationArcheTypeData {
  label: string
  data: number
}
/**
 * MotivationArcheTypePieChart
 */
interface MotivationArcheTypePieChartProps {
  data: MotivationArcheTypeData[]
  onArcheTypeClicked?: (arecheTypeIndex: number) => void
}
const MotivationArcheTypePieChart: React.FC<MotivationArcheTypePieChartProps> = ({ data, onArcheTypeClicked }) => {
  const chartData = {
    labels: data.map((d) => d.label),
    datasets: [
      {
        data: data.map((d) => Math.round(d.data * 100)),
        backgroundColor: backgroundColors(data.length - 2),
      },
    ],
  }

  const options: ChartOptions<'doughnut'> = {
    onClick: (event, elements) => {
      if (onArcheTypeClicked) {
        onArcheTypeClicked(elements[0].index)
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'average',
        yAlign: 'center',
        titleFont: {
          size: 14,
          weight: 'bolder' as const,
        },
        titleMarginBottom: 14,
        bodySpacing: 6,
        boxPadding: 6,
        usePointStyle: true,
        padding: 10,
        itemSort: (a, b) => {
          return a.element.y - b.element.y
        },
        callbacks: {
          label: (context) => {
            const data = context.dataset.data[context.dataIndex] || ''
            const label = context.label || ''
            return `${label}: ${data}%`
          },
        },
      },
    },
  }

  if (!data.length) {
    return null
  }

  return (
    <Box>
      <CustomLegend data={data} onArcheTypeClicked={onArcheTypeClicked} />
      <Doughnut data={chartData} options={options} />
    </Box>
  )
}

export default MotivationArcheTypePieChart
