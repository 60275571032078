import { FC, ReactNode, useCallback, useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'

import { Box, Chip, Dialog, DialogContent, DialogProps, Divider, Grid, Tab, Tabs, Typography } from '@mui/material'

import { useGetTrackedEventQuery } from '../../../../api/core'
import { GRBanner } from '../../../../components/GRBanner/GRBanner'
import GRDialogTitle, { GRDialogCloseButton } from '../../../../components/GRDialogTitle/GRDialogTitle'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import { useError } from '../../../../hooks/useError'
import languageService from '../../../../services/LanguageService'
import { GameCardContent } from '../../../game/components/GameCard/GameCard'
import { useRouteModal } from '../../../route-modals/hooks/useRouteModal'
import { useEventDialogDuration } from '../../hooks/useEventDialogDuration'
import { EventDialogTab, useEventDialogTabs } from '../../hooks/useEventDialogTabs'
import { useLiveEventTagGroupWithEventTypeId } from '../../hooks/useLiveEventTagGroups'
import { useLiveEventDialogAnalyticsEvents } from '../../hooks/useLiveEventsTrackerAnalyticsEvents'
import { LiveEventTagGroup } from '../../types/LiveEventTagGroup'
import { TrackedGame } from '../../types/TrackedGame'
import { TrackingEvent } from '../../types/TrackingEvents'
import { LiveEventsNotPublishedBadge } from '../LiveEventsNotPublishedBadge/LiveEventsNotPublishedBadge'
import { AllEventsTab } from './AllEventsTab/AllEventsTab'
import { DescriptionTab } from './DescriptionTab/DescriptionTab'
import { InstancesTab } from './InstancesTab/InstancesTab'
import './LiveEventsEventDialog.scss'
import { OtherGamesTab } from './OtherGamesTab/OtherGamesTab'

/**
 * Displays a dialog with tabs for browsing single event details, all events related to a game and other games related to
 * the selected event type
 */
type LiveEventsEventDialogProps = DialogProps & {
  data: LiveEventsEventDialogData
  children?: ReactNode
  onTrackedGameChange: (trackedGame: TrackedGame) => void
  onEventChange: (event: TrackingEvent) => void
  onTabChange: (tab: EventDialogTab) => void
  onScreenshotIndexChange: (screenshotIndex: number) => void
}

export type LiveEventsEventDialogData = {
  eventId?: string
  eventTypeId?: string
  trackedGame?: TrackedGame
  tab?: EventDialogTab
  highlightedEventId?: string
  screenshotIndex?: number
}

export const LiveEventsEventDialog: FC<LiveEventsEventDialogProps> = ({
  open,
  onClose,
  onTrackedGameChange,
  onEventChange,
  onTabChange,
  onScreenshotIndexChange,
  data: { eventId, eventTypeId, trackedGame, tab, screenshotIndex },
  children,
}) => {
  const eventQuery = useGetTrackedEventQuery(eventId as string, { skip: !eventId })
  useError({ error: eventQuery.error })

  const { popAllModals } = useRouteModal()
  const tabOptions = useEventDialogTabs()
  const resolvedEventTypeId = !!eventId ? eventQuery.data?.event.typeId : eventTypeId
  const tagName = resolvedEventTypeId && languageService.getTranslation('tags', resolvedEventTypeId)
  const tagGroup = useLiveEventTagGroupWithEventTypeId(resolvedEventTypeId || '')
  const duration = useEventDialogDuration({ durations: eventQuery.data?.durations })

  const dialogContentRef = useRef<HTMLElement>(null)
  useLiveEventDialogAnalyticsEvents(
    trackedGame?.game?.names['us'] || trackedGame?.game?.name,
    trackedGame?.game?.appId,
    eventId,
    tagName,
    eventTypeId,
    eventTypeId ? languageService.getTranslation('tags', eventTypeId) : '',
    tab
  )

  const switchTab = useCallback(
    (tab: EventDialogTab) => {
      onTabChange(tab)
    },
    [onTabChange]
  )

  const handleOnTrackedGameClick = (trackedGame: TrackedGame) => {
    switchTab(EventDialogTab.AllEvents)
    onTrackedGameChange(trackedGame)
  }

  const handleEventChange = (event: TrackingEvent) => {
    switchTab(EventDialogTab.Description)
    onEventChange(event)
  }

  const handleEventHighlighted = (eventId: string) => {
    popAllModals()
  }

  useEffect(() => {
    if (tagGroup && tagGroup.id === LiveEventTagGroup.DEFINING_OCCURRENCES && tab !== EventDialogTab.Description) {
      switchTab(EventDialogTab.Description)
    }
  }, [tagGroup, tab, switchTab])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      disableRestoreFocus
      className="LiveEventsEventDialog"
      PaperProps={{ sx: { minHeight: '80vh', maxHeight: '90vh' } }}
    >
      <GRDialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={3}>
            {trackedGame && (
              <div className="LiveEventsEventDialog__GameCardContent">
                <GameCardContent game={trackedGame.game} displayGameType />
                {!trackedGame.published && <LiveEventsNotPublishedBadge />}
              </div>
            )}
          </Grid>
          <Grid item xs={6} container direction="column" alignItems="center">
            <Typography variant="h3">{tagName}</Typography>
            {tagGroup && (
              <Box mt={0.5} display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                <Chip
                  label={tagGroup.name}
                  style={{ marginBottom: '4px', marginRight: '4px', color: 'white', backgroundColor: tagGroup.colorHex }}
                  size="small"
                ></Chip>
              </Box>
            )}
          </Grid>
          <Grid item xs={3} container direction="column" alignItems="flex-end">
            <GRDialogCloseButton onClick={onClose} share />
          </Grid>
        </Grid>
      </GRDialogTitle>
      <Divider />

      {tagGroup && tagGroup.id !== LiveEventTagGroup.DEFINING_OCCURRENCES && (
        <Tabs value={tab || EventDialogTab.Description}>
          {tabOptions.map((tab) => {
            const disabled = [EventDialogTab.Description, EventDialogTab.Instances].includes(tab.id) && !eventId
            return (
              <Tab
                icon={tab.icon}
                iconPosition="start"
                key={tab.id}
                label={
                  disabled && tab.tooltip ? (
                    <GRTooltip content={tab.tooltip}>
                      <>{tab.label}</>
                    </GRTooltip>
                  ) : (
                    tab.label
                  )
                }
                onClick={() => !disabled && switchTab(tab.id)}
                value={tab.id}
                sx={{ minHeight: 56 }}
                style={{ pointerEvents: 'auto' }}
                disabled={disabled}
                component="div"
              />
            )
          })}
        </Tabs>
      )}

      <DialogContent ref={dialogContentRef} style={{ overflowY: 'scroll' }}>
        {eventQuery.isError && !eventQuery.isFetching ? (
          <GRBanner severity="guidance">
            <Trans i18nKey="live-events:event_not_available" />
          </GRBanner>
        ) : (
          <>
            {tab === EventDialogTab.Description && (
              <DescriptionTab
                currentTrackedGame={trackedGame}
                eventId={eventId}
                eventTypeId={resolvedEventTypeId}
                eventTypeName={tagName}
                screenshotIndex={screenshotIndex}
                duration={duration}
                onScreenshotIndexChange={onScreenshotIndexChange}
                onScrollParentTop={() => dialogContentRef && dialogContentRef.current?.scrollTo({ top: 0, behavior: 'smooth' })}
                onEventHighlighted={handleEventHighlighted}
              />
            )}
            {tab === EventDialogTab.Instances && (
              <InstancesTab
                eventId={eventId}
                eventTypeId={resolvedEventTypeId}
                currentTrackedGame={trackedGame}
                duration={duration}
                onEventHighlighted={handleEventHighlighted}
              />
            )}
            {tab === EventDialogTab.AllEvents && (
              <AllEventsTab currentTrackedGame={trackedGame} currentEventTypeId={eventTypeId} currentEventId={eventId} onEventSelect={handleEventChange} />
            )}
            {tab === EventDialogTab.OtherGames && (
              <OtherGamesTab eventTypeId={eventTypeId} currentTrackedGame={trackedGame} onTrackedGameClick={handleOnTrackedGameClick} />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
