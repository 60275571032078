import { useEffect } from 'react'

export type Size = {
  height: number
  width: number
}

export const useDomSize = (element: HTMLElement | null, ratio: number, callback: (size: Size) => void) => {
  return useEffect(() => {
    if (element) {
      const width = element.offsetWidth
      const height = ratio ? width / ratio : element.offsetHeight
      callback({ height, width })
    }
  }, [element, ratio, callback])
}
