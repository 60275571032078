import classNames from 'classnames'
import { ReactNode, FC, useState } from 'react'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Collapse, Grid, Typography } from '@mui/material'

import './SegmentSection.scss'

/**
 * Component representing a section in segment edit dialog
 */
type SegmentSectionProps = {
  title: string
  subtitle?: string
  collapsible?: boolean
  collapsed?: boolean
  children?: ReactNode
}

export const SegmentSection: FC<SegmentSectionProps> = ({ title, subtitle, collapsible = false, collapsed = true, children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed)
  const collapseArrowClasses = classNames('collapseArrow', { isCollapsed })
  return (
    <Grid container direction="column" spacing={2} sx={{ mb: 3 }} className="SegmentSection">
      <Grid
        container
        item
        gap={1}
        alignItems="center"
        onClick={() => collapsible && setIsCollapsed((value) => !value)}
        sx={{ '&:hover': collapsible ? { cursor: 'pointer' } : {} }}
        tabIndex={0}
      >
        <Typography variant="h3">
          {title}{' '}
          {subtitle && (
            <Typography variant="body1" component="span">
              ({subtitle})
            </Typography>
          )}
        </Typography>
        {collapsible && <KeyboardArrowUpIcon className={collapseArrowClasses} />}
      </Grid>
      <Grid item>
        <Collapse in={isCollapsed}>{children}</Collapse>
      </Grid>
    </Grid>
  )
}
