import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Lock } from '@mui/icons-material'

import { LockedFeatureId } from '../../types/LockedFeature'
import { GRBanner } from '../GRBanner/GRBanner'
import { LockedFeature } from '../LockedFeature/LockedFeature'

/**
 * Component for displaying limited functionality banner. Clicking the banner opens locked feature dialog with
 * content based on the given lockedFeatureId prop.
 */

type LimitedFunctionalityBannerProps = {
  lockedFeatureId: LockedFeatureId
  children?: ReactNode
}

export const LimitedFunctionalityBanner: FC<LimitedFunctionalityBannerProps> = ({ lockedFeatureId, children }) => {
  const { t } = useTranslation()
  const [lockedFeatureDialogOpen, setLockedFeatureDialogOpen] = useState<boolean>(false)
  return (
    <>
      <GRBanner severity="success" icon={<Lock />} onClick={() => setLockedFeatureDialogOpen(true)}>
        {t('common:limited_functionality_banner')}
      </GRBanner>
      <LockedFeature.Dialog lockedFeatureId={lockedFeatureId} open={lockedFeatureDialogOpen} onClose={() => setLockedFeatureDialogOpen(false)} />
    </>
  )
}
