import React, { FC, memo, useMemo } from 'react'

import { GRCountryFlag } from '../../../../components/GRCountryFlag/GRCountryFlag'
import { SoftLaunchMarket } from '../../../../types/SoftLaunchMarket'
import './SoftLaunchMarketList.scss'

interface SoftLaunchMarketListProps {
  softLaunchMarkets: Array<SoftLaunchMarket>
  size?: 'small' | 'medium' | 'large'
}

/**
 * Component for listing Soft Launch markets as country flags. Tier 1 markets separated
 */
const SoftLaunchMarketList: FC<SoftLaunchMarketListProps> = ({ softLaunchMarkets, size = 'medium' }) => {
  const sortedSoftLaunchMarketGroups = useMemo(() => {
    const sortedMarkets = softLaunchMarkets.sort(sortSoftLaunchMarkets)
    const tier1Markets = sortedMarkets.filter((m) => m.tier1Market)
    const otherMarkets = sortedMarkets.filter((m) => !m.tier1Market)

    return [[...tier1Markets], [...otherMarkets]]
  }, [softLaunchMarkets])

  return (
    <div className="SoftLaunchMarketList">
      {sortedSoftLaunchMarketGroups.map((marketGroup, marketGroupIndex) => {
        return (
          <div className="SoftLaunchMarketList__group" key={marketGroupIndex}>
            {marketGroup.map((market) => {
              return (
                <div className={`SoftLaunchMarketList__group__item SoftLaunchMarketList__group__item__${size}`} key={market.iso}>
                  <GRCountryFlag countryCode={market.iso} title={market.name} size={size} />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default memo(SoftLaunchMarketList)

const sortSoftLaunchMarkets = (a: SoftLaunchMarket, b: SoftLaunchMarket) =>
  a.order && b.order && a.order !== b.order ? a.order - b.order : a.iso < b.iso ? -1 : 1
