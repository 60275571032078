import { getDateTimeFormatter } from '../../../helpers/date'
import { useCurrentUserLanguage } from '../../account/hooks/useCurrentUserLanguage'
import { UserLanguage } from '../../account/types/User'

type DateTimeFormatterHookParams = Intl.DateTimeFormatOptions & {
  language?: UserLanguage
}

/**
 * Hook for receiving an Intl.DateTimeFormat based on current user's langauge (default)
 */
export const useDateTimeFormatter = ({ language, ...options }: DateTimeFormatterHookParams = {}) => {
  const userLanguage = useCurrentUserLanguage()
  const resolvedLanguage = language || userLanguage
  const resolvedOptions = Object.keys(options).length > 0 ? options : undefined
  const formatter = getDateTimeFormatter(resolvedLanguage, resolvedOptions)

  return {
    ...formatter,
    format: (value?: string | number | Date) => (typeof value === 'string' ? value : formatter.format(value)),
  }
}
