import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Info } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'

import DateRangePicker, { DateRangeValue } from '../../../../components/DateRangePicker/DateRangePicker'
import utilsService from '../../../../services/UtilsService'
import {
  dateRangeOption120Days,
  dateRangeOption30Days,
  dateRangeOption60Days,
  dateRangeOption7Days,
  dateRangeOption90Days,
  dateRangeOptionAllAvailable,
  dateRangeOptionLastYear,
  dateRangeOptionToday,
} from '../../../../types/DateRangeOptions'
import { RoleEnum } from '../../../account'
import { useRoleCheck } from '../../../account/hooks/roleHooks'

type LiveEventsFeedFiltersProps = {
  dateRange?: DateRangeValue
  minDate?: Date
  maxDate?: Date
  onDateRangeChange: (value?: DateRangeValue) => void
}

export const LiveEventsFeedFilters: FC<LiveEventsFeedFiltersProps> = ({ dateRange, minDate, maxDate, onDateRangeChange }) => {
  const { t } = useTranslation()

  const liveEventsTrackerUnlocked = useRoleCheck(RoleEnum.live_events_tracker)
  const showHappeningTodayDescription = useMemo(() => {
    const todayTimestamp = new Date().setHours(23, 59, 59, 999)

    if (dateRange?.fromDate && dateRange.toDate) {
      return (
        dateRange.toDate.getTime() === todayTimestamp &&
        utilsService.getDayDifferenceFromTwoDates(dateRange.fromDate.getTime(), dateRange.toDate.getTime()) <= 1
      )
    }

    return 0
  }, [dateRange])

  const dateRangeOptions = useMemo(() => {
    return [
      dateRangeOptionToday,
      dateRangeOption7Days,
      dateRangeOption30Days,
      dateRangeOption60Days,
      dateRangeOption90Days,
      dateRangeOption120Days,
      dateRangeOptionLastYear,
      dateRangeOptionAllAvailable,
    ]
  }, [])

  return (
    <>
      <Grid container alignItems="center" wrap="wrap" spacing={2}>
        <Grid item>
          <DateRangePicker
            value={dateRange}
            onChangeValue={(value) => onDateRangeChange(value)}
            minDate={minDate}
            maxDate={maxDate}
            disabled={!liveEventsTrackerUnlocked}
            dateRangeOptions={dateRangeOptions}
          />
        </Grid>
        {showHappeningTodayDescription && (
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Info color="action" />
              </Grid>
              <Grid item xs>
                <Typography color="action" variant="body1">
                  {t('live-events:happening_today')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}
