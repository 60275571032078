import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { storeSubgenres } from '../actions/searchActions'
import { useGetUserSettingsQuery } from '../api/combined'
import { SubgenreMap } from '../features/account'
import analyticsService, { ITrackEventOptions } from '../services/AnalyticsService'
import { AnalyticsProviders } from '../types/IAnalyticsProvider'

export const useSubgenrePicker = (initialSubgenres?: SubgenreMap, trackEventId?: string, hasDefaultSubgenre?: boolean) => {
  const { data: userSettings } = useGetUserSettingsQuery()
  const dispatch = useDispatch()
  const defaultSubgenres = useMemo(() => {
    if ((initialSubgenres && Object.keys(initialSubgenres).length) || hasDefaultSubgenre) return initialSubgenres
    return userSettings?.userDefaultConventionalSubgenres || {}
  }, [hasDefaultSubgenre, initialSubgenres, userSettings?.userDefaultConventionalSubgenres])

  const [subgenres, setSubgenres] = useState<SubgenreMap>(defaultSubgenres || {})

  const handleSubgenresChange = useCallback(
    (subgenres: SubgenreMap) => {
      if (trackEventId) {
        analyticsService.trackEvent(`${trackEventId}: Filtered Games`, {
          data: {
            typeId: Object.keys(subgenres),
          },
          serviceToExclude: [AnalyticsProviders.hubspot],
        } as ITrackEventOptions)
      }
      setSubgenres(subgenres)
      dispatch(storeSubgenres(subgenres))
    },
    [dispatch, trackEventId]
  )

  return { subgenres, handleSubgenresChange }
}
