import { ConventionalCategoriesMap, ConventionalGenresMap, ConventionalSubgenresMap, useGetGenreTaxonomyQuery } from '../api/core'
import { useCurrentUserLanguage } from '../features/account/hooks/useCurrentUserLanguage'
import { ConventionalCategory, ConventionalGenre, ConventionalSubgenre } from '../features/genre-taxonomy'

export const useConventionalSubgenresMap = () => {
  const userLanguage = useCurrentUserLanguage()
  const { data: genreTaxonomy, isLoading, isFetching, isError, error } = useGetGenreTaxonomyQuery({ userLanguage })

  let map: ConventionalSubgenresMap = {}

  if (genreTaxonomy) {
    genreTaxonomy.forEach((category: ConventionalCategory) => {
      category.genres.forEach((genre: ConventionalGenre) => {
        genre.subgenres.forEach((subgenre: ConventionalSubgenre) => {
          map[subgenre.id] = {
            category,
            genre,
            subgenre,
          }
        })
      })
    })
  }

  return { map, isLoading: isLoading || isFetching, isError, error }
}

export const useConventionalGenresMap = () => {
  const userLanguage = useCurrentUserLanguage()
  const { data: genreTaxonomy, isLoading, isFetching, isError, error } = useGetGenreTaxonomyQuery({ userLanguage })

  let map: ConventionalGenresMap = {}

  if (genreTaxonomy) {
    genreTaxonomy.forEach((category: ConventionalCategory) => {
      category.genres.forEach((genre: ConventionalGenre) => {
        map[genre.id] = {
          category,
          genre,
        }
      })
    })
  }

  return { map, isLoading: isLoading || isFetching, isError, error }
}

export const useConventionalCategoriesMap = () => {
  const userLanguage = useCurrentUserLanguage()
  const { data: genreTaxonomy, isLoading, isFetching, isError, error } = useGetGenreTaxonomyQuery({ userLanguage })

  let map: ConventionalCategoriesMap = {}

  if (genreTaxonomy) {
    genreTaxonomy.forEach((category: ConventionalCategory) => {
      map[category.id] = category
    })
  }

  return { map, isLoading: isLoading || isFetching, isError, error }
}
