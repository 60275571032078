import React from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Box, Card, CardContent, Divider, Link, Typography } from '@mui/material'

import { GRTooltip } from '../../../components/GRTooltip/GRTooltip'
import { GameIcon } from '../../game'
import { useCurrentMarket } from '../../markets'
import { getAttributeNameByModel } from '../hooks/useIconAttributes'
import { RankType, VisualAnalysisWithRank, VisualsAttribute, VisualsGroupedData, VisualSortType } from '../types'
import { getIconComparator } from '../util/comparators'
import { AppStoreGenreMap } from '../util/visualStats'
import './VisualIconsList.scss'

type TooltipProps = {
  attributes: VisualsAttribute[]
  item: VisualAnalysisWithRank
  marketIso: string
  rankText: string
}

interface VisualIconsListProps {
  attributes: VisualsAttribute[]
  data: VisualsGroupedData
  genreId: string
  rankType: RankType
  onSelectAnalysis: (analysis: VisualAnalysisWithRank) => void
  sortBy: VisualSortType
  top: number
}

type TooltipValueProps = {
  attribute: VisualsAttribute
  item: VisualAnalysisWithRank
}

const TooltipValue: React.FC<TooltipValueProps> = ({ attribute, item }) => {
  const attrName = getAttributeNameByModel(attribute.model)
  const attrs = item[attrName]
  const value: number = (attrs && attrs[attribute.name]) || 0
  return value <= 0 ? null : (
    <small style={{ display: 'block', textTransform: 'capitalize' }}>
      {attribute.name}: {Math.round(value * 100)}%
    </small>
  )
}

const Tooltip: React.FC<TooltipProps> = ({ attributes, item, marketIso, rankText }) => {
  return (
    <>
      <strong>{item.gameNames[marketIso]}</strong>
      <br />
      <small>{rankText}</small>
      {!!attributes?.length && (
        <>
          <Divider sx={{ my: 1 }} />
          {attributes.map((attribute) => (
            <TooltipValue key={attribute.name} attribute={attribute} item={item} />
          ))}
        </>
      )}
    </>
  )
}

const VisualIconsList: React.FC<VisualIconsListProps> = ({ attributes, data, genreId, onSelectAnalysis, rankType, sortBy, top }) => {
  const { t } = useTranslation()
  const comparator = getIconComparator(sortBy, rankType, attributes)
  const { currentMarketIso: marketIso } = useCurrentMarket()
  return (
    <Box className="VisualIconsList">
      {['top', 'out'].map((group) => {
        const rankText = AppStoreGenreMap[genreId] + ' / ' + t(`common:${rankType === 'free' ? 'sustained_download_rank' : 'sustained_grossing_rank'}`)
        const items = data[group as keyof VisualsGroupedData]
        return (
          <Card key={'group-' + group} sx={{ mt: 2 }}>
            <CardContent>
              <Typography variant="h3" mb={2}>
                {t('common:' + (group === 'out' ? 'outside_' : '') + 'top_' + top)} {rankText}
              </Typography>
              {!items.length && (
                <Typography mb={4} mt={-1} variant="body2">
                  {t('visuals-explorer:visual_attribute_modal_no_games_found')}
                </Typography>
              )}
              {items.sort(comparator).map((item, index) => (
                <Box className="VisualIconsList__gameList" key={index}>
                  <GRTooltip
                    content={
                      <Tooltip
                        marketIso={marketIso}
                        item={item}
                        rankText={rankText + ': ' + (rankType === 'free' ? item.sdrank : item.srank)}
                        attributes={attributes}
                      />
                    }
                    key={group + '-icon-' + index}
                  >
                    <LazyLoadComponent>
                      <Link onClick={() => onSelectAnalysis(item)}>
                        <GameIcon gameName={item.gameNames[marketIso]} src={item.url} />
                      </Link>
                    </LazyLoadComponent>
                  </GRTooltip>
                </Box>
              ))}
            </CardContent>
          </Card>
        )
      })}
    </Box>
  )
}

export default VisualIconsList
