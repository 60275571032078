import React, { memo, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Card, Grid, TableContainer } from '@mui/material'

import { useRoleCheck } from '../../features/account/hooks/roleHooks'
import { RoleEnum } from '../../features/account/types/RoleEnum'
import { RevenueAndDownloadEstimates } from '../../features/estimates/services/RevenueAndDownloadEstimates'
import { GameCardContent } from '../../features/game/components/GameCard/GameCard'
import { GamePowerscore } from '../../features/game/components/GamePowerscore/GamePowerscore'
import { Game } from '../../features/game/types/Game'
import { CellValue } from '../../features/soft-launch/components/CellValue/CellValue'
import { CurrencyIndicator } from '../../features/soft-launch/components/CurrencyIndicator/CurrencyIndicator'
import { TrendIndicator } from '../../features/soft-launch/components/TrendIndicator/TrendIndicator'
import { AnalystOverviewDialog } from '../AnalystOverviewDialog/AnalystOverviewDialog'
import { GRTable, GRTableColumn, SortOrder } from '../GRTable/GRTable'
import { LockedData } from '../LockedData/LockedData'
import NotAvailableIcon from '../NotAvailableIcon/NotAvailableIcon'
import { ReviewIndicator } from '../ReviewIndicator/ReviewIndicator'
import styles from './GamesTableWith30DRevDlEstimates.module.scss'

type GamesTableWith30DRevDlEstimatesProps = {
  games: Game[]
  marketIso: string
  isLoading: boolean
  hasUndefinedSubgenre?: boolean
}

/**
 * Component for displaying Games Table with 30 Days Revenue & Downloads Estimates
 */
export const GamesTableWith30DRevDlEstimates: React.FC<GamesTableWith30DRevDlEstimatesProps> = memo(({ games, marketIso, isLoading, hasUndefinedSubgenre }) => {
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const [analystOverviewDialogGame, setAnalystOverviewDialogGame] = useState<Game | null>(null)
  const estimatesUnlocked = useRoleCheck(RoleEnum.revenue_estimates)

  const tableColumns: GRTableColumn<Game, undefined>[] = [
    {
      labelAccessor: t('common:game'),
      columns: [
        {
          labelAccessor: t('common:name'),
          accessor: ({ row: game }) => {
            return <GameCardContent game={game} />
          },
          sortable: true,
          sortAccessor: 'name',
          cellProps: { align: 'left' },
          headerCellProps: { align: 'left', sx: { minWidth: 200 } },
          defaultSortOrder: SortOrder.ASC,
        },
        {
          labelAccessor: t('common:game_power_score'),
          accessor: ({ row: game }) => (
            <Grid container alignItems="center" justifyContent="center">
              <Grid item width={'40px'}>
                {game.reviewId && game.reviewPublished && <ReviewIndicator onClick={() => setAnalystOverviewDialogGame(game)} />}
              </Grid>
              <Grid item width={'40px'} marginRight={'40px'}>
                <GamePowerscore value={game.gpsPerMarket[marketIso]} />
              </Grid>
            </Grid>
          ),
          sortable: true,
          sortAccessor: ({ row: game }) => game.gpsPerMarket[marketIso],
          cellProps: { align: 'center' },
          headerCellProps: { sx: { width: 80 } },
          defaultSortOrder: SortOrder.DESC,
        },
      ],
    },
    {
      labelAccessor: `${t('common:30_days_revenue')} (${t('common:apple_ios')})`,
      columns: [
        {
          labelAccessor: t('common:value_text'),
          accessor: ({ row }) => (
            <>
              {estimatesUnlocked && new RevenueAndDownloadEstimates(row, marketIso).revenue30Day.value > 0 && (
                <CurrencyIndicator estimate={new RevenueAndDownloadEstimates(row, marketIso).revenue30Day} maxFractionDigits={2} shorten={true} />
              )}
              {estimatesUnlocked && !new RevenueAndDownloadEstimates(row, marketIso).revenue30Day.value && <NotAvailableIcon />}
              {!estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortOrder: SortOrder.DESC,
          sortAccessor: ({ row }) => new RevenueAndDownloadEstimates(row, marketIso).revenue30Day.value,
        },
        {
          labelAccessor: t('common:trend'),
          accessor: ({ row }) => (
            <>
              {estimatesUnlocked && new RevenueAndDownloadEstimates(row, marketIso).revenueTrend30Day.value !== 0 && (
                <TrendIndicator estimate={new RevenueAndDownloadEstimates(row, marketIso).revenueTrend30Day} />
              )}
              {estimatesUnlocked && !new RevenueAndDownloadEstimates(row, marketIso).revenueTrend30Day.value && <NotAvailableIcon />}
              {!estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row }) => new RevenueAndDownloadEstimates(row, marketIso).revenueTrend30Day.value,
        },
      ],
    },
    {
      labelAccessor: `${t('common:30_days_downloads')} (${t('common:apple_ios')})`,
      columns: [
        {
          labelAccessor: t('common:value_text'),
          accessor: ({ row }) => (
            <>
              {estimatesUnlocked && new RevenueAndDownloadEstimates(row, marketIso).downloads30Day.value > 0 && (
                <CellValue value={new RevenueAndDownloadEstimates(row, marketIso).downloads30Day.value} emphasized>
                  {new RevenueAndDownloadEstimates(row, marketIso).downloads30Day.format({ maxFractionDigits: 2, shorten: true })}
                </CellValue>
              )}
              {estimatesUnlocked && !new RevenueAndDownloadEstimates(row, marketIso).downloads30Day.value && <NotAvailableIcon />}
              {!estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row }) => new RevenueAndDownloadEstimates(row, marketIso).downloads30Day.value,
        },
        {
          labelAccessor: t('common:trend'),
          accessor: ({ row }) => (
            <>
              {estimatesUnlocked && new RevenueAndDownloadEstimates(row, marketIso).downloadsTrend30Day.value !== 0 && (
                <TrendIndicator estimate={new RevenueAndDownloadEstimates(row, marketIso).downloadsTrend30Day} />
              )}
              {estimatesUnlocked && !new RevenueAndDownloadEstimates(row, marketIso).downloadsTrend30Day.value && <NotAvailableIcon />}
              {!estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row }) => new RevenueAndDownloadEstimates(row, marketIso).downloadsTrend30Day.value,
        },
      ],
    },
    {
      labelAccessor: `${t('common:30_days_revenue_download_ratio')} (${t('common:apple_ios')})`,
      columns: [
        {
          labelAccessor: t('common:value_text'),
          accessor: ({ row }) => (
            <>
              {estimatesUnlocked && new RevenueAndDownloadEstimates(row, marketIso).revenueAndDownloadsRatio30Day.value > 0 && (
                <CurrencyIndicator
                  estimate={new RevenueAndDownloadEstimates(row, marketIso).revenueAndDownloadsRatio30Day}
                  maxFractionDigits={2}
                  threshold={0}
                />
              )}
              {estimatesUnlocked && !new RevenueAndDownloadEstimates(row, marketIso).revenueAndDownloadsRatio30Day.value && <NotAvailableIcon />}
              {!estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row }) => new RevenueAndDownloadEstimates(row, marketIso).revenueAndDownloadsRatio30Day.value,
        },
        {
          labelAccessor: t('common:trend'),
          accessor: ({ row }) => (
            <>
              {estimatesUnlocked && new RevenueAndDownloadEstimates(row, marketIso).revenueAndDownloadsRatioTrend30Day.value !== 0 && (
                <TrendIndicator estimate={new RevenueAndDownloadEstimates(row, marketIso).revenueAndDownloadsRatioTrend30Day} />
              )}
              {estimatesUnlocked && !new RevenueAndDownloadEstimates(row, marketIso).revenueAndDownloadsRatioTrend30Day.value && <NotAvailableIcon />}
              {!estimatesUnlocked && <LockedData />}
            </>
          ),
          sortable: true,
          sortAccessor: ({ row }) => new RevenueAndDownloadEstimates(row, marketIso).revenueAndDownloadsRatioTrend30Day.value,
        },
      ],
    },
  ]

  const [columns, setColumns] = useState<GRTableColumn<Game, undefined>[]>(tableColumns)
  const handleColumnsUpdate = useCallback((updatedColumns: GRTableColumn<Game, undefined>[]) => {
    setColumns(updatedColumns)
  }, [])

  return (
    <>
      <TableContainer component={Card} ref={containerRef} className={styles.container}>
        <GRTable
          rows={games}
          columns={columns}
          striped
          hoverable
          onColumnsUpdated={handleColumnsUpdate}
          rowIdKey="id"
          noRowsLabel={hasUndefinedSubgenre ? t('market-explorer:no_games_available_with_undefined_subgenre') : t('market-explorer:no_games_available')}
          isLoading={isLoading}
          scroller={containerRef}
        />
      </TableContainer>
      <AnalystOverviewDialog
        reviewId={analystOverviewDialogGame?.reviewId}
        gameName={analystOverviewDialogGame?.resolvedName}
        open={!!analystOverviewDialogGame}
        onClose={() => setAnalystOverviewDialogGame(null)}
      />
    </>
  )
})
