const ENV_CONFIG = require(`./config.${process.env.REACT_APP_ENVIRONMENT}.json`)

declare global {
  interface Window {
    GR_API_URLS: {
      API_URL_CORE: string
      API_URL_TOP_GROSSING: string
      API_URL_FEED: string
      API_URL_ACCOUNT: string
      API_URL_CONNECT_SERVER: string
      API_URL_AI_ANALYST: string
      API_URL_REPORTS: string
      API_URL_AI_ANALYST_INTERNAL: string
    }
    Keycloak: any
    MIXPANEL_CONFIG: {
      key: string
      enable: boolean
    }
    INTERCOM_CONFIG: {
      appId: string
      enable: boolean
    }
    _hsq: any // hubspotquery
    Intercom: any
  }
}

if (!window.GR_API_URLS) {
  window.GR_API_URLS = ENV_CONFIG.GR_API_URLS
}

if (!window.MIXPANEL_CONFIG) {
  window.MIXPANEL_CONFIG = ENV_CONFIG.MIXPANEL_CONFIG
}

if (!window.INTERCOM_CONFIG) {
  window.INTERCOM_CONFIG = ENV_CONFIG.INTERCOM_CONFIG
}

export {}
