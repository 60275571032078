export enum Region {
  Asia = 'Asia',
  Europe = 'Europe',
  Africa = 'Africa',
  Oceania = 'Oceania',
  Americas = 'Americas',
}

export type MarketRegion = {
  name: string
  marketIsos: string[]
}

export type Regions = {
  [region in Region]: MarketRegion
}
