import { ChartData, ChartOptions, TooltipCallbacks } from 'chart.js'
import { FC } from 'react'
import { Doughnut } from 'react-chartjs-2'

type DoughnutChartProps = {
  data: ChartData<'doughnut'>
  tooltipCallbacks?: Partial<TooltipCallbacks<'doughnut'>>
}

export const DoughnutChart: FC<DoughnutChartProps> = ({ data, tooltipCallbacks }) => {
  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          pointStyle: 'circle' as const,
          usePointStyle: true,
          padding: 32,
        },
      },
      tooltip: {
        position: 'average',
        yAlign: 'center',
        titleFont: {
          size: 14,
          weight: 'bolder' as const,
        },
        titleMarginBottom: 14,
        bodySpacing: 6,
        boxPadding: 6,
        usePointStyle: true,
        padding: 10,
        callbacks: tooltipCallbacks,
      },
    },
  }

  return <Doughnut data={data} options={options} />
}
