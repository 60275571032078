export const replaceLastMatchWith = (value: string, match: string, replaceValue?: string) => {
  const decodedValue = decodeURIComponent(value)
  const decodedMatch = decodeURIComponent(match)
  const decodedReplaceValue = replaceValue ? decodeURIComponent(replaceValue) : ''

  return decodedValue.substring(0, decodedValue.lastIndexOf(decodedMatch)) + decodedReplaceValue
}

export const includesIgnoreCase = (value: string, match: string) => {
  return value.toLowerCase().includes(match.toLowerCase())
}
