import { Analyst } from '../../../types/Analyst'
import { UserLanguage } from '../../account/types/User'

export type Report = {
  id: string
  fileUrl: string
  coverUrls: string[]
  publishedAt: number
  published: boolean
  gameIds: string[]
  analysts: Analyst[]
  tags: string[]
  visibility: ReportVisibility
  comment: { [languageKey in UserLanguage]?: string }
  title: { [languageKey in UserLanguage]?: string }
  conventionalCategories: string[]
  conventionalGenres: string[]
  conventionalSubgenres: string[]
  downloadable: boolean
}

export enum ReportVisibility {
  public = 'PUBLIC',
  private = 'PRIVATE',
  all = 'ALL',
}
