import React from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@mui/material'

import { SelectMenu, SelectMenuOption } from '../../../../components/SelectMenu/SelectMenu'

export enum compareFeatureSetOpts {
  all = 'all',
  similar = 'similar',
  different = 'different',
}

/**
 * FeatureSetSelection Component to select feature set type for filtering
 */
interface FeatureSetSelectionProps {
  onChange: (option: compareFeatureSetOpts) => void
  value: compareFeatureSetOpts
}
const FeatureSetSelection: React.FC<FeatureSetSelectionProps> = ({ onChange, value }) => {
  const { t } = useTranslation()
  const options: SelectMenuOption<compareFeatureSetOpts>[] = [
    { name: t('compare:show_all'), value: compareFeatureSetOpts.all },
    { name: t('compare:only_similarities'), value: compareFeatureSetOpts.similar },
    { name: t('compare:only_differences'), value: compareFeatureSetOpts.different },
  ]

  return (
    <FormControl size="small" variant="outlined" sx={{ width: 250 }}>
      <SelectMenu label={t('common:feature_set')} value={value} onChange={(e) => onChange(e.target.value)} options={options} />
    </FormControl>
  )
}

export default FeatureSetSelection
