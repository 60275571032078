import { FC, CSSProperties } from 'react'
import { CustomMarker } from 'react-calendar-timeline'

import { GRTooltip } from '../../../../../components/GRTooltip/GRTooltip'

/**
 * Component for displaying a custom timeline marker for min/max timerange in react calendar timeline component
 */
type TimeRangeMarkerProps = {
  time: number
  tooltipLabel: string
  severity?: 'success' | 'warning'
  styleOverrides?: CSSProperties
}

const colorBySeverity = {
  success: '#38a73e',
  warning: '#b13d37',
}

export const TimeRangeMarker: FC<TimeRangeMarkerProps> = ({ time, tooltipLabel, severity, styleOverrides = {} }) => {
  const backgroundColor = (severity && colorBySeverity[severity]) || '#bd65cf'
  return (
    <CustomMarker date={time}>
      {({ styles }) => (
        <GRTooltip
          content={tooltipLabel}
          severity={severity}
          usePointerPosition
          styles={{ ...styles, backgroundColor, width: '3px', pointerEvents: 'initial', ...styleOverrides }}
        ></GRTooltip>
      )}
    </CustomMarker>
  )
}
