import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Box, Card, CardContent, CardHeader, Divider, Grid, Tab, Tabs, Typography } from '@mui/material'

import { useGetMarketFeaturesQuery } from '../../../../api/core'
import { useGetTopGamesWithAppIdsQuery } from '../../../../api/top-grossing'
import { GRTooltip } from '../../../../components/GRTooltip/GRTooltip'
import languageService from '../../../../services/LanguageService'
import motivationService from '../../../../services/MotivationService'
import { DemographicsColor } from '../../../demographics/types/DemographicsColor'
import { FeatureLink } from '../../../feature/components/FeatureLink'
import { GameIcon } from '../../../game'
import { MotivationKey, MotivationType } from '../../../market-explorer/types/MotivationType'
import { useCurrentMarket } from '../../../markets'
import useGetSegmentMotivationsType from '../../hooks/useGetSegmentMotivationsType'
import { MotivationArcheType, motivationParentTranslationKey } from '../MotivationArcheTypeCard/MotivationArcheTypeCard'
import MotivationRadarChart, { MotivationRadarChartProps } from '../MotivationRadarChart/MotivationRadarChart'
import './ArchetypeDescriptions.scss'

const genders = ['male', 'female']

const ageGroupsWithTranslations = [
  ['age16to24', 'age16_24'],
  ['age25to44', 'age25_44'],
  ['age45plus', 'age45_plus'],
]

interface ArchetypeDescriptionsProps extends MotivationRadarChartProps {
  motivationKey: MotivationKey
  onSetMotivationKey: (key?: MotivationKey) => void
}

export const ArchetypeDescriptions: React.FC<ArchetypeDescriptionsProps> = ({ labels, motivationKey, onSetMotivationKey, datasets, colorLists }) => {
  const { currentMarketIso: marketIso } = useCurrentMarket()
  const { segmentMotivationsType } = useGetSegmentMotivationsType()
  const motivationKeys = Object.keys(motivationService.keyMotivations) as MotivationKey[]
  const featureLegacyIds = motivationService.getFeaturesTheyPrefer(motivationKey)
  const appIds = motivationService.getGamesTheyPrefer(motivationKey)
  const include = 'appId,name,gameId,images'

  const { data: featuresData } = useGetMarketFeaturesQuery({ marketIso, featureLegacyIds })
  const { data: gamesData } = useGetTopGamesWithAppIdsQuery({ appIds, marketIso, include })

  const getDemographicsColorForAgeGroup = (ageGroup: string) => {
    switch (ageGroup) {
      case 'age16to24':
        return DemographicsColor.Age16_24

      case 'age25to44':
        return DemographicsColor.Age25_44

      case 'age45plus':
        return DemographicsColor.Age45_plus

      default:
        return ''
    }
  }

  const datasetsWithSelectedMotivationKey = useMemo(() => {
    return [
      ...datasets,
      {
        label: languageService.getTranslation(motivationParentTranslationKey, motivationKey),
        data: Object.keys(segmentMotivationsType).map((motivationOrder) =>
          motivationService.getKeyMotivation(motivationKey, motivationOrder as MotivationType)
        ),
      },
    ]
  }, [datasets, motivationKey, segmentMotivationsType])

  return (
    <Box className="ArchetypeDescriptions">
      <Card sx={{ mt: 4 }}>
        <CardHeader
          className="ArchetypeDescriptions__archetypeTabsContainer"
          title={
            <Tabs value={motivationKey} centered={true} className="ArchetypeDescriptions__archetypeTabs">
              {motivationKeys.map((key) => (
                <Tab
                  label={languageService.getTranslation(motivationParentTranslationKey, key)}
                  value={key}
                  key={key}
                  onClick={() => onSetMotivationKey(key)}
                />
              ))}
            </Tabs>
          }
        />
        <CardContent>
          <MotivationArcheType motivationKey={motivationKey} />
          <Divider />
          <>
            <Typography variant="h3" mt={4}>
              <Trans i18nKey={'motivations:motivational_drivers'} />
            </Typography>
            <Typography my={2}>
              <Trans i18nKey={'motivations:description'} />
            </Typography>
            <MotivationRadarChart datasets={datasetsWithSelectedMotivationKey} labels={labels} colorLists={colorLists} />
            <Divider />
          </>

          <Grid container justifyContent="space-between" mt={4} columnSpacing={4}>
            <Grid item sm={6}>
              <Typography variant="h3">
                <Trans i18nKey="motivations:example_of_games_they_like" />
                <Box my={2} className="GameList">
                  {gamesData?.map((game, index) => (
                    <GRTooltip key={index} content={game.name}>
                      <Link to={`/game/${game.gameId}`}>
                        <GameIcon gameName={game.name} src={game.images[0]} />
                      </Link>
                    </GRTooltip>
                  ))}
                </Box>
              </Typography>
              <Typography variant="h3" gutterBottom>
                <Trans i18nKey="motivations:demographics" />
              </Typography>
              <Grid container justifyContent="space-between">
                {genders.map((gender) => (
                  <Grid item key={gender} sm={6}>
                    <Typography variant="subtitle2" textAlign="center">
                      <Trans i18nKey={`common:${gender}`} />
                    </Typography>
                    <Typography variant="h3" textAlign="center">
                      {motivationService.getDemographic(motivationKey, gender)}%
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Box className="ArchetypeDescriptions__demographicsPercentBar">
                {['male', 'female'].map((gender, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: motivationService.getDemographic(motivationKey, gender) + '%',
                      backgroundColor: gender === 'male' ? DemographicsColor.Male : DemographicsColor.Female,
                    }}
                  />
                ))}
              </Box>
              <Grid container justifyContent="space-between" mt={4}>
                {ageGroupsWithTranslations.map(([age, translation]) => {
                  return (
                    <Grid item key={age} sm={4}>
                      <Typography variant="subtitle2" textAlign="center">
                        <Trans i18nKey={`common:${translation}`} />
                      </Typography>
                      <Typography variant="h3" textAlign="center">
                        {motivationService.getDemographic(motivationKey, age)}%
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
              <Box className="ArchetypeDescriptions__demographicsPercentBar">
                {ageGroupsWithTranslations.map(([age], index) => (
                  <Box
                    key={index}
                    sx={{ width: motivationService.getDemographic(motivationKey, age) + '%', backgroundColor: getDemographicsColorForAgeGroup(age) }}
                  />
                ))}
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Typography variant="h3">
                <Trans i18nKey="motivations:features_they_prefer" />
              </Typography>
              <ul>
                {featuresData?.map(({ name: featureName, legacyId, choices }) => {
                  return (
                    <li key={legacyId}>
                      <FeatureLink feature={{ featureLegacyId: legacyId, featureName }}>
                        <Typography variant="subtitle2">{languageService.getTranslation('features', legacyId.toString())}</Typography>
                      </FeatureLink>
                    </li>
                  )
                })}
              </ul>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}
