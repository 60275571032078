import { FC, forwardRef } from 'react'
import { ReactCalendarGroupRendererProps } from 'react-calendar-timeline'

import { Grid, Link } from '@mui/material'

import { LiveEventTagGroup } from '../../../../types/LiveEventTagGroup'
import { TrackingEventTimelineGroup } from '../../../../types/LiveEvents'
import { EventTagGroupIndicator } from '../../../EventTagGroupIndicator/EventTagGroupIndicator'

type LiveEventsCalendarTrackingEventGroupProps = ReactCalendarGroupRendererProps<TrackingEventTimelineGroup> & {
  onClick: (group: TrackingEventTimelineGroup) => void
}

export const LiveEventsCalendarTrackingEventGroup: FC<LiveEventsCalendarTrackingEventGroupProps> = forwardRef<
  HTMLDivElement,
  LiveEventsCalendarTrackingEventGroupProps
>(({ group, onClick }, ref) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      ref={ref}
      style={{ position: 'relative' }}
      id={(group.trackedGame.game.id + '-' + group.id) as string}
    >
      <EventTagGroupIndicator colorHex={group.colorHex} sx={{ left: '-8px' }} />

      {group.tagGroupId !== LiveEventTagGroup.DEFINING_OCCURRENCES ? (
        <Link
          underline="none"
          sx={{ color: (theme) => theme.palette.text.primary, '&:hover': { color: (theme) => theme.palette.primary.main, cursor: 'pointer' } }}
          onClick={() => onClick(group as TrackingEventTimelineGroup)}
        >
          {group.title}
        </Link>
      ) : (
        <>{group.title}</>
      )}
    </Grid>
  )
})
