import { FC } from 'react'

import { Grid } from '@mui/material'

import { Game } from '../../../../../features/game'
import { GameCardContent } from '../../../../../features/game/components/GameCard/GameCard'
import { Analyst as TAnalyst } from '../../../../../types/Analyst'
import { Analyst } from '../TrackedGamesTable/TrackedGamesTable'

type AnalystNoteEditHeaderProps = {
  game: Game | undefined
  swicthAnalyst: TAnalyst | undefined
}

export const AnalystNoteEditHeader: FC<AnalystNoteEditHeaderProps> = ({ game, swicthAnalyst }) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>{game && <GameCardContent game={game} disableGameLink />}</Grid>
      <Grid item>
        <Analyst analyst={swicthAnalyst} avatarProps={{ sx: { width: 70, height: 70 } }} />
      </Grid>
    </Grid>
  )
}
